<div style="margin-top: 50px;">
  <p-tabView [(activeIndex)]="indexTab" [scrollable]="true" (onChange)="removerNotification($event)">
    <p-tabPanel >
      <ng-template pTemplate="header">
        <div class="lg:w-11rem md:w-6rem h-2rem flex" style="font-size: small;">
          <div class="lg:w-6 md:w-6  flex align-items-center"style="font-weight: bolder;">
            <span>
              {{'OrderDetailTabComponent.PartyInformationTabText' | translate}}
            </span>
          </div>
          <div class="w-3 align-items-center">
            <div *ngIf="delivery?.partyInfoNotification" class="flex justify-content-end">
                <i class="pi pi-info-circle"></i>
            </div>
          </div>
          <div class="w-3 align-items-center">
            <div class="flex justify-content-end">
              <a class="round-icon bill-icon">
                <i class="pi pi-credit-card"></i>
              </a>
            </div>
          </div>
        </div>
      </ng-template>
      <app-import-order-bill-info
      [delivery]="delivery"
      >
    </app-import-order-bill-info>
    </p-tabPanel>


    <p-tabPanel>
      <ng-template pTemplate="header">
        <div class="lg:w-11rem md:w-6rem h-2rem flex" style="font-size: small;">
          <div class="lg:w-6 md:w-4" style="font-weight: bolder;">
            {{'OrderDetailTabComponent.LoadingInfoTabText' | translate}}
          </div>
          <div  class="w-3 align-items-center">
            <div *ngIf="delivery?.loadingInfoNotification" class="flex justify-content-end">
                <i class="pi pi-info-circle"></i>
            </div>
          </div>
          <div class="w-3 align-items-end">
            <div class="flex justify-content-end">
              <a class="round-icon ship-icon">
                <fa-icon [icon]="icons.faShip" [size]="'2x'"></fa-icon>
              </a>
            </div>
          </div>
        </div>
      </ng-template>
      <app-import-order-loading-data
      [delivery]="delivery"
      >
      </app-import-order-loading-data>
    </p-tabPanel>


    <p-tabPanel >
      <ng-template pTemplate="header">
        <div class="lg:w-11rem md:w-6rem h-2rem flex" style="font-size: small;">
          <div class="lg:w-6 md:w-4" style="font-weight: bolder;">
            {{'OrderDetailTabComponent.DocumentsTabText' | translate}}
          </div>
          <div class="w-3 align-items-center">
            <div *ngIf="delivery?.documentsNotification" class="flex justify-content-end">
                <i class="pi pi-info-circle"></i>
            </div>
          </div>
          <div class="w-3 align-items-center">
            <div class="flex justify-content-end">
              <a class="round-icon folder-icon">
                <i class="pi pi-folder-open"></i>
              </a>
            </div>
          </div>
        </div>
      </ng-template>
      <!-- <app-import-order-operation [order]="order"></app-import-order-operation> -->
      <app-import-order-documents
       [typeDocuments]="0"
       [importOrder]="importOrder"
       [details]="importOrderDetails"
       [operationDocuments]="operationDocuments"
       [forwarderDocuments]="forwarderDocuments"
       [customAgentDocuments]="customAgentDocuments"
       [invoices]="invoices"></app-import-order-documents>
    </p-tabPanel>


    <p-tabPanel>
      <ng-template pTemplate="header">
        <div class="lg:w-11rem md:w-6rem h-2rem flex" style="font-size: small;">
          <div class="lg:w-6 md:w-4" style="font-weight: bolder;">
            {{'OrderDetailTabComponent.ReportsTabText' | translate}}
          </div>
          <div  class="w-3 align-items-center">
            <div *ngIf="delivery?.reportsNotification" class="flex justify-content-end">
                <i class="pi pi-info-circle"></i>
            </div>
          </div>
          <div class="w-3 align-items-center">
            <div class="flex justify-content-end">
              <a class="round-icon file-icon">
                <i class="pi pi-file"></i>
              </a>
            </div>
          </div>
        </div>
      </ng-template>
      <!-- <app-import-order-operation [order]="order"></app-import-order-operation> -->
      <app-import-order-documents
       [typeDocuments]="1"
       [importOrder]="importOrder"
       [qualityControlDocuments]="qualityControlDocuments"
       [productCertificateDocuments]="productCertificateDocuments"
       [exportOfferDocuments]="exportOfferDocuments"></app-import-order-documents>
    </p-tabPanel>

    <p-tabPanel>
      <ng-template pTemplate="header">
        <div class="lg:w-11rem md:w-6rem h-2rem flex" style="font-size: small;">
          <div class="lg:w-6 md:w-4" style="font-weight: bolder;">
            {{'OrderDetailTabComponent.PaymentsTabText' | translate}}
          </div>
          <div  class="w-3 align-items-center">
            <div *ngIf="delivery?.paymentsNotification" class="flex justify-content-end">
              <i class="pi pi-info-circle"></i>
            </div>
          </div>
          <div class="w-3 align-items-center">
            <div class="flex justify-content-end">
              <a class="round-icon dollar-icon">
                <i class="pi pi-dollar"></i>
              </a>
            </div>
          </div>
        </div>
      </ng-template>
      <app-import-order-payments
        [delivery]="delivery"
        [discounts]="discounts"
        [directSaleProgramId]="directSaleProgramId"
        [importOrder]="order?.importOrder"
        [importOrderDetails]="importOrderDetails"
        [payments]="payments"
        [isClosedSale]="isClosedSale"
        [paymentsQualityControl]="paymentsQualityControl"
        >
      </app-import-order-payments>
    </p-tabPanel>
  </p-tabView>
</div>
