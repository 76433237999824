const fruztyApi = '/api/fruzty'

export class Routes {
    // Pages
    public static homePage: string = ''
    public static personPage = 'person'
    public static forbiddenPage = 'forbidden'
    public static login = 'login'
    public static signup = 'signup'
    public static signupCompany = 'signupCompany'
    public static signupExporterAddInfo = 'signupExporterAddInfo'
    public static signupImporterAddInfo = 'signupImporterAddInfo'
    public static signupInvitationCode = 'signup-invitation-code'
    public static userDashboard = 'user-dashboard'
    public static userContactInfo = 'user-contact-info'
    public static userCompanyInfo = 'user-company-info'
    public static userProducts = 'user-products'
    public static userFridgestorages = 'user-fridgestorages'
    public static userConsignees = 'user-consignees'
    public static consigneeForm = 'consignee'
    public static userNotifiers = 'user-notifiers'
    public static notifierForm = 'notifier'
    public static fridgestorageForm = 'fridgestorage'
    public static userChangePassword = 'user-change-password'
    public static resetPasswordRequestPage = 'reset-password-request'
    public static resetPasswordPage = 'reset-password'
    public static userNotifications = 'user-notifications'
    public static userNotificationsConfig = 'user-notifications-config'
    public static userSupport = 'user-support'
    public static marketplacePage = 'marketplace'
    public static externalMarketplace = 'offers'
    public static userExportOffers = 'export-offers'
    public static newExportOffer = 'new-export-offer'
    public static editExportOffer = 'edit-export-offer'
    public static userProductCertificates = 'product-certficates'
    public static productCertificateForm = 'product-certificate'
    public static purchaseOrdersPage = 'purchase-orders'
    public static containerOrderPage = 'container-order'
    public static userPurchaseOrders = 'user-purchase-orders'
    public static verifyPalletsPage = 'verify-pallets'
    public static userOrderDetailPage = 'user-order-detail'
    public static userConfirmPurchasePage = 'user-confirm-purchase'
    public static userPaymentsPage = 'payments-page'
    public static adminManageOrderPage = 'admin-manage-orders'
    public static userBargaining = 'user-bargaining'
    public static bargainingDetail = 'bargaining-detail'
    public static adminOrderDetail = 'admin-order-detail'
    public static adminVerifyPallets = 'admin-verify-pallets'
    public static adminCompleteOrder = 'admin-complete-order'
    public static userExportOfferSuggestionPage = 'user-export-offer-suggestions'
    public static adminDelivery = 'admin-delivery'
    public static userOfferSuggestionsPage = 'offer-suggestions'
    public static adminOperation = 'admin-operation'
    public static adminExportOfferPage = 'admin-export-offers'
    public static adminExportOfferEdit = 'admin-edit-export-offer'
    public static adminSpecies = 'admin-species'
    public static adminSpeciesNew = 'admin-new-species'
    public static adminSpeciesEdit = 'admin-species-compability'
    public static adminUsers = 'admin-users'
    public static adminEditUser = 'admin-edit-user'
    public static userImportRequest = 'user-import-request'
    public static userNewImportRequest = 'user-new-import-request'
    public static userEditImportRequest = 'user-edit-import-request'
    public static userViewImportRequest = 'user-view-import-request'
    public static userClosedSales = 'user-closed-sales'
    public static userCreateClosedSale = 'user-create-closed-sale'
    public static userEditClosedSale = 'user-edit-closed-sale'
    public static exporterClosedSaleOrdersPage = 'exporter-closed-sale-orders'
    public static userClosedSaleImportOrderDetailPage = 'user-closed-sale-order-detail'
    public static userClosedSaleExporterVerifyPallets = 'user-closed-sale-exporter-verify-pallets'
    public static userClosedSalePaymentsPage = 'user-closed-sale-payments'
    public static importerClosedSaleOrdersPage = 'importer-closed-sale-orders'
    public static userPanelPage = 'user-panel'
    public static userImportOrdersPending = 'user-import-orders-pending'
    public static userImportOrdersApproved = 'user-import-orders-approved'
    public static userImportOrdersConfirmed = 'user-import-orders-confirmed'
    public static userImportOrdersCreated = 'user-import-orders-created'
    public static userImportOrdersCurrent = 'user-import-orders-current'
    public static userImportOrdersPurchase = 'user-import-orders-purchase'
    public static userImportOrdersMbl = 'user-import-orders-mbl'
    public static adminNotifications = 'admin-notifications'
    public static adminMasterLoadPage = 'admin-master-load'
    public static adminEditPallets = 'admin-edit-pallets'
    public static signupQCCompany = 'signup-qccompany'
    public static qcUserReviews = 'qc-user-reviews'
    public static qcUserOriginReview = 'qc-user-origin-review'
    public static qcUserOriginReviewed = 'qc-user-origin-reviewed'
    public static forwarderCreateOrderPage = 'forwarder-create-order'
    public static adminCreateUserPage = 'admin-create-user'
    public static adminExporterImportersPage = 'admin-exporter-importers'
    public static userFrozenFruitExportOfferCreate = 'user/frozen-fruit-export-offer/create'
    public static userFrozenFruitExportOffersActivePage = 'user/frozen-fruit-export-offers/active'
    public static userFrozenFruitExportOffersInactivePage = 'user/frozen-fruit-export-offers/inactive'
    public static userFrozenFruitExportOfferEdit = 'user/frozen-fruit-export-offer/edit'
    public static userFrozenFruitExportOfferEditPictures = 'user/frozen-fruit-export-offer/edit-pictures'
    public static userFrozenFruitExportOfferEditDocuments = 'user/frozen-fruit-export-offer/edit-documents'
    public static userFrozenFruitPurchaseOrdersPage = 'frozen-fruit-purchase-orders-page'
    public static userFrozenFruitVerifyPalletsPage = 'user/frozen-fruit/verify-pallets'
    public static qcUserFrozenFruitOriginReviewPage = 'qc-user/frozen-fruit/verify-pallets'
    public static adminFrozenFruitVerifyPalletsPage = 'admin/frozen-fruit/verify-pallets'
    public static adminInvitationCodes = 'invitation-codes'
    public static exportOfferEditAdditionalInfo = 'export-offer/edit/additional-info'
    public static exportOfferDocumentsPage = 'export-offer/documents'
    public static userLoadOrders = 'user-load-orders'
    public static qcUserReviewDocuments = 'qc-user/review/documents'
    public static adminManageQCReviews = 'admin/qc-reviews'
    public static adminQCReview = 'admin/qc-review'
    public static adminQCReviewDocuments = 'admin/qc-review/documents'
    public static userDirectSaleCreate = 'user/direct-sale/create'
    public static userProgramRequestDirectSaleCreate = 'user/direct-sale/create-from-program-request'
    public static userDirectSaleEdit = 'user/direct-sale/edit'
    public static userDirectSaleDocuments = 'user/direct-sale/documents'
    public static userDirectSaleEditAdditionalInfo = 'user/direct-sale/additional-info'
    public static userProgramRequest = 'user/request-program'
    public static userProgramRequestCreate = 'user/request-program/create'
    public static userProgramRequestEdit = 'user/request-program/edit'
    public static userValidationToken = 'validation-account'
    public static forwarderUserReviews = 'forwarder-user-reviews'
    public static forwarderUserOriginReview = 'forwarder-user-origin-review'
    public static forwarderUserOriginReviewed = 'forwarder-user-origin-reviewed'
    public static forwarderUsers = 'forwarder-users'
    public static forwarderUsersDelivery = 'forwarder-delivery'
    public static adminForwarder = 'admin/forwarder'
    public static adminCreateForwarderPage = 'admin/create-forwarder'
    public static adminEditForwarderPage = 'admin/edit-forwarder'
    public static adminCustomAgent = 'admin/custom-agent'
    public static adminCreateCustomAgentPage = 'admin/create-custom-agent'
    public static adminEditCustomAgentPage = 'admin/edit-custom-agent'
    public static customAgentUserReviews = 'custom-agent-user-reviews'
    public static customAgentUserOriginReview = 'custom-agent-user-origin-review'
    public static customAgentUserOriginReviewed = 'custom-agent-user-origin-reviewed'
    public static customAgentUsers = 'custom-agent-users'
    public static forwarderImportOrders = 'forwarder-import-order'
    public static forwarderImportOrderDetail = 'forwarder-import-order-detail'
    public static forwarderImportOrderEdit = 'forwarder-import-order/edit'
    public static userImportOrdersForwarder = 'user-import-orders-forwarder'
    public static qcOrderDetailPage = 'qc-order-detail'
    public static qcOrderPaymentPage = 'qc-order-payment'
    public static offerDetail = 'offer-detail'
    public static productDetail = 'product-detail'
    public static importRequestDetail = 'import-request-detail'

    public static toDoPage = 'to-do'

    public static userOrderNotFound = 'user-order-not-found'

    //Apis

    //Person Api
    public static PersonApi: string = '/api/fruzty/Person'
    //Login
    public static securityApi: string = '/api/identity/Security'

    public static securityCodeApi: string = '/api/fruzty/Security'

    public static userApi: string = '/api/identity/Users'

    public static ProfileApi: string = '/api/fruzty/Profile'

    public static countryApi: string = '/api/fruzty/Country'

    public static speciesApi: string = '/api/fruzty/Species'

    public static fridgestorageApi: string = '/api/fruzty/FridgeStorage'

    public static consigneeApi: string = '/api/fruzty/Consignee'

    public static notifierApi: string = '/api/fruzty/Notifier'

    public static issueApi: string = '/api/fruzty/Issue'

    public static exportOfferApi: string = `${fruztyApi}/ExportOffer`

    public static varietyApi: string = `${fruztyApi}/Variety`

    public static productCertificateTypeApi: string = `${fruztyApi}/ProductCertificateType`

    public static speciesSizeApi: string = `${fruztyApi}/SpeciesSize`

    public static importOrderApi: string = `${fruztyApi}/ImportOrder`

    public static importOrderDetailApi: string = `${fruztyApi}/ImportOrderDetail`

    public static portApi: string = `${fruztyApi}/Port`

    public static airportApi: string = `${fruztyApi}/Airport`

    public static productCertificateApi: string = `${fruztyApi}/ProductCertificate`

    public static offerNegotiationApi: string = `${fruztyApi}/OfferNegotiation`

    public static estimationApi: string = `${fruztyApi}/Estimation`

    public static palletApi: string = `${fruztyApi}/Pallet`

    public static suggestionApi: string = `${fruztyApi}/Suggestion`

    public static notificationApi: string = `${fruztyApi}/Notification`

    public static exportOfferSuggestionApi: string = `${fruztyApi}/Suggestion`

    public static deliveryApi: string = `${fruztyApi}/Delivery`

    public static paymentApi: string = `${fruztyApi}/Payment`

    public static operationApi: string = `${fruztyApi}/Operation`

    public static importRequestApi: string = `${fruztyApi}/ImportRequest`

    public static userJoinRequestApi: string = `${fruztyApi}/UserJoinRequest`

    public static qcCompanyApi: string = `${fruztyApi}/QualityControlCompany`

    public static exportOfferDocumentApi: string = `${fruztyApi}/ExportOfferDocument`

    public static documentServiceApi: string = `${fruztyApi}/Document`

    public static deliveryDiscountApi: string = `${fruztyApi}/DeliveryDiscount`

    public static frozenFruitExportOfferApi: string = `${fruztyApi}/FrozenFruitExportOffer`

    public static frozenFruitPalletApi: string = `${fruztyApi}/FrozenFruitPallet`

    public static genericOrderDetailApi: string = `${fruztyApi}/GenericOrderDetail`

    public static frozenFruitOrderDetailApi: string = `${fruztyApi}/FrozenFruitOrderDetail`

    public static invitationCodeApi: string = '/api/fruzty/InvitationCode'

    public static packagingApi: string = `${fruztyApi}/Packaging`

    public static qcReviewApi: string = `${fruztyApi}/QualityControlReview`

    public static directSaleProgramApi: string = `${fruztyApi}/DirectSaleProgram`
    public static exportInvoiceApi: string = `${fruztyApi}/ExportInvoice`
    public static operationDocumentTypeApi: string = `${fruztyApi}/OperationDocumentType`
    public static forwarderReviewApi: string = `${fruztyApi}/ForwarderReview`
    public static importOrderDocumentApi: string = `${fruztyApi}/ImportOrderDocument`
    public static companyApi: string = `${fruztyApi}/Company`
    public static profileCompanyApi: string = `${fruztyApi}/ProfileCompany`
    public static programRequestApi: string = `${fruztyApi}/ProgramRequest`
    public static customAgentReviewApi: string = `${fruztyApi}/CustomAgentReview`
    public static importOrderReviewMessageApi: string = `${fruztyApi}/ImportOrderReviewMessage`
    public static forwarderApi: string = `${fruztyApi}/Forwarder`
    public static paymentControlQualityApi: string = `${fruztyApi}/PaymentQualityControl`

}
