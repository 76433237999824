<app-dashboard-layout>
  <app-content-header>
    <app-home-header></app-home-header>
  </app-content-header>
  <app-content-body>
    <div class="flex">
      {{'SignupPage.StepText' | translate }} 3 {{'SignupPage.AllStepText' | translate }} 3
      <br/>
      {{'SignupPage.SignupPageImportAddInfo.PageTitle' | translate }}
    </div>
    <div class="flex flex-wrap flex-column justify-content-center align-items-center">
      <h3>{{'SignupPage.JoinUsTitle' | translate }}</h3>
      <div>{{'SignupPage.SignupPageImportAddInfo.PageSubtitle' | translate }}</div>
      <p-card>
        <h3>CONSIGNEE</h3>
        <div style="margin-bottom: 20px;">
          {{'SignupPage.SignupPageImportAddInfo.ConsigneeInformationPleaseText' | translate }}
        </div>
        <form [formGroup]="consigneeFormGroup">
          <div class="p-fluid formgrid grid" style="width: 800px;">
            <div class="field col-12">
              <label for="name">{{'SignupPage.SignupPageImportAddInfo.ConsigneeNameFieldLabel' | translate }} *</label>
              <input id="name" formControlName="name" type="text" required pInputText placeholder="{{'SignupPage.SignupPageImportAddInfo.ConsigneeNameFieldPlaceholder' | translate }}"/>
              <small *ngIf="submit && consigneeFormGroup.controls['name'].invalid" class="p-error">{{getErrorMessage('name')}}</small>
            </div>
            <div class="field col-12">
              <label for="vat">{{'SignupPage.SignupPageImportAddInfo.VatConsigneeFieldLabel' | translate }} *</label>
              <input id="vat" formControlName="vat" type="text" required pInputText placeholder="VAT"/>
              <small *ngIf="submit && consigneeFormGroup.controls['vat'].invalid" class="p-error">{{getErrorMessage('vat')}}</small>
            </div>
            <div class="field col-12">
              <label for="country">{{'SignupPage.SignupPageImportAddInfo.CountryFieldLabel' | translate }} *</label>
              <p-dropdown id="country"
              formControlName="country"
              [options]="countries"
              optionLabel="name"
              [filter]="true"
              filterBy="name"
              [showClear]="true"
              placeholder="{{'SignupPage.SignupPageImportAddInfo.CountryFieldPlaceholder' | translate }}">
              <ng-template let-country pTemplate="item">
                <div class="country-item">
                  <div>{{country.name}}</div>
                </div>
              </ng-template>
            </p-dropdown>
              <small *ngIf="submit && consigneeFormGroup.controls['country'].invalid" class="p-error">{{getErrorMessage('country')}}</small>
            </div>
            <div class="field col-12">
              <label for="contactName">{{'SignupPage.SignupPageImportAddInfo.ContactNameFieldLabel' | translate }} *</label>
              <input id="contactName" formControlName="contactName" type="text" required pInputText placeholder="{{'SignupPage.SignupPageImportAddInfo.ContactNameFieldPlaceholder' | translate }}"/>
              <small *ngIf="submit && consigneeFormGroup.controls['contactName'].invalid" class="p-error">{{getErrorMessage('contactName')}}</small>
            </div>
            <div class="field col-12">
              <label for="contactAddress">{{'SignupPage.SignupPageImportAddInfo.AddressContactFieldLabel' | translate }} *</label>
              <input id="contactAddress" formControlName="contactAddress" type="text" required pInputText placeholder="{{'SignupPage.SignupPageImportAddInfo.AddressContactFieldPlaceholder' | translate }}"/>
              <small *ngIf="submit && consigneeFormGroup.controls['contactAddress'].invalid" class="p-error">{{getErrorMessage('contactAddress')}}</small>
            </div>
            <div class="field col-12">
              <label for="city">{{'SignupPage.SignupPageImportAddInfo.CityFieldLabel' | translate }} *</label>
              <input id="city" formControlName="city" type="text" required pInputText placeholder="{{'SignupPage.SignupPageImportAddInfo.CityFieldPlaceholder' | translate }}"/>
              <small *ngIf="submit && consigneeFormGroup.controls['city'].invalid" class="p-error">{{getErrorMessage('city')}}</small>
            </div>
            <div class="field col-12">
              <label for="contactEmail">{{'SignupPage.SignupPageImportAddInfo.ContactEmailFieldLabel' | translate }} *</label>
              <input id="contactEmail" formControlName="contactEmail" type="text" required pInputText placeholder="{{'SignupPage.SignupPageImportAddInfo.ContactEmailFieldPlaceholder' | translate }}"/>
              <small *ngIf="submit && consigneeFormGroup.controls['contactEmail'].invalid" class="p-error">{{getErrorMessage('contactEmail')}}</small>
            </div>
            <div class="field col-12">
              <label for="contactPhone">{{'SignupPage.SignupPageImportAddInfo.ContactPhoneFieldLabel' | translate }} *</label>
              <input id="contactPhone" formControlName="contactPhone" type="text" required pInputText placeholder="{{'SignupPage.SignupPageImportAddInfo.ContactPhoneFieldPlaceholder' | translate }}"/>
              <small *ngIf="submit && consigneeFormGroup.controls['contactPhone'].invalid" class="p-error">{{getErrorMessage('contactPhone')}}</small>
            </div>
          </div>
        </form>
      </p-card>
      <p-card style="margin-top: 30px">
        <h3>NOTIFY</h3>
        <div style="margin-bottom: 20px;">
          {{'SignupPage.SignupPageImportAddInfo.NotifyInformationPleaseText' | translate }}
        </div>
        <form [formGroup]="notifyFormGroup">
          <div class="p-fluid formgrid grid" style="width: 800px;">
            <div class="field col-12">
              <label for="name">{{'SignupPage.SignupPageImportAddInfo.NotifyNameFieldLabel' | translate }} *</label>
              <input id="name" formControlName="name" type="text" required pInputText placeholder="{{'SignupPage.SignupPageImportAddInfo.NotifyNameFieldPlaceholder' | translate }}"/>
              <small *ngIf="submit && notifyFormGroup.controls['name'].invalid" class="p-error">{{getErrorMessage('name')}}</small>
            </div>
            <div class="field col-12">
              <label for="vat">{{'SignupPage.SignupPageImportAddInfo.VatFieldNotifyLabel' | translate }} *</label>
              <input id="vat" formControlName="vat" type="text" required pInputText placeholder="VAT"/>
              <small *ngIf="submit && notifyFormGroup.controls['vat'].invalid" class="p-error">{{getErrorMessage('vat')}}</small>
            </div>
            <div class="field col-12">
              <label for="country">{{'SignupPage.SignupPageImportAddInfo.CountryFieldLabel' | translate }} *</label>
              <p-dropdown id="country"
              formControlName="country"
              [options]="countries"
              optionLabel="name"
              [filter]="true"
              filterBy="name"
              [showClear]="true"
              placeholder="{{'SignupPage.SignupPageImportAddInfo.CountryFieldPlaceholder' | translate }}">
              <ng-template let-country pTemplate="item">
                <div class="country-item">
                  <div>{{country.name}}</div>
                </div>
              </ng-template>
            </p-dropdown>
              <small *ngIf="submit && notifyFormGroup.controls['country'].invalid" class="p-error">{{getErrorMessage('country')}}</small>
            </div>
            <div class="field col-12">
              <label for="contactName">{{'SignupPage.SignupPageImportAddInfo.ContactNameFieldLabel' | translate }} *</label>
              <input id="contactName" formControlName="contactName" type="text" required pInputText placeholder="{{'SignupPage.SignupPageImportAddInfo.ContactNameFieldPlaceholder' | translate }}"/>
              <small *ngIf="submit && notifyFormGroup.controls['contactName'].invalid" class="p-error">{{getErrorMessage('contactName')}}</small>
            </div>
            <div class="field col-12">
              <label for="contactAddress">{{'SignupPage.SignupPageImportAddInfo.AddressContactFieldLabel' | translate }} *</label>
              <input id="contactAddress" formControlName="contactAddress" type="text" required pInputText placeholder="{{'SignupPage.SignupPageImportAddInfo.AddressContactFieldPlaceholder' | translate }}"/>
              <small *ngIf="submit && notifyFormGroup.controls['contactAddress'].invalid" class="p-error">{{getErrorMessage('contactAddress')}}</small>
            </div>
            <div class="field col-12">
              <label for="city">{{'SignupPage.SignupPageImportAddInfo.CityFieldLabel' | translate }} *</label>
              <input id="city" formControlName="city" type="text" required pInputText placeholder="{{'SignupPage.SignupPageImportAddInfo.CityFieldPlaceholder' | translate }}"/>
              <small *ngIf="submit && notifyFormGroup.controls['city'].invalid" class="p-error">{{getErrorMessage('city')}}</small>
            </div>
            <div class="field col-12">
              <label for="contactEmail">{{'SignupPage.SignupPageImportAddInfo.ContactEmailFieldLabel' | translate }} *</label>
              <input id="contactEmail" formControlName="contactEmail" type="text" required pInputText placeholder="{{'SignupPage.SignupPageImportAddInfo.ContactEmailFieldPlaceholder' | translate }}"/>
              <small *ngIf="submit && notifyFormGroup.controls['contactEmail'].invalid" class="p-error">{{getErrorMessage('contactEmail')}}</small>
            </div>
            <div class="field col-12">
              <label for="contactPhone">{{'SignupPage.SignupPageImportAddInfo.ContactPhoneFieldLabel' | translate }} *</label>
              <input id="contactPhone" formControlName="contactPhone" type="text" required pInputText placeholder="{{'SignupPage.SignupPageImportAddInfo.ContactPhoneFieldPlaceholder' | translate }}"/>
              <small *ngIf="submit && notifyFormGroup.controls['contactPhone'].invalid" class="p-error">{{getErrorMessage('contactPhone')}}</small>
            </div>
          </div>
        </form>
      </p-card>
      <div class="p-fluid formgrid grid" style="width: 800px; margin-top: 30px;">
        <div class="field col-6">&nbsp;</div>
        <div class="field col-6">
          <button pButton pRipple class="justify-content-center w-full">{{'SignupPage.SignupPageImportAddInfo.FinishButton' | translate }}</button>
        </div>
      </div>
    </div>
  </app-content-body>
</app-dashboard-layout>
