import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { ImportRequestService } from 'src/app/application/service/ImportRequestService';
import { ImportRequest, ImportRequestInformation } from 'src/app/domain/models/ImportRequest';
import { BasicUser } from 'src/app/domain/models/User';
import { Routes } from 'src/app/infrastructure/routes';
import { ImportRequestUtil } from 'src/app/ui/util/import-request-util';
import { SpeciesUtils } from 'src/app/ui/util/species-utils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-import-request',
  templateUrl: './user-import-request.component.html',
  styleUrls: ['./user-import-request.component.css'],
  providers: [MessageService]
})
export class UserImportRequestComponent implements OnInit{

  importRequests: ImportRequestInformation[] = []
  loading: boolean = false
  visibleImportRequestDetailDialog: boolean = false
  selected: ImportRequestInformation | undefined

  profile: BasicUser | undefined

  constructor(
    private _importRequestService: ImportRequestService,
    private _router: Router,
    private _messageService: MessageService,
    private _authService: AuthGateway,
    public translate: TranslateService){
  }

  ngOnInit(): void {
    this.getImportRequest();
    this.profile = this._authService.getUser()
  }

  getImportRequest(){
    this.loading = true;
    this.importRequests = [];

    this._importRequestService.getAllByUsername().subscribe({
      next: (v) => {
        this.loading = false;
        console.log(v);
        this.importRequests = v;
      },
      error: (e) => {
        this._messageService.add({
          severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.ImportRequest.ManyRetrieveError')
        })
      },
      complete: () => {
        this.loading = false;
      }
    })
  }

  getIconUrl(url: string){
    return SpeciesUtils.getImageIcon(url);
  }

  goToCreateImportRequest(){
    this._router.navigate(['/' + Routes.userNewImportRequest]);
  }

  goToEditImportRequest(importRequestId: number)
  {
    this._router.navigate(['/' + Routes.userEditImportRequest, importRequestId])
  }

  goToImportRequestOffer(importRequestId: number)
  {
    this._router.navigate(['/' + Routes.userViewImportRequest, importRequestId])
  }

  showImportRequestDetailDialog(importRequestInformation: ImportRequestInformation) {
    this.selected = importRequestInformation
    this.visibleImportRequestDetailDialog = true
  }

  getTotalPalletsImportRequest(importRequest: ImportRequest): number {
    return ImportRequestUtil.getTotalPallets(importRequest)
  }

  getTotalWeeksImportRequest(importRequest: ImportRequest): number {
    return ImportRequestUtil.getTotalWeeks(importRequest)
  }

  getTotalContainersImportRequest(importRequest: ImportRequest): number {
    return ImportRequestUtil.getTotalContainers(importRequest)
  }

  get allCountriesText(): string {
    if(this.profile) {
      switch(this.profile.lang) {
        case 'en':
          return 'All countries'
        case 'es':
          return 'Todos los países'
        default:
          return 'All countries'
      }
    }
    return 'All countries'
  }

  getImageUrl(request: ImportRequestInformation): string {
    return request.importRequest.pictures && request.importRequest.pictures.length > 0 ? environment.apiUrl + request.importRequest.pictures[0].url : ''
  }
}
