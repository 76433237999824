<app-admin-dashboard>
  <p-card>
    <div style="margin-top: 30px;">
      <p-card *ngIf="review">
        <h2>Origin QC Review </h2>
        <div>
          <p-fieldset legend="Import order detail properties" [toggleable]="true" [collapsed]="true">
            <div class="grid">
              <div class="col-3">Import order Id:</div>
              <div class="col-3"><span style="font-weight: bolder;">{{ review.importOrderSequence }}</span></div>
              <div class="col-3">Import order detail Id:</div>
              <div class="col-3"><span style="font-weight: bolder;">{{ review.importOrderDetailSequence }}</span></div>

              <div class="col-3">Species / Variety:</div>
              <div class="col-3">
                <span style="font-weight: bolder;">
                  {{ review | speciesName }} - {{ review.varietyName }}
                </span>
              </div>
              <div class="col-3">Coldstorage:</div>
              <div class="col-3">
                <span style="font-weight: bolder;">
                  {{ review.fridgeStorageName }} {{ review.fridgeStorageCity }}, {{ review.fridgeStorageCountryName }}
                </span>
              </div>

              <div class="col-3">Pallets quantity:</div>
              <div class="col-3">
                <span style="font-weight: bolder;">
                  {{ review.palletsQuantity }}
                </span>
              </div>
              <div class="col-3">Case per pallet:</div>
              <div class="col-3">
                <span style="font-weight: bolder;">
                  {{ review.boxesPerPallet }}
                </span>
              </div>

              <div class="col-3">Departure port:</div>
              <div class="col-3">
                <span style="font-weight: bolder;">
                  {{ review.portFromName }}
                </span>
              </div>
              <div class="col-3">Destination port:</div>
              <div class="col-3">
                <span style="font-weight: bolder;">
                  {{ review.portToName }}
                </span>
              </div>
            </div>
          </p-fieldset>
        </div>
        <div class="grid" style="margin-top: 20px;">
          <div class="col-6" >
            <div>
              <p-card header="Información de Importador">
                <div class="grid">
                  <div class="col-6">Nombre del Importador:</div>
                  <div class="col-6">{{review.importer?.contactName}}</div>
                  <div class="col-6">Nombre de contacto:</div>
                  <div class="col-6">{{review.importer?.contactName}}</div>
                  <div class="col-6">Dirección:</div>
                  <div class="col-6">{{review.importer?.contactAddress}}</div>
                  <div class="col-6">Email:</div>
                  <div class="col-6">{{review.importer?.contactEmail}}</div>
                  <div class="col-6">País:</div>
                  <div class="col-6">{{review.importer?.contactCountry | countryDescription}}</div>
                </div>
              </p-card>
            </div>
          </div>
          <div class="col-6" >
            <div>
              <p-card header="Información de Exportador">
                <div class="grid">
                  <div class="col-6">Nombre del Exportador:</div>
                  <div class="col-6">{{review.exporter?.companyName}}</div>
                  <div class="col-6">Nombre de contacto:</div>
                  <div class="col-6">{{review.exporter?.contactName}}</div>
                  <div class="col-6">Dirección:</div>
                  <div class="col-6">{{review.exporter?.contactAddress}}</div>
                  <div class="col-6">Email:</div>
                  <div class="col-6">{{review.exporter?.contactEmail}}</div>
                  <div class="col-6">País:</div>
                  <div class="col-6">{{review.exporter?.contactCountry | countryDescription}}</div>
                </div>
              </p-card>
            </div>
          </div>
        </div>

        <div *ngIf="!success">
          <p style="margin-top: 40px;">Select the pallets that passed the quality review and fill de QC Form</p>
          <div style="margin-top: 20px;">
            <p-table [value]="pallets"
              [(selection)]="verifiedPallets"
              dataKey="id"
              responsiveLayout="stack"
              [breakpoint]="'600px'"
              styleClass="p-datatable-gridlines"
              [scrollable]="true"
              [resizableColumns]="true"
              columnResizeMode="expand"
              scrollHeight="400px">
              <ng-template pTemplate="header">
                <tr>
                  <th style="width: 4rem">
                    <p-tableHeaderCheckbox *ngIf="review.importOrderDetailStatus == 2"></p-tableHeaderCheckbox>
                  </th>
                  <th>Folio</th>
                  <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.SpeciesHeader' | translate}}</th>
                  <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.VarietyHeader' | translate}}</th>
                  <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.ProducerHeader' | translate}}</th>
                  <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.SpeciesSizeHeader' | translate}}</th>
                  <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.HarvestDateHeader' | translate}}</th>
                  <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.QualityControlDateHeader' | translate}}</th>
                  <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.PackagingHeader' | translate}}</th>
                  <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.PalletTypeHeader' | translate}}</th>
                  <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.TagHeader' | translate}}</th>
                  <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.BagTypeHeader' | translate}}</th>
                  <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.BoxTypeHeader' | translate}}</th>
                  <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.NetWeightHeader' | translate}}</th>
                  <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.ColdStorageHeader' | translate}}</th>
                  <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.BoxePerPalletHeader' | translate}}</th>
                  <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.MarksHeader' | translate}}</th>
                  <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.ContainerNumberHeader' | translate}}</th>
                  <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.GradeHeader' | translate}}</th>
                  <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.TemptaleHeader' | translate}}</th>
                  <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.OriginCountryHeader' | translate}}</th>
                  <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.Cert1Header' | translate}}</th>
                  <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.Cert2Header' | translate}}</th>
                  <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.Cert3Header' | translate}}</th>
                  <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadWeekHeader' | translate}}</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-pallet>
                <tr>
                  <td>
                    <p-tableCheckbox [value]="pallet" *ngIf="pallet.status == 1 && review.importOrderDetailStatus == 2"></p-tableCheckbox>
                    <i class="pi pi-check" style="color: slateblue" *ngIf="pallet.status == 2" pTooltip="Pallet Verified"></i>
                    <i class="pi pi-times" style="color: darkred" *ngIf="pallet.status == 3" pTooltip="Pallet Rejected"></i>
                  </td>
                  <td>{{pallet.palletNumber}}</td>
                  <td>{{pallet.excelImportSpecies}}</td>
                  <td>{{pallet.excelImportVariety}}</td>
                  <td>{{pallet.producer}}</td>
                  <td>{{pallet.speciesSize}}</td>
                  <td>{{pallet.excelImportHarvestDate}}</td>
                  <td>{{pallet.excelImportQualityControlDate}}</td>
                  <td>{{pallet.excelImportPackaging}}</td>
                  <td>{{pallet.excelImportPalletType}}</td>
                  <td>{{pallet.excelImportTag}}</td>
                  <td>{{pallet.excelImportBagType}}</td>
                  <td>{{pallet.excelImportBoxSize}}</td>
                  <td>{{pallet.excelImportNetWeight}}</td>
                  <td>{{pallet.excelImportFridgeStorage}}</td>
                  <td>{{pallet.excelImportBoxesPerPallet}}</td>
                  <td>{{pallet.excelImportMark}}</td>
                  <td>{{pallet.excelImportContainerNumber}}</td>
                  <td>{{pallet.excelImportGrade}}</td>
                  <td>{{pallet.excelImportTemptale}}</td>
                  <td>{{pallet.excelImportOriginCountry}}</td>
                  <td>{{pallet.excelImportCertificate1}}</td>
                  <td>{{pallet.excelImportCertificate2}}</td>
                  <td>{{pallet.excelImportCertificate3}}</td>
                  <td>{{pallet.excelImportLoadWeek}}</td>
                </tr>
              </ng-template>
            </p-table>
          </div>

          <div style="margin-top: 40px;">
            <form [formGroup]="formGroup">
              <div class="p-fluid formgrid grid">
                <div class="field col-12 md:col-6 lg:col-3" style="margin-bottom: 40px;">
                  <label for="qualityControlDate" style="font-weight: bolder;">{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.QualityControlDateLabel' | translate}} *</label>
                  <p-calendar
                    inputId="qualityControlDate"
                    formControlName="qualityControlDate"
                    appendTo="body"
                    [showButtonBar]="true"
                    [showIcon]="true"
                    placeholder="{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.QualityControlDateLabel' | translate}}"
                    [showWeek]="true"
                    [firstDayOfWeek]="1"></p-calendar>
                  <small *ngIf="submit && formGroup.controls['qualityControlDate'].invalid" class="p-error">{{getErrorMessage(formGroup, 'qualityControlDate')}}</small>
                </div>
                <div class="field col-12 md:col-6 lg:col-3" style="margin-bottom: 40px;">
                  <label for="originQCReviewPrice" style="font-weight: bolder;">Origin QC Review Price</label>
                  <p-inputNumber inputId="originQCReviewPrice" formControlName="originQCReviewPrice" mode="currency" currency="USD" locale="en-US" [min]="0" placeholder="Origin QC Review Price"></p-inputNumber>
                  <small *ngIf="submit && formGroup.controls['originQCReviewPrice'].invalid" class="p-error">{{getErrorMessage(formGroup, 'originQCReviewPrice')}}</small>
                </div>
                <div class="field col-12" style="margin-bottom: 40px;">
                  <label for="qcObservations" style="font-weight: bolder;">Observations</label>
                  <textarea formControlName="qcObservations" rows="5" cols="30" pInputTextarea [maxlength]="1000" [autoResize]="true"></textarea>
                </div>
              </div>
              <div class="grid" style="margin-top: 30px;">
                <div class="col-12 md:col-6 lg:col-6">
                </div>
                <div class="col-12 md:col-6 lg:col-6">
                  <div class="grid p-fluid">
                    <div class="col-12 md:col-6 lg:col-6">
                      <p-button label="Back" (onClick)="goToReviews()" styleClass="p-button-outlined"></p-button>
                    </div>
                    <div class="col-12 md:col-6 lg:col-6">
                      <p-button label="Save" (onClick)="saveConfirmPallets()" [loading]="loading"></p-button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div *ngIf="success" style="margin-top: 50px;">
          <div class="flex align-items-center justify-content-center flex-column">
            <i class="pi pi-check-circle" style="font-size: 2.5rem"></i>
            <h1>QC Review successfully updated</h1>
            <p>Would you like to upload the review documents?</p>
            <div class="flex align-items-center justify-content-center gap-4">
              <p-button label="Maybe later" styleClass="p-button-outlined" (onClick)="goToReviews()"></p-button>
              <p-button label="Go to load documents" (onClick)="goToReviewDocuments()"></p-button>
            </div>
          </div>
        </div>
      </p-card>
    </div>
  </p-card>
</app-admin-dashboard>

<p-toast></p-toast>
