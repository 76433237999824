<div id="OperationInfo" style="margin-top: 50px;" *ngIf="order">
  <p-card header="{{ 'DocumentComponent.Title' | translate }}">
    <div>
      <p-table
        [value]="order.operationDocuments"
        responsiveLayout="stack"
        [breakpoint]="'600px'">
        <ng-template pTemplate="caption">
          <h3>{{ 'DocumentComponent.OperationDocumentsTitle' | translate }}</h3>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th><div class="flex justify-content-center">{{ 'DocumentComponent.NameHeader' | translate }}</div></th>
            <th><div class="flex justify-content-center">{{ 'DocumentComponent.CreatedHeader' | translate }}</div></th>
            <th><div class="flex justify-content-center">{{ 'DocumentComponent.SizeHeader' | translate }}</div></th>
            <th><div class="flex justify-content-center">{{ 'DocumentComponent.ViewHeader' | translate }}</div></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-document>
          <tr>
            <td><div class="flex justify-content-center">{{ document.name }}</div></td>
            <td><div class="flex justify-content-center">{{ document.created | date:'dd-MM-yyyy'}}</div></td>
            <td><div class="flex justify-content-center">{{ document.size / 1000 }} KB</div></td>
            <td>
              <div class="flex justify-content-center">
                <p-button *ngIf="document.documentType === 5" (onClick)="getOperationDocument(document.id)" pTooltip="View PDF" icon="pi pi-file-pdf" styleClass="p-button-link p-button-lg" [disabled]="document.size < 1"></p-button>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="summary">
          <div class="flex align-items-center justify-content-between" *ngIf="order.operationDocuments.length < 1">
            {{ 'DocumentComponent.EmptyResult' | translate }}
          </div>
        </ng-template>
      </p-table>
    </div>
    <div style="margin-top: 40px;">
      <p-table
        [value]="order.exportOfferDocuments"
        responsiveLayout="stack"
        [breakpoint]="'600px'">
        <ng-template pTemplate="caption">
          <h3>{{ 'DocumentComponent.ExportOfferDocumentsTitle' | translate }}</h3>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th><div class="flex justify-content-center">{{ 'DocumentComponent.NameHeader' | translate }}</div></th>
            <th><div class="flex justify-content-center">{{ 'DocumentComponent.CreatedHeader' | translate }}</div></th>
            <th><div class="flex justify-content-center">{{ 'DocumentComponent.SizeHeader' | translate }}</div></th>
            <th><div class="flex justify-content-center">{{ 'DocumentComponent.ViewHeader' | translate }}</div></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-document>
          <tr>
            <td><div class="flex justify-content-center">{{ document.name }}</div></td>
            <td><div class="flex justify-content-center">{{ document.created | date:'dd-MM-yyyy'}}</div></td>
            <td><div class="flex justify-content-center">{{ document.size / 1000 }} KB</div></td>
            <td>
              <div class="flex justify-content-center">
                <p-button *ngIf="document.documentType === 7" (onClick)="getExportOfferDocument(document.id, order.importOrder.guid)" pTooltip="View PDF" icon="pi pi-file-pdf" styleClass="p-button-link p-button-lg" [disabled]="document.size < 1"></p-button>
                <p-button *ngIf="document.documentType === 8" (onClick)="getFrozenFruitPesticideAnalysisDocument(document.id)" pTooltip="View PDF" icon="pi pi-file-pdf" styleClass="p-button-link p-button-lg" [disabled]="document.size < 1"></p-button>
                <p-button *ngIf="document.documentType === 9" (onClick)="getFrozenFruitHeavyMetalsAnalysisDocument(document.id)" pTooltip="View PDF" icon="pi pi-file-pdf" styleClass="p-button-link p-button-lg" [disabled]="document.size < 1"></p-button>
                <p-button *ngIf="document.documentType === 10" (onClick)="getFrozenFruitMicrobiologicalAnalysisDocument(document.id)" pTooltip="View PDF" icon="pi pi-file-pdf" styleClass="p-button-link p-button-lg" [disabled]="document.size < 1"></p-button>
                <p-button *ngIf="document.documentType === 11" (onClick)="getFrozenFruitWaterAnalysisDocument(document.id)" pTooltip="View PDF" icon="pi pi-file-pdf" styleClass="p-button-link p-button-lg" [disabled]="document.size < 1"></p-button>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="summary">
          <div class="flex align-items-center justify-content-between" *ngIf="order.exportOfferDocuments.length < 1">
            {{ 'DocumentComponent.EmptyResult' | translate }}
          </div>
        </ng-template>
      </p-table>
    </div>
    <div style="margin-top: 40px;">
      <p-table
        [value]="order.productCertificateDocuments"
        responsiveLayout="stack"
        [breakpoint]="'600px'">
        <ng-template pTemplate="caption">
          <h3>{{ 'DocumentComponent.ProductCertificateDocumentsTitle' | translate }}</h3>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th><div class="flex justify-content-center">{{ 'DocumentComponent.NameHeader' | translate }}</div></th>
            <th><div class="flex justify-content-center">{{ 'DocumentComponent.CreatedHeader' | translate }}</div></th>
            <th><div class="flex justify-content-center">{{ 'DocumentComponent.SizeHeader' | translate }}</div></th>
            <th><div class="flex justify-content-center">{{ 'DocumentComponent.ViewHeader' | translate }}</div></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-document>
          <tr>
            <td><div class="flex justify-content-center">{{ document.name }}</div></td>
            <td><div class="flex justify-content-center">{{ document.created | date:'dd-MM-yyyy'}}</div></td>
            <td><div class="flex justify-content-center">{{ document.size / 1000 }} KB</div></td>
            <td>
              <div class="flex justify-content-center">
                <p-button *ngIf="document.documentType === 6" (onClick)="getProductCertificateDocument(document.id, order.importOrder.guid)" pTooltip="View PDF" icon="pi pi-file-pdf" styleClass="p-button-link p-button-lg" [disabled]="document.size < 1"></p-button>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="summary">
          <div class="flex align-items-center justify-content-between" *ngIf="order.productCertificateDocuments.length < 1">
            {{ 'DocumentComponent.EmptyResult' | translate }}
          </div>
        </ng-template>
      </p-table>
    </div>
    <div style="margin-top: 40px;">
      <p-table
        [value]="order.qualityControlDocuments"
        responsiveLayout="stack"
        [breakpoint]="'600px'">
        <ng-template pTemplate="caption">
          <h3>{{ 'DocumentComponent.QCDocumentsTitle' | translate }}</h3>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th><div class="flex justify-content-center">{{ 'DocumentComponent.NameHeader' | translate }}</div></th>
            <th><div class="flex justify-content-center">{{ 'DocumentComponent.CreatedHeader' | translate }}</div></th>
            <th><div class="flex justify-content-center">{{ 'DocumentComponent.SizeHeader' | translate }}</div></th>
            <th><div class="flex justify-content-center">{{ 'DocumentComponent.ViewHeader' | translate }}</div></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-document>
          <tr>
            <td><div class="flex justify-content-center">{{ document.name }}</div></td>
            <td><div class="flex justify-content-center">{{ document.created | date:'dd-MM-yyyy'}}</div></td>
            <td><div class="flex justify-content-center">{{ document.size / 1000 }} KB</div></td>
            <td>
              <div class="flex justify-content-center">
                <p-button *ngIf="document.documentType === 1" (onClick)="viewOriginQCReviewDocument(document.id)" pTooltip="View PDF" icon="pi pi-file-pdf" styleClass="p-button-link p-button-lg" [disabled]="document.size < 1"></p-button>
                <p-button *ngIf="document.documentType === 2" (onClick)="viewOriginQCConsolidationDocument(document.id)" pTooltip="View PDF" icon="pi pi-file-pdf" styleClass="p-button-link p-button-lg" [disabled]="document.size < 1"></p-button>
                <p-button *ngIf="document.documentType === 3" (onClick)="getFrozenFruitQCReviewDocument(document.id)" pTooltip="View PDF" icon="pi pi-file-pdf" styleClass="p-button-link p-button-lg" [disabled]="document.size < 1"></p-button>
                <p-button *ngIf="document.documentType === 4" (onClick)="getFrozenFruitQCConsolidationDocument(document.id)" pTooltip="View PDF" icon="pi pi-file-pdf" styleClass="p-button-link p-button-lg" [disabled]="document.size < 1"></p-button>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="summary">
          <div class="flex align-items-center justify-content-between" *ngIf="order.qualityControlDocuments.length < 1">
            {{ 'DocumentComponent.EmptyResult' | translate }}
          </div>
        </ng-template>
      </p-table>
    </div>
    <!--p-table
      [value]="order.documents"
      responsiveLayout="stack"
      [breakpoint]="'600px'">
      <ng-template pTemplate="header">
        <tr>
          <th><div class="flex justify-content-center">{{ 'DocumentComponent.DocumentTypeHeader' | translate }}</div></th>
          <th><div class="flex justify-content-center">{{ 'DocumentComponent.NameHeader' | translate }}</div></th>
          <th><div class="flex justify-content-center">{{ 'DocumentComponent.CreatedHeader' | translate }}</div></th>
          <th><div class="flex justify-content-center">{{ 'DocumentComponent.SizeHeader' | translate }}</div></th>
          <th><div class="flex justify-content-center">{{ 'DocumentComponent.ViewHeader' | translate }}</div></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-document>
        <tr>
          <td><div class="flex justify-content-center">{{ getDocumentType(document.documentType) }}</div></td>
          <td><div class="flex justify-content-center">{{ document.name }}</div></td>
          <td><div class="flex justify-content-center">{{ document.created | date:'dd-MM-yyyy'}}</div></td>
          <td><div class="flex justify-content-center">{{ document.size / 1000 }} KB</div></td>
          <td>
            <div class="flex justify-content-center">
              <p-button *ngIf="document.documentType === 1" (onClick)="getQCReviewDocument(document.id)" pTooltip="View PDF" icon="pi pi-file-pdf" styleClass="p-button-link p-button-lg" [disabled]="document.size < 1"></p-button>
              <p-button *ngIf="document.documentType === 2" (onClick)="getQCConsolidationDocument(document.id)" pTooltip="View PDF" icon="pi pi-file-pdf" styleClass="p-button-link p-button-lg" [disabled]="document.size < 1"></p-button>
              <p-button *ngIf="document.documentType === 3" (onClick)="getFrozenFruitQCReviewDocument(document.id)" pTooltip="View PDF" icon="pi pi-file-pdf" styleClass="p-button-link p-button-lg" [disabled]="document.size < 1"></p-button>
              <p-button *ngIf="document.documentType === 4" (onClick)="getFrozenFruitQCConsolidationDocument(document.id)" pTooltip="View PDF" icon="pi pi-file-pdf" styleClass="p-button-link p-button-lg" [disabled]="document.size < 1"></p-button>
              <p-button *ngIf="document.documentType === 5" (onClick)="getOperationDocument(document.id)" pTooltip="View PDF" icon="pi pi-file-pdf" styleClass="p-button-link p-button-lg" [disabled]="document.size < 1"></p-button>
              <p-button *ngIf="document.documentType === 6" (onClick)="getProductCertificateDocument(document.id, order.importOrder.guid)" pTooltip="View PDF" icon="pi pi-file-pdf" styleClass="p-button-link p-button-lg" [disabled]="document.size < 1"></p-button>
              <p-button *ngIf="document.documentType === 7" (onClick)="getExportOfferDocument(document.id, order.importOrder.guid)" pTooltip="View PDF" icon="pi pi-file-pdf" styleClass="p-button-link p-button-lg" [disabled]="document.size < 1"></p-button>
              <p-button *ngIf="document.documentType === 8" (onClick)="getFrozenFruitPesticideAnalysisDocument(document.id)" pTooltip="View PDF" icon="pi pi-file-pdf" styleClass="p-button-link p-button-lg" [disabled]="document.size < 1"></p-button>
              <p-button *ngIf="document.documentType === 9" (onClick)="getFrozenFruitHeavyMetalsAnalysisDocument(document.id)" pTooltip="View PDF" icon="pi pi-file-pdf" styleClass="p-button-link p-button-lg" [disabled]="document.size < 1"></p-button>
              <p-button *ngIf="document.documentType === 10" (onClick)="getFrozenFruitMicrobiologicalAnalysisDocument(document.id)" pTooltip="View PDF" icon="pi pi-file-pdf" styleClass="p-button-link p-button-lg" [disabled]="document.size < 1"></p-button>
              <p-button *ngIf="document.documentType === 11" (onClick)="getFrozenFruitWaterAnalysisDocument(document.id)" pTooltip="View PDF" icon="pi pi-file-pdf" styleClass="p-button-link p-button-lg" [disabled]="document.size < 1"></p-button>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="summary">
        <div class="flex align-items-center justify-content-between" *ngIf="order.documents.length < 1">
          {{ 'DocumentComponent.EmptyResult' | translate }}
        </div>
      </ng-template>
    </p-table-->
  </p-card>
</div>
