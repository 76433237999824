<app-user-dashboard [userMenuActiveItemIndex]="0" [activeItem]="'corporative'">
  <p-card [style]="{'min-height':'200px'}">
    <ng-template pTemplate="header">
      <div class="flex justify-content-start align-items-start flex-column" style="padding: 15px;">
        <h2>{{title}}</h2>
        <p>{{'UserPages.UserFridgestorages.FridgestorageForm.RequiredFieldText' | translate}}</p>
      </div>
    </ng-template>
    <form [formGroup]="formGroup">
      <div class="p-fluid formgrid grid" style="padding: 20px;">
        <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
          <label for="name">{{'UserPages.UserFridgestorages.FridgestorageForm.NameFieldLabel' | translate}} *</label>
          <input maxlength="{{formLimit.nameLimit}}" formControlName="name" type="text" pInputText required autofocus placeholder="{{'UserPages.UserFridgestorages.FridgestorageForm.NameFieldPlaceholder' | translate}}"/>
          <small *ngIf="submit && formGroup.controls['name'].invalid" class="p-error">{{getErrorMessage('name')}}</small>
        </div>
        <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
          <label for="storageType">{{'UserPages.UserFridgestorages.FridgestorageForm.StorageTypeLabel' | translate}} *</label>
          <p-dropdown
            id="storageType"
            formControlName="storageType"
            [options]="storageTypes"
            optionLabel="label"
            optionValue="value"
            [showClear]="true"
            placeholder="{{'UserPages.UserFridgestorages.FridgestorageForm.StorageTypeLabel' | translate}}"></p-dropdown>
          <small *ngIf="submit && formGroup.controls['storageType'].invalid" class="p-error">{{getErrorMessage('storageType')}}</small>
        </div>
        <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
          <label for="vat">{{'UserPages.UserFridgestorages.FridgestorageForm.VatFieldLabel' | translate}}</label>
          <input id="vat" pInputText formControlName="vat" placeholder="{{'UserPages.UserFridgestorages.FridgestorageForm.VatFieldPlaceholder' | translate}}"/>
        </div>
        <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
          <label for="contactName">{{'UserPages.UserFridgestorages.FridgestorageForm.ContactNameFieldLabel' | translate}} *</label>
          <input  maxlength="{{formLimit.nameLimit}}" id="contactName" formControlName="contactName" type="text" pInputText required placeholder="{{'UserPages.UserFridgestorages.FridgestorageForm.ContactNameFieldPlaceholder' | translate}}"/>
          <small *ngIf="submit && formGroup.controls['contactName'].invalid" class="p-error">{{getErrorMessage('contactName')}}</small>
        </div>
        <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
          <label for="contactAddress">{{'UserPages.UserFridgestorages.FridgestorageForm.PlantAddressFieldLabel' | translate}} *</label>
          <input id="contactAddress" formControlName="contactAddress" type="text" pInputText required placeholder="{{'UserPages.UserFridgestorages.FridgestorageForm.PlantAddressFieldPlaceholder' | translate}}"/>
          <small *ngIf="submit && formGroup.controls['contactAddress'].invalid" class="p-error">{{getErrorMessage('contactAddress')}}</small>
        </div>
        <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
          <label for="city">{{'UserPages.UserFridgestorages.FridgestorageForm.CityFieldLabel' | translate}} *</label>
          <input id="city"  maxlength="{{formLimit.cityLimit}}" formControlName="contactCity" type="text" pInputText required placeholder="{{'UserPages.UserFridgestorages.FridgestorageForm.CityFieldPlaceholder' | translate}}"/>
          <small *ngIf="submit && formGroup.controls['contactCity'].invalid" class="p-error">{{getErrorMessage('contactCity')}}</small>
        </div>
        <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
          <label for="contactEmail">{{'UserPages.UserFridgestorages.FridgestorageForm.ContactEmailFieldLabel' | translate}} *</label>
          <input id="contactEmail" maxlength="{{formLimit.mailLimit}}" formControlName="contactEmail" type="text" pInputText required placeholder="{{'UserPages.UserFridgestorages.FridgestorageForm.ContactEmailFieldPlaceholder' | translate}}"/>
          <small *ngIf="submit && formGroup.controls['contactEmail'].invalid" class="p-error">{{getErrorMessage('contactEmail')}}</small>
        </div>
        <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
          <label for="contactPhone">{{'UserPages.UserFridgestorages.FridgestorageForm.ContactPhoneFieldLabel' | translate}} *</label>
          <input id="contactPhone" maxlength="{{formLimit.phoneLimit}}" formControlName="contactPhone" type="text" pInputText placeholder="{{'UserPages.UserFridgestorages.FridgestorageForm.ContactPhoneFieldPlaceholder' | translate}}"/>
          <small *ngIf="submit && formGroup.controls['contactPhone'].invalid" class="p-error">{{getErrorMessage('contactPhone')}}</small>
        </div>
        <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
          <label for="fridgestorageCountry">{{'UserPages.UserFridgestorages.FridgestorageForm.CountryFieldLabel' | translate}} *</label>
          <p-dropdown
            id="fridgestorageCountry"
            [options]="countries"
            formControlName="contactCountry"
            optionLabel="name"
            [filter]="true"
            filterBy="name"
            [showClear]="true"
            placeholder="{{'UserPages.UserFridgestorages.FridgestorageForm.CountryFieldPlaceholder' | translate}}"(onChange)="onChangeCountry()">
              <ng-template pTemplate="selectedItem">
                <div class="flex align-items-center gap-2" *ngIf="selectedCountry">
                  <img [src]="'../../../../assets/img/flags/' + selectedCountry.code + '.png'" style="width: 18px" alt=""/>
                  <span>{{ selectedCountry | countryDescription }}</span>
                </div>
              </ng-template>
              <ng-template let-country pTemplate="item">
                <div class="flex align-items-center gap-2">
                  <img [src]="'../../../../assets/img/flags/' + country.code + '.png'" style="width: 18px" alt=""/>
                  <div>{{ country | countryDescription }}</div>
                </div>
              </ng-template>
          </p-dropdown>
          <small *ngIf="submit && formGroup.controls['contactCountry'].invalid" class="p-error">{{getErrorMessage('contactCountry')}}</small>
        </div>
        <div class="col-12">
          <div class="grid" style="margin-top: 30px;">
            <div class="col-12 md:col-6 lg:col-6"></div>
            <div class="col-12 md:col-6 lg:col-6">
              <div class="grid p-fluid">
                <div class="col-6">
                  <p-button label="{{'UserPages.UserFridgestorages.FridgestorageForm.BackButton' | translate}}" [disabled]="loading" styleClass="p-button-outlined" (click)="cancel()"/>
                </div>
                <div class="col-6">
                  <p-button label="{{'UserPages.UserFridgestorages.FridgestorageForm.SaveButton' | translate}}" [loading]="loading" (click)="save()"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </p-card>
</app-user-dashboard>

<p-toast></p-toast>
