import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { SecurityGateway } from 'src/app/application/gateway/SecurityGateway';
import { BasicUser } from 'src/app/domain/models/User';
import { Routes } from 'src/app/infrastructure/routes';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css'],
  providers: [MessageService]
})
export class SideMenuComponent implements OnInit {

  profile: BasicUser | undefined

  sessionItems: MenuItem[] = []
  userItems: MenuItem[] = []
  profileItems: MenuItem[] = []
  userMenuItems: MenuItem[] = []
  notificationsMenuItems: MenuItem[] = []
  helpMenuItems: MenuItem[] = []

  constructor(private _router: Router,
    private _messageService: MessageService,
    private _authService: AuthGateway,
    private _securityService: SecurityGateway,
    public translate: TranslateService) {
  }

  ngOnInit(): void {
    this.profile = this._authService.getUser()

    this.translate.get([
      'UserMenuComponent.UserMenuItems.HomeLabel',
      'UserMenuComponent.UserMenuItems.MarketplaceLabel',
      'UserMenuComponent.UserMenuItems.LogoutLabel',
      'UserMenuComponent.UserMenuItems.UserContactInfoLabel',
      'UserMenuComponent.UserMenuItems.UserCompanyInfoLabel',
      'UserMenuComponent.UserMenuItems.ConsigneeLabel',
      'UserMenuComponent.UserMenuItems.NotifyLabel',
      'UserMenuComponent.UserMenuItems.SpeciesLabel',
      'UserMenuComponent.UserMenuItems.SecurityOptionsLabel',
      'UserMenuComponent.NotificationsMenuItems.NotificationsLabel',
      'UserMenuComponent.NotificationsMenuItems.NotificationConfigLabel',
      'UserMenuComponent.HelpMenuItems.CustomerServiceLabel',
      'UserMenuComponent.HelpMenuItems.CustomerHelpLabel',
      'UserDashboardComponent.UserDashboardMenu.LoadOrderTitle',
      'UserDashboardComponent.UserDashboardMenu.ImportOrderTitle',
      'UserDashboardComponent.UserDashboardMenu.CurrentImportOrdersLabel',
      'UserDashboardComponent.UserDashboardMenu.PossiblePurchaseImportOrdersLabel',
      'UserDashboardComponent.UserDashboardMenu.ClosedSaleLabel',
      'UserDashboardComponent.UserDashboardMenu.ClosedSaleProgramationLabel',
      'UserDashboardComponent.UserDashboardMenu.ExportOfferTitle',
      'UserDashboardComponent.UserDashboardMenu.MarketplaceExportOfferLabel',
      'UserDashboardComponent.UserDashboardMenu.ClosedSaleExportOfferLabel',
      'UserDashboardComponent.UserDashboardMenu.FrozenFruitExportOfferLabel',
      'UserDashboardComponent.UserDashboardMenu.ExportOfferSuggestionTitle',
      'UserDashboardComponent.UserDashboardMenu.ExportOfferSuggestionLabel',
      'UserDashboardComponent.UserDashboardMenu.ImportRequestTitle',
      'UserDashboardComponent.UserDashboardMenu.ImportRequestLabel',
      'UserDashboardComponent.UserDashboardMenu.NegotiationTitle',
      'UserDashboardComponent.UserDashboardMenu.NegotiationLabel',
      'UserDashboardComponent.UserDashboardMenu.FridgestorageTitle',
      'UserDashboardComponent.UserDashboardMenu.FridgestorageLabel',
      'UserDashboardComponent.UserDashboardMenu.ProductCertificateTitle',
      'UserDashboardComponent.UserDashboardMenu.ProductCertificateLabel',
      'UserDashboardComponent.UserDashboardMenu.QCReviewsTitle',
      'UserDashboardComponent.UserDashboardMenu.OriginQCReviewsLabel',
      'UserDashboardComponent.UserDashboardMenu.OriginQCReviewedsLabel',
      'UserDashboardComponent.UserDashboardMenu.DestinationQCReviewsLabel',
      'UserDashboardComponent.UserDashboardMenu.DestinationQCReviewedsLabel',
      'UserDashboardComponent.UserDashboardMenu.ForwarderReviewsTitle',
      'UserDashboardComponent.UserDashboardMenu.OriginForwarderReviewsLabel',
      'UserDashboardComponent.UserDashboardMenu.OriginForwarderReviewedsLabel',
      'UserDashboardComponent.UserDashboardMenu.CompanyInfoLabel',
      'UserPages.UserLoadOrders.PageTitle',
      'UserDashboardComponent.UserDashboardMenu.CustomAgentReviewsTitle',
      'UserDashboardComponent.UserDashboardMenu.OriginCustomAgentReviewsLabel',
      'UserDashboardComponent.UserDashboardMenu.OriginCustomAgentReviewedsLabel',
      'UserDashboardComponent.UserDashboardMenu.ForwarderOrdersLabel',
    ]).subscribe({
      next: (tranlations) => {
        if(this.profile) {

          this.sessionItems = [
            {
              label: tranlations['UserMenuComponent.UserMenuItems.HomeLabel'],
              icon: 'pi pi-pw pi-home',
              routerLink: [Routes.homePage]
            },
            {
              label: tranlations['UserMenuComponent.UserMenuItems.MarketplaceLabel'],
              icon: 'pi pi-pw pi-shopping-cart',
              routerLink: ['/' + Routes.marketplacePage]
            },
            {
              label: tranlations['UserMenuComponent.NotificationsMenuItems.NotificationsLabel'],
              icon: 'pi pi-fw pi-bell',
              routerLink: '/' + [Routes.userNotifications]
            },
            {
              label: tranlations['UserMenuComponent.HelpMenuItems.CustomerServiceLabel'],
              icon: 'pi pi-pw pi-envelope',
              routerLink: ['/' + Routes.userSupport]
            },
            {
              label: tranlations["UserMenuComponent.UserMenuItems.LogoutLabel"],
              icon: 'pi pi-fw pi-sign-out',
              command: () => { this.logout() }
            }
          ]

          this.profileItems = [
            {
              label: tranlations['UserPages.UserLoadOrders.PageTitle'],
              icon: 'pi pi-fw pi-chart-pie',
              visible: this.profile.type === 1 || this.profile.type === 2,
              routerLink: ['/' + Routes.userLoadOrders]
            },
            {
              label: tranlations['UserDashboardComponent.UserDashboardMenu.CurrentImportOrdersLabel'],
              icon: 'pi pi-fw pi-shopping-bag',
              visible: this.profile.type === 1 || this.profile.type === 2,
              routerLink: ['/' + Routes.userImportOrdersCurrent]
            },
            {
              label: tranlations['UserDashboardComponent.UserDashboardMenu.ClosedSaleProgramationLabel'],
              icon: 'pi pi-fw pi-shopping-bag',
              visible: this.profile.type === 2,
              routerLink: ['/' + Routes.importerClosedSaleOrdersPage]
            },
            {
              label: tranlations['UserDashboardComponent.UserDashboardMenu.MarketplaceExportOfferLabel'],
              icon: 'pi pi-fw pi-shopping-bag',
              visible: this.profile.type === 1,
              routerLink: ['/' + Routes.userExportOffers],
            },
            {
              label: tranlations['UserDashboardComponent.UserDashboardMenu.ClosedSaleExportOfferLabel'],
              icon: 'pi pi-fw pi-shopping-bag',
              visible: this.profile.type === 1,
              routerLink: ['/' + Routes.userClosedSales],
            },
            /**
            {
              label: tranlations['UserDashboardComponent.UserDashboardMenu.FrozenFruitExportOfferLabel'],
              icon: 'pi pi-fw pi-shopping-bag',
              visible: this.profile.type === 1,
              routerLink: ['/' + Routes.userFrozenFruitExportOffersActivePage],
            },*/
            {
              label: tranlations['UserDashboardComponent.UserDashboardMenu.ExportOfferSuggestionLabel'],
              icon: 'pi pi-fw pi-check-circle',
              visible: this.profile.type === 2,
              routerLink: ['/' + Routes.userExportOfferSuggestionPage],
            },
            {
              label: tranlations['UserDashboardComponent.UserDashboardMenu.ImportRequestLabel'],
              icon: 'pi pi-fw pi-book',
              visible: this.profile.type === 2,
              routerLink: ['/' + Routes.userImportRequest]
            },
            {
              label: tranlations['UserDashboardComponent.UserDashboardMenu.NegotiationLabel'],
              icon: 'pi pi-fw pi-arrow-right-arrow-left',
              visible: this.profile.type === 1 || this.profile.type === 2,
              routerLink: ['/' + Routes.userBargaining]
            },
            {
              label: tranlations['UserDashboardComponent.UserDashboardMenu.FridgestorageLabel'],
              icon: 'pi pi-fw pi-truck',
              visible: this.profile.type === 1,
              routerLink: ['/' + Routes.userFridgestorages]
            },
            {
              label: tranlations['UserDashboardComponent.UserDashboardMenu.ProductCertificateLabel'],
              icon: 'pi pi-fw pi-verified',
              visible: this.profile.type === 1,
              routerLink: ['/' + Routes.userProductCertificates],
            },
            {
              label: tranlations['UserDashboardComponent.UserDashboardMenu.OriginQCReviewsLabel'],
              icon: 'pi pi-fw pi-search',
              visible: this.profile.type === 4,
              routerLink: ['/' + Routes.qcUserReviews]
            },
            {
              label: tranlations['UserDashboardComponent.UserDashboardMenu.OriginQCReviewedsLabel'],
              icon: 'pi pi-fw pi-search',
              visible: this.profile.type === 4,
              routerLink: ['/' + Routes.qcUserOriginReviewed]
            },
            
            {
              label: tranlations['UserDashboardComponent.UserDashboardMenu.OriginForwarderReviewsLabel'],
              icon: 'pi pi-fw pi-search',
              visible: this.profile.type === 5,
              routerLink: ['/' + Routes.forwarderUserReviews]
            },
            {
              label: tranlations['UserDashboardComponent.UserDashboardMenu.OriginForwarderReviewedsLabel'],
              icon: 'pi pi-fw pi-search',
              visible: this.profile.type === 5,
              routerLink: ['/' + Routes.forwarderUserOriginReviewed]
            },
            {
              label: tranlations['UserDashboardComponent.UserDashboardMenu.CompanyInfoLabel'],
              icon: 'pi pi-fw pi-search',
              visible: this.profile.type === 5 && this.profile.forwardAdmin,
              routerLink: ['/' + Routes.forwarderUsers]
            },
            {
              label: tranlations['UserDashboardComponent.UserDashboardMenu.ForwarderOrdersLabel'],
              icon: 'pi pi-fw pi-search',
              visible: this.profile.type === 5,
              routerLink: ['/' + Routes.forwarderImportOrders]
            },

            {
              label: tranlations['UserDashboardComponent.UserDashboardMenu.OriginCustomAgentReviewsLabel'],
              icon: 'pi pi-fw pi-search',
              visible: this.profile.type === 6,
              routerLink: ['/' + Routes.customAgentUserReviews]
            },
            {
              label: tranlations['UserDashboardComponent.UserDashboardMenu.OriginCustomAgentReviewedsLabel'],
              icon: 'pi pi-fw pi-search',
              visible: this.profile.type === 6,
              routerLink: ['/' + Routes.customAgentUserOriginReviewed]
            },
            {
              label: tranlations['UserDashboardComponent.UserDashboardMenu.CompanyInfoLabel'],
              icon: 'pi pi-fw pi-search',
              visible: this.profile.type === 6 && this.profile.customAgentAdmin,
              routerLink: ['/' + Routes.customAgentUsers]
            },
          ]

          this.userItems = [
            {
              label: tranlations['UserMenuComponent.UserMenuItems.UserContactInfoLabel'],
              routerLink: '/' + [Routes.userContactInfo],
              icon: 'pi pi-user-edit'
            },
            {
              label: tranlations['UserMenuComponent.UserMenuItems.UserCompanyInfoLabel'],
              routerLink: ['/' + Routes.userCompanyInfo],
              icon: 'pi pi-id-card'
            },
            {
              label: tranlations['UserMenuComponent.UserMenuItems.ConsigneeLabel'],
              routerLink: ['/' + Routes.userConsignees],
              icon: 'pi pi-users',
              visible: this.profile.type === 2
            },
            {
              label: tranlations['UserMenuComponent.UserMenuItems.NotifyLabel'],
              routerLink: ['/' + Routes.userNotifiers],
              icon: 'pi pi-users',
              visible: this.profile.type === 2
            },
            {
              label: tranlations['UserMenuComponent.UserMenuItems.SpeciesLabel'],
              routerLink: ['/' + Routes.userProducts],
              icon: 'pi pi-apple',
              visible: this.profile.type === 1 || this.profile.type === 2
            },
            {
              label: tranlations['UserMenuComponent.UserMenuItems.SecurityOptionsLabel'],
              icon: 'pi pi-lock',
              routerLink: ['/' + Routes.userChangePassword]
            }
          ]
        }
      },
      error: (e) => {
        console.log(e)
      }
    })
  }



  logout(): void {
    this._securityService.doLogout().subscribe({
      next: (v) => {
        this._authService.logout()
        this._router.navigate([Routes.login])
      },
      error: (e) => {
        console.log(e)
        this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudo cerrar sesión correctamente.' });
      },
      complete: () => {
        console.info('Logout successfully')
      }
    })
  }
}
