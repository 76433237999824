import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { NotificationGateway } from 'src/app/application/gateway/NotificationGateway';
import { AppNotification } from 'src/app/domain/models/AppNotification';
import { Routes } from 'src/app/infrastructure/routes';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.css'],
  providers: [MessageService]
})
export class NotificationListComponent implements OnInit {
  @Input()
  isVisible: boolean = false;
  @Output() 
  onDialogClose: EventEmitter<any> = new EventEmitter();

  notifications: AppNotification[] = [];

  constructor(
    private _notificationService: NotificationGateway,
    private _router: Router,
    private _messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.getAllPending();
  }

  afterHide() {
    this.onDialogClose.emit(false);
  }

  getAllPending() {
    this._notificationService.getAllByProfile().subscribe({
      next: (data) => {
        this.notifications = data;
      },
      error: (e) => {
      },
      complete: () => {
      }
    })
  }

  goToNotificationDetail(notification: AppNotification) {
    if (notification.id) {
      this._notificationService.setReaded(notification.id).subscribe({
        next: (v) => {
          window.location.href = notification?.redirectUrl ? notification?.redirectUrl : '/'
        },
        error: (e) => {
          this._messageService.add({ severity: 'error',
            summary: 'Acción fallida',
            detail: 'No se pudo actualizar la notificación.' });
        }
      })
    }
  }

  goToConfiguration() {
    this._router.navigate(['/' + Routes.userNotificationsConfig])
  }

  goToAllNotifications() {
    this._router.navigate(['/' + Routes.userNotifications])
  }
}
