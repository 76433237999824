<app-dashboard-layout>
  <app-content-body>
    <p-card>
      <div class="grid align-items-center">
        <div class="col-12 md:col-4">
        </div>
        <div class="col-12 md:col-4 flex justify-content-center align-items-center">
            <img src="../../../../assets/img/fruzty-logo-slogan.png" width="250" height="111" alt=""/>
        </div>
        <div class="col-12 md:col-4">
          <div class="flex justify-content-end align-items-center">
            <app-switch-lang></app-switch-lang>
          </div>
        </div>
      </div>

      <div class="flex flex-wrap flex-column justify-content-center align-items-center">
        <h2>{{'InvitationToken.JoinUsTitle' | translate }}</h2>
        <div style="margin-bottom: 40px;" class="flex flex-column justify-content-center align-items-center">
          <p>{{'InvitationToken.HeaderText' | translate }}.</p>
        </div>
      </div>

      <form [formGroup]="formGroup" *ngIf="!success">
        <div class="p-fluid formgrid grid flex flex-wrap justify-content-center align-items-center" style="padding: 20px;">
          <div class="w-full md:w-4 lg:w-4 p-fluid formgrid grid">
            <div class="field col-12 xl:col-12 lg:col-12" style="margin-bottom: 40px;">
              <label for="newPassword">{{'ResetPasswordPage.NewPasswordFieldLabel' | translate}} *</label>
              <p-password id="newPassword" formControlName="newPassword" [minlength]="8" [toggleMask]="true">
                <ng-template pTemplate="header">
                  <h6>{{'InvitationToken.SubtitlePassword' | translate}}</h6>
                </ng-template>
                <ng-template pTemplate="footer">
                  <p-divider></p-divider>
                  <p class="mt-2">{{'InvitationToken.PasswordSubtitle' | translate}}</p>
                  <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                    <li>{{'InvitationToken.PasswordSuggestion1' | translate}}</li>
                    <li>{{'InvitationToken.PasswordSuggestion2' | translate}}</li>
                    <li>{{'InvitationToken.PasswordSuggestion3' | translate}}</li>
                    <li>{{'InvitationToken.PasswordSuggestion4' | translate}}</li>
                  </ul>
                </ng-template>
              </p-password>
              <small *ngIf="submit && formGroup.controls['newPassword'].invalid" class="p-error">{{getErrorMessage('newPassword')}}</small>
            </div>
            <div class="field col-12 xl:col-12 lg:col-12" style="margin-bottom: 40px;">
              <label for="confirmPassword">{{'ResetPasswordPage.ConfirmPasswordFieldLabel' | translate}} *</label>
              <p-password id="confirmPassword" formControlName="confirmPassword" [minlength]="8" [toggleMask]="true">
                <ng-template pTemplate="header">
                  <h6>{{'InvitationToken.SubtitlePassword' | translate}}</h6>
                </ng-template>
                <ng-template pTemplate="footer">
                  <p-divider></p-divider>
                  <p class="mt-2">{{'InvitationToken.PasswordSubtitle' | translate}}</p>
                  <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                    <li>{{'InvitationToken.PasswordSuggestion1' | translate}}</li>
                    <li>{{'InvitationToken.PasswordSuggestion2' | translate}}</li>
                    <li>{{'InvitationToken.PasswordSuggestion3' | translate}}</li>
                    <li>{{'InvitationToken.PasswordSuggestion4' | translate}}</li>
                  </ul>
                </ng-template>
              </p-password>
              <small *ngIf="submit && formGroup.controls['confirmPassword'].invalid" class="p-error">{{getErrorMessage('confirmPassword')}}</small>
              <small *ngIf="submit && passwordMatchError" class="p-error">{{getPasswordMissMatchMessage()}}</small>
            </div>
          </div>
        </div>
      </form>

      <div class="flex flex-wrap justify-content-center align-items-center" style="padding: 20px;">
        <div class="w-full md:w-4 lg:w-4 p-fluid formgrid grid">
          <div class="field col-12">
            <p-button *ngIf="!success" [loading]="loading" (onClick)="submitForm()" label="{{'InvitationToken.SubmitButton' | translate }}"/>
            <p-button *ngIf="success" label="{{'InvitationCodePage.BackButton' | translate}}" [disabled]="loading" styleClass="p-button-outlined" (onClick)="goToLogin()"/>
          </div>
        </div>
      </div>
    </p-card>
  </app-content-body>
</app-dashboard-layout>

<p-toast></p-toast>
