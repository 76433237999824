<div class="container" *ngIf="offer">

  <div class="container-img">
    <figure class="figure-img">
      <img class="image" *ngIf="offer.pictures.length>0" [src]="getImageUrl(offer)" />
    </figure>
  </div>
  <div class="container-info">
  <section class="section-info-top">
      <section class="section-text">
        <span class="label">{{'ProductsCardInfo.harvest' | translate}}:</span>
        <span class="text-small">{{formatDate(offer.harvestDate)}}</span>
      </section>
      <section class="section-text">
        <span class="text-small">{{offer.netKilos}}</span>
        <span class="label">{{'ProductsCardInfo.netKilos' | translate}}</span>
      </section>
      <section class="section-text">
        <span class="text-small">{{offer.caseQuantityPerPallet}}</span>
        <span class="label">{{'ProductsCardInfo.casesPerPallet' | translate}}</span>
      </section>
    </section>
    <section class="section-name">
      <h3 class="title" *ngIf="offer.variety">{{offer.variety.species.name}}</h3>
      <section class="section-text">
        <span class="label">{{'ProductsCardInfo.variety' | translate}}:</span>
        <span class="text-small" *ngIf="offer.variety">{{offer.variety.name}}</span>
      </section>
    </section>
    <section class="section-data">
      <section class="section-data-left">
        <section class="section-text">
          <span class="label">{{'ProductsCardInfo.speciesSize' | translate}}:</span>
          <span class="text-small">{{offer.speciesSize}}</span>
        </section>
        <section class="section-text">
          <span class="label">{{'ProductsCardInfo.category' | translate}}:</span>
          <span class="text-small">{{offer.category}}</span>
        </section>
      </section>
      <section class="section-data-right">
        <section class="section-text">
          <span class="text-price"><small>$</small> {{(offer.listPrice).toFixed(2)}}</span>
          <span class="label-price">/FOB USD</span>
        </section>
        <section class="section-text">
          <span class="label">{{'ProductsCardInfo.available' | translate}}:</span>
          <span class="text-small">{{offer.availablePallets}} pallets</span>
        </section>

      </section>
    </section>
  </div>
</div>
