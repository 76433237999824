import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate, Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthGateway} from "../../application/gateway/AuthGateway";
import { Routes } from '../routes';

@Injectable({
  providedIn: 'root'
})
export class RolesGuard implements CanActivate {

  constructor(private _authService: AuthGateway, private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const profile = this._authService.getUser()

    if(!profile)
      return false

    const role = profile.profileType

    if (role && route.data['roles'].includes(role)) {
      return true
    }

    this.router.navigate([Routes.forbiddenPage]).then()
    return false;
  }
}
