<app-user-dashboard [userMenuActiveItemIndex]="0" [activeItem]="'export-offers'">
  <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="breadcrumbHomeItem"></p-breadcrumb>
  <div style="margin-top: 30px">
    <p-card [header]="getCardHeader()" [subheader]="getCardSubheader()">

      <div id="step1" style="margin-top: 40px;" *ngIf="step == step1">
        <form [formGroup]="step1FormGroup">
          <h2 style="color: #8c2b87;">{{'DirectSaleFormPage.Step1Label' | translate}}</h2>
          <p-progressBar [value]="25" color="#8c2b87"></p-progressBar>
          <h2>{{'DirectSaleFormPage.Step4Title' | translate}}</h2>

          <div class="field col-12 md:col-12 lg:col-12">
            <p-dropdown 
            [options]="programTypes" 
            [(ngModel)]="programType"
            [ngModelOptions]="{standalone: true}"
            optionValue="value"
            [showClear]="false"
            [readonly]="isEdit || isFromProgramRequest"
            [style]="{'width': '30%'}"
            [optionLabel]="profile?.lang == 'en' ? 'nameEn' : 'nameEs'" 
            placeholder="{{'DirectSaleFormPage.SelectProgramTypeText' | translate}}" />
          </div>

          <p>{{'DirectSaleFormPage.Step4Subtitle' | translate}}</p>

          <div class="p-fluid formgrid mr-2 ml-2 grid">
            <div class="field col-12 md:col-6 lg:col-4" style="margin-top: 20px;">
              <label for="importerId" style="font-weight: bolder;">{{'DirectSaleFormPage.ImporterFieldLabel' | translate}} *</label>
              <p-dropdown id="importerId"
                formControlName="importerId"
                [options]="importers"
                optionValue="id"
                optionLabel="companyName"
                [filter]="true"
                filterBy="companyName"
                [showClear]="false"
                placeholder="{{'DirectSaleFormPage.ImporterFieldLabel' | translate}}"
                [readonly]="isEdit || isFromProgramRequest"
                (onChange)="onChangeImporter()">
              </p-dropdown>
              <small *ngIf="submit && step1FormGroup.controls['importerId'].invalid" class="p-error">{{'importerId' | formErrorMessage: step1FormGroup}}</small>
            </div>
            <div class="field col-12 md:col-6 lg:col-4" style="margin-top: 20px;">
              <label for="consigneeId" style="font-weight: bolder;">{{'DirectSaleFormPage.ConsigneeFieldLabel' | translate}} *</label>
              <p-dropdown id="consigneeId"
                formControlName="consigneeId"
                [options]="consignees"
                optionValue="id"
                optionLabel="companyName"
                [filter]="true"
                [readonly]="isEdit || isFromProgramRequest"
                filterBy="companyName"
                [showClear]="false"
                placeholder="{{'DirectSaleFormPage.ConsigneeFieldLabel' | translate}}">
              </p-dropdown>
              <small *ngIf="submit && step1FormGroup.controls['consigneeId'].invalid" class="p-error">{{'consigneeId' | formErrorMessage: step1FormGroup}}</small>
            </div>
            <div class="field col-12 md:col-6 lg:col-4" style="margin-top: 20px;">
              <label for="notifyId" style="font-weight: bolder;">{{'DirectSaleFormPage.NotifyFieldLabel' | translate}} *</label>
              <p-dropdown id="notifyId"
                formControlName="notifyId"
                [options]="notifies"
                optionValue="id"
                optionLabel="companyName"
                [filter]="true"
                [readonly]="isEdit || isFromProgramRequest"
                filterBy="companyName"
                [showClear]="false"
                placeholder="{{'DirectSaleFormPage.NotifyFieldLabel' | translate}}">
              </p-dropdown>
              <small *ngIf="submit && step1FormGroup.controls['notifyId'].invalid" class="p-error">{{'notifyId' | formErrorMessage: step1FormGroup}}</small>
            </div>
            <div class="field col-12 md:col-6 lg:col-4" style="margin-top: 20px;">
              <label style="font-weight: bolder;" for="importToCountryId">{{'DirectSaleFormPage.DestinationCountryFieldLabel' | translate}} *</label>
              <p-dropdown id="importToCountryId"
                [options]="countries"
                formControlName="importToCountryId"
                optionLabel="name"
                optionValue="id"
                (onChange)="onChangeImportToCountry()"
                [filter]="true"
                [readonly]="isFromProgramRequest"
                [filterBy]="profile?.lang == 'en' ? 'name' : 'nameEs'"
                [showClear]="true"
                placeholder="{{'DirectSaleFormPage.DestinationCountryFieldLabel' | translate}}">
                <ng-template pTemplate="selectedItem">
                  <div class="flex align-items-center gap-2" *ngIf="selectedCountryTo">
                    <img [src]="'../../../../assets/img/flags/' + selectedCountryTo.code + '.png'" style="width: 18px" alt=""/>
                    <div>{{ selectedCountryTo | countryDescription }}</div>
                  </div>
                </ng-template>
                <ng-template let-country pTemplate="item">
                  <div class="flex align-items-center gap-2">
                    <img [src]="'../../../../assets/img/flags/' + country.code + '.png'" style="width: 18px" alt=""/>
                    <div>{{ country | countryDescription }}</div>
                  </div>
                </ng-template>
              </p-dropdown>
              <small *ngIf="submit && step1FormGroup.controls['importToCountryId'].invalid" class="p-error">{{'importToCountryId' | formErrorMessage: step1FormGroup}}</small>
            </div>
            <div class="field col-12 md:col-6 lg:col-4" style="margin-top: 20px;"  *ngIf="programType === 0">
              <label style="font-weight: bolder;" for="portToId">{{'DirectSaleFormPage.DestinationPortFieldLabel' | translate}} *</label>
              <p-dropdown id="portToId"
                [options]="ports"
                formControlName="portToId"
                optionLabel="name"
                optionValue="id"
                [filter]="true"
                [readonly]="isFromProgramRequest"
                filterBy="name"
                [showClear]="true"
                placeholder="{{'DirectSaleFormPage.DestinationPortFieldLabel' | translate}}"></p-dropdown>
              <small *ngIf="submit && step1FormGroup.controls['portToId'].invalid" class="p-error">{{'portToId' | formErrorMessage: step1FormGroup}}</small>
            </div>
            
            <!-- Airport selects  -->

            <div class="field col-12 md:col-6 lg:col-4" style="margin-top: 20px;" *ngIf="programType === 1">
              <label style="font-weight: bolder;" for="airportId">{{'DirectSaleFormPage.AirportFieldLabel' | translate}} *</label>
              <p-dropdown id="airportId"
                [options]="airports"
                formControlName="airportId"
                optionLabel="name"
                optionValue="id"
                [filter]="true"
                [readonly]="isFromProgramRequest"
                filterBy="name"
                [showClear]="true"
                placeholder="{{'DirectSaleFormPage.AirportFieldLabel' | translate}}"></p-dropdown>
              <small *ngIf="submit && step1FormGroup.controls['airportId'].invalid" class="p-error">{{'airportId' | formErrorMessage: step1FormGroup}}</small>
            </div>

            <div class="field col-12 md:col-6 lg:col-4" style="margin-top: 20px;"  *ngIf="programType === 0">
              <label style="font-weight: bolder;" for="containersPerWeek">{{'DirectSaleFormPage.ContainersPerWeekFieldLabel' | translate}} *</label>
              <p-inputNumber id="containersPerWeek" formControlName="containersPerWeek" [showButtons]="false" [useGrouping]="false" [min]="1" [max]="100000" placeholder="{{'DirectSaleFormPage.ContainersPerWeekFieldLabel' | translate}}"/>
              <small *ngIf="submit && step1FormGroup.controls['containersPerWeek'].invalid" class="p-error">{{'containersPerWeek' | formErrorMessage: step1FormGroup}}</small>
            </div>
            <div class="field col-12 md:col-6 lg:col-4" style="margin-top: 20px;" *ngIf="programType === 1">
              <label style="font-weight: bolder;" for="palletsPerWeek">{{'DirectSaleFormPage.PalletsPerWeekFieldLabel' | translate}} *</label>
              <p-inputNumber id="palletsPerWeek" formControlName="palletsPerWeek" [showButtons]="false" [useGrouping]="false" [min]="1" [max]="100000" placeholder="{{'DirectSaleFormPage.ContainersPerWeekFieldLabel' | translate}}"/>
              <small *ngIf="submit && step1FormGroup.controls['palletsPerWeek'].invalid" class="p-error">{{'palletsPerWeek' | formErrorMessage: step1FormGroup}}</small>
            </div>
            <div class="col-12 md:col-3 lg:col-3" style="margin-top: 20px;">
              <div class="grid" style="padding: 0px; margin: 0px;">
                <div class="field col-10" >
                  <label style="font-weight: bolder;" for="startWeek">{{'DirectSaleFormPage.StartWeekFieldLabel' | translate}} *</label>
                  <p-inputNumber [readonly]="isFromProgramRequest" id="startWeek" formControlName="startWeek" [showButtons]="true" [useGrouping]="false" [min]="startWeekMin" [max]="startWeekMax" placeholder="{{'DirectSaleFormPage.StartWeekFieldLabel' | translate}}"/>
                  <small *ngIf="submit && step1FormGroup.controls['startWeek'].invalid" class="p-error">{{'startWeek' | formErrorMessage: step1FormGroup}}</small>
                  <small *ngIf="submit && step1FormGroup.getError('endWeekRangeError')" class="p-error">{{'MessageService.ExportOffer.ClosedSaleWeekRangeError' | translate}}</small>
                </div>
                <div class="col-2">
                    <p-button (click)="op.toggle($event)" icon="pi pi-calendar" [style]="{'margin-top': '50%'}"/>
                    <p-overlayPanel #op 
                      [style]="{ width: '350px' }"
                      >
                        <p-calendar 
                        class="max-w-full"
                        [inline]="true"
                        [firstDayOfWeek]="0"
                        formControlName="startDate"
                        [showWeek]="true"
                        [style]="{'width':'100%'}"
                        (onSelect)="onChangeStartDate($event)"
                        ></p-calendar>        
                    </p-overlayPanel>
                </div>
              </div>
            </div>

            <div class="field col-12 md:col-3 lg:col-3" style="margin-top: 20px;">
              <label style="font-weight: bolder;" for="startWeekYear">{{'DirectSaleFormPage.StartYearFieldLabel' | translate}} *</label>
              <p-dropdown [readonly]="isFromProgramRequest" onchange="" id="startWeekYear" (onChange)="onChangeStartYear($event)" formControlName="startWeekYear" [options]="years" optionLabel="label" optionValue="value" placeholder="{{'DirectSaleFormPage.StartYearFieldLabel' | translate}}"></p-dropdown>
              <small *ngIf="submit && step1FormGroup.controls['startWeekYear'].invalid" class="p-error">{{'startWeekYear' | formErrorMessage: step1FormGroup}}</small>
              <small *ngIf="submit && step1FormGroup.getError('endWeekYearRangeError')" class="p-error">{{'MessageService.ExportOffer.ClosedSaleYearRangeError' | translate}}</small>
            </div>
            <div class="col-12 md:col-3 lg:col-3" style="margin-top: 20px;">
              <div class="grid" style="padding: 0px; margin: 0px;">
                <div class="field col-10">
                  <label style="font-weight: bolder;" for="endWeek">{{'DirectSaleFormPage.EndWeekFieldLabel' | translate}}</label>
                  <p-inputNumber [readonly]="isFromProgramRequest" id="endWeek" formControlName="endWeek" [showButtons]="true" [useGrouping]="false" [min]="endWeekMin" [max]="endWeekMax" placeholder="{{'DirectSaleFormPage.EndWeekFieldLabel' | translate}}"/>
                  <small *ngIf="submit && step1FormGroup.getError('endWeekRangeError')" class="p-error">{{'MessageService.ExportOffer.ClosedSaleWeekRangeError' | translate}}</small>
                </div>
                <div class="col-2" >
                  <p-button (click)="op1.toggle($event)" icon="pi pi-calendar"  [style]="{'margin-top': '50%'}"/>
                  <p-overlayPanel #op1 
                    [style]="{ width: '350px' }"
                    >
                      <p-calendar 
                      class="max-w-full"
                      [inline]="true"
                      formControlName="endDate"
                      [showWeek]="true"
                      [firstDayOfWeek]="0"
                      [style]="{'width':'100%'}"
                      (onSelect)="onChangeEndDate($event)"
                      ></p-calendar>        
                  </p-overlayPanel>
                </div>
              </div>
            </div>
            <div class="field col-12 md:col-3 lg:col-3" style="margin-top: 20px;">
              <label style="font-weight: bolder;" for="endWeekYear">{{'DirectSaleFormPage.EndYearFieldLabel' | translate}}</label>
              <p-dropdown [readonly]="isFromProgramRequest" id="endWeekYear"  (onChange)="onChangeEndYear($event)" formControlName="endWeekYear" [options]="years" optionLabel="label" optionValue="value" placeholder="{{'DirectSaleFormPage.EndYearFieldLabel' | translate}}"></p-dropdown>
              <small *ngIf="submit && step1FormGroup.getError('endWeekYearRangeError')" class="p-error">{{'MessageService.ExportOffer.ClosedSaleYearRangeError' | translate}}</small>
            </div>
          </div>
          <div class="grid mr-2 ml-2" style="margin-top: 30px; background-color: #FCF5FC; border-radius: 20px;">
            <div class="col-12 md:col-4 lg:col-4" style="border-right: 2px solid; border-color: #8c2b87;">
              <div class="flex justify-content-start align-items-center">
                <i class="pi pi-calendar" style="font-size: 2rem; color: #8c2b87; padding-left: 20px;"></i>
                <label style="padding-left: 20px;">{{'DirectSaleFormPage.TotalWeeksLabel' | translate}}</label>
                <span style="font-weight: bolder; font-size: 1.5em; padding-left: 20px;">{{ totalWeeks }}</span>
              </div>
            </div>
            <div class="col-12 md:col-4 lg:col-4" style="border-right: 2px solid; border-color: #8c2b87;">
              <div class="flex justify-content-start align-items-center">
                <span class="material-icons material-symbols-outlined" style="font-size: 2rem; color: #8c2b87; padding-left: 20px;">pallet</span>
                <label style="padding-left: 20px;">{{'DirectSaleFormPage.TotalPalletsScheduledLabel' | translate}} </label>
                <span style="font-weight: bolder; font-size: 1.5em; padding-left: 20px;"> {{totalPallets}}</span>
              </div>
            </div>
            <div class="col-12 md:col-4 lg:col-4" *ngIf="programType === 0">
              <div class="flex justify-content-start align-items-center">
                <span class="material-icons material-symbols-outlined" style="font-size: 2rem; color: #8c2b87; padding-left: 20px;">archive</span>
                <label style="padding-left: 20px;">{{'DirectSaleFormPage.TotalContainersLabel' | translate}}</label>
                <span style="font-weight: bolder; font-size: 1.5em; padding-left: 20px;"> {{totalContainers}}</span>
              </div>
            </div>
            <div class="col-12 md:col-4 lg:col-4" *ngIf="programType === 1">
              <div class="flex justify-content-start align-items-center">
                <span class="material-icons material-symbols-outlined" style="font-size: 2rem; color: #8c2b87; padding-left: 20px;">archive</span>
                <label style="padding-left: 20px;">{{'DirectSaleFormPage.TotalPalletsLabel' | translate}}</label>
                <span style="font-weight: bolder; font-size: 1.5em; padding-left: 20px;"> {{totalProgramPallets}}</span>
              </div>
            </div>
          </div>
          <div class="grid mr-2 ml-2" style="margin-top: 30px;">
            <div class="col-6"></div>
            <div class="col-6">
              <div class="p-fluid formgrid grid">
                <div class="col-6"></div>
                <div class="col-6">
                  <p-button label="{{'DirectSaleFormPage.NextButtonLabel' | translate}}" (onClick)="goToStep2()" [loading]="loading"></p-button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div id="step2" style="margin-top: 40px;" *ngIf="step == step2">
        <form [formGroup]="step2FormGroup">
          <h2 style="color: #8c2b87;">{{'DirectSaleFormPage.Step2Label' | translate}}</h2>
          <p-progressBar [value]="50" color="#8c2b87"></p-progressBar>
          <h2>{{'DirectSaleFormPage.Step2Title' | translate}}</h2>
          <p>{{'DirectSaleFormPage.Step2Subtitle' | translate}}</p>

          <div class="p-fluid formgrid grid mr-2 ml-2">
            <div class="field col-12 md:col-6 lg:col-4" style="margin-top: 20px;">
              <label style="font-weight: bolder;" for="species">{{'DirectSaleFormPage.SpeciesFieldLabel' | translate}} *</label>
              <p-dropdown id="species"
                formControlName="speciesId"
                [options]="species"
                optionLabel="nameEs"
                optionValue="id"
                [filter]="true"
                [filterBy]="profile?.lang == 'en' ? 'name' : 'nameEs'"
                [showClear]="true"
                [readonly]="isFromProgramRequest"
                placeholder="{{'DirectSaleFormPage.SpeciesFieldLabel' | translate }}"
                (onChange)="onChangeSpecies()">
                <ng-template pTemplate="selectedItem">
                  <div class="flex align-items-center gap-2" *ngIf="selectedSpecies">
                      <img [src]="selectedSpecies.imageUrl" style="width: 18px" alt=""/>
                      <div>{{ selectedSpecies | speciesName }}</div>
                  </div>
                </ng-template>
                <ng-template let-specie pTemplate="item">
                    <div class="flex align-items-center gap-2">
                        <img [src]="specie.imageUrl" style="width: 18px" alt=""/>
                        <div>{{ specie | speciesName }}</div>
                    </div>
                </ng-template>
              </p-dropdown>
              <small *ngIf="submit && step2FormGroup.controls['speciesId'].invalid" class="p-error">{{'speciesId' | formErrorMessage: step2FormGroup}}</small>
            </div>
            <div class="field col-12 md:col-6 lg:col-4" style="margin-top: 20px;">
              <label for="variety" style="font-weight: bolder;">{{'DirectSaleFormPage.VarietyFieldLabel' | translate}} *</label>
              <p-dropdown id="variety"
                formControlName="varietyId"
                [options]="varieties"
                optionLabel="name"
                [readonly]="isFromProgramRequest"
                optionValue="id"
                [filter]="true"
                filterBy="name"
                [showClear]="true"
                placeholder="{{'DirectSaleFormPage.VarietyFieldLabel' | translate}}"
                (onChange)="onChangeVariety()"
                emptyMessage="{{'DirectSaleFormPage.VarietyFieldEmptyMessage' | translate}}.">
                <ng-template pTemplate="selectedItem">
                  <div class="flex align-items-center gap-2" *ngIf="selectedVariety">
                      <img [src]="selectedVariety.urlIcon" style="width: 18px" alt=""/>
                      <div>{{ selectedVariety.name }}</div>
                  </div>
                </ng-template>
                <ng-template let-variety pTemplate="item">
                    <div class="flex align-items-center gap-2">
                        <img [src]="variety.urlIcon" style="width: 18px" alt=""/>
                        <div>{{ variety.name }}</div>
                    </div>
                </ng-template>
              </p-dropdown>
              <small *ngIf="submit && step2FormGroup.controls['varietyId'].invalid" class="p-error">{{'varietyId' | formErrorMessage: step2FormGroup}}</small>
            </div>
            <div class="field col-12 md:col-6 lg:col-4" style="margin-top: 20px;">
              <label style="font-weight: bolder;" for="caseQuantityPerPallet">{{'DirectSaleFormPage.CasePerPalletsFieldLabel' | translate }} *</label>
              <p-inputNumber inputId="caseQuantityPerPallet" formControlName="caseQuantityPerPallet" [min]="1" placeholder="{{'DirectSaleFormPage.CasePerPalletsFieldLabel' | translate}}"></p-inputNumber>
              <small *ngIf="submit && step2FormGroup.controls['caseQuantityPerPallet'].invalid" class="p-error">{{'caseQuantityPerPallet' | formErrorMessage: step2FormGroup}}</small>
            </div>
            <div class="field col-12 md:col-6 lg:col-4" style="margin-top: 20px;">
              <label for="listPrice" style="font-weight: bolder;">{{'DirectSaleFormPage.ListPriceFieldLabelCloseSale' | translate }} *</label>
              <p-inputNumber inputId="listPrice" formControlName="listPrice" mode="currency" currency="USD" locale="en-US" [min]="0" placeholder="{{'DirectSaleFormPage.ListPriceFieldLabelCloseSale' | translate }}"></p-inputNumber>
              <small *ngIf="submit && step2FormGroup.controls['listPrice'].invalid" class="p-error">{{'listPrice' | formErrorMessage: step2FormGroup}}</small>
            </div>
            <div class="field col-12 md:col-6 lg:col-4" style="margin-top: 20px;">
              <label for="incoterms" style="font-weight: bolder;">{{'DirectSaleFormPage.IncotermsFieldLabel' | translate }} *</label>
              <p-dropdown inputId="incoterms" formControlName="incoterms" [options]="incotermsOptions" optionLabel="label" optionValue="value" placeholder="{{'DirectSaleFormPage.IncotermsFieldLabel' | translate}}"></p-dropdown>
              <small *ngIf="submit && step2FormGroup.controls['incoterms'].invalid" class="p-error">{{'incoterms' | formErrorMessage: step2FormGroup}}</small>
            </div>
            <div class="field col-12 md:col-6 lg:col-4" style="margin-top: 20px;">
              <label for="paymentAgainstBL" style="font-weight: bolder; font-size: calc(100% - 4px); white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ paymentBLTitle | translate }} *</label>
              <p-dropdown id="paymentAgainstBL" formControlName="paymentAgainstBL" [options]="paymentBLItems" optionLabel="label" optionValue="value" placeholder="{{'DirectSaleFormPage.PaymentAgainstBLLabel' | translate}}"></p-dropdown>
              <small *ngIf="submit && step2FormGroup.controls['paymentAgainstBL'].invalid" class="p-error">{{'paymentAgainstBL' | formErrorMessage: step2FormGroup}}</small>
              <small *ngIf="paymentBLPercent">{{'DirectSaleFormPage.FinalPaymentSubjectQCClosedSaleReview' | translate}} {{finalPaymentPercent}}%</small>
            </div>
            <div class="field col-12 md:col-6 lg:col-4" style="margin-top: 20px;">
              <label style="font-weight: bolder;" for="netKilos">{{'DirectSaleFormPage.NetKilosFieldLabel' | translate}}</label>
              <input pInputText inputId="netKilos"  formControlName="netKilos" [maxlength]="formLimit.netKilosLimit" placeholder="{{'DirectSaleFormPage.NetKilosFieldLabel' | translate}}"/>
            </div>
            <div class="field col-12 md:col-6 lg:col-4" style="margin-top: 20px;">
              <label for="packaging" style="font-weight: bolder;">{{'DirectSaleFormPage.PackagingFieldLabel' | translate}}</label>
              <p-dropdown id="packaging"
                formControlName="packaging"
                [options]="packagingList"
                optionLabel="name"
                [filter]="true"
                filterBy="name"
                [showClear]="false"
                placeholder="{{'DirectSaleFormPage.PackagingFieldLabel' | translate}}"
                (onChange)="filterPackaging($event.value)">
              </p-dropdown>
            </div>
            <div class="field col-12 md:col-6 lg:col-4" style="margin-top: 20px;">
              <label style="font-weight: bolder;" for="speciesSize">{{'DirectSaleFormPage.SpeciesSizeFieldLabel' | translate}}</label>
              <input pInputText  [maxlength]="formLimit.speciesSizeLimit" inputId="speciesSize"inputId="speciesSize" formControlName="speciesSize" placeholder="{{'DirectSaleFormPage.SpeciesSizeFieldLabel' | translate}}"/>
            </div>
          </div>
          <div class="grid mr-2 ml-2" style="margin-top: 30px;">
            <div class="col-6"></div>
            <div class="col-6">
              <div class="p-fluid formgrid grid">
                <div class="col-6">
                  <p-button label="{{'DirectSaleFormPage.BackButtonLabel' | translate}}" (onClick)="backStep1()" styleClass="p-button-outlined"></p-button>
                </div>
                <div class="col-6">
                  <p-button *ngIf="!directSaleProgramEdit" label="{{'DirectSaleFormPage.NextButtonLabel' | translate}}" (onClick)="onConfirmSave()" [loading]="loading"></p-button>
                  <p-button *ngIf="directSaleProgramEdit" label="{{'DirectSaleFormPage.NextButtonLabel' | translate}}" (onClick)="onConfirmEdit()" [loading]="loading"></p-button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div id="step3" style="margin-top: 40px;" *ngIf="step == step3">
        <h2 style="color: #8c2b87;">{{'DirectSaleFormPage.Step3Label' | translate}}</h2>
        <p-progressBar [value]="100" color="#8c2b87"></p-progressBar>
        <div>
          <h2>{{'UserPages.UserExportOffers.NewExportOffer.Step2Title' | translate}}</h2>
          <p>{{'UserPages.UserExportOffers.NewExportOffer.Step3Subtitle' | translate}}.</p>
          <p-fileUpload name="demo[]"
            #imagesUploader
            (onSelect)="onSelectImage($event, imagesUploader)"
            (onRemove)="onRemoveImage($event, imagesUploader)"
            (onClear)="onClearImageUploader($event, imagesUploader)"
            [showUploadButton]="false"
            [multiple]="true"
            accept=".jpg, .jpeg, .png"
            [fileLimit]="3"
            [files]="uploadedImages"
            [maxFileSize]="3000000"
            chooseLabel="{{'UserPages.UserExportOffers.NewExportOffer.ChooseImagesButton' | translate}}"
            cancelLabel="{{'UserPages.UserExportOffers.NewExportOffer.CancelImagesButton' | translate}}">
            <ng-template pTemplate="toolbar">
              <div class="py-3"><h2>{{'UserPages.UserExportOffers.NewExportOffer.LoadImagesFieldLabel' | translate}}</h2></div>
            </ng-template>
            <ng-template pTemplate="content">

            </ng-template>
          </p-fileUpload>
          <p-table [value]="pictures">
            <ng-template pTemplate="header">
            </ng-template>
            <ng-template pTemplate="body" let-picture let-rowIndex="rowIndex">
              <tr>
                <td>
                  <img [src]="getImageUrl(picture)" style="border-radius: 7px; width: 100%; max-width: 100px; height: auto;" alt=""/>
                </td>
                <td>
                  {{getImageName(picture)}}
                </td>
                <td>
                  <div class="flex justify-content-end align-items-center gap-4">
                    <p-button icon="pi pi-times" (click)="onRemoveImageEdit(rowIndex)"></p-button>
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
          <!-- <app-export-offer-picture-uploader [exportOfferId]="guid"></app-export-offer-picture-uploader> -->
        </div>
        <div style="margin-top: 40px;">
          <h2>{{'UserPages.UserExportOffers.NewExportOffer.Step3ProductCertificateTypeTitle' | translate}}</h2>
          <p>{{'UserPages.UserExportOffers.NewExportOffer.Step3ProductCertificateTypeSubtitle' | translate}}</p>
          <app-export-offer-certificate-type [exportOfferId]="guid" [otherCertificateType]="directSaleProgramEdit?.exportOffer?.otherCertificateType"></app-export-offer-certificate-type>
        </div>
        <div class="grid" style="margin-top: 30px;">
          <div class="col-6"></div>
          <div class="col-6">
            <div class="p-fluid formgrid grid">
              <div class="col-6">
                <!--p-button label="{{'DirectSaleFormPage.BackButtonLabel' | translate}}" styleClass="p-button-outlined"></p-button-->
              </div>
              <div class="col-6">
                <p-button label="{{'DirectSaleFormPage.FinishButtonLabel' | translate}}" (onClick)="finish()" [loading]="loading"></p-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="finalStep" *ngIf="step == finalStep">
        <div class="flex align-items-center justify-content-center flex-column">
          <h2>{{'DirectSaleFormPage.FinalStepTitle' | translate}}</h2>
          <div style="margin-top: 20px;" class="flex align-items-center justify-content-center flex-column">
            <img src="../../../../../assets/img/create-export-offer-aditional-icon.png" alt="Icon" style="border-radius: 7px; width: 100%; max-width: 300px; height: auto;"/>
            <p>{{'DirectSaleFormPage.FinalStepText1' | translate}}</p>
            <p>{{'DirectSaleFormPage.FinalStepText3' | translate}}</p>
          </div>
          <div style="margin-top: 20px;" class="flex align-items-center justify-content-center flex-column gap-4">
            <p-button label="{{'DirectSaleFormPage.FinalStepAdditionalInfoButtonLabel' | translate}}" (onClick)="goToDirectSaleEditAdditionalInfoPage()"></p-button>
            <p-button label="{{'DirectSaleFormPage.FinalStepSkipAdditionalInfoButtonLabel' | translate}}" (onClick)="goToDirectSalesPage()" styleClass="p-button-outlined"></p-button>
          </div>
        </div>
      </div>
    </p-card>
  </div>
</app-user-dashboard>

<p-toast></p-toast>

<p-dialog header="{{'DirectSaleFormPage.ConfirmSaveDialogTitle' | translate}}" [(visible)]="confirmSaveDialogVisible" [style]="{width: '70%'}" [modal]="true">
  <p>{{'DirectSaleFormPage.ConfirmSaveDialogSubtitle' | translate}}</p>
  <h3>{{'DirectSaleFormPage.ConfirmSaveDialogResumeTitle' | translate}}</h3>
  <div class="grid" style="margin-top: 40px;">
    <div class="col-12 md:col-6"><span style="font-weight: bolder;">{{'DirectSaleFormPage.ImporterFieldLabel' | translate}}</span></div>
    <div class="col-12 md:col-6">{{resume?.importer}}</div>
    <div class="col-12 md:col-6"><span style="font-weight: bolder;">{{'DirectSaleFormPage.ConsigneeFieldLabel' | translate}}</span></div>
    <div class="col-12 md:col-6">{{resume?.consignee}}</div>
    <div class="col-12 md:col-6"><span style="font-weight: bolder;">{{'DirectSaleFormPage.NotifyFieldLabel' | translate}}</span></div>
    <div class="col-12 md:col-6">{{resume?.notify}}</div>
    <div class="col-12 md:col-6"><span style="font-weight: bolder;">{{'DirectSaleFormPage.DestinationPortFieldLabel' | translate}}</span></div>
    <div class="col-12 md:col-6">{{resume?.destinationPort}}</div>
    <div class="col-12 md:col-6"><span style="font-weight: bolder;">{{'DirectSaleFormPage.StartWeekFieldLabel' | translate}}</span></div>
    <div class="col-12 md:col-6">{{resume?.starWeek}}</div>
    <div class="col-12 md:col-6"><span style="font-weight: bolder;">{{'DirectSaleFormPage.EndWeekFieldLabel' | translate}}</span></div>
    <div class="col-12 md:col-6">{{resume?.endWeek}}</div>
    <div class="col-12 md:col-6"><span style="font-weight: bolder;">{{'DirectSaleFormPage.ContainersPerWeekFieldLabel' | translate}}</span></div>
    <div class="col-12 md:col-6">{{resume?.containersPerWeek}}</div>
    <div class="col-12 md:col-6"><span style="font-weight: bolder;">{{'DirectSaleFormPage.ListPriceFieldLabelCloseSale' | translate}}</span></div>
    <div class="col-12 md:col-6">{{resume?.price | currency: 'USD':'US$'}} {{resume?.incoterms}}</div>
    <div class="col-12 md:col-6"><span style="font-weight: bolder;">{{'DirectSaleFormPage.SpeciesFieldLabel' | translate}}</span></div>
    <div class="col-12 md:col-6">{{ resume?.species | speciesName }}</div>
    <div class="col-12 md:col-6"><span style="font-weight: bolder;">{{'DirectSaleFormPage.VarietyFieldLabel' | translate}}</span></div>
    <div class="col-12 md:col-6">{{resume?.variety}}</div>
  </div>
  <ng-template pTemplate="footer">
    <div class="flex align-items-center justify-content-end gap-4">
      <p-button label="{{'MessageService.ConfirmDialogNoButtonLabel' | translate}}" (onClick)="confirmSaveDialogVisible = false" styleClass="p-button-outlined"></p-button>
      <p-button label="{{'MessageService.ConfirmDialogYesButtonLabel' | translate}}" (onClick)="save()" [loading]="loading"></p-button>
    </div>
  </ng-template>
</p-dialog>

<p-dialog header="{{'DirectSaleFormPage.ConfirmEditDialogTitle' | translate}}" [(visible)]="confirmEditDialogVisible" [style]="{width: '70%'}" [modal]="true">
  <p>{{'DirectSaleFormPage.ConfirmEditDialogSubtitle' | translate}}</p>
  <h3>{{'DirectSaleFormPage.ConfirmSaveDialogResumeTitle' | translate}}</h3>
  <div class="grid" style="margin-top: 40px;">
    <div class="col-12 md:col-6"><span style="font-weight: bolder;">{{'DirectSaleFormPage.ImporterFieldLabel' | translate}}</span></div>
    <div class="col-12 md:col-6">{{resume?.importer}}</div>
    <div class="col-12 md:col-6"><span style="font-weight: bolder;">{{'DirectSaleFormPage.ConsigneeFieldLabel' | translate}}</span></div>
    <div class="col-12 md:col-6">{{resume?.consignee}}</div>
    <div class="col-12 md:col-6"><span style="font-weight: bolder;">{{'DirectSaleFormPage.NotifyFieldLabel' | translate}}</span></div>
    <div class="col-12 md:col-6">{{resume?.notify}}</div>
    <div class="col-12 md:col-6"><span style="font-weight: bolder;">{{'DirectSaleFormPage.DestinationPortFieldLabel' | translate}}</span></div>
    <div class="col-12 md:col-6">{{resume?.destinationPort}}</div>
    <div class="col-12 md:col-6"><span style="font-weight: bolder;">{{'DirectSaleFormPage.StartWeekFieldLabel' | translate}}</span></div>
    <div class="col-12 md:col-6">{{resume?.starWeek}}</div>
    <div class="col-12 md:col-6"><span style="font-weight: bolder;">{{'DirectSaleFormPage.EndWeekFieldLabel' | translate}}</span></div>
    <div class="col-12 md:col-6">{{resume?.endWeek}}</div>
    <div class="col-12 md:col-6"><span style="font-weight: bolder;">{{'DirectSaleFormPage.ContainersPerWeekFieldLabel' | translate}}</span></div>
    <div class="col-12 md:col-6">{{resume?.containersPerWeek}}</div>
    <div class="col-12 md:col-6"><span style="font-weight: bolder;">{{'DirectSaleFormPage.ListPriceFieldLabelCloseSale' | translate}}</span></div>
    <div class="col-12 md:col-6">{{resume?.price | currency: 'USD':'US$'}} {{resume?.incoterms}}</div>
    <div class="col-12 md:col-6"><span style="font-weight: bolder;">{{'DirectSaleFormPage.SpeciesFieldLabel' | translate}}</span></div>
    <div class="col-12 md:col-6">{{ resume?.species | speciesName }}</div>
    <div class="col-12 md:col-6"><span style="font-weight: bolder;">{{'DirectSaleFormPage.VarietyFieldLabel' | translate}}</span></div>
    <div class="col-12 md:col-6">{{resume?.variety}}</div>
  </div>
  <ng-template pTemplate="footer">
    <div class="flex align-items-center justify-content-end gap-4">
      <p-button label="{{'MessageService.ConfirmDialogNoButtonLabel' | translate}}" (onClick)="confirmEditDialogVisible = false" styleClass="p-button-outlined"></p-button>
      <p-button label="{{'MessageService.ConfirmDialogYesButtonLabel' | translate}}" (onClick)="edit()" [loading]="loading"></p-button>
    </div>
  </ng-template>
</p-dialog>
