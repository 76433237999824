import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CreatePaymentQualityControlRequest, PaymentQualityControl } from "src/app/domain/models/PaymentQualityControl";

@Injectable({providedIn: 'root'})
export abstract class PaymentQualityControlGateway {
    abstract create(request: CreatePaymentQualityControlRequest): Observable<PaymentQualityControl>
    abstract approvePayment(paymentQualityControlId: number): Observable<any>
    abstract getAllByImportOrderDetailId(paymentQualityControlId: string): Observable<PaymentQualityControl>
    abstract getAllByOrderId(orderId: string): Observable<PaymentQualityControl[]>
}

