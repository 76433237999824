import { Component } from '@angular/core';
import { Routes } from 'src/app/infrastructure/routes';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer-home',
  templateUrl: './footer-home.component.html',
  styleUrls: ['./footer-home.component.css']
})
export class FooterHomeComponent {

  linkedInUrl: string = 'https://www.linkedin.com/company/fruzty/'
  instagramUrl: string = 'https://www.instagram.com/fruzty_com?igsh=MXE3Mzc4eG40ZnpvbA=='


  loginUrl:string = Routes.login;
  homeUrl: string = Routes.homePage;
  offersUrl: string = Routes.externalMarketplace;
  singUpUrl: string = Routes.signup;

  contactEmail: string = environment.contactEmail;
  mailToUrl: string = 'mailto:' + this.contactEmail;

  visibleDialog: boolean = false


  openLink(url: string){
    window.open(url)
  }

  visibleRequestCode(){
     this.visibleDialog = true
  }

}
