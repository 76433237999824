import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { UploadOperationFileRequest, Operation, OperationInformation } from "src/app/domain/models/Operation";
import { OperationGateway } from "../gateway/OperationGateWay";
import { Routes } from "src/app/infrastructure/routes";
import { OperationDocument } from "src/app/domain/models/OperationDocument";

@Injectable({providedIn: 'root'})
export class OperationService extends OperationGateway{

    constructor(private _http: HttpClient){
        super()
    }

    uploadFile(uploadRequest: UploadOperationFileRequest): Observable<Operation> {
      return this._http.put<Operation>(Routes.operationApi + '/UploadInstructive',
      this.getFormDataCreate(uploadRequest)
      )
    }

    updateDocument(operationDocument: OperationDocument): Observable<OperationDocument> {
      return this._http.put<OperationDocument>(Routes.operationApi + '/UpdateDocument',
        this.getFormDataUpdate(operationDocument)
      )
    }

    deleteDocument(operationDocumentId: number): Observable<any> {
      return this._http.delete<any>(Routes.operationApi + '/DeleteDocument', { params: {operationDocumentId}})
    }

    getFormDataCreate(uploadRequest: UploadOperationFileRequest){
        const formData = new FormData();
        formData.append("orderId", uploadRequest.orderId.toString());
        formData.append("name", uploadRequest.name);
        formData.append("pdf", uploadRequest.pdf)
        formData.append("operationDocumentTypeId", uploadRequest.operationDocumentTypeId)
        return formData;
    }

    getFormDataUpdate(operationDocument: OperationDocument) {
      const formData = new FormData();
      formData.append("id", operationDocument.id.toString())
      formData.append("name", operationDocument.name)
      formData.append("pdf", operationDocument.pdf)
      return formData;
    }


    getByImportOrderId(importOrderId: any): Observable<OperationInformation>{
        return this._http.get<OperationInformation>(Routes.operationApi + '/GetOperationDocuments', {params: {importOrderId}})
    }
}
