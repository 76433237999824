import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthGateway } from '../application/gateway/AuthGateway';

@Pipe({
  name: 'documentDescription'
})
export class DocumentDescriptionPipe implements PipeTransform {

  constructor(private _authService: AuthGateway,
    public translate: TranslateService) {}

  transform(document: any): unknown {
    let pageLang = this.translate.currentLang

    const profile = this._authService.getUser()
    if(profile) pageLang = profile.lang

    if (document === undefined || document === null) return this.translate.instant('CommonLabels.NotAvailableLabel');

    if (typeof document === 'object' && ('nameEn' in document || 'nameEs' in document) && 'name' in document) {
      if (pageLang === undefined) return document.name; //Se ubica aquí por páginas donde el lang no esté cargado
      return pageLang === 'es' ? (document.nameEs ? document.nameEs : document.name) : (document.nameEn ? document.nameEn : document.name);
    } else {
      // Si country no tiene las propiedades esperadas, simplemente retornar el mismo valor
      return this.translate.instant('CommonLabels.NotAvailableLabel');
    }
  }

}
