import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { CountryGateway } from 'src/app/application/gateway/CountryGateway';
import { FridgeStorageGateway } from 'src/app/application/gateway/FridgeStorageGateway';
import { ImportOrderGateway } from 'src/app/application/gateway/ImportOrderGateway';
import { PackagingGateway } from 'src/app/application/gateway/PackagingGateway';
import { PortGateway } from 'src/app/application/gateway/PortGateway';
import { SpeciesGateway } from 'src/app/application/gateway/SpeciesGateway';
import { VarietyGateway } from 'src/app/application/gateway/VarietyGateway';
import { Country } from 'src/app/domain/models/Country';
import { EditBasicOffer } from 'src/app/domain/models/ForwarderOrder';
import { FridgeStorage, NewFridgeStorage } from 'src/app/domain/models/FridgeStorage';
import { GenericOrderDetail, ImportOrder, ImportOrderDetail } from 'src/app/domain/models/ImportOrder';
import { Packaging } from 'src/app/domain/models/Packaging';
import { Port } from 'src/app/domain/models/Port';
import { Species } from 'src/app/domain/models/Species';
import { BasicUser } from 'src/app/domain/models/User';
import { Variety } from 'src/app/domain/models/Variety';
import { Routes } from 'src/app/infrastructure/routes';
import { ErrorMessageUtil } from 'src/app/ui/util/error-message-util';
import { ExportOfferUtils } from 'src/app/ui/util/export-offer-utils';
import { SpeciesUtils } from 'src/app/ui/util/species-utils';

@Component({
  selector: 'app-forwarder-edit-import-order',
  templateUrl: './forwarder-edit-import-order.component.html',
  styleUrls: ['./forwarder-edit-import-order.component.css'],
  providers: [MessageService]
})
export class ForwarderEditImportOrderComponent {
  
  profile: BasicUser | undefined

  step: number = 1
  step1: number = 1
  step2: number = 2
  step3: number = 3
  totalStep: number = 3

  loading: boolean = false
  submit: boolean = false
  loadFridgestorageDialogVisible: boolean = false

  importOrderId: any | undefined
  importOrderDetail: GenericOrderDetail | undefined

  species: Species[] = []
  varieties: Variety[] = []
  selectedSpecies: Species | undefined
  selectedVariety: Variety | undefined

  incotermsOptions: any[] = []
  fridgeStorages: FridgeStorage[] = []
  fridgeStorageOptions: any[] = []
  countriesOrigin: any[] = []
  destinationPorts: Port[] = []
  countries: Country[] = []
  selectedCountryFrom: Country | undefined
  selectedCountryTo: Country | undefined
  years: any[] = []

  packagingList: Packaging[] = []
  packagingSelected: number[] = []

  colors: any[] = []
  boxTypes: any[] = []
  basePallets: any[] = []
  qualityControlOptions: any[] = []
  marksOptions: any[] = []
  selectedColor: any | undefined

  step1FormGroup: FormGroup = this._formBuilder.group({
    speciesId: [null, Validators.required],
    varietyId: [null, Validators.required],
    palletQuantity: [null, Validators.required],
    // availablePallets: [null],
    // fridgeStorageId: [null, Validators.required],
    listPrice: [null, Validators.required],
    caseQuantityPerPallet: [null, Validators.required],
    incoterms: ['EXW', Validators.required],
    // paymentAgainstBL: [100, Validators.required],
    // countryId: ['', Validators.required],
    // countryFromId: [null],
    // portId: ['', Validators.required],
  })

  // step2FormGroup: FormGroup = this._formBuilder.group({
  //   harvestDate: [null, Validators.required],
  //   shelfLife: [null, Validators.required],
  //   netKilos: ['', Validators.required],
  //   grossKilos: ['', Validators.required],
  //   speciesSize: ['', Validators.required],
  //   readyToLoadDate: [null, Validators.required],
  //   packagings: ['', Validators.required],
  //   otherPackaging: [{value: '', disabled: true}],
  //   category: ['', Validators.required]
  // })

  // step3FormGroup: FormGroup = this._formBuilder.group({
  //   color: [null],
  //   boxType: [null],
  //   caseSize: [null],
  //   kilosPerBox: [null],
  //   isQualityControl: [false],
  //   qualityControlDate: [null],
  //   basePallets: [null],
  //   marks: [false],
  //   sizeMillimeters: [null]
  // })

  // fridgestorageFormGroup: FormGroup = this._formBuilder.group({
  //   fridgestorageName: [null, Validators.required],
  //   fridgestorageVat: [null],
  //   fridgestorageContactName: [null, Validators.required],
  //   fridgestorageContactAddress: [null, Validators.required],
  //   fridgestorageContactCity: [null, Validators.required],
  //   fridgestorageContactEmail: [null, Validators.required],
  //   fridgestorageContactPhone: [null, Validators.required],
  //   fridgestorageContactCountryId: [null, Validators.required]
  // })

  constructor(
    private _router: Router,
    private _messageService: MessageService,
    private _activatedRoute: ActivatedRoute,
    private _authService: AuthGateway,
    private _formBuilder: FormBuilder,
    private _speciesService: SpeciesGateway,
    private _varietyService: VarietyGateway,
    private _fridgeStorageService: FridgeStorageGateway,
    private _countryService: CountryGateway,
    private _portService: PortGateway,
    private _packagingService: PackagingGateway,
    private _importOrderService: ImportOrderGateway,
    public translate: TranslateService) {

      this._activatedRoute.params.subscribe(params => {
        if(params['importOrderId']) this.importOrderId = params['importOrderId']
      })
  }

  ngOnInit(): void {
    this.profile = this._authService.getUser()

    this.getImportOrder()
    this.loadSpecies()
    // this.loadFridgeStorages()
    // this.loadCountries()
    // this.loadPackagings()

    this.loadForm()
  }

  getImportOrder() {
    if(this.importOrderId) {
      this._importOrderService.getForwarderOrderById(this.importOrderId).subscribe({
        next: (v) => {
          console.log(v.genericDetails)
          this.importOrderDetail = v.genericDetails ? v.genericDetails[0] : undefined
          this.loadForm()
        },
        error: (e) => {
          console.log(e)
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOffer.SingleRetrieveError') });
        },
        complete: () => console.info('ExportOffer query successfully')
      })
    }
  }

  loadForm() {
    this.incotermsOptions = ExportOfferUtils.getIncotermsOptions()
    if(this.profile) {
      this.colors = ExportOfferUtils.getColors(this.profile.lang)
      this.boxTypes = ExportOfferUtils.getBoxTypes(this.profile.lang)
      this.basePallets = ExportOfferUtils.getBasePallets(this.profile.lang)
      this.qualityControlOptions = ExportOfferUtils.getControlQualityOptions(this.profile.lang)
      this.marksOptions = ExportOfferUtils.getMarksOptions(this.profile.lang)
    }

    if (this.importOrderDetail){
      // this.step1FormGroup.controls['varietyId'].setValue(this.importOrderDetail.varietyId)
      this.step1FormGroup.controls['palletQuantity'].setValue(this.importOrderDetail.palletsQuantity)
      this.step1FormGroup.controls['listPrice'].setValue(this.importOrderDetail.price)
      this.step1FormGroup.controls['caseQuantityPerPallet'].setValue(this.importOrderDetail.boxesPerPallet)
      this.step1FormGroup.controls['incoterms'].setValue(this.importOrderDetail.incoterms)
    }

  }

  getCardHeader(): string {
    return "Editar oferta"
  }

  getCardSubheader(): string {
    return "En este formulario podrá editar la información de la orden. Los campos con asterisco (*) son obligatorios."
  }

  loadSpecies() {
    this._speciesService.getAllByProductType(1).subscribe({
      next: (v) => {
        this.species = v
        if(this.importOrderDetail && this.importOrderDetail.speciesId) {
          this.step1FormGroup.controls['speciesId'].setValue(this.importOrderDetail.speciesId)
          this.selectedSpecies = this.species.find(x => x.id === this.importOrderDetail!.speciesId)
          this.loadVarieties(this.importOrderDetail.speciesId)
        }
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Species.ManyRetrieveError') })
      },
      complete: () => console.info('Species query successfully')
    })
  }
  
  loadVarieties(speciesId: number) {
    this.varieties = []
    this._varietyService.getAllBySpecies(speciesId).subscribe({
      next: (v) => {
        this.varieties = v
        if(this.importOrderDetail) {
          this.step1FormGroup.controls['varietyId'].setValue(this.importOrderDetail.varietyId)
          this.selectedVariety = this.varieties.find(x => x.id === this.importOrderDetail!.varietyId)
        }
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Variety.ManyRetrieveError') })
      },
      complete: () => console.info('Variety query successfully')
    })
  }

  loadSpeciesParams() {
    this.selectedSpecies = this.species.find(x => x.id === this.step1FormGroup.controls['speciesId'].value)
    this.loadVarieties(this.step1FormGroup.controls['speciesId'].value)
  }

  // loadPackagings() {
  //   this._packagingService.getAll().subscribe({
  //     next: (v) => {
  //       this.packagingList = v
  //       this.loading = false
  //     },
  //     error: (e) => {
  //       console.log(e)
  //       this.loading = false
  //       this._messageService.add({ severity: 'error',
  //         summary: this.translate.instant('MessageService.Summary.FailedAction'),
  //         detail: this.translate.instant('MessageService.Packaging.ManyRetrieveError') })
  //     },
  //     complete: () => console.info('Packaging query successfully')
  //   })
  // }

  getSpeciesName(species: Species): string {
    if(this.profile)
      return SpeciesUtils.getSpeciesName(this.profile.lang, species)
    return ''
  }

  getErrorMessage(formGroup: FormGroup, fieldName: string): string {
    return ErrorMessageUtil.getErrorMessage(formGroup, this.translate, fieldName)
  }

  onChangeVariety() {
    this.selectedVariety = this.varieties.find(x => x.id === this.step1FormGroup.controls['varietyId'].value)
  }

  // onChangeFridgestorage() {
  //   const selected = this.step1FormGroup.controls['fridgeStorageId'].value
  //   if(selected === 0) {
  //     this.openNewFridgestorageDialog()
  //   }
  // }

  // openNewFridgestorageDialog() {
  //   this.fridgestorageFormGroup.controls["fridgestorageName"].setValue(''),
  //   this.fridgestorageFormGroup.controls["fridgestorageVat"].setValue(''),
  //   this.fridgestorageFormGroup.controls["fridgestorageContactName"].setValue(''),
  //   this.fridgestorageFormGroup.controls["fridgestorageContactAddress"].setValue(''),
  //   this.fridgestorageFormGroup.controls["fridgestorageContactEmail"].setValue(''),
  //   this.fridgestorageFormGroup.controls["fridgestorageContactPhone"].setValue(''),
  //   this.fridgestorageFormGroup.controls["fridgestorageContactCity"].setValue(''),
  //   this.fridgestorageFormGroup.controls["fridgestorageContactCountryId"].setValue('')
  //   this.loadFridgestorageDialogVisible = true
  // }

  // loadFridgeStorages() {
  //   this.fridgeStorages = []
  //   this.fridgeStorageOptions = []
  //   this._fridgeStorageService.getAllByStorageType(1).subscribe({
  //     next: (v) => {
  //       this.fridgeStorages = v
  //       this.fridgeStorages.forEach(x => {
  //         this.fridgeStorageOptions.push({
  //           value: x.id,
  //           label: x.name + ', ' + x.contactAddress + ' - ' +  x.contactCountry?.name
  //         })
  //       })
  //       this.fridgeStorageOptions.push({
  //         value: 0,
  //         label: this.translate.instant('MessageService.FridgeStorage.CreateOptionLabel') + '...'
  //       })
  //       if(this.importOrderDetail)
  //         this.step1FormGroup.controls['fridgeStorageId'].setValue(this.importOrderDetail.fridgeStorageId)
  //     },
  //     error: (e) => {
  //       console.log(e)
  //       this.loading = false
  //       this._messageService.add({ severity: 'error',
  //         summary: this.translate.instant('MessageService.Summary.FailedAction'),
  //         detail: this.translate.instant('MessageService.FridgeStorage.ManyRetrieveError') })
  //     },
  //     complete: () => console.info('Frigde Storage query successfully')
  //   })
  // }

  get paymentBLTitle() {
    if (this.step1FormGroup.controls['paymentAgainstBL'].value)
      return ExportOfferUtils.getPaymentBLTitle(this.step1FormGroup.controls['paymentAgainstBL'].value);
    return 'UserPages.UserBargaining.OpenTab.BargainingDetail.MakeCounterOfferModal.PaymentAgainstBLLabel'
  }

  get paymentBLItems() {
    return ExportOfferUtils.getPaymentBLValues(this.step1FormGroup.controls['incoterms'].value);
  }

  get showPaymentBLPercent() {
    return ExportOfferUtils.showPaymentBLPercent(this.step1FormGroup.controls['incoterms'].value)
  }

  get finalPaymentPercent(): number {
    if(this.step1FormGroup.controls['paymentAgainstBL'].value)
      return 100 - this.step1FormGroup.controls['paymentAgainstBL'].value
    return 0
  }

  // onChangeCountryFrom() {
  //   if (this.step1FormGroup.controls['countryFromId'].value) {
  //     this.selectedCountryFrom = this.countriesOrigin.find(x => x.id == this.step1FormGroup.controls['countryFromId'].value)
  //   }
  // }

  // loadCountries() {
  //   this.countries = []
  //   this._countryService.getAll().subscribe({
  //     next: (v) => {
  //       this.countries = v
  //       this.countriesOrigin.push({
  //         id: null,
  //         name: 'All countries',
  //         nameEs: 'Todos los países',
  //         code: 'WORLD'
  //       })
  //       this.countries.forEach(x => {
  //         this.countriesOrigin.push({...x})
  //       })
  //       this.selectedCountryFrom = this.countriesOrigin.find(x => x.id == this.step1FormGroup.controls['countryFromId'].value)
  //       this.selectedCountryTo = this.countriesOrigin.find(x => x.id == this.step1FormGroup.controls['countryId'].value)
  //       this.getYears()
  //     },
  //     error: (e) => {
  //       console.log(e)
  //       this._messageService.add({ severity: 'error',
  //         summary: this.translate.instant('MessageService.Summary.FailedAction'),
  //         detail: this.translate.instant('MessageService.Country.ManyRetrieveError') })
  //     },
  //     complete: () => console.info('Contries query successfully')
  //   })
  // }

  // loadDestinationPorts() {
  //   this.destinationPorts = [];
  //   if (this.selectedCountryTo) {
  //     this._portService.getDestinationPortsByCountry(this.selectedCountryTo.id).subscribe({
  //       next: (v) => {
  //         this.destinationPorts = v
  //       },
  //       error: (e) => {
  //         console.log(e)
  //         this._messageService.add({ severity: 'error',
  //           summary: this.translate.instant('MessageService.Summary.FailedAction'),
  //           detail: this.translate.instant('MessageService.Port.DestinationPortManyRetrieveError') })
  //       },
  //       complete: () => console.info('Destination Ports query successfully')
  //     })
  //   }
  // }

  // getYears() {
  //   let current = new Date().getFullYear()
  //   this.years.push({label: current, value: current})
  //   let next = current + 1
  //   this.years.push({label: next, value: next})
  // }

  // onChangeCountryTo() {
  //   if (this.step1FormGroup.controls['countryId'].value) {
  //     this.selectedCountryTo = this.countriesOrigin.find(x => x.id == this.step1FormGroup.controls['countryId'].value)
  //     if (this.selectedCountryTo) this.loadDestinationPorts()
  //   }
  // }

  // onCancelNewFridgestorage() {
  //   this.step1FormGroup.controls['fridgeStorageId'].setValue(null)
  //   this.loadFridgestorageDialogVisible = false
  //   this.submit = false
  //   this.loading = false
  // }

  // saveFridgestorage() {
  //   this.submit = true
  //   this.loading = true
  //   if(this.fridgestorageFormGroup.valid) {
  //     const newFridgeStorage: NewFridgeStorage = {
  //       name: this.fridgestorageFormGroup.controls["fridgestorageName"].value,
  //       vat: this.fridgestorageFormGroup.controls["fridgestorageVat"].value,
  //       contactName: this.fridgestorageFormGroup.controls["fridgestorageContactName"].value,
  //       contactAddress: this.fridgestorageFormGroup.controls["fridgestorageContactAddress"].value,
  //       contactEmail: this.fridgestorageFormGroup.controls["fridgestorageContactEmail"].value,
  //       contactPhone: this.fridgestorageFormGroup.controls["fridgestorageContactPhone"].value,
  //       contactCity: this.fridgestorageFormGroup.controls["fridgestorageContactCity"].value,
  //       contactCountryId: this.fridgestorageFormGroup.controls["fridgestorageContactCountryId"].value,
  //       storageType: 1
  //     }

  //     this._fridgeStorageService.create(newFridgeStorage).subscribe({
  //       next: (v) => {
  //         this.loadFridgeStorages()
  //         this.loadFridgestorageDialogVisible = false
  //         this._messageService.add({ severity: 'success',
  //           summary: this.translate.instant('MessageService.Summary.SuccessAction'),
  //           detail: this.translate.instant('MessageService.FridgeStorage.CreateSuccess') })
  //         this.loading = false
  //         this.submit = false
  //       },
  //       error: (e) => {
  //         this.loading = false
  //         this.submit = false
  //         console.log(e)
  //         this._messageService.add({ severity: 'error',
  //           summary: this.translate.instant('MessageService.Summary.FailedAction'),
  //           detail: this.translate.instant('MessageService.FridgeStorage.CreateError') });
  //       },
  //       complete: () =>  {
  //         this.loading = false
  //         this.submit = false
  //         console.info('FridgeStorage created successfully')
  //       }
  //     })
  //   }
  //   else {
  //     this.loading = false
  //   }
  // }

  // showStep1() {
  //   this.step = this.step1
  // }

  // showStep2() {
  //   this.submit = true
  //   if(this.step1FormGroup.valid) {
  //     this.submit = false
  //     this.step = this.step2
  //   }
  //   else {
  //     this._messageService.add({ severity: 'error',
  //       summary: this.translate.instant('MessageService.Summary.FailedAction'),
  //       detail: this.translate.instant('MessageService.FormMessages.FormInvalid') });
  //   }
  // }

  // showStep3() {
  //   this.submit = true
  //   if(this.step2FormGroup.valid) {
  //     this.step = this.step3
  //   }
  //   else {
  //     this._messageService.add({ severity: 'error',
  //       summary: this.translate.instant('MessageService.Summary.FailedAction'),
  //       detail: this.translate.instant('MessageService.FormMessages.FormInvalid') });
  //   }
  // }

  // filterPackaging(event: any) {
  //   if (event?.isOther === true) {
  //     this.step2FormGroup.controls['otherPackaging'].enable()
  //   } else {
  //     this.step2FormGroup.controls['otherPackaging'].setValue('')
  //     this.step2FormGroup.controls['otherPackaging'].disable()
  //   }
  //   if (event !== undefined && event !== null) this.packagingSelected[0] = event.id
  // }

  getProgressBar(step: number) {
    return (step / this.totalStep) * 100
  }

  // onChangeColor() {
  //   this.selectedColor = this.colors.find(x => x.value === this.step3FormGroup.controls['color'].value)
  // }

  finish() {
    this.loading = true
    this.submit = true
    if(this.step1FormGroup.valid) {
      const request: EditBasicOffer = {
        importOrderDetailId: this.importOrderDetail?.id,
        varietyId: this.step1FormGroup.controls['varietyId'].value,
        palletQuantity: this.step1FormGroup.controls['palletQuantity'].value,
        CasePerPallets: this.step1FormGroup.controls['caseQuantityPerPallet'].value,
        price: this.step1FormGroup.controls['listPrice'].value,
        incoterms: this.step1FormGroup.controls['incoterms'].value      
      }

      this._importOrderService.updateImportAndDetailMbl(request).subscribe({
        next: (v) => {
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: this.translate.instant('MessageService.ExportOffer.EditSuccess') })
          this.submit = false
          this.loading = false
          setTimeout(() => {
            this._router.navigate([Routes.forwarderImportOrders]);
          }, 1500);
        },
        error: (e) => {
          this.submit = false
          this.loading = false
          console.log(e)
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOffer.EditError') });
        },
        complete: () => console.info('Export Offer update successfully')
      })
    }
    else {
      this._messageService.add({ severity: 'error',
        summary: this.translate.instant('MessageService.Summary.FailedAction'),
        detail: this.translate.instant('MessageService.FormMessages.FormInvalid') })
      this.loading = false
    }
  }

}
