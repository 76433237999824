<app-user-dashboard [userMenuActiveItemIndex]="0" [activeItem]="'forwarderReviews'">
    <div style="margin-top: 30px">
        <p-card [header]="getCardHeader()" [subheader]="getCardSubheader()">
            <div id="step1" *ngIf="step == step1">
                <form [formGroup]="step1FormGroup">
                    <!-- <h2 style="color: #8c2b87;">{{'UserPages.UserExportOffers.NewExportOffer.StepLabel' | translate}} {{step1}} {{'UserPages.UserExportOffers.NewExportOffer.StepOfLabel' | translate}} {{totalStep}}</h2> -->
                    <p-progressBar [value]="getProgressBar(step3)" color="#8c2b87"></p-progressBar>
                    <h2>Sobre lo esencial de tu producto</h2>

                    <div class="p-fluid formgrid mr-2 ml-2 grid" style="margin-top: 40px;">
                        <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
                          <label for="species" style="font-weight: bolder;">{{'UserPages.UserExportOffers.NewExportOffer.SpecieFieldLabel' | translate}} *</label>
                          <p-dropdown id="species"
                            formControlName="speciesId"
                            [options]="species"
                            optionLabel="nameEs"
                            optionValue="id"
                            [filter]="true"
                            [filterBy]="profile?.lang == 'en' ? 'name' : 'nameEs'"
                            [showClear]="true"
                            placeholder="{{'UserPages.UserExportOffers.NewExportOffer.SpecieFieldPlaceholder' | translate}}"
                            (onChange)="loadSpeciesParams()">
                            <ng-template pTemplate="selectedItem">
                              <div class="flex align-items-center gap-2" *ngIf="selectedSpecies">
                                <img [src]="selectedSpecies.imageUrl" style="width: 18px" alt=""/>
                                <div>{{ getSpeciesName(selectedSpecies) }}</div>
                              </div>
                            </ng-template>
                            <ng-template let-specie pTemplate="item">
                                <div class="flex align-items-center gap-2">
                                  <img [src]="specie.imageUrl" style="width: 18px" alt=""/>
                                  <div>{{ getSpeciesName(specie) }}</div>
                                </div>
                            </ng-template>
                          </p-dropdown>
                          <small *ngIf="submit && step1FormGroup.controls['speciesId'].invalid" class="p-error">{{getErrorMessage(step1FormGroup, 'speciesId')}}</small>
                        </div>

                        <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
                            <label for="variety" style="font-weight: bolder;">{{'UserPages.UserExportOffers.NewExportOffer.VarietyFieldLabel' | translate}} *</label>
                            <p-dropdown id="variety"
                              formControlName="varietyId"
                              [options]="varieties"
                              optionLabel="name"
                              optionValue="id"
                              [filter]="true"
                              filterBy="name"
                              [showClear]="true"
                              placeholder="{{'UserPages.UserExportOffers.NewExportOffer.VarietyFieldPlaceholder' | translate}}"
                              (onChange)="onChangeVariety()"
                              emptyMessage="{{'UserPages.UserExportOffers.NewExportOffer.SpecieFieldPlaceholder' | translate}}.">
                              <ng-template pTemplate="selectedItem">
                                <div class="flex align-items-center gap-2" *ngIf="selectedVariety">
                                    <img [src]="selectedVariety.urlIcon" style="width: 18px" alt=""/>
                                    <div>{{ selectedVariety.name }}</div>
                                </div>
                              </ng-template>
                              <ng-template let-variety pTemplate="item">
                                  <div class="flex align-items-center gap-2">
                                      <img [src]="variety.urlIcon" style="width: 18px" alt=""/>
                                      <div>{{ variety.name }}</div>
                                  </div>
                              </ng-template>
                            </p-dropdown>
                            <small *ngIf="submit && step1FormGroup.controls['varietyId'].invalid" class="p-error">{{getErrorMessage(step1FormGroup, 'varietyId')}}</small>
                        </div>

                        <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
                            <label for="caseQuantityPerPallet" style="font-weight: bolder;">{{'UserPages.UserExportOffers.NewExportOffer.CasePerPalletsFieldLabel' | translate}} *</label>
                            <p-inputNumber inputId="caseQuantityPerPallet" formControlName="caseQuantityPerPallet" placeholder="{{'UserPages.UserExportOffers.NewExportOffer.CasePerPalletsFieldPlaceholder' | translate}}"></p-inputNumber>
                            <small *ngIf="submit && step1FormGroup.controls['caseQuantityPerPallet'].invalid" class="p-error">{{getErrorMessage(step1FormGroup, 'caseQuantityPerPallet')}}</small>
                        </div>
                        <div class="field col-12 md:col-3 lg:col-3">
                            <label for="listPrice" style="font-weight: bolder;">{{'UserPages.UserExportOffers.NewExportOffer.ListPriceFieldLabel' | translate}} *</label>
                            <p-inputNumber inputId="listPrice" formControlName="listPrice" mode="currency" currency="USD" locale="en-US" [min]="0" placeholder="{{'UserPages.UserExportOffers.NewExportOffer.ListPriceFieldPlaceholder' | translate}}"></p-inputNumber>
                            <small *ngIf="submit && step1FormGroup.controls['listPrice'].invalid" class="p-error">{{getErrorMessage(step1FormGroup, 'listPrice')}}</small>
                        </div>
                        <div class="field col-12 md:col-3 lg:col-3">
                            <label for="incoterms" style="font-weight: bolder;">{{'UserPages.UserExportOffers.NewExportOffer.IncotermsFieldLabel' | translate}} *</label>
                            <p-dropdown inputId="incoterms"
                              formControlName="incoterms"
                              [options]="incotermsOptions"
                              optionLabel="label"
                              optionValue="value"
                              [showClear]="true"
                              placeholder="{{'UserPages.UserExportOffers.NewExportOffer.IncotermsFieldPlaceholder' | translate}}"></p-dropdown>
                            <small *ngIf="submit && step1FormGroup.controls['incoterms'].invalid" class="p-error">{{getErrorMessage(step1FormGroup, 'incoterms')}}</small>
                        </div>

                        <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
                            <label for="palletQuantity" style="font-weight: bolder;">{{'UserPages.UserExportOffers.NewExportOffer.PalletQuantityFieldLabel' | translate}} *</label>
                            <p-inputNumber inputId="palletQuantity" formControlName="palletQuantity" [useGrouping]="false" placeholder="{{'UserPages.UserExportOffers.NewExportOffer.PalletQuantityFieldPlaceholder' | translate}}"></p-inputNumber>
                            <small *ngIf="submit && step1FormGroup.controls['palletQuantity'].invalid" class="p-error">{{getErrorMessage(step1FormGroup, 'palletQuantity')}}</small>
                        </div>
                    </div>

                    <div class="grid" style="margin-top: 30px;">
                        <div class="col-6"></div>
                        <div class="col-6">
                            <div class="p-fluid formgrid grid">
                                <div class="col-6"></div>
                                <div class="col-6">
                                    <!-- <p-button label="{{'UserPages.UserExportOffers.NewExportOffer.NextButton' | translate}}" (onClick)="showStep2()" [loading]="loading"></p-button> -->
                                    <p-button label="{{'UserPages.UserExportOffers.NewExportOffer.SaveButtonLabel' | translate}}" (onClick)="finish()" [loading]="loading"></p-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </p-card>
    </div>
</app-user-dashboard>

<p-toast></p-toast>