import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ImportOrderDetail, ImportOrderDocument, UploadImportOrderDocumentRequest } from "src/app/domain/models/ImportOrder";
import { Routes } from "src/app/infrastructure/routes";
import { ImportOrderDetailGateway } from "../gateway/ImportOrderDetailGateway";
import { UpdateControlCompanyRequest } from "src/app/domain/models/QCCompany";

@Injectable({providedIn: 'root'})
export class ImportOrderDetailService extends ImportOrderDetailGateway {

  constructor(private _http: HttpClient) {
    super()
  }

  getAllPendingForQCReviews(username: string): Observable<ImportOrderDetail[]> {
    return this._http.get<ImportOrderDetail[]>(Routes.importOrderDetailApi + '/GetAllPendingForQCReviews', {params: {username}})
  }

  getAllReviewedOriginQCReviews(username: string): Observable<ImportOrderDetail[]> {
    return this._http.get<ImportOrderDetail[]>(Routes.importOrderDetailApi + '/GetAllReviewedOriginQCReviews', {params: {username}})
  }

  getInstructiveDocument(detailId: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type':  'application/octet-stream'
    })

    return this._http.get<any>(Routes.importOrderDetailApi + '/GenerateInstructive', {params: {detailId}, headers: headers, observe: 'response', responseType: 'blob' as 'json'})
  }

  getInstructiveDocumentPdf(detailId: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type':  'application/pdf'
    })

    return this._http.get<any>(Routes.importOrderDetailApi + '/GenerateInstructivePdf', {params: {detailId}, headers: headers, observe: 'response', responseType: 'blob' as 'json'})
  }

  getQcPayment(detailId: string):Observable<ImportOrderDetail>
  {
    return this._http.get<ImportOrderDetail>(Routes.importOrderDetailApi + '/GetQCPayment', {params: {detailId}});
  }

  updateQcCompany(updateQcCompany: UpdateControlCompanyRequest): Observable<any>
  {
    return this._http.put<any>(Routes.importOrderDetailApi + '/UpdateQcCompany', updateQcCompany);
  }

  removeQcPayment(importOrderDetailId: any): Observable<any>
  {
    return this._http.put<any>(Routes.importOrderDetailApi + '/RemoveQcPayment', {}, {params: {importOrderDetailId}})
  }
}
