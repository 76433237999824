<app-user-dashboard [userMenuActiveItemIndex]="0" [activeItem]="'export-offers'">
  <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="breadcrumbHomeItem"></p-breadcrumb>
  <div style="margin-top: 30px">
    <p-card>
      <div>
        <h2>{{'UserPages.UserExportOffers.EditExportOffer.PageTitle6' | translate}}</h2>
        <p>{{'UserPages.UserExportOffers.EditExportOffer.PageSubtitle5' | translate}}</p>
        <app-export-offer-product-certificates-crud [exportOfferId]="exportOfferId"></app-export-offer-product-certificates-crud>
      </div>
      <div style="margin-top: 50px;">
        <h2>{{'UserPages.UserExportOffers.NewExportOffer.PageTitle6' | translate}}</h2>
        <p>{{'UserPages.UserExportOffers.NewExportOffer.PageSubtitle6' | translate}}</p>
        <app-export-offer-documents-crud [exportOfferId]="exportOfferId"></app-export-offer-documents-crud>
      </div>
    </p-card>
  </div>
</app-user-dashboard>
