
<div>
    <p-button label="{{'RequestImportModal.RequestProgramText' | translate}}"  [style]="{'color': '#8c2b87'}" [loading]="loading" styleClass="p-button-outlined" (click)="visibleRequestProgram=true"></p-button>
</div>
<p-dialog header="{{'RequestImportModal.requestProgramQuestionText' | translate}}" [(visible)]="visibleRequestProgram" [style]="{width: '1000px'}" [modal]="true">
    <form [formGroup]="importRequestFormGroup">
      <div class="p-fluid formgrid mr-2 ml-2 grid"> 

          <div class="field col-12 md:col-3 lg:col-3" style="margin-top: 20px;">
            <label for="consigneeId" style="font-weight: bolder; font-size: 12px;">{{'DirectSaleFormPage.ConsigneeFieldLabel' | translate}} *</label>
            <p-dropdown id="consigneeId"
              formControlName="consigneeId"
              [options]="consignees"
              optionValue="id"
              optionLabel="companyName"
              [filter]="true"
              filterBy="companyName"
              [showClear]="false"
              class="p-inputtext-sm"
              placeholder="{{'DirectSaleFormPage.ConsigneeFieldLabel' | translate}}">
              <ng-template let-consignee pTemplate="consignee">
                <div class="flex align-items-center gap-2" style="font-size: 12px;">
                          {{consignee.companyName}}
                </div>
              </ng-template>
            </p-dropdown>
            <small *ngIf="submit && importRequestFormGroup.controls['consigneeId'].invalid" class="p-error">{{'consigneeId' | formErrorMessage: importRequestFormGroup}}</small>
          </div>
          <div class="field col-12 md:col-3 lg:col-3" style="margin-top: 20px;">
            <label for="notifyId" style="font-weight: bolder; font-size: 12px;">{{'DirectSaleFormPage.NotifyFieldLabel' | translate}} *</label>
            <p-dropdown id="notifyId"
              formControlName="notifyId"
              [options]="notifies"
              optionValue="id"
              optionLabel="companyName"
              [filter]="true"
              appendTo="body"
              filterBy="companyName"
              [showClear]="false"
              class="p-inputtext-sm"
              placeholder="{{'DirectSaleFormPage.NotifyFieldLabel' | translate}}">
              <ng-template let-notify pTemplate="notify">
                <div class="flex align-items-center gap-2" style="font-size: 12px;">
                          {{notify.companyName}}
                </div>
              </ng-template>
            </p-dropdown>
            <small *ngIf="submit && importRequestFormGroup.controls['notifyId'].invalid" class="p-error">{{'notifyId' | formErrorMessage: importRequestFormGroup}}</small>
          </div>
          <div class="field col-12 md:col-3 lg:col-3" style="margin-top: 20px;">
            <label for="countryToId" style="font-weight: bolder; font-size: 12px;">{{'MarketplacePage.Spot.ImportToFieldPlaceholder' | translate}} *</label>
            <p-dropdown
              id="countryToId"
              [options]="destinationCountries"
              formControlName="countryToId"
              optionLabel="name"
              optionValue="id"
              appendTo="body"
              (onChange)="loadDestinationPorts()"
              [filter]="true"
              filterBy="name"
              [showClear]="false"
              placeholder="{{'MarketplacePage.Spot.ImportToFieldPlaceholder' | translate}}"
              class="p-inputtext-sm">
              <ng-template pTemplate="selectedItem">
                <div class="flex align-items-center gap-2" *ngIf="selectedCountryTo">
                  <img [src]="'../../../../assets/img/flags/' + selectedCountryTo.code + '.png'" style="width: 18px" alt="flag"/>
                  <div style="font-size: 12px;">{{ selectedCountryTo | countryDescription }}</div>
                </div>
              </ng-template>
              <ng-template let-country pTemplate="item">
                <div class="flex align-items-center gap-2">
                  <img [src]="'../../../../assets/img/flags/' + country.code + '.png'" style="width: 18px" alt="flag"/>
                  <div style="font-size: 12px;"> {{ country | countryDescription }}</div>
                </div>
              </ng-template>
            </p-dropdown>
          </div>
          <div class="field col-12 md:col-3 lg:col-3" style="margin-top: 20px;">
            <label for="portToId" style="font-weight: bolder; font-size: 12px;">{{'MarketplacePage.Spot.ToPortFieldLabel' | translate}} *</label>
            <p-dropdown
              id="portToId"
              [options]="destinationPorts"
              formControlName="portToId"
              optionLabel="name"
              optionValue="id"
              [filter]="true"
              filterBy="name"
              [showClear]="false"
              placeholder="{{'MarketplacePage.Spot.ToPortFieldLabel' | translate}}"
              emptyMessage="{{'MarketplacePage.Spot.EmptyResultsText' | translate}}"
              class="p-inputtext-sm">
              <ng-template let-port pTemplate="item">
                <div style="font-size: 12px;"> {{port.name}}</div>
            </ng-template>
            </p-dropdown>
          </div>
          <div class="col-12 md:col-3 lg:col-3 flex justify-content-start gap-1" style="margin-top: 20px;">
            <div class="field">
              <label style="font-weight: bolder; font-size: 12px;" for="startWeek">{{'DirectSaleFormPage.StartWeekFieldLabel' | translate}} *</label>
              <p-inputNumber styleClass="p-inputtext-sm" id="startWeek" formControlName="startWeek" [showButtons]="true" [useGrouping]="false" [min]="startWeekMin" [max]="startWeekMax" placeholder="{{'DirectSaleFormPage.StartWeekFieldLabel' | translate}}"/>
              <small *ngIf="submit && importRequestFormGroup.controls['startWeek'].invalid" class="p-error">{{'startWeek' | formErrorMessage: importRequestFormGroup}}</small>
              <small *ngIf="submit && importRequestFormGroup.getError('endWeekRangeError')" class="p-error">{{'MessageService.ExportOffer.ClosedSaleWeekRangeError' | translate}}</small>
            </div>
                <p-button   styleClass="p-button-sm" (click)="op.toggle($event)" icon="pi pi-calendar" [style]="{'margin-top': '65%' }"/>
                <p-overlayPanel #op 
                  [style]="{ width: '350px' }"
                  >
                    <p-calendar 
                    class="max-w-full"
                    [inline]="true"
                    formControlName="startDate"
                    [firstDayOfWeek]="1"
                    [showWeek]="true"
                    [style]="{'width':'100%'}"
                    (onSelect)="onChangeStartDate($event)"
                    ></p-calendar>        
                </p-overlayPanel>
          </div>
  
          <div class="field col-12 md:col-3 lg:col-3" style="margin-top: 20px;">
            <label style="font-weight: bolder;font-size: 12px;" for="startWeekYear">{{'DirectSaleFormPage.StartYearFieldLabel' | translate}} *</label>
            <p-dropdown onchange="" 
            id="startWeekYear" 
            formControlName="startWeekYear" 
            [options]="years" 
            optionLabel="label" 
            optionValue="value"
            class="p-inputtext-sm" 
            placeholder="{{'DirectSaleFormPage.StartYearFieldLabel' | translate}}"></p-dropdown>
            <small *ngIf="submit && importRequestFormGroup.controls['startWeekYear'].invalid" class="p-error">{{'startWeekYear' | formErrorMessage: importRequestFormGroup}}</small>
            <small *ngIf="submit && importRequestFormGroup.getError('endWeekYearRangeError')" class="p-error">{{'MessageService.ExportOffer.ClosedSaleYearRangeError' | translate}}</small>
          </div>
          <div class="col-12 md:col-3 lg:col-3 flex justify-content-start gap-1" style="margin-top: 20px;">

              <div class="field">
                <label style="font-weight: bolder; font-size: 12px;" for="endWeek">{{'DirectSaleFormPage.EndWeekFieldLabel' | translate}}</label>
                <p-inputNumber styleClass="p-inputtext-sm" id="endWeek" formControlName="endWeek" [showButtons]="true" 
                [useGrouping]="false" [min]="endWeekMin" [max]="endWeekMax" placeholder="{{'DirectSaleFormPage.EndWeekFieldLabel' | translate}}"
                [style]="{}"
                />
                <small *ngIf="submit && importRequestFormGroup.getError('endWeekRangeError')" class="p-error">{{'MessageService.ExportOffer.ClosedSaleWeekRangeError' | translate}}</small>
              </div>
                <p-button 
                [style]="{'background-color': '#8c2b87'}" 
                styleClass="p-button-sm" 
                (click)="op1.toggle($event)" 
                icon="pi pi-calendar"  
                [style]="{'margin-top': '65%'}"/>
                <p-overlayPanel #op1 
                  [style]="{ width: '350px' }"
                  >
                    <p-calendar 
                    class="max-w-full"
                    [inline]="true"
                    [firstDayOfWeek]="1"
                    formControlName="endDate"
                    [showWeek]="true"
                    [style]="{'width':'100%'}"
                    (onSelect)="onChangeEndDate($event)"
                    ></p-calendar>        
                </p-overlayPanel>
          </div>
          <div class="field col-12 md:col-3 lg:col-3" style="margin-top: 20px;">
            <label style="font-weight: bolder; font-size: 12px;" for="endWeekYear">{{'DirectSaleFormPage.EndYearFieldLabel' | translate}}</label>
            <p-dropdown id="endWeekYear" 
            formControlName="endWeekYear" 
            [options]="years" 
            optionLabel="label" 
            optionValue="value"
            class="p-inputtext-sm" 
            placeholder="{{'DirectSaleFormPage.EndYearFieldLabel' | translate}}"></p-dropdown>
            <small *ngIf="submit && importRequestFormGroup.getError('endWeekYearRangeError')" class="p-error">{{'MessageService.ExportOffer.ClosedSaleYearRangeError' | translate}}</small>
          </div>
          <div class="field col-12 md:col-3 lg:col-3" style="margin-top: 20px;">
            <label style="font-weight: bolder; font-size: 12px;" for="containersPerWeek">{{'DirectSaleFormPage.ContainersPerWeekFieldLabel' | translate}} *</label>
            <p-inputNumber styleClass="p-inputtext-sm" id="containersPerWeek" formControlName="containersPerWeek" [showButtons]="false" [useGrouping]="false" [min]="1" [max]="100000" placeholder="{{'DirectSaleFormPage.ContainersPerWeekFieldLabel' | translate}}"/>
            <small *ngIf="submit && importRequestFormGroup.controls['containersPerWeek'].invalid" class="p-error">{{'containersPerWeek' | formErrorMessage: importRequestFormGroup}}</small>
          </div>
          <div class="field col-12 md:col-3 lg:col-3" style="margin-top: 20px;">
            <label style="font-weight: bolder; font-size: 12px;" for="palletsQuantity">{{'RequestImportModal.PalletsQuantity' | translate}} *</label>
            <p-inputNumber styleClass="p-inputtext-sm" id="palletsQuantity" formControlName="palletsQuantity" [showButtons]="false" [useGrouping]="false" [min]="1" [max]="100000" placeholder="Cantidad de pallets"/>
            <small *ngIf="submit && importRequestFormGroup.controls['palletsQuantity'].invalid" class="p-error">{{'palletsQuantity' | formErrorMessage: importRequestFormGroup}}</small>
          </div>
      </div>
    </form>
    <div style="margin-top: 50px;" class="flex justify-content-end gap-2">
        <p-button [style]="{'background-color': '#8c2b87'}"  styleClass="p-button-sm" label="{{'RequestImportModal.CancelButtonLabel' | translate}}" (click)="visibleRequestProgram=false">
        </p-button>
        <p-button 
        [style]="{'color': '#8c2b87'}" label="{{'RequestImportModal.RequestProgramText' | translate}}" 
        [loading]="loading" styleClass="p-button-outlined p-button-sm" 
        (click)="requestProgram()">
        </p-button>
    </div>
   </p-dialog>
