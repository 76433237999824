import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { GenericOrderDetailGateway } from 'src/app/application/gateway/GenericOrderDetailGateway';
import { ImportOrderDetailGateway } from 'src/app/application/gateway/ImportOrderDetailGateway';
import { QualityControlReviewGateway } from 'src/app/application/gateway/QualityControlReviewGateway';
import { GenericOrderDetail, ImportOrderDetail } from 'src/app/domain/models/ImportOrder';
import { QualityControlReviewItemList } from 'src/app/domain/models/QualityControlReview';
import { Species } from 'src/app/domain/models/Species';
import { BasicUser } from 'src/app/domain/models/User';
import { Routes } from 'src/app/infrastructure/routes';
import { SpeciesUtils } from 'src/app/ui/util/species-utils';

@Component({
  selector: 'app-qc-user-origin-reviewed',
  templateUrl: './qc-user-origin-reviewed.component.html',
  styleUrls: ['./qc-user-origin-reviewed.component.css'],
  providers: [MessageService]
})
export class QcUserOriginReviewedComponent implements OnInit {
  loading: boolean = false

  profile: BasicUser | undefined
  reviews: GenericOrderDetail[] = []
  reviews2: QualityControlReviewItemList[] = []

  tabMenuItems: MenuItem[] = []
  activeItem: MenuItem = {}

  constructor(private _router: Router,
    private _messageService: MessageService,
    private _authService: AuthGateway,
    public translate: TranslateService,
    private _importOrderDetailService: ImportOrderDetailGateway,
    private _genericOrderDetailSercice: GenericOrderDetailGateway,
    private _qualityControlReviewService: QualityControlReviewGateway,
  ) {

  }

  ngOnInit(): void {
    this.profile = this._authService.getUser()

    this.tabMenuItems = [
      { label: 'Pending', command: () =>  this._router.navigate(['/'+Routes.qcUserReviews]) },
      { label: 'Reviewed', command: () =>  this._router.navigate(['/'+Routes.qcUserOriginReviewed]) }
    ]
    this.activeItem = this.tabMenuItems[1]

    this.loadOriginReviews2()
  }

  loadOriginReviews() {
    this.loading = true
    this.reviews = []
    this._genericOrderDetailSercice.getAllReviewedOriginQCReviews().subscribe({
      next: (v) => {
        console.log(v)
        this.reviews = v
        this.loading = false
      },
      error: (e) => {
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: 'Could not retrieve the import order details' });

        this.loading = false
        console.log(e)
      },
      complete: () =>  {
        this.loading = false
        console.info('Reviews query successfully')
      }
    })
  }

  loadOriginReviews2() {
    this._qualityControlReviewService.getAllByStatusAndReviewType(2, 1).subscribe({
      next: (v) => {
        console.log(v)
        this.reviews2 = v
        this.loading = false
      },
      error: (e) => {
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: 'Could not retrieve the import order details' });

        this.loading = false
        console.log(e)
      },
      complete: () =>  {
        this.loading = false
        console.info('Reviews query successfully')
      }
    })
  }

  goToOriginReview(review: QualityControlReviewItemList) {
    if(!review.isFrozenFruit)
      this._router.navigate(['/'+Routes.qcUserOriginReview, review.guid])
    else
      this._router.navigate(['/'+Routes.qcUserFrozenFruitOriginReviewPage, review.guid])
  }

  goToReviewDocuments(review: QualityControlReviewItemList) {
    this._router.navigate(['/'+Routes.qcUserReviewDocuments, review.guid])
  }
}
