<app-admin-dashboard>
    <p-card>
        <ng-template pTemplate="header">
            <div class="flex justify-content-between flex-wrap align-items-center" style="padding: 15px;">
                <h2>Crear Forwarder</h2>
            </div>
        </ng-template>
        <ng-template pTemplate="body">
            <div id="form" *ngIf="!success">
                <form [formGroup]="formGroup">
                    <p-fieldset legend="Información de empresa">
                        <div class="p-fluid formgrid grid">
                            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
                                <label for="companyName" style="font-weight: bold;">Nombre de Forwarder *</label>
                                <input id="companyName" formControlName="companyName" type="text" required pInputText placeholder="Nombre de contacto"/>
                                <small *ngIf="submit && formGroup.controls['companyName'].invalid" class="p-error">{{getErrorMessage('companyName')}}</small>
                            </div>
                            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
                                <label for="country" style="font-weight: bold;">País *</label>
                                <p-dropdown id="country"
                                    formControlName="countryId"
                                    [options]="countries"
                                    optionValue="id"
                                    optionLabel="name"
                                    [filter]="true"
                                    filterBy="name"
                                    [showClear]="true"
                                    placeholder="Seleccione un país"
                                    (onChange)="onChangeCountry()">
                                    <ng-template pTemplate="selectedItem">
                                        <div class="flex align-items-center gap-2" *ngIf="selectedCountry">
                                            <img [src]="'../../../../assets/img/flags/' + selectedCountry.code + '.png'" style="width: 18px" alt=""/>
                                            <div>{{ selectedCountry | countryDescription }}</div>
                                        </div>
                                    </ng-template>
                                    <ng-template let-country pTemplate="item">
                                        <div class="flex align-items-center gap-2">
                                            <img [src]="'../../../../assets/img/flags/' + country.code + '.png'" style="width: 18px" alt=""/>
                                            <div>{{ country | countryDescription }}</div>
                                        </div>
                                    </ng-template>
                                </p-dropdown>
                                <small *ngIf="submit && formGroup.controls['countryId'].invalid" class="p-error">{{getErrorMessage('countryId')}}</small>
                            </div>
                            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
                                <label for="contactName" style="font-weight: bold;">Nombre de contacto *</label>
                                <input id="contactName" formControlName="contactName" type="text" required pInputText placeholder="Nombre de contacto"/>
                                <small *ngIf="submit && formGroup.controls['contactName'].invalid" class="p-error">{{getErrorMessage('contactName')}}</small>
                            </div>
                            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
                                <label for="contactLastName" style="font-weight: bold;">Apellido de contacto *</label>
                                <input id="contactLastName" formControlName="contactLastName" type="text" required pInputText placeholder="Apellido de contacto"/>
                                <small *ngIf="submit && formGroup.controls['contactLastName'].invalid" class="p-error">{{getErrorMessage('contactLastName')}}</small>
                            </div>
                            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
                                <label for="contactPhone" style="font-weight: bold;">Teléfono de contacto *</label>
                                <input id="contactPhone" formControlName="contactPhone" type="text" required pInputText placeholder="Teléfono de contacto"/>
                                <small *ngIf="submit && formGroup.controls['contactPhone'].invalid" class="p-error">{{getErrorMessage('contactPhone')}}</small>
                            </div>
                            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
                                <label for="contactEmail" style="font-weight: bold;">Correo electrónico *</label>
                                <input id="contactEmail" formControlName="contactEmail" type="text" required pInputText placeholder="Correo electrónico de contacto"/>
                                <small *ngIf="submit && formGroup.controls['contactEmail'].invalid" class="p-error">{{getErrorMessage('contactEmail')}}</small>
                            </div>
                            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
                                <label for="profile" style="font-weight: bold;">Administrador *</label>
                                <p-dropdown id="profile"
                                    formControlName="profileId"
                                    [options]="profiles"
                                    optionValue="id"
                                    optionLabel="fullname"
                                    [filter]="true"
                                    filterBy="name"
                                    [showClear]="true"
                                    placeholder="Seleccione un administrador"
                                    (onChange)="onChangeProfile($event)">
                                </p-dropdown>
                                <small *ngIf="submit && formGroup.controls['profileId'].invalid" class="p-error">{{getErrorMessage('profileId')}}</small>
                            </div>
                        </div>
                    </p-fieldset>
                    <div class="grid" style="padding: 20px;">
                        <div class="col-6 col-offset-6">
                            <div class="grid p-fluid">
                                <div class="col-12 md:col-6 lg:col-6">
                                    <p-button label="Regresar" (onClick)="goToBack()" [loading]="loading" styleClass="p-button-outlined"></p-button>
                                </div>
                                <div class="col-12 md:col-6 lg:col-6">
                                    <p-button [loading]="loading" label="Guardar" (onClick)="createCompany()"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div id="success" *ngIf="success">
                <p>Se ha creado el Forwarder con éxito.</p>
                <p-button label="Aceptar" (onClick)="goToBack()"></p-button>
            </div>
        </ng-template>
    </p-card>
</app-admin-dashboard>

<p-toast></p-toast>