import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { DocumentServiceGateway } from 'src/app/application/gateway/DocumentServiceGateway';
import { QualityControlReviewGateway } from 'src/app/application/gateway/QualityControlReviewGateway';
import { QualityControlReview, UploadQualityControlReviewPdfRequest } from 'src/app/domain/models/QualityControlReview';
import { Routes } from 'src/app/infrastructure/routes';

@Component({
  selector: 'app-admin-qc-review-documents',
  templateUrl: './admin-qc-review-documents.component.html',
  styleUrls: ['./admin-qc-review-documents.component.css'],
  providers: [MessageService]
})
export class AdminQcReviewDocumentsComponent implements OnInit {

  reviewId: string | undefined
  review: QualityControlReview | undefined

  loading: boolean = false
  documents: any

  constructor(private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _messageService: MessageService,
    public translate: TranslateService,
    private _qualityControlReviewService: QualityControlReviewGateway,
    private _documentService: DocumentServiceGateway) {

    this._activatedRoute.params.subscribe(params => {
      this.reviewId = params['reviewId']
    })
  }

  ngOnInit(): void {
    this.loadReview()
  }

  loadReview() {
    this.loading = true
    if(this.reviewId) {
      this._qualityControlReviewService.getByIdFreshFruit(this.reviewId).subscribe({
        next: (v) => {
          console.log(v)
          this.review = v
          this.loading = false
        },
        error: (e) => {
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: 'Could not retrieve the import order detail' });

          this.loading = false
          console.log(e)
        },
        complete: () =>  {
          this.loading = false
          console.info('Import order detail query successfully')
        }
      })
    }
  }

  onSelectReviewDocumentPdf(event: any) {
    if(this.review) {
      let request: UploadQualityControlReviewPdfRequest = {
        qualityControlReviewGuid: this.review.guid,
        pdf: event.files[0]
      }

      this._qualityControlReviewService.uploadOriginQCReviewPdf(request).subscribe({
        next: (v) => {
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: 'Review pdf file saved successfully.' })
        },
        error: (e) => {
          console.log(e)
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: 'Can not save pdf file.' })
        },
        complete: () =>  {
          this.loading = false
          console.info('QCReviewPdf uploaded successfully')
        }
      })
    }
  }

  onSelectConsolidationDocumentPdf(event: any) {
    if(this.review) {
      let request: UploadQualityControlReviewPdfRequest = {
        qualityControlReviewGuid: this.review.guid,
        pdf: event.files[0]
      }

      this._qualityControlReviewService.uploadOriginQCConsolidationPdf(request).subscribe({
        next: (v) => {
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: 'Consolidation pdf file saved successfully.' })
        },
        error: (e) => {
          console.log(e)
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: 'Can not save pdf file.' })
        },
        complete: () =>  {
          this.loading = false
          console.info('QCReviewPdf uploaded successfully')
        }
      })
    }
  }

  viewOriginQCReviewDocument() {
    if(this.review) {
      this._documentService.getOriginQCReviewDocument(this.review.guid).subscribe((resultBlob: any) => {
        const downloadURL = window.URL.createObjectURL(resultBlob.body);
        window.open(downloadURL);})
    }
  }

  viewOriginQCConsolidationDocument() {
    if(this.review) {
      this._documentService.getOriginQCConsolidationDocument(this.review.guid).subscribe((resultBlob: any) => {
        const downloadURL = window.URL.createObjectURL(resultBlob.body);
        window.open(downloadURL);})
    }
  }

  goToReviews() {
    this._router.navigate(['/' + Routes.adminManageQCReviews])
  }
}
