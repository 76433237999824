import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { CountryGateway } from 'src/app/application/gateway/CountryGateway';
import { ExportOfferGateway } from 'src/app/application/gateway/ExportOfferGateway';
import { FridgeStorageGateway } from 'src/app/application/gateway/FridgeStorageGateway';
import { PackagingGateway } from 'src/app/application/gateway/PackagingGateway';
import { ProductCertificateTypeGateway } from 'src/app/application/gateway/ProductCertificateTypeGateway';
import { ProfileGateway } from 'src/app/application/gateway/ProfileGateway';
import { SpeciesGateway } from 'src/app/application/gateway/SpeciesGateway';
import { VarietyGateway } from 'src/app/application/gateway/VarietyGateway';
import { Country } from 'src/app/domain/models/Country';
import { BasicExportOffer, ExportOfferCreateRequest, UpdatePicturesAndCertificateTypesRequest } from 'src/app/domain/models/ExportOffer';
import { FridgeStorage, NewFridgeStorage } from 'src/app/domain/models/FridgeStorage';
import { Packaging } from 'src/app/domain/models/Packaging';
import { PaymentAgainstBL } from 'src/app/domain/models/Payment';
import { Species, SpeciesSize } from 'src/app/domain/models/Species';
import { BasicUser } from 'src/app/domain/models/User';
import { Variety } from 'src/app/domain/models/Variety';
import { Routes } from 'src/app/infrastructure/routes';
import { ExportOfferCertificateTypeComponent } from 'src/app/ui/components/export-offer/export-offer-certificate-type/export-offer-certificate-type.component';
import { ExportOfferPictureUploaderComponent } from 'src/app/ui/components/export-offer/export-offer-picture-uploader/export-offer-picture-uploader.component';
import { ErrorMessageUtil } from 'src/app/ui/util/error-message-util';
import { ExportOfferUtils } from 'src/app/ui/util/export-offer-utils';
import { formLimit } from 'src/app/ui/util/form-limit';
import { SpeciesUtils } from 'src/app/ui/util/species-utils';

@Component({
  selector: 'app-new-export-offer',
  templateUrl: './new-export-offer.component.html',
  styleUrls: ['./new-export-offer.component.css'],
  providers: [MessageService]
})
export class NewExportOfferComponent implements OnInit {
  exportOfferId: any | undefined
  exportOffer: BasicExportOffer | undefined

  breadcrumbItems: MenuItem[] = []
  breadcrumbHomeItem: MenuItem = {}

  submit: boolean = false
  submitStep2: boolean = false
  loading: boolean = false
  loadFridgestorageDialogVisible: boolean = false
  productCertificateDialogVisible: boolean = false
  documentDialogVisible: boolean = false

  profile: BasicUser | undefined

  species: Species[] = []
  varieties: Variety[] = []
  selectedSpecies: Species | undefined
  selectedVariety: Variety | undefined
  selectedMarketCountry1: Country | undefined
  selectedMarketCountry2: Country | undefined
  selectedMarketCountry3: Country | undefined
  selectedColor: any | undefined

  colors: any[] = []
  sizes: SpeciesSize[] = []
  qualityControlOptions: any[] = []
  boxTypes: any[] = []
  kilosPerBoxes: any[] = []
  basePallets: any[] = []
  marksOptions: any[] = []
  countries: Country[] = []

  productCertificates: any[] = []
  certificateTypes: any[] = []
  selectedCertifications: number[] = []
  fridgeStorages: FridgeStorage[] = []
  fridgeStorageOptions: any[] = []
  documents: any[] = []

  paymentBLItems: any[] = []

  incotermsOptions: any[] = []

  step: number = 1
  step1: number = 1
  step2: number = 2
  step3: number = 3
  finalStep: number = 4

  uploadedImages: any[] = []

  packagingList: Packaging[] = []
  packagingSelected: number[] = []

  paymentsAgaintsBLItems: PaymentAgainstBL[] = []

  step1FormGroup: FormGroup = this._formBuilder.group({
    speciesId: [null, Validators.required],
    varietyId: [null, Validators.required],
    palletQuantity: [null, Validators.required],
    availablePallets: [null],
    fridgeStorageId: [null, Validators.required],
    listPrice: [null, Validators.required],
    caseQuantityPerPallet: [null, Validators.required],
    incoterms: ['EXW', Validators.required],
    paymentAgainstBL: [100, Validators.required]
  })

  step2FormGroup: FormGroup = this._formBuilder.group({
    harvestDate: [null, Validators.required],
    shelfLife: [null, Validators.required],
    netKilos: ['', Validators.required],
    grossKilos: ['', Validators.required],
    speciesSize: ['', Validators.required],
    readyToLoadDate: [null, Validators.required],
    marketCountry1Id: [null, Validators.required],
    marketCountry2Id: [null],
    marketCountry3Id: [null],
    packagings: ['', Validators.required],
    otherPackaging: [{value: '', disabled: true}],
    category: ['', Validators.required]
  })

  formGroup: FormGroup = this._formBuilder.group({
    speciesId: ['', Validators.required],
    varietyId: ['', Validators.required],
    palletQuantity: [null, Validators.required],
    fridgeStorageId: ['', Validators.required],
    listPrice: [null, Validators.required],
    caseQuantityPerPallet: [null, Validators.required],
    incoterm: ['', Validators.required],
    color: [''],
    speciesSize: [''],
    harvestDate: [null, Validators.required],
    readyToLoadDate: [null],
    shelfLife: [null, Validators.required],
    boxType: ['', Validators.required],
    caseSize: [''],
    kilosPerBox: [''],
    netKilos: ['', Validators.required],
    grossKilos: ['', Validators.required],
    packagings: [''],
    isQualityControl: [false],
    qualityControlDate: [null],
    basePallets: [''],
    marks: [false],
    marketCountry1Id: ['', Validators.required],
    marketCountry2Id: [''],
    marketCountry3Id: [''],
    sizeMillimeters: [null]
  })

  fridgestorageFormGroup: FormGroup = this._formBuilder.group({
    fridgestorageName: [null, Validators.required],
    fridgestorageVat: [null],
    fridgestorageContactName: [null, Validators.required],
    fridgestorageContactAddress: [null, Validators.required],
    fridgestorageContactCity: [null, Validators.required],
    fridgestorageContactEmail: [null, [Validators.required, Validators.email]],
    fridgestorageContactPhone: [null, Validators.required],
    fridgestorageContactCountryId: [null, Validators.required]
  })

  productCertificatePdfFile: any
  productCertificateFormGroup: FormGroup = this._formBuilder.group({
    name: ['', Validators.required],
    documentIssueDate: [null],
    expirationDate: [null],
    productCertificateTypeId: ['', Validators.required]
  })

  documentPdfFile: any
  documentFormGroup: FormGroup = this._formBuilder.group({
    name: ['', Validators.required]
  })

  formLimit = formLimit

  constructor(private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _messageService: MessageService,
    private _authService: AuthGateway,
    private _profileService: ProfileGateway,
    private _formBuilder: FormBuilder,
    private _varietyService: VarietyGateway,
    private _speciesService: SpeciesGateway,
    private _fridgeStorageService: FridgeStorageGateway,
    private _exportOfferService: ExportOfferGateway,
    private _productCertificateTypeService: ProductCertificateTypeGateway,
    private _countryService: CountryGateway,
    private _packagingService: PackagingGateway,
    public translate: TranslateService) {

      this._activatedRoute.params.subscribe(params => {
        if(params['exportOfferId'])
          this.exportOfferId = params['exportOfferId']
      })
  }

  ngOnInit(): void {
    this.profile = this._authService.getUser()

    let breadcrumbMenuItemLabel1 = 'Export offer'
    let breadcrumbMenuItemLabel2 = 'Upload offer pictures'
    let breadcrumbHomeMenuItemLabel = 'My export offers'

    if(this.profile) {
      switch(this.profile.lang) {
        case 'en':
          breadcrumbMenuItemLabel1 = 'Upload new offer'
          breadcrumbMenuItemLabel2 = 'Upload offer pictures'
          breadcrumbHomeMenuItemLabel = 'My export offers'
          break
        case 'es':
          breadcrumbMenuItemLabel1 = 'Oferta de exportación'
          breadcrumbMenuItemLabel2 = 'Cargar imágenes de producto'
          breadcrumbHomeMenuItemLabel = 'Mis ofertas de exportación'
          break
      }
    }

    this.breadcrumbItems = [{ label: breadcrumbMenuItemLabel1, disabled: true}]
    this.breadcrumbHomeItem = { label: breadcrumbHomeMenuItemLabel, routerLink: '/' + Routes.userExportOffers };

    if(this.profile) {
      this.colors = ExportOfferUtils.getColors(this.profile.lang)
      this.boxTypes = ExportOfferUtils.getBoxTypes(this.profile.lang)
      this.kilosPerBoxes = ExportOfferUtils.getKilosPerBox()
      this.basePallets = ExportOfferUtils.getBasePallets(this.profile.lang)
      this.qualityControlOptions = ExportOfferUtils.getControlQualityOptions(this.profile.lang)
      this.marksOptions = ExportOfferUtils.getMarksOptions(this.profile.lang)
      this.incotermsOptions = ExportOfferUtils.getIncotermsOptions()
    }

    if(this.exportOfferId) {
      this.getExportOffer()
    }
    else {
      this.loadSpecies()
      this.loadProductCertificateTypes()
      this.loadFridgeStorages()
      this.loadCountries()
      this.loadPackagings()
    }

    this.getPaymentsBLItems()

    // // Obtener el parámetro adicional de la URL
    // const additional = this._activatedRoute.snapshot.paramMap.get('additional');
    // // Verificar si el parámetro existe y es igual a 'true'
    // if (additional === 'true') {
    //   // Lógica para mostrar el mensaje final
    //   this.step = this.finalStep
    // }
  }

  loadPackagings() {
    this._packagingService.getAll().subscribe({
      next: (v) => {
        this.packagingList = v
        this.loading = false
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Packaging.ManyRetrieveError') })
      },
      complete: () => console.info('Packaging query successfully')
    })
  }

  getSpeciesName(species: Species): string {
    if(this.profile)
      return SpeciesUtils.getSpeciesName(this.profile.lang, species)
    return ''
  }

  getExportOffer() {
    if(this.exportOfferId) {
      this.loading = true
      this._exportOfferService.getBasicExportOffer(this.exportOfferId).subscribe({
        next: (v) => {
          console.log(v)
          this.exportOffer = v
          this.loadForm()
        },
        error: (e) => {
          console.log(e)
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOffer.SingleRetrieveError') });
        },
        complete: () => console.info('ExportOffer query successfully')
      })
    }
  }

  loadForm() {
    if(this.exportOffer) {
      this.step1FormGroup.controls['availablePallets'].setValidators(Validators.required)
      this.step1FormGroup.controls['speciesId'].disable()
      this.step1FormGroup.controls['palletQuantity'].setValue(this.exportOffer.palletQuantity)
      this.step1FormGroup.controls['availablePallets'].setValue(this.exportOffer.availablePallets)
      this.step1FormGroup.controls['fridgeStorageId'].setValue(this.exportOffer.fridgeStorageId)
      this.step1FormGroup.controls['listPrice'].setValue(this.exportOffer.listPrice)
      this.step1FormGroup.controls['caseQuantityPerPallet'].setValue(this.exportOffer.caseQuantityPerPallet)
      this.step1FormGroup.controls['incoterms'].setValue(this.exportOffer.typeSale)
      this.getPaymentsBLItems()
      this.step2FormGroup.controls['harvestDate'].setValue(this.exportOffer.harvestDate ? new Date(this.exportOffer.harvestDate) : null)
      this.step2FormGroup.controls['shelfLife'].setValue(this.exportOffer.shelfLife)
      this.step2FormGroup.controls['netKilos'].setValue(this.exportOffer.netKilos)
      this.step2FormGroup.controls['grossKilos'].setValue(this.exportOffer.grossKilos)
      this.step2FormGroup.controls['speciesSize'].setValue(this.exportOffer.speciesSize)
      this.step2FormGroup.controls['readyToLoadDate'].setValue(this.exportOffer.readyToLoadDate ? new Date(this.exportOffer.readyToLoadDate) : null)
      this.step1FormGroup.controls['paymentAgainstBL'].setValue(this.exportOffer.paymentAgainstBL)
      this.step2FormGroup.controls['category'].setValue(this.exportOffer.category)
      // this.packagingSelected = this.exportOffer.packagings
      this.step2FormGroup.controls['packagings'].setValue(this.exportOffer.packagingTypes ? this.exportOffer.packagingTypes[0] : null)
      this.step2FormGroup.controls['otherPackaging'].setValue(this.exportOffer.otherPackaging)

      if (this.exportOffer.packagingTypes) this.filterPackaging(this.exportOffer.packagingTypes[0])

      this.loadSpecies()
      this.loadProductCertificateTypes()
      this.loadFridgeStorages()
      this.loadCountries()
      this.loadPackagings()
    }
  }

  loadSpecies() {
    this._speciesService.getAllByProductType(1).subscribe({
      next: (v) => {
        this.species = v
        if(this.exportOffer && this.exportOffer.speciesId) {
          this.step1FormGroup.controls['speciesId'].setValue(this.exportOffer.speciesId)
          this.selectedSpecies = this.species.find(x => x.id === this.exportOffer!.speciesId)
          this.loadVarieties(this.exportOffer.speciesId)
        }
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Species.ManyRetrieveError') })
      },
      complete: () => console.info('Species query successfully')
    })
  }

  loadProductCertificateTypes() {
    this._productCertificateTypeService.getAll().subscribe({
      next: (v) => {
        this.certificateTypes = v
        this.loading = false
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.ProductCertificateType.ManyRetrieveError') })
      },
      complete: () => console.info('ProductCertificateTypes query successfully')
    })
  }

  loadVarieties(speciesId: number) {
    this.varieties = []
    this._varietyService.getAllBySpecies(speciesId).subscribe({
      next: (v) => {
        this.varieties = v
        if(this.exportOffer) {
          this.step1FormGroup.controls['varietyId'].setValue(this.exportOffer.varietyId)
          this.selectedVariety = this.varieties.find(x => x.id === this.exportOffer!.varietyId)
        }
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Variety.ManyRetrieveError') })
      },
      complete: () => console.info('Variety query successfully')
    })
  }

  loadSpeciesParams() {
    this.selectedSpecies = this.species.find(x => x.id === this.step1FormGroup.controls['speciesId'].value)
    this.loadVarieties(this.step1FormGroup.controls['speciesId'].value)
  }

  loadFridgeStorages() {
    this.fridgeStorages = []
    this.fridgeStorageOptions = []
    this._fridgeStorageService.getAllByStorageType(1).subscribe({
      next: (v) => {
        this.fridgeStorages = v
        this.fridgeStorages.forEach(x => {
          this.fridgeStorageOptions.push({
            value: x.id,
            label: x.name + ', ' + x.contactAddress + ' - ' +  x.contactCountry?.name
          })
        })
        this.fridgeStorageOptions.push({
          value: 0,
          label: this.translate.instant('MessageService.FridgeStorage.CreateOptionLabel') + '...'
        })
        if(this.exportOffer)
          this.step1FormGroup.controls['fridgeStorageId'].setValue(this.exportOffer.fridgeStorageId)
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.FridgeStorage.ManyRetrieveError') })
      },
      complete: () => console.info('Frigde Storage query successfully')
    })
  }

  loadCountries() {
    this.countries = []
    this._countryService.getAll().subscribe({
      next: (v) => {
        
        // if(this.profile?.lang === 'es')
        // {

        //   v.forEach(country => {
        //     country.name = country.nameEs;
        //     this.countries.push(country)
        //   });
        // }else{
        this.countries = v
        // }
        this.loading = false

        if(this.exportOffer && this.exportOffer.marketCountryFirstId) {
          this.step2FormGroup.controls['marketCountry1Id'].setValue(this.exportOffer.marketCountryFirstId)
          this.onChangeMarketCountry1()
        }

        if(this.exportOffer && this.exportOffer.marketCountrySecondId) {
          this.step2FormGroup.controls['marketCountry2Id'].setValue(this.exportOffer.marketCountrySecondId)
          this.onChangeMarketCountry2()
        }

        if(this.exportOffer && this.exportOffer.marketCountryThirdId) {
          this.step2FormGroup.controls['marketCountry3Id'].setValue(this.exportOffer.marketCountryThirdId)
          this.onChangeMarketCountry3()
        }
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Country.ManyRetrieveError') })
      },
      complete: () => console.info('Country query successfully')
    })
  }

  onChangeVariety() {
    this.selectedVariety = this.varieties.find(x => x.id === this.step1FormGroup.controls['varietyId'].value)
  }

  onChangeFridgestorage() {
    const selected = this.step1FormGroup.controls['fridgeStorageId'].value
    if(selected === 0) {
      this.openNewFridgestorageDialog()
    }
  }

  onChangeColor() {
    this.selectedColor = this.colors.find(x => x.value === this.formGroup.controls['color'].value)
  }

  onChangeMarketCountry1() {
    this.selectedMarketCountry1 = this.countries.find(x => x.id === this.step2FormGroup.controls['marketCountry1Id'].value)
  }

  onChangeMarketCountry2() {
    this.selectedMarketCountry2 = this.countries.find(x => x.id === this.step2FormGroup.controls['marketCountry2Id'].value)
  }

  onChangeMarketCountry3() {
    this.selectedMarketCountry3 = this.countries.find(x => x.id === this.step2FormGroup.controls['marketCountry3Id'].value)
  }

  onSelectImage(event: any, uploader: any) {
    this.uploadedImages = uploader.files
  }

  onRemoveImage(event: any, uploader: any) {
    this.uploadedImages = uploader.files
  }

  onClearImageUploader(event: any, uploader: any) {
    this.uploadedImages = uploader.files
  }

  save2() {
    this.loading = true
    this.submit = true
    if(this.step1FormGroup.valid && this.step2FormGroup.valid) {
      if(!this.exportOfferId) {
        const request: ExportOfferCreateRequest = {
          varietyId: this.step1FormGroup.controls['varietyId'].value,
          palletQuantity: this.step1FormGroup.controls['palletQuantity'].value,
          caseQuantityPerPallet: this.step1FormGroup.controls['caseQuantityPerPallet'].value,
          listPrice: this.step1FormGroup.controls['listPrice'].value,
          typeSale: this.step1FormGroup.controls['incoterms'].value,
          fridgeStorageId: this.step1FormGroup.controls['fridgeStorageId'].value,
          harvestDate: this.step2FormGroup.controls['harvestDate'].value,
          shelfLife: this.step2FormGroup.controls['shelfLife'].value,
          readyToLoadDate: this.step2FormGroup.controls['readyToLoadDate'].value,
          netKilos: this.step2FormGroup.controls['netKilos'].value,
          grossKilos: this.step2FormGroup.controls['grossKilos'].value,
          speciesSize: this.step2FormGroup.controls['speciesSize'].value,
          marketCountryFirstId: this.step2FormGroup.controls['marketCountry1Id'].value,
          marketCountrySecondId: this.step2FormGroup.controls['marketCountry2Id'].value,
          marketCountryThirdId: this.step2FormGroup.controls['marketCountry3Id'].value,
          paymentAgainstBL: this.step1FormGroup.controls['paymentAgainstBL'].value,
          otherPackaging: this.step2FormGroup.controls['otherPackaging'].value,
          packagings: this.packagingSelected,
          category: this.step2FormGroup.controls['category'].value
        }

        this._exportOfferService.createBasicExportOffer(request).subscribe({
          next: (v) => {
            this.exportOfferId = v
            this._messageService.add({ severity: 'success',
              summary: this.translate.instant('MessageService.Summary.SuccessAction'),
              detail: this.translate.instant('MessageService.ExportOffer.CreateSuccess') });
            this.submit = false
            this.loading = false
          },
          error: (e) => {
            this.submit = false
            this.loading = false
            console.log(e)
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.ExportOffer.CreateError') });
          },
          complete: () => console.info('Export Offer created successfully')
        })
      }
      else {
        const basicExportOffer: BasicExportOffer = {
          guid: this.exportOfferId,
          varietyId: this.step1FormGroup.controls['varietyId'].value,
          palletQuantity: this.step1FormGroup.controls['palletQuantity'].value,
          availablePallets: this.step1FormGroup.controls['availablePallets'].value,
          caseQuantityPerPallet: this.step1FormGroup.controls['caseQuantityPerPallet'].value,
          listPrice: this.step1FormGroup.controls['listPrice'].value,
          typeSale: this.step1FormGroup.controls['incoterms'].value,
          fridgeStorageId: this.step1FormGroup.controls['fridgeStorageId'].value,
          harvestDate: this.step2FormGroup.controls['harvestDate'].value,
          shelfLife: this.step2FormGroup.controls['shelfLife'].value,
          readyToLoadDate: this.step2FormGroup.controls['readyToLoadDate'].value,
          netKilos: this.step2FormGroup.controls['netKilos'].value,
          grossKilos: this.step2FormGroup.controls['grossKilos'].value,
          speciesSize: this.step2FormGroup.controls['speciesSize'].value,
          marketCountryFirstId: this.step2FormGroup.controls['marketCountry1Id'].value,
          marketCountrySecondId: this.step2FormGroup.controls['marketCountry2Id'].value,
          marketCountryThirdId: this.step2FormGroup.controls['marketCountry3Id'].value,
          paymentAgainstBL: this.step1FormGroup.controls['paymentAgainstBL'].value,
          otherPackaging: this.step2FormGroup.controls['otherPackaging'].value,
          packagings: this.packagingSelected,
          category: this.step2FormGroup.controls['category'].value
        }

        this._exportOfferService.updateBasicExportOffer(basicExportOffer).subscribe({
          next: (v) => {
            this._messageService.add({ severity: 'success',
              summary: this.translate.instant('MessageService.Summary.SuccessAction'),
              detail: this.translate.instant('MessageService.ExportOffer.EditSuccess') })
            this.submit = false
            this.loading = false
          },
          error: (e) => {
            this.submit = false
            this.loading = false
            console.log(e)
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.ExportOffer.EditError') });
          },
          complete: () => console.info('Export Offer update successfully')
        })
      }
    }
    else {
      this._messageService.add({ severity: 'error',
        summary: this.translate.instant('MessageService.Summary.FailedAction'),
        detail: this.translate.instant('MessageService.FormMessages.FormInvalid') })
      this.loading = false
    }
  }

  showStep1() {
    this.step = this.step1
  }

  backStep2() {
    this.step = this.step2
  }

  showStep2() {
    this.submit = true
    if(this.step1FormGroup.valid) {
      this.submit = false
      this.step = this.step2
    }
    else {
      this._messageService.add({ severity: 'error',
        summary: this.translate.instant('MessageService.Summary.FailedAction'),
        detail: this.translate.instant('MessageService.FormMessages.FormInvalid') });
    }
  }

  showStep3() {
    this.submit = true
    if(this.step2FormGroup.valid) {
      this.save2()
      this.step = this.step3
    }
    else {
      this._messageService.add({ severity: 'error',
        summary: this.translate.instant('MessageService.Summary.FailedAction'),
        detail: this.translate.instant('MessageService.FormMessages.FormInvalid') });
    }
  }

  onChangePayment(event: any)
  {
    console.log(event.value);
  }

  onChangeIncoterm(event: any)
  {
    this.getPaymentsBLItems()
  }

  @ViewChild(ExportOfferPictureUploaderComponent) pictureUploaderComponent: ExportOfferPictureUploaderComponent | undefined
  @ViewChild(ExportOfferCertificateTypeComponent) exportOfferCertificateTypeComponent: ExportOfferCertificateTypeComponent | undefined
  finish() {
    if(this.exportOfferId && this.exportOfferCertificateTypeComponent) {
      const result = this.exportOfferCertificateTypeComponent?.getSelectedProductCertificateTypes()
      let certificateTypeIds: number[] = []
      result.productCertificateTypes.forEach(x => {
        certificateTypeIds.push(x.id)
      })

      const request: UpdatePicturesAndCertificateTypesRequest = {
        exportOfferId: this.exportOfferId,
        pictures: this.uploadedImages,
        certificateTypeIds: certificateTypeIds,
        otherCertificateType: result.otherCertificateType
      }
      this.loading = true
      this._exportOfferService.updatePicturesAndCertificateTypes(request).subscribe({
        next: (v) => {
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: this.translate.instant('MessageService.ExportOffer.EditSuccess') })
          this.submit = false
          this.loading = false
          this.step = this.finalStep

          // const currentId = this._activatedRoute.snapshot.paramMap.get('exportOfferId');
          // if (currentId !== null && currentId !== undefined && currentId !== "") {
          //   // Navegar a la URL con el parámetro adicional
          //   this._router.navigate([`/${Routes.newExportOffer}/${currentId}`, { additional: true }]);
          // }
        },
        error: (e) => {
          this.submit = false
          this.loading = false
          console.log(e)
          let errorMessage = ''
          const lang = this.translate.currentLang
          switch(lang) {
            case 'es':
              errorMessage = e.error.messageEs
              break
            case 'en':
              errorMessage = e.error.messageEn
              break
            default:
              errorMessage = e.error.messageEn
          }
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: errorMessage ?? this.translate.instant('MessageService.ExportOffer.EditError') });
        },
        complete: () => console.info('Export Offer update successfully')
      })
    }
  }

  goToExportOffersPage() {
    this._router.navigate([Routes.userExportOffers])
  }

  getErrorMessage(formGroup: FormGroup, fieldName: string): string {
    return ErrorMessageUtil.getErrorMessage(formGroup, this.translate, fieldName)
  }

  openNewFridgestorageDialog() {
    this.fridgestorageFormGroup.controls["fridgestorageName"].setValue(''),
    this.fridgestorageFormGroup.controls["fridgestorageVat"].setValue(''),
    this.fridgestorageFormGroup.controls["fridgestorageContactName"].setValue(''),
    this.fridgestorageFormGroup.controls["fridgestorageContactAddress"].setValue(''),
    this.fridgestorageFormGroup.controls["fridgestorageContactEmail"].setValue(''),
    this.fridgestorageFormGroup.controls["fridgestorageContactPhone"].setValue(''),
    this.fridgestorageFormGroup.controls["fridgestorageContactCity"].setValue(''),
    this.fridgestorageFormGroup.controls["fridgestorageContactCountryId"].setValue('')
    this.loadFridgestorageDialogVisible = true
  }

  saveFridgestorage() {
    this.submit = true
    this.loading = true
    if(this.fridgestorageFormGroup.valid) {
      const newFridgeStorage: NewFridgeStorage = {
        name: this.fridgestorageFormGroup.controls["fridgestorageName"].value,
        vat: this.fridgestorageFormGroup.controls["fridgestorageVat"].value,
        contactName: this.fridgestorageFormGroup.controls["fridgestorageContactName"].value,
        contactAddress: this.fridgestorageFormGroup.controls["fridgestorageContactAddress"].value,
        contactEmail: this.fridgestorageFormGroup.controls["fridgestorageContactEmail"].value,
        contactPhone: this.fridgestorageFormGroup.controls["fridgestorageContactPhone"].value,
        contactCity: this.fridgestorageFormGroup.controls["fridgestorageContactCity"].value,
        contactCountryId: this.fridgestorageFormGroup.controls["fridgestorageContactCountryId"].value,
        storageType: 1
      }

      this._fridgeStorageService.create(newFridgeStorage).subscribe({
        next: (v) => {
          this.loadFridgeStorages()
          this.loadFridgestorageDialogVisible = false
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: this.translate.instant('MessageService.FridgeStorage.CreateSuccess') })
          this.loading = false
          this.submit = false
        },
        error: (e) => {
          this.loading = false
          this.submit = false
          console.log(e)
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.FridgeStorage.CreateError') });
        },
        complete: () =>  {
          this.loading = false
          this.submit = false
          console.info('FridgeStorage created successfully')
        }
      })
    }
    else {
      this.loading = false
    }
  }

  onCancelNewFridgestorage() {
    this.step1FormGroup.controls['fridgeStorageId'].setValue(null)
    this.loadFridgestorageDialogVisible = false
    this.submit = false
    this.loading = false
  }

  get finalPaymentPercent(): number {
    if(this.step1FormGroup.controls['paymentAgainstBL'].value)
      return 100 - this.step1FormGroup.controls['paymentAgainstBL'].value
    return 0
  }

  getCardHeader(): string {
    if(this.exportOffer)
      return this.translate.instant('UserPages.UserExportOffers.EditExportOffer.PageTitle')
    else
      return this.translate.instant('UserPages.UserExportOffers.NewExportOffer.PageTitle')
  }

  getCardSubheader(): string {
    if(this.exportOffer)
      return this.translate.instant('UserPages.UserExportOffers.EditExportOffer.PageSubtitle')
    else
      return this.translate.instant('UserPages.UserExportOffers.NewExportOffer.PageSubtitle')
  }

  goToExportOfferEditAdditionalInfo() {
    this._router.navigate(['/' + Routes.exportOfferEditAdditionalInfo, this.exportOfferId])
  }

  filterPackagingMultiselect(event: any) {
    if (event.includes(6)) { // Other/Otros
      this.step2FormGroup.controls['otherPackaging'].enable()
    }
    else {
      this.step2FormGroup.controls['otherPackaging'].setValue('')
      this.step2FormGroup.controls['otherPackaging'].disable()
    }
  }

  filterPackaging(event: any) {
    if (event?.isOther === true) {
      this.step2FormGroup.controls['otherPackaging'].enable()
    } else {
      this.step2FormGroup.controls['otherPackaging'].setValue('')
      this.step2FormGroup.controls['otherPackaging'].disable()
    }
    if (event !== undefined && event !== null) this.packagingSelected[0] = event.id
  }

  get paymentBLTitle() {
    if (this.step1FormGroup.controls['paymentAgainstBL'].value)
      return ExportOfferUtils.getPaymentBLTitle(this.step1FormGroup.controls['paymentAgainstBL'].value);
    return 'UserPages.UserBargaining.OpenTab.BargainingDetail.MakeCounterOfferModal.PaymentAgainstBLLabel'
  }

  getPaymentsBLItems()
  {
    this.paymentBLItems = ExportOfferUtils.getPaymentBLValues(this.step1FormGroup.controls['incoterms'].value);
  }

  // get paymentBLItems() {
  //   return ExportOfferUtils.getPaymentBLValues(this.step1FormGroup.controls['incoterms'].value);
  // }

  get showPaymentBLPercent() {
    return ExportOfferUtils.showPaymentBLPercent(this.step1FormGroup.controls['incoterms'].value)
  }
}
