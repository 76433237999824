<app-user-dashboard [userMenuActiveItemIndex]="0" [activeItem]="'corporative'">
  <p-card [style]="{'min-height':'200px'}">
    <ng-template pTemplate="header">
      <div class="flex justify-content-start align-items-start flex-column" style="margin-left: 15px;">
        <h2>{{'UserPages.UserNotifies.PageTitle' | translate}}</h2>
        <p style="margin-left: 15px;">{{'UserPages.UserNotifies.PageSubtitle' | translate}}</p>
      </div>
    </ng-template>
    <p-table [loading]="loading"
      [value]="notifiers"
      responsiveLayout="stack"
      [breakpoint]="'600px'">
      <ng-template pTemplate="caption">
        <div class="flex align-items-center justify-content-start">
          <p-button label="{{'UserPages.UserNotifies.NewButton' | translate}}" icon="pi pi-plus" class="mr-2" (click)="goToCreateForm()"/>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserNotifies.NameTableHeader' | translate}}</div></th>
          <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserNotifies.ContactTableHeader' | translate}}</div></th>
          <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserNotifies.ContactEmailTableHeader' | translate}}</div></th>
          <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserNotifies.CityTableHeader' | translate}}</div></th>
          <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserNotifies.CountryTableHeader' | translate}}</div></th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-notify>
        <tr [pSelectableRow]="notify">
          <td><div class="flex justify-content-center align-items-center">{{notify.companyName}}</div></td>
          <td><div class="flex justify-content-center align-items-center">{{notify.contactName}}</div></td>
          <td><div class="flex justify-content-center align-items-center">{{notify.contactEmail}}</div></td>
          <td><div class="flex justify-content-center align-items-center">{{notify.contactCity}}</div></td>
          <td><div class="flex justify-content-center align-items-center">{{notify.contactCountry | countryDescription}}</div></td>
          <td>
            <div class="flex justify-content-end align-items-center gap-2">
              <p-button label="{{'UserPages.UserNotifies.EditButton' | translate}}" icon="pi pi-pencil" (click)="goToEditForm(notify.id)" class="mr-2"/>
              <p-button label="{{'UserPages.UserNotifies.DeleteButton' | translate}}" icon="pi pi-trash" (click)="onDelete(notify.id)" class="mr-2"/>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </p-card>
</app-user-dashboard>

<p-toast></p-toast>
<p-confirmDialog acceptLabel="{{'UserPages.UserNotifies.YesLabel' | translate}}" rejectLabel="{{'UserPages.UserNotifies.NotLabel' | translate}}" rejectButtonStyleClass="p-button-outlined" [style]="{width: '50vw'}"></p-confirmDialog>
