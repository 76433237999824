import { GenericOrderDetail, ImportOrder, ImportOrderDetail, ImportOrderInformation } from "src/app/domain/models/ImportOrder";
import { environment } from '../../../environments/environment'

export interface IImportOrderStatus {
  value: number,
  name: string
}

export class OrderUtils {
  static getOrderTotalPrice(details?: ImportOrderDetail[]): number {
    let total = 0
    if(details) {
      details.forEach(d => {
        total += d.caseQuantityPerPallet * d.price * d.palletsQuantity
      })
    }
    return total
  }

  static getOrdetTotalPriceGeneric(details: GenericOrderDetail[]): number {
    let total = 0
    if(details) {
      details.forEach(d => {
        if(d.palletsQuantity > 0 && d.boxesPerPallet > 0) {
          total += d.palletsQuantity * d.boxesPerPallet * d.price
        }
        else {
          total += d.boxesQuantity * d.price
        }
      })
    }
    return total
  }

  static getDetailTotalPrice(detail: ImportOrderDetail) {
    let total = 0
    if(detail) {
      return detail.caseQuantityPerPallet * detail.price * detail.palletsQuantity
    }
    return total
  }

  static getOrderTotalQuantity(details: ImportOrderDetail[]) {
    let total = 0
    if(details) {
      details.forEach(x => total += x.palletsQuantity)
    }
    return total
  }

  static getOrderTitle(id?: number): string {
    return OrderUtils.padWithLeadingZeros(id)
  }

  static getImageUrl(order: ImportOrderInformation | undefined) {
    if(order && order.details && order.details.length > 0 && order.details[0].exportOffer && order.details[0].exportOffer.offerPictures && order.details[0].exportOffer.offerPictures.length > 0 && order.details[0].exportOffer.offerPictures[0].url) {
      return environment.apiUrl + order.details[0].exportOffer.offerPictures[0].url
    }
    else if (order && order.details && order.details.length > 0 && order.details[0].species && order.details[0].species?.imageUrl) {
      return order.details[0].species.imageUrl
    }
    return ''
  }

  static getImportOrderStatusList(): IImportOrderStatus[] {
    return [
      {
        value: 0,
        name: "Created"
      },
      {
        value: 1,
        name: "Pending Verify Pallets"
      },
      {
        value: 2,
        name: "Admin Pallets Verified"
      },
      {
        value: 3,
        name: "Importer Order Confirmed"
      },
      {
        value: 4,
        name: "Importer Payment Confirmed"
      },
      {
        value: 5,
        name: "Cancelled"
      },
      {
        value: 6,
        name: "Expired"
      },
      {
        value: 7,
        name: "Approved"
      }
    ]
  }

  static padWithLeadingZeros(num?: number) {
    if(num)
        return String(num).padStart(6, '0')
    return ''
  }
}

export class ImportOrderStatus {
  static PendingVerifyPallets: string = 'PendingVerifyPallets'
  static AdminPalletsVerified: string = 'AdminPalletsVerified'
  static ImporterOrderConfirmed: string = 'ImporterOrderConfirmed'
  static ImporterPaymentConfirmed: string = 'ImporterPaymentConfirmed'
  static AllConfirmed:string = 'ImporterOrderConfirmed,ImporterPaymentConfirmed'
  static Created: string = 'Created'
}

export class ImportOrderStatusEnum {
  static Created = 0 // Orden ha sido creada.
  static PendingVerifyPallets = 1 // Orden está pendiente para que el exportador y administrador verifique los pallets.
  static AdminPalletsVerified = 2 // El administrador ha verificado los pallets completamente.
  static ImporterOrderConfirmed = 3 // El importador ha confirmado la orden (Empieza a correr tiempo para confirmar pago)
  static ImporterPaymentConfirmed = 4 // El importador ha confirmado el pago.
  static Cancelled = 5 // El importador o el administrador ha cancelado la orden (rollback en la oferta de exportación)
  static Expired = 6 // El sistema ha expirado la orden porque ya pasó el tiempo para confirmar el pago.
  static Approved = 7 // El pago ha sido aprobado satisfactoriamente.
}


