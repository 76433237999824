import { FormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";

export class ErrorMessageUtil {

  static getErrorMessage(formGroup: FormGroup, translateService: TranslateService, fieldName: string, divisor?: number): string {
    if(!formGroup)
      return ''
    if (formGroup.controls[fieldName].hasError('required')) {
      return translateService.instant('FormErrorMessage.FieldValueRequired')
    }
    if (formGroup.controls[fieldName].hasError('email')) {
      return translateService.instant('FormErrorMessage.EmailFieldEmailFormatError')
    }
    if (formGroup.controls[fieldName].hasError('divisor') && divisor) {
      return translateService.instant('FormErrorMessage.DivisorError') + divisor;
    }
    return '';
  }

  static getResponseError(error: any, lang?: string): string | null {
    switch(lang) {
      case 'en':
        return error.messageEn
      case 'es':
        return error.messageEs
      default:
        return null
    }
  }
}
