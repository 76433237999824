<app-user-dashboard [userMenuActiveItemIndex]="0" [activeItem]="'export-offers'">
  <form [formGroup]="formGroup">
    <div id="step1" *ngIf="step == 'step1'">
      <p-breadcrumb class="max-w-full" [model]="breadcrumbItemsStep1" [home]="home"></p-breadcrumb>
      <p-card [style]="{'min-height':'200px'}">
        <ng-template pTemplate="header">
          <div class="flex justify-content-between flex-wrap align-items-center" style="padding: 15px;">
            <h2>{{'UserPages.UserClosedSale.UserCreateClosedSale.PageTitle' | translate}}</h2>
          </div>
        </ng-template>
        <h2>{{'UserPages.UserClosedSale.UserCreateClosedSale.PageTitle2' | translate}}</h2>
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-6 lg:col-3" style="margin-bottom: 40px;">
            <label style="font-weight: bolder;" for="species">{{'UserPages.UserClosedSale.UserCreateClosedSale.SpecieFieldLabel' | translate}} *</label>
            <p-dropdown id="species"
              formControlName="speciesId"
              [options]="species"
              optionLabel="nameEs"
              optionValue="id"
              [filter]="true"
              [filterBy]="profile?.lang == 'en' ? 'name' : 'nameEs'"
              [showClear]="true"
              placeholder="{{'UserPages.UserClosedSale.UserCreateClosedSale.SpecieFieldPlaceholder' | translate }}"
              (onChange)="loadSpeciesParams()">
              <ng-template pTemplate="selectedItem">
                <div class="flex align-items-center gap-2" *ngIf="selectedSpecies">
                    <img [src]="selectedSpecies.imageUrl" style="width: 18px" alt=""/>
                    <div>{{ getSpeciesName(selectedSpecies) }}</div>
                </div>
              </ng-template>
              <ng-template let-specie pTemplate="item">
                  <div class="flex align-items-center gap-2">
                      <img [src]="specie.imageUrl" style="width: 18px" alt=""/>
                      <div>{{ getSpeciesName(specie) }}</div>
                  </div>
              </ng-template>
            </p-dropdown>
            <small *ngIf="submitStep2 && formGroup.controls['speciesId'].invalid" class="p-error">{{getErrorMessage(formGroup, 'speciesId')}}</small>
          </div>
          <div class="field col-12 md:col-6 lg:col-3" style="margin-bottom: 40px;">
            <label style="font-weight: bolder;" for="variety">{{'UserPages.UserClosedSale.UserCreateClosedSale.VarietyFieldLabel' | translate}} *</label>
            <p-dropdown id="variety"
              formControlName="varietyId"
              [options]="varieties"
              optionLabel="name"
              optionValue="id"
              [filter]="true"
              filterBy="name"
              [showClear]="true"
              placeholder="{{'UserPages.UserClosedSale.UserCreateClosedSale.VarietyFieldPlaceholder' | translate}}"
              (onChange)="onChangeVariety()"
              emptyMessage="{{'UserPages.UserClosedSale.UserCreateClosedSale.EmptySpeciesText' | translate}}">
              <ng-template pTemplate="selectedItem">
                <div class="flex align-items-center gap-2" *ngIf="selectedVariety">
                    <img [src]="selectedVariety.urlIcon" style="width: 18px" alt=""/>
                    <div>{{ selectedVariety.name }}</div>
                </div>
              </ng-template>
              <ng-template let-variety pTemplate="item">
                  <div class="flex align-items-center gap-2">
                      <img [src]="variety.urlIcon" style="width: 18px" alt=""/>
                      <div>{{ variety.name }}</div>
                  </div>
              </ng-template>
            </p-dropdown>
            <small *ngIf="submitStep2 && formGroup.controls['varietyId'].invalid" class="p-error">{{getErrorMessage(formGroup, 'varietyId')}}</small>
          </div>
          <div class="field col-12 md:col-6 lg:col-3" style="margin-bottom: 40px;">
            <label style="font-weight: bolder;" for="caseQuantityPerPallet">{{'UserPages.UserClosedSale.UserCreateClosedSale.CasePerPalletsFieldLabel' | translate }} *</label>
            <p-inputNumber inputId="caseQuantityPerPallet" formControlName="caseQuantityPerPallet" [min]="1"  placeholder="{{'UserPages.UserClosedSale.UserCreateClosedSale.CasePerPalletsFieldPlaceholder' | translate}}"></p-inputNumber>
            <small *ngIf="submitStep2 && formGroup.controls['caseQuantityPerPallet'].invalid" class="p-error">{{getErrorMessage(formGroup, 'caseQuantityPerPallet')}}</small>
          </div>
          <div class="field col-12 md:col-6 lg:col-3">
            <label for="listPrice" style="font-weight: bolder;">{{'UserPages.UserClosedSale.UserCreateClosedSale.ListPrice' | translate }} *</label>
            <p-inputNumber inputId="listPrice" formControlName="listPrice" mode="currency" currency="USD" locale="en-US" [min]="0" placeholder="{{'UserPages.UserClosedSale.UserCreateClosedSale.ListPriceFieldPlaceholder' | translate }}"></p-inputNumber>
            <small *ngIf="submitStep2 && formGroup.controls['listPrice'].invalid" class="p-error">{{getErrorMessage(formGroup, 'listPrice')}}</small>
          </div>
          <div class="field col-12 md:col-6 lg:col-3">
            <label style="font-weight: bolder;" for="incoterm">{{'UserPages.UserClosedSale.UserCreateClosedSale.IncotermsFieldLabel' | translate }} *</label>
            <p-dropdown inputId="incoterm" formControlName="incoterm" [options]="incotermsOptions" optionLabel="label" optionValue="value" placeholder="{{'UserPages.UserClosedSale.UserCreateClosedSale.IncotermsFieldPlaceholder' | translate}}"></p-dropdown>
            <small *ngIf="submitStep2 && formGroup.controls['incoterm'].invalid" class="p-error">{{getErrorMessage(formGroup, 'incoterm')}}</small>
          </div>
          <div class="field col-12 md:col-3 lg:col-3">
            <label for="paymentAgainstBL" style="font-weight: bolder;">{{ paymentBLTitle | translate }} *</label>
            <p-dropdown id="paymentAgainstBL" formControlName="paymentAgainstBL" [options]="paymentBLItems" optionLabel="label" optionValue="value" placeholder="{{'UserPages.UserExportOffers.NewExportOffer.PaymentAgainstBLClosedSaleLabel' | translate}}"></p-dropdown>
            <small *ngIf="submit && formGroup.controls['paymentAgainstBL'].invalid" class="p-error">{{getErrorMessage(formGroup, 'paymentAgainstBL')}}</small>
            <small *ngIf="showPaymentBLPercent">{{'UserPages.UserExportOffers.NewExportOffer.FinalPaymentSubjectQCClosedSaleReview' | translate}} {{finalPaymentPercent}}%</small>
          </div>
          <div class="field col-12 md:col-6 lg:col-3" style="margin-bottom: 40px;">
            <label style="font-weight: bolder;" for="fridgeStorage">{{'UserPages.UserClosedSale.UserCreateClosedSale.FridstorageFieldLabel' | translate }} *</label>
            <p-dropdown id="fridgeStorage"
              formControlName="fridgeStorageId"
              [options]="fridgeStorages"
              optionLabel="name"
              optionValue="id"
              [filter]="true"
              filterBy="name"
              [showClear]="true"
              placeholder="{{'UserPages.UserClosedSale.UserCreateClosedSale.FridstorageFieldPlaceholder' | translate }}">
            </p-dropdown>
            <small *ngIf="submitStep2 && formGroup.controls['fridgeStorageId'].invalid" class="p-error">{{getErrorMessage(formGroup, 'fridgeStorageId')}}</small>
          </div>
          <div class="field col-12 md:col-6 lg:col-3 flex align-items-center" style="margin-bottom: 40px;">
            <p-button label="{{'UserPages.UserClosedSale.UserCreateClosedSale.CreateFridgestorageButton' | translate}}" icon="pi pi-plus" (onClick)="openNewFridgestorageDialog()"></p-button>
          </div>
        </div>

        <p-divider></p-divider>
        <h2>{{'UserPages.UserClosedSale.UserCreateClosedSale.PageTitle3' | translate }}</h2>
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-6 lg:col-3" style="margin-bottom: 40px;">
            <label style="font-weight: bolder;" for="harvestDate">{{'UserPages.UserClosedSale.UserCreateClosedSale.HarvestDateFieldLabel' | translate }} *</label>
            <p-calendar
              inputId="harvestDate"
              formControlName="harvestDate"
              appendTo="body"
              [showButtonBar]="true"
              [showIcon]="true"
              placeholder="{{'UserPages.UserClosedSale.UserCreateClosedSale.HarvestDateFieldPlaceholder' | translate}}"
              [showWeek]="true"
              [firstDayOfWeek]="1"></p-calendar>
            <small *ngIf="submitStep2 && formGroup.controls['harvestDate'].invalid" class="p-error">{{getErrorMessage(formGroup, 'harvestDate')}}</small>
          </div>
          <div class="field col-12 md:col-6 lg:col-3" style="margin-bottom: 40px;">
            <label style="font-weight: bolder;" for="shelfLife">Shelf Life ({{'UserPages.UserClosedSale.UserCreateClosedSale.DayShelflifeText' | translate}}) *</label>
            <p-inputNumber inputId="shelfLife" formControlName="shelfLife" [min]="1" [max]="1000"  placeholder="{{'UserPages.UserClosedSale.UserCreateClosedSale.ShelflifePlaceholder' | translate}}"></p-inputNumber>
            <small *ngIf="submitStep2 && formGroup.controls['shelfLife'].invalid" class="p-error">{{getErrorMessage(formGroup, 'shelfLife')}}</small>
          </div>
          <div class="field col-12 md:col-6 lg:col-3" style="margin-bottom: 40px;">
            <label style="font-weight: bolder;" for="netKilos">{{'UserPages.UserClosedSale.UserCreateClosedSale.NetKiloFieldLabel' | translate}} *</label>
            <input pInputText inputId="netKilos" formControlName="netKilos" [maxlength]="formLimit.netKilosLimit" placeholder="{{'UserPages.UserClosedSale.UserCreateClosedSale.NetKiloFieldPlaceholder' | translate}}"/>
            <small *ngIf="submitStep2 && formGroup.controls['netKilos'].invalid" class="p-error">{{getErrorMessage(formGroup, 'netKilos')}}</small>
          </div>
          <div class="field col-12 md:col-6 lg:col-3" style="margin-bottom: 40px;">
            <label style="font-weight: bolder;" for="grossKilos">{{'UserPages.UserClosedSale.UserCreateClosedSale.GrossKiloFieldLabel' | translate}} *</label>
            <input pInputText inputId="grossKilos" formControlName="grossKilos" [maxlength]="formLimit.grossKilosLimit" placeholder="{{'UserPages.UserClosedSale.UserCreateClosedSale.GrossKiloFieldPlaceholder' | translate}}"/>
            <small *ngIf="submitStep2 && formGroup.controls['grossKilos'].invalid" class="p-error">{{getErrorMessage(formGroup, 'grossKilos')}}</small>
          </div>
          <div class="field col-12 md:col-6 lg:col-3" style="margin-bottom: 40px;">
            <label style="font-weight: bolder;" for="boxType">{{'UserPages.UserClosedSale.UserCreateClosedSale.BoxTypeFieldLabel' | translate}} *</label>
            <p-dropdown inputId="boxType" formControlName="boxType" [options]="boxTypes" optionLabel="label" optionValue="value" placeholder="{{'UserPages.UserClosedSale.UserCreateClosedSale.BoxTypeFieldPlaceholder' | translate}}"></p-dropdown>
            <small *ngIf="submitStep2 && formGroup.controls['boxType'].invalid" class="p-error">{{getErrorMessage(formGroup, 'boxType')}}</small>
          </div>
          <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
            <label for="packagings" style="font-weight: bolder;">{{'UserPages.UserExportOffers.NewExportOffer.PackagingLabel' | translate}} *</label>
            <p-dropdown id="packagings"
              formControlName="packagings"
              [options]="packagingList"
              optionLabel="name"
              [filter]="true"
              filterBy="name"
              [showClear]="false"
              placeholder="{{'UserPages.UserExportOffers.NewExportOffer.SelectText' | translate}}"
              (onChange)="filterPackaging($event.value)">
            </p-dropdown>
            <small *ngIf="submit && formGroup.controls['packagings'].invalid" class="p-error">{{getErrorMessage(formGroup, 'packagings')}}</small>
          </div>
          <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
            <label style="font-weight: bolder;" for="caseSize" style="font-weight: bolder;">{{'UserPages.UserExportOffers.NewExportOffer.CaseSize' | translate}}</label>
            <input pInputText inputId="caseSize" [maxlength]="formLimit.caseSizeLimit" formControlName="caseSize" [maxlength]="50" placeholder="{{'UserPages.UserExportOffers.NewExportOffer.CaseSize' | translate}}"/>
            <small *ngIf="submitStep2 && formGroup.controls['caseSize'].invalid" class="p-error">{{getErrorMessage(formGroup, 'caseSize')}}</small>
          </div>
          <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
            <label style="font-weight: bolder;" for="speciesSize">{{'UserPages.UserClosedSale.UserCreateClosedSale.SpeciesSizeFieldLabel' | translate}}</label>
            <input pInputText inputId="speciesSize"inputId="speciesSize" [maxlength]="formLimit.speciesSizeLimit" formControlName="speciesSize" placeholder="{{'UserPages.UserClosedSale.UserCreateClosedSale.SpeciesSizeFieldPlaceholder' | translate}}"/>
          </div>
          <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
            <label style="font-weight: bolder;" for="sizeMillimeters">{{'UserPages.UserClosedSale.UserCreateClosedSale.SpeciesSizeMillimetersFieldLabel' | translate}}</label>
            <p-inputNumber inputId="sizeMillimeters" formControlName="sizeMillimeters" [min]="0" mode="decimal" [maxFractionDigits]="2"  [minFractionDigits]="2" suffix=" mm" locale="en-US" placeholder="{{'UserPages.UserClosedSale.UserCreateClosedSale.SpeciesSizeMillimetersFieldPlaceholder' | translate}}"></p-inputNumber>
          </div>
          <div class="field col-12 md:col-6 lg:col-3" style="margin-bottom: 40px;">
            <label style="font-weight: bolder;" for="category">{{'UserPages.UserClosedSale.UserCreateClosedSale.CategoryFieldLabel' | translate}}</label>
            <input pInputText inputId="category" formControlName="category" [maxlength]="formLimit.categoryLimit" placeholder="{{'UserPages.UserClosedSale.UserCreateClosedSale.CategoryFieldPlaceholder' | translate}}"/>
          </div>
          <div class="field col-12 md:col-6 lg:col-3" style="margin-bottom: 40px;">
            <label style="font-weight: bolder;" for="readyToLoadDate">{{'UserPages.UserClosedSale.UserCreateClosedSale.ReadyToLoadDateFieldLabel' | translate}}</label>
            <p-calendar
              inputId="readyToLoadDate"
              formControlName="readyToLoadDate"
              appendTo="body"
              [showButtonBar]="true"
              [showIcon]="true"
              placeholder="{{'UserPages.UserClosedSale.UserCreateClosedSale.ReadyToLoadDateFieldPlaceholder' | translate}}"
              [showWeek]="true"
              [firstDayOfWeek]="1"></p-calendar>
          </div>
          <div class="field col-12 md:col-6 lg:col-3" style="margin-bottom: 40px;">
            <label style="font-weight: bolder;" for="basePallets">{{'UserPages.UserClosedSale.UserCreateClosedSale.BasePalletsFieldLabel' | translate}}</label>
            <p-dropdown inputId="basePallets" formControlName="basePallets" [options]="basePallets" optionLabel="label" optionValue="value" placeholder="{{'UserPages.UserClosedSale.UserCreateClosedSale.BasePalletsFieldPlaceholder' | translate}}"></p-dropdown>
          </div>
          <div class="field col-12 md:col-6 lg:col-3" style="margin-bottom: 40px;">
            <label style="font-weight: bolder;" for="isQualityControl">{{'UserPages.UserClosedSale.UserCreateClosedSale.isQualityControlFieldLabel' | translate}}</label>
            <p-dropdown id="isQualityControl" formControlName="isQualityControl" [options]="qualityControlOptions" optionLabel="label" optionValue="value" placeholder="{{'UserPages.UserClosedSale.UserCreateClosedSale.isQualityControlPlaceholder' | translate}}"></p-dropdown>
          </div>
          <div class="field col-12 md:col-6 lg:col-3" style="margin-bottom: 40px;">
            <label style="font-weight: bolder;" for="qualityControlDate">{{'UserPages.UserClosedSale.UserCreateClosedSale.QualityControlDateFieldLabel' | translate}}</label>
            <p-calendar
              inputId="qualityControlDate"
              formControlName="qualityControlDate"
              appendTo="body"
              [showButtonBar]="true"
              [showIcon]="true"
              placeholder="{{'UserPages.UserClosedSale.UserCreateClosedSale.QualityControlDateFieldPlaceholder' | translate}}"
              [showWeek]="true"
              [firstDayOfWeek]="1"></p-calendar>
          </div>
          <div class="field col-12 md:col-6 lg:col-3" style="margin-bottom: 40px;">
            <label style="font-weight: bolder;" for="marks">{{'UserPages.UserClosedSale.UserCreateClosedSale.HasMarksFieldLabel' | translate}}</label>
            <p-dropdown id="marks" formControlName="marks" [options]="marksOptions" optionLabel="label" optionValue="value" placeholder="{{'UserPages.UserClosedSale.UserCreateClosedSale.HasMarksFieldPlaceholder' | translate}}"></p-dropdown>
          </div>
        </div>

        <p-divider></p-divider>
        <h2>{{'UserPages.UserClosedSale.UserCreateClosedSale.OfferImagesLabel' | translate}}</h2>
        <div class="col-12">
          <p-fileUpload
            name="demo[]"
            #imagesUploader
            (onSelect)="onSelectImage($event, imagesUploader)"
            (onRemove)="onRemoveImage($event, imagesUploader)"
            (onClear)="onClearImageUploader($event, imagesUploader)"
            [showUploadButton]="false"
            [multiple]="true"
            accept=".jpg, .jpeg, .png"
            [fileLimit]="3"
            [files]="uploadedImages"
            [maxFileSize]="3000000"
            chooseLabel="{{'UserPages.UserClosedSale.UserCreateClosedSale.SelectImageButton' | translate}}"
            cancelLabel="{{'UserPages.UserClosedSale.UserCreateClosedSale.CancelImagesButton' | translate}}">
            <ng-template pTemplate="toolbar">
              <div class="py-3">{{'UserPages.UserClosedSale.UserCreateClosedSale.UploadImageText' | translate}}</div>
            </ng-template>
            <ng-template pTemplate="content">

            </ng-template>
          </p-fileUpload>
        </div>

        <p-divider></p-divider>
        <h2>{{'UserPages.UserExportOffers.NewExportOffer.PageTitle5' | translate}}</h2>
        <p>{{'UserPages.UserExportOffers.NewExportOffer.PageSubtitle5' | translate}}</p>
        <div class="col-12">
          <p-table
            [loading]="loading"
            [value]="productCertificates"
            [paginator]="true"
            [rows]="10"
            [showCurrentPageReport]="true"
            [rowsPerPageOptions]="[10, 25, 50]"
            responsiveLayout="stack"
            [breakpoint]="'600px'">
            <ng-template pTemplate="caption">
              <div class="flex align-items-center justify-content-start">
                <p-button label="{{'UserPages.UserProductCertificates.NewButtonLabel' | translate}}" icon="pi pi-plus" class="mr-2" (onClick)="openProductCertificateCreateDialog(formDirective, productCertificateFileUploader)"/>
              </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                  <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserProductCertificates.NameHeader' | translate}}</div></th>
                  <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserProductCertificates.CertificateTypeHeader' | translate}}</div></th>
                  <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserProductCertificates.IssueDateHeader' | translate}}</div></th>
                  <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserProductCertificates.ExpireDateHeader' | translate}}</div></th>
                  <th><div class="flex justify-content-center align-items-center">PDF</div></th>
                  <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-certificate>
              <tr [pSelectableRow]="certificate">
                <td><div class="flex justify-content-center align-items-center">{{certificate.name}}</div></td>
                <td><div class="flex justify-content-center align-items-center">{{certificate.productCertificateType?.name}}</div></td>
                <td><div class="flex justify-content-center align-items-center">{{certificate.documentIssueDate | date:'dd-MM-yyyy'}}</div></td>
                <td><div class="flex justify-content-center align-items-center">{{certificate.expirationDate | date:'dd-MM-yyyy'}}</div></td>
                <td><div class="flex justify-content-center align-items-center">{{certificate.pdf?.name }}</div></td>
                <td>
                  <div class="flex justify-content-end align-items-center gap-2">
                    <p-button label="{{'UserPages.UserProductCertificates.EditButtonLabel' | translate}}" icon="pi pi-pencil" (onClick)="openProductCertificateEditDialog(certificate, productCertificateFileUploader)" class="mr-2"/>
                    <p-button label="{{'UserPages.UserProductCertificates.DeleteButtonLabel' | translate}}" icon="pi pi-trash" (onClick)="onDeleteProductCertificate(certificate)" class="mr-2"/>
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>

        <p-divider></p-divider>
        <h2>{{'UserPages.UserExportOffers.NewExportOffer.PageTitle6' | translate}}</h2>
        <p>{{'UserPages.UserExportOffers.NewExportOffer.PageSubtitle6' | translate}}</p>
        <p-table [value]="documents" responsiveLayout="stack" [breakpoint]="'600px'">
          <ng-template pTemplate="caption">
            <div class="flex align-items-center justify-content-start">
              <p-button label="{{'UserPages.UserExportOfferDocument.NewButtonLabel' | translate}}" icon="pi pi-plus" class="mr-2" (onClick)="openDocumentCreateDialog(documentFormDirective, documentFileUploader)"/>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
              <tr>
                <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserExportOfferDocument.NameHeader' | translate}}</div></th>
                <th><div class="flex justify-content-center align-items-center">PDF</div></th>
                <th></th>
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-document>
              <tr>
                <td><div class="flex justify-content-center align-items-center">{{ document.name }}</div></td>
                <td><div class="flex justify-content-center align-items-center">{{document.pdf?.name}}</div></td>
                <td>
                  <div class="flex justify-content-end align-items-center gap-2">
                    <p-button label="{{'UserPages.UserExportOfferDocument.EditButtonLabel' | translate}}" icon="pi pi-pencil" (onClick)="openDocumentEditDialog(document, documentFileUploader)" class="mr-2"/>
                    <p-button label="{{'UserPages.UserExportOfferDocument.DeleteButtonLabel' | translate}}" icon="pi pi-trash" (onClick)="onDeleteDocument(document)" class="mr-2"/>
                  </div>
                </td>
              </tr>
          </ng-template>
        </p-table>

        <p-divider></p-divider>
        <h2>{{'UserPages.UserClosedSale.UserCreateClosedSale.ImporterInformationTitle' | translate}}</h2>
        <div class="flex grid">
          <div class="col-12">
            <p-card header="Importador">
              <p>{{'UserPages.UserClosedSale.UserCreateClosedSale.SelectImporterDescriptionText' | translate}}:</p>
              <p-table [loading]="loading"
                [value]="importers"
                selectionMode="single"
                [(selection)]="selectedImporter"
                dataKey="id"
                (onRowSelect)="loadConsigneesNotifies()"
                responsiveLayout="stack"
                [breakpoint]="'600px'">
                <ng-template pTemplate="header">
                  <tr>
                    <th>
                      <div class="flex justify-content-center align-items-center">{{'UserPages.UserClosedSale.UserCreateClosedSale.ImporterTable.NameTableHeader' | translate}}</div>
                    </th>
                    <th>
                      <div class="flex justify-content-center align-items-center">{{'UserPages.UserClosedSale.UserCreateClosedSale.ImporterTable.ContactHeader' | translate}}</div>
                    </th>
                    <th>
                      <div class="flex justify-content-center align-items-center">{{'UserPages.UserClosedSale.UserCreateClosedSale.ImporterTable.CountryHeader' | translate}}</div>
                    </th>
                    <th>
                      <div class="flex justify-content-center align-items-center">{{'UserPages.UserClosedSale.UserCreateClosedSale.ImporterTable.EmailHeader' | translate}}</div>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-importer>
                  <tr [pSelectableRow]="importer">
                    <td><div class="flex justify-content-center align-items-center">{{importer.companyName}}</div></td>
                    <td><div class="flex justify-content-center align-items-center">{{importer.contactName}}</div></td>
                    <td><div class="flex justify-content-center align-items-center">{{importer.contactCountry | countryDescription}}</div></td>
                    <td><div class="flex justify-content-center align-items-center">{{importer.contactEmail}}</div></td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                  <small *ngIf="!selectedImporter" class="p-error">{{'UserPages.UserClosedSale.UserCreateClosedSale.ImporterTable.ImporterNotSelectedText' | translate}}</small>
                </ng-template>
              </p-table>
            </p-card>
          </div>
          <div class="col-12 md:col-6 lg:col-6">
            <p-card header="Consignee">
              <p>{{'UserPages.UserClosedSale.UserCreateClosedSale.SelectConsigneeDescriptionText' | translate}}:</p>
              <p-table [loading]="loading"
                [value]="consignees"
                selectionMode="single"
                [(selection)]="selectedConsignee"
                dataKey="id"
                responsiveLayout="stack"
                [breakpoint]="'600px'">
                <ng-template pTemplate="header">
                  <tr>
                    <th>
                      <div class="flex justify-content-center align-items-center">{{'UserPages.UserClosedSale.UserCreateClosedSale.ConsigneeTable.NameTableHeader' | translate}}</div>
                    </th>
                    <th>
                      <div class="flex justify-content-center align-items-center">{{'UserPages.UserClosedSale.UserCreateClosedSale.ConsigneeTable.ContactHeader' | translate}}</div>
                    </th>
                    <th>
                      <div class="flex justify-content-center align-items-center">{{'UserPages.UserClosedSale.UserCreateClosedSale.ConsigneeTable.CountryHeader' | translate}}</div>
                    </th>
                    <th>
                      <div class="flex justify-content-center align-items-center">{{'UserPages.UserClosedSale.UserCreateClosedSale.ConsigneeTable.EmailHeader' | translate}}</div>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-consignee>
                  <tr [pSelectableRow]="consignee">
                    <td><div class="flex justify-content-center align-items-center">{{consignee.companyName}}</div></td>
                    <td><div class="flex justify-content-center align-items-center">{{consignee.contactName}}</div></td>
                    <td><div class="flex justify-content-center align-items-center">{{consignee.contactAddress}}, {{consignee.contactCity}}</div></td>
                    <td><div class="flex justify-content-center align-items-center">{{consignee.contactCountry | countryDescription}}</div></td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                  <small *ngIf="selectedImporter && !selectedConsignee" class="p-error">{{'UserPages.UserClosedSale.UserCreateClosedSale.ConsigneeTable.ConsigneeNotSelectedText' | translate}}</small>
                </ng-template>
              </p-table>
            </p-card>
          </div>
          <div class="col-12 md:col-6 lg:col-6">
            <p-card header="Notify">
              <p>{{'UserPages.UserClosedSale.UserCreateClosedSale.SelectNotifyDescriptionText' | translate}}:</p>
              <p-table [loading]="loading"
                [value]="notifies"
                selectionMode="single"
                [(selection)]="selectedNotify"
                dataKey="id"
                responsiveLayout="stack"
                [breakpoint]="'600px'">
                <ng-template pTemplate="header">
                  <tr>
                    <th>
                      <div class="flex justify-content-center align-items-center">{{'UserPages.UserClosedSale.UserCreateClosedSale.NotifyTable.NameTableHeader' | translate}}</div>
                    </th>
                    <th>
                      <div class="flex justify-content-center align-items-center">{{'UserPages.UserClosedSale.UserCreateClosedSale.NotifyTable.ContactHeader' | translate}}</div>
                    </th>
                    <th>
                      <div class="flex justify-content-center align-items-center">{{'UserPages.UserClosedSale.UserCreateClosedSale.NotifyTable.CountryHeader' | translate}}</div>
                    </th>
                    <th>
                      <div class="flex justify-content-center align-items-center">{{'UserPages.UserClosedSale.UserCreateClosedSale.NotifyTable.EmailHeader' | translate}}</div>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-notify>
                  <tr [pSelectableRow]="notify">
                    <td><div class="flex justify-content-center align-items-center">{{notify.companyName}}</div></td>
                    <td><div class="flex justify-content-center align-items-center">{{notify.contactName}}</div></td>
                    <td><div class="flex justify-content-center align-items-center">{{notify.contactAddress}}, {{notify.contactCity}}</div></td>
                    <td><div class="flex justify-content-center align-items-center">{{notify.contactCountry | countryDescription}}</div></td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                  <small *ngIf="selectedImporter && !selectedNotify" class="p-error">{{'UserPages.UserClosedSale.UserCreateClosedSale.NotifyTable.NotifyNotSelectedText' | translate}}</small>
                </ng-template>
              </p-table>
            </p-card>
          </div>
        </div>

        <p-divider></p-divider>
        <h2>{{'UserPages.UserClosedSale.UserCreateClosedSale.ProgramLoad' | translate}}</h2>
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-6 lg:col-3" style="margin-bottom: 40px;">
            <label style="font-weight: bolder;" for="importToCountryId">{{'UserPages.UserClosedSale.UserCreateClosedSale.DestinationCountryFieldLabel' | translate}} *</label>
            <p-dropdown id="importToCountryId" [options]="countries" formControlName="importToCountryId" optionLabel="name" optionValue="id" (onChange)="loadPorts()" [filter]="true" filterBy="name" [showClear]="true" placeholder="{{'UserPages.UserClosedSale.UserCreateClosedSale.DestinationCountryFieldPlaceholder' | translate}}">
              <ng-template pTemplate="selectedItem">
                <div class="flex align-items-center gap-2" *ngIf="selectedCountryTo">
                  <img [src]="'../../../../assets/img/flags/' + selectedCountryTo.code + '.png'" style="width: 18px" alt=""/>
                  <div>{{ selectedCountryTo | countryDescription }}</div>
                </div>
              </ng-template>
              <ng-template let-country pTemplate="item">
                <div class="flex align-items-center gap-2">
                  <img [src]="'../../../../assets/img/flags/' + country.code + '.png'" style="width: 18px" alt=""/>
                  <div>{{ country | countryDescription }}</div>
                </div>
              </ng-template>
            </p-dropdown>
            <small *ngIf="submitStep2 && formGroup.controls['importToCountryId'].invalid" class="p-error">{{getErrorMessage(formGroup, 'importToCountryId')}}</small>
          </div>
          <div class="field col-12 md:col-6 lg:col-3" style="margin-bottom: 40px;">
            <label style="font-weight: bolder;" for="portToId">{{'UserPages.UserClosedSale.UserCreateClosedSale.DestinationPortFieldLabel' | translate}} *</label>
            <p-dropdown id="portToId" [options]="ports" formControlName="portToId" optionLabel="name" optionValue="id" [filter]="true" filterBy="name" [showClear]="true" placeholder="{{'UserPages.UserClosedSale.UserCreateClosedSale.DestinationCountryFieldPlaceholder' | translate}}"></p-dropdown>
            <small *ngIf="submitStep2 && formGroup.controls['portToId'].invalid" class="p-error">{{getErrorMessage(formGroup, 'portToId')}}</small>
          </div>
          <div class="field col-12 md:col-6 lg:col-3" style="margin-bottom: 40px;">
            <label style="font-weight: bolder;" for="palletQuantity">{{'UserPages.UserClosedSale.UserCreateClosedSale.PalletsPerWeekFieldLabel' | translate}} *</label>
            <p-inputNumber id="palletQuantity" formControlName="palletQuantity" [showButtons]="false" [useGrouping]="false" [min]="1" placeholder="{{'UserPages.UserClosedSale.UserCreateClosedSale.PalletsPerWeekFieldPlaceholder' | translate}}"/>
            <small *ngIf="submitStep2 && formGroup.controls['palletQuantity'].invalid" class="p-error">{{getErrorMessage(formGroup, 'palletQuantity')}}</small>
          </div>
          <div class="field col-12 md:col-6 lg:col-3" style="margin-bottom: 40px;"></div>
          <div class="field col-12 md:col-6 lg:col-3" style="margin-bottom: 40px;">
            <label style="font-weight: bolder;" for="startWeek">{{'UserPages.UserClosedSale.UserCreateClosedSale.StartWeekFieldLabel' | translate}} *</label>
            <p-inputNumber id="startWeek" formControlName="startWeek" [showButtons]="true" [useGrouping]="false" [min]="1" [max]="52" placeholder="{{'UserPages.UserClosedSale.UserCreateClosedSale.StartWeekFieldPlaceholder' | translate}}"/>
            <small *ngIf="submitStep2 && formGroup.controls['startWeek'].invalid" class="p-error">{{getErrorMessage(formGroup, 'startWeek')}}</small>
          </div>
          <div class="field col-12 md:col-6 lg:col-3" style="margin-bottom: 40px;">
            <label style="font-weight: bolder;" for="startWeekYear">{{'UserPages.UserClosedSale.UserCreateClosedSale.StartYearFieldLabel' | translate}} *</label>
            <p-dropdown id="startWeekYear" formControlName="startWeekYear" [options]="years" optionLabel="label" optionValue="value" placeholder="{{'UserPages.UserClosedSale.UserCreateClosedSale.StartYearFieldPlaceholder' | translate}}">
            </p-dropdown>
            <small *ngIf="submitStep2 && formGroup.controls['startWeekYear'].invalid" class="p-error">{{getErrorMessage(formGroup, 'startWeekYear')}}</small>
          </div>
          <div class="field col-12 md:col-6 lg:col-3" style="margin-bottom: 40px;">
            <label style="font-weight: bolder;" for="endWeek">{{'UserPages.UserClosedSale.UserCreateClosedSale.EndWeekFieldLabel' | translate}}</label>
            <p-inputNumber id="endWeek" formControlName="endWeek" [showButtons]="true" [useGrouping]="false" [min]="1" [max]="52" placeholder="{{'UserPages.UserClosedSale.UserCreateClosedSale.EndWeekFieldPlaceholder' | translate}}"/>
          </div>
          <div class="field col-12 md:col-6 lg:col-3" style="margin-bottom: 40px;">
            <label style="font-weight: bolder;" for="endWeekYear">{{'UserPages.UserClosedSale.UserCreateClosedSale.EndYearFieldLabel' | translate}}</label>
            <p-dropdown id="endWeekYear" formControlName="endWeekYear" [options]="years" optionLabel="label" optionValue="value" placeholder="{{'UserPages.UserClosedSale.UserCreateClosedSale.EndYearFieldPlaceholder' | translate}}">
            </p-dropdown>
          </div>
          <div class="field col-12" style="margin-top: 40px;">
            <p-divider></p-divider>
            <h2>{{'UserPages.UserClosedSale.UserCreateClosedSale.TotalTitle' | translate}}</h2>
          </div>
          <div class="field col-12 md:col-6 lg:col-3" style="margin-bottom: 40px;">
            <label>{{'UserPages.UserClosedSale.UserCreateClosedSale.TotalWeeksLabel' | translate}}: </label>
            <span style="font-weight: bolder; font-size: 1.5em;"> {{ totalWeeks }}</span>
          </div>
          <div class="field col-12 md:col-6 lg:col-3" style="margin-bottom: 40px;">
            <label>{{'UserPages.UserClosedSale.UserCreateClosedSale.TotalPalletsScheduled' | translate}}: </label>
            <span style="font-weight: bolder; font-size: 1.5em;"> {{totalPallets}}</span>
          </div>
          <div class="field col-12 md:col-6 lg:col-3" style="margin-bottom: 40px;">
            <label>{{'UserPages.UserClosedSale.UserCreateClosedSale.TotalContainersPerWeek' | translate}}: </label>
            <span style="font-weight: bolder; font-size: 1.5em;"> {{containersPerWeek}}</span>
          </div>
          <div class="field col-12 md:col-6 lg:col-3" style="margin-bottom: 40px;"></div>
        </div>

        <div class="grid" style="margin-top: 50px;">
          <div class="col-6"></div>
          <div class="col-6">
            <div class="p-fluid formgrid grid">
              <div class="col-6">
                <p-button label="{{'UserPages.UserClosedSale.UserCreateClosedSale.CancelButton' | translate}}" (click)="goToCloseSalesPage()" [loading]="loading" styleClass="p-button-outlined"></p-button>
              </div>
              <div class="col-6">
                <p-button label="{{'UserPages.UserClosedSale.UserCreateClosedSale.SaveButton' | translate}}" (onClick)="save()" [loading]="loading"></p-button>
              </div>
            </div>
          </div>
        </div>

      </p-card>
    </div>

    <div id="step3" *ngIf="step == 'step3'">
      <div class="flex align-items-center justify-content-center flex-column">
        <i class="pi pi-check-circle" style="font-size: 2.5rem"></i>
        <h1>{{'UserPages.UserClosedSale.UserCreateClosedSale.SuccessMessage' | translate}}</h1>
        <p-button label="{{'UserPages.UserClosedSale.UserCreateClosedSale.AcceptButton' | translate}}" (click)="goToCloseSaleOrdersPage()"></p-button>
      </div>
    </div>
  </form>
</app-user-dashboard>

<p-dialog header="{{'UserPages.UserClosedSale.UserCreateClosedSale.NewFriedStorageModal.ModalTitle' | translate}}" [(visible)]="loadFridgestorageDialogVisible" [style]="{width: '50%'}" [modal]="true">
  <form [formGroup]="fridgestorageFormGroup">
    <div class="p-fluid formgrid grid" style="padding: 20px;">
      <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
        <label for="fridgestorageName">{{'UserPages.UserClosedSale.UserCreateClosedSale.NewFriedStorageModal.NameFieldLabel' | translate}} *</label>
        <input id="fridgestorageName" formControlName="fridgestorageName" type="text" pInputText [maxlength]="300" required autofocus placeholder="{{'UserPages.UserClosedSale.UserCreateClosedSale.NewFriedStorageModal.NameFieldPlaceholder' | translate}}"/>
        <small *ngIf="submit && fridgestorageFormGroup.controls['fridgestorageName'].invalid" class="p-error">{{getErrorMessage(fridgestorageFormGroup, 'fridgestorageName')}}</small>
      </div>
      <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
        <label for="fridgestorageVat">{{'UserPages.UserClosedSale.UserCreateClosedSale.NewFriedStorageModal.VatFieldLabel' | translate}} *</label>
        <input id="fridgestorageVat" pInputText required formControlName="fridgestorageVat" [maxlength]="20" placeholder="{{'UserPages.UserClosedSale.UserCreateClosedSale.NewFriedStorageModal.VatFieldPlaceholder' | translate}}"/>
        <small *ngIf="submit && fridgestorageFormGroup.controls['fridgestorageVat'].invalid" class="p-error">{{getErrorMessage(fridgestorageFormGroup, 'fridgestorageVat')}}</small>
      </div>
      <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
        <label for="fridgestorageContactName">{{'UserPages.UserClosedSale.UserCreateClosedSale.NewFriedStorageModal.ContactNameFieldLabel' | translate}} *</label>
        <input id="fridgestorageContactName" formControlName="fridgestorageContactName" type="text" pInputText [maxlength]="300" required placeholder="{{'UserPages.UserClosedSale.UserCreateClosedSale.NewFriedStorageModal.ContactNameFieldPlaceholder' | translate}}"/>
        <small *ngIf="submit && fridgestorageFormGroup.controls['fridgestorageContactName'].invalid" class="p-error">{{getErrorMessage(fridgestorageFormGroup, 'fridgestorageContactName')}}</small>
      </div>
      <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
        <label for="fridgestorageContactEmail">{{'UserPages.UserClosedSale.UserCreateClosedSale.NewFriedStorageModal.ContactEmailFieldLabel' | translate}} *</label>
        <input id="fridgestorageContactEmail" formControlName="fridgestorageContactEmail" type="text" pInputText [maxlength]="100" required placeholder="{{'UserPages.UserClosedSale.UserCreateClosedSale.NewFriedStorageModal.ContactEmailFieldPlaceholder' | translate}}"/>
        <small *ngIf="submit && fridgestorageFormGroup.controls['fridgestorageContactEmail'].invalid" class="p-error">{{getErrorMessage(fridgestorageFormGroup, 'fridgestorageContactEmail')}}</small>
      </div>
      <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
        <label for="fridgestorageContactAddress">{{'UserPages.UserClosedSale.UserCreateClosedSale.NewFriedStorageModal.FactoryAddressLabel' | translate}} *</label>
        <input id="fridgestorageContactAddress" formControlName="fridgestorageContactAddress" type="text" [maxlength]="300" pInputText required placeholder="{{'UserPages.UserClosedSale.UserCreateClosedSale.NewFriedStorageModal.FactoryAddressPlaceholder' | translate}}"/>
        <small *ngIf="submit && fridgestorageFormGroup.controls['fridgestorageContactAddress'].invalid" class="p-error">{{getErrorMessage(fridgestorageFormGroup, 'fridgestorageContactAddress')}}</small>
      </div>
      <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
        <label for="fridgestorageContactCity">{{'UserPages.UserClosedSale.UserCreateClosedSale.NewFriedStorageModal.ContactCityFieldLabel' | translate}} *</label>
        <input id="fridgestorageContactCity" formControlName="fridgestorageContactCity" type="text" pInputText [maxlength]="100" required placeholder="{{'UserPages.UserClosedSale.UserCreateClosedSale.NewFriedStorageModal.ContactCityFieldPlaceholder' | translate}}"/>
        <small *ngIf="submit && fridgestorageFormGroup.controls['fridgestorageContactCity'].invalid" class="p-error">{{getErrorMessage(fridgestorageFormGroup, 'fridgestorageContactCity')}}</small>
      </div>
      <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
        <label for="fridgestorageContactCountryId">{{'UserPages.UserClosedSale.UserCreateClosedSale.NewFriedStorageModal.CountryFieldLabel' | translate}} *</label>
        <p-dropdown
            id="fridgestorageContactCountryId"
            [options]="countries"
            formControlName="fridgestorageContactCountryId"
            optionLabel="name"
            optionValue="id"
            [filter]="true"
            filterBy="name"
            [showClear]="true"
            placeholder="{{'UserPages.UserClosedSale.UserCreateClosedSale.NewFriedStorageModal.CountryFieldPlaceholder' | translate}}"
            appendTo="body">
        </p-dropdown>
        <small *ngIf="submit && fridgestorageFormGroup.controls['fridgestorageContactCountryId'].invalid" class="p-error">{{getErrorMessage(fridgestorageFormGroup, 'fridgestorageContactCountryId')}}</small>
      </div>
      <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
        <label for="fridgestorageContactPhone">{{'UserPages.UserClosedSale.UserCreateClosedSale.NewFriedStorageModal.FridgeStorageContactPhoneLabel' | translate}} *</label>
        <input id="fridgestorageContactPhone" formControlName="fridgestorageContactPhone" type="text" pInputText [maxlength]="50" placeholder="{{'UserPages.UserClosedSale.UserCreateClosedSale.NewFriedStorageModal.FridgeStorageContactPhonePlaceholder' | translate}}"/>
        <small *ngIf="submit && fridgestorageFormGroup.controls['fridgestorageContactPhone'].invalid" class="p-error">{{getErrorMessage(fridgestorageFormGroup, 'fridgestorageContactPhone')}}</small>
      </div>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <p-button label="{{'UserPages.UserClosedSale.UserCreateClosedSale.NewFriedStorageModal.CloseButton' | translate}}" (onClick)="loadFridgestorageDialogVisible = false" styleClass="p-button-outlined" [loading]="loading"></p-button>
    <p-button label="{{'UserPages.UserClosedSale.UserCreateClosedSale.NewFriedStorageModal.SaveButton' | translate}}" (onClick)="saveFridgestorage()" [loading]="loading"></p-button>
  </ng-template>
</p-dialog>

<p-dialog header="{{'UserPages.UserProductCertificates.PageTitle' | translate}}" [(visible)]="productCertificateDialogVisible" [style]="{width: '50%'}" [modal]="true">
  <form [formGroup]="productCertificateFormGroup" #formDirective="ngForm">
    <div class="p-fluid formgrid grid" style="padding: 20px;">
      <div class="field col-12 md:col-6 lg:col-6" style="margin-bottom: 40px;">
        <label for="name" style="font-weight: bolder;">{{'UserPages.UserProductCertificates.Form.NameLabel' | translate}} *</label>
        <input id="name" formControlName="name" type="text" required pInputText [maxlength]="300" placeholder="{{'UserPages.UserProductCertificates.Form.NameLabel' | translate}}"/>
        <small *ngIf="submitProductCertificate && productCertificateFormGroup.controls['name'].invalid" class="p-error">{{getErrorMessage(productCertificateFormGroup, 'name')}}</small>
      </div>
      <div class="field col-12 md:col-6 lg:col-6" style="margin-bottom: 40px;">
        <label for="certificateType" style="font-weight: bolder;">{{'UserPages.UserProductCertificates.Form.CertificateTypeLabel' | translate}} *</label>
        <p-dropdown inputId="certificateType" formControlName="productCertificateTypeId" [options]="certificateTypes" optionLabel="name" optionValue="id" placeholder="{{'UserPages.UserProductCertificates.Form.CertificateTypeLabel' | translate}}"></p-dropdown>
        <small *ngIf="submitProductCertificate && productCertificateFormGroup.controls['productCertificateTypeId'].invalid" class="p-error">{{getErrorMessage(productCertificateFormGroup, 'productCertificateTypeId')}}</small>
      </div>
      <div class="field col-12 md:col-6 lg:col-6" style="margin-bottom: 40px;">
        <label for="issueDate" style="font-weight: bolder;">{{'UserPages.UserProductCertificates.Form.IssueDateLabel' | translate}} </label>
        <p-calendar
          id="issueDate"
          appendTo="body"
          formControlName="documentIssueDate"
          [showButtonBar]="true"
          [showIcon]="true"
          placeholder="{{'UserPages.UserProductCertificates.Form.IssueDateLabel' | translate}}"
          [showWeek]="true"
          [firstDayOfWeek]="1"></p-calendar>
      </div>
      <div class="field col-12 md:col-6 lg:col-6" style="margin-bottom: 40px;">
        <label for="expirationDate" style="font-weight: bolder;">{{'UserPages.UserProductCertificates.Form.ExpireDateLabel' | translate}}</label>
        <p-calendar
          id="expirationDate"
          appendTo="body"
          formControlName="expirationDate"
          [showButtonBar]="true"
          [showIcon]="true"
          placeholder="{{'UserPages.UserProductCertificates.Form.ExpireDateLabel' | translate}}"
          [showWeek]="true"
          [firstDayOfWeek]="1"></p-calendar>
      </div>
      <div class="field col-12" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserProductCertificates.Form.LoadDocumentLabel' | translate}}</label>
        <p-fileUpload
          #productCertificateFileUploader
          (onSelect)="onSelectProductCertificateFile($event)"
          (onClear)="onClearProductCertificateFile($event)"
          [showUploadButton]="false"
          [multiple]="false"
          accept=".pdf"
          [maxFileSize]="5000000"
          chooseLabel="{{'UserPages.UserProductCertificates.Form.SelectFile' | translate}}"
          cancelLabel="{{'UserPages.UserProductCertificates.Form.CancelSelectFile' | translate}}"/>
      </div>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <p-button label="{{'UserPages.UserProductCertificates.Form.BackButtonLabel' | translate}}" (onClick)="productCertificateDialogVisible = false" styleClass="p-button-outlined"></p-button>
    <p-button label="{{'UserPages.UserProductCertificates.Form.SaveButtonLabel' | translate}}" (onClick)="saveProductCertificate()" [loading]="loading"></p-button>
  </ng-template>
</p-dialog>

<p-dialog header="{{'UserPages.UserExportOfferDocument.PageTitle' | translate}}" [(visible)]="documentDialogVisible" [style]="{width: '50%'}" [modal]="true">
  <form [formGroup]="documentFormGroup" #documentFormDirective="ngForm">
    <div class="p-fluid formgrid grid" style="padding: 20px;">
      <div class="field col-12 md:col-12 lg:col-12" style="margin-bottom: 40px;">
        <label for="name" style="font-weight: bolder;">{{'UserPages.UserExportOfferDocument.Form.NameLabel' | translate}} *</label>
        <input id="name" formControlName="name" type="text" required pInputText [maxlength]="300" placeholder="{{'UserPages.UserExportOfferDocument.Form.NameLabel' | translate}}"/>
        <small *ngIf="submitDocument && documentFormGroup.controls['name'].invalid" class="p-error">{{getErrorMessage(documentFormGroup, 'name')}}</small>
      </div>
      <div class="field col-12" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserExportOfferDocument.Form.LoadDocumentLabel' | translate}}</label>
        <p-fileUpload
          #documentFileUploader
          (onSelect)="onSelectDocumentFile($event)"
          (onClear)="onClearDocumentFile($event)"
          [showUploadButton]="false"
          [multiple]="false"
          accept=".pdf"
          [maxFileSize]="5000000"
          chooseLabel="{{'UserPages.UserExportOfferDocument.Form.SelectFile' | translate}}"
          cancelLabel="{{'UserPages.UserExportOfferDocument.Form.CancelSelectFile' | translate}}"/>
      </div>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <p-button label="{{'UserPages.UserExportOfferDocument.Form.BackButtonLabel' | translate}}" (onClick)="documentDialogVisible = false" styleClass="p-button-outlined"></p-button>
    <p-button label="{{'UserPages.UserExportOfferDocument.Form.SaveButtonLabel' | translate}}" (onClick)="saveDocument()" [loading]="loading"></p-button>
  </ng-template>
</p-dialog>

<p-toast></p-toast>
