import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Routes } from 'src/app/infrastructure/routes';

@Component({
  selector: 'app-order-not-found',
  templateUrl: './order-not-found.component.html',
  styleUrls: ['./order-not-found.component.css']
})
export class OrderNotFoundComponent implements OnInit {


  constructor(private readonly _router: Router) {
  }

  ngOnInit(): void {
  }

  redirect() {
    this._router.navigate([Routes.userImportOrdersCurrent]).then()
  }
}
