import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, MessageService } from 'primeng/api';
import { AirportGateway } from 'src/app/application/gateway/AirportGateway';
import { CountryGateway } from 'src/app/application/gateway/CountryGateway';
import { DeliveryDiscountGateway } from 'src/app/application/gateway/DeliveryDiscountGateway';
import { DeliveryGateway } from 'src/app/application/gateway/DeliveryGateway';
import { ExportOfferGateway } from 'src/app/application/gateway/ExportOfferGateway';
import { FrozenFruitExportOfferGateway } from 'src/app/application/gateway/FrozenFruitExportOfferGateway';
import { PortGateway } from 'src/app/application/gateway/PortGateway';
import { Airport } from 'src/app/domain/models/Airport';
import { Country } from 'src/app/domain/models/Country';
import { Delivery, DeliveryInformation, UpdateDeliveryRequest } from 'src/app/domain/models/Delivery';
import { DeliveryDiscount, NewDeliveryDiscount } from 'src/app/domain/models/DeliveryDiscount';
import { ExportOffer } from 'src/app/domain/models/ExportOffer';
import { FrozenFruitExportOffer } from 'src/app/domain/models/FrozenFruitExportOffer';
import { GenericOrderDetail } from 'src/app/domain/models/ImportOrder';
import { Packaging } from 'src/app/domain/models/Packaging';
import { Port } from 'src/app/domain/models/Port';
import { ProductCertificateType } from 'src/app/domain/models/ProductCertificateType';
import { Importer } from 'src/app/domain/models/Profile';
import { Routes } from 'src/app/infrastructure/routes';
import { ErrorMessageUtil } from 'src/app/ui/util/error-message-util';

@Component({
  selector: 'app-forwarder-delivery',
  templateUrl: './forwarder-delivery.component.html',
  styleUrls: ['./forwarder-delivery.component.css'],
  providers: [MessageService]
})
export class ForwarderDeliveryComponent implements OnInit {

  tabMenuItems: MenuItem[] = []
  activeItem: MenuItem = {}

  orderId: any | undefined
  deliveryInformation: DeliveryInformation | undefined
  delivery: Delivery | undefined
  importer: Importer | undefined
  selectedExportOffer: ExportOffer | undefined

  selectedDiscount: DeliveryDiscount | undefined
  discounts: DeliveryDiscount[] = []
  orderDetails: GenericOrderDetail[] = []
  ports: Port[] = []
  countries: Country[] = []
  selectedPackagingTypes: Packaging[] = []
  selectedProductCertificateTypes: ProductCertificateType[] = []
  frozenFruitExportOffer: FrozenFruitExportOffer | undefined

  airPorts: Airport[] = []

  loading: boolean = false
  isCloseSale: boolean = false
  submit: boolean = false
  visibleOfferDetailDialog: boolean = false
  discountDialogVisible: boolean = false
  submitDiscount: boolean = false

  formGroup: FormGroup = this._formBuilder.group({
    bl: [''],
    booking: [''],
    containerNumber: [''],
    deliveryInstructions: [''],
    grossWeight: [''],
    containerCbm: [''],
    containerTemperature: [''],
    placeReceipt: [''],
    departurePortId: ['', Validators.required],
    destinationPortId: ['', Validators.required],
    placeDelivery: [''],
    ship: [''],
    voyageNumber: [''],
    typeMovement: [''],
    oceanFreight: [null],
    inland: [null],
    localCharges: [null],
    customs: [null],
    otherCosts: [null],
    freightPayableAt: [''],
    shippedOnBoard: [null],
    placeIssue: [''],
    dateIssue: [null],
    excessValueDeclaration: [''],
    notifierContactName: [''],
    notifierContactEmail: [''],
    notifierContactCity: [''],
    notifierContactAddress: [''],
    notifierCompanyName: [''],
    notifierContactPhone: [''],
    notifierVAT: [''],
    notifierContactCountryId: [''],
    consigneeContactName: [''],
    consigneeContactEmail: [''],
    consigneeContactPhone: [''],
    consigneeContactAddress: [''],
    consigneeContactCity: [''],
    consigneeCompanyName: [''],
    consigneeVAT: [''],
    consigneeContactCountryId: [''],
    week: [null],
    surcharge: [null],
    etd: [null],
    eta: [null],
    handling: [null],
    gateOut: [null],
    stackingStart: [null],
    stackingEnd: [null],
    dus: [''],
    sps: [''],
    cutoffDate: [null],
    carrierName: [''],
    departurePatentTruck: [''],
    departurePatentCar: [''],
    mbl: [''],
    ot: [''],
    ref1: [''],
    ref2: [''],
    ctr: [''],
    seal: [''],
    departureAirportId: ['', Validators.required],
    destinationAirportId: ['', Validators.required],

  })

  discountFormGroup: FormGroup = this._formBuilder.group({
    description: ['', Validators.required],
    amount: [null, Validators.required]
  })

  constructor(private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _deliveryService: DeliveryGateway,
    private _portService: PortGateway,
    private _countryService: CountryGateway,
    private _deliveryDiscountService: DeliveryDiscountGateway,
    private _exportOfferService: ExportOfferGateway,
    private _frozenFruitExportOfferService: FrozenFruitExportOfferGateway,
    public translate: TranslateService,
    private _messageService: MessageService,
    private _airportService: AirportGateway
  ) {

    this._activatedRoute.params.subscribe(params => {
      this.orderId = params['orderId']
    })
  }

  ngOnInit(): void {
    this.loadDelivery()
  }

  loadDelivery() {
    if(this.orderId) {
      this.loading = true
      this._deliveryService.getByImportOrderId(this.orderId).subscribe({
        next: (v) => {
          this.deliveryInformation = v
          this.delivery = this.deliveryInformation.delivery
          this.importer = this.deliveryInformation.importer
          this.orderDetails = this.deliveryInformation.orderDetails
          this.discounts = this.deliveryInformation.discounts
          this.isCloseSale = this.deliveryInformation.isCloseSale
          this.loadForm()
        },
        error: (e) => {
          let errorMessage = ''
            const lang = this.translate.currentLang
            switch(lang) {
              case 'es':
                errorMessage = e.error.messageEs
                break
              case 'en':
                errorMessage = e.error.messageEn
                break
              default:
                errorMessage = this.translate.instant('MessageService.Delivery.SingleRetrieveError')
          }
          this._messageService.add({ 
            severity: 'error', 
            summary: this.translate.instant('MessageService.Summary.FailedAction'), 
            detail: errorMessage
          });
        },
        complete: () => {
          this.loading = false
          console.info('Delivery query successfully')
        }
      })
    }
  }

  validateForm(){
    if(this.delivery){
      if(this.delivery.transportType === 0){
        this.formGroup.controls['destinationPortId'].setValidators([Validators.required]); 
        this.formGroup.controls['departurePortId'].setValidators([Validators.required]); 
        this.formGroup.controls['departureAirportId'].clearValidators();                
        this.formGroup.controls['destinationAirportId'].clearValidators();                
      } else {                
        this.formGroup.controls['departureAirportId'].setValidators([Validators.required]); 
        this.formGroup.controls['destinationAirportId'].setValidators([Validators.required]); 
        this.formGroup.controls['departurePortId'].clearValidators();                
        this.formGroup.controls['destinationPortId'].clearValidators();               
      }
      this.formGroup.controls['departureAirportId'].updateValueAndValidity() 
      this.formGroup.controls['destinationAirportId'].updateValueAndValidity(); 
      this.formGroup.controls['departurePortId'].updateValueAndValidity();                
      this.formGroup.controls['destinationPortId'].updateValueAndValidity();
    }
  }

  loadPorts() {
    this._portService.getAll().subscribe({
      next: (v) => {
        this.ports = v
      },
      error: (e) => {
        console.log(e)
        let errorMessage = ''
          const lang = this.translate.currentLang
          switch(lang) {
            case 'es':
              errorMessage = e.error.messageEs
              break
            case 'en':
              errorMessage = e.error.messageEn
              break
            default:
              errorMessage = this.translate.instant('MessageService.Port.DestinationPortManyRetrieveError')
        }
        this._messageService.add({ 
          severity: 'error', 
          summary: this.translate.instant('MessageService.Summary.FailedAction'), 
          detail: errorMessage
        });
      },
      complete: () => {
        this.loading = false
        console.info('Port query successfully')
      }
    })
  }

  loadCountries() {
    this._countryService.getAll().subscribe({
      next: (v) => {
        this.countries = v
      },
      error: (e) => {
        console.log(e)
        let errorMessage = ''
          const lang = this.translate.currentLang
          switch(lang) {
            case 'es':
              errorMessage = e.error.messageEs
              break
            case 'en':
              errorMessage = e.error.messageEn
              break
            default:
              errorMessage = this.translate.instant('MessageService.Country.ManyRetrieveError')
        }
        this._messageService.add({ 
          severity: 'error', 
          summary: this.translate.instant('MessageService.Summary.FailedAction'), 
          detail: errorMessage
        });
      },
      complete: () => {
        this.loading = false
        console.info('Port query successfully')
      }
    })
  }

  loadAirports()
  {
    this.loading = true
    this._airportService.getAll().subscribe({
      next: (v) => {
        this.airPorts = v
        this.loading = false
      },
      error: (e) => {
        this.loading = false
        this._messageService.add({ 
          severity: 'error', 
          summary: this.translate.instant('MessageService.Summary.FailedAction'), 
          detail: this.translate.instant('MessageService.Airport.ManyRetrieveError')
        });
      }
    })
  }

  loadForm() {
    if(this.delivery) {
      this.loadPorts()
      this.loadAirports()
      this.loadCountries()
      this.formGroup.controls['bl'].setValue(this.delivery.bl)
      this.formGroup.controls['booking'].setValue(this.delivery.booking)
      this.formGroup.controls['containerNumber'].setValue(this.delivery.containerNumber)
      this.formGroup.controls['deliveryInstructions'].setValue(this.delivery.deliveryInstructions)
      this.formGroup.controls['grossWeight'].setValue(this.delivery.grossWeight)
      this.formGroup.controls['containerCbm'].setValue(this.delivery.containerCbm)
      this.formGroup.controls['containerTemperature'].setValue(this.delivery.containerTemperature)
      this.formGroup.controls['placeReceipt'].setValue(this.delivery.placeReceipt)
      this.formGroup.controls['departurePortId'].setValue(this.delivery.departurePortId)
      this.formGroup.controls['destinationPortId'].setValue(this.delivery.destinationPortId)
      this.formGroup.controls['placeDelivery'].setValue(this.delivery.placeDelivery)
      this.formGroup.controls['ship'].setValue(this.delivery.ship)
      this.formGroup.controls['voyageNumber'].setValue(this.delivery.voyageNumber)
      this.formGroup.controls['typeMovement'].setValue(this.delivery.typeMovement)
      this.formGroup.controls['oceanFreight'].setValue(this.delivery.oceanFreight)
      this.formGroup.controls['inland'].setValue(this.delivery.inland)
      this.formGroup.controls['localCharges'].setValue(this.delivery.localCharges)
      this.formGroup.controls['customs'].setValue(this.delivery.customs)
      this.formGroup.controls['otherCosts'].setValue(this.delivery.otherCosts)
      this.formGroup.controls['freightPayableAt'].setValue(this.delivery.freightPayableAt)
      this.formGroup.controls['shippedOnBoard'].setValue(this.delivery.shippedOnBoard ? new Date(this.delivery.shippedOnBoard) : null)
      this.formGroup.controls['placeIssue'].setValue(this.delivery.placeIssue)
      this.formGroup.controls['dateIssue'].setValue(this.delivery.dateIssue ? new Date(this.delivery.dateIssue) : null)
      this.formGroup.controls['excessValueDeclaration'].setValue(this.delivery.excessValueDeclaration)
      this.formGroup.controls['notifierContactName'].setValue(this.delivery.notifierContactName)
      this.formGroup.controls['notifierContactEmail'].setValue(this.delivery.notifierContactEmail)
      this.formGroup.controls['notifierContactCity'].setValue(this.delivery.notifierContactCity)
      this.formGroup.controls['notifierContactAddress'].setValue(this.delivery.notifierContactAddress)
      this.formGroup.controls['notifierCompanyName'].setValue(this.delivery.notifierCompanyName)
      this.formGroup.controls['notifierContactPhone'].setValue(this.delivery.notifierContactPhone)
      this.formGroup.controls['notifierVAT'].setValue(this.delivery.notifierVAT)
      this.formGroup.controls['notifierContactCountryId'].setValue(this.delivery.notifierContactCountryId)
      this.formGroup.controls['consigneeContactName'].setValue(this.delivery.consigneeContactName)
      this.formGroup.controls['consigneeContactEmail'].setValue(this.delivery.consigneeContactEmail)
      this.formGroup.controls['consigneeContactPhone'].setValue(this.delivery.consigneeContactPhone)
      this.formGroup.controls['consigneeContactAddress'].setValue(this.delivery.consigneeContactAddress)
      this.formGroup.controls['consigneeContactCity'].setValue(this.delivery.consigneeContactCity)
      this.formGroup.controls['consigneeCompanyName'].setValue(this.delivery.consigneeCompanyName)
      this.formGroup.controls['consigneeVAT'].setValue(this.delivery.consigneeVAT)
      this.formGroup.controls['consigneeContactCountryId'].setValue(this.delivery.consigneeContactCountryId)
      this.formGroup.controls['week'].setValue(this.delivery.week)
      this.formGroup.controls['surcharge'].setValue(this.delivery.surcharge)
      this.formGroup.controls['etd'].setValue(this.delivery.etd ? new Date(this.delivery.etd) : null)
      this.formGroup.controls['eta'].setValue(this.delivery.eta ? new Date(this.delivery.eta) : null)
      this.formGroup.controls['handling'].setValue(this.delivery.handling)
      this.formGroup.controls['gateOut'].setValue(this.delivery.gateOut)
      this.formGroup.controls['stackingStart'].setValue(this.delivery.stackingStart ? new Date(this.delivery.stackingStart) : null)
      this.formGroup.controls['stackingEnd'].setValue(this.delivery.stackingEnd ? new Date(this.delivery.stackingEnd) : null)
      this.formGroup.controls['dus'].setValue(this.delivery.dus)
      this.formGroup.controls['sps'].setValue(this.delivery.sps)
      this.formGroup.controls['cutoffDate'].setValue(this.delivery.cutoffDate ? new Date(this.delivery.cutoffDate) : null)
      this.formGroup.controls['carrierName'].setValue(this.delivery.carrierName)
      this.formGroup.controls['departurePatentTruck'].setValue(this.delivery.departurePatentTruck)
      this.formGroup.controls['departurePatentCar'].setValue(this.delivery.departurePatentCar)
      this.formGroup.controls['mbl'].setValue(this.delivery.mbl)
      this.formGroup.controls['ot'].setValue(this.delivery.ot)
      this.formGroup.controls['ref1'].setValue(this.delivery.ref1)
      this.formGroup.controls['ref2'].setValue(this.delivery.ref2)
      this.formGroup.controls['ctr'].setValue(this.delivery.ctr)
      this.formGroup.controls['seal'].setValue(this.delivery.seal)
      this.formGroup.controls['departureAirportId'].setValue(this.delivery.departureAirportId)
      this.formGroup.controls['destinationAirportId'].setValue(this.delivery.destinationAirportId)
      this.validateForm()
    }
  }

  getDetailTotalCases(detail: GenericOrderDetail): number {
    return detail.palletsQuantity > 0 && detail.boxesPerPallet > 0 ? detail.palletsQuantity * detail.boxesPerPallet : detail.boxesQuantity
  }

  getDetailTotalPrice(detail: GenericOrderDetail): number {
    if(detail.palletsQuantity > 0 && detail.boxesPerPallet > 0) {
      return detail.palletsQuantity * detail.boxesPerPallet * detail.price
    }
    else {
      return detail.boxesQuantity * detail.price
    }
  }

  getDetailPalletPrice(detail: GenericOrderDetail): number {
    let total = 0

    if(detail.palletPrices)
    {
      detail.palletPrices.forEach(x => {
        total += x.price ? x.boxPerPallets * x.price : 0;
      })
      return total
    }
    return 0
  }

  getErrorMessage(formGroup: FormGroup, fieldName: string): string {
    return ErrorMessageUtil.getErrorMessage(formGroup, this.translate, fieldName)
  }

  get totalCost(): number {
    return this.formGroup.controls['oceanFreight'].value +
      this.formGroup.controls['inland'].value +
      this.formGroup.controls['localCharges'].value +
      this.formGroup.controls['customs'].value +
      this.formGroup.controls['otherCosts'].value +
      this.formGroup.controls['surcharge'].value +
      this.formGroup.controls['handling'].value +
      this.formGroup.controls['gateOut'].value
  }

  openExportOfferDialog(detail: GenericOrderDetail) {
    this.selectedExportOffer = undefined
    this.frozenFruitExportOffer = undefined

    if(this.deliveryInformation && this.deliveryInformation.isFrozenFruit) {
      this.loading = true
      this._frozenFruitExportOfferService.getById(detail.frozenFruitExportOfferId!).subscribe({
        next: (v) => {
          this.frozenFruitExportOffer = v
          this.loading = false
          this.visibleOfferDetailDialog = true
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOffer.SingleRetrieveError') })
        },
        complete: () => console.info('Frozen fruit export offer query successfully')
      })
    }
    else {
      this.loading = true
      this._exportOfferService.getById(detail.exportOfferId!).subscribe({
        next: (v) => {
          this.selectedExportOffer = v
          if(this.selectedExportOffer.productCertificateTypes)
            this.selectedProductCertificateTypes = this.selectedExportOffer.productCertificateTypes

          if(this.selectedExportOffer.packagingTypes)
            this.selectedPackagingTypes = this.selectedExportOffer.packagingTypes

          this.loading = false
          this.visibleOfferDetailDialog = true
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOffer.SingleRetrieveError') })
        },
        complete: () => console.info('Frozen fruit export offer query successfully')
      })
    }
  }

  openDiscountCreateDialog(formDirective: FormGroupDirective) {
    formDirective.resetForm()
    this.discountFormGroup.controls['description'].setValue('')
    this.discountFormGroup.controls['amount'].setValue(null)
    this.selectedDiscount = undefined
    this.submitDiscount = false
    this.discountDialogVisible = true
  }

  openDiscountEditDialog(discount: DeliveryDiscount) {
    this.selectedDiscount = discount
    this.discountFormGroup.controls['description'].setValue(this.selectedDiscount.description)
    this.discountFormGroup.controls['amount'].setValue(this.selectedDiscount.amount)
    this.discountDialogVisible = true
  }

  deleteDiscount(id: number) {
    this.loading = true
    this._deliveryDiscountService.delete(id).subscribe({
      next: (v) => {
        this.loadDiscounts()
        this.loading = false
        this._messageService.add({ severity: 'success', summary: this.translate.instant('MessageService.Summary.SuccessAction'), detail: this.translate.instant('ForwarderPage.ForwarderDelivery.Discounts.Messages.SuccessDelete') })
      },
      error: (e) => {
        this.loading = false
        this._messageService.add({ severity: 'error', summary: this.translate.instant('MessageService.Summary.FailedAction'), detail: this.translate.instant('ForwarderPage.ForwarderDelivery.Discounts.Messages.ErrorDelete') });
      },
      complete: () =>  {
        this.loading = false
        this.submitDiscount = false
        console.info('Delivery Discount deleted successfully')
      }
    })
  }

  get totalDiscounts(): number {
    let total = 0
    if(this.discounts) {
      this.discounts.forEach(x => {
        total += x.amount
      })
    }
    return total
  }

  goToReviewOrders() {
    this._router.navigate([Routes.forwarderUserReviews]);
  }

  saveDelivery() {
    this.submit = true
    this.loading = true
    if(this.formGroup.valid) {
      if(this.delivery && this.orderId) {
        this.delivery.bl = this.formGroup.controls['bl'].value
        this.delivery.booking = this.formGroup.controls['booking'].value
        this.delivery.deliveryInstructions = this.formGroup.controls['deliveryInstructions'].value
        this.delivery.grossWeight = this.formGroup.controls['grossWeight'].value
        this.delivery.containerCbm = this.formGroup.controls['containerCbm'].value
        this.delivery.containerTemperature = this.formGroup.controls['containerTemperature'].value
        this.delivery.placeReceipt = this.formGroup.controls['placeReceipt'].value
        this.delivery.departurePortId = this.formGroup.controls['departurePortId'].value
        this.delivery.destinationPortId = this.formGroup.controls['destinationPortId'].value
        this.delivery.placeDelivery = this.formGroup.controls['placeDelivery'].value
        this.delivery.ship = this.formGroup.controls['ship'].value
        this.delivery.voyageNumber = this.formGroup.controls['voyageNumber'].value
        this.delivery.typeMovement = this.formGroup.controls['typeMovement'].value
        this.delivery.oceanFreight = this.formGroup.controls['oceanFreight'].value
        this.delivery.inland = this.formGroup.controls['inland'].value
        this.delivery.localCharges = this.formGroup.controls['localCharges'].value
        this.delivery.customs = this.formGroup.controls['customs'].value
        this.delivery.otherCosts = this.formGroup.controls['otherCosts'].value
        this.delivery.freightPayableAt = this.formGroup.controls['freightPayableAt'].value
        this.delivery.shippedOnBoard = this.formGroup.controls['shippedOnBoard'].value
        this.delivery.placeIssue = this.formGroup.controls['placeIssue'].value
        this.delivery.dateIssue = this.formGroup.controls['dateIssue'].value
        this.delivery.excessValueDeclaration = this.formGroup.controls['excessValueDeclaration'].value
        this.delivery.notifierContactName = this.formGroup.controls['notifierContactName'].value
        this.delivery.notifierContactEmail = this.formGroup.controls['notifierContactEmail'].value
        this.delivery.notifierContactCity = this.formGroup.controls['notifierContactCity'].value
        this.delivery.notifierContactAddress = this.formGroup.controls['notifierContactAddress'].value
        this.delivery.notifierCompanyName = this.formGroup.controls['notifierCompanyName'].value
        this.delivery.notifierContactPhone = this.formGroup.controls['notifierContactPhone'].value
        this.delivery.notifierVAT = this.formGroup.controls['notifierVAT'].value
        this.delivery.notifierContactCountryId = this.formGroup.controls['notifierContactCountryId'].value
        this.delivery.consigneeContactName = this.formGroup.controls['consigneeContactName'].value
        this.delivery.consigneeContactEmail = this.formGroup.controls['consigneeContactEmail'].value
        this.delivery.consigneeContactPhone = this.formGroup.controls['consigneeContactPhone'].value
        this.delivery.consigneeContactAddress = this.formGroup.controls['consigneeContactAddress'].value
        this.delivery.consigneeContactCity = this.formGroup.controls['consigneeContactCity'].value
        this.delivery.consigneeCompanyName = this.formGroup.controls['consigneeCompanyName'].value
        this.delivery.consigneeVAT = this.formGroup.controls['consigneeVAT'].value
        this.delivery.consigneeContactCountryId = this.formGroup.controls['consigneeContactCountryId'].value
        this.delivery.containerNumber = this.formGroup.controls['containerNumber'].value
        this.delivery.week = this.formGroup.controls['week'].value
        this.delivery.surcharge = this.formGroup.controls['surcharge'].value
        this.delivery.etd = this.formGroup.controls['etd'].value
        this.delivery.eta = this.formGroup.controls['eta'].value
        this.delivery.handling = this.formGroup.controls['handling'].value
        this.delivery.gateOut = this.formGroup.controls['gateOut'].value
        this.delivery.dus = this.formGroup.controls['dus'].value
        this.delivery.sps = this.formGroup.controls['sps'].value

        let selectedStackingStartDateTime = this.formGroup.controls['stackingStart'].value
        if (selectedStackingStartDateTime) this.delivery.stackingStart = new Date(selectedStackingStartDateTime.getTime() - (selectedStackingStartDateTime.getTimezoneOffset() * 60000))

        let selectedStackingEndDateTime = this.formGroup.controls['stackingEnd'].value
        if (selectedStackingEndDateTime) this.delivery.stackingEnd = new Date(selectedStackingEndDateTime.getTime() - (selectedStackingEndDateTime.getTimezoneOffset() * 60000))

        let selectedCutoffDateTime = this.formGroup.controls['cutoffDate'].value
        if (selectedCutoffDateTime) this.delivery.cutoffDate = new Date(selectedCutoffDateTime.getTime() - (selectedCutoffDateTime.getTimezoneOffset() * 60000))

        // this.delivery.cutoffDate = this.formGroup.controls['cutoffDate'].value
        this.delivery.carrierName = this.formGroup.controls['carrierName'].value
        this.delivery.departurePatentTruck = this.formGroup.controls['departurePatentTruck'].value
        this.delivery.departurePatentCar = this.formGroup.controls['departurePatentCar'].value
        this.delivery.mbl = this.formGroup.controls['mbl'].value
        this.delivery.ot = this.formGroup.controls['ot'].value
        this.delivery.ref1 = this.formGroup.controls['ref1'].value
        this.delivery.ref2 = this.formGroup.controls['ref2'].value

        this.delivery.departureAirportId = this.formGroup.controls['departureAirportId'].value;
        this.delivery.destinationAirportId = this.formGroup.controls['destinationAirportId'].value;
        this.delivery.ctr = this.formGroup.controls['ctr'].value

        const request: UpdateDeliveryRequest = {
          importOrderId: this.orderId,
          delivery: this.delivery
        }

        this._deliveryService.update(request).subscribe({
          next: (v) => {
            this.deliveryInformation = v
            this._messageService.add({ severity: 'success', summary: this.translate.instant('MessageService.Summary.SuccessAction'), detail: this.translate.instant('ForwarderPage.ForwarderDelivery.Delivery.Messages.SuccessDelete') })
            this.loading = false
            setTimeout(() => {
              this._router.navigate([Routes.forwarderUserReviews]);
            }, 1500);
          },
          error: (e) => {
            this.loading = false
            this._messageService.add({ severity: 'error', summary: this.translate.instant('MessageService.Summary.FailedAction'), detail: this.translate.instant('ForwarderPage.ForwarderDelivery.Delivery.Messages.ErrorDelete') });
          },
          complete: () =>  {
            this.loading = false
            this.submit = false
            console.info('Delivery updated successfully')
          }
        })
      }
    }
    else {
      this.loading = false
      this._messageService.add({ severity: 'error', summary: this.translate.instant('MessageService.Summary.FailedAction'), detail: this.translate.instant('ForwarderPage.ForwarderDelivery.Delivery.Messages.ErrorForm') });
    }
  }

  saveDiscount(){
    this.submitDiscount = true
    this.loading = true
    if(this.discountFormGroup.valid && this.delivery) {
      if(!this.selectedDiscount) {
        let discount: NewDeliveryDiscount = {
          description: this.discountFormGroup.controls['description'].value,
          amount: this.discountFormGroup.controls['amount'].value,
          importOrderId: this.orderId
        }

        this._deliveryDiscountService.create(discount).subscribe({
          next: (v) => {
            this.loadDiscounts()
            this.loading = false
            this.discountDialogVisible = false
            this._messageService.add({ severity: 'success', summary: this.translate.instant('MessageService.Summary.SuccessAction'), detail: this.translate.instant('ForwarderPage.ForwarderDelivery.Discounts.Messages.SuccessCreate') })
          },
          error: (e) => {
            this.loading = false
            this._messageService.add({ severity: 'error', summary: this.translate.instant('MessageService.Summary.FailedAction'), detail: this.translate.instant('ForwarderPage.ForwarderDelivery.Discounts.Messages.ErrorCreate') });
          },
          complete: () =>  {
            this.loading = false
            this.submitDiscount = false
            console.info('Delivery Discount created successfully')
          }
        })
      }
      else {
        this.selectedDiscount.description = this.discountFormGroup.controls['description'].value
        this.selectedDiscount.amount = this.discountFormGroup.controls['amount'].value

        this._deliveryDiscountService.update(this.selectedDiscount).subscribe({
          next: (v) => {
            this.loadDiscounts()
            this.loading = false
            this.discountDialogVisible = false
            this._messageService.add({ severity: 'success', summary: this.translate.instant('MessageService.Summary.SuccessAction'), detail: this.translate.instant('ForwarderPage.ForwarderDelivery.Discounts.Messages.SuccessEdit') })
          },
          error: (e) => {
            this.loading = false
            this._messageService.add({ severity: 'error', summary: this.translate.instant('MessageService.Summary.FailedAction'), detail: this.translate.instant('ForwarderPage.ForwarderDelivery.Discounts.Messages.ErrorEdit') });
          },
          complete: () =>  {
            this.loading = false
            this.submitDiscount = false
            console.info('Delivery Discount updated successfully')
          }
        })
      }
    }
    else {
      this.loading = false
    }
  }

  loadDiscounts() {
    this.loading = true
    if(this.delivery) {
      this._deliveryDiscountService.getAllByDeliveryId(this.orderId).subscribe({
        next: (v) => {
          this.loading = false
          this.discounts = v
        },
        error: (e) => {
          this.loading = false
          let errorMessage = ''
            const lang = this.translate.currentLang
            switch(lang) {
              case 'es':
                errorMessage = e.error.messageEs
                break
              case 'en':
                errorMessage = e.error.messageEn
                break
              default:
                errorMessage = this.translate.instant('ForwarderPage.ForwarderDelivery.Discounts.Messages.ErrorLoad')
          }
          this._messageService.add({ 
            severity: 'error', 
            summary: this.translate.instant('MessageService.Summary.FailedAction'), 
            detail: errorMessage
          });
        },
        complete: () =>  {
          this.loading = false
          this.submitDiscount = false
          console.info('Delivery Discount query successfully')
        }
      })
    }
  }

}
