import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { ExportOfferGateway } from 'src/app/application/gateway/ExportOfferGateway';
import { ExportOfferListItem } from 'src/app/domain/models/ExportOffer';
import { Routes } from 'src/app/infrastructure/routes';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-export-offer-create-option',
  templateUrl: './export-offer-create-option.component.html',
  styleUrls: ['./export-offer-create-option.component.css'],
  providers: [MessageService]
})
export class ExportOfferCreateOptionComponent implements OnInit {

  isCreateFromTemplate: boolean = false
  loading: boolean = false

  exportOffers: ExportOfferListItem[] = []

  constructor(private _router: Router,
    private _messageService: MessageService,
    private _exportOfferService: ExportOfferGateway,
    private _authService: AuthGateway,
    public translate: TranslateService) {

  }

  ngOnInit(): void {
    this.isCreateFromTemplate = false
  }

  reset() {
    this.isCreateFromTemplate = false
  }

  goCreateExportOffer(): void {
    const newRoute = '/' + Routes.newExportOffer
    window.location.href = newRoute;
  }

  showCreateFromTemplate() {
    this.getAll()
    this.isCreateFromTemplate = true
  }

  getAll() {
    this.loading = true
    this._exportOfferService.getAllByUsername().subscribe({
      next: (v) => {
        this.exportOffers = v.filter((item) => item.status === 1);
        this.loading = false
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.ExportOffer.ManyRetrieveError') })
      },
      complete: () => console.info('Export Offers query successfully')
    })
  }

  getImageUrl(offer: ExportOfferListItem): string {
    if(offer.pictures && offer.pictures.length > 0){
      for(let i = 0; i < offer.pictures.length; i++) {
        if(offer.pictures[i].url)
          return environment.apiUrl + offer.pictures[i].url
      }
    }
    return ''
  }

  duplicate(id: number) {
    this.loading = true
    this._exportOfferService.duplicate(id).subscribe({
      next: (v) => {
        let duplicated = v
        this._messageService.add({ severity: 'info',
          summary: this.translate.instant('UserPages.UserExportOffers.DuplicateActionMessage.SuccessSummary'),
          detail: this.translate.instant('UserPages.UserExportOffers.DuplicateActionMessage.SuccessDetail') })
        this.loading = false
        this.isCreateFromTemplate = false
        // Obtén la ruta actual
        const currentUrl = '/' + Routes.newExportOffer;
        // Construye la URL absoluta con los parámetros adicionales
        const newUrl = `${currentUrl};exportOfferId=${duplicated.guid}`;
        window.location.href = newUrl;
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('UserPages.UserExportOffers.DuplicateActionMessage.ErrorSummary'),
          detail: this.translate.instant('UserPages.UserExportOffers.DuplicateActionMessage.ErrorDetail') })
      },
      complete: () => console.info('Export Offer duplicate successfully')
    })
  }
}
