<app-user-dashboard [userMenuActiveItemIndex]="0" [activeItem]="'user-import-request'">
    <p-card>
        <ng-template pTemplate="header">
            <div class="flex justify-content-between flex-wrap align-items-center" style="padding: 15px;">
                <h2>{{'UserPages.UserImportRequest.PageTitle' | translate}}</h2>
            </div>
        </ng-template>
        <p-table
          [value]="importRequests"
          [rowsPerPageOptions]="[10, 25, 50]"
          [loading]="loading"
          [globalFilterFields]="[
          'id',
          'variety',
          'typeSale',
          'Country'
          ]"
          responsiveLayout="stack"
          [breakpoint]="'600px'"
        >
        <ng-template pTemplate="caption">
          <div class="flex align-items-center justify-content-end">
            <p-button label="{{'UserPages.UserImportRequest.CreateImportRequest' | translate}}" (onClick)="goToCreateImportRequest()"></p-button>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th>
                    <div class="flex justify-content-center align-items-center">
                        ID
                        <p-columnFilter type="text" field="id" display="menu" [showAddButton]="false" [showMatchModes]="false" [showOperator]="false"></p-columnFilter>
                    </div>
                </th>
                <th>
                  <div class="flex justify-content-center align-items-center">
                    {{'UserPages.UserImportRequest.ImportRequestTable.SpecieHeader' | translate}}
                  </div>
                </th>
                <th>
                    <div class="flex justify-content-center align-items-center">
                      {{'UserPages.UserImportRequest.ImportRequestTable.VarietyHeader' | translate}}
                        <p-columnFilter type="text" field="name" display="menu" [showAddButton]="false" [showMatchModes]="true" [showOperator]="false"></p-columnFilter>
                    </div>
                </th>
                <th>
                    <div class="flex justify-content-center align-items-center">
                      {{'UserPages.UserImportRequest.ImportRequestTable.WeekRangeHeader' | translate}}
                    </div>
                </th>
                <th>
                  <div class="flex justify-content-center align-items-center">
                    {{'UserPages.UserImportRequest.ImportRequestTable.TotalPalletsHeader' | translate}}
                  </div>
                </th>
                <th>
                  <div class="flex justify-content-center align-items-center">
                    {{'UserPages.UserImportRequest.ImportRequestTable.TotalContainerHeader' | translate}}
                  </div>
                </th>
                <th>
                    <div class="flex justify-content-center align-items-center">
                      {{'UserPages.UserImportRequest.ImportRequestTable.SellTypeText' | translate}}
                    </div>
                </th>
                <th>
                    <div class="flex justify-content-center align-items-center">
                      {{'UserPages.UserImportRequest.ImportRequestTable.DestinationCountryHeader' | translate}}
                    </div>
                </th>
                <th>
                  <div class="flex justify-content-center align-items-center">
                    {{'UserPages.UserImportRequest.ImportRequestTable.OriginCountryHeader' | translate}}
                  </div>
              </th>
                <th>
                    <div class="flex justify-content-center align-items-center">
                      {{'UserPages.UserImportRequest.ImportRequestTable.ActionsHeader' | translate}}
                    </div>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-info>
            <tr>
                <td>
                  <div class="flex justify-content-center align-items-center" style="font-weight: bold;">
                    <p>{{info.importRequest.id}}</p>
                  </div>
                </td>
                <td>
                  <div class="flex justify-content-center align-items-center">
                    <img [src]="info.importRequest.variety.urlIcon" alt="Image" height="70px">
                  </div>
                </td>
                <td>
                  <div class="flex justify-content-center align-items-center">
                    <p>{{info.importRequest.variety.name}}</p>
                  </div>
                </td>
                <td>
                    <div  class="flex justify-content-center align-items-center">
                        {{ info.importRequest.fromWeek }} ({{info.importRequest.fromYearWeek }}) -
                        {{info.importRequest.toWeek}} ({{info.importRequest.toYearWeek }})
                    </div>
                </td>
                <td>
                  <div class="flex justify-content-center align-items-center">
                    <p>{{getTotalPalletsImportRequest(info.importRequest)}}</p>
                  </div>
                </td>
                <td>
                  <div class="flex justify-content-center align-items-center">
                    <p>{{getTotalContainersImportRequest(info.importRequest)}}</p>
                  </div>
                </td>
                <td>
                  <div class="flex justify-content-center align-items-center">
                    <p>{{info.importRequest.typeSale}}</p>
                  </div>
                </td>
                <td>
                  <div class="flex justify-content-center align-items-center">
                    <p>{{info.importRequest.country | countryDescription}}</p>
                  </div>
                </td>
                <td>
                  <div class="flex justify-content-center align-items-center">
                    <p>{{info.importRequest.countryFrom ? (info.importRequest.countryFrom | countryDescription) : allCountriesText}}</p>
                  </div>
                </td>
                <td>
                    <div class="flex justify-content-center align-items-center gap-2">
                      <p-button icon="pi pi-pencil" pTooltip="{{'UserPages.UserImportRequest.ImportRequestTable.EditToolTipText' | translate}}"  (click)="goToEditImportRequest(info.importRequest.id)"></p-button>
                      <p-button icon="pi pi-book" pTooltip="{{'UserPages.UserImportRequest.ImportRequestTable.OffersReceivedToolTipText' | translate}}" (click)="goToImportRequestOffer(info.importRequest.id)" [badge]="info.exportOffersCount"></p-button>
                      <p-button icon="pi pi-search" pTooltip="{{'UserPages.UserImportRequest.ImportRequestTable.SeeDetail' | translate}}" (click)="showImportRequestDetailDialog(info)"></p-button>
                    </div>
                </td>
            </tr>
        </ng-template>
        </p-table>
        <!-- <div style="margin-top: 20px;">
          <p-dataView #dv [value]="importRequests" [rows]="5" [paginator]="true" emptyMessage="{{'UserPages.UserClosedSale.NotInformationMessageText' | translate}}" [loading]="loading">
            <ng-template let-request pTemplate="listItem">
              <div class="col-12" style="margin-bottom: 20px;">
                <div class="grid">
                  <div class="col-12 md:col-6 flex">
                    <div class="flex justify-content-start">
                      <span>{{request.id}}</span>
                      <img [src]="getImageUrl(request)" style="border-radius: 7px; width: 100%; max-width: 100px; height: auto;" alt="" *ngIf="request.pictures.length > 0" />
                      <div>
                        <h2 style="text-transform: uppercase; font-size: medium;">{{request.importRequest.variety.species | speciesName}}</h2>
                        <span style="text-transform: uppercase; font-size: medium;">{{request.importRequest.variety.name}}</span>
                      </div>
                    </div>
                    <span class="material-symbols-outlined info-icon">
                      pallet
                      <span>{{request.palletQuantity}}</span>
                    </span>
                    <span>{{request.typeSale}}</span>
                  </div>
                  <div class="col-12 md:col-6 flex"  style="padding-bottom: 20px; border-bottom: 1px solid #ccc;">
                    <div class="w-full grid" style="margin-left: 10px;">
                      <div class="flex justify-content-start align-items-center">
                        <img [src]="'../../../../assets/img/flags/' + request.importRequest.countryFrom.code + '.png'" height="50px" alt=""/>
                        <div class="flex flex-column" style="margin-left: 10px;">
                          <span style="font-weight: bolder; font-size: small;">{{request.importRequest.countryFrom.name}}</span>
                          <span style="font-size: small;">{{'UserPages.UserClosedSale.ProductDetail.OriginPortText' | translate}} (?)</span>
                        </div>
                      </div>
                      <span class="material-symbols-outlined" style="color: #6da043;">directions_boat</span>
                      <span class="material-symbols-outlined" style="color: #6da043;">arrow_forward_ios</span>
                      <div class="flex justify-content-start align-items-center">
                        <img [src]="'../../../../assets/img/flags/' + request.importRequest.country.code + '.png'" height="50px" alt=""/>
                        <div class="flex flex-column" style="margin-left: 10px;">
                          <span style="font-weight: bolder; font-size: small;">{{request.importRequest.country.name}}</span>
                          <span style="font-size: small;">
                            {{'UserPages.UserClosedSale.ProductDetail.DestinationPortText' | translate}}
                            {{request.importRequest.port.name}}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div></div>
            </ng-template>

          </p-dataView>
        </div> -->
    </p-card>
</app-user-dashboard>

<p-dialog header="{{'UserPages.UserImportRequest.ImportRequestTable.ImportRequestDialog.Header' | translate}}" [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleImportRequestDetailDialog" [style]="{width: '80%'}">
  <app-import-request-detail [importRequestInformation]="selected"></app-import-request-detail>
  <ng-template pTemplate="footer">
    <p-button label="{{'UserPages.UserImportRequest.ImportRequestTable.ImportRequestDialog.CloseButton' | translate}}" (onClick)="visibleImportRequestDetailDialog = false"></p-button>
  </ng-template>
</p-dialog>