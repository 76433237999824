import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { NotificationGateway } from 'src/app/application/gateway/NotificationGateway';
import { ProfileGateway } from 'src/app/application/gateway/ProfileGateway';
import { BasicUser } from 'src/app/domain/models/User';
import { Routes } from 'src/app/infrastructure/routes';
import { ExportOfferCreateOptionComponent } from '../export-offer/export-offer-create-option/export-offer-create-option.component';

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.css'],
  providers: [MessageService]
})
export class UserDashboardComponent implements OnInit {
  @Input()
  userMenuActiveItemIndex: number = 0

  @Input()
  public activeItem: any | undefined

  @Input()
  public fullScreen: boolean = false

  displaySidebar = false

  items: MenuItem[] = []

  profile: BasicUser | undefined
  isExporter: boolean = false;
  visibleCreateExportOfferOptionDialog: boolean = false

  constructor(private _router: Router,
    private _messageService: MessageService,
    private _authService: AuthGateway,
    private _profileService: ProfileGateway,
    private _notificationService: NotificationGateway,
    public translate: TranslateService) {

  }

  ngOnInit(): void {
    this.profile = this._authService.getUser()
    if(this.profile) {
      this.translate.use(this.profile.lang)

      if(this.profile.type == 1){
        this.isExporter = true;
      }

      if(this._authService.isAuthenticated()) {
        this.configureMenus()
      }
    }
  }

  private configureMenus() {
    this.translate.get([
      'UserDashboardComponent.UserDashboardMenu.LoadOrderTitle',
      'UserPages.UserLoadOrders.PageTitle',
      'UserDashboardComponent.UserDashboardMenu.ImportOrderTitle',
      'UserDashboardComponent.UserDashboardMenu.CurrentImportOrdersLabel',
      'UserDashboardComponent.UserDashboardMenu.PossiblePurchaseImportOrdersLabel',
      'UserDashboardComponent.UserDashboardMenu.ClosedSaleLabel',
      'UserDashboardComponent.UserDashboardMenu.ClosedSaleProgramationLabel',
      'UserDashboardComponent.UserDashboardMenu.ExportOfferTitle',
      'UserDashboardComponent.UserDashboardMenu.MarketplaceExportOfferLabel',
      'UserDashboardComponent.UserDashboardMenu.ClosedSaleExportOfferLabel',
      'UserDashboardComponent.UserDashboardMenu.FrozenFruitExportOfferLabel',
      'UserDashboardComponent.UserDashboardMenu.ExportOfferSuggestionTitle',
      'UserDashboardComponent.UserDashboardMenu.ExportOfferSuggestionLabel',
      'UserDashboardComponent.UserDashboardMenu.ImportRequestTitle',
      'UserDashboardComponent.UserDashboardMenu.ImportRequestLabel',
      'UserDashboardComponent.UserDashboardMenu.NegotiationTitle',
      'UserDashboardComponent.UserDashboardMenu.NegotiationLabel',
      'UserDashboardComponent.UserDashboardMenu.FridgestorageTitle',
      'UserDashboardComponent.UserDashboardMenu.FridgestorageLabel',
      'UserDashboardComponent.UserDashboardMenu.ProductCertificateTitle',
      'UserDashboardComponent.UserDashboardMenu.ProductCertificateLabel',
      'UserDashboardComponent.UserDashboardMenu.QCReviewsTitle',
      'UserDashboardComponent.UserDashboardMenu.OriginQCReviewsLabel',
      'UserDashboardComponent.UserDashboardMenu.OriginQCReviewedsLabel',
      'UserDashboardComponent.UserDashboardMenu.DestinationQCReviewsLabel',
      'UserDashboardComponent.UserDashboardMenu.DestinationQCReviewedsLabel',
      'UserDashboardComponent.UserDashboardMenu.UserPanelLabel',
      'UserDashboardComponent.UserDashboardMenu.ProgramLabel',
      'UserDashboardComponent.UserDashboardMenu.ForwarderReviewsTitle',
      'UserDashboardComponent.UserDashboardMenu.OriginForwarderReviewsLabel',
      'UserDashboardComponent.UserDashboardMenu.OriginForwarderReviewedsLabel',
      'UserDashboardComponent.UserDashboardMenu.CompanyInfoLabel',
      'UserDashboardComponent.UserDashboardMenu.CustomAgentReviewsTitle',
      'UserDashboardComponent.UserDashboardMenu.OriginCustomAgentReviewsLabel',
      'UserDashboardComponent.UserDashboardMenu.OriginCustomAgentReviewedsLabel',
      'UserDashboardComponent.UserDashboardMenu.ForwarderOrdersLabel',
    ]).subscribe({
      next: (tranlations) => {
        if(this.profile) {
          this.items = [
            {
              label: tranlations['UserDashboardComponent.UserDashboardMenu.UserPanelLabel'],
              icon: 'pi pi-fw pi-user',
              visible: this.profile.type === 1 || this.profile.type === 2,
              routerLink: ['/' + Routes.userPanelPage]
            },
            {
              label: 'Marketplace',
              icon: 'custom-icon',
              expanded: this.activeItem == 'user-load-orders' || this.activeItem == 'export-offers' || this.activeItem == 'user-import-request' || this.activeItem == 'orders' || this.activeItem == 'negotiations' || this.activeItem == 'export-offers-suggestions',
              visible: this.profile.type === 1 || this.profile.type == 2,
              items: [
                {
                  label: 'Marketplace',
                  routerLink: ['/' + Routes.marketplacePage]
                },
                {
                  label: tranlations['UserDashboardComponent.UserDashboardMenu.MarketplaceExportOfferLabel'],
                  routerLink: ['/' + Routes.userExportOffers],
                  visible: this.profile.type === 1
                },
                {
                  label: tranlations['UserDashboardComponent.UserDashboardMenu.ImportOrderTitle'],
                  routerLink: ['/' + Routes.userImportOrdersCurrent],
                  visible: this.profile.type === 1 || this.profile.type === 2
                },
                {
                  label: tranlations['UserDashboardComponent.UserDashboardMenu.LoadOrderTitle'],
                  routerLink: ['/' + Routes.userLoadOrders],
                  visible: this.profile.type === 1 || this.profile.type === 2,

                },
                {
                  label: tranlations['UserDashboardComponent.UserDashboardMenu.ImportRequestLabel'],
                  routerLink: ['/' + Routes.userImportRequest],
                  visible: this.profile.type === 2
                },
                {
                  label: tranlations['UserDashboardComponent.UserDashboardMenu.NegotiationLabel'],
                  routerLink: ['/' + Routes.userBargaining],
                  visible: this.profile.type === 1 || this.profile.type === 2
                },
                {
                  label: tranlations['UserDashboardComponent.UserDashboardMenu.ExportOfferSuggestionLabel'],
                  routerLink: ['/' + Routes.userExportOfferSuggestionPage],
                  visible: this.profile.type === 2
                },
                /**
                {
                  label: tranlations['UserDashboardComponent.UserDashboardMenu.FrozenFruitExportOfferLabel'],
                  routerLink: ['/' + Routes.userFrozenFruitExportOffersActivePage],
                }*/
              ]
            },
            {
              label: tranlations['UserDashboardComponent.UserDashboardMenu.ProgramLabel'],
              icon: 'pi pi-fw pi-calendar',
              visible: this.profile.type == 1,
              routerLink: ['/' + Routes.userClosedSales],
            },
            {
              label: tranlations['UserDashboardComponent.UserDashboardMenu.ProgramLabel'],
              icon: 'pi pi-fw pi-calendar',
              visible: this.profile.type === 2,
              expanded: this.activeItem == 'orders',
              routerLink: ['/' + Routes.userClosedSales]
            },
            {
              label: tranlations['UserDashboardComponent.UserDashboardMenu.QCReviewsTitle'],
              icon: 'pi pi-fw pi-search',
              expanded: this.activeItem == 'qcReviews',
              visible: this.profile.type === 4,
              items: [
                {
                  label: tranlations['UserDashboardComponent.UserDashboardMenu.OriginQCReviewsLabel'],
                  routerLink: ['/' + Routes.qcUserReviews]
                },
                {
                  label: tranlations['UserDashboardComponent.UserDashboardMenu.OriginQCReviewedsLabel'],
                  routerLink: ['/' + Routes.qcUserOriginReviewed]
                }
              ]
            },
            {
              label: tranlations['UserDashboardComponent.UserDashboardMenu.ForwarderReviewsTitle'],
              icon: 'pi pi-fw pi-search',
              expanded: this.activeItem == 'forwarderReviews',
              visible: this.profile.type === 5,
              items: [
                {
                  label: tranlations['UserDashboardComponent.UserDashboardMenu.OriginForwarderReviewsLabel'],
                  routerLink: ['/' + Routes.forwarderUserReviews]
                },
                {
                  label: tranlations['UserDashboardComponent.UserDashboardMenu.OriginForwarderReviewedsLabel'],
                  routerLink: ['/' + Routes.forwarderUserOriginReviewed]
                },
                {
                  label: tranlations['UserDashboardComponent.UserDashboardMenu.CompanyInfoLabel'],
                  visible: this.profile.forwardAdmin,
                  routerLink: ['/' + Routes.forwarderUsers]
                },
                {
                  label: tranlations['UserDashboardComponent.UserDashboardMenu.ForwarderOrdersLabel'],
                  routerLink: ['/' + Routes.forwarderImportOrders]
                }
              ]
            },
            {
              label: tranlations['UserDashboardComponent.UserDashboardMenu.CustomAgentReviewsTitle'],
              icon: 'pi pi-fw pi-search',
              expanded: this.activeItem == 'customAgentReviews',
              visible: this.profile.type === 6,
              items: [
                {
                  label: tranlations['UserDashboardComponent.UserDashboardMenu.OriginCustomAgentReviewsLabel'],
                  routerLink: ['/' + Routes.customAgentUserReviews]
                },
                {
                  label: tranlations['UserDashboardComponent.UserDashboardMenu.OriginCustomAgentReviewedsLabel'],
                  routerLink: ['/' + Routes.customAgentUserOriginReviewed]
                },
                {
                  label: tranlations['UserDashboardComponent.UserDashboardMenu.CompanyInfoLabel'],
                  visible: this.profile.customAgentAdmin,
                  routerLink: ['/' + Routes.customAgentUsers]
                }
              ]
            }
          ]
        }
      },
      error: (e) => {
        console.log(e)
      }
    })
  }

  get userCompleteName(): string {
    if(this.profile) {
      return this.profile.contactName
    }
    return ''
  }

  get profileName(): string {
    if(this.profile) {
      return this.profile.profileType
    }
    return ''
  }

  get profileCompanyName(): string {
    if(this.profile) {
      return this.profile.companyName
    }
    return ''
  }

  @ViewChild(ExportOfferCreateOptionComponent) exportOfferCreateOptionComponent: ExportOfferCreateOptionComponent | undefined
  openCreateExportOfferOptionDialog() {
    this.exportOfferCreateOptionComponent?.reset()
    this.visibleCreateExportOfferOptionDialog = true
  }

  onActiveItemChange(event: any) {
    this.activeItem = event
  }

  get isAuthenticated(): boolean {
    return this._authService.isAuthenticated()
  }

  get contentCssClass(): string {
    let clases = '';

     this.fullScreen ?  clases += 'w-full h-full' : clases += 'md:col-12 lg:col-10';

     this.activeItem == 'marketplace' ? clases += 'marketplace-background': clases;

     return clases;
  }

  get withStyle(): string {

    let style = ''

    this.activeItem == 'home' ? style+= 'max-width: 1280px;' : style;

    return style;
  }

  goCreateOffers() {
    this._router.navigate(['/' + Routes.newExportOffer, '']);
  }

  goToCreateImportOrderRequeriment() {
    this._router.navigate(['/' + Routes.userNewImportRequest]);
  }

  goToLogin() {
    this._router.navigate(['/' + Routes.login]);
  }


  goToMarketplaceAd()
  {
    if(this.isAuthenticated){
      this._router.navigate(['/' + Routes.marketplacePage]);
    }else{
      this._router.navigate(['/' + Routes.externalMarketplace]);
    }

  }
}
