
<app-dashboard-layout>
  <app-content-body>
    <div class="grid">
      <div class="col-12 flex justify-content-end">
        <app-switch-lang></app-switch-lang>
      </div>
      <div class="col-12 md:col-8 lg:col-8">
        <div class="grid grid-nogutter surface-section text-800">
          <div class="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center ">
            <section>
              <span class="block text-6xl font-bold mb-1">{{'LoginPage.WelcomeText' | translate}}</span>
              <div class="text-6xl text-primary font-bold mb-3">{{'LoginPage.LegendText' | translate}}</div>
              <p class="mt-0 mb-4 text-700 line-height-3">
                {{'LoginPage.SecondLegendText' | translate}}
              </p>
              <button pButton pRipple label="{{'LoginPage.MarketplaceButton' | translate}}" type="button" class="mr-3 p-button-raised"
                (click)="goToMarketplace()"></button>
              <!--button pButton pRipple label="{{'LoginPage.CreateAccountButton' | translate}}" type="button" class="p-button-outlined"
                (click)="goToSignupPage()"></button-->
            </section>
          </div>
        </div>
      </div>
      <div class="col-12 md:col-4 lg:col-4">
        <div class="flex justify-content-center align-items-center flex-wrap" style="margin-right: 80px;">
          <img src="../../../../assets/img/fruzty-logo-slogan.png" width="480" height="214" />
          <p-card header="{{'LoginPage.WelcomeText' | translate}}" subheader="{{'LoginPage.LogInText' | translate}}">
            <form [formGroup]="formGroup" (ngSubmit)="handleSubmit()">
              <div *ngIf="showLogin" class="p-fluid formgrid grid">
                <div class="field col-12">
                  <p-message *ngIf="loginError" severity="error" text="{{'LoginPage.LoginErrorMessage' | translate}}"></p-message>
                </div>
                <div class="field col-12">
                  <label for="email">{{'LoginPage.EmailLabel' | translate}}</label>
                  <input id="email" type="text" pInputText formControlName="email" />
                  <small *ngIf="submit && formGroup.controls['email'].invalid"
                    class="p-error">{{getErrorMessage('email')}}</small>
                </div>
                <div class="field col-12" style="margin-bottom: 40px;">
                  <label for="password">{{'LoginPage.PasswordLabel' | translate}}</label>
                  <p-password id="password" type="password" formControlName="password" [feedback]="false"
                    [toggleMask]="true" />
                  <small *ngIf="submit && formGroup.controls['password'].invalid"
                    class="p-error">{{getErrorMessage('password')}}</small>
                </div>
                <div class="flex justify-content-start field col-6">
                  <span (click)="visibleDialog = true" class="font-medium no-underline ml-2 text-left cursor-pointer" style="color: #8c2b87;">{{'LoginPage.RequestCodeText' | translate}}</span>
                  <!--a [routerLink]="signupPageRoute" class="font-medium no-underline ml-2 text-right cursor-pointer"
                    style="color: #8c2b87;">{{'LoginPage.HaveAccountText' | translate}} </a-->
                </div>
                <div class="flex justify-content-end field col-6">
                  <a [routerLink]="resetPasswordRoute" class="font-medium no-underline ml-2 text-right cursor-pointer"
                    style="color: #8c2b87;">{{'LoginPage.ForgotPasswordText' | translate}} </a>
                </div>
                <div class="field col-12">
                  <p-button label="{{'LoginPage.LoginButton' | translate}}" type="submit" [loading]="loading" />
                </div>
              </div>

              <div *ngIf="showAuthentication" class="p-fluid grid flex">
                <div class="col-12 mb-2">
                  <p>{{'LoginPage.VerifiedCodeText' | translate}}</p>
                </div>
                <div class="col-2">
                  <p-inputNumber #authCode1 [min]="0" [max]="9" formControlName="authCode1" [maxlength]="1" [useGrouping]="false"
                    class="p-inputtext-sm" (onInput)="handleFocusNext(authCode2)" (onFocus)="clear('authCode1')" (paste)="onPaste($event)" [style]="{'text-aling':'center'}">
                  </p-inputNumber>
                </div>

                <div class="col-2">
                  <p-inputNumber #authCode2 [min]="0" [max]="9" formControlName="authCode2" [maxlength]="1"
                    class="p-inputtext-sm" (onInput)="handleFocusNext(authCode3)" (onFocus)="clear('authCode2')" (paste)="onPaste($event)"></p-inputNumber>
                </div>

                <div class="col-2">
                  <p-inputNumber #authCode3 [min]="0" [max]="9" formControlName="authCode3" [maxlength]="1"
                    class="p-inputtext-sm" (onInput)="handleFocusNext(authCode4)" (onFocus)="clear('authCode3')" (paste)="onPaste($event)"></p-inputNumber>
                </div>

                <div class="col-2">
                  <p-inputNumber #authCode4 [min]="0" [max]="9" formControlName="authCode4" [maxlength]="1"
                    class="p-inputtext-sm" (onInput)="handleFocusNext(authCode5)" (onFocus)="clear('authCode4')" (paste)="onPaste($event)"></p-inputNumber>
                </div>

                <div class="col-2">
                  <p-inputNumber #authCode5 [min]="0" [max]="9" formControlName="authCode5" [maxlength]="1"
                    class="p-inputtext-sm" (onInput)="handleFocusNext(authCode6)" (onFocus)="clear('authCode5')" (paste)="onPaste($event)"></p-inputNumber>
                </div>

                <div class="col-2">
                  <p-inputNumber #authCode6 [min]="0" [max]="9" formControlName="authCode6" [maxlength]="1"
                    class="p-inputtext-sm" (onFocus)="clear('authCode6')" (paste)="onPaste($event)"></p-inputNumber>
                </div>
                <div class="col-12">
                  <p-message *ngIf="codeError" severity="error" text="Código incorrecto o expirado."></p-message>
                </div>
                <div class="col-6 mt-2">
                  <p-button label="{{'LoginPage.ConfirmCodeButton' | translate}}" type="submit" [loading]="loading"/>
                </div>
                <div class="col-6 mt-2">
                  <p-button  styleClass="p-button-outlined p-button-secondary" label="{{'LoginPage.ResendCodeButton' | translate}}" (onClick)="resendCode()" [loading]="loading" />
                </div>
              </div>
            </form>
          </p-card>
        </div>
      </div>
    </div>
  </app-content-body>
  <p-toast></p-toast>
</app-dashboard-layout>


<p-dialog [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleDialog" [style]="{width: '50wv'}">
  <app-requests-code></app-requests-code>
</p-dialog>
