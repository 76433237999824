import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ExportOfferService } from 'src/app/application/service/ExportOfferService';
import { ExportOffer, ExportOfferInformation } from 'src/app/domain/models/ExportOffer';
import { Routes } from 'src/app/infrastructure/routes';

@Component({
  selector: 'app-admin-manage-export-offers-page',
  templateUrl: './admin-manage-export-offers-page.component.html',
  styleUrls: ['./admin-manage-export-offers-page.component.css'],
  providers: [ConfirmationService, MessageService]
})
export class AdminManageExportOffersPageComponent implements OnInit {

  exportOffers: ExportOfferInformation[] = []
  loading: boolean = false

  date: Date | undefined;

  constructor(
    private _exportOfferService: ExportOfferService,
    private _router: Router,
    private _messageService: MessageService,
    public translate: TranslateService,
    private _confirmationService: ConfirmationService
  ){

  }

  ngOnInit(): void {
      this.getExportOffers()
  }

  @ViewChild("dt1") dataTableComponent: any;
  setup(value: any, id: any){
    console.log(new Date(value));
    this.dataTableComponent.filters[id][0].value;
   if(value != null)
     this.dataTableComponent.filters[id][0].value = this.date;
 }

  getExportOffers(){
    this.loading = true;
    this.exportOffers = []
    this._exportOfferService.getAll().subscribe({
      next: (v) => {
        this.loading = false
        this.exportOffers = v
      },
      error: (e) => {
        this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudieron recuperar las ofertas.' });
      },
      complete: () => {
        this.loading = false
      }
    })
  }

  @ViewChild('dt1') dataTable: any
  filterGlobal(event: any) {
    this.dataTable.filterGlobal(event.target.value, 'contains')
  }

  goToAdminEdit(exportOfferId: number){
    this._router.navigate(['/', Routes.adminExportOfferEdit, exportOfferId])
  }

  desactivate (exportOffer: ExportOffer){
    this._exportOfferService.desactive(exportOffer.id).subscribe(
      {
        next: () => {
          this._messageService.add({ severity: 'error', summary: 'Acción exitosa', detail: 'Se desactivo la oferta correctamente.' });
          exportOffer.isActive = false;
        },
        error: (e) => {
          this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se logro desactivar la oferta.' });
        },
        complete: () => {
          this.loading = false
        }
      }
    )
  }

  activate (exportOffer: ExportOffer){
    this._exportOfferService.active(exportOffer.id).subscribe(
      {
        next: () => {
          this._messageService.add({ severity: 'error', summary: 'Acción exitosa', detail: 'Se activo la oferta correctamente.' });
          exportOffer.isActive = true;
        },
        error: (e) => {
          this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se logro activar la oferta.' });
        },
        complete: () => {
          this.loading = false
        }
      }
    )
  }

  deleteOffer (exportOffer: ExportOffer){
    this._confirmationService.confirm({
      message: this.translate.instant('AdminExportOffer.RemoveDetailConfirmDialogMessage'),
      header: this.translate.instant('AdminExportOffer.RemoveDetailConfirmDialogHeader'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loading = true
        this._exportOfferService.deleteOffer(exportOffer.guid).subscribe(
          {
            next: () => {
              this._messageService.add({ severity: 'success', summary: 'Acción exitosa', detail: 'Se eliminó la oferta correctamente.' });
              this.getExportOffers()
            },
            error: (e) => {
              console.log(e)
              let errorMessage = ''
              const lang = this.translate.currentLang
              switch(lang) {
                case 'es':
                  errorMessage = e.error.messageEs
                  break
                case 'en':
                  errorMessage = e.error.messageEn
                  break
                default:
                  errorMessage = e.error.messageEn
              }
              if(e.status === 500){
                this._messageService.add({ severity: 'error', summary: this.translate.instant('MessageService.Summary.FailedAction'), detail: "No fue posible eliminar el registro, consulte con sistemas" })
              }
              else {
                this._messageService.add({ severity: 'error', summary: this.translate.instant('MessageService.Summary.FailedAction'), detail: errorMessage });
              }
              this.loading = false
            },
            complete: () => {
              this.loading = false
            }
          }
        )
      },
      reject: (type: any) => {}
    })
  }
}
