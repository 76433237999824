import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { ImportOrderGateway } from 'src/app/application/gateway/ImportOrderGateway';
import { Delivery } from 'src/app/domain/models/Delivery';
import { Routes } from 'src/app/infrastructure/routes';
import { ImportOrderStatusEnum } from 'src/app/ui/util/order-utils';
import { OperationDocument } from 'src/app/domain/models/OperationDocument';
import { OperationInformation } from 'src/app/domain/models/Operation';
import { BasicUser } from 'src/app/domain/models/User';
import { TranslateService } from '@ngx-translate/core';
import { ImportOrderInformation } from 'src/app/domain/models/ImportOrder';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.css'],
  providers: [MessageService]
})
export class OrderDetailComponent implements OnInit {
  breadcrumbMenuItems: MenuItem[] = []
  breadcrumbHome: MenuItem = {}

  orderId: any | undefined
  order: ImportOrderInformation | undefined
  profile: BasicUser | undefined
  delivery: Delivery | undefined
  operationInformation: OperationInformation | undefined;
  documents: OperationDocument[] = []

  loading: boolean = false

  constructor(private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _messageService: MessageService,
    private _authService: AuthGateway,
    private _importOrderService: ImportOrderGateway,
    public translate: TranslateService
    ) {
      this._activatedRoute.params.subscribe(params => {
        this.orderId = params['orderId']
      })
  }

  ngOnInit(): void {
    this.loadInfo()

    let breadcrumbMenuItemLabel1 = 'Import order detail'
    let breadcrumbHomeItemLabel = 'My import orders'

    if(this.profile) {
      switch(this.profile.lang) {
        case 'en':
          breadcrumbMenuItemLabel1 = 'Import order detail'
          breadcrumbHomeItemLabel = 'My import orders'
          break
        case 'es':
          breadcrumbMenuItemLabel1 = 'Detalle de orden de importación'
          breadcrumbHomeItemLabel = 'Ordenes de importación'
          break
      }
    }

    this.breadcrumbMenuItems = [{label: breadcrumbMenuItemLabel1, disabled: true}]
    this.breadcrumbHome = {label: breadcrumbHomeItemLabel, command: () => this.goToImportOrderList() }
  }

  loadInfo() {
    if(this.orderId) {
      this.profile = this._authService.getUser()
      this.loadImportOrder()
    }
  }

  loadImportOrder() {
    this.loading = true
    if(this.orderId) {
      this._importOrderService.getById(this.orderId).subscribe({
        next: (v) => {
          this.order = v
          this.loading = false
        },
        error: (e) => {
          if(e.status === 404)
          {
            this._router.navigate([Routes.userOrderNotFound])
          }else{
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.ImportOrder.SingleRetrieveError') });
          }

          this.loading = false
        },
        complete: () => console.info('Import Order query successfully')
      })
    }
  }

  goToImportOrderList() {
    if(this.order) {
      const status = this.order?.importOrder.status
      // let route = Routes.userImportOrdersPending
      // switch(status) {
      //   case ImportOrderStatusEnum.PendingVerifyPallets:
      //     route = Routes.userImportOrdersPending
      //     break
      //   case ImportOrderStatusEnum.AdminPalletsVerified:
      //     route = Routes.userImportOrdersApproved
      //     break
      //   case ImportOrderStatusEnum.ImporterOrderConfirmed:
      //     route = Routes.userImportOrdersConfirmed
      //     break
      //   case ImportOrderStatusEnum.ImporterPaymentConfirmed:
      //     route = Routes.userImportOrdersConfirmed
      //     break
      //   case ImportOrderStatusEnum.Created:
      //     route = Routes.userImportOrdersCreated
      //     break
      // }
      this._router.navigate(['/' + Routes.userImportOrdersCurrent])
    }
  }
}
