<div class="flex align-items-center md:gap-1 justify-content-between sm:gap-0 ">
  <div class="flex align-items-start align-self-center">
    <div class="align-self-center hidden-large">
      <button *ngIf="isAuthenticated" pButton pRipple (click)="displaySidebar = true" icon="pi pi-bars" class="p-button-secondary p-button-outlined"></button>
    </div>
    <div class="flex align-items-start">
      <div>
        <a [routerLink]="'/'"><img src="../../../../assets/img/fruzty-logo.png" style="width: 250px" alt="Fruzty logo"/></a>
      </div>
      <div class="align-self-center">
        <p-button *ngIf="isAuthenticated && isExporter" icon="pi pi-plus" pRipple (click)="goCreateOffers()" label="{{'HomeHeader.NewOfferButton' | translate}}"></p-button>
        <p-button *ngIf="profile?.type === 2" icon="pi pi-plus" pRipple (onClick)="goToCreateImportOrderRequeriment()" label="{{'HomeHeader.UserDashboard.NewImportRequestButton' | translate}}"></p-button>
      </div>
    </div>
  </div>
  <div *ngIf="!isAuthenticated" class="align-self-center">
    <div class="flex flex-wrap gap-4 mr-4">
      <button pButton pRipple class="p-button-outlined" styleClass="p-button-sm" (click)="goToLoginPage()">{{'HomeHeader.LoginButton' | translate}}</button>
      <p-button (onClick)="goToMarketplaceAd()" styleClass="p-button-secondary p-button-sm" [style]="{'max-height': '37px'}">
        <span class="material-symbols-outlined" style="font-size: 25px;">nutrition</span>
        <span style="margin-left: 6px">{{'HomeHeader.Offers' | translate}}</span>
      </p-button>
      <app-switch-lang></app-switch-lang>
    </div>
  </div>
  <div *ngIf="isAuthenticated" class="align-self-center mb-1 hidden-medium">
    <app-user-menu></app-user-menu>
  </div>
</div>
<p-sidebar [(visible)]="displaySidebar" [modal]="false" [transitionOptions]="'1000ms'" [style]="{'width':'300px'}">
  <app-side-menu></app-side-menu>
</p-sidebar>
