import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { ImportOrderGateway } from 'src/app/application/gateway/ImportOrderGateway';
import { GenericOrderDetail, ImportOrder, ImportOrderDetail, ImportOrderInformation, ImportOrderListItem } from 'src/app/domain/models/ImportOrder';
import { BasicUser } from 'src/app/domain/models/User';
import { Routes } from 'src/app/infrastructure/routes';
import { OrderUtils } from 'src/app/ui/util/order-utils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-purchase-orders',
  templateUrl: './user-purchase-orders.component.html',
  styleUrls: ['./user-purchase-orders.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class UserPurchaseOrdersComponent implements OnInit {
  tabMenuItems: MenuItem[] = []
  activeItem: MenuItem = {}

  orders: ImportOrderListItem[] = []
  profile: BasicUser | undefined

  loading: boolean = false

  @Input()
  activeItemIndex = 0

  constructor(private _router: Router,
    private _authService: AuthGateway,
    private _messageService: MessageService,
    private _importOrderService: ImportOrderGateway,
    public translate: TranslateService) {
  }

  ngOnInit(): void {
    let tabMenuItemInProcessLabel = 'In process'
    let tabMenuItemPurchaseLabel = 'Possible purchases'

    this.profile = this._authService.getUser()

    if(this.profile) {
      switch(this.profile.lang) {
        case 'en':
          tabMenuItemInProcessLabel = 'In process'
          tabMenuItemPurchaseLabel = 'Possible purchases'
          break
        case 'es':
          tabMenuItemInProcessLabel = 'En proceso'
          tabMenuItemPurchaseLabel = 'Posibles compras'
          break
      }
    }

    this.tabMenuItems = [
      { label: tabMenuItemInProcessLabel, command: () =>  this._router.navigate(['/'+Routes.userImportOrdersCurrent]) },
      { label: tabMenuItemPurchaseLabel, command: () =>  this._router.navigate(['/'+Routes.userImportOrdersPurchase]) },
      { label: tabMenuItemPurchaseLabel, command: () =>  this._router.navigate(['/'+Routes.userImportOrdersMbl]) },
    ]
    this.activeItem = this.tabMenuItems[this.activeItemIndex]
    // this.getAll()
  }

  onActiveItemChange(event: MenuItem) {
    this.activeItem = event;
  }

  getAll() {
    if(this.profile) {
      if(this.profile.profileType == 'Exporter') {
        this.getAllByExporter()
      }
      else if(this.profile.profileType == 'Importer') {
        this.getAllByImporter()
      }
    }
  }

  getAllByImporter() {
    this.loading = true
    this._importOrderService.getAllByImporter().subscribe({
      next: (v) => {
        this.orders = v
        this.loading = false
      },
      error: (e) => {
        this.loading = false
        console.log(e)
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.ImportOrder.ManyRetrieveError') });
      },
      complete: () => console.info('Import Order query successfully')
    })
  }

  getAllByExporter() {
    this.loading = true
    this._importOrderService.getAllByExporter().subscribe({
      next: (v) => {
        this.orders = v
        this.loading = false
      },
      error: (e) => {
        this.loading = false
        console.log(e)
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.ImportOrder.ManyRetrieveError') });
      },
      complete: () => console.info('Import Order query successfully')
    })
  }

  getOrderTitle(order: ImportOrderListItem): string {
    return OrderUtils.getOrderTitle(order.sequence)
  }

  getPictureUrl(pictureUrl: ImportOrderListItem): string {
    return environment.apiUrl + pictureUrl
  }

  goToOrderDetailPage(orderId: any) {
    this._router.navigate(['/' + Routes.userOrderDetailPage, orderId])
  }

  getOrderTotalPrice(details: GenericOrderDetail[]): number {
    return OrderUtils.getOrdetTotalPriceGeneric(details)
  }

  goToVerifyPalletsPage() {
    this._router.navigate(['/' + Routes.verifyPalletsPage])
  }

  confirmPurchase(order: ImportOrderListItem) {
    this._router.navigate(['/' + Routes.userConfirmPurchasePage, order.id])
  }

  getSpeciesName(detail: GenericOrderDetail): string {
    if(this.profile) {
      switch(this.profile.lang) {
        case 'es':
          return detail.speciesNameEs
        case 'en':
          return detail.speciesNameEn
        default:
          return detail.speciesNameEn
      }
    }
    return ''
  }
}
