<app-admin-dashboard>
  <p-card>
    <h2>Revisiones de control de calidad</h2>
    <div style="margin-top: 50px;">
      <p-table
        [value]="qcReviews"
        [paginator]="true"
        [rows]="5"
        responsiveLayout="scroll"
        [loading]="loading">
        <ng-template pTemplate="header">
          <tr>
            <th>Status</th>
            <th pSortableColumn="qualityControlCompanyName">
              QC Company
              <p-columnFilter
                type="text"
                field="qualityControlCompanyName"
                display="menu"
                [showAddButton]="false" [showMatchModes]="false" [showOperator]="false"
                [matchMode]="'contains'"></p-columnFilter>
            </th>
            <th>Quality request Date</th>
            <th>QC Date</th>
            <th>Price</th>
            <th>Observations</th>
            <th>Review Document</th>
            <th>Consolidation Document</th>
            <th>Actions</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-review>
          <tr>
            <td>
              <p-tag severity="warning" value="Created" *ngIf="review.status === 0"></p-tag>
              <p-tag severity="warning" value="Pending" *ngIf="review.status === 1"></p-tag>
              <p-tag severity="success" value="Reviewed" *ngIf="review.status === 2"></p-tag>
              <p-tag severity="danger" value="Cancelled" *ngIf="review.status === 3"></p-tag>
            </td>
            <td>{{review.qualityControlCompanyName}}</td>
            <td>{{review.created | date: 'dd-MM-yyyy HH:mm'}}</td>
            <td>{{review.qualityControlDate ? (review.qualityControlDate | date: 'dd-MM-yyyy') : 'Pending review'}}</td>
            <td>{{review.price ? (review.price | currency: 'USD':'US$') : 'Pending review'}}</td>
            <td>{{review.observations ? review.observations : 'No observations'}}</td>
            <td><p-button [disabled]="review.status !== 2" pTooltip="View file" icon="pi pi-file-pdf" (onClick)="viewOriginQCReviewDocument(review)" styleClass="p-button-outlined"></p-button></td>
            <td><p-button [disabled]="review.status !== 2" pTooltip="View file" icon="pi pi-file-pdf" (onClick)="viewOriginQCConsolidationDocument(review)" styleClass="p-button-outlined"></p-button></td>
            <td>
              <div class="flex align-items-center justify-content-end gap-4">
                <p-button label="Manage" icon="pi pi-check-square" (onClick)="goToQCReview(review.guid)"></p-button>
                <p-button label="Documents" icon="pi pi-file-pdf" (onClick)="goToQCReviewDocuments(review.guid)"></p-button>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </p-card>
</app-admin-dashboard>

<p-toast></p-toast>
