import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { Routes } from 'src/app/infrastructure/routes';
import { BasicUser } from 'src/app/domain/models/User';
import { TranslateService } from '@ngx-translate/core';
import { ImportOrderDetail, ImportOrderInformation } from 'src/app/domain/models/ImportOrder';
import { ImportOrderDetailGateway } from 'src/app/application/gateway/ImportOrderDetailGateway';


@Component({
  selector: 'app-qc-order-detail',
  templateUrl: './qc-order-detail.component.html',
  styleUrls: ['./qc-order-detail.component.css'],
  providers: [MessageService]
})
export class QcOrderDetailComponent {
  breadcrumbMenuItems: MenuItem[] = []
  breadcrumbHome: MenuItem = {}

  orderId: any | undefined
  order: ImportOrderInformation | undefined
  profile: BasicUser | undefined

  loading: boolean = false


  // taxes: number = 19
  currency: string = "USD"

  qcCompanyName: string = "Delta Quality"
  qcCompanyPrice: number = 100000

  iconProduct: string = 'icon'

  importOrderInfo: ImportOrderDetail | undefined



  constructor(private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _messageService: MessageService,
    private _authService: AuthGateway,
    public translate: TranslateService,
    private _importOrderDetailService: ImportOrderDetailGateway,

    ) {
      this._activatedRoute.params.subscribe(params => {
        this.orderId = params['orderId']
      })

  }


  ngOnInit(): void {
    this.profile = this._authService.getUser()

    let breadcrumbMenuItemLabel1 = 'Import order detail'
    let breadcrumbMenuItemLabel2 = 'Verify pallets'
    let breadcrumbHomeItemLabel = 'My import orders'
    let breadcrumbMenuItemLabel3 = 'Quality control payment details'

    if(this.profile) {
      switch(this.profile.lang) {
        case 'en':
          breadcrumbMenuItemLabel1 = 'Import order detail'
          breadcrumbMenuItemLabel2 = 'Verify pallets'
          breadcrumbHomeItemLabel = 'My import orders'
          breadcrumbMenuItemLabel3 = 'Quality control payment details'
          
          break
          case 'es':
            breadcrumbMenuItemLabel1 = 'Detalle de orden de importación'
            breadcrumbMenuItemLabel2 = 'Verificar  pallets'
            breadcrumbMenuItemLabel3 = 'Detalle pago control de calidad'
            breadcrumbHomeItemLabel = 'Ordenes de importación'
          break
      }
    }

    this.breadcrumbMenuItems = [
      {label: breadcrumbMenuItemLabel1, command: () => { this.goToImportOrderPage() }},
      {label: breadcrumbMenuItemLabel2, command: () => { this.goToVerifyPalletsPage() }},
      {label: breadcrumbMenuItemLabel3, disabled: true}
    ]
    this.breadcrumbHome = {label: breadcrumbHomeItemLabel, command: () => this.goToImportOrderList() }
    this.loadInfo()

  }

  loadInfo() {
    if(this.orderId) {
      this.profile = this._authService.getUser()
      this.loadImportOrderDetailInfo()
    }
  }

  loadImportOrderDetailInfo() {
    if(this.orderId) {
      this._importOrderDetailService.getQcPayment(this.orderId).subscribe({
        next: (v) => {
          this.importOrderInfo = v
        },
        error: (e) => {
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.QcOrderDetail.LoadInfoError') });
        }
      })
    }
  }

  navigateToQcOrderPayment() {
    this._router.navigate(['/'+ Routes.qcOrderPaymentPage, this.orderId])
  }

  formatNumber(num: number) {
    if (num > 1000) {
        return num.toLocaleString();
    } else {
        return num.toString();
    }
  }

  padNumberWithZeros(num: number) {
    let numStr = num.toString();
    while (numStr.length < 7) {
        numStr = '0' + numStr;
    }
    return numStr;
  }

  goToImportOrderList() {

    this._router.navigate(['/' + Routes.userImportOrdersCurrent])
    
  }

  changeQc() {
    this._router.navigate(['/' + Routes.verifyPalletsPage, this.orderId])
  }

  goToImportOrderPage() {
    if(this.importOrderInfo) {
      this._router.navigate(['/' + Routes.userOrderDetailPage, this.importOrderInfo.importOrderId])
    }
  }

  goToVerifyPalletsPage() {
    if(this.importOrderInfo) {
      this._router.navigate(['/' + Routes.verifyPalletsPage, this.orderId])
    }
  }
}
