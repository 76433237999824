<app-user-dashboard [userMenuActiveItemIndex]="0" [activeItem]="'export-offers'">
  <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="breadcrumbHomeItem"></p-breadcrumb>
  <div style="margin-top: 30px">
    <p-card [header]="getCardHeader()" [subheader]="getCardSubheader()">

      <div id="step1" *ngIf="step == step1">
        <form [formGroup]="step1FormGroup">
          <h2 style="color: #8c2b87;">{{'UserPages.UserExportOffers.NewExportOffer.StepLabel' | translate}} 1 {{'UserPages.UserExportOffers.NewExportOffer.StepOfLabel' | translate}} 3</h2>
          <p-progressBar [value]="33.33" color="#8c2b87"></p-progressBar>
          <h2>{{'UserPages.UserExportOffers.NewExportOffer.PageTitle2' | translate}}</h2>
          <p>{{'UserPages.UserExportOffers.NewExportOffer.PageSubtitle2' | translate}}</p>

          <div class="p-fluid formgrid mr-2 ml-2 grid" style="margin-top: 40px;">
            <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
              <label for="species" style="font-weight: bolder;">{{'UserPages.UserExportOffers.NewExportOffer.SpecieFieldLabel' | translate}} *</label>
              <p-dropdown id="species"
                formControlName="speciesId"
                [options]="species"
                optionLabel="nameEs"
                optionValue="id"
                [filter]="true"
                [filterBy]="profile?.lang == 'en' ? 'name' : 'nameEs'"
                [showClear]="true"
                placeholder="{{'UserPages.UserExportOffers.NewExportOffer.SpecieFieldPlaceholder' | translate}}"
                (onChange)="loadSpeciesParams()">
                <ng-template pTemplate="selectedItem">
                  <div class="flex align-items-center gap-2" *ngIf="selectedSpecies">
                    <img [src]="selectedSpecies.imageUrl" style="width: 18px" alt=""/>
                    <div>{{ getSpeciesName(selectedSpecies) }}</div>
                  </div>
                </ng-template>
                <ng-template let-specie pTemplate="item">
                    <div class="flex align-items-center gap-2">
                      <img [src]="specie.imageUrl" style="width: 18px" alt=""/>
                      <div>{{ getSpeciesName(specie) }}</div>
                    </div>
                </ng-template>
              </p-dropdown>
              <small *ngIf="submit && step1FormGroup.controls['speciesId'].invalid" class="p-error">{{getErrorMessage(step1FormGroup, 'speciesId')}}</small>
            </div>
            <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
              <label for="variety" style="font-weight: bolder;">{{'UserPages.UserExportOffers.NewExportOffer.VarietyFieldLabel' | translate}} *</label>
              <p-dropdown id="variety"
                formControlName="varietyId"
                [options]="varieties"
                optionLabel="name"
                optionValue="id"
                [filter]="true"
                filterBy="name"
                [showClear]="true"
                placeholder="{{'UserPages.UserExportOffers.NewExportOffer.VarietyFieldPlaceholder' | translate}}"
                (onChange)="onChangeVariety()"
                emptyMessage="{{'UserPages.UserExportOffers.NewExportOffer.SpecieFieldPlaceholder' | translate}}.">
                <ng-template pTemplate="selectedItem">
                  <div class="flex align-items-center gap-2" *ngIf="selectedVariety">
                      <img [src]="selectedVariety.urlIcon" style="width: 18px" alt=""/>
                      <div>{{ selectedVariety.name }}</div>
                  </div>
                </ng-template>
                <ng-template let-variety pTemplate="item">
                    <div class="flex align-items-center gap-2">
                        <img [src]="variety.urlIcon" style="width: 18px" alt=""/>
                        <div>{{ variety.name }}</div>
                    </div>
                </ng-template>
              </p-dropdown>
              <small *ngIf="submit && step1FormGroup.controls['varietyId'].invalid" class="p-error">{{getErrorMessage(step1FormGroup, 'varietyId')}}</small>
            </div>
            <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
              <label for="caseQuantityPerPallet" style="font-weight: bolder;">{{'UserPages.UserExportOffers.NewExportOffer.CasePerPalletsFieldLabel' | translate}} *</label>
              <p-inputNumber inputId="caseQuantityPerPallet" formControlName="caseQuantityPerPallet" placeholder="{{'UserPages.UserExportOffers.NewExportOffer.CasePerPalletsFieldPlaceholder' | translate}}"></p-inputNumber>
              <small *ngIf="submit && step1FormGroup.controls['caseQuantityPerPallet'].invalid" class="p-error">{{getErrorMessage(step1FormGroup, 'caseQuantityPerPallet')}}</small>
            </div>
            <div class="field col-12 md:col-3 lg:col-3">
              <label for="listPrice" style="font-weight: bolder;">{{'UserPages.UserExportOffers.NewExportOffer.ListPriceFieldLabel' | translate}} *</label>
              <p-inputNumber inputId="listPrice" formControlName="listPrice" mode="currency" currency="USD" locale="en-US" [min]="0" placeholder="{{'UserPages.UserExportOffers.NewExportOffer.ListPriceFieldPlaceholder' | translate}}"></p-inputNumber>
              <small *ngIf="submit && step1FormGroup.controls['listPrice'].invalid" class="p-error">{{getErrorMessage(step1FormGroup, 'listPrice')}}</small>
            </div>
            <div class="field col-12 md:col-3 lg:col-3">
              <label for="incoterms" style="font-weight: bolder;">{{'UserPages.UserExportOffers.NewExportOffer.IncotermsFieldLabel' | translate}} *</label>
              <p-dropdown inputId="incoterms"
                formControlName="incoterms"
                [options]="incotermsOptions"
                optionLabel="label"
                optionValue="value"
                (onChange)="onChangeIncoterm($event)"
                [showClear]="true"
                placeholder="{{'UserPages.UserExportOffers.NewExportOffer.IncotermsFieldPlaceholder' | translate}}"></p-dropdown>
              <small *ngIf="submit && step1FormGroup.controls['incoterms'].invalid" class="p-error">{{getErrorMessage(step1FormGroup, 'incoterms')}}</small>
            </div>
            <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;" *ngIf="!exportOffer">
              <label for="palletQuantity" style="font-weight: bolder;">{{'UserPages.UserExportOffers.NewExportOffer.PalletQuantityFieldLabel' | translate}} *</label>
              <p-inputNumber inputId="palletQuantity" formControlName="palletQuantity" [useGrouping]="false" placeholder="{{'UserPages.UserExportOffers.NewExportOffer.PalletQuantityFieldPlaceholder' | translate}}"></p-inputNumber>
              <small *ngIf="submit && step1FormGroup.controls['palletQuantity'].invalid" class="p-error">{{getErrorMessage(step1FormGroup, 'palletQuantity')}}</small>
            </div>
            <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;" *ngIf="exportOffer">
              <label for="availablePallets" style="font-weight: bolder;">{{'UserPages.UserExportOffers.EditExportOffer.AvailablePalletsLabel' | translate}} *</label>
              <p-inputNumber inputId="availablePallets" formControlName="availablePallets" [min]="1" placeholder="{{'UserPages.UserExportOffers.EditExportOffer.AvailablePalletsPlaceholder' | translate}}"></p-inputNumber>
              <small *ngIf="formGroup.controls['availablePallets'] && formGroup.controls['availablePallets'].invalid" class="p-error">{{getErrorMessage(formGroup, 'availablePallets')}}</small>
            </div>
            <div class="field col-12 md:col-6 lg:col-6">
              <label for="paymentAgainstBL" style="font-weight: bolder; font-size: calc(100% - 4px); white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ paymentBLTitle | translate }} *</label>
              <p-dropdown 
                id="paymentAgainstBL" 
                formControlName="paymentAgainstBL" 
                [options]="paymentBLItems" 
                optionLabel="label" 
                optionValue="value"
                (onChange)="onChangePayment($event)" 
                placeholder="{{'UserPages.UserExportOffers.NewExportOffer.PaymentAgainstBLLabel' | translate}}">
              </p-dropdown>
              <small *ngIf="submit && step1FormGroup.controls['paymentAgainstBL'].invalid" class="p-error">{{getErrorMessage(step1FormGroup, 'paymentAgainstBL')}}</small>
              <small *ngIf="showPaymentBLPercent">{{'UserPages.UserExportOffers.NewExportOffer.FinalPaymentSubjectQCReview' | translate}} {{finalPaymentPercent}}%</small>
            </div>
            <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
              <label for="fridgeStorage" style="font-weight: bolder;">{{'UserPages.UserExportOffers.NewExportOffer.FridstorageFieldLabel' | translate}} *</label>
              <p-dropdown id="fridgeStorage"
                formControlName="fridgeStorageId"
                [options]="fridgeStorageOptions"
                optionValue="value"
                optionLabel="label"
                [filter]="true"
                filterBy="label"
                [showClear]="true"
                placeholder="{{'UserPages.UserExportOffers.NewExportOffer.FridstorageFieldPlaceholder' | translate}}"
                emptyMessage="{{'UserPages.UserExportOffers.NewExportOffer.FridgestorageEmptyMessage' | translate}}"
                (onChange)="onChangeFridgestorage()">
              </p-dropdown>
              <small *ngIf="submit && step1FormGroup.controls['fridgeStorageId'].invalid" class="p-error">{{getErrorMessage(step1FormGroup, 'fridgeStorageId')}}</small>
            </div>
            <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
            </div>
          </div>
          <div class="grid" style="margin-top: 30px;">
            <div class="col-6"></div>
            <div class="col-6">
              <div class="p-fluid formgrid grid">
                <div class="col-6"></div>
                <div class="col-6">
                  <p-button label="{{'UserPages.UserExportOffers.NewExportOffer.NextButton' | translate}}" (onClick)="showStep2()" [loading]="loading"></p-button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div id="step2" *ngIf="step == step2">
        <form [formGroup]="step2FormGroup">
          <h2 style="color: #8c2b87;">{{'UserPages.UserExportOffers.NewExportOffer.StepLabel' | translate}} 2 {{'UserPages.UserExportOffers.NewExportOffer.StepOfLabel' | translate}} 3</h2>
          <p-progressBar [value]="66.66" color="#8c2b87"></p-progressBar>
          <h2>{{'UserPages.UserExportOffers.NewExportOffer.PageTitle3' | translate}}</h2>
          <p>{{'UserPages.UserExportOffers.NewExportOffer.PageSubtitle3' | translate}}</p>

          <div class="p-fluid formgrid mr-2 ml-2 grid" style="margin-top: 40px;">
            <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
              <label for="harvestDate" style="font-weight: bolder;">{{'UserPages.UserExportOffers.NewExportOffer.HarvestDateFieldLabel' | translate}} *</label>
              <p-calendar
                inputId="harvestDate"
                formControlName="harvestDate"
                appendTo="body"
                [showButtonBar]="true"
                [showIcon]="true"
                placeholder="{{'UserPages.UserExportOffers.NewExportOffer.HarvestDateFieldPlaceholder' | translate}}"
                [showWeek]="true"
                [firstDayOfWeek]="1"></p-calendar>
              <small *ngIf="submit && step2FormGroup.controls['harvestDate'].invalid" class="p-error">{{getErrorMessage(step2FormGroup, 'harvestDate')}}</small>
            </div>
            <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
              <label for="shelfLife" style="font-weight: bolder;">Shelf Life *</label>
              <p-inputNumber inputId="shelfLife" formControlName="shelfLife" placeholder="Shelf life" [min]="1" [max]="1000"></p-inputNumber>
              <small *ngIf="submit && step2FormGroup.controls['shelfLife'].invalid" class="p-error">{{getErrorMessage(step2FormGroup, 'shelfLife')}}</small>
            </div>
            <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
              <label for="netKilos" style="font-weight: bolder;">{{'UserPages.UserExportOffers.NewExportOffer.NetKiloFieldLabel' | translate}} *</label>
              <input pInputText inputId="netKilos" formControlName="netKilos" [maxlength]="50" placeholder="{{'UserPages.UserExportOffers.NewExportOffer.NetKiloFieldPlaceholder' | translate}}"/>
              <small *ngIf="submit && step2FormGroup.controls['netKilos'].invalid" class="p-error">{{getErrorMessage(step2FormGroup, 'netKilos')}}</small>
            </div>
            <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
              <label for="grossKilos" style="font-weight: bolder;">{{'UserPages.UserExportOffers.NewExportOffer.GrossKiloFieldLabel' | translate}} *</label>
              <input pInputText inputId="grossKilos" formControlName="grossKilos" [maxlength]="50" placeholder="{{'UserPages.UserExportOffers.NewExportOffer.GrossKiloFieldPlaceholder' | translate}}"/>
              <small *ngIf="submit && step2FormGroup.controls['grossKilos'].invalid" class="p-error">{{getErrorMessage(step2FormGroup, 'grossKilos')}}</small>
            </div>
            <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
              <label for="speciesSize" style="font-weight: bolder;">{{'UserPages.UserExportOffers.NewExportOffer.SpeciesSizeFieldLabel' | translate}} *</label>
              <input pInputText inputId="speciesSize" formControlName="speciesSize" [maxlength]="50" placeholder="{{'UserPages.UserExportOffers.NewExportOffer.SpeciesSizeFieldPlaceholder' | translate}}"/>
              <small *ngIf="submit && step2FormGroup.controls['speciesSize'].invalid" class="p-error">{{getErrorMessage(step2FormGroup, 'speciesSize')}}</small>
            </div>
            <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
              <label for="readyToLoadDate" style="font-weight: bolder;">{{'UserPages.UserExportOffers.NewExportOffer.ReadyToLoadDateFieldLabel' | translate}} *</label>
              <p-calendar
                inputId="readyToLoadDate"
                formControlName="readyToLoadDate"
                appendTo="body"
                [showButtonBar]="true"
                [showIcon]="true"
                placeholder="{{'UserPages.UserExportOffers.NewExportOffer.ReadyToLoadDateFieldPlaceholder' | translate}}"
                [showWeek]="true"
                [firstDayOfWeek]="1"></p-calendar>
              <small *ngIf="submit && step2FormGroup.controls['readyToLoadDate'].invalid" class="p-error">{{getErrorMessage(step2FormGroup, 'readyToLoadDate')}}</small>
            </div>
            <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
              <label for="packagings">{{'UserPages.UserExportOffers.NewExportOffer.PackagingLabel' | translate}} *</label>
              <!-- <p-multiSelect formControlName="packagings" [(ngModel)]="packagingSelected" [options]="packagingList" placeholder="{{'UserPages.UserExportOffers.NewExportOffer.SelectText' | translate}}" optionLabel="name" optionValue="id" option [showHeader]="false" (onChange)="filterPackagingMultiselect($event.value)" required>
              </p-multiSelect> -->
              <p-dropdown id="packagings"
                formControlName="packagings"
                [options]="packagingList"
                optionLabel="name"
                [filter]="true"
                filterBy="name"
                [showClear]="false"
                placeholder="{{'UserPages.UserExportOffers.NewExportOffer.SelectText' | translate}}"
                (onChange)="filterPackaging($event.value)">
              </p-dropdown>
              <small *ngIf="submit && step2FormGroup.controls['packagings'].invalid" class="p-error">{{getErrorMessage(step2FormGroup, 'packagings')}}</small>
            </div>
            <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
              <label for="otherPackaging">{{'UserPages.UserExportOffers.NewExportOffer.OtherLabel' | translate}}</label>
              <input pInputText inputId="otherPackaging"  [maxlength]="formLimit.otherPackagingLimit" formControlName="otherPackaging" placeholder="{{'UserPages.UserExportOffers.NewExportOffer.OtherLabel' | translate}}"/>
              <small *ngIf="submit && step2FormGroup.controls['otherPackaging'].invalid" class="p-error">{{getErrorMessage(step2FormGroup, 'otherPackaging')}}</small>
            </div>
            <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
              <label for="category" style="font-weight: bolder;">{{'UserPages.UserExportOffers.NewExportOffer.CategoryFieldLabel' | translate}} *</label>
              <input pInputText inputId="category" [maxlength]="formLimit.categoryLimit" formControlName="category" placeholder="{{'UserPages.UserExportOffers.NewExportOffer.CategoryFieldPlaceholder' | translate}}"/>
              <small *ngIf="submit && step2FormGroup.controls['category'].invalid" class="p-error">{{getErrorMessage(step2FormGroup, 'category')}}</small>
            </div>
          </div>

          <h2>{{'UserPages.UserExportOffers.NewExportOffer.PageTitle4' | translate}}</h2>
          <p>{{'UserPages.UserExportOffers.NewExportOffer.PageSubtitle4' | translate}}</p>
          <div class="p-fluid formgrid mr-2 ml-2 grid" style="margin-top: 40px;">
            <div class="field col-12 md:col-4 lg:col-4">
              <label for="marketCountry1" style="font-weight: bolder;">{{'UserPages.UserExportOffers.NewExportOffer.MarketCountry1FieldLabel' | translate}}*</label>
              <p-dropdown inputId="marketCountry1"
                formControlName="marketCountry1Id"
                [options]="countries"
                optionValue="id"
                optionLabel="name"
                placeholder="{{'UserPages.UserExportOffers.NewExportOffer.MarketCountry1FieldPlaceholder' | translate}}"
                (onChange)="onChangeMarketCountry1()">
                <ng-template pTemplate="selectedItem">
                  <div class="flex align-items-center gap-2" *ngIf="selectedMarketCountry1">
                      <img [src]="'../../../../assets/img/flags/' + selectedMarketCountry1.code + '.png'" style="width: 18px" alt=""/>
                      <div>{{ selectedMarketCountry1 | countryDescription }}</div>
                  </div>
                </ng-template>
                <ng-template let-country pTemplate="item">
                    <div class="flex align-items-center gap-2">
                        <img [src]="'../../../../assets/img/flags/' + country.code + '.png'" style="width: 18px" alt=""/>
                        <div>{{ country | countryDescription }}</div>
                    </div>
                </ng-template>
              </p-dropdown>
              <small *ngIf="submit && step2FormGroup.controls['marketCountry1Id'].invalid" class="p-error">{{getErrorMessage(formGroup, 'marketCountry1Id')}}</small>
            </div>
            <div class="field col-12 md:col-4 lg:col-4">
              <label for="marketCountry2" style="font-weight: bolder;">{{'UserPages.UserExportOffers.NewExportOffer.MarketCountry2FieldLabel' | translate}}</label>
              <p-dropdown inputId="marketCountry2"
                formControlName="marketCountry2Id"
                [options]="countries"
                optionValue="id"
                optionLabel="{{profile?.lang === 'es' ? 'nameEs' : 'name'}}"
                placeholder="{{'UserPages.UserExportOffers.NewExportOffer.MarketCountry2FieldPlaceholder' | translate}}"
                [showClear]="true"
                (onChange)="onChangeMarketCountry2()">
                <ng-template pTemplate="selectedItem">
                  <div class="flex align-items-center gap-2" *ngIf="selectedMarketCountry2">
                      <img [src]="'../../../../assets/img/flags/' + selectedMarketCountry2.code + '.png'" style="width: 18px" alt=""/>
                      <div>{{ selectedMarketCountry2 | countryDescription }}</div>
                  </div>
                </ng-template>
                <ng-template let-country pTemplate="item">
                    <div class="flex align-items-center gap-2">
                        <img [src]="'../../../../assets/img/flags/' + country.code + '.png'" style="width: 18px" alt=""/>
                        <div>{{ country | countryDescription }}</div>
                    </div>
                </ng-template>
              </p-dropdown>
            </div>
            <div class="field col-12 md:col-4 lg:col-4">
              <label for="marketCountry3" style="font-weight: bolder;">{{'UserPages.UserExportOffers.NewExportOffer.MarketCountry3FieldLabel' | translate}}</label>
              <p-dropdown
                inputId="marketCountry3"
                formControlName="marketCountry3Id"
                [options]="countries"
                optionValue="id"
                optionLabel="name"
                placeholder="{{'UserPages.UserExportOffers.NewExportOffer.MarketCountry3FieldPlaceholder' | translate}}"
                [showClear]="true"
                (onChange)="onChangeMarketCountry3()">
                <ng-template pTemplate="selectedItem">
                  <div class="flex align-items-center gap-2" *ngIf="selectedMarketCountry3">
                      <img [src]="'../../../../assets/img/flags/' + selectedMarketCountry3.code + '.png'" style="width: 18px" alt=""/>
                      <div>{{ selectedMarketCountry3 | countryDescription }}</div>
                  </div>
                </ng-template>
                <ng-template let-country pTemplate="item">
                    <div class="flex align-items-center gap-2">
                        <img [src]="'../../../../assets/img/flags/' + country.code + '.png'" style="width: 18px" alt=""/>
                        <div>{{ country | countryDescription }}</div>
                    </div>
                </ng-template>
              </p-dropdown>
            </div>
            <div class="field col-12 md:col-3 lg:col-3"></div>
          </div>

          <div class="grid" style="margin-top: 30px;">
            <div class="col-6"></div>
            <div class="col-6">
              <div class="p-fluid formgrid grid">
                <div class="col-6">
                  <p-button label="{{'UserPages.UserExportOffers.NewExportOffer.BackButton' | translate}}" (onClick)="showStep1()" styleClass="p-button-outlined"></p-button>
                </div>
                <div class="col-6">
                  <p-button label="{{'UserPages.UserExportOffers.NewExportOffer.NextButton' | translate}}" (onClick)="showStep3()" [loading]="loading"></p-button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div id="step3" *ngIf="step == step3">
        <h2 style="color: #8c2b87;">{{'UserPages.UserExportOffers.NewExportOffer.StepLabel' | translate}} 3 {{'UserPages.UserExportOffers.NewExportOffer.StepOfLabel' | translate}} 3</h2>
        <p-progressBar [value]="100" color="#8c2b87"></p-progressBar>
        <div>
          <h2>{{'UserPages.UserExportOffers.NewExportOffer.Step2Title' | translate}}</h2>
          <p>{{'UserPages.UserExportOffers.NewExportOffer.Step3Subtitle' | translate}}.</p>
          <p-fileUpload name="demo[]"
            #imagesUploader
            (onSelect)="onSelectImage($event, imagesUploader)"
            (onRemove)="onRemoveImage($event, imagesUploader)"
            (onClear)="onClearImageUploader($event, imagesUploader)"
            [showUploadButton]="false"
            [multiple]="true"
            accept=".jpg, .jpeg, .png"
            [fileLimit]="3"
            [files]="uploadedImages"
            [maxFileSize]="3000000"
            chooseLabel="{{'UserPages.UserExportOffers.NewExportOffer.ChooseImagesButton' | translate}}"
            cancelLabel="{{'UserPages.UserExportOffers.NewExportOffer.CancelImagesButton' | translate}}">
            <ng-template pTemplate="toolbar">
              <div class="py-3"><h2>{{'UserPages.UserExportOffers.NewExportOffer.LoadImagesFieldLabel' | translate}}</h2></div>
            </ng-template>
            <ng-template pTemplate="content">

            </ng-template>
          </p-fileUpload>
          <!-- <app-export-offer-picture-uploader [exportOfferId]="exportOfferId"></app-export-offer-picture-uploader> -->
        </div>
        <div style="margin-top: 40px;">
          <h2>{{'UserPages.UserExportOffers.NewExportOffer.Step3ProductCertificateTypeTitle' | translate}}</h2>
          <p>{{'UserPages.UserExportOffers.NewExportOffer.Step3ProductCertificateTypeSubtitle' | translate}}</p>
          <app-export-offer-certificate-type [exportOfferId]="exportOfferId" [otherCertificateType]="exportOffer?.otherCertificateType"></app-export-offer-certificate-type>
        </div>
        <div class="grid" style="margin-top: 30px;">
          <div class="col-6"></div>
          <div class="col-6">
            <div class="p-fluid formgrid grid">
              <div class="col-6">
                <p-button label="{{'UserPages.UserExportOffers.NewExportOffer.BackButton' | translate}}" (onClick)="backStep2()" styleClass="p-button-outlined"></p-button>
              </div>
              <div class="col-6">
                <p-button label="{{'UserPages.UserExportOffers.NewExportOffer.FinishButton' | translate}}" (onClick)="finish()" [loading]="loading"></p-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="finalStep" *ngIf="step == finalStep">
        <div class="flex align-items-center justify-content-center flex-column">
          <h2>{{'UserPages.UserExportOffers.NewExportOffer.FinalStepTitle' | translate}}</h2>
          <div style="margin-top: 20px;" class="flex align-items-center justify-content-center flex-column">
            <img src="../../../../../assets/img/create-export-offer-aditional-icon.png" alt="Icon" style="border-radius: 7px; width: 100%; max-width: 300px; height: auto;"/>
            <p>{{'UserPages.UserExportOffers.NewExportOffer.FinalStepText1' | translate}}</p>
            <p>{{'UserPages.UserExportOffers.NewExportOffer.FinalStepText2' | translate}}</p>
            <p>{{'UserPages.UserExportOffers.NewExportOffer.FinalStepText3' | translate}}</p>
          </div>
          <div style="margin-top: 20px;" class="flex align-items-center justify-content-center flex-column gap-4">
            <p-button label="{{'UserPages.UserExportOffers.NewExportOffer.FinalStepAdditionalInfoButtonLabel' | translate}}" (onClick)="goToExportOfferEditAdditionalInfo()"></p-button>
            <p-button label="{{'UserPages.UserExportOffers.NewExportOffer.FinalStepSkipAdditionalInfoButtonLabel' | translate}}" styleClass="p-button-outlined" (onClick)="goToExportOffersPage()"></p-button>
          </div>
        </div>
      </div>
    </p-card>
  </div>
</app-user-dashboard>
<p-toast></p-toast>

<p-dialog header="{{'UserPages.UserExportOffers.NewExportOffer.NewFriedStorageModal.ModalTitle' | translate}}" [(visible)]="loadFridgestorageDialogVisible" [style]="{width: '70%'}" [modal]="true">
  <form [formGroup]="fridgestorageFormGroup">
    <div class="p-fluid formgrid grid" style="padding: 20px;">
      <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
        <label for="fridgestorageName">{{'UserPages.UserExportOffers.NewExportOffer.NewFriedStorageModal.NameFieldLabel' | translate}} *</label>
        <input id="fridgestorageName" formControlName="fridgestorageName" type="text" pInputText [maxlength]="formLimit.nameLimit" required autofocus placeholder="{{'UserPages.UserExportOffers.NewExportOffer.NewFriedStorageModal.NameFieldPlaceholder' | translate}}"/>
        <small *ngIf="submit && fridgestorageFormGroup.controls['fridgestorageName'].invalid" class="p-error">{{getErrorMessage(fridgestorageFormGroup, 'fridgestorageName')}}</small>
      </div>
      <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
        <label for="fridgestorageVat">{{'UserPages.UserExportOffers.NewExportOffer.NewFriedStorageModal.VatFieldLabel' | translate}}</label>
        <input id="fridgestorageVat" pInputText formControlName="fridgestorageVat" [maxlength]="formLimit.vatLimit" placeholder="{{'UserPages.UserExportOffers.NewExportOffer.NewFriedStorageModal.VatFieldPlaceholder' | translate}}"/>
      </div>
      <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
        <label for="fridgestorageContactName">{{'UserPages.UserExportOffers.NewExportOffer.NewFriedStorageModal.ContactNameFieldLabel' | translate}} *</label>
        <input id="fridgestorageContactName" formControlName="fridgestorageContactName" type="text"  [maxlength]="formLimit.nameLimit" pInputText required placeholder="{{'UserPages.UserExportOffers.NewExportOffer.NewFriedStorageModal.ContactNameFieldPlaceholder' | translate}}"/>
        <small *ngIf="submit && fridgestorageFormGroup.controls['fridgestorageContactName'].invalid" class="p-error">{{getErrorMessage(fridgestorageFormGroup, 'fridgestorageContactName')}}</small>
      </div>
      <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
        <label for="fridgestorageContactEmail">{{'UserPages.UserExportOffers.NewExportOffer.NewFriedStorageModal.ContactEmailFieldLabel' | translate}} *</label>
        <input id="fridgestorageContactEmail" formControlName="fridgestorageContactEmail" type="text" [maxlength]="formLimit.mailLimit" pInputText required placeholder="{{'UserPages.UserExportOffers.NewExportOffer.NewFriedStorageModal.ContactEmailFieldPlaceholder' | translate}}"/>
        <small *ngIf="submit && fridgestorageFormGroup.controls['fridgestorageContactEmail'].invalid" class="p-error">{{getErrorMessage(fridgestorageFormGroup, 'fridgestorageContactEmail')}}</small>
      </div>
      <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
        <label for="fridgestorageContactAddress">{{'UserPages.UserExportOffers.NewExportOffer.NewFriedStorageModal.FactoryAddressLabel' | translate}} *</label>
        <input id="fridgestorageContactAddress" formControlName="fridgestorageContactAddress" type="text" [maxlength]="formLimit.addressLimit" pInputText required placeholder="{{'UserPages.UserExportOffers.NewExportOffer.NewFriedStorageModal.FactoryAddressPlaceholder' | translate}}"/>
        <small *ngIf="submit && fridgestorageFormGroup.controls['fridgestorageContactAddress'].invalid" class="p-error">{{getErrorMessage(fridgestorageFormGroup, 'fridgestorageContactAddress')}}</small>
      </div>
      <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
        <label for="fridgestorageContactCity">{{'UserPages.UserExportOffers.NewExportOffer.NewFriedStorageModal.ContactCityFieldLabel' | translate}} *</label>
        <input id="fridgestorageContactCity" formControlName="fridgestorageContactCity" type="text" [maxlength]="formLimit.cityLimit" pInputText required placeholder="{{'UserPages.UserExportOffers.NewExportOffer.NewFriedStorageModal.ContactCityFieldPlaceholder' | translate}}"/>
        <small *ngIf="submit && fridgestorageFormGroup.controls['fridgestorageContactCity'].invalid" class="p-error">{{getErrorMessage(fridgestorageFormGroup, 'fridgestorageContactCity')}}</small>
      </div>
      <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
        <label for="fridgestorageContactCountryId">{{'UserPages.UserExportOffers.NewExportOffer.NewFriedStorageModal.CountryFieldLabel' | translate}} *</label>
        <p-dropdown
            id="fridgestorageContactCountryId"
            [options]="countries"
            formControlName="fridgestorageContactCountryId"
            optionLabel="name"
            optionValue="id"
            [filter]="true"
            filterBy="name"
            [showClear]="true"
            placeholder="{{'UserPages.UserExportOffers.NewExportOffer.NewFriedStorageModal.CountryFieldPlaceholder' | translate}}"
            appendTo="body">
        </p-dropdown>
        <small *ngIf="submit && fridgestorageFormGroup.controls['fridgestorageContactCountryId'].invalid" class="p-error">{{getErrorMessage(fridgestorageFormGroup, 'fridgestorageContactCountryId')}}</small>
      </div>
      <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
        <label for="fridgestorageContactPhone">{{'UserPages.UserExportOffers.NewExportOffer.NewFriedStorageModal.FridgeStorageContactPhoneLabel' | translate}} *</label>
        <input id="fridgestorageContactPhone" [maxlength]="formLimit.phoneLimit" formControlName="fridgestorageContactPhone" type="text" pInputText placeholder="{{'UserPages.UserExportOffers.NewExportOffer.NewFriedStorageModal.FridgeStorageContactPhonePlaceholder' | translate}}"/>
        <small *ngIf="submit && fridgestorageFormGroup.controls['fridgestorageContactPhone'].invalid" class="p-error">{{getErrorMessage(fridgestorageFormGroup, 'fridgestorageContactPhone')}}</small>
      </div>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <p-button label="{{'UserPages.UserExportOffers.NewExportOffer.NewFriedStorageModal.CloseButton' | translate}}" (onClick)="onCancelNewFridgestorage()" styleClass="p-button-outlined"></p-button>
    <p-button label="{{'UserPages.UserExportOffers.NewExportOffer.NewFriedStorageModal.SaveButton' | translate}}" (onClick)="saveFridgestorage()" [loading]="loading"></p-button>
  </ng-template>
</p-dialog>
