<app-dashboard-layout>
  <app-content-body>
    <div class="flex justify-content-center align-items-center flex-column">
      <div class="flex justify-content-end w-full mr-2">
        <app-switch-lang></app-switch-lang>
      </div>
      <img src="../../../../assets/img/fruzty-logo-slogan.png" width="480" height="214"/>
      <p-card header="{{'ResetPasswordRequestPage.CardHeaderText' | translate}}" subheader="{{'ResetPasswordRequestPage.SubheaderCardText' | translate}}" [style]="{'width': '30rem', 'margin-top': '8em'}">
        <form [formGroup]="formGroup">
          <div class="p-fluid formgrid grid">
            <div class="field col-12">
              <label for="email">{{'ResetPasswordRequestPage.EmailFieldLabel' | translate}}</label>
              <input id="email" type="text" pInputText formControlName="email"/>
              <small *ngIf="submit && formGroup.controls['email'].invalid" class="p-error">{{getErrorMessage('email')}}</small>
            </div>
            <div class="field col-12">
              <p-button label="{{'ResetPasswordRequestPage.SendButton' | translate}}" [loading]="loading" (click)="onSubmit()"/>
            </div>
            <div class="field col-12">
              <p-message *ngIf="success" severity="success" text="{{'ResetPasswordRequestPage.SuccessMessage' | translate}}"></p-message>
            </div>
            <div class="field col-12">
              <a [routerLink]="'/'">{{'ResetPasswordRequestPage.BackLink' | translate}}</a>
            </div>
          </div>
        </form>
      </p-card>
    </div>
  </app-content-body>
</app-dashboard-layout>

<p-toast></p-toast>
