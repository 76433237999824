import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { DocumentServiceGateway } from 'src/app/application/gateway/DocumentServiceGateway';
import { FrozenFruitPalletGateway } from 'src/app/application/gateway/FrozenFruitPalletGateway';
import { FrozenFruitPallet, FrozenFruitPalletList, FrozenFruitPalletQCOriginReviewRequest } from 'src/app/domain/models/FrozenFruitPallet';
import { GenericOrderDetail, UploadImportOrderDetailPdfRequest } from 'src/app/domain/models/ImportOrder';
import { BasicUser } from 'src/app/domain/models/User';
import { Routes } from 'src/app/infrastructure/routes';
import { ErrorMessageUtil } from 'src/app/ui/util/error-message-util';

@Component({
  selector: 'app-qc-user-frozen-fruit-origin-review',
  templateUrl: './qc-user-frozen-fruit-origin-review.component.html',
  styleUrls: ['./qc-user-frozen-fruit-origin-review.component.css'],
  providers: [MessageService]
})
export class QcUserFrozenFruitOriginReviewComponent implements OnInit {

  orderDetailId: number | undefined
  dto: FrozenFruitPalletList | undefined
  orderDetail!: GenericOrderDetail
  pallets: FrozenFruitPallet[] = []
  approvedPallets: FrozenFruitPallet[] = []
  qcReviewPdf: any
  qcContainerConsolidationPdf: any

  submit: boolean = false
  loading: boolean = false

  profile: BasicUser | undefined

  formGroup: FormGroup = this._formBuilder.group({
    qualityControlDate: [null, Validators.required],
    originQCReviewPrice: [null, Validators.required],
    qcObservations: [null]
  })

  constructor(private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _messageService: MessageService,
    public translate: TranslateService,
    private _authService: AuthGateway,
    private _formBuilder: FormBuilder,
    private _frozenFruitPalletService: FrozenFruitPalletGateway,
    private _documentService: DocumentServiceGateway) {
    this._activatedRoute.params.subscribe(params => {
      this.orderDetailId = params['orderDetailId']
    })
  }

  ngOnInit(): void {
    this.profile = this._authService.getUser()
    this.loadPallets()
  }

  loadPallets() {
    if(this.orderDetailId) {
      this._frozenFruitPalletService.getAllByFrozenFruitOrderDetail(this.orderDetailId).subscribe({
        next: (v) => {
          console.log(v)
          this.dto = v
          this.orderDetail = this.dto.orderDetail
          this.pallets = this.dto.pallets
          this.loadForm()
          this.loading = false
        },
        error: (e) => {
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: 'Could not retrieve the import order detail' })
          this.loading = false
          console.log(e)
        },
        complete: () =>  {
          this.loading = false
          console.info('Import order detail query successfully')
        }
      })
    }
  }

  loadForm() {
    if(this.orderDetail) {
      this.formGroup.controls['qualityControlDate'].setValue(this.orderDetail.qualityControlDate ? new Date(this.orderDetail.qualityControlDate) : null)
      this.formGroup.controls['qcObservations'].setValue(this.orderDetail.originQCObservations)
      this.formGroup.controls['originQCReviewPrice'].setValue(this.orderDetail.originQCReviewPrice ? this.orderDetail.originQCReviewPrice : null)
      if(this.orderDetail.qualityControlDate)
        this.formGroup.controls['qualityControlDate'].disable()
    }
  }

  savePallets() {
    if(this.orderDetailId) {

      this.submit = true
      if(this.formGroup.valid) {
        let approvedPalletIds: number[] = []

        this.approvedPallets.forEach(x => {
          if(x.id)
            approvedPalletIds.push(x.id)
        })

        let request: FrozenFruitPalletQCOriginReviewRequest = {
          frozenFruitOrderDetailId: this.orderDetailId,
          approvedPalletIds: approvedPalletIds,
          qcReviewPrice: this.formGroup.controls['originQCReviewPrice'].value,
          qualityControlDate: this.formGroup.controls['qualityControlDate'].value,
          qcObservations: this.formGroup.controls['qcObservations'].value
        }

        this.loading = true
        this._frozenFruitPalletService.qcCompanyVerifyPallets(request).subscribe({
          next: (v) => {
            this.loadPallets()
            this._messageService.add({ severity: 'success', summary: 'Acción exitosa', detail: 'Los pallets fueron actualizados con éxito.' })
            this.submit = false
            this.loading = false
          },
          error: (e) => {
            console.log(e)
            this.submit = false
            this.loading = false
            this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudieron actualizar los pallets.' })
          },
          complete: () =>  {
            console.info('Import Order Detail updated successfully')
          }
        })
      }
      else {
        this.loading = false
      }
    }
  }

  back() {
    this._router.navigate(['/' + Routes.qcUserReviews])
  }

  getErrorMessage(formGroup: FormGroup, fieldName: string): string {
    return ErrorMessageUtil.getErrorMessage(formGroup, this.translate, fieldName)
  }

  getSpeciesName(orderDetail: GenericOrderDetail): string {
    if(this.profile) {
      switch(this.profile.lang) {
        case 'es':
          return orderDetail.speciesNameEs
        case 'en':
          return orderDetail.speciesNameEn
        default:
          return orderDetail.speciesNameEn
      }
    }
    return ''
  }

  onSelectQCReviewPdf(event: any) {
    this.qcReviewPdf = event.files[0]
    this.saveQCReviewPdf()
  }

  onSelectQCConsolidationPdf(event: any) {
    this.qcContainerConsolidationPdf = event.files[0]
    this.saveQCConsolidationPdf()
  }

  saveQCReviewPdf() {
    if(this.qcReviewPdf && this.dto) {
      let qcReviewPdfRequest: UploadImportOrderDetailPdfRequest = {
        importOrderDetailId: this.dto.orderDetail.id,
        pdf: this.qcReviewPdf
      }
      this._frozenFruitPalletService.uploadQCReviewPdf(qcReviewPdfRequest).subscribe({
        next: (v) => {
          this.loadPallets()
          this._messageService.add({ severity: 'success', summary: 'Acción exitosa', detail: 'El PDF de inspección fue subido con éxito.' })
        },
        error: (e) => {
          console.log(e)
          this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudo subir el PDF de inspección.' })
        },
        complete: () =>  {
          this.loading = false
          console.info('QCReviewPdf uploaded successfully')
        }
      })
    }
  }

  saveQCConsolidationPdf() {
    if(this.qcContainerConsolidationPdf && this.dto) {
      let qcContainerConsolidationPdfRequest: UploadImportOrderDetailPdfRequest = {
        importOrderDetailId: this.dto.orderDetail.id,
        pdf: this.qcContainerConsolidationPdf
      }
      this._frozenFruitPalletService.uploadQCContainerConsolidationPdf(qcContainerConsolidationPdfRequest).subscribe({
        next: (v) => {
          this.loadPallets()
          this._messageService.add({ severity: 'success', summary: 'Acción exitosa', detail: 'El PDF de consolidación fue subido con éxito.' })
        },
        error: (e) => {
          console.log(e)
          this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudo subir el PDF de consolidación.' });
        },
        complete: () =>  {
          this.loading = false
          console.info('QCConsolidationPdf uploaded successfully')
        }
      })
    }
  }

  enableUploaderReviewPdf(): boolean {
    return  this.orderDetail?.qcReviewPdfLength === 0
  }

  enableConsolidationPdf(): boolean {
    return this.orderDetail?.qcContainerConsolidationPdfLength === 0
  }

  getQCReviewDocument() {
    if(this.orderDetail) {
      this._documentService.getFrozenFruitQCReviewDocument(this.orderDetail.id).subscribe((resultBlob: any) => {
        var downloadURL = window.URL.createObjectURL(resultBlob.body);
        window.open(downloadURL);})
    }
  }

  getQCConsolidationDocument() {
    if(this.orderDetail) {
      this._documentService.getFrozenFruitQCConsolidationDocument(this.orderDetail.id).subscribe((resultBlob: any) => {
        var downloadURL = window.URL.createObjectURL(resultBlob.body);
        window.open(downloadURL);})
    }
  }
}
