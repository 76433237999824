import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Route, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { CountryGateway } from 'src/app/application/gateway/CountryGateway';
import { ImportRequestGateway } from 'src/app/application/gateway/ImportRequestGateway';
import { PortGateway } from 'src/app/application/gateway/PortGateway';
import { ProductCertificateTypeGateway } from 'src/app/application/gateway/ProductCertificateTypeGateway';
import { PackagingGateway } from 'src/app/application/gateway/PackagingGateway';
import { SpeciesGateway } from 'src/app/application/gateway/SpeciesGateway';
import { VarietyGateway } from 'src/app/application/gateway/VarietyGateway';
import { Country } from 'src/app/domain/models/Country';
import { NewImportRequest } from 'src/app/domain/models/ImportRequest';
import { Packaging } from 'src/app/domain/models/Packaging';
import { Port } from 'src/app/domain/models/Port';
import { ProductCertificateType } from 'src/app/domain/models/ProductCertificateType';
import { Profile } from 'src/app/domain/models/Profile';
import { Species, SpeciesSize } from 'src/app/domain/models/Species';
import { BasicUser } from 'src/app/domain/models/User';
import { Variety } from 'src/app/domain/models/Variety';
import { Routes } from 'src/app/infrastructure/routes';
import { isDivisor } from 'src/app/ui/util/custom-validators';
import { ErrorMessageUtil } from 'src/app/ui/util/error-message-util';
import { ExportOfferUtils } from 'src/app/ui/util/export-offer-utils';
import { Lang } from 'src/app/ui/util/langs';
import { SpeciesUtils } from 'src/app/ui/util/species-utils';
import { formLimit } from 'src/app/ui/util/form-limit';
//Calendar.prototype.getDateFormat = () => 'dd-mm-yy';

const Steps = {
  Step1: "step1",
  Step2: "step2"
}

@Component({
  selector: 'app-new-import-request',
  templateUrl: './new-import-request.component.html',
  styleUrls: ['./new-import-request.component.css'],
  providers: [MessageService]
})
export class NewImportRequestComponent implements OnInit {
  breadcrumbItemsStep1: MenuItem[] = []
  breadcrumbItemsStep2: MenuItem[] = []
  home: MenuItem = {}

  submit: boolean = false
  submitStep2: boolean = false
  loading: boolean = false

  profile: BasicUser | undefined

  species: Species[] = []
  varieties: Variety[] = []
  selectedSpecies: Species | undefined
  selectedVariety: Variety | undefined

  step: number = 1
  step1: number = 1
  step2: number = 2
  step3: number = 3
  finalStep: number = 4

  colors: string[] = []
  sizes: SpeciesSize[] = []
  qualityControlOptions: any[] = []
  boxTypes: any[] = []
  kilosPerBoxes: any[] = []
  basePallets: any[] = []
  countries: Country[] = []
  countriesOrigin: any[] = []
  destinationPorts: Port[] = []
  minDate: Date = new Date()
  maxDate: Date = new Date()

  formLimit = formLimit

  initalYear: number = new Date().getFullYear();

  selectedCountryFrom: Country | undefined
  selectedCountryTo: Country | undefined

  certificateTypes: ProductCertificateType[] = []
  certificateTypesSelected: number[] = []

  packagingList: Packaging[] = []
  packagingSelected: number[] = []

  incotermsOptions: any[] = []

  // step: string = Steps.Step1

  uploadedImages: any[] = []
  uploadPdf: any[] = []

  years: any[] = []

  formGroup: FormGroup = this._formBuilder.group({
    speciesId: ['', Validators.required],
    varietyId: ['', Validators.required],
    caseQuantityPerPallet: [null, Validators.required],
    pricePerCase: [null, Validators.required],
    incoterm: ['', Validators.required],
    color: [''],
    countryId: ['', Validators.required],
    countryFromId: [null],
    portId: ['', Validators.required],
    speciesSize: ['', Validators.required],
    sizeMillimeters: [null],
    category: [null, Validators.required],
    readyToLoadDate: [null],
    boxType: ['', Validators.required],
    kilosPerBox: [''],
    netKilos: ['', Validators.required],
    packaging: [''],
    basePallets: ['', Validators.required],
    fromWeek: [null, Validators.required],
    fromYearWeek: [null, Validators.required],
    toWeek: [null],
    toYearWeek: [null],
    palletQuantity: [20, [Validators.required, isDivisor(20)]],
    otherCertificate: [{value: '', disabled: true}],
    packagings: ['', Validators.required],
    otherPackaging: [{value: '', disabled: true}]
  })

  step1FormGroup: FormGroup = this._formBuilder.group({
    speciesId: ['', Validators.required],
    varietyId: ['', Validators.required],
    caseQuantityPerPallet: [null, Validators.required],
    pricePerCase: [null, Validators.required],
    incoterm: ['EXW', Validators.required],
    paymentAgainstBL: [100, Validators.required],
    palletQuantity: [20, [Validators.required, isDivisor(20)]],
    countryId: ['', Validators.required],
    countryFromId: [null],
    portId: ['', Validators.required],

    
  })

  step2FormGroup: FormGroup = this._formBuilder.group({
    netKilos: ['', Validators.required],
    boxType: ['', Validators.required],
    speciesSize: ['', Validators.required],
    category: [null, Validators.required],
    basePallets: ['', Validators.required],
    productCertificateType: [''],
    otherCertificate: [{value: '', disabled: true}],
    packaging: [''],
    otherPackaging: [{value: '', disabled: true}],
    fromWeek: [null, Validators.required],
    fromYearWeek: [null, Validators.required],
    packagings: ['', Validators.required],
    toWeek: [null],
    toYearWeek: [null],
  })

  constructor(private _router: Router,
    private _messageService: MessageService,
    private _authService: AuthGateway,
    private _formBuilder: FormBuilder,
    private _varietyService: VarietyGateway,
    private _speciesService: SpeciesGateway,
    private _importRequestService: ImportRequestGateway,
    private _countryService: CountryGateway,
    private _portService: PortGateway,
    private _productCertificateTypeService: ProductCertificateTypeGateway,
    private _packagingService: PackagingGateway,
    public translate: TranslateService) {}

  getYears() {
    let current = new Date().getFullYear()
    this.years.push({label: current, value: current})
    let next = current + 1
    this.years.push({label: next, value: next})
  }

  ngOnInit(): void {
    this.profile = this._authService.getUser()

    let breadcrumbStep1ItemLabel1 = 'Load import resquest'
    let breadcrumbStep1ItemLabel2 = 'Load import request pictures'
    let homeItemLabel = 'Import requests'

    if(this.profile) {
      switch(this.profile.lang) {
        case 'en':
          breadcrumbStep1ItemLabel1 = 'Load import resquest'
          breadcrumbStep1ItemLabel2 = 'Load import request pictures'
          homeItemLabel = 'Import requests'
          break
        case 'es':
          breadcrumbStep1ItemLabel1 = 'Cargar nuevo requerimiento de importacion'
          breadcrumbStep1ItemLabel2 = 'Cargar imágenes de requerimiento'
          homeItemLabel = 'Requerimientos de importación'
          break
      }
    }

    this.breadcrumbItemsStep1 = [
      { label: breadcrumbStep1ItemLabel1 },
      { label: breadcrumbStep1ItemLabel2, disabled: true }
    ]

    this.breadcrumbItemsStep2 = [
      { label: breadcrumbStep1ItemLabel1, command: () => this.showStep1() },
      { label: breadcrumbStep1ItemLabel2 }
    ]
    this.home = { label: homeItemLabel, routerLink: '/' + Routes.userImportRequest };

    this.loadSpecies()
    this.loadCountries()
    this.loadProductCertificateTypes()
    this.loadPackagings()
    this.minDate = new Date(this.initalYear, 1, 1 );
    this.maxDate = new Date(this.initalYear + 1, 1, 1 );
  }

  loadSpecies() {
    this._speciesService.getAllByProductType(1).subscribe({
      next: (v) => {
        this.species = v
        this.loading = false
        this.incotermsOptions = ExportOfferUtils.getIncotermsOptions()
        this.getYears()

        if(this.profile) {
          this.colors = ExportOfferUtils.getColors(this.profile.lang)
          this.boxTypes = ExportOfferUtils.getBoxTypes(this.profile.lang)
          this.kilosPerBoxes = ExportOfferUtils.getKilosPerBox()
          this.basePallets = ExportOfferUtils.getBasePallets(this.profile.lang)
        }
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Species.ManyRetrieveError') })
      },
      complete: () => console.info('Species query successfully')
    })
  }

  loadDestinationPorts() {
    if (this.step1FormGroup.controls['countryId'].value) {
      this._portService.getDestinationPortsByCountry(this.step1FormGroup.controls['countryId'].value).subscribe({
        next: (v) => {
          this.destinationPorts = v
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.Port.DestinationPortManyRetrieveError') })
        },
        complete: () => console.info('Destination Ports query successfully')
      })
    }
  }

  loadVarieties() {
    this.varieties = []
    this._varietyService.getAllBySpecies(this.step1FormGroup.controls['speciesId'].value).subscribe({
      next: (v) => {
        this.varieties = v
        this.loading = false
      },
      error: (e) => {
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Variety.ManyRetrieveError') })
      },
      complete: () => console.info('Variety query successfully')
    })
  }

  loadSpeciesParams() {
    this.selectedSpecies = this.species.find(x => x.id === this.step1FormGroup.controls['speciesId'].value)
    this.loadVarieties()
  }

  onChangeVariety() {
    this.selectedVariety = this.varieties.find(x => x.id === this.step1FormGroup.controls['varietyId'].value)
  }

  loadCountries() {
    this.countries = []
    this._countryService.getAll().subscribe({
      next: (v) => {
        this.countries = v
        this.loading = false
        this.countriesOrigin.push({
          id: null,
          name: 'All countries',
          nameEs: 'Todos los países',
          code: 'WORLD'
        })
        this.countries.forEach(x => {
          this.countriesOrigin.push({...x})
        })
        this.selectedCountryFrom = this.countriesOrigin.find(x => x.id == this.step1FormGroup.controls['countryFromId'].value)
        this.selectedCountryTo = this.countriesOrigin.find(x => x.id == this.step1FormGroup.controls['countryId'].value)
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Country.ManyRetrieveError') })
      },
      complete: () => console.info('Species Sizes query successfully')
    })
  }

  loadProductCertificateTypes() {
    this._productCertificateTypeService.getAll().subscribe({
      next: (v) => {
        this.certificateTypes = v
        this.loading = false
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudieron recuperar los tipos de certificados.' })
      },
      complete: () => console.info('ProductCertificateTypes query successfully')
    })
  }

  loadPackagings() {
    this._packagingService.getAll().subscribe({
      next: (v) => {
        this.packagingList = v
        this.loading = false
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Packaging.ManyRetrieveError') })
      },
      complete: () => console.info('Packaging query successfully')
    })
  }

  onSelectImage(event: any, uploader: any) {
    this.uploadedImages = uploader.files
  }

  onRemoveImage(event: any, uploader: any) {
    this.uploadedImages = uploader.files
  }

  onClearImageUploader(event: any, uploader: any) {
    this.uploadedImages = uploader.files
  }

  onSelectPdf(event: any, uploader: any){
    this.uploadPdf = uploader.files
  }

  onRemovePdf(event: any, uploader: any) {
    this.uploadPdf = uploader.files
  }

  onClearPdf(event: any, uploader: any) {
    this.uploadPdf = uploader.files
  }

  save() {
    this.loading = true
    this.submit = true

    if (this.step2FormGroup.valid) {
      let fromYearWeek = this.step2FormGroup.controls['fromYearWeek'].value;
      let toYearWeek = this.step2FormGroup.controls['toYearWeek'].value ? this.step2FormGroup.controls['toYearWeek'].value : this.step2FormGroup.controls['fromYearWeek'].value

      let importRequest: NewImportRequest = {
        importerUsername: this._authService.getUsername(),
        varietyId: this.step1FormGroup.controls['varietyId'].value,
        color: this.formGroup.controls['color'].value,
        packaging: this.step2FormGroup.controls['packaging'].value,
        boxType: this.step2FormGroup.controls['boxType'].value,
        kilosPerBox: this.formGroup.controls['kilosPerBox'].value,
        netKilos: this.step2FormGroup.controls['netKilos'].value,
        caseQuantityPerPallet: this.step1FormGroup.controls['caseQuantityPerPallet'].value,
        countryId: this.step1FormGroup.controls['countryId'].value,
        speciesSize: this.step2FormGroup.controls['speciesSize'].value,
        pictures: this.uploadedImages,
        basePallets: this.step2FormGroup.controls['basePallets'].value,
        typeSale: this.step1FormGroup.controls['incoterm'].value,
        portId: this.step1FormGroup.controls['portId'].value,
        fromWeek: this.step2FormGroup.controls['fromWeek'].value,
        fromYearWeek: fromYearWeek,
        toWeek: this.step2FormGroup.controls['toWeek'].value ? this.step2FormGroup.controls['toWeek'].value : this.step2FormGroup.controls['fromWeek'].value,
        toYearWeek: toYearWeek,
        sizeMillimeters: this.formGroup.controls['sizeMillimeters'].value,
        category: this.step2FormGroup.controls['category'].value,
        pdf: this.uploadPdf[0] ?  this.uploadPdf[0] : null,
        countryFromId: this.step1FormGroup.controls['countryFromId'].value,
        palletQuantity: this.step1FormGroup.controls['palletQuantity'].value,
        pricePerCase: this.step1FormGroup.controls['pricePerCase'].value,
        otherCertificate: this.step2FormGroup.controls['otherCertificate'].value,
        productCertificateType: this.certificateTypesSelected,
        otherPackaging: this.step2FormGroup.controls['otherPackaging'].value,
        packagings: this.packagingSelected
      }

      this._importRequestService.create(importRequest).subscribe({
        next: (v) => {
          // this._messageService.add({ severity: 'success',
          //   summary: this.translate.instant('MessageService.Summary.SuccessAction'),
          //   detail: this.translate.instant('MessageService.ImportRequest.CreateSuccess') });
          this.submit = false
          this.loading = false
          this.step = this.finalStep
        },
        error: (e) => {
          this.loading = false
          console.log(e)
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ImportRequest.CreateError') });
        },
        complete: () => console.info('Import request created successfully')
      })
    }
    else {
      this.loading = false
    }
  }

  showStep1() {
    this.step = this.step1;
  }

  showStep2() {

    if (this.step1FormGroup.valid) {
      // this.submitStep2 = true
      this.step = this.step2


      // this.step = Steps.Step2
    }
    else {
      this._messageService.add({ severity: 'error',
        summary: this.translate.instant('MessageService.Summary.FailedAction'),
        detail: this.translate.instant('MessageService.FormMessages.FormInvalid') });
    }
  }

  showStep3()
  {
    this.submitStep2 = true
    if(this.step2FormGroup.valid)
      {
        let startWeek = this.step2FormGroup.controls['fromWeek'].value
        let startWeekYear = this.step2FormGroup.controls['fromYearWeek'].value
        let endWeek = this.step2FormGroup.controls['toWeek'].value
        let endWeekYear = this.step2FormGroup.controls['toYearWeek'].value

        if(endWeekYear && startWeekYear > endWeekYear) {
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOffer.ClosedSaleYearRangeError')})
          this.loading = false
          return
        }

        if(endWeek && endWeekYear && startWeekYear === endWeekYear && startWeek > endWeek) {
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOffer.ClosedSaleWeekRangeError')})
          this.loading = false
          return
        }

        if(this.certificateTypesSelected.length < 1) {
          this.step2FormGroup.controls['productCertificateType'].markAsTouched();
          this.step2FormGroup.controls['productCertificateType'].setErrors({ 'invalid': true });
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOffer.CertificateTypeLengthError')})
          this.loading = false
          return
        }

        if(this.packagingSelected.length <= 0) {
          this.step2FormGroup.controls['packagings'].markAsTouched();
          this.step2FormGroup.controls['packagings'].setErrors({ 'invalid': true });
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOffer.PackagingLengthError')})
          this.loading = false
          return
        }

        this.step = this.step3;
      
      }else
      {
        this._messageService.add({ severity: 'error',
        summary: this.translate.instant('MessageService.Summary.FailedAction'),
        detail: this.translate.instant('MessageService.FormMessages.FormInvalid') });
      }

  }


  goToImportRequestPage() {
    this._router.navigate([Routes.userImportRequest])
  }

  getSpeciesName(species: Species): string {
    if(this.profile)
      return SpeciesUtils.getSpeciesName(this.profile.lang, species)
    return ''
  }

  getErrorMessage(formGroup: FormGroup, fieldName: string): string {
    return ErrorMessageUtil.getErrorMessage(formGroup, this.translate, fieldName, 20)
  }

  onFocusYear(yearName: string) {
    if (!this.step2FormGroup.controls[yearName].value) {
      this.step2FormGroup.controls[yearName].setValue(this.initalYear);
    }
  }

  get totalPallets(): number {
    let pallets = this.step1FormGroup.controls['palletQuantity'].value
    let startWeek = this.step2FormGroup.controls['fromWeek'].value
    let startWeekYear = this.step2FormGroup.controls['fromYearWeek'].value
    let endWeek = this.step2FormGroup.controls['toWeek'].value
    let endWeekYear = this.step2FormGroup.controls['toYearWeek'].value

    if(pallets) {
      if(startWeekYear && endWeekYear && startWeekYear === endWeekYear && endWeek > startWeek) {
        return ((endWeek - startWeek) + 1) * pallets
      }

      if (startWeekYear && endWeekYear && endWeekYear == startWeekYear + 1) {
        return ((52 - startWeek) + endWeek + 1) * pallets
      }

      return pallets
    }
    return 0
  }

  get totalWeeks(): number {
    let startWeek = this.step2FormGroup.controls['fromWeek'].value
    let startWeekYear = this.step2FormGroup.controls['fromYearWeek'].value
    let endWeek = this.step2FormGroup.controls['toWeek'].value
    let endWeekYear = this.step2FormGroup.controls['toYearWeek'].value

    return ExportOfferUtils.getTotalWeeks(startWeek, startWeekYear, endWeek, endWeekYear)
  }

  get containersPerWeek(): number {
    let pallets = this.step1FormGroup.controls['palletQuantity'].value
    if(pallets && pallets % 20 == 0) {
      return pallets / 20
    }
    return 0
  }

  get totalContainers(): number {
    const pallets = this.totalPallets
    if(pallets && pallets % 20 == 0) {
      return pallets / 20
    }
    return 0
  }

  filterMultiselect(event: any) {
    if (event.includes(5)) { // Other/Otros
      this.step2FormGroup.controls['otherCertificate'].enable()
    }
    else {
      this.step2FormGroup.controls['otherCertificate'].setValue('')
      this.step2FormGroup.controls['otherCertificate'].disable()
    }
  }

  filterPackagingMultiselect(event: any) {
    if (event.includes(6)) { // Other/Otros
      this.step2FormGroup.controls['otherPackaging'].enable()
    }
    else {
      this.step2FormGroup.controls['otherPackaging'].setValue('')
      this.step2FormGroup.controls['otherPackaging'].disable()
    }
  }

  onChangeCountryFrom() {
    this.selectedCountryFrom = this.countriesOrigin.find(x => x.id == this.step1FormGroup.controls['countryFromId'].value)
  }
  onChangeCountryTo() {
    this.selectedCountryTo = this.countriesOrigin.find(x => x.id == this.step1FormGroup.controls['countryId'].value)
  }

  get paymentBLTitle() {
    return ExportOfferUtils.getPaymentBLTitle(this.step1FormGroup.controls['paymentAgainstBL'].value);
  }

  get paymentBLItems() {
    return ExportOfferUtils.getPaymentBLValues(this.step1FormGroup.controls['incoterm'].value);
  }

  get showPaymentBLPercent() {
    return ExportOfferUtils.showPaymentBLPercent(this.step1FormGroup.controls['incoterm'].value)
  }

  get finalPaymentPercent(): number {
    if(this.step1FormGroup.controls['paymentAgainstBL'].value)
      return 100 - this.step1FormGroup.controls['paymentAgainstBL'].value
    return 0
  }

  getCardHeader(): string {

      return this.translate.instant('UserPages.UserImportRequest.NewImportRequest.CardTitle')

  }

  getCardSubheader(): string {
      return this.translate.instant('UserPages.UserImportRequest.NewImportRequest.CardSubtitle')
  }
}
