import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { InvitationCodeGateway } from 'src/app/application/gateway/InvitationCodeGateway';
import { InvitationCode, InvitationCodeCreate, InvitationCodeInactive } from 'src/app/domain/models/InvitationCode';
import { Lang } from 'src/app/domain/models/Language';
import { BasicUser } from 'src/app/domain/models/User';
import { ErrorMessageUtil } from 'src/app/ui/util/error-message-util';

@Component({
  selector: 'app-admin-invitation-codes',
  templateUrl: './admin-invitation-codes.component.html',
  styleUrls: ['./admin-invitation-codes.component.css'],
  providers: [ConfirmationService, MessageService]
})
export class AdminInvitationCodesComponent implements OnInit {

  requestCodes: InvitationCode[] = []
  invitationCodes: InvitationCode[] = []
  email: string = '';
  fullname: string = '';
  loading: boolean = false
  submit: boolean = false
  profile: BasicUser | undefined
  langs: Lang[] = []
  lang: Lang | undefined

  formGroup: FormGroup = this._formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    fullname: ['', Validators.required],
    language: ['', Validators.required]
  })

  constructor(private _router: Router,
    private _formBuilder: FormBuilder,
    private _authService: AuthGateway,
    private _messageService: MessageService,
    private _invitationCodeService: InvitationCodeGateway,
    public translate: TranslateService){
  }

  ngOnInit(): void {
    this.langs = [
      {
        label: 'English',
        lang: 'en',
        icon: 'GB.png'
      },
      {
        label: 'Español',
        lang: 'es',
        icon: 'ES.png'
      }
    ]
    this.lang = this.langs.find(x => x.lang == 'en')

    this.profile = this._authService.getUser()
    this.loadInvitationCodes()
  }

  loadInvitationCodes() {
    this._invitationCodeService.getAll().subscribe({
      next: (v) => {
        this.invitationCodes = v.filter(x => x.code !== "")
        this.requestCodes = v.filter(x => x.isActive && x.isRequest && x.code === "")
      },
      error: (e) => {
        console.log(e)
        this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No fue posible recuperar los códigos de invitación.' });
      },
      complete: () =>  {
        this.loading = false
        console.info('InvitationCode query successfully')
      }
    })
  }

  sendGenerationCode(request: InvitationCodeCreate) {

    this.loading = true
    this._invitationCodeService.create(request).subscribe({
      next: (v) => {
        this.loadInvitationCodes()
        this._messageService.add({ severity: 'success', summary: 'Acción exitosa', detail: 'Se ha generado el código de invitación con éxito.' })
        this.submit = false
        this.loading = false
      },
      error: (e) => {
        console.log(e)
        this.submit = false
        let errorMessage = ''
        switch(this.profile?.lang) {
          case 'es':
            errorMessage = e.error.messageEs
            break
          case 'en':
            errorMessage = e.error.messageEn
            break
        }
        if(e.status === 500){
          this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No fue posible generar el código de invitación.' })
        }
        else {
          this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: errorMessage });
        }
        this.loading = false
      },
      complete: () =>  {
        console.info('InvitationCode generated successfully')
      }
    })

  }

  createCode(requestCode: InvitationCode) {
    if (requestCode.fullname && requestCode.email) {
      let request: InvitationCodeCreate = {
        email: requestCode.email,
        fullname: requestCode.fullname,
        language: requestCode.language ?? "en",
        isRequest: true
      }

      this.sendGenerationCode(request)
    }
  }

  generateCode() {
    if (this.fullname && this.email) {
      this.submit = true
      if(this.formGroup.valid) {
        let request: InvitationCodeCreate = {
          email: this.email,
          fullname: this.fullname,
          language: this.lang?.lang ?? "en",
          isRequest: false
        }

        this.sendGenerationCode(request)
      }
      else {
        this.loading = false
      }
    }
  }

  inactiveCode(id: number) {
    this.loading = true
    let inactiveCode: InvitationCodeInactive = {
      id: id
    }
    this._invitationCodeService.deactivate(inactiveCode).subscribe({
      next: (v) => {
        this.loadInvitationCodes()
        this._messageService.add({ severity: 'success', summary: 'Acción exitosa', detail: 'El código fue eliminado con éxito.' })
        this.loading = false
      },
      error: (e) => {
        this.loading = false
        this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No fue posible eliminar el código.' });
      },
      complete: () =>  {
        this.loading = false
        console.info('InvitationCode deleted successfully')
      }
    })
  }

  getErrorMessage(fieldName: string): string {
    return ErrorMessageUtil.getErrorMessage(this.formGroup, this.translate, fieldName)
  }

  get placeholder(): string {
    return this.translate.instant('SwitchLangComponent.SwitchLangComponentPlaceholder')
  }

  switchLang = () => {
    this.lang = this.langs.find(x => x.lang == this.formGroup.controls['language'].value)
  }

  showLanguage(language: string): string | undefined {
    return this.langs.find(x => x.lang == language)?.label
  }

}
