<app-user-dashboard [userMenuActiveItemIndex]="0" [activeItem]="'corporative'">
  <p-card [style]="{'min-height':'200px'}">
    <ng-template pTemplate="header">
      <div class="flex justify-content-start align-items-start flex-column" style="margin-left: 15px;">
        <h2>{{'UserPages.UserConsignees.PageTitle' | translate}}</h2>
        <p>{{'UserPages.UserConsignees.PageDescription' | translate}}</p>
      </div>
    </ng-template>
    <p-table [loading]="loading"
      [value]="consignees"
      responsiveLayout="stack"
      [breakpoint]="'600px'">
      <ng-template pTemplate="caption">
        <div class="flex align-items-center justify-content-start">
          <p-button label="{{'UserPages.UserConsignees.NewButton' | translate}}" icon="pi pi-plus" class="mr-2" (click)="goToCreateForm()"/>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
          <tr>
            <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserConsignees.NameTableHeader' | translate}}</div></th>
            <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserConsignees.ContactTableHeader' | translate}}</div></th>
            <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserConsignees.ContactEmailTableHeader' | translate}}</div></th>
            <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserConsignees.CityTableHeader' | translate}}</div></th>
            <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserConsignees.CountryTableHeader' | translate}}</div></th>
            <th></th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-consignee>
          <tr [pSelectableRow]="consignee">
            <td><div class="flex justify-content-center align-items-center">{{consignee.companyName}}</div></td>
            <td><div class="flex justify-content-center align-items-center">{{consignee.contactName}}</div></td>
            <td><div class="flex justify-content-center align-items-center">{{consignee.contactEmail}}</div></td>
            <td><div class="flex justify-content-center align-items-center">{{consignee.contactCity}}</div></td>
            <td><div class="flex justify-content-center align-items-center">{{consignee.contactCountry | countryDescription}}</div></td>
            <td>
              <div class="flex justify-content-end align-items-center gap-2">
                <p-button label="{{'UserPages.UserConsignees.EditButton' | translate}}" icon="pi pi-pencil" (click)="goToEditForm(consignee.id)" class="mr-2"/>
                <p-button label="{{'UserPages.UserConsignees.DeleteButton' | translate}}" icon="pi pi-trash" (click)="onDelete(consignee.id)" class="mr-2"/>
              </div>
            </td>
          </tr>
      </ng-template>
    </p-table>
  </p-card>
</app-user-dashboard>

<p-toast></p-toast>
<p-confirmDialog acceptLabel="{{'UserPages.UserConsignees.YesLabel' | translate}}" rejectLabel="{{'UserPages.UserConsignees.NotLabel' | translate}}" rejectButtonStyleClass="p-button-outlined" [style]="{width: '50vw'}"></p-confirmDialog>
