<app-dashboard-layout>
  <app-content-body>
    <div class="grid align-items-center">
      <div class="col-12 md:col-4">
      </div>
      <div class="col-12 md:col-4 flex justify-content-center align-items-center">
          <img src="../../../../assets/img/fruzty-logo-slogan.png" width="250" height="111" alt=""/>
      </div>
      <div class="col-12 md:col-4">
        <div class="flex justify-content-end align-items-center">
          <app-switch-lang></app-switch-lang>
        </div>
      </div>
    </div>
  </app-content-body>
  <app-requests-code [showBackLogin]="true"></app-requests-code>
</app-dashboard-layout>

<p-toast></p-toast>
