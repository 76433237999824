import { Component, Input, OnInit } from '@angular/core';
import { Delivery } from 'src/app/domain/models/Delivery';

@Component({
  selector: 'app-import-order-bill-info',
  templateUrl: './import-order-bill-info.component.html',
  styleUrls: ['./import-order-bill-info.component.css']
})
export class ImportOrderBillInfoComponent implements OnInit {

  @Input()
  delivery: Delivery | undefined


  ngOnInit(): void {
      
  }
}
