import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { CountryGateway } from 'src/app/application/gateway/CountryGateway';
import { FridgeStorageGateway } from 'src/app/application/gateway/FridgeStorageGateway';
import { FrozenFruitExportOfferGateway } from 'src/app/application/gateway/FrozenFruitExportOfferGateway';
import { SpeciesGateway } from 'src/app/application/gateway/SpeciesGateway';
import { VarietyGateway } from 'src/app/application/gateway/VarietyGateway';
import { Country } from 'src/app/domain/models/Country';
import { FridgeStorage } from 'src/app/domain/models/FridgeStorage';
import { FrozenFruitExportOffer } from 'src/app/domain/models/FrozenFruitExportOffer';
import { Species } from 'src/app/domain/models/Species';
import { BasicUser } from 'src/app/domain/models/User';
import { Variety } from 'src/app/domain/models/Variety';
import { Routes } from 'src/app/infrastructure/routes';
import { ErrorMessageUtil } from 'src/app/ui/util/error-message-util';
import { ExportOfferUtils } from 'src/app/ui/util/export-offer-utils';
import { Lang } from 'src/app/ui/util/langs';
import { SpeciesUtils } from 'src/app/ui/util/species-utils';

@Component({
  selector: 'app-frozen-fruit-export-offer-edit',
  templateUrl: './frozen-fruit-export-offer-edit.component.html',
  styleUrls: ['./frozen-fruit-export-offer-edit.component.css'],
  providers: [MessageService]
})
export class FrozenFruitExportOfferEditComponent implements OnInit {

  frozenFruitExportOfferId: number | undefined
  exportOffer: FrozenFruitExportOffer | undefined

  profile: BasicUser | undefined

  loading: boolean = false
  submit: boolean = false

  species: Species[] = []
  selectedSpecies: Species | undefined

  varieties: Variety[] = []
  selectedVariety: Variety | undefined

  incotermsOptions: any[] = []
  productStackingTypeOptions: any[] = []
  certifiedPalletOptions: any[] = []
  marksOptions: any[] = []
  qualityControlOptions: any[] = []

  fridgeStorages: FridgeStorage[] = []
  selectedFridgeStorage: FridgeStorage | undefined

  countries: Country[] = []
  selectedMarketCountry1: Country | undefined
  selectedMarketCountry2: Country | undefined
  selectedMarketCountry3: Country | undefined

  formGroup: FormGroup = this._formBuilder.group({
    speciesId: [null, Validators.required],
    varietyId: [null, Validators.required],
    category: [null, Validators.required],
    format: [null, Validators.required],
    size: [null, Validators.required],
    price: [null, Validators.required],
    incoterms: [null, Validators.required],
    productStackingType: [null, Validators.required],
    containersQuantity: [null],
    palletsQuantity: [null],
    boxesPerPallet: [null],
    boxesQuantity: [null],
    fridgeStorageId: [null, Validators.required],
    harvestDate: [null],
    processDate: [null],
    expirationDate: [null],
    grossKilos: [null],
    netKilos: [null],
    mainPackaging: [null],
    boxType: [null],
    boxSize: [null],
    boxColor: [null],
    bagType: [null],
    bagColor: [null],
    basePallet: [null],
    isCertifiedPallet: [false],
    isQualityControl: [false],
    isMarks: [false],
    qualityControlDate: [null],
    marketCountry1Id: [null],
    marketCountry2Id: [null],
    marketCountry3Id: [null],
  },{
    validators: [this.requiredBoxesQuantityForm(), this.requiredBoxesPerPalletForm()]
  })

  constructor(private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _messageService: MessageService,
    private _formBuilder: FormBuilder,
    private _authService: AuthGateway,
    private _frozenFruitExportOfferService: FrozenFruitExportOfferGateway,
    public translate: TranslateService,
    private _varietyService: VarietyGateway,
    private _speciesService: SpeciesGateway,
    private _fridgeStorageService: FridgeStorageGateway,
    private _countryService: CountryGateway,) {
    this._activatedRoute.params.subscribe(params => {
      this.frozenFruitExportOfferId = params['frozenFruitExportOfferId']
    })
  }

  ngOnInit(): void {
    this.profile = this._authService.getUser()

    this.loadFrozenFruitExportOffer()
  }

  loadFrozenFruitExportOffer() {
    if(this.frozenFruitExportOfferId) {
      this.loading = true
      this._frozenFruitExportOfferService.getById(this.frozenFruitExportOfferId).subscribe({
        next: (v) => {
          this.exportOffer = v
          this.loadForm()
          this.loading = false
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOffer.SingleRetrieveError') })
        },
        complete: () => console.info('Frozen fruit export offer query successfully')
      })
    }
  }

  loadForm() {
    if(this.exportOffer) {
      this.formGroup.controls['speciesId'].setValue(this.exportOffer.variety?.speciesId)
      this.formGroup.controls['category'].setValue(this.exportOffer.category)
      this.formGroup.controls['format'].setValue(this.exportOffer.format)
      this.formGroup.controls['size'].setValue(this.exportOffer.size)
      this.formGroup.controls['price'].setValue(this.exportOffer.price)
      this.formGroup.controls['incoterms'].setValue(this.exportOffer.incoterms)
      this.formGroup.controls['productStackingType'].setValue(this.exportOffer.productStackingType)
      this.formGroup.controls['containersQuantity'].setValue(this.exportOffer.containersQuantity)
      this.formGroup.controls['palletsQuantity'].setValue(this.exportOffer.palletsQuantity)
      this.formGroup.controls['boxesPerPallet'].setValue(this.exportOffer.boxesPerPallet)
      this.formGroup.controls['boxesQuantity'].setValue(this.exportOffer.boxesQuantity)
      this.formGroup.controls['harvestDate'].setValue(this.exportOffer.harvestDate ? new Date(this.exportOffer.harvestDate) : null)
      this.formGroup.controls['processDate'].setValue(this.exportOffer.processDate ? new Date(this.exportOffer.processDate) : null)
      this.formGroup.controls['expirationDate'].setValue(this.exportOffer.expirationDate ? new Date(this.exportOffer.expirationDate) : null)
      this.formGroup.controls['grossKilos'].setValue(this.exportOffer.grossKilos)
      this.formGroup.controls['netKilos'].setValue(this.exportOffer.netKilos)
      this.formGroup.controls['mainPackaging'].setValue(this.exportOffer.mainPackaging)
      this.formGroup.controls['boxType'].setValue(this.exportOffer.boxType)
      this.formGroup.controls['boxSize'].setValue(this.exportOffer.boxSize)
      this.formGroup.controls['boxColor'].setValue(this.exportOffer.boxColor)
      this.formGroup.controls['bagType'].setValue(this.exportOffer.bagType)
      this.formGroup.controls['bagColor'].setValue(this.exportOffer.bagColor)
      this.formGroup.controls['basePallet'].setValue(this.exportOffer.basePallet)
      this.formGroup.controls['isCertifiedPallet'].setValue(this.exportOffer.isCertifiedPallet)
      this.formGroup.controls['isQualityControl'].setValue(this.exportOffer.isQualityControl)
      this.formGroup.controls['isMarks'].setValue(this.exportOffer.isMarks)
      this.formGroup.controls['qualityControlDate'].setValue(this.exportOffer.qualityControlDate ? new Date(this.exportOffer.qualityControlDate) : null)

      this.loadSpecies()
      this.loadVarieties()
      this.loadFridgeStorages()
      this.loadCountries()
      this.incotermsOptions = ExportOfferUtils.getIncotermsOptions()
      if(this.profile) {
        this.productStackingTypeOptions = ExportOfferUtils.getProductStackingType(this.profile.lang)
        this.certifiedPalletOptions = Lang.getBooleanYesNoOptions(this.profile.lang)
        this.marksOptions = Lang.getBooleanYesNoOptions(this.profile.lang)
        this.qualityControlOptions = Lang.getBooleanYesNoOptions(this.profile.lang)
      }
    }
  }

  getErrorMessage(formGroup: FormGroup, fieldName: string): string {
    return ErrorMessageUtil.getErrorMessage(formGroup, this.translate, fieldName)
  }

  getSpeciesName(species: Species): string {
    if(this.profile)
      return SpeciesUtils.getSpeciesName(this.profile.lang, species)
    return ''
  }

  loadSpeciesParams() {
    this.selectedSpecies = this.species.find(x => x.id === this.formGroup.controls['speciesId'].value)
    this.loadVarieties()
  }

  onChangeVariety() {
    this.selectedVariety = this.varieties.find(x => x.id === this.formGroup.controls['varietyId'].value)
  }

  loadSpecies() {
    this.loading = true
    this._speciesService.getAllByProductType(2).subscribe({
      next: (v) => {
        this.species = v
        this.loading = false

        if(this.exportOffer) {
          this.formGroup.controls['speciesId'].setValue(this.exportOffer.variety?.speciesId)
          this.selectedSpecies = this.species.find(x => x.id === this.exportOffer?.variety?.speciesId)
        }

      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Species.ManyRetrieveError') })
      },
      complete: () => console.info('Species query successfully')
    })
  }

  loadVarieties() {
    this.varieties = []
    this.loading = true
    this._varietyService.getAllBySpecies(this.formGroup.controls['speciesId'].value).subscribe({
      next: (v) => {
        this.varieties = v

        if(this.exportOffer) {
          this.formGroup.controls['varietyId'].setValue(this.exportOffer.varietyId)
          this.selectedVariety = this.varieties.find(x => x.id === this.exportOffer?.varietyId)
        }

        this.loading = false
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Variety.ManyRetrieveError') })
      },
      complete: () => console.info('Variety query successfully')
    })
  }

  loadFridgeStorages() {
    this.fridgeStorages = []
    this._fridgeStorageService.getAllByStorageType(2).subscribe({
      next: (v) => {
        this.fridgeStorages = v
        this.loading = false

        if(this.exportOffer) {
          this.formGroup.controls['fridgeStorageId'].setValue(this.exportOffer.fridgeStorageId)
          this.selectedFridgeStorage = this.fridgeStorages.find(x => x.id === this.exportOffer?.fridgeStorageId)
        }
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.FridgeStorage.ManyRetrieveError') })
      },
      complete: () => console.info('Frigde Storage query successfully')
    })
  }

  loadCountries() {
    this.countries = []
    this._countryService.getAll().subscribe({
      next: (v) => {
        this.countries = v
        this.loading = false

        if(this.exportOffer) {
          this.formGroup.controls['marketCountry1Id'].setValue(this.exportOffer.marketCountry1Id)
          this.formGroup.controls['marketCountry2Id'].setValue(this.exportOffer.marketCountry2Id)
          this.formGroup.controls['marketCountry3Id'].setValue(this.exportOffer.marketCountry3Id)

          this.selectedMarketCountry1 = this.countries.find(x => x.id === this.exportOffer?.marketCountry1Id)
          this.selectedMarketCountry2 = this.countries.find(x => x.id === this.exportOffer?.marketCountry2Id)
          this.selectedMarketCountry3 = this.countries.find(x => x.id === this.exportOffer?.marketCountry3Id)
        }
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Country.ManyRetrieveError') })
      },
      complete: () => console.info('Country query successfully')
    })
  }

  onChangeFridgeStorage() {
    this.selectedFridgeStorage = this.fridgeStorages.find(x => x.id === this.formGroup.controls['fridgeStorageId'].value)
  }

  onChangeMarketCountry1() {
    this.selectedMarketCountry1 = this.countries.find(x => x.id === this.formGroup.controls['marketCountry1Id'].value)
  }

  onChangeMarketCountry2() {
    this.selectedMarketCountry2 = this.countries.find(x => x.id === this.formGroup.controls['marketCountry2Id'].value)
  }

  onChangeMarketCountry3() {
    this.selectedMarketCountry3 = this.countries.find(x => x.id === this.formGroup.controls['marketCountry3Id'].value)
  }

  get isRequiredBoxesQuantity(): boolean {
    return this.formGroup.getError('requiredBoxesQuantity')
  }

  requiredBoxesQuantityForm(): ValidatorFn {
    return (control:AbstractControl): ValidationErrors | null => {
      const stackingType = control.get('productStackingType')?.value
      const boxesQuantity = control.get('boxesQuantity')?.value
      return stackingType && stackingType === 1 && !boxesQuantity ? {requiredBoxesQuantity : true} : null
    }
  }

  getBoxesQuantityRequiredErrorMessage(): string {
    return 'This field is required.'
  }

  get isRequiredPalletQuantity(): boolean {
    return this.formGroup.getError('requiredPalletQuantity')
  }

  requiredPalletQuantityForm(): ValidatorFn {
    return (control:AbstractControl): ValidationErrors | null => {
      const stackingType = control.get('productStackingType')?.value
      const palletQuantity = control.get('palletsQuantity')?.value
      return stackingType && stackingType === 2 && !palletQuantity ? {requiredPalletQuantity : true} : null
    }
  }

  getPalletQuantityRequiredErrorMessage(): string {
    return 'This field is required.'
  }

  get isRequiredBoxesPerPallet(): boolean {
    return this.formGroup.getError('requiredBoxesPerPallet')
  }

  requiredBoxesPerPalletForm(): ValidatorFn {
    return (control:AbstractControl): ValidationErrors | null => {
      const stackingType = control.get('productStackingType')?.value
      const boxesPerPallet = control.get('boxesPerPallet')?.value
      return stackingType && stackingType === 2 && !boxesPerPallet ? {requiredBoxesPerPallet : true} : null
    }
  }

  getBoxesPerPalletRequiredErrorMessage(): string {
    return 'This field is required.'
  }

  requiredBoxesPerPallet(): ValidatorFn {
    return (control:AbstractControl) : ValidationErrors | null => {
      if(this.formGroup) {
        const stackingType = this.formGroup.controls['productStackingType'].value
        const boxesPerPallet = control.value
        return stackingType && stackingType === 2 && !boxesPerPallet ? {requiredBoxesPerPallet : true} : null
      }
      return null
    }
  }

  getBoxesPerPalletErrorMessage(): string {
    if (this.formGroup.controls['boxesPerPallet'].hasError('requiredBoxesPerPallet')) {
      return 'This field is required.'
    }
    return ''
  }

  save() {
    if(this.exportOffer) {
      this.submit = true
      if(this.formGroup.valid) {
        this.exportOffer.varietyId = this.formGroup.controls['varietyId'].value
        this.exportOffer.category = this.formGroup.controls['category'].value
        this.exportOffer.format = this.formGroup.controls['format'].value
        this.exportOffer.size = this.formGroup.controls['size'].value
        this.exportOffer.price = this.formGroup.controls['price'].value
        this.exportOffer.incoterms = this.formGroup.controls['incoterms'].value
        this.exportOffer.productStackingType = this.formGroup.controls['productStackingType'].value
        this.exportOffer.containersQuantity = this.formGroup.controls['containersQuantity'].value
        this.exportOffer.palletsQuantity = this.formGroup.controls['palletsQuantity'].value
        this.exportOffer.boxesPerPallet = this.formGroup.controls['boxesPerPallet'].value
        this.exportOffer.boxesQuantity = this.formGroup.controls['boxesQuantity'].value
        this.exportOffer.fridgeStorageId = this.formGroup.controls['fridgeStorageId'].value
        this.exportOffer.harvestDate = this.formGroup.controls['harvestDate'].value
        this.exportOffer.processDate = this.formGroup.controls['processDate'].value
        this.exportOffer.expirationDate = this.formGroup.controls['expirationDate'].value
        this.exportOffer.grossKilos = this.formGroup.controls['grossKilos'].value
        this.exportOffer.netKilos = this.formGroup.controls['netKilos'].value
        this.exportOffer.mainPackaging = this.formGroup.controls['mainPackaging'].value
        this.exportOffer.boxType = this.formGroup.controls['boxType'].value
        this.exportOffer.boxSize = this.formGroup.controls['boxSize'].value
        this.exportOffer.boxColor = this.formGroup.controls['boxColor'].value
        this.exportOffer.bagType = this.formGroup.controls['bagType'].value
        this.exportOffer.bagColor = this.formGroup.controls['bagColor'].value
        this.exportOffer.basePallet = this.formGroup.controls['basePallet'].value
        this.exportOffer.isCertifiedPallet = this.formGroup.controls['isCertifiedPallet'].value
        this.exportOffer.isQualityControl = this.formGroup.controls['isQualityControl'].value
        this.exportOffer.isMarks = this.formGroup.controls['isMarks'].value
        this.exportOffer.qualityControlDate = this.formGroup.controls['qualityControlDate'].value
        this.exportOffer.marketCountry1Id = this.formGroup.controls['marketCountry1Id'].value
        this.exportOffer.marketCountry2Id = this.formGroup.controls['marketCountry2Id'].value
        this.exportOffer.marketCountry3Id = this.formGroup.controls['marketCountry3Id'].value

        this.loading = true
        this._frozenFruitExportOfferService.update(this.exportOffer).subscribe({
          next: (v) => {
            this.submit = false
            this.loading = false
            this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: this.translate.instant('MessageService.ExportOffer.EditSuccess') })
          },
          error: (e) => {
            console.log(e)
            this.submit = false
            this.loading = false
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.ExportOffer.EditError') })
          },
          complete: () => console.info('Frozen fruit export offer updated successfully')
        })
      }
      else {
        this.loading = false
      }
    }
  }

  goToFrozenFruitExportOffersPage() {
    this._router.navigate(['/' + Routes.userFrozenFruitExportOffersActivePage])
  }
}
