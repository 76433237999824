import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppNotification } from "src/app/domain/models/AppNotification";

@Injectable({providedIn: 'root'})
export abstract class NotificationGateway {
  abstract getAllByProfile(): Observable<AppNotification[]>
  abstract getAllPendingByProfile(): Observable<AppNotification[]>
  abstract getAllReadedByProfile(): Observable<AppNotification[]>
  abstract setReaded(notificationId: number): Observable<any>
  abstract getPendingCount(): Observable<number>
}
