import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signup-page-import-add-info',
  templateUrl: './signup-page-import-add-info.component.html',
  styleUrls: ['./signup-page-import-add-info.component.css']
})
export class SignupPageImportAddInfoComponent {

  countries: any[] = []
  submit: boolean = false

  consigneeFormGroup: FormGroup = this._formBuilder.group({
    name: ['', Validators.required],
    vat: ['', Validators.required],
    country: ['', Validators.required],
    city: ['', Validators.required],
    contactName: ['', Validators.required],
    contactAddress: ['', Validators.required],
    contactPhone: [''],
    contactEmail: ['', [Validators.required, Validators.email]],
  })

  notifyFormGroup: FormGroup = this._formBuilder.group({
    name: ['', Validators.required],
    vat: ['', Validators.required],
    country: ['', Validators.required],
    city: ['', Validators.required],
    contactName: ['', Validators.required],
    contactAddress: ['', Validators.required],
    contactPhone: [''],
    contactEmail: ['', [Validators.required, Validators.email]],
  })

  constructor(private _router: Router, private _formBuilder: FormBuilder) {
    this.countries = [
      {name: 'Chile', code: 'CL'},
      {name: 'Argentina', code: 'AR'},
      {name: 'Perú', code: 'PE'},
      {name: 'Ecuador', code: 'EC'},
      {name: 'Australia', code: 'AU'},
      {name: 'Brazil', code: 'BR'},
      {name: 'China', code: 'CN'},
      {name: 'Egypt', code: 'EG'},
      {name: 'France', code: 'FR'},
      {name: 'Germany', code: 'DE'},
      {name: 'India', code: 'IN'},
      {name: 'Japan', code: 'JP'},
      {name: 'Spain', code: 'ES'},
      {name: 'United States', code: 'US'}]
  }

  getErrorMessage(fieldName: string): string {
    if (this.consigneeFormGroup.controls[fieldName].hasError('required')) {
      return 'Este campo es requerido.';
    }
    if (this.consigneeFormGroup.controls[fieldName].hasError('email')) {
      console.log("error en correo")
      return 'Formato de correo electrónico incorrecto.';
    }
    return '';
  }
}
