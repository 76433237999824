import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { SpeciesService } from 'src/app/application/service/SpeciesService';
import { SpeciesCreateRequest } from 'src/app/domain/models/Species';

@Component({
  selector: 'app-admin-new-species',
  templateUrl: './admin-new-species.component.html',
  styleUrls: ['./admin-new-species.component.css'],
  providers: [MessageService]
})
export class AdminNewSpeciesComponent implements OnInit {

  formGroup: FormGroup = this._formBuilder.group({
    name: ['', Validators.required],
  })

  loading: boolean = false;
  uploadedFiles: any[] = []
  submit: boolean = false;
  speciesId: number | undefined

  constructor(private _formBuilder: FormBuilder,
    private _router: Router,
    private _messageService: MessageService,
    private _speciesService: SpeciesService
  ){

  }



  ngOnInit(): void {

  }

  save() {
    this.submit = true;
    this.loading = true;
    if(this.formGroup.valid){
    if(!this.uploadedFiles[0]){
      this.loading = false;
      this._messageService.add({ severity: 'error', summary: 'Accion fallida', detail: 'Por favor cargue una imagen' })
    }else{
      const speciesRequest: SpeciesCreateRequest = {
        name: this.formGroup.controls["name"].value,
        img: this.uploadedFiles[0]
      }

      this._speciesService.create(speciesRequest).subscribe({
        next: (v) => {
          this.loading = false;
          this._messageService.add({ severity: 'error', summary: 'Accion exitosa', detail: 'Especie creada correctamente' })
        },
        error: (v) => {
          this.loading = false;
          this._messageService.add({ severity: 'error', summary: 'Accion fallida', detail: 'No se pudo realizar la creacion de la especie' }
          )
        }
      })
    }
  }
  }

  onSelect(event: any) {
    this.uploadedFiles = []
    for(let file of event.files) {
        this.uploadedFiles.push(file);
    }
  }

  // loadForm(){
  //   if(this.speciesId){
  //     this.
  //   }
  // }

  getErrorMessage(): string {
      return 'Este campo es requerido.';
  }
}
