<p-galleria
  [value]="getPictures()"
  [responsiveOptions]="responsiveOptions"
  [numVisible]="5"
  [thumbnailsPosition]="position"
  >
  <ng-template pTemplate="item" let-item>
    <div
      style="
      display:flex;
      justify-content: center;
      align-items: center;
      height:430px;
      padding:0 1em;
      "
    >
    <img [src]="completeUrl(item.url)"
    style="
      object-fit: cover;
      width: 100%;
      height: 100%;
    " />
  </div>

  </ng-template>
  <ng-template pTemplate="thumbnail" let-item>
    <div
      class="grid grid-nogutter justify-content-center"
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 80px;
        background: #eaeaea;
        border-radius: 4px;
        overflow: hidden;
      "
      >
      <img [src]="completeUrl(item.url)"
        style="width: auto;"
        style="height: 100px;"

      />
    </div>
  </ng-template>
</p-galleria>

