import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { FrozenFruitExportOffer } from 'src/app/domain/models/FrozenFruitExportOffer';
import { BasicUser } from 'src/app/domain/models/User';
import { ExportOfferUtils } from 'src/app/ui/util/export-offer-utils';
import { Lang } from 'src/app/ui/util/langs';

@Component({
  selector: 'app-frozen-fruit-export-offer-detail-description',
  templateUrl: './frozen-fruit-export-offer-detail-description.component.html',
  styleUrls: ['./frozen-fruit-export-offer-detail-description.component.css']
})
export class FrozenFruitExportOfferDetailDescriptionComponent implements OnInit {

  @Input()
  offer: FrozenFruitExportOffer | undefined

  user: BasicUser | undefined

  speciesDescription: any

  constructor(private _authService: AuthGateway,
    public translate: TranslateService) {
    this.user = this._authService.getUser()
  }

  ngOnInit(): void {
    this.getSpeciesDescription()
  }

  getSpeciesDescription() {
    if(this.user && this.offer) {

      switch(this.user.lang) {
        case 'en':
          console.log(this.user.lang)
          this.speciesDescription = {
            description: this.offer.variety?.species?.descriptionEn,
            technicalCharacteristics: this.offer.variety?.species?.technicalCharacteristicsEn,
            qualityControlCriteria: this.offer?.variety?.species?.qualityControlCriteriaEn,
            harvestAreaChile: this.offer?.variety?.species?.harvestAreaChileEn
          }
          console.log(this.speciesDescription)
          break
        case 'es':
          this.speciesDescription = {
            description: this.offer.variety?.species?.descriptionEs,
            technicalCharacteristics: this.offer.variety?.species?.technicalCharacteristicsEs,
            qualityControlCriteria: this.offer?.variety?.species?.qualityControlCriteriaEs,
            harvestAreaChile: this.offer?.variety?.species?.harvestAreaChileEs
          }
          break
      }
    }
  }

  getBoxType(boxType?: string): string {
    return this.user ? ExportOfferUtils.getBoxTypeName(this.user.lang, boxType) : ''
  }

  getColor(color?: string) {
    return this.user ? ExportOfferUtils.getColorName(this.user.lang, color) : ''
  }

  getBasePallets(basePallets?: string) {
    return this.user ? ExportOfferUtils.getBasePalletsName(this.user.lang, basePallets) : ''
  }

  getBooleanLabel(value?: boolean) {
    return this.user ? Lang.getBooleanLabel(this.user.lang, value) : ''
  }

  getAvailableLabel() {
    return this.translate.instant('ExportOffer.ExportOfferDetail.NoAvailable')
  }
}
