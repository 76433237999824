import { Component } from '@angular/core';

@Component({
  selector: 'app-product-certificate-crud',
  templateUrl: './product-certificate-crud.component.html',
  styleUrls: ['./product-certificate-crud.component.css']
})
export class ProductCertificateCrudComponent {

}
