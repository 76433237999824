import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { ImportOrderGateway } from 'src/app/application/gateway/ImportOrderGateway';
import { ExportOffer } from 'src/app/domain/models/ExportOffer';
import { GenericOrderDetail, ImportOrderContainerItem, ImportOrderDetailContainerItem } from 'src/app/domain/models/ImportOrder';
import { Routes } from 'src/app/infrastructure/routes';
import { OrderUtils } from '../../util/order-utils';
import { BasicUser } from 'src/app/domain/models/User';
import { TranslateService } from '@ngx-translate/core';
import { QualityControlCompany } from 'src/app/domain/models/QCCompany';
import { ProductCertificateType } from 'src/app/domain/models/ProductCertificateType';
import { Packaging } from 'src/app/domain/models/Packaging';
import { ExportOfferGateway } from 'src/app/application/gateway/ExportOfferGateway';

@Component({
  selector: 'app-purchase-orders-page',
  templateUrl: './purchase-orders-page.component.html',
  styleUrls: ['./purchase-orders-page.component.css'],
  providers: [ConfirmationService, MessageService]
})
export class PurchaseOrdersPageComponent implements OnInit {
  orders: ImportOrderContainerItem[] = []
  loading: boolean = false
  waitConfirmation: boolean = false
  visibleMoveToAnotherContainer: boolean = false

  breadcrumbItems: MenuItem[] = []
  home: MenuItem

  selectedOrder: ImportOrderContainerItem | undefined
  selectedDetail: ImportOrderDetailContainerItem | undefined
  selectedExportOffer: ExportOffer | undefined
  selectedProductCertificateTypes: ProductCertificateType[] = []
  selectedPackagingTypes: Packaging[] = []
  selectedQCCompany: QualityControlCompany | undefined
  qcCompanies: QualityControlCompany[] = []
  visibleOfferDetailDialog: boolean = false
  visibleQCCompany: boolean = false

  itemQCSelected: any
  visibleDisclaimerQCDialog: boolean = false

  noQcCompanyName: string = '';

  noQCCompany: QualityControlCompany = {
    id: 0,
    profileId: 0,
    isConsolidateContainer: false,
    basePricePerContainer: 0,
    hasQCSystem: false,
    pricePerPallet: 0, 
    pricePerContainer: 0,
    basePricePerPallet: 0,
    profile: {
      id: 0,
      companyName: 'NO QC',
      vat: 0,
      type: 0,
      contactName: '',
      contactEmail: '',
      contactPhone: '',
      contactAddress: '',
      contactCity: '',
      contactCountryId: 0,
      lang:'',
      hasTwoAuthetication: false,
      updaterUsername: ''

    }

  }

  oceanFreight: number = 0

  profile: BasicUser | undefined

  constructor(private _router: Router,
    private _authService: AuthGateway,
    private _messageService: MessageService,
    private _confirmationService: ConfirmationService,
    private _importOrderService: ImportOrderGateway,
    private _exportOfferService: ExportOfferGateway,
    public translate: TranslateService) {
      this.profile = this._authService.getUser()

      let breadcrumbItemLabel1 = 'Container'
      let breadcrumbItemLabel2 = ''
      let breadcrumbHomeItemLabel = ''

      if(this.profile) {
        switch(this.profile.lang) {
          case 'en':
            breadcrumbItemLabel1 = 'Container'
            breadcrumbItemLabel2 = 'Confirm order'
            breadcrumbHomeItemLabel = 'My orders'
            break
          case 'es':
            breadcrumbItemLabel1 = 'Container'
            breadcrumbItemLabel2 = 'Confirmar orden'
            breadcrumbHomeItemLabel = 'Mis órdenes'
            break
        }
      }

      this.breadcrumbItems = [{ label: breadcrumbItemLabel1, disabled: true }, { label: breadcrumbItemLabel2, disabled: true }]
      this.home = { label: breadcrumbHomeItemLabel, routerLink: ['/' + Routes.userImportOrdersCurrent] }
  }

  ngOnInit(): void {
    this.loadOrders()
  }

  loadOrders() {
    this.loading = true
    this._importOrderService.getAllForShoppingCart().subscribe({
      next: (v) => {
        this.orders = v
        this.loading = false
        this.configureOrders()
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.ImportOrder.ManyRetrieveError') })
      },
      complete: () => console.info('Import Orders query successfully')
    })
  }

  configureOrders(): void {
    if(this.orders) {
      this.orders.forEach(o => {
        o.expanded = false
      })
    }
  }

  removeDetail(detailId: any, orderId: any) {
    this._confirmationService.confirm({
      message: this.translate.instant('ContainerOrderPage.RemoveDetailConfirmDialogMessage'),
      header: this.translate.instant('ContainerOrderPage.RemoveDetailConfirmDialogHeader'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loading = true
        this._importOrderService.removeDetail(orderId, detailId).subscribe({
          next: (v) => {
            this._messageService.add({ severity: 'success',
              summary: this.translate.instant('MessageService.Summary.SuccessAction'),
              detail: this.translate.instant('ContainerOrderPage.RemoveDetailSuccess') })
            this.loadOrders()
            this.loading = false
          },
          error: (e) => {
            console.log(e)
            this.loading = false
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('ContainerOrderPage.RemoveDetailError') })
          },
          complete: () => console.info('Detail removed successfully')
        })
      },
      reject: (type: any) => {}
    })
  }

  confirmOrder(order: ImportOrderContainerItem) {
    this.waitConfirmation = true
    const qualityControlCompanyId = order.qualityControlCompany ? order.qualityControlCompany.id == 0 ? '' : order.qualityControlCompany.id   : ''
    this._importOrderService.confirmOrder(order.id, qualityControlCompanyId).subscribe({
      next: (v) => {
        this._messageService.add({ severity: 'success',
          summary: this.translate.instant('MessageService.Summary.SuccessAction'),
          detail: this.translate.instant('ContainerOrderPage.OrderConfirmationSuccess') })
        this.loadOrders()
        this.waitConfirmation = false
      },
      error: (e) => {
        console.log(e)
        this.waitConfirmation = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('ContainerOrderPage.OrderConfirmationError') })
      },
      complete: () => console.info('Import Order confirmed successfully')
    })
  }

  showMoveToAnotherContainerDialog(selectedDetail: ImportOrderDetailContainerItem) {
    this.selectedDetail = selectedDetail
    this.visibleMoveToAnotherContainer = true
  }

  moveDetail() {
    if(this.selectedOrder && this.selectedDetail) {
      this._importOrderService.moveDetail(this.selectedOrder.id, this.selectedDetail.id).subscribe({
        next: (v) => {
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: this.translate.instant('ContainerOrderPage.DetailMovedSuccess') })
          this.loadOrders()
          this.loading = false
          this.visibleMoveToAnotherContainer = false
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('ContainerOrderPage.DetailMovedError') })
        },
        complete: () => console.info('Import Order moved successfully')
      })
    }
  }

  setVisibleOrderDetail(order: ImportOrderContainerItem) {
    if (order) {
      order.expanded = !order.expanded
    }
  }

  goToContainerOrderPage() {
    this._router.navigate(['/' + Routes.containerOrderPage])
  }

  getOrderTitle(order: ImportOrderContainerItem): string {
    return OrderUtils.getOrderTitle(order.sequence)
  }

  getOrderTotalQuantity(order: ImportOrderContainerItem): number {
    let total = 0
    order.details.forEach(x => total += x.palletsQuantity)
    return total
  }

  getProductTotalPrice(order: ImportOrderContainerItem): number {
    let total = 0
    order.details.forEach(d => {
      total += this.getDetailTotalPrice(d)
    })
    return total
  }

  getDetailTotalPrice(detail: ImportOrderDetailContainerItem): number {
    return detail.boxesPerPallet * detail.palletsQuantity * detail.price
  }

  goToMarketplace() {
    this._router.navigate([Routes.marketplacePage])
  }

  showOffer(offer: GenericOrderDetail) {

    this._exportOfferService.getById(offer.exportOfferId).subscribe({
      next: (v) => {
        this.selectedExportOffer = v
        if(this.selectedExportOffer.productCertificateTypes)
          this.selectedProductCertificateTypes = this.selectedExportOffer.productCertificateTypes

        if(this.selectedExportOffer.packagingTypes)
          this.selectedPackagingTypes = this.selectedExportOffer.packagingTypes

        this.loading = false
        this.visibleOfferDetailDialog = true
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.ExportOffer.ManyRetrieveError') })
      },
      complete: () => console.info('Export Offers query successfully')
    })
  }

  showSelectQCCompany(order: ImportOrderContainerItem) {
    this.qcCompanies = []
    this.selectedOrder = order
    //let orderCompanies = order.qcCompanies;
    order.qcCompanies.forEach(x => {
      this.qcCompanies.push(x)
    })
    //this.qcCompanies = order.qcCompanies;
    this.qcCompanies.push(this.noQCCompany);
    this.visibleQCCompany = true
  }

  getQCPrice(order: ImportOrderContainerItem): number {
      if(order.details.length > 1 && order.qualityControlCompany)
      {
        let qcPrice = 0
        order.details.forEach(x => {
          if(order.qualityControlCompany)
            qcPrice += x.palletsQuantity * order.qualityControlCompany.basePricePerPallet
        })
        return qcPrice
      }

      if(order.details.length <= 1 && order.qualityControlCompany)
        return order.qualityControlCompany.basePricePerContainer

      return 0
  }

  getOrderTotalPrice(order: ImportOrderContainerItem): number {
    return order.totalPrice + order.oceanFreight + this.getQCPrice(order)
  }

  onQCCompanyTableRowSelect(event: any) {
    const company = event.data
    this.itemQCSelected = company
    
    //Si selecciona NOQC
    if (company.id == 0 && company.profileId == 0) {
      this.visibleQCCompany = false
      this.visibleDisclaimerQCDialog = true
    }
    else {
      if(this.selectedOrder)
        this.selectedOrder.qualityControlCompany = company
      this.visibleQCCompany = false
    }
  }

  getOrderTotalCases(order: ImportOrderContainerItem): number {
    let total = 0
    order.details.forEach(x => total += (x.palletsQuantity * x.boxesPerPallet))
    return total
  }

  getOrderDetailTotalCases(detail: ImportOrderDetailContainerItem): number{
    let total = 0
    total += detail.boxesPerPallet * detail.palletsQuantity;

    return total;
  }

  withoutNOQC() {
    this.visibleDisclaimerQCDialog = false
    if(this.selectedOrder)
      this.selectedOrder.qualityControlCompany = this.itemQCSelected
  }
  
  withNOQC() {
    this.visibleDisclaimerQCDialog = false
    this.visibleQCCompany = true
    this.itemQCSelected = null
    if (this.selectedOrder) this.selectedOrder.qualityControlCompany = undefined
  }
}
