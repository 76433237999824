import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ChangePassword, ResetPassword, TokenResetPassword, User } from "src/app/domain/models/User";

@Injectable({providedIn: 'root'})
export abstract class UserGateway {
    abstract updatePassword(changePassword: ChangePassword): Observable<any>
    abstract resetPasswordRequestToken(request: TokenResetPassword): Observable<any>
    abstract resetPassword(request: ResetPassword): Observable<any>
}
