<app-user-dashboard [userMenuActiveItemIndex]="0" [activeItem]="'corporative'">
  <p-card [style]="{'min-height':'200px'}">
    <ng-template pTemplate="header">
      <div class="flex justify-content-start align-items-start flex-column" style="margin-left: 15px;">
        <h2>{{title}}</h2>
        <p>{{'UserPages.UserNotifies.NotifierForm.RequiredFieldText' | translate}}</p>
      </div>
    </ng-template>
    <form [formGroup]="formGroup">
      <div class="p-fluid formgrid grid" style="padding: 20px;">
        <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
          <label for="name" style="font-weight: bolder;">{{'UserPages.UserNotifies.NotifierForm.NotifyNameFieldLabel' | translate}} *</label>
          <input id="name" [maxlength]="formLimit.nameLimit" formControlName="name" type="text" required pInputText placeholder="{{'UserPages.UserNotifies.NotifierForm.NotifyNameFieldPlaceholder' | translate}} "/>
          <small *ngIf="submit && formGroup.controls['name'].invalid" class="p-error">{{getErrorMessage('name')}}</small>
        </div>
        <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
          <label for="vat" style="font-weight: bolder;">{{'UserPages.UserNotifies.NotifierForm.VatFieldLabel' | translate}} *</label>
          <input id="vat" formControlName="vat"[maxlength]="formLimit.vatLimit" type="text" required pInputText placeholder="{{'UserPages.UserNotifies.NotifierForm.VatFieldPlaceholder' | translate}}"/>
          <small *ngIf="submit && formGroup.controls['vat'].invalid" class="p-error">{{getErrorMessage('vat')}}</small>
        </div>
        <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
          <label for="country" style="font-weight: bolder;">{{'UserPages.UserNotifies.NotifierForm.CountryFieldLabel' | translate}} *</label>
          <p-dropdown id="country"
            formControlName="contactCountryId"
            [options]="countries"
            optionLabel="name"
            optionValue="id"
            [filter]="true"
            filterBy="name"
            [showClear]="true"
            placeholder="{{'UserPages.UserNotifies.NotifierForm.CountryFieldPlaceholder' | translate}}">
          </p-dropdown>
          <small *ngIf="submit && formGroup.controls['contactCountryId'].invalid" class="p-error">{{getErrorMessage('contactCountryId')}}</small>
        </div>
        <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
          <label for="contactName" style="font-weight: bolder;">{{'UserPages.UserNotifies.NotifierForm.ContactNameFieldLabel' | translate}} *</label>
          <input id="contactName" [maxlength]="formLimit.nameLimit" formControlName="contactName" type="text" required pInputText placeholder="{{'UserPages.UserNotifies.NotifierForm.ContactNameFieldPlaceholder' | translate}}"/>
          <small *ngIf="submit && formGroup.controls['contactName'].invalid" class="p-error">{{getErrorMessage('contactName')}}</small>
        </div>
        <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
          <label for="contactAddress" style="font-weight: bolder;">{{'UserPages.UserNotifies.NotifierForm.AddressContactFieldLabel' | translate}}o *</label>
          <input id="contactAddress" [maxlength]="formLimit.addressLimit" formControlName="contactAddress" type="text" required pInputText placeholder="{{'UserPages.UserNotifies.NotifierForm.AddressContactFieldPlaceholder' | translate}}"/>
          <small *ngIf="submit && formGroup.controls['contactAddress'].invalid" class="p-error">{{getErrorMessage('contactAddress')}}</small>
        </div>
        <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
          <label for="city" style="font-weight: bolder;">{{'UserPages.UserNotifies.NotifierForm.CityFieldLabel' | translate}} *</label>
          <input id="city" formControlName="contactCity"[maxlength]="formLimit.cityLimit"  type="text" required pInputText placeholder="{{'UserPages.UserNotifies.NotifierForm.CityFieldPlaceholder' | translate}}"/>
          <small *ngIf="submit && formGroup.controls['contactCity'].invalid" class="p-error">{{getErrorMessage('contactCity')}}</small>
        </div>
        <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
          <label for="contactEmail" style="font-weight: bolder;">{{'UserPages.UserNotifies.NotifierForm.ContactEmailFieldLabel' | translate}} *</label>
          <input id="contactEmail" [maxlength]="formLimit.mailLimit" formControlName="contactEmail" type="text" required pInputText placeholder="{{'UserPages.UserNotifies.NotifierForm.ContactEmailFieldPlaceholder' | translate}}"/>
          <small *ngIf="submit && formGroup.controls['contactEmail'].invalid" class="p-error">{{getErrorMessage('contactEmail')}}</small>
        </div>
        <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
          <label for="contactPhone" style="font-weight: bolder;">{{'UserPages.UserNotifies.NotifierForm.ContactPhoneFieldLabel' | translate}} *</label>
          <input id="contactPhone" [maxlength]="formLimit.phoneLimit" formControlName="contactPhone" type="text" required pInputText placeholder="{{'UserPages.UserNotifies.NotifierForm.ContactPhoneFieldPlaceholder' | translate}}"/>
          <small *ngIf="submit && formGroup.controls['contactPhone'].invalid" class="p-error">{{getErrorMessage('contactPhone')}}</small>
        </div>
        <div class="col-12">
          <div class="grid" style="margin-top: 30px;">
            <div class="col-12 md:col-6 lg:col-6"></div>
            <div class="col-12 md:col-6 lg:col-6">
              <div class="grid p-fluid">
                <div class="col-6">
                  <p-button label="{{'UserPages.UserNotifies.NotifierForm.BackButton' | translate}}" [disabled]="loading" styleClass="p-button-outlined" (click)="cancel()"/>
                </div>
                <div class="col-6">
                  <p-button label="{{'UserPages.UserNotifies.NotifierForm.SaveButton' | translate}}" [loading]="loading" (click)="save()"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </p-card>
</app-user-dashboard>

<p-toast></p-toast>
