import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { ProfileGateway } from 'src/app/application/gateway/ProfileGateway';
import { BasicUser } from 'src/app/domain/models/User';
import { Routes } from 'src/app/infrastructure/routes';

@Component({
  selector: 'app-user-panel',
  templateUrl: './user-panel.component.html',
  styleUrls: ['./user-panel.component.css']
})
export class UserPanelComponent implements OnInit {

  user: BasicUser | undefined

  constructor(private _router: Router,
    private _authService: AuthGateway,
    private _profileService: ProfileGateway) {
  }

  loadBasicUser() {
    this._profileService.getBasicUser().subscribe({
      next: (v) => {
        this.user = v
      },
      error: (e) => {
        console.log(e)
      }
    })
  }

  ngOnInit(): void {
    this.loadBasicUser()
  }

  goToContactInfoPage() {
    this._router.navigate(['/'+Routes.userContactInfo])
  }

  goToCompanyInfoPage() {
    this._router.navigate(['/'+Routes.userCompanyInfo])
  }

  goToConsigneesPage() {
    this._router.navigate(['/'+Routes.userConsignees])
  }

  goToNotifiesPage() {
    this._router.navigate(['/'+Routes.userNotifiers])
  }

  goToUserProducts() {
    this._router.navigate(['/'+Routes.userProducts])
  }

  goToUserFridgestorages() {
    this._router.navigate(['/'+Routes.userFridgestorages])
  }

  goToUserCertificates() {
    this._router.navigate(['/'+Routes.userProductCertificates])
  }

  goToSecurityOptions() {
    this._router.navigate(['/'+Routes.userChangePassword])
  }
}
