import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { ConsigneeGateway } from 'src/app/application/gateway/ConsigneeGateway';
import { Consignee } from 'src/app/domain/models/Consignee';
import { Routes } from 'src/app/infrastructure/routes';

@Component({
  selector: 'app-user-consignees',
  templateUrl: './user-consignees.component.html',
  styleUrls: ['./user-consignees.component.css'],
  providers: [ConfirmationService, MessageService]
})
export class UserConsigneesComponent implements OnInit {

  consignees: Consignee[] = []
  selected: Consignee | null = null
  loading: boolean = true

  constructor(private _router: Router,
    private _messageService: MessageService,
    private _confirmationService: ConfirmationService,
    private _authService: AuthGateway,
    private _consigneeService: ConsigneeGateway,
    public translate: TranslateService) {

  }

  ngOnInit(): void {
    this.getAll()
  }

  getAll() {
    this.loading = true
    this._consigneeService.getAllByUsername(this._authService.getUsername()).subscribe({
      next: (v) => {
        this.consignees = v
        this.loading = false
      },
      error: (e) => {
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Consignee.ManyRetrieveError') });
        console.log(e)
        this.loading = false
      },
      complete: () => console.info('Consignee query successfully')
    })
  }

  goToCreateForm(){
    this._router.navigate([Routes.consigneeForm, 0])
  }

  goToEditForm(id: number) {
    this._router.navigate([Routes.consigneeForm, id])
  }

  onDelete(id: number) {
    this._confirmationService.confirm({
      message: this.translate.instant('MessageService.Consignee.DeleteMessage'),
      header: this.translate.instant('MessageService.Consignee.DeleteHeader'),
      icon: 'pi pi-info-circle',
      accept: () => {
        this._consigneeService.delete(id).subscribe({
          next: (v) => {
            this.selected = null
            this._messageService.add({ severity: 'success',
              summary: this.translate.instant('MessageService.Summary.SuccessAction'),
              detail: this.translate.instant('MessageService.Consignee.DeleteSuccess') })
            this.getAll()
          },
          error: (e) => {
            console.log(e)
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail:  this.translate.instant('MessageService.Consignee.DeleteError')});
          },
          complete: () => console.info('Consignee delete successfully')
        })
      },
      reject: (type: any) => {
          switch (type) {
              case ConfirmEventType.REJECT:
                  //this._messageService.add({ severity: 'error', summary: 'Rejected', detail: 'Se canceló la eliminación' })
                  break;
              case ConfirmEventType.CANCEL:
                  //this._messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Se canceló la eliminación' })
                  break;
          }
      }
    })
  }
}
