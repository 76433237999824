import { Component, Input } from '@angular/core';
import { FrozenFruitExportOffer } from 'src/app/domain/models/FrozenFruitExportOffer';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-frozen-fruit-export-offer-detail-carousel',
  templateUrl: './frozen-fruit-export-offer-detail-carousel.component.html',
  styleUrls: ['./frozen-fruit-export-offer-detail-carousel.component.css']
})
export class FrozenFruitExportOfferDetailCarouselComponent {

  @Input()
  offer: FrozenFruitExportOffer | undefined

  responsiveOptions: any = [
    {
        breakpoint: '1199px',
        numVisible: 1,
        numScroll: 1
    },
    {
        breakpoint: '991px',
        numVisible: 1,
        numScroll: 1
    },
    {
        breakpoint: '767px',
        numVisible: 1,
        numScroll: 1
    }
  ]


  getPicturesUrl(): any {
    let pictures: any[] = []

    if(this.offer) {
      if(this.offer.pictureUrl1)
        pictures.push(environment.apiUrl + this.offer.pictureUrl1)

      if(this.offer.pictureUrl2)
        pictures.push(environment.apiUrl + this.offer.pictureUrl2)

      if(this.offer.pictureUrl3)
        pictures.push(environment.apiUrl + this.offer.pictureUrl3)
    }
    return pictures
  }
}
