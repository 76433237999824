import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { ConsigneeGateway } from 'src/app/application/gateway/ConsigneeGateway';
import { CountryGateway } from 'src/app/application/gateway/CountryGateway';
import { Consignee } from 'src/app/domain/models/Consignee';
import { Country } from 'src/app/domain/models/Country';
import { Routes } from 'src/app/infrastructure/routes';
import { formLimit } from 'src/app/ui/util/form-limit';

@Component({
  selector: 'app-consignee-form',
  templateUrl: './consignee-form.component.html',
  styleUrls: ['./consignee-form.component.css'],
  providers: [MessageService]
})
export class ConsigneeFormComponent implements OnInit {
  title: string = ''
  countries: Country[] = []
  selectedCountry: Country | undefined
  submit = false
  loading = false
  consigneeId = 0
  consignee: Consignee | undefined

  formLimit = formLimit

  formGroup: FormGroup = this._formBuilder.group({
    name: ['', Validators.required],
    vat: ['', Validators.required],
    contactCountry: ['', Validators.required],
    contactCity: ['', Validators.required],
    contactName: ['', Validators.required],
    contactAddress: ['', Validators.required],
    contactPhone: [''],
    contactEmail: ['', [Validators.required, Validators.email]],
  })

  constructor(private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _messageService: MessageService,
    private _authService: AuthGateway,
    private _countryService: CountryGateway,
    private _consigneeService: ConsigneeGateway,
    public translate: TranslateService) {

  }

  ngOnInit(): void {
    this._activatedRoute.params.subscribe(params => {
      this.consigneeId = params['consigneeId']
    })

    this._countryService.getAll().subscribe({
      next: (v) => {
        this.countries = v
      },
      error: (e) => {
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Country.ManyRetrieveError') });
        console.log(e)
      },
      complete: () => console.info('Country query successfully')
    })

    if(this.consigneeId && this.consigneeId > 0) {
      this.title = this.translate.instant('UserPages.UserConsignees.ConsigneeForm.EditTitle')
      this._consigneeService.getById(this.consigneeId).subscribe({
        next: (v) => {
          console.log(v)
          this.consignee = v
          this.loadForm()
        },
        error: (e) => {
          console.log(e)
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.Consignee.SingleRetrieveError') });
        },
        complete: () => console.info('Consignee query successfully')
      })
    }
    else {
      this.title = this.translate.instant('UserPages.UserConsignees.ConsigneeForm.CreateTitle')
      this.consignee = {}
    }
  }

  loadForm() {
    if(this.consignee) {
      this.formGroup.controls["name"].setValue(this.consignee.companyName)
      this.formGroup.controls["vat"].setValue(this.consignee.vat)
      this.formGroup.controls["contactName"].setValue(this.consignee.contactName)
      this.formGroup.controls["contactAddress"].setValue(this.consignee.contactAddress)
      this.formGroup.controls["contactEmail"].setValue(this.consignee.contactEmail)
      this.formGroup.controls["contactPhone"].setValue(this.consignee.contactPhone)
      this.formGroup.controls["contactCity"].setValue(this.consignee.contactCity)
      this.formGroup.controls["contactCountry"].setValue(this.consignee.contactCountry)
      this.selectedCountry = this.countries.find(x => x.id == this.consignee?.contactCountry?.id)
    }
  }

  save() {
    this.submit = true
    this.loading = true
    if(this.formGroup.valid && this.consignee) {
      this.consignee.companyName = this.formGroup.controls["name"].value
      this.consignee.vat = this.formGroup.controls["vat"].value
      this.consignee.contactName = this.formGroup.controls["contactName"].value
      this.consignee.contactAddress = this.formGroup.controls["contactAddress"].value
      this.consignee.contactEmail = this.formGroup.controls["contactEmail"].value
      this.consignee.contactPhone = this.formGroup.controls["contactPhone"].value
      this.consignee.contactCity = this.formGroup.controls["contactCity"].value
      this.consignee.contactCountryId = this.formGroup.controls["contactCountry"].value.id

      if(this.consigneeId && this.consigneeId > 0) {
        //update
        this._consigneeService.update(this.consignee).subscribe({
          next: (v) => {
            this.consignee = v
            this._messageService.add({ severity: 'success',
              summary: this.translate.instant('MessageService.Summary.SuccessAction'),
              detail: this.translate.instant('MessageService.Consignee.EditSuccess') })
            this.loading = false
            this.submit = false
          },
          error: (e) => {
            this.loading = false
            console.log(e)
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.Consignee.EditError') });
          },
          complete: () =>  {
            this.loading = false
            this.submit = false
            console.info('Consignee update successfully') }
        })
      }
      else {
        //create
        this.consignee.username = this._authService.getUsername()
        this._consigneeService.create(this.consignee).subscribe({
          next: (v) => {
            this.consignee = v
            this.consigneeId = this.consignee.id ? this.consignee.id : 0
            this.title = this.translate.instant('UserPages.UserConsignees.ConsigneeForm.EditTitle')
            this._messageService.add({ severity: 'success',
              summary: this.translate.instant('MessageService.Summary.SuccessAction'),
              detail: this.translate.instant('MessageService.Consignee.CreateSuccess') })
            this.loading = false
            this.submit = false
          },
          error: (e) => {
            this.loading = false
            console.log(e)
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.Consignee.CreateError') });
          },
          complete: () =>  {
            this.loading = false
            this.submit = false
            console.info('Consignee created successfully')
          }
        })
      }
    }
    else {
      this.loading = false
    }
  }

  cancel() {
    this._router.navigate([Routes.userConsignees])
  }

  getErrorMessage(fieldName: string): string {
    if (this.formGroup.controls[fieldName].hasError('required')) {
      return this.translate.instant('FormErrorMessage.FieldValueRequired')
    }
    if (this.formGroup.controls[fieldName].hasError('email')) {
      return this.translate.instant('FormErrorMessage.EmailFieldEmailFormatError')
    }
    return ''
  }

  onChangeCountry() {
    this.selectedCountry = this.countries.find(x => x.id == this.formGroup.controls['contactCountry'].value.id)
  }
}
