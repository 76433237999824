import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { NotificationGateway } from 'src/app/application/gateway/NotificationGateway';
import { AppNotification } from 'src/app/domain/models/AppNotification';
import { BasicUser } from 'src/app/domain/models/User';

class View {
  static PENDING = 1
  static READED = 2
}

@Component({
  selector: 'app-user-notifications',
  templateUrl: './user-notifications.component.html',
  styleUrls: ['./user-notifications.component.css'],
  providers: [MessageService]
})
export class UserNotificationsComponent implements OnInit {
  tabMenuItems: MenuItem[] = [
    { label: 'Sin leer', command: () =>  this.getAllPending() },
    { label: 'Leídas', command: () =>  this.getAllReaded() },
  ]
  activeItem = this.tabMenuItems[0]

  view: View = View.PENDING

  notifications: AppNotification[] = []
  selected: AppNotification | undefined
  loading: boolean = false
  visibleNotificationDialog: boolean = false

  profile: BasicUser | undefined

  constructor(private _router: Router,
    private _authService: AuthGateway,
    private _messageService: MessageService,
    private _notificationService: NotificationGateway) {

  }

  ngOnInit(): void {
    this.profile = this._authService.getUser()

    let tabMenuItemsLabel1 = 'Unread'
    let tabMenuItemsLabel2 = 'Read'

    if(this.profile) {
      switch(this.profile.lang) {
        case 'en':
          tabMenuItemsLabel1 = 'Unread'
          tabMenuItemsLabel2 = 'Read'
          break
        case 'es':
          tabMenuItemsLabel1 = 'Sin leer'
          tabMenuItemsLabel2 = 'Leídas'
          break
      }
    }

    this.tabMenuItems = [
      { label: tabMenuItemsLabel1, command: () =>  this.getAllPending() },
      { label: tabMenuItemsLabel2, command: () =>  this.getAllReaded() },
    ]

    this.getAllPending()
  }

  getAllPending() {
    this.loading = true
    this.view = View.PENDING
    this._notificationService.getAllPendingByProfile().subscribe({
      next: (v) => {
        this.notifications = v
      },
      error: (e) => {
        console.log(e)
        this._messageService.add({ severity: 'error',
          summary: 'Acción fallida',
          detail: 'No se pudieron recuperar las notificaciones.' });
      },
      complete: () => {
        this.loading = false
        console.info('Notifications query successfully')
      }
    })
  }

  getAllReaded() {
    this.loading = true
    this.view = View.READED
    this._notificationService.getAllReadedByProfile().subscribe({
      next: (v) => {
        this.notifications = v
      },
      error: (e) => {
        console.log(e)
        this._messageService.add({ severity: 'error',
          summary: 'Acción fallida',
          detail: 'No se pudieron recuperar las notificaciones.' });
      },
      complete: () => {
        this.loading = false
        console.info('Notifications query successfully')
      }
    })
  }

  openNotificationDialog(notification: AppNotification): void {
    this.selected = notification
    this.visibleNotificationDialog = true
  }

  closeNotificationDialog(): void {
    this.setReadedNotification(false)
  }

  redirectToNotificationLink() {
    console.log(this.selected?.redirectUrl)
    if(this.selected && this.selected.redirectUrl) {
      this.setReadedNotification(true)
    }
  }

  setReadedNotification(redirect: boolean): void {
    if(this.selected) {
      this._notificationService.setReaded(this.selected.id).subscribe({
        next: (v) => {
          if(!redirect) {
            if(this.selected?.status == 0) {
              window.location.reload()
            }
            else {
              this.visibleNotificationDialog = false
            }

            if(this.view === View.PENDING) {
              this.getAllPending()
            }
            else if(this.view === View.READED) {
              this.getAllReaded()
            }
          }
          else {
            window.location.href = this.selected?.redirectUrl ? this.selected?.redirectUrl : '/'
          }
        },
        error: (e) => {
          console.log(e)
          this._messageService.add({ severity: 'error',
            summary: 'Acción fallida',
            detail: 'No se pudo actualizar la notificación.' });
        },
        complete: () => {
          this.loading = false
          console.info('Notification updated successfully')
        }
      })
    }
  }

  getTitle(notification: AppNotification): string {
    if(this.profile) {
      switch(this.profile.lang) {
        case 'en':
          return notification.title
        case 'es':
          return notification.titleEs
      }
    }
    return ''
  }

  getDescription(notification: AppNotification) {
    if(this.profile) {
      switch(this.profile.lang) {
        case 'en':
          return notification.description
        case 'es':
          return notification.descriptionEs
      }
    }
    return ''
  }
}
