<app-user-dashboard [userMenuActiveItemIndex]="0" [activeItem]="'customAgentReviews'">
    <div style="margin-top: 30px;">
      <p-card *ngIf="review">
        <ng-template pTemplate="header">
          <div class="flex justify-content-between flex-wrap align-items-center" style="padding: 15px;">
            <h2>{{ 'CustomAgentPage.CustomAgentUserOriginReview.Title' | translate }}</h2>
            <p-button label="{{ 'CustomAgentPage.CustomAgentUserOriginReview.SendNotification' | translate }}" [badge]="messagePending" badgeClass="p-badge-danger" (onClick)="openMessageDialog()"></p-button>
          </div>
        </ng-template>
        <div>
          <p-fieldset legend="{{ 'CustomAgentPage.CustomAgentUserOriginReview.LegendText' | translate }}" [toggleable]="true" [collapsed]="true">
            <div class="grid">
              <div class="col-3">{{ 'CustomAgentPage.CustomAgentUserOriginReview.ImportOrderLabel' | translate }}:</div>
              <div class="col-3"><span style="font-weight: bolder;">{{ review.importOrderSequence }}</span></div>
              <div class="col-3">{{ 'CustomAgentPage.CustomAgentUserOriginReview.ImportOrderDetailLabel' | translate }}:</div>
              <div class="col-3"><span style="font-weight: bolder;">{{ review.importOrderDetailSequence }}</span></div>
  
              <div class="col-3">{{ 'CustomAgentPage.CustomAgentUserOriginReview.SpeciesVarietyLabel' | translate }}:</div>
              <div class="col-3">
                <span style="font-weight: bolder;">
                  {{ review | speciesName }} - {{ review.varietyName }}
                </span>
              </div>
              <div class="col-3">{{ 'CustomAgentPage.CustomAgentUserOriginReview.ColdStorageLabel' | translate }}:</div>
              <div class="col-3">
                <span style="font-weight: bolder;">
                  {{ review.fridgeStorageName }} {{ review.fridgeStorageCity }}, {{ review.fridgeStorageCountryName }}
                </span>
              </div>
  
              <div class="col-3">{{ 'CustomAgentPage.CustomAgentUserOriginReview.OriginPortLabel' | translate }}:</div>
              <div class="col-3">
                <span style="font-weight: bolder;">
                  {{ review.portFromName }}
                </span>
              </div>
              <div class="col-3">{{ 'CustomAgentPage.CustomAgentUserOriginReview.DestinationPortLabel' | translate }}:</div>
              <div class="col-3">
                <span style="font-weight: bolder;">
                  {{ review.portToName }}
                </span>
              </div>
            </div>
          </p-fieldset>
        </div>

        <div class="grid" style="margin-top: 20px;">
          <div class="col-6" >
            <div>
              <p-card header="{{ 'CustomAgentPage.Importer.Title' | translate }}">
                <div class="grid">
                  <div class="col-6">{{ 'CustomAgentPage.Importer.CompanyName' | translate }}:</div>
                  <div class="col-6">{{review.importer?.contactName}}</div>
                  <div class="col-6">{{ 'CustomAgentPage.Importer.ContactName' | translate }}:</div>
                  <div class="col-6">{{review.importer?.contactName}}</div>
                  <div class="col-6">{{ 'CustomAgentPage.Importer.ContactAddress' | translate }}:</div>
                  <div class="col-6">{{review.importer?.contactAddress}}</div>
                  <div class="col-6">{{ 'CustomAgentPage.Importer.ContactEmail' | translate }}:</div>
                  <div class="col-6">{{review.importer?.contactEmail}}</div>
                  <div class="col-6">{{ 'CustomAgentPage.Importer.ContactCountry' | translate }}:</div>
                  <div class="col-6">{{review.importer?.contactCountry | countryDescription}}</div>
                </div>
              </p-card>
            </div>
          </div>
          <div class="col-6" >
            <div>
              <p-card header="{{ 'CustomAgentPage.Exporter.Title' | translate }}">
                <div class="grid">
                  <div class="col-6">{{ 'CustomAgentPage.Exporter.CompanyName' | translate }}:</div>
                  <div class="col-6">{{review.exporter?.companyName}}</div>
                  <div class="col-6">{{ 'CustomAgentPage.Exporter.ContactName' | translate }}:</div>
                  <div class="col-6">{{review.exporter?.contactName}}</div>
                  <div class="col-6">{{ 'CustomAgentPage.Exporter.ContactAddress' | translate }}:</div>
                  <div class="col-6">{{review.exporter?.contactAddress}}</div>
                  <div class="col-6">{{ 'CustomAgentPage.Exporter.ContactEmail' | translate }}:</div>
                  <div class="col-6">{{review.exporter?.contactEmail}}</div>
                  <div class="col-6">{{ 'CustomAgentPage.Exporter.ContactCountry' | translate }}:</div>
                  <div class="col-6">{{review.exporter?.contactCountry | countryDescription}}</div>
                </div>
              </p-card>
            </div>
          </div>
        </div>
  
        <div style="margin-top: 50px;">
            <h3>{{ 'CustomAgentPage.CustomAgentUserOriginReview.TitleDocuments' | translate }}</h3>
            <div class="p-fluid formgrid grid">
              <div class="field col-4">
                <label style="font-weight: bolder;">{{'CustomAgentPage.CustomAgentUserOriginReview.OperationDocumentsTitle' | translate }} *</label>
                <p-dropdown [options]="operationDocumentTypes" placeholder="{{ 'CustomAgentPage.CustomAgentUserOriginReview.OperationDocumentsTitle' | translate}}" (onChange)="onSelectDocumentType($event)" styleClass="mb-2" optionLabel="description" optionValue="id" [disabled]="loading"></p-dropdown>
              </div>
              <div class="field col-12">
                <p-fileUpload #fileUpload
                    (onSelect)="onUploadDocumentPdf(fileUpload)"
                    [showUploadButton]="false"
                    [multiple]="false"
                    accept=".pdf,.xls,.xlsx"
                    [maxFileSize]="5000000"
                    [disabled]="documentTypeId === '' || loading"
                    chooseLabel="{{'OrderDetailTabComponent.Form.SelectFile' | translate}}"
                    cancelLabel="{{'OrderDetailTabComponent.Form.CancelSelectFile' | translate}}">
                </p-fileUpload>
              </div>
              <div class="field col-12">
                <p-table
                    [value]="documents"
                    responsiveLayout="stack"
                    [breakpoint]="'600px'">
                    <ng-template pTemplate="header">
                      <tr>
                        <th><div class="flex justify-content-center align-items-center">{{ 'CustomAgentPage.CustomAgentUserOriginReview.TableDocuments.DocumentName' | translate }}</div></th>
                        <th><div class="flex justify-content-center align-items-center">{{ 'CustomAgentPage.CustomAgentUserOriginReview.TableDocuments.OperationDocumentsTitle' | translate }}</div></th>
                        <th><div class="flex justify-content-center align-items-center">{{ 'CustomAgentPage.CustomAgentUserOriginReview.TableDocuments.DocumentSize' | translate }}</div></th>
                        <th><div class="flex justify-content-center align-items-center">{{ 'CustomAgentPage.CustomAgentUserOriginReview.TableDocuments.DocumentUser' | translate }}</div></th>
                        <th><div class="flex justify-content-center align-items-center">{{ 'CustomAgentPage.CustomAgentUserOriginReview.TableDocuments.CreatedDate' | translate }}</div></th>
                        <th><div class="flex justify-content-center align-items-center">{{ 'CustomAgentPage.CustomAgentUserOriginReview.TableDocuments.ActionsHeader' | translate }}</div></th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-document>
                        <tr>
                            <td style="cursor: pointer; color: #6E9F41" (click)="getImportOrderDetailDocument(document)"><div class="flex justify-content-start"><span class="pi pi-file"></span>{{ document.name }}</div></td>
                            <td><div class="flex justify-content-center">{{ document.created | date:'dd-MM-yyyy'}}</div></td>
                            <td><div class="flex justify-content-center">{{ document.docLength / 1000 }} KB</div></td>
                            <td><div class="flex justify-content-center"><span class="pi pi-user"></span>{{ document.createdBy }}</div></td>
                            <td><div class="flex justify-content-end">{{ document.created | date:'dd-MM-yyyy HH:mm' }}</div></td>
                            <td><div class="flex justify-content-end"><p-button label="{{ 'CustomAgentPage.CustomAgentUserOriginReview.ButtonDelete' | translate }}" icon="pi pi-trash" styleClass="p-button-danger" (onClick)="deleteDocument(document)" [disabled]="loading"></p-button></div></td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="summary">
                        <div class="flex align-items-center justify-content-between" *ngIf="documents.length === 0">
                            {{ 'DocumentComponent.EmptyResult' | translate }}
                        </div>
                    </ng-template>
                </p-table>
              </div>
            </div>
        </div>

      </p-card>
    </div>
</app-user-dashboard>
  
<p-toast></p-toast>
<p-confirmDialog [style]="{width: '30vw'}"></p-confirmDialog>
  
<p-dialog header="{{ 'ChatNotification.Title' | translate }}" [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleMessageDialog" [style]="{width: '50%'}" [modal]="true">
  <app-user-import-order-chat [messages]="messages" [loading]="loadingMessages" (messageSent)="onMessageSent($event)"/>
</p-dialog>