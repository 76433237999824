<app-admin-dashboard>
  <p-card>
    <div id="exporters" *ngIf="step == EXPORTERS">
      <h1>Seleccionar exportador</h1>
      <p>Seleccione un exportador de la lista, sino encuentra el exportador que necesita creelo desde el botón "Crear"</p>
      <p-button label="Siguiente" (onClick)="goToCreateExportOfferStep()"></p-button>
    </div>

    <div id="exporters" *ngIf="step == CREATE_EXPORT_OFFER">
      <h1>Crear oferta de exportación</h1>
      <p-button label="Atrás" (onClick)="goToExporters()"></p-button>
      <p-button label="Siguiente" (onClick)="goToCreateExportOfferStep()"></p-button>
    </div>
  </p-card>
</app-admin-dashboard>
