
<div style="margin-top: 20px; margin-left: 50px; margin-right: 50px;" style="font-size: small;">
  <ng-container *ngIf="typeDocuments == 0">
    <div class="grid">
      <div class="col-6" >
        <div class="border-round-sm document-section flex justify-content-start align-items-center shadow-2" pRipple (click)="showModalExportInvoice()">
          <div class="dot flex justify-content-center align-items-center">
            <span class="pi pi-file" style="color: white;"></span>
          </div>
          <span style="margin-left: 10px;">{{ 'OrderDetailTabComponent.ExportInvoiceText' | translate}}</span>
          <p-button [badge]="notificationBadge" badgeClass="p-badge-danger" styleClass="p-button-text p-button-secondary p-button-sm" [style]="{'width': 'auto', 'padding': '0'}"></p-button>
        </div>
      </div>
      <ng-container *ngFor="let detail of details">
        <div class="col-6" >
          <div class="border-round-sm document-section flex justify-content-start align-items-center shadow-2" pRipple (click)="showDownloadPdf(detail.id)">
            <div class="dot flex justify-content-center align-items-center">
              <span class="pi pi-file" style="color: white;"></span>
            </div>
            <span style="margin-left: 10px;">{{ 'OrderDetailTabComponent.InstructiveText' | translate}} - {{ detail.exporterCompanyName }}</span>
          </div>
        </div>
      </ng-container>
      <ng-container *ngFor="let opDocument of operationDocuments">
        <div class="col-6">
          <div class="border-round-sm document-section flex justify-content-start align-items-center shadow-2" pRipple (click)="getDocumentMain(opDocument)">
            <div class="dot flex justify-content-center align-items-center">
              <span class="pi pi-file" style="color: white;"></span>
            </div>
            <span style="margin-left: 10px;">{{opDocument | documentDescription }}</span>
            <div style="flex: 1; text-align: right;">
              <span>{{ opDocument.created | date:'dd-MM-yyyy HH:mm' }}</span>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngFor="let forwardDocument of forwarderDocuments">
        <div class="col-6">
          <div class="border-round-sm document-section flex justify-content-start align-items-center shadow-2" pRipple (click)="getDocumentMain(forwardDocument)">
            <div class="dot flex justify-content-center align-items-center">
              <span class="pi pi-file" style="color: white;"></span>
            </div>
            <span style="margin-left: 10px;">{{forwardDocument | documentDescription }}</span>
            <div style="flex: 1; text-align: right;">
              <span>{{ forwardDocument.created | date:'dd-MM-yyyy HH:mm' }}</span>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngFor="let customAgentDocument of customAgentDocuments">
        <div class="col-6">
          <div class="border-round-sm document-section flex justify-content-start align-items-center shadow-2" pRipple (click)="getDocumentMain(customAgentDocument)">
            <div class="dot flex justify-content-center align-items-center">
              <span class="pi pi-file" style="color: white;"></span>
            </div>
            <span style="margin-left: 10px;">{{customAgentDocument | documentDescription }}</span>
            <div style="flex: 1; text-align: right;">
              <span>{{ customAgentDocument.created | date:'dd-MM-yyyy HH:mm' }}</span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="typeDocuments == 1">
    <div class="grid">
      <ng-container *ngFor="let qcDocument of qualityControlDocuments">
        <div class="col-6">
          <div class="border-round-sm document-section flex justify-content-start align-items-center shadow-2" pRipple (click)="getDocumentMain(qcDocument)">
            <div class="dot flex justify-content-center align-items-center">
              <span class="pi pi-file" style="color: white;"></span>
            </div>
            <span style="margin-left: 10px;">{{qcDocument | documentDescription }}</span>
            <div style="flex: 1; text-align: right;">
              <span>{{ qcDocument.created | date:'dd-MM-yyyy HH:mm' }}</span>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngFor="let productDocument of productCertificateDocuments">
        <div class="col-6">
          <div class="border-round-sm document-section flex justify-content-start align-items-center shadow-2" pRipple (click)="getDocumentMain(productDocument)">
            <div class="dot flex justify-content-center align-items-center">
              <span class="pi pi-file" style="color: white;"></span>
            </div>
            <span style="margin-left: 10px;">{{productDocument | documentDescription }}</span>
            <div style="flex: 1; text-align: right;">
              <span>{{ productDocument.created | date:'dd-MM-yyyy HH:mm' }}</span>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngFor="let exportDocument of exportOfferDocuments">
        <div class="col-6">
          <div class="border-round-sm document-section flex justify-content-start align-items-center shadow-2" pRipple (click)="getDocumentMain(exportDocument)">
            <div class="dot flex justify-content-center align-items-center">
              <span class="pi pi-file" style="color: white;"></span>
            </div>
            <span style="margin-left: 10px;">{{exportDocument | documentDescription }}</span>
            <div style="flex: 1; text-align: right;">
              <span>{{ exportDocument.created | date:'dd-MM-yyyy HH:mm' }}</span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>

<p-dialog header="{{ 'OrderDetailTabComponent.ExportInvoiceText' | translate}}" [(visible)]="invoiceDialogVisible" [style]="{width: '30%'}" [modal]="true">
  <p-fileUpload
    #invoiceUploader
    *ngIf="profile?.profileType === 'Exporter'"
    (onSelect)="onUploadDocumentPdf($event, invoiceUploader)"
    [showUploadButton]="false"
    [multiple]="false"
    accept=".pdf"
    [maxFileSize]="5000000"
    chooseLabel="{{'OrderDetailTabComponent.Form.SelectFile' | translate}}"
    cancelLabel="{{'OrderDetailTabComponent.Form.CancelSelectFile' | translate}}">
  </p-fileUpload>
  <!-- <p-card header="Factura de exportación"> -->
    <div>
      <p-table
        [value]="invoices"
        responsiveLayout="stack"
        [breakpoint]="'600px'">
        <ng-template pTemplate="body" let-document>
          <tr>
            <td><div class="flex justify-content-start" style="cursor: pointer; color: #6E9F41" (click)="getDocumentMain(document)"><span class="pi pi-file"></span>{{ document.name }}</div></td>
            <td><div class="flex justify-content-end" style="color: #6D6D6D"><span class="pi pi-file"></span>{{ document.created | date:'dd-MM-yyyy HH:mm' }}</div></td>
          </tr>
        </ng-template>
        <ng-template pTemplate="summary">
          <div class="flex align-items-center justify-content-between" *ngIf="invoices.length === 0">
            {{ 'DocumentComponent.EmptyResult' | translate }}
          </div>
        </ng-template>
      </p-table>
    </div>
  <!-- </p-card> -->
</p-dialog>

<p-dialog header="{{'OrderDetailTabComponent.DownloadFormatMessage' | translate}}" 
[resizable]="false" [modal]="true" [draggable]="false"  [style]="{width: '1200px'}">

<div class="grid">
 
  <div class="col-12 flex justify-content-end gap-2">
    <p-button (click)="getInstructivePdf()" styleClass="p-button-outlined" [style]="{color: 'red'}" label="PDF" [loading]="loading">
      <span class="material-symbols-outlined">
        picture_as_pdf
        </span>
    </p-button>

    <!-- <p-button (click)="getInstructive()" icon="pi pi-file-excel" label="Excel" [loading]="loading">
    </p-button> -->
  </div>
  <div class="col-12">
    <p-table [value]="palletsInstructiveFormat">
      <ng-template pTemplate="header">
        <tr>
          <th>Especie</th>
          <th>Variedad</th>
          <th>Etiqueta</th>
          <th>Embalaje</th>
          <th>Kg neto</th>
          <th>Cat</th>
          <th>Calibre</th>
          <th>Pallets</th>
          <th>Cajas</th>
      </tr>
      </ng-template>
      <ng-template pTemplate="body" let-pallet>
        <tr>
          <td>{{pallet.speciesName}}</td>
          <td>{{pallet.varietyName}}</td>
          <td>{{pallet.Label}}</td>
          <td>{{pallet.packaging}}</td>
          <td>{{pallet.netKilos}}</td>
          <td>{{pallet.category}}</td>
          <td>{{pallet.speciesSize}}</td>
          <td>{{pallet.pallets}}</td>
          <td>{{pallet.boxesPerPallets}}</td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="grid">
              <div class="col-2">
                <span>Pallets :</span>
              </div>
              <div class="col-10">
                <span>
                  {{pallet.palletCodes}}
                </span>
              </div>
            </div>
          </td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
</p-dialog>

<p-dialog header="{{'OrderDetailTabComponent.DownloadFormatMessage' | translate}}" [(visible)]="visibleFormatDialog" [style]="{width: '80%'}" [modal]="true">
  <div class="col-12 flex justify-content-end gap-2">
    <p-button (click)="getInstructivePdf()" styleClass="p-button-outlined" [style]="{color: 'red'}" label="PDF" [loading]="loading">
      <span class="material-symbols-outlined">
        picture_as_pdf
        </span>
    </p-button>

    <!-- <p-button (click)="getInstructive()" icon="pi pi-file-excel" label="Excel" [loading]="loading">
    </p-button> -->
  </div>

  <div style="margin-top: 40px;">
    <p-table
      [value]="pallets"
      dataKey="dataKey"
      responsiveLayout="stack"
      [breakpoint]="'600px'"
      styleClass="p-datatable-gridlines"
      [scrollable]="true"
      [resizableColumns]="true"
      columnResizeMode="expand"
      scrollHeight="600px">
      <ng-template pTemplate="header">
        <tr>
          <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.FolioHeader' | translate}}</th>
          <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.SpeciesHeader' | translate}}</th>
          <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.VarietyHeader' | translate}}</th>
          <th *ngIf="profile?.type === 1">{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.ProducerHeader' | translate}}</th>
          <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.SpeciesSizeHeader' | translate}}</th>
          <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.HarvestDateHeader' | translate}}</th>
          <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.QualityControlDateHeader' | translate}}</th>
          <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.PackagingHeader' | translate}}</th>
          <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.PalletTypeHeader' | translate}}</th>
          <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.TagHeader' | translate}}</th>
          <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.BagTypeHeader' | translate}}</th>
          <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.BoxTypeHeader' | translate}}</th>
          <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.NetWeightHeader' | translate}}</th>
          <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.ColdStorageHeader' | translate}}</th>
          <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.BoxePerPalletHeader' | translate}}</th>
          <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.MarksHeader' | translate}}</th>
          <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.GradeHeader' | translate}}</th>
          <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.ContainerNumberHeader' | translate}}</th>
          <th>ETD</th>
          <th>ETA</th>
          <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadWeekHeader' | translate}}</th>
          <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.OriginCountryHeader' | translate}}</th>
          <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.DestinationCountryHeader' | translate}}</th>
          <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.TemptaleHeader' | translate}}</th>
          <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.Cert1Header' | translate}}</th>
          <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.Cert2Header' | translate}}</th>
          <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.Cert3Header' | translate}}</th>
          <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadWeekHeader' | translate}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-pallet let-editing="editing" let-ri="rowIndex">
        <tr>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="pallet.palletNumber" maxlength="20" required [readOnly]="true"/>
              </ng-template>
              <ng-template pTemplate="output">
                {{pallet.palletNumber}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="pallet.excelImportSpecies" maxlength="100" required/>
              </ng-template>
              <ng-template pTemplate="output">
                {{pallet.excelImportSpecies}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="pallet.excelImportVariety" maxlength="100" required/>
              </ng-template>
              <ng-template pTemplate="output">
                {{pallet.excelImportVariety}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td *ngIf="profile?.type === 1">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="pallet.producer" maxlength="200" required/>
              </ng-template>
              <ng-template pTemplate="output">
                {{pallet.producer}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="pallet.speciesSize" maxlength="30"/>
              </ng-template>
              <ng-template pTemplate="output">
                {{pallet.speciesSize}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="pallet.excelImportHarvestDate" maxlength="50"/>
              </ng-template>
              <ng-template pTemplate="output">
                {{pallet.excelImportHarvestDate}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="pallet.excelImportQualityControlDate" maxlength="50"/>
              </ng-template>
              <ng-template pTemplate="output">
                {{pallet.excelImportQualityControlDate}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="pallet.excelImportPackaging" maxlength="50"/>
              </ng-template>
              <ng-template pTemplate="output">
                {{pallet.excelImportPackaging}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="pallet.excelImportPalletType" maxlength="50"/>
              </ng-template>
              <ng-template pTemplate="output">
                {{pallet.excelImportPalletType}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="pallet.excelImportTag" maxlength="50"/>
              </ng-template>
              <ng-template pTemplate="output">
                {{pallet.excelImportTag}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="pallet.excelImportBagType" maxlength="50"/>
              </ng-template>
              <ng-template pTemplate="output">
                {{pallet.excelImportBagType}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="pallet.excelImportBoxSize" maxlength="50"/>
              </ng-template>
              <ng-template pTemplate="output">
                {{pallet.excelImportBoxSize}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="pallet.excelImportNetWeight" maxlength="50"/>
              </ng-template>
              <ng-template pTemplate="output">
                {{pallet.excelImportNetWeight}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="pallet.excelImportFridgeStorage" maxlength="50"/>
              </ng-template>
              <ng-template pTemplate="output">
                {{pallet.excelImportFridgeStorage}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="pallet.excelImportBoxesPerPallet" maxlength="50"/>
              </ng-template>
              <ng-template pTemplate="output">
                {{pallet.excelImportBoxesPerPallet}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="pallet.excelImportMark" maxlength="50"/>
              </ng-template>
              <ng-template pTemplate="output">
                {{pallet.excelImportMark}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="pallet.excelImportGrade" maxlength="50"/>
              </ng-template>
              <ng-template pTemplate="output">
                {{pallet.excelImportGrade}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="pallet.excelImportContainerNumber" maxlength="50"/>
              </ng-template>
              <ng-template pTemplate="output">
                {{pallet.excelImportContainerNumber}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>{{ orderDetailInformation?.etd ? (orderDetailInformation?.etd | date:'dd-MM-yyyy') : '(Post loading information)' }}</td>
          <td>{{ orderDetailInformation?.eta ? (orderDetailInformation?.eta | date:'dd-MM-yyyy') : '(Post loading information)'}}</td>
          <td>{{ orderDetailInformation?.week ? orderDetailInformation?.week : '(Post loading information)'}}</td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="pallet.excelImportOriginCountry" maxlength="50"/>
              </ng-template>
              <ng-template pTemplate="output">
                {{pallet.excelImportOriginCountry}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>{{ orderDetailInformation?.destinationPortName ? orderDetailInformation?.destinationPortName : '(Post loading information)'}}</td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="pallet.excelImportTemptale" maxlength="50"/>
              </ng-template>
              <ng-template pTemplate="output">
                {{pallet.excelImportTemptale}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="pallet.excelImportCertificate1" maxlength="50"/>
              </ng-template>
              <ng-template pTemplate="output">
                {{pallet.excelImportCertificate1}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="pallet.excelImportCertificate2" maxlength="50"/>
              </ng-template>
              <ng-template pTemplate="output">
                {{pallet.excelImportCertificate2}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="pallet.excelImportCertificate3" maxlength="50"/>
              </ng-template>
              <ng-template pTemplate="output">
                {{pallet.excelImportCertificate3}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="pallet.excelImportLoadWeek" maxlength="50"/>
              </ng-template>
              <ng-template pTemplate="output">
                {{pallet.excelImportLoadWeek}}
              </ng-template>
            </p-cellEditor>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-dialog>