import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { ExportOfferGateway } from 'src/app/application/gateway/ExportOfferGateway';
import { OfferNegotiationGateway } from 'src/app/application/gateway/OfferNegotiationGateway';
import { ProfileGateway } from 'src/app/application/gateway/ProfileGateway';
import { ExportOffer } from 'src/app/domain/models/ExportOffer';
import { AddNegotiation, OfferNegotiationDetail, OfferNegotiationInformation } from 'src/app/domain/models/OfferNegotiation';
import { Packaging } from 'src/app/domain/models/Packaging';
import { ProductCertificateType } from 'src/app/domain/models/ProductCertificateType';
import { BasicUser } from 'src/app/domain/models/User';
import { Routes } from 'src/app/infrastructure/routes';
import { ExportOfferUtils } from 'src/app/ui/util/export-offer-utils';

@Component({
  selector: 'app-bargaining-detail',
  templateUrl: './bargaining-detail.component.html',
  styleUrls: ['./bargaining-detail.component.css'],
  providers: [MessageService]
})
export class BargainingDetailComponent implements OnInit {
  visibleBargainingDialog: boolean = false
  visibleExportOfferDialog: boolean = false
  visibleRedirectDialog: boolean = false

  submit = false
  loading = false
  negotiationId: number | undefined
  negotiation: OfferNegotiationInformation | undefined
  selectedExportOffer: ExportOffer | undefined
  selectedProductCertificateTypes: ProductCertificateType[] = []
  selectedPackagingTypes: Packaging[] = []

  profile: BasicUser | undefined
  profileType = ''

  paymentBLItems: any[] = []

  details: OfferNegotiationDetail[] = []

  incotermsOptions: any[] = ExportOfferUtils.getIncotermsOptions()

  formGroup: FormGroup = this._formBuilder.group({
    negotiationPrice: ['', Validators.required],
    negotiationPallets: ['', Validators.required],
    negotiationIncoterms: ['', Validators.required],
    negotiationMessage: [''],
    negotiationPaymentAgainstBL: [100, Validators.required]
  })

  constructor(private _activatedRoute: ActivatedRoute,
    private _messageService: MessageService,
    private _formBuilder: FormBuilder,
    private _authService: AuthGateway,
    private _profileService: ProfileGateway,
    private _negotiationService: OfferNegotiationGateway,
    private _router: Router,
    private _exportOfferService: ExportOfferGateway,
    public translate: TranslateService) {

    this._activatedRoute.params.subscribe(params => {
      this.negotiationId = params['negotiationId']
      this.profile = this._authService.getUser()
    })
  }

  ngOnInit(): void {
    if(this.profile) {
      this.profileType = this.profile.profileType
      this.loadNegotiation()
    }
  }

  loadNegotiation() {
    if(this.negotiationId) {
      this.loading = true
      this._negotiationService.getById(this.negotiationId).subscribe({
        next: (v) => {
          this.negotiation = v
          this.getDetails()
        },
        error: (e) => {
          console.log(e)
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.Negotiation.SingleRetrieveError') })
        },
        complete: () => {
          this.loading = false
          console.info('Offer Negotiation query successfully')
        }
      })
    }
  }

  getIndex(detail: OfferNegotiationDetail): number {
    if(this.negotiation && this.negotiation.offerNegotiationDetails) {
      return this.negotiation.offerNegotiationDetails.indexOf(detail)
    }
    return 0
  }

  getDetails() {
    if(this.negotiation && this.negotiation.offerNegotiationDetails) {
      this.details = this.negotiation.offerNegotiationDetails
    }
  }

  get username(): string {
    return this._authService.getUsername()
  }

  getHeader(detail: OfferNegotiationDetail): string {
    let startLabel = 'Start negotiation'
    let counterOffer = 'Counter Offer'

    switch(this.profile?.lang) {
      case 'en':
        startLabel = 'Start negotiation'
        counterOffer = 'Counter Offer'
        break
      case 'es':
        startLabel = 'Inicio'
        counterOffer = 'Contraoferta'
        break
    }

    if(this.negotiation && this.negotiation.offerNegotiationDetails) {
      const index = this.negotiation.offerNegotiationDetails.indexOf(detail)
      if(index == 0)
        return startLabel
    }
    return counterOffer
  }

  getCreated(detail: OfferNegotiationDetail): string {
    if(detail) {
      return formatDate(detail.created, 'dd-MM-yyyy HH:mm', 'en_US')
    }
    return ''
  }

  openBargainingDialog(): void {
    let last = this.details[this.details.length - 1]
    if(last) {
      this.formGroup.controls['negotiationPrice'].setValue(last.unitPrice)
      this.formGroup.controls['negotiationIncoterms'].setValue(last.typeSale)
      this.getPaymentsBLItems()
      this.formGroup.controls['negotiationPallets'].setValue(last.pallets)
      this.formGroup.controls['negotiationPaymentAgainstBL'].setValue(last.paymentAgainstBL)
    }
    this.visibleBargainingDialog = true
  }

  closeBargainingDialog(): void {
    this.visibleBargainingDialog = false
  }

  openExportOfferDialog(): void {
    if(this.negotiation) {
      this._exportOfferService.getById(this.negotiation.exportOffer.id).subscribe({
        next: (v) => {
          this.selectedExportOffer = v
          if(this.selectedExportOffer.productCertificateTypes)
            this.selectedProductCertificateTypes = this.selectedExportOffer.productCertificateTypes

          if(this.selectedExportOffer.packagingTypes)
            this.selectedPackagingTypes = this.selectedExportOffer.packagingTypes

          this.loading = false
          this.visibleExportOfferDialog = true
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOffer.ManyRetrieveError') })
        },
        complete: () => console.info('Export Offers query successfully')
      })
    }
  }

  openRedirectDialog() {
    if(this.profileType === 'Importer'){
      this.visibleRedirectDialog = true
    }
  }

  saveNegotiationDetail() {
    this.loading = true
    this.submit = true
    if(this.negotiation && this.formGroup.valid) {

      const addNegotiation: AddNegotiation = {
        offerNegotiationId: this.negotiation.offerNegotiation.id,
        pallets: this.formGroup.controls['negotiationPallets'].value,
        unitPrice: this.formGroup.controls['negotiationPrice'].value,
        typeSale: this.formGroup.controls['negotiationIncoterms'].value,
        username: this._authService.getUsername(),
        isCounterOffer: true,
        message: this.formGroup.controls['negotiationMessage'].value,
        paymentAgainstBL: this.formGroup.controls['negotiationPaymentAgainstBL'].value,
      }

      this._negotiationService.addNegociation(addNegotiation).subscribe({
        next: (v) => {
          this.negotiation = v
          this.loadNegotiation()
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.Negotiation.CreateSuccess') })
          this.visibleBargainingDialog = false
          this.loading = false
          this.submit = false
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this.submit = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.Negotiation.CreateError') })
        },
        complete: () =>  {
          this.loading = false
          this.submit = false
          console.info('Offer Negotiation Detail created successfully')
        }
      })
    }
    else {
      this.loading = false
    }
  }

  approveOfferNegotiation(offerNegotiationDetailId: number) {
    this.loading = true
    if(this.negotiation) {
      this._negotiationService.approve(offerNegotiationDetailId).subscribe({
        next: (v) => {
          this.negotiation = v
          this.getDetails()
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: this.translate.instant('MessageService.Negotiation.ApprovalSuccess') })
          this.loading = false
          if(this.profile && this.profile.type === 2)
            this.redirectToPurchaseOrdersPage()
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.Negotiation.ApprovalError') })
        },
        complete: () =>  {
          this.loading = false
          console.info('Offer Negotiation Detail created successfully')
        }
      })
    }
    else {
      this.loading = false
    }
  }

  rejectOfferNegotiation() {
    this.loading = true
    if(this.negotiation) {
      this._negotiationService.reject(this.negotiation.offerNegotiation.id).subscribe({
        next: (v) => {
          this.negotiation = v
          this.getDetails()
          this.loading = false
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: this.translate.instant('MessageService.Negotiation.RejectSuccess') })
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.Negotiation.RejectError') })
        },
        complete: () =>  {
          this.loading = false
          console.info('Offer Negotiation Detail created successfully')
        }
      })
    }
    else {
      this.loading = false
    }
  }

  getErrorMessage(fieldName: string): string {
    if (this.formGroup.controls[fieldName].hasError('required')) {
      return this.translate.instant('FormErrorMessage.FieldValueRequired')
    }
    if (this.formGroup.controls[fieldName].hasError('email')) {
      return this.translate.instant('FormErrorMessage.EmailFieldEmailFormatError')
    }
    return ''
  }

  redirectToPurchaseOrdersPage(){
   this._router.navigate([Routes.purchaseOrdersPage]);
  }

  get totalCounterOffer(): number {
    if(this.negotiation) {
      const pallets = this.formGroup.controls['negotiationPallets'].value
      const boxes = this.negotiation.exportOffer.caseQuantityPerPallet
      const unitPrice = this.formGroup.controls['negotiationPrice'].value
      return boxes * pallets * unitPrice
    }
    return 0
  }

  goToOrders(){
    this._router.navigate([(Routes.purchaseOrdersPage)])
  }

  onChangeIncoterm(event: any)
  {
    this.getPaymentsBLItems()
  }

  getPaymentsBLItems()
  {
    this.paymentBLItems = ExportOfferUtils.getPaymentBLValues(this.formGroup.controls['negotiationIncoterms'].value);
  }

  // get paymentBLItems() {
  //   return ExportOfferUtils.getPaymentBLValues(this.formGroup.controls['negotiationIncoterms'].value);
  // }

  get showPaymentBLPercent() {
    return ExportOfferUtils.showPaymentBLPercent(this.formGroup.controls['negotiationIncoterms'].value)
  }

  get finalPaymentPercent(): number {
    if(this.formGroup.controls['negotiationPaymentAgainstBL'].value)
      return 100 - this.formGroup.controls['negotiationPaymentAgainstBL'].value
    return 0
  }

  get paymentBLTitle() {
    if (this.formGroup.controls['negotiationPaymentAgainstBL'].value)
      return ExportOfferUtils.getPaymentBLTitle(this.formGroup.controls['negotiationPaymentAgainstBL'].value);
    return 'UserPages.UserBargaining.OpenTab.BargainingDetail.MakeCounterOfferModal.PaymentAgainstBLLabel'
  }

}
