import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { DocumentServiceGateway } from 'src/app/application/gateway/DocumentServiceGateway';
import { ForwarderGateway } from 'src/app/application/gateway/ForwarderGateway';
import { ImportOrderDocumentGateway } from 'src/app/application/gateway/ImportOrderDocumentGateway';
import { ImportOrderGateway } from 'src/app/application/gateway/ImportOrderGateway';
import { OperationDocumentTypeGateway } from 'src/app/application/gateway/OperationDocumentTypeGateway';
import { ForwarderReview } from 'src/app/domain/models/ForwarderReview';
import { ChatMessage, ImportOrderDocument, ImportOrderReviewMessageCreate, UploadImportOrderDocumentRequest } from 'src/app/domain/models/ImportOrder';
import { OperationDocumentType } from 'src/app/domain/models/Operation';
import { ErrorMessageUtil } from 'src/app/ui/util/error-message-util';

@Component({
  selector: 'app-forwarder-user-origin-review',
  templateUrl: './forwarder-user-origin-review.component.html',
  styleUrls: ['./forwarder-user-origin-review.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class ForwarderUserOriginReviewComponent implements OnInit {

  breadcrumbItems: MenuItem[] = []
  breadcrumbHomeItem: MenuItem = {}

  reviewId: string | undefined
  review: ForwarderReview | undefined
  documents: ImportOrderDocument[] = []

  loading: boolean = false
  success: boolean = false
  loadingMessages: boolean = false

  visibleMessageDialog = false
  messages: ChatMessage[] = []

  documentTypeId: string = ""
  operationDocumentTypes: OperationDocumentType[] = []

  constructor(private _activatedRoute: ActivatedRoute,
    private _messageService: MessageService,
    public translate: TranslateService,
    private _importOrderDocumentService: ImportOrderDocumentGateway,
    private _documentService: DocumentServiceGateway,
    private _forwarderReviewService: ForwarderGateway,
    private _importOrderService: ImportOrderGateway,
    private _confirmationService: ConfirmationService,
    private _operationDocumentTypeService: OperationDocumentTypeGateway){}

  ngOnInit(): void {
    this._activatedRoute.params.subscribe(params => {
      this.reviewId = params['reviewId']
    })

    this.loadExportDocumentType()
    this.loadReview()
  }

  loadExportDocumentType() {
    this.operationDocumentTypes =  []
    this._operationDocumentTypeService.getAll().subscribe({
      next: (v) => {
        this.operationDocumentTypes = v.map((item) => {
          return {
            ...item,
            description: this.translate.currentLang === 'es' ? item.nameEs : item.name
          }
        })
        this.loading = false
      },
      error: (e) => {
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.ExportOfferDocument.ManyRetrieveError') });
        console.log(e)
        this.loading = false
      },
      complete: () =>  {
        this.loading = false
        console.info('Operation Document types query successfully')
      }
    })
  }

  loadReview() {
    this.loading = true
    if(this.reviewId) {
      this._forwarderReviewService.getByReviewId(this.reviewId).subscribe({
        next: (v) => {
          this.review = v
          this.loadDocuments()
          this.loading = false
        },
        error: (e) => {
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('ForwarderPage.Messages.ErrorLoadOrder') });

          this.loading = false
          console.log(e)
        },
        complete: () =>  {
          this.loading = false
          console.info('Review query successfully')
        }
      })
    }
  }

  loadDocuments() {
    if (this.review)
    {
      this.loading = true
      this.documents = []
      this._importOrderDocumentService.getAllImportOrderDocuments(this.review.importOrderGuid, 0).subscribe({
        next: (v) => {
          console.log(v)
          this.documents = v
          this.loading = false
        },
        error: (e) => {
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('ForwarderPage.Messages.ErrorLoadDocument') });
  
          this.loading = false
          console.log(e)
        },
        complete: () =>  {
          this.loading = false
          console.info('Documents query successfully')
        }
      })
    }
  }

  getImportOrderDetailDocument(doc: any) {
    this._documentService.getImportOrderDetailDocument(doc.guid).subscribe((resultBlob: any) => {

      const contentType = resultBlob.headers.get('Content-Type');
      const blob = new Blob([resultBlob.body], { type: contentType });
      const downloadURL = window.URL.createObjectURL(blob);

      if (contentType === 'application/pdf') {
        window.open(downloadURL)
      } else {
        // Para otros tipos de archivos, forzar la descarga
        const anchor = window.document.createElement('a');
        anchor.href = downloadURL;
        anchor.download = doc.name;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      }

      window.URL.revokeObjectURL(downloadURL);
    })
  }

  onUploadDocumentPdf(fileUpload: any) {
    if (this.review && this.documentTypeId)
    {
      this.loading = true
      let request: UploadImportOrderDocumentRequest = {
        guid: this.review.importOrderGuid,
        document: fileUpload.files[0],
        documentType: 0,
        operationDocumentTypeId: this.documentTypeId
      }
  
      this._importOrderDocumentService.uploadImportOrderDocument(request).subscribe({
        next: (v) => {
          this.loadReview()
          this.loadDocuments()
          this.documentTypeId = ''
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: this.translate.instant('ForwarderPage.Messages.SuccessUploadDocument') })
        },
        error: (e) => {
          console.log(e)
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('ForwarderPage.Messages.ErrorUploadDocument') })
        },
        complete: () =>  {
          this.loading = false
          console.info('Export Invoice uploaded successfully')
        }
      })
      fileUpload.clear()
    }
  }

  deleteDocument (doc: any){
    this._confirmationService.confirm({
      message: this.translate.instant('ForwarderPage.ForwarderUserOriginReview.DialogDocument.Title'),
      header: this.translate.instant('ForwarderPage.ForwarderUserOriginReview.DialogDocument.Message'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loading = true
        this._importOrderDocumentService.deleteImportOrderDocument(doc.guid).subscribe(
          {
            next: () => {
              this._messageService.add({ severity: 'success', summary: this.translate.instant('MessageService.Summary.SuccessAction'), detail: this.translate.instant('ForwarderPage.Messages.SuccessDeleteDocument') });
              this.loadDocuments()
            },
            error: (e) => {
              console.log(e)
              this._messageService.add({ severity: 'error', summary: this.translate.instant('MessageService.Summary.FailedAction'), detail: this.translate.instant('ForwarderPage.Messages.ErrorDeleteDocument') });
              this.loading = false
            },
            complete: () => {
              this.loading = false
            }
          }
        )
      },
      reject: (type: any) => {}
    })
  }

  openMessageDialog() {
    this.visibleMessageDialog = true
    this.loadMessages()
  }

  getErrorMessage(formGroup: FormGroup, fieldName: string): string {
    return ErrorMessageUtil.getErrorMessage(formGroup, this.translate, fieldName)
  }

  loadMessages() {
    if (this.review?.importOrderGuid) {
      this.loadingMessages = true
      this._importOrderService.getReviewMessages(this.review?.importOrderGuid).subscribe({
        next: (v) => {
          this.messages = v.map((item) => { 
            return {
              sender: item.profile.contactEmail,
              message: item.message,
              created: item.created
            }
          })
          if (this.review) this.review.messages = 0
        },
        error: (e) => {
          console.log(e)
        },
        complete: () => console.info('Review Message query successfully')
      })
      this.loadingMessages = false
    }
  }

  onMessageSent(message: ChatMessage) {
    if (message.message) {
      this.loading = true;
      const newMessage: ImportOrderReviewMessageCreate = {
        importOrderId: this.review?.importOrderGuid,
        message: message.message
      }
      this._importOrderService.addReviewMessages(newMessage).subscribe({
        next: (v) => {
          this.loading = false;
          this.loadMessages();
        },
        error: (e) => {
          console.log(e)
          this._messageService.add({ severity: 'error', summary: this.translate.instant('MessageService.Summary.FailedAction'), detail: this.translate.instant('ChatNotification.Messages.ErrorLoadMessages') });
        },
        complete: () => console.info('Review Message query successfully')
      })
    }
  }

  get messagePending(): string {
    if(this.review?.messages)
      return this.review?.messages > 0 ? this.review?.messages?.toString() : '';
    return ''
  }

  onSelectDocumentType(event: any) {
    this.documentTypeId = event.value
  }

}
