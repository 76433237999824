<app-user-dashboard [userMenuActiveItemIndex]="0" [activeItem]="'user-import-request'">
    <p-card>
        <ng-template pTemplate="header">
            <div class="flex justify-content-between flex-wrap align-items-center" style="padding: 15px;">
                <h2>{{ 'UserPages.ViewImportRequestOffers.PageTitle' | translate }}</h2>
            </div>
        </ng-template>
        <p-table
          #dt1
          [value]="exportOffers" [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
          [rowsPerPageOptions]="[10, 25, 50]"
          [loading]="loading">
            <ng-template pTemplate="header">
                <tr>
                    <th>
                      <div class="flex justify-content-center align-items-center">
                        {{ 'UserPages.ViewImportRequestOffers.SpeciesHeader' | translate }}
                      </div>
                    </th>
                    <th>
                      <div class="flex justify-content-center align-items-center">
                        {{ 'UserPages.ViewImportRequestOffers.VarietyHeader' | translate }}
                      </div>
                    </th>
                    <th>
                      <div class="flex justify-content-center align-items-center">
                        {{ 'UserPages.ViewImportRequestOffers.AvailablePalletsHeader' | translate }}
                      </div>
                    </th>
                    <th>
                      <div class="flex justify-content-center align-items-center">
                        {{ 'UserPages.ViewImportRequestOffers.PriceHeader' | translate }}
                      </div>
                    </th>
                    <th>
                      <div class="flex justify-content-center align-items-center">
                        {{ 'UserPages.ViewImportRequestOffers.CountryHeader' | translate }}
                      </div>
                    </th>
                    <th>
                      <div class="flex justify-content-center align-items-center">
                        {{ 'UserPages.ViewImportRequestOffers.RequestDateHeader' | translate }}
                      </div>
                    </th>
                    <th>
                      <div class="flex justify-content-center align-items-center">
                        {{ 'UserPages.ViewImportRequestOffers.ViewOfferHeader' | translate }}
                      </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-exportOfferInfo>
                <tr [pSelectableRow]="exportOfferInfo">
                    <td>
                      <div class="flex justify-content-center align-items-center">
                        <p>{{exportOfferInfo.exportOffer.variety.species.name}}</p>
                      </div>
                    </td>
                    <td>
                      <div class="flex justify-content-center align-items-center">
                        <p>{{exportOfferInfo.exportOffer.variety.name}}</p>
                      </div>
                    </td>
                    <td>
                      <div class="flex justify-content-center align-items-center">
                        {{exportOfferInfo.exportOffer.availablePallets}}
                      </div>
                    </td>
                    <td>
                      <div class="flex justify-content-center align-items-center">
                        {{exportOfferInfo.exportOffer.listPrice | currency: 'USD':'US$'}}
                      </div>
                    </td>
                    <td>
                      <div class="flex justify-content-center align-items-center">
                        <img [src]="'../../../../assets/img/flags/' + exportOfferInfo.exportOffer.country.code + '.png'"
                        height="50px" />
                      </div>
                    </td>
                    <td>
                      <div class="flex justify-content-center align-items-center">
                        {{exportOfferInfo.created | date:'dd-MM-yyyy HH:mm'}}
                      </div>
                    </td>
                    <td>
                      <div class="flex justify-content-center align-items-center gap-1">
                        <p-button icon="pi pi-eye" pTooltip="{{ 'CommonLabels.Tooltip.ViewDetail' | translate}}" (onClick)="showOfferDetailDialog2(exportOfferInfo)" ></p-button>
                      </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </p-card>
</app-user-dashboard>

<p-toast></p-toast>

<p-dialog [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleOfferDetailDialog" [style]="{width: '80%'}">
  <div  *ngIf="selected && selected.exportOffer">
    <div class="grid">
      <div class="col-12 md:col-6 lg:col-6">
        <app-export-offer-detail-carousel [offer]="selected.exportOffer"></app-export-offer-detail-carousel>
      </div>
      <div class="col-12 md:col-6 lg:col-6">
        <p-card [header]="getHeader(selected.exportOffer)" [subheader]="getSubheader(selected.exportOffer)">
          <div class="flex justify-content-between align-items-center">
            <p style="font-size: 1.5rem; color: #8c2b87; font-weight: bold;">TOTAL {{ totalPrice | currency: 'USD':'US$' }}
              {{getIncoterms(selected.exportOffer.typeSale)}}</p>
          </div>
          <div class="grid" style="margin-top: 30px;">
            <div class="col-12 md:col-3 lg:col-3">{{ 'UserPages.ViewImportRequestOffers.OfferDialog.CaseQuantityPerPalletLabel' | translate }}:</div>
            <div class="col-12 md:col-3 lg:col-3">
              <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{
                selected.exportOffer.caseQuantityPerPallet }}</span>
            </div>
            <div class="col-12 md:col-3 lg:col-3">{{ 'UserPages.ViewImportRequestOffers.OfferDialog.ListPriceLabel' | translate }}:</div>
            <div class="col-12 md:col-3 lg:col-3">
              <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{
                selected.exportOffer.listPrice | currency: 'USD':'US$' }}</span>
            </div>
            <div class="col-12 md:col-3 lg:col-3">{{'UserPages.ViewImportRequestOffers.OfferDialog.PaymentAgainstBLLabel' | translate}}</div>
            <div class="col-12 md:col-3 lg:col-3">
              <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{ selected.exportOffer.paymentAgainstBL }} %</span>
            </div>
            <div class="col-12 md:col-3 lg:col-3">{{ 'UserPages.ViewImportRequestOffers.OfferDialog.AvailablePalletsLabel' | translate }}:</div>
            <div class="col-12 md:col-3 lg:col-3">
              <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{
                availablePallets }}</span>
            </div>
            <div class="col-12 md:col-3 lg:col-3">{{ 'UserPages.ViewImportRequestOffers.OfferDialog.ShelfLife' | translate }}:</div>
            <div class="col-12 md:col-3 lg:col-3">
              <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{
                selected.exportOffer.shelfLife ? selected.exportOffer.shelfLife + ' días' : 'No disponible' }}</span>
            </div>
            <div class="col-12 md:col-3 lg:col-3">{{ 'UserPages.ViewImportRequestOffers.OfferDialog.Color' | translate }}:</div>
            <div class="col-12 md:col-3 lg:col-3">
              <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{
                selected.exportOffer.color ? getColor(selected.exportOffer.color) : 'No disponible'}}</span>
            </div>
            <div class="col-12 md:col-3 lg:col-3">{{ 'UserPages.ViewImportRequestOffers.OfferDialog.NetKilos' | translate }}:</div>
            <div class="col-12 md:col-3 lg:col-3">
              <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{
                selected.exportOffer.netKilos ? selected.exportOffer.netKilos + ' Kg.' : 'No disponible' }}</span>
            </div>
            <div class="col-12 md:col-3 lg:col-3">{{ 'UserPages.ViewImportRequestOffers.OfferDialog.GrossKilos' | translate }}:</div>
            <div class="col-12 md:col-3 lg:col-3">
              <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{
                selected.exportOffer.grossKilos ? selected.exportOffer.grossKilos + ' Kg.' : 'No disponible' }}</span>
            </div>
            <div class="col-12 md:col-3 lg:col-3">{{ 'UserPages.ViewImportRequestOffers.OfferDialog.SpeciesSize' | translate }}:</div>
            <div class="col-12 md:col-3 lg:col-3">
              <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{
                selected.exportOffer.speciesSize ? selected.exportOffer.speciesSize : 'No disponible' }}</span>
            </div>
            <div class="col-12 md:col-3 lg:col-3">{{ 'UserPages.ViewImportRequestOffers.OfferDialog.IncotermsLabel' | translate}}:</div>
            <div class="col-12 md:col-3 lg:col-3">
              <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{
                selected.exportOffer.typeSale ? selected.exportOffer.typeSale : getNoAvailableLabel() }}</span>
            </div>
            <div class="col-12 md:col-6 lg:col-6">{{'ExportOffer.ExportOfferDetail.AvailableProductCertificates' | translate}}:</div>
            <div class="col-12 md:col-6 lg:col-6">
              <ul *ngIf="selectedProductCertificateTypes.length > 0">
                <ng-container *ngFor="let productCertificateType of selectedProductCertificateTypes">
                  <!-- <li>{{ productCertificateType.name }}&nbsp;<span *ngIf="productCertificateType.isOther" style="font-weight: bolder;">{{ selected.exportOffer.otherCertificateType }}</span></li> -->
                  <li *ngIf="!productCertificateType.isOther">{{ productCertificateType.name }}</li>
                  <li *ngIf="productCertificateType.isOther">{{ selected.exportOffer.otherCertificateType }}</li>
                </ng-container>
              </ul>
              <span *ngIf="selectedProductCertificateTypes.length == 0" style="font-weight: bolder;">{{ 'UserPages.UserExportOffers.NotResultsMessages' | translate }}</span>
            </div>
            <div class="col-12 md:col-6 lg:col-6">{{'ExportOffer.ExportOfferDetail.AvailablePackaging' | translate}}:</div>
            <div class="col-12 md:col-6 lg:col-6">
              <ul *ngIf="selectedPackagingTypes.length > 0">
                <ng-container *ngFor="let packagingType of selectedPackagingTypes">
                  <!-- <li>{{ packagingType.name }}&nbsp;<span *ngIf="packagingType.isOther" style="font-weight: bolder;">{{ selected.exportOffer.otherPackaging }}</span></li> -->
                  <li *ngIf="!packagingType.isOther">{{ packagingType.name }}</li>
                  <li *ngIf="packagingType.isOther">{{ selected.exportOffer.otherPackaging }}</li>
                </ng-container>
              </ul>
              <span *ngIf="selectedPackagingTypes.length == 0" style="font-weight: bolder;">{{ 'UserPages.UserExportOffers.NotResultsMessages' | translate }}</span>
            </div>
          </div>

          <div class="p-fluid formgrid grid align-items-end" style="margin-top: 30px;">
            <div class="col-12">
              <h2>{{ 'UserPages.ViewImportRequestOffers.OfferDialog.ImmediatePurchaseTitle' | translate }}</h2>
            </div>
            <div class="field col-12 md:col-6 lg:col-6">
              <label for="pallets">Pallets</label>
              <p-inputNumber inputId="pallets"
                [(ngModel)]="pallets"
                [showButtons]="false"
                [min]="1"
                [max]="selected.exportOffer.availablePallets"
                [size]="2" buttonLayout="horizontal"
                spinnerMode="horizontal"
                [step]="1"
                decrementButtonClass="p-button-outlined"
                incrementButtonClass="p-button-outlined"
                incrementButtonIcon="pi pi-plus"
                decrementButtonIcon="pi pi-minus"
                (onInput)="getTotalPriceOfferDialog(selected.exportOffer, $event)"
                class="p-inputtext-sm"></p-inputNumber>
            </div>
            <div class="field col-12 md:col-6 lg:col-6">
              <p-button label="{{'MarketplacePage.WishList.ExportOfferModal.PurchaseNowButton' | translate}}" (onClick)="createOrder()"></p-button>
            </div>
          </div>

          <form [formGroup]="negotiationFormGroup" style="margin-top: 30px;">
            <div class="grid">
              <div class="col-12">
                <h2>{{ 'UserPages.ViewImportRequestOffers.OfferDialog.NegotiationTitle' | translate }}</h2>
              </div>
              <div class="col-12">
                <div class="p-fluid formgrid grid flex align-items-start">
                  <div class="field col-12 md:col-6 lg:col-6">
                    <label for="negociation-price">{{ 'UserPages.ViewImportRequestOffers.OfferDialog.NegotiatedPriceLabel' | translate }}</label>
                    <p-inputNumber inputId="negociation-price" formControlName="negociationPrice" [min]="0"
                      mode="currency" currency="USD" locale="en-US" placeholder="{{'MarketplacePage.WishList.ExportOfferModal.NegotiationPriceFieldPlaceholder' | translate}}"
                      class="p-inputtext-sm"></p-inputNumber>
                    <small *ngIf="submitNegotiation && negotiationFormGroup.controls['negociationPrice'].invalid"
                      class="p-error">{{getErrorMessage('negociationPrice')}}</small>
                  </div>
                  <div class="field col-12 md:col-6 lg:col-6">
                    <label for="negotiation-pallets">{{'MarketplacePage.WishList.ExportOfferModal.PalletsFieldLabel' | translate}}</label>
                    <p-inputNumber inputId="negotiation-pallets" formControlName="negociationPallets" [min]="1"
                      [max]="selected.exportOffer.availablePallets" class="p-inputtext-sm"></p-inputNumber>
                    <small *ngIf="submitNegotiation && negotiationFormGroup.controls['negociationPallets'].invalid"
                      class="p-error">{{getErrorMessage('negociationPallets')}}</small>
                  </div>
                  <div class="field col-12 md:col-6 lg:col-6">
                    <label for="negociation-incoterm">{{'MarketplacePage.WishList.ExportOfferModal.SellModeFieldLabel' | translate}}</label>
                    <p-dropdown inputId="negociation-incoterm" formControlName="negociationIncoterm"
                      [options]="incotermsOptions" optionLabel="label" optionValue="value"
                      placeholder="{{'MarketplacePage.WishList.ExportOfferModal.SellModeFieldPlaceholder' | translate}}" appendTo="body" class="p-inputtext-sm"></p-dropdown>
                    <small *ngIf="submitNegotiation && negotiationFormGroup.controls['negociationIncoterm'].invalid"
                      class="p-error">{{getErrorMessage('negociationIncoterm')}}</small>
                  </div>
                  <div class="field col-12 md:col-6 lg:col-6">
                    <label for="negociation-paymentAgainstBL" style="font-size: calc(100% - 4px);">{{ paymentBLTitle | translate }} *</label>
                    <p-dropdown id="negociation-paymentAgainstBL" formControlName="negotiationPaymentAgainstBL" [options]="paymentBLItems" optionLabel="label" optionValue="value" placeholder="{{'UserPages.UserBargaining.OpenTab.BargainingDetail.MakeCounterOfferModal.PaymentAgainstBLLabel' | translate}}" appendTo="body"></p-dropdown>
                    <small *ngIf="submitNegotiation && negotiationFormGroup.controls['negotiationPaymentAgainstBL'].invalid" class="p-error">{{getErrorMessage('negotiationPaymentAgainstBL')}}</small>
                    <small *ngIf="showPaymentBLPercent">{{'UserPages.UserExportOffers.NewExportOffer.FinalPaymentSubjectQCReview' | translate}} {{finalPaymentPercent}}%</small>
                  </div>
                  <div class="field col-12 md:col-12 lg:col-12">
                    <label>&nbsp;</label>
                    <p-button label="{{'MarketplacePage.WishList.ExportOfferModal.NegotiationFieldSetLegend' | translate}}" (onClick)="createNegotiation()" [style]="{'background-color': '#8c2b87'}"></p-button>
                    <label *ngIf="submitNegotiation && !negotiationFormGroup.valid">&nbsp;</label>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </p-card>
      </div>
    </div>
    <app-export-offer-detail-description [offer]="selected.exportOffer"></app-export-offer-detail-description>
  </div>
  <ng-template pTemplate="footer">
    <p-button label="{{'MarketplacePage.WishList.ExportOfferModal.CloseButton' | translate}}" (onClick)="closeOfferDetailDialog()"></p-button>
  </ng-template>
</p-dialog>
