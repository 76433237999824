<app-user-dashboard [userMenuActiveItemIndex]="0" [activeItem]="'marketplace'">
  <p-card>
    <div *ngIf="exportOffer">
      <h2>{{'FrozenFruitExportOfferForm.EditSubtitle3' | translate}}</h2>
      <div class="col-12">
        <p-table [value]="pictures">
          <ng-template pTemplate="body" let-picture let-rowIndex="rowIndex">
            <tr>
              <td>
                <p-fileUpload
                  #imagesUploader
                  mode="basic"
                  chooseLabel="{{'UserPages.UserExportOffers.EditExportOffer.UploadButton' | translate}}"
                  [fileLimit]="1"
                  [auto]="true"
                  accept=".jpg, .jpeg, .png"
                  [maxFileSize]="3000000"
                  (onSelect)="onSelectImage($event, imagesUploader, rowIndex)"
                  [disabled]="picture.url"></p-fileUpload>
              </td>
              <td>
                <img [src]="getImageUrl(picture)" style="border-radius: 7px; width: 100%; max-width: 100px; height: auto;" alt="" *ngIf="getImageUrl(picture)"/>
              </td>
              <td>
                <span *ngIf="getImageName(picture)">{{getImageName(picture)}}</span>
              </td>
              <td>
                <div class="flex justify-content-end align-items-center gap-4">
                  <p-button icon="pi pi-times" (click)="onRemoveImage(rowIndex)" *ngIf="picture.url"></p-button>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>

      <div class="grid" style="margin-top: 40px;">
        <div class="col-12 md:col-6 lg:6"></div>
        <div class="col-12 md:col-6 lg:6">
          <div class="p-fluid formgrid grid">
            <div class="col-6">
              <p-button label="{{'FrozenFruitExportOfferForm.BackButtonLabel' | translate}}" [disabled]="loading" styleClass="p-button-outlined" (onClick)="goToFrozenFruitExportOffersPage()"></p-button>
            </div>
            <div class="col-6">
              <p-button label="{{'FrozenFruitExportOfferForm.SaveButtonLabel' | translate}}" [loading]="loading" (onClick)="savePictures()"></p-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </p-card>
</app-user-dashboard>

<p-toast></p-toast>
