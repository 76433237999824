import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { NotifierGateway } from 'src/app/application/gateway/NotifierGateway';
import { Notifier } from 'src/app/domain/models/Notifier';
import { Routes } from 'src/app/infrastructure/routes';

@Component({
  selector: 'app-user-notifies',
  templateUrl: './user-notifies.component.html',
  styleUrls: ['./user-notifies.component.css'],
  providers: [ConfirmationService, MessageService]
})
export class UserNotifiesComponent implements OnInit {
  notifiers: Notifier[] = []
  selected: Notifier | null = null
  loading: boolean = true

  constructor(private _router: Router,
    private _messageService: MessageService,
    private _confirmationService: ConfirmationService,
    private _authService: AuthGateway,
    private _notifierService: NotifierGateway,
    public translate: TranslateService) {

  }

  ngOnInit(): void {
    this.getAll()
  }

  getAll() {
    this.loading = true
    this._notifierService.getAllByUsername(this._authService.getUsername()).subscribe({
      next: (v) => {
        this.notifiers = v
        this.loading = false
      },
      error: (e) => {
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Notify.ManyRetrieveError') });
        console.log(e)
        this.loading = false
      },
      complete: () => console.info('Notifies query successfully')
    })
  }

  goToCreateForm() {
    this._router.navigate([Routes.notifierForm, 0])
  }

  goToEditForm(id: number) {
    this._router.navigate([Routes.notifierForm, id])
  }

  onDelete(id: number) {
    this._confirmationService.confirm({
      message: this.translate.instant('MessageService.Notify.DeleteMessage'),
      header: this.translate.instant('MessageService.Notify.DeleteHeader'),
      icon: 'pi pi-info-circle',
      accept: () => {
        this._notifierService.delete(id).subscribe({
          next: (v) => {
            this.selected = null
            this._messageService.add({ severity: 'info',
              summary: this.translate.instant('MessageService.Summary.SuccessAction'),
              detail: this.translate.instant('MessageService.Notify.DeleteSuccess') })
            this.getAll()
          },
          error: (e) => {
            console.log(e)
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.Notify.DeleteError') });
          },
          complete: () => console.info('Notify delete successfully')
        })
      },
      reject: (type: any) => {
          switch (type) {
              case ConfirmEventType.REJECT:
                  //this._messageService.add({ severity: 'error', summary: 'Rejected', detail: 'Se canceló la eliminación' })
                  break;
              case ConfirmEventType.CANCEL:
                  //this._messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Se canceló la eliminación' })
                  break;
          }
      }
    })
  }
}
