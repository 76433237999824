import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  public intercept(request: HttpRequest<any>, newRequest: HttpHandler): Observable<HttpEvent<any>> {
    if(request.url.startsWith('/')) {
      if(!request.url.startsWith('/assets/i18n/')) {
        request = request.clone({
          url: environment.apiUrl + request.url,
          setHeaders: {
            Authorization: `Bearer ${localStorage.getItem("_token_auth")}`
          }
        });
      }
    }
    return newRequest.handle(request);
  }
}
