import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { FridgeStorage, NewFridgeStorage } from "src/app/domain/models/FridgeStorage";
import { Routes } from "src/app/infrastructure/routes";
import { FridgeStorageGateway } from "../gateway/FridgeStorageGateway";

@Injectable({providedIn: 'root'})
export class FridgeStorageService extends FridgeStorageGateway {

  constructor(private _http: HttpClient) {
    super()
  }

  getAll(): Observable<FridgeStorage[]> {
    return this._http.get<FridgeStorage[]>(Routes.fridgestorageApi + '/GetAll')
  }

  getAllAvailable(): Observable<FridgeStorage[]> {
    return this._http.get<FridgeStorage[]>(Routes.fridgestorageApi + '/GetAllAvailable')
  }

  getById(fridgeStorageId: number): Observable<FridgeStorage> {
    return this._http.get<FridgeStorage>(Routes.fridgestorageApi + '/GetById', { params: {fridgeStorageId}})
  }

  create(fridgestorage: NewFridgeStorage): Observable<FridgeStorage> {
    return this._http.post<FridgeStorage>(Routes.fridgestorageApi + '/Create', fridgestorage)
  }

  update(fridgestorage: FridgeStorage): Observable<FridgeStorage> {
    return this._http.put<FridgeStorage>(Routes.fridgestorageApi + '/Update', fridgestorage)
  }

  delete(fridgeStorageId: number): Observable<FridgeStorage> {
    return this._http.delete<FridgeStorage>(Routes.fridgestorageApi + '/Delete', { params: {fridgeStorageId}})
  }

  getAllByStorageType(storageType: any): Observable<FridgeStorage[]> {
    return this._http.get<FridgeStorage[]>(Routes.fridgestorageApi + '/GetAllByStorageType', { params: {storageType}})
  }
}
