import { AfterContentInit, AfterViewInit, Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Tooltip } from 'primeng/tooltip';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { ExportOfferGateway } from 'src/app/application/gateway/ExportOfferGateway';
import { FrozenFruitExportOfferGateway } from 'src/app/application/gateway/FrozenFruitExportOfferGateway';
import { ImportOrderGateway } from 'src/app/application/gateway/ImportOrderGateway';
import { PalletGateway } from 'src/app/application/gateway/PalletGateway';
import { Delivery } from 'src/app/domain/models/Delivery';
import { DeliveryDiscount } from 'src/app/domain/models/DeliveryDiscount';
import { ExportOffer } from 'src/app/domain/models/ExportOffer';
import { ForwarderReviewItemList } from 'src/app/domain/models/ForwarderReview';
import { FrozenFruitExportOffer } from 'src/app/domain/models/FrozenFruitExportOffer';
import { GenericOrderDetail, ImportOrderDetail, ImportOrderInformation } from 'src/app/domain/models/ImportOrder';
import { OperationInformation } from 'src/app/domain/models/Operation';
import { OperationDocument } from 'src/app/domain/models/OperationDocument';
import { Packaging } from 'src/app/domain/models/Packaging';
import { PalletPrice } from 'src/app/domain/models/Pallet';
import { Payment } from 'src/app/domain/models/Payment';
import { ProductCertificateType } from 'src/app/domain/models/ProductCertificateType';
import { Exporter } from 'src/app/domain/models/Profile';
import { BasicUser } from 'src/app/domain/models/User';
import { Routes } from 'src/app/infrastructure/routes';
import { OrderUtils } from 'src/app/ui/util/order-utils';

@Component({
  selector: 'app-order-detail-component',
  templateUrl: './order-detail-component.component.html',
  styleUrls: ['./order-detail-component.component.css'],
  providers: [MessageService]
})
export class OrderDetailComponentComponent implements OnInit, AfterViewInit {

  @Input()
  order: ImportOrderInformation | undefined

  @Input()
  directSaleProgramId: any | undefined

  @Input()
  isClosedSale: boolean = false

  delivery: Delivery | undefined
  profile: BasicUser | undefined
  operationInformation: OperationInformation | undefined
  details: GenericOrderDetail[] = []
  documents: OperationDocument[] = []
  exportOffer: ExportOffer | undefined
  selectedProductCertificateTypes: ProductCertificateType[] = []
  selectedPackagingTypes: Packaging[] = []
  frozenFruitExportOffer: FrozenFruitExportOffer | undefined
  discounts: DeliveryDiscount[] = []
  payments: Payment[] = []

  visibleExportOfferDialog: boolean = false
  visibleExporterDialog: boolean = false
  visibleCancelDialog: boolean = false
  loading: boolean = false

  visibleCurrentPalletsPrice: boolean = false
  cancelPurchaseAvailable: boolean = false

  currentPrices: PalletPrice[] = []

  palletsPrice: PalletPrice[] = []

  showPartyNotification: boolean = false;
  showLoadingNotification: boolean = false;
  showDocumentNotification: boolean = false;
  showReportsNotification: boolean = false;
  showPaymentsNotification: boolean = false;

  exporterShow: Exporter | undefined



  constructor(private _router: Router,
    private _authService: AuthGateway,
    private _messageService: MessageService,
    public translate: TranslateService,
    private _exportOfferService: ExportOfferGateway,
    private _frozenFruitExportOfferService: FrozenFruitExportOfferGateway,
    private _palletService: PalletGateway,
    private _importOrderService: ImportOrderGateway
  ) {
  }

  ngOnInit(): void {
    this.loadInfo()

  }

  ngAfterViewInit(): void {
      // this.showAll()
  }

  loadInfo() {
    if(this.order) {
      this.profile = this._authService.getUser()
      this.details = this.order.genericDetails
      this.delivery = this.order.delivery
      this.discounts = this.order.discounts ? this.order.discounts : []
      this.payments = this.order.payments ? this.order.payments : []
      this.operationInformation = this.order.operation
      this.getPriceConfiguration(this.details[0])
      this.validateCancelPurchase()
      if(this.operationInformation) {
        this.documents = this.operationInformation.documents
      }
    }
  }

  get exporterDetails(): ImportOrderDetail[] {
    if(this.order && this.profile?.type == 1) {
      return this.order.details.filter(x => x.exportUsername == this._authService.getUsername())
    }
    else if(this.order && this.profile?.type == 2) {
      return this.order.details
    }
    return []
  }

  getOrderTitle(): string {
    return OrderUtils.getOrderTitle(this.order?.importOrder.sequence)
  }

  padWithLeadingZeros(id: number): string {
    return OrderUtils.padWithLeadingZeros(id)
  }

  getDetailTotalPrice(detail: GenericOrderDetail): number {
    if(detail.palletsQuantity > 0 && detail.boxesPerPallet > 0) {
      return detail.palletsQuantity * detail.boxesPerPallet * detail.price
    }
    else {
      return detail.boxesQuantity * detail.price
    }
  }

  showCurrentPalletPriceModal() {
    if(this.isClosedSale){
      // this.calculateTablePrice()
      this.visibleCurrentPalletsPrice = true
    }

  }

  get orderTotalPrice(): number {
    let total = 0
    this.details.forEach(x => {
      total += this.getDetailTotalPrice(x)
    })
    return total
  }

  get PalletPriceTotal(): number {
    let total = 0
    this.details.forEach(x => {
      total += this.getPalletPrice(x)
    })
    return total
  }

  confirmPurchase() {
    if(this.order) {
      this._router.navigate(['/' + Routes.userConfirmPurchasePage, this.order.importOrder.guid])
    }
  }



  goToPayments() {
    if(this.delivery) {
      if(this.isClosedSale) {
        this._router.navigate(['/' + Routes.userClosedSalePaymentsPage, this.order?.importOrder.guid, this.directSaleProgramId])
      }
      else
      {
        this._router.navigate(['/' + Routes.userPaymentsPage, this.order?.importOrder.guid])
      }
    }
  }

  goToVerifyPalletsPage(importOrderDetail: GenericOrderDetail) {
    if(this.isClosedSale) {
      this._router.navigate(['/' + Routes.userClosedSaleExporterVerifyPallets, importOrderDetail.id, this.directSaleProgramId])
    }
    else {
      this._router.navigate(['/' + Routes.verifyPalletsPage, importOrderDetail.id])
    }
  }

  goToFrozenFruitVerifyPalletsPage(importOrderDetail: GenericOrderDetail) {
    this._router.navigate(['' + Routes.userFrozenFruitVerifyPalletsPage, importOrderDetail.id])
  }


  getPalletPrice(detail: GenericOrderDetail): number {
    let total = 0

    if(detail.palletPrices)
    {
      detail.palletPrices.forEach(x => {
        total += x.price ? x.boxPerPallets * x.price : 0;
      })
      return total
    }
    return 0
  }

  goToReviewDocuments(review: ForwarderReviewItemList) {
    if(review)
      this._router.navigate(['/' + Routes.forwarderUserOriginReview, review.guid])
  }

  goToDelivery(importOrderGuid: string) {
      this._router.navigate(['/' + Routes.forwarderUsersDelivery, importOrderGuid])
  }

  viewExportOfferDetailDialog(detail: GenericOrderDetail) {
    this.exportOffer = undefined
    this.frozenFruitExportOffer = undefined

    if(this.order && this.order.importOrder.isFrozenFruit) {
      this.loading = true
      this._frozenFruitExportOfferService.getById(detail.frozenFruitExportOfferId!).subscribe({
        next: (v) => {
          this.frozenFruitExportOffer = v
          this.loading = false
          this.visibleExportOfferDialog = true
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOffer.SingleRetrieveError') })
        },
        complete: () => console.info('Frozen fruit export offer query successfully')
      })
    }
    else {
      this.loading = true
      this._exportOfferService.getById(detail.exportOfferId!).subscribe({
        next: (v) => {
          this.exportOffer = v
          if(this.exportOffer.productCertificateTypes)
            this.selectedProductCertificateTypes = this.exportOffer.productCertificateTypes

          if(this.exportOffer.packagingTypes)
            this.selectedPackagingTypes = this.exportOffer.packagingTypes

          this.loading = false
          this.visibleExportOfferDialog = true
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOffer.SingleRetrieveError') })
        },
        complete: () => console.info('Frozen fruit export offer query successfully')
      })
    }
  }


  @ViewChild('panel') panel!: OverlayPanel;
  @ViewChild('palletTipButton') elm!: ElementRef;


  @ViewChildren(Tooltip) tooltips!: QueryList <Tooltip>;

  showAll(): void {
    this.tooltips.forEach(toolTip => toolTip.activate())
  }


  viewExporterInfo(exporter: Exporter)
  {
    this.exporterShow = exporter
    this.visibleExporterDialog = true
  }

  getSpeciesName(detail: GenericOrderDetail): string {
    if(this.profile) {
      switch(this.profile.lang) {
        case 'es':
          return detail.speciesNameEs
        case 'en':
          return detail.speciesNameEn
        default:
          return detail.speciesNameEn
      }
    }
    return ''
  }

  validateCancelPurchase()
  {
    if(this.details){
      this.details.forEach(element => {
          if(element.status >= 2){
            this.cancelPurchaseAvailable = true;
            return;
          }
      });
    }

  }

  getPriceConfiguration(detail: GenericOrderDetail)
  {
    this.palletsPrice = []
    if(detail)
      {
        this.loading = true
        this._palletService.getPalletPriceConfiguration(detail.id).subscribe({
          next: (v) => {
            this.currentPrices = v
            this.loading = false
          },
          error: (e) => {
            this.loading = false
            this._messageService.add({
              severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: e.message
            })
          }
        })

      }
  }

  cancelPurchaseOrder(){
    if(this.order){
      this.loading = true;
      this._importOrderService.cancelPurchase(this.order.importOrder.guid).subscribe({
        next: (v) => {
          this.loading = false
          this._messageService.add({
            severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: this.translate.instant('MessageService.ImportOrder.CancelPurchaseSuccess')
          })

          this._router.navigate(['/' + Routes.marketplacePage])
        },
        error: (e) => {
          this.loading = false
          this._messageService.add({
            severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ImportOrder.CancelPurchaseError')
          })
        }
      })
    }
  }

  getDetailTotalCases(detail: GenericOrderDetail): number {
    return detail.palletsQuantity > 0 && detail.boxesPerPallet > 0 ? detail.palletsQuantity * detail.boxesPerPallet : detail.boxesQuantity
  }

  getTotalPalletsConfiguration() {
    let totalPallets = 0;
    this.currentPrices.forEach(x => {
      totalPallets += x.boxPerPallets;
    })

    return totalPallets;
  }

  getDetailPalletsQuantity(detail: GenericOrderDetail): number {
    if(this.order?.importOrder.isFrozenFruit) {
      return 20
    }
    else {
      return detail.palletsQuantity
    }
  }

  get showPalletsInfo(): boolean {
    if (this.profile?.profileType !== 'Importer') return true

    return this.payments.some(item => item.isApproved === true);
  }
}
