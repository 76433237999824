import { HttpClient, HttpHeaderResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AddOrderClosedSaleRequest, BasicExportOffer, ClosedSaleCreateRequest, DirectSaleCreateRequest, EditDirectSale, ExportCloseSaleFilter, ExportOffer, ExportOfferAd, ExportOfferAddtionalInfo, ExportOfferCreateRequest, ExportOfferInformation, ExportOfferListItem, MarketplaceResult, NewExportOffer, OfferPicture, UpdatePicturesAndCertificateTypesRequest, ValidateDirectSale } from "src/app/domain/models/ExportOffer";
import { ProductCertificateType } from "src/app/domain/models/ProductCertificateType";
import { Routes } from "src/app/infrastructure/routes";
import { ExportOfferGateway } from "../gateway/ExportOfferGateway";
import { DirectSaleProgram } from "src/app/domain/models/DirectSaleProgram";
import { Country } from "src/app/domain/models/Country";

@Injectable({providedIn: 'root'})
export class ExportOfferService extends ExportOfferGateway {

  constructor(private _http: HttpClient) {
    super()
  }

  getAll(): Observable<ExportOfferInformation[]> {
    return this._http.get<ExportOfferInformation[]>(Routes.exportOfferApi + '/GetAll')
  }

  getAllByUsername(): Observable<ExportOfferListItem[]> {
    return this._http.get<ExportOfferListItem[]>(Routes.exportOfferApi + '/GetAllByUsername')
  }

  create(offer: NewExportOffer): Observable<ExportOffer> {
    return this._http.post<ExportOffer>(Routes.exportOfferApi + '/Create', this.getFormDataNewExportOffer(offer))
  }

  getById(exportOfferId: number): Observable<ExportOffer> {
    return this._http.get<ExportOffer>(Routes.exportOfferApi + '/GetById', {params: {exportOfferId}})
  }

  update(offer: ExportOffer): Observable<ExportOffer> {
    return this._http.put<ExportOffer>(Routes.exportOfferApi + '/Update', this.getFormData(offer))
  }

  updateAdmin(offer: ExportOffer): Observable<ExportOffer> {
    return this._http.put<ExportOffer>(Routes.exportOfferApi + '/UpdateAdmin', this.getFormData(offer))
  }

  duplicate(exportOfferId: number): Observable<ExportOffer> {
    return this._http.post<ExportOffer>(Routes.exportOfferApi + '/Duplicate', {} , {params: {exportOfferId}})
  }

  desactive(importOrderId: number): Observable<any> {
    return this._http.put<ExportOffer>(Routes.exportOfferApi + '/Desactive', {}, {params: {importOrderId}})
  }

  active(importOrderId: number): Observable<any> {
    return this._http.put<ExportOffer>(Routes.exportOfferApi + '/Active', {}, {params: {importOrderId}})
  }

  getAllForMarketplace(countryId?: number): Observable<MarketplaceResult> {
    let param = countryId ? countryId : 0
    return this._http.get<MarketplaceResult>(Routes.exportOfferApi + '/GetAllForMarketplace', {params: {countryId: param}})
  }

  getSuggestionList(importOrderDetailId: any, countryId: number, varietyId: number): Observable<ExportOfferInformation[]> {
    return this._http.get<ExportOfferInformation[]>(Routes.exportOfferApi + '/GetSuggestionList', {params: {importOrderDetailId, countryId, varietyId}})
  }

  createClosedSale(request: ClosedSaleCreateRequest): Observable<ExportOffer> {
    return this._http.post<ExportOffer>(Routes.exportOfferApi + '/CreateClosedSale', this.getCloseSaleFormData(request))
  }

  addOrderClosedSale(request: AddOrderClosedSaleRequest): Observable<ExportOffer> {
    return this._http.post<ExportOffer>(Routes.exportOfferApi + '/AddOrderClosedSale', request)
  }

  getAllClosedSaleByExporter(exporterUsername: string): Observable<ExportOfferInformation[]> {
    return this._http.get<ExportOfferInformation[]>(Routes.exportOfferApi + '/GetAllClosedSaleByExporter', {params: {exporterUsername}})
  }

  getOfferDetailMarketplace(exportOfferGuid: string): Observable<ExportOfferInformation> {
    return this._http.get<ExportOfferInformation>(Routes.exportOfferApi + '/GetOfferDetailMarketplace', {params: {exportOfferGuid}})
  }

  updatePicturesAndCertificateTypes(request: UpdatePicturesAndCertificateTypesRequest): Observable<any> {
    const formData = new FormData()
    formData.append("exportOfferId", request.exportOfferId.toString())
    if(request.pictures) {
      request.pictures.forEach((p: any) => {
        formData.append(`pictures`, p)
      })
    }
    if(request.certificateTypeIds) {
      formData.append("otherCertificateType", request.otherCertificateType ? request.otherCertificateType : '')
      request.certificateTypeIds.forEach(x => {
        formData.append("certificateTypeIds", x.toString())
      })
    }
    return this._http.put<ExportOffer>(Routes.exportOfferApi + '/UpdatePicturesAndCertificateTypes', formData)
  }

  getAllPicturesById(id: number): Observable<OfferPicture[]> {
    return this._http.get<OfferPicture[]>(Routes.exportOfferApi + '/GetAllPicturesById', {params: {id}})
  }

  createBasicExportOffer(request: ExportOfferCreateRequest): Observable<number> {
    return this._http.post<number>(Routes.exportOfferApi + '/CreateBasicExportOffer', request)
  }

  getProductCertificateTypesByExportOfferId(exportOfferId: number): Observable<ProductCertificateType[]> {
    return this._http.get<ProductCertificateType[]>(Routes.exportOfferApi + '/GetProductCertificateTypesByExportOfferId', {params: {exportOfferId}})
  }

  updateBasicExportOffer(basicExportOffer: BasicExportOffer): Observable<any> {
    return this._http.put<any>(Routes.exportOfferApi + '/UpdateBasicExportOffer', basicExportOffer)
  }

  getBasicExportOffer(exportOfferId: any): Observable<BasicExportOffer> {
    return this._http.get<BasicExportOffer>(Routes.exportOfferApi + '/GetBasicExportOffer', {params: {exportOfferId}})
  }

  getExportOfferAddtionalInfo(exportOfferId: any): Observable<ExportOfferAddtionalInfo> {
    return this._http.get<ExportOfferAddtionalInfo>(Routes.exportOfferApi + '/GetExportOfferAddtionalInfoDto', {params: {exportOfferId}})
  }

  updateExportOfferAdditionalInfo(exportOfferAdditionalInfo: ExportOfferAddtionalInfo): Observable<any> {
    return this._http.put<any>(Routes.exportOfferApi + '/UpdateExportOfferAdditionalInfo', this.getFormDataAdditionalExportOffer(exportOfferAdditionalInfo))
  }

  createBasicDirectSale(request: DirectSaleCreateRequest): Observable<any> {
    return this._http.post<any>(Routes.exportOfferApi + '/CreateBasicDirectSale', request)
  }

  getAllClosedSaleByExporterFilter(exportCloseSaleFilter: ExportCloseSaleFilter): Observable<ExportOfferInformation[]>
  {
    return this._http.post<ExportOfferInformation[]>(
      Routes.exportOfferApi + '/GetAllClosedSaleByExporterFilter',
      this.getFormDataCloseSaleForm(exportCloseSaleFilter)
    )
  }

  GetAllExportOfferToCloseSale(exporterUsername: string): Observable<ExportOfferInformation[]>
  {
    return this._http.get<ExportOfferInformation[]>(Routes.exportOfferApi + '/GetAllExportOfferToCloseSale', {params: {exporterUsername}})
  }

  editDirectProgramSale(request: EditDirectSale)
  {
    return this._http.put<DirectSaleProgram>(Routes.exportOfferApi + '/EditBasicDirectSale', request)
  }

  validateEditDirectProgramSale(request: ValidateDirectSale): Observable<null>{
    return this._http.put<null>(Routes.exportOfferApi + '/ValidateEditBasicDirectSale', request)
  }

  getOfferDetail(adsCode: string ): Observable<ExportOfferAd[]>
  {
    if(adsCode){
    return this._http.get<ExportOfferAd[]>(

        Routes.exportOfferApi + '/GetAllForMarketPlaceAds' ,{params:{adsCode}}
      )
    } else {
      return this._http.get<ExportOfferAd[]>(

          Routes.exportOfferApi + '/GetAllForMarketPlaceAds'
        )

    }
  }

  getAllForMarketPlaceAds(): Observable<ExportOfferAd[]>
  {
    return this._http.get<ExportOfferAd[]>(

        Routes.exportOfferApi + '/GetAllForMarketPlaceAds'
      )
  }

  getAdsMarketplaceResult(offerAdsCode: string ): Observable<MarketplaceResult>
  {
    return this._http.get<MarketplaceResult>(
      Routes.exportOfferApi + '/GetAdsMarketplaceResult' ,{params:{offerAdsCode}}
    )
  }

  getCountriesAvailable(): Observable<Country[]>
  {
    return this._http.get<Country[]>(
      Routes.exportOfferApi + '/GetCountriesAvailable'
    )
  }

  getAdsOfferDetail(adsCode: string ): Observable<ExportOfferAd> {
    return this._http.get<ExportOfferAd>(
      Routes.exportOfferApi + '/GetAdsOffer', {params:{adsCode}}
    )
  }

  getOfferAdsCodeQrUrl(code: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type':  'image/png'
    })

    return this._http.get<any>(Routes.exportOfferApi + '/GetExportOfferQRcode', 
      {params: {code, qrUrlType: 0}, headers: headers, observe: 'response', responseType: 'blob' as 'json'})
  }

  getOfferMarketplaceCodeQrUrl(code: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type':  'image/png'
    })

    return this._http.get<any>(Routes.exportOfferApi + '/GetExportOfferQRcode', 
      {params: {code, qrUrlType: 1}, headers: headers, observe: 'response', responseType: 'blob' as 'json'})
  }

  getOfferImportRequestCodeQrUrl(code: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type':  'image/png'
    })

    return this._http.get<any>(Routes.exportOfferApi + '/GetExportOfferQRcode', 
      {params: {code, qrUrlType: 2}, headers: headers, observe: 'response', responseType: 'blob' as 'json'})
  }

  getFormData(offer: ExportOffer) {
    const formData = new FormData();
    if(offer) {
      formData.append("id", offer.id ? offer.id.toString() : '')
      formData.append("exporterUsername", offer.exporterUsername ? offer.exporterUsername : '')
      formData.append("varietyId", offer.varietyId ? offer.varietyId.toString() : '')
      formData.append("palletQuantity", offer.palletQuantity ? offer.palletQuantity.toString() : '0')
      formData.append("availablePallets", offer.availablePallets ? offer.availablePallets.toString() : '0')
      formData.append("fridgeStorageId", offer.fridgeStorageId ? offer.fridgeStorageId.toString() : '')
      formData.append("color", offer.color ? offer.color : '')
      formData.append("paymentAgainstBL", offer.paymentAgainstBL ? offer.paymentAgainstBL.toString() : '')

      if(offer.harvestDate) {
        if(offer.harvestDate instanceof Date)
          offer.harvestDate.toDateString()

        formData.append("harvestDate", new Date(offer.harvestDate).toDateString())
      }
      else {
        formData.append("harvestDate", '')
      }

      if(offer.readyToLoadDate) {
        if(offer.readyToLoadDate instanceof Date)
          offer.readyToLoadDate.toDateString()

        formData.append("readyToLoadDate", new Date(offer.readyToLoadDate).toDateString())
      }
      else {
        formData.append("readyToLoadDate", '')
      }

      if(offer.qualityControlDate) {
        if(offer.qualityControlDate instanceof Date) offer.qualityControlDate.toDateString()

        formData.append("qualityControlDate", new Date(offer.qualityControlDate).toDateString())
      }
      else {
        formData.append("qualityControlDate", '')
      }

      formData.append("boxType", offer.boxType ? offer.boxType : '')
      formData.append("kilosPerBox", offer.kilosPerBox ? offer.kilosPerBox : '')
      formData.append("netKilos", offer.netKilos ? offer.netKilos : '')
      formData.append("grossKilos", offer.grossKilos ? offer.grossKilos : '')
      formData.append("caseQuantityPerPallet", offer.caseQuantityPerPallet ? offer.caseQuantityPerPallet.toString() : '')
      formData.append("isQualityControl", offer.isQualityControl ? offer.isQualityControl.toString() : 'false')
      formData.append("shelfLife", offer.shelfLife ? offer.shelfLife.toString() : '')
      formData.append("countryId", offer.countryId ? offer.countryId.toString() : '')
      formData.append("speciesSize", offer.speciesSize ? offer.speciesSize.toString() : '')
      formData.append("listPrice", offer.listPrice ? offer.listPrice.toLocaleString("en-US") : '0')
      formData.append("basePallets", offer.basePallets ? offer.basePallets : '')
      formData.append("marks", offer.marks ? offer.marks.toString() : 'false')
      formData.append("typeSale", offer.typeSale ? offer.typeSale : '')
      formData.append("isClosedSale", 'false')
      formData.append("marketCountryFirstId", offer.marketCountryFirstId ? offer.marketCountryFirstId.toString() : '')
      formData.append("marketCountrySecondId", offer.marketCountrySecondId ? offer.marketCountrySecondId.toString() : '')
      formData.append("marketCountryThirdId", offer.marketCountryThirdId ? offer.marketCountryThirdId.toString() : '')
      formData.append("sizeMillimeters", offer.sizeMillimeters ? offer.sizeMillimeters.toLocaleString("en-US") : '0')
      formData.append("category", offer.category ? offer.category : '')
      formData.append("caseSize", offer.caseSize ? offer.caseSize: '')
      formData.append("exporterId", offer.exporterId.toString())

      if(offer.pictures) {
        offer.pictures.forEach((p: any) => {
          formData.append("pictures", p)
        })
      }

      if(offer.certificates) {
        offer.certificates.forEach(c => {
          formData.append("certificates", c.id.toString())
        })
      }

      if(offer.removedPictures) {
        offer.removedPictures?.forEach(p => {
          formData.append("removedPictures", p.toString())
        })
      }

      if(offer.removedCertificates) {
        offer.removedCertificates?.forEach(c => {
          formData.append("removedCertificates", c.id.toString())
        })
      }
    }
    return formData
  }

  getFormDataNewExportOffer(offer: NewExportOffer) {
    const formData = new FormData();
    if(offer) {
      formData.append("exporterUsername", offer.exporterUsername ? offer.exporterUsername : '')
      formData.append("varietyId", offer.varietyId ? offer.varietyId.toString() : '')
      formData.append("palletQuantity", offer.palletQuantity ? offer.palletQuantity.toString() : '')
      formData.append("availablePallets", offer.availablePallets ? offer.availablePallets.toString() : '')
      formData.append("fridgeStorageId", offer.fridgeStorageId ? offer.fridgeStorageId.toString() : '')
      formData.append("color", offer.color ? offer.color : '')
      formData.append("harvestDate", offer.harvestDate ? offer.harvestDate.toDateString() : '')
      formData.append("readyToLoadDate", offer.readyToLoadDate ? offer.readyToLoadDate.toDateString() : '')
      formData.append("boxType", offer.boxType ? offer.boxType : '')
      formData.append("kilosPerBox", offer.kilosPerBox ? offer.kilosPerBox : '')
      formData.append("netKilos", offer.netKilos ? offer.netKilos : '')
      formData.append("grossKilos", offer.grossKilos ? offer.grossKilos : '')
      formData.append("caseQuantityPerPallet", offer.caseQuantityPerPallet ? offer.caseQuantityPerPallet.toString() : '')
      formData.append("isQualityControl", offer.isQualityControl ? offer.isQualityControl.toString() : 'false')
      formData.append("shelfLife", offer.shelfLife ? offer.shelfLife.toString() : '')
      formData.append("countryId", offer.countryId ? offer.countryId.toString() : '')
      formData.append("speciesSize", offer.speciesSize ? offer.speciesSize.toString() : '')
      formData.append("listPrice", offer.listPrice ? offer.listPrice.toLocaleString("en-US") : '0')
      formData.append("basePallets", offer.basePallets ? offer.basePallets : '')
      formData.append("marks", offer.marks ? offer.marks.toString() : 'false')
      formData.append("typeSale", offer.typeSale ? offer.typeSale : '')
      formData.append("isClosedSale", 'false')
      formData.append("marketCountryFirstId", offer.marketCountryFirstId ? offer.marketCountryFirstId.toString() : '')
      formData.append("marketCountrySecondId", offer.marketCountrySecondId ? offer.marketCountrySecondId.toString() : '')
      formData.append("marketCountryThirdId", offer.marketCountryThirdId ? offer.marketCountryThirdId.toString() : '')
      formData.append("sizeMillimeters", offer.sizeMillimeters ? offer.sizeMillimeters.toLocaleString("en-US") : '0')
      formData.append("category", offer.category ? offer.category : '')
      formData.append("qualityControlDate", offer.qualityControlDate ? offer.qualityControlDate.toDateString() : '')
      formData.append("caseSize", offer.caseSize ? offer.caseSize: '')

      if(offer.pictures) {
        offer.pictures.forEach((p: any) => {
          formData.append("pictures", p)
        })
      }

      if(offer.certificates) {
        offer.certificates.forEach(c => {
          formData.append("certificates", c.id.toString())
        })
      }

      if(offer.productCertificates) {
        offer.productCertificates.forEach((x, index) => {
          formData.append(`productCertificates[${index}].name`, x.name.trim())
          formData.append(`productCertificates[${index}].username`, x.username)
          formData.append(`productCertificates[${index}].productCertificateTypeId`, x.productCertificateTypeId.toString())
          formData.append(`productCertificates[${index}].documentIssueDate`, x.documentIssueDate ? x.documentIssueDate.toDateString() : '')
          formData.append(`productCertificates[${index}].expirationDate`, x.expirationDate ? x.expirationDate.toDateString() : '')
          formData.append(`productCertificates[${index}].pdf`, x.pdf)
        })
      }

      if(offer.documents) {
        offer.documents.forEach((x, index) => {
          formData.append(`documents[${index}].name`, x.name.trim())
          formData.append(`documents[${index}].pdf`, x.pdf)
        })
      }
    }
    return formData
  }

  getCloseSaleFormData(request: ClosedSaleCreateRequest) {
    const formData = new FormData()
    if(request) {
      formData.append("ExportOffer.ExporterUsername", request.exportOffer.exporterUsername ? request.exportOffer.exporterUsername : '')
      formData.append("ExportOffer.VarietyId", request.exportOffer.varietyId ? request.exportOffer.varietyId.toString() : '')
      formData.append("ExportOffer.PalletQuantity", request.exportOffer.palletQuantity ? request.exportOffer.palletQuantity.toString() : '0')
      formData.append("ExportOffer.AvailablePallets", request.exportOffer.availablePallets ? request.exportOffer.availablePallets.toString() : '')
      formData.append("ExportOffer.FridgeStorageId", request.exportOffer.fridgeStorageId ? request.exportOffer.fridgeStorageId.toString() : '')
      formData.append("ExportOffer.Color", request.exportOffer.color ? request.exportOffer.color : '')
      formData.append("ExportOffer.HarvestDate", request.exportOffer.harvestDate ? request.exportOffer.harvestDate.toDateString() : '')
      formData.append("ExportOffer.ReadyToLoadDate", request.exportOffer.readyToLoadDate ? request.exportOffer.readyToLoadDate.toDateString() : '')
      formData.append("ExportOffer.BoxType", request.exportOffer.boxType ? request.exportOffer.boxType : '')
      formData.append("ExportOffer.KilosPerBox", request.exportOffer.kilosPerBox ? request.exportOffer.kilosPerBox : '')
      formData.append("ExportOffer.NetKilos", request.exportOffer.netKilos ? request.exportOffer.netKilos : '')
      formData.append("ExportOffer.GrossKilos", request.exportOffer.grossKilos ? request.exportOffer.grossKilos : '')
      formData.append("ExportOffer.CaseQuantityPerPallet", request.exportOffer.caseQuantityPerPallet ? request.exportOffer.caseQuantityPerPallet.toString() : '')
      formData.append("ExportOffer.IsQualityControl", request.exportOffer.isQualityControl ? request.exportOffer.isQualityControl.toString() : 'false')
      formData.append("ExportOffer.ShelfLife", request.exportOffer.shelfLife ? request.exportOffer.shelfLife.toString() : '')
      formData.append("ExportOffer.CountryId", request.exportOffer.countryId ? request.exportOffer.countryId.toString() : '')
      formData.append("ExportOffer.SpeciesSize", request.exportOffer.speciesSize ? request.exportOffer.speciesSize.toString() : '')
      formData.append("ExportOffer.ListPrice", request.exportOffer.listPrice ? request.exportOffer.listPrice.toLocaleString("en-US") : '0')
      formData.append("ExportOffer.BasePallets", request.exportOffer.basePallets ? request.exportOffer.basePallets : '')
      formData.append("ExportOffer.Marks", request.exportOffer.marks ? request.exportOffer.marks.toString() : 'false')
      formData.append("ExportOffer.TypeSale", request.exportOffer.typeSale ? request.exportOffer.typeSale : '')
      formData.append("ExportOffer.IsClosedSale", 'true')
      formData.append("ExportOffer.ClosedSaleStartWeek", request.exportOffer.closedSaleStartWeek ? request.exportOffer.closedSaleStartWeek.toString() : '0')
      formData.append("ExportOffer.ClosedSaleStartWeekYear", request.exportOffer.closedSaleStartWeekYear ? request.exportOffer.closedSaleStartWeekYear.toString() : '0')
      formData.append("ExportOffer.ClosedSaleEndWeek", request.exportOffer.closedSaleEndWeek ? request.exportOffer.closedSaleEndWeek.toString() : '0')
      formData.append("ExportOffer.ClosedSaleEndWeekYear", request.exportOffer.closedSaleEndWeekYear ? request.exportOffer.closedSaleEndWeekYear.toString() : '0')
      formData.append("ExportOffer.SizeMillimeters", request.exportOffer.sizeMillimeters ? request.exportOffer.sizeMillimeters.toLocaleString("en-US") : '0')
      formData.append("ExportOffer.Category", request.exportOffer.category ? request.exportOffer.category : '')
      formData.append("ExportOffer.CaseSize", request.exportOffer.caseSize ? request.exportOffer.caseSize : '')
      formData.append("ExportOffer.PaymentAgainstBL", request.exportOffer.paymentAgainstBL ? request.exportOffer.paymentAgainstBL.toString() : '')

      formData.append("importToCountryId", request.importToCountryId.toString())
      formData.append("portToId", request.portToId.toString())
      formData.append("importerId", request.importerId.toString())
      formData.append("consigneeId", request.consigneeId.toString())
      formData.append("notifyId", request.notifyId.toString())
      formData.append("palletsQuantity", request.palletsQuantity.toString())
      formData.append("week", request.week.toString())

      if(request.exportOffer.pictures) {
        request.exportOffer.pictures.forEach((p: any) => {
          formData.append("ExportOffer.pictures", p)
        })
      }

      if(request.exportOffer.certificates) {
        request.exportOffer.certificates.forEach(c => {
          formData.append("ExportOffer.certificates", c.id.toString())
        })
      }

      if(request.exportOffer.productCertificates) {
        request.exportOffer.productCertificates.forEach((x, index) => {
          formData.append(`productCertificates[${index}].name`, x.name.trim())
          formData.append(`productCertificates[${index}].username`, x.username)
          formData.append(`productCertificates[${index}].productCertificateTypeId`, x.productCertificateTypeId.toString())
          formData.append(`productCertificates[${index}].documentIssueDate`, x.documentIssueDate ? x.documentIssueDate.toDateString() : '')
          formData.append(`productCertificates[${index}].expirationDate`, x.expirationDate ? x.expirationDate.toDateString() : '')
          formData.append(`productCertificates[${index}].pdf`, x.pdf)
        })
      }

      if(request.exportOffer.documents) {
        request.exportOffer.documents.forEach((x, index) => {
          formData.append(`documents[${index}].name`, x.name.trim())
          formData.append(`documents[${index}].pdf`, x.pdf)
        })
      }


      if(request.packagings) {
        request.packagings.forEach((p: any) => {
          formData.append("packagings", p.toString())
        })
      }
    }
    return formData
  }

  getFormDataCloseSaleForm(request: ExportCloseSaleFilter)
  {
    const formData = new FormData()
    if(request){

      formData.append('exportUsername', request.exportUsername)
      if(request.varietyId){
        formData.append('varietyId', request.varietyId.toString())
      }
      if(request.speciesId){
        formData.append('speciesId', request.speciesId.toString())
      }
      if(request.importerId){
          formData.append('importerId', request.importerId.toString())
      }
      if(request.exportOfferId){
        formData.append('exportOfferId', request.exportOfferId.toString())
      }
      if(request.isActive == 0 || request.isActive == 1)
        {
      formData.append('isActive', request.isActive.toString())
      }
      if(request.kilosPerBox){
      formData.append('kilosPerBox', request.kilosPerBox)
      }
      if(request.harvestDate) {
        if(request.harvestDate instanceof Date)
          {
            request.harvestDate.toDateString()
            formData.append("harvestDate", new Date(request.harvestDate).toDateString())
          }

      }
      else {
        formData.append("harvestDate", '')
      }
      if(request.marketId){
      formData.append('marketId', request.marketId.toString())
      }
      if(request.speciesSize){
      formData.append('speciesSize', request.speciesSize.toString())
      }
      if(request.shelfLife){
      formData.append('shelfLife', request.shelfLife.toString())
      }
      if(request.price){
      formData.append('price', request.price.toString())
      }
      if(request.fridgeStorageId){
      formData.append('fridgeStorageId', request.fridgeStorageId.toString())
      }


    }

    return formData
  }

  deleteOffer(exportOrderId: any): Observable<any> {
    return this._http.delete<any>(Routes.exportOfferApi + '/Delete', {params: {exportOrderId}})
  }

  getFormDataAdditionalExportOffer(offer: ExportOfferAddtionalInfo) {
    const formData = new FormData();

    formData.append("id", offer.id)
    formData.append("guid", offer.guid)
    formData.append("color", offer.color ? offer.color.toString() : '')
    formData.append("boxType", offer.boxType ? offer.boxType.toString() : '')
    formData.append("caseSize", offer.caseSize ? offer.caseSize.toString() : '')
    formData.append("kilosPerBox", offer.kilosPerBox ? offer.kilosPerBox.toString() : '')
    formData.append("isQualityControl", offer.isQualityControl ? offer.isQualityControl.toString() : 'false')
    if (offer.qualityControlDate) formData.append("qualityControlDate", new Date(offer.qualityControlDate).toDateString())
    formData.append("basePallets", offer.basePallets ? offer.basePallets.toString() : '')
    formData.append("marks", offer.marks ? offer.marks.toString() : '')
    formData.append("sizeMillimeters", offer.sizeMillimeters ? offer.sizeMillimeters.toLocaleString("en-US") : '0')
    formData.append("category", offer.category ? offer.category : '')
    formData.append("grossKilos", offer.grossKilos ? offer.grossKilos : '')
    if (offer.qualityDocument) formData.append("qualityDocument", offer.qualityDocument)

    return formData
  }


}
