import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { FrozenFruitExportOfferCreateRequest, FrozenFruitExportOffer, FrozenFruitExportOfferUploadPicturesRequest, FrozenFruitExportOfferUploadDocumentsRequest, FrozenFruitExportOfferListItem, FrozenFruitExportOfferDocuments } from "src/app/domain/models/FrozenFruitExportOffer";
import { FrozenFruitImportOrderCreateRequest } from "src/app/domain/models/ImportOrder";
import { Routes } from "src/app/infrastructure/routes";
import { FrozenFruitExportOfferGateway } from "../gateway/FrozenFruitExportOfferGateway";

@Injectable({providedIn: 'root'})
export class FrozenFruitExportOfferService extends FrozenFruitExportOfferGateway {

  constructor(private _http: HttpClient) {
    super()
  }

  getAllByExporter(isActive: boolean): Observable<FrozenFruitExportOfferListItem[]> {
    return this._http.get<FrozenFruitExportOfferListItem[]>(Routes.frozenFruitExportOfferApi + '/GetAllByExporter', {params: {isActive}})
  }

  create(request: FrozenFruitExportOfferCreateRequest): Observable<FrozenFruitExportOffer> {
    return this._http.post<FrozenFruitExportOffer>(Routes.frozenFruitExportOfferApi + '/Create', request)
  }

  update(request: FrozenFruitExportOffer): Observable<FrozenFruitExportOffer> {
    return this._http.put<FrozenFruitExportOffer>(Routes.frozenFruitExportOfferApi + '/Update', request)
  }

  uploadProductPictures(request: FrozenFruitExportOfferUploadPicturesRequest): Observable<any> {
    const formData = new FormData()
    formData.append('frozenFruitExportOfferId', request.frozenFruitExportOfferId.toString())
    formData.append("picture1", request.picture1 ? request.picture1 : '')
    formData.append("picture2", request.picture2 ? request.picture2 : '')
    formData.append("picture3", request.picture3 ? request.picture3 : '')
    return this._http.put<any>(Routes.frozenFruitExportOfferApi + '/UploadProductPictures', formData)
  }

  uploadDocuments(request: FrozenFruitExportOfferUploadDocumentsRequest): Observable<any> {
    const formData = new FormData()
    formData.append('frozenFruitExportOfferId', request.frozenFruitExportOfferId.toString())
    formData.append("pesticideAnalysisDocument", request.pesticideAnalysisDocument ? request.pesticideAnalysisDocument : '')
    formData.append("heavyMetalsAnalysisDocument", request.heavyMetalsAnalysisDocument ? request.heavyMetalsAnalysisDocument : '')
    formData.append("microbiologicalAnalysisDocument", request.microbiologicalAnalysisDocument ? request.microbiologicalAnalysisDocument : '')
    formData.append("waterAnalysisDocument", request.waterAnalysisDocument ? request.waterAnalysisDocument : '')
    return this._http.put<any>(Routes.frozenFruitExportOfferApi + '/UploadDocuments', formData)
  }

  getById(id: number): Observable<FrozenFruitExportOffer> {
    return this._http.get<FrozenFruitExportOffer>(Routes.frozenFruitExportOfferApi + '/GetById', {params: {id}})
  }

  toogleIsActive(id: number, isActive: boolean): Observable<any> {
    return this._http.put<any>(Routes.frozenFruitExportOfferApi + '/ToogleIsActive', {}, {params: {id, isActive}})
  }

  duplicate(id: number): Observable<FrozenFruitExportOffer> {
    return this._http.put<FrozenFruitExportOffer>(Routes.frozenFruitExportOfferApi + '/Duplicate', {}, {params: {id}})
  }

  getDocumentsById(id: number): Observable<FrozenFruitExportOfferDocuments> {
    return this._http.get<FrozenFruitExportOfferDocuments>(Routes.frozenFruitExportOfferApi + '/GetDocumentsById', {params: {id}})
  }

  getAllForMarketplace(countryId: number): Observable<FrozenFruitExportOfferListItem[]> {
    return this._http.get<FrozenFruitExportOfferListItem[]>(Routes.frozenFruitExportOfferApi + '/GetAllForMarketplace', {params: {countryId}})
  }

  addFrozenFruitShoppingCart(request: FrozenFruitImportOrderCreateRequest): Observable<any> {
    return this._http.post<any>(Routes.frozenFruitExportOfferApi + '/AddShoppingCart', request)
  }
}
