<app-admin-dashboard>
    <p-card>
        <ng-template pTemplate="header">
            <div class="flex justify-content-between flex-wrap align-items-center" style="padding-left: 15px;">
                <h2>Cargar información operación</h2>
            </div>
        </ng-template>
        <ng-template pTemplate="body">
            <div class="flex flex-row flex-wrap">
                <div class="flex align-items-center justify-content-start m-2 col-3">
                    <input pInputText id="name" type="text" placeholder="Nombre del documento" [(ngModel)]="name" style="width: 100%;"/>
                </div>
                <div class="flex align-items-center justify-content-start m-2 col-8" >
                  <p-dropdown [options]="operationDocumentTypes" placeholder="Tipo de documento de operación" (onChange)="onSelectDocumentType($event)" styleClass="mb-2" optionLabel="name" optionValue="id"></p-dropdown>
                </div>
                <div class="flex align-items-center justify-content-start m-2 col-12">
                    <p-fileUpload
                    #fileUploader
                    [showUploadButton]="false"
                    [multiple]="false"
                    accept=".pdf"
                    name="pdf"
                    [maxFileSize]="5000000"
                    chooseLabel="Cargar"
                    (onSelect)="onSelect($event)"/>
                </div>
                <div class="flex gap-2 col-6 p-fluid" style="margin-top: 50px;">
                  <div class="col-6">
                    <p-button label="Regresar" (onClick)="goToOrderDetail()" [loading]="loading" styleClass="p-button-outlined"></p-button>
                  </div>
                  <div class="col-6">
                    <p-button label="Subir" (onClick)="upload()" [loading]="loading"></p-button>
                  </div>
                </div>
            </div>
        </ng-template>
    </p-card>
</app-admin-dashboard>
<p-toast></p-toast>
