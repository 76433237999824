<app-user-dashboard [userMenuActiveItemIndex]="0" [activeItem]="'password-change'">
  <p-card [style]="{'min-height':'400px'}">
    <ng-template pTemplate="header">
      <div class="flex justify-content-between flex-wrap align-items-center" style="padding: 15px;">
        <h2>{{'UserPages.UserChangePassword.PageTitle' | translate}}</h2>
      </div>
      <p style="margin-left: 15px;">
        {{'UserPages.UserChangePassword.PageDescription' | translate}}
      </p>
    </ng-template>
    <form [formGroup]="formGroup">
      <div class="p-fluid formgrid grid" style="padding: 20px;">
        <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
          <label for="oldPassword">{{'UserPages.UserChangePassword.CurrenPasswordFieldLabel' | translate}} *</label>
          <p-password id="oldPassword" formControlName="oldPassword" [minlength]="8" [feedback]="false" [toggleMask]="true"></p-password>
          <small *ngIf="submit && formGroup.controls['oldPassword'].invalid" class="p-error">{{getErrorMessage('oldPassword')}}</small>
        </div>
        <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
          <label for="newPassword">{{'UserPages.UserChangePassword.NewPasswordFieldLabel' | translate}} *</label>
          <p-password id="newPassword" formControlName="newPassword" [minlength]="8" [toggleMask]="true">
            <ng-template pTemplate="header">
              <h6>{{'UserPages.UserChangePassword.SubtitlePassword' | translate}}</h6>
            </ng-template>
            <ng-template pTemplate="footer">
              <p-divider></p-divider>
              <p class="mt-2">{{'UserPages.UserChangePassword.PasswordSubtitle' | translate}}</p>
              <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                <li>{{'UserPages.UserChangePassword.PasswordSuggestion1' | translate}}</li>
                <li>{{'UserPages.UserChangePassword.PasswordSuggestion2' | translate}}</li>
                <li>{{'UserPages.UserChangePassword.PasswordSuggestion3' | translate}}</li>
                <li>{{'UserPages.UserChangePassword.PasswordSuggestion4' | translate}}</li>
              </ul>
            </ng-template>
          </p-password>
          <small *ngIf="submit && formGroup.controls['newPassword'].invalid" class="p-error">{{getErrorMessage('newPassword')}}</small>
        </div>
        <div class="field col-12 xl:col-3 lg:col-3" style="margin-bottom: 40px;"></div>
        <div class="field col-12 xl:col-3 lg:col-3" style="margin-bottom: 40px;"></div>
        <div class="field col-12 xl:col-3 lg:col-3" style="margin-bottom: 40px;">
          <p-button label="{{'UserPages.UserChangePassword.ForgotPasswordButton' | translate}}" styleClass="p-button-outlined" (click)="goToChangePasswordPage()"></p-button>
        </div>
        <div class="field col-12 xl:col-3 lg:col-3" style="margin-bottom: 40px;">
          <p-button [loading]="loading" label="{{'UserPages.UserChangePassword.ChangePasswordButton' | translate}}" (click)="save()"></p-button>
        </div>
      </div>
    </form>
    <div class="flex justify-content-between flex-wrap align-items-center" style="padding: 15px;">
      <h2>{{'UserPages.UserChangePassword.TwoStepAuthenticationTitle' | translate}}</h2>
    </div>
    <div class="p-fluid formgrid grid" style="padding: 20px;">
      <div class="col-12">
        <p>{{'UserPages.UserChangePassword.TwoStepAuthenticationText' | translate}}.</p>
      </div>
      <div *ngIf="showSwitch" class="col-12">
        <!-- <label for="switch1">Activar autenticación en 2 pasos</label> -->
        <span id="switch1" style="font-weight: bold;">{{authLabel}}</span>
        <p-inputSwitch [(ngModel)]="isTwoAuth" inputId="switch1" class="ml-2" (onChange)="toggleAuth()" ></p-inputSwitch>
      </div>
      <div *ngIf="showAuthentication" class="col-6 sm:col-12">
        <form [formGroup]="formGroup2FA">
          <div class="p-fluid grid flex col-6 md:col-6 sm:col-12">
            <div class="col-12 mb-2">
              <p>{{'LoginPage.VerifiedCodeText' | translate}}</p>
            </div>
            <div class="col-2">
              <p-inputNumber #authCode1 [min]="0" [max]="9" formControlName="authCode1" [maxlength]="1" [useGrouping]="false"
                class="p-inputtext-sm" (onInput)="handleFocusNext(authCode2)" (onFocus)="clear('authCode1')" (paste)="onPaste($event)" [style]="{'text-aling':'center'}">
              </p-inputNumber>
            </div>

            <div class="col-2">
              <p-inputNumber #authCode2 [min]="0" [max]="9" formControlName="authCode2" [maxlength]="1"
                class="p-inputtext-sm" (onInput)="handleFocusNext(authCode3)" (onFocus)="clear('authCode2')" (paste)="onPaste($event)"></p-inputNumber>
            </div>

            <div class="col-2">
              <p-inputNumber #authCode3 [min]="0" [max]="9" formControlName="authCode3" [maxlength]="1"
                class="p-inputtext-sm" (onInput)="handleFocusNext(authCode4)" (onFocus)="clear('authCode3')" (paste)="onPaste($event)"></p-inputNumber>
            </div>

            <div class="col-2">
              <p-inputNumber #authCode4 [min]="0" [max]="9" formControlName="authCode4" [maxlength]="1"
                class="p-inputtext-sm" (onInput)="handleFocusNext(authCode5)" (onFocus)="clear('authCode4')" (paste)="onPaste($event)"></p-inputNumber>
            </div>

            <div class="col-2">
              <p-inputNumber #authCode5 [min]="0" [max]="9" formControlName="authCode5" [maxlength]="1"
                class="p-inputtext-sm" (onInput)="handleFocusNext(authCode6)" (onFocus)="clear('authCode5')" (paste)="onPaste($event)"></p-inputNumber>
            </div>

            <div class="col-2">
              <p-inputNumber #authCode6 [min]="0" [max]="9" formControlName="authCode6" [maxlength]="1"
                class="p-inputtext-sm" (onFocus)="clear('authCode6')" (paste)="onPaste($event)"></p-inputNumber>
            </div>
            <div class="col-6 mt-2">
              <p-button label="{{'LoginPage.ConfirmCodeButton' | translate}}" (onClick)="toggleAuth()" [loading]="loading"/>
            </div>
            <div class="col-6 mt-2">
              <p-button  styleClass="p-button-outlined p-button-secondary" label="{{'LoginPage.ResendCodeButton' | translate}}" (onClick)="resendCode()" [loading]="loading" />
            </div>
          </div>
        </form>
      </div>
    </div>
  </p-card>
</app-user-dashboard>

<p-toast></p-toast>
