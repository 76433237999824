<app-user-dashboard [userMenuActiveItemIndex]="0" [activeItem]="'export-offers'">
  <p-card>
    <div class="flex justify-content-between flex-wrap align-items-center">
      <h1>{{'FrozenFruitExportOffersPage.Title' | translate}}</h1>
      <p-button label="{{'FrozenFruitExportOffersPage.CreateButtonLabel' | translate}}" (onClick)="goToCreate()"></p-button>
    </div>

    <p-tabMenu [model]="tabMenuItems" [activeItem]="tabMenuActiveItem" [style]="{padding: '15px'}"></p-tabMenu>
    <app-frozen-fruit-export-offers [viewMode]="'actives'"></app-frozen-fruit-export-offers>
  </p-card>
</app-user-dashboard>
