<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
<div class="flex flex-column md:flex-row align-items-center justify-content-end gap-2">
  <p-button (onClick)="goToMarketplace()" styleClass="p-button-secondary p-button-sm" [style]="{'max-height': '37px'}" *ngIf="profile?.type == 1 || profile?.type == 2 || profile?.type == 3">
    <span class="material-symbols-outlined" style="font-size: 25px;">nutrition</span>
    <span style="margin-left: 6px">Marketplace</span>
  </p-button>
  <p-button icon="pi pi-fw pi-shopping-bag" label="{{'HomeHeader.UserMenu.MySellsButton' | translate}}" *ngIf="profile?.type == 1" (onClick)="goToExportOffers()" styleClass="p-button-outlined p-button-sm"></p-button>
  <p-button icon="pi pi-fw pi-shopping-bag" label="{{'HomeHeader.UserMenu.MyPurchaseButton' | translate}}" *ngIf="profile?.type == 2" (onClick)="goToImportOrders()" styleClass="p-button-outlined p-button-sm"></p-button>
  <p-button icon="pi pi-fw pi-search" label="QC Reviews" *ngIf="profile?.type == 4" (onClick)="goToQCReviews()" styleClass="p-button-outlined p-button-sm"></p-button>
  <p-button icon="pi pi-fw pi-search" label="Forwarder Reviews" *ngIf="profile?.type == 5" (onClick)="goToForwarderReviews()" styleClass="p-button-outlined p-button-sm"></p-button>
  <p-button icon="pi pi-fw pi-search" label="Custom Agent Reviews" *ngIf="profile?.type == 6" (onClick)="goToCustomAgentReviews()" styleClass="p-button-outlined p-button-sm"></p-button>

  <p-button icon="pi pi-pw pi-user" label="{{'HomeHeader.UserMenu.AccountItem' | translate}}" (onClick)="menu.toggle($event)" styleClass="p-button-text p-button-sm"></p-button>
  <app-switch-lang [showOnlyIcon]="true" [showClear]="false"></app-switch-lang>

  <p-button icon="pi pi pi-fw pi-bell" pTooltip="{{'HomeHeader.UserMenu.NotificationsToolTip' | translate}}" tooltipPosition="bottom" [badge]="notificationBadge" badgeClass="p-badge-warning" (onClick)="notificationsMenuVisible = true" styleClass="p-button-text p-button-secondary p-button-sm" [style]="{'width': '80px'}"></p-button>
  <p-button icon="pi pi-fw pi-comments" pTooltip="{{'HomeHeader.UserMenu.CustomerSupporToolTip' | translate}}" tooltipPosition="bottom" (onClick)="helpMenu.toggle($event)" styleClass="p-button-text p-button-secondary p-button-sm"></p-button>
  <p-button icon="pi pi-fw pi-sign-out" pTooltip="{{'HomeHeader.UserMenu.LogoutToolTip' | translate}}" tooltipPosition="bottom" (onClick)="logout()" styleClass="p-button-text p-button-secondary p-button-sm"></p-button>
</div>

<p-menu #menu [model]="userMenuItems" [popup]="true"></p-menu>
<app-notification-list [isVisible]="notificationsMenuVisible" (onDialogClose)="onDialogClose($event)"/>
<p-menu #helpMenu [model]="helpMenuItems" [popup]="true"></p-menu>


