import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { ExportOfferSuggestionGateway } from 'src/app/application/gateway/ExportOfferSuggestionGateway';
import { ExportOffer } from 'src/app/domain/models/ExportOffer';
import { SuggestionListItem } from 'src/app/domain/models/ExportOfferSuggestion';
import { Packaging } from 'src/app/domain/models/Packaging';
import { ProductCertificateType } from 'src/app/domain/models/ProductCertificateType';
import { Routes } from 'src/app/infrastructure/routes';
import { OrderUtils } from 'src/app/ui/util/order-utils';

@Component({
  selector: 'app-user-export-offer-suggestions',
  templateUrl: './user-export-offer-suggestions.component.html',
  styleUrls: ['./user-export-offer-suggestions.component.css'],
  providers: [MessageService]
})
export class UserExportOfferSuggestionsComponent implements OnInit {

  suggestions: SuggestionListItem[] = []
  selected: SuggestionListItem | undefined
  exportOffer: ExportOffer | undefined
  selectedProductCertificateTypes: ProductCertificateType[] = []
  selectedPackagingTypes: Packaging[] = []

  loading: boolean = false
  visibleOfferDetailDialog: boolean = false

  constructor(private _router: Router,
    private _authService: AuthGateway,
    private _messageService: MessageService,
    private _suggestionService: ExportOfferSuggestionGateway,
    public translate: TranslateService) {

  }

  ngOnInit(): void {
    this.loadSuggestions()
  }

  loadSuggestions() {
    this.loading = true
    this._suggestionService.getByProfileId().subscribe({
      next: (v) => {
        this.suggestions = v
      },
      error: (e) => {
        console.log(e)
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.ExportOfferSuggestion.ManyRetrieveError') });
      },
      complete: () => {
        this.loading = false
        console.info('Export Offer Suggestion query successfully')
      }
    })
  }

  goToSuggestions(suggestionId: number) {
    this._router.navigate(['/'+Routes.userOfferSuggestionsPage, suggestionId])
  }

  padWithLeadingZeros(id: number) {
    return OrderUtils.padWithLeadingZeros(id)
  }
}
