<p-card *ngIf="order">
  <div class="grid" style="padding-left: 15px; padding-right: 15px">
    <h1>{{'UserPages.UserPurchaseOrders.PaymentsPage.OrderDetailPage.PageTitle' | translate}}</h1>
  </div>
  <p-table
    [value]="details"
    responsiveLayout="stack"
    dataKey="rowexpansion"
    [breakpoint]="'600px'">
    <ng-template pTemplate="caption">
      <div class="flex align-items-center justify-content-between" style="margin-bottom: 40px;">
        <div class="flex align-items-center justify-content-start w-full gap-6">
          <div class="flex flex-column align-items-center">
            <div>
              <i class="pi pi-file"></i>&nbsp;{{'UserPages.UserPurchaseOrders.PaymentsPage.OrderDetailPage.OrderCodeText' | translate}}:
            </div>
            <div>
              {{getOrderTitle()}}
            </div>
          </div>
          <div class="flex flex-column align-items-center">
            <div>
              <i class="pi pi-calendar"></i>&nbsp;{{'UserPages.UserPurchaseOrders.PaymentsPage.OrderDetailPage.OrderDateText' | translate}}:
            </div>
            <div>
              {{order.importOrder.created | date: 'dd-MM-yyyy'}}
            </div>
          </div>
          <div *ngIf="order.importOrder.status == 3 || order.importOrder.status == 4">
            <i class="pi pi-check-circle" style="color: #6da043;"></i>&nbsp;&nbsp;<span style="color: #6da043;">{{'UserPages.UserPurchaseOrders.PaymentsPage.OrderDetailPage.OrderPurchaseConfirmText' | translate}}</span>
          </div>
        </div>
        <div class="flex w-full align-items-center justify-content-end">
          <div class="flex flex-column gap-2">
            <p-button  styleClass="p-button-outlined" pTooltip="Descartar compra"  *ngIf="cancelPurchaseAvailable && order.importOrder.status == 3 &&  profile?.profileType == 'Importer' && !isClosedSale" label="{{'UserPages.UserPurchaseOrders.PaymentsPage.OrderDetailPage.CancelPurchaseButton' | translate}}" (onClick)="visibleCancelDialog = true"></p-button>
            <p-button  class="action-btn" pTooltip="Click aquí para verificar compra"  *ngIf="profile?.profileType == 'Importer' && order.importOrder.status == 2 && !isClosedSale" label="{{'UserPages.UserPurchaseOrders.PaymentsPage.OrderDetailPage.ConfirmPurchaseButton' | translate}}" (onClick)="confirmPurchase()"></p-button>
            <p-button class="action-btn" *ngIf="profile?.profileType == 'Importer' && (order.importOrder.status == 3 || order.importOrder.status == 4)" label="{{'UserPages.UserPurchaseOrders.PaymentsPage.OrderDetailPage.PaymentButton' | translate}}" icon="pi pi-money-bill" (onClick)="goToPayments()"></p-button>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th colspan="2">
          <div class="flex justify-content-center align-items-center">
            {{'UserPages.UserPurchaseOrders.PaymentsPage.OrderDetailPage.DetailOrderTable.ProductHeader' | translate}}
          </div>
        </th>
        <th>
          <div class="flex justify-content-center align-items-center">
            {{'UserPages.UserPurchaseOrders.PaymentsPage.OrderDetailPage.DetailOrderTable.LocationHeader' | translate}}
          </div>
        </th>
        <th>
          <div class="flex justify-content-center align-items-center">
            {{'UserPages.UserPurchaseOrders.PaymentsPage.OrderDetailPage.DetailOrderTable.ListPriceHeader' | translate}}
          </div>
        </th>
        <th>
          <div class="flex justify-content-center align-items-center">
            {{'UserPages.UserPurchaseOrders.PaymentsPage.OrderDetailPage.DetailOrderTable.CasePerPalletsHeader' | translate}}
          </div>
        </th>
        <th>
          <div class="flex justify-content-center align-items-center">
            {{'UserPages.UserPurchaseOrders.PaymentsPage.OrderDetailPage.DetailOrderTable.PalletsHeader' | translate}}
          </div>
        </th>
        <th>
          <div class="flex justify-content-center align-items-center">
            Total cases
          </div>
        </th>
        <th *ngIf="!isClosedSale">
          <div class="flex justify-content-center align-items-center">
            {{'UserPages.UserPurchaseOrders.PaymentsPage.OrderDetailPage.DetailOrderTable.TotalPriceHeader' | translate}}
          </div>
        </th>
        <th *ngIf="order.importOrder.status == 4">
          <div class="flex justify-content-center align-items-center">
            Exporter
          </div>
        </th>
        <th>
          <div class="flex justify-content-center align-items-center">
            {{'UserPages.UserPurchaseOrders.PaymentsPage.OrderDetailPage.DetailOrderTable.StatusHeader' | translate}}
          </div>
        </th>
        <th>
          <div class="flex justify-content-center align-items-center">
            {{'UserPages.UserPurchaseOrders.PaymentsPage.OrderDetailPage.DetailOrderTable.ActionsHeader' | translate}}
          </div>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-detail let-expanded="expanded">
      <tr>
        <td>
          <img [src]="detail.varietyUrlIcon" style="border-radius: 7px; width: 100%; max-width: 80px; height: auto;" alt=""/>
        </td>
        <td>
          <div class="flex justify-content-start align-items-center">
            <div>
              <p>{{getSpeciesName(detail)}} / {{detail.varietyName ? detail.varietyName : "N.A" }}</p>
            </div>
          </div>
        </td>
        <td>
          <div class="flex justify-content-center align-items-center">
            {{ detail.fridgeStorageCity && detail.fridgeStorageCountry && detail.fridgeStorageName ? detail.fridgeStorageName + ' ' + detail.fridgeStorageCity + ', ' + detail.fridgeStorageCountry : (detail.fridgeStorageCountry ? detail.fridgeStorageCountry : 'N.A')}}
          </div>
        </td>
        <td>
          <div *ngIf="!isClosedSale" class="flex justify-content-center align-items-center">
            {{ detail.price | currency:'USD':'US$' }} {{detail.incoterms}}
          </div>
          <div *ngIf="isClosedSale" class="flex justify-content-center align-items-center">
            <p-button
            class="p-button-sm"
            (onClick)="showCurrentPalletPriceModal()"
            label="{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.ViewPricePalletButton' | translate}}"
            severity="secondary"
            icon="pi pi-eye"
            [disabled]="currentPrices.length < 1"
            >
            </p-button>
          </div>
        </td>
        <td>
          <div *ngIf="isClosedSale && currentPrices.length > 0" class="flex justify-content-center align-items-center">
            {{ detail.palletsQuantity > 0 ? (getTotalPalletsConfiguration() / detail.palletsQuantity) : 'N.A' }}
          </div>
          <div *ngIf="!isClosedSale || currentPrices.length == 0"  class="flex justify-content-center align-items-center">
            {{ detail.boxesPerPallet > 0 ? detail.boxesPerPallet : 'N.A.' }}
          </div>
        </td>
        <td>
          <div class="flex justify-content-center align-items-center">
            {{ detail.boxesPerPallet > 0 ? detail.palletsQuantity : 'N.A.'}}
          </div>
        </td>
        <td>
          <div *ngIf="isClosedSale && currentPrices.length > 0" class="flex justify-content-center align-items-center">
            {{ getTotalPalletsConfiguration() }}
          </div>
          <div *ngIf="!isClosedSale || currentPrices.length == 0" class="flex justify-content-center align-items-center">
            {{ getDetailTotalCases(detail) }}
          </div>
        </td>
        <td *ngIf="!isClosedSale">
          <div class="flex justify-content-center align-items-center">
            <span style="font-weight: bold; color: #8c2b87;">{{ getDetailTotalPrice(detail) | currency:'USD':'US$' }}</span>
          </div>
        </td>
        <td *ngIf="order.importOrder.status == 4">
          <div  *ngIf="detail.exporter" class="flex justify-content-center align-items-center">
            <p-button Tooltip="Ver info" styleClass="p-button-outlined" (onClick)="viewExporterInfo(detail.exporter)">{{ detail.exporterCompanyName }}</p-button>
          </div>
          <div  *ngIf="!detail.exporter" class="flex justify-content-center align-items-center">
            <span>N.A</span>
          </div>
        </td>
        <td>
          <div class="flex justify-content-center align-items-center">
            <p-tag *ngIf="detail.status == 1" value="Pend. Export." severity="warning" icon="pi pi-exclamation-triangle"></p-tag>
            <p-tag *ngIf="detail.status == 2" value="Pend. QC" severity="warning" icon="pi pi-exclamation-triangle"></p-tag>
            <p-tag *ngIf="detail.status == 3" value="Verif. Partial" severity="success" icon="pi pi-check"></p-tag>
            <p-tag *ngIf="detail.status == 4" value="Verif." severity="success" icon="pi pi-check"></p-tag>
          </div>
        </td>
        <td>
          <div class="flex justify-content-center align-items-center">
            <div class="flex justify-content-start gap-2">
              <p-button class="action-btn" pTooltip="{{'UserPages.UserPurchaseOrders.PaymentsPage.OrderDetailPage.DetailOrderTable.VerifyPalletsButton' | translate}}" icon="pi pi-table" *ngIf="detail.status === 1 && profile?.type == 1 && !order.importOrder.isFrozenFruit" (onClick)="goToVerifyPalletsPage(detail)" styleClass="p-button"></p-button>
              <p-button  pTooltip="{{'UserPages.UserPurchaseOrders.PaymentsPage.OrderDetailPage.DetailOrderTable.SeePalletsButton' | translate}}" tooltipPosition="bottom" icon="pi pi-table" *ngIf="detail.status > 1 && profile?.type != 5 && !order.importOrder.isFrozenFruit" (onClick)="goToVerifyPalletsPage(detail)" [style]="{'background-color': '#8c2b87', 'color': 'white'}" styleClass="p-button-outlined"></p-button>
              <p-button class="action-btn" pTooltip="{{'UserPages.UserPurchaseOrders.PaymentsPage.OrderDetailPage.DetailOrderTable.VerifyPalletsButton' | translate}}" tooltipPosition="bottom" icon="pi pi-table" *ngIf="detail.status === 1 && profile?.type == 1 && order.importOrder.isFrozenFruit" (onClick)="goToFrozenFruitVerifyPalletsPage(detail)" styleClass="p-button-outlined"></p-button>
              <p-button  *ngIf="detail.exportOfferId || this.order.importOrder.isFrozenFruit" pTooltip="{{'UserPages.UserClosedSale.ImporterClosedSaleOrders.SeeExportOfferButton' | translate}}" icon="pi pi-info-circle" tooltipPosition="bottom" (onClick)="viewExportOfferDetailDialog(detail)" styleClass="p-button-outlined"></p-button>
              <p-button  *ngIf="profile?.type == 5" pTooltip="{{ 'ForwarderPage.ForwarderReviewsPending.ButtonPreBL' | translate }}" icon="pi pi-table" (onClick)="goToDelivery(this.order.importOrder.guid)"></p-button>
              <!-- <p-button *ngIf="profile?.type == 4" label="{{ 'ForwarderPage.ForwarderReviewsPending.ButtonReview' | translate }}" icon="pi pi-check-square" (onClick)="goToReviewDocuments(review)"></p-button> -->
            </div>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="summary" *ngIf="this.profile?.type == 2">
      <div class="flex align-items-center justify-content-end">
        <div *ngIf="!isClosedSale">
          {{'UserPages.UserPurchaseOrders.PaymentsPage.OrderDetailPage.TotalOrderText' | translate}}: <span style="font-weight: bold; color: #8c2b87;">
            {{ orderTotalPrice | currency:'USD':'US$' }}
          </span>
        </div>
      </div>
    </ng-template>
  </p-table>


  <div *ngIf="(order?.importOrder?.status == 4 || order?.importOrder?.isClosedSale) && delivery">
    <app-order-detail-tab
      [importOrder]="order.importOrder"
      [delivery]="delivery"
      [importOrderDetails]="order.genericDetails"
      [isClosedSale]="isClosedSale"
      [directSaleProgramId]="directSaleProgramId"
      [discounts]="discounts"
      [payments]="payments"
      [qualityControlDocuments]="order.qualityControlDocuments"
      [operationDocuments]="order.operationDocuments"
      [forwarderDocuments]="order.forwarderDocuments"
      [customAgentDocuments]="order.customAgentDocuments"
      [productCertificateDocuments]="order.productCertificateDocuments"
      [exportOfferDocuments]="order.exportOfferDocuments"
      [invoices]="order.invoices"
      [paymentsQualityControl]="order.paymentsQualityControl"
      *ngIf="delivery"
      >
    </app-order-detail-tab>
  </div>

</p-card>

<p-toast></p-toast>

<p-dialog [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleExportOfferDialog" [style]="{width: '80%'}">
  <app-export-offer-detail [offer]="exportOffer" [productCertificateTypes]="selectedProductCertificateTypes" [packagingTypes]="selectedPackagingTypes" [importOrder]="order?.importOrder"></app-export-offer-detail>
  <app-frozen-fruit-export-offer-detail [offer]="frozenFruitExportOffer"></app-frozen-fruit-export-offer-detail>
  <ng-template pTemplate="footer">
    <p-button label="{{'UserPages.UserBargaining.OpenTab.BargainingDetail.AcceptNegotiationModal.CloseModalButton' | translate }}" (click)="visibleExportOfferDialog = false"></p-button>
  </ng-template>
</p-dialog>

<p-dialog header="Exporter Details" [resizable]="false" [modal]="true"  [draggable]="false" [(visible)]="visibleExporterDialog" [style]="{width: '80%'}">
  <app-exporter-info-modal [exporterInfo]="exporterShow">
  </app-exporter-info-modal>
  <ng-template pTemplate="footer">
    <p-button label="Aceptar" (click)="visibleExporterDialog = false"></p-button>
  </ng-template>
</p-dialog>

<p-dialog header="{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.PalletPriceModal.CurrentPricesHeader' | translate}}" [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleCurrentPalletsPrice" [style]="{width: '800px'}">
  <p-table
  [value]="currentPrices"
  [paginator]="true"
  [rows]="10"
  [loading]="loading"
  responsiveLayout="stack"
  [breakpoint]="'600px'"
  [showCurrentPageReport]="true"
  [rowsPerPageOptions]="[10, 25, 50]"
  >
  <ng-template pTemplate="header">
    <tr>
      <th>
        {{'UserPages.UserPurchaseOrders.VerifyPalletsPage.PalletPriceModal.CategoryHeader' | translate}}
      </th>
      <th>
        {{'UserPages.UserPurchaseOrders.VerifyPalletsPage.PalletPriceModal.SpeciesSizeHeader' | translate}}
      </th>
      <th>
        {{'UserPages.UserPurchaseOrders.VerifyPalletsPage.PalletPriceModal.BoxQuantityHeader' | translate}}
      </th>
      <th>
        {{'UserPages.UserPurchaseOrders.VerifyPalletsPage.PalletPriceModal.PriceHeader' | translate}}
      </th>
    </tr>
  </ng-template>
  <ng-template  pTemplate="body" let-palletPrice>
    <tr>
      <td>
        {{palletPrice.category}}
      </td>
      <td>
        {{palletPrice.speciesSize}}
      </td>
      <td>
        {{palletPrice.boxPerPallets}}
      </td>
      <td>
        {{palletPrice.price | currency: 'USD'}}
      </td>
    </tr>
  </ng-template>
  </p-table>
</p-dialog>

<p-dialog header="{{'CancelPurchaseModal.Header' | translate}}" [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleCancelDialog" [style]="{width: '500px'}">
  <div class="grid">
    <div class="col-12">
      <p style="text-align: center;">{{'CancelPurchaseModal.ConfirmMessage' | translate}}</p>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="flex justify-content-between">
      <p-button label="{{'CancelPurchaseModal.CancelButton' | translate}}" (onClick)="visibleCancelDialog = false" [loading]="loading" styleClass="p-button-outlined"></p-button>
      <p-button label="{{'CancelPurchaseModal.ConfirmButton' | translate}}" (onClick)="cancelPurchaseOrder()" [loading]="loading"></p-button>
    </div>
  </ng-template>
</p-dialog>