import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Variety } from "src/app/domain/models/Variety";
import { Routes } from "src/app/infrastructure/routes";
import { VarietyGateway } from "../gateway/VarietyGateway";

@Injectable({providedIn: 'root'})
export class VarietyService extends VarietyGateway {


  constructor(private _http: HttpClient) {
    super()
  }

  getAll(): Observable<Variety[]> {
    return this._http.get<Variety[]>(Routes.varietyApi + '/GetAll')
  }

  getAllBySpecies(speciesId: number): Observable<Variety[]> {
    return this._http.get<Variety[]>(Routes.varietyApi + '/GetAll', {params: {speciesId}})
  }
}
