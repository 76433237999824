import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-signup-invitationcode',
  templateUrl: './signup-invitationcode.component.html',
  styleUrls: ['./signup-invitationcode.component.css'],
  providers: [MessageService]
})
export class SignupInvitationcodeComponent implements OnInit {

  constructor(
    public translate: TranslateService) {
  }

  ngOnInit(): void {
  }

}
