<app-user-dashboard [userMenuActiveItemIndex]="0" [activeItem]="'certificates'">
  <p-card [style]="{'min-height':'200px'}">
    <ng-template pTemplate="header">
      <div class="flex justify-content-start flex-column align-items-start" style="padding: 15px;">
        <h2>{{'UserPages.UserProductCertificates.PageTitle' | translate}}</h2>
        <p>{{'UserPages.UserProductCertificates.Text1' | translate}}</p>
      </div>
    </ng-template>
    <p-table
      [loading]="loading"
      [value]="certificates"
      [paginator]="true"
      [rows]="10"
      [showCurrentPageReport]="true"
      [rowsPerPageOptions]="[10, 25, 50]"
      responsiveLayout="stack"
      [breakpoint]="'600px'">
      <ng-template pTemplate="caption">
        <div class="flex align-items-center justify-content-start">
          <p-button label="{{'UserPages.UserProductCertificates.NewButtonLabel' | translate}}" icon="pi pi-plus" class="mr-2" (click)="goToCreateForm()"/>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
          <tr>
            <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserProductCertificates.NameHeader' | translate}}</div></th>
            <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserProductCertificates.CertificateTypeHeader' | translate}}</div></th>
            <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserProductCertificates.IssueDateHeader' | translate}}</div></th>
            <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserProductCertificates.ExpireDateHeader' | translate}}</div></th>
            <th><div class="flex justify-content-center align-items-center">PDF</div></th>
            <th><div class="flex justify-content-center align-items-center">Export Offer Id</div></th>
            <th></th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-certificate>
        <tr [pSelectableRow]="certificate">
          <td><div class="flex justify-content-center align-items-center">{{certificate.name}}</div></td>
          <td><div class="flex justify-content-center align-items-center">{{certificate.productCertificateType.name}}</div></td>
          <td><div class="flex justify-content-center align-items-center">{{certificate.documentIssueDate | date:'dd-MM-yyyy'}}</div></td>
          <td><div class="flex justify-content-center align-items-center">{{certificate.expirationDate | date:'dd-MM-yyyy'}}</div></td>
          <td><div class="flex justify-content-center align-items-center"><a *ngIf="certificate.pdfUrl" [href]="getPdfUrl(certificate)" target="_blank"><i class="pi pi-file-pdf" style="font-size: 2rem"></i></a></div></td>
          <td><div class="flex justify-content-center align-items-center">{{certificate.exportOfferId}}</div></td>
          <td>
            <div class="flex justify-content-end align-items-center gap-2">
              <p-button label="{{'UserPages.UserProductCertificates.EditButtonLabel' | translate}}" icon="pi pi-pencil" (click)="goToEditForm(certificate.id)" class="mr-2"/>
              <p-button label="{{'UserPages.UserProductCertificates.DeleteButtonLabel' | translate}}" icon="pi pi-trash" (click)="onDelete(certificate.id)" class="mr-2"/>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </p-card>
</app-user-dashboard>

<p-toast></p-toast>

<p-confirmDialog acceptLabel="Yes" rejectLabel="No" rejectButtonStyleClass="p-button-outlined" [style]="{width: '50vw'}"></p-confirmDialog>
