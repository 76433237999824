import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Species, SpeciesCreateRequest, SpeciesSize, SpeciesVarieties } from "src/app/domain/models/Species";
import { Routes } from "src/app/infrastructure/routes";
import { SpeciesGateway } from "../gateway/SpeciesGateway";
import { FormsModule } from "@angular/forms";

@Injectable({providedIn: 'root'})
export class SpeciesService extends SpeciesGateway {

  constructor(private _http: HttpClient) {
    super()
  }

  getAll(): Observable<Species[]> {
    return this._http.get<Species[]>(Routes.speciesApi + '/GetAll')
  }

  getAllByProductType(productType: any): Observable<Species[]> {
    return this._http.get<Species[]>(Routes.speciesApi + '/GetAllByProductType', {params: {productType}})
  }

  getAllByUsername(username: string): Observable<Species[]> {
    return this._http.get<Species[]>(Routes.speciesApi + '/GetAllByUsername', {params: {username}})
  }

  getAllWithVarieties(): Observable<SpeciesVarieties[]> {
    return this._http.get<SpeciesVarieties[]>(Routes.speciesApi + '/GetAllWithVarieties')
  }

  getSpeciesSizes(speciesId: number): Observable<SpeciesSize[]> {
    return this._http.get<SpeciesSize[]>(Routes.speciesSizeApi + '/GetAllBySpeciesId', {params: {speciesId}})
  }

  create(speciesCreateRequest: SpeciesCreateRequest): Observable<Species> {
    return this._http.post<Species>(Routes.speciesApi + '/Create', this.getFormData(speciesCreateRequest));
  }

  getFormData(speciesCreateRequest: SpeciesCreateRequest){
    const form = new FormData();
    form.append("name", speciesCreateRequest.name);
    form.append("imgGenericIcon", speciesCreateRequest.img )
    return form;
  }
}
