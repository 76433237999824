<app-dashboard-layout>
  <div class="flex flex-column w-full bg-container">
    <div class="flex justify-content-between align-items-center gap-4 w-full ">
      <img src="../../../../assets/img/fruzty-logo.png" width="250" height="111" alt="Fruzty logo"/>
      <h1>PANEL ADMINISTRATIVO</h1>
    </div>
    <div>
      <p-menubar [model]="items"></p-menubar>
    </div>
  </div>

  <app-content-body>
    <div class="grid" style="min-height: 530px;">
      <div class="col-12">
        <div class="surface-section">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </app-content-body>
</app-dashboard-layout>

<p-sidebar [(visible)]="displaySidebar" [modal]="false" [transitionOptions]="'1000ms'" [style]="{'width':'300px'}">
  <p-menu [model]="sideMenuItems" [style]="{'width':'250px'}"></p-menu>
</p-sidebar>
<p-toast></p-toast>
