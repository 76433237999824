import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { ProfileGateway } from 'src/app/application/gateway/ProfileGateway';
import { BasicUser } from 'src/app/domain/models/User';

interface Lang {
  label: string
  lang: string
  icon: string
}

@Component({
  selector: 'app-switch-lang',
  templateUrl: './switch-lang.component.html',
  styleUrls: ['./switch-lang.component.css'],
  providers: [MessageService]
})
export class SwitchLangComponent implements OnInit {

  langs: Lang[] = []
  lang: Lang | undefined

  @Input()
  showOnlyIcon: boolean = false

  @Input()
  showClear: boolean = true

  profile: BasicUser | undefined

  constructor(private _messageService: MessageService,
    private _profileService: ProfileGateway,
    public translate: TranslateService,
    private _authService: AuthGateway) { }

  ngOnInit(): void {
    //this.loadLangs()
    this.langs = [
      {
        label: 'English',
        lang: 'en',
        icon: 'GB.png'
      },
      {
        label: 'Español',
        lang: 'es',
        icon: 'ES.png'
      }
    ]

    this.profile = this._authService.getUser()
    if(this.profile) {
      this.lang = this.langs.find(x => x.lang == this.profile?.lang)
      this.translate.use(this.profile?.lang)
    } else {
      let selectedLang = sessionStorage.getItem('_lang_');
      let useLang = selectedLang ?? 'en'
      this.lang = this.langs.find(x => x.lang == useLang)
      this.translate.use("en")
    }
  }

  switchLang = () => {
    if(this._authService.isAuthenticated() && this.lang) {
      const selectedLang = this.lang.lang
      this._profileService.changeLanguaje(selectedLang).subscribe({
        next: (v) => {
          this._profileService.getBasicUser().subscribe({
            next: (v) => {
              localStorage.setItem('_auth_user', JSON.stringify(v))
              this.translate.use(v.lang)
              window.location.reload()
            },
            error: (e) => {
              console.log(e)
            }
          })
        },
        error: (e) => {
          this.translate.use(selectedLang)
        },
        complete: () => console.info('Profile query successfully')
      })
    }
    else if(this.lang) {
      const selectedLang = this.lang.lang
      this.translate.use(selectedLang)
      sessionStorage.setItem('_lang_', this.lang.lang)
    }
  }

  loadLangs() {
    this.translate.getLangs().forEach(x => {
      //this.langs.push({ lang: x })
    })
  }

  get currentLang(): string {
    return this.translate.currentLang
  }

  get placeholder(): string {
    if(!this.showOnlyIcon)
      return this.translate.instant('SwitchLangComponent.SwitchLangComponentPlaceholder')
    return ''
  }
}
