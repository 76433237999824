import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, MessageService } from 'primeng/api';
import { ImportRequestGateway } from 'src/app/application/gateway/ImportRequestGateway';
import { ImportRequestInformation } from 'src/app/domain/models/ImportRequest';
import { ExportOfferUtils } from '../../../util/export-offer-utils';
import { ExportOffer} from 'src/app/domain/models/ExportOffer';
import { ExportOfferGateway } from 'src/app/application/gateway/ExportOfferGateway';
import { ExportOfferService } from 'src/app/application/service/ExportOfferService';
import { BasicUser } from 'src/app/domain/models/User';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { Routes } from 'src/app/infrastructure/routes';
import { Country } from 'src/app/domain/models/Country';


export interface OfferDropdown {
  id: number,
  name: string,
  exportOffer: ExportOffer
}

@Component({
  selector: 'app-import-request-detail-page',
  templateUrl: './import-request-detail-page.component.html',
  styleUrls: ['./import-request-detail-page.component.css'],
  providers: [MessageService]
})
export class ImportRequestDetailPageComponent implements OnInit {

  info: ImportRequestInformation | undefined

  importRequestGuid: string | undefined;

  loading: boolean = false

  urlRoute: string = ''

  totalPrice = 0

  imageData: any

  requestOffers: OfferDropdown[] = []
  selectedOffer: OfferDropdown | undefined;

  responsiveOptions!: any[];


  profile: BasicUser | undefined

  breadcrumbItems: MenuItem[] = []

  breadcrumbStep1ItemLabel2: string = ''

  allCountries: Country = {
    id: 0,
    name: 'All countries',
    nameEs: 'Todos los países',
    code: 'WORLD',
    isOrigin: true
  }


  ngOnInit(

  ): void {
    this.getImportRequestInfo()
    this.urlRoute = window.location.href;
  }

  constructor(
    public translate: TranslateService,
    private _importRequestService: ImportRequestGateway,
    private _activatedRoute: ActivatedRoute ,
    private _messageService: MessageService,
    private _exportOfferGateway: ExportOfferGateway,
    private _exportOfferService: ExportOfferService,
    private _authService: AuthGateway
  ){
    this._activatedRoute.params.subscribe(params => {
      this.importRequestGuid = params['importRequestGuid']
    })
    this.profile = _authService.getUser()

    if(this.profile){
      switch(this.profile.lang) {
        case 'en':
          this.breadcrumbStep1ItemLabel2 = 'Import request detail'
          break
        case 'es':
          this.breadcrumbStep1ItemLabel2 = 'Detalle de requerimiento de importación'
          break
      }

      this.breadcrumbItems = [{ label: 'Marketplace',  routerLink: ['/' + Routes.marketplacePage]},
      { label: this.breadcrumbStep1ItemLabel2}
      ]
    }
  }


  getNoAvailableLabel() {
    return this.translate.instant('ExportOffer.ExportOfferDetail.NoAvailable')
  }


  getImportRequestInfo()
  {
    if(this.importRequestGuid){
      this._importRequestService.getByGuid(this.importRequestGuid).subscribe({
        next: (v) => {
          console.log("info: ",v)
          this.info = v
          this.getImageQr()
          this.loadOffersForImportRequest();
        },
        error: (e) => {
          this.loading = false;
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOffer.ManyRetrieveError') })
        }
      })
    }
  }

  getIncoterms(value: string) {
    return ExportOfferUtils.getIncotermsName(value)
  }


  getImageQr()
  {
    if(this.info?.importRequest){
      this._exportOfferService.getOfferImportRequestCodeQrUrl(this.info.importRequest.guid).subscribe({
        next: (v) => {
          this.createImageFromBlob(v.body)
        },
        error: (e) => {
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('Cannot load QR image') })
        }
      })
    }
  }

  getMessage(){
    return this.translate.instant('BreadcrumbImportRequest.importRequestDetail')
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
       this.imageData = reader.result;
    }, false);

    if (image) {
       reader.readAsDataURL(image);
    }
  }


  loadOffersForImportRequest(){
    this.requestOffers = []
    if(this.info){
      this._importRequestService.getAllOfferForRequest(this.info.importRequest.id).subscribe({
        next: (v) => {
          v.forEach(offer => {
            this.requestOffers.push({
              id: offer.id,
              name: 'ID: ' + offer.id + ' - ' +offer.variety.name + ' - PALLETS: ' + offer.availablePallets,
              exportOffer: offer
            })
          })
        },
        error: (e) => {
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOffer.ManyRetrieveError') })
        },
        complete: () => console.info('Export offers query successfully')
      })
    }
  }


  sendOfferToRequest()
  {
    if(this.info?.importRequest && this.selectedOffer)
    {
      this._importRequestService.AddExportOffer(this.info.importRequest.id, this.selectedOffer.id).subscribe({
        next: (v) => {
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: this.translate.instant('MessageService.ImportRequest.ApplyOfferSuccess') })
        },
        error: (e) => {
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ImportRequest.ApplyOfferError') })
        },
        complete: () => console.info('Send offer query successfully')
      })}
  }


  getHeader(): string {
    let header = ''
    if(this.profile)
    {
      switch(this.profile.lang) {
        case 'en':
          header = this.info?.importRequest?.variety && this.info?.importRequest?.variety.species && this.info?.importRequest?.variety.species.name ? this.info?.importRequest?.variety.species.name.toUpperCase() : ''
          break

        case 'es':
          header = this.info?.importRequest?.variety && this.info?.importRequest?.variety.species && this.info?.importRequest?.variety.species.nameEs ? this.info?.importRequest?.variety.species.nameEs.toUpperCase() : ''
          break
      }
    }
    return header
  }

  getSubheader(): string {
    return this.info?.importRequest?.variety && this.info.importRequest.variety.name ? this.info.importRequest.variety.name.toUpperCase() : ''
  }

  get finalPaymentSubjectQC(): number {
    if(this.info && this.info.importRequest?.paymentAgainstBL)
      return 100 - this.info?.importRequest?.paymentAgainstBL
    return 0
  }



}
