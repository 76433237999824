import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ImportOrderDetailInformation, UploadImportOrderDetailPdfRequest } from "src/app/domain/models/ImportOrder";
import { CreatePalletPriceRequest, CreatePalletsRequest, MasterLoad, NewPallet, Pallet, PalletInstructiveFormat, PalletPrice, UpdatePalletsRequest } from "src/app/domain/models/Pallet";

@Injectable({providedIn: 'root'})
export abstract class PalletGateway {
  abstract Create(request: CreatePalletsRequest): Observable<Pallet[]>
  abstract createClosedSale(request: CreatePalletsRequest): Observable<any>
  abstract getByImportOrderDetailId(importOrderDetailId: number): Observable<ImportOrderDetailInformation>
  abstract update(request: CreatePalletsRequest): Observable<Pallet[]>
  abstract adminVerifyPallets(request: UpdatePalletsRequest): Observable<Pallet[]>
  abstract qcCompanyVerifyPallets(request: UpdatePalletsRequest): Observable<Pallet[]>
  abstract uploadQCReviewPdf(request: UploadImportOrderDetailPdfRequest): Observable<any>
  abstract uploadQCContainerConsolidationPdf(request: UploadImportOrderDetailPdfRequest): Observable<any>
  abstract getMasterLoad(year: number): Observable<MasterLoad[]>
  abstract createPalletPrice(request: CreatePalletPriceRequest): Observable<PalletPrice[]>
  abstract getPalletPriceConfiguration(importOrderDetailGuid: string ): Observable<PalletPrice[]>
  abstract getPalletPriceConfigurationFromArray(pallets: NewPallet[]): Observable<PalletPrice[]>
  abstract getPalletsInstructiveFormat(importOrderDetailGuid: string): Observable<ImportOrderDetailInformation>
}
