<app-user-dashboard [userMenuActiveItemIndex]="0" [activeItem]="'corporative'">
  <p-card [style]="{'min-height':'200px'}">
    <ng-template pTemplate="header">
      <div class="flex justify-content-between flex-wrap align-items-center" style="padding: 15px;">
        <h2>{{'UserPages.UserProducts.PageTitle' | translate}}</h2>
      </div>
    </ng-template>
      <div class="p-fluid grid">
        <div class="col-12">
          {{'UserPages.UserProducts.PageDescription' | translate}}
        </div>
        <div class="col-12">
          <p-table [value]="species"
            [loading]="loading"
            [paginator]="true"
            [rows]="10"
            [showCurrentPageReport]="true"
            [rowsPerPageOptions]="[10, 25, 50]"
            [(selection)]="selection"
            dataKey="id"
            responsiveLayout="stack"
            [breakpoint]="'600px'">
            <ng-template pTemplate="header">
                <tr>
                  <th style="width: 4rem">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                  </th>
                  <th></th>
                  <th><p-columnFilter type="text" [field]="getFilterField()" [showButtons]="false" placeholder="{{'UserPages.UserProducts.FilterFieldPlaceholder' | translate}}"></p-columnFilter></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-specie>
                <tr>
                  <td>
                    <p-tableCheckbox [value]="specie"></p-tableCheckbox>
                  </td>
                  <td><img [src]="specie.imageUrl" [alt]="specie.name" width="100" class="shadow-4" style="border-radius: 7px;"/></td>
                  <td>{{specie | speciesName}}</td>
                </tr>
            </ng-template>
        </p-table>
        </div>
        <div class="col-12">
          <div class="grid" style="margin-top: 30px;">
            <div class="col-12 md:col-6 lg:col-6"></div>
            <div class="col-12 md:col-6 lg:col-6">
              <div class="grid p-fluid">
                <div class="col-6">
                  <p-button label="{{'UserPages.UserProducts.BackButton' | translate}}" [disabled]="loading" styleClass="p-button-outlined" (click)="onCancel()"/>
                </div>
                <div class="col-6">
                  <p-button label="{{'UserPages.UserProducts.SaveButton' | translate}}" [loading]="loading" (click)="save()"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </p-card>
</app-user-dashboard>

<p-toast></p-toast>
