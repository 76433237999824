<app-user-dashboard [userMenuActiveItemIndex]="0" [activeItem]="'qcReviews'">
  <p-tabMenu [model]="tabMenuItems" [scrollable]="true" [activeItem]="activeItem" [style]="{padding: '15px'}"></p-tabMenu>
  <p-card>
    <h1>Origin QC reviewed reviews</h1>
    <p>Origin QC reviews that have already been done.</p>
    <p-table [value]="reviews2"
        [paginator]="true"
        [rows]="10"
        [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[10, 25, 50]"
        [loading]="loading"
        responsiveLayout="stack"
        [breakpoint]="'600px'">
        <ng-template pTemplate="header">
          <tr>
            <th>
              <div class="flex justify-content-center align-items-center"></div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">Product type</div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">Species/Variety</div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">Coldstorage location</div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">Importer</div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">Departure port</div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">Review Date</div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">Status</div>
            </th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-review>
          <tr>
            <td>
              <img [src]="review.varietyUrlIcon" style="border-radius: 7px; width: 100%; max-width: 100px; height: auto;" alt=""/>
            </td>
            <td>
              <div class="flex justify-content-center align-items-center">{{review.productType}}</div>
            </td>
            <td>
              <div class="flex justify-content-center align-items-center">
                <div>
                  <p>{{ review | speciesName }} / {{ review.varietyName }}</p>
                </div>
              </div>
            </td>
            <td>
              <div class="flex justify-content-center align-items-center">
                {{ review.storageLocation }}
              </div>
            </td>
            <td>
              <div class="flex justify-content-center align-items-center">{{ review.importerCompanyName }}</div>
            </td>
            <td>
              <div class="flex justify-content-center align-items-center">{{ review.departurePort ? review.departurePort : '(Post loading information)' }}</div>
            </td>
            <td>
              <div class="flex justify-content-center align-items-center">{{ review.qualityControlDate | date: 'dd-MM-yyyy' }}</div>
            </td>
            <td>
              <div class="flex justify-content-center align-items-center">
                <p-tag icon="pi pi-exclamation-triangle" severity="warning" value="Partial pallet approved" [rounded]="true" *ngIf="review.importOrderDetailStatus == 3"></p-tag>
                <p-tag icon="pi pi-check" severity="success" value="All pallet approved" [rounded]="true" *ngIf="review.importOrderDetailStatus == 4"></p-tag>
              </div>
            </td>
            <td>
              <div class="flex justify-content-center align-items-center gap-4">
                <p-button pTooltip="Documents" icon="pi pi-file" (onClick)="goToReviewDocuments(review)"></p-button>
                <p-button pTooltip="Edit" icon="pi pi-file-edit" (onClick)="goToOriginReview(review)"></p-button>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
  </p-card>
</app-user-dashboard>
