

export class PdfUtils {

    static downloadPdf(file: Blob, name: string)
    {
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(new Blob([file], { type: 'application/pdf' }));

        link.href = url;
        link.download = name + '.pdf';
        link.click();
        window.open(url, '_blank');
    }
}