import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Consignee } from "src/app/domain/models/Consignee";
import { Routes } from "src/app/infrastructure/routes";
import { ConsigneeGateway } from "../gateway/ConsigneeGateway";

@Injectable({providedIn: 'root'})
export class ConsigneeService extends ConsigneeGateway {

  constructor(private _http: HttpClient) {
    super()
  }

  getAll(): Observable<Consignee[]> {
    return this._http.get<Consignee[]>(Routes.consigneeApi + '/GetAll')
  }

  getAllByUsername(username: string): Observable<Consignee[]> {
    return this._http.get<Consignee[]>(Routes.consigneeApi + '/GetAllByUsername', { params: {username}})
  }

  getById(consigneeId: number): Observable<Consignee> {
    return this._http.get<Consignee>(Routes.consigneeApi + '/GetById', { params: {consigneeId}})
  }

  create(consignee: Consignee): Observable<Consignee> {
    return this._http.post<Consignee>(Routes.consigneeApi + '/Create', this.getFormData(consignee))
  }

  update(consignee: Consignee): Observable<Consignee> {
    return this._http.put<Consignee>(Routes.consigneeApi + '/Update', this.getFormData(consignee))
  }

  delete(consigneeId: number): Observable<Consignee> {
    return this._http.delete<Consignee>(Routes.consigneeApi + '/Delete', { params: {consigneeId}})
  }

  getAllForClosedSale(importerId: number): Observable<Consignee[]> {
    return this._http.get<Consignee[]>(Routes.consigneeApi + '/GetAllForClosedSale', { params: {importerId}})
  }

  private getFormData(consignee: Consignee) {
    const formData = new FormData();
    formData.append("id", consignee.id ? consignee.id.toString() : '');
    formData.append("contactName", consignee.contactName ? consignee.contactName : '');
    formData.append("contactEmail", consignee.contactEmail ? consignee.contactEmail : '');
    formData.append("contactCity", consignee.contactCity ? consignee.contactCity : '');
    formData.append("contactAddress", consignee.contactAddress ? consignee.contactAddress : '');
    formData.append("companyName", consignee.companyName ? consignee.companyName : '');
    formData.append("contactPhone", consignee.contactPhone ? consignee.contactPhone : '');
    formData.append("vat", consignee.vat ? consignee.vat : '');
    formData.append("contactCountryId", consignee.contactCountryId ? consignee.contactCountryId.toString() : '');
    formData.append("username", consignee.username ? consignee.username : '');
    formData.append("pdf", consignee.pdf ? consignee.pdf : '');
    return formData
  }
}
