import { Injectable } from "@angular/core";
import { ImportRequestGateway } from "../gateway/ImportRequestGateway";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Routes } from "src/app/infrastructure/routes";
import { ImportAndProgramByExportOffer, ImportRequest, ImportRequestInformation, ImportRequestMarketplace, ImportRequestOffer, NewImportRequest } from "src/app/domain/models/ImportRequest";
import { Observable } from "rxjs";
import { ExportOffer } from "src/app/domain/models/ExportOffer";



@Injectable({providedIn: 'root'})
export class ImportRequestService extends ImportRequestGateway {
    constructor( private _http: HttpClient){
        super()
    }

    getAllByUsername(): Observable<ImportRequestInformation[]> {
      return this._http.get<ImportRequestInformation[]>(Routes.importRequestApi + '/GetAllByUsername')
    }

    create(importRequest: NewImportRequest): Observable<ImportRequest> {
      return this._http.post<ImportRequest>(Routes.importRequestApi + '/Create', this.getFormDataNewImportRequest(importRequest))
    }

    update(importRequest: ImportRequest): Observable<ImportRequest> {
      return this._http.put<ImportRequest>(Routes.importRequestApi + '/Update', this.getFormData(importRequest))
    }

    desactive(importRequestId: number): Observable<any> {
      return this._http.put<ImportRequest>(Routes.importRequestApi + '/Desactive', {}, {params: {importRequestId}})
    }

    active(importRequestId: number): Observable<any> {
      return this._http.put<ImportRequest>(Routes.importRequestApi + '/Active', {}, {params: {importRequestId}})
    }

    getById(importRequestId: number): Observable<ImportRequestInformation> {
      return this._http.get<ImportRequestInformation>(Routes.importRequestApi + '/GetById', {params: {importRequestId}})
    }

    getAllForMarketplace(importRequestMarketplace: ImportRequestMarketplace): Observable<ImportRequestInformation[]> {
      return this._http.put<ImportRequestInformation[]>(Routes.importRequestApi + '/GetAllForMarketPlace', importRequestMarketplace)
    }

    getAllForMarketplaceByDefault(): Observable<ImportRequestInformation[]> {
      return this._http.get<ImportRequestInformation[]>(Routes.importRequestApi + '/GetAllForMarketPlaceByDefault')
    }


    getAllOfferForRequest(importRequestId: number): Observable<ExportOffer[]>{
      return this._http.get<ExportOffer[]>(Routes.importRequestApi + '/GetAvaiableOfferForRequest', {params: {importRequestId}})
    }

    AddExportOffer(importRequestId: number, offerId: number): Observable<ImportRequestOffer>{
      return this._http.post<ImportRequestOffer>(Routes.importRequestApi + '/AddExportOffer', {importRequestId, offerId})
    }

    createImportRequestAndProgramByOffer(importAndProgramByExportOffer: ImportAndProgramByExportOffer): Observable<any>
    {
      return this._http.post<ImportRequest>(Routes.importRequestApi + '/CreateImportRequestAndProgramByOffer', importAndProgramByExportOffer)
    }

    getByGuid(importRequestGuid: string): Observable<ImportRequestInformation> {
      return this._http.get<ImportRequestInformation>(Routes.importRequestApi + '/GetByGuid', {params: {importRequestGuid}})
    }

    getFormData(importRequest: ImportRequest) {
        const formData = new FormData();
        if(importRequest) {
          formData.append("id", importRequest.id ? importRequest.id.toString() : '')
          formData.append("importerUsername", importRequest.importerUsername ? importRequest.importerUsername : '')
          formData.append("varietyId", importRequest.varietyId ? importRequest.varietyId.toString() : '')
          formData.append("color", importRequest.color ? importRequest.color : '')
          formData.append("harvestDate", importRequest.harvestDate ? importRequest.harvestDate.toDateString() : '')
          formData.append("readyToLoadDate", importRequest.readyToLoadDate ? importRequest.readyToLoadDate.toDateString() : '')
          formData.append("boxType", importRequest.boxType ? importRequest.boxType : '')
          formData.append("kilosPerBox", importRequest.kilosPerBox ? importRequest.kilosPerBox : '')
          formData.append("netKilos", importRequest.netKilos ? importRequest.netKilos : '')
          formData.append("grossKilos", importRequest.grossKilos ? importRequest.grossKilos : '')
          formData.append("caseQuantityPerPallet", importRequest.caseQuantityPerPallet ? importRequest.caseQuantityPerPallet.toString() : '')
          formData.append("countryId", importRequest.countryId ? importRequest.countryId.toString() : '')
          formData.append("speciesSize", importRequest.speciesSize ? importRequest.speciesSize.toString() : '')
          formData.append("listPrice", importRequest.listPrice ? importRequest.listPrice.toLocaleString("en-US") : '0')
          formData.append("basePallets", importRequest.basePallets ? importRequest.basePallets : '')
          formData.append("typeSale", importRequest.typeSale ? importRequest.typeSale : '')
          formData.append("portId", importRequest.portId ? importRequest.portId.toString() : '')
          formData.append("fromWeek", importRequest.fromWeek.toString())
          formData.append("toWeek", importRequest.toWeek.toString())
          formData.append("fromYearWeek", importRequest.fromYearWeek.toString())
          formData.append("toYearWeek", importRequest.toYearWeek.toString())
          formData.append("category", importRequest.category ? importRequest.category.toString() : '');
          formData.append("sizeMillimeters", importRequest.sizeMillimeters ? importRequest.sizeMillimeters.toLocaleString("en-US")  : '0');
          formData.append("countryFromId", importRequest.countryFromId ? importRequest.countryFromId.toString() : '')
          formData.append("palletQuantity", importRequest.palletQuantity ? importRequest.palletQuantity.toString() : '0')
          formData.append("pricePerCase", importRequest.pricePerCase ? importRequest.pricePerCase.toString() : '0')
          formData.append("otherCertificate", importRequest.otherCertificate ? importRequest.otherCertificate.toString() : '')
          formData.append("productCertificateType", importRequest.productCertificateType && importRequest.productCertificateType.length > 0 ? JSON.stringify(importRequest.productCertificateType) : "[]")
          formData.append("otherPackaging", importRequest.otherPackaging ? importRequest.otherPackaging.toString() : '')
          formData.append("packagings", importRequest.packagings && importRequest.packagings.length > 0 ? JSON.stringify(importRequest.packagings) : "[]")
          formData.append("paymentAgainstBL", importRequest.paymentAgainstBL ? importRequest.paymentAgainstBL : '0');

          if(importRequest.pictures) {
            importRequest.pictures.forEach((p: any) => {
              formData.append("pictures", p)
            })
          }

          if(importRequest.removedPictures) {
            importRequest.removedPictures?.forEach(p => {
              formData.append("removedPictures", p.toString())
            })
          }

          if(importRequest.pdf){
            formData.append("dataSheetPdf", importRequest.pdf)
          }
        }
        return formData
    }

    getFormDataNewImportRequest(importRequest: NewImportRequest) {
        const formData = new FormData();
        if(importRequest) {
          formData.append("importerUsername", importRequest.importerUsername ? importRequest.importerUsername : '')
          formData.append("varietyId", importRequest.varietyId ? importRequest.varietyId.toString() : '')
          formData.append("color", importRequest.color ? importRequest.color : '')
          formData.append("harvestDate", importRequest.harvestDate ? importRequest.harvestDate.toDateString() : '')
          formData.append("readyToLoadDate", importRequest.readyToLoadDate ? importRequest.readyToLoadDate.toDateString() : '')
          formData.append("boxType", importRequest.boxType ? importRequest.boxType : '')
          formData.append("kilosPerBox", importRequest.kilosPerBox ? importRequest.kilosPerBox : '')
          formData.append("netKilos", importRequest.netKilos ? importRequest.netKilos : '')
          formData.append("grossKilos", importRequest.grossKilos ? importRequest.grossKilos : '')
          formData.append("caseQuantityPerPallet", importRequest.caseQuantityPerPallet ? importRequest.caseQuantityPerPallet.toString() : '')
          formData.append("countryId", importRequest.countryId ? importRequest.countryId.toString() : '')
          formData.append("speciesSize", importRequest.speciesSize ? importRequest.speciesSize.toString() : '')
          formData.append("basePallets", importRequest.basePallets ? importRequest.basePallets : '')
          formData.append("typeSale", importRequest.typeSale ? importRequest.typeSale : '')
          formData.append("portId", importRequest.portId ? importRequest.portId.toString() : '')
          formData.append("fromWeek", importRequest.fromWeek.toString())
          formData.append("toWeek", importRequest.toWeek.toString())
          formData.append("fromYearWeek", importRequest.fromYearWeek.toString())
          formData.append("toYearWeek", importRequest.toYearWeek.toString())
          formData.append("category", importRequest.category ? importRequest.category.toString() : '');
          formData.append("sizeMillimeters", importRequest.sizeMillimeters ? importRequest.sizeMillimeters.toLocaleString("en-US") : '');
          formData.append("countryFromId", importRequest.countryFromId ? importRequest.countryFromId.toString() : '')
          formData.append("palletQuantity", importRequest.palletQuantity ? importRequest.palletQuantity.toString() : '0')
          formData.append("pricePerCase", importRequest.pricePerCase ? importRequest.pricePerCase.toString() : '0')
          formData.append("otherCertificate", importRequest.otherCertificate ? importRequest.otherCertificate.toString() : '')
          formData.append("productCertificateType", importRequest.productCertificateType && importRequest.productCertificateType.length > 0 ? JSON.stringify(importRequest.productCertificateType) : "[]")
          formData.append("otherPackaging", importRequest.otherPackaging ? importRequest.otherPackaging.toString() : '')
          formData.append("packagings", importRequest.packagings && importRequest.packagings.length > 0 ? JSON.stringify(importRequest.packagings) : "[]")

          if(importRequest.pictures) {
            importRequest.pictures.forEach((p: any) => {
              formData.append("pictures", p)
            })
          }

          if(importRequest.pdf){
            formData.append("dataSheetPdf", importRequest.pdf)
          }
        }
        return formData
    }

    getImportRequestCodeQrUrl(importRequestGuid: string): Observable<any> {
      const headers = new HttpHeaders({
        'Content-Type':  'image/png'
      })

      return this._http.get<any>(Routes.importRequestApi + '/GetImportRequestQRcode',
        {params: {importRequestGuid, qrUrlType: 2}, headers: headers, observe: 'response', responseType: 'blob' as 'json'})
    }
}
