import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { FrozenFruitExportOfferGateway } from 'src/app/application/gateway/FrozenFruitExportOfferGateway';
import { FrozenFruitExportOffer, FrozenFruitExportOfferListItem } from 'src/app/domain/models/FrozenFruitExportOffer';
import { BasicUser } from 'src/app/domain/models/User';
import { Routes } from 'src/app/infrastructure/routes';
import { ExportOfferUtils } from 'src/app/ui/util/export-offer-utils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-frozen-fruit-export-offers',
  templateUrl: './frozen-fruit-export-offers.component.html',
  styleUrls: ['./frozen-fruit-export-offers.component.css'],
  providers: [ConfirmationService, MessageService]
})
export class FrozenFruitExportOffersComponent implements OnInit {

  profile: BasicUser | undefined

  @Input()
  viewMode = 'actives'

  offerMenuItems: MenuItem[] = []

  offers: FrozenFruitExportOfferListItem[] = []
  selected: FrozenFruitExportOffer | undefined

  loading: boolean = false
  isActive = true
  visibleOfferDetailDialog: boolean = false

  constructor(private _router: Router,
    private _authService: AuthGateway,
    private _frozenFruitExportOfferService: FrozenFruitExportOfferGateway,
    private _messageService: MessageService,
    public translate: TranslateService,
    private _confirmationService: ConfirmationService,) {
    this.profile = this._authService.getUser()
  }

  ngOnInit(): void {
    let offerMenuItemEditLabel = 'Edit'
    let offerMenuItemDuplicateLabel = 'Duplicate'
    let offerMenuItemDeactiveLabel = 'Deactivate'
    let offerMenuItemActivateLabel = 'Activate'
    let offerMenuItemEditPictures = 'Edit pictures'
    let offerMenuItemEditDocuments = 'Edit documents'

    if(this.profile) {
      switch(this.profile.lang) {
        case 'en':
          offerMenuItemEditLabel = 'Edit'
          offerMenuItemDuplicateLabel = 'Duplicate'
          offerMenuItemDeactiveLabel = 'Deactivate'
          offerMenuItemActivateLabel = 'Activate'
          offerMenuItemEditPictures = 'Edit pictures'
          offerMenuItemEditDocuments = 'Edit documents'
          break
        case 'es':
          offerMenuItemEditLabel = 'Editar'
          offerMenuItemDuplicateLabel = 'Duplicar'
          offerMenuItemDeactiveLabel = 'Desactivar'
          offerMenuItemActivateLabel = 'Activar'
          offerMenuItemEditPictures = 'Editar imágenes'
          offerMenuItemEditDocuments = 'Editar documentos'
          break
      }
    }

    if(this.viewMode == 'actives') {
      this.offerMenuItems = [
        { label: offerMenuItemEditLabel,
          command: () => { this.goToEdit() }
        },
        { label: offerMenuItemEditPictures, command: () => { this.goToEditPictures() } },
        { label: offerMenuItemEditDocuments, command: () => { this.goToEditDocuments() } },
        { label: offerMenuItemDuplicateLabel, command: () => { this.duplicate() } },
        { label: offerMenuItemDeactiveLabel, command: () => {this.toogleIsActive(false) } },
      ]
      this.isActive = true
    }
    else if (this.viewMode == 'inactives') {
      this.offerMenuItems = [
        { label: offerMenuItemActivateLabel, command: () => { this.toogleIsActive(true) } }
      ]
      this.isActive = false
    }

    this.loadExportOffers()
  }

  loadExportOffers() {
    if(this.profile) {
      this.loading = true
      this._frozenFruitExportOfferService.getAllByExporter(this.isActive).subscribe({
        next: (v) => {
          this.offers = v
          this.loading = false
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOffer.ManyRetrieveError') })
        },
        complete: () => console.info('Frozen fruit export offer query successfully')
      })
    }
  }

  setSelected(offer: any) {
    console.log(offer)
    this.selected = offer
  }

  showOfferDetailDialog(exportOfferId: number) {
    this.loading = true
    this._frozenFruitExportOfferService.getById(exportOfferId).subscribe({
      next: (v) => {
        this.selected = v
        this.loading = false
        this.visibleOfferDetailDialog = true
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.ExportOffer.SingleRetrieveError') })
      },
      complete: () => console.info('Frozen fruit export offer query successfully')
    })
  }

  getExportOfferPictureUrl(exportOffer: FrozenFruitExportOfferListItem): string | null {
    if(exportOffer.pictureUrl1)
      return environment.apiUrl + exportOffer.pictureUrl1

    if(exportOffer.pictureUrl2)
      return environment.apiUrl + exportOffer.pictureUrl2

    if(exportOffer.pictureUrl3)
      return environment.apiUrl + exportOffer.pictureUrl3

    return null
  }

  goToCreate() {
    this._router.navigate(['/' + Routes.userFrozenFruitExportOfferCreate])
  }

  goToEdit() {
    if(this.selected)
      this._router.navigate(['/' + Routes.userFrozenFruitExportOfferEdit, this.selected.id])
  }

  goToEditPictures() {
    if(this.selected)
      this._router.navigate(['/' + Routes.userFrozenFruitExportOfferEditPictures, this.selected.id])
  }

  goToEditDocuments() {
    if(this.selected)
      this._router.navigate(['/' + Routes.userFrozenFruitExportOfferEditDocuments, this.selected.id])
  }

  toogleIsActive(isActive: boolean) {
    if(this.selected) {
      this.loading = true
      this._frozenFruitExportOfferService.toogleIsActive(this.selected.id, isActive).subscribe({
        next: (v) => {
          this.loading = false
          this.loadExportOffers()
          if(isActive) {
            this._messageService.add({ severity: 'success',
                  summary: this.translate.instant('MessageService.Summary.SuccessAction'),
                  detail: this.translate.instant('UserPages.UserExportOffers.ActiveConfirmDialog.SuccessDetail') })
          }
          else {
            this._messageService.add({ severity: 'success',
                  summary: this.translate.instant('UserPages.UserExportOffers.DeactiveConfirmDialog.SuccessSumary'),
                  detail: this.translate.instant('UserPages.UserExportOffers.DeactiveConfirmDialog.SuccessDetail') })
          }
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({
            severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOffer.EditError') })
        },
        complete: () => console.info('Frozen fruit export offer updated successfully')
      })
    }
  }

  duplicate() {
    if(this.selected) {
      this.loading = true
      this._frozenFruitExportOfferService.duplicate(this.selected.id).subscribe({
        next: (v) => {
          this.loading = false
          this.selected = v
          this.loadExportOffers()
          this._messageService.add({ severity: 'success',
                  summary: this.translate.instant('MessageService.Summary.SuccessAction'),
                  detail: this.translate.instant('UserPages.UserExportOffers.DuplicateActionMessage.SuccessDetail') })
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({
            severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOffer.EditError') })
        },
        complete: () => console.info('Frozen fruit export offer updated successfully')
      })
    }
  }

  getBoxType(boxType: string): string {
    return this.profile ? ExportOfferUtils.getBoxTypeName(this.profile.lang, boxType) : ''
  }

  getIncludedLabel(included: boolean) {
    return included ? this.translate.instant('UserPages.UserExportOffers.Included') : this.translate.instant('UserPages.UserExportOffers.NotIncluded')
  }
}
