export class Lang {
  static SPANISH = 'es'
  static ENGLISH = 'en'

  static getBooleanLabel(lang: string, value?: boolean): string {
    if(value === undefined)
      return 'undefined'
    switch(lang) {
      case Lang.SPANISH:
        return value ? 'Si' : 'No'

      case Lang.ENGLISH:
        return value ? 'Yes' : 'No'

      default:
        return value ? 'Yes' : 'No'
    }
  }

  static getBooleanYesNoOptions(lang: string): any[] {
    const english = [{label: 'Yes', value: true}, {label: 'No', value: false}]
    switch(lang) {
      case Lang.SPANISH:
        return [{label: 'Si', value: true}, {label: 'No', value: false}]

      case Lang.ENGLISH:
        return english

      default:
        return english
    }
  }
}

