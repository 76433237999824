<diV class="grid">
    <div class="col-6">
      <div class="col-12">
        <h3>Costs</h3>
      </div>
      <div class="grid" style="font-size: small;">
        <div class="col-6">Subtotal:</div>
        <div class="col-6 flex justify-content-end" style="color: red; font-weight: bold; ">
          <span *ngIf="isClosedSale">
            {{ getPalletPrice() | currency:'USD':'US$' }}
          </span>
          <span *ngIf="!isClosedSale">
            {{ getOrderTotalPrice() | currency:'USD':'US$' }}
          </span>
        </div>
        <div class="col-6">Ocean freight:</div>
        <div class="col-6 flex justify-content-end" style="color: red; font-weight: bold;">{{delivery?.oceanFreight | currency:'USD':'US$'}}</div>
        <div class="col-6">Pickup:</div>
        <div class="col-6 flex justify-content-end" style="color: red; font-weight: bold;">{{delivery?.inland | currency:'USD':'US$'}}</div>
        <div class="col-6">Local charges:</div>
        <div class="col-6 flex justify-content-end" style="color: red; font-weight: bold;">{{delivery?.localCharges | currency:'USD':'US$'}}</div>
        <div class="col-6">Customs:</div>
        <div class="col-6 flex justify-content-end" style="color: red; font-weight: bold;">{{delivery?.customs | currency:'USD':'US$'}}</div>
        <div class="col-6">Surcharge:</div>
        <div class="col-6 flex justify-content-end" style="color: red; font-weight: bold;">{{delivery?.surcharge | currency:'USD':'US$'}}</div>
        <div class="col-6">Handling:</div>
        <div class="col-6 flex justify-content-end" style="color: red; font-weight: bold;">{{delivery?.handling | currency:'USD':'US$'}}</div>
        <div class="col-6">Gate out:</div>
        <div class="col-6 flex justify-content-end" style="color: red; font-weight: bold;">{{delivery?.gateOut | currency:'USD':'US$'}}</div>
        <div class="col-6">Others costs:</div>
        <div class="col-6 flex justify-content-end" style="color: red; font-weight: bold;">{{delivery?.otherCosts | currency:'USD':'US$'}}</div>
        <div class="col-6">QC Review:</div>
        <div class="col-6 flex justify-content-end" style="color: red; font-weight: bold;">{{qcReviewCost | currency:'USD':'US$'}}</div>
        <div class="col-6">Total cost:</div>
        <div class="col-6 flex justify-content-end"><span style="font-weight: bold;">{{totalCost | currency:'USD':'US$'}}</span></div>
      </div>
    </div>
    <div class="col-6">
      <div class="col-12">
        <h3>Discounts</h3>
      </div>
      <div class="grid" style="font-size: small;">
        <div class="col-12">
          <p-divider></p-divider>
        </div>
        <ng-container *ngFor="let discount of discounts">
          <div class="col-6">{{discount.description}}:</div>
          <div class="col-6 flex justify-content-end" [style]="{'color': discount.amount >= 0 ? 'green' : 'red'}">{{discount.amount | currency:'USD':'US$'}}</div>
        </ng-container>
        <div class="col-6">Total discounts:</div>
        <div class="col-6 flex justify-content-end"><span style="font-weight: bold;">{{totalDiscounts | currency:'USD':'US$'}}</span></div>
      </div>
    </div>
    <div class="col-6">
      <div class="grid" style="font-size: small;">
        <div class="col-12">
          <p-divider></p-divider>
        </div>
        <div class="col-12">
          <h3>Payments</h3>
        </div>
        <ng-container *ngFor="let payment of payments">
          <div class="col-4 flex justify-content-center align-items-center">{{payment.description}}:</div>
          <div class="col-4 flex justify-content-center align-items-center" style="color: green; font-weight: 600;">{{payment.amount | currency:'USD':'US$'}}</div>
          <div class="col-4 flex justify-content-center align-items-center">
            <p-button *ngIf="payment.id" (onClick)="getPaymentDocument(payment.id)" pTooltip="View PDF" icon="pi pi-file-pdf" styleClass="p-button-link p-button-lg"></p-button>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="col-6">
      <div class="col-12">
        <p-divider></p-divider>
      </div>
      <div class="grid">
        <div class="col-6">Total cost:</div>
        <div class="col-6 flex justify-content-end"><span style="font-weight: bold;">{{ totalCost | currency:'USD':'US$'}}</span></div>
        <div class="col-6">Total discounts:</div>
        <div class="col-6 flex justify-content-end"><span style="font-weight: bold;">{{totalDiscounts | currency:'USD':'US$'}}</span></div>
        <!-- <div class="col-6">Total  payments Origin Qc:</div>
        <div class="col-6 flex justify-content-end"><span style="font-weight: bold;">{{ totalPaymentsQc | currency:'USD':'US$'}}</span></div> -->
        <div class="col-6">Total payment:</div>
        <div class="col-6 flex justify-content-end"><span style="font-weight: bold;">{{ totalPayments | currency:'USD':'US$'}}</span></div>
        <div class="col-6" style="font-weight: 600;">Balance:</div>
        <div class="col-6 flex justify-content-end"><span style="font-weight: bold;">{{ absoluteBalance | currency:'USD':'US$'}}</span></div>
      </div>
    </div>
  </diV>
<!-- <div class="col-12 md:col-6 lg:col-6">
      <div>
        <p-card>
          <h2>{{'UserPages.UserPurchaseOrders.PaymentsPage.PaymentTitleText' | translate}} &nbsp;<i class="pi pi-dollar" style="font-size: 1.5rem;"></i></h2>
          <div class="grid">
            <ng-container *ngFor="let payment of payments">
              <div class="col-6 flex align-items-center">{{payment.description}}:</div>
              <div class="col-3 flex align-items-center">{{payment.amount | currency:'USD':'US$'}}</div>
              <div class="col-3 flex justify-content-end align-items-center">
                <i *ngIf="payment.id" (click)="getPaymentDocument(payment.id)" pTooltip="Document PDF" class="pi pi-file-pdf" style="font-size: 2rem; cursor: pointer;"></i>
              </div>
            </ng-container>
          </div>
          <div class="grid" style="margin-top: 20px;">
            <div class="col-6">{{'UserPages.UserPurchaseOrders.PaymentsPage.PaymentMethodText' | translate}}:</div>
            <div class="col-6">{{'UserPages.UserPurchaseOrders.PaymentsPage.PaymentSwiftText' | translate}}</div>
            <div class="col-12">
              <div class="w-full flex justify-content-end">
                <p-button *ngIf="profile?.profileType == 'Importer' && (importOrder?.status == 3 || importOrder?.status == 4)" icon="pi pi-money-bill" label="{{'UserPages.UserPurchaseOrders.PaymentsPage.OrderDetailPage.PaymentButton' | translate}}" (onClick)="goToPayments()"></p-button>
              </div>
            </div>
          </div>
        </p-card>
      </div>
  </div> -->
