import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormGroupDirective, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, ConfirmEventType, MenuItem, MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { ExportOfferGateway } from 'src/app/application/gateway/ExportOfferGateway';
import { UserJoinRequestGateway } from 'src/app/application/gateway/UserJoinRequestGateway';
import { ExportOffer, ExportOfferInformation } from 'src/app/domain/models/ExportOffer';
import { ProductCertificateType } from 'src/app/domain/models/ProductCertificateType';
import { BasicUser } from 'src/app/domain/models/User';
import { UserJoinRequest } from 'src/app/domain/models/UserJoinRequest';
import { Routes } from 'src/app/infrastructure/routes';
import { ExportOfferUtils } from 'src/app/ui/util/export-offer-utils';
import { environment } from '../../../../../environments/environment'
import { Packaging } from 'src/app/domain/models/Packaging';
import { ErrorMessageUtil } from 'src/app/ui/util/error-message-util';
import { Exporter, Importer } from 'src/app/domain/models/Profile';
import { Consignee } from 'src/app/domain/models/Consignee';
import { Notifier } from 'src/app/domain/models/Notifier';
import { ConsigneeGateway } from 'src/app/application/gateway/ConsigneeGateway';
import { NotifierGateway } from 'src/app/application/gateway/NotifierGateway';
import { ProfileGateway } from 'src/app/application/gateway/ProfileGateway';
import { Country } from 'src/app/domain/models/Country';
import { CountryGateway } from 'src/app/application/gateway/CountryGateway';
import { PortGateway } from 'src/app/application/gateway/PortGateway';
import { Port } from 'src/app/domain/models/Port';
import { DirectSaleProgramGateway } from 'src/app/application/gateway/DirectSaleProgramGateway';
import { DirectSaleProgram, DirectSaleProgramCreateRequest } from 'src/app/domain/models/DirectSaleProgram';
import { Species } from 'src/app/domain/models/Species';
import { Variety } from 'src/app/domain/models/Variety';
import { SpeciesGateway } from 'src/app/application/gateway/SpeciesGateway';
import { VarietyGateway } from 'src/app/application/gateway/VarietyGateway';
import { SpeciesUtils } from 'src/app/ui/util/species-utils';
import { FridgeStorageGateway } from 'src/app/application/gateway/FridgeStorageGateway';
import { FridgeStorage } from 'src/app/domain/models/FridgeStorage';
import { ProgramRequest, ProgramRequestInformation, UpdateProgramRequest } from 'src/app/domain/models/ProgramRequest';
import { ProgramRequestGateway } from 'src/app/application/gateway/ProgramRequestGateway';
import { ProgramRequestUtil } from 'src/app/ui/util/programRequestUtil';
import { statusInterface } from 'src/app/ui/util/filter-util';
import { formLimit } from 'src/app/ui/util/form-limit';

@Component({
  selector: 'app-user-closed-sale',
  templateUrl: './user-closed-sale.component.html',
  styleUrls: ['./user-closed-sale.component.css'],
  providers: [ConfirmationService, MessageService]
})
export class UserClosedSaleComponent implements OnInit {
  items: MenuItem[] = []
  offerMenuItems: MenuItem[] = []
  editMenu: MenuItem[] = []
  viewMenu: MenuItem[] = []
  offers: ExportOfferInformation[] = []
  selected: ExportOfferInformation | undefined
  visibleOfferDetailDialog: boolean = false
  loading: boolean = false
  visibleInvite: boolean = false
  submit: boolean = false
  directSaleProgramModalVisible: boolean = false

  profile: BasicUser | undefined

  selectedExportOffer: ExportOffer | undefined
  selectedExportOfferInformation: ExportOfferInformation | undefined
  selectedExportOfferId: number | undefined
  selectedProductCertificateTypes: ProductCertificateType[] = []
  selectedPackagingTypes: Packaging[] = []
  selectedProgramRequest: ProgramRequestInformation | undefined

  importers: Importer[] = []
  consignees: Consignee[] = []
  notifies: Notifier[] = []
  incotermsOptions: any[] = []
  countries: Country[] = []
  ports: Port[] = []
  fridgeStorages: FridgeStorage[] = []


  varieties: Variety[] = []
  species: Species[] = []
  filterSpecies: Species[] = []
  filterVarieties: Variety[] = []
  selectedSpecies: Species | undefined
  selectedVariety: Variety | undefined
  selectedCountryFrom: Country | undefined
  visibleProgramRequestDetailDialog: boolean = false

  programRequestInfo: ProgramRequestInformation[] = []

  visibleDeleteConfirmOne: boolean = false;
  visibleDeleteConfirmTwo: boolean = false;
  visibleCreateConfirm: boolean = false;
  exporters: Exporter[] = []
  
  selectGuid: string | undefined


  filterForm: FormGroup = this._formBuilder.group({
    speciesId: [''],
    varietyId: [''],
    date: [''],
    isActive: [undefined],
    importerId: [''],
    exporterId: [''],
    exportOfferId: ['']
  })

  filterPurchaseForm: FormGroup = this._formBuilder.group({
    speciesId: [''],
    varietyId: [''],
    date: [''],
    isActive: [null],
    importerId: [''],
    exportOfferId: ['']
  })

  othersFiltersPurchaseOrderForm: FormGroup = this._formBuilder.group({
    kilosPerBox: [''],
    marketId: [''],
    speciesSize: [''],
    price: [''],
  })

  othersFiltersForm: FormGroup = this._formBuilder.group({
    harvestDate: [''],
    kilosPerBox: [''],
    marketId: [''],
    coldStorage: [''],
    speciesSize: [''],
    shelfLife: [''],
    price: [''],
    fridgeStorageId: ['']

  })

  statusArray: any[] = []
  programRequestStatusItem: statusInterface[] = []

  sidebarVisible: boolean = false
  sidebarPurchaseOrderVisible: boolean = false

  selectedCountryTo: Country | undefined
  selectedDeleteProgramGuid: string = '';

  filterFormValue: any = {}



  userJoinRequestFormGroup: FormGroup = this._formBuilder.group({
    guestEmail: ['', [Validators.required, Validators.email]],
  })

  directSaleProgramFormGroup: FormGroup = this._formBuilder.group({
    importerId: [null, Validators.required],
    consigneeId: [null, Validators.required],
    notifyId: [null, Validators.required],
    containersPerWeek: [null, Validators.required],
    startWeek: [null, [Validators.required]],
    startWeekYear: [this.currentYear, [Validators.required]],
    endWeek: [null],
    endWeekYear: [null],
    importToCountryId: [null, Validators.required],
    portToId: [null, Validators.required]
  },
  {
    validators: [this.endWeekValidator(), this.endWeekYearValidator()]
  })

  formLimit = formLimit

  get currentYear(): number {
    return new Date().getFullYear()
  }

  constructor(private _router: Router,
    private _messageService: MessageService,
    private _confirmationService: ConfirmationService,
    private _formBuilder: FormBuilder,
    private _authService: AuthGateway,
    private _exportOfferService: ExportOfferGateway,
    private _userJoinRequestService: UserJoinRequestGateway,
    public translate: TranslateService,
    private _profileService: ProfileGateway,
    private _consigneeService: ConsigneeGateway,
    private _notifyService: NotifierGateway,
    private _countryService: CountryGateway,
    private _portService: PortGateway,
    private _directSaleProgramService: DirectSaleProgramGateway,
    private _speciesService: SpeciesGateway,
    private _varietyService: VarietyGateway,
    private _fridgeStorageService: FridgeStorageGateway,
    private _programRequestService: ProgramRequestGateway
  ) {

    this.profile = this._authService.getUser()
    this.statusArray = [
      { label: this.profile?.lang === "es" ? "Activa" : "Active", value: 1 },
      { label: this.profile?.lang === "es" ? "Inactiva" : "Inactive", value: 0 },

    ]

    this.programRequestStatusItem = [
      { label: this.profile?.lang === "es" ? "Activa" : "Active", value: 0 },
      { label: this.profile?.lang === "es" ? "Solicitado" : "Required", value: 1 },
      { label: this.profile?.lang === "es" ? "Rechazado" : "Rejected", value: 2 }
    ]
  }

  ngOnInit(): void {
    let offerMenuItem1Label = 'View program'
    let offerMenuItem2Label = 'Edit'
    let offerMenuItem3Label = 'Documents'
    let offerMenuItem4Label = 'Desactive'
    let offerMenuItem5Label = 'Edit additional info'

    this.profile = this._authService.getUser()
    if(this.profile) {
      switch(this.profile.lang) {
        case 'en':
          offerMenuItem1Label = 'View program'
          offerMenuItem2Label = 'Edit'
          offerMenuItem4Label = 'Desactive'
          offerMenuItem5Label = 'Edit additional info'
          break
        case 'es':
          offerMenuItem1Label = 'Ver programación'
          offerMenuItem2Label = 'Editar'
          offerMenuItem4Label = 'Desactivar'
          offerMenuItem5Label = 'Editar información complementaria'
          break
      }
    }

    // this.offerMenuItems = [
    //   { label: offerMenuItem2Label, command: () => { this.goToEditClosedSalePage() } },
    //   { label: offerMenuItem3Label, command: () => { this.goToDirectSaleDocumentsPage() } },
    //   { label: offerMenuItem5Label, command: () => { this.goToDirectSaleEditAdditionalInfoPage(this.selectGuid) }  },
    //   { label: offerMenuItem4Label, command: () => { this.desactiveSelected() }  },
    // ]


    this.editMenu = [
      {
        label: 'Editar'
      },
      {
        label: 'Información complementaria'
      }
    ]

    this.viewMenu = 
    [

    ]

    this.loadExportOffers()
    this.loadDirectSaleImporter()
    this.loadSpecies()
    this.loadImporters()
    this.getExporters()
    this.getCountries()
    this.getFridgeStorages()
    this.getAllProgramRequest()
  }

  loadConsigneesNotifies() {
    this.loadConsignees()
    this.loadNotifies()
  }

  loadConsignees() {
    if(this.selectedExportOfferInformation) {
      this._consigneeService.getAllForClosedSale(this.selectedExportOfferInformation.directSalePrograms[0].importerId).subscribe({
        next: (v) => {
          this.consignees = v
          this.loading = false
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudieron recuperar los consignees.'})
        },
        complete: () => console.info('Consignees query successfully')
      })
    }
  }

  loadNotifies() {
    if(this.selectedExportOfferInformation) {
      this._notifyService.getAllForClosedSale(this.selectedExportOfferInformation.directSalePrograms[0].importerId).subscribe({
        next: (v) => {
          this.notifies = v
          this.loading = false
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudieron recuperar los notify.'})
        },
        complete: () => console.info('Notifies query successfully')
      })
    }
  }

  loadPorts() {
    if(this.selectedExportOfferInformation)
      {
        
        this._portService.getDestinationPortsByCountry(this.selectedExportOfferInformation.directSalePrograms[0].destinationCountryId).subscribe({
          next: (v) => {
            this.ports = v
          },
          error: (e) => {
            console.log(e)
            this.loading = false
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.Port.DestinationPortManyRetrieveError') })
          },
          complete: () => console.info('Destination Ports query successfully')
        })
      }  
  }

  selectProgramRequestDetail(programRequestInfo: ProgramRequestInformation)
  {
    this.selectedProgramRequest = programRequestInfo; 
    this.visibleProgramRequestDetailDialog = true
  }

  deactivateProgram() {

    this._directSaleProgramService.desactive(this.selectedDeleteProgramGuid).subscribe({
      next: (v) => {
        this.loading = false
        this._messageService.add({ 
        severity: 'success', summary: this.translate.instant('MessageService.Summary.SuccessAction'), 
        detail: 'Se deshabilito el programa correctamente.'
      })
      this.loadExportOffers()
      this.visibleDeleteConfirmTwo = false;
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudieron eliminar las orden.'})
      },
      complete: () => console.info('Importers query successfully')
    })

  }

  loadSpecies() {
    this._speciesService.getAllByProductType(1).subscribe({
      next: (v) => {
        this.species = v
        this.loading = false
      },
      error: (e) => {
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Species.ManyRetrieveError') })
      },
      complete: () => console.info('Species query successfully')
    })
  }

  getMenuItemsForView(offer: ExportOfferInformation): MenuItem[] {
    this.profile = this._authService.getUser()
    let menu :MenuItem[] = []
    if(this.profile) {
      switch(this.profile.lang) {
        case "es":
          menu = [
            {
              label: 'Ver Oferta', command: () => this.showOfferDetailDialog(offer)
            },
            {
              label: 'Ver Documentación', command: () => this.goToDirectSaleDocumentsPage(offer.exportOffer.id)
            }
          ]
          break;
        default:
          menu = [
            {
              label: 'View Offer', command: () => this.showOfferDetailDialog(offer)
            },
            {
              label: 'See Documentation', command: () => this.goToDirectSaleDocumentsPage(offer.exportOffer.id)
            }
          ]
      }
    }
    return menu;
}

  getMenuItemsForEdit(offer: ExportOfferInformation): MenuItem[] {
    this.profile = this._authService.getUser()
    let menu :MenuItem[] = []
    if(this.profile) {
      switch(this.profile.lang) {
        case "es":
          menu = [
            {
              label: 'Editar', command: () => this.goToEditClosedSalePage(offer.directSalePrograms[0].guid)
            },
            {
              label: 'Información complementaria', command: () => this.goToDirectSaleEditAdditionalInfoPage(offer.exportOffer.guid) 
            }
          ]
          break;
        default:
          menu = [
            {
              label: 'Edit', command: () => this.goToEditClosedSalePage(offer.directSalePrograms[0].guid)
            },
            {
              label: 'Additional information', command: () => this.goToDirectSaleEditAdditionalInfoPage(offer.exportOffer.guid) 
            }
          ]
      }
    }

    return menu;
  }

  getMenuItemsForCopy(offer: ExportOfferInformation): MenuItem[]
  {
    this.profile = this._authService.getUser()
    let menu :MenuItem[] = []
    if(this.profile) {
      switch(this.profile.lang) {
        case "es":
          menu = [
            {
              label: 'Duplicar', command: () => this.showDirectSaleProgramCreateModal(offer)
            },
          ]
          break;
        default:
          menu = [
            {
              label: 'Duplicate', command: () => this.showDirectSaleProgramCreateModal(offer)
            },
          ]
      }
    }

    return menu;
  }

  getMenuItemForDelete(offer: ExportOfferInformation): MenuItem[]
  {
    this.profile = this._authService.getUser()
    let menu :MenuItem[] = []
    if(this.profile) {
      switch(this.profile.lang) {
        case "es":
          menu = [
            {
              label: 'Eliminar', command: () => this.showFirstConfirm(offer.directSalePrograms[0].guid)
            },
          ]
          break;
        default:
          menu = [
            {
              label: 'Delete', command: () => this.showFirstConfirm(offer.directSalePrograms[0].guid)
            },
          ]
      }
    }
    return menu;
  }

  loadVarieties() {
    this.selectedSpecies = this.species.find(x => x.id === this.filterForm.controls['speciesId'].value)
    this.varieties = []
    if(this.selectedSpecies)
    {
      this._varietyService.getAllBySpecies(this.filterForm.controls['speciesId'].value).subscribe({
        next: (v) => {
          this.varieties = v
          this.loading = false
          this.executeFilter()
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.Variety.ManyRetrieveError') })
        },
        complete: () => console.info('Variety query successfully')
      })
    }else{
      this.executeFilter()
    }

  }

  loadImporters() {
    this.importers = []
    if(this.profile) {
      if(this.profile.type == 1)
        {
          this._profileService.getAllImportersByExporterId().subscribe({
            next: (v) => {
              this.importers = v
              this.loading = false
            },
            error: (e) => {
              console.log(e)
              this.loading = false
              this._messageService.add({ severity: 'error',
                summary: this.translate.instant('MessageService.Summary.FailedAction'),
                detail: this.translate.instant('MessageService.Importer.ManyRetrieveError') })
            },
            complete: () => console.info('Importers query successfully')
          })
        }
    }
  }

  onChangeVariety() {
    this.selectedVariety = this.varieties.find(x => x.id === this.filterForm.controls['varietyId'].value)
    this.executeFilter()
  }

  getSpeciesName(species: Species): string {
    if(this.profile)
      return SpeciesUtils.getSpeciesName(this.profile.lang, species)
    return ''
  }

  showModalCreateConfirm()
  {
    this.visibleCreateConfirm = true;
  }

  loadFilterVarieties(){
    this.filterVarieties= []
    if(this.offers) {
      this.offers.forEach(o => {
        const variety = this.filterSpecies.find(x => x.id === o.exportOffer.varietyId)
        if(!variety)
          this.filterVarieties.push(o.exportOffer.variety)
      })
    }
  }


  loadExportOffers() {

    if(this.profile?.type == 1){
      this.loading = true
      this._exportOfferService.getAllClosedSaleByExporter(this._authService.getUsername()).subscribe({
        next: (v) => {
          this.offers = v
          this.configureOrders()
          this.loading = false
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOffer.ManyRetrieveError') })
        },
        complete: () => console.info('Export Offers query successfully')
      })
    }

  }

  loadDirectSaleImporter()
  {
    if(this.profile?.type == 2)
      {
        this.loading = true
        this._directSaleProgramService.getDirectSaleImporter().subscribe({
          next: (v) => {
            console.log(v)
            this.offers = v
            this.configureOrders()
            this.loading = false
          },
          error: (e) => {
            console.log(e)
            this.loading = false
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.ExportOffer.ManyRetrieveError') })
          },
          complete: () => console.info('Export Offers query successfully')
        })
      }
  }

  getFridgeStorages() {
    this.fridgeStorages = []
    this._fridgeStorageService.getAllByStorageType(1).subscribe({
      next: (v) => {
        this.fridgeStorages = v
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.FridgeStorage.ManyRetrieveError') })
      },
      complete: () => console.info('Fridge Storage query successfully')
    })
  }

  executeFilter()
  {
    this.loading = true
    let formData = this.filterForm.value;
    formData['exportUsername'] = this._authService.getUsername()
    formData['harvestDate'] = this.othersFiltersForm.controls['harvestDate'].value
    formData['kilosPerBox'] = this.othersFiltersForm.controls['kilosPerBox'].value
    formData['marketId'] = this.othersFiltersForm.controls['marketId'].value
    formData['speciesSize'] = this.othersFiltersForm.controls['speciesSize'].value
    formData['shelfLife'] = this.othersFiltersForm.controls['shelfLife'].value
    formData['price'] = this.othersFiltersForm.controls['price'].value
    formData['fridgeStorageId'] = this.othersFiltersForm.controls['fridgeStorageId'].value

    console.log(formData['isActive']);

    if(formData['isActive'] == null)
    {
      formData['isActive'] = 1;
    }

    this.selectedCountryFrom =this.countries.find(x => x.id == this.othersFiltersForm.controls['marketId'].value);

    if(this.profile)
      {
        if(this.profile.type == 1){
          this.executeFilterExporter(formData)
        }else{
          this.executeFilterImporter(formData)
        }
      }
  }

  executeFilterExporter(formData: any)
  {
    this._exportOfferService.getAllClosedSaleByExporterFilter(formData).subscribe({
      next: (v) => {
        this.offers = v
        this.configureOrders()
        this.loading = false
      },
      error: (e) => {
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.ExportOffer.ManyRetrieveError') })
      },
      complete: () => console.info('Export Offers query successfully')
    })
  }



  executeFilterImporter(formData: any)
  {
    this._directSaleProgramService.getDirectSaleImporterFilter(formData).subscribe({
      next: (v) => {
        this.offers = v
        this.configureOrders()
        this.loading = false
      },
      error: (e) => {
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.ExportOffer.ManyRetrieveError') })
      },
      complete: () => console.info('Export Offers query successfully')
    })
  }

  getExporters() {
    this.exporters = []
    this.loading = true
    if(this.profile)
       {
        if(this.profile.type == 2)
          {
            this._profileService.getAllExportersByImporterId().subscribe({
              next: (v) => {
                this.exporters = v
                this.loading = false
              },
              error: (e) => {
                console.log(e)
                this.loading = false
                this._messageService.add({ severity: 'error',
                  summary: this.translate.instant('MessageService.Summary.FailedAction'),
                  detail: this.translate.instant('MessageService.Importer.ManyRetrieveError') })
              },
              complete: () => console.info('Exporters query successfully')
            })
          }
        }
  }

  setVisibleOrderDetail(offer: ExportOfferInformation) {
    if (offer) {
      offer.expanded = !offer.expanded
    }
  }

  setVisibleProgramRequestDetail(programRequest: ProgramRequestInformation) {
    if (programRequest) {
      programRequest.expanded = !programRequest.expanded
    }
  }

  configureOrders(): void {
    if(this.offers) {
      this.offers.forEach(o => {
        o.expanded = false
      })
    }
  }

  setSelected(offer: ExportOfferInformation) {
    offer.exportOffer.pictures = offer.pictures ?  offer.pictures : null;
    this.selected = offer
    this.offerMenuItems.forEach(i => {
      i.disabled = this.selected && !this.selected.exportOffer.isActive
    })
  }

  getImageUrl(offer: ExportOfferInformation): string {
    return offer.pictures && offer.pictures.length > 0 ? environment.apiUrl + offer.pictures[0].url : ''
  }

  showOfferDetailDialog(offer: ExportOfferInformation) {
    this.setSelected(offer)
    if(this.selected) {
      this.visibleOfferDetailDialog = true
    }
    this._exportOfferService.getById(offer.exportOffer.id).subscribe({
      next: (v) => {
        this.selectedExportOffer = v
        if(this.selectedExportOffer.productCertificateTypes)
          this.selectedProductCertificateTypes = this.selectedExportOffer.productCertificateTypes

        if(this.selectedExportOffer.packagingTypes)
          this.selectedPackagingTypes = this.selectedExportOffer.packagingTypes

        this.loading = false
        this.visibleOfferDetailDialog = true
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.ExportOffer.ManyRetrieveError') })
      },
      complete: () => console.info('Export Offers query successfully')
    })
  }

  showFirstConfirm(directSaleProgramGuid: string)
  {
    this.selectedDeleteProgramGuid = directSaleProgramGuid;
    this.visibleDeleteConfirmOne = true;
  }

  showSecondConfirm()
  {
    this.visibleDeleteConfirmOne = false;
    this.visibleDeleteConfirmTwo = true;
  }
  
  closeOfferDetailDialog() {
    this.visibleOfferDetailDialog = false
  }

  goToDirectSaleCreatePage() {
    this._router.navigate(['/' + Routes.userDirectSaleCreate])
  }

  goToEditClosedSalePage(directSaleProgramId: string) {
    // if(this.selected) {
      this._router.navigate(['/' + Routes.userDirectSaleCreate, {directSaleProgramId}])
    // }
  }

  goToDirectSaleProgramOrders(directSaleProgramId: number) {
    this._router.navigate(['/' + Routes.exporterClosedSaleOrdersPage, directSaleProgramId])
  }

  goToDirectSaleDocumentsPage(id: number) {
      this._router.navigate(['/' + Routes.userDirectSaleDocuments, id])
  }

  goToDirectSaleEditAdditionalInfoPage(guid: string) {
    this.selectGuid = guid;
      this._router.navigate(['/' + Routes.userDirectSaleEditAdditionalInfo, guid])
  }

  cleanFilter()
  {


    this.filterForm.controls['speciesId'].setValue('')
    this.filterForm.controls['varietyId'].setValue('')
    this.filterForm.controls['date'].setValue('')
    this.filterForm.controls['isActive'].setValue('')
    this.filterForm.controls['importerId'].setValue('')
    this.filterForm.controls['exportOfferId'].setValue('')

    this.othersFiltersForm.controls['fridgeStorageId'].setValue('')
    this.othersFiltersForm.controls['harvestDate'].setValue('')
    this.othersFiltersForm.controls['kilosPerBox'].setValue('')
    this.othersFiltersForm.controls['marketId'].setValue('')
    this.othersFiltersForm.controls['speciesSize'].setValue('')
    this.othersFiltersForm.controls['shelfLife'].setValue('')
    this.othersFiltersForm.controls['price'].setValue('')

    this.executeFilter()

  }

  desactiveSelected() {
    if(this.selected) {
      this._confirmationService.confirm({
        message: this.translate.instant('UserPages.UserExportOffers.DeactiveConfirmDialog.Message'),
        header: this.translate.instant('UserPages.UserExportOffers.DeactiveConfirmDialog.Header'),
        icon: 'pi pi-info-circle',
        accept: () => {
          if(this.selected) {
            this._exportOfferService.desactive(this.selected.exportOffer.id).subscribe({
              next: (v) => {
                this._messageService.add({ severity: 'info',
                  summary: this.translate.instant('MessageService.Summary.SuccessAction'),
                  detail: this.translate.instant('UserPages.UserExportOffers.DeactiveConfirmDialog.SuccessDetail') })
                this.loadExportOffers()
              },
              error: (e) => {
                console.log(e)
                this._messageService.add({ severity: 'error',
                  summary: this.translate.instant('MessageService.Summary.FailedAction'),
                  detail: this.translate.instant('UserPages.UserExportOffers.DeactiveConfirmDialog.ErrorDetail') });
              },
              complete: () => console.info('Notifier delete successfully')
            })
          }
        },
        reject: (type: any) => {
            switch (type) {
                case ConfirmEventType.REJECT:
                    //this._messageService.add({ severity: 'error', summary: 'Rejected', detail: 'Se canceló la eliminación' })
                    break;
                case ConfirmEventType.CANCEL:
                    //this._messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Se canceló la eliminación' })
                    break;
            }
        }
      })
    }
  }

  saveUserJoinRequest() {
    this.loading = true
    this.submit = true
    if(this.userJoinRequestFormGroup.valid) {
      let request: UserJoinRequest = {
        hostUsername: this._authService.getUsername(),
        guestEmail: this.userJoinRequestFormGroup.controls['guestEmail'].value.toString().trim()
      }

      this._userJoinRequestService.create(request).subscribe({
        next: (v) => {
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: this.translate.instant('MessageService.UserJoinRequest.CreateSuccess') });
          this.submit = false
          this.loading = false
          this.visibleInvite = false
        },
        error: (e) => {
          this.loading = false
          console.log(e)

          const responseError = ErrorMessageUtil.getResponseError(e.error, this.profile?.lang);
          let errorDetail = responseError ? responseError : this.translate.instant('MessageService.UserJoinRequest.CreateError')

          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail:  errorDetail});
        },
        complete: () => console.info('User Join Request created successfully')
      })
    }
    else {
      this.loading = false
    }
  }

  getErrorMessage(fieldName: string): string {
    if (this.userJoinRequestFormGroup.controls[fieldName].hasError('required')) {
      return this.translate.instant('FormErrorMessage.FieldValueRequired')
    }
    if (this.userJoinRequestFormGroup.controls[fieldName].hasError('email')) {
      return this.translate.instant('FormErrorMessage.EmailFieldEmailFormatError')
    }
    return ''
  }

  getBoxType(boxType: string): string {
    return this.profile ? ExportOfferUtils.getBoxTypeName(this.profile.lang, boxType) : ''
  }

  getIncludedLabel(included: boolean) {
    return included ? this.translate.instant('UserPages.UserExportOffers.Included') : this.translate.instant('UserPages.UserExportOffers.NotIncluded')
  }

  showDirectSaleProgramCreateModal(offer: ExportOfferInformation): void {
    
    this.selectedExportOfferInformation = offer
    this.selectedExportOfferId = offer.exportOffer.id
    this.getImporters()
    this.loadConsigneesNotifies()
    this.loadPorts()
    this.selectedCountryTo = this.countries.find(x => x.id == offer.directSalePrograms[0].destinationCountryId) 
    this.directSaleProgramFormGroup.controls['importerId'].setValue(offer.directSalePrograms[0].importerId)
    this.directSaleProgramFormGroup.controls['consigneeId'].setValue(offer.directSalePrograms[0].consigneeId)
    this.directSaleProgramFormGroup.controls['notifyId'].setValue(offer.directSalePrograms[0].notifyId)
    //this.directSaleProgramFormGroup.controls['startWeek'].setValue(offer.directSalePrograms[0].startWeek)
    //this.directSaleProgramFormGroup.controls['startWeekYear'].setValue(offer.directSalePrograms[0].startWeekYear)
    this.directSaleProgramFormGroup.controls['containersPerWeek'].setValue(offer.directSalePrograms[0].containersPerWeek)
    this.directSaleProgramFormGroup.controls['importToCountryId'].setValue(offer.directSalePrograms[0].destinationCountryId)
    //this.directSaleProgramFormGroup.controls['portToId'].setValue(offer.directSalePrograms[0].dischargePortId)
    //this.directSaleProgramFormGroup.controls['endWeek'].setValue(offer.directSalePrograms[0].endWeek)
    //this.directSaleProgramFormGroup.controls['endWeekYear'].setValue(offer.directSalePrograms[0].endWeekYear)
    this.loading = false
    this.submit = false
    this.directSaleProgramModalVisible = true
  }

  getCountries() {
    this.countries = []
    this._countryService.getAll().subscribe({
      next: (v) => {
        this.countries = v
        this.loading = false
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Country.ManyRetrieveError') })
      },
      complete: () => console.info('Country query successfully')
    })
  }

  onChangeImportToCountry() {
    this.getPorts()
  }

  getPorts() {
    this.selectedCountryTo = this.countries.find(x => x.id == this.directSaleProgramFormGroup.controls['importToCountryId'].value)

    if(this.directSaleProgramFormGroup.controls['importToCountryId'].value) {
      this._portService.getDestinationPortsByCountry(this.directSaleProgramFormGroup.controls['importToCountryId'].value).subscribe({
        next: (v) => {
          this.ports = v
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.Port.DestinationPortManyRetrieveError') })
        },
        complete: () => console.info('Destination Ports query successfully')
      })
    }
  }

  endWeekYearValidator(): ValidatorFn {
    return (control: AbstractControl) : ValidationErrors | null => {
      if(!this.directSaleProgramFormGroup)
        return null

      if(!this.directSaleProgramFormGroup.controls['startWeekYear'].value)
        return null

      if(!this.directSaleProgramFormGroup.controls['endWeekYear'].value)
        return null

      let startWeekYear = this.directSaleProgramFormGroup.controls['startWeekYear'].value
      let endWeekYear = this.directSaleProgramFormGroup.controls['endWeekYear'].value

      return endWeekYear && startWeekYear > endWeekYear ? {endWeekYearRangeError:true}: null;
    }
  }

  endWeekValidator(): ValidatorFn {
    return (control: AbstractControl) : ValidationErrors | null => {
      if(!this.directSaleProgramFormGroup)
        return null

      if(!this.directSaleProgramFormGroup.controls['startWeekYear'].value)
        return null

      if(!this.directSaleProgramFormGroup.controls['endWeekYear'].value)
        return null

      if(!this.directSaleProgramFormGroup.controls['startWeek'].value)
        return null

      if(!this.directSaleProgramFormGroup.controls['endWeek'].value)
        return null

      let startWeek = this.directSaleProgramFormGroup.controls['startWeek'].value
      let endWeek = this.directSaleProgramFormGroup.controls['endWeek'].value
      let startWeekYear = this.directSaleProgramFormGroup.controls['startWeekYear'].value
      let endWeekYear = this.directSaleProgramFormGroup.controls['endWeekYear'].value

      return (startWeekYear === endWeekYear && startWeek > endWeek) ? {endWeekRangeError:true} : null;
    }
  }

  years: any[] = []
  getYears() {
    let current = new Date().getFullYear()
    this.years.push({label: current, value: current})
    let next = current + 1
    this.years.push({label: next, value: next})
  }

  getImporters() {
    this.importers = []
    this.loading = true
    if(this.profile) {
      this._profileService.getAllImportersByExporterId().subscribe({
        next: (v) => {
          this.importers = v
          this.incotermsOptions = ExportOfferUtils.getIncotermsClosedSaleOptions()
          this.getYears()
          this.loading = false
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.Importer.ManyRetrieveError') })
        },
        complete: () => console.info('Importers query successfully')
      })
    }
  }

  onChangeImporter() {
    this.getConsignees()
    this.getNotifies()
  }

  filterProgramRequestEvent(event: any){
    this.filterFormValue = event

    this.executeFilterProgramRequest();
  }

  executeFilterProgramRequest()
  {
    this.loading = true

    this.filterFormValue['kilosPerBox'] = this.othersFiltersPurchaseOrderForm.controls['kilosPerBox'].value
    this.filterFormValue['marketId'] = this.othersFiltersPurchaseOrderForm.controls['marketId'].value
    this.filterFormValue['speciesSize'] = this.othersFiltersPurchaseOrderForm.controls['speciesSize'].value
    this.filterFormValue['price'] = this.othersFiltersPurchaseOrderForm.controls['price'].value

    this._programRequestService.getAllByFilter(this.filterFormValue).subscribe(
      {
        next: (v) => {
          this.programRequestInfo = v
          this.loading = false
        },
        error: (e) => {
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ProgramRequest.ManyRetrieveError') })
        }
      }
    )
    this.loading = false
  }

  showAdvancedFilter(event: any)
  {
    this.filterFormValue = event
    this.sidebarPurchaseOrderVisible = true;
  }

  cleanFilterPurchaseOrder()
  {
    
    this.othersFiltersPurchaseOrderForm.controls['kilosPerBox'].setValue('')
    this.othersFiltersPurchaseOrderForm.controls['marketId'].setValue('')
    this.othersFiltersPurchaseOrderForm.controls['speciesSize'].setValue('')
    this.othersFiltersPurchaseOrderForm.controls['price'].setValue('')
  }

  getConsignees() {
    if(this.directSaleProgramFormGroup.controls['importerId'].value) {
      this._consigneeService.getAllForClosedSale(this.directSaleProgramFormGroup.controls['importerId'].value).subscribe({
        next: (v) => {
          this.consignees = v
          this.loading = false
        },
        error: (e) => {
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.Consignee.ManyRetrieveError') })
        },
        complete: () => console.info('Consignees query successfully')
      })
    }
  }

  getNotifies() {
    if(this.directSaleProgramFormGroup.controls['importerId'].value) {
      this._notifyService.getAllForClosedSale(this.directSaleProgramFormGroup.controls['importerId'].value).subscribe({
        next: (v) => {
          this.notifies = v
          this.loading = false
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.Notify.ManyRetrieveError') })
        },
        complete: () => console.info('Notifies query successfully')
      })
    }
  }

  get totalWeeks(): number {
    let startWeek = this.directSaleProgramFormGroup.controls['startWeek'].value
    let startWeekYear = this.directSaleProgramFormGroup.controls['startWeekYear'].value
    let endWeek = this.directSaleProgramFormGroup.controls['endWeek'].value
    let endWeekYear = this.directSaleProgramFormGroup.controls['endWeekYear'].value

    return ExportOfferUtils.getTotalWeeks(startWeek, startWeekYear, endWeek, endWeekYear)
  }

  get totalPallets(): number {
    return this.totalContainers * 20
  }

  get totalContainers(): number {
    let containersPerWeek = this.directSaleProgramFormGroup.controls['containersPerWeek'].value
    return this.totalWeeks * containersPerWeek
  }

  getTotalWeekRow(directSaleProgram: DirectSaleProgram)
  {
    let startWeek = directSaleProgram.startWeek
    let startWeekYear = directSaleProgram.startWeekYear
    let endWeek = directSaleProgram.endWeek
    let endWeekYear = directSaleProgram.endWeekYear 
    return ExportOfferUtils.getTotalWeeks(startWeek, startWeekYear, endWeek, endWeekYear);
  } 
  
  getTotalWeekRowProgramRequest(programRequest: ProgramRequest): number
  {
    let startWeek = programRequest.startWeek
    let startWeekYear = programRequest.startWeekYear
    let endWeek = programRequest.endWeek
    let endWeekYear = programRequest.endWeekYear 
    return ProgramRequestUtil.getTotalWeeks(startWeek, startWeekYear, endWeek, endWeekYear);
  }  

  saveDirectSaleProgram() {
    this.loading = true
    this.submit = true
    if(this.directSaleProgramFormGroup.valid) {
      if(this.selectedExportOfferId) {
        const request: DirectSaleProgramCreateRequest = {
          importerId: this.directSaleProgramFormGroup.controls['importerId'].value,
          consigneeId: this.directSaleProgramFormGroup.controls['consigneeId'].value,
          notifyId: this.directSaleProgramFormGroup.controls['notifyId'].value,
          exportOfferId: this.selectedExportOfferId,
          startWeek: this.directSaleProgramFormGroup.controls['startWeek'].value,
          startWeekYear: this.directSaleProgramFormGroup.controls['startWeekYear'].value,
          containersPerWeek: this.directSaleProgramFormGroup.controls['containersPerWeek'].value,
          destinationCountryId: this.directSaleProgramFormGroup.controls['importToCountryId'].value,
          dischargePortId: this.directSaleProgramFormGroup.controls['portToId'].value,
          endWeek: this.directSaleProgramFormGroup.controls['endWeek'].value,
          endWeekYear: this.directSaleProgramFormGroup.controls['endWeekYear'].value,
          type: 0,
          palletsPerWeek: 0
        }

        this._directSaleProgramService.createWithOrders(request).subscribe({
          next: (v) => {
            this._messageService.add({ severity: 'success',
              summary: this.translate.instant('MessageService.Summary.SuccessAction'),
              detail: this.translate.instant('MessageService.DirectSaleProgram.CreateSuccess') })
            this.submit = false
            this.loading = false
            this.loadExportOffers()
            this.directSaleProgramModalVisible = false
          },
          error: (e) => {
            this.submit = false
            this.loading = false
            console.log(e)
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.DirectSaleProgram.CreateError') })
          },
          complete: () => console.info('Direct sale program created successfully')
        })
      }
    }
    else {
      this.loading = false
    }
  }

  goToProgramRequestCreate()
  {
    this._router.navigate(['/' + Routes.userProgramRequestCreate])
  }

  goToDirectSaleCreateFrom(programRequestGuid: string)
  {
    this._router.navigate(['/' + Routes.userProgramRequestDirectSaleCreate, programRequestGuid])
  }

  getAllProgramRequestExporter()
  {
    this.loading = true
    this._programRequestService.getAllByExporterUsername().subscribe({
      next: (v) => {
        this.programRequestInfo = v
        this.loading = false
      },
      error: (e) => {
        this.loading = false
        this._messageService.add({ severity: 'error',
        summary: this.translate.instant('MessageService.Summary.FailedAction'),
        detail: this.translate.instant('MessageService.ProgramRequest.ManyRetrieveError') })
      }
    })
  }

  getAllProgramRequestImporter()
  {
    this.loading = true
    this._programRequestService.getAllByUsername().subscribe({
      next: (v) => {
        console.log(v)
        this.programRequestInfo = v
        this.loading = false
      },
      error: (e) => {
        this.loading = false
        this._messageService.add({ severity: 'error',
        summary: this.translate.instant('MessageService.Summary.FailedAction'),
        detail: this.translate.instant('MessageService.ProgramRequest.ManyRetrieveError') })
      }
    })
  }

  getAllProgramRequest()
  {
    if(this.profile)
      {
        if(this.profile.type == 1)
          {
            this.getAllProgramRequestExporter()
          }else{
            this.getAllProgramRequestImporter()
          }
      }
  }

  getStatusValue(status: number): string {
    const statusObj = this.programRequestStatusItem.find(item => item.value === status);
    return statusObj ? statusObj.label : '';
  }

  goToEditProgramRequest(programRequestId: string)
  {
    this._router.navigate(['/' + Routes.userProgramRequestEdit, programRequestId])
  }

  updateProgramRequest(programRequestGuid: string, status: number)
  {
    this.loading = true

    let programRequest: UpdateProgramRequest = {
      programRequestGuid: programRequestGuid,
      status: status
    }

    this._programRequestService.updateStatus(programRequest).subscribe({
      next: (v) => {
        this.loading = false;
        this.getAllProgramRequest()
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.SuccessAction'),
          detail: this.translate.instant('MessageService.ProgramRequest.UpdateStatusSuccess') })
      },
      error: (e) => {
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.ProgramRequest.UpdateStatusError') })
      }
    })
  }

  showInviteImporter()
  {
    this.visibleInvite = true;
  }

}
