<div *ngIf="offer">
  <div class="grid">
    <div class="col-12 md:col-4 lg:col-4">
      <app-export-offer-detail-carousel [offer]="offer"></app-export-offer-detail-carousel>
    </div>
    <div class="col-12 md:col-8 lg:col-8">
      <p-card [header]="getHeader()" [subheader]="getSubheader()">
        <div class="grid">
          <div class="col-12 md:col-6 lg:col-6">{{'ExportOffer.ExportOfferDetail.CasePerPalletsText' | translate}}:</div>
          <div class="col-12 md:col-6 lg:col-6">
            <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{ offer.caseQuantityPerPallet }}</span>
          </div>
          <div class="col-12 md:col-6 lg:col-6">{{'ExportOffer.ExportOfferDetail.ListPriceText' | translate}}</div>
          <div class="col-12 md:col-6 lg:col-6">
            <div class="grid">
              <div class="col-12 md:col-6 lg:col-6">
                <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{ offer.listPrice | currency: 'USD':'US$' }} {{offer.typeSale}}</span>
              </div>
              <div *ngIf="getNegotiationPrice()" class="col-12 md:col-6 lg:col-6">
                <div class="flex flex-column justify-content-center align-items-center">
                  <span style="font-size: .8rem; font-weight: bold; ">{{'ExportOffer.ExportOfferDetail.NegotiationPriceText' | translate}}:</span>
                  <span style="border-radius: 7px; padding: 5px; font-weight: bold; color: #6EA042; background-color: #EFF5EA;">{{this.negotiationPrice | currency: 'USD':'US$'}} {{this.negotiationIncoterms}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 md:col-6 lg:col-6" style="font-size: 0.8rem;">{{'ExportOffer.ExportOfferDetail.PaymentAgainstBLLabel' | translate}}</div>
          <div class="col-12 md:col-6 lg:col-6">
            <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{ offer.paymentAgainstBL }} %</span>
          </div>
          <div class="col-12 md:col-6 lg:col-6">{{'ExportOffer.ExportOfferDetail.FinalPaymentSubjectQC' | translate}}</div>
          <div class="col-12 md:col-6 lg:col-6">
            <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{ finalPaymentSubjectQC }} %</span>
          </div>
          <div class="col-12 md:col-6 lg:col-6">{{'ExportOffer.ExportOfferDetail.InitialPalletsText' | translate}}:</div>
          <div class="col-12 md:col-6 lg:col-6">
            <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{ offer.palletQuantity }}</span>
          </div>
          <div class="col-12 md:col-6 lg:col-6">{{'ExportOffer.ExportOfferDetail.AvailablePalletsText' | translate}}:</div>
          <div class="col-12 md:col-6 lg:col-6">
            <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{offer.availablePallets }}</span>
          </div>
          <div class="col-12 md:col-6 lg:col-6">{{'ExportOffer.ExportOfferDetail.AvailableProductCertificates' | translate}}:</div>
          <div class="col-12 md:col-6 lg:col-6">
            <ul *ngIf="productCertificateTypes.length > 0">
              <ng-container *ngFor="let productCertificateType of productCertificateTypes">
                <!-- <li>{{ productCertificateType.name }}&nbsp;<span *ngIf="productCertificateType.isOther" style="font-weight: bolder;">{{ offer.otherCertificateType }}</span></li> -->
                <li *ngIf="!productCertificateType.isOther">{{ productCertificateType.name }}</li>
                <li *ngIf="productCertificateType.isOther">{{ offer.otherCertificateType }}</li>
              </ng-container>
            </ul>
            <span *ngIf="productCertificateTypes.length == 0" style="font-weight: bolder;">{{ 'UserPages.UserExportOffers.NotResultsMessages' | translate }}</span>
          </div>
          <div class="col-12 md:col-6 lg:col-6">{{'ExportOffer.ExportOfferDetail.AvailablePackaging' | translate}}:</div>
          <div class="col-12 md:col-6 lg:col-6">
            <ul *ngIf="packagingTypes.length > 0">
              <ng-container *ngFor="let packagingType of packagingTypes">
                <!-- <li>{{ packagingType.name }}&nbsp;<span *ngIf="packagingType.isOther" style="font-weight: bolder;">{{ offer.otherPackaging }}</span></li> -->
                <li *ngIf="!packagingType.isOther">{{ packagingType.name }}</li>
                <li *ngIf="packagingType.isOther">{{ offer.otherPackaging }}</li>
              </ng-container>
            </ul>
            <span *ngIf="packagingTypes.length == 0" style="font-weight: bolder;">{{ 'UserPages.UserExportOffers.NotResultsMessages' | translate }}</span>
          </div>
        </div>
      </p-card>
    </div>
  </div>
  <app-export-offer-detail-description [offer]="offer"></app-export-offer-detail-description>
</div>
