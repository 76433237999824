<div>
  <p-menu [model]="sessionItems" [style]="{'width':'250px'}"></p-menu>
</div>
<div style="margin-top: 10px;">
  <p-menu [model]="profileItems" [style]="{'width':'250px'}"></p-menu>
</div>
<div style="margin-top: 10px;">
  <p-menu [model]="userItems" [style]="{'width':'250px'}"></p-menu>
</div>

<p-toast></p-toast>
