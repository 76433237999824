<div id="purshaseInfo" *ngIf="delivery">
  <div class="grid" style="margin-top: 50px;">
    <div class="col-12 md:col-6 lg:col-6">
      <div>
        <p-card>
          <h2>{{'UserPages.UserPurchaseOrders.PaymentsPage.BillInfoTitleText' | translate}} &nbsp;<i class="pi pi-wallet" style="font-size: 1.5rem;"></i></h2>
          <h3>Consignee</h3>
          <div class="grid">
            <div class="col-6">{{'UserPages.UserPurchaseOrders.PaymentsPage.ConsigneeNameText' | translate}}:</div>
            <div class="col-6">{{delivery.consigneeCompanyName}}</div>
            <div class="col-6">{{'UserPages.UserPurchaseOrders.PaymentsPage.ConsigneeContactNameText' | translate}}:</div>
            <div class="col-6">{{delivery.consigneeContactName}}</div>
            <div class="col-6">{{'UserPages.UserPurchaseOrders.PaymentsPage.ConsigneeContactAddressLabel' | translate}}:</div>
            <div class="col-6">{{delivery.consigneeContactAddress}}, {{delivery.consigneeContactCity}}</div>
            <div class="col-6">{{'UserPages.UserPurchaseOrders.PaymentsPage.ConsigneeCountryText' | translate}}:</div>
            <div class="col-6">{{delivery.consigneeContactCountry | countryDescription}}</div>
            <div class="col-6">{{'UserPages.UserPurchaseOrders.PaymentsPage.ConsigneeEmailText' | translate}}:</div>
            <div class="col-6">{{delivery.consigneeContactEmail}}</div>
          </div>
          <p-divider></p-divider>
          <h3>Notify</h3>
          <div class="grid">
            <div class="col-6">{{'UserPages.UserPurchaseOrders.PaymentsPage.NotifyNameText' | translate}}:</div>
            <div class="col-6">{{delivery.notifierCompanyName}}</div>
            <div class="col-6">{{'UserPages.UserPurchaseOrders.PaymentsPage.NotifyContactNameText' | translate}}:</div>
            <div class="col-6">{{delivery.notifierContactName}}</div>
            <div class="col-6">{{'UserPages.UserPurchaseOrders.PaymentsPage.NotifyContactAddressLabel' | translate}}:</div>
            <div class="col-6">{{delivery.notifierContactAddress}}, {{delivery.notifierContactCity}}</div>
            <div class="col-6">{{'UserPages.UserPurchaseOrders.PaymentsPage.NotifyCountryText' | translate}}:</div>
            <div class="col-6">{{delivery.notifierContactCountry | countryDescription}}</div>
            <div class="col-6">{{'UserPages.UserPurchaseOrders.PaymentsPage.NotifyEmailText' | translate}}:</div>
            <div class="col-6">{{delivery.notifierContactEmail}}</div>
          </div>
        </p-card>
      </div>

      <div style="margin-top: 30px;">
        <p-card>
          <h2>{{'UserPages.UserPurchaseOrders.PaymentsPage.LoadingDataText' | translate}} &nbsp;<i class="pi pi-truck" style="font-size: 1.5rem;"></i></h2>
          <div class="grid">
            <div class="col-6">Place of receipt:</div>
            <div class="col-6">{{delivery.placeReceipt ? delivery.placeReceipt : DefaultBookingText }}</div>
            <div class="col-6">Port of loading:</div>
            <div class="col-6">{{delivery.departurePort ? delivery.departurePort.name : DefaultBookingText }}</div>
            <div class="col-6">Port of discharge:</div>
            <div class="col-6">{{delivery.destinationPort ? delivery.destinationPort.name : DefaultBookingText }}</div>
            
            <div class="col-6">Airport of loading:</div>
            <div class="col-6">{{delivery.departureAirport ? delivery.departureAirport.name : '(As Booking info)' }}</div>
            <div class="col-6">Airport of discharge:</div>
            <div class="col-6">{{delivery.destinationAirport ? delivery.destinationAirport.name : '(As Booking info)' }}</div>

            <div class="col-6">Week:</div>
            <div class="col-6">{{delivery.week ? delivery.week : DefaultBookingText }}</div>
            <div class="col-6">Place of delivery:</div>
            <div class="col-6">{{delivery.placeDelivery ? delivery.placeDelivery : DefaultBookingText }}</div>
            <div class="col-6">Vessel name/Flight Number:</div>
            <div class="col-6">{{delivery.ship ? delivery.ship : DefaultBookingText }}</div>
            <div class="col-6">Voyage:</div>
            <div class="col-6">{{delivery.voyageNumber ? delivery.voyageNumber : DefaultBookingText }}</div>
            <div class="col-6">Type of movement:</div>
            <div class="col-6">{{delivery.typeMovement ? delivery.typeMovement : DefaultBookingText }}</div>
            <div class="col-6">BL N°:</div>
            <div class="col-6">{{delivery.bl ? delivery.bl : DefaultLoadingInfoText }}</div>
            <div class="col-6">Booking N°:</div>
            <div class="col-6">{{delivery.booking ? delivery.booking : DefaultLoadingInfoText }}</div>
            <div class="col-6">Container N°:</div>
            <div class="col-6">{{delivery.containerNumber ? delivery.containerNumber : DefaultLoadingInfoText }}</div>
            <div class="col-6">Loading data instructions:</div>
            <div class="col-6">{{delivery.deliveryInstructions ? delivery.deliveryInstructions : DefaultLoadingInfoText }}</div>
            <div class="col-6">Freight payable at:</div>
            <div class="col-6">{{delivery.freightPayableAt ? delivery.freightPayableAt : DefaultLoadingInfoText }}</div>
            <div class="col-6">Shipped on board:</div>
            <div class="col-6">{{delivery.shippedOnBoard ? (delivery.shippedOnBoard | date: 'dd-MM-yyyy') : DefaultLoadingInfoText }}</div>
            <div class="col-6">Place of issue:</div>
            <div class="col-6">{{delivery.placeIssue ? delivery.placeIssue : DefaultLoadingInfoText }}</div>
            <div class="col-6">Date of issue:</div>
            <div class="col-6">{{delivery.dateIssue ? (delivery.dateIssue | date: 'dd-MM-yyyy') : DefaultLoadingInfoText }}</div>
            <div class="col-6">ETA:</div>
            <div class="col-6">{{delivery.eta ? (delivery.eta | date: 'dd-MM-yyyy') : DefaultLoadingInfoText }}</div>
            <div class="col-6">ETD:</div>
            <div class="col-6">{{delivery.etd ? (delivery.etd | date: 'dd-MM-yyyy') : DefaultLoadingInfoText }}</div>
            <div class="col-6">Temperature:</div>
            <div class="col-6">{{delivery.containerTemperature ? delivery.containerTemperature : DefaultLoadingInfoText }} °C</div>
            <div class="col-6">CBM:</div>
            <div class="col-6">{{delivery.containerCbm ? delivery.containerCbm + ' %': DefaultLoadingInfoText }} </div>
          </div>
        </p-card>
      </div>

      <div style="margin-top: 30px;">
        <p-card>
          <h2>{{'UserPages.UserPurchaseOrders.PaymentsPage.ShippingMethodTitleText' | translate}} &nbsp;<i class="pi pi-truck" style="font-size: 1.5rem;"></i></h2>
          <div class="grid">
            <div class="col-6">Cutoff date:</div>
            <div class="col-6">{{delivery.cutoffDate ? (delivery.cutoffDate | date: 'dd-MM-yyyy') : DefaultLoadingInfoText }}</div>
            <div class="col-6">Stacking start:</div>
            <div class="col-6">{{delivery.stackingStart ? (delivery.stackingStart | date: 'dd-MM-yyyy') : DefaultLoadingInfoText }}</div>
            <div class="col-6">Stacking end:</div>
            <div class="col-6">{{delivery.stackingEnd ? (delivery.stackingEnd | date: 'dd-MM-yyyy') : DefaultLoadingInfoText }}</div>
            <div class="col-6">Carrier name:</div>
            <div class="col-6">{{delivery.carrierName ? delivery.carrierName : DefaultLoadingInfoText }}</div>
            <div class="col-6">Departure license car:</div>
            <div class="col-6">{{delivery.departurePatentCar ? delivery.departurePatentCar : DefaultLoadingInfoText }}</div>
            <div class="col-6">Departure license truck:</div>
            <div class="col-6">{{delivery.departurePatentTruck ? delivery.departurePatentTruck : DefaultLoadingInfoText }}</div>
            <div class="col-6">DUS:</div>
            <div class="col-6">{{delivery.dus ? delivery.dus : DefaultLoadingInfoText }}</div>
            <div class="col-6">SPS:</div>
            <div class="col-6">{{delivery.sps ? delivery.sps : DefaultLoadingInfoText }}</div>
          </div>
        </p-card>
      </div>
    </div>
    <div class="col-12 md:col-6 lg:col-6">
      <div>
        <p-card>
          <h2>{{'UserPages.UserPurchaseOrders.PaymentsPage.PaymentTitleText' | translate}} &nbsp;<i class="pi pi-dollar" style="font-size: 1.5rem;"></i></h2>
          <div class="grid">
            <ng-container *ngFor="let payment of payments">
              <div class="col-6 flex align-items-center">{{payment.description}}:</div>
              <div class="col-3 flex align-items-center">{{payment.amount | currency:'USD':'US$'}}</div>
              <div class="col-3 flex justify-content-end align-items-center">
                <i *ngIf="payment.id" (click)="getPaymentDocument(payment.id)" pTooltip="Document PDF" class="pi pi-file-pdf" style="font-size: 2rem; cursor: pointer;"></i>
              </div>
            </ng-container>
          </div>
          <div class="grid" style="margin-top: 20px;">
            <div class="col-6">{{'UserPages.UserPurchaseOrders.PaymentsPage.PaymentMethodText' | translate}}:</div>
            <div class="col-6">{{'UserPages.UserPurchaseOrders.PaymentsPage.PaymentSwiftText' | translate}}</div>
            <div class="col-12">
              <div class="w-full flex justify-content-end">
                <p-button *ngIf="profile?.profileType == 'Importer' && (importOrder?.status == 3 || importOrder?.status == 4)" icon="pi pi-money-bill" label="{{'UserPages.UserPurchaseOrders.PaymentsPage.OrderDetailPage.PaymentButton' | translate}}" (onClick)="goToPayments()"></p-button>
              </div>
            </div>
          </div>
        </p-card>
      </div>

      <div style="margin-top: 30px;">
        <p-card>
          <div class="grid">
            <div class="col-12"><h3>Costs</h3></div>
            <div class="col-6">Subtotal:</div>
            <div class="col-6 flex justify-content-end" style="color: red">{{ getOrderTotalPrice() | currency:'USD':'US$' }}</div>
            <div class="col-6">Ocean freight:</div>
            <div class="col-6 flex justify-content-end" style="color: red">{{delivery.oceanFreight | currency:'USD':'US$'}}</div>
            <div class="col-6">Pickup:</div>
            <div class="col-6 flex justify-content-end" style="color: red">{{delivery.inland | currency:'USD':'US$'}}</div>
            <div class="col-6">Local charges:</div>
            <div class="col-6 flex justify-content-end" style="color: red">{{delivery.localCharges | currency:'USD':'US$'}}</div>
            <div class="col-6">Customs:</div>
            <div class="col-6 flex justify-content-end" style="color: red">{{delivery.customs | currency:'USD':'US$'}}</div>
            <div class="col-6">Surcharge:</div>
            <div class="col-6 flex justify-content-end" style="color: red">{{delivery.surcharge | currency:'USD':'US$'}}</div>
            <div class="col-6">Handling:</div>
            <div class="col-6 flex justify-content-end" style="color: red">{{delivery.handling | currency:'USD':'US$'}}</div>
            <div class="col-6">Gate out:</div>
            <div class="col-6 flex justify-content-end" style="color: red">{{delivery.gateOut | currency:'USD':'US$'}}</div>
            <div class="col-6">Others costs:</div>
            <div class="col-6 flex justify-content-end" style="color: red">{{delivery.otherCosts | currency:'USD':'US$'}}</div>
            <div class="col-6">QC Review:</div>
            <div class="col-6 flex justify-content-end" style="color: red">{{qcReviewCost | currency:'USD':'US$'}}</div>
            <div class="col-6">Total cost:</div>
            <div class="col-6 flex justify-content-end"><span style="font-weight: bold;">{{totalCost | currency:'USD':'US$'}}</span></div>
            <div class="col-12">
              <p-divider></p-divider>
            </div>
            <div class="col-12"><h3>Payments</h3></div>
            <ng-container *ngFor="let payment of payments">
              <div class="col-6">{{payment.description}}:</div>
              <div class="col-6 flex justify-content-end" style="color: green">{{payment.amount | currency:'USD':'US$'}}</div>
            </ng-container>
            <div class="col-6">Total payments:</div>
            <div class="col-6 flex justify-content-end"><span style="font-weight: bold;">{{totalPayments | currency:'USD':'US$'}}</span></div>
            <div class="col-12">
              <p-divider></p-divider>
            </div>
            <div class="col-12"><h3>Discounts</h3></div>
            <ng-container *ngFor="let discount of discounts">
              <div class="col-6">{{discount.description}}:</div>
              <div class="col-6 flex justify-content-end" [style]="{'color': discount.amount >= 0 ? 'green' : 'red'}">{{discount.amount | currency:'USD':'US$'}}</div>
            </ng-container>
            <div class="col-6">Total discounts:</div>
            <div class="col-6 flex justify-content-end"><span style="font-weight: bold;">{{totalDiscounts | currency:'USD':'US$'}}</span></div>
            <div class="col-12">
              <p-divider></p-divider>
            </div>
            <div class="col-6">Balance:</div>
            <div class="col-6 flex justify-content-end"><span [style]="{'font-weight': 'bold', 'color': balance < 0 ? 'red' : 'green'}">{{ absoluteBalance | currency:'USD':'US$' }}</span></div>
          </div>
        </p-card>
      </div>
    </div>
  </div>
</div>
