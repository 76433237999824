import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CreateProgramRequest, ProgramRequest, ProgramRequestInformation, UpdateProgramRequest } from "src/app/domain/models/ProgramRequest";

@Injectable({providedIn: 'root'})
export abstract class ProgramRequestGateway {

    abstract create(createProgramRequest: CreateProgramRequest): Observable<ProgramRequest>
    abstract update(updateProgramRequest: ProgramRequest): Observable<ProgramRequest>
    abstract getAllByUsername(): Observable<ProgramRequestInformation[]>
    abstract getAllByExporterUsername(): Observable<ProgramRequestInformation[]>
    abstract GetById(guid: string): Observable<ProgramRequestInformation>
    abstract updateStatus(request: UpdateProgramRequest): Observable<ProgramRequest>
    abstract getAllByFilter(request: any): Observable<ProgramRequestInformation[]>
}