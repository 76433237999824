import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Country } from "src/app/domain/models/Country";
import { Routes } from "src/app/infrastructure/routes";
import { CountryGateway } from "../gateway/CountryGateway";

@Injectable({providedIn: 'root'})
export class CountryService extends CountryGateway {

  constructor(private _http: HttpClient) {
    super()
  }

  getAll(): Observable<Country[]> {
    return this._http.get<Country[]>(Routes.countryApi + '/GetAll')
  }

  getById(id: number): Observable<Country> {
    throw new Error("Method not implemented.");
  }

  create(country: Country): Observable<Country> {
    throw new Error("Method not implemented.");
  }

  update(country: Country): Observable<Country> {
    throw new Error("Method not implemented.");
  }

  delete(id: number): Observable<Country> {
    throw new Error("Method not implemented.");
  }

  getAllByIsOrigin(isOrigin: boolean): Observable<Country[]> {
    return this._http.get<Country[]>(Routes.countryApi + '/GetAllByIsOrigin', {params: {isOrigin}})
  }
}
