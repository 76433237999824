import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Routes } from "src/app/infrastructure/routes";
import { ImportOrderDocumentGateway } from "../gateway/ImportOrderDocumentGateway";
import { ImportOrderDocument, UploadImportOrderDocumentRequest } from "src/app/domain/models/ImportOrder";

@Injectable({providedIn: 'root'})
export class ImportOrderDocumentService extends ImportOrderDocumentGateway {

  constructor(private _http: HttpClient) {
    super()
  }

  getAllImportOrderDocuments(guid: string, documentType: number): Observable<ImportOrderDocument[]> {
    return this._http.get<ImportOrderDocument[]>(Routes.importOrderDocumentApi + '/All', {params: {guid, documentType}})
  }

  uploadImportOrderDocument(request: UploadImportOrderDocumentRequest): Observable<any> {
    const formData = new FormData()
    formData.append("guid", request.guid.toString())
    formData.append("document", request.document)
    formData.append("documentType", request.documentType.toString())
    formData.append("operationDocumentTypeId", request.operationDocumentTypeId.toString())

    return this._http.put<any>(Routes.importOrderDocumentApi + '/Create', formData)
  }

  deleteImportOrderDocument(guid: any): Observable<any> {
    return this._http.delete<any>(Routes.importOrderDocumentApi + '/DeleteDocument', {params: {guid}})
  }

}
