import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Notifier } from "src/app/domain/models/Notifier";
import { Routes } from "src/app/infrastructure/routes";
import { NotifierGateway } from "../gateway/NotifierGateway";

@Injectable({providedIn: 'root'})
export class NotifierService extends NotifierGateway {

  constructor(private _http: HttpClient) {
    super()
  }

  getAll(): Observable<Notifier[]> {
    return this._http.get<Notifier[]>(Routes.notifierApi + '/GetAll')
  }

  getAllByUsername(username: string): Observable<Notifier[]> {
    return this._http.get<Notifier[]>(Routes.notifierApi + '/GetAllByUsername', { params: {username}})
  }

  getById(notifierId: number): Observable<Notifier> {
    return this._http.get<Notifier>(Routes.notifierApi + '/GetById', { params: {notifierId}})
  }

  create(notifier: Notifier): Observable<Notifier> {
    return this._http.post<Notifier>(Routes.notifierApi + '/Create', this.getFormData(notifier))
  }

  update(notifier: Notifier): Observable<Notifier> {
    return this._http.put<Notifier>(Routes.notifierApi + '/Update', this.getFormData(notifier))
  }

  delete(notifierId: number): Observable<Notifier> {
    return this._http.delete<Notifier>(Routes.notifierApi + '/Delete', { params: {notifierId}})
  }

  getAllForClosedSale(importerId: number): Observable<Notifier[]> {
    return this._http.get<Notifier[]>(Routes.notifierApi + '/GetAllForClosedSale', { params: {importerId}})
  }

  private getFormData(notifier: Notifier) {
    const formData = new FormData();
    formData.append("id", notifier.id ? notifier.id.toString() : '');
    formData.append("contactName", notifier.contactName ? notifier.contactName : '');
    formData.append("contactEmail", notifier.contactEmail ? notifier.contactEmail : '');
    formData.append("contactCity", notifier.contactCity ? notifier.contactCity : '');
    formData.append("contactAddress", notifier.contactAddress ? notifier.contactAddress : '');
    formData.append("companyName", notifier.companyName ? notifier.companyName : '');
    formData.append("contactPhone", notifier.contactPhone ? notifier.contactPhone : '');
    formData.append("vat", notifier.vat ? notifier.vat : '');
    formData.append("contactCountryId", notifier.contactCountryId ? notifier.contactCountryId.toString() : '');
    formData.append("username", notifier.username ? notifier.username : '');
    formData.append("pdf", notifier.pdf ? notifier.pdf : '');
    return formData
  }
}
