<div class="grid" style="margin-top: 30px; margin-left: 30px;">
  <div class="col-12">
    <h2>{{'ExportOffer.ExportOfferDetail.SpecieDescription.Title' | translate}}</h2>
  </div>
  <div class="col-6">
    <div class="grid" style="border-right: 3px solid grey;">
      <div class="col-12 md:col-4 lg:col-4"><span style="font-weight: bolder">{{'ExportOffer.ExportOfferDetail.SpecieDescription.DescriptionText' | translate}}:</span></div>
      <div class="col-12 md:col-8 lg:col-8">{{speciesDescription.description ? speciesDescription.description : getAvailableLabel() }}</div>
      <div class="col-12 md:col-4 lg:col-4"><span style="font-weight: bolder">{{'ExportOffer.ExportOfferDetail.SpecieDescription.TechnicalCharacteristicsText' | translate}}:</span></div>
      <div class="col-12 md:col-8 lg:col-8">{{speciesDescription.technicalCharacteristics ? speciesDescription.technicalCharacteristics : getAvailableLabel()}}</div>
      <div class="col-12 md:col-4 lg:col-4"><span style="font-weight: bolder">{{'ExportOffer.ExportOfferDetail.SpecieDescription.ExportedVarietiesText' | translate}}:</span></div>
      <div class="col-12 md:col-8 lg:col-8">{{offer?.variety?.species?.exportVarieties}}</div>
    </div>
  </div>
  <div class="col-6">
    <div class="grid" style="margin-left: 20px;">
      <div class="col-12 md:col-4 lg:col-4"><span style="font-weight: bolder">{{'ExportOffer.ExportOfferDetail.SpecieDescription.QualityControlCriteriaText' | translate}}:</span></div>
      <div class="col-12 md:col-8 lg:col-8">{{speciesDescription.qualityControlCriteria ? speciesDescription.qualityControlCriteria : getAvailableLabel()}}</div>
      <div class="col-12 md:col-4 lg:col-4"><span style="font-weight: bolder">{{'ExportOffer.ExportOfferDetail.SpecieDescription.HarvestZoneChileText' | translate}}:</span></div>
      <div class="col-12 md:col-8 lg:col-8">{{speciesDescription.harvestAreaChile ? speciesDescription.harvestAreaChile : getAvailableLabel()}}</div>
      <div class="col-12 md:col-4 lg:col-4"><span style="font-weight: bolder">{{'ExportOffer.ExportOfferDetail.SpecieDescription.HarvestDateInChileText' | translate}}:</span></div>
      <div class="col-12 md:col-8 lg:col-8">{{getHarvestPeriod(offer?.variety?.species)}}</div>
    </div>
  </div>
</div>
<div class="grid" style="margin-top: 40px; margin-left: 30px;">
  <div class="col-12">
    <h2>{{'ExportOffer.ExportOfferDetail.ProductDescription' | translate}}</h2>
  </div>
  <div class="col-12 md:col-4 lg:col-4 grid product-description-block1-small product-description-block1-large">
    <div class="col-6">
      <span style="font-weight: bolder;">{{'ExportOffer.ExportOfferDetail.SpeciesText' | translate}}:</span>
    </div>
    <div class="col-6">{{ speciesName }}</div>
    <div class="col-6">
      <span style="font-weight: bolder;">{{'ExportOffer.ExportOfferDetail.VarietyText' | translate}}:</span>
    </div>
    <div class="col-6">
      <div class="flex align-items-center">
        <img [src]="offer?.variety?.urlIcon" style="width: 18px" alt="Variety icon"/> {{offer?.variety?.name}}
      </div>
    </div>
    <div class="col-6">
      <span style="font-weight: bolder;">{{'ExportOffer.ExportOfferDetail.CategoryText' | translate}}:</span>
    </div>
    <div class="col-6">
      {{offer?.category ? offer?.category : getAvailableLabel()}}
    </div>
    <div class="col-6">
      <span style="font-weight: bolder;">{{'ExportOffer.ExportOfferDetail.LocationLabel' | translate}}:</span>
    </div>
    <div class="col-6">
      {{offer?.fridgeStorage ? offer?.fridgeStorage?.contactCity + ', ' + (offer?.fridgeStorage?.contactCountry | countryDescription) : offer?.country?.name}}
    </div>
    <div class="col-6">
      <span style="font-weight: bolder;">{{'ExportOffer.ExportOfferDetail.HarvestDateText' | translate}}:</span>
    </div>
    <div class="col-6">
      {{offer?.harvestDate ? (offer?.harvestDate | date: 'dd-MM-yyyy') : getAvailableLabel()}}
    </div>
    <div class="col-6">
      <span style="font-weight: bolder;">Shelf Life:</span>
    </div>
    <div class="col-6">
      {{offer?.shelfLife ? offer?.shelfLife + ' ' + translate.instant('ExportOffer.ExportOfferDetail.DayShelflifeText') : getAvailableLabel()}}
    </div>
    <div class="col-6">
      <span style="font-weight: bolder;">{{'ExportOffer.ExportOfferDetail.CasePerPalletsText' | translate}}:</span>
    </div>
    <div class="col-6">
      {{offer?.caseQuantityPerPallet}}
    </div>
    <div class="col-6">
      <span style="font-weight: bolder;">{{'ExportOffer.ExportOfferDetail.SpeciesSizeText' | translate}}:</span>
    </div>
    <div class="col-6">
      {{offer?.speciesSize ? offer?.speciesSize : getAvailableLabel()}}
    </div>
  </div>
  <div class="col-12 md:col-4 lg:col-4 grid product-description-block2-small product-description-block2-large">
    <div class="col-6">
      <span style="font-weight: bolder;">{{'ExportOffer.ExportOfferDetail.SpeciesSizeMillimetersFieldText' | translate}}:</span>
    </div>
    <div class="col-6">
      {{offer?.sizeMillimeters ? offer?.sizeMillimeters + ' mm.' : getAvailableLabel()}}
    </div>
    <div class="col-6">
      <span style="font-weight: bolder;">{{'ExportOffer.ExportOfferDetail.NetKilosText' | translate}}:</span>
    </div>
    <div class="col-6">
      {{offer?.netKilos ? offer?.netKilos + ' Kg.' : getAvailableLabel()}}
    </div>
    <div class="col-6">
      <span style="font-weight: bolder;">{{'ExportOffer.ExportOfferDetail.GrossKilosText' | translate}}:</span>
    </div>
    <div class="col-6">
      {{offer?.grossKilos ? offer?.grossKilos + ' Kg.' : getAvailableLabel()}}
    </div>
    <div class="col-6">
      <span style="font-weight: bolder;">{{'ExportOffer.ExportOfferDetail.BoxTypeText' | translate}}:</span>
    </div>
    <div class="col-6">
      {{offer?.boxType ? getBoxType(offer?.boxType) : getAvailableLabel()}}
    </div>
    <div class="col-6">
      <span style="font-weight: bolder;">{{'ExportOffer.ExportOfferDetail.CaseSize' | translate}}:</span>
    </div>
    <div class="col-6">
      {{offer?.caseSize ? offer?.caseSize : getAvailableLabel()}}
    </div>
    <div class="col-6">
      <span style="font-weight: bolder;">{{'ExportOffer.ExportOfferDetail.BasePalletsText' | translate}}:</span>
    </div>
    <div class="col-6">
      {{offer?.basePallets ? getBasePallets(offer?.basePallets) : getAvailableLabel()}}
    </div>
    <div class="col-6">
      <span style="font-weight: bolder;">{{'ExportOffer.ExportOfferDetail.ColorText' | translate}}:</span>
    </div>
    <div class="col-6">
      {{offer?.color ? getColor(offer?.color) : getAvailableLabel()}}
    </div>
    <div class="col-6">
      <span style="font-weight: bolder;">{{'ExportOffer.ExportOfferDetail.MarksText' | translate}}:</span>
    </div>
    <div class="col-6">
      {{getBooleanLabel(offer?.marks)}}
    </div>
  </div>
  <div class="col-12 md:col-4 lg:col-4 grid product-description-block3-small product-description-block3-large">
    <div class="col-6">
      <span style="font-weight: bolder;">{{'ExportOffer.ExportOfferDetail.ReadyToLoadDateText' | translate}}:</span>
    </div>
    <div class="col-6">
      {{offer?.readyToLoadDate ? (offer?.readyToLoadDate | date: 'dd-MM-yyyy') : getAvailableLabel()}}
    </div>
    <div class="col-6">
      <span style="font-weight: bolder;">{{'ExportOffer.ExportOfferDetail.qualityControlText' | translate}}:</span>
    </div>
    <div class="col-6">
      <div class="flex align-items-center">
        {{getBooleanLabel(offer?.isQualityControl)}}
        <p-button *ngIf="offer?.qualityDocuments?.length" (onClick)="getExportOfferQualityDocument(offer?.qualityDocuments)" pTooltip="{{'UserPages.UserExportOffers.EditExportOffer.ViewQualityDocument' | translate}}" [style]="{ 'margin': 0, 'padding': 0 }" icon="pi pi-file-pdf" styleClass="p-button-link p-button-lg"></p-button>
      </div>
    </div>
    <div class="col-6">
      <span style="font-weight: bolder;">{{'ExportOffer.ExportOfferDetail.QualityControlDateText' | translate}}:</span>
    </div>
    <div class="col-6">
      {{offer?.qualityControlDate ? (offer?.qualityControlDate | date: 'dd-MM-yyyy') : getAvailableLabel()}}
    </div>
    <div class="col-6">
      <span style="font-weight: bolder;">{{'ExportOffer.ExportOfferDetail.OriginCountryText' | translate}}:</span>
    </div>
    <div class="col-6">
      <div class="flex align-items-center">
        <img [src]="'../../../../assets/img/flags/' + (offer?.fridgeStorage ? offer?.fridgeStorage?.contactCountry?.code : offer?.country?.code) + '.png'" style="width: 18px" alt=""/> {{offer?.country | countryDescription}}
      </div>
    </div>
    <div class="col-6" *ngIf="!offer?.isClosedSale">
      <span style="font-weight: bolder;">{{'ExportOffer.ExportOfferDetail.MarketCountry1Text' | translate}}:</span>
    </div>
    <div class="col-6" *ngIf="!offer?.isClosedSale">
      <div class="flex align-items-center">
        <img [src]="'../../../../assets/img/flags/' + offer?.marketCountryFirst?.code + '.png'" style="width: 18px" alt=""/> {{offer?.marketCountryFirst ? (offer?.marketCountryFirst | countryDescription) : getAvailableLabel()}}
      </div>
    </div>
    <div class="col-6" *ngIf="!offer?.isClosedSale">
      <span style="font-weight: bolder;">{{'ExportOffer.ExportOfferDetail.MarketCountry2Text' | translate}}:</span>
    </div>
    <div class="col-6" *ngIf="!offer?.isClosedSale">
      <div class="flex align-items-center" *ngIf="offer?.marketCountrySecond">
        <img [src]="'../../../../assets/img/flags/' + offer?.marketCountrySecond?.code + '.png'" style="width: 18px" alt=""/> {{offer?.marketCountrySecond ? (offer?.marketCountrySecond | countryDescription) : getAvailableLabel()}}
      </div>
      <div *ngIf="!offer?.marketCountrySecond">
        {{'ExportOffer.ExportOfferDetail.NoAvailable' | translate}}
      </div>
    </div>
    <div class="col-6" *ngIf="!offer?.isClosedSale">
      <span style="font-weight: bolder;">{{'ExportOffer.ExportOfferDetail.MarketCountry3Text' | translate}}:</span>
    </div>
    <div class="col-6" *ngIf="!offer?.isClosedSale">
      <div class="flex align-items-center" *ngIf="offer?.marketCountryThird">
        <img [src]="'../../../../assets/img/flags/' + offer?.marketCountryThird?.code + '.png'" style="width: 18px" alt=""/> {{offer?.marketCountryThird ? (offer?.marketCountryThird | countryDescription) : getAvailableLabel()}}
      </div>
      <div *ngIf="!offer?.marketCountryThird">
        {{'ExportOffer.ExportOfferDetail.NoAvailable' | translate}}
      </div>
    </div>
    <div class="col-6" *ngIf="offer?.isClosedSale">&nbsp;</div>
    <div class="col-6" *ngIf="offer?.isClosedSale">&nbsp;</div>
    <div class="col-6" *ngIf="offer?.isClosedSale">&nbsp;</div>
    <div class="col-6" *ngIf="offer?.isClosedSale">&nbsp;</div>
    <div class="col-6" *ngIf="offer?.isClosedSale">&nbsp;</div>
    <div class="col-6" *ngIf="offer?.isClosedSale">&nbsp;</div>
  </div>
</div>
