<p-blockUI [blocked]="loading">Cargando</p-blockUI>
<app-dashboard-layout>
  <app-content-header>
    <app-home-header></app-home-header>
  </app-content-header>
  <app-content-body>
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="home" [style]="{'margin-bottom':'20px'}"></p-breadcrumb>
    <h2>Organize your frozen fruit containers</h2>
    <div *ngIf="orders.length <= 0">
      <p>{{'ContainerOrderPage.NotContainersMessageText' | translate}}</p>
      <p-button label="{{'ContainerOrderPage.GoToMarketPlaceButton' | translate}}" (click)="goToMarketplace()"></p-button>
    </div>
    <ng-container *ngFor="let order of orders">
      <div style="margin-top: 40px;">
        <p-card>
          <div class="grid">
            <div class="col-12">
              <div class="col-12 flex justify-content-start align-items-start"><app-timer [orderDateTarget]="order.created" [hoursTimer]="1"></app-timer></div>
            </div>
            <div class="col-12 md:col-2 lg:col-2"></div>
            <div class="col-12 md:col-1 lg:col-1 flex justify-content-center align-items-center"><span style="font-weight: bold;">{{'ContainerOrderPage.ContainersTable.RequestDateHeader' | translate}}</span> </div>
            <div class="col-12 md:col-1 lg:col-1 flex justify-content-center align-items-center"><span style="font-weight: bold;">{{'ContainerOrderPage.ContainersTable.OriginCountryHeader' | translate}}</span></div>
            <div class="col-12 md:col-2 lg:col-2 flex justify-content-center align-items-center"><span style="font-weight: bold;">{{'ContainerOrderPage.ContainersTable.DestinationCountryHeader' | translate}}</span></div>
            <div class="col-12 md:col-1 lg:col-1 flex justify-content-center align-items-center"><span style="font-weight: bold;">{{'ContainerOrderPage.ContainersTable.TotalCasesHeader' | translate}}</span></div>
            <div class="col-12 md:col-1 lg:col-1 flex justify-content-center align-items-center" style="font-weight: bold;">{{'ContainerOrderPage.ContainersTable.ProductPriceHeader' | translate}}</div>
            <div class="col-12 md:col-1 lg:col-1 flex justify-content-center align-items-center" style="font-weight: bold;">{{'ContainerOrderPage.ContainersTable.QCPriceeHeader' | translate}}</div>
            <div class="col-12 md:col-1 lg:col-1 flex justify-content-center align-items-center" style="font-weight: bold;">O. freight</div>
            <div class="col-12 md:col-1 lg:col-1 flex justify-content-center align-items-center"><span style="font-weight: bold;">{{'ContainerOrderPage.ContainersTable.TotalPriceHeader' | translate}}</span></div>
            <div class="col-12 md:col-1 lg:col-1 flex justify-content-end align-items-center"><span style="font-weight: bold;"></span></div>

            <div class="col-12 md:col-2 lg:col-2">
              <div class="grid">
                <div class="col-4">
                  <img src="../../../../assets/icons/container-icon.png" style="width: 100%; height: auto;" alt=""/>
                </div>
                <div class="col-8 flex align-items-center">
                  <span style="font-weight: bold;">Orden : {{ getOrderTitle(order) }}</span>
                </div>
              </div>
            </div>
            <div class="col-12 md:col-1 lg:col-1 flex justify-content-center align-items-center">{{ order.created | date: 'dd-MM-yyyy' }}</div>
            <div class="col-12 md:col-1 lg:col-1 flex justify-content-center align-items-center">{{ order.countryFrom }}</div>
            <div class="col-12 md:col-2 lg:col-2 flex justify-content-center align-items-center">{{ order.countryTo + ' / ' + order.portTo }}</div>
            <div class="col-12 md:col-1 lg:col-1 flex justify-content-center align-items-center">{{ order.totalQuantity }}</div>
            <div class="col-12 md:col-1 lg:col-1 flex justify-content-center align-items-center">{{ order.totalPrice | currency: 'USD':'US$' }}</div>
            <div class="col-12 md:col-1 lg:col-1 flex justify-content-center align-items-center">{{ getQCPrice(order) | currency: 'USD':'US$' }}</div>
            <div class="col-12 md:col-1 lg:col-1 flex justify-content-center align-items-center">{{ order.oceanFreight | currency: 'USD':'US$' }}</div>
            <div class="col-12 md:col-1 lg:col-1 flex justify-content-center align-items-center" style="font-weight: bold;">{{getOrderTotalPrice(order) | currency: 'USD':'US$'}}</div>
            <div class="col-12 md:col-1 lg:col-1 flex justify-content-end align-items-center">
              <div class="flex align-items-center">
                <button type="button" pButton pRipple pTooltip="{{'ContainerOrderPage.ContainersTable.DetailsButtonLabel' | translate}}" (click)="setVisibleOrderDetail(order)" class="p-button-text p-button-rounded p-button-plain mr-2" [icon]="order.expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" style="background-color: #8d2c87;"></button>
              </div>
            </div>
          </div>

          <div *ngIf="order.expanded" class="col-12">
            <p-table [value]="order.details" dataKey="id">
              <ng-template pTemplate="header">
                <tr>
                  <th>
                    <div class="flex justify-content-center align-items-center" style="font-weight: bold;">{{'ContainerOrderPage.OrderDetailTable.ProductTextHeader' | translate}}</div>
                  </th>
                  <th>
                    <div class="flex justify-content-center align-items-center" style="font-weight: bold;">{{'ContainerOrderPage.OrderDetailTable.NameTexHeader' | translate}}</div>
                  </th>
                  <th>
                    <div class="flex justify-content-center align-items-center" style="font-weight: bold;">{{'ContainerOrderPage.OrderDetailTable.ListPriceHeader' | translate}}</div>
                  </th>
                  <th>
                    <div class="flex justify-content-center align-items-center" style="font-weight: bold;">{{'ContainerOrderPage.OrderDetailTable.CasePerPalletsHeader' | translate}}</div>
                  </th>
                  <th>
                    <div class="flex justify-content-center align-items-center" style="font-weight: bold;">{{'ContainerOrderPage.OrderDetailTable.PalletsHeader' | translate}}</div>
                  </th>
                  <th>
                    <div class="flex justify-content-center align-items-center" style="font-weight: bold;">Total cases</div>
                  </th>
                  <th>
                    <div class="flex justify-content-center align-items-center" style="font-weight: bold;">{{'ContainerOrderPage.OrderDetailTable.TotalHeader' | translate}}</div>
                  </th>
                  <th>
                    <div class="flex justify-content-center align-items-center" style="font-weight: bold;">{{'ContainerOrderPage.OrderDetailTable.ActionsHeader' | translate}}</div>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-detail>
                <tr>
                  <td><div class="flex justify-content-center align-items-center"><img [src]="detail.varietyUrlIcon" style="border-radius: 7px; width: 100%; max-width: 50px; height: auto;" alt=""/></div></td>
                  <td><div class="flex justify-content-center align-items-center">{{ detail.speciesName + ' / ' + detail.varietyName }}</div></td>
                  <td><div class="flex justify-content-center align-items-center">{{ detail.price | currency: 'USD':'US$' }} {{ detail.incoterms }}</div></td>
                  <td><div class="flex justify-content-center align-items-center">{{ detail.boxesPerPallet > 0 ? detail.boxesPerPallet : 'N.A.' }}</div></td>
                  <td><div class="flex justify-content-center align-items-center">{{ detail.boxesPerPallet > 0 ? 20 : 'N.A.' }}</div></td>
                  <td><div class="flex justify-content-center align-items-center">{{ getTotalBoxex(detail) }}</div></td>
                  <td><div class="flex justify-content-center align-items-center">{{ getDetailTotalPrice(detail) | currency: 'USD':'US$'}} {{detail.incoterms }}</div></td>
                  <td>
                    <div class="flex justify-content-center align-items-center gap-4">
                      <p-button icon="pi pi-search" pTooltip="{{'ContainerOrderPage.OrderDetailTable.SeeOfferToolTipText' | translate}}" styleClass="p-button-rounded p-button-info p-button-sm" (onClick)="showOfferDetailDialog(detail.frozenFruitExportOfferId)"></p-button>
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>

          <div class="col-12 flex justify-content-center align-items-center" style="margin-top: 30px;">
            <div class="grid w-full">
              <div class="col-12 flex justify-content-end align-items-center">
                <p-button label="QC Company" (onClick)="showSelectQCCompany(order)" *ngIf="order.qcCompanies.length > 0 && !order.qualityControlCompany"></p-button>
                <p-button label="{{'ContainerOrderPage.ContainersTable.MakeOrderButton' | translate}}" (onClick)="confirmOrder(order)" [loading]="waitConfirmation" *ngIf="(order.qcCompanies.length > 0 && order.qualityControlCompany) || order.qcCompanies.length < 1"></p-button>
              </div>
              <div class="col-12 flex justify-content-end align-items-center" style="font-weight: bolder;" *ngIf="order.qcCompanies.length > 0 && !order.qualityControlCompany">
                {{'ContainerOrderPage.QCCompanySuggestionText' | translate}}
              </div>
            </div>
          </div>
        </p-card>
      </div>
    </ng-container>
  </app-content-body>
</app-dashboard-layout>

<p-toast></p-toast>

<p-confirmDialog [style]="{width: '30vw'}"></p-confirmDialog>

<p-dialog [(visible)]="waitConfirmation" [modal]="true" [closable]="false" [style]="{ width: '30vw' }" [draggable]="false" [resizable]="false">
  <p class="m-0 flex align-items-center">
    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
    &nbsp;{{'ContainerOrderPage.CreatingOrderWaitMessage' | translate}}
  </p>
</p-dialog>

<p-dialog header="QC Company" [(visible)]="visibleQCCompany" [style]="{width: '40vw'}">
  <p>{{'ContainerOrderPage.QCCompanyModal.Text' | translate}}</p>
  <p-table [value]="qcCompanies"
    selectionMode="single"
    (onRowSelect)="onQCCompanyTableRowSelect($event)"
    [paginator]="true"
    [rows]="5"
    responsiveLayout="scroll">
    <ng-template pTemplate="header">
      <tr>
        <th>{{'ContainerOrderPage.QCCompanyModal.QCCompanyNameHeader' | translate}}</th>
        <th>{{'ContainerOrderPage.QCCompanyModal.QCCompanyBasePricePerPalletHeader' | translate}}</th>
        <th>{{'ContainerOrderPage.QCCompanyModal.QCCompanyBasePricePerContainerHeader' | translate}}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-company>
      <tr [pSelectableRow]="company">
        <td>{{company.profile?.companyName}}</td>
        <td>{{company.basePricePerPallet | currency: 'USD':'US$'}}</td>
        <td>{{company.basePricePerContainer | currency: 'USD':'US$'}}</td>
      </tr>
    </ng-template>
  </p-table>
  <ng-template pTemplate="footer">
    <p-button label="Cerrar" (onClick)="visibleQCCompany = false" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>

<p-dialog [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleOfferDetailDialog" [style]="{width: '80%'}">
  <app-frozen-fruit-export-offer-detail [offer]="frozenFruitExportOffer"></app-frozen-fruit-export-offer-detail>
  <ng-template pTemplate="footer">
    <p-button label="{{'UserPages.UserExportOffers.CloseModalButton' | translate}}" (onClick)="visibleOfferDetailDialog = false"></p-button>
  </ng-template>
</p-dialog>
