import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { FrozenFruitExporterVerifyPalletsRequest, FrozenFruitPalletList, FrozenFruitPalletQCOriginReviewRequest } from "src/app/domain/models/FrozenFruitPallet";
import { UploadImportOrderDetailPdfRequest } from "src/app/domain/models/ImportOrder";
import { Routes } from "src/app/infrastructure/routes";
import { FrozenFruitPalletGateway } from "../gateway/FrozenFruitPalletGateway";

@Injectable({providedIn: 'root'})
export class FrozenFruitPalletService extends FrozenFruitPalletGateway {

  constructor(private _http: HttpClient) {
    super()
  }

  createFromExporterVerify(request: FrozenFruitExporterVerifyPalletsRequest): Observable<any> {
    return this._http.post<any>(Routes.frozenFruitPalletApi + '/CreateFromExporterVerify', request)
  }

  qcCompanyVerifyPallets(request: FrozenFruitPalletQCOriginReviewRequest): Observable<any> {
    return this._http.put<any>(Routes.frozenFruitPalletApi + '/QCCompanyVerifyPallets', request)
  }

  getAllByFrozenFruitOrderDetail(frozenFruitOrderDetailId: number): Observable<FrozenFruitPalletList> {
    return this._http.get<any>(Routes.frozenFruitPalletApi + '/GetAllByFrozenFruitOrderDetail', {params: {frozenFruitOrderDetailId}})
  }

  uploadQCReviewPdf(request: UploadImportOrderDetailPdfRequest): Observable<any> {
    return this._http.put<any>(Routes.frozenFruitOrderDetailApi + '/UploadQCReviewPdf', this.getUploadFileFormData(request))
  }

  uploadQCContainerConsolidationPdf(request: UploadImportOrderDetailPdfRequest): Observable<any> {
    return this._http.put<any>(Routes.frozenFruitOrderDetailApi + '/UploadQCContainerConsolidationPdf', this.getUploadFileFormData(request))
  }

  private getUploadFileFormData(request: UploadImportOrderDetailPdfRequest) {
    const formData = new FormData()
    formData.append("importOrderDetailId", request.importOrderDetailId.toString())
    formData.append("pdf", request.pdf)
    return formData;
  }
}
