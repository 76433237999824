import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CustomAgentGateway } from "../gateway/CustomAgentGateway";
import { CustomAgentReview, CustomAgentReviewItemList, CustomAgentUserCreateRequest } from "src/app/domain/models/CustomAgentReview";
import { Routes } from "src/app/infrastructure/routes";
import { AssignCustomAgent, ProfileCompayList } from "src/app/domain/models/Company";

@Injectable({providedIn: 'root'})
export class CustomAgentService extends CustomAgentGateway {

  constructor(private _http: HttpClient) {
    super()
  }

  getAllByStatusAndReviewType(reviewType: number): Observable<CustomAgentReviewItemList[]> {
    return this._http.get<CustomAgentReviewItemList[]>(Routes.customAgentReviewApi + '/GetAllByStatusAndReviewType', {params: {reviewType}})
  }

  getByReviewId(guid: string): Observable<CustomAgentReview> {
    return this._http.get<CustomAgentReview>(Routes.customAgentReviewApi + '/GetByReviewId', {params: {guid}})
  }
  
  createUser(request: CustomAgentUserCreateRequest): Observable<any> {
    return this._http.post<any>(Routes.customAgentReviewApi + '/CreateUser', request)
  }
  
  getCustomAgentsByAdmin(): Observable<ProfileCompayList[]> {
    return this._http.get<ProfileCompayList[]>(Routes.profileCompanyApi + '/GetCompanyUsersByAdmin')
  }

  assignCustomAgent(request: AssignCustomAgent): Observable<any> {
    return this._http.put<any>(Routes.customAgentReviewApi + '/AssignCustomAgent', request)
  }
}
