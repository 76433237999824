<app-user-dashboard [userMenuActiveItemIndex]="0" [activeItem]="'direct-sale'">
  <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="breadcrumbHomeItem"></p-breadcrumb>
  <div style="margin-top: 30px">
    <p-card>
      <ng-template pTemplate="header">
        <div class="flex justify-content-between flex-wrap align-items-center" style="padding: 15px;">
          <h2>{{'UserPages.UserClosedSale.ExporterClosedSaleOrders.PageTitle' | translate}}</h2>
          <div class="flex justify-content-end align-items-center gap-4">
            <p-button (onClick)="showOfferDetailDialog()" styleClass="p-button-outlined">
              <span class="material-symbols-outlined">nutrition</span>
              <span>{{'UserPages.UserClosedSale.ExporterClosedSaleOrders.ViewExportOfferButtonLabel' | translate}}</span>
            </p-button>
          </div>
        </div>
      </ng-template>

      <p>{{'UserPages.UserClosedSale.ImporterClosedSaleOrders.PageDescriptionText' | translate}}</p>
      <div class="grid" style="margin-top: 30px">
        <div class="col-2 flex justify-content-start" >
          <div class="col-12 card-summary flex justify-content-start">
            <div class="grid">
              <div class="col-12 md:col-12">
                <p class="summary-title">{{'ImportCloseSaleDetail.TotalAmountSummary' | translate}}</p>
              </div>
              <div class="col-12 md:col-12">
                <div class="grid">
                  <div class="col-12 flex justify-content-between align-items-end">
                    <span class="material-symbols-outlined icon-style" style="margin-left: 5px;">
                      monetization_on
                      </span>
                      <span class="summary-data"> 
                        {{totalPrice | currency: 'USD':'US$'}}
                      </span>
                  </div>
                  <div class="col-12 flex justify-content-end">
                    <span style="font-size: 10px; color: #797E83; margin-right: 5px;" >
                      USD({{'ImportCloseSaleDetail.DolarSummary' | translate}})
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 flex justify-content-start">
          <div class="col-12 card-summary">
            <div class="grid">
              <div class="col-12 md:col-12">
                <p class="summary-title">{{'ImportCloseSaleDetail.TotalBoxesSummary' | translate}}</p>
              </div>
              <div class="col-12 md:col-12">
                <div class="grid">
                  <div class="col-12 flex justify-content-between align-items-end">
                    <span class="material-symbols-outlined icon-style" style="margin-left: 5px;">
                      inventory_2
                      </span>
                      <span class="summary-data"> 
                        {{totalBoxes}}
                      </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 flex justify-content-start">
          <div class="col-12 card-summary">
            <div class="grid">
              <div class="col-12 md:col-12">
                <p class="summary-title">{{'ImportCloseSaleDetail.TotalPalletsSummary' | translate}}</p>
              </div>
              <div class="col-12 md:col-12">
                <div class="grid">
                  <div class="col-12 flex justify-content-between align-items-end">
                    <span class="material-symbols-outlined icon-style" style="margin-left: 5px;">
                      pallet
                      </span>
                      <span class="summary-data"> 
                        {{totalPalletsHistoric}}
                      </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 flex justify-content-start">
          <div class="col-12 card-summary">
            <div class="grid">
              <div class="col-12 md:col-12">
                <p class="summary-title">{{'ImportCloseSaleDetail.TotalContainers' | translate}}</p>
              </div>
              <div class="col-12 md:col-12">
                <div class="grid">
                  <div class="col-12 flex justify-content-between align-items-end">
                    <span class="material-symbols-outlined icon-style" style="margin-left: 5px;">
                      package_2
                      </span>
                      <span class="summary-data"> 
                        {{totalContainers}}
                      </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="grid">
        <div class="col-4">
          <p-calendar
            #calendar
            [inline]="true"
            [showWeek]="true"
            [firstDayOfWeek]="1"
            [readonlyInput]="true"
            (onMonthChange)="onMonthChange($event)"
            [style]="{'width':'90%'}"
            >
            <ng-template pTemplate="date" let-date>
                <span *ngIf="getWeekNumber(date.day, date.month, date.year)"  class="inWeekRange">
                  {{date.day}}
                </span>
                <span *ngIf="!getWeekNumber(date.day, date.month, date.year)">
                  {{date.day}}
                </span>
            </ng-template>
          </p-calendar>
        </div>
        <div class="col-8">
          <h2>{{'UserPages.UserClosedSale.ImporterClosedSaleOrders.FilterForText' | translate}}:</h2>
          <form [formGroup]="filterFormGroup">
            <div class="grid p-fluid">
              <div class="col-4">
                <label>{{'UserPages.UserClosedSale.ImporterClosedSaleOrders.FilterFieldPerWeekLabel' | translate}}</label>
                <p-inputNumber formControlName="week" placeholder="{{'UserPages.UserClosedSale.ImporterClosedSaleOrders.FilterFieldPerWeekPlaceholder' | translate}}"/>
              </div>
              <div class="col-4 flex align-items-end">
                <p-button (click)="sidebarVisible = true" 
                [style]="{'margin-bottom': '2px'}"
                label="{{'ImportCloseSaleDetail.FilterTextButton' | translate}}" class="p-inputtext-sm" styleClass="p-button-outlined">
                <span class="material-symbols-outlined" style="margin-right: 6px;">
                  add
                  </span>
                </p-button>
              </div>
              <div class="grid col-12">
                <div class="col-4" style="margin-top: 40px;">
                  <p-button label="{{'UserPages.UserClosedSale.ImporterClosedSaleOrders.CleanFilterButton' | translate}}" (onClick)="onClearFilter()" styleClass="p-button-outlined">
                  </p-button>
                </div>
                <div class="col-4" style="margin-top: 40px;">
                  <p-button label="{{'UserPages.UserClosedSale.ImporterClosedSaleOrders.ApplyFilterButton' | translate}}" (onClick)="onFilter()">
                  </p-button>
                </div>
              </div>    
            </div>
          </form>
        </div>
      </div>
      

      <p-menu #offerMenu [model]="offerMenuitems" [popup]="true"></p-menu>
        <p-dataView [value]="weekDtos" [loading]="loading" [rows]="20" [paginator]="true"
          emptyMessage="{{'UserPages.UserClosedSale.ExporterClosedSaleOrders.EmptyResultsText' | translate}}">
          <ng-template let-dto pTemplate="listItem">
            <div class="col-12">
              <div class="flex justify-content-between align-items-center">
                <h2>{{'UserPages.UserClosedSale.ExporterClosedSaleOrders.WeekFieldText' | translate}}: {{dto.week}} - {{dto.year}}</h2>
                <p-button
                styleClass="p-button-outlined" 
                [style]="{'font-size': 'small', 'margin-left': '10px'}" 
                label="{{'UserPages.UserClosedSale.ExporterClosedSaleOrders.AddOrderButton' | translate}}" 
                icon="pi pi-plus"
                (onClick)="showAddContainers(dto.week, dto.year)"
                ></p-button>
              </div>
 

              <p-dataView #dv
              [value]="dto.importOrdersDtos"
              [sortField]="sortField"
              [sortOrder]="sortOrder"
              [rows]="20"
              [paginator]="false"
              [loading]="loading"
              emptyMessage="{{'UserPages.UserClosedSale.ExporterClosedSaleOrders.EmptyResultsText' | translate}}"
              >
              <ng-template pTemplate="header">
              </ng-template>
              <ng-template let-dto pTemplate="listItem">
                <div class="col-12">
                  <p-card [style]="{'margin-bottom': '40px'}">
                    <div class="grid">
                      <div class="col-12 md:col-6 flex justify-content-between align-items-center" style="padding-right: 20px; padding-bottom: 20px; border-right: 1px solid #ccc; border-bottom: 1px solid #ccc;">
                        <img [src]="getImageUrl(dto.details[0].exportOffer)" style="border-radius: 7px; width: 100%; max-width: 100px; height: auto;" alt="" *ngIf="dto.details[0].exportOffer.offerPictures.length > 0"/>
                        <span style="border-radius: 7px; padding: 5px; font-weight: bold;" *ngIf="dto.details[0].exportOffer.offerPictures.length < 1">{{'UserPages.UserExportOffers.NotAvailablePictureLabel' | translate}}</span>
                        <div>
                          <h2 style="text-transform: uppercase; font-size: medium;">{{dto.details[0].exportOffer.variety.species | speciesName}}</h2>
                          <span style="text-transform: uppercase; font-size: medium;">{{dto.details[0].exportOffer.variety.name}}</span>
                        </div>
                        <span style="font-weight: bolder; color: #8c2b87;">{{'ImportCloseSaleTable.CodeText' | translate}}: {{dto.importOrder.sequence}}</span>
                        <div class="flex align-items-center">
                          <span class="material-symbols-outlined" style="color: #8c2b87">pallet</span>
                          <span style="margin-left: 10px;">{{dto.details[0].palletsQuantity}}</span>
                        </div>
                        <div class="flex align-items-center">
                          <p-button
                          [style]="{'font-size': 'small'}" 
                          label="{{'UserPages.UserClosedSale.ExporterClosedSaleOrders.SeeOrderButton' | translate}}" 
                          icon="pi pi-shopping-bag" (onClick)="goToImportOrderDetail(dto.importOrder.guid)"
                          >
                          </p-button>
                        </div>
                      </div>
                      <div class="col-12 md:col-6 flex justify-content-between align-items-center" style="padding-right: 20px; padding-bottom: 20px; border-right: 1px solid #ccc; border-bottom: 1px solid #ccc;">
                        <div class="col-12 md:col-8 flex justify-content-between align-items-center">
                          <div class="flex justify-content-start align-items-center">
                            <img [src]="'../../../../../assets/img/flags/' + dto.importOrder.importFromCountry.code + '.png'" height="40px" alt=""/>
                            <div class="flex flex-column" style="margin-left: 10px;">
                              <span style="font-weight: bolder; font-size: small;">{{dto.importOrder.importFromCountry.name}}</span>
                              <!-- <span style="font-size: small;" *ngIf="!dto.importOrder.portFrom">Puerto de origen (?)</span> -->
                              <span style="font-size: small;" *ngIf="dto.importOrder.portFrom">{{dto.importOrder.portFrom.name}}</span>
                            </div>
                          </div>
                          <span class="material-symbols-outlined" style="color: #6da043;" *ngIf="dto.importOrder.transportType == 0" >directions_boat</span>
                          <span class="material-symbols-outlined" style="color: #6da043;" *ngIf="dto.importOrder.transportType == 1" > flight</span>
                          <span class="material-symbols-outlined" style="color: #6da043;">arrow_forward_ios</span>
                          
                          <div class="flex justify-content-start align-items-center" *ngIf="dto.importOrder.transportType == 0">
                            <img [src]="'../../../../../assets/img/flags/' + dto.importOrder.importToCountry.code + '.png'" height="40px" alt=""/>
                            <div class="flex flex-column" style="margin-left: 10px;">
                              <span style="font-weight: bolder; font-size: small; ">{{dto.importOrder.importToCountry.name}}</span>
                              <span style="font-size: small;">{{dto.importOrder.portTo.name}}</span>
                            </div>
                          </div>

                          <div class="flex justify-content-start align-items-center" *ngIf="dto.importOrder.transportType == 1">
                            <img [src]="'../../../../../assets/img/flags/' + dto.importOrder.importToCountry.code + '.png'" height="40px" alt=""/>
                            <div class="flex flex-column" style="margin-left: 10px;">
                              <span style="font-weight: bolder; font-size: small; ">{{dto.importOrder.importToCountry.name}}</span>
                              <span style="font-size: small;">{{dto.importOrder.destinationAirport?.name}}</span>
                            </div>
                          </div>
                        </div>
                        
                        <div class="col-12 md:col-4 flex justify-content-between align-items-center">
                          <div class="flex justify-content-end">
                              <p-tag *ngIf="dto.importOrder.status == 5 " [value]="statuses[dto.importOrder.status].label" [severity]="getStatusSeverity(dto.importOrder.status)" [icon]="getStatusIcon(dto.importOrder.status)">
                              </p-tag>
                            <p-button
                            [style]="{'font-size': 'small'}"
                              label="{{'UserPages.UserClosedSale.ExporterClosedSaleOrders.DetailOrderTable.VerifyPalletsButton' | translate}}"
                              icon="pi pi-table" (onClick)="goToVerifyPalletsPage(dto.details[0].guid)"
                              styleClass="p-button-warning" *ngIf="dto.details[0].status == 1 && dto.importOrder.status != 5"
                              ></p-button>
                            <p-button
                             [style]="{'font-size': 'small'}"
                              pTooltip="{{'UserPages.UserClosedSale.ExporterClosedSaleOrders.DetailOrderTable.SeePalletsButton' | translate}}"
                              icon="pi pi-table" (onClick)="goToVerifyPalletsPage(dto.details[0].guid)"
                              styleClass="p-button-outlined" *ngIf="dto.details[0].status == 4 && dto.importOrder.status != 5"></p-button>
                          </div>
                          <div style="margin-left: 20px;">
                            <p-button 
                            [icon]="dto.importOrder.expanded ? 'pi pi-angle-up' : 'pi pi-angle-down'" 
                            styleClass="p-button-rounded p-button-outlined"
                            (click)="setVisibleOrderDetail(dto.importOrder)"
                            >
                            </p-button>
                          </div>
                        </div>
                      </div>
                      <div  *ngIf="dto.importOrder.expanded" class="col-12 md:col-6">
                        <div class="grid">
                          <div class="col-12">
                            <span class="section-title">{{'ImportCloseSaleTable.ProductDetailText' | translate}}</span>
                          </div>
                          <div class="col-6 flex flex-row align-items-center">
                            <span class="material-symbols-outlined info-icon">
                              inventory_2
                              </span>
                              <span class="info-text">
                                {{'ImportCloseSaleTable.CasePerPalletsText' | translate}}: 
                                {{dto.details[0].exportOffer.caseQuantityPerPallet}}
                              </span>
                          </div>
                          <div class="col-6 flex flex-row align-items-center">
                            <span class="material-symbols-outlined info-icon">
                              location_on
                              </span>
                              <span class="info-text">
                                {{'ImportCloseSaleTable.LocationText' | translate}}:
                                {{dto.details[0].fridgeStorage ?  
                                  dto.details[0].fridgeStorage.contactCity + ',' +  
                                  dto.details[0].fridgeStorage.contactAddress : 
                                  'No included'}}
                              </span>
                          </div>
                          <div class="col-6 flex justify-content-start align-items-center">
                              <span class="material-symbols-outlined info-icon" >
                                monetization_on
                                </span>
                                <span class="info-text">
                                  {{'ImportCloseSaleTable.ListPriceText' | translate}}:
                                  <span *ngIf="dto.details[0].palletPrices.length == 1" class="info-text" style="margin-top: 10px;">
                                    {{dto.details[0].palletPrices[0].price | currency: 'USD':'US$'}}
                                  </span>
                                  <span class="info-text" *ngIf="dto.details[0].palletPrices.length == 0">
                                    {{'ImportCloseSaleTable.PriceNotEstablished' | translate}}
                                  </span> 
                                </span>
                                <div *ngIf="dto.details[0].palletPrices.length > 1" class="see-prices" (click)="showCurrentPalletPriceModal(dto.details[0])"> 
                                  <span class="program-info" style="color: #6da043;">
                                    {{'ImportCloseSaleTable.SeePrices' | translate}}
                                  </span>
                                </div>
                                <!-- <p-button
                                *ngIf="dto.details[0].palletPrices.length > 1"
                                class="m-4"
                                styleClass="p-button-sm"
                                [style]="{'font-size': 'small'}"
                                (onClick)="showCurrentPalletPriceModal(dto.details[0])"
                                severity="secondary"
                                icon="pi pi-eye"
                                >
                                </p-button> -->
                          </div>
                          <div class="col-6 flex flex-row align-items-center">
                            <span class="material-symbols-outlined info-icon">
                              calendar_today
                              </span>
                              <span class="info-text">
                                {{'ImportCloseSaleTable.HarvestDateText' | translate}}:
                                {{dto.details[0].harvestDate ? (dto.details[0].harvestDate | date:'dd-MM-yyyy') : '-'}}
                              </span>
                          </div>
                          <div class="col-6 flex flex-row align-items-center">
                            <span class="material-symbols-outlined info-icon">
                              heart_check
                              </span>
                              <span class="info-text">
                                {{'ImportCloseSaleTable.ShelfLifeText' | translate}}:
                                {{dto.details[0].shelfLife ? dto.details[0].shelfLife : '-'}}
                              </span>
                          </div>
                          <div class="col-6 flex flex-row align-items-center">
                            <span class="material-symbols-outlined info-icon">
                              event
                              </span>
                              <span class="info-text">
                                {{'ImportCloseSaleTable.ReadyToLoadDateText' | translate}}:
                                {{dto.details[0].readyToLoadDate ? (dto.details[0].readyToLoadDate | date:'dd-MM-yyyy') : '-'}}
                              </span>
                          </div>
                          <div class="col-6 flex flex-row align-items-center">
                            <span class="material-symbols-outlined info-icon" >
                              pallet
                              </span>
                              <span class="info-text">
                                {{'ImportCloseSaleTable.PalletText' | translate}}:
                                {{dto.details[0].palletsQuantity}}
                              </span> 
                          </div>
                        </div>
                      </div>
                      <div *ngIf="dto.importOrder.expanded" class="col-12 md:col-6">
                        <div class="grid" style="margin-left: 5px;">
                          <div class="col-5">
                            <span class="section-title">{{'ImportCloseSaleTable.AgreementText' | translate}}</span>
                            <div class="col-12 flex flex-row align-items-center">
                              <span class="material-symbols-outlined info-icon">
                                person
                                </span>
                              <span class="info-text-program">
                                Importer:
                              </span>
                              <span class="program-info">
                                <!-- {{dto.details[0].directSalePrograms[0].importerCompanyName}} -->
                                {{dto.importOrder.importer.companyName}}
                              </span>
                            </div>
                            <div class="col-12 flex flex-row align-items-center">
                              <span class="material-symbols-outlined info-icon">
                                person
                                </span>
                              <span class="info-text-program">
                                Consignee:
                              </span>
                              <span class="program-info">
                                {{dto.importOrder.directSaleProgram.consigneeCompanyName}}
                              </span>
                            </div>
                            <div class="col-12 flex flex-row align-items-center">
                              <span class="material-symbols-outlined info-icon">
                                person
                              </span>
                              <span class="info-text-program">
                                Notify:
                              </span>
                              <span class="program-info">
                                {{dto.importOrder.directSaleProgram.notifyCompanyName}}
                              </span>
                            </div>
                            <div class="col-12 flex flex-row align-items-center">
                              <span class="material-symbols-outlined info-icon">
                                person
                              </span>
                              <span class="info-text-program">
                                Forwarder:
                              </span>
                              <span *ngIf="dto.importOrder.forwarderCompany != null" class="program-info">
                                {{ dto.importOrder.forwarderCompany.companyName }}
                              </span>
                              <div *ngIf="dto.importOrder.forwarderCompany == null" class="col-12 edit-div" style="display: flex;" (click)="onAssignForwarder(dto.importOrder)">
                                <span style="font-size: medium; color: #6da043; " class="material-symbols-outlined">
                                  person_add
                                </span>
                                <span class="program-info" style="color: #6da043; font-size: small;">
                                  {{'ImportCloseSaleTable.AssignForwarder' | translate}}
                                </span>
                              </div>
                            </div>
                            <div class="col-12 flex flex-row align-items-center">
                              <span class="material-symbols-outlined info-icon">
                                person
                              </span>
                              <span class="info-text-program">
                                Custom Agent:
                              </span>
                              <span *ngIf="dto.importOrder.customAgent != null" class="program-info">
                                {{ dto.importOrder.customAgent.companyName }}
                              </span>
                              <div *ngIf="dto.importOrder.customAgent == null" class="col-12 edit-div" style="display: flex;" (click)="onAssignCustomAgent(dto.importOrder)">
                                <span style="font-size: medium; color: #6da043; " class="material-symbols-outlined">
                                  person_add
                                </span>
                                <span class="program-info" style="color: #6da043; font-size: small;">
                                  {{'ImportCloseSaleTable.AssignCustomAgent' | translate}}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="col-6">
                            <span class="section-title">{{'ImportCloseSaleTable.Week/Year' | translate}}</span>
                            <div class="col-12">
                              <div class="grid">
                                <div class="col-12">
                                    <div class="flex justify-content-start align-items-end">
                                      <span class="material-symbols-outlined info-icon">
                                        calendar_today
                                      </span>
                                        <span class="program-info"> {{dto.importOrder.closedSaleWeek}} - {{dto.importOrder.closedSaleWeekYear}}</span>
                                    </div>
                                </div>
                                <div class="col-12 edit-div" (click)="onEditWeek(dto.importOrder)">
                                  <span style="font-size: medium; color: #6da043; " class="material-symbols-outlined">
                                    edit
                                  </span>
                                  <span class="program-info" style="color: #6da043; font-size: small;">
                                    {{'ImportCloseSaleTable.EditButtonLabel' | translate}}
                                  </span>
                                </div>
                              </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  </p-card>
                </div>
              </ng-template>
              </p-dataView>
          </div>
        </ng-template>
      </p-dataView>
    </p-card>
  </div>
</app-user-dashboard>

<p-toast></p-toast>

<p-dialog [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleExportOfferDialog" [style]="{width: '80%'}">
  <app-export-offer-detail [offer]="exportOffer" [productCertificateTypes]="selectedProductCertificateTypes" [packagingTypes]="selectedPackagingTypes"></app-export-offer-detail>
  <ng-template pTemplate="footer">
    <p-button label="{{'UserPages.UserClosedSale.ExporterClosedSaleOrders.CloseButton' | translate}}"
      (click)="visibleExportOfferDialog = false"></p-button>
  </ng-template>
</p-dialog>

<p-dialog header="{{'UserPages.UserClosedSale.ExporterClosedSaleOrders.NewScheduleModal.ModalHeaderText' | translate}}"
  [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleAddProgramationDialog"
  [style]="{width: '80%'}">
  <form [formGroup]="formGroup">
    <h2>{{'UserPages.UserClosedSale.ExporterClosedSaleOrders.NewScheduleModal.ModalTitle' | translate}}</h2>
    <div class="flex grid">
      <div class="col-12">
        <p-card header="Importador"
          subheader="{{'UserPages.UserClosedSale.ExporterClosedSaleOrders.NewScheduleModal.CardHeaderText' | translate}}">
          <p-table [loading]="loading" [value]="importers" selectionMode="single" [(selection)]="selectedImporter"
            dataKey="id" (onRowSelect)="loadConsigneesNotifies()" responsiveLayout="stack" [breakpoint]="'600px'">
            <ng-template pTemplate="header">
              <tr>
                <th>
                  <div class="flex justify-content-center align-items-center">
                    {{'UserPages.UserClosedSale.ExporterClosedSaleOrders.NewScheduleModal.NewScheduleTable.NameHeader' | translate}}</div>
                </th>
                <th>
                  <div class="flex justify-content-center align-items-center">
                    {{'UserPages.UserClosedSale.ExporterClosedSaleOrders.NewScheduleModal.NewScheduleTable.ContactHeader' | translate}}</div>
                </th>
                <th>
                  <div class="flex justify-content-center align-items-center">
                    {{'UserPages.UserClosedSale.ExporterClosedSaleOrders.NewScheduleModal.NewScheduleTable.CountryHeader' | translate}}</div>
                </th>
                <th>
                  <div class="flex justify-content-center align-items-center">
                    {{'UserPages.UserClosedSale.ExporterClosedSaleOrders.NewScheduleModal.NewScheduleTable.EmailHeader' | translate}}</div>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-importer>
              <tr [pSelectableRow]="importer">
                <td>
                  <div class="flex justify-content-center align-items-center">{{importer.companyName}}</div>
                </td>
                <td>
                  <div class="flex justify-content-center align-items-center">{{importer.contactName}}</div>
                </td>
                <td>
                  <div class="flex justify-content-center align-items-center">{{importer.contactCountry | countryDescription}}</div>
                </td>
                <td>
                  <div class="flex justify-content-center align-items-center">{{importer.contactEmail}}</div>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="summary">
              <small *ngIf="!selectedImporter"
                class="p-error">{{'UserPages.UserClosedSale.ExporterClosedSaleOrders.NewScheduleModal.NewScheduleTable.ImporterNotSelectedText' |
                translate}}</small>
            </ng-template>
          </p-table>
        </p-card>
      </div>
      <div class="col-12 md:col-6 lg:col-6">
        <p-card header="Consignee"
          subheader="{{'UserPages.UserClosedSale.ExporterClosedSaleOrders.NewScheduleModal.SelectConsigneeDescriptionText' | translate}}">
          <p-table [loading]="loading" [value]="consignees" selectionMode="single" [(selection)]="selectedConsignee"
            dataKey="id" responsiveLayout="stack" [breakpoint]="'600px'">
            <ng-template pTemplate="header">
              <tr>
                <th>
                  <div class="flex justify-content-center align-items-center">
                    {{'UserPages.UserClosedSale.ExporterClosedSaleOrders.NewScheduleModal.ConsigneeTable.NameTableHeader' | translate}}</div>
                </th>
                <th>
                  <div class="flex justify-content-center align-items-center">
                    {{'UserPages.UserClosedSale.ExporterClosedSaleOrders.NewScheduleModal.ConsigneeTable.ContactHeader' | translate}}</div>
                </th>
                <th>
                  <div class="flex justify-content-center align-items-center">
                    {{'UserPages.UserClosedSale.ExporterClosedSaleOrders.NewScheduleModal.ConsigneeTable.CountryHeader' | translate}}</div>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-consignee>
              <tr [pSelectableRow]="consignee">
                <td>
                  <div class="flex justify-content-center align-items-center">{{consignee.companyName}}</div>
                </td>
                <td>
                  <div class="flex justify-content-center align-items-center">{{consignee.contactName}}</div>
                </td>
                <td>
                  <div class="flex justify-content-center align-items-center">{{consignee.contactCountry | countryDescription}}</div>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="summary">
              <small *ngIf="selectedImporter && !selectedConsignee" class="p-error">
                {{'UserPages.UserClosedSale.ExporterClosedSaleOrders.NewScheduleModal.ConsigneeTable.ConsigneeNotSelectedText' | translate}}</small>
            </ng-template>
          </p-table>
        </p-card>
      </div>
      <div class="col-12 md:col-6 lg:col-6">
        <p-card header="Notify"
          subheader="{{'UserPages.UserClosedSale.ExporterClosedSaleOrders.NewScheduleModal.SelectNotifyDescriptionText' | translate}}">
          <p-table [loading]="loading" [value]="notifies" selectionMode="single" [(selection)]="selectedNotify"
            dataKey="id" responsiveLayout="stack" [breakpoint]="'600px'">
            <ng-template pTemplate="header">
              <tr>
                <th>
                  <div class="flex justify-content-center align-items-center">
                    {{'UserPages.UserClosedSale.ExporterClosedSaleOrders.NewScheduleModal.NotifyTable.NameTableHeader' | translate}}</div>
                </th>
                <th>
                  <div class="flex justify-content-center align-items-center">
                    {{'UserPages.UserClosedSale.ExporterClosedSaleOrders.NewScheduleModal.NotifyTable.ContactHeader' | translate}}</div>
                </th>
                <th>
                  <div class="flex justify-content-center align-items-center">
                    {{'UserPages.UserClosedSale.ExporterClosedSaleOrders.NewScheduleModal.NotifyTable.CountryHeader' | translate}}</div>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-notify>
              <tr [pSelectableRow]="notify">
                <td>
                  <div class="flex justify-content-center align-items-center">{{notify.companyName}}</div>
                </td>
                <td>
                  <div class="flex justify-content-center align-items-center">{{notify.contactName}}</div>
                </td>
                <td>
                  <div class="flex justify-content-center align-items-center">{{notify.contactCountry | countryDescription}}</div>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="summary">
              <small *ngIf="selectedImporter && !selectedNotify"
                class="p-error">{{'UserPages.UserClosedSale.ExporterClosedSaleOrders.NewScheduleModal.NotifyTable.NotifyNotSelectedText' | translate}}</small>
            </ng-template>
          </p-table>
        </p-card>

      </div>
    </div>
    <p-divider></p-divider>
    <h2>{{'UserPages.UserClosedSale.ExporterClosedSaleOrders.NewScheduleModal.ModalTitle2' | translate}}</h2>
    <div class="p-fluid formgrid grid">
      <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
        <label for="importToCountryId">{{'UserPages.UserClosedSale.ExporterClosedSaleOrders.NewScheduleModal.DestinationCountryFieldLabel' | translate}} *</label>
        <p-dropdown id="importToCountryId" [options]="countries" formControlName="importToCountryId" optionLabel="name"
          optionValue="id" (onChange)="loadPorts()" [filter]="true" filterBy="name" [showClear]="true"
          placeholder="{{'UserPages.UserClosedSale.ExporterClosedSaleOrders.NewScheduleModal.DestinationCountryFieldPlaceholder' | translate}}"></p-dropdown>
        <small *ngIf="submit && formGroup.controls['importToCountryId'].invalid"
          class="p-error">{{getErrorMessage('importToCountryId')}}</small>
      </div>
      <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
        <label for="portToId">{{'UserPages.UserClosedSale.ExporterClosedSaleOrders.NewScheduleModal.DestinationPortFieldLabel' | translate}} *</label>
        <p-dropdown id="portToId" [options]="ports" formControlName="portToId" optionLabel="name" optionValue="id"
          [filter]="true" filterBy="name" [showClear]="true" placeholder="{{'UserPages.UserClosedSale.ExporterClosedSaleOrders.NewScheduleModal.DestinationPortFieldPlaceholder' | translate}}"></p-dropdown>
        <small *ngIf="submit && formGroup.controls['portToId'].invalid"
          class="p-error">{{getErrorMessage('portToId')}}</small>
      </div>
      <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
        <label for="palletQuantity">{{'UserPages.UserClosedSale.ExporterClosedSaleOrders.NewScheduleModal.PalletsPerWeekFieldLabel' | translate}} *</label>
        <p-inputNumber id="palletQuantity" formControlName="palletQuantity" [showButtons]="false" [useGrouping]="false"
          [min]="1" placeholder="{{'UserPages.UserClosedSale.ExporterClosedSaleOrders.NewScheduleModal.PalletsPerWeekFieldPlaceholder' | translate}}" />
        <small *ngIf="submit && formGroup.controls['palletQuantity'].invalid"
          class="p-error">{{getErrorMessage('palletQuantity')}}</small>
      </div>
      <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
        <label for="startWeek">{{'UserPages.UserClosedSale.ExporterClosedSaleOrders.NewScheduleModal.StartWeekFieldLabel' | translate}} *</label>
        <p-inputNumber id="startWeek" formControlName="startWeek" [showButtons]="true" [useGrouping]="false" [min]="1"
          [max]="52" placeholder="{{'UserPages.UserClosedSale.ExporterClosedSaleOrders.NewScheduleModal.StartWeekFieldPlaceholder' | translate}}" />
        <small *ngIf="submit && formGroup.controls['startWeek'].invalid"
          class="p-error">{{getErrorMessage('startWeek')}}</small>
      </div>
      <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
        <label for="startWeekYear">{{'UserPages.UserClosedSale.ExporterClosedSaleOrders.NewScheduleModal.StartYearFieldLabel' | translate}} *</label>
        <p-dropdown id="startWeekYear" formControlName="startWeekYear" [options]="years" optionLabel="label"
          optionValue="value" placeholder="{{'UserPages.UserClosedSale.ExporterClosedSaleOrders.NewScheduleModal.StartYearFieldPlaceholder' | translate}}">
        </p-dropdown>
        <small *ngIf="submit && formGroup.controls['startWeekYear'].invalid"
          class="p-error">{{getErrorMessage('startWeekYear')}}</small>
      </div>
      <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;"></div>
      <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserClosedSale.ExporterClosedSaleOrders.NewScheduleModal.TotalWeeksLabel' | translate}}</label>
        <span style="font-weight: bolder; font-size: 1.5em;"> {{ totalWeeks }}</span>
      </div>
      <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserClosedSale.ExporterClosedSaleOrders.NewScheduleModal.TotalPalletsScheduled' | translate}}</label>
        <span style="font-weight: bolder; font-size: 1.5em;"> {{totalPallets}}</span>
      </div>
      <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserClosedSale.ExporterClosedSaleOrders.NewScheduleModal.TotalContainersPerWeek' | translate}}</label>
        <span style="font-weight: bolder; font-size: 1.5em;"> {{containersPerWeek}}</span>
      </div>
    </div>
  </form>

  <ng-template pTemplate="footer">
    <p-button label="{{'UserPages.UserClosedSale.ExporterClosedSaleOrders.NewScheduleModal.CancelButton' | translate}}" (onClick)="visibleAddProgramationDialog = false"
      styleClass="p-button-outlined"></p-button>
    <p-button label="{{'UserPages.UserClosedSale.ExporterClosedSaleOrders.NewScheduleModal.SaveButton' | translate}}" (onClick)="saveClosedSale()" [loading]="loading"></p-button>
  </ng-template>
</p-dialog>

<p-dialog header="{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.PalletPriceModal.CurrentPricesHeader' | translate}}" [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleCurrentPalletsPrice" [style]="{width: '800px'}">
  <p-table
  [value]="currentPrices"
  [paginator]="true"
  [rows]="10"
  [loading]="loading"
  responsiveLayout="stack"
  [breakpoint]="'600px'"
  [showCurrentPageReport]="true"
  [rowsPerPageOptions]="[10, 25, 50]"
  >
  <ng-template pTemplate="header">
    <tr>
      <th>
        {{'UserPages.UserPurchaseOrders.VerifyPalletsPage.PalletPriceModal.CategoryHeader' | translate}}
      </th>
      <th>
        {{'UserPages.UserPurchaseOrders.VerifyPalletsPage.PalletPriceModal.SpeciesSizeHeader' | translate}}
      </th>
      <th>
        {{'UserPages.UserPurchaseOrders.VerifyPalletsPage.PalletPriceModal.BoxQuantityHeader' | translate}}
      </th>
      <th>
        {{'UserPages.UserPurchaseOrders.VerifyPalletsPage.PalletPriceModal.PriceHeader' | translate}}
      </th>
    </tr>
  </ng-template>
  <ng-template  pTemplate="body" let-palletPrice>
    <tr>
      <td>
        {{palletPrice.category}}
      </td>
      <td>
        {{palletPrice.speciesSize}}
      </td>
      <td>
        {{palletPrice.boxPerPallets}}
      </td>
      <td>
        {{palletPrice.price | currency: 'USD'}}
      </td>
    </tr>
  </ng-template>
  </p-table>
</p-dialog>

<p-dialog header="{{'ImportCloseSaleTable.EditButtonLabel' | translate}}" [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleEditWeek" [style]="{width: '400px'}">
  
  <ng-container *ngIf="editWeek">
    <form [formGroup]="weekFormGroup">
        <div class="flex justify-content-between">
          <span class="p-float-label p-fluid">
            <p-inputNumber 
            id="week"   
            formControlName="week" 
            [showButtons]="true" 
            [useGrouping]="false" 
            [min]="1" 
            [max]="52" 
            placeholder="{{'UserPages.UserClosedSale.UserCreateClosedSale.StartWeekFieldPlaceholder' | translate}}" 
            class="p-inputtext-sm"/>
            <!-- <label htmlFor="week">{{'UserPages.UserClosedSale.ExporterClosedSaleOrders.WeekFieldLabel' | translate}}</label> -->
          </span>
          <span class="p-float-label p-fluid">
            <p-dropdown id="year" formControlName="year" [options]="years" optionLabel="label" optionValue="value" placeholder="{{'UserPages.UserClosedSale.UserCreateClosedSale.StartYearFieldPlaceholder' | translate}}" class="p-inputtext-sm"></p-dropdown>
            <!-- <label htmlFor="year">{{'UserPages.UserClosedSale.ExporterClosedSaleOrders.WeekYearFieldLabel' | translate}}</label> -->
          </span>

          <!-- </div> -->
          <!-- <div class="p-float-label">
            <p-dropdown 
            id="year" 
            formControlName="year" 
            [options]="years" 
            optionLabel="label" 
            optionValue="value"
            placeholder="{{'UserPages.UserClosedSale.UserCreateClosedSale.StartYearFieldPlaceholder' | translate}}" 
            class="p-inputtext-sm">
            </p-dropdown>
            <label htmlFor="year">{{'UserPages.UserClosedSale.ExporterClosedSaleOrders.WeekYearFieldLabel' | translate}}</label>
          </div> -->
        </div>
      </form>
    </ng-container>


  <ng-template pTemplate="footer">
    <p-button *ngIf="editWeek" [style]="{'height': 'auto', 'margin-left': '5px'}" label="{{'UserPages.UserClosedSale.ExporterClosedSaleOrders.WeekSaveButtonLabel' | translate}}" (onClick)="saveWeek()" [loading]="loading"></p-button>
  </ng-template>
</p-dialog>

<p-dialog header="{{'UserPages.UserClosedSale.ExporterClosedSaleOrders.AddOrderButton' | translate}}" [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleAddContainers" [style]="{width: '400px'}">
  
    <form [formGroup]="addContainersForm">
        <div class="flex justify-content-between">
          <span class="p-float-label p-fluid">
            <p-inputNumber 
            id="containersNumber"   
            formControlName="containersNumber" 
            [showButtons]="true" 
            [useGrouping]="false" 
            [min]="1" 
            [max]="10" 
            placeholder="{{'AddOrdersExporterClosed.AddContainersPlaceholder' | translate}}" 
            class="p-inputtext-sm"/>
          </span>
        </div>
      </form>
  <ng-template pTemplate="footer">
    <p-button  [style]="{'height': 'auto', 'margin-left': '5px'}" 
    label="{{'AddOrdersExporterClosed.AddContainersButton' | translate}}" 
    (onClick)="addOrdersToWeek()" [loading]="loading"></p-button>
  </ng-template>
</p-dialog>

<p-sidebar [(visible)]="sidebarVisible" position="right">
  <h3 style="text-align: center;">{{'AdvancedFilters.SideBarTitle' | translate}}</h3>

  <form [formGroup]="othersFiltersForm">
    <div class="col-12 flex justify-content-center flex-column">
      <div class="col-12 flex justify-content-center flex-column">
        <p-calendar
          inputId="harvestDate"
          formControlName="harvestDate"
          appendTo="body"
          dateFormat="dd-mm-yy"
          [showButtonBar]="true"
          [showIcon]="true"
          [showWeek]="true"
          placeholder="{{'AdvancedFilters.HarvestDatePlaceholder' | translate}}"
          [firstDayOfWeek]="1"></p-calendar>
      </div>
      <div class="col-12 flex justify-content-center flex-column">
        <input pInputText inputId="netKilos" formControlName="kilosPerBox" 
        placeholder="{{'AdvancedFilters.KilosPerBoxFieldPlaceholder' | translate}}"/>
      </div>
      <div class="col-12 flex justify-content-center flex-column">
        <p-dropdown id="fridgeStorage"
          formControlName="fridgeStorageId"
          [options]="fridgeStorages"
          optionValue="id"
          optionLabel="name"
          [filter]="true"
          filterBy="name"
          autoWidth="false" [style]="{'width':'100%'}"
          [showClear]="true"
          placeholder="{{'UserPages.UserExportOffers.NewExportOffer.FridstorageFieldPlaceholder' | translate}}"
          emptyMessage="{{'UserPages.UserExportOffers.NewExportOffer.FridgestorageEmptyMessage' | translate}}"
          class="p-inputtext-sm">
        </p-dropdown>
      </div>
      <div class="col-12 flex justify-content-center flex-column">
        <input pInputText formControlName="speciesSize" placeholder="{{'AdvancedFilters.SpeciesSizeFieldPlaceholder' | translate}}"/>
      </div>
      <div class="col-12 flex justify-content-center flex-column">
        <input pInputText formControlName="shelfLife" [min]="1" [max]="1000" placeholder="{{'AdvancedFilters.ShelfLife' | translate}}"/>
      </div>
      <div class="col-12 flex justify-content-center flex-column">
        <input pInputText formControlName="price" placeholder="{{'AdvancedFilters.PricePlaceholder' | translate}}"/>
      </div>
        <div class="col-12 flex justify-content-center flex-columnr">
          <p-button (click)="onFilter()" label="{{'AdvancedFilters.ApplyFiltersText' | translate}}" styleClass="p-button">
            <span class="material-symbols-outlined">
              tune
              </span>
          </p-button>
        </div>
    </div>
  </form>
</p-sidebar>

<p-dialog [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleAssignForwarderDialog" [style]="{width: '30%'}">
  <ng-template pTemplate="header"> 
    <h2>{{ 'ForwarderPage.ForwarderReviewsPending.Modal.Title' | translate }}</h2>
  </ng-template> 
  <ng-template pTemplate="body"> 
    <div class="p-fluid formgrid grid">
      <div class="field col-12 xl:col-12 lg:col-12" style="margin-bottom: 40px;">
        <p-dropdown inputId="profileId" [(ngModel)]="selectedCompanyForwarder" [options]="companyForwarders" optionValue="id" optionLabel="companyName" placeholder="{{ 'ForwarderPage.ForwarderReviewsPending.Modal.SelectForwarderPlaceholder' | translate }}" [style]="{'minWidth':'100%'}" class="p-inputtext-sm" appendTo="body"></p-dropdown>
      </div>
    </div>
  </ng-template> 
  <ng-template pTemplate="footer">
    <p-button [loading]="loading" label="{{ 'ForwarderPage.ForwarderReviewsPending.Modal.ButtonAssign' | translate }}" (onClick)="assignForwarder()"/>
  </ng-template>
</p-dialog>

<p-dialog [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleAssignCustomAgentDialog" [style]="{width: '30%'}">
  <ng-template pTemplate="header"> 
    <h2>{{ 'CustomAgentPage.CustomAgentReviewsPending.Modal.Title' | translate }}</h2>
  </ng-template> 
  <ng-template pTemplate="body"> 
    <div class="p-fluid formgrid grid">
      <div class="field col-12 xl:col-12 lg:col-12" style="margin-bottom: 40px;">
        <p-dropdown inputId="profileId" [(ngModel)]="selectedCompanyCustomAgent" [options]="companyCustomAgents" optionValue="id" optionLabel="companyName" placeholder="{{ 'CustomAgentPage.CustomAgentReviewsPending.Modal.SelectCustomAgentPlaceholder' | translate }}" [style]="{'minWidth':'100%'}" class="p-inputtext-sm" appendTo="body"></p-dropdown>
      </div>
    </div>
  </ng-template> 
  <ng-template pTemplate="footer">
    <p-button [loading]="loading" label="{{ 'CustomAgentPage.CustomAgentReviewsPending.Modal.ButtonAssign' | translate }}" (onClick)="assignCustomAgent()"/>
  </ng-template>
</p-dialog>

<p-confirmDialog acceptLabel="Aceptar" rejectLabel="Cancelar" rejectButtonStyleClass="p-button-outlined" [style]="{width: '50vw'}"></p-confirmDialog>
