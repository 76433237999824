<app-admin-dashboard>
  <p-card>
    <h1>Asociar Exportador con Importadores</h1>

    <p-fieldset legend="Exporter">
      <div class="grid">
        <div class="col-3" style="font-weight: bolder;">Id</div>
        <div class="col-3">{{exporter?.id}}</div>
        <div class="col-3" style="font-weight: bolder;">Nombre de empresa</div>
        <div class="col-3">{{exporter?.companyName}}</div>

        <div class="col-3" style="font-weight: bolder;">VAT</div>
        <div class="col-3">{{exporter?.vat}}</div>
        <div class="col-3" style="font-weight: bolder;">Nombre de contacto</div>
        <div class="col-3">{{exporter?.contactName}}</div>

        <div class="col-3" style="font-weight: bolder;">Email</div>
        <div class="col-3">{{exporter?.contactEmail}}</div>
        <div class="col-3" style="font-weight: bolder;">Ciudad</div>
        <div class="col-3">{{exporter?.contactCity}}</div>

        <div class="col-3" style="font-weight: bolder;">País</div>
        <div class="col-3">{{exporter?.country | countryDescription}}</div>
        <div class="col-3"></div>
        <div class="col-3"></div>
      </div>
    </p-fieldset>

    <div style="margin-top: 40px;">
      <p-table [value]="importers"
        [paginator]="true"
        [rows]="5"
        [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[10, 25, 50]"
        stateStorage="session"
        stateKey="statedemo-session">
        <ng-template pTemplate="header">
          <tr>
            <th>Id</th>
            <th>Nombre de empresa</th>
            <th>VAT</th>
            <th>Nombre de contacto</th>
            <th>Email</th>
            <th>Ciudad</th>
            <th>País</th>
            <th>Asociado</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-importer>
          <tr>
            <td>{{ importer.id }}</td>
            <td>{{ importer.companyName }}</td>
            <td>{{ importer.vat }}</td>
            <td>{{ importer.contactName }}</td>
            <td>{{ importer.contactEmail }}</td>
            <td>{{ importer.contactCity }}</td>
            <td>{{ importer.country | countryDescription }}</td>
            <td>
              <p-inputSwitch [(ngModel)]="importer.isExporterAsociated" (onChange)="onToogleAsociation(importer.id, $event)"></p-inputSwitch>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>


    <div style="margin-top: 40px;">
      <p-button label="Regresar" (onClick)="goToAdminUsers()"></p-button>
    </div>

  </p-card>
</app-admin-dashboard>
<p-toast></p-toast>
