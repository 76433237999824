import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { ExportOfferGateway } from 'src/app/application/gateway/ExportOfferGateway';
import { ProductCertificateTypeGateway } from 'src/app/application/gateway/ProductCertificateTypeGateway';
import { ExportOfferProductCertificateTypeResult } from 'src/app/domain/models/ExportOffer';
import { ProductCertificateType } from 'src/app/domain/models/ProductCertificateType';

@Component({
  selector: 'app-export-offer-certificate-type',
  templateUrl: './export-offer-certificate-type.component.html',
  styleUrls: ['./export-offer-certificate-type.component.css']
})
export class ExportOfferCertificateTypeComponent implements OnInit {

  @Input()
  exportOfferId: any | undefined

  productCertificateTypes: ProductCertificateType[] = []
  selectedProductCertificateTypes: ProductCertificateType[] = []

  @Input()
  otherCertificateType: string | undefined

  loading: boolean = false

  constructor(private _messageService: MessageService,
    private _productCertificateTypeService: ProductCertificateTypeGateway,
    private _exportOfferService: ExportOfferGateway,
    public translate: TranslateService) {

  }

  ngOnInit(): void {
    this.getProductCertificateTypes()
  }

  getProductCertificateTypes() {
    this.loading = true
    this._productCertificateTypeService.getAll().subscribe({
      next: (v) => {
        this.productCertificateTypes = v
        this.getExportOfferProductCertificateTypes()
      },
      error: (e) => {
        console.log(e)
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.ProductCertificateType.ManyRetrieveError') })
        this.loading = false
      },
      complete: () => console.info('Product Certificate Types query successfully')
    })
  }

  getExportOfferProductCertificateTypes() {
    if(this.exportOfferId) {
      this._exportOfferService.getProductCertificateTypesByExportOfferId(this.exportOfferId).subscribe({
        next: (v) => {
          this.selectedProductCertificateTypes = v
          this.loading = false
        },
        error: (e) => {
          console.log(e)
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ProductCertificateType.ManyRetrieveError') })
          this.loading = false
        },
        complete: () => console.info('Product Certificate Types query successfully')
      })
    }
    else {
      this.loading = false
    }
  }

  getSelectedProductCertificateTypes(): ExportOfferProductCertificateTypeResult {
    return {
      productCertificateTypes: this.selectedProductCertificateTypes,
      otherCertificateType: this.otherCertificateType
    }
  }
}
