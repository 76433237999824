import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Estimation } from "src/app/domain/models/Route";
import { Routes } from "src/app/infrastructure/routes";
import { EstimationGateway } from "../gateway/EstimationGateway";

@Injectable({providedIn: 'root'})
export class EstimationService extends EstimationGateway {
  constructor(private _http: HttpClient) {
    super()
  }

  getAllByFilter(countryFromId: number, portToId: number): Observable<Estimation[]> {
    return this._http.get<Estimation[]>(Routes.estimationApi + '/GetAllByFilter', { params: {countryFromId, portToId}})
  }

}
