import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, MessageService } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import readXlsxFile, { readSheetNames } from 'read-excel-file';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { ConsigneeGateway } from 'src/app/application/gateway/ConsigneeGateway';
import { CountryGateway } from 'src/app/application/gateway/CountryGateway';
import { ExportOfferGateway } from 'src/app/application/gateway/ExportOfferGateway';
import { FridgeStorageGateway } from 'src/app/application/gateway/FridgeStorageGateway';
import { PackagingGateway } from 'src/app/application/gateway/PackagingGateway';
import { PortGateway } from 'src/app/application/gateway/PortGateway';
import { ProductCertificateTypeGateway } from 'src/app/application/gateway/ProductCertificateTypeGateway';
import { ProfileGateway } from 'src/app/application/gateway/ProfileGateway';
import { SpeciesGateway } from 'src/app/application/gateway/SpeciesGateway';
import { VarietyGateway } from 'src/app/application/gateway/VarietyGateway';
import { NotifierService } from 'src/app/application/service/NotifierService';
import { Consignee } from 'src/app/domain/models/Consignee';
import { Country } from 'src/app/domain/models/Country';
import { ClosedSaleCreateRequest, ExportOffer, NewExportOffer } from 'src/app/domain/models/ExportOffer';
import { ExportOfferDocument, NewExportOfferDocument } from 'src/app/domain/models/ExportOfferDocument';
import { FridgeStorage, NewFridgeStorage } from 'src/app/domain/models/FridgeStorage';
import { Notifier } from 'src/app/domain/models/Notifier';
import { Packaging } from 'src/app/domain/models/Packaging';
import { Port } from 'src/app/domain/models/Port';
import { NewProductCertificate, ProductCertificate } from 'src/app/domain/models/ProductCertificate';
import { Importer, Profile } from 'src/app/domain/models/Profile';
import { Species, SpeciesSize } from 'src/app/domain/models/Species';
import { BasicUser } from 'src/app/domain/models/User';
import { Variety } from 'src/app/domain/models/Variety';
import { Routes } from 'src/app/infrastructure/routes';
import { isDivisor } from 'src/app/ui/util/custom-validators';
import { ErrorMessageUtil } from 'src/app/ui/util/error-message-util';
import { ExportOfferUtils } from 'src/app/ui/util/export-offer-utils';
import { formLimit } from 'src/app/ui/util/form-limit';
import { Lang } from 'src/app/ui/util/langs';
import { SpeciesUtils } from 'src/app/ui/util/species-utils';

const Steps = {
  Step1: "step1",
  Step2: "step2"
}

@Component({
  selector: 'app-user-create-closed-sale',
  templateUrl: './user-create-closed-sale.component.html',
  styleUrls: ['./user-create-closed-sale.component.css'],
  providers: [MessageService]
})
export class UserCreateClosedSaleComponent implements OnInit {
  breadcrumbItemsStep1: MenuItem[] = []
  breadcrumbItemsStep2: MenuItem[] = []
  home: MenuItem = {}

  submit: boolean = false
  submitStep2: boolean = false
  loading: boolean = false
  loadExcelDialogVisible: boolean = false
  loadFridgestorageDialogVisible: boolean = false

  exportOffer: ExportOffer | undefined
  profile: BasicUser | undefined

  species: Species[] = []
  varieties: Variety[] = []
  selectedSpecies: Species | undefined
  selectedVariety: Variety | undefined
  selectedImporter: Profile | undefined
  selectedConsignee: Consignee | undefined
  selectedNotify: Notifier | undefined

  colors: string[] = []
  sizes: SpeciesSize[] = []
  qualityControlOptions: any[] = []
  boxTypes: any[] = []
  kilosPerBoxes: any[] = []
  basePallets: any[] = []
  marksOptions: any[] = []
  countries: Country[] = []
  selectedCountryTo: Country | undefined
  ports: Port[] = []

  certificateTypes: any[] = []
  selectedCertifications: number[] = []
  fridgeStorages: FridgeStorage[] = []

  importers: Importer[] = []
  consignees: Consignee[] = []
  notifies: Notifier[] = []
  folios: any[] = []
  palletsFolios: number[] = []

  excelFile: any
  excelSheets: any
  selectedSheet: string = ''
  column: string = ''
  row: number = 1
  columns: string[] = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
  readedFolios: any[] = []

  incotermsOptions: any[] = []

  step: string = Steps.Step1

  uploadedImages: any[] = []

  productCertificates: any[] = []
  productCertificateDialogVisible: boolean = false
  submitProductCertificate: boolean = false

  documents: any[] = []
  documentDialogVisible: boolean = false
  submitDocument: boolean = false

  packagingList: Packaging[] = []
  packagingSelected: number[] = []

  formGroup: FormGroup = this._formBuilder.group({
    speciesId: ['', Validators.required],
    varietyId: ['', Validators.required],
    palletQuantity: [20, [Validators.required, isDivisor(20)]],
    fridgeStorageId: ['', Validators.required],
    listPrice: [null, Validators.required],
    caseQuantityPerPallet: [null, Validators.required],
    incoterm: ['EXW', Validators.required],
    paymentAgainstBL: [100, Validators.required],
    speciesSize: [null],
    harvestDate: [null, Validators.required],
    readyToLoadDate: [null],
    shelfLife: [null, Validators.required],
    boxType: ['', Validators.required],
    caseSize: [''],
    kilosPerBox: [''],
    netKilos: ['', Validators.required],
    grossKilos: ['', Validators.required],
    packaging: [''],
    isQualityControl: [false],
    qualityControlDate: [null],
    basePallets: [''],
    marks: [false],
    importToCountryId: [null, Validators.required],
    portToId: [null, Validators.required],
    startWeek: [null, Validators.required],
    startWeekYear: [this.currentYear, Validators.required],
    endWeek: [null],
    endWeekYear: [null],
    sizeMillimeters: [null],
    category: [null],
    packagings: ['', Validators.required],
    otherPackaging: [{value: '', disabled: true}],
  })

  get currentYear(): number {
    return new Date().getFullYear()
  }

  fridgestorageFormGroup: FormGroup = this._formBuilder.group({
    fridgestorageName: [null, Validators.required],
    fridgestorageVat: [null, Validators.required],
    fridgestorageContactName: [null, Validators.required],
    fridgestorageContactAddress: [null, Validators.required],
    fridgestorageContactCity: [null, Validators.required],
    fridgestorageContactEmail: [null, Validators.required],
    fridgestorageContactPhone: [null, Validators.required],
    fridgestorageContactCountryId: [null, Validators.required]
  })

  productCertificatePdfFile: any
  productCertificateFormGroup: FormGroup = this._formBuilder.group({
    name: ['', Validators.required],
    documentIssueDate: [null],
    expirationDate: [null],
    productCertificateTypeId: ['', Validators.required]
  })

  documentPdfFile: any
  documentFormGroup: FormGroup = this._formBuilder.group({
    name: ['', Validators.required]
  })

  palletsFormGroup: FormGroup = this._formBuilder.group({})

  formLimit = formLimit

  constructor(private _router: Router,
    private _messageService: MessageService,
    private _authService: AuthGateway,
    private _profileService: ProfileGateway,
    private _formBuilder: FormBuilder,
    private _varietyService: VarietyGateway,
    private _speciesService: SpeciesGateway,
    private _fridgeStorageService: FridgeStorageGateway,
    private _exportOfferService: ExportOfferGateway,
    private _productCertificateTypeService: ProductCertificateTypeGateway,
    private _countryService: CountryGateway,
    private _consigneeService: ConsigneeGateway,
    private _notifyService: NotifierService,
    private _portService: PortGateway,
    private _packagingService: PackagingGateway,
    public translate: TranslateService) {
  }

  ngOnInit(): void {
    this.profile = this._authService.getUser()

    if(this.profile) {
      this.loadImporters()
      this.colors = ExportOfferUtils.getColors(this.profile.lang)
      this.boxTypes = ExportOfferUtils.getBoxTypes(this.profile.lang)
      this.kilosPerBoxes = ExportOfferUtils.getKilosPerBox()
      this.basePallets = ExportOfferUtils.getBasePallets(this.profile.lang)
      this.qualityControlOptions = ExportOfferUtils.getControlQualityOptions(this.profile.lang)
      this.marksOptions = ExportOfferUtils.getMarksOptions(this.profile.lang)
      this.incotermsOptions = ExportOfferUtils.getIncotermsClosedSaleOptions()
      this.getYears()

      let breadcrumbStep1ItemLabel1 = 'Load export offer'
      let breadcrumbStep1ItemLabel2 = 'Load pallets'
      let homeItemLabel = 'My direct sales'

      switch(this.profile.lang) {
        case 'en':
          breadcrumbStep1ItemLabel1 = 'Load export offer'
          breadcrumbStep1ItemLabel2 = 'Load pallets'
          homeItemLabel = 'My direct sales'
          break
        case 'es':
          breadcrumbStep1ItemLabel1 = 'Cargar nuevo producto'
          breadcrumbStep1ItemLabel2 = 'Cargar Folios de Pallets'
          homeItemLabel = 'Mis ventas directas'
          break
      }

      this.breadcrumbItemsStep1 = [{ label: breadcrumbStep1ItemLabel1 }]
      this.breadcrumbItemsStep2 = [
        { label: breadcrumbStep1ItemLabel1, command: () => this.showStep1() },
        { label: breadcrumbStep1ItemLabel2 }]
      this.home = { label: homeItemLabel, routerLink: '/' + Routes.userClosedSales };      
    }


    this.loadSpecies()
    this.loadProductCertificateTypes()
    this.loadFridgeStorages()
    this.loadCountries()
    this.loadPackagings()
  }


  getSpeciesName(species: Species): string {
    if(this.profile)
      return SpeciesUtils.getSpeciesName(this.profile.lang, species)
    return ''
  }

  loadSpecies() {
    this._speciesService.getAllByProductType(1).subscribe({
      next: (v) => {
        this.species = v
        this.loading = false
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Species.ManyRetrieveError') })
      },
      complete: () => console.info('Species query successfully')
    })
  }

  loadProductCertificateTypes() {
    this._productCertificateTypeService.getAll().subscribe({
      next: (v) => {
        this.certificateTypes = v
        this.loading = false
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.ProductCertificateType.ManyRetrieveError') })
      },
      complete: () => console.info('ProductCertificateTypes query successfully')
    })
  }

  loadVarieties() {
    this.varieties = []
    this._varietyService.getAllBySpecies(this.formGroup.controls['speciesId'].value).subscribe({
      next: (v) => {
        this.varieties = v
        this.loading = false
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Variety.ManyRetrieveError') })
      },
      complete: () => console.info('Variety query successfully')
    })
  }

  loadSpeciesParams() {
    this.selectedSpecies = this.species.find(x => x.id === this.formGroup.controls['speciesId'].value)
    this.loadVarieties()
  }

  loadFridgeStorages() {
    this.fridgeStorages = []
    this._fridgeStorageService.getAllByStorageType(1).subscribe({
      next: (v) => {
        this.fridgeStorages = v
        this.loading = false
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.FridgeStorage.ManyRetrieveError') })
      },
      complete: () => console.info('Fridgestorage query successfully')
    })
  }

  loadCountries() {
    this.countries = []
    this._countryService.getAll().subscribe({
      next: (v) => {
        this.countries = v
        this.loading = false
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Country.ManyRetrieveError') })
      },
      complete: () => console.info('Country query successfully')
    })
  }

  loadImporters() {
    this.importers = []
    if(this.profile) {
      this._profileService.getAllImportersByExporterId().subscribe({
        next: (v) => {
          this.importers = v
          this.loading = false
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.Importer.ManyRetrieveError') })
        },
        complete: () => console.info('Importers query successfully')
      })
    }
  }

  loadConsignees() {
    if(this.selectedImporter) {
      this._consigneeService.getAllForClosedSale(this.selectedImporter.id).subscribe({
        next: (v) => {
          this.consignees = v
          this.loading = false
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.Consignee.ManyRetrieveError') })
        },
        complete: () => console.info('Consignees query successfully')
      })
    }
  }

  loadNotifies() {
    if(this.selectedImporter) {
      this._notifyService.getAllForClosedSale(this.selectedImporter.id).subscribe({
        next: (v) => {
          this.notifies = v
          this.loading = false
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.Notify.ManyRetrieveError') })
        },
        complete: () => console.info('Notifies query successfully')
      })
    }
  }

  loadConsigneesNotifies() {
    this.loadConsignees()
    this.loadNotifies()
  }

  loadPorts() {
    this.selectedCountryTo = this.countries.find(x => x.id == this.formGroup.controls['importToCountryId'].value)

    if(this.formGroup.controls['importToCountryId'].value) {
      this._portService.getDestinationPortsByCountry(this.formGroup.controls['importToCountryId'].value).subscribe({
        next: (v) => {
          this.ports = v
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.Port.DestinationPortManyRetrieveError') })
        },
        complete: () => console.info('Destination Ports query successfully')
      })
    }
  }

  loadPackagings() {
    this._packagingService.getAll().subscribe({
      next: (v) => {
        this.packagingList = v
        this.loading = false
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Packaging.ManyRetrieveError') })
      },
      complete: () => console.info('Packaging query successfully')
    })
  }

  onChangeVariety() {
    this.selectedVariety = this.varieties.find(x => x.id === this.formGroup.controls['varietyId'].value)
  }

  getMaxPallets() {
    return this.formGroup.controls['palletQuantity'].value ? this.formGroup.controls['palletQuantity'].value : 0
  }

  onSelectImage(event: any, uploader: any) {
    this.uploadedImages = uploader.files
  }

  onRemoveImage(event: any, uploader: any) {
    this.uploadedImages = uploader.files
  }

  onClearImageUploader(event: any, uploader: any) {
    this.uploadedImages = uploader.files
  }

  save() {
    this.loading = true
    this.submit = true
    this.submitStep2 = true

    if(this.formGroup.valid) {

      if(!this.selectedImporter) {
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Importer.NullImporterError') })
        this.loading = false
        return
      }

      if(!this.selectedConsignee) {
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Consignee.NullConsigneeError') })
        this.loading = false
        return
      }

      if(!this.selectedNotify) {
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Notify.NullNotifyError') })
        this.loading = false
        return
      }

      let startWeek = this.formGroup.controls['startWeek'].value
      let startWeekYear = this.formGroup.controls['startWeekYear'].value
      let endWeek = this.formGroup.controls['endWeek'].value
      let endWeekYear = this.formGroup.controls['endWeekYear'].value

      if(endWeekYear && startWeekYear > endWeekYear) {
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.ExportOffer.ClosedSaleYearRangeError') })
        this.loading = false
        return
      }

      if(endWeekYear && startWeekYear === endWeekYear && startWeek > endWeek) {
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.ExportOffer.ClosedSaleWeekRangeError')})
        this.loading = false
        return
      }

      let offer: NewExportOffer = {
        exporterUsername: this._authService.getUsername(),
        varietyId: this.formGroup.controls['varietyId'].value,
        palletQuantity: this.formGroup.controls['palletQuantity'].value,
        fridgeStorageId: this.formGroup.controls['fridgeStorageId'].value,
        availablePallets: this.totalPallets,
        packaging: this.formGroup.controls['packaging'].value,
        harvestDate: this.formGroup.controls['harvestDate'].value,
        readyToLoadDate: this.formGroup.controls['readyToLoadDate'].value,
        boxType: this.formGroup.controls['boxType'].value,
        kilosPerBox: this.formGroup.controls['kilosPerBox'].value,
        netKilos: this.formGroup.controls['netKilos'].value,
        grossKilos: this.formGroup.controls['grossKilos'].value,
        caseQuantityPerPallet: this.formGroup.controls['caseQuantityPerPallet'].value,
        isQualityControl: this.formGroup.controls['isQualityControl'].value,
        shelfLife: this.formGroup.controls['shelfLife'].value,
        speciesSize: this.formGroup.controls['speciesSize'].value,
        listPrice: this.formGroup.controls['listPrice'].value,
        pictures: this.uploadedImages,
        certificates: this.selectedCertifications,
        qualityControlDate: this.formGroup.controls['qualityControlDate'].value,
        basePallets: this.formGroup.controls['basePallets'].value,
        marks: this.formGroup.controls['marks'].value,
        typeSale: this.formGroup.controls['incoterm'].value,
        isClosedSale: true,
        closedSaleStartWeek: this.formGroup.controls['startWeek'].value,
        closedSaleStartWeekYear: this.formGroup.controls['startWeekYear'].value,
        closedSaleEndWeek: this.formGroup.controls['endWeek'].value,
        closedSaleEndWeekYear: this.formGroup.controls['endWeekYear'].value,
        sizeMillimeters: this.formGroup.controls['sizeMillimeters'].value,
        category: this.formGroup.controls['category'].value,
        caseSize: this.formGroup.controls['caseSize'].value,
        productCertificates: this.productCertificates,
        documents: this.documents,
        paymentAgainstBL: this.formGroup.controls['paymentAgainstBL'].value,
        otherPackaging: this.formGroup.controls['otherPackaging'].value
      }

      let closedSaleCreateRequest: ClosedSaleCreateRequest = {
        exportOffer: offer,
        importToCountryId: this.formGroup.controls['importToCountryId'].value,
        portToId: this.formGroup.controls['portToId'].value,
        importerId: this.selectedImporter.id,
        consigneeId: this.selectedConsignee.id ? this.selectedConsignee.id : 0,
        notifyId: this.selectedNotify.id ? this.selectedNotify.id: 0,
        palletsQuantity: this.formGroup.controls['palletQuantity'].value,
        week: this.formGroup.controls['startWeek'].value,
        packagings: this.packagingSelected
      }

      this._exportOfferService.createClosedSale(closedSaleCreateRequest).subscribe({
        next: (v) => {
          this.exportOffer = v
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: this.translate.instant('MessageService.ExportOffer.CreateSuccess') })
          this.submit = false
          this.loading = false
          this.step = 'step3'
        },
        error: (e) => {
          this.loading = false
          console.log(e)
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOffer.CreateError') })
        },
        complete: () => console.info('Export Offer created successfully')
      })
    }
    else {
      this.loading = false
      this._messageService.add({ severity: 'error',
        summary: this.translate.instant('MessageService.Summary.FailedAction'),
        detail: this.translate.instant('MessageService.FormMessages.FormInvalid') })
    }
  }

  showStep1() {
    this.step = Steps.Step1
  }

  showStep2() {
    this.submitStep2 = true
    if(this.formGroup.valid) {

      if(!this.selectedImporter) {
        this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'Debe seleccionar un Importador.' })
        return
      }

      if(!this.selectedConsignee) {
        this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'Debe seleccionar un Consignee.' })
        return
      }

      if(!this.selectedNotify) {
        this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'Debe seleccionar un Notify.' })
        return
      }

      this.generatePalletFields()
      this.submitStep2 = false
      this.step = Steps.Step2
    }
    else {
      this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'Hay errores en el formulario, por favor revise antes de seguir.' });
    }
  }

  generatePalletFields() {
    this.palletsFormGroup = this._formBuilder.group({})
    this.folios = []
    for(let i = 0; i < this.formGroup.controls['salePalletsQuantity'].value; i++) {
      this.folios.push({}) //Crea un array con objetos ficticio para ejecutar el ngFor
      this.palletsFormGroup.addControl(i.toString(), new FormControl(null, Validators.required))
    }
  }

  onSelectExcelFile(uploader: any) {
    this.excelFile = uploader.files[0]
    readSheetNames(uploader.files[0]).then((sheetNames) => {
      this.excelSheets = sheetNames
    })

    uploader.clear()
  }

  onLoadExcel() {
    readXlsxFile(this.excelFile).then((rows) => {
      // `rows` is an array of rows
      // each row being an array of cells.
      if(rows && rows.length > 0) {
        const columnIndex = this.columns.indexOf(this.column.toUpperCase())
        const rowIndex = this.row - 1

        let maxColumnIndex = rows[0].length
        rows.forEach(x => {
          if(x.length > maxColumnIndex)
            maxColumnIndex = x.length
        })

        if(columnIndex < 0)
          this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'Debe ingresar una columna de la A a la Z' })

        if(columnIndex > maxColumnIndex)
          this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'La columna ingresada sobrepasa las columnas leídas del archivo cargado' })

        if(rowIndex > rows.length)
          this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'La fila ingresada sobrepasa las filas leídas del archivo cargado.' })

        for (let i = rowIndex; i < rows.length; i++) {
          let row = rows[i]
          //console.log("Readed row " + row)
          this.readedFolios.push(row[columnIndex])
        }

        if(this.formGroup.controls['salePalletsQuantity'].value) {
          for(let i = 0; i < this.formGroup.controls['salePalletsQuantity'].value; i++) {
            this.palletsFormGroup.controls[i.toString()].setValue(this.readedFolios[i] ? this.readedFolios[i] : null)
          }
        }
      }
    })
    this.loadExcelDialogVisible = false
  }

  goToCloseSalesPage() {
    this._router.navigate([Routes.userClosedSales])
  }

  goToCloseSaleOrdersPage() {
    if(this.exportOffer) {
      this._router.navigate([Routes.exporterClosedSaleOrdersPage, this.exportOffer.id])
    }
  }

  getErrorMessage(formGroup: FormGroup, fieldName: string): string {
    return ErrorMessageUtil.getErrorMessage(formGroup, this.translate, fieldName, 20)
  }

  getPalletErrorMessage(fieldName: string): string {
    if (this.palletsFormGroup.controls[fieldName].hasError('required')) {
      return 'Este campo es requerido.';
    }
    return '';
  }

  years: any[] = []
  getYears() {
    let current = new Date().getFullYear()
    this.years.push({label: current, value: current})
    let next = current + 1
    this.years.push({label: next, value: next})
  }

  get totalPallets(): number {
    let pallets = this.formGroup.controls['palletQuantity'].value
    let startWeek = this.formGroup.controls['startWeek'].value
    let startWeekYear = this.formGroup.controls['startWeekYear'].value
    let endWeek = this.formGroup.controls['endWeek'].value
    let endWeekYear = this.formGroup.controls['endWeekYear'].value

    if(pallets) {
      if(startWeekYear && endWeekYear && startWeekYear === endWeekYear && endWeek > startWeek) {
        return ((endWeek - startWeek) + 1) * pallets
      }

      if (startWeekYear && endWeekYear && endWeekYear == startWeekYear + 1) {
        return ((52 - startWeek) + endWeek + 1) * pallets
      }

      return pallets
    }
    return 0
  }

  get totalWeeks(): number {
    let startWeek = this.formGroup.controls['startWeek'].value
    let startWeekYear = this.formGroup.controls['startWeekYear'].value
    let endWeek = this.formGroup.controls['endWeek'].value
    let endWeekYear = this.formGroup.controls['endWeekYear'].value

    return ExportOfferUtils.getTotalWeeks(startWeek, startWeekYear, endWeek, endWeekYear)
  }

  get containersPerWeek(): number {
    let pallets = this.formGroup.controls['palletQuantity'].value
    if(pallets && pallets % 20 == 0) {
      return pallets / 20
    }
    return 0
  }

  openNewFridgestorageDialog() {
    this.fridgestorageFormGroup.controls["fridgestorageName"].setValue(''),
    this.fridgestorageFormGroup.controls["fridgestorageVat"].setValue(''),
    this.fridgestorageFormGroup.controls["fridgestorageContactName"].setValue(''),
    this.fridgestorageFormGroup.controls["fridgestorageContactAddress"].setValue(''),
    this.fridgestorageFormGroup.controls["fridgestorageContactEmail"].setValue(''),
    this.fridgestorageFormGroup.controls["fridgestorageContactPhone"].setValue(''),
    this.fridgestorageFormGroup.controls["fridgestorageContactCity"].setValue(''),
    this.fridgestorageFormGroup.controls["fridgestorageContactCountryId"].setValue('')
    this.loadFridgestorageDialogVisible = true
  }

  saveFridgestorage() {
    this.submit = true
    this.loading = true
    if(this.fridgestorageFormGroup.valid) {
      const newFridgeStorage: NewFridgeStorage = {
        name: this.fridgestorageFormGroup.controls["fridgestorageName"].value,
        vat: this.fridgestorageFormGroup.controls["fridgestorageVat"].value,
        contactName: this.fridgestorageFormGroup.controls["fridgestorageContactName"].value,
        contactAddress: this.fridgestorageFormGroup.controls["fridgestorageContactAddress"].value,
        contactEmail: this.fridgestorageFormGroup.controls["fridgestorageContactEmail"].value,
        contactPhone: this.fridgestorageFormGroup.controls["fridgestorageContactPhone"].value,
        contactCity: this.fridgestorageFormGroup.controls["fridgestorageContactCity"].value,
        contactCountryId: this.fridgestorageFormGroup.controls["fridgestorageContactCountryId"].value,
        storageType: 1
      }

      this._fridgeStorageService.create(newFridgeStorage).subscribe({
        next: (v) => {
          this.loadFridgeStorages()
          this.loadFridgestorageDialogVisible = false
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: this.translate.instant('MessageService.FridgeStorage.CreateSuccess') })
          this.loading = false
          this.submit = false
        },
        error: (e) => {
          this.loading = false
          this.submit = false
          console.log(e)
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.FridgeStorage.CreateError') });
        },
        complete: () =>  {
          this.loading = false
          this.submit = false
          console.info('FridgeStorage query successfully')
        }
      })
    }
    else {
      this.loading = false
    }
  }

  selectedProductCertificate: ProductCertificate | undefined
  openProductCertificateCreateDialog(formDirective: FormGroupDirective, fileUploader: FileUpload) {
    fileUploader.clear()
    formDirective.resetForm()
    this.productCertificateFormGroup.controls['name'].setValue('')
    this.productCertificateFormGroup.controls['productCertificateTypeId'].setValue('')
    this.productCertificateFormGroup.controls['documentIssueDate'].setValue('')
    this.productCertificateFormGroup.controls['expirationDate'].setValue('')
    this.productCertificatePdfFile = null
    this.selectedProductCertificate = undefined
    this.submitProductCertificate = false
    this.productCertificateDialogVisible = true
  }

  openProductCertificateEditDialog(certificate: ProductCertificate, fileUploader: FileUpload) {
    this.selectedProductCertificate = certificate
    this.productCertificateFormGroup.controls['name'].setValue(this.selectedProductCertificate.name)
    this.productCertificateFormGroup.controls['productCertificateTypeId'].setValue(this.selectedProductCertificate.productCertificateTypeId)
    this.productCertificateFormGroup.controls['documentIssueDate'].setValue(this.selectedProductCertificate.documentIssueDate ? new Date(this.selectedProductCertificate.documentIssueDate) : null)
    this.productCertificateFormGroup.controls['expirationDate'].setValue(this.selectedProductCertificate.expirationDate ? new Date(this.selectedProductCertificate.expirationDate) : null)

    if(this.selectedProductCertificate.pdf) {
      fileUploader.clear()
      fileUploader.files = [this.selectedProductCertificate.pdf]
      this.productCertificatePdfFile = this.selectedProductCertificate.pdf
    }

    this.productCertificateDialogVisible = true
  }

  onDeleteProductCertificate(certificate: ProductCertificate) {
    if(certificate) {
      const index = this.productCertificates.indexOf(certificate)
      this.productCertificates.splice(index, 1);
    }
  }


  onSelectProductCertificateFile(event: any) {
    this.productCertificatePdfFile = event.files[0]
  }

  onClearProductCertificateFile(event: any) {
    this.productCertificatePdfFile = null
  }

  saveProductCertificate() {
    this.submitProductCertificate = true
    if(this.productCertificateFormGroup.valid) {
      const productCertificateType = this.certificateTypes.find(x => x.id == this.productCertificateFormGroup.controls['productCertificateTypeId'].value)
      if(!this.selectedProductCertificate) {
        let productCertificate: NewProductCertificate = {
          name: this.productCertificateFormGroup.controls['name'].value,
          productCertificateTypeId: this.productCertificateFormGroup.controls['productCertificateTypeId'].value,
          productCertificateType: productCertificateType,
          documentIssueDate: this.productCertificateFormGroup.controls['documentIssueDate'].value,
          expirationDate: this.productCertificateFormGroup.controls['expirationDate'].value,
          pdf: this.productCertificatePdfFile
        }
        this.productCertificates.push(productCertificate)
        this.productCertificateDialogVisible = false
        this.submitProductCertificate = false
      }
      else {
        this.selectedProductCertificate.name = this.productCertificateFormGroup.controls['name'].value
        this.selectedProductCertificate.productCertificateTypeId = this.productCertificateFormGroup.controls['productCertificateTypeId'].value
        this.selectedProductCertificate.productCertificateType = productCertificateType
        this.selectedProductCertificate.documentIssueDate = this.productCertificateFormGroup.controls['documentIssueDate'].value
        this.selectedProductCertificate.expirationDate = this.productCertificateFormGroup.controls['expirationDate'].value
        this.selectedProductCertificate.pdf = this.productCertificatePdfFile
        this.productCertificateDialogVisible = false
        this.submitProductCertificate = false
      }
    }
  }

  selectedDocument: ExportOfferDocument | undefined
  openDocumentCreateDialog(formDirective: FormGroupDirective, fileUploader: FileUpload) {
    fileUploader.clear()
    formDirective.resetForm()
    this.documentFormGroup.controls['name'].setValue('')
    this.documentPdfFile = null
    this.selectedDocument = undefined
    this.submitDocument = false
    this.documentDialogVisible = true
  }

  openDocumentEditDialog(document: ExportOfferDocument, fileUploader: FileUpload) {
    this.selectedDocument = document
    this.documentFormGroup.controls['name'].setValue(this.selectedDocument.name)

    if(this.selectedDocument.pdf) {
      fileUploader.clear()
      fileUploader.files = [this.selectedDocument.pdf]
      this.documentPdfFile = this.selectedDocument.pdf
    }

    this.documentDialogVisible = true
  }

  onDeleteDocument(document: ExportOfferDocument) {
    if(document) {
      const index = this.documents.indexOf(document)
      this.documents.splice(index, 1);
    }
  }

  onSelectDocumentFile(event: any) {
    this.documentPdfFile = event.files[0]
  }

  onClearDocumentFile(event: any) {
    this.documentPdfFile = null
  }

  saveDocument() {
    this.submitDocument = true
    if(this.documentFormGroup.valid) {
      if(!this.selectedDocument) {
        let productCertificate: NewExportOfferDocument = {
          name: this.documentFormGroup.controls['name'].value,
          pdf: this.documentPdfFile
        }
        this.documents.push(productCertificate)
        this.documentDialogVisible = false
        this.submitDocument = false
      }
      else {
        this.selectedDocument.name = this.documentFormGroup.controls['name'].value
        this.selectedDocument.pdf = this.documentPdfFile
        this.documentDialogVisible = false
        this.submitDocument = false
      }
    }
  }

  get paymentBLTitle() {
    return ExportOfferUtils.getPaymentBLClosedSaleTitle(this.formGroup.controls['paymentAgainstBL'].value);
  }

  get paymentBLItems() {
    return ExportOfferUtils.getPaymentBLClosedSaleValues(this.formGroup.controls['incoterm'].value);
  }

  get showPaymentBLPercent() {
    return ExportOfferUtils.showPaymentBLClosedSalePercent(this.formGroup.controls['incoterm'].value)
  }

  get finalPaymentPercent(): number {
    if(this.formGroup.controls['paymentAgainstBL'].value)
      return 100 - this.formGroup.controls['paymentAgainstBL'].value
    return 0
  }

  filterPackaging(event: any) {
    if (event?.isOther === true) {
      this.formGroup.controls['otherPackaging'].enable()
    } else {
      this.formGroup.controls['otherPackaging'].setValue('')
      this.formGroup.controls['otherPackaging'].disable()
    }
    if (event !== undefined && event !== null) this.packagingSelected[0] = event.id
  }
}
