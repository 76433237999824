<app-admin-dashboard>
    <p-card>
        <ng-template pTemplate="header">
            <div class="flex justify-content-between flex-wrap align-items-center" style="padding: 15px;">
                <h2>Editar Custom Agent</h2>
            </div>
        </ng-template>
        <ng-template pTemplate="body">
            <div id="form" *ngIf="!success">
                <form [formGroup]="formGroup">
                    <p-fieldset legend="Información de empresa">
                        <div class="p-fluid formgrid grid">
                            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
                                <label for="companyName" style="font-weight: bold;">Nombre de Custom Agent *</label>
                                <input id="companyName" formControlName="companyName" type="text" required pInputText placeholder="Nombre de contacto"/>
                                <small *ngIf="submit && formGroup.controls['companyName'].invalid" class="p-error">{{getErrorMessage('companyName')}}</small>
                            </div>
                            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
                                <label for="country" style="font-weight: bold;">País *</label>
                                <p-dropdown id="country"
                                    formControlName="countryId"
                                    [options]="countries"
                                    optionValue="id"
                                    optionLabel="name"
                                    [filter]="true"
                                    filterBy="name"
                                    [showClear]="true"
                                    placeholder="Seleccione un país"
                                    (onChange)="onChangeCountry()">
                                    <ng-template pTemplate="selectedItem">
                                        <div class="flex align-items-center gap-2" *ngIf="selectedCountry">
                                            <img [src]="'../../../../assets/img/flags/' + selectedCountry.code + '.png'" style="width: 18px" alt=""/>
                                            <div>{{ selectedCountry | countryDescription }}</div>
                                        </div>
                                    </ng-template>
                                    <ng-template let-country pTemplate="item">
                                        <div class="flex align-items-center gap-2">
                                            <img [src]="'../../../../assets/img/flags/' + country.code + '.png'" style="width: 18px" alt=""/>
                                            <div>{{ country | countryDescription }}</div>
                                        </div>
                                    </ng-template>
                                </p-dropdown>
                                <small *ngIf="submit && formGroup.controls['countryId'].invalid" class="p-error">{{getErrorMessage('countryId')}}</small>
                            </div>
                            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
                                <label for="contactName" style="font-weight: bold;">Nombre de contacto *</label>
                                <input id="contactName" formControlName="contactName" type="text" required pInputText placeholder="Nombre de contacto"/>
                                <small *ngIf="submit && formGroup.controls['contactName'].invalid" class="p-error">{{getErrorMessage('contactName')}}</small>
                            </div>
                            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
                                <label for="contactLastName" style="font-weight: bold;">Apellido de contacto *</label>
                                <input id="contactLastName" formControlName="contactLastName" type="text" required pInputText placeholder="Apellido de contacto"/>
                                <small *ngIf="submit && formGroup.controls['contactLastName'].invalid" class="p-error">{{getErrorMessage('contactLastName')}}</small>
                            </div>
                            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
                                <label for="contactPhone" style="font-weight: bold;">Teléfono de contacto *</label>
                                <input id="contactPhone" formControlName="contactPhone" type="text" required pInputText placeholder="Teléfono de contacto"/>
                                <small *ngIf="submit && formGroup.controls['contactPhone'].invalid" class="p-error">{{getErrorMessage('contactPhone')}}</small>
                            </div>
                            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
                                <label for="contactEmail" style="font-weight: bold;">Correo electrónico *</label>
                                <input id="contactEmail" formControlName="contactEmail" type="text" required pInputText placeholder="Correo electrónico de contacto"/>
                                <small *ngIf="submit && formGroup.controls['contactEmail'].invalid" class="p-error">{{getErrorMessage('contactEmail')}}</small>
                            </div>
                        </div>
                    </p-fieldset>
                    <p-fieldset legend="Información de usuarios">
                        <div class="flex flex-wrap flex-column justify-content-center align-items-center">
                            <div class="w-full md:w-4 lg:w-4 p-fluid formgrid grid">
                                <div class="field col-12 xl:col-12 lg:col-12" style="margin-bottom: 40px;">
                                    <label for="profileId" style="font-weight: bold;">Custom Agent</label>
                                    <p-dropdown inputId="profileId" formControlName="profileId" [options]="profiles" optionLabel="fullname" placeholder="Seleccione un Custom Agent"></p-dropdown>
                                </div>
                                <div class="field col-12 md:col-12 lg:col-12" style="margin-bottom: 20px;">
                                    <div class=" w-full">
                                      <p-button class="p-button p-button-primary" (onClick)="addCustomAgent()" [loading]="loading">Agregar Custom Agent</p-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-fluid formgrid grid">
                            <div class="field col-12 xl:col-12 lg:col-12" style="margin-bottom: 40px;">
                                <p-table [value]="users" [tableStyle]="{'min-width': '50rem'}">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th>Contact Name</th>
                                            <th>Contact LastName</th>
                                            <th>Contact Email</th>
                                            <th>Contact Phone</th>
                                            <th style="text-align: center;">Custom Agent Type</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-user let-rowIndex="rowIndex">
                                        <tr>
                                            <td>{{user.profile.contactName}}</td>
                                            <td>{{user.profile.contactLastName}}</td>
                                            <td>{{user.contactEmail}}</td>
                                            <td>{{user.contactPhone}}</td>
                                            <td>
                                                <div class="flex justify-content-end align-items-center gap-4">
                                                    <span>{{user.isAdmin ? 'Administrador' : 'Usuario'}}</span>
                                                    <p-button *ngIf="!user.isAdmin" pRipple icon="pi pi-lock" (onClick)="onSelectAdmin(user)" pTooltip="Asignar Administrador"></p-button>
                                                    <p-button *ngIf="user.id === 0" pRipple icon="pi pi-times" (onClick)="removeUser(rowIndex)" styleClass="p-button-danger" pTooltip="Remover"></p-button>
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                        </div>
                    </p-fieldset>
                    <div class="grid" style="padding: 20px;">
                        <div class="col-6 col-offset-6">
                            <div class="grid p-fluid">
                                <div class="col-12 md:col-6 lg:col-6">
                                    <p-button label="Regresar" (onClick)="goToBack()" [loading]="loading" styleClass="p-button-outlined"></p-button>
                                </div>
                                <div class="col-12 md:col-6 lg:col-6">
                                    <p-button [loading]="loading" label="Guardar" (onClick)="editCompany()"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div id="success" *ngIf="success">
                <p>Se ha actualizado el Custom Agent con éxito.</p>
                <p-button label="Aceptar" (onClick)="goToBack()"></p-button>
            </div>
        </ng-template>
    </p-card>
</app-admin-dashboard>

<p-toast></p-toast>