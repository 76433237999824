import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Routes } from "src/app/infrastructure/routes";
import { InvitationCodeGateway } from "../gateway/InvitationCodeGateway";
import { InvitationCode, InvitationCodeCreate, InvitationCodeInactive, InvitationCodeResult } from "src/app/domain/models/InvitationCode";

@Injectable({providedIn: 'root'})
export class InvitationCodeService extends InvitationCodeGateway {

  constructor(private _http: HttpClient) {
    super()
  }

  create(request: InvitationCodeCreate): Observable<InvitationCodeResult> {
    return this._http.post<any>(Routes.invitationCodeApi + '/Create', request)
  }

  deactivate(invitationCode: InvitationCodeInactive): Observable<any> {
    return this._http.patch<any>(Routes.invitationCodeApi + '/Inactivate', invitationCode)
  }

  getAll(): Observable<InvitationCode[]> {
    return this._http.get<InvitationCode[]>(Routes.invitationCodeApi + '/GetAll')
  }

  requestCode(request: InvitationCodeCreate): Observable<InvitationCodeResult> {
    return this._http.post<any>(Routes.invitationCodeApi + '/SendRequest', request)
  }
}
