<app-user-dashboard [userMenuActiveItemIndex]="0" [activeItem]="'export-offers'">
  <div *ngIf="exportOffer">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="breadcrumbHomeItem"></p-breadcrumb>
    <div style="margin-top: 30px">
      <p-card>
        <ng-template pTemplate="header">
          <div class="flex justify-content-between flex-wrap align-items-center" style="padding: 15px;">
            <h2 style="color: #8c2b87;">{{'DirectSaleFormPage.AdditionalInfoTitle' | translate}}</h2>
            <p-button label="{{ 'DirectSaleFormPage.ViewOfferText' | translate }}" (onClick)="showOfferDetailDialog()"></p-button>
          </div>
        </ng-template>
        <form [formGroup]="formGroup">
          <p-progressBar [value]="100" color="#8c2b87"></p-progressBar>
          <div class="p-fluid formgrid grid mr-2 ml-2" style="margin-top: 40px;">
            <div class="field col-12 md:col-4 lg:col-4">
              <label style="font-weight: bolder;" for="grossKilos">{{'DirectSaleFormPage.GrossKilosFieldLabel' | translate}}</label>
              <input pInputText inputId="grossKilos" formControlName="grossKilos" [maxlength]="formLimit.grossKilosLimit" placeholder="{{'DirectSaleFormPage.GrossKilosFieldLabel' | translate}}"/>
            </div>
            <div class="field col-12 md:col-4 lg:col-4">
              <label style="font-weight: bolder;" for="boxType">{{'DirectSaleFormPage.BoxTypeFieldLabel' | translate}}</label>
              <p-dropdown inputId="boxType" formControlName="boxType" [options]="boxTypesOptions" optionLabel="label" optionValue="value" placeholder="{{'DirectSaleFormPage.BoxTypeFieldLabel' | translate}}"></p-dropdown>
            </div>
            <div class="field col-12 md:col-4 lg:col-4">
              <label style="font-weight: bolder;" for="caseSize" style="font-weight: bolder;">{{'DirectSaleFormPage.CaseSizeFieldLabel' | translate}}</label>
              <input pInputText inputId="caseSize" formControlName="caseSize" [maxlength]="formLimit.caseSizeLimit" placeholder="{{'DirectSaleFormPage.CaseSizeFieldLabel' | translate}}"/>
            </div>
            <div class="field col-12 md:col-4 lg:col-4">
              <label style="font-weight: bolder;" for="sizeMillimeters">{{'DirectSaleFormPage.SpeciesSizeMillimetersFieldLabel' | translate}}</label>
              <p-inputNumber inputId="sizeMillimeters" formControlName="sizeMillimeters" [min]="0" mode="decimal" [maxFractionDigits]="2"  [minFractionDigits]="2" suffix=" mm" locale="en-US" placeholder="{{'DirectSaleFormPage.SpeciesSizeMillimetersFieldLabel' | translate}}"></p-inputNumber>
            </div>
            <div class="field col-12 md:col-4 lg:col-4">
              <label style="font-weight: bolder;" for="category">{{'DirectSaleFormPage.CategoryFieldLabel' | translate}}</label>
              <input pInputText inputId="category" formControlName="category" [maxlength]="formLimit.categoryLimit" placeholder="{{'DirectSaleFormPage.CategoryFieldLabel' | translate}}"/>
            </div>
            <div class="field col-12 md:col-4 lg:col-4">
              <label style="font-weight: bolder;" for="basePallets">{{'DirectSaleFormPage.BasePalletsFieldLabel' | translate}}</label>
              <p-dropdown inputId="basePallets" formControlName="basePallets" [options]="basePalletsOptions" optionLabel="label" optionValue="value" placeholder="{{'DirectSaleFormPage.BasePalletsFieldLabel' | translate}}"></p-dropdown>
            </div>
            <div class="field col-12 md:col-4 lg:col-4">
              <label style="font-weight: bolder;" for="isQualityControl">{{'DirectSaleFormPage.IsQualityControlFieldLabel' | translate}}</label>
              <p-dropdown id="isQualityControl" formControlName="isQualityControl" [options]="qualityControlOptions" optionLabel="label" optionValue="value" placeholder="{{'DirectSaleFormPage.IsQualityControlFieldLabel' | translate}}" (onChange)="showUploadQualityDoc()"></p-dropdown>
              <p-button label="{{'UserPages.UserExportOffers.EditExportOffer.UploadQualityDocument' | translate}}" *ngIf="buttonQualityDoc" class="mr-3 p-button-raised" [style]="{'margin-top': '5px'}" (click)="visibleUploadQualityDoc = true" icon="pi pi-upload"></p-button>
            </div>
            <div class="field col-12 md:col-4 lg:col-4">
              <label style="font-weight: bolder;" for="qualityControlDate">{{'DirectSaleFormPage.QualityControlDateFieldLabel' | translate}}</label>
              <p-calendar
                inputId="qualityControlDate"
                formControlName="qualityControlDate"
                appendTo="body"
                [showButtonBar]="true"
                [showIcon]="true"
                placeholder="{{'DirectSaleFormPage.QualityControlDateFieldLabel' | translate}}"
                [showWeek]="true"
                [firstDayOfWeek]="1"></p-calendar>
            </div>
            <div class="field col-12 md:col-4 lg:col-4">
              <label style="font-weight: bolder;" for="marks">{{'DirectSaleFormPage.HasMarksFieldLabel' | translate}}</label>
              <p-dropdown id="marks" formControlName="marks" [options]="marksOptions" optionLabel="label" optionValue="value" placeholder="{{'DirectSaleFormPage.HasMarksFieldLabel' | translate}}"></p-dropdown>
            </div>
          </div>
          <div class="grid mr-2 ml-2" style="margin-top: 30px;">
            <div class="col-6"></div>
            <div class="col-6">
              <div class="p-fluid formgrid grid">
                <div class="col-6"></div>
                <div class="col-6">
                  <p-button label="{{'DirectSaleFormPage.SaveButtonLabel' | translate}}" (onClick)="save()" [loading]="loading"></p-button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </p-card>
    </div>
  </div>
</app-user-dashboard>

<p-toast></p-toast>

<p-dialog header="{{'UserPages.UserClosedSale.DialogHeader' | translate}}" [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleOfferDetailDialog" [style]="{width: '80%'}">
  <app-export-offer-detail [offer]="selectedExportOffer" [productCertificateTypes]="selectedProductCertificateTypes" [packagingTypes]="selectedPackagingTypes"></app-export-offer-detail>
  <ng-template pTemplate="footer">
    <p-button label="{{'UserPages.UserClosedSale.ModalCloseButton' | translate}}" (onClick)="this.visibleOfferDetailDialog = false"></p-button>
  </ng-template>
</p-dialog>

<p-dialog [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleUploadQualityDoc" [style]="{width: '80%'}">
  <ng-template pTemplate="header">
    <h3>{{'UserPages.UserExportOffers.EditExportOffer.UploadQualityDocument' | translate}}</h3>
  </ng-template>
  <p-fileUpload name="sheetPdf[]"
      #docUploader
      (onSelect)="onSelect($event, docUploader)"
      (onRemove)="onRemove($event, docUploader)"
      (onClear)="onClear($event, docUploader)"
      [showUploadButton]="false"
      [multiple]="false"
      accept=".pdf"
      [fileLimit]="1"
      [files]="qualityDocument"
      [maxFileSize]="3000000"
      chooseLabel="{{'UserPages.UserExportOffers.EditExportOffer.SelectImageButton' | translate}}">
      <ng-template pTemplate="toolbar">
        <p-button *ngIf="this.exportOffer?.documents?.length" label="{{'UserPages.UserExportOffers.EditExportOffer.ViewQualityDocument' | translate}}" pTooltip="{{'UserPages.UserExportOffers.EditExportOffer.ViewQualityDocument' | translate}}" icon="pi pi-file-pdf" styleClass="p-button-outlined" (onClick)="getExportOfferQualityDocument(this.exportOffer?.documents)"></p-button>
      </ng-template>
      <ng-template pTemplate="content">
      </ng-template>
  </p-fileUpload>
  <ng-template pTemplate="footer">
      <p-button label="{{ 'UserPages.UserExportOffers.NewExportOffer.SaveButtonLabel' | translate }}" (onClick)="visibleUploadQualityDoc = false" [loading]="loading"></p-button>
  </ng-template>
</p-dialog>