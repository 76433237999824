import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CreateProfileSpecies, Exporter, ExporterImporterCreate, ExporterImportersDto, Importer, Profile, ProfileContactInfo, ProfileCreateRequest, TwoFactorCode } from "src/app/domain/models/Profile";
import { Routes } from "src/app/infrastructure/routes";
import { ProfileGateway } from "../gateway/ProfileGateway";
import { environment } from '../../../environments/environment'
import { Species } from "src/app/domain/models/Species";
import { BasicUser } from "src/app/domain/models/User";

@Injectable({providedIn: 'root'})
export class ProfileService extends ProfileGateway {

  constructor(private _http: HttpClient) {
    super();
  }

  create(request: ProfileCreateRequest): Observable<Profile> {
    return this._http.post<Profile>(Routes.ProfileApi + '/Create', request)
  }

  getByUsername(): Observable<Profile> {
    return this._http.get<Profile>(Routes.ProfileApi + '/GetByUsername')
  }

  getById(profileId: number): Observable<Profile> {
    return this._http.get<Profile>(Routes.ProfileApi + '/GetById', { params: {profileId}})
  }

  getContactInfo(): Observable<ProfileContactInfo> {
    return this._http.get<Profile>(Routes.ProfileApi + '/GetContactInfo')
  }

  update(profile: Profile): Observable<Profile> {
    return this._http.put<Profile>(Routes.ProfileApi + '/Update', this.getFormData(profile))
  }

  updateContactInfo(request: ProfileContactInfo): Observable<any> {
    return this._http.put<Profile>(Routes.ProfileApi + '/UpdateContactInfo', request)
  }

  updateSpecies(createProfileSpecies: CreateProfileSpecies): Observable<Species[]> {
    return this._http.post<Species[]>(Routes.ProfileApi + '/AddSpecies', createProfileSpecies)
  }

  deactivate(): Observable<any> {
    return this._http.delete(Routes.ProfileApi + '/Deactivate')
  }

  getProfileType(): Observable<any> {
    return this._http.get<any>(Routes.ProfileApi + '/GetProfileType')
  }

  getAll(): Observable<Profile[]> {
    return this._http.get<Profile[]>(Routes.ProfileApi + '/GetAll')
  }

  toogleIsActivate(profile: Profile): Observable<Profile> {
    return this._http.put<Profile>(Routes.ProfileApi + '/ToogleIsActivate', profile)
  }

  getAllImportersByExporterId(): Observable<Importer[]> {
    return this._http.get<Importer[]>(Routes.ProfileApi + '/GetAllImportersByExporterId')
  }

  getAllExportersByImporterId(): Observable<Exporter[]>{
    return this._http.get<Exporter[]>(Routes.ProfileApi + '/GetAllExportersByImporterId')
  }

  toggleTwoAuth(twoFactorCode: TwoFactorCode): Observable<any> {
    return this._http.post<Profile>(Routes.ProfileApi + '/ActivateTwoStepsAuth', twoFactorCode)
  }

  resendCode(username: string, password: string){
    return this._http.post<any>(Routes.ProfileApi + '/ResendAuthCode', {
      "username": username,
      "password": password
    });
  }

  getAllImportersForExporterAsociation(exporterId: number): Observable<ExporterImportersDto> {
    return this._http.get<ExporterImportersDto>(Routes.ProfileApi + '/GetAllImportersForExporterAsociation', {params: {exporterId}})
  }

  toogleExporterImporterAsociation(request: ExporterImporterCreate): Observable<any> {
    return this._http.patch<any>(Routes.ProfileApi + '/ToogleExporterImporterAsociation', request)
  }

  changeLanguaje(languaje: string): Observable<any> {
    return this._http.patch<any>(Routes.ProfileApi + '/ChangeLanguaje', {}, {params: {languaje}})
  }

  getFormData(profile: Profile) {
    const formData = new FormData();
    formData.append("updaterUsername", profile.updaterUsername);
    formData.append("id", profile.id.toString());
    formData.append("companyName", profile.companyName);
    formData.append("vat", profile.vat ? profile.vat.toString() : '');
    formData.append("contactName", profile.contactName);
    formData.append("contactLastName", profile.contactLastName ? profile.contactLastName : '');
    formData.append("contactEmail", profile.contactEmail);
    formData.append("contactPhone", profile.contactPhone);
    formData.append("contactAddress", profile.contactAddress);
    formData.append("contactCountry", JSON.stringify(profile.contactCountry));
    formData.append("contactCountryId", profile.contactCountryId ? profile.contactCountryId.toString() : '');
    formData.append("contactCity", profile.contactCity);
    formData.append("legalRepresentativeName", profile.legalRepresentativeName ? profile.legalRepresentativeName : '');
    formData.append("legalRepresentativeNumber", profile.legalRepresentativeNumber ? profile.legalRepresentativeNumber : '');
    formData.append("sagCode", profile.sagCode ? profile.sagCode : '');
    formData.append("pdf", profile.pdf ? profile.pdf : '');
    formData.append("newExportOffers", profile.newExportOffers ? profile.newExportOffers.toString() : 'false')
    formData.append("newImportRequirements", profile.newImportRequirements ? profile.newImportRequirements.toString() : 'false')
    formData.append("hasTwoAuthetication", profile.hasTwoAuthetication ? profile.hasTwoAuthetication.toString() : 'false')
    formData.append("isVerified", profile.isVerified ? profile.isVerified.toString() : 'false')
    formData.append("lang", profile.lang ? profile.lang : 'en')
    return formData
  }

  validateSII(rut: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${environment.apiSIIToken}`
    })
    const url = 'https://apigateway.cl/api/v1/sii/contribuyentes/situacion_tributaria/tercero/' + encodeURIComponent(rut) + '?formato=json'
    return this._http.get<any>(url, {headers: headers})
  }

  getBasicUser(): Observable<BasicUser> {
    return this._http.get<BasicUser>(Routes.ProfileApi + '/GetBasicUser')
  }

  sendValidationToken(profileId: number): Observable<any> {
    return this._http.post<any>(Routes.ProfileApi + '/SendValidationToken', {profileId})
  }
}
