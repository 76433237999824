import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { InvitationCode, InvitationCodeCreate, InvitationCodeInactive, InvitationCodeResult } from "src/app/domain/models/InvitationCode";

@Injectable({providedIn: 'root'})
export abstract class InvitationCodeGateway {
    abstract create(request: InvitationCodeCreate): Observable<InvitationCodeResult>
    abstract deactivate(invittionCode: InvitationCodeInactive): Observable<any>
    abstract getAll(): Observable<InvitationCode[]>
    abstract requestCode(request: InvitationCodeCreate): Observable<InvitationCodeResult>
}
