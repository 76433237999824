import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { EstimationGateway } from 'src/app/application/gateway/EstimationGateway';
import { FrozenFruitExportOfferGateway } from 'src/app/application/gateway/FrozenFruitExportOfferGateway';
import { PortGateway } from 'src/app/application/gateway/PortGateway';
import { Country } from 'src/app/domain/models/Country';
import { FrozenFruitExportOffer, FrozenFruitExportOfferListItem } from 'src/app/domain/models/FrozenFruitExportOffer';
import { FrozenFruitImportOrderCreateRequest } from 'src/app/domain/models/ImportOrder';
import { Port } from 'src/app/domain/models/Port';
import { Estimation } from 'src/app/domain/models/Route';
import { Species } from 'src/app/domain/models/Species';
import { BasicUser } from 'src/app/domain/models/User';
import { Variety } from 'src/app/domain/models/Variety';
import { Routes } from 'src/app/infrastructure/routes';

interface SpeciesOption {
  id: number
  name: string
}

interface VarietyOption {
  id: number
  name: string
}

@Component({
  selector: 'app-frozen-fruit-export-offer-marketplace',
  templateUrl: './frozen-fruit-export-offer-marketplace.component.html',
  styleUrls: ['./frozen-fruit-export-offer-marketplace.component.css'],
  providers: [MessageService]
})
export class FrozenFruitExportOfferMarketplaceComponent implements OnInit {

  profile: BasicUser | undefined

  @Input()
  originCountries: Country[] = []

  @Input()
  destinationCountries: Country[] = []

  destinationPorts: Port[] = []
  estimations: Estimation[] = []

  selected: FrozenFruitExportOffer | undefined
  selectedCountryFrom: Country | undefined
  selectedCountryTo: Country | undefined

  exportOffers: FrozenFruitExportOfferListItem[] = []

  loading: boolean = false
  visibleDetailDialog: boolean = false

  viewTypeGrid = "grid"
  viewTypeCards = "cards"
  viewType: string = this.viewTypeGrid

  viewTypeOptions: any[] = []

  filterSpecies: SpeciesOption[] = []
  filterVarieties: VarietyOption[] = []

  portFormGroup: FormGroup = this._formBuilder.group({
    countryFromId: ['', Validators.required],
    portToId: ['', Validators.required],
    countryToId: ['', Validators.required],
  })

  constructor(private _router: Router,
    private _formBuilder: FormBuilder,
    private _messageService: MessageService,
    public translate: TranslateService,
    private _portService: PortGateway,
    private _estimationService: EstimationGateway,
    private _authService: AuthGateway,
    private _frozenFruitExportOfferService: FrozenFruitExportOfferGateway) {

  }

  ngOnInit(): void {
    this.profile = this._authService.getUser()

    let viewTypeOptionsLabel1 = 'Grid'
    let viewTypeOptionsLabel2 = 'Classic Marketplace'

    this.viewTypeOptions = [
      {
        label: viewTypeOptionsLabel1,
        value: this.viewTypeGrid
      },
      {
        label: viewTypeOptionsLabel2,
        value: this.viewTypeCards
      }
    ]
  }

  loadExportOffers() {
    this.selectedCountryFrom = this.originCountries.find(x => x.id === this.portFormGroup.controls['countryFromId'].value)
    this._frozenFruitExportOfferService.getAllForMarketplace(this.portFormGroup.controls['countryFromId'].value).subscribe({
      next: (v) => {
        console.log(v)
        this.exportOffers = v
        this.loadEstimations()
        this.loadFilterSpecies()
        this.loadFilterVarieties()
        this.loading = false
        localStorage.setItem("_frozen_fruit_marketplace_country_from_param", this.portFormGroup.controls['countryFromId'].value)
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.ExportOffer.ManyRetrieveError') })
      },
      complete: () => {
        console.info('Marketplace query successfully')
      }
    })
  }

  clearSpotSearchParams() {

  }

  loadDestinationPorts() {
    this.selectedCountryTo = this.destinationCountries.find(x => x.id === this.portFormGroup.controls['countryToId'].value)

    if(this.portFormGroup.controls['countryToId'].value) {
      this.loading = true
      this._portService.getDestinationPortsByCountry(this.portFormGroup.controls['countryToId'].value).subscribe({
        next: (v) => {
          this.destinationPorts = v
          this.loading = false
          localStorage.setItem("_frozen_fruit_marketplace_country_to_param", this.portFormGroup.controls['countryToId'].value)
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.Port.DestinationPortManyRetrieveError') })
        },
        complete: () => console.info('Destination Ports query successfully')
      })
    }
  }

  loadEstimations() {
    if(this.portFormGroup.controls['countryFromId'].value && this.portFormGroup.controls['portToId'].value) {
      this._estimationService.getAllByFilter(this.portFormGroup.controls['countryFromId'].value, this.portFormGroup.controls['portToId'].value).subscribe({
        next: (v) => {
          this.estimations = v
          localStorage.setItem("_frozen_fruit_marketplace_port_to_param", this.portFormGroup.controls['portToId'].value)
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.Estimation.ManyRetrieveError') })
        },
        complete: () => console.info('Destination Ports query successfully')
      })
    }
  }

  filterGlobal(event: any, dataTable: any) {
    dataTable.filterGlobal(event.target.value, 'contains')
  }

  loadFilterSpecies() {
    this.filterSpecies = []
    if(this.exportOffers) {
      this.exportOffers.forEach(o => {
        const speciesOption = this.filterSpecies.find(x => x.id === o.speciesId)
        if(!speciesOption)
          this.filterSpecies.push({
            id: o.speciesId,
            name: o.speciesName
          })
      })
    }
  }

  loadFilterVarieties(){
    this.filterVarieties= []
    if(this.exportOffers) {
      this.exportOffers.forEach(o => {
        const varietyOption = this.filterSpecies.find(x => x.id === o.varietyId)
        if(!varietyOption)
          this.filterVarieties.push({
            id: o.varietyId,
            name: o.varietyName
          })
      })
    }
  }

  createOrderFromList() {

  }

  countryFromId: number = 0
  countryToId: number = 0
  portToId: number = 0
  showDetailModal(exportOfferId: number) {
    this.loading = true
    this._frozenFruitExportOfferService.getById(exportOfferId).subscribe({
      next: (v) => {
        this.selected = v
        this.countryFromId = this.portFormGroup.controls['countryFromId'].value
        this.countryToId = this.portFormGroup.controls['countryToId'].value
        this.portToId = this.portFormGroup.controls['portToId'].value
        this.loading = false
        this.visibleDetailDialog = true
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.ExportOffer.SingleRetrieveError') })
      },
      complete: () => console.info('Frozen fruit export offer query successfully')
    })
  }

  getTotalCases(exportOffer: FrozenFruitExportOfferListItem): number {
    return exportOffer.boxesPerPallet > 0 ? exportOffer.containersQuantity * 20 * exportOffer.boxesPerPallet : exportOffer.containersQuantity * exportOffer.boxesQuantity
  }

  getTotalPrice(exportOffer: FrozenFruitExportOfferListItem): number {
    return this.getTotalCases(exportOffer) * exportOffer.price
  }

  goToShoppingCart() {
    this._router.navigate([Routes.userFrozenFruitPurchaseOrdersPage])
  }

  addShoppingCart(success: boolean) {
    if(success) {
      this.loadExportOffers()
      this._messageService.add({ severity: 'success',
        summary: this.translate.instant('MessageService.Summary.SuccessAction'),
        detail: this.translate.instant('MessageService.ImportOrder.CreateSuccess') })
      this.visibleDetailDialog = false
    }
  }
}
