<app-user-dashboard [userMenuActiveItemIndex]="0" [activeItem]="'forwarderReviews'">
    <p-tabMenu [model]="tabMenuItems" [scrollable]="true" [activeItem]="activeItem" [style]="{padding: '15px'}"></p-tabMenu>
    <p-card>
        <ng-template pTemplate="header">
          <div class="flex justify-content-between flex-wrap align-items-center" style="padding-left: 15px;">
            <h2>{{ 'ForwarderPage.ForwarderDelivery.DeliveryInformation' | translate }}</h2>
          </div>
        </ng-template>
        <ng-template pTemplate="body">
            <div *ngIf="deliveryInformation">
              <form [formGroup]="formGroup">
                <p-fieldset legend="{{ 'ForwarderPage.ForwarderDelivery.PurchaseOrderInformation' | translate }}" [toggleable]="true" [collapsed]="true">
                  <h2>{{ 'ForwarderPage.ForwarderDelivery.ImporterInformation' | translate }}</h2>
                  <div class="p-fluid formgrid grid" *ngIf="importer">
                    <div class="field col-12 md:col-4 lg:col-3">
                      <label>{{ 'ForwarderPage.ForwarderDelivery.ImporterContactName' | translate }}</label>
                      <input [ngModel]="importer.contactName" [ngModelOptions]="{standalone: true}" type="text" pInputText readonly/>
                    </div>
                    <div class="field col-12 md:col-4 lg:col-3">
                      <label>{{ 'ForwarderPage.ForwarderDelivery.ImporterCompanyName' | translate }}</label>
                      <input [ngModel]="importer.companyName" [ngModelOptions]="{standalone: true}" type="text" pInputText readonly/>
                    </div>
                    <div class="field col-12 md:col-4 lg:col-3">
                      <label>{{ 'ForwarderPage.ForwarderDelivery.ImporterVAT' | translate }}</label>
                      <input [ngModel]="importer.vat" [ngModelOptions]="{standalone: true}" type="text" pInputText readonly/>
                    </div>
                    <div class="field col-12 md:col-4 lg:col-3">
                      <label>{{ 'ForwarderPage.ForwarderDelivery.ImporterLegalRepresentant' | translate }}</label>
                      <input [ngModel]="importer.legalRepresentativeName" [ngModelOptions]="{standalone: true}" type="text" pInputText readonly/>
                    </div>
                    <div class="field col-12 md:col-4 lg:col-3">
                      <label>{{ 'ForwarderPage.ForwarderDelivery.ImporterIDLegalRepresentant' | translate }}</label>
                      <input [ngModel]="importer.legalRepresentativeNumber" [ngModelOptions]="{standalone: true}" type="text" pInputText readonly/>
                    </div>
                    <div class="field col-12 md:col-4 lg:col-3">
                      <label>{{ 'ForwarderPage.ForwarderDelivery.ImporterContactEmail' | translate }}</label>
                      <input [ngModel]="importer.contactEmail" [ngModelOptions]="{standalone: true}" type="text" pInputText readonly/>
                    </div>
                    <div class="field col-12 md:col-4 lg:col-3">
                      <label>{{ 'ForwarderPage.ForwarderDelivery.ImporterContactPhone' | translate }}</label>
                      <input [ngModel]="importer.contactPhone" [ngModelOptions]="{standalone: true}" type="text" pInputText readonly/>
                    </div>
                    <div class="field col-12 md:col-4 lg:col-3">
                      <label>{{ 'ForwarderPage.ForwarderDelivery.ImporterContactAddress' | translate }}</label>
                      <input [ngModel]="importer.contactAddress" [ngModelOptions]="{standalone: true}" type="text" pInputText readonly/>
                    </div>
                    <div class="field col-12 md:col-4 lg:col-3">
                      <label>{{ 'ForwarderPage.ForwarderDelivery.ImporterContactCity' | translate }}</label>
                      <input [ngModel]="importer.contactCity" [ngModelOptions]="{standalone: true}" type="text" pInputText readonly/>
                    </div>
                    <div class="field col-12 md:col-4 lg:col-3">
                      <label>{{ 'ForwarderPage.ForwarderDelivery.ImporterContactCountry' | translate }}</label>
                      <input [ngModel]="importer.country" [ngModelOptions]="{standalone: true}" type="text" pInputText readonly/>
                    </div>
                  </div>
                  <p-divider></p-divider>
                  <h2>{{ 'ForwarderPage.ForwarderDelivery.PurchaseDetail' | translate }}</h2>
                  <div class="p-fluid formgrid grid">
                    <div class="field col-12 md:col-4 lg:col-3">
                      <label>{{ 'ForwarderPage.ForwarderDelivery.CreationDate' | translate }}</label>
                      <input [ngModel]="deliveryInformation.importOrderCreated | date: 'dd-MM-yyyy'" [ngModelOptions]="{standalone: true}" type="text" pInputText readonly/>
                    </div>
                  </div>
                  <p-table [value]="orderDetails"
                    [paginator]="true"
                    [rows]="10"
                    [showCurrentPageReport]="true"
                    [rowsPerPageOptions]="[10, 25, 50]"
                    [loading]="loading"
                    responsiveLayout="stack" [breakpoint]="'960px'" [tableStyle]="{'min-width': '100%'}">
                    <ng-template pTemplate="header">
                      <tr>
                        <th>
                          <div class="flex justify-content-center align-items-center">
                            {{ 'ForwarderPage.ForwarderDelivery.OrderDetailTable.SpeciesVarietyHeader' | translate }}
                          </div>
                        </th>
                        <th>
                          <div class="flex justify-content-center align-items-center">
                            {{ 'ForwarderPage.ForwarderDelivery.OrderDetailTable.ExporterHeader' | translate }}
                          </div>
                        </th>
                        <th>
                          <div class="flex justify-content-center align-items-center">
                            {{ 'ForwarderPage.ForwarderDelivery.OrderDetailTable.UnitPriceHeader' | translate }}
                          </div>
                        </th>
                        <th>
                          <div class="flex justify-content-center align-items-center">
                            {{ 'ForwarderPage.ForwarderDelivery.OrderDetailTable.BoxPerPalletsHeader' | translate }}
                          </div>
                        </th>
                        <th>
                          <div class="flex justify-content-center align-items-center">
                            {{ 'ForwarderPage.ForwarderDelivery.OrderDetailTable.PalletsHeader' | translate }}
                          </div>
                        </th>
                        <th>
                          <div class="flex justify-content-center align-items-center">
                            {{ 'ForwarderPage.ForwarderDelivery.OrderDetailTable.BoxTotalHeader' | translate }}
                          </div>
                        </th>
                        <th>
                          <div *ngIf="isCloseSale" class="flex justify-content-center align-items-center">
                            {{ 'ForwarderPage.ForwarderDelivery.OrderDetailTable.SchedulePriceHeader' | translate }}
                          </div>
                          <div *ngIf="!isCloseSale" class="flex justify-content-center align-items-center">
                            {{ 'ForwarderPage.ForwarderDelivery.OrderDetailTable.TotalPriceHeader' | translate }}
                          </div>
                        </th>
                        <th *ngIf="isCloseSale">
                          <div class="flex justify-content-center align-items-center">
                            {{ 'ForwarderPage.ForwarderDelivery.OrderDetailTable.FruitCostHeader' | translate }}
                          </div>
                        </th>
                        <th></th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-orderDetail>
                      <tr>
                        <td>
                          <div class="flex justify-content-center align-items-center">
                            {{orderDetail.speciesNameEs}} / {{orderDetail.varietyName}}
                          </div>
                        </td>
                        <td>
                          <div class="flex justify-content-center align-items-center">
                            {{orderDetail.exporterCompanyName}}
                          </div>
                        </td>
                        <td>
                          <div class="flex justify-content-center align-items-center">
                            {{orderDetail.price | currency: 'USD':'US$'}}
                          </div>
                        </td>
                        <td>
                          <div class="flex justify-content-center align-items-center">
                            {{orderDetail.boxesPerPallet}}
                          </div>
                        </td>
                        <td>
                          <div class="flex justify-content-center align-items-center">
                            {{orderDetail.palletsQuantity}}
                          </div>
                        </td>
                        <td>
                          <div class="flex justify-content-center align-items-center">
                            {{getDetailTotalCases(orderDetail)}}
                          </div>
                        </td>
                        <td>
                          <div class="flex justify-content-center align-items-center">
                            {{ getDetailTotalPrice(orderDetail) | currency:'USD':'US$' }}
                          </div>
                        </td>
                        <td *ngIf="isCloseSale">
                          <div class="flex justify-content-center align-items-center">
                            {{ getDetailPalletPrice(orderDetail) | currency:'USD':'US$' }}
                          </div>
                        </td>
                        <td>
                          <div class="flex justify-content-center align-items-center">
                            <p-button label="{{ 'ForwarderPage.ForwarderDelivery.OrderDetailTable.ViewOffer' | translate }}" (onClick)="openExportOfferDialog(orderDetail)"></p-button>
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                  <p-divider></p-divider>
                  <h2>{{ 'ForwarderPage.ForwarderDelivery.Consignee.Title' | translate }}</h2>
                  <div class="p-fluid formgrid grid">
                    <div class="field col-12 md:col-4 lg:col-3">
                      <label for="consigneeCompanyName">{{ 'ForwarderPage.ForwarderDelivery.Consignee.CompanyName' | translate }}</label>
                      <input id="consigneeCompanyName" formControlName="consigneeCompanyName" type="text" pInputText pInputText maxlength="150"/>
                    </div>
                    <div class="field col-12 md:col-4 lg:col-3">
                      <label for="consigneeContactName">{{ 'ForwarderPage.ForwarderDelivery.Consignee.ContactName' | translate }}</label>
                      <input id="consigneeContactName" formControlName="consigneeContactName" type="text" pInputText pInputText maxlength="150"/>
                    </div>
                    <div class="field col-12 md:col-4 lg:col-3">
                      <label for="consigneeVAT">{{ 'ForwarderPage.ForwarderDelivery.Consignee.VAT' | translate }}</label>
                      <input id="consigneeVAT" formControlName="consigneeVAT" type="text" pInputText/>
                    </div>
                    <div class="field col-12 md:col-4 lg:col-3">
                      <label for="consigneeContactAddress">{{ 'ForwarderPage.ForwarderDelivery.Consignee.ContactAddress' | translate }}</label>
                      <input id="consigneeContactAddress" formControlName="consigneeContactAddress" type="text" pInputText pInputText maxlength="150"/>
                    </div>
                    <div class="field col-12 md:col-4 lg:col-3">
                      <label for="consigneeContactCity">{{ 'ForwarderPage.ForwarderDelivery.Consignee.ContactCity' | translate }}</label>
                      <input id="consigneeContactCity" formControlName="consigneeContactCity" type="text" pInputText/>
                    </div>
                    <div class="field col-12 md:col-4 lg:col-3">
                      <label for="consigneeContactCountryId">{{ 'ForwarderPage.ForwarderDelivery.Consignee.ContactCountry' | translate }}</label>
                      <p-dropdown id="consigneeContactCountryId" formControlName="consigneeContactCountryId" [options]="countries" optionLabel="name" optionValue="id" placeholder="{{ 'ForwarderPage.ForwarderDelivery.Notify.SelectCountry' | translate }}"></p-dropdown>
                    </div>
                    <div class="field col-12 md:col-4 lg:col-3">
                      <label for="consigneeContactEmail">{{ 'ForwarderPage.ForwarderDelivery.Consignee.ContactEmail' | translate }}</label>
                      <input id="consigneeContactEmail" formControlName="consigneeContactEmail" type="text" pInputText pInputText maxlength="150"/>
                    </div>
                    <div class="field col-12 md:col-4 lg:col-3">
                      <label for="consigneeContactPhone">{{ 'ForwarderPage.ForwarderDelivery.Consignee.ContactPhone' | translate }}</label>
                      <input id="consigneeContactPhone" formControlName="consigneeContactPhone" type="text" pInputText/>
                    </div>
                  </div>
                  <p-divider></p-divider>
                  <h2>{{ 'ForwarderPage.ForwarderDelivery.Notify.Title' | translate }}</h2>
                  <div class="p-fluid formgrid grid">
                    <div class="field col-12 md:col-4 lg:col-3">
                      <label for="notifierCompanyName">{{ 'ForwarderPage.ForwarderDelivery.Notify.CompanyName' | translate }}</label>
                      <input id="notifierCompanyName" formControlName="notifierCompanyName" type="text" pInputText maxlength="150"/>
                    </div>
                    <div class="field col-12 md:col-4 lg:col-3">
                      <label for="notifierContactName">{{ 'ForwarderPage.ForwarderDelivery.Notify.ContactName' | translate }}</label>
                      <input id="notifierContactName" formControlName="notifierContactName" type="text" pInputText pInputText maxlength="150"/>
                    </div>
                    <div class="field col-12 md:col-4 lg:col-3">
                      <label for="notifierVAT">{{ 'ForwarderPage.ForwarderDelivery.Notify.VAT' | translate }}</label>
                      <input id="notifierVAT" formControlName="notifierVAT" type="text" pInputText/>
                    </div>
                    <div class="field col-12 md:col-4 lg:col-3">
                      <label for="notifierContactAddress">{{ 'ForwarderPage.ForwarderDelivery.Notify.ContactAddress' | translate }}</label>
                      <input id="notifierContactAddress" formControlName="notifierContactAddress" type="text" pInputText pInputText maxlength="150"/>
                    </div>
                    <div class="field col-12 md:col-4 lg:col-3">
                      <label for="notifierContactCity">{{ 'ForwarderPage.ForwarderDelivery.Notify.ContactCity' | translate }}</label>
                      <input id="notifierContactCity" formControlName="notifierContactCity" type="text" pInputText/>
                    </div>
                    <div class="field col-12 md:col-4 lg:col-3">
                      <label for="notifierContactCountryId">{{ 'ForwarderPage.ForwarderDelivery.Notify.ContactCountry' | translate }}</label>
                      <p-dropdown id="notifierContactCountryId" formControlName="notifierContactCountryId" [options]="countries" optionLabel="name" optionValue="id" placeholder="{{ 'ForwarderPage.ForwarderDelivery.Notify.SelectCountry' | translate }}"></p-dropdown>
                    </div>
                    <div class="field col-12 md:col-4 lg:col-3">
                      <label for="notifierContactEmail">{{ 'ForwarderPage.ForwarderDelivery.Notify.ContactEmail' | translate }}</label>
                      <input id="notifierContactEmail" formControlName="notifierContactEmail" type="text" pInputText pInputText maxlength="150"/>
                    </div>
                    <div class="field col-12 md:col-4 lg:col-3">
                      <label for="notifierContactPhone">{{ 'ForwarderPage.ForwarderDelivery.Notify.ContactPhone' | translate }}</label>
                      <input id="notifierContactPhone" formControlName="notifierContactPhone" type="text" pInputText/>
                    </div>
                  </div>
                </p-fieldset>
      
                <h2>{{ 'ForwarderPage.ForwarderDelivery.Delivery.Title' | translate }}</h2>
      
                <div class="p-fluid formgrid grid">
                  <div class="field col-12 md:col-4 lg:col-3">
                    <label for="mbl">{{ 'ForwarderPage.ForwarderDelivery.Delivery.NumberMBL' | translate }}</label>
                    <input id="mbl" formControlName="mbl" type="text" pInputText/>
                  </div>
                  <div class="field col-12 md:col-4 lg:col-3">
                    <label for="bl">{{ 'ForwarderPage.ForwarderDelivery.Delivery.NumberBL' | translate }}</label>
                    <input id="bl" formControlName="bl" type="text" pInputText/>
                  </div>
                  <div class="field col-12 md:col-4 lg:col-3">
                    <label for="booking">{{ 'ForwarderPage.ForwarderDelivery.Delivery.BookingBL' | translate }}</label>
                    <input id="booking" formControlName="booking" type="text" pInputText/>
                  </div>
                  <div class="field col-12 md:col-4 lg:col-3">
                    <label for="containerNumber">{{ 'ForwarderPage.ForwarderDelivery.Delivery.ContainerBL' | translate }}</label>
                    <input id="containerNumber" formControlName="containerNumber" type="text" pInputText/>
                  </div>
                  <div class="field col-12 md:col-4 lg:col-3">
                    <label for="deliveryInstructions">{{ 'ForwarderPage.ForwarderDelivery.Delivery.InstructionsBL' | translate }}</label>
                    <input id="deliveryInstructions" formControlName="deliveryInstructions" type="text" pInputText/>
                  </div>
                  <div class="col-12">
                    <p-divider></p-divider>
                  </div>
                  <div class="field col-12 md:col-4 lg:col-3">
                    <label for="grossWeight">{{ 'ForwarderPage.ForwarderDelivery.Delivery.GrossWeight' | translate }}</label>
                    <input id="grossWeight" formControlName="grossWeight" type="text" pInputText/>
                  </div>
                  <div class="field col-12 md:col-4 lg:col-3">
                    <label for="containerCbm">{{ 'ForwarderPage.ForwarderDelivery.Delivery.CBM' | translate }}</label>
                    <p-inputNumber id="containerCbm" 
                      formControlName="containerCbm" 
                      [min]="0" 
                      [max]="100" 
                      [useGrouping]="false" 
                      [maxlength]="7"
                      [maxFractionDigits]="2"
                      locale="en-US"
                      mode="decimal"  
                      [suffix]="'%'"
                      />
                  </div>
                  <div class="field col-12 md:col-4 lg:col-3">
                    <label for="containerTemperature">{{ 'ForwarderPage.ForwarderDelivery.Delivery.Temperature' | translate }}</label>
                    <p-inputNumber id="containerTemperature" 
                      formControlName="containerTemperature" 
                      [useGrouping]="false"
                      [maxFractionDigits]="2"
                      locale="en-US" 
                      [maxlength]="5"  
                      placeholder="°C" 
                      mode="decimal"/>
                  </div>
                  <div class="col-12">
                    <p-divider></p-divider>
                  </div>
                  <div class="field col-12 md:col-4 lg:col-3">
                    <label for="placeReceipt">{{ 'ForwarderPage.ForwarderDelivery.Delivery.PlaceReceipt' | translate }}</label>
                    <input id="placeReceipt" formControlName="placeReceipt" type="text" pInputText/>
                  </div>
                  <div class="field col-12 md:col-4 lg:col-3" *ngIf="delivery?.transportType == 0">
                    <label for="departurePortId" style="font-weight: bold;">{{ 'ForwarderPage.ForwarderDelivery.Delivery.OriginPort' | translate }} *</label>
                    <p-dropdown id="departurePortId" formControlName="departurePortId" [options]="ports | portName" optionLabel="fullname" optionValue="id" placeholder="{{ 'ForwarderPage.ForwarderDelivery.Delivery.PortSelect' | translate }}"></p-dropdown>
                    <small *ngIf="submit && formGroup.controls['departurePortId'].invalid" class="p-error">{{getErrorMessage(formGroup, 'departurePortId')}}</small>
                  </div>
                  <div class="field col-12 md:col-4 lg:col-3" *ngIf="delivery?.transportType == 0">
                    <label for="destinationPortId">{{ 'ForwarderPage.ForwarderDelivery.Delivery.DestinationPort' | translate }}</label>
                    <p-dropdown id="destinationPortId" formControlName="destinationPortId" [options]="ports | portName" optionLabel="fullname" optionValue="id" placeholder="{{ 'ForwarderPage.ForwarderDelivery.Delivery.PortSelect' | translate }}"></p-dropdown>
                    <small *ngIf="submit && formGroup.controls['destinationPortId'].invalid" class="p-error">{{getErrorMessage(formGroup, 'destinationPortId')}}</small>
                  </div>
                  
                  <div class="field col-12 md:col-4 lg:col-3" *ngIf="delivery?.transportType == 1" >
                    <label for="departureAirportId" style="font-weight: bold;">{{ 'ForwarderPage.ForwarderDelivery.Delivery.OriginAirport' | translate }} *</label>
                    <p-dropdown id="departureAirportId" formControlName="departureAirportId" [options]="airPorts | portName" optionLabel="fullname" optionValue="id" placeholder="{{ 'ForwarderPage.ForwarderDelivery.Delivery.PortSelect' | translate }}"></p-dropdown>
                    <small *ngIf="submit && formGroup.controls['departureAirportId'].invalid" class="p-error">{{getErrorMessage(formGroup, 'departureAirportId')}}</small>
                  </div>
                  <div class="field col-12 md:col-4 lg:col-3" *ngIf="delivery?.transportType == 1">
                    <label for="destinationAirportId">{{ 'ForwarderPage.ForwarderDelivery.Delivery.DestinationAirport' | translate }}</label>
                    <p-dropdown id="destinationAirportId" formControlName="destinationAirportId" [options]="airPorts | portName" optionLabel="fullname" optionValue="id" placeholder="{{ 'ForwarderPage.ForwarderDelivery.Delivery.PortSelect' | translate }}"></p-dropdown>
                    <small *ngIf="submit && formGroup.controls['destinationAirportId'].invalid" class="p-error">{{getErrorMessage(formGroup, 'destinationAirportId')}}</small>
                  </div>

                  <div class="field col-12 md:col-4 lg:col-3">
                    <label for="week">{{ 'ForwarderPage.ForwarderDelivery.Delivery.Week' | translate }}</label>
                    <p-inputNumber id="week" formControlName="week" [useGrouping]="false" [min]="1" [max]="52" [maxlength]="5"/>
                  </div>
                  <div class="field col-12 md:col-4 lg:col-3">
                    <label for="placeDelivery">{{ 'ForwarderPage.ForwarderDelivery.Delivery.PlaceDelivery' | translate }}</label>
                    <input id="placeDelivery" formControlName="placeDelivery" type="text" pInputText/>
                  </div>
                  <div class="field col-12 md:col-4 lg:col-3">
                    <label for="ship">{{ 'ForwarderPage.ForwarderDelivery.Delivery.VesselName' | translate }}</label>
                    <input id="ship" formControlName="ship" type="text" pInputText/>
                  </div>
                  <div class="field col-12 md:col-4 lg:col-3">
                    <label for="voyageNumber">{{ 'ForwarderPage.ForwarderDelivery.Delivery.Voyage' | translate }}</label>
                    <input id="voyageNumber" formControlName="voyageNumber" type="text" pInputText/>
                  </div>
                  <div class="field col-12 md:col-4 lg:col-3">
                    <label for="typeMovement">{{ 'ForwarderPage.ForwarderDelivery.Delivery.MovementType' | translate }}</label>
                    <input id="typeMovement" formControlName="typeMovement" type="text" pInputText/>
                  </div>
                  <div class="field col-12 md:col-4 lg:col-3">
                    <label for="etd">{{ 'ForwarderPage.ForwarderDelivery.Delivery.ETD' | translate }}</label>
                    <p-calendar
                      inputId="etd"
                      formControlName="etd"
                      appendTo="body"
                      [showButtonBar]="true"
                      [showIcon]="true"
                      [showWeek]="true"
                      [firstDayOfWeek]="1"></p-calendar>
                  </div>
                  <div class="field col-12 md:col-4 lg:col-3">
                    <label for="eta">{{ 'ForwarderPage.ForwarderDelivery.Delivery.ETA' | translate }}</label>
                    <p-calendar
                      inputId="eta"
                      formControlName="eta"
                      appendTo="body"
                      [showButtonBar]="true"
                      [showIcon]="true"
                      [showWeek]="true"
                      [firstDayOfWeek]="1"></p-calendar>
                  </div>
                  <div class="field col-12 md:col-4 lg:col-3">
                    <label for="stackingStart">{{ 'ForwarderPage.ForwarderDelivery.Delivery.StackingStart' | translate }}</label>
                    <p-calendar
                      inputId="stackingStart"
                      formControlName="stackingStart"
                      appendTo="body"
                      [showButtonBar]="true"
                      [showIcon]="true"
                      [showWeek]="true"
                      [showTime]="true"
                      [firstDayOfWeek]="1"></p-calendar>
                  </div>
                  <div class="field col-12 md:col-4 lg:col-3">
                    <label for="stackingEnd">{{ 'ForwarderPage.ForwarderDelivery.Delivery.StackingEnd' | translate }}</label>
                    <p-calendar
                      inputId="stackingEnd"
                      formControlName="stackingEnd"
                      appendTo="body"
                      [showButtonBar]="true"
                      [showIcon]="true"
                      [showWeek]="true"
                      [showTime]="true"
                      [firstDayOfWeek]="1"></p-calendar>
                  </div>
                  <div class="field col-12 md:col-4 lg:col-3">
                    <label for="dus">{{ 'ForwarderPage.ForwarderDelivery.Delivery.DUS' | translate }}</label>
                    <input id="dus" formControlName="dus" type="text" pInputText/>
                  </div>
                  <div class="field col-12 md:col-4 lg:col-3">
                    <label for="sps">{{ 'ForwarderPage.ForwarderDelivery.Delivery.SPS' | translate }}</label>
                    <input id="sps" formControlName="sps" type="text" pInputText/>
                  </div>
                  <div class="field col-12 md:col-4 lg:col-3">
                    <label for="cutoffDate">{{ 'ForwarderPage.ForwarderDelivery.Delivery.CutoffDate' | translate }}</label>
                    <p-calendar
                      inputId="cutoffDate"
                      formControlName="cutoffDate"
                      appendTo="body"
                      [showButtonBar]="true"
                      [showIcon]="true"
                      [showWeek]="true"
                      [showTime]="true"
                      [firstDayOfWeek]="1"></p-calendar>
                  </div>
                  <div class="field col-12 md:col-4 lg:col-3">
                    <label for="carrierName">{{ 'ForwarderPage.ForwarderDelivery.Delivery.CarrierName' | translate }}</label>
                    <input id="carrierName" formControlName="carrierName" type="text" pInputText/>
                  </div>
                  <div class="field col-12 md:col-4 lg:col-3">
                    <label for="departurePatentTruck">{{ 'ForwarderPage.ForwarderDelivery.Delivery.DepartureTruck' | translate }}</label>
                    <input id="departurePatentTruck" formControlName="departurePatentTruck" type="text" pInputText/>
                  </div>
                  <div class="field col-12 md:col-4 lg:col-3">
                    <label for="departurePatentCar">{{ 'ForwarderPage.ForwarderDelivery.Delivery.DepartureCar' | translate }}</label>
                    <input id="departurePatentCar" formControlName="departurePatentCar" type="text" pInputText/>
                  </div>
                  <div class="field col-12 md:col-4 lg:col-3">
                    <label for="ot">{{ 'ForwarderPage.ForwarderDelivery.Delivery.OT' | translate }}</label>
                    <input id="ot" formControlName="ot" type="text" pInputText/>
                  </div>
                  <div class="field col-12 md:col-4 lg:col-3">
                    <label for="ref1">{{ 'ForwarderPage.ForwarderDelivery.Delivery.Ref1' | translate }}</label>
                    <input id="ref1" formControlName="ref1" type="text" pInputText/>
                  </div>
                  <div class="field col-12 md:col-4 lg:col-3">
                    <label for="ref2">{{ 'ForwarderPage.ForwarderDelivery.Delivery.Ref2' | translate }}</label>
                    <input id="ref2" formControlName="ref2" type="text" pInputText/>
                  </div>
                  <div class="field col-12 md:col-4 lg:col-3">
                    <label for="ctr">{{ 'ForwarderPage.ForwarderDelivery.Delivery.CTR' | translate }}</label>
                    <input id="ctr" formControlName="ctr" type="text" pInputText/>
                  </div>
                  <div class="col-12">
                    <p-divider></p-divider>
                  </div>
                  <div class="field col-12 md:col-4 lg:col-3">
                    <label for="oceanFreight">{{ 'ForwarderPage.ForwarderDelivery.Delivery.OceanFreight' | translate }}</label>
                    <p-inputNumber id="oceanFreight" formControlName="oceanFreight" [min]="0" mode="currency" currency="USD" locale="en-US"/>
                  </div>
                  <div class="field col-12 md:col-4 lg:col-3">
                    <label for="inland">{{ 'ForwarderPage.ForwarderDelivery.Delivery.Pickup' | translate }}</label>
                    <p-inputNumber id="inland" formControlName="inland" [min]="0" mode="currency" currency="USD" locale="en-US"/>
                  </div>
                  <div class="field col-12 md:col-4 lg:col-3">
                    <label for="localCharges">{{ 'ForwarderPage.ForwarderDelivery.Delivery.LocalCharges' | translate }}</label>
                    <p-inputNumber id="localCharges" formControlName="localCharges" [min]="0" mode="currency" currency="USD" locale="en-US"/>
                  </div>
                  <div class="field col-12 md:col-4 lg:col-3">
                    <label for="customs">{{ 'ForwarderPage.ForwarderDelivery.Delivery.CustomAgent' | translate }}</label>
                    <p-inputNumber id="customs" formControlName="customs" [min]="0" mode="currency" currency="USD" locale="en-US"/>
                  </div>
                  <div class="field col-12 md:col-4 lg:col-3">
                    <label for="surcharge">{{ 'ForwarderPage.ForwarderDelivery.Delivery.Surcharge' | translate }}</label>
                    <p-inputNumber id="surcharge" formControlName="surcharge" [min]="0" mode="currency" currency="USD" locale="en-US"/>
                  </div>
                  <div class="field col-12 md:col-4 lg:col-3">
                    <label for="surcharge">{{ 'ForwarderPage.ForwarderDelivery.Delivery.Handling' | translate }}</label>
                    <p-inputNumber id="handling" formControlName="handling" [min]="0" mode="currency" currency="USD" locale="en-US"/>
                  </div>
                  <div class="field col-12 md:col-4 lg:col-3">
                    <label for="surcharge">{{ 'ForwarderPage.ForwarderDelivery.Delivery.GateOut' | translate }}</label>
                    <p-inputNumber id="gateOut" formControlName="gateOut" [min]="0" mode="currency" currency="USD" locale="en-US"/>
                  </div>
                  <div class="field col-12 md:col-4 lg:col-3">
                    <label for="otherCosts">{{ 'ForwarderPage.ForwarderDelivery.Delivery.Others' | translate }}</label>
                    <p-inputNumber id="otherCosts" formControlName="otherCosts" [min]="0" mode="currency" currency="USD" locale="en-US"/>
                  </div>
                  <div class="field col-12 md:col-4 lg:col-3">
                    <label>{{ 'ForwarderPage.ForwarderDelivery.Delivery.TotalCost' | translate }}</label>
                    <p-inputNumber [ngModel]="totalCost" [ngModelOptions]="{standalone: true}"  mode="currency" currency="USD" locale="en-US" [readonly]="true"/>
                  </div>
                  <div class="col-12" style="margin-bottom: 40px;">
      
                    <p-table [value]="discounts" responsiveLayout="stack" [breakpoint]="'600px'">
                      <ng-template pTemplate="caption">
                        <h2>{{ 'ForwarderPage.ForwarderDelivery.Discounts.Title' | translate }}</h2>
                        <div class="flex align-items-center justify-content-start">
                          <p-button label="{{ 'ForwarderPage.ForwarderDelivery.Buttons.New' | translate }}" icon="pi pi-plus" class="mr-2" (onClick)="openDiscountCreateDialog(discountFormDirective)"/>
                        </div>
                      </ng-template>
                      <ng-template pTemplate="header">
                        <tr>
                          <th>
                            <div class="flex justify-content-center align-items-center">
                              {{ 'ForwarderPage.ForwarderDelivery.Discounts.Table.Description' | translate }}
                            </div>
                          </th>
                          <th>
                            <div class="flex justify-content-center align-items-center">
                              {{ 'ForwarderPage.ForwarderDelivery.Discounts.Table.Amount' | translate }}
                            </div>
                          </th>
                          <th></th>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-discount>
                        <tr>
                          <td><div class="flex justify-content-center align-items-center">{{discount.description}}</div></td>
                          <td><div class="flex justify-content-center align-items-center">{{discount.amount | currency: 'USD':'US$'}}</div></td>
                          <td>
                            <div class="flex justify-content-end align-items-center gap-2">
                              <p-button label="{{ 'ForwarderPage.ForwarderDelivery.Buttons.Edit' | translate }}" icon="pi pi-pencil" class="mr-2" (onClick)="openDiscountEditDialog(discount)"/>
                              <p-button label="{{ 'ForwarderPage.ForwarderDelivery.Buttons.Delete' | translate }}" icon="pi pi-trash" class="mr-2" (onClick)="deleteDiscount(discount.id)"/>
                            </div>
                          </td>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="summary">
                        <div class="flex align-items-center justify-content-end">
                          <div class="grid">
                            <div class="col-6 flex align-items-center justify-content-end">
                              <span>{{ 'ForwarderPage.ForwarderDelivery.Discounts.TotalCostOperation' | translate }}:</span>
                            </div>
                            <div class="col-6 flex align-items-center justify-content-end">
                              {{totalCost | currency: 'USD':'US$'}}
                            </div>
                            <div class="col-6 flex align-items-center justify-content-end">
                              <span>{{ 'ForwarderPage.ForwarderDelivery.Discounts.TotalDiscount' | translate }}:</span>
                            </div>
                            <div class="col-6 flex align-items-center justify-content-end">
                              {{totalDiscounts | currency: 'USD':'US$'}}
                            </div>
                            <div class="col-6 flex align-items-center justify-content-end">
                              <span>{{ 'ForwarderPage.ForwarderDelivery.Discounts.TotalCost' | translate }}:</span>
                            </div>
                            <div class="col-6 flex align-items-center justify-content-end">
                              {{ (totalCost - totalDiscounts) | currency: 'USD':'US$' }}
                            </div>
                          </div>
                        </div>
                      </ng-template>
                    </p-table>
      
                  </div>
                  <div class="col-12">
                    <p-divider></p-divider>
                  </div>
                  <div class="field col-12 md:col-4 lg:col-3">
                    <label for="freightPayableAt">{{ 'ForwarderPage.ForwarderDelivery.FreightPayable' | translate }}</label>
                    <input id="freightPayableAt" formControlName="freightPayableAt" type="text" pInputText/>
                  </div>
                  <div class="field col-12 md:col-4 lg:col-3">
                    <label for="shippedOnBoard">{{ 'ForwarderPage.ForwarderDelivery.ShippedBoard' | translate }}</label>
                    <p-calendar
                      inputId="shippedOnBoard"
                      formControlName="shippedOnBoard"
                      appendTo="body"
                      [showButtonBar]="true"
                      [showIcon]="true"
                      [showWeek]="true"
                      [firstDayOfWeek]="1"></p-calendar>
                  </div>
                  <div class="field col-12 md:col-4 lg:col-3">
                    <label for="placeIssue">{{ 'ForwarderPage.ForwarderDelivery.IssuePlace' | translate }}</label>
                    <input id="placeIssue" formControlName="placeIssue" type="text" pInputText/>
                  </div>
                  <div class="field col-12 md:col-4 lg:col-3">
                    <label for="dateIssue">{{ 'ForwarderPage.ForwarderDelivery.IssueDate' | translate }}</label>
                    <p-calendar
                      inputId="dateIssue"
                      formControlName="dateIssue"
                      appendTo="body"
                      [showButtonBar]="true"
                      [showIcon]="true"
                      [showWeek]="true"
                      [firstDayOfWeek]="1"></p-calendar>
                  </div>
                  <div class="field col-12 md:col-4 lg:col-3">
                    <label for="excessValueDeclaration">{{ 'ForwarderPage.ForwarderDelivery.ExcessValue' | translate }}</label>
                    <input id="excessValueDeclaration" formControlName="excessValueDeclaration" type="text" pInputText/>
                  </div>
                  <div class="col-12">
                    <p-divider></p-divider>
                  </div>
                  <div class="col-6"></div>
                  <div class="col-6 grid">
                    <div class="col-6"><p-button label="{{ 'ForwarderPage.ForwarderDelivery.Buttons.Back' | translate }}" (onClick)="goToReviewOrders()" styleClass="p-button-outlined"></p-button></div>
                    <div class="col-6"><p-button label="{{ 'ForwarderPage.ForwarderDelivery.Buttons.SaveDelivery' | translate }}" [loading]="loading" (onClick)="saveDelivery()"></p-button></div>
                  </div>
                </div>
              </form>
            </div>
          </ng-template>
    </p-card>
</app-user-dashboard>

<p-toast></p-toast>

<p-dialog [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleOfferDetailDialog" [style]="{width: '80%'}">
  <app-export-offer-detail [offer]="selectedExportOffer" [productCertificateTypes]="selectedProductCertificateTypes" [packagingTypes]="selectedPackagingTypes"></app-export-offer-detail>
  <app-frozen-fruit-export-offer-detail [offer]="frozenFruitExportOffer"></app-frozen-fruit-export-offer-detail>
  <ng-template pTemplate="footer">
    <p-button label="{{ 'ForwarderPage.ForwarderDelivery.Buttons.Close' | translate }}" (click)="visibleOfferDetailDialog = false"></p-button>
  </ng-template>
</p-dialog>

<p-dialog header="{{ 'ForwarderPage.ForwarderDelivery.Discounts.Title' | translate }}" [(visible)]="discountDialogVisible" [style]="{width: '30%'}" [modal]="true">
    <form [formGroup]="discountFormGroup" #discountFormDirective="ngForm">
      <div class="p-fluid formgrid grid" style="padding: 20px;">
        <div class="field col-12" style="margin-bottom: 40px;">
          <label for="description" style="font-weight: bolder;">{{ 'ForwarderPage.ForwarderDelivery.Discounts.Table.Description' | translate }} *</label>
          <input id="description" formControlName="description" type="text" required pInputText [maxlength]="500" placeholder="{{ 'ForwarderPage.ForwarderDelivery.Discounts.Table.Description' | translate }}"/>
          <small *ngIf="submitDiscount && discountFormGroup.controls['description'].invalid" class="p-error">{{getErrorMessage(discountFormGroup, 'description')}}</small>
        </div>
        <div class="field col-12" style="margin-bottom: 40px;">
          <label for="amount" style="font-weight: bolder;">{{ 'ForwarderPage.ForwarderDelivery.Discounts.Table.Amount' | translate }} *</label>
          <p-inputNumber id="amount" formControlName="amount" mode="currency" currency="USD" locale="en-US" placeholder="{{ 'ForwarderPage.ForwarderDelivery.Discounts.Table.Amount' | translate }}"/>
          <small *ngIf="submitDiscount && discountFormGroup.controls['amount'].invalid" class="p-error">{{getErrorMessage(discountFormGroup, 'amount')}}</small>
        </div>
      </div>
    </form>
    <ng-template pTemplate="footer">
      <p-button label="{{ 'ForwarderPage.ForwarderDelivery.Buttons.Close' | translate }}" (onClick)="discountDialogVisible = false" styleClass="p-button-outlined"></p-button>
      <p-button label="{{ 'ForwarderPage.ForwarderDelivery.Buttons.SaveDiscount' | translate }}" (onClick)="saveDiscount()" [loading]="loading"></p-button>
    </ng-template>
  </p-dialog>