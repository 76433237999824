<app-dashboard-layout>
  <app-content-header>
    <app-home-header></app-home-header>
  </app-content-header>
  <app-content-body>
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="home" [style]="{'margin-bottom':'20px'}"></p-breadcrumb>

    <div class="grid flex align-items-center">
      <div class="col-12">
        <p-card>
          <div class="grid flex align-items-center">
            <div class="col-12 md:col lg:col flex justify-content-center">
              <span style="font-weight: bold; font-size: 21px;">&nbsp;</span>
            </div>
            <div class="col-12 md:col-4 lg:col-4 flex justify-content-center">
              <span style="font-weight: bold; font-size: 21px;">Producto</span>
            </div>
            <div class="col-12 md:col lg:col flex justify-content-center">
              <span style="font-weight: bold; font-size: 21px;">Precio unitario</span>
            </div>
            <div class="col-12 md:col lg:col flex justify-content-center">
              <span style="font-weight: bold; font-size: 21px;">Cantidad</span>
            </div>
            <div class="col-12 md:col lg:col flex justify-content-center">
              <span style="font-weight: bold; font-size: 21px;">Precio total</span>
            </div>
            <div class="col-12 md:col lg:col flex justify-content-center">
              <span style="font-weight: bold; font-size: 21px;">Acciones</span>
            </div>
          </div>
        </p-card>
      </div>
    </div>


    <p-dataView #dv [value]="pallets">
      <ng-template let-pallet pTemplate="listItem">
        <div class="col-12">
          <p-card [style]="{'margin-bottom': '20px'}">
            <div class="grid flex align-items-center">
              <div class="col-12 md:col lg:col flex justify-content-center">
                <img src="https://cdn.portalfruticola.com/2019/02/e2f548b3-maylen.jpg" style="border-radius: 7px; width: 100%; max-width: 300px; height: auto;"/>
              </div>
              <div class="col-12 md:col-4 lg:col-4 flex justify-content-center">
                <div>
                  <h2>TABLE GRAPES</h2>
                  <p>Fecha de cosecha: 19-04-2023</p>
                  <p>Fecha de empaque: 19-04-2023</p>
                  <p>Control de calidad: incluído</p>
                </div>
              </div>
              <div class="col-12 md:col lg:col flex justify-content-center">
                <p style="font-size: 1.5rem; color: #8c2b87; font-weight: bold;">USD 20.00$</p>
              </div>
              <div class="col-12 md:col lg:col flex justify-content-center">
                <span style="font-size: 1.5rem;">Pallets: 100</span>
              </div>
              <div class="col-12 md:col lg:col flex justify-content-center">
                <span style="font-size: 1.5rem; font-weight: bold;">USD 2000.00$</span>
              </div>
              <div class="col-12 md:col lg:col flex justify-content-center">
                <p-button label="Eliminar" styleClass="p-button-outlined"/>
              </div>
            </div>
          </p-card>
        </div>
      </ng-template>
    </p-dataView>

    <div class="flex flex align-items-center justify-content-end" style="margin-top:20px; margin-right: 50px;">
      <p-button label="Confirmar Orden"></p-button>
    </div>
  </app-content-body>
</app-dashboard-layout>
