
    <div class="flex justify-content-start align-items-start" *ngIf="!processing">
        <!--div class="col-6 flex justify-content-start" [hidden]="hideTimer">
          <p [hidden]="hideTimer">Tiempo restante para confirmacion:</p>
        </div-->
        <div class="col-6 flex justify-content-center align-items-center w-full">
          <div>
            {{ days }} {{'Timer.DaysText' | translate}}, {{hours}} {{'Timer.HoursText' | translate}}, {{minutes}} {{'Timer.MinutesText' | translate}}<span *ngIf="showSeconds">, {{ seconds }} {{'Timer.SecondsText' | translate}}</span>
        </div>
    </div>
    <div *ngIf="processing" class="col-6 flex justify-content-start align-items-start">
      <p>{{'Timer.CalculatedText' | translate}}</p>
    </div>
