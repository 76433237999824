<div class="flex align-items-center justify-content-center flex-column" *ngIf="!isCreateFromTemplate">
  <span>{{'ExportOfferCreateComponent.Title' | translate}}</span>
  <h2>{{'ExportOfferCreateComponent.Subtitle' | translate}}</h2>
  <div style="margin-top: 20px;">
    <img src="../../../../../assets/img/create-export-offer-icon.png" alt="Icon" style="border-radius: 7px; width: 100%; max-width: 300px; height: auto;"/>
  </div>
  <div style="margin-top: 40px" class="w-full">
    <div class="flex align-items-center justify-content-center gap-4">
      <p-button label="{{'ExportOfferCreateComponent.CreateButtonLabel' | translate}}" icon="pi pi-plus" styleClass="p-button-outlined" (onClick)="goCreateExportOffer()"></p-button>
      <p-button label="{{'ExportOfferCreateComponent.ShowDuplicateButtonLabel' | translate}}" icon="pi pi-list" (onClick)="showCreateFromTemplate()"></p-button>
    </div>
  </div>
</div>

<div *ngIf="isCreateFromTemplate">
  <h2>{{'ExportOfferCreateComponent.Subtitle2' | translate}}</h2>
  <p-table
    [value]="exportOffers"
    [paginator]="true"
    [rows]="10"
    [showCurrentPageReport]="true"
    [rowsPerPageOptions]="[10, 25, 50]"
    [loading]="loading"
    responsiveLayout="stack"
    [breakpoint]="'600px'">
    <ng-template pTemplate="header">
      <tr>
        <th><div class="flex justify-content-center align-items-center">{{'ExportOfferCreateComponent.ImageHeader' | translate}}</div></th>
        <th><div class="flex justify-content-center align-items-center">{{'ExportOfferCreateComponent.SpeciesVarietyHeader' | translate}}</div></th>
        <th><div class="flex justify-content-center align-items-center">{{'ExportOfferCreateComponent.BoxesPerPalletHeader' | translate}}</div></th>
        <th><div class="flex justify-content-center align-items-center">{{'ExportOfferCreateComponent.PriceHeader' | translate}}</div></th>
        <th><div class="flex justify-content-center align-items-center">{{'ExportOfferCreateComponent.AvailablePalletsHeader' | translate}}</div></th>
        <th><div class="flex justify-content-center align-items-center">{{'ExportOfferCreateComponent.FridgeStorageHeader' | translate}}</div></th>
        <th><div class="flex justify-content-center align-items-center">{{'ExportOfferCreateComponent.ActionsHeader' | translate}}</div></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-exportOffer>
      <tr>
        <td>
          <div class="flex justify-content-center align-items-center">
            <img [src]="getImageUrl(exportOffer)" style="border-radius: 7px; width: 100%; max-width: 100px; height: auto;" alt="Product picture" *ngIf="exportOffer.pictures.length > 0"/>
            <span style="border-radius: 7px; padding: 5px; font-weight: bold;" *ngIf="exportOffer.pictures.length < 1">{{'UserPages.UserExportOffers.NotAvailablePictureLabel' | translate}}</span>
          </div>
        </td>
        <td><div class="flex justify-content-center align-items-center">{{exportOffer.speciesName + '/' + exportOffer.varietyName}}</div></td>
        <td><div class="flex justify-content-center align-items-center">{{exportOffer.boxesPerPallet}}</div></td>
        <td><div class="flex justify-content-center align-items-center">{{exportOffer.listPrice | currency: 'USD':'US$'}} {{exportOffer.incoterms}}</div></td>
        <td><div class="flex justify-content-center align-items-center">{{exportOffer.availablePallets}}</div></td>
        <td><div class="flex justify-content-center align-items-center">{{exportOffer.fridgeStorageName}}</div></td>
        <td>
          <div class="flex justify-content-center align-items-center">
            <p-button label="{{'ExportOfferCreateComponent.DuplicateButtonLabel' | translate}}" (onClick)="duplicate(exportOffer.id)"></p-button>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="7">{{'ExportOfferCreateComponent.EmptyResultsText' | translate}}</td>
      </tr>
    </ng-template>
  </p-table>
</div>
