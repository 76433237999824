import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { CompanyGateway } from 'src/app/application/gateway/CompanyGateway';
import { CountryGateway } from 'src/app/application/gateway/CountryGateway';
import { CustomAgentGateway } from 'src/app/application/gateway/CustomAgentGateway';
import { Company, EditCompany, ProfileCompayList } from 'src/app/domain/models/Company';
import { Country } from 'src/app/domain/models/Country';
import { CustomAgentUserCreateRequest } from 'src/app/domain/models/CustomAgentReview';
import { Profile } from 'src/app/domain/models/Profile';

@Component({
  selector: 'app-custom-agent-users',
  templateUrl: './custom-agent-users.component.html',
  styleUrls: ['./custom-agent-users.component.css'],
  providers: [MessageService]
})
export class CustomAgentUsersComponent implements OnInit {

  countries: Country[] = []
  selectedCountry: Country | undefined
  customAgentId: number | undefined
  customAgent: Company | undefined
  
  profiles: Profile[] = []
  users: ProfileCompayList[] = []
  selectedUserAdmin: any
  selectedUserCountry: Country | undefined

  loading: boolean = false
  submit: boolean = false
  visibleDialog: boolean = false

  formGroup: FormGroup = this._formBuilder.group({
    companyName: ['', Validators.required],
    contactName: ['', Validators.required],
    contactLastName: [null, Validators.required],
    contactPhone: [null, Validators.required],
    contactEmail: ['', [Validators.required, Validators.email]],
    countryId: [null, Validators.required],
    profileId: [null]
  })

  formUserGroup: FormGroup = this._formBuilder.group({
    userContactName: ['', Validators.required],
    userContactLastName: ['', Validators.required],
    userContactEmail: ['', [Validators.required, Validators.email]],
    userCountryId: [null, Validators.required],
    userContactCity: [''],
    userContactAddress: [''],
    userContactPhone: [''],
  })

  constructor(
    private _formBuilder: FormBuilder,
    private _messageService: MessageService,
    private _countryService: CountryGateway,
    private _companyService: CompanyGateway,
    private _customAgentService: CustomAgentGateway,
    private _authService: AuthGateway,
    public translate: TranslateService) {}

  ngOnInit(): void {
    this.loadCountries()
    this.loadCustomAgent()
  }

  loadForm() {
    if(this.customAgent) {
      this.customAgentId = this.customAgent.id
      this.formGroup.controls["companyName"].setValue(this.customAgent.companyName)
      this.formGroup.controls["contactName"].setValue(this.customAgent.contactName)
      this.formGroup.controls["contactLastName"].setValue(this.customAgent.contactLastName)
      this.formGroup.controls["contactPhone"].setValue(this.customAgent.contactPhone)
      this.formGroup.controls["contactEmail"].setValue(this.customAgent.contactEmail)
      this.formGroup.controls["countryId"].setValue(this.customAgent.countryId)

      if (this.customAgent.countryId != null) this.onChangeCountry()
    }
  }

  loadCountries(): void {
    this._countryService.getAll().subscribe({
      next: (v) => {
        this.countries = v
      },
      error: (e) => {
        this._messageService.add({ severity: 'error', summary: this.translate.instant('MessageService.Summary.FailedAction'), detail: this.translate.instant('CustomAgentPage.Messages.ErrorLoadCountries') });
        console.log(e)
      },
      complete: () => console.info('Country query successfully')
    })
  }

  loadCustomAgent(): void {
    this._companyService.getUsersCompanyByProfile().subscribe({
      next: (v) => {
        this.customAgent = v
        if (this.customAgent.users) this.users = this.customAgent.users
        this.loadForm()
      },
      error: (e) => {
        console.log(e)
        let errorMessage = ''
        const lang = this.translate.currentLang
        switch(lang) {
          case 'es':
            errorMessage = e.error.messageEs
            break
          case 'en':
            errorMessage = e.error.messageEn
            break
          default:
            errorMessage = this.translate.instant('CustomAgentPage.Messages.ErrorLoadCustomAgents')
        }
        this._messageService.add({ severity: 'error', summary: this.translate.instant('MessageService.Summary.FailedAction'), detail: errorMessage });
      },
      complete: () =>  {
        console.info('Custom Agent query successfully')
      }
    })
  }

  getErrorMessage(formGroup: FormGroup, fieldName: string): string {
    if (formGroup.controls[fieldName].hasError('required')) {
      return this.translate.instant('FormErrorMessage.FieldValueRequired')
    }
    if (formGroup.controls[fieldName].hasError('email')) {
      return this.translate.instant('FormErrorMessage.EmailFieldEmailFormatError')
    }
    return '';
  }

  editCompany(): void{
    this.submit = true
    if(this.formGroup.valid) {
      const company: EditCompany = {
        id: this.customAgentId,
        companyName: this.formGroup.controls['companyName'].value,
        contactName: this.formGroup.controls['contactName'].value,
        contactLastName: this.formGroup.controls['contactLastName'].value,
        contactPhone: this.formGroup.controls['contactPhone'].value,
        contactEmail: this.formGroup.controls['contactEmail'].value,
        countryId: this.formGroup.controls['countryId'].value,
        companyTypeId: 1, // Custom Agent
        users: this.users.map((item) => { return { ...item, company: this.customAgent}})
      }

      this.loading = true
      this._companyService.update(company).subscribe({
        next: (v) => {
          this.loading = false
          this.submit = false
          this._messageService.add({ severity: 'success', summary: this.translate.instant('MessageService.Summary.SuccessAction'), detail: this.translate.instant('CustomAgentPage.Messages.SuccessEditCustomAgent') });
        },
        error: (e) => {
          this.loading = false
          this.submit = false
          this._messageService.add({ severity: 'error', summary: this.translate.instant('MessageService.Summary.FailedAction'), detail: this.translate.instant('CustomAgentPage.Messages.ErrorAddedCustomAgent') });
          console.log(e)
        },
        complete: () => console.info('Custom Agent created successfully')
      })
    } else {
      this.findInvalidControls()
      this._messageService.add({ severity: 'error',
        summary: this.translate.instant('MessageService.Summary.FailedAction'),
        detail: this.translate.instant('MessageService.FormMessages.FormInvalid') })
    }
  }

  onChangeCountry() {
    this.selectedCountry = this.countries.find(x => x.id === this.formGroup.controls['countryId'].value)
  }

  onChangeUserCountry() {
    this.selectedUserCountry = this.countries.find(x => x.id === this.formUserGroup.controls['userCountryId'].value)
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.formGroup.controls;
    for (const name in controls) {
        if (controls[name].invalid) {
            invalid.push(name);
        }
    }
    return invalid;
  }

  onSelectAdmin(selected: ProfileCompayList) {
    this.selectedUserAdmin = selected.profile.id

    this.users.forEach(user => {
      user.isAdmin = user.profile.id === this.selectedUserAdmin;
    });
  }

  addCustomAgent() {
    let profile = this.formGroup.controls['profileId'].value
    if (profile)
    {
      if (this.users.some((item) => item.profile.id === profile.id))
      {
        this._messageService.add({ severity: 'info', summary: this.translate.instant('MessageService.Summary.FailedAction'), detail: this.translate.instant('CustomAgentPage.Messages.ErrorExistingCustomAgent') });
        return;
      }

      let newUser: ProfileCompayList = {
        isAdmin: false,
        profile: profile,
        id: 0,
        companyId: this.customAgentId ?? 0,
        profileId: profile.id
      }
      this.users.push(newUser)
    }
  }

  removeUser(idx: number) {
    this.users.splice(idx, 1)
  }

  saveUser() {
    this.submit = true
    console.log(this.formUserGroup.valid)
    if(this.formUserGroup.valid && this.customAgentId) {
      const profile: CustomAgentUserCreateRequest = {
        name: this.formUserGroup.controls['userContactName'].value,
        lastName: this.formUserGroup.controls['userContactLastName'].value,
        email: this.formUserGroup.controls['userContactEmail'].value,
        companyName: this.formGroup.controls['companyName'].value,
        contactCountryId: this.formUserGroup.controls['userCountryId'].value,
        contactAddress: this.formUserGroup.controls['userContactAddress'].value,
        contactCity: this.formUserGroup.controls['userContactCity'].value,
        contactPhone: this.formUserGroup.controls['userContactPhone'].value,
        companyId: this.customAgentId
      }

      this.loading = true
      this._customAgentService.createUser(profile).subscribe({
        next: (v) => {
          this.loading = false
          this.submit = false
          this._messageService.add({ severity: 'success', summary: this.translate.instant('MessageService.Summary.SuccessAction'), detail: this.translate.instant('CustomAgentPage.Messages.SuccessAddedCustomAgent') });
          this.loadCustomAgent()
          this.visibleDialog = false
        },
        error: (e) => {
          console.error(e);
          this.loading = false
          this.submit = false
          this._messageService.add({ severity: 'error', summary: this.translate.instant('MessageService.Summary.FailedAction'), detail: this.translate.instant('CustomAgentPage.Messages.ErrorAddedCustomAgent') });
          console.log(e)
        },
        complete: () => console.info('Profile created successfully')
      })
    }else{
      this.findInvalidControls()
    }
  }
}
