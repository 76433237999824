import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { SecurityGateway } from 'src/app/application/gateway/SecurityGateway';
import { ValidateTokenRequest } from 'src/app/domain/models/Profile';
import { Routes } from 'src/app/infrastructure/routes';
import { passwordMatchValidator } from 'src/app/ui/util/custom-validators';
import { ErrorMessageUtil } from 'src/app/ui/util/error-message-util';

@Component({
  selector: 'app-signup-validationtoken',
  templateUrl: './signup-validationtoken.component.html',
  styleUrls: ['./signup-validationtoken.component.css'],
  providers: [MessageService]
})
export class SignupValidationtokenComponent implements OnInit {

  token: string = "";
  submit: boolean = false
  loading: boolean = false
  success: boolean = false

  formGroup: FormGroup = this._formBuilder.group({
    newPassword: ['', Validators.required],
    confirmPassword: ['', Validators.required],
  },
  {
    validators: [passwordMatchValidator('newPassword', 'confirmPassword')]
  })

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _securityService: SecurityGateway,
    private _messageService: MessageService,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    this._activatedRoute.params.subscribe(params => {
      this.token = params['token'];
    });
  }

  getErrorMessage(fieldName: string): string {
    return ErrorMessageUtil.getErrorMessage(this.formGroup, this.translate, fieldName)
  }

  get passwordMatchError(): boolean {
    return this.formGroup.getError('passwordMismatch')
  }

  getPasswordMissMatchMessage() {
    const lang = this.translate.currentLang
    switch(lang) {
      case 'en':
        return 'Password does not match.'
      case 'es':
        return 'Las contraseñas no coinciden.'
      default:
        return 'Password does not match.'
    }
  }

  submitForm(): void {
    this.submit = true
    if(this.formGroup.valid) {
      this.loading = true

      let request: ValidateTokenRequest = {
        token: this.token,
        password: this.formGroup.controls['newPassword'].value,
        confirmPassword: this.formGroup.controls['confirmPassword'].value
      }

      this._securityService.validateToken(request).subscribe({
        next: (v) => {
          this.loading = false
          this.success = true
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: this.translate.instant('UserPages.UserChangePassword.UpdatePasswordSuccess') });
        },
        error: (e) => {
          this.loading = false
          this.submit = false
          let errorMessage = ''
          switch(this.translate.currentLang) {
            case 'es':
              errorMessage = e.error.messageEs
              break
            case 'en':
              errorMessage = e.error.messageEn
              break
            default:
              errorMessage = e.error.messageEn
          }
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: errorMessage });
        },
        complete: () =>  { console.info('complete') }
      })
    }
  }

  goToLogin() {
    this._router.navigate(['/' + Routes.login]);
  }

}
