<div class="container">
  <section class="section-top">
    <div class="column-icon">
      <div class="section-icon-networks">

        <figure class=figure-logo>
          <img class=icon-logo src="../../../../assets/img/versiones_logo5.png" alt="Logo Fruzty"/>
        </figure>
        <section class="section-social-networks">
          <a (click)="openLink(linkedInUrl)" class="icon-click" >
            <figure class=figure-circle>
              <i class="pi pi-linkedin icon-network"></i>
            </figure>
          </a>
          <a (click)="openLink(instagramUrl)" class="icon-click">
            <figure class=figure-circle>
              <i class="pi pi-instagram icon-network"></i>
            </figure>
          </a>

          <!--
            <figure class=figure-circle>
              <i class="pi pi-facebook icon-network"></i>
            </figure>
            <figure class=figure-circle>
              <i class="pi pi-youtube icon-network"></i>
            </figure>
          -->
        </section>
      </div>


    </div>
    <div class="column-menu">
      <h2 class="title-menu">{{'HomeFooter.Menu1.Title' | translate}}</h2>
      <a [href]="homeUrl" style="text-decoration: inherit; color:inherit">
        <p class="text-menu">{{'HomeFooter.Menu1.Item1' | translate}}</p>
      </a>
      <a (click)="visibleRequestCode()" class="icon-click" style="text-decoration: inherit; color:inherit">
        <p class="text-menu">{{'HomeFooter.Menu1.Item2' | translate}}</p>
      </a>
      <a [href]="loginUrl" style="text-decoration: inherit; color:inherit">
        <p class="text-menu">{{'HomeFooter.Menu1.Item3' | translate}}</p>
      </a>
    </div>
    <div class="column-menu">
      <h2 class="title-menu">{{'HomeFooter.Menu2.Title' | translate}}</h2>
      <a [href]="offersUrl" style="text-decoration: inherit; color:inherit">
        <p class="text-menu">{{'HomeFooter.Menu2.Item1' | translate}}</p>
      </a>
    </div>
    <div class="column-menu">
      <h2 class="title-menu">{{'HomeFooter.Menu3.Title' | translate}}</h2>
      <div>
        <a [href]="mailToUrl" style="text-decoration: inherit; color:inherit">
          <p class="text-menu">{{'HomeFooter.Menu3.Item1' | translate}} {{contactEmail}}</p>
        </a>
      </div>

      <p class="text-menu">{{'HomeFooter.Menu3.Item2' | translate}}</p>
      <p class="text-menu">{{'HomeFooter.Menu3.Item3' | translate}}</p>
    </div>
    <div class="column-menu">
      <h2 class="title-menu">{{'HomeFooter.Menu4.Title' | translate}}</h2>
      <a href="#" style="text-decoration: inherit; color:inherit">
        <p class="text-menu">{{'HomeFooter.Menu4.Item1' | translate}}</p>
      </a>
      <a href="#" style="text-decoration: inherit; color:inherit">
        <p class="text-menu">{{'HomeFooter.Menu4.Item2' | translate}}</p>
      </a>
    </div>
  </section>
  <section class="section-bottom">
    {{'HomeFooter.CopyRight' | translate}}
  </section>
</div>
<p-toast></p-toast>

<p-dialog [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleDialog" [style]="{width: '50wv'}">
  <app-requests-code></app-requests-code>
</p-dialog>

