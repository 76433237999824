import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { DocumentServiceGateway } from 'src/app/application/gateway/DocumentServiceGateway';
import { ExportOfferGateway } from 'src/app/application/gateway/ExportOfferGateway';
import { ExportOffer, ExportOfferAddtionalInfo } from 'src/app/domain/models/ExportOffer';
import { Packaging } from 'src/app/domain/models/Packaging';
import { ProductCertificateType } from 'src/app/domain/models/ProductCertificateType';
import { BasicUser } from 'src/app/domain/models/User';
import { Routes } from 'src/app/infrastructure/routes';
import { ExportOfferUtils } from 'src/app/ui/util/export-offer-utils';
import { formLimit } from 'src/app/ui/util/form-limit';

@Component({
  selector: 'app-user-direct-sale-edit-additional-info',
  templateUrl: './user-direct-sale-edit-additional-info.component.html',
  styleUrls: ['./user-direct-sale-edit-additional-info.component.css'],
  providers: [MessageService]
})
export class UserDirectSaleEditAdditionalInfoComponent implements OnInit {

  breadcrumbItems: MenuItem[] = []
  breadcrumbHomeItem: MenuItem = {}

  exportOfferId: number | undefined

  exportOffer: ExportOfferAddtionalInfo | undefined
  qualityDocument: any[] = []
  selectedQualityDocument: any

  visibleOfferDetailDialog: boolean = false
  selectedExportOffer: ExportOffer | undefined
  selectedProductCertificateTypes: ProductCertificateType[] = []
  selectedPackagingTypes: Packaging[] = []

  profile: BasicUser | undefined

  loading: boolean = false
  buttonQualityDoc: boolean = false
  visibleUploadQualityDoc: boolean = false

  boxTypesOptions: any[] = []
  basePalletsOptions: any[] = []
  qualityControlOptions: any[] = []
  marksOptions: any[] = []

  formGroup: FormGroup = this._formBuilder.group({
    grossKilos: [null],
    boxType: [null],
    caseSize: [null],
    sizeMillimeters: [null],
    category: [null],
    qualityControlDate: [null],
    basePallets: [null],
    isQualityControl: [false],
    marks: [false]
  })

  formLimit = formLimit

  constructor(private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _messageService: MessageService,
    public translate: TranslateService,
    private _authService: AuthGateway,
    private _formBuilder: FormBuilder,
    private _documentService: DocumentServiceGateway,
    private _exportOfferService: ExportOfferGateway,) {
      this._activatedRoute.params.subscribe(params => {
        this.exportOfferId = params['exportOfferId']
      })
  }

  ngOnInit(): void {
    this.profile = this._authService.getUser()
    if(this.profile) {
      let homeItemLabel = 'My direct sales'
      let breadcrumbStep1ItemLabel1 = 'Additional info'

      switch(this.profile.lang) {
        case 'en':
          homeItemLabel = 'My direct sales'
          breadcrumbStep1ItemLabel1 = 'Additional info'
          break
        case 'es':
          homeItemLabel = 'Mis ventas directas'
          breadcrumbStep1ItemLabel1 = 'Información complementaria'
          break
      }

      this.breadcrumbHomeItem = { label: homeItemLabel, routerLink: '/' + Routes.userClosedSales };
      this.breadcrumbItems = [{ label: breadcrumbStep1ItemLabel1, disabled: true }]
    }

    this.getExportOffer()
  }

  getExportOffer() {
    if(this.exportOfferId) {
      this.loading = true
      this._exportOfferService.getExportOfferAddtionalInfo(this.exportOfferId).subscribe({
        next: (v) => {
          console.log(v)
          this.exportOffer = v
          this.loadForm()
        },
        error: (e) => {
          console.log(e)
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOffer.SingleRetrieveError') });
        },
        complete: () => console.info('ExportOffer query successfully')
      })
    }
  }

  loadForm() {
    if(this.profile && this.exportOffer) {
      this.boxTypesOptions = ExportOfferUtils.getBoxTypes(this.profile.lang)
      this.basePalletsOptions = ExportOfferUtils.getBasePallets(this.profile.lang)
      this.qualityControlOptions = ExportOfferUtils.getControlQualityOptions(this.profile.lang)
      this.marksOptions = ExportOfferUtils.getMarksOptions(this.profile.lang)
      this.formGroup.controls['grossKilos'].setValue(this.exportOffer.grossKilos)
      this.formGroup.controls['boxType'].setValue(this.exportOffer.boxType)
      this.formGroup.controls['caseSize'].setValue(this.exportOffer.caseSize)
      this.formGroup.controls['sizeMillimeters'].setValue(this.exportOffer.sizeMillimeters)
      this.formGroup.controls['category'].setValue(this.exportOffer.category)
      this.formGroup.controls['qualityControlDate'].setValue(this.exportOffer.qualityControlDate ? new Date(this.exportOffer.qualityControlDate) : null)
      this.formGroup.controls['isQualityControl'].setValue(this.exportOffer.isQualityControl)
      this.formGroup.controls['basePallets'].setValue(this.exportOffer.basePallets)
      this.formGroup.controls['marks'].setValue(this.exportOffer.marks)
      this.loading = false      
      this.showUploadQualityDoc()
    }
  }

  save() {
    this.loading = true
    if(this.formGroup.valid) {
      if(this.exportOffer) {

        if (this.qualityDocument) {
          this.selectedQualityDocument = this.qualityDocument[0]
        }

        let isQualityControlValue = this.formGroup.controls['isQualityControl'].value

        if (isQualityControlValue == true && this.selectedQualityDocument?.name == undefined && this.exportOffer.documents !== undefined && this.exportOffer.documents?.length <= 0) {
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOffer.DocumentQualityError')})
          this.loading = false
          return;
        }

        let request: ExportOfferAddtionalInfo = {
          guid: this.exportOffer.guid,
          basePallets: this.formGroup.controls['basePallets'].value,
          boxType: this.formGroup.controls['boxType'].value,
          caseSize: this.formGroup.controls['caseSize'].value,
          category: this.formGroup.controls['category'].value,
          color: this.exportOffer.color,
          grossKilos: this.exportOffer.grossKilos,
          id: this.exportOffer.id,
          isQualityControl: isQualityControlValue,
          kilosPerBox: this.exportOffer.kilosPerBox,
          marks: this.formGroup.controls['marks'].value,
          qualityControlDate: this.formGroup.controls['qualityControlDate'].value,
          sizeMillimeters: this.formGroup.controls['sizeMillimeters'].value,
          qualityDocument: isQualityControlValue && this.qualityDocument[0] ? this.qualityDocument[0] : null
        }

        this._exportOfferService.updateExportOfferAdditionalInfo(request).subscribe({
          next: (v) => {
            this._messageService.add({ severity: 'success',
              summary: this.translate.instant('MessageService.Summary.SuccessAction'),
              detail: this.translate.instant('MessageService.ExportOffer.EditSuccess') })
            this.loading = false
            this._router.navigate(['/' + Routes.userClosedSales])
          },
          error: (e) => {
            this.loading = false
            console.log(e)
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.ExportOffer.EditError') });
          },
          complete: () => console.info('Export Offer update successfully')
        })
      }
    }
  }

  showOfferDetailDialog() {
    if(this.profile && this.exportOffer) {
      this._exportOfferService.getById(this.exportOffer.id).subscribe({
        next: (v) => {
          this.selectedExportOffer = v
          if(this.selectedExportOffer.productCertificateTypes)
            this.selectedProductCertificateTypes = this.selectedExportOffer.productCertificateTypes

          if(this.selectedExportOffer.packagingTypes)
            this.selectedPackagingTypes = this.selectedExportOffer.packagingTypes

          this.loading = false
          this.visibleOfferDetailDialog = true
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOffer.ManyRetrieveError') })
        },
        complete: () => console.info('Export Offers query successfully')
      })
    }
  }

  onSelect(event: any, uploader: any){
    this.qualityDocument = uploader.files
  }

  onRemove(event: any, uploader: any) {
    this.qualityDocument = uploader.files
  }

  onClear(event: any, uploader: any) {
    this.qualityDocument = uploader.files
  }

  getExportOfferQualityDocument(documents: any[] | undefined) {
    if (documents == undefined || documents.length <= 0) return;

    let docId = documents[0].id

    this._documentService.getExportOfferDocument2(docId).subscribe((resultBlob: any) => {
      var downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }

  showUploadQualityDoc() {
    this.buttonQualityDoc = false
    if (this.formGroup.controls['isQualityControl'].value) {
      this.buttonQualityDoc = true
    }
  }

}
