<app-user-dashboard [userMenuActiveItemIndex]="0" [activeItem]="'forwarderReviews'">
    <p-card>
        <ng-template pTemplate="header">
            <div class="flex justify-content-between flex-wrap align-items-center" style="padding: 15px;">
                <h2>Ordenes Forwarder</h2>
            </div>

            <div class="col-12 flex justify-content-end flex-wrap align-items-center gap-1" style="padding: 15px;">
                <button type="button" pButton pRipple  icon="pi pi-file-excel" label="Cargar desde excel" (click)="visibleUploadExcel = true" ></button>
                <p-button label="Invitar a Fruzty" icon="pi pi-user" styleClass="p-button-secondary" (onClick)="visibleInvite = true"></p-button>
            </div>
        </ng-template>
        <ng-template pTemplate="body">
            <p-table [value]="orders" dataKey="ot" sortField="ot" sortMode="single" rowGroupMode="subheader" groupRowsBy="ot" [tableStyle]="{'min-width': '60rem'}">
                <ng-template pTemplate="header">
                    <tr>
                        <th>
                            <div class="flex justify-content-center align-items-center">
                                {{'UserPages.UserLoadOrders.MainTable.OrderHeader' | translate}}
                            </div>
                        </th>
                        <th>
                            <div class="flex justify-content-center align-items-center">
                                {{'UserPages.UserLoadOrders.MainTable.ContainerHeader' | translate}}
                                <p-columnFilter type="text" field="container" display="menu" [showAddButton]="false" [showMatchModes]="false" [showOperator]="false"></p-columnFilter>
                            </div>
                        </th>
                        <th>
                            <div class="flex justify-content-center align-items-center">
                                {{'UserPages.UserLoadOrders.MainTable.OriginPortHeader' | translate}}
                            </div>
                        </th>
                        <th>
                            <div class="flex justify-content-center align-items-center">
                                {{'UserPages.UserLoadOrders.MainTable.DestinationPortHeader' | translate}}
                            </div>
                        </th>
                        <th>
                            <div class="flex justify-content-center align-items-center">
                                {{'UserPages.UserLoadOrders.MainTable.AssignHeader' | translate}}
                            </div>
                        </th>
                        <th>
                            <div class="flex justify-content-center align-items-center">
                                {{'UserPages.UserLoadOrders.MainTable.StatusHeader' | translate}}
                            </div>
                        </th>
                        <th>
                            <div class="flex justify-content-center align-items-center">
                                {{'UserPages.UserLoadOrders.MainTable.WeeksHeader' | translate}}
                            </div>
                        </th>
                        <th>
                            <div class="flex justify-content-center align-items-center">
                                {{'UserPages.UserLoadOrders.MainTable.ETDHeader' | translate}}
                            </div>
                        </th>
                        <th>
                            <div class="flex justify-content-center align-items-center">
                                {{'UserPages.UserLoadOrders.MainTable.ETAHeader' | translate}}
                            </div>
                        </th>
                        <th>
                            <div class="flex justify-content-center align-items-center">
                                {{'UserPages.UserLoadOrders.MainTable.DetailHeader' | translate}}
                            </div>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="groupheader" let-order let-rowIndex="rowIndex" let-expanded="expanded">
                    <tr>
                        <td colspan="6">
                            <div class="flex justify-content-start align-items-center">
                                <button type="button" pButton pRipple [pRowToggler]="order" class="p-button-text p-button-rounded p-button-plain mr-2" [style]="{'color': '#fff', 'background-color': '#672572','width': '1.5rem', 'height': '1.5rem', 'margin': '0 5px'}"  [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                                <span class="font-bold ml-2">{{ order.ot }} - Shipper ({{ order.clientName }})</span>
                            </div>
                        </td>
                        <td colspan="6">
                            <!-- TODO: Agregar componente de selección de importador-exportador -->
                            <!-- <button type="button" pButton pRipple label="Asignar Importador/Exportador"></button> -->
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="rowexpansion" let-order let-rowIndex="rowIndex" let-rowData.viewDetails="false">
                    <tr>
                        <td>
                            <div class="flex justify-content-center align-items-center">
                                <span>{{ order.sequence }}</span>
                            </div>
                        </td>
                        <td>
                            <div class="flex justify-content-center align-items-center">
                                <span>{{ order.container ? order.container : getDefaultLoadingInfo() | translate }}</span>
                            </div>
                        </td>
                        <td>
                            <div class="flex justify-content-center align-items-center">
                                <span>
                                    {{ order.originPort ? order.originPort?.name : getDefaultLoadingInfo() | translate }}<br>{{ order.originPort ? (order.originPort?.country | countryDescription) : ''}}
                                </span>
                            </div>
                        </td>
                        <td>
                            <div class="flex justify-content-center align-items-center">
                                <span>
                                    {{ order.destinationPort ? order.destinationPort?.name : getDefaultLoadingInfo() | translate }}<br>{{ order.destinationPort ? (order.destinationPort?.country | countryDescription) : ''}}
                                </span>
                            </div>
                        </td>
                        <td>
                            <div class="flex justify-content-center align-items-center">
                                <button type="button" pButton pRipple label="Asignar Importador/Exportador" (click)="openAssignImporterExporter(order)"></button>
                                
                                <!-- <span>{{ order.totalPallets }}</span> -->
                                <!-- <span>{{ getFirstPalletsConfirmed(order) }}</span> -->
                            </div>
                        </td>
                        <td>
                            <div class="flex justify-content-center align-items-center">
                                <p-tag *ngIf="order.status != null && statuses[order.status]" [value]="statuses[order.status].label" [severity]="getStatusSeverity(order.status)" [icon]="getStatusIcon(order.status)">
                                </p-tag>
                            </div>
                        </td>
                        <td>
                            <div class="flex justify-content-center align-items-center">
                                <span>{{ order.week ? order.week : getDefaultLoadingInfo() | translate }}</span>
                            </div>
                        </td>
                        <td>
                            <div class="flex justify-content-center align-items-center">
                                <span>{{ order.etd ? (order.etd | date: 'dd-MM-yyyy') : getDefaultLoadingInfo() | translate }}</span>
                            </div>
                        </td>
                        <td>
                            <div class="flex justify-content-center align-items-center">
                                <span>{{ order.eta ? (order.eta | date: 'dd-MM-yyyy') : getDefaultLoadingInfo() | translate }}</span>
                            </div>
                        </td>
                        <td>
                            <div class="flex justify-content-center align-items-center">
                              <span style="color: #672572; font-weight: bold">{{'UserPages.UserLoadOrders.Products' | translate}}</span>
                              <i (click)="order.viewDetails = !order.viewDetails" [ngClass]="order.viewDetails ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" [style]="{'color': '#fff', 'background-color': '#672572','width': '1.5rem', 'height': '1.5rem', 'margin': '0 5px', 'border-radius': '50%', 'display': 'flex', 'justify-content': 'center', 'align-items': 'center', 'cursor': 'pointer'}" ></i>
                            </div>
                        </td>
                    </tr>
                    <ng-container *ngTemplateOutlet="extensiontemplate; context: order"></ng-container>
                    <ng-template #extensiontemplate>
                        <tr *ngIf="order.viewDetails">
                            <td colspan="12">
                                <div class="p-3">
                                    <p-table [value]="order.details" dataKey="id">
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th pSortableColumn="varietyName">{{'UserPages.UserLoadOrders.SubTable.Specie/VarietyHeader' | translate}} <p-sortIcon field="varietyName"></p-sortIcon></th>
                                                <th pSortableColumn="palletsQuantity">{{'UserPages.UserLoadOrders.SubTable.PalletsHeader' | translate}} <p-sortIcon field="palletsQuantity"></p-sortIcon></th>
                                                <th pSortableColumn="boxesPerPallet">{{'UserPages.UserLoadOrders.SubTable.BoxHeader' | translate}} <p-sortIcon field="boxesPerPallet"></p-sortIcon></th>
                                                <th>{{'UserPages.UserLoadOrders.SubTable.ImporterHeader' | translate}}</th>
                                                <th>{{'UserPages.UserLoadOrders.SubTable.ExporterHeader' | translate}}</th>
                                                <th pSortableColumn="status">{{'UserPages.UserLoadOrders.SubTable.StatusHeader' | translate}} <p-sortIcon field="status"></p-sortIcon></th>
                                                <th>{{'UserPages.UserLoadOrders.SubTable.ActionHeader' | translate}}</th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-detail>
                                            <tr>
                                                <td>
                                                    <div class="flex justify-content-start align-items-center">
                                                        <img *ngIf="detail.varietyUrlIcon" [src]="detail.varietyUrlIcon" style="border-radius: 7px; width: 100%; max-width: 50px; height: auto;" alt="Url varietyIcon"/>
                                                        <span *ngIf="detail.varietyName">{{getSpeciesName(detail)}}<br>{{ detail.varietyName }}</span>
                                                        <span *ngIf="!detail.varietyUrlIcon">{{getDefaultLoadingInfo() | translate}}</span>
                                                    </div>
                                                </td>
                                                <td>{{ detail.palletsQuantity }}</td>
                                                <td>{{ detail.boxesPerPallet }}</td>
                                                <td>{{ order.importer?.id < 0 ? (getDefaultLoadingInfo() | translate) : order.importer?.contactName}}</td>
                                                <td>{{ order.exporter?.companyName ?? order.clientName }}</td>
                                                <td>
                                                    <div class="flex justify-content-start align-items-center">
                                                        <p-tag *ngIf="detail.status == 1" value="Pend. Export." severity="warning" icon="pi pi-exclamation-triangle"></p-tag>
                                                        <p-tag *ngIf="detail.status == 2" value="Pend. QC" severity="warning" icon="pi pi-exclamation-triangle"></p-tag>
                                                        <p-tag *ngIf="detail.status == 3" value="Verif. Partial" severity="success" icon="pi pi-check"></p-tag>
                                                        <p-tag *ngIf="detail.status == 4" value="Verif." severity="success" icon="pi pi-check"></p-tag>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="p-fluid grid flex justify-content-evenly align-items-center">
                                                        <p-tag pTooltip="{{'UserPages.UserLoadOrders.SubTable.EditToolTipText' | translate}}" severity="warning" [style]="{'margin': '0 auto', 'color': '#fff', 'cursor': 'pointer', 'height': '40px', 'width': '40px' }" (click)="redirectToDocumentation(order.id)">
                                                          <div class="flex align-items-center gap-2">
                                                            <i class="pi pi-pencil text-md"></i>
                                                          </div>
                                                        </p-tag>
                                                        <p-tag pTooltip="{{'UserPages.UserLoadOrders.SubTable.ViewToolTipText' | translate}}" severity="success" [style]="{'margin': '0 auto', 'color': '#fff', 'cursor': 'pointer', 'height': '40px', 'width': '40px' }" (click)="redirectToDetail(order.id)">
                                                            <div class="flex align-items-center gap-2">
                                                              <i class="pi pi-eye text-md"></i>
                                                            </div>
                                                        </p-tag>
                                                        <p-tag pTooltip="{{'UserPages.UserLoadOrders.SubTable.DocumentToolTipText' | translate}}" [style]="{'background-color': '#CAD0D6', 'margin': '0 auto', 'color': '#fff', 'cursor': 'pointer', 'height': '40px', 'width': '40px' }" (click)="openDocuments(order.id)">
                                                            <div class="flex align-items-center gap-2">
                                                              <i class="pi pi-file text-md"></i>
                                                            </div>
                                                        </p-tag>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </ng-template>
            </p-table>
        </ng-template>
    </p-card>
</app-user-dashboard>

<p-toast></p-toast>
<p-confirmDialog [style]="{width: '30vw'}"></p-confirmDialog>

<p-dialog [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleUploadExcel" [style]="{width: '80%'}">
    <h3>Cargar desde Excel</h3>
    <p-fileUpload name="sheetPdf[]"
        #imagesUploader
        (onSelect)="onSelect($event, imagesUploader)"
        (onRemove)="onRemove($event, imagesUploader)"
        (onClear)="onClear($event, imagesUploader)"
        [showUploadButton]="false"
        [multiple]="false"
        accept=".xlsx"
        [fileLimit]="1"
        [files]="uploadFile"
        [maxFileSize]="3000000"
        chooseLabel="Seleccionar">
        <ng-template pTemplate="toolbar">
        </ng-template>
        <ng-template pTemplate="content">
        </ng-template>
    </p-fileUpload>
    <ng-template pTemplate="footer">
        <p-button label="{{ 'UserPages.UserExportOffers.NewExportOffer.SaveButtonLabel' | translate }}" (onClick)="sendFiles()" [loading]="loading"></p-button>
    </ng-template>
</p-dialog>

<p-dialog header="{{'UserPages.UserClosedSale.ModalTitle' | translate}}" [(visible)]="visibleInvite" [style]="{width: '50vw'}">
    <p>{{'UserPages.UserClosedSale.ModalDescriptionText' | translate}}</p>
    <form [formGroup]="userJoinRequestFormGroup">
      <div class="p-fluid formgrid grid">
        <div class="field col-12" style="margin-bottom: 40px;">
          <label for="guestEmail">{{'UserPages.UserClosedSale.EmailModalFieldLabel' | translate}} *</label>
          <input pInputText inputId="guestEmail" formControlName="guestEmail" placeholder="{{'UserPages.UserClosedSale.EmailModalFieldPlaceholder' | translate}}"/>
          <small *ngIf="submit && userJoinRequestFormGroup.controls['guestEmail'].invalid" class="p-error">{{getErrorMessage('guestEmail')}}</small>
        </div>
      </div>
    </form>
    <ng-template pTemplate="footer">
      <p-button label="{{'UserPages.UserClosedSale.CancelModalButton' | translate}}" (onClick)="visibleInvite = false" [loading]="loading" styleClass="p-button-outlined"></p-button>
      <p-button label="{{'UserPages.UserClosedSale.SendModalButton' | translate}}" (onClick)="saveUserJoinRequest()" [loading]="loading"></p-button>
    </ng-template>
</p-dialog>

<p-dialog [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleUploadDocuments" [style]="{width: '80%'}">
    <h3>{{ 'ForwarderPage.ForwarderUserOriginReview.TitleDocuments' | translate }}</h3>
    <div class="p-fluid formgrid grid">
        <div class="field col-4">
        <label style="font-weight: bolder;">{{'ForwarderPage.ForwarderUserOriginReview.OperationDocumentsTitle' | translate }} *</label>
        <p-dropdown [options]="operationDocumentTypes" placeholder="{{ 'ForwarderPage.ForwarderUserOriginReview.OperationDocumentsTitle' | translate}}" (onChange)="onSelectDocumentType($event)" styleClass="mb-2" optionLabel="description" optionValue="id" [disabled]="loading"></p-dropdown>
        </div>
        <div class="field col-12">
            <p-fileUpload #fileUpload
                (onSelect)="onUploadDocumentPdf(fileUpload)"
                [showUploadButton]="false"
                [multiple]="false"
                accept=".pdf,.xls,.xlsx"
                [maxFileSize]="5000000"
                [disabled]="documentTypeId === '' || loading"
                chooseLabel="{{'OrderDetailTabComponent.Form.SelectFile' | translate}}"
                cancelLabel="{{'OrderDetailTabComponent.Form.CancelSelectFile' | translate}}">
            </p-fileUpload>
        </div>
        <div class="field col-12">
        <p-table
            [value]="documents"
            responsiveLayout="stack"
            [breakpoint]="'600px'"
            [scrollable]="true"
            scrollHeight="30vh">
            <ng-template pTemplate="header">
                <tr>
                <th><div class="flex justify-content-center align-items-center">{{ 'ForwarderPage.ForwarderUserOriginReview.TableDocuments.DocumentName' | translate }}</div></th>
                <th><div class="flex justify-content-center align-items-center">{{ 'ForwarderPage.ForwarderUserOriginReview.TableDocuments.OperationDocumentsTitle' | translate }}</div></th>
                <th><div class="flex justify-content-center align-items-center">{{ 'ForwarderPage.ForwarderUserOriginReview.TableDocuments.DocumentSize' | translate }}</div></th>
                <th><div class="flex justify-content-center align-items-center">{{ 'ForwarderPage.ForwarderUserOriginReview.TableDocuments.DocumentUser' | translate }}</div></th>
                <th><div class="flex justify-content-center align-items-center">{{ 'ForwarderPage.ForwarderUserOriginReview.TableDocuments.CreatedDate' | translate }}</div></th>
                <th><div class="flex justify-content-center align-items-center">{{ 'ForwarderPage.ForwarderUserOriginReview.TableDocuments.ActionsHeader' | translate }}</div></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-document>
                <tr>
                    <td style="cursor: pointer; color: #6E9F41" (click)="getImportOrderDetailDocument(document)"><div class="flex justify-content-start"><span class="pi pi-file"></span>{{ document.name }}</div></td>
                    <td><div class="flex justify-content-center">{{ document.operationDocumentType | documentDescription }}</div></td>
                    <td><div class="flex justify-content-center">{{ document.docLength / 1000 }} KB</div></td>
                    <td><div class="flex justify-content-center"><span class="pi pi-user"></span>{{ document.createdBy }}</div></td>
                    <td><div class="flex justify-content-end">{{ document.created | date:'dd-MM-yyyy HH:mm' }}</div></td>
                    <td><div class="flex justify-content-end"><p-button label="{{ 'ForwarderPage.ForwarderUserOriginReview.ButtonDelete' | translate }}" icon="pi pi-trash" styleClass="p-button-danger" (onClick)="deleteDocument(document)"></p-button></div></td>
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                <div class="flex align-items-center justify-content-between" *ngIf="documents.length === 0">
                    {{ 'DocumentComponent.EmptyResult' | translate }}
                </div>
            </ng-template>
        </p-table>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <p-button label="{{ 'CommonLabels.Buttons.CloseButton' | translate }}" (onClick)="visibleUploadDocuments = false" [loading]="loading"></p-button>
    </ng-template>
</p-dialog>

<p-dialog [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleRelations" [style]="{width: '50%'}">
    <ng-template pTemplate="header">
        <h3>Asignación de Importador y Exportador</h3>
    </ng-template>
    <div class="p-fluid formgrid mr-2 ml-2 grid" style="margin-top: 40px;">
        <div class="field col-12 md:col-6 lg:col-6" style="margin-bottom: 40px;">
            <p-dropdown
                inputId="importers"
                [options]="forwarderRelations?.importers || []"
                optionLabel="contactName"
                optionValue="id"
                [showClear]="true"
                placeholder="Importadores"
                appendTo="body"
                (onClick)="setImporter($event)"
                [(ngModel)]="importerSelected"
            ></p-dropdown>
        </div>
        <div class="field col-12 md:col-6 lg:col-6">
            <p-dropdown
                inputId="exporters"
                [options]="forwarderRelations?.exporters || []"
                optionLabel="companyName"
                optionValue="id"
                [showClear]="true"
                placeholder="Exportadores"
                appendTo="body"
                (onClick)="setExporter($event)"
                [(ngModel)]="exporterSelected"
            ></p-dropdown>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <p-button label="{{ 'CommonLabels.Buttons.AssignButton' | translate }}" (onClick)="assignImporterExporter()" [loading]="loading"></p-button>
    </ng-template>
</p-dialog>
