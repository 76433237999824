<app-user-dashboard [userMenuActiveItemIndex]="0" [activeItem]="'export-offers'">

  <p-tabView>
    <p-tabPanel header="{{'ProgramTab.ProgramTabView' | translate}}">
      <p-card>
        <ng-template pTemplate="header">
          <div style="padding: 15px;">
            <h1>{{'UserPages.UserClosedSale.ImporterClosedSaleOrders.PageTitle' | translate}}</h1>
            <p>{{'UserPages.UserClosedSale.ImporterClosedSaleOrders.PageDescriptionText' | translate}}</p>
            <div class="grid" style="margin-top: 30px">
              <div class="col-2" >
                <div class="col-12 card-summary">
                  <div class="grid">
                    <div class="col-12 md:col-12">
                      <p class="summary-title">{{'ImportCloseSaleDetail.TotalAmountSummary' | translate}}</p>
                    </div>
                    <div class="col-12 md:col-12">
                      <div class="grid">
                        <div class="col-12 flex justify-content-between align-items-end">
                          <span class="material-symbols-outlined icon-style" style="margin-left: 5px;">
                            monetization_on
                            </span>
                            <span class="summary-data"> 
                              {{totalPrice | currency: 'USD':'US$'}}
                            </span>
                        </div>
                        <div class="col-12 flex justify-content-end">
                          <span style="font-size: 10px; color: #797E83; margin-right: 5px;" >
                            USD({{'ImportCloseSaleDetail.DolarSummary' | translate}})
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-2">
                <div class="col-12 card-summary">
                  <div class="grid">
                    <div class="col-12 md:col-12">
                      <p class="summary-title">{{'ImportCloseSaleDetail.TotalBoxesSummary' | translate}}</p>
                    </div>
                    <div class="col-12 md:col-12">
                      <div class="grid">
                        <div class="col-12 flex justify-content-between align-items-end">
                          <span class="material-symbols-outlined icon-style" style="margin-left: 5px;">
                            inventory_2
                            </span>
                            <span class="summary-data"> 
                              {{totalBoxes}}
                            </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-2">
                <div class="col-12 card-summary">
                  <div class="grid">
                    <div class="col-12 md:col-12">
                      <p class="summary-title">{{'ImportCloseSaleDetail.TotalPalletsSummary' | translate}}</p>
                    </div>
                    <div class="col-12 md:col-12">
                      <div class="grid">
                        <div class="col-12 flex justify-content-between align-items-end">
                          <span class="material-symbols-outlined icon-style" style="margin-left: 5px;">
                            pallet
                            </span>
                            <span class="summary-data"> 
                              {{totalPallets}}
                            </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-2">
                <div class="col-12 card-summary">
                  <div class="grid">
                    <div class="col-12 md:col-12">
                      <p class="summary-title">{{'ImportCloseSaleDetail.TotalContainers' | translate}}</p>
                    </div>
                    <div class="col-12 md:col-12">
                      <div class="grid">
                        <div class="col-12 flex justify-content-between align-items-end">
                          <span class="material-symbols-outlined icon-style" style="margin-left: 5px;">
                            package_2
                            </span>
                            <span class="summary-data"> 
                              {{totalContainers}}
                            </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-2">
                <div class="col-12 card-summary">
                  <div class="grid">
                    <div class="col-12 md:col-12">
                      <p class="summary-title">{{'ImportCloseSaleDetail.TotalExporters' | translate}}</p>
                    </div>
                    <div class="col-12 md:col-12">
                      <div class="grid">
                        <div class="col-12 flex justify-content-between align-items-end">
                          <span class="material-symbols-outlined icon-style" style="margin-left: 5px;">
                            person
                            </span>
                            <span class="summary-data"> 
                              {{totalExporters}}
                            </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="grid p-fluid">
              <div class="col-5">
                <p-calendar class="max-w-full"
                  #calendar
                  [inline]="true"
                  [showWeek]="true"
                  [firstDayOfWeek]="1"
                  (onMonthChange)="onMonthChange($event)"
                  [readonlyInput]="true"
                  >
                  <ng-template pTemplate="date" let-date>
                    <span *ngIf="getWeekNumber(date.day, date.month, date.year)" style="text-align: center;"  class="inWeekRange">
                      {{date.day}}
                    </span>
                    <span *ngIf="!getWeekNumber(date.day, date.month, date.year)" style="text-align: center;">
                      {{date.day}}
                    </span>
                </ng-template>
                </p-calendar>
              </div>
              <div class="col-7">
                <p-card [header]="translate.instant('UserPages.UserClosedSale.ImporterClosedSaleOrders.CardTitle') + ' '+ selectedMonthText + ' ' + year">
    
                  <h2>{{'UserPages.UserClosedSale.ImporterClosedSaleOrders.FilterForText' | translate}}:</h2>
                  <form [formGroup]="filterFormGroup">
                    <div class="grid p-fluid">
                      <div class="col-4">
                        <label>{{'UserPages.UserClosedSale.ImporterClosedSaleOrders.FilterFieldPerWeekLabel' | translate}}</label>
                        <p-inputNumber formControlName="week" placeholder="{{'UserPages.UserClosedSale.ImporterClosedSaleOrders.FilterFieldPerWeekPlaceholder' | translate}}"/>
                      </div>
                      <div class="col-4 flex justify-content-start align-items-end">
                        <p-button (click)="sidebarVisible = true" [style]="{'margin': '0px'}"
                        label="{{'ImportCloseSaleDetail.FilterTextButton' | translate}}" class="p-inputtext-sm" styleClass="p-button-outlined">
                        <span class="material-symbols-outlined" style="margin-right: 6px;">
                          add
                          </span>
                        </p-button>
                      </div>
                      <div class="col-6" style="margin-top: 40px;"><p-button label="{{'UserPages.UserClosedSale.ImporterClosedSaleOrders.CleanFilterButton' | translate}}" (onClick)="onClearFilter()" styleClass="p-button-outlined"></p-button></div>
                      <div class="col-6" style="margin-top: 40px;"><p-button label="{{'UserPages.UserClosedSale.ImporterClosedSaleOrders.ApplyFilterButton' | translate}}" (onClick)="onFilter()"></p-button></div>
                    </div>
                  </form>
    
                </p-card>
              </div>
            </div>
          </div>
        </ng-template>
    
        <p-dataView [value]="weekDtos" [loading]="loading" [rows]="20" [paginator]="true" emptyMessage="{{'UserPages.UserClosedSale.ImporterClosedSaleOrders.EmptyResultsText' | translate}}" filterBy="week">
          <ng-template let-dto pTemplate="listItem">
            <div class="col-12">
              <h2>{{'UserPages.UserClosedSale.ExporterClosedSaleOrders.WeekFieldText' | translate}}: {{dto.week}} - {{dto.year}}</h2>
              <p-dataView #dv
              [value]="dto.importOrdersDtos"
              [rows]="20"
              [paginator]="false"
              [loading]="loading"
              emptyMessage="{{'UserPages.UserClosedSale.ExporterClosedSaleOrders.EmptyResultsText' | translate}}"
              >
              <ng-template pTemplate="header">
              </ng-template>
              <ng-template let-dto pTemplate="listItem">
                <div class="col-12">
                  <p-card [style]="{'margin-bottom': '40px'}">
                    <div class="grid">
                      <div class="col-12 md:col-6 flex justify-content-between align-items-center" style="padding-right: 20px; padding-bottom: 20px; border-right: 1px solid #ccc; border-bottom: 1px solid #ccc;">
                        <img [src]="getImageUrl(dto.details[0].exportOffer)" style="border-radius: 7px; width: 100%; max-width: 100px; height: auto;" alt="" *ngIf="dto.details[0].exportOffer.offerPictures.length > 0"/>
                        <span style="border-radius: 7px; padding: 5px; font-weight: bold;" *ngIf="dto.details[0].exportOffer.offerPictures.length < 1">{{'UserPages.UserExportOffers.NotAvailablePictureLabel' | translate}}</span>
                        <div>
                          <h2 style="text-transform: uppercase; font-size: medium;">{{dto.details[0].exportOffer.variety.species | speciesName}}</h2>
                          <span style="text-transform: uppercase; font-size: medium;">{{dto.details[0].exportOffer.variety.name}}</span>
                        </div>
                        <span style="font-weight: bolder; color: #8c2b87;">{{'ImportCloseSaleTable.CodeText' | translate}}: {{dto.importOrder.sequence}}</span>
                        <div class="flex align-items-center">
                          <span class="material-symbols-outlined" style="color: #8c2b87">package_2</span>
                          <span style="margin-left: 10px;">{{dto.details[0].palletsQuantity * dto.details[0].exportOffer.caseQuantityPerPallet}}</span>
                        </div>
                        <div class="flex align-items-center">
                          <p-button
                          [style]="{'font-size': 'small'}" 
                          label="{{'UserPages.UserClosedSale.ExporterClosedSaleOrders.SeeOrderButton' | translate}}" 
                          icon="pi pi-shopping-bag" (onClick)="goToOrderDetailPage(dto.importOrder.guid)"
                          >
                          </p-button>
                        </div>
                      </div>
                      <div class="col-12 md:col-6 flex justify-content-between align-items-center" style="padding-right: 20px; padding-bottom: 20px; border-right: 1px solid #ccc; border-bottom: 1px solid #ccc;">
                        <div class="col-12 md:col-8 flex justify-content-between align-items-center">
                          <div class="flex justify-content-start align-items-center">
                            <img [src]="'../../../../../assets/img/flags/' + dto.importOrder.importFromCountry.code + '.png'" height="40px" alt=""/>
                            <div class="flex flex-column" style="margin-left: 10px;">
                              <span style="font-weight: bolder; font-size: small;">{{dto.importOrder.importFromCountry.name}}</span>
                              <!-- <span style="font-size: small;" *ngIf="!dto.importOrder.portFrom">{{'ImportCloseSaleTable.PortOriginText' | translate}} (?)</span> -->
                              <span style="font-size: small;" *ngIf="dto.importOrder.portFrom">{{dto.importOrder.portFrom.name}}</span>
                            </div>
                          </div>
                          <span class="material-symbols-outlined" style="color: #6da043;">directions_boat</span>
                          <span class="material-symbols-outlined" style="color: #6da043;">arrow_forward_ios</span>
                          <div class="flex justify-content-start align-items-center">
                            <img [src]="'../../../../../assets/img/flags/' + dto.importOrder.importToCountry.code + '.png'" height="40px" alt=""/>
                            <div class="flex flex-column" style="margin-left: 10px;">
                              <span style="font-weight: bolder; font-size: small; ">{{dto.importOrder.importToCountry.name}}</span>
                              <span style="font-size: small;">{{dto.importOrder.portTo.name}}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 md:col-4 flex justify-content-between align-items-center">
                          <div class="flex justify-content-end">
                            
                            <p-button 
                              icon="pi pi-shopping-bag" 
                              [style]="{'font-size': 'small'}"
                              label="{{'UserPages.UserClosedSale.ImporterClosedSaleOrders.SeeExportOfferButton' | translate}}" 
                              (onClick)="viewExportOfferDetailDialog(dto.details[0])" 
                              styleClass="p-button-secondary">
                            </p-button>
                          </div>
                          <div style="margin-left: 20px;">
                            <p-button 
                            [icon]="dto.importOrder.expanded ? 'pi pi-angle-up' : 'pi pi-angle-down'" 
                            styleClass="p-button-rounded p-button-outlined"
                            (click)="setVisibleOrderDetail(dto.importOrder)"
                            >
                            </p-button>
                          </div>
                        </div>
                      </div>
                      <div  *ngIf="dto.importOrder.expanded" class="col-12 md:col-6">
                        <div class="grid">
                          <div class="col-12">
                            <span class="section-title">{{'ImportCloseSaleTable.ProductDetailText' | translate}}</span>
                          </div>
                          <div class="col-6  flex flex-row align-items-center">
                            <span class="material-symbols-outlined info-icon">
                              inventory_2
                              </span>
                              <span class="info-text">
                                {{'ImportCloseSaleTable.CasePerPalletsText' | translate}}: 
                                {{dto.details[0].exportOffer.caseQuantityPerPallet ? dto.details[0].exportOffer.caseQuantityPerPallet : 'Not included'}}
                              </span>
                          </div>
                          <div class="col-6 flex flex-row align-items-center">
                            <span class="material-symbols-outlined info-icon">
                              location_on
                              </span>
                              <span class="info-text">
                                {{'ImportCloseSaleTable.LocationText' | translate}}:
                                {{dto.details[0].fridgeStorage ? 
                                  dto.details[0].fridgeStorage.contactCity + ',' +  
                                  dto.details[0].fridgeStorage.contactAddress : 
                                  'No included'}}
                              </span>
                          </div>
                          <div class="col-6  flex flex-row align-items-center">
                              <span class="material-symbols-outlined info-icon" >
                                monetization_on
                                </span>
                                <span class="info-text">
                                  {{'ImportCloseSaleTable.ListPriceText' | translate}}:
                                  <span *ngIf="dto.details[0].palletPrices.length == 1" class="info-text">
                                  {{dto.details[0].palletPrices[0].price | currency: 'USD':'US$'}}
                                </span>
                                <span class="info-text" *ngIf="dto.details[0].palletPrices.length == 0">
                                  {{'ImportCloseSaleTable.PriceNotEstablished' | translate}}
                                </span> 
                                </span>
                                <div *ngIf="dto.details[0].palletPrices.length > 1" class="see-prices" (click)="showCurrentPalletPriceModal(dto.details[0])"> 
                                  <span class="program-info" style="color: #6da043;">
                                    {{'ImportCloseSaleTable.SeePrices' | translate}}
                                  </span>
                                </div>
                                <!-- <p-button
                                *ngIf="dto.details[0].palletPrices.length > 1"
                                class="m-4"
                                styleClass="p-button-sm"
                                [style]="{'font-size': 'small'}"
                                (onClick)="showCurrentPalletPriceModal(dto.details[0])"
                                severity="secondary"
                                icon="pi pi-eye"
                                >
                                </p-button>  -->
                          </div>
                          <div class="col-6  flex flex-row align-items-center">
                            <span class="material-symbols-outlined info-icon" >
                              pallet
                              </span>
                              <span class="info-text">
                                {{'ImportCloseSaleTable.PalletText' | translate}}:
                                {{dto.details[0].palletsQuantity}}
                              </span> 
                          </div>
                          <div class="col-6 flex flex-row align-items-center">
                            <span class="material-symbols-outlined info-icon">
                              heart_check
                              </span>
                              <span class="info-text">
                                {{'ImportCloseSaleTable.ShelfLifeText' | translate}}:
                                {{dto.details[0].shelfLife ? dto.details[0].shelfLife : '-'}}
                              </span>
                          </div>
                          <div class="col-6 flex flex-row align-items-center">
                            <span class="material-symbols-outlined info-icon">
                              event
                              </span>
                              <span class="info-text">
                                {{'ImportCloseSaleTable.ReadyToLoadDateText' | translate}}:
                                {{dto.details[0].readyToLoadDate ? (dto.details[0].readyToLoadDate | date:'dd-MM-yyyy') : '-'}}
                              </span>
                          </div>
                          <div class="col-6 flex flex-row align-items-center">
                            <span class="material-symbols-outlined info-icon">
                              calendar_today
                              </span>
                              <span class="info-text">
                                {{'ImportCloseSaleTable.HarvestDateText' | translate}}:
                                {{dto.details[0].harvestDate ? (dto.details[0].harvestDate | date:'dd-MM-yyyy') : '-'}}
                              </span>
                          </div>
                          <div class="col-6 flex flex-row align-items-center">
                            <span class="material-symbols-outlined info-icon">
                              science
                              </span>
                              <span class="info-text" *ngIf="dto.details[0].exportOffer.isQualityControl">
                                {{'UserPages.UserClosedSale.ProductDetail.QualityControlCriteriaText' | translate}}:
                                 {{'UserPages.UserClosedSale.ProductDetail.Included' | translate}}
                              </span>
                              <span class="info-text" *ngIf="!dto.details[0].exportOffer.isQualityControl">
                                {{'UserPages.UserClosedSale.ProductDetail.QualityControlCriteriaText' | translate}}:
                                {{'UserPages.UserClosedSale.ProductDetail.NotIncluded' | translate}}
                              </span>
                          </div>
                          <div class="col-6 flex flex-row align-items-center">
                            <span class="material-symbols-outlined info-icon" >
                              weight
                              </span>
                              <span class="info-text">
                                {{'UserPages.UserClosedSale.ProductDetail.NetKilosText' | translate}}:
                                {{dto.details[0].exportOffer.netKilos ? dto.details[0].exportOffer.netKilos + ' kg' : '-' }}
                              </span>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="dto.importOrder.expanded" class="col-12 md:col-6">
                        <div class="grid" style="margin-left: 5px;">
                          <div class="col-5">
                            <span class="section-title">{{'ImportCloseSaleTable.AgreementText' | translate}}</span>
                            <div class="col-12 flex flex-row align-items-center">
                              <span class="material-symbols-outlined info-icon">
                                person
                                </span>
                              <span class="info-text-program">
                                {{'ImportCloseSaleTable.ExportadorText' | translate}}:
                              </span>
                              <span class="program-info">
                                <!-- {{dto.details[0].directSalePrograms[0].importerCompanyName}} -->
                                {{dto.details[0].exportOffer.exporter.companyName}}
                              </span>
                            </div>

                            <div *ngIf="dto.importOrder.directSaleProgram?.consigneeCompanyName" class="col-12 flex flex-row align-items-center">
                              <span class="material-symbols-outlined info-icon">
                                person
                                </span>
                              <span class="info-text-program">
                                Consignee:
                              </span>
                              <span class="program-info">
                                {{dto.importOrder.directSaleProgram.consigneeCompanyName}}
                              </span>
                            </div>

                            <div *ngIf="dto.importOrder.directSaleProgram?.notifyCompanyName" class="col-12 flex flex-row align-items-center">
                              <span class="material-symbols-outlined info-icon">
                                person
                                </span>
                              <span class="info-text-program">
                                Notify:
                              </span>
                              <span class="program-info">
                                {{ dto.importOrder.directSaleProgram.notifyCompanyName }}
                              </span>
                            </div>

                            <div *ngIf="dto.importOrder.forwarderCompany?.companyName" class="col-12 flex flex-row align-items-center">
                              <span class="material-symbols-outlined info-icon">
                                person
                                </span>
                              <span class="info-text-program">
                                Forwarder:
                              </span>
                              <span class="program-info">
                                {{ dto.importOrder.forwarderCompany.companyName }}
                              </span>
                            </div>

                            <div *ngIf="dto.importOrder.customAgent?.companyName" class="col-12 flex flex-row align-items-center">
                              <span class="material-symbols-outlined info-icon">
                                person
                                </span>
                              <span class="info-text-program">
                                Custom Agent:
                              </span>
                              <span class="program-info">
                                {{ dto.importOrder.customAgent.companyName }}
                              </span>
                            </div>

                          </div>
                          <div class="col-6">
                            <span class="section-title">{{'ImportCloseSaleTable.Week/Year' | translate}}</span>
                            <div class="col-12">
                              <div class="grid">
                                <div class="col-12">
                                    <div class="flex justify-content-start align-items-end">
                                      <span class="material-symbols-outlined info-icon">
                                        calendar_today
                                      </span>
                                        <span class="program-info">{{dto.importOrder.closedSaleWeek}} - {{dto.importOrder.closedSaleWeekYear}}</span>
                                    </div>
                                </div>
                              </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  </p-card>
                </div>
              </ng-template>
              </p-dataView>
            </div>
          </ng-template>
        </p-dataView>
      </p-card>
    </p-tabPanel>
    </p-tabView>

</app-user-dashboard>

<p-toast></p-toast>

<p-dialog [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleExportOfferDialog" [style]="{width: '80%'}">
  <app-export-offer-detail [offer]="exportOffer" [productCertificateTypes]="selectedProductCertificateTypes" [packagingTypes]="selectedPackagingTypes"></app-export-offer-detail>
  <ng-template pTemplate="footer">
    <p-button label="{{'UserPages.UserClosedSale.ImporterClosedSaleOrders.CloseButton' | translate}}" (click)="visibleExportOfferDialog = false"></p-button>
  </ng-template>
</p-dialog>

<p-dialog header="{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.PalletPriceModal.CurrentPricesHeader' | translate}}" [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleCurrentPalletsPrice" [style]="{width: '800px'}">
  <p-table
  [value]="currentPrices"
  [paginator]="true"
  [rows]="10"
  [loading]="loading"
  responsiveLayout="stack"
  [breakpoint]="'600px'"
  [showCurrentPageReport]="true"
  [rowsPerPageOptions]="[10, 25, 50]"
  >
  <ng-template pTemplate="header">
    <tr>
      <th>
        {{'UserPages.UserPurchaseOrders.VerifyPalletsPage.PalletPriceModal.CategoryHeader' | translate}}
      </th>
      <th>
        {{'UserPages.UserPurchaseOrders.VerifyPalletsPage.PalletPriceModal.SpeciesSizeHeader' | translate}}
      </th>
      <th>
        {{'UserPages.UserPurchaseOrders.VerifyPalletsPage.PalletPriceModal.BoxQuantityHeader' | translate}}
      </th>
      <th>
        {{'UserPages.UserPurchaseOrders.VerifyPalletsPage.PalletPriceModal.PriceHeader' | translate}}
      </th>
    </tr>
  </ng-template>
  <ng-template  pTemplate="body" let-palletPrice>
    <tr>
      <td>
        {{palletPrice.category}}
      </td>
      <td>
        {{palletPrice.speciesSize}}
      </td>
      <td>
        {{palletPrice.boxPerPallets}}
      </td>
      <td>
        {{palletPrice.price | currency: 'USD'}}
      </td>
    </tr>
  </ng-template>
  </p-table>
</p-dialog>


<p-sidebar [(visible)]="sidebarVisible" position="right">
  <h3 style="text-align: center;">{{'AdvancedFilters.SideBarTitle' | translate}}</h3>

  <form [formGroup]="othersFiltersForm">
    <div class="col-12 flex justify-content-center flex-column">
      <div class="col-12 flex justify-content-center flex-column">
        <p-calendar
          inputId="harvestDate"
          formControlName="harvestDate"
          appendTo="body"
          [showButtonBar]="true"
          [showIcon]="true"
          [showWeek]="true"
          placeholder="{{'AdvancedFilters.HarvestDatePlaceholder' | translate}}"
          [firstDayOfWeek]="1"></p-calendar>
      </div>
      <div class="col-12 flex justify-content-center flex-column">
        <input pInputText inputId="netKilos" formControlName="kilosPerBox" 
        placeholder="{{'AdvancedFilters.KilosPerBoxFieldPlaceholder' | translate}}"/>
      </div>
      <div class="col-12 flex justify-content-center flex-column">
        <p-dropdown
        [options]="countries"
        formControlName="marketId"
        optionLabel="name"
        optionValue="id"
        [filter]="true"
        filterBy="name"
        autoWidth="false" [style]="{'width':'100%'}"
        [showClear]="true"
        (onChange)="countryChange()"
        placeholder="{{'AdvancedFilters.MarketPlaceholder' | translate}}"
        class="p-inputtext-sm">
        <ng-template pTemplate="selectedItem">
          <div class="flex align-items-center gap-2" *ngIf="selectedCountryFrom">
            <img [src]="'../../../../../assets/img/flags/' + selectedCountryFrom.code + '.png'" style="width: 18px" alt="flag"/>
            <div>{{ selectedCountryFrom | countryDescription }}</div>
          </div>
        </ng-template>
        <ng-template let-country pTemplate="item">
          <div class="flex align-items-center gap-2">
            <img [src]="'../../../../../assets/img/flags/' + country.code + '.png'" style="width: 18px" alt="flag"/>
            <div>{{ country | countryDescription }}</div>
          </div>
        </ng-template>
      </p-dropdown>
      </div>
      <div class="col-12 flex justify-content-center flex-column">
        <input pInputText formControlName="speciesSize" placeholder="{{'AdvancedFilters.SpeciesSizeFieldPlaceholder' | translate}}"/>
      </div>
      <div class="col-12 flex justify-content-center flex-column">
        <input pInputText formControlName="shelfLife" placeholder="{{'AdvancedFilters.ShelfLife' | translate}}"/>
      </div>
      <div class="col-12 flex justify-content-center flex-column">
        <input pInputText formControlName="price" placeholder="{{'AdvancedFilters.PricePlaceholder' | translate}}"/>
      </div>
        <div class="col-12 flex justify-content-center flex-columnr">
          <p-button label="{{'AdvancedFilters.ApplyFiltersText' | translate}}" styleClass="p-button" (onClick)="onFilter()">
            <span class="material-symbols-outlined">
              tune
              </span>
          </p-button>
        </div>
    </div>
  </form>
</p-sidebar>
