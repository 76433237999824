import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { Packaging } from 'src/app/domain/models/Packaging';
import { ProductCertificate } from 'src/app/domain/models/ProductCertificate';
import { ProductCertificateType } from 'src/app/domain/models/ProductCertificateType';
import { ProgramRequest, ProgramRequestInformation } from 'src/app/domain/models/ProgramRequest';
import { BasicUser } from 'src/app/domain/models/User';
import { ExportOfferUtils } from 'src/app/ui/util/export-offer-utils';
import { ProgramRequestUtil } from 'src/app/ui/util/programRequestUtil';

@Component({
  selector: 'app-program-request-detail',
  templateUrl: './program-request-detail.component.html',
  styleUrls: ['./program-request-detail.component.css']
})
export class ProgramRequestDetailComponent {


  @Input()
  programRequestInformation: ProgramRequestInformation | undefined

  profile: BasicUser | undefined

  responsiveOptions: any[] = [
    {
        breakpoint: '1199px',
        numVisible: 1,
        numScroll: 1
    },
    {
        breakpoint: '991px',
        numVisible: 1,
        numScroll: 1
    },
    {
        breakpoint: '767px',
        numVisible: 1,
        numScroll: 1
    }
  ]

  constructor(private _authService: AuthGateway,
    public translate: TranslateService) {

  }

  ngOnInit(): void {
    this.profile = this._authService.getUser()
  }

  completeUrl(url: string){
    return ExportOfferUtils.completeUrl(url);
  }

  getBoxType(boxType: string): string {
    if(this.profile)
      return ExportOfferUtils.getBoxTypeName(this.profile.lang, boxType)
    return ''
  }

  getTotalPalletsImportRequest(programRequest: ProgramRequest): number {
    return ProgramRequestUtil.getTotalPallets(programRequest)
  }

  getTotalWeeksImportRequest(programRequest: ProgramRequest): number {
    return ProgramRequestUtil.getTotalWeeks(
      programRequest.startWeek,
      programRequest.startWeekYear,
      programRequest.endWeek,
      programRequest.endWeekYear
    )
  }

  getTotalContainersPerWeek(programRequest: ProgramRequest) {
    return ProgramRequestUtil.getTotalContainersPerWeek(programRequest)
  }

  getTotalContainersImportRequest(programRequest: ProgramRequest): number {
    return ProgramRequestUtil.getTotalContainers(programRequest)
  }

  get availableLabel(): String {
    return this.translate.instant('ExportOffer.ExportOfferDetail.NoAvailable')
  }

  getCertificatesName(certificates: ProductCertificateType[]): string
  {
    let name = '';

    if(certificates.length == 1)
      {
        return certificates[0].name; 
      }

    certificates.forEach((x, index) => {
      if(index == 0)
        {
          name = name + x.name;
        }else{
          name =  name + '/' + x.name;
        } 
    }

    );

    return name
  }

  getPackagingTypes(packagingTypes: Packaging[])
  {
    let name = '';

    if(packagingTypes.length == 1)
      {
        return packagingTypes[0].name; 
      }

      packagingTypes.forEach((x, index) => {
      if(index == 0)
        {
          name = name + x.name;
        }else{
          name =  name + '/' + x.name;
        } 
    }

    );

    return name
  }

}
