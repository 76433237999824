<app-admin-dashboard>
  <p-card>
    <ng-template pTemplate="header">
      <div class="flex justify-content-between flex-wrap align-items-center" style="padding: 15px;">
        <h2>Administración de usuarios</h2>
      </div>
    </ng-template>
    <ng-template pTemplate="body">
      <p-table [value]="profiles"
        [paginator]="true"
        [rows]="10"
        [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[10, 25, 50]"
        [loading]="loading">
        <ng-template pTemplate="caption">
          <div class="flex align-items-center justify-content-end">
            <p-button label="Nuevo" icon="pi pi-plus" (onClick)="goToCreateUser()"></p-button>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th>
              <div class="flex justify-content-center align-items-center">
                ID
              </div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">
                Perfil
              </div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">
                Nombre de contacto
              </div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">
                Nombre de usuario
              </div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">
                Empresa
              </div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center"></div>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-profile>
          <tr>
            <td>
              <div class="flex justify-content-center align-items-center">{{profile.id}}</div>
            </td>
            <td>
              <div class="flex justify-content-center align-items-center">{{getProfileTypeName(profile.type)}}</div>
            </td>
            <td>
              <div class="flex justify-content-center align-items-center">{{profile.contactName}}</div>
            </td>
            <td>
              <div class="flex justify-content-center align-items-center">{{profile.contactEmail}}</div>
            </td>
            <td>
              <div class="flex justify-content-center align-items-center">{{profile.companyName}}</div>
            </td>
            <td>
              <div class="flex justify-content-end align-items-end gap-4">
                <p-button label="Importadores" *ngIf="profile.type == 1" (onClick)="goToExporterImporters(profile.id)"></p-button>
                <p-button styleClass="p-button-warning" label="Editar" (onClick)="goToEditUser(profile.id)"></p-button>
                <p-button [styleClass]="profile.isActive ? 'p-button-danger' : 'p-button-primary'" [label]="profile.isActive ? 'Desactivar' : 'Activar'" (onClick)="toogleIsActive(profile)"></p-button>
                <p-button styleClass="p-button-secondary" label="Reenviar invitación" *ngIf="profile.validationToken != null && profile.validationToken != ''" (onClick)="sendValidationToken(profile.id)"></p-button>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </ng-template>
  </p-card>
</app-admin-dashboard>

<p-toast></p-toast>
<p-confirmDialog acceptLabel="Si" rejectLabel="No" rejectButtonStyleClass="p-button-outlined" [style]="{width: '50vw'}"></p-confirmDialog>
