import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { DocumentServiceGateway } from 'src/app/application/gateway/DocumentServiceGateway';
import { ExportOfferGateway } from 'src/app/application/gateway/ExportOfferGateway';
import { ExportOfferAddtionalInfo } from 'src/app/domain/models/ExportOffer';
import { ExportOfferDocument } from 'src/app/domain/models/ExportOfferDocument';
import { BasicUser } from 'src/app/domain/models/User';
import { Routes } from 'src/app/infrastructure/routes';
import { ErrorMessageUtil } from 'src/app/ui/util/error-message-util';
import { ExportOfferUtils } from 'src/app/ui/util/export-offer-utils';
import { formLimit } from 'src/app/ui/util/form-limit';

@Component({
  selector: 'app-export-offer-edit-additional-info',
  templateUrl: './export-offer-edit-additional-info.component.html',
  styleUrls: ['./export-offer-edit-additional-info.component.css'],
  providers: [MessageService]
})
export class ExportOfferEditAdditionalInfoComponent implements OnInit {

  breadcrumbItems: MenuItem[] = []
  breadcrumbHomeItem: MenuItem = {}

  exportOfferId: number | undefined
  exportOffer: ExportOfferAddtionalInfo | undefined
  qualityDocument: any[] = []
  selectedQualityDocument: any

  profile: BasicUser | undefined

  loading: boolean = false
  buttonQualityDoc: boolean = false
  visibleUploadQualityDoc: boolean = false

  colors: any[] = []
  boxTypes: any[] = []
  kilosPerBoxes: any[] = []
  basePallets: any[] = []
  qualityControlOptions: any[] = []
  marksOptions: any[] = []

  selectedColor: any | undefined

  formGroup: FormGroup = this._formBuilder.group({
    color: [null],
    boxType: [null],
    caseSize: [null],
    kilosPerBox: [null],
    isQualityControl: [false],
    qualityControlDate: [null],
    basePallets: [null],
    marks: [false],
    sizeMillimeters: [null]
  })

  formLimit = formLimit

  constructor(private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _messageService: MessageService,
    private _authService: AuthGateway,
    private _exportOfferService: ExportOfferGateway,
    private _documentService: DocumentServiceGateway,
    public translate: TranslateService) {
    this._activatedRoute.params.subscribe(params => {
      this.exportOfferId = params['exportOfferId']
    })
  }

  ngOnInit(): void {
    this.profile = this._authService.getUser()

    let breadcrumbMenuItemLabel1 = 'Export offer'
    let breadcrumbMenuItemLabel2 = 'Additional info'
    let breadcrumbHomeMenuItemLabel = 'My export offers'

    if(this.profile) {
      switch(this.profile.lang) {
        case 'en':
          breadcrumbMenuItemLabel1 = 'Upload new offer'
          breadcrumbMenuItemLabel2 = 'Additional info'
          breadcrumbHomeMenuItemLabel = 'My export offers'
          break
        case 'es':
          breadcrumbMenuItemLabel1 = 'Oferta de exportación'
          breadcrumbMenuItemLabel2 = 'Información complementaria'
          breadcrumbHomeMenuItemLabel = 'Mis ofertas de exportación'
          break
      }
    }

    this.breadcrumbItems = [{ label: breadcrumbMenuItemLabel1, routerLink: ['/' + Routes.newExportOffer, this.exportOfferId]}, { label: breadcrumbMenuItemLabel2, disabled: true}]
    this.breadcrumbHomeItem = { label: breadcrumbHomeMenuItemLabel, routerLink: '/' + Routes.userExportOffers };

    this.getExportOffer()
  }

  getExportOffer() {
    if(this.exportOfferId) {
      this.loading = true
      this._exportOfferService.getExportOfferAddtionalInfo(this.exportOfferId).subscribe({
        next: (v) => {
          console.log(v)
          this.exportOffer = v
          this.loadForm()
        },
        error: (e) => {
          console.log(e)
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOffer.SingleRetrieveError') });
        },
        complete: () => console.info('ExportOffer query successfully')
      })
    }
  }

  loadForm() {
    if(this.exportOffer) {
      if(this.profile) {
        this.colors = ExportOfferUtils.getColors(this.profile.lang)
        this.boxTypes = ExportOfferUtils.getBoxTypes(this.profile.lang)
        this.kilosPerBoxes = ExportOfferUtils.getKilosPerBox()
        this.basePallets = ExportOfferUtils.getBasePallets(this.profile.lang)
        this.qualityControlOptions = ExportOfferUtils.getControlQualityOptions(this.profile.lang)
        this.marksOptions = ExportOfferUtils.getMarksOptions(this.profile.lang)
      }
      this.selectedColor = this.colors.find(x => x.value === this.exportOffer!.color)
      this.formGroup.controls['color'].setValue(this.exportOffer.color)
      this.formGroup.controls['boxType'].setValue(this.exportOffer.boxType)
      this.formGroup.controls['caseSize'].setValue(this.exportOffer.caseSize)
      this.formGroup.controls['kilosPerBox'].setValue(this.exportOffer.kilosPerBox)
      this.formGroup.controls['isQualityControl'].setValue(this.exportOffer.isQualityControl)
      this.formGroup.controls['qualityControlDate'].setValue(this.exportOffer.qualityControlDate ? new Date(this.exportOffer.qualityControlDate) : null)
      this.formGroup.controls['basePallets'].setValue(this.exportOffer.basePallets)
      this.formGroup.controls['marks'].setValue(this.exportOffer.marks)
      this.formGroup.controls['sizeMillimeters'].setValue(this.exportOffer.sizeMillimeters)
      this.loading = false
      this.showUploadQualityDoc()
    }
  }

  save() {
    this.loading = true
    if(this.formGroup.valid) {
      if(this.exportOffer) {        
        if (this.qualityDocument) {
          this.selectedQualityDocument = this.qualityDocument[0]
        }

        let isQualityControlValue = this.formGroup.controls['isQualityControl'].value

        if (isQualityControlValue == true && this.selectedQualityDocument?.name == undefined && this.exportOffer.documents !== undefined && this.exportOffer.documents?.length <= 0) {
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOffer.DocumentQualityError')})
          this.loading = false
          return;
        }

        let request: ExportOfferAddtionalInfo = {
          guid: this.exportOffer.guid,
          basePallets: this.formGroup.controls['basePallets'].value,
          boxType: this.formGroup.controls['boxType'].value,
          caseSize: this.formGroup.controls['caseSize'].value,
          category: this.exportOffer.category,
          color: this.formGroup.controls['color'].value,
          grossKilos: this.exportOffer.grossKilos,
          id: this.exportOffer.id,
          isQualityControl: isQualityControlValue,
          kilosPerBox: this.formGroup.controls['kilosPerBox'].value,
          marks: this.formGroup.controls['marks'].value,
          qualityControlDate: this.formGroup.controls['qualityControlDate'].value,
          sizeMillimeters: this.formGroup.controls['sizeMillimeters'].value,
          qualityDocument: isQualityControlValue && this.qualityDocument[0] ? this.qualityDocument[0] : null
        }

        this._exportOfferService.updateExportOfferAdditionalInfo(request).subscribe({
          next: (v) => {
            this._messageService.add({ severity: 'success',
              summary: this.translate.instant('MessageService.Summary.SuccessAction'),
              detail: this.translate.instant('MessageService.ExportOffer.EditSuccess') })
            this.loading = false
            // Espera 1.5 segundo antes de redirigir
            setTimeout(() => {
              this._router.navigate([Routes.userExportOffers]);
            }, 1500);
          },
          error: (e) => {
            this.loading = false
            console.log(e)
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.ExportOffer.EditError') });
          },
          complete: () => console.info('Export Offer update successfully')
        })
      }
    }
  }

  onChangeColor() {
    this.selectedColor = this.colors.find(x => x.value === this.formGroup.controls['color'].value)
  }

  goToEditExportOffer() {
    this._router.navigate(['/'])
  }

  getErrorMessage(formGroup: FormGroup, fieldName: string): string {
    return ErrorMessageUtil.getErrorMessage(formGroup, this.translate, fieldName)
  }

  onSelect(event: any, uploader: any){
    this.qualityDocument = uploader.files
  }

  onRemove(event: any, uploader: any) {
    this.qualityDocument = uploader.files
  }

  onClear(event: any, uploader: any) {
    this.qualityDocument = uploader.files
  }

  getExportOfferQualityDocument(documents: any[] | undefined) {
    if (documents == undefined || documents.length <= 0) return;

    let docId = documents[0].id

    this._documentService.getExportOfferDocument2(docId).subscribe((resultBlob: any) => {
      var downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }

  showUploadQualityDoc() {
    this.buttonQualityDoc = false
    if (this.formGroup.controls['isQualityControl'].value) {
      this.buttonQualityDoc = true
    }
  }
}
