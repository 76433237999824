import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PaymentCreateRequest, Payment, PaymentUploadFile, PaymentsInformation } from "src/app/domain/models/Payment";
import { Routes } from "src/app/infrastructure/routes";
import { PaymentGateway } from "../gateway/PaymentGateway";

@Injectable({providedIn: 'root'})
export class PaymentService extends PaymentGateway {
  constructor(private _http: HttpClient) {
    super()
  }

  create(request: PaymentCreateRequest): Observable<Payment> {
    return this._http.post<Payment>(Routes.paymentApi + '/Create', this.getFormData(request));
  }

  getAllByImportOrderId(importOrderId: any): Observable<PaymentsInformation> {
    return this._http.get<PaymentsInformation>(Routes.paymentApi + '/GetAllByImportOrderId', {params: {importOrderId}});
  }

  approvePayment(paymentId: number): Observable<any> {
    return this._http.put<any>(Routes.paymentApi + '/ApprovePayment', {}, {params:{paymentId}});
  }

  uploadFile(paymentUploadFile: PaymentUploadFile): Observable<Payment> {
    return this._http.put<Payment>(Routes.paymentApi + '/UploadPaymentFile', this.getFormDataUpload(paymentUploadFile));
  }

  getFormData(paymentCreateRequest: PaymentCreateRequest){
    const formData = new FormData();
    formData.append("importOrderId", paymentCreateRequest.importOrderId.toString());
    formData.append("swiftId", paymentCreateRequest.payment.swiftId);
    formData.append("amount", paymentCreateRequest.payment.amount.toString());
    formData.append("description", paymentCreateRequest.payment.description ? paymentCreateRequest.payment.description: '' )
    formData.append("pdf", paymentCreateRequest.pdf ?   paymentCreateRequest.pdf : '');
    return formData;

  }

  getFormDataUpload(paymentUploadFile: PaymentUploadFile){
    const formData = new FormData();
    formData.append("paymentId", paymentUploadFile.paymentId.toString());
    formData.append("pdf", paymentUploadFile.pdf);
    return formData;
  }



}
