<app-user-dashboard [userMenuActiveItemIndex]="0" [activeItem]="'orders'">
  <p-card [style]="{'min-height':'200px'}">
    <ng-template pTemplate="header">
      <div class="flex justify-content-between flex-wrap align-items-center" style="padding: 15px;">
        <h2>{{'UserPages.UserPurchaseOrders.PageTitle' | translate}}</h2>
      </div>
      <div>
        <p-tabMenu [model]="tabMenuItems" [scrollable]="true" [activeItem]="activeItem" [style]="{padding: '15px'}"></p-tabMenu>
      </div>
    </ng-template>
    <ng-template pTemplate="body">
      <p-table [value]="orders"
        [paginator]="true"
        [rows]="10"
        [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[10, 25, 50]"
        [loading]="loading"
        responsiveLayout="stack"
        [breakpoint]="'600px'"
        [globalFilterFields]="['status']">
        <ng-template pTemplate="caption">
          <div class="flex justify-content-start align-items-center">
            <p-tag severity="warning">{{'UserPages.UserPurchaseOrders.MessageTotalText' | translate}}</p-tag>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th rowspan="2">
              <div class="flex justify-content-center align-items-center">
                {{'UserPages.UserPurchaseOrders.CurrentTable.ImageHeader' | translate}}
              </div>
            </th>
            <th rowspan="2">
              <div class="flex justify-content-center align-items-center">
                {{'UserPages.UserPurchaseOrders.CurrentTable.Specie/VarietyHeader' | translate}}
              </div>
            </th>
            <th rowspan="2">
              <div class="flex justify-content-center align-items-center">
                {{'UserPages.UserPurchaseOrders.CurrentTable.TotalHeader' | translate}}
              </div>
            </th>
            <th rowspan="2">
              <div class="flex justify-content-center align-items-center">
                {{'UserPages.UserPurchaseOrders.CurrentTable.CodeHeader' | translate}}
              </div>
            </th>
            <th colspan="2" style="border: none">
              <div class="flex justify-content-center align-items-center">
                {{'UserPages.UserPurchaseOrders.CurrentTable.VerificationHeader' | translate}}
              </div>
            </th>
            <th colspan="2" style="border: none">
              <div class="flex justify-content-center align-items-center">
                {{'UserPages.UserPurchaseOrders.CurrentTable.ConfirmationHeader' | translate}}
              </div>
            </th>
            <th rowspan="2">
              <div class="flex justify-content-center align-items-center">
                {{'UserPages.UserPurchaseOrders.CurrentTable.ActionsHeader' | translate}}
                <p-columnFilter field="status" [showAddButton]="false" [showMatchModes]="false" [showOperator]="false" [matchMode]="'equals'" display="menu">
                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                      <p-dropdown [ngModel]="value" [options]="statuses" (onChange)="filter($event.value)">
                          <ng-template let-option pTemplate="item">
                              <span>{{ option.label }}</span>
                          </ng-template>
                      </p-dropdown>
                  </ng-template>
              </p-columnFilter>
              </div>
            </th>
            <th rowspan="2">
              <div class="flex justify-content-center align-items-center">
                {{'UserPages.UserPurchaseOrders.CurrentTable.InfoHeader' | translate}}
              </div>
            </th>
          </tr>
          <tr>
            <th>
              <div class="flex justify-content-center align-items-center">
                {{'UserPages.UserPurchaseOrders.CurrentTable.ExporterHeader' | translate}}
              </div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">
                {{'UserPages.UserPurchaseOrders.CurrentTable.QualityHeader' | translate}}
              </div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">
                {{'UserPages.UserPurchaseOrders.CurrentTable.ImporterHeader' | translate}}
              </div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">
                {{'UserPages.UserPurchaseOrders.CurrentTable.PaymentHeader' | translate}}
              </div>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-order>
          <tr>
            <td>
              <img [src]="getPictureUrl(order.pictureUrl)" style="border-radius: 7px; width: 100%; max-width: 100px; height: auto;" alt=""/>
            </td>
            <td>
              <div class="flex justify-content-center align-items-center">
                <div>
                  <ng-container *ngFor="let detail of order.details">
                    {{ getSpeciesName(detail) }}
                    {{getSpeciesName(detail)}} / {{detail.varietyName ? detail.varietyName : "N.A" }}
                  </ng-container>
                </div>
              </div>
            </td>
            <td>
              <div class="flex justify-content-center align-items-center">
                <span style="font-weight: bold;">{{ order.totalPrice | currency: 'USD':'US$'}}</span>
              </div>
            </td>
            <td>
              <div class="flex justify-content-center align-items-center">
                {{ getOrderTitle(order) }}
                <br *ngIf="getOrderTitle(order) !== ''">
                {{ order.created | date: 'dd-MM-yyyy'}}
              </div>
            </td>
            <td>
              <div class="flex justify-content-center align-items-center">
                <i class="pi pi-check" style="color: #689F38" *ngIf="order.status >= 1" pTooltip="Aprobado"></i>
                <i class="pi pi-circle-fill" style="color: lightgray" *ngIf="order.status < 1" pTooltip="Pendiente"></i>
              </div>
            </td>
            <td>
              <div class="flex justify-content-center align-items-center">
                <i class="pi pi-check" style="color: #689F38" *ngIf="order.status >= 2" pTooltip="Aprobado"></i>
                <i class="pi pi-circle-fill" style="color: lightgray" *ngIf="order.status < 2" pTooltip="Pendiente"></i>
              </div>
            </td>
            <td>
              <div class="flex justify-content-center align-items-center">
                <i class="pi pi-check" style="color: #689F38" *ngIf="order.status >= 3" pTooltip="Aprobado"></i>
                <i class="pi pi-circle-fill" style="color: lightgray" *ngIf="order.status < 3" pTooltip="Pendiente"></i>
              </div>
            </td>
            <td>
              <div class="flex justify-content-center align-items-center">
                <i class="pi pi-check" style="color: #689F38" *ngIf="order.status >= 4" pTooltip="Aprobado"></i>
                <i class="pi pi-circle-fill" style="color: lightgray" *ngIf="order.status < 4" pTooltip="Pendiente"></i>
              </div>
            </td>
            <td>
              <div class="flex justify-content-center align-items-center">
                <p-tag *ngIf="order.status != null && statuses[order.status]" [value]="statuses[order.status].label" [severity]="getStatusSeverity(order.status)" [icon]="getStatusIcon(order.status)">
                </p-tag>
              </div>
            </td>
            <td>
              <div class="flex justify-content-end align-items-center gap-4">
                <p-button icon="pi pi-info" styleClass="p-button-rounded" [style]="{'background-color': '#8C2B87', 'width': '1.5rem', 'height': '1.5rem'}" pTooltip="Ver orden" (onClick)="goToOrderDetailPage(order.id)"></p-button>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </ng-template>
  </p-card>
</app-user-dashboard>
  