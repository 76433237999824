import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { CompanyGateway } from 'src/app/application/gateway/CompanyGateway';
import { CountryGateway } from 'src/app/application/gateway/CountryGateway';
import { ProfileGateway } from 'src/app/application/gateway/ProfileGateway';
import { NewCompany } from 'src/app/domain/models/Company';
import { Country } from 'src/app/domain/models/Country';
import { Profile } from 'src/app/domain/models/Profile';
import { Routes } from 'src/app/infrastructure/routes';

@Component({
  selector: 'app-admin-create-forwarder',
  templateUrl: './admin-create-forwarder.component.html',
  styleUrls: ['./admin-create-forwarder.component.css'],
  providers: [MessageService]
})
export class AdminCreateForwarderComponent implements OnInit {

  countries: Country[] = []
  profiles: Profile[] = []
  selectedCountry: Country | undefined

  selectedUserAdmin: any
  users: any[] = []

  loading: boolean = false
  submit: boolean = false
  success: boolean = false

  formGroup: FormGroup = this._formBuilder.group({
    companyName: ['', Validators.required],
    contactName: ['', Validators.required],
    contactLastName: [null, Validators.required],
    contactPhone: [null, Validators.required],
    contactEmail: ['', [Validators.required, Validators.email]],
    countryId: [null, Validators.required],
    profileId: [null, Validators.required]
  })

  constructor(private _router: Router,
    private _formBuilder: FormBuilder,
    private _messageService: MessageService,
    private _countryService: CountryGateway,
    private _companyService: CompanyGateway,
    public translate: TranslateService,
    private _profileService: ProfileGateway) {
  }

  ngOnInit(): void {
    this.loadCountries()
    this.loadProfiles()
  }

  loadProfiles() {
    this._profileService.getAll().subscribe({
      next: (v) => {
        this.profiles = v.filter(x => x.type === 5)
        this.profiles = this.profiles.map((item) => {
          return {
            ...item,
            fullname: `${item.contactName} ${item.contactLastName}`
          }
        })
      },
      error: (e) => {
        console.log(e)
        this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudieron recuperar las perfiles.' });
      },
      complete: () =>  {
        this.loading = false
        console.info('Profile query successfully')
      }
    })
  }

  loadCountries(): void {
    this._countryService.getAll().subscribe({
      next: (v) => {
        this.countries = v
      },
      error: (e) => {
        this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudo recuperar la lista de países.' });
        console.log(e)
      },
      complete: () => console.info('Country query successfully')
    })
  }

  getErrorMessage(fieldName: string): string {
    if (this.formGroup.controls[fieldName].hasError('required')) {
      return 'Este campo es requerido.';
    }
    if (this.formGroup.controls[fieldName].hasError('email')) {
      return 'Formato de correo electrónico incorrecto.';
    }
    return '';
  }

  goToBack(): void {
    this._router.navigate(['/' + Routes.adminForwarder])
  }

  createCompany(): void{
    this.submit = true
    if(this.formGroup.valid) {
      const company: NewCompany = {
        companyName: this.formGroup.controls['companyName'].value,
        contactName: this.formGroup.controls['contactName'].value,
        contactLastName: this.formGroup.controls['contactLastName'].value,
        contactPhone: this.formGroup.controls['contactPhone'].value,
        contactEmail: this.formGroup.controls['contactEmail'].value,
        countryId: this.formGroup.controls['countryId'].value,
        companyTypeId: 0, // Forwarder
        profileId: this.selectedUserAdmin
      }

      this.loading = true
      this._companyService.create(company).subscribe({
        next: (v) => {
          this.success = true
          this.loading = false
          this.submit = false
          this._messageService.add({ severity: 'success', summary: 'Acción exitosa', detail: 'El Forwarder fue creado con éxito.' });
        },
        error: (e) => {
          this.loading = false
          this.submit = false
          this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudo crear el Forwarder. ' + e.error.messageEs });
          console.log(e)
        },
        complete: () => console.info('Forwarder created successfully')
      })
    } else {
      this.findInvalidControls()
      this._messageService.add({ severity: 'error',
        summary: this.translate.instant('MessageService.Summary.FailedAction'),
        detail: this.translate.instant('MessageService.FormMessages.FormInvalid') })
    }
  }

  onChangeCountry() {
    this.selectedCountry = this.countries.find(x => x.id === this.formGroup.controls['countryId'].value)
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.formGroup.controls;
    for (const name in controls) {
        if (controls[name].invalid) {
            console.log(name)
            invalid.push(name);
        }
    }
    return invalid;
  }

  onChangeProfile(event: any) {
    this.selectedUserAdmin = event.value
  }

}
