import { ExportOffer, ExportOfferAd, OfferPicture } from "src/app/domain/models/ExportOffer"
import { environment } from '../../../environments/environment'
import { Lang } from "./langs"

export class ExportOfferUtils {

  static getBoxTypes(lang: string): any[] {
    const english = [{label : 'Cardboard', value: 'cardboard'}, {label : 'Plastic', value: 'plastic'}, {label : 'Wood', value: 'wood'}]
    switch(lang) {
      case Lang.SPANISH:
        return [{label : 'Cartón', value: 'cardboard'}, {label : 'Plástico', value: 'plastic'}, {label : 'Madera', value: 'wood'}]

      case Lang.ENGLISH:
        return english

      default:
        return english
    }
  }

  static getBoxTypeName(lang: string, value?: string): string {
    const boxTypes = ExportOfferUtils.getBoxTypes(lang)
    const boxType = boxTypes.find(x => x.value == value)

    if(boxType) {
      return boxType.label
    }
    else {
      switch (lang) {
        case 'en':
          return 'Not available'
        case 'es':
          return 'No disponible'
        default:
          return 'Not available'
      }
    }
  }

  static getColors(lang: string): any[] {
    const english = [{label : 'Red', value: 'red'}, {label : 'Green', value: 'green'}, {label : 'Brown', value: 'brown'}, {label : 'Black', value: 'black'}, {label : 'Orange', value: 'orange'}, {label : 'Blue', value: 'blue'}, {label : 'Yellow', value: 'yellow'}]
    switch(lang) {
      case Lang.SPANISH:
        return [{label : 'Rojo', value: 'red'}, {label : 'Verde', value: 'green'}, {label : 'Marrón', value: 'brown'}, {label : 'Negro', value: 'black'}, {label : 'Naranja', value: 'orange'}, {label : 'Azul', value: 'blue'}, {label : 'Amarillo', value: 'yellow'}]

      case Lang.ENGLISH:
        return english

      default:
        return english
    }
  }

  static getColorName(lang: string, value?: string): string {
    if(!value)
      return ''
    const colors = ExportOfferUtils.getColors(lang)
    return colors.find(x => x.value == value) ? colors.find(x => x.value == value).label : ''
  }

  static getControlQualityOptions(lang: string): any[] {
    const english = [{label: 'Yes', value: true}, {label: 'No', value: false}]
    switch(lang) {
      case Lang.SPANISH:
        return [{label: 'Si', value: true}, {label: 'No', value: false}]

      case Lang.ENGLISH:
        return english

      default:
        return english
    }
  }

  static getMarksOptions(lang: string): any[] {
    const english = [{label: 'Yes', value: true}, {label: 'No', value: false}]
    switch(lang) {
      case Lang.SPANISH:
        return [{label: 'Si', value: true}, {label: 'No', value: false}]

      case Lang.ENGLISH:
        return english

      default:
        return english
    }
  }

  static getKilosPerBox(): any[] {
    return [{label : '4.2 Kg', value: '4.2'}]
  }

  static getBasePallets(lang: string, value?: string): any[] {
    const english = [{label : 'Euro pallet', value: 'block'}, {label : '2 Way pallet', value: 'yoke'}]
    switch(lang) {
      case Lang.SPANISH:
        return [{label : 'Taco', value: 'block'}, {label : 'Yugo', value: 'yoke'}]

      case Lang.ENGLISH:
        return english

      default:
        return english
    }
  }

  static getBasePalletsName(lang: string, value?: string): string {
    if(!value)
      return ''
    const basePallets = ExportOfferUtils.getBasePallets(lang)
    return basePallets.find(x => x.value == value) ? basePallets.find(x => x.value == value).label : ''
  }

  static getImageUrl(exportOffer: ExportOffer, pictures: OfferPicture[], index: number): string {
    return pictures && pictures[index] ? environment.apiUrl + pictures[index].url : (exportOffer.variety.species.imageUrl ? exportOffer.variety.species.imageUrl : '')
  }

  static getImageAdUrl(exportOfferAd: ExportOfferAd, pictures: OfferPicture[], index: number): string {
    return pictures && pictures[index] ? environment.apiUrl + pictures[index].url : (exportOfferAd.variety.species.imageUrl ? exportOfferAd.variety.species.imageUrl : '')
  }

  static getImageUrlIcon(exportOffer: ExportOffer){
    return exportOffer.variety.species.imageUrl ? exportOffer.variety.species.imageUrl: '';
  }

  static getTotalPrice(exportOffer: ExportOffer, requiredPallets: number): number {
    let total = 0
    if(exportOffer) {
      return exportOffer.caseQuantityPerPallet * exportOffer.listPrice * requiredPallets
    }
    return total
  }

  static completeUrl(url: string){
    return environment.apiUrl + url;
  }

  static getIncotermsOptions(): any[] {
    return [
      {label: 'Ex Works', value: 'EXW'},
      {label: 'FOB', value: 'FOB'},
      //{label: 'CIF', value: 'CIF'}
    ]
  }

  static getIncotermsClosedSaleOptions(): any[] {
    return [
      {label: 'Ex Works', value: 'EXW'},
      {label: 'FOB', value: 'FOB'},
      {label: 'CIF', value: 'CIF'}
    ]
  }

  static getIncotermsName(value: string): string {
    const incoterms = ExportOfferUtils.getIncotermsOptions()
    return incoterms.find(x => x.value == value) ? incoterms.find(x => x.value == value).label : ''
  }

  static getProductStackingType(lang: string): any[] {
    const english = [{label : 'Cases on floor', value: 1}, {label : 'Pallet', value: 2}]
    switch(lang) {
      case Lang.SPANISH:
        return [{label : 'Cajas al piso', value: 1}, {label : 'Pallet', value: 2}]

      case Lang.ENGLISH:
        return english

      default:
        return english
    }
  }

  static getPaymentBLTitle(value: string): string {
    if (value.toString() !== '100') return 'UserPages.UserExportOffers.NewExportOffer.PaymentAgainstBLLabelGuaranted'
    return 'UserPages.UserExportOffers.NewExportOffer.PaymentAgainstBLLabel'
  }

  static getPaymentBLValues(value: string): any {
    if (value === 'EXW') return [{ label: '100%', value: 100 }]
    return [
      { label: '70%', value: 70 },
      { label: '80%', value: 80 },
      { label: '90%', value: 90 },
      { label: '100%', value: 100 }
    ]
  }

  static showPaymentBLPercent(value: string): boolean {
    if (value === 'EXW') return false
    return true
  }

  static getPaymentBLClosedSaleTitle(value: string): string {
    if (value?.toString() !== '100') return 'UserPages.UserExportOffers.NewExportOffer.PaymentAgainstBLClosedSaleLabelGuaranted'
    return 'UserPages.UserExportOffers.NewExportOffer.PaymentAgainstBLClosedSaleLabel'
  }

  static getPaymentBLClosedSaleValues(value: string): any {
    if (value === 'EXW') return [{ label: '100%', value: 100 }]
    return [
      { label: '40%', value: 40 },
      { label: '50%', value: 50 },
      { label: '60%', value: 60 },
      { label: '70%', value: 70 },
      { label: '80%', value: 80 },
      { label: '90%', value: 90 },
      { label: '100%', value: 100 }
    ]
  }

  static showPaymentBLClosedSalePercent(value: string): boolean {
    if (value === 'EXW') return false
    return true
  }

  static getTotalWeeks(startWeek: any, startWeekYear: any, endWeek: any, endWeekYear: any) {
    if(startWeekYear && endWeekYear && startWeekYear === endWeekYear && endWeek >= startWeek) {
      return (endWeek - startWeek) + 1
    }

    if (startWeekYear && endWeekYear && endWeekYear == startWeekYear + 1) {
      return ((52 - startWeek) + endWeek) + 1
    }
    return 0
  }
}
