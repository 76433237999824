import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { MenuItem, MessageService } from 'primeng/api';
import readXlsxFile, { readSheetNames } from 'read-excel-file';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { FrozenFruitPalletGateway } from 'src/app/application/gateway/FrozenFruitPalletGateway';
import { FrozenFruitExporterVerifyPalletsRequest, FrozenFruitPallet } from 'src/app/domain/models/FrozenFruitPallet';
import { BasicUser } from 'src/app/domain/models/User';
import { Routes } from 'src/app/infrastructure/routes';
import { ErrorMessageUtil } from 'src/app/ui/util/error-message-util';

@Component({
  selector: 'app-frozen-fruit-verify-pallets',
  templateUrl: './frozen-fruit-verify-pallets.component.html',
  styleUrls: ['./frozen-fruit-verify-pallets.component.css'],
  providers: [MessageService]
})
export class FrozenFruitVerifyPalletsComponent implements OnInit {
  breadcrumbMenuItems: MenuItem[] = []
  breadcrumbHome: MenuItem = {}

  profile: BasicUser | undefined

  orderDetailId: number | undefined
  pallets: FrozenFruitPallet[] = []

  loading: boolean = false

  excelFile: any
  excelSheets: any
  startRow: number = 1
  folioColumn: string = ''
  caliberColumn: string = ''
  producerColumn: string = ''
  visibleLoadExcelDialog: boolean = false
  selectedSheet: string = ''
  success: boolean = false

  columns: string[] = [
    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
    'AA', 'AB', 'AC', 'AD', 'AE', 'AF', 'AG', 'AH', 'AI', 'AJ', 'AK', 'AL', 'AM', 'AN', 'AO', 'AP', 'AQ', 'AR', 'AS', 'AT', 'AU', 'AV', 'AW', 'AX', 'AY', 'AZ',
    'BA', 'BB', 'BC', 'BD', 'BE', 'BF', 'BG', 'BH', 'BI', 'BJ', 'BK', 'BL', 'BM', 'BN', 'BO', 'BP', 'BQ', 'BR', 'BS', 'BT', 'BU', 'BV', 'BW', 'BX', 'BY', 'BZ',
    'CA', 'CB', 'CC', 'CD', 'CE', 'CF', 'CG', 'CH', 'CI', 'CJ', 'CK', 'CL', 'CM', 'CN', 'CO', 'CP', 'CQ', 'CR', 'CS', 'CT', 'CU', 'CV', 'CW', 'CX', 'CY', 'CZ',
    'DA', 'DB', 'DC', 'DD', 'DE', 'DF', 'DG', 'DH', 'DI', 'DJ', 'DK', 'DL', 'DM', 'DN', 'DO', 'DP', 'DQ', 'DR', 'DS', 'DT', 'DU', 'DV', 'DW', 'DX', 'DY', 'DZ',
    'EA', 'EB', 'EC', 'ED', 'EE', 'EF', 'EG', 'EH', 'EI', 'EJ', 'EK', 'EL', 'EM', 'EN', 'EO', 'EP', 'EQ', 'ER', 'ES', 'ET', 'EU', 'EV', 'EW', 'EX', 'EY', 'EZ',
    'FA', 'FB', 'FC', 'FD', 'FE', 'FF', 'FG', 'FH', 'FI', 'FJ', 'FK', 'FL', 'FM', 'FN', 'FO', 'FP', 'FQ', 'FR', 'FS', 'FT', 'FU', 'FV', 'FW', 'FX', 'FY', 'FZ',
    'GA', 'GB', 'GC', 'GD', 'GE', 'GF', 'GG', 'GH', 'GI', 'GJ', 'GK', 'GL', 'GM', 'GN', 'GO', 'GP', 'GQ', 'GR', 'GS', 'GT', 'GU', 'GV', 'GW', 'GX', 'GY', 'GZ',
    'HA', 'HB', 'HC', 'HD', 'HE', 'HF', 'HG', 'HH', 'HI', 'HJ', 'HK', 'HL', 'HM', 'HN', 'HO', 'HP', 'HQ', 'HR', 'HS', 'HT', 'HU', 'HV', 'HW', 'HX', 'HY', 'HZ',
    'IA', 'IB', 'IC', 'ID', 'IE', 'IF', 'IG', 'IH', 'II', 'IJ', 'IK', 'IL', 'IM', 'IN', 'IO', 'IP', 'IQ', 'IR', 'IS', 'IT', 'IU', 'IV', 'IW', 'IX', 'IY', 'IZ',
    'JA', 'JB', 'JC', 'JD', 'JE', 'JF', 'JG', 'JH', 'JI', 'JJ', 'JK', 'JL', 'JM', 'JN', 'JO', 'JP', 'JQ', 'JR', 'JS', 'JT', 'JU', 'JV', 'JW', 'JX', 'JY', 'JZ',
    'KA', 'KB', 'KC', 'KD', 'KE', 'KF', 'KG', 'KH', 'KI', 'KJ', 'KK', 'KL', 'KM', 'KN', 'KO', 'KP', 'KQ', 'KR', 'KS', 'KT', 'KU', 'KV', 'KW', 'KX', 'KY', 'KZ',
    'LA', 'LB', 'LC', 'LD', 'LE', 'LF', 'LG', 'LH', 'LI', 'LJ', 'LK', 'LL', 'LM', 'LN', 'LO', 'LP', 'LQ', 'LR', 'LS', 'LT', 'LU', 'LV', 'LW', 'LX', 'LY', 'LZ',
    'MA', 'MB', 'MC', 'MD', 'ME', 'MF', 'MG', 'MH', 'MI', 'MJ', 'MK', 'ML', 'MM', 'MN', 'MO', 'MP', 'MQ', 'MR', 'MS', 'MT', 'MU', 'MV', 'MW', 'MX', 'MY', 'MZ',
    'NA', 'NB', 'NC', 'ND', 'NE', 'NF', 'NG', 'NH', 'NI', 'NJ', 'NK', 'NL', 'NM', 'NN', 'NO', 'NP', 'NQ', 'NR', 'NS', 'NT', 'NU', 'NV', 'NW', 'NX', 'NY', 'NZ',
    'OA', 'OB', 'OC', 'OD', 'OE', 'OF', 'OG', 'OH', 'OI', 'OJ', 'OK', 'OL', 'OM', 'ON', 'OO', 'OP', 'OQ', 'OR', 'OS', 'OT', 'OU', 'OV', 'OW', 'OX', 'OY', 'OZ',
    'PA', 'PB', 'PC', 'PD', 'PE', 'PF', 'PG', 'PH', 'PI', 'PJ', 'PK', 'PL', 'PM', 'PN', 'PO', 'PP', 'PQ', 'PR', 'PS', 'PT', 'PU', 'PV', 'PW', 'PX', 'PY', 'PZ',
    'QA', 'QB', 'QC', 'QD', 'QE', 'QF', 'QG', 'QH', 'QI', 'QJ', 'QK', 'QL', 'QM', 'QN', 'QO', 'QP', 'QQ', 'QR', 'QS', 'QT', 'QU', 'QV', 'QW', 'QX', 'QY', 'QZ',
    'RA', 'RB', 'RC', 'RD', 'RE', 'RF', 'RG', 'RH', 'RI', 'RJ', 'RK', 'RL', 'RM', 'RN', 'RO', 'RP', 'RQ', 'RR', 'RS', 'RT', 'RU', 'RV', 'RW', 'RX', 'RY', 'RZ',
    'SA', 'SB', 'SC', 'SD', 'SE', 'SF', 'SG', 'SH', 'SI', 'SJ', 'SK', 'SL', 'SM', 'SN', 'SO', 'SP', 'SQ', 'SR', 'SS', 'ST', 'SU', 'SV', 'SW', 'SX', 'SY', 'SZ',
    'TA', 'TB', 'TC', 'TD', 'TE', 'TF', 'TG', 'TH', 'TI', 'TJ', 'TK', 'TL', 'TM', 'TN', 'TO', 'TP', 'TQ', 'TR', 'TS', 'TT', 'TU', 'TV', 'TW', 'TX', 'TY', 'TZ',
    'UA', 'UB', 'UC', 'UD', 'UE', 'UF', 'UG', 'UH', 'UI', 'UJ', 'UK', 'UL', 'UM', 'UN', 'UO', 'UP', 'UQ', 'UR', 'US', 'UT', 'UU', 'UV', 'UW', 'UX', 'UY', 'UZ',
    'VA', 'VB', 'VC', 'VD', 'VE', 'VF', 'VG', 'VH', 'VI', 'VJ', 'VK', 'VL', 'VM', 'VN', 'VO', 'VP', 'VQ', 'VR', 'VS', 'VT', 'VU', 'VV', 'VW', 'VX', 'VY', 'VZ',
    'WA', 'WB', 'WC', 'WD', 'WE', 'WF', 'WG', 'WH', 'WI', 'WJ', 'WK', 'WL', 'WM', 'WN', 'WO', 'WP', 'WQ', 'WR', 'WS', 'WT', 'WU', 'WV', 'WW', 'WX', 'WY', 'WZ',
    'XA', 'XB', 'XC', 'XD', 'XE', 'XF', 'XG', 'XH', 'XI', 'XJ', 'XK', 'XL', 'XM', 'XN', 'XO', 'XP', 'XQ', 'XR', 'XS', 'XT', 'XU', 'XV', 'XW', 'XX', 'XY', 'XZ',
    'YA', 'YB', 'YC', 'YD', 'YE', 'YF', 'YG', 'YH', 'YI', 'YJ', 'YK', 'YL', 'YM', 'YN', 'YO', 'YP', 'YQ', 'YR', 'YS', 'YT', 'YU', 'YV', 'YW', 'YX', 'YY', 'YZ',
    'ZA', 'ZB', 'ZC', 'ZD', 'ZE', 'ZF', 'ZG', 'ZH', 'ZI', 'ZJ', 'ZK', 'ZL', 'ZM', 'ZN', 'ZO', 'ZP', 'ZQ', 'ZR', 'ZS', 'ZT', 'ZU', 'ZV', 'ZW', 'ZX', 'ZY', 'ZZ'
  ]

  loadExcelFormGroup: FormGroup = this._formBuilder.group({
    startRow: [2, Validators.required],
    folioColumn: ['A'],
    speciesColumn: ['B'],
    varietyColumn: ['C'],
    producerColumn: ['D'],
    speciesSizeColumn: ['E'],
    harvestDateColumn: ['F'],
    qualityControlDateColumn: ['G'],
    packagingColumn: ['H'],
    palletTypeColumn: ['I'],
    tagColumn: ['J'],
    bagTypeColumn: ['K'],
    boxSizeColumn: ['L'],
    netWeightColumn: ['M'],
    fridgeStorageColumn: ['N'],
    boxesPerPalletColumn: ['N'],
    markColumn: ['O'],
    containerNumberColumn: ['P'],
    gradeColumn: ['Q'],
    temptaleColumn: ['R'],
    originCountryColumn: ['S'],
    certificate1Column: ['T'],
    certificate2Column: ['U'],
    certificate3Column: ['V'],
    loadWeekColumn: ['W']
  })

  constructor(private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _authService: AuthGateway,
    private _messageService: MessageService,
    public translate: TranslateService,
    private _formBuilder: FormBuilder,
    private _frozenFruitPalletService: FrozenFruitPalletGateway) {
    this._activatedRoute.params.subscribe(params => {
      this.orderDetailId = params['orderDetailId']
    })
  }

  ngOnInit(): void {
    this.profile = this._authService.getUser()

    let breadcrumbMenuItemLabel1 = 'Import order detail'
    let breadcrumbMenuItemLabel2 = 'Verify pallets'
    let breadcrumbHomeItemLabel = 'My import orders'

    if(this.profile) {
      switch(this.profile.lang) {
        case 'en':
          breadcrumbMenuItemLabel1 = 'Import order detail'
          breadcrumbMenuItemLabel2 = 'Verify pallets'
          breadcrumbHomeItemLabel = 'My import orders'
          break
        case 'es':
          breadcrumbMenuItemLabel1 = 'Detalle de orden de importación'
          breadcrumbMenuItemLabel2 = 'Verificar  pallets'
          breadcrumbHomeItemLabel = 'Ordenes de importación'
          break
      }
    }

    this.breadcrumbMenuItems = [
      {label: breadcrumbMenuItemLabel1, command: () => { this.goToImportOrderPage() }},
      {label: breadcrumbMenuItemLabel2, disabled: true}
    ]
    this.breadcrumbHome = {label: breadcrumbHomeItemLabel, command: () => this.goToImportOrderList() }

    this.generatePalletObjects()
  }

  generatePalletObjects() {
    for(let i = 0; i < 20; i++) {
      const pallet: FrozenFruitPallet = {}
      this.pallets.push(pallet)
    }
  }

  showLoadExcelDialog() {
    this.excelFile = null
    this.excelSheets = null
    this.startRow = 1
    this.folioColumn = ''
    this.caliberColumn = ''
    this.producerColumn = ''
    this.visibleLoadExcelDialog = true
  }

  onSelectExcelFile(uploader: any) {
    this.excelFile = uploader.files[0]
    readSheetNames(uploader.files[0]).then((sheetNames) => {
      this.excelSheets = sheetNames
    })

    uploader.clear()
  }

  onLoadExcel() {
    this.loading = true
    if(this.loadExcelFormGroup.valid) {
      if(!this.excelFile)
        return

      if(!this.selectedSheet)
        return

      readXlsxFile(this.excelFile, {sheet: this.selectedSheet}).then((rows) => {
        if(rows) {
          this.pallets.forEach(pallet => {
            pallet.code = ''
            pallet.excelImportSpecies = ''
            pallet.excelImportVariety = ''
            pallet.excelImportSpeciesSize = ''
            pallet.excelImportProducer = ''
            pallet.excelImportHarvestDate = ''
            pallet.excelImportQualityControlDate = ''
            pallet.excelImportPackaging = ''
            pallet.excelImportPalletType = ''
            pallet.excelImportTag = ''
            pallet.excelImportBagType = ''
            pallet.excelImportBoxSize = ''
            pallet.excelImportNetWeight = ''
            pallet.excelImportFridgeStorage = ''
            pallet.excelImportBoxesPerPallet = ''
            pallet.excelImportMark = ''
            pallet.excelImportContainerNumber = ''
            pallet.excelImportGrade = ''
            pallet.excelImportTemptale = ''
            pallet.excelImportOriginCountry = ''
            pallet.excelImportCertificate1 = ''
            pallet.excelImportCertificate2 = ''
            pallet.excelImportCertificate3 = ''
            pallet.excelImportLoadWeek = ''
          })

          rows.forEach(row => {
            for(let k=0; k < 23; k++) {
              if(row[k]) {
                let value = row[k]
                if(value instanceof Date) {
                  value.setDate(value.getDate() + 1)
                  value = moment(value).format('DD/MM/YYYY')
                }
                else if (value) {
                  value = value.toString()
                }
                row[k] = value
              }
            }
          })

          let startRow = this.loadExcelFormGroup.controls['startRow'].value
          let folioColumn = this.loadExcelFormGroup.controls['folioColumn'].value
          let speciesColumn = this.loadExcelFormGroup.controls['speciesColumn'].value
          let varietyColumn = this.loadExcelFormGroup.controls['varietyColumn'].value
          let producerColumn = this.loadExcelFormGroup.controls['producerColumn'].value
          let speciesSizeColumn = this.loadExcelFormGroup.controls['speciesSizeColumn'].value
          let harvestDateColumn = this.loadExcelFormGroup.controls['harvestDateColumn'].value
          let qualityControlDateColumn = this.loadExcelFormGroup.controls['qualityControlDateColumn'].value
          let packagingColumn = this.loadExcelFormGroup.controls['packagingColumn'].value
          let palletTypeColumn = this.loadExcelFormGroup.controls['palletTypeColumn'].value
          let tagColumn = this.loadExcelFormGroup.controls['tagColumn'].value
          let bagTypeColumn = this.loadExcelFormGroup.controls['bagTypeColumn'].value
          let boxSizeColumn = this.loadExcelFormGroup.controls['boxSizeColumn'].value
          let netWeightColumn = this.loadExcelFormGroup.controls['netWeightColumn'].value
          let fridgeStorageColumn = this.loadExcelFormGroup.controls['fridgeStorageColumn'].value
          let boxesPerPalletColumn = this.loadExcelFormGroup.controls['boxesPerPalletColumn'].value
          let markColumn = this.loadExcelFormGroup.controls['markColumn'].value
          let containerNumberColumn = this.loadExcelFormGroup.controls['containerNumberColumn'].value
          let gradeColumn = this.loadExcelFormGroup.controls['gradeColumn'].value
          let temptaleColumn = this.loadExcelFormGroup.controls['temptaleColumn'].value
          let originCountryColumn = this.loadExcelFormGroup.controls['originCountryColumn'].value
          let certificate1Column = this.loadExcelFormGroup.controls['certificate1Column'].value
          let certificate2Column = this.loadExcelFormGroup.controls['certificate2Column'].value
          let certificate3Column = this.loadExcelFormGroup.controls['certificate3Column'].value
          let loadWeekColumn = this.loadExcelFormGroup.controls['loadWeekColumn'].value

          const rowStartIndex = startRow - 1
          let j = 0

          let cellValue = (row: any, column: any, pallet: any, property: string) => {
            if(column) {
              const index =  this.columns.indexOf(column.toUpperCase())
              pallet[property] = row[index] ? row[index].toString() : ''
            }
          }

          for(let i = rowStartIndex; i < rows.length; i++) {
            const row = rows[i]
            let pallet = this.pallets[j]
            if(pallet) {
              cellValue(row, folioColumn, pallet, 'code')
              cellValue(row, speciesColumn, pallet, 'excelImportSpecies')
              cellValue(row, varietyColumn, pallet, 'excelImportVariety')
              cellValue(row, producerColumn, pallet, 'excelImportProducer')
              cellValue(row, speciesSizeColumn, pallet, 'excelImportSpeciesSize')
              cellValue(row, harvestDateColumn, pallet, 'excelImportHarvestDate')
              cellValue(row, qualityControlDateColumn, pallet, 'excelImportQualityControlDate')
              cellValue(row, packagingColumn, pallet, 'excelImportPackaging')
              cellValue(row, palletTypeColumn, pallet, 'excelImportPalletType')
              cellValue(row, tagColumn, pallet, 'excelImportTag')
              cellValue(row, bagTypeColumn, pallet, 'excelImportBagType')
              cellValue(row, boxSizeColumn, pallet, 'excelImportBoxSize')
              cellValue(row, netWeightColumn, pallet, 'excelImportNetWeight')
              cellValue(row, fridgeStorageColumn, pallet, 'excelImportFridgeStorage')
              cellValue(row, boxesPerPalletColumn, pallet, 'excelImportBoxesPerPallet')
              cellValue(row, markColumn, pallet, 'excelImportMark')
              cellValue(row, containerNumberColumn, pallet, 'excelImportContainerNumber')
              cellValue(row, gradeColumn, pallet, 'excelImportGrade')
              cellValue(row, temptaleColumn, pallet, 'excelImportTemptale')
              cellValue(row, originCountryColumn, pallet, 'excelImportOriginCountry')
              cellValue(row, certificate1Column, pallet, 'excelImportCertificate1')
              cellValue(row, certificate2Column, pallet, 'excelImportCertificate2')
              cellValue(row, certificate3Column, pallet, 'excelImportCertificate3')
              cellValue(row, loadWeekColumn, pallet, 'excelImportLoadWeek')
            }
            j++
          }
        }
      })
      this.loading = false
      this.visibleLoadExcelDialog = false
    } else {
      this.loading = false
    }
  }

  save() {
    console.log(this.orderDetailId)
    if(this.orderDetailId) {
      let palletsRequest: FrozenFruitPallet[] = []

      this.pallets.forEach(x => {
        if(x.code)
          palletsRequest.push(x)
      })

      if(palletsRequest.length < 1) {
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Pallet.IncompletePalletNumbersError') })
        return
      }

      let request: FrozenFruitExporterVerifyPalletsRequest = {
        frozenFruitOrderDetailId: this.orderDetailId,
        frozenFruitPallets: palletsRequest
      }

      this.loading = true
      this._frozenFruitPalletService.createFromExporterVerify(request).subscribe({
        next: (v) => {
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: this.translate.instant('MessageService.Pallet.CreateSuccess') })
          this.loading = false
          this.success = true
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          let errorMessage = ''
          switch(this.profile?.lang) {
            case 'es':
              errorMessage = e.error.messageEs
              break
            case 'en':
              errorMessage = e.error.messageEn
              break
          }
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.Pallet.CreateError') + ' ' + errorMessage })
        },
        complete: () => console.info('Pallets created successfully')
      })
    }
  }

  onCancel() {
    this._router.navigate(['/' + Routes.userImportOrdersCurrent])
  }

  goToImportOrderPage() {
  }

  goToImportOrderList() {
  }

  getErrorMessage(formGroup: FormGroup, fieldName: string): string {
    return ErrorMessageUtil.getErrorMessage(formGroup, this.translate, fieldName)
  }
}
