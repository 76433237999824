import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { DocumentServiceGateway } from 'src/app/application/gateway/DocumentServiceGateway';
import { PalletGateway } from 'src/app/application/gateway/PalletGateway';
import { QualityControlReviewGateway } from 'src/app/application/gateway/QualityControlReviewGateway';
import { QualityControlReview, QualityControlReviewItemList } from 'src/app/domain/models/QualityControlReview';
import { Routes } from 'src/app/infrastructure/routes';

@Component({
  selector: 'app-admin-manage-qc-reviews',
  templateUrl: './admin-manage-qc-reviews.component.html',
  styleUrls: ['./admin-manage-qc-reviews.component.css'],
  providers: [MessageService]
})
export class AdminManageQcReviewsComponent implements OnInit {

  qcReviews: QualityControlReviewItemList[] = []

  loading: boolean = false

  constructor(private _router: Router,
    private _messageService: MessageService,
    private _formBuilder: FormBuilder,
    private _palletService: PalletGateway,
    private _authService: AuthGateway,
    public translate: TranslateService,
    private _documentService: DocumentServiceGateway,
    private _qualityControlReviewService: QualityControlReviewGateway) {

  }

  ngOnInit(): void {
    this.getAllQualityControlReviews()
  }

  getAllQualityControlReviews() {
    this.loading = true
    this._qualityControlReviewService.getAll().subscribe({
      next: (v) => {
        this.qcReviews = v
        this.loading = false
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: 'Error recuperando las revisiones QC' + e.error })
      },
      complete: () => console.info('Pallets created successfully')
    })
  }

  viewOriginQCReviewDocument(review: QualityControlReview) {
    this._documentService.getOriginQCReviewDocument(review.guid).subscribe((resultBlob: any) => {
      const downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }

  viewOriginQCConsolidationDocument(review: QualityControlReview) {
    this._documentService.getOriginQCConsolidationDocument(review.guid).subscribe((resultBlob: any) => {
      const downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }

  goToQCReview(guid: string) {
    this._router.navigate(['/' + Routes.adminQCReview, guid])
  }

  goToQCReviewDocuments(guid: string) {
    this._router.navigate(['/' + Routes.adminQCReviewDocuments, guid])
  }
}
