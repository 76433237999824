import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PaymentQualityControlGateway } from "../gateway/PaymentQualityControlGateway";
import { CreatePaymentQualityControlRequest, PaymentQualityControl } from "src/app/domain/models/PaymentQualityControl";
import { Routes } from "src/app/infrastructure/routes";


@Injectable({providedIn: 'root'})
export class PaymentQualityControlService extends PaymentQualityControlGateway {

    constructor(private _http: HttpClient)
    {
        super()
    }

    create(request: CreatePaymentQualityControlRequest): Observable<PaymentQualityControl> {
        
        return this._http.post<PaymentQualityControl>(Routes.paymentControlQualityApi + '/Create', this.getFormData(request));
    }

    approvePayment(paymentQualityControlId: number): Observable<any> {
        
        return this._http.put<any>(Routes.paymentControlQualityApi + '/ApprovedPayment',{}, {params: {paymentQualityControlId}})
    }

    getAllByImportOrderDetailId(paymentQualityControlId: string): Observable<any> {
        
        return this._http.get<any>(Routes.paymentControlQualityApi + '/GetAllByImportOrderDetailId', {params : {paymentQualityControlId}})
    }

    getAllByOrderId(orderId: string): Observable<PaymentQualityControl[]> {
        
        return this._http.get<any>(Routes.paymentControlQualityApi + '/GetAllByOrderId', { params: {orderId}})
    }


    getFormData(request: CreatePaymentQualityControlRequest)
    {
        const formData = new FormData();

        if(request !== undefined)
        {
					formData.append("importOrderDetailId", request.importOrderDetailId);
					formData.append("paymentMethod", request.paymentMethod.toString());
					formData.append("value", request.value.toString());

					if(request.description)
							{
									formData.append("description", request.description);
							}

					if(request.swiftId)
							{
									formData.append("swiftId", request.swiftId);
							}

					if(request.pdf)
					{
							formData.append("pdf", request.pdf)
					}
					
					if (request.contactName) {
						formData.append("contactName", request.contactName.toString());
					}
					if (request.companyName) {
						formData.append("companyName", request.companyName.toString());
					}
					if (request.contactPhone) {
						formData.append("contactPhone", request.contactPhone.toString());
					}
			}
			return formData;
    }

}