<app-user-dashboard [userMenuActiveItemIndex]="0">
  <p-breadcrumb class="max-w-full" [model]="breadcrumbMenuItems" [home]="breadcrumbHome"></p-breadcrumb>
  <p-card header="{{'UserPages.UserImportRequest.EditImportRequest.PageTitle' | translate}}">
    <div id="step1" *ngIf="step == step1">
      <form [formGroup]="step1FormGroup" >
        <h2 style="color: #8c2b87;">{{'UserPages.UserExportOffers.NewExportOffer.StepLabel' | translate}} 1 {{'UserPages.UserExportOffers.NewExportOffer.StepOfLabel' | translate}} 3</h2>
        <p-progressBar [value]="33.33" color="#8c2b87"></p-progressBar>
        <h2>{{'UserPages.UserImportRequest.NewImportRequest.PageTitle' | translate}}</h2>

        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
            <label for="species">{{'UserPages.UserImportRequest.NewImportRequest.SpecieFieldLabel' | translate}} *</label>
            <p-dropdown id="species"
              formControlName="speciesId"
              [options]="species"
              optionValue="id"
              [filter]="true"
              [filterBy]="profile?.lang == 'en' ? 'name' : 'nameEs'"
              [showClear]="true"
              placeholder="{{'UserPages.UserImportRequest.NewImportRequest.SpecieFieldPlaceholder' | translate}}"
              (onChange)="loadSpeciesParams()">
              <ng-template pTemplate="selectedItem">
                <div class="flex align-items-center gap-2" *ngIf="selectedSpecies">
                    <img [src]="selectedSpecies.imageUrl" style="width: 18px" alt=""/>
                    <div>{{ getSpeciesName(selectedSpecies) }}</div>
                </div>
              </ng-template>
              <ng-template let-specie pTemplate="item">
                  <div class="flex align-items-center gap-2">
                      <img [src]="specie.imageUrl" style="width: 18px" alt=""/>
                      <div>{{ getSpeciesName(specie) }}</div>
                  </div>
              </ng-template>
            </p-dropdown>
            <small *ngIf="submitStep2 && step1FormGroup.controls['speciesId'].invalid"
              class="p-error">{{getErrorMessage(step1FormGroup, 'speciesId')}}</small>
          </div>
          <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
            <label for="variety">{{'UserPages.UserImportRequest.NewImportRequest.VarietyFieldLabel' | translate}} *</label>
            <p-dropdown id="variety"
              formControlName="varietyId"
              [options]="varieties"
              optionValue="id"
              [filter]="true"
              filterBy="name"
              [showClear]="true"
              (onChange)="onChangeVariety()"
              placeholder="{{'UserPages.UserImportRequest.NewImportRequest.VarietyFieldPlaceholder' | translate}}">
              <ng-template pTemplate="selectedItem">
                <div class="flex align-items-center gap-2" *ngIf="selectedVariety">
                  <img [src]="selectedVariety.urlIcon" style="width: 18px"/>
                  <div>{{ selectedVariety.name }}</div>
                </div>
              </ng-template>
              <ng-template let-variety pTemplate="item">
                  <div class="flex align-items-center gap-2">
                      <img [src]="variety.urlIcon" style="width: 18px"/>
                      <div>{{ variety.name }}</div>
                  </div>
              </ng-template>
            </p-dropdown>
            <small *ngIf="submitStep2 && step1FormGroup.controls['varietyId'].invalid"
              class="p-error">{{getErrorMessage(step1FormGroup, 'varietyId')}}</small>
          </div>
          <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
            <label for="caseQuantityPerPallet">{{'UserPages.UserImportRequest.NewImportRequest.CasePerPalletsFieldLabel' |
              translate}} *</label>
            <p-inputNumber inputId="caseQuantityPerPallet" formControlName="caseQuantityPerPallet" [min]="1"
              placeholder="{{'UserPages.UserImportRequest.NewImportRequest.CasePerPalletsFieldPlaceholder' | translate}}"></p-inputNumber>
            <small *ngIf="submitStep2 && step1FormGroup.controls['caseQuantityPerPallet'].invalid" class="p-error">{{getErrorMessage(step1FormGroup, 'caseQuantityPerPallet')}}</small>
          </div>
          <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
            <label for="pricePerCase">{{'UserPages.UserImportRequest.NewImportRequest.PricePerCase' | translate}} *</label>
            <p-inputNumber inputId="pricePerCase" [min]="0" formControlName="pricePerCase" mode="currency" currency="USD" locale="en-US" placeholder="{{'UserPages.UserImportRequest.NewImportRequest.PricePerCase' | translate}}"></p-inputNumber>
            <small *ngIf="submitStep2 && step1FormGroup.controls['pricePerCase'].invalid" class="p-error">{{getErrorMessage(step1FormGroup, 'pricePerCase')}}</small>
          </div>
          <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
            <label for="palletQuantity">{{'UserPages.UserImportRequest.NewImportRequest.PalletsPerWeekFieldLabel' |
              translate}} *</label>
            <p-inputNumber inputId="palletQuantity" formControlName="palletQuantity" [useGrouping]="false" [min]="20"
              placeholder="{{'UserPages.UserImportRequest.NewImportRequest.PalletsPerWeekFieldPlaceholder' | translate}}"></p-inputNumber>
            <small *ngIf="submitStep2 && step1FormGroup.controls['palletQuantity'].invalid" class="p-error">{{getErrorMessage(step1FormGroup, 'palletQuantity')}}</small>
          </div>
          <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
            <label for="incoterm">{{'UserPages.UserImportRequest.NewImportRequest.IncotermsFieldLabel' | translate}} *</label>
            <p-dropdown inputId="incoterm" formControlName="incoterm" [options]="incotermsOptions" optionLabel="label"
              optionValue="value" placeholder="{{'UserPages.UserImportRequest.NewImportRequest.IncotermsFieldPlaceholder' | translate}}"></p-dropdown>
            <small *ngIf="submitStep2 && step1FormGroup.controls['incoterm'].invalid" class="p-error">{{getErrorMessage(step1FormGroup, 'incoterm')}}</small>
          </div>
          <div class="field col-12 md:col-3 lg:col-6">|
            <label for="paymentAgainstBL" style="font-weight: bolder; font-size: calc(100% - 4px);  text-overflow: ellipsis;">{{ paymentBLTitle | translate }} *</label>
            <p-dropdown id="paymentAgainstBL" formControlName="paymentAgainstBL" [options]="paymentBLItems" optionLabel="label" optionValue="value" 
            placeholder="{{'UserPages.UserExportOffers.NewExportOffer.PaymentAgainstBLLabel' | translate}}"></p-dropdown>
            <small *ngIf="submit && step1FormGroup.controls['paymentAgainstBL'].invalid" class="p-error">{{getErrorMessage(step1FormGroup, 'paymentAgainstBL')}}</small>
            <small *ngIf="showPaymentBLPercent">{{'UserPages.UserExportOffers.NewExportOffer.FinalPaymentSubjectQCReview' | translate}} {{finalPaymentPercent}}%</small>
          </div>
          <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
            <label for="countryFromId">{{'UserPages.UserImportRequest.NewImportRequest.OriginCountryFieldLabel' | translate}}</label>
            <p-dropdown inputId="countryFromId" id="countries" [options]="countriesOrigin"
              formControlName="countryFromId" optionLabel="name" optionValue="id" [filter]="true" filterBy="name" [showClear]="true" placeholder="{{'UserPages.UserImportRequest.NewImportRequest.OriginCountryFieldPlaceholder' | translate}}" (onChange)="onChangeCountryFrom()">
              <ng-template pTemplate="selectedItem">
                <div class="flex align-items-center gap-2" *ngIf="selectedCountryFrom">
                  <img [src]="'../../../../assets/img/flags/' + selectedCountryFrom.code + '.png'" style="width: 18px" alt=""/>
                  <span>{{ selectedCountryFrom | countryDescription }}</span>
                </div>
              </ng-template>
              <ng-template let-country pTemplate="item">
                <div class="flex align-items-center gap-2">
                  <img [src]="'../../../../assets/img/flags/' + country.code + '.png'" style="width: 18px" alt=""/>
                  <div>{{ country | countryDescription }}</div>
                </div>
              </ng-template>
            </p-dropdown>
          </div>
          <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
            <label for="countryId">{{'UserPages.UserImportRequest.NewImportRequest.DestinationCountryFieldLabel' | translate}} *</label>
            <p-dropdown inputId="countryId" id="countries" [options]="countries" formControlName="countryId"
              optionLabel="name" optionValue="id" (onChange)="loadDestinationPorts()" [filter]="true" filterBy="name" [showClear]="true" placeholder="{{'UserPages.UserImportRequest.NewImportRequest.DestinationCountryFieldPlaceholder' | translate}}" (onChange)="onChangeCountryTo()">
              <ng-template pTemplate="selectedItem">
                <div class="flex align-items-center gap-2" *ngIf="selectedCountryTo">
                  <img [src]="'../../../../assets/img/flags/' + selectedCountryTo.code + '.png'" style="width: 18px" alt=""/>
                  <span>{{ selectedCountryTo | countryDescription }}</span>
                </div>
              </ng-template>
              <ng-template let-country pTemplate="item">
                <div class="flex align-items-center gap-2">
                  <img [src]="'../../../../assets/img/flags/' + country.code + '.png'" style="width: 18px" alt=""/>
                  <div>{{ country | countryDescription }}</div>
                </div>
              </ng-template>
            </p-dropdown>
            <small *ngIf="submitStep2 && step1FormGroup.controls['countryId'].invalid" class="p-error">{{getErrorMessage(step1FormGroup, 'countryId')}}</small>
          </div>
          <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
            <label for="portId">{{'UserPages.UserImportRequest.NewImportRequest.DestinationPortFieldLabel' | translate}} *</label>
            <p-dropdown inputId="portId" id="ports" [options]="destinationPorts" formControlName="portId"
              optionLabel="name" optionValue="id" [filter]="true" filterBy="name" [showClear]="true"
              placeholder="{{'UserPages.UserImportRequest.NewImportRequest.DestinationPortFieldPlaceholder' | translate}}"></p-dropdown>
            <small *ngIf="submitStep2 && step1FormGroup.controls['portId'].invalid" class="p-error">{{getErrorMessage(step1FormGroup, 'portId')}}</small>
          </div>
        </div>
          <div class="grid" style="margin-top: 30px;">
            <div class="col-6"></div>
              <div class="col-6 flex justify-content-end">
                <p-button [style]="{'width': '145px'}" label="{{'UserPages.UserExportOffers.NewExportOffer.NextButton' | translate}}" (onClick)="showStep2()" [loading]="loading"></p-button>
              </div>
            </div>
      </form>
    </div>

    <div id="step2" *ngIf="step == step2">
      <form [formGroup]="step2FormGroup">
        <h2 style="color: #8c2b87;">{{'UserPages.UserExportOffers.NewExportOffer.StepLabel' | translate}} 2 {{'UserPages.UserExportOffers.NewExportOffer.StepOfLabel' | translate}} 3</h2>
          <p-progressBar [value]="66.66" color="#8c2b87"></p-progressBar>
        <h2>{{'UserPages.UserImportRequest.NewImportRequest.PageTitle2' | translate}}</h2>
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
            <label for="speciesSize">{{'UserPages.UserImportRequest.NewImportRequest.SpeciesSizeFieldLabel' | translate}} *</label>
            <input pInputText [maxlength]="formLimit.speciesSizeLimit" inputId="speciesSize" formControlName="speciesSize" placeholder="{{'UserPages.UserImportRequest.NewImportRequest.SpeciesSizeFieldPlaceholder' | translate}}">
            <small *ngIf="submitStep2 && step2FormGroup.controls['speciesSize'].invalid" class="p-error">{{getErrorMessage(step2FormGroup, 'speciesSize')}}</small>
          </div>
          <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
            <label for="netKilos">{{'UserPages.UserImportRequest.NewImportRequest.NetKiloFieldLabel' | translate}} *</label>
            <input pInputText inputId="netKilos" [maxlength]="formLimit.netKilosLimit" formControlName="netKilos" placeholder="{{'UserPages.UserImportRequest.NewImportRequest.NetKiloFieldPlaceholder' | translate}}" />
            <small *ngIf="submitStep2 && step2FormGroup.controls['netKilos'].invalid" class="p-error">{{getErrorMessage(step2FormGroup, 'netKilos')}}</small>
          </div>

          <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
            <label for="boxType">{{'UserPages.UserImportRequest.NewImportRequest.BoxTypeFieldLabel' | translate}} *</label>
            <p-dropdown inputId="boxType" formControlName="boxType" [options]="boxTypes" optionLabel="label"
              optionValue="value" placeholder="{{'UserPages.UserImportRequest.NewImportRequest.BoxTypeFieldPlaceholder' | translate}}"></p-dropdown>
            <small *ngIf="submitStep2 && step2FormGroup.controls['boxType'].invalid" class="p-error">{{getErrorMessage(step2FormGroup, 'boxType')}}</small>
          </div>
          <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
            <label for="category">{{'UserPages.UserImportRequest.NewImportRequest.CategoryFieldLabel' | translate}} *</label>
            <input pInputText  [maxlength]="formLimit.categoryLimit" inputId="category" formControlName="category" placeholder="{{'UserPages.UserImportRequest.NewImportRequest.CategoryFieldPlaceholder' | translate}}" />
            <small *ngIf="submitStep2 && step2FormGroup.controls['category'].invalid" class="p-error">{{getErrorMessage(step2FormGroup, 'category')}}</small>
          </div>
          <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
            <label for="basePallets">{{'UserPages.UserImportRequest.NewImportRequest.BasePalletsFieldLabel' | translate}} *</label>
            <p-dropdown inputId="basePallets" formControlName="basePallets" [options]="basePallets" optionLabel="label"
              optionValue="value" placeholder="{{'UserPages.UserImportRequest.NewImportRequest.BasePalletsFieldPlaceholder' | translate}}">
            </p-dropdown>
            <small *ngIf="submitStep2 && step2FormGroup.controls['basePallets'].invalid" class="p-error">{{getErrorMessage(step2FormGroup, 'basePallets')}}</small>
          </div>
        </div>
        <h2>{{'UserPages.UserImportRequest.NewImportRequest.PageTitle4' | translate}}</h2>
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-4 lg:col-3">
            <label for="certificateType">{{'UserPages.UserProductCertificates.Form.CertificateTypeLabel' | translate}} *</label>
            <p-multiSelect formControlName="productCertificateType" [(ngModel)]="certificateTypesSelected" [options]="certificateTypes" placeholder="{{'UserPages.UserProductCertificates.Form.SelectText' | translate}}" optionLabel="name" optionValue="id" option [showHeader]="false" (onChange)="filterMultiselect($event.value)" required>
            </p-multiSelect>
            <small *ngIf="submitStep2 && step2FormGroup.controls['productCertificateType'].invalid" class="p-error">{{getErrorMessage(step2FormGroup, 'productCertificateType')}}</small>
          </div>
          <div class="field col-12 md:col-4 lg:col-3">
            <label for="otherCertificate">{{'UserPages.UserProductCertificates.Form.OtherLabel' | translate}}</label>
            <input pInputText [maxlength]="formLimit.otherCertificateLimit" inputId="otherCertificate" formControlName="otherCertificate" placeholder="{{'UserPages.UserProductCertificates.Form.OtherLabel' | translate}}"/>
            <small *ngIf="submitStep2 && step2FormGroup.controls['otherCertificate'].invalid" class="p-error">{{getErrorMessage(step2FormGroup, 'otherCertificate')}}</small>
          </div>
        </div>

        <h2>{{'UserPages.UserImportRequest.NewImportRequest.PageTitle5' | translate}}</h2>
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-4 lg:col-3">
            <label for="packagings">{{'UserPages.UserImportRequest.NewImportRequest.PackagingLabel' | translate}} *</label>
            <p-multiSelect formControlName="packagings" [(ngModel)]="packagingSelected" [options]="packagingList" placeholder="{{'UserPages.UserImportRequest.NewImportRequest.SelectText' | translate}}" optionLabel="name" optionValue="id" option [showHeader]="false" (onChange)="filterPackagingMultiselect($event.value)" required>
            </p-multiSelect>
            <small *ngIf="submitStep2 && step2FormGroup.controls['packagings'].invalid" class="p-error">{{getErrorMessage(step2FormGroup, 'packagings')}}</small>
          </div>
          <div class="field col-12 md:col-4 lg:col-3">
            <label for="otherPackaging">{{'UserPages.UserImportRequest.NewImportRequest.OtherLabel' | translate}}</label>
            <input pInputText [maxlength]="formLimit.otherPackagingLimit" inputId="otherPackaging" formControlName="otherPackaging" placeholder="{{'UserPages.UserImportRequest.NewImportRequest.OtherLabel' | translate}}"/>
            <small *ngIf="submitStep2 && step2FormGroup.controls['otherPackaging'].invalid" class="p-error">{{getErrorMessage(step2FormGroup, 'otherPackaging')}}</small>
          </div>
        </div>

        <h2>
          {{'UserPages.UserImportRequest.NewImportRequest.PageTitle3' | translate}}
        </h2>
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
            <label>{{'UserPages.UserImportRequest.NewImportRequest.StartWeekFieldLabel' | translate}} *</label>
            <p-inputNumber id="fromWeek" formControlName="fromWeek" [showButtons]="true" [useGrouping]="false" [min]="1"
              [max]="52" placeholder="{{'UserPages.UserImportRequest.NewImportRequest.StartWeekFieldPlaceholder' | translate}}" [required]="true"/>
            <small *ngIf="submitStep2 && step2FormGroup.controls['fromWeek'].invalid"
              class="p-error">{{getErrorMessage(step2FormGroup, 'fromWeek')}}</small>
          </div>
          <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
            <label>{{'UserPages.UserImportRequest.NewImportRequest.StartYearFieldLabel' | translate}} *</label>
            <p-dropdown id="fromYearWeek" formControlName="fromYearWeek" [options]="years" optionLabel="label"
              optionValue="value" [showClear]="true" placeholder="{{'UserPages.UserImportRequest.NewImportRequest.StartYearFieldPlaceholder' | translate}}" [required]="true"></p-dropdown>
            <small *ngIf="submitStep2 && step2FormGroup.controls['fromYearWeek'].invalid"
              class="p-error">{{getErrorMessage(step2FormGroup, 'fromYearWeek')}}</small>
          </div>
          <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
            <label>{{'UserPages.UserImportRequest.NewImportRequest.EndWeekFieldLabel' | translate}}</label>
            <p-inputNumber id="toWeek" formControlName="toWeek" [showButtons]="true" [useGrouping]="false" [min]="1"
              [max]="52" placeholder="{{'UserPages.UserImportRequest.NewImportRequest.EndWeekFieldPlaceholder' | translate}}" />
          </div>
          <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
            <label>{{'UserPages.UserImportRequest.NewImportRequest.EndYearFieldLabel' | translate}}</label>
            <p-dropdown id="toYearWeek" formControlName="toYearWeek" [options]="years" optionLabel="label"
              optionValue="value" [showClear]="true" placeholder="{{'UserPages.UserImportRequest.NewImportRequest.EndYearFieldPlaceholder' | translate}}"></p-dropdown>
          </div>

          <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
            <label>{{'UserPages.UserImportRequest.NewImportRequest.TotalWeeksText' | translate}}: </label>
            <span style="font-weight: bolder; font-size: 1.5em;"> {{totalWeeks}}</span>
          </div>
          <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
            <label>{{'UserPages.UserImportRequest.NewImportRequest.TotalPalletsScheduled' | translate}}: </label>
            <span style="font-weight: bolder; font-size: 1.5em;"> {{totalPallets}}</span>
          </div>
          <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
            <label>{{'UserPages.UserImportRequest.NewImportRequest.TotalContainersPerWeek' | translate}}: </label>
            <span style="font-weight: bolder; font-size: 1.5em;"> {{containersPerWeek}}</span>
          </div>
          <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
            <label>{{'UserPages.UserImportRequest.NewImportRequest.TotalContainers' | translate}}: </label>
            <span style="font-weight: bolder; font-size: 1.5em;"> {{totalContainers}}</span>
          </div>
        </div>

        <div class="grid" style="margin-top: 30px;">
          <div class="col-6 flex justify-content-start ">
            <p-button styleClass="p-button-outlined" label="{{'UserPages.UserExportOffers.NewExportOffer.BackButton' | translate}}" (onClick)="showStep1()" [loading]="loading" [style]="{'width': '145px'}"></p-button>
          </div>
          <div class="col-6 flex justify-content-end">
                <p-button [style]="{'width': '145px'}" label="{{'UserPages.UserExportOffers.NewExportOffer.NextButton' | translate}}" (onClick)="showStep3()" [loading]="loading"></p-button>
          </div>
        </div>
      </form>
    </div>
    <div id="step3" *ngIf="step == step3">
      <h2>{{'UserPages.UserImportRequest.EditImportRequest.ImagesTitle' | translate}}</h2>
      <p-table [value]="pictures">
        <ng-template pTemplate="caption">
          <div class="flex justify-content-start align-items-center gap-4">
            <p-fileUpload #imagesUploader
              mode="basic"
              chooseLabel="{{'UserPages.UserImportRequest.EditImportRequest.LoadButton' | translate}}"
              [fileLimit]="1"
              [auto]="true"
              accept=".jpg, .jpeg, .png"
              [maxFileSize]="3000000"
              (onSelect)="onSelectImage($event, imagesUploader)"
              [disabled]="pictures.length === 3"></p-fileUpload>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
        </ng-template>
        <ng-template pTemplate="body" let-picture let-rowIndex="rowIndex">
          <tr>
            <td>
              <img [src]="getImageUrl(picture)"
                style="border-radius: 7px; width: 100%; max-width: 100px; height: auto;" />
            </td>
            <td>
              {{getImageName(picture)}}
            </td>
            <td>
              <div class="flex justify-content-end align-items-center gap-4">
                <p-button icon="pi pi-times" (click)="onRemoveImage(rowIndex)"></p-button>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <div class="grid" style="margin-top: 30px;">
        <div class="col-6 flex justify-content-start ">
          <p-button label="{{'UserPages.UserImportRequest.EditImportRequest.BackButton' | translate}}" (click)="showStep2()" styleClass="p-button-outlined"></p-button>
        </div>
        <div class="col-6 flex justify-content-end ">
          <p-button label="{{'UserPages.UserImportRequest.EditImportRequest.SaveButton' | translate}}" [loading]="loading" (click)="save()" [style]="{'margin-left': '20px'}"></p-button>
        </div>
      </div>
    </div>

  </p-card>
</app-user-dashboard>
<p-toast></p-toast>
