<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
<div class="flex flex-column align-items-center" style="width: 100%; margin: 0px;">
  <div class="flex align-items-center justify-content-between w-full grid" [style]="withStyle">
    <div *ngIf="isAuthenticated" class="flex align-items-center justify-content-between lg:justify-content-start col-12 md:col-12 lg:col-4">
      <a [routerLink]="'/'"><img src="../../../../assets/img/fruzty-logo.png" style="width: 170px; height: auto" alt="fruzty-logo"/></a>
      <div class="inline lg:hidden"><app-switch-lang [showOnlyIcon]="true" [showClear]="false"></app-switch-lang></div>
      <p-button *ngIf="isAuthenticated" class="inline lg:hidden" pRipple (onClick)="displaySidebar = true" icon="pi pi-bars" styleClass="p-button-secondary p-button-outlined"></p-button>

      <p-button class="hidden lg:inline" styleClass="p-button-sm" *ngIf="profile?.type === 1 && isAuthenticated" icon="pi pi-plus" pRipple (onClick)="openCreateExportOfferOptionDialog()" label="{{'HomeHeader.UserDashboard.NewOfferButton' | translate}}"></p-button>
      <p-button class="hidden lg:inline" styleClass="p-button-sm" *ngIf="profile?.type === 2 && isAuthenticated" icon="pi pi-plus" pRipple (onClick)="goToCreateImportOrderRequeriment()" label="{{'HomeHeader.UserDashboard.NewImportRequestButton' | translate}}"></p-button>
    </div>
    <div *ngIf="isAuthenticated" class="col-12 md:col-12 lg:col-8 hidden lg:block">
      <app-user-menu></app-user-menu>
    </div>
    <div *ngIf="!isAuthenticated" class="bg-container">
      <div class="container">
        <a [routerLink]="'/'"><img src="../../../../assets/img/fruzty-logo.png" style="width: 170px; height: auto" alt="fruzty-logo"/></a>
        <div class="section-right">
          <p-button (onClick)="goToMarketplaceAd()" styleClass="p-button-secondary p-button-sm" [style]="{'max-height': '37px'}">
            <span class="material-symbols-outlined" style="font-size: 25px;">nutrition</span>
            <span style="margin-left: 6px">{{'HomeHeader.Offers' | translate}}</span>
          </p-button>
          <app-switch-lang></app-switch-lang>
          <p-button icon="pi pi-sign-in" label="{{'HomeHeader.UserDashboard.LoginButtonLabel' | translate}}" (onClick)="goToLogin()"></p-button>
        </div>
      </div>
    </div>
  </div>
  <app-disclaimer></app-disclaimer>
  <div class="grid w-full" style="min-height: 530px; margin-top: 10px;">
    <div class="col-2 hidden lg:inline" *ngIf="!fullScreen">
      <p-card [style]="{'margin-bottom': '10px', 'background-color': '#8c2b87', 'color': 'white'}">
        <div class="flex">
          <a routerLink="/user-panel" style="text-decoration: none;">
            <span class="pi pi-user" style="font-size: 37px; color: white; margin-left: 20px;"></span>
          </a>
          <div style="margin-left: 10px;">
            {{'HomeHeader.UserDashboard.HelloText' | translate}}<br/>
            <span>{{profileCompanyName}}</span><br/>
            <a routerLink="/user-panel" style="text-decoration: none;"><span style="font-weight: bold; color: white;">{{userCompleteName}}</span></a><br/>
            <span>{{profileName}}</span>
          </div>
        </div>
      </p-card>
      <p-panelMenu [model]="items"></p-panelMenu>
    </div>
    <div [class]="contentCssClass">
      <ng-content></ng-content>
    </div>
  </div>
</div>

<p-sidebar [(visible)]="displaySidebar" [modal]="false" [transitionOptions]="'1000ms'" [style]="{'width':'300px'}">
  <app-side-menu></app-side-menu>
</p-sidebar>

<p-toast></p-toast>

<p-dialog [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleCreateExportOfferOptionDialog" [style]="{width: '80%'}">
  <app-export-offer-create-option></app-export-offer-create-option>
</p-dialog>


