<app-user-dashboard [userMenuActiveItemIndex]="0" [activeItem]="'orders'">
  <p-card [style]="{'min-height':'200px'}">
    <ng-template pTemplate="header">
      <div class="flex justify-content-between flex-wrap align-items-center" style="padding: 15px;">
        <h2>{{'UserPages.UserPurchaseOrders.PageTitle' | translate}}</h2>
      </div>
      <div>
        <p-tabMenu [model]="tabMenuItems" [scrollable]="true" [activeItem]="activeItem" [style]="{padding: '15px'}"></p-tabMenu>
      </div>
    </ng-template>
    <ng-template pTemplate="body">
      <p-table [value]="orders"
        [paginator]="true"
        [rows]="10"
        [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[10, 25, 50]"
        [loading]="loading"
        responsiveLayout="stack"
        [breakpoint]="'600px'"
        [globalFilterFields]="['exportOfferId', 'importOrderId']">
        <ng-template pTemplate="caption">
          <div class="flex align-items-center justify-content-end">
            <button icon="pi pi-shopping-cart" pButton pRipple (click)="goToOrders()"
              style="background-color: #8c2b87;"
              label="{{'MarketplacePage.Spot.OrderContainers' | translate}}"
              *ngIf="profile?.profileType === 'Importer'"></button>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th>
              <div class="flex justify-content-center align-items-center">
                {{'UserPages.UserPurchaseOrders.PurchaseTable.ImageHeader' | translate}}
              </div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">
                {{'UserPages.UserPurchaseOrders.PurchaseTable.Specie/VarietyHeader' | translate}}
              </div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">
                {{'UserPages.UserPurchaseOrders.PurchaseTable.TotalHeader' | translate}}
              </div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">
                {{'UserPages.UserPurchaseOrders.PurchaseTable.CodeHeader' | translate}}
              </div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">
                {{'UserPages.UserPurchaseOrders.PurchaseTable.PalletsHeader' | translate}}
              </div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">
                {{'UserPages.UserPurchaseOrders.PurchaseTable.ProductTypeHeader' | translate}}
              </div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">
                {{'UserPages.UserPurchaseOrders.PurchaseTable.DurationHeader' | translate}}
              </div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">
                {{'UserPages.UserPurchaseOrders.PurchaseTable.ActionsHeader' | translate}}
              </div>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-order>
          <tr>
            <td>
              <img [src]="getPictureUrl(order.pictureUrl)" style="border-radius: 7px; width: 100%; max-width: 100px; height: auto;" alt=""/>
            </td>
            <td>
              <div class="flex justify-content-center align-items-center">
                <div>
                  <ng-container *ngFor="let detail of order.details">
                    {{ getSpeciesName(detail) }}
                    <br *ngIf="getSpeciesName(detail) !== ''">
                    {{detail.varietyName}}
                  </ng-container>
                </div>
              </div>
            </td>
            <td>
              <div class="flex justify-content-center align-items-center">
                <span style="font-weight: bold;">{{ order.totalPrice | currency: 'USD':'US$'}}</span>
              </div>
            </td>
            <td>
              <div class="flex justify-content-center align-items-center">
                {{ getOrderTitle(order) }}
                <br *ngIf="getOrderTitle(order) !== ''">
                {{ order.created | date: 'dd-MM-yyyy'}}
              </div>
            </td>
            <td>
              <div class="flex justify-content-center align-items-center">
                {{ order.totalPallets }}
              </div>
            </td>
            <td>
              <div class="flex justify-content-center align-items-center">
                {{ order.productType }}
              </div>
            </td>
            <td>
              <div class="flex justify-content-center align-items-center">
                {{ getDurationDate(order.duration) }}
              </div>
            </td>
            <td>
              <div class="flex justify-content-end align-items-center gap-4">
                <p-button label="{{'UserPages.UserPurchaseOrders.DetailsButtonLabel' | translate}}" [loading]="loading" (onClick)="goToOrderDetailPage(order.id)"></p-button>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </ng-template>
  </p-card>
</app-user-dashboard>
