<!--
<p-carousel [value]="getPictures()" [numVisible]="1" [circular]="false" [responsiveOptions]="responsiveOptions" *ngIf="getPictures().length > 0">
  <ng-template let-picture pTemplate="item">
    <div class="mb-3 flex align-items-center justify-content-center" *ngIf="picture.url">
      <img [src]="completeUrl(picture.url)" style="border-radius: 7px; width: auto; max-height: 500px; max-width: 100%;" alt="Image" />
    </div>
  </ng-template>
</p-carousel>
[(value)]="completeUrl(picture.url)"

<img *ngIf="getPictures().length < 1 && offer" class="w-9 sm:w-16rem xl:w-16rem shadow-2 block xl:block mx-auto border-round" [src]="getExportOfferImageUrl()" alt="image-specie"/>
<img *ngIf="getPictures().length < 1 && offerAd" class="w-9 sm:w-16rem xl:w-16rem shadow-2 block xl:block mx-auto border-round" [src]="getExportOfferAdImageUrl()" alt="image-specie"/>
[value]="getPictures()"
-->

<!-- <span style="border-radius: 7px; padding: 5px; font-weight: bold;" *ngIf="getPictures().length < 1">{{'MarketplacePage.WishList.ExportOfferModal.NoImageText' | translate}}</span> -->

<p-galleria
  [value]="getPictures()"
  [responsiveOptions]="responsiveOptions"
  [numVisible]="5"
  [thumbnailsPosition]="position"
  >
  <ng-template pTemplate="item" let-item>
    <div
      style="
      display:flex;
      justify-content: center;
      align-items: center;
      height:430px;
      padding:0 1em;
      "
    >
    <img [src]="completeUrl(item.url)"
    style="
      object-fit: cover;
      width: 100%;
      height: 100%;
    " />
  </div>

  </ng-template>
  <ng-template pTemplate="thumbnail" let-item>
    <div
      class="grid grid-nogutter justify-content-center"
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 80px;
        background: #eaeaea;
        border-radius: 4px;
        overflow: hidden;
      "
      >
      <img [src]="completeUrl(item.url)"
        style="width: auto;"
        style="height: 100px;"

      />
    </div>
  </ng-template>
</p-galleria>

