<div *ngIf="importRequestInformation">
  <div class="grid">
    <div class="col-12 md:col-4 lg:col-4">
      <p-carousel [value]="importRequestInformation.pictures" [numVisible]="1" [numScroll]="1" [circular]="false"
        [responsiveOptions]="responsiveOptions">
        <ng-template let-picture pTemplate="item">
          <div class="mb-3" style="height: 50%;">
            <img [src]="completeUrl(picture.url)" style="border-radius: 7px; width: 100%; height: auto;"
              alt="Image" />
          </div>
        </ng-template>
      </p-carousel>
    </div>
    <div class="col-12 md:col-8 lg:col-8">
      <p-card header="{{'ImportRequestDetailComponent.Title1' | translate }}">
        <div class="grid">
          <div class="col-12 md:col-3 lg:col-3">{{'ImportRequestDetailComponent.SpeciesLabel' | translate }}</div>
          <div class="col-12 md:col-3 lg:col-3">
            <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{
              importRequestInformation.importRequest.variety.species.nameEs }}</span>
          </div>
          <div class="col-12 md:col-3 lg:col-3">{{'ImportRequestDetailComponent.VarietyLabel' | translate }}</div>
          <div class="col-12 md:col-3 lg:col-3">
            <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{
              importRequestInformation.importRequest.variety.name }}</span>
          </div>
          <div class="col-12 md:col-3 lg:col-3">{{'ImportRequestDetailComponent.IncotermsLabel' | translate }}</div>
          <div class="col-12 md:col-3 lg:col-3">
            <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{
              importRequestInformation.importRequest.typeSale }}</span>
          </div>
          <div class="col-12 md:col-3 lg:col-3">{{'ImportRequestDetailComponent.OriginCountryLabel' | translate }}</div>
          <div class="col-12 md:col-3 lg:col-3">
            <span style="border-radius: 7px; padding: 5px; font-weight: bold;" *ngIf="importRequestInformation.importRequest.countryFrom !== null">{{
              importRequestInformation.importRequest.countryFrom?.name }}</span>
            <span style="border-radius: 7px; padding: 5px; font-weight: bold;" *ngIf="importRequestInformation.importRequest.countryFrom === null">{{
              'ImportRequestDetailComponent.OriginCountryAll' | translate }}</span>
          </div>
          <div class="col-12 md:col-3 lg:col-3">{{'ImportRequestDetailComponent.CaseQuantityPerPalletLabel' | translate }}</div>
          <div class="col-12 md:col-3 lg:col-3">
            <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{
              importRequestInformation.importRequest.caseQuantityPerPallet }}</span>
          </div>
          <div class="col-12 md:col-3 lg:col-3">{{'ImportRequestDetailComponent.PricePerCase' | translate }}</div>
          <div class="col-12 md:col-3 lg:col-3">
            <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{
              importRequestInformation.importRequest.pricePerCase | currency: 'USD':'US$' }}</span>
          </div>
          <div class="col-12 md:col-3 lg:col-3">{{'ImportRequestDetailComponent.NetKilosLabel' | translate }}</div>
          <div class="col-12 md:col-3 lg:col-3">
            <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{
              importRequestInformation.importRequest.netKilos ? importRequestInformation.importRequest.netKilos + ' Kg.' : availableLabel }}</span>
          </div>
          <div class="col-12 md:col-3 lg:col-3">{{'ImportRequestDetailComponent.GrossKilosLabel' | translate }}</div>
          <div class="col-12 md:col-3 lg:col-3">
            <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{
              importRequestInformation.importRequest.grossKilos ? importRequestInformation.importRequest.grossKilos + ' Kg.' : availableLabel }}</span>
          </div>
          <div class="col-12 md:col-3 lg:col-3">{{'ImportRequestDetailComponent.SpeciesSizeLabel' | translate }}</div>
          <div class="col-12 md:col-3 lg:col-3">
            <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{
              importRequestInformation.importRequest.speciesSize }}</span>
          </div>
          <div class="col-12 md:col-3 lg:col-3">{{'ImportRequestDetailComponent.BoxTypeLabel' | translate }}</div>
          <div class="col-12 md:col-3 lg:col-3">
            <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{
              getBoxType(importRequestInformation.importRequest.boxType )}} </span>
          </div>
          <div class="col-12 md:col-3 lg:col-3">{{'ImportRequestDetailComponent.CategoryLabel' | translate }}</div>
          <div class="col-12 md:col-3 lg:col-3">
            <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{
              importRequestInformation.importRequest.category ? importRequestInformation.importRequest.category : availableLabel  }}</span>
          </div>
          <div class="col-12 md:col-3 lg:col-3">{{'ImportRequestDetailComponent.DestinationCountryLabel' | translate }}</div>
          <div class="col-12 md:col-3 lg:col-3">
            <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{
              importRequestInformation.importRequest.country | countryDescription }} </span>
          </div>
          <div class="col-12 md:col-3 lg:col-3">{{'ImportRequestDetailComponent.DestinationPortLabel' | translate }}</div>
          <div class="col-12 md:col-3 lg:col-3">
            <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{
              importRequestInformation.importRequest.port.name }} </span>
          </div>
          <div class="col-12 md:col-3 lg:col-3">{{'ImportRequestDetailComponent.StartWeekLabel' | translate }}</div>
          <div class="col-12 md:col-3 lg:col-3">
            <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{
              importRequestInformation.importRequest.fromWeek }} - {{importRequestInformation.importRequest.fromYearWeek}}</span>
          </div>
          <div class="col-12 md:col-3 lg:col-3">{{'ImportRequestDetailComponent.EndWeekLabel' | translate }}</div>
          <div class="col-12 md:col-3 lg:col-3">
            <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{
              importRequestInformation.importRequest.toWeek }} - {{importRequestInformation.importRequest.toYearWeek}}</span>
          </div>
          <div class="col-12 md:col-3 lg:col-3">{{'ImportRequestDetailComponent.PalletQuantityLabel' | translate }}</div>
          <div class="col-12 md:col-3 lg:col-3">
            <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{importRequestInformation.importRequest.palletQuantity}}</span>
          </div>
          <div class="col-12 md:col-3 lg:col-3">{{'ImportRequestDetailComponent.TotalWeekLabel' | translate }}</div>
          <div class="col-12 md:col-3 lg:col-3">
            <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{getTotalWeeksImportRequest(importRequestInformation.importRequest)}}</span>
          </div>
          <div class="col-12 md:col-3 lg:col-3">{{'ImportRequestDetailComponent.TotalPalletsLabel' | translate }}</div>
          <div class="col-12 md:col-3 lg:col-3">
            <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{getTotalPalletsImportRequest(importRequestInformation.importRequest)}}</span>
          </div>
          <div class="col-12 md:col-3 lg:col-3">{{'ImportRequestDetailComponent.ContainerPerWeekPallet' | translate }}</div>
          <div class="col-12 md:col-3 lg:col-3">
            <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{getTotalContainersPerWeek(importRequestInformation.importRequest)}}</span>
          </div>
          <div class="col-12 md:col-3 lg:col-3">{{'ImportRequestDetailComponent.TotalContainers' | translate }}</div>
          <div class="col-12 md:col-3 lg:col-3">
            <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{getTotalContainersImportRequest(importRequestInformation.importRequest)}}</span>
          </div>
        </div>
      </p-card>
    </div>
  </div>
</div>
