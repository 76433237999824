<app-admin-dashboard>
  <p-breadcrumb class="max-w-full" [model]="breadcrumbMenuItems" [home]="breadcrumbHome"></p-breadcrumb>

  <div class="flex justify-content-between flex-wrap align-items-center" style="padding: 15px;">
    <h2>Completar pallets de órden de compra</h2>
  </div>

  <div *ngIf="!success">
    <p-table #dt1
      [value]="exportOfferSuggestions"
      [(selection)]="selectedOfferSuggestions"
      dataKey="exportOffer.id"
      [paginator]="true"
      [rows]="10"
      [showCurrentPageReport]="true"
      [rowsPerPageOptions]="[10, 25, 50]"
      [loading]="loading">

      <ng-template pTemplate="caption">
        <div class="flex justify-content-between align-items-center">
          <div>
            Cantidad de pallets requeridos: {{requiredPallets}}
          </div>
          <p-button label="Sugerir" (onClick)="save()" [disabled]="exportOfferSuggestions.length < 1"></p-button>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 4rem">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th><div class="flex justify-content-center align-items-center">País</div></th>
          <th><div class="flex justify-content-center align-items-center">Especie/Variedad</div></th>
          <th><div class="flex justify-content-center align-items-center">Fecha cosecha</div></th>
          <th><div class="flex justify-content-center align-items-center">Ubicación</div></th>
          <th><div class="flex justify-content-center align-items-center">Cajas x pallet</div></th>
          <th><div class="flex justify-content-center align-items-center">Precio unitario</div></th>
          <th><div class="flex justify-content-center align-items-center">Pallets disponibles</div></th>
          <th><div class="flex justify-content-center align-items-center">Precio total</div></th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-offer>
        <tr>
          <td>
            <p-tableCheckbox [value]="offer"></p-tableCheckbox>
          </td>
          <td><div class="flex justify-content-center align-items-center">{{offer.exportOffer.fridgeStorage?.contactCountry | countryDescription}}</div></td>
          <td><div class="flex justify-content-center align-items-center">{{offer.exportOffer.variety.species.name}}/{{offer.exportOffer.variety.name}}</div></td>
          <td><div class="flex justify-content-center align-items-center">{{offer.exportOffer.harvestDate | date: 'dd-MM-y'}}</div></td>
          <td><div class="flex justify-content-center align-items-center">{{offer.exportOffer.fridgeStorage?.contactCity}}</div></td>
          <td><div class="flex justify-content-center align-items-center">{{offer.exportOffer.caseQuantityPerPallet}}</div></td>
          <td><div class="flex justify-content-center align-items-center">{{offer.exportOffer.listPrice | currency: 'USD':'US$'}}</div></td>
          <td><div class="flex justify-content-center align-items-center">{{offer.exportOffer.availablePallets}}</div></td>
          <td><div class="flex justify-content-center align-items-center">{{getTotalPrice(offer.exportOffer, requiredPallets) | currency: 'USD':'US$'}}</div></td>
          <td><div class="flex justify-content-end align-items-center"><p-button icon="pi pi-eye" pTooltip="Ver oferta" (onClick)="openOfferDetailDialog(offer)"></p-button></div></td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div *ngIf="success">
    <div class="flex align-items-center justify-content-center flex-column">
      <i class="pi pi-check-circle" style="font-size: 2.5rem"></i>
      <h1>Se crearon las sugerencias exitosamente</h1>
      <p-button label="Aceptar" (click)="onAccept()"></p-button>
    </div>
  </div>
</app-admin-dashboard>

<p-dialog [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleOfferDetailDialog" [style]="{width: '80%'}">
  <app-export-offer-detail [offer]="selected" [productCertificateTypes]="selectedProductCertificateTypes" [packagingTypes]="selectedPackagingTypes"></app-export-offer-detail>
  <ng-template pTemplate="footer">
    <p-button label="Cerrar" (onClick)="visibleOfferDetailDialog = false"></p-button>
  </ng-template>
</p-dialog>
<p-toast></p-toast>
