import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ImportOrderDetailInformation, UploadImportOrderDetailPdfRequest } from "src/app/domain/models/ImportOrder";
import { CreatePalletPriceRequest, CreatePalletsRequest, MasterLoad, NewPallet, Pallet, PalletInstructiveFormat, PalletPrice, UpdatePalletsRequest } from "src/app/domain/models/Pallet";
import { Routes } from "src/app/infrastructure/routes";
import { PalletGateway } from "../gateway/PalletGateway";

@Injectable({providedIn: 'root'})
export class PalletService extends PalletGateway {

  constructor(private _http: HttpClient) {
    super()
  }

  Create(request: CreatePalletsRequest): Observable<Pallet[]> {
    return this._http.post<Pallet[]>(Routes.palletApi + '/Create', request);
  }

  createClosedSale(request: CreatePalletsRequest): Observable<any> {
    return this._http.post<Pallet[]>(Routes.palletApi + '/CreateClosedSale', request);
  }

  getByImportOrderDetailId(importOrderDetailId: number): Observable<ImportOrderDetailInformation> {
    return this._http.get<ImportOrderDetailInformation>(Routes.palletApi + '/GetByImportOrderDetailId', { params: {importOrderDetailId}})
  }

  update(request: CreatePalletsRequest): Observable<Pallet[]> {
    return this._http.put<Pallet[]>(Routes.palletApi + '/Update', request)
  }

  adminVerifyPallets(request: UpdatePalletsRequest): Observable<Pallet[]> {
    return this._http.put<Pallet[]>(Routes.palletApi + '/AdminVerifyPallets', request)
  }

  qcCompanyVerifyPallets(request: UpdatePalletsRequest): Observable<Pallet[]> {
    return this._http.put<Pallet[]>(Routes.palletApi + '/QCCompanyVerifyPallets', request)
  }

  getMasterLoad(year: number): Observable<MasterLoad[]> {
    return this._http.get<MasterLoad[]>(Routes.palletApi + '/GetMasterLoad', { params: {year}})
  }

  uploadQCReviewPdf(request: UploadImportOrderDetailPdfRequest): Observable<any> {
    return this._http.put<Pallet[]>(Routes.importOrderDetailApi + '/UploadQCReviewPdf', this.getUploadFileFormData(request))
  }

  uploadQCContainerConsolidationPdf(request: UploadImportOrderDetailPdfRequest): Observable<any> {
    return this._http.put<Pallet[]>(Routes.importOrderDetailApi + '/UploadQCContainerConsolidationPdf', this.getUploadFileFormData(request))
  }

  private getUploadFileFormData(request: UploadImportOrderDetailPdfRequest) {
    const formData = new FormData()
    formData.append("importOrderDetailId", request.importOrderDetailId.toString())
    formData.append("pdf", request.pdf)
    return formData;
  }

  createPalletPrice(request: CreatePalletPriceRequest): Observable<PalletPrice[]> {
      return this._http.post<PalletPrice[]>(Routes.palletApi + '/CreatePalletPrice', request)
  }

  getPalletPriceConfiguration(importOrderDetailGuid: string): Observable<PalletPrice[]> {
    return this._http.get<PalletPrice[]>(Routes.palletApi + '/GetPriceConfiguration', {params: {importOrderDetailGuid}})
  }

  getPalletPriceConfigurationFromArray(pallets: NewPallet[]): Observable<PalletPrice[]> {
    return this._http.post<PalletPrice[]>(Routes.palletApi + '/AskConfiguration', {pallets: pallets})
  }
  
  getPalletsInstructiveFormat(importOrderDetailGuid: string): Observable<ImportOrderDetailInformation> {
    return this._http.get<ImportOrderDetailInformation>(Routes.palletApi + '/GetReportPreview', { params: {importOrderDetailGuid}})
  }
}
