import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, ConfirmEventType, MenuItem, MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { ExportOfferGateway } from 'src/app/application/gateway/ExportOfferGateway';
import { ExportOffer, ExportOfferInformation, ExportOfferListItem } from 'src/app/domain/models/ExportOffer';
import { ProductCertificateType } from 'src/app/domain/models/ProductCertificateType';
import { BasicUser } from 'src/app/domain/models/User';
import { Routes } from 'src/app/infrastructure/routes';
import { ExportOfferCreateOptionComponent } from 'src/app/ui/components/export-offer/export-offer-create-option/export-offer-create-option.component';
import { ExportOfferUtils } from 'src/app/ui/util/export-offer-utils';
import { environment } from '../../../../../environments/environment'
import { Packaging } from 'src/app/domain/models/Packaging';
import { Species } from 'src/app/domain/models/Species';
import { Variety } from 'src/app/domain/models/Variety';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SpeciesGateway } from 'src/app/application/gateway/SpeciesGateway';
import { VarietyGateway } from 'src/app/application/gateway/VarietyGateway';
import { SpeciesUtils } from 'src/app/ui/util/species-utils';
import * as moment from 'moment';
import { ExportOfferFiltersComponent } from './export-offer-filters/export-offer-filters.component';
import { DocumentServiceGateway } from 'src/app/application/gateway/DocumentServiceGateway';

@Component({
  selector: 'app-user-export-offers',
  templateUrl: './user-export-offers.component.html',
  styleUrls: ['./user-export-offers.component.css'],
  providers: [ConfirmationService, MessageService]
})
export class UserExportOffersComponent implements OnInit {
  @ViewChild(ExportOfferFiltersComponent) filterComponent: ExportOfferFiltersComponent | undefined;

  tabMenuItems: MenuItem[] = []
  tabMenuActiveItem!: MenuItem
  offerMenuItems: MenuItem[] = []
  products: any[] = []
  loading: boolean = false
  offers: ExportOfferListItem[] = []
  offersFiltered: ExportOfferListItem[] = []
  selected: ExportOffer | undefined
  selectedProductCertificateTypes: ProductCertificateType[] = []
  selectedPackagingTypes: Packaging[] = []
  visibleOfferDetailDialog: boolean = false
  profile: BasicUser | undefined

  species: Species[] = []
  varieties: Variety[] = []
  selectedSpecies: Species | undefined
  selectedVariety: Variety | undefined

  filterSelectedCount: number = 0;

  visibleCreateExportOfferOptionDialog: boolean = false
  displayFiltersSidebar: boolean = false

  statusItem: any[] = []

  constructor(private _router: Router,
    private _messageService: MessageService,
    private _confirmationService: ConfirmationService,
    private _authService: AuthGateway,
    private _exportOfferService: ExportOfferGateway,
    private _formBuilder: FormBuilder,
    private _speciesService: SpeciesGateway,
    private _varietyService: VarietyGateway,
    private _documentService: DocumentServiceGateway,
    public translate: TranslateService) {
      this.profile = this._authService.getUser()

      this.statusItem = [
        { label: this.profile?.lang === "es" ? "Inactiva" : "Inactive", value: 0 },
        { label: this.profile?.lang === "es" ? "Activa" : "Active", value: 1 },
        { label: this.profile?.lang === "es" ? "Vencida" : "Expired", value: 2 }
      ]
  }

  formGroupFilter: FormGroup = this._formBuilder.group({
    speciesId: [null],
    varietyId: [null],
    status: [null],
    date: [null],
    id: ''
  })

  ngOnInit(): void {
    this.getAll()
    this.loadSpecies()
  }

  getAll() {
    this.loading = true
    this._exportOfferService.getAllByUsername().subscribe({
      next: (v) => {
        this.offersFiltered = v
        this.offers = v
        this.configureExportOffer()
        this.setFilters()
        this.loading = false
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.ExportOffer.ManyRetrieveError') })
      },
      complete: () => console.info('Export Offers query successfully')
    })
  }

  configureExportOffer(): void {
    if(this.offersFiltered) {
      this.offersFiltered.forEach(o => {
        o.expanded = false
      })
    }
  }

  getImageUrl(offer: ExportOfferListItem): string {
    if(offer.pictures && offer.pictures.length > 0){
      for(let i = 0; i < offer.pictures.length; i++) {
        if(offer.pictures[i].url)
          return environment.apiUrl + offer.pictures[i].url
      }
    }
    return ''
  }

  setVisibleOffer(offer: ExportOfferListItem) {
    if (offer) {
      offer.expanded = !offer.expanded
    }
  }

  setSelected(exportOffer: ExportOffer, opt: number = -1) {
    this.selected = exportOffer
    if(this.selected.productCertificateTypes)
      this.selectedProductCertificateTypes = this.selected.productCertificateTypes

    if(this.selected.packagingTypes)
      this.selectedPackagingTypes = this.selected.packagingTypes

    if (opt === 0) this.goToEditExportOfferPage()
    else if (opt === 1) this.goToExportOfferDocumentsPage()
    else if (opt === 2) this.desactiveSelected()
    else if (opt === 3) this.activeSelected()
  }

  goToNewExportOfferPage() {
    this._router.navigate(['/' + Routes.newExportOffer, ''])
  }

  goToEditExportOfferPage() {
    if(this.selected) {
      this._router.navigate(['/' + Routes.newExportOffer, {exportOfferId: this.selected?.guid}])
    }
  }

  @ViewChild(ExportOfferCreateOptionComponent) exportOfferCreateOptionComponent: ExportOfferCreateOptionComponent | undefined
  openCreateExportOfferOptionDialog() {
    this.exportOfferCreateOptionComponent?.reset()
    this.visibleCreateExportOfferOptionDialog = true
  }

  duplicateSelected() {
    if(this.selected) {
      this._exportOfferService.duplicate(this.selected.id).subscribe({
        next: (v) => {
          this._messageService.add({ severity: 'info',
            summary: this.translate.instant('UserPages.UserExportOffers.DuplicateActionMessage.SuccessSummary'),
            detail: this.translate.instant('UserPages.UserExportOffers.DuplicateActionMessage.SuccessDetail') })
          this.getAll()
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('UserPages.UserExportOffers.DuplicateActionMessage.ErrorSummary'),
            detail: this.translate.instant('UserPages.UserExportOffers.DuplicateActionMessage.ErrorDetail') })
        },
        complete: () => console.info('Export Offer duplicate successfully')
      })
    }
  }

  desactiveSelected() {
    if(this.selected) {
      this._confirmationService.confirm({
        message: this.translate.instant('UserPages.UserExportOffers.DeactiveConfirmDialog.Message'),
        header: this.translate.instant('UserPages.UserExportOffers.DeactiveConfirmDialog.Header'),
        icon: 'pi pi-info-circle',
        acceptLabel: this.translate.instant('UserPages.UserExportOffers.YesLabel'),
        rejectLabel: this.translate.instant('UserPages.UserExportOffers.NotLabel'),
        accept: () => {
          if(this.selected) {
            this._exportOfferService.desactive(this.selected.id).subscribe({
              next: (v) => {
                this._messageService.add({ severity: 'info',
                  summary: this.translate.instant('UserPages.UserExportOffers.DeactiveConfirmDialog.SuccessSumary'),
                  detail: this.translate.instant('UserPages.UserExportOffers.DeactiveConfirmDialog.SuccessDetail') })
                this.getAll()
              },
              error: (e) => {
                console.log(e)
                this._messageService.add({ severity: 'error',
                  summary: this.translate.instant('UserPages.UserExportOffers.DeactiveConfirmDialog.ErrorSummary'),
                  detail: this.translate.instant('UserPages.UserExportOffers.DeactiveConfirmDialog.ErrorDetail') });
              },
              complete: () => console.info('Export Offer inactive successfully')
            })
          }
        },
        reject: (type: any) => {
          switch (type) {
            case ConfirmEventType.REJECT:
                this._messageService.add({ severity: 'error',
                  summary: 'Rejected',
                  detail: this.translate.instant('UserPages.UserExportOffers.DeactiveConfirmDialog.RejectedDetail') })
                break;
            case ConfirmEventType.CANCEL:
                this._messageService.add({ severity: 'warn',
                  summary: 'Cancelled',
                  detail: this.translate.instant('UserPages.UserExportOffers.DeactiveConfirmDialog.CancelledDetail') })
                break;
          }
        }
      })
    }
  }

  activeSelected() {
    if(this.selected) {
      this._confirmationService.confirm({
        message: this.translate.instant('UserPages.UserExportOffers.ActiveConfirmDialog.Message'),
        header: this.translate.instant('UserPages.UserExportOffers.ActiveConfirmDialog.Header'),
        icon: 'pi pi-info-circle',
        acceptLabel: this.translate.instant('UserPages.UserExportOffers.YesLabel'),
        rejectLabel: this.translate.instant('UserPages.UserExportOffers.NotLabel'),
        accept: () => {
          if(this.selected) {
            this._exportOfferService.active(this.selected.id).subscribe({
              next: (v) => {
                this._messageService.add({ severity: 'info',
                  summary: this.translate.instant('UserPages.UserExportOffers.ActiveConfirmDialog.SuccessSumary'),
                  detail: this.translate.instant('UserPages.UserExportOffers.ActiveConfirmDialog.SuccessDetail') })
                this.getAll()
              },
              error: (e) => {
                console.log(e)
                this._messageService.add({ severity: 'error',
                  summary: this.translate.instant('UserPages.UserExportOffers.ActiveConfirmDialog.ErrorSummary'),
                  detail: this.translate.instant('UserPages.UserExportOffers.ActiveConfirmDialog.ErrorDetail') });
              },
              complete: () => console.info('Export Offer active successfully')
            })
          }
        },
        reject: (type: any) => {
            switch (type) {
                case ConfirmEventType.REJECT:
                    this._messageService.add({ severity: 'error',
                      summary: 'Rejected',
                      detail: this.translate.instant('UserPages.UserExportOffers.ActiveConfirmDialog.RejectedDetail') })
                    break;
                case ConfirmEventType.CANCEL:
                    this._messageService.add({ severity: 'warn',
                      summary: 'Cancelled',
                      detail: this.translate.instant('UserPages.UserExportOffers.ActiveConfirmDialog.CancelledDetail') })
                    break;
            }
        }
      })
    }
  }

  getExportOfferImageUrl(offer: ExportOfferInformation, index: number) {
    return ExportOfferUtils.getImageUrl(offer.exportOffer, offer.pictures,  index)
  }

  showOfferDetailDialog(exportOfferId: number) {
    this._exportOfferService.getById(exportOfferId).subscribe({
      next: (v) => {
        this.setSelected(v)
        this.loading = false
        this.visibleOfferDetailDialog = true
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.ExportOffer.ManyRetrieveError') })
      },
      complete: () => console.info('Export Offers query successfully')
    })
  }

  closeOfferDetailDialog() {
    this.visibleOfferDetailDialog = false
  }

  getBoxType(boxType: string): string {
    return this.profile ? ExportOfferUtils.getBoxTypeName(this.profile.lang, boxType) : ''
  }

  getIncludedLabel(included: boolean) {
    return included ? this.translate.instant('UserPages.UserExportOffers.Included') : this.translate.instant('UserPages.UserExportOffers.NotIncluded')
  }

  goToExportOfferDocumentsPage() {
    if(this.selected)
      this._router.navigate(['/' + Routes.exportOfferDocumentsPage, this.selected.id])
  }

  getSpeciesStaticName(exportOffer: ExportOfferListItem) {
    if(this.profile) {
      switch(this.profile.lang) {
        case 'en':
          return exportOffer.speciesName
        case 'es':
          return exportOffer.speciesNameEs
        default:
          return exportOffer.speciesNameEs
      }
    }
    return ''
  }

  getSpeciesName(species: Species): string {
    if(this.profile)
      return SpeciesUtils.getSpeciesName(this.profile.lang, species)
    return ''
  }

  openFiltersSidebar() {
    this.displayFiltersSidebar = true
  }

  loadSpecies() {
    this._speciesService.getAllByProductType(1).subscribe({
      next: (v) => {
        this.species = v
        this.loading = false
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Species.ManyRetrieveError') })
      },
      complete: () => console.info('Species query successfully')
    })
  }

  loadVarieties() {
    this.selectedSpecies = this.species.find(x => x.id === this.formGroupFilter.controls['speciesId'].value)
    this.varieties = []
    this.setFilters()
    if (this.selectedSpecies) {
      this._varietyService.getAllBySpecies(this.formGroupFilter.controls['speciesId'].value).subscribe({
        next: (v) => {
          this.varieties = v
          this.loading = false
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.Variety.ManyRetrieveError') })
        },
        complete: () => console.info('Variety query successfully')
      })
    }
  }

  onChangeVariety() {
    this.selectedVariety = this.varieties.find(x => x.id === this.formGroupFilter.controls['varietyId'].value)
    this.setFilters()
  }

  setFilters() {
    // Obtener el formulario de filtro del componente de filtro
    const filterForm = this.filterComponent?._formGroupFilter;

    // Llamar al método applyFilters del MainComponent con el formulario de filtro
    this.applyFilters(filterForm);
  }

  applyFilters(formGroupForeign: FormGroup | undefined) {
    this.offersFiltered = this.offers;

    if (this.formGroupFilter.controls['speciesId'].value !== null) {
      this.offersFiltered = this.offersFiltered.filter(item => {
        const speciesNameToCheck = this.profile?.lang === 'en' ? item.speciesName : item.speciesNameEs;
        return speciesNameToCheck === (this.profile?.lang === 'en' ? this.selectedSpecies?.name : this.selectedSpecies?.nameEs);
      });
    }

    if (this.formGroupFilter.controls['varietyId'].value !== null) {
      this.offersFiltered = this.offersFiltered.filter( item => item.varietyId === this.formGroupFilter.controls['varietyId'].value)
    }

    if (this.formGroupFilter.controls['status'].value !== null) {
      this.offersFiltered = this.offersFiltered.filter( item => item.status === this.formGroupFilter.controls['status'].value)
    }

    if (this.formGroupFilter.controls['date'].value !== null) {
      const formattedSelectedDate = moment(this.formGroupFilter.controls['date'].value).format('YYYY-MM-DD');
      this.offersFiltered = this.offersFiltered.filter(item => {
        const formattedCreatedDate = moment(item.created).format('YYYY-MM-DD');
        return formattedCreatedDate === formattedSelectedDate;
      });
    }

    if (this.formGroupFilter.controls['id'].value !== '') {
      this.offersFiltered = this.offersFiltered.filter( item => item.id == this.formGroupFilter.controls['id'].value)
    }

    // Validación de filtros de componente Filter
    if (formGroupForeign !== undefined) {
      this.filterSelectedCount = this.getFilterCount(formGroupForeign);
      if (this.filterSelectedCount > 0) {
        if (formGroupForeign.controls['harvestDate'].value !== null) {
          const formattedSelectedDate = moment(formGroupForeign.controls['harvestDate'].value).format('YYYY-MM-DD');
          this.offersFiltered = this.offersFiltered.filter(item => {
            const formattedCreatedDate = moment(item.harvestDate).format('YYYY-MM-DD');
            return formattedCreatedDate === formattedSelectedDate;
          });
        }

        if (formGroupForeign.controls['netKilos'].value !== '') {
          this.offersFiltered = this.offersFiltered.filter( item => item.netKilos == formGroupForeign.controls['netKilos'].value)
        }

        if (formGroupForeign.controls['marketCountryId'].value !== null) {
          this.offersFiltered = this.offersFiltered.filter( item => item.marketCountryFirst.id == formGroupForeign.controls['marketCountryId'].value)
        }

        if (formGroupForeign.controls['fridgeStorageId'].value !== null) {
          this.offersFiltered = this.offersFiltered.filter( item => item.fridgeStorageId == formGroupForeign.controls['fridgeStorageId'].value)
        }

        if (formGroupForeign.controls['speciesSize'].value !== '') {
          this.offersFiltered = this.offersFiltered.filter( item => item.speciesSize == formGroupForeign.controls['speciesSize'].value)
        }

        if (formGroupForeign.controls['price'].value !== '') {
          this.offersFiltered = this.offersFiltered.filter( item => item.listPrice == formGroupForeign.controls['price'].value)
        }
      }
    }

  }

  private getFilterCount(filterForm: FormGroup): number {
    let count = 0;
    Object.keys(filterForm.controls).forEach(key => {
      if (filterForm.get(key)?.value !== null && filterForm.get(key)?.value !== '') {
        count++;
      }
    });
    return count;
  }

  getStatusValue(status: number): string {
    const statusObj = this.statusItem.find(item => item.value === status);
    return statusObj ? statusObj.label : '';
  }

  getExportOfferQualityDocument(documents: any[] | undefined) {
    if (documents == undefined || documents.length <= 0) return;

    let docId = documents[0].id

    this._documentService.getExportOfferDocument2(docId).subscribe((resultBlob: any) => {
      var downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }

}

