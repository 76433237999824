import { ProgramRequest } from "src/app/domain/models/ProgramRequest";

export class ProgramRequestUtil {

    static getTotalPallets(programRequest: ProgramRequest)
    {
        const pallets = programRequest.palletQuantity
        const startWeek = programRequest.startWeek
        const startWeekYear = programRequest.startWeekYear
        const endWeek = programRequest.endWeek
        const endWeekYear = programRequest.endWeekYear

        if(startWeekYear && endWeekYear && startWeekYear === endWeekYear && endWeek > startWeek) {
            return ((endWeek - startWeek) + 1) * pallets
          }
      
          if (startWeekYear && endWeekYear && endWeekYear == startWeekYear + 1) {
            return ((52 - startWeek) + endWeek + 1) * pallets
          }
      
          return 0
    }

    
    static getTotalContainersPerWeek(programRequest: ProgramRequest): number {
        const pallets = programRequest.palletQuantity
    
        if(pallets % 20 == 0) {
          return pallets / 20
        }
        return 0
      }

      static getTotalContainers(programRequest: ProgramRequest): number {
        const pallets = ProgramRequestUtil.getTotalPallets(programRequest)
    
        if(pallets % 20 == 0) {
          return pallets / 20
        }
        return 0
      }

      static getTotalWeeks(startWeek: any, startWeekYear: any, endWeek: any, endWeekYear: any) {
        if(startWeekYear && endWeekYear && startWeekYear === endWeekYear && endWeek >= startWeek) {
          return (endWeek - startWeek) + 1
        }
    
        if (startWeekYear && endWeekYear && endWeekYear == startWeekYear + 1) {
          return ((52 - startWeek) + endWeek) + 1
        }
        return 0
      }
      
    static ISO8601_week_no(dt: Date): number 
      {
         var tdt = new Date(dt.valueOf());
         var dayn = (dt.getDay() + 6) % 7;
         tdt.setDate(tdt.getDate() - dayn + 3);
         var firstThursday = tdt.valueOf();
         tdt.setMonth(0, 1);
         if (tdt.getDay() !== 4) 
           {
          tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7);
            }
         return 1 + Math.ceil((firstThursday - tdt.valueOf()) / 604800000);
      }
}