import { TemplateBindingParseResult } from '@angular/compiler';
import { Component, Input } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { ConsigneeGateway } from 'src/app/application/gateway/ConsigneeGateway';
import { CountryGateway } from 'src/app/application/gateway/CountryGateway';
import { NotifierGateway } from 'src/app/application/gateway/NotifierGateway';
import { PackagingGateway } from 'src/app/application/gateway/PackagingGateway';
import { PortGateway } from 'src/app/application/gateway/PortGateway';
import { ProductCertificateTypeGateway } from 'src/app/application/gateway/ProductCertificateTypeGateway';
import { ProfileGateway } from 'src/app/application/gateway/ProfileGateway';
import { ProgramRequestGateway } from 'src/app/application/gateway/ProgramRequestGateway';
import { SpeciesGateway } from 'src/app/application/gateway/SpeciesGateway';
import { VarietyGateway } from 'src/app/application/gateway/VarietyGateway';
import { Consignee } from 'src/app/domain/models/Consignee';
import { Country } from 'src/app/domain/models/Country';
import { Notifier } from 'src/app/domain/models/Notifier';
import { Packaging } from 'src/app/domain/models/Packaging';
import { Port } from 'src/app/domain/models/Port';
import { ProductCertificateType } from 'src/app/domain/models/ProductCertificateType';
import { Exporter } from 'src/app/domain/models/Profile';
import { CreateProgramRequest, ProgramRequestInformation } from 'src/app/domain/models/ProgramRequest';
import { Species, SpeciesSize } from 'src/app/domain/models/Species';
import { BasicUser } from 'src/app/domain/models/User';
import { Variety } from 'src/app/domain/models/Variety';
import { isDivisor } from 'src/app/ui/util/custom-validators';
import { ErrorMessageUtil } from 'src/app/ui/util/error-message-util';
import { ExportOfferUtils } from 'src/app/ui/util/export-offer-utils';
import { SpeciesUtils } from 'src/app/ui/util/species-utils';
import { Routes } from 'src/app/infrastructure/routes';
import { Router } from '@angular/router';
import { formLimit } from 'src/app/ui/util/form-limit';

@Component({
  selector: 'app-user-program-request-create',
  templateUrl: './user-program-request-create.component.html',
  styleUrls: ['./user-program-request-create.component.css'],
  providers: [MessageService]
})
export class UserProgramRequestCreateComponent {

  submit: boolean = false
  submitStep1: boolean = false
  submitStep2: boolean = false
  loading: boolean = false

  profile: BasicUser | undefined


  species: Species[] = []
  varieties: Variety[] = []
  selectedSpecies: Species | undefined
  selectedVariety: Variety | undefined

  formLimit = formLimit;

  step: number = 1
  step1: number = 1
  step2: number = 2
  step3: number = 3
  finalStep: number = 4

  exporters: Exporter[] = []
  consignees: Consignee[] = []
  notifies: Notifier[] = []

  isEdit: boolean = false

  colors: string[] = []
  sizes: SpeciesSize[] = []
  qualityControlOptions: any[] = []
  boxTypes: any[] = []
  kilosPerBoxes: any[] = []
  basePallets: any[] = []
  countries: Country[] = []
  countriesOrigin: any[] = []
  destinationPorts: Port[] = []
  minDate: Date = new Date()
  maxDate: Date = new Date()

  initalYear: number = new Date().getFullYear();

  selectedCountryFrom: Country | undefined
  selectedCountryTo: Country | undefined

  certificateTypes: ProductCertificateType[] = []
  certificateTypesSelected: number[] = []

  packagingList: Packaging[] = []
  packagingSelected: number[] = []
  visibleInvite: boolean = false

  incotermsOptions: any[] = []



    // step: string = Steps.Step1

    uploadedImages: any[] = []
    uploadPdf: any[] = []
    uploadPurchaseOrderPdf: any[] = []
    years: any[] = []

    step1FormGroup: FormGroup = this._formBuilder.group({
      exporterId: [null, Validators.required],
      consigneeId: [null, Validators.required],
      notifyId: [null, Validators.required],
      speciesId: [null, Validators.required],
      varietyId: [null, Validators.required],
      caseQuantityPerPallet: [null, Validators.required],
      listPrice: [null, Validators.required],
      incoterms: ['EXW', Validators.required],
      paymentAgainstBL: [100],
      palletQuantity: [20, [Validators.required, isDivisor(20)]],
      countryId: ['', Validators.required],
      countryToId: [null, Validators.required],
      portToId: [null, Validators.required],
      importPurchaseOrder: ['']
    },
    {
      validators: [this.endWeekValidator(), this.endWeekYearValidator()]
    })

    step2FormGroup: FormGroup = this._formBuilder.group({
      netKilos: ['', Validators.required],
      boxType: ['', Validators.required],
      speciesSize: [''],
      category: [null],
      basePallets: [''],
      startWeek: [null, [Validators.required]],
      startWeekYear: [this.currentYear, [Validators.required]],
      endWeek: [null],
      endWeekYear: [null],
      productCertificateType: [''],
      otherCertificate: [{value: '', disabled: true}],
      packaging: [''],
      otherPackaging: [{value: '', disabled: true}],
    })

    constructor(
      private _router: Router,
      private _formBuilder: FormBuilder,
      private _messageService: MessageService,
      private _authService: AuthGateway,
      private _programRequestService: ProgramRequestGateway,
      private _varietyService: VarietyGateway,
      private _speciesService: SpeciesGateway,
      private _countryService: CountryGateway,
      private _portService: PortGateway,
      private _productCertificateTypeService: ProductCertificateTypeGateway,
      private _packagingService: PackagingGateway,
      private _consigneeService: ConsigneeGateway,
      private _notifyService: NotifierGateway,
      private _profileService: ProfileGateway,
      public translate: TranslateService

    ){
      this.profile = this._authService.getUser()


    }

    ngOnInit(): void {

  
  
      this.loadSpecies()
      this.loadCountries()
      this.loadProductCertificateTypes()
      this.loadPackagings()
      this.getExporters()
      this.getConsignees()
      this.getNotifies()
    }

    get currentYear(): number {
      return new Date().getFullYear()
    }


    endWeekYearValidator(): ValidatorFn {
      return (control: AbstractControl) : ValidationErrors | null => {
        if(!this.step2FormGroup)
          return null
  
        if(!this.step2FormGroup.controls['startWeekYear'].value)
          return null
  
        if(!this.step2FormGroup.controls['endWeekYear'].value)
          return null
  
        let startWeekYear = this.step2FormGroup.controls['startWeekYear'].value
        let endWeekYear = this.step2FormGroup.controls['endWeekYear'].value
  
        return endWeekYear && startWeekYear > endWeekYear ? {endWeekYearRangeError:true}: null;
      }
    }

    getExporters() {
      this.exporters = []
      this.loading = true
      if(this.profile) {
        this._profileService.getAllExportersByImporterId().subscribe({
          next: (v) => {
            this.exporters = v
            this.incotermsOptions = ExportOfferUtils.getIncotermsClosedSaleOptions()
            this.getYears()
            this.loading = false
          },
          error: (e) => {
            console.log(e)
            this.loading = false
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.Importer.ManyRetrieveError') })
          },
          complete: () => console.info('Exporters query successfully')
        })
      }
    }
  
    endWeekValidator(): ValidatorFn {
      return (control: AbstractControl) : ValidationErrors | null => {
        if(!this.step2FormGroup)
          return null
  
        if(!this.step2FormGroup.controls['startWeekYear'].value)
          return null
  
        if(!this.step2FormGroup.controls['endWeekYear'].value)
          return null
  
        if(!this.step2FormGroup.controls['startWeek'].value)
          return null
  
        if(!this.step2FormGroup.controls['endWeek'].value)
          return null
  
        let startWeek = this.step2FormGroup.controls['startWeek'].value
        let endWeek = this.step2FormGroup.controls['endWeek'].value
        let startWeekYear = this.step2FormGroup.controls['startWeekYear'].value
        let endWeekYear = this.step2FormGroup.controls['endWeekYear'].value
  
        return (startWeekYear === endWeekYear && startWeek > endWeek) ? {endWeekRangeError:true} : null;
      }
    }

    getYears() {
      let current = new Date().getFullYear()
      this.years.push({label: current, value: current})
      let next = current + 1
      this.years.push({label: next, value: next})
    }

    loadSpecies() {
      this._speciesService.getAllByProductType(1).subscribe({
        next: (v) => {
          this.species = v
          this.loading = false
          this.incotermsOptions = ExportOfferUtils.getIncotermsOptions()
  
          if(this.profile) {
            this.colors = ExportOfferUtils.getColors(this.profile.lang)
            this.boxTypes = ExportOfferUtils.getBoxTypes(this.profile.lang)
            this.kilosPerBoxes = ExportOfferUtils.getKilosPerBox()
            this.basePallets = ExportOfferUtils.getBasePallets(this.profile.lang)
          }
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.Species.ManyRetrieveError') })
        },
        complete: () => console.info('Species query successfully')
      })
    }
  
    loadDestinationPorts() {
      if (this.step1FormGroup.controls['countryToId'].value) {
        this._portService.getDestinationPortsByCountry(this.step1FormGroup.controls['countryToId'].value).subscribe({
          next: (v) => {
            this.destinationPorts = v
          },
          error: (e) => {
            console.log(e)
            this.loading = false
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.Port.DestinationPortManyRetrieveError') })
          },
          complete: () => console.info('Destination Ports query successfully')
        })
      }
    }
  
    loadVarieties() {
      this.varieties = []
      this._varietyService.getAllBySpecies(this.step1FormGroup.controls['speciesId'].value).subscribe({
        next: (v) => {
          this.varieties = v
          this.loading = false
        },
        error: (e) => {
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.Variety.ManyRetrieveError') })
        },
        complete: () => console.info('Variety query successfully')
      })
    }
  
    loadSpeciesParams() {
      this.selectedSpecies = this.species.find(x => x.id === this.step1FormGroup.controls['speciesId'].value)
      this.loadVarieties()
    }
  
    onChangeVariety() {
      this.selectedVariety = this.varieties.find(x => x.id === this.step1FormGroup.controls['varietyId'].value)
    }

    filterPackaging(event: any) {
      if (event?.isOther === true) {
        this.step1FormGroup.controls['otherPackaging'].enable()
      } else {
        this.step1FormGroup.controls['otherPackaging'].setValue('')
        this.step1FormGroup.controls['otherPackaging'].disable()
      }
      if (event) this.packagingSelected[0] = event.id
    }

    get paymentBLTitle() {
      return ExportOfferUtils.getPaymentBLClosedSaleTitle(this.step1FormGroup.controls['paymentAgainstBL'].value);
    }
  
    get paymentBLItems() {
      return ExportOfferUtils.getPaymentBLClosedSaleValues(this.step1FormGroup.controls['incoterms'].value);
    }
  
    get paymentBLPercent() {
      return ExportOfferUtils.showPaymentBLClosedSalePercent(this.step1FormGroup.controls['incoterms'].value)
    }

    get showPaymentBLPercent() {
      return ExportOfferUtils.showPaymentBLPercent(this.step1FormGroup.controls['incoterms'].value)
    }

    get finalPaymentPercent(): number {
      if(this.step1FormGroup.controls['paymentAgainstBL'].value)
        return 100 - this.step1FormGroup.controls['paymentAgainstBL'].value
      return 0
    }

    getSpeciesName(species: Species): string {
      if(this.profile)
        return SpeciesUtils.getSpeciesName(this.profile.lang, species)
      return ''
    }

    back(){
      this._router.navigate(['/' + Routes.importerClosedSaleOrdersPage])
    }


  
    loadCountries() {
      this.countries = []
      this._countryService.getAll().subscribe({
        next: (v) => {
          this.countries = v
          this.loading = false
          // this.countriesOrigin.push({
          //   id: null,
          //   name: 'All countries',
          //   nameEs: 'Todos los países',
          //   code: 'WORLD'
          // })
          this.countries.forEach(x => {
            this.countriesOrigin.push({...x})
          })
          this.selectedCountryFrom = this.countriesOrigin.find(x => x.id == this.step1FormGroup.controls['countryId'].value)
          this.selectedCountryTo = this.countriesOrigin.find(x => x.id == this.step1FormGroup.controls['countryToId'].value)
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.Country.ManyRetrieveError') })
        },
        complete: () => console.info('Species Sizes query successfully')
      })
    }
  
    loadProductCertificateTypes() {
      this._productCertificateTypeService.getAll().subscribe({
        next: (v) => {
          this.certificateTypes = v
          this.loading = false
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudieron recuperar los tipos de certificados.' })
        },
        complete: () => console.info('ProductCertificateTypes query successfully')
      })
    }
  
    loadPackagings() {
      this._packagingService.getAll().subscribe({
        next: (v) => {
          this.packagingList = v
          this.loading = false
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.Packaging.ManyRetrieveError') })
        },
        complete: () => console.info('Packaging query successfully')
      })
    }
  
    onSelectImage(event: any, uploader: any) {
      this.uploadedImages = uploader.files
    }
  
    onRemoveImage(event: any, uploader: any) {
      this.uploadedImages = uploader.files
    }
  
    onClearImageUploader(event: any, uploader: any) {
      this.uploadedImages = uploader.files
    }
  
    onSelectPdf(event: any, uploader: any){
      this.uploadPurchaseOrderPdf = uploader.files
    }
  
    onRemovePdf(event: any, uploader: any) {
      this.uploadPurchaseOrderPdf = uploader.files
    }
  
    onClearPdf(event: any, uploader: any) {
      this.uploadPurchaseOrderPdf = uploader.files
    }

    onSelectSheetPdf(event: any, uploader: any){
      this.uploadPdf = uploader.files
    }
  
    onRemoveSheetPdf(event: any, uploader: any) {
      this.uploadPdf = uploader.files
    }
  
    onClearSheetPdf(event: any, uploader: any) {
      this.uploadPdf = uploader.files
    }

    onChangeImporter() {
      this.getConsignees()
      this.getNotifies()
    }

    getConsignees() {
      if(this.profile) {
        this._consigneeService.getAllByUsername(this.profile.username).subscribe({
          next: (v) => {
            this.consignees = v
            this.loading = false
          },
          error: (e) => {
            console.log(e)
            this.loading = false
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.Consignee.ManyRetrieveError') })
          },
          complete: () => console.info('Consignees query successfully')
        })
      }
    }

    showStep1() {
      this.step = this.step1;
    }
  
    showStep2() {
  
      this.submitStep1 = true
      this.loading = true
      if (this.step1FormGroup.valid) {
        this.loading = false
        this.step = this.step2
      }
      else {
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.FormMessages.FormInvalid') });
          this.loading = false
      }

      this.loading = false
    }
  
    showStep3()
    {
  
      this.submitStep2 = true
      if(this.step2FormGroup.valid)
        {
          let startWeek = this.step2FormGroup.controls['startWeek'].value
          let startWeekYear = this.step2FormGroup.controls['startWeekYear'].value
          let endWeek = this.step2FormGroup.controls['endWeek'].value
          let endWeekYear = this.step2FormGroup.controls['endWeekYear'].value
  
          if(endWeekYear && startWeekYear > endWeekYear) {
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.ExportOffer.ClosedSaleYearRangeError')})
            this.loading = false
            return
          }
  
          if(endWeek && endWeekYear && startWeekYear === endWeekYear && startWeek > endWeek) {
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.ExportOffer.ClosedSaleWeekRangeError')})
            this.loading = false
            return
          }
  
          if(this.certificateTypesSelected.length < 1) {
            this.step2FormGroup.controls['productCertificateType'].markAsTouched();
            this.step2FormGroup.controls['productCertificateType'].setErrors({ 'invalid': true });
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.ExportOffer.CertificateTypeLengthError')})
            this.loading = false
            return
          }
  
          if(this.packagingSelected.length <= 0) {
            this.step2FormGroup.controls['packaging'].markAsTouched();
            this.step2FormGroup.controls['packaging'].setErrors({ 'invalid': true });
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.ExportOffer.PackagingLengthError')})
            this.loading = false
            return
          }
  
          this.step = this.step3;
        
        }else
        {
          this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.FormMessages.FormInvalid') });
        }
  
    }

    get totalPallets(): number {
      let pallets = this.step1FormGroup.controls['palletQuantity'].value
      let startWeek = this.step2FormGroup.controls['startWeek'].value
      let startWeekYear = this.step2FormGroup.controls['startWeekYear'].value
      let endWeek = this.step2FormGroup.controls['endWeek'].value
      let endWeekYear = this.step2FormGroup.controls['endWeekYear'].value
  
      if(pallets) {
        if(startWeekYear && endWeekYear && startWeekYear === endWeekYear && endWeek > startWeek) {
          return ((endWeek - startWeek) + 1) * pallets
        }
  
        if (startWeekYear && endWeekYear && endWeekYear == startWeekYear + 1) {
          return ((52 - startWeek) + endWeek + 1) * pallets
        }
  
        return pallets
      }
      return 0
    }

    get totalWeeks(): number {
      let startWeek = this.step2FormGroup.controls['startWeek'].value
      let startWeekYear = this.step2FormGroup.controls['startWeekYear'].value
      let endWeek = this.step2FormGroup.controls['endWeek'].value
      let endWeekYear = this.step2FormGroup.controls['endWeekYear'].value
  
      return ExportOfferUtils.getTotalWeeks(startWeek, startWeekYear, endWeek, endWeekYear)
    }
  
    get containersPerWeek(): number {
      let pallets = this.step1FormGroup.controls['palletQuantity'].value
      if(pallets && pallets % 20 == 0) {
        return pallets / 20
      }
      return 0
    }
  
    get totalContainers(): number {
      const pallets = this.totalPallets
      if(pallets && pallets % 20 == 0) {
        return pallets / 20
      }
      return 0
    }

    filterMultiselect(event: any) {
      if (event.includes(5)) { // Other/Otros
        this.step2FormGroup.controls['otherCertificate'].enable()
      }
      else {
        this.step2FormGroup.controls['otherCertificate'].setValue('')
        this.step2FormGroup.controls['otherCertificate'].disable()
      }
    }

    filterPackagingMultiselect(event: any) {
      if (event.includes(6)) { // Other/Otros
        this.step2FormGroup.controls['otherPackaging'].enable()
      }
      else {
        this.step2FormGroup.controls['otherPackaging'].setValue('')
        this.step2FormGroup.controls['otherPackaging'].disable()
      }
    }

    onChangeCountryFrom() {
      this.selectedCountryFrom = this.countriesOrigin.find(x => x.id == this.step1FormGroup.controls['countryId'].value)
    }
    onChangeCountryTo() {
      this.selectedCountryTo = this.countriesOrigin.find(x => x.id == this.step1FormGroup.controls['countryToId'].value)
    }

    getErrorMessage(formGroup: FormGroup, fieldName: string): string {
      return ErrorMessageUtil.getErrorMessage(formGroup, this.translate, fieldName, 20)
    }
  
    getNotifies() {
      if(this.profile) {
        this._notifyService.getAllByUsername(this.profile.username).subscribe({
          next: (v) => {
            this.notifies = v
            this.loading = false
          },
          error: (e) => {
            this.loading = false
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.Notify.ManyRetrieveError') })
          },
          complete: () => console.info('Notifies query successfully')
        })
      }
    }

    save() {
      this.loading = true;
      this.submit = true;

      if(this.step2FormGroup.valid) {
        let startWeekYear = this.step2FormGroup.controls['startWeekYear'].value;
        let endWeekYear = this.step2FormGroup.controls['endWeekYear'].value ? this.step2FormGroup.controls['endWeekYear'].value : this.step2FormGroup.controls['startWeekYear'].value

        const createProgramRequest: CreateProgramRequest = {
          importerUsername: this._authService.getUsername(),
          exporterId: this.step1FormGroup.controls['exporterId'].value,
          consigneeId: this.step1FormGroup.controls['consigneeId'].value,
          notifyId: this.step1FormGroup.controls['notifyId'].value,
          varietyId: this.step1FormGroup.controls['varietyId'].value,
          caseQuantityPerPallet: this.step1FormGroup.controls['caseQuantityPerPallet'].value,
          pricePerCase: this.step1FormGroup.controls['listPrice'].value,
          typeSale: this.step1FormGroup.controls['incoterms'].value,
          paymentAgainstBL: this.step1FormGroup.controls['paymentAgainstBL'].value,
          countryToId: this.step1FormGroup.controls['countryToId'].value,
          countryId: this.step1FormGroup.controls['countryId'].value,
          portToId: this.step1FormGroup.controls['portToId'].value,

          speciesSize: this.step2FormGroup.controls['speciesSize'].value,
          pictures: this.uploadedImages,
          basePallets: this.step2FormGroup.controls['basePallets'].value,
          boxType: this.step2FormGroup.controls['boxType'].value,
          palletQuantity: this.step1FormGroup.controls['palletQuantity'].value,
          otherCertificate: this.step2FormGroup.controls['otherCertificate'].value,
          otherPackaging: this.step2FormGroup.controls['otherPackaging'].value,
          packaging: this.packagingSelected,
          productCertificateType: this.certificateTypesSelected,
          dataSheetPdf: this.uploadPdf[0] ?  this.uploadPdf[0] : null,
          importPurchaseOrderPdf: this.uploadPurchaseOrderPdf[0] ?  this.uploadPurchaseOrderPdf[0] : null,
          importPurchaseOrder: this.step1FormGroup.controls['importPurchaseOrder'].value,

          startWeek: this.step2FormGroup.controls['startWeek'].value,
          startWeekYear: startWeekYear,
          endWeek: this.step2FormGroup.controls['endWeek'].value ? this.step2FormGroup.controls['endWeek'].value : this.step2FormGroup.controls['startWeek'].value,
          endWeekYear: endWeekYear,
          netKilos: this.step2FormGroup.controls['netKilos'].value,
          category: this.step2FormGroup.controls['category'].value,

        }

        this._programRequestService.create(createProgramRequest).subscribe({
          next: (v) => {
            this._messageService.add({ severity: 'success',
              summary: this.translate.instant('MessageService.Summary.SuccessAction'),
              detail: this.translate.instant('MessageService.ProgramRequest.CreateSuccess') })
            this.submit = false
            this.loading = false
            this.step = this.finalStep
            setTimeout(() => {
              this.goToCreateProgramRequest()
            }, 1500);
          },
          error: (e) => {
            this.loading = false
            console.log(e)
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.ProgramRequest.CreateError') })
          },
          complete: () => console.info('Program request created successfully')
        })
      }

      this.loading = false
    }

    getCardHeader(): string {
        return this.translate.instant('ProgramRequestPage.Title')
    }
  
    getCardSubheader(): string {
        return this.translate.instant('DirectSaleFormPage.Step1Title')
    }

    goToCreateProgramRequest() {
      this._router.navigate(['/' + Routes.userClosedSales])
    }

}
