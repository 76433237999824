import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormGroupDirective } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService, ConfirmationService, ConfirmEventType } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { DocumentServiceGateway } from 'src/app/application/gateway/DocumentServiceGateway';
import { ProductCertificateGateway } from 'src/app/application/gateway/ProductCertificateGateway';
import { ProductCertificateTypeGateway } from 'src/app/application/gateway/ProductCertificateTypeGateway';
import { ProductCertificate, NewProductCertificate } from 'src/app/domain/models/ProductCertificate';
import { ProductCertificateType } from 'src/app/domain/models/ProductCertificateType';
import { ErrorMessageUtil } from 'src/app/ui/util/error-message-util';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-export-offer-product-certificates-crud',
  templateUrl: './export-offer-product-certificates-crud.component.html',
  styleUrls: ['./export-offer-product-certificates-crud.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class ExportOfferProductCertificatesCrudComponent implements OnInit {
  @Input()
  exportOfferId: any | undefined

  productCertificates: any[] = []
  certificateTypes: ProductCertificateType[] = []

  loading: boolean = false
  productCertificateDialogVisible: boolean = false
  submitProductCertificate: boolean = false

  selectedProductCertificate: ProductCertificate | undefined
  productCertificatePdfFile: any

  productCertificateFormGroup: FormGroup = this._formBuilder.group({
    name: ['', Validators.required],
    documentIssueDate: [null],
    expirationDate: [null],
    productCertificateTypeId: ['', Validators.required]
  })

  constructor(private _formBuilder: FormBuilder,
    private _messageService: MessageService,
    private _confirmationService: ConfirmationService,
    private _documentService: DocumentServiceGateway,
    private _productCertificateService: ProductCertificateGateway,
    private _productCertificateTypeService: ProductCertificateTypeGateway,
    public translate: TranslateService) {
  }

  ngOnInit(): void {
    this.getProductCertificates()
  }

  getProductCertificates() {
    this.productCertificates =  []
    if(this.exportOfferId) {
      this._productCertificateService.getAllByExportOfferId(this.exportOfferId).subscribe({
        next: (v) => {
          this.productCertificates = v
          this.loading = false
        },
        error: (e) => {
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ProductCertificate.ManyRetrieveError') });
          console.log(e)
          this.loading = false
        },
        complete: () =>  {
          this.loading = false
          console.info('Product Certificates query successfully')
        }
      })
    }
  }

  getProductCertificateTypes() {
    this._productCertificateTypeService.getAll().subscribe({
      next: (v) => {
        this.certificateTypes = v
        this.loading = false
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.ProductCertificateType.ManyRetrieveError') })
      },
      complete: () => console.info('ProductCertificateTypes query successfully')
    })
  }

  openProductCertificateCreateDialog(formDirective: FormGroupDirective, fileUploader: FileUpload) {
    this.selectedProductCertificate = undefined
    fileUploader.clear()
    formDirective.resetForm()
    this.productCertificateFormGroup.controls['name'].setValue('')
    this.productCertificateFormGroup.controls['productCertificateTypeId'].setValue('')
    this.productCertificateFormGroup.controls['documentIssueDate'].setValue('')
    this.productCertificateFormGroup.controls['expirationDate'].setValue('')
    this.productCertificatePdfFile = null
    this.submitProductCertificate = false
    this.getProductCertificateTypes()
    this.productCertificateDialogVisible = true
  }

  openProductCertificateEditDialog(certificate: ProductCertificate, fileUploader: FileUpload) {
    this.selectedProductCertificate = certificate
    this.productCertificateFormGroup.controls['name'].setValue(this.selectedProductCertificate.name)
    this.productCertificateFormGroup.controls['productCertificateTypeId'].setValue(this.selectedProductCertificate.productCertificateTypeId)
    this.productCertificateFormGroup.controls['documentIssueDate'].setValue(this.selectedProductCertificate.documentIssueDate ? new Date(this.selectedProductCertificate.documentIssueDate) : null)
    this.productCertificateFormGroup.controls['expirationDate'].setValue(this.selectedProductCertificate.expirationDate ? new Date(this.selectedProductCertificate.expirationDate) : null)

    if(this.selectedProductCertificate.pdfUrl) {
      let uri = this.selectedProductCertificate.pdfUrl.trim().replace(" ", "%20")
      uri = environment.apiUrl + uri
      fetch(uri).then(r => r.blob()).then(blob => {
        if(blob && this.selectedProductCertificate) {
          let filename = uri.substring(uri.lastIndexOf('/')+1)
          filename = filename.replace("%20", " ")
          let pdf = new File([blob], filename)
          fileUploader.clear()
          fileUploader.files = [pdf]
          this.productCertificatePdfFile = pdf
        }
      })
    }

    this.getProductCertificateTypes()
    this.productCertificateDialogVisible = true
  }

  onDeleteProductCertificate(certificate: ProductCertificate) {
    this._confirmationService.confirm({
      message: this.translate.instant('MessageService.ProductCertificate.ConfirmDeleteDialogMessage'),
      header: this.translate.instant('MessageService.ProductCertificate.ConfirmDeleteDialogHeader'),
      icon: 'pi pi-info-circle',
      accept: () => {
        this._productCertificateService.delete(certificate.id).subscribe({
          next: (v) => {
            this.selectedProductCertificate = undefined
            this.getProductCertificates()
            this._messageService.add({ severity: 'info',
              summary: this.translate.instant('MessageService.Summary.SuccessAction'),
              detail: this.translate.instant('MessageService.ProductCertificate.DeleteSuccess') })
          },
          error: (e) => {
            console.log(e)
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.ProductCertificate.DeleteError') });
          },
          complete: () => console.info('Product Certificate delete successfully')
        })
      },
      reject: (type: any) => {
          switch (type) {
              case ConfirmEventType.REJECT:
                //this._messageService.add({ severity: 'error', summary: 'Rejected', detail: 'Se canceló la eliminación' })
                break;
              case ConfirmEventType.CANCEL:
                //this._messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Se canceló la eliminación' })
                break;
          }
      }
    })
  }

  onSelectProductCertificateFile(event: any) {
    this.productCertificatePdfFile = event.files[0]
  }

  onClearProductCertificateFile(event: any) {
    this.productCertificatePdfFile = null
  }

  saveProductCertificate() {
    this.submitProductCertificate = true
    if(this.productCertificateFormGroup.valid) {
      this.loading = true
      if(this.selectedProductCertificate) {
        this.selectedProductCertificate.name = this.productCertificateFormGroup.controls['name'].value
        this.selectedProductCertificate.productCertificateTypeId = this.productCertificateFormGroup.controls['productCertificateTypeId'].value
        this.selectedProductCertificate.documentIssueDate = this.productCertificateFormGroup.controls['documentIssueDate'].value
        this.selectedProductCertificate.expirationDate = this.productCertificateFormGroup.controls['expirationDate'].value
        this.selectedProductCertificate.pdf = this.productCertificatePdfFile ? this.productCertificatePdfFile : this.selectedProductCertificate.pdf

        this._productCertificateService.update(this.selectedProductCertificate).subscribe({
          next: (v) => {
            this.selectedProductCertificate = v
            this.getProductCertificates()
            this._messageService.add({ severity: 'success',
              summary: this.translate.instant('MessageService.Summary.SuccessAction'),
              detail: this.translate.instant('MessageService.ProductCertificate.EditSuccess') })
            this.loading = false
            this.submitProductCertificate = false
            this.productCertificateDialogVisible = false
          },
          error: (e) => {
            this.loading = false
            this.submitProductCertificate = false
            console.log(e)
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.ProductCertificate.EditError') });
          },
          complete: () =>  {
            this.loading = false
            this.submitProductCertificate = false
            console.info('Product Certificate update successfully') }
        })
      }
      else {
        let newCertificate: NewProductCertificate = {
          name: this.productCertificateFormGroup.controls['name'].value,
          productCertificateTypeId: this.productCertificateFormGroup.controls['productCertificateTypeId'].value,
          documentIssueDate: this.productCertificateFormGroup.controls['documentIssueDate'].value,
          expirationDate: this.productCertificateFormGroup.controls['expirationDate'].value,
          pdf: this.productCertificatePdfFile,
          exportOfferId: this.exportOfferId
        }

        this._productCertificateService.create(newCertificate).subscribe({
          next: (v) => {
            this.selectedProductCertificate = v
            this.getProductCertificates()
            this._messageService.add({ severity: 'success',
              summary: this.translate.instant('MessageService.Summary.SuccessAction'),
              detail: this.translate.instant('MessageService.ProductCertificate.CreateSuccess') })
            this.loading = false
            this.submitProductCertificate = false
            this.productCertificateDialogVisible = false
          },
          error: (e) => {
            this.loading = false
            this.submitProductCertificate = false
            console.log(e)
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.ProductCertificate.CreateError') });
          },
          complete: () =>  {
            this.loading = false
            this.submitProductCertificate = false
            console.info('Product Certificate create successfully')
          }
        })
      }
    }
    else {
      this.loading = false
    }
  }

  getProductCertificateDocument(productCertificateId: number) {
    this._documentService.getProductCertificateDocument2(productCertificateId).subscribe((resultBlob: any) => {
      var downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }

  getErrorMessage(formGroup: FormGroup, fieldName: string): string {
    return ErrorMessageUtil.getErrorMessage(formGroup, this.translate, fieldName)
  }
}
