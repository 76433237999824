<app-user-dashboard [userMenuActiveItemIndex]="0" [activeItem]="'export-offers'">

  <p-card>
    <div class="grid" style="margin-left: 15px; margin-right: 15px;">
      <div class="col-12 md:col-6">
        <h2>{{'UserPages.UserExportOffers.PageTitle' | translate}}</h2>
      </div>
      <div class="col-12 md:col-6 flex justify-content-end align-items-center gap-4">
        <p-button icon="pi pi-plus" label="{{'UserPages.UserExportOffers.UploadNewOfferButton' | translate}}" (onClick)="openCreateExportOfferOptionDialog()"></p-button>
      </div>
    </div>
    <p-divider></p-divider>
    <form [formGroup]="formGroupFilter" style="margin-bottom: 20px;">
      <div class="grid p-fluid">
        <div class="col-12 md:col">
          <p-dropdown
           formControlName="speciesId" [options]="species"
           optionLabel="name" optionValue="id"
           [filter]="true" filterBy="name"
           [showClear]="true"
            placeholder="{{'UserPages.UserExportOffers.SpecieFieldPlaceholder' | translate}}"
            [style]="{'background-color': '#EDEFF2', 'height': '45px'}" (onChange)="loadVarieties()" class="p-inputtext-sm">
            <ng-template pTemplate="selectedItem">
              <div class="flex align-items-center gap-2" *ngIf="selectedSpecies">
                <img [src]="selectedSpecies.imageUrl" style="width: 18px" alt=""/>
                <div>{{ getSpeciesName(selectedSpecies) }}</div>
              </div>
            </ng-template>
            <ng-template let-specie pTemplate="item">
              <div class="flex align-items-center gap-2">
                <img [src]="specie.imageUrl" style="width: 18px" alt=""/>
                <div>{{ getSpeciesName(specie) }}</div>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
        <div class="col-12 md:col">
          <p-dropdown formControlName="varietyId" [options]="varieties" optionLabel="name" optionValue="id" [filter]="true" filterBy="name" [showClear]="true" placeholder="{{'UserPages.UserExportOffers.VarietyFieldPlaceholder' | translate}}" [style]="{'background-color': '#EDEFF2', 'height': '45px'}" (onChange)="onChangeVariety()" class="p-inputtext-sm">
            <ng-template pTemplate="selectedItem">
              <div class="flex align-items-center gap-2" *ngIf="selectedVariety">
                <img [src]="selectedVariety.urlIcon" style="width: 18px"/>
                <div>{{ selectedVariety.name }}</div>
              </div>
            </ng-template>
            <ng-template let-variety pTemplate="item">
                <div class="flex align-items-center gap-2">
                    <img [src]="variety.urlIcon" style="width: 18px"/>
                    <div>{{ variety.name }}</div>
                </div>
            </ng-template>
          </p-dropdown>
        </div>
        <div class="col-12 md:col">
          <input id="code" type="text" pInputText formControlName="id" placeholder="{{'UserPages.UserExportOffers.CodeFieldPlaceholder' | translate}}" style="background-color: #EDEFF2; height: 45px;" (keyup)="setFilters()"/>
        </div>
        <div class="col-12 md:col">
          <p-dropdown [autoDisplayFirst]="false" formControlName="status" [options]="statusItem" optionLabel="label" optionValue="value" [showClear]="true" placeholder="{{'UserPages.UserExportOffers.StatusFieldPlaceholder' | translate}}" [style]="{'background-color': '#EDEFF2', 'height': '45px'}" (onChange)="setFilters()" class="p-inputtext-sm"></p-dropdown>
        </div>
        <div class="col-12 md:col">
          <p-calendar formControlName="date" placeholder="{{'UserPages.UserExportOffers.DateCreated' | translate}}" [showIcon]="true" [style]="{'background-color': '#EDEFF2', 'height': '45px'}" (ngModelChange)="setFilters()" [showButtonBar]="true"></p-calendar>
        </div>
        <div class="col-12 md:col">
          <p-button icon="pi pi-plus" label="{{'UserPages.UserExportOffers.FilterAdvancedText' | translate}}" (onClick)="openFiltersSidebar()" [style]="{'background-color': '#FFF', 'border': '1px solid #8c2b87', 'color': '#8c2b87', 'height': '45px'}" [badge]="filterSelectedCount.toString()" badgeClass="p-badge-success"></p-button>
        </div>
      </div>
    </form>
    <div style="margin-top: 20px;">
      <p-dataView
        [value]="offersFiltered"
        [rows]="5"
        [loading]="loading"
        [paginator]="true"
        emptyMessage="{{'UserPages.UserExportOffers.NotResultsMessages' | translate}}"
        [style]="{'width': '100%'}"
      >
        <ng-template pTemplate="listItem" let-offer>
          <div class="col-12" style="margin-bottom: 20px;">
            <p-card>
              <div class="grid">
                <div class="col-12 flex align-items-center">
                  <div class="col-1">
                    <div class="flex justify-content-start align-items-center">
                      <div class="col-12 flex justify-content-center align-items-center">
                        <figure style="border-radius: 7px; min-width: 120px; height: 100px; display: flex; justify-content: center; align-items: center; margin:0; overflow:hidden" *ngIf="offer.pictures.length > 0">
                          <img [src]="getImageUrl(offer)" style="width: 100%; height: 100%, object-fit: cover;" alt="Product picture" *ngIf="offer.pictures.length > 0"/>
                        </figure>
                        <span style="border-radius: 7px; padding: 5px; font-weight: bold;" *ngIf="offer.pictures.length < 1">{{'UserPages.UserExportOffers.NotAvailablePictureLabel' | translate}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-2">
                    <span style="padding-left:1em">{{getSpeciesStaticName(offer)}}</span>
                  </div>
                  <div class="col-2">
                    <span class="flex align-items-center">{{offer.varietyName}}</span>
                  </div>
                  <div class="col-1">
                    <span class="flex align-items-center" style="color: #672572; font-weight: bold;">{{'ImportCloseSaleTable.CodeText' | translate}}: {{offer.id}}</span>
                  </div>
                  <div class="col-1 flex justify-content-center">
                    <p-tag *ngIf="offer.status == 0" [value]="getStatusValue(offer.status)" [style]="{ 'display': 'grid', 'width': '100%', 'min-width': '80px', 'background-color': '#F1D0D0', 'color': '#9E4343'}"></p-tag>
                    <p-tag *ngIf="offer.status == 1" [value]="getStatusValue(offer.status)" [style]="{ 'display': 'grid', 'width': '100%', 'min-width': '80px', 'background-color': '#C3DBA2', 'color': '#4D6A34'}"></p-tag>
                    <p-tag *ngIf="offer.status == 2" [value]="getStatusValue(offer.status)" [style]="{ 'display': 'grid', 'width': '100%', 'min-width': '80px', 'background-color': '#D7D7D7', 'color': '#6C7A8F'}"></p-tag>
                  </div>
                  <div class="col-2 flex justify-content-center">
                    <i class="pi pi-calendar" style="color: #6D6D6D; font-weight: bold; margin-right: 8px"></i>
                    {{offer.created | date: 'dd-MM-yyyy'}}
                  </div>
                  <div class="col-2 flex justify-content-center">
                    <span style="color: #672572; font-weight: bold;">{{offer.listPrice | currency: 'USD':'US$ '}}/{{offer.incoterms}}</span>
                  </div>
                  <div class="col-1 flex justify-content-center">
                    <p-button icon="pi pi-angle-down" styleClass="p-button-rounded p-button-sm p-button-outlined" [style]="{'background-color': 'transparent', 'border-color':'#6E9F41'}" (click)="setVisibleOffer(offer)" [icon]="offer.expanded ? 'pi pi-chevron-up' : 'pi pi-chevron-down'"></p-button>
                  </div>
                </div>
              </div>
              <div *ngIf="offer.expanded" class="grid align-items-start" style="border-top: 1px solid #D6D6D6">
                <div class="col-12 md:col-7 grid" style="margin: 0">
                  <div class="col-12 flex align-items-start justify-content-center" style="margin: 0;">
                      <span style="color: #D6D6D6;">{{'UserPages.UserExportOffers.ProductInformationText' | translate}}</span>
                  </div>
                  <div class="col-6">
                    <div class="col-12 flex">
                      <span class="material-symbols-outlined" style="color: #672572; font-size: 20px; font-weight: bold; margin-right: 8px;">calendar_today</span>
                      {{'UserPages.UserExportOffers.HarvestDateText' | translate}}: {{offer.harvestDate | date: 'dd-MM-y'}}
                    </div>
                    <div class="col-12 flex">
                      <span class="material-symbols-outlined" style="color: #672572; font-size: 20px; font-weight: bold; margin-right: 8px">weight</span>
                      {{'UserPages.UserExportOffers.KilosPerBoxText' | translate}}: {{ offer.netKilos ? offer.netKilos + ' Kg.' : 'CommonLabels.NotAvailableLabel' | translate }}
                    </div>
                    <div class="col-12 flex">
                      <span class="material-symbols-outlined" style="color: #672572; font-size: 20px; font-weight: bold; margin-right: 8px">inventory_2</span>
                      {{'UserPages.UserExportOffers.BoxTypeText' | translate}}: {{getBoxType(offer.boxType)}}
                    </div>
                    <div class="col-12 flex">
                      <span class="material-symbols-outlined" style="color: #672572; font-size: 20px; font-weight: bold; margin-right: 8px">ac_unit</span>
                      {{'UserPages.UserExportOffers.FridstorageText' | translate}}: {{offer.fridgeStorageName}}
                    </div>
                  </div>
                  <div class="col-6" style="border-left: 1px solid #D6D6D6">
                    <div class="col-12 flex">
                      <div class="flex align-items-center">
                        <span class="material-symbols-outlined" style="color: #672572; font-size: 20px; font-weight: bold; margin-right: 8px">science</span>
                        {{'UserPages.UserExportOffers.qualityControlText' | translate}}: {{getIncludedLabel(offer.isQualityControl)}}
                        <p-button *ngIf="offer?.qualityDocuments.length > 0" (onClick)="getExportOfferQualityDocument(offer.qualityDocuments)" pTooltip="{{'UserPages.UserExportOffers.EditExportOffer.ViewQualityDocument' | translate}}" [style]="{ 'margin': 0, 'padding': 0 }" icon="pi pi-file-pdf" styleClass="p-button-link p-button-lg"></p-button>
                      </div>
                    </div>
                    <div class="col-12 flex">
                      <span class="material-symbols-outlined" style="color: #672572; font-size: 20px; font-weight: bold; margin-right: 8px">calendar_today</span>
                      {{'UserPages.UserExportOffers.ShelfLifeText' | translate}}: {{offer.shelfLife ? offer.shelfLife + ('CommonLabels.DaysText' | translate) : 'CommonLabels.NotAvailableLabel' | translate }}
                    </div>
                    <div class="col-12 flex">
                      <span class="material-symbols-outlined" style="color: #672572; font-size: 20px; font-weight: bold; margin-right: 8px">weight</span>
                      {{'UserPages.UserExportOffers.SpeciesSizeText' | translate}}: {{offer.speciesSize ? offer.speciesSize : 'CommonLabels.NotAvailableLabel' | translate }}
                    </div>
                    <div class="col-12 flex">
                      <span class="material-symbols-outlined" style="color: #672572; font-size: 20px; font-weight: bold; margin-right: 8px">public</span>
                      {{'UserPages.UserExportOffers.MarketText' | translate}}:
                      <img [src]="'../../../../assets/img/flags/' + offer?.marketCountryFirst.code + '.png'" style="width: 25px; margin: 0 1px;" alt="" [pTooltip]="offer?.marketCountryFirst | countryDescription" tooltipPosition="right"/>
                      <img *ngIf="offer?.marketCountrySecond" [src]="'../../../../assets/img/flags/' + offer?.marketCountrySecond.code + '.png'" style="width: 25px; margin: 0 1px;" alt="" [pTooltip]="offer?.marketCountrySecond | countryDescription" tooltipPosition="right"/>
                      <img *ngIf="offer?.marketCountryThird" [src]="'../../../../assets/img/flags/' + offer?.marketCountryThird.code + '.png'" style="width: 25px; margin: 0 1px;" alt="" [pTooltip]="offer?.marketCountryThird | countryDescription" tooltipPosition="right"/>
                    </div>
                  </div>
                </div>
                <div class="col-12 md:col-5 grid" style="margin: 0; border-left: 1px solid #D6D6D6">
                  <div class="col-12 flex align-items-start justify-content-center" style="margin: 0">
                    <span style="color: #D6D6D6">{{'UserPages.UserExportOffers.ProductRegisterText' | translate}}</span>
                  </div>
                  <div class="col-9">
                    <div class="col-12 flex">
                      <span class="material-symbols-outlined" style="color: #6E9F41; font-size: 20px; font-weight: bold; margin-right: 8px">pallet</span>
                      {{'UserPages.UserExportOffers.OriginalPalletQuantity' | translate}}: {{offer.palletQuantity}}
                    </div>
                    <div class="col-12 flex">
                      <span class="material-symbols-outlined" style="color: #6E9F41; font-size: 20px; font-weight: bold; margin-right: 8px">pallet</span>
                      {{'UserPages.UserExportOffers.AvailablePalletsText' | translate}}: {{offer.availablePallets}}
                    </div>
                    <div class="col-12 flex">
                      <span class="material-symbols-outlined" style="color: #6E9F41; font-size: 20px; font-weight: bold; margin-right: 8px">calendar_today</span>
                      {{'UserPages.UserExportOffers.DateCreated' | translate}}: {{offer.created | date: 'dd-MM-yyyy'}}
                    </div>
                    <div class="col-12 flex">
                      <span class="material-symbols-outlined" style="color: #6E9F41; font-size: 20px; font-weight: bold; margin-right: 8px">calendar_today</span>
                      {{'UserPages.UserExportOffers.LastModified' | translate}}: {{offer.lastModified ? (offer.lastModified | date: 'dd-MM-yyyy HH:mm') : (offer.created | date: 'dd-MM-yyyy')}}
                    </div>
                  </div>
                  <div class="col-3 justify-content-center">
                    <div class="col-12 flex justify-content-center">
                      <button pButton pRipple icon="pi pi-eye" class="p-button-rounded" styleClass="p-button-sm" pTooltip="{{'CommonLabels.Tooltip.ViewDetail' | translate}}" [style]="{'background-color': '#DAE8C3', 'color': '#70A043', 'width': '28px', 'height': '28px'}" (click)="showOfferDetailDialog(offer.id)"></button>
                    </div>
                    <div class="col-12 flex justify-content-center">
                      <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded" styleClass="p-button-sm" pTooltip="{{'CommonLabels.Tooltip.Edit' | translate}}" [style]="{'background-color': '#E2E8F0', 'color': '#65748A', 'width': '28px', 'height': '28px'}" (click)="setSelected(offer, 0)"></button>
                    </div>
                    <div class="col-12 flex justify-content-center" *ngIf="offer.status == 1">
                      <button pButton pRipple icon="pi pi-file" class="p-button-rounded" styleClass="p-button-sm" pTooltip="{{'CommonLabels.Tooltip.Documents' | translate}}" [style]="{'background-color': '#E2E8F0', 'color': '#8E99AB', 'width': '28px', 'height': '28px'}" (click)="setSelected(offer, 1)"></button>
                    </div>
                    <div class="col-12 flex justify-content-center" *ngIf="offer.status == 1">
                      <button pButton pRipple icon="pi pi-trash" class="p-button-rounded" styleClass="p-button-sm" pTooltip="{{'CommonLabels.Tooltip.Deactivate' | translate}}" [style]="{'background-color': '#F1D0D0', 'color': '#912C2C', 'width': '28px', 'height': '28px'}" (click)="setSelected(offer, 2)"></button>
                    </div>
                    <div class="col-12 flex justify-content-center" *ngIf="offer.status == 0">
                      <button pButton pRipple icon="pi pi-check" class="p-button-rounded" styleClass="p-button-sm" pTooltip="{{'CommonLabels.Tooltip.Activate' | translate}}" [style]="{'background-color': '#DAE8C3', 'color': '#70A043', 'width': '28px', 'height': '28px'}" (click)="setSelected(offer, 3)"></button>
                    </div>
                  </div>
                </div>
              </div>
            </p-card>
          </div>
        </ng-template>
      </p-dataView>
    </div>

    <!-- <ng-template pTemplate="body"> -->
    <!-- </ng-template> -->
  </p-card>
</app-user-dashboard>

<p-dialog [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleOfferDetailDialog" [style]="{width: '80%'}">
  <app-export-offer-detail [offer]="selected" [productCertificateTypes]="selectedProductCertificateTypes" [packagingTypes]="selectedPackagingTypes"></app-export-offer-detail>
  <ng-template pTemplate="footer">
    <p-button label="{{'UserPages.UserExportOffers.CloseModalButton' | translate}}" (onClick)="closeOfferDetailDialog()"></p-button>
  </ng-template>
</p-dialog>

<p-toast></p-toast>

<p-confirmDialog acceptLabel="" rejectLabel="" rejectButtonStyleClass="p-button-outlined" [style]="{width: '50vw'}"></p-confirmDialog>

<p-dialog [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleCreateExportOfferOptionDialog" [style]="{width: '80%'}">
  <app-export-offer-create-option></app-export-offer-create-option>
</p-dialog>

<p-sidebar [(visible)]="displayFiltersSidebar" position="right" [transitionOptions]="'1000ms'" [style]="{'width':'300px'}">
  <app-export-offer-filters (filtersApplied)="setFilters()" (closeSidebar)="displayFiltersSidebar = false"></app-export-offer-filters>
</p-sidebar>
