import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { ExportOfferGateway } from 'src/app/application/gateway/ExportOfferGateway';
import { OfferPicture } from 'src/app/domain/models/ExportOffer';
import { environment } from 'src/environments/environment';

interface Picture {
  url?: string
  file?: any
}

@Component({
  selector: 'app-export-offer-picture-uploader',
  templateUrl: './export-offer-picture-uploader.component.html',
  styleUrls: ['./export-offer-picture-uploader.component.css'],
  providers: [MessageService]
})
export class ExportOfferPictureUploaderComponent implements OnInit {

  @Input()
  exportOfferId: any | undefined

  pictures: OfferPicture[] = [{id:0}, {id:0}, {id:0}]

  removedPictures: number[] = []
  loading: boolean = false

  constructor(private _messageService: MessageService,
    private _exportOfferService: ExportOfferGateway,
    public translate: TranslateService) {

  }

  ngOnInit(): void {
    this.loadPictures()
  }

  loadPictures() {
    if(this.exportOfferId) {
      this.loading = true
      this._exportOfferService.getAllPicturesById(this.exportOfferId).subscribe({
        next: (v) => {
          const exportOfferPictures = v
          if(exportOfferPictures[0]) {
            this.pictures[0] = exportOfferPictures[0]
          }

          if(exportOfferPictures[1]) {
            this.pictures[1] = exportOfferPictures[1]
          }

          if(exportOfferPictures[2]) {
            this.pictures[2] = exportOfferPictures[2]
          }
          this.loading = false
        },
        error: (e) => {
          console.log(e)
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOffer.SingleRetrieveError') })
          this.loading = false
        },
        complete: () => console.info('ExportOffer query successfully')
      })
    }
  }

  onSelectImage(event: any, uploader: any, index: number): void {
    const picture = this.pictures[index]
    if(picture) {
      picture.url = uploader.files[0].objectURL.changingThisBreaksApplicationSecurity
      picture.file = uploader.files[0]
      picture.isDeleted = false
    }
    uploader.clear()
  }

  onRemoveImage(index: number): void {
    const picture = this.pictures[index]
    if(picture) {
      picture.url = undefined
      picture.file = undefined
      picture.isDeleted = true
    }
  }

  getPictures(): OfferPicture[] {
    return this.pictures
  }

  getImageUrl(picture: OfferPicture): any {
    if(picture) {
      if(picture.file) {
        return picture.file.objectURL
      }
      else if(picture.url) {
        return environment.apiUrl + picture.url
      }
    }
    return null
  }

  getImageName(picture: OfferPicture): any {
    if(picture) {
      if(picture.file) {
        return picture.file.name
      }
      else if(picture.url) {
        const urlParts: string[] = picture.url.split('/')
        return urlParts[urlParts.length-1]
      }
    }
    return null
  }

}
