import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { DirectSaleProgram } from 'src/app/domain/models/DirectSaleProgram';
import { ExportOffer, ExportOfferInformation } from 'src/app/domain/models/ExportOffer';
import { BasicUser } from 'src/app/domain/models/User';
import { ExportOfferUtils } from '../../util/export-offer-utils';
import { Router } from '@angular/router';
import { Routes } from 'src/app/infrastructure/routes';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { DocumentServiceGateway } from 'src/app/application/gateway/DocumentServiceGateway';

@Component({
  selector: 'app-direct-sale-detail',
  templateUrl: './direct-sale-detail.component.html',
  styleUrls: ['./direct-sale-detail.component.css']
})
export class DirectSaleDetailComponent implements OnInit {

  profile: BasicUser | undefined

  @Input()
  offer! : ExportOfferInformation

  @Output()
  ShowFirstConfirmEvent= new EventEmitter

  @Output()
  ShowOfferDetailDialogEvent = new EventEmitter

  @Output()
  ShowDirectSaleProgramCreateModal = new EventEmitter


  selectGuid: string | undefined
  statusArray: any[] = []
  

  constructor(
    private _authService: AuthGateway,
    private _router: Router,
    private _documentService: DocumentServiceGateway,
    public translate: TranslateService) {
      this.profile = this._authService.getUser()

      this.statusArray = [
        { label: this.profile?.lang === "es" ? "Inactiva" : "Inactive", value: 0 },
        { label: this.profile?.lang === "es" ? "Activa" : "Active", value: 1 },
      ]
  }

  ngOnInit(): void {}

  setVisibleOrderDetail(offer: ExportOfferInformation) {
    if (offer) {
      offer.expanded = !offer.expanded
    }
  }

  getMenuItemForDelete(offer: ExportOfferInformation): MenuItem[]
  {
    this.profile = this._authService.getUser()
    let menu :MenuItem[] = []
    if(this.profile) {
      switch(this.profile.lang) {
        case "es":
          menu = [
            {
              label: 'Eliminar', command: () => this.showFirstConfirm(offer.directSalePrograms[0].guid)
            },
          ]
          break;
        default:
          menu = [
            {
              label: 'Delete', command: () => this.showFirstConfirm(offer.directSalePrograms[0].guid)
            },
          ]
      }
    }
    return menu;
  }

  showFirstConfirm(guid: string)
  {
    this.ShowFirstConfirmEvent.emit(guid)
  }

  getTotalWeekRow(directSaleProgram: DirectSaleProgram)
  {
    let startWeek = directSaleProgram.startWeek
    let startWeekYear = directSaleProgram.startWeekYear
    let endWeek = directSaleProgram.endWeek
    let endWeekYear = directSaleProgram.endWeekYear 
    return ExportOfferUtils.getTotalWeeks(startWeek, startWeekYear, endWeek, endWeekYear);
  }
  
  getMenuItemsForCopy(offer: ExportOfferInformation): MenuItem[]
  {
    this.profile = this._authService.getUser()
    let menu :MenuItem[] = []
    if(this.profile) {
      switch(this.profile.lang) {
        case "es":
          menu = [
            {
              label: 'Duplicar', command: () => this.showDirectSaleProgramCreateModal(offer)
            },
          ]
          break;
        default:
          menu = [
            {
              label: 'Duplicate', command: () => this.showDirectSaleProgramCreateModal(offer)
            },
          ]
      }
    }

    return menu;
  }

  getStatusValue(status: number): string {
    const statusObj = this.statusArray.find(item => item.value === status);
    return statusObj ? statusObj.label : '';
  }

  showDirectSaleProgramCreateModal(offer: ExportOfferInformation)
  {
    this.ShowDirectSaleProgramCreateModal.emit(offer)
  }

  getMenuItemsForEdit(offer: ExportOfferInformation): MenuItem[] {
    this.profile = this._authService.getUser()
    let menu :MenuItem[] = []
    if(this.profile) {
      switch(this.profile.lang) {
        case "es":
          menu = [
            {
              label: 'Editar', command: () => this.goToEditClosedSalePage(offer.directSalePrograms[0].guid)
            },
            {
              label: 'Información complementaria', command: () => this.goToDirectSaleEditAdditionalInfoPage(offer.exportOffer.guid) 
            }
          ]
          break;
        default:
          menu = [
            {
              label: 'Edit', command: () => this.goToEditClosedSalePage(offer.directSalePrograms[0].guid)
            },
            {
              label: 'Additional information', command: () => this.goToDirectSaleEditAdditionalInfoPage(offer.exportOffer.guid) 
            }
          ]
      }
    }

    return menu;
  }

  getMenuItemsForView(offer: ExportOfferInformation): MenuItem[] {
    this.profile = this._authService.getUser()
    let menu :MenuItem[] = []
    if(this.profile) {
      switch(this.profile.lang) {
        case "es":
          menu = [
            {
              label: 'Ver Oferta', command: () => this.showOfferDetailDialog(offer)
            },
            {
              label: 'Ver Documentación', command: () => this.goToDirectSaleDocumentsPage(offer.exportOffer.id)
            }
          ]
          break;
        default:
          menu = [
            {
              label: 'View Offer', command: () => this.showOfferDetailDialog(offer)
            },
            {
              label: 'See Documentation', command: () => this.goToDirectSaleDocumentsPage(offer.exportOffer.id)
            }
          ]
      }
    }
    return menu;
  }

  showOfferDetailDialog(offer: ExportOfferInformation)
  {
    this.ShowOfferDetailDialogEvent.emit(offer)
  }

  goToDirectSaleCreatePage() {
    this._router.navigate(['/' + Routes.userDirectSaleCreate])
  }

  goToEditClosedSalePage(directSaleProgramId: string) {
    // if(this.selected) {
      this._router.navigate(['/' + Routes.userDirectSaleCreate, {directSaleProgramId}])
    // }
  }

  goToDirectSaleProgramOrders(directSaleProgramId: number) {

    if(this.profile){
      if(this.profile?.type == 2){
        this.goToDirectSaleProgramImporter(directSaleProgramId);
      }else{
        this._router.navigate(['/' + Routes.exporterClosedSaleOrdersPage, directSaleProgramId])
      }

    }
  }

  goToDirectSaleProgramImporter(directSaleProgramId: number)
  {
    this._router.navigate(['/' + Routes.importerClosedSaleOrdersPage, directSaleProgramId])

  }

  goToDirectSaleDocumentsPage(id: number) {
      this._router.navigate(['/' + Routes.userDirectSaleDocuments, id])
  }

  goToDirectSaleEditAdditionalInfoPage(guid: string) {
    this.selectGuid = guid;
      this._router.navigate(['/' + Routes.userDirectSaleEditAdditionalInfo, guid])
  }

  getImageUrl(offer: ExportOfferInformation): string {
    return offer.pictures && offer.pictures.length > 0 ? environment.apiUrl + offer.pictures[0].url : ''
  }
  getIncludedLabel(included: boolean | undefined) {
    return included ? this.translate.instant('UserPages.UserExportOffers.Included') : this.translate.instant('UserPages.UserExportOffers.NotIncluded')
  }

  getExportOfferQualityDocument(documents: any[] | undefined) {
    if (documents == undefined || documents.length <= 0) return;

    let docId = documents[0].id

    this._documentService.getExportOfferDocument2(docId).subscribe((resultBlob: any) => {
      var downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }
  
}
