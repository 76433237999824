<div>
    <form [formGroup]="filterForm">
        <div class="grid p-fluid">
            <div class="col-12 md:col">
              <p-dropdown 
              placeholder="{{'UserPages.UserClosedSale.DirectSaleFilters.SpeciesFilter' | translate}}" 
              class="p-inputtext-sm"
              [options]="species"
              optionLabel="nameEs"
              formControlName="speciesId"
              optionValue="id"
              (onChange)="loadVarieties()"
              [filter]="true"
              filterBy="name"
              [style]="{'background-color': '#EDEFF2'}"
              [showClear]="true"
              >
              <ng-template pTemplate="selectedItem">
                <div class="flex align-items-center gap-2" *ngIf="selectedSpecies">
                    <img [src]="selectedSpecies.imageUrl" style="width: 18px" alt=""/>
                    <div>{{ getSpeciesName(selectedSpecies) }}</div>
                </div>
              </ng-template>
              <ng-template let-specie pTemplate="item">
                  <div class="flex align-items-center gap-2">
                      <img [src]="specie.imageUrl" style="width: 18px" alt=""/>
                      <div>{{ getSpeciesName(specie) }}</div>
                  </div>
              </ng-template>
            </p-dropdown>
            </div>
            <div class="col-12 md:col">
              <p-dropdown 
              placeholder="{{'UserPages.UserClosedSale.DirectSaleFilters.VarietyFilter' | translate}}" 
              class="p-inputtext-sm"
              optionLabel="name"
              optionValue="id"
              formControlName="varietyId"
              [options]="varieties"
              [filter]="true"
              [showClear]="true"
              filterBy="name"
              [style]="{'background-color': '#EDEFF2'}"
              (onChange)="onChangeVariety()"
              >
              <ng-template pTemplate="selectedItem">
                <div class="flex align-items-center gap-2" *ngIf="selectedVariety">
                  <img [src]="selectedVariety.urlIcon" style="width: 18px"/>
                  <div>{{ selectedVariety.name }}</div>
                </div>
              </ng-template>
              <ng-template let-variety pTemplate="item">
                  <div class="flex align-items-center gap-2">
                      <img [src]="variety.urlIcon" style="width: 18px"/>
                      <div>{{ variety.name }}</div>
                  </div>
              </ng-template>
            </p-dropdown>
            </div>
            <div class="col-12 md:col">
              <input id="code" 
              formControlName="id" 
              type="text" 
              pInputText 
              placeholder="{{'UserPages.UserClosedSale.DirectSaleFilters.ExportOfferCode' | translate}}"
              style="background-color: #EDEFF2; height: 46px;" (keyup)="executeFilter()"
              >
            </div>
            <div class="col-12 md:col">
              <p-dropdown 
              placeholder="{{'UserPages.UserClosedSale.DirectSaleFilters.ImporterFilter' | translate}}" 
              class="p-inputtext-sm"
              optionLabel="companyName"
              optionValue="id"
              formControlName="importerId"
              [options]="importers"
              (onChange)="executeFilter()"
              [style]="{'background-color': '#EDEFF2'}"
              [showClear]="true"
              ></p-dropdown>
            </div>
            <div class="col-12 md:col">
              <p-dropdown 
              placeholder="{{'UserPages.UserClosedSale.DirectSaleFilters.StatusFilter' | translate}}"
              [options]="statusArray"
              formControlName="status"
              optionLabel="label"
              optionValue="value"
              [showClear]="true" 
              (onChange)="executeFilter()"
              [style]="{'background-color': '#EDEFF2'}"
              class="p-inputtext-sm">
            </p-dropdown>
            </div>
            <div class="col-12 md:col">
              <p-button (click)="showAdvanceFilter()" 
              [style]="{'background-color': '#FFF', 'border': '1px solid #8c2b87', 'color': '#8c2b87', 'height': '46px'}"
              label="{{'UserPages.UserClosedSale.DirectSaleFilters.AdvancedFilters' | translate}}" class="p-inputtext-sm" styleClass="p-button-outlined">
              <span class="material-symbols-outlined" style="margin-right: 6px;">
                add
                </span>
              </p-button>
            </div>
            <div class="col-12 md:col">
              <p-button label="{{'UserPages.UserClosedSale.DirectSaleFilters.CleanFilters' | translate}}" styleClass="p-button-outlined" [style]="{'background-color': '#FFF', 'border': '1px solid #8c2b87', 'color': '#8c2b87', 'height': '46px'}" (click)="cleanFilter()">
                <span class="material-symbols-outlined" style="margin-right: 6px;">mop</span>
              </p-button>
            </div>
          </div>
        </form>
</div>
