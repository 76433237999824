import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { DocumentServiceGateway } from 'src/app/application/gateway/DocumentServiceGateway';
import { PalletGateway } from 'src/app/application/gateway/PalletGateway';
import { QualityControlReviewGateway } from 'src/app/application/gateway/QualityControlReviewGateway';
import { Pallet } from 'src/app/domain/models/Pallet';
import { QualityControlReview, QualityControlReviewUpdate } from 'src/app/domain/models/QualityControlReview';
import { Routes } from 'src/app/infrastructure/routes';
import { ErrorMessageUtil } from 'src/app/ui/util/error-message-util';

@Component({
  selector: 'app-admin-qc-review',
  templateUrl: './admin-qc-review.component.html',
  styleUrls: ['./admin-qc-review.component.css'],
  providers: [MessageService]
})
export class AdminQcReviewComponent implements OnInit {

  reviewId: string | undefined
  review: QualityControlReview | undefined

  pallets: Pallet[] = []
  verifiedPallets: Pallet[] = []

  submit: boolean = false
  loading: boolean = false
  success: boolean = false

  formGroup: FormGroup = this._formBuilder.group({
    qualityControlDate: [null, Validators.required],
    originQCReviewPrice: [null, Validators.required],
    qcObservations: [null]
  })

  constructor(private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _messageService: MessageService,
    private _formBuilder: FormBuilder,
    private _authService: AuthGateway,
    public translate: TranslateService,
    private _palletService: PalletGateway,
    private _documentService: DocumentServiceGateway,
    private _qualityControlReviewService: QualityControlReviewGateway) {

    this._activatedRoute.params.subscribe(params => {
      this.reviewId = params['reviewId']
    })
  }

  ngOnInit(): void {
    this.loadReview()
  }

  loadReview() {
    this.loading = true
    if(this.reviewId) {
      this._qualityControlReviewService.getByIdFreshFruit(this.reviewId).subscribe({
        next: (v) => {
          console.log(v)
          this.review = v
          this.pallets = this.review.pallets
          this.loadForm()
          this.loading = false
        },
        error: (e) => {
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: 'Could not retrieve the import order detail' });

          this.loading = false
          console.log(e)
        },
        complete: () =>  {
          this.loading = false
          console.info('Import order detail query successfully')
        }
      })
    }
  }

  loadForm() {
    if(this.review) {
      this.formGroup.controls['qualityControlDate'].setValue(this.review.qualityControlDate ? new Date(this.review.qualityControlDate) : null)
      this.formGroup.controls['qcObservations'].setValue(this.review.observations)
      this.formGroup.controls['originQCReviewPrice'].setValue(this.review.price ? this.review.price : null)
    }
  }

  saveConfirmPallets() {
    this.submit = true
    this.loading = true
    if(this.review && this.formGroup.valid) {

      this.verifiedPallets.forEach(x => {
        x.fridgeStorageVerified = true
      })

      const request: QualityControlReviewUpdate = {
        guid: this.review.guid,
        pallets: this.verifiedPallets,
        qualityControlDate: this.formGroup.controls['qualityControlDate'].value,
        observations: this.formGroup.controls['qcObservations'].value,
        price: this.formGroup.controls['originQCReviewPrice'].value
      }

      this._qualityControlReviewService.verifyFreshFruitOrigin(request).subscribe({
        next: (v) => {
          this._messageService.add({ severity: 'success', summary: 'Acción exitosa', detail: 'Origin QC review updated successfully.' })
          this.submit = false
          this.loading = false
          this.success = true
        },
        error: (e) => {
          console.log(e)
          this.submit = false
          this.loading = false
          this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'Can not update review.' })
        },
        complete: () =>  {
          this.submit = false
          this.loading = false
          console.info('Review updated successfully')
        }
      })
    }
    else {
      this.loading = false
    }
  }

  goToReviews() {
    this._router.navigate(['/' + Routes.adminManageQCReviews])
  }

  getErrorMessage(formGroup: FormGroup, fieldName: string): string {
    return ErrorMessageUtil.getErrorMessage(formGroup, this.translate, fieldName)
  }

  goToReviewDocuments() {
    if(this.review)
      this._router.navigate(['/' + Routes.adminQCReviewDocuments, this.review.guid])
  }
}
