import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, ConfirmEventType, MenuItem, MessageService } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { CompanyGateway } from 'src/app/application/gateway/CompanyGateway';
import { DocumentServiceGateway } from 'src/app/application/gateway/DocumentServiceGateway';
import { ExportOfferGateway } from 'src/app/application/gateway/ExportOfferGateway';
import { ImportOrderDetailGateway } from 'src/app/application/gateway/ImportOrderDetailGateway';
import { ImportOrderGateway } from 'src/app/application/gateway/ImportOrderGateway';
import { OperationGateway } from 'src/app/application/gateway/OperationGateWay';
import { PaymentGateway } from 'src/app/application/gateway/PaymentGateway';
import { PaymentQualityControlService } from 'src/app/application/service/PaymentQualityControlService';
import { Company } from 'src/app/domain/models/Company';
import { Delivery } from 'src/app/domain/models/Delivery';
import { DeliveryDiscount } from 'src/app/domain/models/DeliveryDiscount';
import { ExportOffer } from 'src/app/domain/models/ExportOffer';
import { GenericOrderDetail, ImportOrderInformation } from 'src/app/domain/models/ImportOrder';
import { OperationInformation } from 'src/app/domain/models/Operation';
import { OperationDocument } from 'src/app/domain/models/OperationDocument';
import { Packaging } from 'src/app/domain/models/Packaging';
import { Payment, PaymentsInformation } from 'src/app/domain/models/Payment';
import { PaymentQualityControl } from 'src/app/domain/models/PaymentQualityControl';
import { ProductCertificateType } from 'src/app/domain/models/ProductCertificateType';
import { BasicUser } from 'src/app/domain/models/User';
import { Routes } from 'src/app/infrastructure/routes';
import { ErrorMessageUtil } from 'src/app/ui/util/error-message-util';
import { IImportOrderStatus, OrderUtils } from 'src/app/ui/util/order-utils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin-order-detail',
  templateUrl: './admin-order-detail.component.html',
  styleUrls: ['./admin-order-detail.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class AdminOrderDetailComponent implements OnInit {

  breadcrumbMenuItems: MenuItem[] = [{label: 'Detalle de orden'}]
  breadcrumbHome: MenuItem = {label: 'Ordenes de compra', routerLink: ['/' + Routes.adminManageOrderPage]}

  orderId: any | undefined
  order: ImportOrderInformation | undefined
  details: GenericOrderDetail[] = []
  delivery: Delivery | undefined
  payments: Payment[] = []
  operationInformation: OperationInformation | undefined;
  documents: OperationDocument[] = []
  selectedExportOffer: ExportOffer | undefined
  selectedProductCertificateTypes: ProductCertificateType[] = []
  selectedPackagingTypes: Packaging[] = []
  discounts: DeliveryDiscount[] = []
  paymentsInformation: PaymentsInformation | undefined
  forwarder: Company | undefined
  customAgent: Company | undefined
  companyForwarders: Company[] = []
  companyCustomAgent: Company[] = []
  selectedCompanyForwarder: number | undefined
  selectedCompanyCustomAgent: number | undefined

  paymentsQc: PaymentQualityControl[] = [];

  visibleExportOfferDialog: boolean = false
  visibleEditDocumentDialog: boolean = false
  isClosedSale: boolean = false

  uploadedFiles: any[] = []
  statuses: IImportOrderStatus[] = OrderUtils.getImportOrderStatusList()

  profile: BasicUser | undefined

  operationFormGroup: FormGroup = this._formBuilder.group({
    name: [null, Validators.required],
  })

  constructor(private _router: Router,
    private _formBuilder: FormBuilder,
    private _activatedRoute: ActivatedRoute,
    private _messageService: MessageService,
    private _confirmationService: ConfirmationService,
    private _authService: AuthGateway,
    private _importOrderService: ImportOrderGateway,
    private _paymentService: PaymentGateway,
    private _operationService: OperationGateway,
    private _documentService: DocumentServiceGateway,
    private _exportOfferService: ExportOfferGateway,
    private _companyService: CompanyGateway,
    public translate: TranslateService,
    private _paymentQualityControlService: PaymentQualityControlService,
    private _importOrderDetailService: ImportOrderDetailGateway
  ) {

      this._activatedRoute.params.subscribe(params => {
        this.orderId = params['orderId']
      })
  }

  ngOnInit(): void {
    if(this.orderId) {
      this.profile = this._authService.getUser()
      this.loadImportOrder()
      this.loadCompanies(0)
      this.loadCompanies(1)
    }
  }

  loadImportOrder() {
    this._importOrderService.getById(this.orderId).subscribe({
      next: (v) => {
        this.order = v
        this.delivery = this.order.delivery
        this.details = this.order.genericDetails
        this.discounts = this.order.discounts ? this.order.discounts : []
        this.payments = this.order.payments ? this.order.payments : []
        this.operationInformation = this.order.operation
        this.isClosedSale = this.order.importOrder.isClosedSale
        this.forwarder = this.order.importOrder.forwarderCompany
        this.customAgent = this.order.importOrder.customAgent
        this.loadQcPayments()
      },
      error: (e) => {
        console.log(e)
        this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudo recuperar la orden.' });
      },
      complete: () => console.info('Order query successfully')
    })
  }

  loadCompanies(type: number) {
    this._companyService.getAllByCompanyType(type).subscribe({
      next: (v) => {
        if (type === 0) this.companyForwarders = v
        if (type === 1) this.companyCustomAgent = v
      },
      error: (e) => {
        console.log(e)
        this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No fue posible recuperar los forwarders.' });
      },
      complete: () =>  {
        this.loading = false
        console.info('Company query successfully')
      }
    })
  }

  loadPayments() {
    if(this.order) {
      this._paymentService.getAllByImportOrderId(this.order?.importOrder.guid).subscribe({
        next: (v) => {
          this.paymentsInformation = v
          this.payments = this.paymentsInformation.payments
        },
        error: (e) => {
          console.log(e)
          this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudieron recuperar los pagos.' });
        },
        complete: () => {
          console.info('Payments query successfully')
        }
      })
    }
  }

  loadQcPayments()
  {
    if(this.order) {
      this._paymentQualityControlService.getAllByOrderId(this.order?.importOrder.guid).subscribe({
        next: (v) => {
          this.paymentsQc = v
        },
        error: (e) => {
          console.log(e)
          this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudieron recuperar los pagos de QC.' });
        },
        complete: () => {
          console.info('Payments qc query successfully')
        }
      })
    }
  }

  downloadQcDocument(paymentQcGuid: string)
  {
    this._documentService.getQCPaymentPdf(paymentQcGuid).subscribe((resultBlob: any) => {
      var downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }

  approveQcPayment(paymentQcId: number)
  {
    this.loading = true
    this._paymentQualityControlService.approvePayment(paymentQcId).subscribe({
      next: (v) => {
        this.loadImportOrder()
        this.loading = false
        this._messageService.add({ severity: 'success', summary: 'Acción exitosa', detail: 'El pago fue aprobado con éxito.' });
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudo aprobar el pago.' });
      },
      complete: () => {
        this.loading = false
      }
    })
  }

  getDetailPalletPrice(detail: GenericOrderDetail): number {
    let total = 0

    if(detail.palletPrices)
    {
      detail.palletPrices.forEach(x => {
        total += x.price ? x.boxPerPallets * x.price : 0;
      })
      return total
    }
    return 0
  }

  loadOperation(){
    if(this.delivery && this.payments)
    {
      this._operationService.getByImportOrderId(this.orderId).subscribe({
        next: (v) => {
          console.log(v);
          this.operationInformation = v
          this.documents = this.operationInformation.documents
        },
        error: (e) => {
          console.log(e)
          this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudieron recuperar los pagos.' });
        },
        complete: () => {
          console.info('Operation query successfully')
        }
      })
    }
  }

  getImageUrl(): string {
    return OrderUtils.getImageUrl(this.order)
  }

  getOrderTitle(): string {
    return OrderUtils.getOrderTitle(this.order?.importOrder.sequence)
  }

  getDetailTotalCases(detail: GenericOrderDetail): number {
    return detail.palletsQuantity > 0 && detail.boxesPerPallet > 0 ? detail.palletsQuantity * detail.boxesPerPallet : detail.boxesQuantity
  }

  getDetailTotalPrice(detail: GenericOrderDetail): number {
    return detail.palletsQuantity > 0 && detail.boxesPerPallet > 0 ? detail.palletsQuantity * detail.boxesPerPallet * detail.price : detail.boxesQuantity * detail.price
  }

  getOrderTotalPrice(): number {
    let total = 0
    if(this.details) {
      this.details.forEach(x => {
        total += this.getDetailTotalPrice(x)
      })
    }
    return total
  }

  getOrderTotalPalletPrice(): number {
    let total = 0
    if(this.details) {
      this.details.forEach(x => {
        total += this.getDetailPalletPrice(x)
      })
    }
    return total
  }

  goToVerifyPallets(orderDetailId: number) {
    if(this.order) {
      if(!this.order.importOrder.isFrozenFruit)
        this._router.navigate(['/' + Routes.adminVerifyPallets, orderDetailId])
      else
        this._router.navigate(['/' + Routes.adminFrozenFruitVerifyPalletsPage, orderDetailId])
    }
  }

  goToCompleteOrder(orderDetailId: number) {
    if(this.order) {
      this._router.navigate(['/' + Routes.adminCompleteOrder, orderDetailId])
    }
  }

  get totalCost(): number {
    if(this.order && this.delivery) {
      let totalPrice = this.isClosedSale ? this.getOrderTotalPalletPrice() : this.getOrderTotalPrice();
      return totalPrice +
        (this.delivery.oceanFreight ? this.delivery.oceanFreight : 0) +
        (this.delivery.inland ? this.delivery.inland : 0) +
        (this.delivery.localCharges ? this.delivery.localCharges : 0) +
        (this.delivery.customs ? this.delivery.customs : 0) +
        (this.delivery.otherCosts ? this.delivery.otherCosts : 0) +
        (this.delivery.surcharge ? this.delivery.surcharge : 0) +
        (this.delivery.handling ? this.delivery.handling : 0) +
        (this.delivery.gateOut ? this.delivery.gateOut : 0) +
        this.qcReviewCost
    }
    return 0
  }

  get totalPayments(): number {
    let total = 0
    if(this.payments) {
      this.payments.forEach(x => {
        total += x.amount
      })
    }
    return total
  }

  get balance(): number {
    return this.totalPayments - this.totalCost + this.totalDiscounts
  }

  get absoluteBalance(): number {
    return Math.abs(this.balance)
  }

  showDocument(fileType: string){
    if(this.delivery?.operation){
      if(fileType === 'Instructivo'){
        return;
      }
    }
  }

  approvePayment(paymentId: number) {
    this.loading = true
    this._paymentService.approvePayment(paymentId).subscribe({
      next: (v) => {
        this.loadPayments()
        this.loadImportOrder()
        this.loading = false
        this._messageService.add({ severity: 'success', summary: 'Acción exitosa', detail: 'El pago fue aprobado con éxito.' });
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudo aprobar el pago.' });
      },
      complete: () => {
        this.loading = false
        console.info('Payment approved successfully')
      }
    })
  }

  getOpenFile(url: string){
    window.open(environment.apiUrl + url, '_blank');
  }

  get environment() {
    return environment
  }

  goToDelivery() {
    if(this.order)
    this._router.navigate(['/' + Routes.adminDelivery, this.orderId])
  }

  goToOperation() {
    this._router.navigate(['/' + Routes.adminOperation, this.orderId])
  }

  viewExportOfferDetailDialog(detail: GenericOrderDetail) {
    if(detail) {
      this._exportOfferService.getById(detail.exportOfferId!).subscribe({
        next: (v) => {
          this.selectedExportOffer = v
          if(this.selectedExportOffer.productCertificateTypes)
            this.selectedProductCertificateTypes = this.selectedExportOffer.productCertificateTypes

          if(this.selectedExportOffer.packagingTypes)
            this.selectedPackagingTypes = this.selectedExportOffer.packagingTypes

          this.loading = false
          this.visibleExportOfferDialog = true
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOffer.ManyRetrieveError') })
        },
        complete: () => console.info('Export Offers query successfully')
      })

    }
  }

  selectedDocument: OperationDocument | undefined
  openEditDocumentDialog(document: OperationDocument, fileUploader: FileUpload) {
    fileUploader.clear()
    this.selectedDocument = document
    this.operationFormGroup.controls['name'].setValue(document.name)
    this.visibleEditDocumentDialog = true
  }

  selectedFile: any | undefined
  onSelect(event: any) {
    this.uploadedFiles = []
    this.selectedFile = event.files[0]
    this.operationFormGroup.controls['name'].setValue(this.selectedFile.name)
  }

  loading: boolean = false
  saveDocument() {
    if(this.operationFormGroup.valid && this.selectedDocument) {
      let document: OperationDocument = {
        id: this.selectedDocument.id,
        name: this.operationFormGroup.controls['name'].value,
        pdf: this.selectedFile
      }
      this.loading = true
      this._operationService.updateDocument(document).subscribe({
        next: (v) => {
          this.loadOperation()
          this._messageService.add({ severity: 'success', summary: 'Confirmed', detail: 'Se actualizó el documento correctamente.' })
          this.loading = false
        },
        error: (e) => {
          this.loading = false
          console.log(e)
          this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudo actualizar el documento.' });
        },
        complete: () => {
          this.loading = false
          console.log('Se actualizó el documento correctamente.')
        }
      })
      this.visibleEditDocumentDialog = false
    }
    else {
      this.loading = false
    }
  }

  onDelete(id: number) {
    this._confirmationService.confirm({
      message: '¿Está seguro de eliminar este documento?',
      header: 'Eliminar documento',
      icon: 'pi pi-info-circle',
      accept: () => {
        this._operationService.deleteDocument(id).subscribe({
          next: (v) => {
            this.loadOperation()
            this._messageService.add({ severity: 'info',
              summary: this.translate.instant('MessageService.Summary.SuccessAction'),
              detail: 'Se eliminó el documento exitosamente.' })
          },
          error: (e) => {
            console.log(e)
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: 'No se pudo eliminar el documento.' });
          },
          complete: () => console.info('Notify delete successfully')
        })
      },
      reject: (type: any) => {
          switch (type) {
              case ConfirmEventType.REJECT:
                  //this._messageService.add({ severity: 'error', summary: 'Rejected', detail: 'Se canceló la eliminación' })
                  break;
              case ConfirmEventType.CANCEL:
                  //this._messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Se canceló la eliminación' })
                  break;
          }
      }
    })
  }

  getErrorMessage(formGroup: FormGroup, fieldName: string): string {
    return ErrorMessageUtil.getErrorMessage(formGroup, this.translate, fieldName)
  }

  goToEditPallets(orderDetailId: number) {
    this._router.navigate(['/' + Routes.adminEditPallets, orderDetailId])
  }

  removeQcPayment(importOrderDetailId: string)
  {
    this.loading = true;
    this._importOrderDetailService.removeQcPayment(importOrderDetailId).subscribe({
      next: (v) => {
        this.loading = false
        this._messageService.add({ severity: 'success', summary: 'Acción exitosa', detail: 'Se retiro el QC exitosamente.' });
        this.loadImportOrder()
      },
      error: (v) => {
        this.loading = false
        this._messageService.add({ severity: 'success', summary: 'Acción exitosa', detail: 'No se logro retirar el QC.' });
      }
    })
  }

  getPaymentDocument(paymentId: number) {
    this._documentService.getPaymentDocument(paymentId).subscribe((resultBlob: any) => {
      var downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }

  getOperationDocument(documentId: number) {
    this._documentService.getOperationDocument(documentId).subscribe((resultBlob: any) => {
      var downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }

  getProductCertificateDocument(productCertificateId: number, importOrderId: any) {
    this._documentService.getProductCertificateDocument(productCertificateId, importOrderId).subscribe((resultBlob: any) => {
      var downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }

  getExportOfferDocument(exportOfferDocumentId: number, importOrderId: any) {
    this._documentService.getExportOfferDocument(exportOfferDocumentId, importOrderId).subscribe((resultBlob: any) => {
      var downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }

  getQCReviewDocument(importOrderDetailId: any) {
    this._documentService.getQCReviewDocument(importOrderDetailId).subscribe((resultBlob: any) => {
      var downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }

  getQCConsolidationDocument(importOrderDetailId: any) {
    this._documentService.getQCConsolidationDocument(importOrderDetailId).subscribe((resultBlob: any) => {
      var downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }

  getFrozenFruitQCReviewDocument(importOrderDetailId: any) {
    this._documentService.getFrozenFruitQCReviewDocument(importOrderDetailId).subscribe((resultBlob: any) => {
      var downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }

  getFrozenFruitQCConsolidationDocument(importOrderDetailId: any) {
    this._documentService.getFrozenFruitQCConsolidationDocument(importOrderDetailId).subscribe((resultBlob: any) => {
      var downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }

  getFrozenFruitPesticideAnalysisDocument(importOrderDetailId: any) {
    this._documentService.getFrozenFruitPesticideAnalysisDocument(importOrderDetailId).subscribe((resultBlob: any) => {
      var downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }

  getFrozenFruitHeavyMetalsAnalysisDocument(importOrderDetailId: any) {
    this._documentService.getFrozenFruitHeavyMetalsAnalysisDocument(importOrderDetailId).subscribe((resultBlob: any) => {
      var downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }

  getFrozenFruitMicrobiologicalAnalysisDocument(importOrderDetailId: any) {
    this._documentService.getFrozenFruitMicrobiologicalAnalysisDocument(importOrderDetailId).subscribe((resultBlob: any) => {
      var downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }

  getFrozenFruitWaterAnalysisDocument(importOrderDetailId: any) {
    this._documentService.getFrozenFruitWaterAnalysisDocument(importOrderDetailId).subscribe((resultBlob: any) => {
      var downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }

  getDocumentType(documentType: number): string {
    let documentTypeName = ''
    if(this.profile) {
      switch(this.profile.lang) {
        case 'en':
          switch(documentType) {
            case 1:
              documentTypeName = 'Fresh fruit QC review'
            break
            case 2:
              documentTypeName = 'Fresh fruit QC consolidation'
            break
            case 3:
              documentTypeName = 'Frozen fruit QC review'
            break
            case 4:
              documentTypeName = 'Frozen fruit QC consolidation'
            break
            case 5:
              documentTypeName = 'Operation document'
            break
            case 6:
              documentTypeName = 'Product certificate document'
            break
            case 7:
              documentTypeName = 'Export offer document'
            break
          }
          break
        case 'es':
          switch(documentType) {
            case 1:
              documentTypeName = 'Revisión control de calidad fruta fresca'
            break
            case 2:
              documentTypeName = 'Consolidación control de calidad fruta fresca'
            break
            case 3:
              documentTypeName = 'Revisión control de calidad fruta congelada'
            break
            case 4:
              documentTypeName = 'Consolidación control de calidad fruta congelada'
            break
            case 5:
              documentTypeName = 'Documento de operación'
            break
            case 6:
              documentTypeName = 'Documento certificación de producto'
            break
            case 7:
              documentTypeName = 'Documento técnico de oferta de exportación'
            break
          }
          break
      }
    }
    return documentTypeName
  }

  get qcReviewCost(): number {
    let total = 0
    let totalTax = 0;
    if(this.details) {
      this.details.forEach(x => {
        totalTax = x.qcTaxPrice ? x.qcTaxPrice : 0;
        total += x.qcGeneratedPrice ? x.qcGeneratedPrice + totalTax : 0
        
      })
    }
    return total
  }

  get totalDiscounts(): number {
    let total = 0
    if(this.discounts) {
      this.discounts.forEach(x => {
        total += x.amount
      })
    }
    return total
  }

  get totalQcPayments(): number {
    let total = 0;

    if(this.paymentsQc){
      this.paymentsQc.forEach(x => {
        total += x.value
      })
    }

    return total;
  }

  onChangeCompanyForwarder(event: any) {
    this.selectedCompanyForwarder = event.value
  }

  assignForwarder() {
    if (this.selectedCompanyForwarder)
    {
      let company = this.selectedCompanyForwarder
      this._confirmationService.confirm({
        message: '¿Está seguro de asignar este Forwarder?',
        header: 'Asignar Forwarder',
        icon: 'pi pi-info-circle',
        accept: () => {
          this._importOrderService.assingForwarder(this.orderId, company).subscribe({
            next: (v) => {
              this.loadImportOrder()
              this.loading = false
              this._messageService.add({ severity: 'success', summary: 'Acción exitosa', detail: 'El Forwarder fue asignado con éxito.' });
            },
            error: (e) => {
              console.log(e)
              this.loading = false
              this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No fue posible asignar el Forwarder.' });
            },
            complete: () => {
              this.loading = false
              console.info('Forwarder assigned successfully')
            }
          })
        },
        reject: (type: any) => {}
      })
    }
  }

  onChangeCompanyCustomAgent(event: any) {
    this.selectedCompanyCustomAgent = event.value
  }

  assignCustomAgent() {
    if (this.selectedCompanyCustomAgent)
    {
      let company = this.selectedCompanyCustomAgent
      this._confirmationService.confirm({
        message: '¿Está seguro de asignar este Custom Agent?',
        header: 'Asignar Custom Agent',
        icon: 'pi pi-info-circle',
        accept: () => {
          this._importOrderService.assingCustomAgent(this.orderId, company).subscribe({
            next: (v) => {
              this.loadImportOrder()
              this.loading = false
              this._messageService.add({ severity: 'success', summary: 'Acción exitosa', detail: 'El Custom Agent fue asignado con éxito.' });
            },
            error: (e) => {
              console.log(e)
              this.loading = false
              this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No fue posible asignar el Custom Agent.' });
            },
            complete: () => {
              this.loading = false
              console.info('Custom Agent assigned successfully')
            }
          })
        },
        reject: (type: any) => {}
      })
    }
  }
}
