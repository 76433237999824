import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, ConfirmEventType, MenuItem, MessageService } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { CountryGateway } from 'src/app/application/gateway/CountryGateway';
import { DocumentServiceGateway } from 'src/app/application/gateway/DocumentServiceGateway';
import { ExportOfferDocumentGateway } from 'src/app/application/gateway/ExportOfferDocumentGateway';
import { ExportOfferGateway } from 'src/app/application/gateway/ExportOfferGateway';
import { FridgeStorageGateway } from 'src/app/application/gateway/FridgeStorageGateway';
import { ProductCertificateGateway } from 'src/app/application/gateway/ProductCertificateGateway';
import { ProductCertificateTypeGateway } from 'src/app/application/gateway/ProductCertificateTypeGateway';
import { ProfileGateway } from 'src/app/application/gateway/ProfileGateway';
import { SpeciesGateway } from 'src/app/application/gateway/SpeciesGateway';
import { VarietyGateway } from 'src/app/application/gateway/VarietyGateway';
import { Country } from 'src/app/domain/models/Country';
import { ExportOffer, OfferPicture } from 'src/app/domain/models/ExportOffer';
import { ExportOfferDocument, NewExportOfferDocument } from 'src/app/domain/models/ExportOfferDocument';
import { FridgeStorage } from 'src/app/domain/models/FridgeStorage';
import { NewProductCertificate, ProductCertificate } from 'src/app/domain/models/ProductCertificate';
import { ProductCertificateType } from 'src/app/domain/models/ProductCertificateType';
import { Species, SpeciesSize } from 'src/app/domain/models/Species';
import { BasicUser } from 'src/app/domain/models/User';
import { Variety } from 'src/app/domain/models/Variety';
import { Routes } from 'src/app/infrastructure/routes';
import { ErrorMessageUtil } from 'src/app/ui/util/error-message-util';
import { ExportOfferUtils } from 'src/app/ui/util/export-offer-utils';
import { SpeciesUtils } from 'src/app/ui/util/species-utils';
import { environment } from '../../../../../../environments/environment'
import { formLimit } from 'src/app/ui/util/form-limit';

interface Picture {
  picture: OfferPicture
  file: any
}

@Component({
  selector: 'app-edit-export-offer',
  templateUrl: './edit-export-offer.component.html',
  styleUrls: ['./edit-export-offer.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class EditExportOfferComponent implements OnInit {
  breadcrumbMenuItems: MenuItem[] = []
  breadcrumbHome: MenuItem = {}

  exportOfferId: number | undefined
  exportOffer: ExportOffer | undefined

  species: Species[] = []
  varieties: Variety[] = []
  colors: any[] = []
  sizes: SpeciesSize[] = []
  qualityControlOptions: any[] = []
  certificateTypes: ProductCertificateType[] = []
  boxTypes: any[] = []
  kilosPerBoxes: any[] = []
  basePallets: any[] = []
  selectedCertifications: ProductCertificateType[] = []
  fridgeStorages: FridgeStorage[] = []
  pictures: Picture[] = []
  marksOptions: any[] = []
  removedPictures: number[] = []
  countries: Country[] = []
  selectedVariety: Variety | undefined
  selectedFridgestorage: FridgeStorage | undefined
  selectedColor: any | undefined

  productCertificateDialogVisible: boolean = false
  submitProductCertificate: boolean = false
  productCertificates: any[] = []
  productCertificatePdfFile: any
  documents: any[] = []
  documentDialogVisible: boolean = false
  submitDocument: boolean = false

  incotermsOptions: any[] = ExportOfferUtils.getIncotermsOptions()

  loading: boolean = false
  submit: boolean = false

  formLimit = formLimit;

  profile: BasicUser | undefined

  formGroup: FormGroup = this._formBuilder.group({
    speciesId: ['', Validators.required],
    varietyId: ['', Validators.required],
    availablePallets: [null, Validators.required],
    fridgeStorageId: ['', Validators.required],
    listPrice: [null, Validators.required],
    caseQuantityPerPallet: [null, Validators.required],
    incoterm: ['', Validators.required],
    color: [''],
    speciesSize: [''],
    harvestDate: [null, Validators.required],
    readyToLoadDate: [null],
    shelfLife: [null, Validators.required],
    boxType: ['', Validators.required],
    caseSize: [''],
    kilosPerBox: [''],
    netKilos: ['', Validators.required],
    grossKilos: ['', Validators.required],
    isQualityControl: [false],
    qualityControlDate: [null],
    basePallets: [''],
    marks: [false],
    marketCountry1Id: ['', Validators.required],
    marketCountry2Id: [''],
    marketCountry3Id: [''],
    sizeMillimeters: [null],
    category: [null]
  })

  productCertificateFormGroup: FormGroup = this._formBuilder.group({
    name: ['', Validators.required],
    documentIssueDate: [null],
    expirationDate: [null],
    productCertificateTypeId: ['', Validators.required]
  })

  documentPdfFile: any
  documentFormGroup: FormGroup = this._formBuilder.group({
    name: ['', Validators.required]
  })

  constructor(private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _messageService: MessageService,
    private _confirmationService: ConfirmationService,
    private _authService: AuthGateway,
    private _profileService: ProfileGateway,
    private _varietyService: VarietyGateway,
    private _speciesService: SpeciesGateway,
    private _fridgeStorageService: FridgeStorageGateway,
    private _exportOfferService: ExportOfferGateway,
    private _productCertificateTypeService: ProductCertificateTypeGateway,
    private _productCertificateService: ProductCertificateGateway,
    private _countryService: CountryGateway,
    private _exportOfferDocumentService: ExportOfferDocumentGateway,
    private _documentService: DocumentServiceGateway,
    public translate: TranslateService) {
    this._activatedRoute.params.subscribe(params => {
      this.exportOfferId = params['exportOfferId']
    })
  }

  ngOnInit(): void {
    let breadcrumbMenuItemsItem1Label = 'Export offer detail'
    let breadcrumbHomeItemLabel = 'My export offers'

    if(this.profile) {
      switch(this.profile.lang) {
        case 'en':
          breadcrumbMenuItemsItem1Label = 'Export offer detail'
          breadcrumbHomeItemLabel = 'My export offers'
          break
        case 'es':
          breadcrumbMenuItemsItem1Label = 'Detalle de oferta de exportación'
          breadcrumbHomeItemLabel = 'Mis Ofertas de exportación'
          break
      }
    }

    this.breadcrumbMenuItems = [{ label: breadcrumbMenuItemsItem1Label }]
    this.breadcrumbHome = { label: breadcrumbHomeItemLabel, command: () => { this._router.navigate(['/' + Routes.userExportOffers])}}

    this.profile = this._authService.getUser()

    if(this.profile) {
      this.colors = ExportOfferUtils.getColors(this.profile.lang)
      this.boxTypes = ExportOfferUtils.getBoxTypes(this.profile.lang)
      this.kilosPerBoxes = ExportOfferUtils.getKilosPerBox()
      this.basePallets = ExportOfferUtils.getBasePallets(this.profile.lang)
      this.qualityControlOptions = ExportOfferUtils.getControlQualityOptions(this.profile.lang)
      this.marksOptions = ExportOfferUtils.getMarksOptions(this.profile.lang)
    }

    this.loadProductCertificateTypes()
    this.loadExportOffer()
  }

  getSpeciesName(species: Species | undefined): string {
    if(this.profile)
      return SpeciesUtils.getSpeciesName(this.profile.lang, species)
    return ''
  }

  loadExportOffer() {
    if(this.exportOfferId) {
      this._exportOfferService.getById(this.exportOfferId).subscribe({
        next: (v) => {
          this.exportOffer = v
          this.loadForm()
        },
        error: (e) => {
          console.log(e)
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOffer.SingleRetrieveError') });
        },
        complete: () => console.info('ExportOffer query successfully')
      })
    }
  }

  loadForm() {
    if(this.exportOffer) {
      this.loadSpecies()
      this.formGroup.controls['speciesId'].setValue(this.exportOffer.variety.speciesId)
      this.loadVarieties(true)
      this.loadSpeciesSizes(true)
      this.formGroup.controls['availablePallets'].setValue(this.exportOffer.availablePallets)
      this.formGroup.controls['caseQuantityPerPallet'].setValue(this.exportOffer.caseQuantityPerPallet)
      this.formGroup.controls['listPrice'].setValue(this.exportOffer.listPrice)
      this.loadFridgeStorages(true)
      this.formGroup.controls['color'].setValue(this.exportOffer.color)
      this.selectedColor = this.colors.find(x => x.value == this.exportOffer?.color)
      this.formGroup.controls['harvestDate'].setValue(this.exportOffer.harvestDate ? new Date(this.exportOffer.harvestDate) : null)
      this.formGroup.controls['readyToLoadDate'].setValue(this.exportOffer.readyToLoadDate ? new Date(this.exportOffer.readyToLoadDate) : null)
      this.formGroup.controls['shelfLife'].setValue(this.exportOffer.shelfLife)
      this.formGroup.controls['boxType'].setValue(this.exportOffer.boxType)
      this.formGroup.controls['kilosPerBox'].setValue(this.exportOffer.kilosPerBox)
      this.formGroup.controls['netKilos'].setValue(this.exportOffer.netKilos);
      this.formGroup.controls['grossKilos'].setValue(this.exportOffer.grossKilos);
      this.formGroup.controls['isQualityControl'].setValue(this.exportOffer.isQualityControl)
      this.formGroup.controls['qualityControlDate'].setValue(this.exportOffer.qualityControlDate ? new Date(this.exportOffer.qualityControlDate) : null)
      this.formGroup.controls['basePallets'].setValue(this.exportOffer.basePallets)
      this.formGroup.controls['marks'].setValue(this.exportOffer.marks)
      this.formGroup.controls['incoterm'].setValue(this.exportOffer.typeSale)
      this.formGroup.controls['sizeMillimeters'].setValue(this.exportOffer.sizeMillimeters)
      this.formGroup.controls['category'].setValue(this.exportOffer.category)
      this.formGroup.controls['caseSize'].setValue(this.exportOffer.caseSize)
      this.loadCountries(true)

      this.exportOffer.offerPictures?.forEach(async p => {
        p.isNew = false
        this.pictures.push({picture: p, file: null})
      })

      //Copy new array
      this.selectedCertifications = []
      this.selectedCertifications = this.exportOffer.productCertificates ? this.exportOffer.productCertificates : []

      this.loadProductCertificates()
      this.loadDocuments()
    }
  }

  loadSpeciesParams() {
    this.loadVarieties(false)
    this.loadSpeciesSizes(false)
    this.loadCountries(false)
  }

  loadVarieties(setValue: boolean) {
    this.varieties = []
    if(this.exportOffer) {
      this._varietyService.getAllBySpecies(this.exportOffer.variety.speciesId).subscribe({
        next: (v) => {
          this.varieties = v
          this.loading = false
          if(setValue && this.exportOffer && this.exportOffer.variety) {
            this.formGroup.controls['varietyId'].setValue(this.exportOffer.variety.id)
            this.selectedVariety = this.varieties.find(x => x.id === this.formGroup.controls['varietyId'].value)
          }
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.Variety.ManyRetrieveError') })
        },
        complete: () => console.info('Variety query successfully')
      })
    }
  }

  loadSpeciesSizes(setValue: boolean) {
    this.sizes = []
    this._speciesService.getSpeciesSizes(this.formGroup.controls['speciesId'].value).subscribe({
      next: (v) => {
        this.sizes = v
        this.loading = false
        if(setValue && this.exportOffer && this.exportOffer.speciesSize)
          this.formGroup.controls['speciesSize'].setValue(this.exportOffer.speciesSize)
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: 'No se pudieron recuperar los calibres.' })
      },
      complete: () => console.info('Species Sizes query successfully')
    })
  }

  loadSpecies() {
    this._speciesService.getAll().subscribe({
      next: (v) => {
        this.species = v
        this.loading = false
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Species.ManyRetrieveError') })
      },
      complete: () => console.info('Species query successfully')
    })
  }

  loadProductCertificateTypes() {
    this._productCertificateTypeService.getAll().subscribe({
      next: (v) => {
        this.certificateTypes = v
        this.loading = false
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.ProductCertificateType.ManyRetrieveError') })
      },
      complete: () => console.info('ProductCertificateTypes query successfully')
    })
  }

  loadFridgeStorages(setValue: boolean) {
    this.fridgeStorages = []
    this._fridgeStorageService.getAllByStorageType(1).subscribe({
      next: (v) => {
        this.fridgeStorages = v
        this.loading = false
        if(setValue && this.exportOffer) {
          this.formGroup.controls['fridgeStorageId'].setValue(this.exportOffer.fridgeStorageId)
          this.selectedFridgestorage = this.fridgeStorages.find(x => x.id === this.formGroup.controls['fridgeStorageId'].value)
        }
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.FridgeStorage.ManyRetrieveError') })
      },
      complete: () => console.info('Fridge Storage query successfully')
    })
  }

  loadCountries(setValue: boolean) {
    this.countries = []
    this._countryService.getAll().subscribe({
      next: (v) => {
        this.countries = v
        this.loading = false
        if(setValue && this.exportOffer) {
          this.formGroup.controls['marketCountry1Id'].setValue(this.exportOffer.marketCountryFirstId)
          this.formGroup.controls['marketCountry2Id'].setValue(this.exportOffer.marketCountrySecondId)
          this.formGroup.controls['marketCountry3Id'].setValue(this.exportOffer.marketCountryThirdId)
        }
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Country.ManyRetrieveError') })
      },
      complete: () => console.info('Country query successfully')
    })
  }

  loadProductCertificates() {
    this.productCertificates =  []
    if(this.exportOffer) {
      this._productCertificateService.getAllByExportOfferId(this.exportOffer.id).subscribe({
        next: (v) => {
          this.productCertificates = v
          this.loading = false
        },
        error: (e) => {
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ProductCertificate.ManyRetrieveError') });
          console.log(e)
          this.loading = false
        },
        complete: () =>  {
          this.loading = false
          console.info('Product Certificates query successfully')
        }
      })
    }
  }

  loadDocuments() {
    this.documents =  []
    if(this.exportOffer) {
      this._exportOfferDocumentService.getAllByExportOffer(this.exportOffer.id).subscribe({
        next: (v) => {
          this.documents = v
          this.loading = false
        },
        error: (e) => {
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOfferDocument.ManyRetrieveError') });
          console.log(e)
          this.loading = false
        },
        complete: () =>  {
          this.loading = false
          console.info('Export Offer Documents query successfully')
        }
      })
    }
  }

  onChangeVariety() {
    this.selectedVariety = this.varieties.find(x => x.id === this.formGroup.controls['varietyId'].value)
  }

  onChangeFridgestorage() {
    this.selectedFridgestorage = this.fridgeStorages.find(x => x.id === this.formGroup.controls['fridgeStorageId'].value)
  }

  onChangeColor() {
    this.selectedColor = this.colors.find(x => x.value === this.formGroup.controls['color'].value)
  }

  getImageUrl(picture: Picture): any {
    if(picture) {
      if(!picture.picture.isNew && picture.picture.url) {
        return environment.apiUrl + picture.picture.url
      }
      else {
        return picture.file.objectURL
      }
    }
    return ''
  }

  getImageName(picture: Picture): string {
    let fileName = ''
    if(!picture.picture.isNew && picture.picture.url) {
      const urlParts: string[] = picture.picture.url.split('/')
      fileName = urlParts[urlParts.length-1]
    }
    else {
      return picture.file.name
    }
    return fileName
  }

  onSelectImage(event: any, uploader: any): void {
    this.pictures.push(
      {
        picture: {id: 0,
                  url: uploader.files[0].objectURL.changingThisBreaksApplicationSecurity,
                  isNew: true},
        file: uploader.files[0]
      })
    uploader.clear()
  }

  onRemoveImage(index: number): void {
    if(this.exportOffer && this.pictures[index]) {
      this.removedPictures.push(this.pictures[index].picture.id)
      this.pictures.splice(index, 1)
    }
  }

  onClearImageUploader(event: any, uploader: any) {
    //this.uploadedImages = uploader.files
  }

  goToExportOffersPage() {
    this._router.navigate(['/' + Routes.userExportOffers])
  }

  save() {
    this.loading = true
    this.submit = true
    if(this.formGroup.valid && this.exportOffer) {
      this.exportOffer.varietyId = this.formGroup.controls['varietyId'].value
      this.exportOffer.availablePallets = this.formGroup.controls['availablePallets'].value
      this.exportOffer.fridgeStorageId = this.formGroup.controls['fridgeStorageId'].value
      this.exportOffer.color = this.formGroup.controls['color'].value
      this.exportOffer.harvestDate = this.formGroup.controls['harvestDate'].value
      this.exportOffer.readyToLoadDate = this.formGroup.controls['readyToLoadDate'].value
      this.exportOffer.boxType = this.formGroup.controls['boxType'].value
      this.exportOffer.kilosPerBox = this.formGroup.controls['kilosPerBox'].value
      this.exportOffer.netKilos = this.formGroup.controls['netKilos'].value
      this.exportOffer.grossKilos = this.formGroup.controls['grossKilos'].value
      this.exportOffer.caseQuantityPerPallet = this.formGroup.controls['caseQuantityPerPallet'].value
      this.exportOffer.isQualityControl = this.formGroup.controls['isQualityControl'].value
      this.exportOffer.shelfLife = this.formGroup.controls['shelfLife'].value
      this.exportOffer.speciesSize = this.formGroup.controls['speciesSize'].value
      this.exportOffer.listPrice = this.formGroup.controls['listPrice'].value
      this.exportOffer.qualityControlDate = this.formGroup.controls['qualityControlDate'].value
      this.exportOffer.basePallets = this.formGroup.controls['basePallets'].value
      this.exportOffer.marks = this.formGroup.controls['marks'].value
      this.exportOffer.typeSale = this.formGroup.controls['incoterm'].value
      this.exportOffer.marketCountryFirstId = this.formGroup.controls['marketCountry1Id'].value
      this.exportOffer.marketCountrySecondId = this.formGroup.controls['marketCountry2Id'].value
      this.exportOffer.marketCountryThirdId = this.formGroup.controls['marketCountry3Id'].value
      this.exportOffer.sizeMillimeters = this.formGroup.controls['sizeMillimeters'].value
      this.exportOffer.category = this.formGroup.controls['category'].value
      this.exportOffer.caseSize = this.formGroup.controls['caseSize'].value
      //Set the removed pictures
      this.exportOffer.pictures = []
      if(this.pictures) {
        this.pictures.forEach(p => {
          if (p.picture.isNew)
            this.exportOffer?.pictures.push(p.file)
        })
      }
      this.exportOffer.removedPictures = this.removedPictures

      //Set the selected certificates
      this.exportOffer.certificates = this.selectedCertifications

      //Filter and set the removed product certificates
      let removedCerts: ProductCertificateType[] = []
      if(this.selectedCertifications && this.exportOffer.productCertificates) {
        this.exportOffer.productCertificates.forEach(c => {
          const keep = this.selectedCertifications.find(s => s.id === c.id)
          if(!keep) {
            removedCerts.push(c)
          }
        })
      }
      this.exportOffer.removedCertificates = removedCerts

      this._exportOfferService.update(this.exportOffer).subscribe({
        next: (v) => {
          this.exportOffer = v
          //this.loadForm()
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: this.translate.instant('MessageService.ExportOffer.EditSuccess') })
          this.submit = false
          this.loading = false
        },
        error: (e) => {
          this.loading = false
          console.log(e)
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOffer.EditError') });
        },
        complete: () => console.info('Export Offer update successfully')
      })

    }
    else {
      this._messageService.add({ severity: 'error',
        summary: this.translate.instant('MessageService.Summary.FailedAction'),
        detail: this.translate.instant('MessageService.FormMessages.FormInvalid') });
      this.loading = false
    }
  }

  getErrorMessage(formGroup: FormGroup, fieldName: string): string {
    return ErrorMessageUtil.getErrorMessage(formGroup, this.translate, fieldName)
  }

  openProductCertificateCreateDialog(formDirective: FormGroupDirective, fileUploader: FileUpload) {
    this.selectedProductCertificate = undefined
    fileUploader.clear()
    formDirective.resetForm()
    this.productCertificateFormGroup.controls['name'].setValue('')
    this.productCertificateFormGroup.controls['productCertificateTypeId'].setValue('')
    this.productCertificateFormGroup.controls['documentIssueDate'].setValue('')
    this.productCertificateFormGroup.controls['expirationDate'].setValue('')
    this.productCertificatePdfFile = null
    this.submitProductCertificate = false
    this.productCertificateDialogVisible = true
  }

  selectedProductCertificate: ProductCertificate | undefined
  openProductCertificateEditDialog(certificate: ProductCertificate, fileUploader: FileUpload) {
    this.selectedProductCertificate = certificate
    this.productCertificateFormGroup.controls['name'].setValue(this.selectedProductCertificate.name)
    this.productCertificateFormGroup.controls['productCertificateTypeId'].setValue(this.selectedProductCertificate.productCertificateTypeId)
    this.productCertificateFormGroup.controls['documentIssueDate'].setValue(this.selectedProductCertificate.documentIssueDate ? new Date(this.selectedProductCertificate.documentIssueDate) : null)
    this.productCertificateFormGroup.controls['expirationDate'].setValue(this.selectedProductCertificate.expirationDate ? new Date(this.selectedProductCertificate.expirationDate) : null)

    if(this.selectedProductCertificate.pdfUrl) {
      let uri = this.selectedProductCertificate.pdfUrl.trim().replace(" ", "%20")
      uri = environment.apiUrl + uri
      fetch(uri).then(r => r.blob()).then(blob => {
        if(blob && this.selectedProductCertificate) {
          let filename = uri.substring(uri.lastIndexOf('/')+1)
          filename = filename.replace("%20", " ")
          let pdf = new File([blob], filename)
          fileUploader.clear()
          fileUploader.files = [pdf]
          this.productCertificatePdfFile = pdf
        }
      })
    }

    this.productCertificateDialogVisible = true
  }

  getPdfUrl(certificate: ProductCertificate): string {
    if(certificate.pdfUrl) {
      let uri = certificate.pdfUrl.trim().replace(" ", "%20")
      return environment.apiUrl + uri
    }
    return ''
  }

  onSelectProductCertificateFile(event: any) {
    this.productCertificatePdfFile = event.files[0]
  }

  onClearProductCertificateFile(event: any) {
    this.productCertificatePdfFile = null
  }

  saveProductCertificate() {
    this.submitProductCertificate = true
    if(this.productCertificateFormGroup.valid) {
      this.loading = true
      if(this.selectedProductCertificate) {
        this.selectedProductCertificate.name = this.productCertificateFormGroup.controls['name'].value
        this.selectedProductCertificate.productCertificateTypeId = this.productCertificateFormGroup.controls['productCertificateTypeId'].value
        this.selectedProductCertificate.documentIssueDate = this.productCertificateFormGroup.controls['documentIssueDate'].value
        this.selectedProductCertificate.expirationDate = this.productCertificateFormGroup.controls['expirationDate'].value
        this.selectedProductCertificate.pdf = this.productCertificatePdfFile ? this.productCertificatePdfFile : this.selectedProductCertificate.pdf

        this._productCertificateService.update(this.selectedProductCertificate).subscribe({
          next: (v) => {
            this.selectedProductCertificate = v
            this.loadProductCertificates()
            this._messageService.add({ severity: 'success',
              summary: this.translate.instant('MessageService.Summary.SuccessAction'),
              detail: this.translate.instant('MessageService.ProductCertificate.EditSuccess') })
            this.loading = false
            this.submitProductCertificate = false
            this.productCertificateDialogVisible = false
          },
          error: (e) => {
            this.loading = false
            this.submitProductCertificate = false
            console.log(e)
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.ProductCertificate.EditError') });
          },
          complete: () =>  {
            this.loading = false
            this.submitProductCertificate = false
            console.info('Product Certificate update successfully') }
        })
      }
      else {
        let newCertificate: NewProductCertificate = {
          name: this.productCertificateFormGroup.controls['name'].value,
          productCertificateTypeId: this.productCertificateFormGroup.controls['productCertificateTypeId'].value,
          documentIssueDate: this.productCertificateFormGroup.controls['documentIssueDate'].value,
          expirationDate: this.productCertificateFormGroup.controls['expirationDate'].value,
          pdf: this.productCertificatePdfFile,
          exportOfferId: this.exportOfferId
        }

        this._productCertificateService.create(newCertificate).subscribe({
          next: (v) => {
            this.selectedProductCertificate = v
            this.loadProductCertificates()
            this._messageService.add({ severity: 'success',
              summary: this.translate.instant('MessageService.Summary.SuccessAction'),
              detail: this.translate.instant('MessageService.ProductCertificate.CreateSuccess') })
            this.loading = false
            this.submitProductCertificate = false
            this.productCertificateDialogVisible = false
          },
          error: (e) => {
            this.loading = false
            this.submitProductCertificate = false
            console.log(e)
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.ProductCertificate.CreateError') });
          },
          complete: () =>  {
            this.loading = false
            this.submitProductCertificate = false
            console.info('Product Certificate create successfully')
          }
        })
      }
    }
    else {
      this.loading = false
    }
  }

  onDeleteProductCertificate(certificate: ProductCertificate) {
    this._confirmationService.confirm({
      message: this.translate.instant('MessageService.ProductCertificate.ConfirmDeleteDialogMessage'),
      header: this.translate.instant('MessageService.ProductCertificate.ConfirmDeleteDialogHeader'),
      icon: 'pi pi-info-circle',
      accept: () => {
        this._productCertificateService.delete(certificate.id).subscribe({
          next: (v) => {
            this.selectedProductCertificate = undefined
            this.loadProductCertificates()
            this._messageService.add({ severity: 'info',
              summary: this.translate.instant('MessageService.Summary.SuccessAction'),
              detail: this.translate.instant('MessageService.ProductCertificate.DeleteSuccess') })

          },
          error: (e) => {
            console.log(e)
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.ProductCertificate.DeleteError') });
          },
          complete: () => console.info('Product Certificate delete successfully')
        })
      },
      reject: (type: any) => {
          switch (type) {
              case ConfirmEventType.REJECT:
                //this._messageService.add({ severity: 'error', summary: 'Rejected', detail: 'Se canceló la eliminación' })
                break;
              case ConfirmEventType.CANCEL:
                //this._messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Se canceló la eliminación' })
                break;
          }
      }
    })
  }

  onSelectDocumentFile(event: any) {
    this.documentPdfFile = event.files[0]
  }

  onClearDocumentFile(event: any) {
    this.documentPdfFile = null
  }

  getDocumentPdfUrl(document: ExportOfferDocument): string {
    if(document.pdfUrl) {
      let uri = document.pdfUrl.trim().replace(" ", "%20")
      return environment.apiUrl + uri
    }
    return ''
  }

  selectedDocument: ExportOfferDocument | undefined
  openDocumentCreateDialog(formDirective: FormGroupDirective, fileUploader: FileUpload) {
    fileUploader.clear()
    formDirective.resetForm()
    this.documentFormGroup.controls['name'].setValue('')
    this.documentPdfFile = null
    this.selectedDocument = undefined
    this.submitDocument = false
    this.documentDialogVisible = true
  }

  openDocumentEditDialog(document: ExportOfferDocument, fileUploader: FileUpload) {
    this.selectedDocument = document
    this.documentFormGroup.controls['name'].setValue(this.selectedDocument.name)
    if(this.selectedDocument.pdfUrl) {
      let uri = this.selectedDocument.pdfUrl.trim().replace(" ", "%20")
      uri = environment.apiUrl + uri
      fetch(uri).then(r => r.blob()).then(blob => {
        if(blob && this.selectedDocument) {
          let filename = uri.substring(uri.lastIndexOf('/')+1)
          filename = filename.replace("%20", " ")
          let pdf = new File([blob], filename)
          fileUploader.clear()
          fileUploader.files = [pdf]
          this.documentPdfFile = pdf
        }
      })
    }
    this.documentDialogVisible = true
  }

  onDeleteDocument(document: ExportOfferDocument) {
    this._confirmationService.confirm({
      message: this.translate.instant('MessageService.ExportOfferDocument.ConfirmDeleteDialogMessage'),
      header: this.translate.instant('MessageService.ExportOfferDocument.ConfirmDeleteDialogHeader'),
      icon: 'pi pi-info-circle',
      accept: () => {
        this._exportOfferDocumentService.delete(document.id).subscribe({
          next: (v) => {
            this.selectedDocument = undefined
            this.loadDocuments()
            this._messageService.add({ severity: 'info',
              summary: this.translate.instant('MessageService.Summary.SuccessAction'),
              detail: this.translate.instant('MessageService.ExportOfferDocument.DeleteSuccess') })

          },
          error: (e) => {
            console.log(e)
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.ExportOfferDocument.DeleteError') });
          },
          complete: () => console.info('Export Offer Document delete successfully')
        })
      },
      reject: (type: any) => {
          switch (type) {
              case ConfirmEventType.REJECT:
                //this._messageService.add({ severity: 'error', summary: 'Rejected', detail: 'Se canceló la eliminación' })
                break;
              case ConfirmEventType.CANCEL:
                //this._messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Se canceló la eliminación' })
                break;
          }
      }
    })
  }

  saveDocument() {
    this.submitDocument = true
    if(this.documentFormGroup.valid) {
      this.loading = true
      if(this.selectedDocument) {
        this.selectedDocument.name = this.documentFormGroup.controls['name'].value
        this.selectedDocument.pdf = this.documentPdfFile ? this.documentPdfFile : this.selectedDocument.pdf

        this._exportOfferDocumentService.update(this.selectedDocument).subscribe({
          next: (v) => {
            this.selectedDocument = v
            this.loadDocuments()
            this._messageService.add({ severity: 'success',
              summary: this.translate.instant('MessageService.Summary.SuccessAction'),
              detail: this.translate.instant('MessageService.ExportOfferDocument.EditSuccess') })
            this.loading = false
            this.submitDocument = false
            this.documentDialogVisible = false
          },
          error: (e) => {
            this.loading = false
            this.submitDocument = false
            console.log(e)
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.ExportOfferDocument.EditError') });
          },
          complete: () =>  {
            this.loading = false
            this.submitDocument = false
            console.info('Document update successfully') }
        })
      }
      else {
        let newDocument: NewExportOfferDocument = {
          name: this.documentFormGroup.controls['name'].value,
          pdf: this.documentPdfFile,
          exportOfferId: this.exportOfferId
        }

        this._exportOfferDocumentService.create(newDocument).subscribe({
          next: (v) => {
            this.selectedDocument = v
            this.loadDocuments()
            this._messageService.add({ severity: 'success',
              summary: this.translate.instant('MessageService.Summary.SuccessAction'),
              detail: this.translate.instant('MessageService.ExportOfferDocument.CreateSuccess') })
            this.loading = false
            this.submitDocument = false
            this.documentDialogVisible = false
          },
          error: (e) => {
            this.loading = false
            this.submitDocument = false
            console.log(e)
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.ExportOfferDocument.CreateError') });
          },
          complete: () =>  {
            this.loading = false
            this.submitDocument = false
            console.info('Document create successfully')
          }
        })
      }
    }
    else {
      this.loading = false
    }
  }

  getProductCertificateDocument(productCertificateId: number) {
    this._documentService.getProductCertificateDocument2(productCertificateId).subscribe((resultBlob: any) => {
      var downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }

  getExportOfferDocument(exportOfferDocumentId: number) {
    this._documentService.getExportOfferDocument2(exportOfferDocumentId).subscribe((resultBlob: any) => {
      var downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }
}
