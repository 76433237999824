<app-user-dashboard [userMenuActiveItemIndex]="0" [activeItem]="'forwarderReviews'">
  <p-tabMenu [model]="tabMenuItems" [scrollable]="true" [activeItem]="activeItem" [style]="{padding: '15px'}"></p-tabMenu>
  <p-card>
    <h1>{{ 'UserDashboardComponent.UserDashboardMenu.OriginForwarderReviewsLabel' | translate }}</h1>
    <p>{{ 'ForwarderPage.ForwarderReviewsPending.Title' | translate }}</p>
    <p-table [value]="reviews"
      [paginator]="true"
      [rows]="10"
      [showCurrentPageReport]="true"
      [rowsPerPageOptions]="[10, 25, 50]"
      [loading]="loading"
      responsiveLayout="stack"
      [breakpoint]="'600px'">
      <ng-template pTemplate="header">
        <tr>
          <th>
            <div class="flex justify-content-center align-items-center"></div>
          </th>
          <th>
            <div class="flex justify-content-center align-items-center">{{ 'ForwarderPage.ForwarderReviewsPending.ProductTypeHeader' | translate }}</div>
          </th>
          <th>
            <div class="flex justify-content-center align-items-center">{{ 'ForwarderPage.ForwarderReviewsPending.SpeciesVarietyHeader' | translate }}</div>
          </th>
          <th>
            <div class="flex justify-content-center align-items-center">{{ 'ForwarderPage.ForwarderReviewsPending.ColdStorageHeader' | translate }}</div>
          </th>
          <th>
            <div class="flex justify-content-center align-items-center">{{ 'ForwarderPage.ForwarderReviewsPending.OriginPort' | translate }}</div>
          </th>
          <th>
            <div class="flex justify-content-center align-items-center">{{ 'ForwarderPage.ForwarderReviewsPending.ForwardReviewDate' | translate }}</div>
          </th>
          <th>
            <div class="flex justify-content-center align-items-center">{{ 'ForwarderPage.ForwarderReviewsPending.ForwardReviewImporter' | translate }}</div>
          </th>
          <th>
            <div class="flex justify-content-center align-items-center">{{ 'ForwarderPage.ForwarderReviewsPending.ForwardReviewExporter' | translate }}</div>
          </th>
          <th *ngIf="profile?.forwardAdmin">
            <div class="flex justify-content-center align-items-center">{{ 'ForwarderPage.ForwarderReviewsPending.ForwarderAssigned' | translate }}</div>
          </th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-review>
        <tr>
          <td>
            <img [src]="review.varietyUrlIcon" style="border-radius: 7px; width: 100%; max-width: 100px; height: auto;" alt=""/>
          </td>
          <td>
            <div class="flex justify-content-center align-items-center">{{review.productType}}</div>
          </td>
          <td>
            <div class="flex justify-content-center align-items-center">
              <div>
                <p>{{ review | speciesName }} / {{ review.varietyName }}</p>
              </div>
            </div>
          </td>
          <td>
            <div class="flex justify-content-center align-items-center">
              {{ review.storageLocation }}
            </div>
          </td>
          <td>
            <div class="flex justify-content-center align-items-center">{{ review.departurePort ? review.departurePort : '(Post loading information)' }}</div>
          </td>          
          <td>
            <div class="flex justify-content-center align-items-center">{{ review.created | date:'dd-MM-yyyy HH:mm' }}</div>
          </td>
          <td>
            <div class="flex justify-content-center align-items-center">{{ review.importer ? review.importer.contactName : '(Post loading information)' }}</div>
          </td>
          <td>
            <div class="flex justify-content-center align-items-center">{{ review.exporter ? review.exporter.companyName : '(Post loading information)' }}</div>
          </td>
          <td *ngIf="profile?.forwardAdmin">
            <div class="flex justify-content-center align-items-center">{{ review.profileAssigned ? review.profileAssigned.contactName : '(Post loading information)' }}</div>
          </td>
          <td>
            <div class="flex flex-column align-items-center justify-content-center gap-4">
              <p-button label="{{ 'ForwarderPage.ForwarderReviewsPending.ButtonAssign' | translate }}" icon="pi pi-user" *ngIf="profile?.forwardAdmin" styleClass="p-button-warning" (onClick)="openDialog(review)"></p-button>
              <p-button label="{{ 'ForwarderPage.ForwarderReviewsPending.ButtonPreBL' | translate }}" icon="pi pi-table" (onClick)="goToDelivery(review)"></p-button>
              <p-button label="{{ 'ForwarderPage.ForwarderReviewsPending.ButtonReview' | translate }}" icon="pi pi-check-square" (onClick)="goToReviewDocuments(review)"></p-button>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </p-card>
</app-user-dashboard>

<p-dialog [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleAssignDialog" [style]="{width: '30%'}">
  <ng-template pTemplate="header"> 
    <h2>{{ 'ForwarderPage.ForwarderReviewsPending.Modal.Title' | translate }}</h2>
  </ng-template> 
  <ng-template pTemplate="body"> 
    <div class="p-fluid formgrid grid">
      <div class="field col-12 xl:col-12 lg:col-12" style="margin-bottom: 40px;">
        <p-dropdown inputId="profileId" [(ngModel)]="selectedForwarderOrder" [options]="forwarders" optionValue="profileId" optionLabel="profile.contactName" placeholder="{{ 'ForwarderPage.ForwarderReviewsPending.Modal.SelectForwarderPlaceholder' | translate }}" [style]="{'minWidth':'100%'}" class="p-inputtext-sm" appendTo="body"></p-dropdown>
      </div>
    </div>
  </ng-template> 
  <ng-template pTemplate="footer">
    <p-button [loading]="loading" label="{{ 'ForwarderPage.ForwarderReviewsPending.Modal.ButtonAssign' | translate }}" (onClick)="assignUser()"/>
  </ng-template>
</p-dialog>

<p-toast></p-toast>
