import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { ExportOfferGateway } from 'src/app/application/gateway/ExportOfferGateway';
import { ImportOrderGateway } from 'src/app/application/gateway/ImportOrderGateway';
import { PalletGateway } from 'src/app/application/gateway/PalletGateway';
import { SuggestionGateway } from 'src/app/application/gateway/SuggestionGateway';
import { ExportOffer, ExportOfferInformation } from 'src/app/domain/models/ExportOffer';
import { CreateSuggestion } from 'src/app/domain/models/ExportOfferSuggestion';
import { GenericOrderDetail, ImportOrderDetail, ImportOrderDetailInformation } from 'src/app/domain/models/ImportOrder';
import { Packaging } from 'src/app/domain/models/Packaging';
import { ProductCertificateType } from 'src/app/domain/models/ProductCertificateType';
import { Routes } from 'src/app/infrastructure/routes';
import { ExportOfferUtils } from 'src/app/ui/util/export-offer-utils';

@Component({
  selector: 'app-admin-complete-order',
  templateUrl: './admin-complete-order.component.html',
  styleUrls: ['./admin-complete-order.component.css'],
  providers: [MessageService]
})
export class AdminCompleteOrderComponent implements OnInit {

  breadcrumbMenuItems: MenuItem[] = []
  breadcrumbHome: MenuItem

  orderDetailId: number | undefined
  orderDetail: GenericOrderDetail | undefined
  orderDetailInformation: ImportOrderDetailInformation | undefined

  exportOfferSuggestions: ExportOfferInformation[] = []
  selectedOfferSuggestions: ExportOfferInformation[] = []
  selected: ExportOffer | undefined
  selectedProductCertificateTypes: ProductCertificateType[] = []
  selectedPackagingTypes: Packaging[] = []

  visibleOfferDetailDialog: boolean = false
  loading: boolean = false
  success: boolean = false

  constructor(private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _messageService: MessageService,
    private _importOrderService: ImportOrderGateway,
    private _palletService: PalletGateway,
    private _exportOfferService: ExportOfferGateway,
    private _suggestionService: SuggestionGateway) {

    this._activatedRoute.params.subscribe(params => {
      this.orderDetailId = params['orderDetailId']
    })

    this.breadcrumbHome = {label: 'Ordenes de compra', routerLink: ['/' + Routes.adminManageOrderPage]}
  }

  ngOnInit(): void {
    if(this.orderDetailId) {
      this.loading = true

      this._palletService.getByImportOrderDetailId(this.orderDetailId).subscribe({
        next: (v) => {
          this.orderDetailInformation = v
          this.orderDetail = this.orderDetailInformation.importOrderDetail
          this.breadcrumbMenuItems = [{label: 'Detalle de orden', routerLink: ['/' + Routes.adminOrderDetail, this.orderDetail.importOrderId]}]
          this.getOfferSuggestions()
        },
        error: (e) => {
          console.log(e)
          this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudo recuperar el detalle de orden.' });
        },
        complete: () =>  {
          this.loading = false
          console.info('Import Order Detail query successfully')
        }
      })
    }
  }

  getOfferSuggestions() {
    if(this.orderDetail) {
      this._exportOfferService.getSuggestionList(this.orderDetail.id, this.orderDetail.exportOfferCountryId, this.orderDetail.varietyId).subscribe({
        next: (v) => {
          this.exportOfferSuggestions = v
          console.log(v)
        },
        error: (e) => {
          console.log(e)
          this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudieron recuperar las ofertas de exportación.' });
        },
        complete: () =>  {
          this.loading = false
          console.info('Export Offer Suggestions query successfully')
        }
      })
    }
  }

  getTotalPrice(exportOffer: ExportOffer, requiredPallets: number): number {
    return ExportOfferUtils.getTotalPrice(exportOffer, requiredPallets)
  }

  get requiredPallets(): number {
    if(this.orderDetailInformation && this.orderDetail) {
      const approvedPallets = this.orderDetailInformation.pallets.filter(x => x.fridgeStorageVerified)
      return this.orderDetail.palletsQuantity - approvedPallets.length
    }
    return 0
  }

  save() {
    if(this.orderDetailInformation) {

      if(this.selectedOfferSuggestions.length < 1) {
        this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'Seleccione primero las sugerencias de ofertas de exportación.' })
        return
      }

      const offerSuggestionIds: number[] = []
      this.selectedOfferSuggestions.forEach(x => {
        offerSuggestionIds.push(x.exportOffer.id)
      })

      const request: CreateSuggestion = {
        exportOfferSuggestionIds: offerSuggestionIds,
        importOrderDetailId: this.orderDetailInformation.importOrderDetail.id
      }

      this.loading = true
      this._suggestionService.create(request).subscribe({
        next: (v) => {
          this.success = true
          this._messageService.add({ severity: 'success', summary: 'Acción exitosa', detail: 'Las sugerencias se crearon con éxito.' })
        },
        error: (e) => {
          console.log(e)
          if(e.status === 400){
            this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: e.error });
          }else{
            this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudieron crear las sugerencias.' });
          }

          this.loading = false

        },
        complete: () =>  {
          this.loading = false
          console.info('Export Offer Suggestions created successfully')
        }
      })
    }
  }

  openOfferDetailDialog(exportOfferInformation: ExportOfferInformation) {
    this.selected = exportOfferInformation.exportOffer
    if(this.selected.productCertificateTypes)
      this.selectedProductCertificateTypes = this.selected.productCertificateTypes

    if(this.selected.packagingTypes)
      this.selectedPackagingTypes = this.selected.packagingTypes

    this.visibleOfferDetailDialog = true
  }

  onAccept() {
    this._router.navigate(['/' + Routes.adminManageOrderPage])
  }
}
