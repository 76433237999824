import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Delivery } from 'src/app/domain/models/Delivery';
import { BasicSelect } from 'src/app/ui/util/select-utils';
import { transportTypes, TransportTypeUtils } from 'src/app/ui/util/transportType.Utils';

@Component({
  selector: 'app-import-order-loading-data',
  templateUrl: './import-order-loading-data.component.html',
  styleUrls: ['./import-order-loading-data.component.css']
})
export class ImportOrderLoadingDataComponent {

  @Input()
  delivery: Delivery | undefined

  DefaultBookingText: string = "(As Booking info)"
  DefaultLoadingInfoText: string = "(Post Loading information)"

  transportTypes: BasicSelect[] = transportTypes 

  constructor(
    public translateService: TranslateService
  ){

  }

  getTransportType(transportType: number): string{
    return  TransportTypeUtils.getTransportName(this.translateService.currentLang, transportType);
  }

}
