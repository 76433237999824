import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { FridgeStorageGateway } from 'src/app/application/gateway/FridgeStorageGateway';
import { FridgeStorage } from 'src/app/domain/models/FridgeStorage';
import { BasicUser } from 'src/app/domain/models/User';
import { Routes } from 'src/app/infrastructure/routes';

@Component({
  selector: 'app-user-fridgestorages',
  templateUrl: './user-fridgestorages.component.html',
  styleUrls: ['./user-fridgestorages.component.css'],
  providers: [ConfirmationService, MessageService]
})
export class UserFridgestoragesComponent implements OnInit {

  fridgestorages: FridgeStorage[] = []
  selected: FridgeStorage | null = null
  loading: boolean = true

  profile: BasicUser | undefined

  constructor(private _router: Router,
    private _messageService: MessageService,
    private _confirmationService: ConfirmationService,
    private _authService: AuthGateway,
    private _fridgeStorageService: FridgeStorageGateway,
    public translate: TranslateService) {

  }

  ngOnInit(): void {
    this.profile = this._authService.getUser()
    this.getAll()
  }

  getAll() {
    this.loading = true
    this._fridgeStorageService.getAllAvailable().subscribe({
      next: (v) => {
        this.fridgestorages = v
        this.loading = false
      },
      error: (e) => {
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.FridgeStorage.ManyRetrieveError') });
        this.loading = false
        console.log(e)
      },
      complete: () => console.info('FridgeStorages query successfully')
    })
  }

  goToCreateForm() {
    this._router.navigate([Routes.fridgestorageForm, 0])
  }

  goToEditForm(id: number) {
      this._router.navigate([Routes.fridgestorageForm, id])
  }

  onDelete(id: number) {
    this._confirmationService.confirm({
      message: this.translate.instant('MessageService.FridgeStorage.ConfirmDeleteDialogMessage'),
      header: this.translate.instant('MessageService.FridgeStorage.ConfirmDeleteDialogHeader'),
      icon: 'pi pi-info-circle',
      accept: () => {
        this._fridgeStorageService.delete(id).subscribe({
          next: (v) => {
            this.selected = null
            this._messageService.add({ severity: 'info',
              summary: this.translate.instant('MessageService.Summary.SuccessAction'),
              detail: this.translate.instant('MessageService.FridgeStorage.DeleteSuccess') })
            this.getAll()
          },
          error: (e) => {
            console.log(e)
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.FridgeStorage.DeleteError') });
          },
          complete: () => console.info('FridgeStorage delete successfully')
        })
      },
      reject: (type: any) => {
          switch (type) {
              case ConfirmEventType.REJECT:
                  //this._messageService.add({ severity: 'error', summary: 'Rejected', detail: 'Se canceló la eliminación' })
                  break;
              case ConfirmEventType.CANCEL:
                  //this._messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Se canceló la eliminación' })
                  break;
          }
      }
    })
  }

  getStorageType(storageType: number): string {
    if(this.profile) {
      switch(this.profile.lang) {
        case 'en':
          switch(storageType) {
            case 1:
              return 'Cold storage'
            case 2:
              return 'Processing plant'
          }
          break
        case 'es':
          switch(storageType) {
            case 1:
              return 'Frigorífico'
            case 2:
              return 'Planta de procesamiento'
          }
      }
    }
    return ''
  }
}
