import { AuthGateway } from "../gateway/AuthGateway";
import {Injectable} from "@angular/core";
import { AuthUser } from "src/app/domain/models/AuthUser";
import { HttpHeaders } from "@angular/common/http";
import { BasicUser } from "src/app/domain/models/User";

@Injectable({providedIn: 'root'})
export class AuthApiService extends AuthGateway {
  private static accessToken: string | null

  constructor() {
    super();
    AuthApiService.accessToken = localStorage.getItem('_token_auth')
  }

  private static parseJwt(token: string) {
    let base64Url = token.split('.')[1]
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    let jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join('')
    )

    return JSON.parse(jsonPayload)
  }

  authenticate(token: string): void {
    localStorage.setItem('_token_auth', token)
    AuthApiService.accessToken = token
  }

  getToken(): string {
    return AuthApiService.accessToken ? AuthApiService.accessToken : "";
  }

  getUser(): BasicUser | undefined {
    const localStorageValue = localStorage.getItem('_auth_user')
    if(localStorageValue)
      return JSON.parse(localStorageValue)

    return undefined
  }

  private getJwtObject(): any {
    if (!AuthApiService.accessToken) return null
    return AuthApiService.parseJwt(AuthApiService.accessToken)
  }

  getCompleteName(): string {
    const jwt = this.getJwtObject()
    return jwt ? jwt.complete_name : null
  }

  getUsername(): string {
    const jwt = this.getJwtObject()
    return jwt ? jwt.unique_name : null
  }

  getRole(): string {
    const jwt = this.getJwtObject()
    return jwt ? jwt.role : null
  }

  isAuthenticated(): boolean {
    if (!AuthApiService.accessToken) return false
    let jwt = AuthApiService.parseJwt(AuthApiService.accessToken)
    return jwt.exp >= Date.now() / 1000
  }

  logout(): void {
    localStorage.clear()
    sessionStorage.clear()
    AuthApiService.accessToken = ''
  }

  getHttpHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.getToken()}`
    })
  }
}
