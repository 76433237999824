<app-user-dashboard [userMenuActiveItemIndex]="0" [activeItem]="'customAgentReviews'">
    <p-tabMenu [model]="tabMenuItems" [scrollable]="true" [activeItem]="activeItem" [style]="{padding: '15px'}"></p-tabMenu>
    <p-card>
      <h1>{{ 'UserDashboardComponent.UserDashboardMenu.OriginCustomAgentReviewedsLabel' | translate }}</h1>
      <p>{{ 'CustomAgentPage.CustomAgentUserOriginReviewd.Title' | translate }}</p>
      <p-table [value]="reviews"
        [paginator]="true"
        [rows]="10"
        [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[10, 25, 50]"
        [loading]="loading"
        responsiveLayout="stack"
        [breakpoint]="'600px'">
        <ng-template pTemplate="header">
          <tr>
            <th>
              <div class="flex justify-content-center align-items-center"></div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">{{ 'CustomAgentPage.CustomAgentUserOriginReviewd.ProductTypeLabel' | translate }}</div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">{{ 'CustomAgentPage.CustomAgentUserOriginReviewd.SpeciesVarietyLabel' | translate }}</div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">{{ 'CustomAgentPage.CustomAgentUserOriginReviewd.ColdStorageLabel' | translate }}</div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">{{ 'CustomAgentPage.CustomAgentUserOriginReviewd.OriginPortLabel' | translate }}</div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">{{ 'CustomAgentPage.CustomAgentUserOriginReviewd.ForwardReviewDateLabel' | translate }}</div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">{{ 'CustomAgentPage.CustomAgentUserOriginReviewd.ForwardReviewImporterLabel' | translate }}</div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">{{ 'CustomAgentPage.CustomAgentUserOriginReviewd.ForwardReviewExporterLabel' | translate }}</div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">{{ 'CustomAgentPage.CustomAgentUserOriginReviewd.CustomAgentAssignedLabel' | translate }}</div>
            </th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-review>
          <tr>
            <td>
              <img [src]="review.varietyUrlIcon" style="border-radius: 7px; width: 100%; max-width: 100px; height: auto;" alt=""/>
            </td>
            <td>
              <div class="flex justify-content-center align-items-center">{{review.productType}}</div>
            </td>
            <td>
              <div class="flex justify-content-center align-items-center">
                <div>
                  <p>{{ review | speciesName }} / {{ review.varietyName }}</p>
                </div>
              </div>
            </td>
            <td>
              <div class="flex justify-content-center align-items-center">
                {{ review.storageLocation }}
              </div>
            </td>
            <td>
              <div class="flex justify-content-center align-items-center">{{ review.departurePort ? review.departurePort : '(Post loading information)' }}</div>
            </td>
            <td>
              <div class="flex justify-content-center align-items-center">{{ review.created | date:'dd-MM-yyyy HH:mm' }}</div>
            </td>
            <td>
              <div class="flex justify-content-center align-items-center">{{ review.importer ? review.importer.contactName : '(Post loading information)' }}</div>
            </td>
            <td>
              <div class="flex justify-content-center align-items-center">{{ review.exporter ? review.exporter.companyName : '(Post loading information)' }}</div>
            </td>
            <td>
              <div class="flex justify-content-center align-items-center">{{ review.profileAssigned ? review.profileAssigned.contactName : '(Post loading information)' }}</div>
            </td>
            <td>
              <div class="flex flex-column align-items-center justify-content-center gap-4">
                <p-button label="{{ 'CustomAgentPage.CustomAgentReviewsPending.ButtonAssign' | translate }}" icon="pi pi-user" *ngIf="profile?.customAgentAdmin" styleClass="p-button-warning" (onClick)="openDialog(review)"></p-button>
                <p-button label="{{ 'CustomAgentPage.CustomAgentUserOriginReviewd.ButtonReview' | translate }}" icon="pi pi-check-square" [badge]="messagePending(review)" badgeClass="p-badge-danger" (onClick)="goToReviewDocuments(review)"></p-button>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </p-card>
  </app-user-dashboard>
  
  <p-dialog [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleAssignDialog" [style]="{width: '30%'}">
    <ng-template pTemplate="header"> 
      <h2>{{ 'CustomAgentPage.CustomAgentReviewsPending.Modal.Title' | translate }}</h2>
    </ng-template> 
    <ng-template pTemplate="body"> 
      <div class="p-fluid formgrid grid">
        <div class="field col-12 xl:col-12 lg:col-12" style="margin-bottom: 40px;">
          <p-dropdown inputId="profileId" [(ngModel)]="selectedCustomAgentOrder" [options]="customAgents" optionValue="profileId" optionLabel="profile.contactName" placeholder="{{ 'CustomAgentPage.CustomAgentReviewsPending.Modal.SelectCustomAgentPlaceholder' | translate }}" [style]="{'minWidth':'100%'}" class="p-inputtext-sm" appendTo="body"></p-dropdown>
        </div>
      </div>
    </ng-template> 
    <ng-template pTemplate="footer">
      <p-button [loading]="loading" label="{{ 'CustomAgentPage.CustomAgentReviewsPending.Modal.ButtonAssign' | translate }}" (onClick)="assignUser()"/>
    </ng-template>
  </p-dialog>
  
  <p-toast></p-toast>
  