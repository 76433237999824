<p-card>
    <div class="grid" style="margin-left: 15px; margin-right: 15px;">
      <div class="col-12 md:col-3">
        <h1>{{'ProgramTab.ProgramRequestOrder' | translate}}</h1>
      </div>
      <div class="col-12 md:col-5 p-fluid flex align-items-center">
        <span class="p-input-icon-right">
          <input pInputText inputId="global_search" placeholder="Ingrese la información a consultar" class="p-inputtext-sm"/>
          <i class="pi pi-search"></i>
        </span>
      </div>
      <div class="col-12 md:col-4 flex justify-content-end align-items-center gap-4">
        <p-button label="{{'UserPages.UserClosedSale.InviteFruztyButton' | translate}}" icon="pi pi-user" styleClass="p-button-secondary" (onClick)="inviteEvent()"></p-button>
        <p-button label="{{'UserPages.UserClosedSale.NewRequirementButton' | translate}}" icon="pi pi-plus-circle" (onClick)="goToProgramRequestCreate()"></p-button>
      </div>
    </div>

    <p-table
      [value]="programRequestInfo"
      [rowsPerPageOptions]="[10, 25, 50]"
      [loading]="loading"
      responsiveLayout="stack"
      [breakpoint]="'600px'"
      [globalFilterFields]="[
      'id',
      'variety'
      ]"
    >
      <ng-template
      pTemplate="header"

      >
      <tr>
          <th>
            <div class="flex justify-content-center align-items-center">
              ID
              <p-columnFilter type="text" field="id" display="menu" [showAddButton]="false" [showMatchModes]="false" [showOperator]="false"></p-columnFilter>
            </div>
          </th>
          <th>
            <div class="flex justify-content-center align-items-center">
              {{'UserPages.UserImportRequest.ProgramRequestTable.SpecieHeader' | translate}}
            </div>
          </th>
          <th>
            <div class="flex justify-content-center align-items-center">
              {{'UserPages.UserImportRequest.ProgramRequestTable.VarietyHeader' | translate}}
                <p-columnFilter type="text" field="name" display="menu" [showAddButton]="false" [showMatchModes]="true" [showOperator]="false"></p-columnFilter>
            </div>
          </th>
          <th>
            <div class="flex justify-content-center align-items-center">
              {{'UserPages.UserImportRequest.ProgramRequestTable.WeekRangeHeader' | translate}}
            </div>
          </th>
          <th>
            <div class="flex justify-content-center align-items-center">
              {{'UserPages.UserImportRequest.ProgramRequestTable.TotalPalletsHeader' | translate}}
            </div>
          </th>
          <th>
            <div class="flex justify-content-center align-items-center">
              {{'UserPages.UserImportRequest.ProgramRequestTable.TotalContainerHeader' | translate}}
            </div>
          </th>
          <th>
            <div class="flex justify-content-center align-items-center">
              {{'UserPages.UserImportRequest.ProgramRequestTable.SellTypeText' | translate}}
            </div>
          </th>
          <th>
            <div class="flex justify-content-center align-items-center">
              {{'UserPages.UserImportRequest.ProgramRequestTable.DestinationCountryHeader' | translate}}
            </div>
          </th>
          <th>
            <div class="flex justify-content-center align-items-center">
              {{'UserPages.UserImportRequest.ProgramRequestTable.OriginCountryHeader' | translate}}
            </div>
          </th>
          <th>
            <div class="flex justify-content-center align-items-center">
              {{'UserPages.UserImportRequest.ProgramRequestTable.StatusHeader' | translate}}
            </div>
          </th>
          <th>
            <div class="flex justify-content-center align-items-center">
              {{'UserPages.UserImportRequest.ProgramRequestTable.ActionsHeader' | translate}}
            </div>
          </th>
      </tr>
      </ng-template>
      <ng-template pTemplate="body" let-info> 
        <tr>
          <td>
            <div class="flex justify-content-center align-items-center" style="font-weight: bold;">
              <p>{{info.programRequest.id}}</p>
            </div>
          </td>
          <td>
            <div class="flex justify-content-center align-items-center">
              <img [src]="info.programRequest.variety.urlIcon" alt="Image" height="70px">
            </div>
          </td>
          <td>
            <div class="flex justify-content-center align-items-center">
              <p>{{info.programRequest.variety.name}}</p>
            </div>
          </td>
          <td>
            <div  class="flex justify-content-center align-items-center">
                {{ info.programRequest.startWeek }} ({{info.programRequest.startWeekYear }}) -
                {{info.programRequest.endWeek}} ({{info.programRequest.endWeekYear }})
            </div>
          </td>
          <td>
            <div class="flex justify-content-center align-items-center">
              <p>{{getTotalPalletsImportRequest(info.programRequest)}}</p>
            </div>
          </td>
          <td>
            <div class="flex justify-content-center align-items-center">
              <p>{{getTotalContainersImportRequest(info.programRequest)}}</p>
            </div>
          </td>
          <td>
            <div class="flex justify-content-center align-items-center">
              <p>{{info.programRequest.typeSale}}</p>
            </div>
          </td>
          <td>
            <div class="flex justify-content-center align-items-center">
              <p>{{info.programRequest.countryTo | countryDescription}}</p>
            </div>
          </td>
          <td>
            <div class="flex justify-content-center align-items-center">
              <p>{{info.programRequest.country ? (info.programRequest.country | countryDescription) : allCountriesText}}</p>
            </div>
          </td>
          <td>
            <div class="col-12 flex justify-content-center">
              <p-tag *ngIf="info.programRequest.programRequestStatus == 0" [value]="getStatusValue(info.programRequest.programRequestStatus)" [style]="{ 'display': 'grid', 'width': '100%', 'min-width': '80px', 'background-color': '#F1D0D0', 'color': '#9E4343'}"></p-tag>
              <p-tag *ngIf="info.programRequest.programRequestStatus == 1" [value]="getStatusValue(info.programRequest.programRequestStatus)" [style]="{ 'display': 'grid', 'width': '100%', 'min-width': '80px', 'background-color': '#C3DBA2', 'color': '#4D6A34'}"></p-tag>
              <p-tag *ngIf="info.programRequest.programRequestStatus == 2" [value]="getStatusValue(info.programRequest.programRequestStatus)" [style]="{ 'display': 'grid', 'width': '100%', 'min-width': '80px', 'background-color': '#D7D7D7', 'color': '#6C7A8F'}"></p-tag>
            </div>
          </td>
          <td>
            <div class="flex justify-content-center align-items-center gap-2">
              <p-button *ngIf="info.programRequest.programRequestStatus == 0" icon="pi pi-pencil" pTooltip="{{'UserPages.UserImportRequest.ImportRequestTable.EditToolTipText' | translate}}" (click)="goToEditProgramRequest(info.programRequest.guid)"></p-button>
              <!-- <p-button icon="pi pi-book" pTooltip="{{'UserPages.UserImportRequest.ImportRequestTable.OffersReceivedToolTipText' | translate}}"></p-button> -->
              <p-button (click)="selectProgramRequestDetail(info)" icon="pi pi-search" pTooltip="{{'UserPages.UserImportRequest.ImportRequestTable.SeeDetail' | translate}}" ></p-button>
            </div>
        </td>
        </tr>

      </ng-template>
    </p-table>

  </p-card>

  <p-dialog header="{{'UserPages.UserImportRequest.ImportRequestTable.ImportRequestDialog.Header' | translate}}" [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleProgramRequestDetailDialog" [style]="{width: '80%'}">
    <!-- <div class="grid"> -->
      <app-program-request-detail [programRequestInformation]="selectedProgramRequest"></app-program-request-detail>
    <!-- </div> -->
    <ng-template pTemplate="footer">
      <p-button label="{{'UserPages.UserImportRequest.ImportRequestTable.ImportRequestDialog.CloseButton' | translate}}" (onClick)="visibleProgramRequestDetailDialog = false"></p-button>
    </ng-template>
  </p-dialog>
