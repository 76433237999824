import { Lang } from "./langs";
import { BasicSelect } from "./select-utils";



export const transportTypes:  BasicSelect[] = [

    {value: 0, nameEs: "Marítimo", nameEn: "Maritime"},
    {value: 1, nameEs: "Aéreo", nameEn: "Air" }
  ]

export class TransportTypeUtils {


    static getTransportName(lang: string, transportType: number): string {
      let name = ''

      let transport = transportTypes.find((x) => x.value == transportType)

      if(transport){
        switch(lang) {
            case Lang.ENGLISH:
              name = transport.nameEn ?  transport.nameEn : ''
              break
  
            case Lang.SPANISH:
              name = transport.nameEs ? transport.nameEs : ''
              break
  
            default:
              name = transport.nameEn ?  transport.nameEn : ''
        }
    }
        return name
    }
}