import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DashboardLayoutComponent } from "./dashboard-layout/dashboard-layout.component";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from "@angular/router";
import { ContentBodyComponent } from './content-body/content-body.component';
import { ContentHeaderComponent } from './content-header/content-header.component';
import { PanelMenuModule } from "primeng/panelmenu";
import { MenubarModule } from "primeng/menubar";
import { CardModule } from 'primeng/card';
import { PanelModule } from 'primeng/panel';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { MenuModule } from 'primeng/menu';
import { SidebarModule } from 'primeng/sidebar';
import { ButtonModule } from 'primeng/button';
import { TabMenuModule } from 'primeng/tabmenu';
import { HomeHeaderComponent } from './home-header/home-header.component';
import { DividerModule } from 'primeng/divider';
import { UserDashboardComponent } from '../components/user-dashboard/user-dashboard.component';
import { InputTextModule } from 'primeng/inputtext';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { ExportOfferDetailComponent } from './export-offer/export-offer-detail/export-offer-detail/export-offer-detail.component';
import { CarouselModule } from 'primeng/carousel';
import { TimerComponent } from './timer/timer.component';
import { WishListComponent } from './wish-list/wish-list.component';
import { CalendarModule } from 'primeng/calendar';
import { SwitchLangComponent } from './switch-lang/switch-lang.component';
import { DropdownModule } from 'primeng/dropdown';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { ImportRequestDetailComponent } from './import-request/import-request-detail/import-request-detail.component';
import { ExportOfferDetailDescriptionComponent } from './export-offer/export-offer-detail/export-offer-detail-description/export-offer-detail-description.component';
import { ExportOfferDetailCarouselComponent } from './export-offer/export-offer-detail/export-offer-detail-carousel/export-offer-detail-carousel.component';
import { ImportOrderDeliveryComponent } from './import-order/import-order-delivery/import-order-delivery.component';
import { ImportOrderOperationComponent } from './import-order/import-order-operation/import-order-operation.component';
import { TableModule } from "primeng/table";
import { UserImportOrderListComponent } from './import-order/user-import-order-list/user-import-order-list.component';
import { FrozenFruitExportOfferDetailComponent } from './frozen-fruit-export-offer/frozen-fruit-export-offer-detail/frozen-fruit-export-offer-detail.component';
import { FrozenFruitExportOfferDetailCarouselComponent } from './frozen-fruit-export-offer/frozen-fruit-export-offer-detail-carousel/frozen-fruit-export-offer-detail-carousel.component';
import { FrozenFruitExportOfferDetailDescriptionComponent } from './frozen-fruit-export-offer/frozen-fruit-export-offer-detail-description/frozen-fruit-export-offer-detail-description.component';
import { InputNumberModule } from "primeng/inputnumber";
import { ExportOfferCreateOptionComponent } from './export-offer/export-offer-create-option/export-offer-create-option.component';
import { DialogModule } from "primeng/dialog";
import { ExportOfferPictureUploaderComponent } from './export-offer/export-offer-picture-uploader/export-offer-picture-uploader.component';
import { FileUploadModule } from "primeng/fileupload";
import { ExportOfferCertificateTypeComponent } from './export-offer/export-offer-certificate-type/export-offer-certificate-type.component';
import { DataViewLayoutOptions, DataViewModule } from "primeng/dataview";
import { InputMaskModule } from "primeng/inputmask";
import { CountryDescriptionPipe } from '../../pipes/country-description.pipe';
import { DocumentDescriptionPipe } from '../../pipes/document-description.pipe';
import { ColorNamePipe } from "src/app/pipes/color-name.pipe";
import { SpeciesNamePipe } from "src/app/pipes/species-name.pipe";
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { RequestsCodeComponent } from './requests-code/requests-code.component';
import { FormErrorMessagePipe } from "src/app/pipes/form-error-message.pipe";
import { ImportOrderBillInfoComponent } from './import-order/import-order-bill-info/import-order-bill-info.component';
import { ImportOrderLoadingDataComponent } from './import-order/import-order-loading-data/import-order-loading-data.component';
import { ImportOrderDocumentsComponent } from './import-order/import-order-documents/import-order-documents.component';
import { ImportOrderPaymentsComponent } from './import-order/import-order-payments/import-order-payments.component';
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { OrderDetailTabComponent } from './order-detail-tab/order-detail-tab.component';
import { TabViewModule } from "primeng/tabview";
import { ExporterInfoModalComponent } from './exporter-info-modal/exporter-info-modal.component';
import { ProgramRequestDetailComponent } from './program-request/program-request-detail/program-request-detail.component';
import { UserImportOrderChatComponent } from './import-order/user-import-order-chat/user-import-order-chat.component';
import { FilterComponentComponent } from './filter-component/filter-component.component';
import { TagModule } from 'primeng/tag';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { DirectSaleDetailComponent } from "./direct-sale-detail/direct-sale-detail.component";
import { ImportProgramRequestListComponent } from './program-request/import-program-request-list/import-program-request-list.component';
import { PortNamePipe } from "src/app/pipes/port-name.pipe";
import { NotificationListComponent } from './notification-list/notification-list.component';
import { GalleriaModule } from 'primeng/galleria';
import { ProductCardComponent } from './product-card/product-card.component';
import { FooterHomeComponent } from './footer-home/footer-home.component';
import { SocialMediaShareComponent } from './social-media-share/social-media-share.component';
import { QrImageComponent } from './qr-image/qr-image.component';
import { ImageModule } from 'primeng/image';
import { RequestImportRequestComponent } from './request-import-request/request-import-request.component';
import { OverlayPanelModule } from "primeng/overlaypanel";
import { ImportRequestCarouselComponent } from './import-request-carousel/import-request-carousel.component';

@NgModule({
    declarations: [
        DashboardLayoutComponent,
        ContentBodyComponent,
        ContentHeaderComponent,
        HomeHeaderComponent,
        UserDashboardComponent,
        SideMenuComponent,
        UserMenuComponent,
        AdminDashboardComponent,
        ExportOfferDetailComponent,
        TimerComponent,
        WishListComponent,
        SwitchLangComponent,
        ImportRequestDetailComponent,
        ExportOfferDetailDescriptionComponent,
        ExportOfferDetailCarouselComponent,
        ImportOrderDeliveryComponent,
        ImportOrderOperationComponent,
        UserImportOrderListComponent,
        FrozenFruitExportOfferDetailComponent,
        FrozenFruitExportOfferDetailCarouselComponent,
        FrozenFruitExportOfferDetailDescriptionComponent,
        ExportOfferCreateOptionComponent,
        ExportOfferPictureUploaderComponent,
        ExportOfferCertificateTypeComponent,
        CountryDescriptionPipe,
        DocumentDescriptionPipe,
        ColorNamePipe,
        SpeciesNamePipe,
        FormErrorMessagePipe,
        DisclaimerComponent,
        RequestsCodeComponent,
        ImportOrderBillInfoComponent,
        ImportOrderLoadingDataComponent,
        ImportOrderDocumentsComponent,
        ImportOrderPaymentsComponent,
        OrderDetailTabComponent,
        ExporterInfoModalComponent,
        ProgramRequestDetailComponent,
        FilterComponentComponent,
        DirectSaleDetailComponent,
        ImportProgramRequestListComponent,
        UserImportOrderChatComponent,
        FilterComponentComponent,
        PortNamePipe,
        NotificationListComponent,
        ProductCardComponent,
        FooterHomeComponent,
        SocialMediaShareComponent,
        QrImageComponent,
        RequestImportRequestComponent,
        ImportRequestCarouselComponent
    ],
    exports: [
        DashboardLayoutComponent,
        ContentHeaderComponent,
        ContentBodyComponent,
        HomeHeaderComponent,
        UserDashboardComponent,
        AdminDashboardComponent,
        ExportOfferDetailComponent,
        TimerComponent,
        SwitchLangComponent,
        ImportRequestDetailComponent,
        ExportOfferDetailDescriptionComponent,
        ExportOfferDetailCarouselComponent,
        ImportOrderDeliveryComponent,
        ImportOrderOperationComponent,
        UserImportOrderListComponent,
        FrozenFruitExportOfferDetailComponent,
        ExportOfferPictureUploaderComponent,
        ExportOfferCertificateTypeComponent,
        ExportOfferCreateOptionComponent,
        CountryDescriptionPipe,
        DocumentDescriptionPipe,
        ColorNamePipe,
        SpeciesNamePipe,
        DisclaimerComponent,
        RequestsCodeComponent,
        FormErrorMessagePipe,
        ImportOrderBillInfoComponent,
        ImportOrderLoadingDataComponent,
        ImportOrderDocumentsComponent,
        ImportOrderPaymentsComponent,
        OrderDetailTabComponent,
        ExporterInfoModalComponent,
        ProgramRequestDetailComponent,
        FilterComponentComponent,
        DirectSaleDetailComponent,
        ImportProgramRequestListComponent,
        UserImportOrderChatComponent,
        FilterComponentComponent,
        PortNamePipe,
        ProductCardComponent,
        FooterHomeComponent,
        SocialMediaShareComponent,
        QrImageComponent,
        RequestImportRequestComponent,
        ImportRequestCarouselComponent
    ],
    bootstrap: [DashboardLayoutComponent],
    imports: [
        ReactiveFormsModule,
        FormsModule,
        CommonModule,
        RouterModule,
        MenubarModule,
        PanelMenuModule,
        CardModule,
        PanelModule,
        DynamicDialogModule,
        MenuModule,
        SidebarModule,
        ButtonModule,
        TabMenuModule,
        DividerModule,
        InputTextModule,
        InputNumberModule,
        MessagesModule,
        MessageModule,
        ToastModule,
        CarouselModule,
        CalendarModule,
        DropdownModule,
        TableModule,
        FileUploadModule,
        DataViewModule,
        InputMaskModule,
        FontAwesomeModule,
        TabViewModule,
        TagModule,
        ToggleButtonModule,
        ImageModule,
        OverlayPanelModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpTranslateLoader,
                deps: [HttpClient]
            }
        }),
        DialogModule,
        GalleriaModule
    ]
})
export class ComponentsModule{

}

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http);
  }
