import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { CountryGateway } from 'src/app/application/gateway/CountryGateway';
import { ProfileGateway } from 'src/app/application/gateway/ProfileGateway';
import { Country } from 'src/app/domain/models/Country';
import { Profile, ProfileContactInfo } from 'src/app/domain/models/Profile';
import { BasicUser } from 'src/app/domain/models/User';
import { Routes } from 'src/app/infrastructure/routes';
import { formLimit } from 'src/app/ui/util/form-limit';


@Component({
  selector: 'app-user-contact-info',
  templateUrl: './user-contact-info.component.html',
  styleUrls: ['./user-contact-info.component.css'],
  providers: [ConfirmationService, MessageService]
})
export class UserContactInfoComponent implements OnInit {
  profileTypeOptions: any[] = []
  selectedCountry: any = {}
  countries: Country[] = []
  submit: boolean = false
  loading: boolean = false
  disabledFields: boolean = true

  oldUsername: string | undefined
  contactInfo: ProfileContactInfo | undefined
  profile: BasicUser | undefined
  profileType: string | undefined

  formLimit = formLimit

  langs: any[] = [
    {
      label: 'English',
      value: 'en'
    },
    {
      label: 'Español',
      value: 'es'
    }
  ]

  formGroup: FormGroup = this._formBuilder.group({
    contactName: [{value: '', disabled: true}, Validators.required],
    contactCountryId: [{value: null, disabled: true}, Validators.required],
    contactCity: [{value: '', disabled: true}, Validators.required],
    contactAddress: [{value: '', disabled: true}, Validators.required],
    contactPhone: [{value: '', disabled: true}],
    contactEmail: [{value: '', disabled: true}, [Validators.required, Validators.email]],
    lang: [{value: '', disabled: true}, [Validators.required]]
  })

  constructor(private _router: Router,
    private _formBuilder: FormBuilder,
    private _messageService: MessageService,
    private _confirmationService: ConfirmationService,
    private _authService: AuthGateway,
    private _profileService: ProfileGateway,
    private _countryService: CountryGateway,
    public translate: TranslateService) {

      this.countries = []
  }

  ngOnInit(): void {
    this.profile = this._authService.getUser()
    this.getProfileType()
    this.getContactInfo()
  }

  getContactInfo() {
    this._profileService.getContactInfo().subscribe({
      next: (v) => {
        this.contactInfo = v
        this.oldUsername = this.contactInfo.contactEmail
        this.loadForm()
      },
      error: (e) => {
        console.log(e)
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.ProfileMessageService.SingleRetrieveError') });
      },
      complete: () => console.info('Profile query successfully')
    })
  }

  getCountries() {
    this._countryService.getAll().subscribe({
      next: (v) => {
        this.countries = v
      },
      error: (e) => {
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Country.ManyRetrieveError') });
        console.log(e)
      },
      complete: () => console.info('Country query successfully')
    })
  }

  getProfileType() {
    if(this.profile) {
      switch(this.profile.lang) {
        case 'en':
          switch(this.profile.type) {
            case 1:
              this.profileType = 'Exporter'
            break
            case 2:
              this.profileType = 'Importer'
            break
            case 4:
              this.profileType = 'QC Company'
            break
            case 5:
              this.profileType = 'Forwarder'
            break
            default:
              this.profileType = 'undefined'
          }
          break
        case 'es':
          switch(this.profile.type) {
            case 1:
              this.profileType = 'Exporter'
            break
            case 2:
              this.profileType = 'Importer'
            break
            case 4:
              this.profileType = 'QC Company'
            break
            case 5:
              this.profileType = 'Forwarder'
            break
            default:
              this.profileType = 'undefined'
          }
          break
        default:
          this.profileType = 'undefined'
      }
    }
  }

  loadForm() {
    if(this.contactInfo) {
      this.formGroup.controls["contactName"].setValue(this.contactInfo.contactName)
      this.formGroup.controls["contactCountryId"].setValue(this.contactInfo.contactCountryId)
      this.formGroup.controls["contactCity"].setValue(this.contactInfo.contactCity)
      this.formGroup.controls["contactAddress"].setValue(this.contactInfo.contactAddress)
      this.formGroup.controls["contactPhone"].setValue(this.contactInfo.contactPhone)
      this.formGroup.controls["contactEmail"].setValue(this.contactInfo.contactEmail)
      this.formGroup.controls["lang"].setValue(this.contactInfo.lang)
      this.getCountries()
    }
  }

  save(): void {
    this.loading = true
    this.submit = true
    if(this.formGroup.valid) {
      const request: ProfileContactInfo = {
        contactName: this.formGroup.controls["contactName"].value,
        contactEmail: this.formGroup.controls["contactEmail"].value,
        contactPhone: this.formGroup.controls["contactPhone"].value,
        contactAddress: this.formGroup.controls["contactAddress"].value,
        contactCountryId: this.formGroup.controls["contactCountryId"].value,
        contactCity: this.formGroup.controls["contactCity"].value,
        lang: this.formGroup.controls["lang"].value
      }

      this._profileService.updateContactInfo(request).subscribe({
        next: (v) => {
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: this.translate.instant('MessageService.ProfileMessageService.EditSuccess') })

          if(this.oldUsername != this.formGroup.controls["contactEmail"].value) {
            this.closeSession()
          }
          else {
            this._profileService.getBasicUser().subscribe({
              next: (v) => {
                localStorage.setItem('_auth_user', JSON.stringify(v))
                this.translate.use(v.lang)
                window.location.reload();
              },
              error: (e) => {
                console.log(e)
              }
            })
          }

          this.submit = false
          this.loading = false
        },
        error: (e) => {
          this.loading = false
          console.log(e)
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ProfileMessageService.EditError') + e.error });
        },
        complete: () => console.info('Profile query successfully')
      })
    } else {
      this.loading = false
    }
  }

  setEnableEdit() {
    this.disabledFields = !this.disabledFields
    let fields = ["contactName", "contactCountryId", "contactCity", "contactAddress", "contactPhone", "contactEmail", "lang"]

    if(this.disabledFields) {
      fields.forEach(x => this.formGroup.controls[x].disable())
    }
    else {
      fields.forEach(x => this.formGroup.controls[x].enable())
    }
  }

  deactiveAccount() {
    this._confirmationService.confirm({
      message: this.translate.instant('MessageService.ProfileMessageService.DeactiveMessage'),
      header: this.translate.instant('MessageService.ProfileMessageService.DeactiveHeader'),
      icon: 'pi pi-info-circle',
      accept: () => {
        this._profileService.deactivate().subscribe({
          next: (v) => {
            this._messageService.add({ severity: 'info',
              summary: this.translate.instant('MessageService.Summary.SuccessAction'),
              detail: this.translate.instant('MessageService.ProfileMessageService.DeactiveSuccess') })
            this._router.navigate([Routes.login])
          },
          error: (e) => {
            console.log(e)
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.ProfileMessageService.DeactiveError') });
          },
          complete: () => console.info('FridgeStorage delete successfully')
      })
      },
      reject: (type: any) => {
          switch (type) {
              case ConfirmEventType.REJECT:
                //this._messageService.add({ severity: 'error', summary: 'Rejected', detail: 'Se canceló la eliminación' })
              break;
              case ConfirmEventType.CANCEL:
                //this._messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Se canceló la eliminación' })
              break;
          }
      }
    })
  }

  closeSession() {
    this._confirmationService.confirm({
      message: this.translate.instant('MessageService.ProfileMessageService.ClosedSessionMessage'),
      header: this.translate.instant('MessageService.ProfileMessageService.ClosedSessionHeader'),
      icon: 'pi pi-info-circle',
      rejectVisible: false,
      accept: () => {
        this._authService.logout()
        this._router.navigate([Routes.login])
      }
    })
  }

  getErrorMessage(fieldName: string): string {
    if (this.formGroup.controls[fieldName].hasError('required')) {
      return this.translate.instant('FormErrorMessage.FieldValueRequired')
    }
    if (this.formGroup.controls[fieldName].hasError('email')) {
      return this.translate.instant('FormErrorMessage.EmailFieldEmailFormatError')
    }
    return ''
  }

  onCancel() {
    this._router.navigate(['/'+Routes.userPanelPage])
  }
}
