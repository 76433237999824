import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthGateway } from '../application/gateway/AuthGateway';
import { Species } from '../domain/models/Species';
import { Lang } from '../ui/util/langs';

@Pipe({
  name: 'speciesName'
})
export class SpeciesNamePipe implements PipeTransform {

  constructor(private _authService: AuthGateway, public translate: TranslateService) {
  }

  transform(species: any | undefined | null): string {
    if(!species)
      return this.translate.instant('CommonLabels.NotAvailableLabel')

    const profile = this._authService.getUser()
    if(!profile)
      return this.translate.instant('CommonLabels.NotAvailableLabel')

    switch(profile.lang) {
      case Lang.ENGLISH:
        if(species.name)
          return species.name
        else if(species.speciesName)
          return species.speciesName
        else if(species.speciesNameEn)
          return species.speciesNameEn
        else return ''
      case Lang.SPANISH:
        if(species.nameEs)
          return species.nameEs
        else if(species.speciesNameEs)
          return species.speciesNameEs
        else return ''
      default:
        return ''
    }
  }
}
