import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ExportOffer, ExportOfferInformation } from 'src/app/domain/models/ExportOffer';
import { ExportOfferUtils } from '../../util/export-offer-utils';
import { BasicUser } from 'src/app/domain/models/User';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { Routes } from 'src/app/infrastructure/routes';
import { ExportOfferGateway } from 'src/app/application/gateway/ExportOfferGateway';
import { MenuItem, MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { ProductCertificateType } from 'src/app/domain/models/ProductCertificateType';
import { Packaging } from 'src/app/domain/models/Packaging';
import { Species } from 'src/app/domain/models/Species';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NewNegotiation } from 'src/app/domain/models/OfferNegotiation';
import { OfferNegotiationGateway } from 'src/app/application/gateway/OfferNegotiationGateway';
import { ImportOrderService } from 'src/app/application/service/ImportOrderService';
import { DocumentService } from 'src/app/application/service/DocumentService';
import { Port } from 'src/app/domain/models/Port';
import { Country } from 'src/app/domain/models/Country';
import { PortGateway } from 'src/app/application/gateway/PortGateway';
import { CountryGateway } from 'src/app/application/gateway/CountryGateway';
import { InputNumber } from 'primeng/inputnumber';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.css'],
  providers: [MessageService]
})
export class ProductDetailComponent implements OnInit {

  exportOfferGuid: string | undefined

  profile: BasicUser | undefined

  selectedProductCertificateTypes: ProductCertificateType[] = []
  selectedPackagingTypes: Packaging[] = []

  exportOfferInfo: ExportOfferInformation | undefined

  loading: boolean = false
  urlRoute: string = ''
  imageData: any

  pallets: number = 1
  visibleDestinationConfirm: boolean = false;
  submit: boolean = false;

  destinationPorts: Port[] = []
  destinationCountries: Country[] = []

  destinationFormGroup: FormGroup = this._formBuilder.group({
    countryToId: [null, [Validators.required]],
    portToId: [null, [Validators.required]],
  })

  submitNegotiation = false
  negotiationFormGroup: FormGroup = this._formBuilder.group({
    negociationPrice: ['', Validators.required],
    negociationIncoterm: ['', Validators.required],
    negociationPallets: [null, Validators.required],
    negotiationPaymentAgainstBL: [null, Validators.required]
  })

  purchaseForm: FormGroup = this._formBuilder.group({
    pallets: [1, [Validators.required]]
  })

  paymentBLItems: any[] = []

  incotermsOptions: any[] = ExportOfferUtils.getIncotermsOptions()

  breadcrumbItems: MenuItem[] = []
  selectedCountryTo: Country | undefined

  breadcrumbStep1ItemLabel2: string = ''
  markTrue: string = ''

  totalPrice: number = 0

  maxSale: number = 20;
  allowPurchase: boolean = true;
  allowNegotiation: boolean = true

  constructor(
    private _authService: AuthGateway,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _messageService: MessageService,
    private _exportOfferService: ExportOfferGateway,
    public translate: TranslateService,
    private _formBuilder: FormBuilder,
    private _offerNegotiationService: OfferNegotiationGateway,
    private _importOrderService: ImportOrderService,
    private _documentService: DocumentService,
    private _portService: PortGateway,
    private _countryService: CountryGateway,
  ){
    this._activatedRoute.params.subscribe(params => {
      this.exportOfferGuid = params['exportOfferGuid']
    })

    this.profile = _authService.getUser()

    if(this.profile){
      switch(this.profile.lang) {
        case 'en':
          this.breadcrumbStep1ItemLabel2 = 'Export offer detail'
          this.markTrue = 'Yes'
          break
          case 'es':
            this.breadcrumbStep1ItemLabel2 = 'Detalle de oferta de exportación'
            this.markTrue = 'Si'
          break
      }

      this.breadcrumbItems = [{ label: 'Marketplace',  routerLink: ['/' + Routes.marketplacePage]},
      { label: this.breadcrumbStep1ItemLabel2}
      ]
    }
  }


  ngOnInit(): void {
    this.profile = this._authService.getUser()
    this.loadDetailData()
    this.urlRoute = window.location.href;
    this.loadCountries()
  }



  loadDetailData()
  {
    if(this.exportOfferGuid)
    {
      this.loading = true;
      this._exportOfferService.getOfferDetailMarketplace(this.exportOfferGuid).subscribe({
        next: (v) => {
          this.exportOfferInfo = v
          this.exportOfferInfo.exportOffer.pictures = this.exportOfferInfo.pictures;
          if( this.exportOfferInfo.exportOffer.packagingTypes){
            this.selectedPackagingTypes = this.exportOfferInfo.exportOffer.packagingTypes
          }
          if(this.exportOfferInfo.exportOffer.availablePallets < 20){
            this.maxSale = this.exportOfferInfo.exportOffer.availablePallets
          }
          if(this.exportOfferInfo.exportOffer.certificates)
            this.selectedProductCertificateTypes =this.exportOfferInfo.exportOffer.certificates

          this.getImageQr()
          this.getTotalPriceOfferDialog(1)
          this.loading = false;
        },
        error: (e) => {
          this.loading = false;
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOffer.ManyRetrieveError') })
        }
      })
    }

  }


  getHeader(offer: ExportOffer): string {
    if(offer)
      return offer.variety && offer.variety.species && offer.variety.species.name ? offer.variety.species.name.toUpperCase() : ''
    //  return SpeciesUtils.getSpeciesName(offer.variety.species).toUpperCase()
    return ''
  }

  getSubheader(offer: ExportOffer): string {
    return offer.variety && offer.variety.name ? offer.variety.name.toUpperCase() : ''
  }


  getTotalPriceOfferDialog(value: number) {
    if(this.exportOfferInfo && value){
      this.totalPrice = ExportOfferUtils.getTotalPrice(this.exportOfferInfo?.exportOffer,value)
    }
  }

  getIncludedLabel(included: boolean) {
    return included ? this.translate.instant('UserPages.UserExportOffers.Included') : this.translate.instant('UserPages.UserExportOffers.NotIncluded')
  }

  navigateToLogin()
  {
    if(this.profile)
    {
      this._router.navigate(['/'+ Routes.marketplacePage])
    }else{
      this._router.navigate(['/', Routes.login])
    }
  }

  getIncoterms(value: string) {
    return ExportOfferUtils.getIncotermsName(value)
  }

  getNoAvailableLabel() {
    return this.translate.instant('ExportOffer.ExportOfferDetail.NoAvailable')
  }

  getImageQr()
  {
    if(this.exportOfferGuid){
      this._exportOfferService.getOfferMarketplaceCodeQrUrl(this.exportOfferInfo?.exportOffer.guid).subscribe({
        next: (v) => {
          this.createImageFromBlob(v.body)
        },
        error: (e) => {
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('Cannot load QR image') })
        }
      })
    }
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
       this.imageData = reader.result;
    }, false);

    if (image) {
       reader.readAsDataURL(image);
    }
 }

  get speciesDescription() {
    if(this.exportOfferInfo?.exportOffer) {

      switch(this.translate.currentLang) {
        case 'en':
          return {
            description: this.exportOfferInfo?.exportOffer.variety?.species?.descriptionEn,
            technicalCharacteristics: this.exportOfferInfo?.exportOffer.variety?.species?.technicalCharacteristicsEn,
            qualityControlCriteria: this.exportOfferInfo?.exportOffer?.variety?.species?.qualityControlCriteriaEn,
            harvestAreaChile: this.exportOfferInfo?.exportOffer?.variety?.species?.harvestAreaChileEn
          }
          break
        case 'es':
          return  {
            description: this.exportOfferInfo?.exportOffer.variety?.species?.descriptionEs,
            technicalCharacteristics: this.exportOfferInfo?.exportOffer.variety?.species?.technicalCharacteristicsEs,
            qualityControlCriteria: this.exportOfferInfo?.exportOffer?.variety?.species?.qualityControlCriteriaEs,
            harvestAreaChile: this.exportOfferInfo?.exportOffer?.variety?.species?.harvestAreaChileEs
          }
          break
      }
    }
    return  {
      description: this.getAvailableLabel(),
      technicalCharacteristics: this.getAvailableLabel(),
      qualityControlCriteria: this.getAvailableLabel(),
      harvestAreaChile: this.getAvailableLabel()
    }
  }

  getHarvestPeriod(specie?: Species) {
    let specieHarvestPeriod: string | undefined = ""
    if(this.exportOfferInfo?.exportOffer && specie) {
      switch(this.translate.currentLang) {
        case 'en':
          specieHarvestPeriod = specie?.harvestPeriod
          break
        case 'es':
          specieHarvestPeriod = specie?.harvestPeriodEs
          break
      }
    }
    return specieHarvestPeriod
  }

  getAvailableLabel() {
    return this.translate.instant('ExportOffer.ExportOfferDetail.NoAvailable')
  }

  getBasePallets(basePallets?: string) {
    return this.profile ? ExportOfferUtils.getBasePalletsName(this.profile.lang, basePallets) : ''
  }

  getBoxType(boxType?: string): string {
    return this.profile ? ExportOfferUtils.getBoxTypeName(this.profile.lang, boxType) : ''
  }

  getErrorMessage(fieldName: string): string {
    if (this.negotiationFormGroup.controls[fieldName].hasError('required')) {
      return this.translate.instant('FormErrorMessage.FieldValueRequired')
    }
    return ''
  }


  getPurchaseMessage(fieldName: string){
    if (this.purchaseForm.controls[fieldName].hasError('required')) {
      return this.translate.instant('FormErrorMessage.FieldValueRequired')
    }
  }

  getPalletsErrorMessage(fieldName: string): string {
      return 'El valor solicitado supera la cantidad de pallets disponibles'
  }

  get showPaymentBLPercent() {
    return ExportOfferUtils.showPaymentBLPercent(this.negotiationFormGroup.controls['negociationIncoterm'].value)
  }

  get finalPaymentPercent(): number {
    if(this.negotiationFormGroup.controls['negotiationPaymentAgainstBL'].value)
      return 100 - this.negotiationFormGroup.controls['negotiationPaymentAgainstBL'].value
    return 0
  }

  get paymentBLTitle() {
    if (this.negotiationFormGroup.controls['negotiationPaymentAgainstBL'].value)
      return ExportOfferUtils.getPaymentBLTitle(this.negotiationFormGroup.controls['negotiationPaymentAgainstBL'].value);
    return 'UserPages.UserBargaining.OpenTab.BargainingDetail.MakeCounterOfferModal.PaymentAgainstBLLabel'
  }
  getPaymentsBLItems()
  {
    this.paymentBLItems = ExportOfferUtils.getPaymentBLValues(this.negotiationFormGroup.controls['negociationIncoterm'].value);
  }

  onChangeIncoterm(event: any)
  {
    this.getPaymentsBLItems()
  }

  createNegotiation() {
    this.submitNegotiation = true
    this.loading = true
    if(this.exportOfferInfo && this.negotiationFormGroup.valid) {

      if(this.negotiationFormGroup.controls['negociationPallets'].value > this.exportOfferInfo.exportOffer.availablePallets) {
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MarketplacePage.Spot.AvailablePalletsError') })
        return
      }

      var portId = localStorage.getItem("_marketplace_port_to_param");
      var countryToParamId = localStorage.getItem("_marketplace_country_to_param");

      if(portId && countryToParamId){
        let negotiation: NewNegotiation = {
          pallets: this.negotiationFormGroup.controls["negociationPallets"].value,
          unitPrice: this.negotiationFormGroup.controls["negociationPrice"].value,
          typeSale: this.negotiationFormGroup.controls["negociationIncoterm"].value,
          exportOfferId: this.exportOfferInfo.exportOffer.id,
          username: this._authService.getUsername(),
          portToId: parseInt(portId),
          countryToId: parseInt(countryToParamId),
          paymentAgainstBL: this.negotiationFormGroup.controls["negotiationPaymentAgainstBL"].value
        }

        this._offerNegotiationService.create(negotiation).subscribe({
          next: (v) => {
            this._messageService.add({ severity: 'success',
              summary: this.translate.instant('MessageService.Summary.SuccessAction'),
              detail: this.translate.instant('MessageService.ImportOrder.CreateSuccess') })
            this.loading = false
            this._router.navigate([(Routes.userBargaining)])
          },
          error: (e) => {
            console.log(e)
            this.loading = false
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.ImportOrder.CreateError') + e.error })
          },
          complete: () => console.info('Offer Negotiation create successfully')
        })
      }
      else {
        this.loading = false
      }
    }
  }

  validateDestinationForm()
  {
    this.submit = true;

    if(this.destinationFormGroup.valid){
      this.createOrder()
    }
  }

  createOrder() {

    var portId = localStorage.getItem("_marketplace_port_to_param");
    var countryToParamId = localStorage.getItem("_marketplace_country_to_param");


    if(portId && countryToParamId)
      {

      if(this.exportOfferInfo) {
        if(this.purchaseForm.controls['pallets'].value < 1) {
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MarketplacePage.Spot.PalletsQuantityGreaterZeroError') })
          return;
        }

        if(this.purchaseForm.controls['pallets'].value > this.exportOfferInfo.exportOffer.availablePallets) {
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MarketplacePage.Spot.AvailablePalletsError') })
          return;
        }

        let offerRequest = {
          palletQuantity: this.purchaseForm.controls['pallets'].value,
          exportOfferId: this.exportOfferInfo?.exportOffer?.id,
          importUsername: this._authService.getUsername(),
          countryToId: countryToParamId,
          portToId: portId
        }

        this.loading = true;
        this._importOrderService.create(offerRequest).subscribe({
          next: (v) => {
            this.loading = false;
            this._messageService.add({ severity: 'success',
              summary: this.translate.instant('MessageService.Summary.SuccessAction'),
              detail: this.translate.instant('MessageService.ImportOrder.CreateSuccess') })
            this._router.navigate([(Routes.purchaseOrdersPage)])
          },
          error: (e) => {

            this.loading = false
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.ImportOrder.CreateError') + e.error })
          },
          complete: () => console.info('Import Orders create successfully')
        })
      }
      else {
        this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'Seleccione un contenedor de destino.' })
      }
    }else{
      this.visibleDestinationConfirm = true
    }


  }

  getExportOfferQualityDocument(documents: any[] | undefined) {
    if (documents == undefined || documents.length <= 0) return;

    let docId = documents[0].id

    this._documentService.getExportOfferDocument2(docId).subscribe((resultBlob: any) => {
      var downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }

  loadCountries() {
    this.loading = true;
    this._countryService.getAll().subscribe({
      next: (v) => {
        this.destinationCountries = v
        this.loading = false;

      },
      error: (e) => {
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Country.DestinationManyRetrieveError') })
      },
      complete: () => {
        console.info('Destination Countries query successfully')
      }
    })
  }

  loadDestinationPorts() {
    if(this.destinationFormGroup.controls['countryToId'].value) {
      this._portService.getDestinationPortsByCountry(this.destinationFormGroup.controls['countryToId'].value).subscribe({
        next: (v) => {
          this.destinationPorts = v
          localStorage.setItem("_marketplace_country_to_param", this.destinationFormGroup.controls['countryToId'].value)
          this.selectedCountryTo = this.destinationCountries.find(x => x.id === this.destinationFormGroup.controls['countryToId'].value)
        },
        error: (e) => {
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.Port.DestinationPortManyRetrieveError') })
        },
        complete: () => console.info('Destination Ports query successfully')
      })
    }
  }

  selectDestinationPort()
  {
    if(this.destinationFormGroup.controls['portToId'].value){
      let portToId = this.destinationFormGroup.controls['portToId'].value;
      localStorage.setItem('_marketplace_port_to_param', portToId );
    }

  }

  @ViewChild('palletsId') input: InputNumber | undefined

  onInputSale(event: any) {
    if(event.value > this.maxSale){
      this.allowPurchase = false;
      this.getTotalPriceOfferDialog(event.value);
    }else{
      this.allowPurchase = true;
      this.getTotalPriceOfferDialog(event.value);
    }
  }

  onInputNegotiation(event: any){
    if(event.value > this.maxSale){
      this.allowNegotiation = false;
    }else{
      this.allowNegotiation = true;
    }
  }


  getPackagingTypes(packagingTypes: Packaging[])
  {
    let name = '';
    if(packagingTypes.length == 1)
      {
        return packagingTypes[0].name;
      }
      packagingTypes.forEach((x, index) => {
      if(index == 0)
        {
          name = name + x.name;
        }else{
          name =  name + '/' + x.name;
        }
    }
    );
    return name
  }
  getCertificatesName(certificates: ProductCertificateType[]): string
  {
    let name = '';
    if(certificates.length == 1)
      {
        return certificates[0].name;
      }
    certificates.forEach((x, index) => {
      if(index == 0)
        {
          name = name + x.name;
        }else{
          name =  name + '/' + x.name;
        }
    }
    );
    return name
  }
}
