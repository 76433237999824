import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Notifier } from "src/app/domain/models/Notifier";

@Injectable({providedIn: 'root'})
export abstract class NotifierGateway {
  abstract getAll(): Observable<Array<Notifier>>
  abstract getAllByUsername(username: string): Observable<Array<Notifier>>
  abstract getById(id: number): Observable<Notifier>
  abstract create(notifier: Notifier): Observable<Notifier>
  abstract update(notifier: Notifier): Observable<Notifier>
  abstract delete(id: number): Observable<Notifier>
  abstract getAllForClosedSale(importerId: number): Observable<Array<Notifier>>
}
