<app-admin-dashboard>
  <p-breadcrumb class="max-w-full" [model]="breadcrumbMenuItems" [home]="breadcrumbHome"></p-breadcrumb>
  <p-card *ngIf="orderDetailInformation && orderDetail">
    <h2>Revisión QC pallets </h2>
    <p>Select the reviewed pallets and fill de QC Form</p>
    <div>
      <p-fieldset legend="Import order detail properties" [toggleable]="true" [collapsed]="true">
        <div class="grid">
          <div class="col-3">Import order Id:</div>
          <div class="col-3"><span style="font-weight: bolder;">{{orderDetail.importOrderId}}</span></div>
          <div class="col-3">Import order detail Id:</div>
          <div class="col-3"><span style="font-weight: bolder;">{{orderDetail.sequence}}</span></div>

          <div class="col-3">Species / Variety:</div>
          <div class="col-3">
            <span style="font-weight: bolder;">
              {{ orderDetail.speciesNameEn }} - {{ orderDetail.varietyName }}
            </span>
          </div>
          <div class="col-3">Coldstorage:</div>
          <div class="col-3">
            <span style="font-weight: bolder;">
              {{ orderDetail.fridgeStorageName }} {{ orderDetail.fridgeStorageCity }}, {{ orderDetail.fridgeStorageCountry }}
            </span>
          </div>

          <div class="col-3">Pallets quantity:</div>
          <div class="col-3">
            <span style="font-weight: bolder;">
              {{ orderDetail.palletsQuantity }}
            </span>
          </div>
          <div class="col-3">Case per pallet:</div>
          <div class="col-3">
            <span style="font-weight: bolder;">
              {{ orderDetail.boxesPerPallet }}
            </span>
          </div>

          <div class="col-3">Departure port:</div>
          <div class="col-3">
            <span style="font-weight: bolder;">
              {{orderDetail.portFromName }}
            </span>
          </div>
          <div class="col-3">Destination port:</div>
          <div class="col-3">
            <span style="font-weight: bolder;">
              {{ orderDetail.portToName }}
            </span>
          </div>
        </div>
      </p-fieldset>
    </div>

    <div>
      <p-table [value]="pallets"
        [(selection)]="verifiedPallets"
        dataKey="id"
        responsiveLayout="stack"
        [breakpoint]="'600px'"
        styleClass="p-datatable-gridlines"
        [scrollable]="true"
        [resizableColumns]="true"
        columnResizeMode="expand"
        scrollHeight="600px">
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 4rem">
              <p-tableHeaderCheckbox *ngIf="orderDetail?.status == 2"></p-tableHeaderCheckbox>
            </th>
            <th>Folio</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.SpeciesHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.VarietyHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.ProducerHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.SpeciesSizeHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.HarvestDateHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.QualityControlDateHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.PackagingHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.PalletTypeHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.TagHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.BagTypeHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.BoxTypeHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.NetWeightHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.ColdStorageHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.BoxePerPalletHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.MarksHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.ContainerNumberHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.GradeHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.TemptaleHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.OriginCountryHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.Cert1Header' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.Cert2Header' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.Cert3Header' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadWeekHeader' | translate}}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-pallet>
          <tr>
            <td>
              <p-tableCheckbox [value]="pallet" *ngIf="pallet.status == 1"></p-tableCheckbox>
              <i class="pi pi-check" style="color: slateblue" *ngIf="pallet.status == 2" pTooltip="Pallet Verified"></i>
              <i class="pi pi-times" style="color: darkred" *ngIf="pallet.status == 3" pTooltip="Pallet Rejected"></i>
            </td>
            <td>{{pallet.palletNumber}}</td>
            <td>{{pallet.excelImportSpecies}}</td>
            <td>{{pallet.excelImportVariety}}</td>
            <td>{{pallet.producer}}</td>
            <td>{{pallet.speciesSize}}</td>
            <td>{{pallet.excelImportHarvestDate}}</td>
            <td>{{pallet.excelImportQualityControlDate}}</td>
            <td>{{pallet.excelImportPackaging}}</td>
            <td>{{pallet.excelImportPalletType}}</td>
            <td>{{pallet.excelImportTag}}</td>
            <td>{{pallet.excelImportBagType}}</td>
            <td>{{pallet.excelImportBoxSize}}</td>
            <td>{{pallet.excelImportNetWeight}}</td>
            <td>{{pallet.excelImportFridgeStorage}}</td>
            <td>{{pallet.excelImportBoxesPerPallet}}</td>
            <td>{{pallet.excelImportMark}}</td>
            <td>{{pallet.excelImportContainerNumber}}</td>
            <td>{{pallet.excelImportGrade}}</td>
            <td>{{pallet.excelImportTemptale}}</td>
            <td>{{pallet.excelImportOriginCountry}}</td>
            <td>{{pallet.excelImportCertificate1}}</td>
            <td>{{pallet.excelImportCertificate2}}</td>
            <td>{{pallet.excelImportCertificate3}}</td>
            <td>{{pallet.excelImportLoadWeek}}</td>
          </tr>
        </ng-template>
      </p-table>

      <form [formGroup]="formGroup">
        <div class="p-fluid formgrid grid" style="margin-top: 30px;">
          <div class="field col-12 md:col-6 lg:col-3" style="margin-bottom: 40px;">
            <label for="qualityControlDate">{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.QualityControlDateLabel' | translate}} *</label>
            <p-calendar
              inputId="qualityControlDate"
              formControlName="qualityControlDate"
              appendTo="body"
              [showButtonBar]="true"
              [showIcon]="true"
              placeholder="{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.QualityControlDateLabel' | translate}}"
              [showWeek]="true"
              [firstDayOfWeek]="1"></p-calendar>
            <small *ngIf="submit && formGroup.controls['qualityControlDate'].invalid" class="p-error">{{getErrorMessage(formGroup, 'qualityControlDate')}}</small>
          </div>
          <div class="field col-12" style="margin-bottom: 40px;">
            <label>Pdf Inspección de control de calidad</label>
            <p-fileUpload
              #QCReviewPdfUploader
              (onSelect)="onSelectQCReviewPdf($event)"
              [showUploadButton]="false"
              [multiple]="false"
              accept=".pdf"
              [maxFileSize]="5000000"
              chooseLabel="{{'UserPages.UserProductCertificates.Form.SelectFile' | translate}}"
              cancelLabel="{{'UserPages.UserProductCertificates.Form.CancelSelectFile' | translate}}"/>
          </div>
          <div class="field col-12" style="margin-bottom: 40px;">
            <label>Pdf Consolidación de control de calidad</label>
            <p-fileUpload
              #QCConsolidationPdfUploader
              (onSelect)="onSelectQCConsolidationPdf($event)"
              [showUploadButton]="false"
              [multiple]="false"
              accept=".pdf"
              [maxFileSize]="5000000"
              chooseLabel="{{'UserPages.UserProductCertificates.Form.SelectFile' | translate}}"
              cancelLabel="{{'UserPages.UserProductCertificates.Form.CancelSelectFile' | translate}}"/>
          </div>
        </div>
      </form>

      <div class="grid" style="margin-top: 30px;">
        <div class="col-12 md:col-6 lg:col-6">
        </div>
        <div class="col-12 md:col-6 lg:col-6">
          <div class="grid p-fluid">
            <div class="col-12 md:col-6 lg:col-6">
              <p-button label="Regresar" (onClick)="cancel()" styleClass="p-button-outlined"></p-button>
            </div>
            <div class="col-12 md:col-6 lg:col-6">
              <p-button label="Confirmar" (onClick)="saveConfirmPallets()" [loading]="loading"></p-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </p-card>
</app-admin-dashboard>

<p-toast></p-toast>
