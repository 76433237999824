import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { NotificationGateway } from 'src/app/application/gateway/NotificationGateway';
import { SecurityGateway } from 'src/app/application/gateway/SecurityGateway';
import { BasicUser } from 'src/app/domain/models/User';
import { Routes } from 'src/app/infrastructure/routes';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.css'],
  providers: [MessageService]
})
export class UserMenuComponent implements OnInit {
  items: MenuItem[] = []

  pendingNotificationCount: number = 0

  profile: BasicUser | undefined

  userMenuItems: MenuItem[] = []

  notificationsMenuItems: MenuItem[] = []

  helpMenuItems: MenuItem[] = []

  notificationsMenuVisible: boolean = false;

  constructor(private _router: Router,
    private _messageService: MessageService,
    private _authService: AuthGateway,
    private _securityService: SecurityGateway,
    private _notificationService: NotificationGateway,
    public translate: TranslateService) {
  }

  ngOnInit(): void {
    this.profile = this._authService.getUser()

    this.translate.get([
      'UserMenuComponent.UserMenuItems.UserContactInfoLabel',
      'UserMenuComponent.UserMenuItems.UserCompanyInfoLabel',
      'UserMenuComponent.UserMenuItems.ConsigneeLabel',
      'UserMenuComponent.UserMenuItems.NotifyLabel',
      'UserMenuComponent.UserMenuItems.SpeciesLabel',
      'UserMenuComponent.UserMenuItems.SecurityOptionsLabel',
      'UserMenuComponent.NotificationsMenuItems.NotificationsLabel',
      'UserMenuComponent.NotificationsMenuItems.NotificationConfigLabel',
      'UserMenuComponent.HelpMenuItems.CustomerServiceLabel',
      'UserMenuComponent.HelpMenuItems.CustomerHelpLabel'
    ]).subscribe({
      next: (tranlations) => {
        if(this.profile) {
          this.userMenuItems = [
            {
              label: tranlations['UserMenuComponent.UserMenuItems.UserContactInfoLabel'],
              routerLink: '/' + [Routes.userContactInfo],
            },
            {
              label: tranlations['UserMenuComponent.UserMenuItems.UserCompanyInfoLabel'],
              routerLink: ['/' + Routes.userCompanyInfo],
            },
            {
              label: tranlations['UserMenuComponent.UserMenuItems.ConsigneeLabel'],
              routerLink: ['/' + Routes.userConsignees],
              visible: this.profile.type === 2
            },
            {
              label: tranlations['UserMenuComponent.UserMenuItems.NotifyLabel'],
              routerLink: ['/' + Routes.userNotifiers],
              visible: this.profile.type === 2
            },
            {
              label: tranlations['UserMenuComponent.UserMenuItems.SpeciesLabel'],
              routerLink: ['/' + Routes.userProducts],
              visible: this.profile.type === 1 || this.profile.type === 2
            },
            {
              label: tranlations['UserMenuComponent.UserMenuItems.SecurityOptionsLabel'],
              routerLink: ['/' + Routes.userChangePassword]
            }
          ]

          this.notificationsMenuItems = [
            {
              label: tranlations['UserMenuComponent.NotificationsMenuItems.NotificationsLabel'],
              routerLink: ['/' + Routes.userNotifications]
            },
            {
              label: tranlations['UserMenuComponent.NotificationsMenuItems.NotificationConfigLabel'],
              routerLink: ['/' + Routes.userNotificationsConfig]
            },
          ]

          this.helpMenuItems = [
            {
              label: tranlations['UserMenuComponent.HelpMenuItems.CustomerServiceLabel'],
              routerLink: ['/' + Routes.userSupport]
            },
            {
              label: tranlations['UserMenuComponent.HelpMenuItems.CustomerHelpLabel']
            },
          ]
        }
      },
      error: (e) => {
        console.log(e)
      }
    })

    if(this._authService.isAuthenticated()) {
      this._notificationService.getPendingCount().subscribe({
        next: (v) => {
          this.pendingNotificationCount = v
        },
        error: (e) => {
          console.log(e)
        },
        complete: () => console.info('Pending notifications count query successfully')
      })
    }
  }

  get notificationBadge(): string {
    return this.pendingNotificationCount > 0 ? this.pendingNotificationCount.toString() : ''
  }

  goToMarketplace() {
    this._router.navigate(['/' + Routes.marketplacePage])
  }

  goToExportOffers() {
    this._router.navigate([Routes.userExportOffers])
  }

  goToImportOrders() {
    this._router.navigate(['/' + Routes.userImportOrdersCurrent])
  }

  goToQCReviews() {
    this._router.navigate(['/' + Routes.qcUserReviews])
  }

  goToForwarderReviews() {
    this._router.navigate(['/' + Routes.forwarderUserReviews])
  }

  goToCustomAgentReviews() {
    this._router.navigate(['/' + Routes.customAgentUserReviews])
  }

  onDialogClose(event: any) {
    this.notificationsMenuVisible = event;
  }

  logout(): void {
    this._securityService.doLogout().subscribe({
      next: (v) => {
        this._authService.logout()
        this._router.navigate([Routes.login])
      },
      error: (e) => {
        console.log(e)
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.System.LogoutFailed') });
      },
      complete: () => {
        console.info('Logout successfully')
      }
    })
  }
}
