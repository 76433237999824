import { Component, Input } from '@angular/core';
import {Clipboard} from '@angular/cdk/clipboard';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-social-media-share',
  templateUrl: './social-media-share.component.html',
  styleUrls: ['./social-media-share.component.css'],
  providers: [MessageService]
})
export class SocialMediaShareComponent {


  @Input()
  url: string = ''

  whatsappUrl :string = 'https://api.whatsapp.com/send?text='
  facebookUrl :string = 'https://www.facebook.com/share_channel/?link='
  linkedInUrl: string = 'https://www.linkedin.com/uas/login?session_redirect='

  constructor(
    private clipboard: Clipboard,
    private _messageService: MessageService,
    public translate: TranslateService
  )
  {}



  clickWapp(){
    window.open(this.whatsappUrl + this.url, "_blank");
  }

  clickFace(){
    window.open(this.facebookUrl + this.url, "_blank");
  }

  clickLinkedIn(){
    window.open(this.linkedInUrl + this.url, "_blank");
  }

  copyUrl() {
    this.clipboard.copy(this.url);
    this._messageService.add({ severity: 'success',
      summary: this.translate.instant('MessageService.Summary.SuccessAction'),
      detail: this.translate.instant('Share.CopyUrlMessage') })
  }

}
