import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService, TreeNode } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { ProfileGateway } from 'src/app/application/gateway/ProfileGateway';
import { SpeciesGateway } from 'src/app/application/gateway/SpeciesGateway';
import { CreateProfileSpecies } from 'src/app/domain/models/Profile';
import { Species, SpeciesVarieties } from 'src/app/domain/models/Species';
import { BasicUser } from 'src/app/domain/models/User';
import { Routes } from 'src/app/infrastructure/routes';
import { SpeciesUtils } from 'src/app/ui/util/species-utils';

@Component({
  selector: 'app-user-products',
  templateUrl: './user-products.component.html',
  styleUrls: ['./user-products.component.css'],
  providers: [MessageService]
})
export class UserProductsComponent implements OnInit {

  species: Species[] = []
  selection: Species[] = []
  loading: boolean = true

  profile: BasicUser | undefined

  constructor(private _router: Router,
    private _messageService: MessageService,
    private _authService: AuthGateway,
    private _speciesService: SpeciesGateway,
    private _profileService: ProfileGateway,
    public translate: TranslateService) {

  }

  ngOnInit(): void {
    this.profile = this._authService.getUser()
    this.getAll()
  }

  getAll() {
    this.loading = true
    this._speciesService.getAll().subscribe({
      next: (v) => {
        this.species = v
        this.getAllByUsername()
        this.loading = false
      },
      error: (e) => {
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Species.ManyRetrieveError') });
        console.log(e)
        this.loading = false
      },
      complete: () =>  {
        this.loading = false
        console.info('Species query successfully')
      }
    })
  }

  getAllByUsername() {
    this._speciesService.getAllByUsername(this._authService.getUsername()).subscribe({
      next: (v) => {
        console.log(v)
        this.selection = v
        this.loading = false
      },
      error: (e) => {
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Species.ManyRetrieveError') });
        console.log(e)
        this.loading = false
      },
      complete: () =>  {
        this.loading = false
        console.info('SpeciesProfile query successfully')
      }
    })
  }

  save() {
    if(this.selection) {
      let added: number[] = []
      this.selection.forEach(x => {
        if(x.id)
          added.push(x.id)
      })

      let removed: number[] = []
      this.species.forEach(x => {
        if(!this.selection.find(s => s.id === x.id)) {
          if(x.id)
            removed.push(x.id)
        }
      })

      let createProfileSpecies: CreateProfileSpecies = {
        addedSpecies: added,
        removedSpecies: removed
      }

      this._profileService.updateSpecies(createProfileSpecies).subscribe({
        next: (v) => {
          this.selection = v
          console.log(v)
          this.loading = false
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: this.translate.instant('MessageService.ProfileMessageService.FavoriteProductsSuccess') })
        },
        error: (e) => {
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ProfileMessageService.FavoriteProductsError') });
          console.log(e)
          this.loading = false
        },
        complete: () =>  {
          this.loading = false
          console.info('SpeciesProfile update successfully')
        }
      })
    }
  }

  getSpeciesName(species: Species): string {
    if(this.profile)
      return SpeciesUtils.getSpeciesName(this.profile.lang, species)
    return ''
  }

  onCancel() {
    this._router.navigate(['/' + Routes.userPanelPage])
  }

  getFilterField(): string {
    let lang = this.profile?.lang
    if (lang === 'es') {
        return 'nameEs';
    } else {
        return 'name';
    }
}
}
