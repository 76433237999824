import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { FrozenFruitExportOffer, FrozenFruitExportOfferCreateRequest, FrozenFruitExportOfferDocuments, FrozenFruitExportOfferListItem, FrozenFruitExportOfferUploadDocumentsRequest, FrozenFruitExportOfferUploadPicturesRequest } from "src/app/domain/models/FrozenFruitExportOffer";
import { FrozenFruitImportOrderCreateRequest } from "src/app/domain/models/ImportOrder";

@Injectable({providedIn: 'root'})
export abstract class FrozenFruitExportOfferGateway {
  abstract getAllByExporter(isActive: boolean): Observable<FrozenFruitExportOfferListItem[]>
  abstract create(request: FrozenFruitExportOfferCreateRequest): Observable<FrozenFruitExportOffer>
  abstract update(request: FrozenFruitExportOffer): Observable<FrozenFruitExportOffer>
  abstract uploadProductPictures(request: FrozenFruitExportOfferUploadPicturesRequest): Observable<any>
  abstract uploadDocuments(request: FrozenFruitExportOfferUploadDocumentsRequest): Observable<any>
  abstract getById(id: number): Observable<FrozenFruitExportOffer>
  abstract toogleIsActive(id: number, isActive: boolean): Observable<any>
  abstract duplicate(id: number): Observable<FrozenFruitExportOffer>
  abstract getDocumentsById(id: number): Observable<FrozenFruitExportOfferDocuments>
  abstract getAllForMarketplace(countryId: number): Observable<FrozenFruitExportOfferListItem[]>
  abstract addFrozenFruitShoppingCart(request: FrozenFruitImportOrderCreateRequest): Observable<any>
}
