import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { ExportOfferGateway } from 'src/app/application/gateway/ExportOfferGateway';
import { ExportOfferSuggestionGateway } from 'src/app/application/gateway/ExportOfferSuggestionGateway';
import { ExportOffer } from 'src/app/domain/models/ExportOffer';
import { Suggestion, SuggestionExportOffer, SuggestionInformation } from 'src/app/domain/models/ExportOfferSuggestion';
import { Packaging } from 'src/app/domain/models/Packaging';
import { ProductCertificateType } from 'src/app/domain/models/ProductCertificateType';
import { Routes } from 'src/app/infrastructure/routes';
import { ExportOfferUtils } from 'src/app/ui/util/export-offer-utils';

@Component({
  selector: 'app-offer-suggestions',
  templateUrl: './offer-suggestions.component.html',
  styleUrls: ['./offer-suggestions.component.css'],
  providers: [MessageService]
})
export class OfferSuggestionsComponent implements OnInit {

  suggestionId: number | undefined
  suggestionInfo: SuggestionInformation | undefined
  selected: SuggestionExportOffer | undefined
  offerSuggestions: SuggestionExportOffer[] = []
  loading: boolean = false
  success: boolean = false
  visibleRedirectDialog: boolean = false

  selectedExportOffer: ExportOffer | undefined
  selectedProductCertificateTypes: ProductCertificateType[] = []
  selectedPackagingTypes: Packaging[] = []

  visibleOfferDetailDialog: boolean = false

  constructor(private _messageService: MessageService,
    private _activatedRoute: ActivatedRoute,
    private _suggestionService: ExportOfferSuggestionGateway,
    private router: Router,
    private _exportOfferService: ExportOfferGateway,
    public translate: TranslateService) {

    this._activatedRoute.params.subscribe(params => {
      this.suggestionId = params['suggestionId']
    })
  }

  ngOnInit(): void {
    this.loadSuggestion()
  }

  loadSuggestion() {
    if(this.suggestionId) {
      this.loading = true
      this._suggestionService.getById(this.suggestionId).subscribe({
        next: (v) => {
          this.suggestionInfo = v
          this.offerSuggestions = this.suggestionInfo.offerSuggetions
        },
        error: (e) => {
          console.log(e)
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOfferSuggestion.ManyRetrieveError') });
        },
        complete: () => {
          this.loading = false
          console.info('Offer Suggestions query successfully')
        }
      })
    }
  }

  getTotalPrice(exportOffer: ExportOffer): number {
    if(exportOffer && this.suggestionInfo?.suggestion?.palletsQuantity) {
      return ExportOfferUtils.getTotalPrice(exportOffer, this.suggestionInfo?.suggestion?.palletsQuantity)
    }
    return 0
  }

  openRedirectDialog() {
      this.visibleRedirectDialog = true

  }

  acceptOfferSuggestion() {
    if(this.selected && this.suggestionId) {
      this.loading = true
      this._suggestionService.acceptSuggestion(this.selected.id, this.suggestionId).subscribe({
        next: (v) => {
          this.loading = false
          this.success = true
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: this.translate.instant('MessageService.ExportOfferSuggestion.AcceptSuccess') })
          this.redirectToOrders();
        },
        error: (e) => {
          this.loading = false
          this.success = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: this.translate.instant('MessageService.ExportOfferSuggestion.AcceptError') });
        },
        complete: () => {
          console.info('Accept Suggestion successfully')
        }
      })
    }
    else {
      this._messageService.add({ severity: 'error',
        summary: this.translate.instant('MessageService.Summary.SuccessAction'),
        detail: this.translate.instant('MessageService.ExportOfferSuggestion.SelectError') });
    }
  }


  redirectToOrders(){
    this.router.navigate(['/' + Routes.userImportOrdersCurrent]);
  }

  showExportOfferDetailDialog(suggestion: SuggestionExportOffer) {
    this.selected = suggestion

    if(this.selected?.suggestion?.importOrderDetail?.exportOffer.id) {
      this._exportOfferService.getById(this.selected.suggestion.importOrderDetail.exportOffer.id).subscribe({
        next: (v) => {
          this.selectedExportOffer = v
          if(this.selectedExportOffer.productCertificateTypes)
            this.selectedProductCertificateTypes = this.selectedExportOffer.productCertificateTypes

          if(this.selectedExportOffer.packagingTypes)
            this.selectedPackagingTypes = this.selectedExportOffer.packagingTypes

          this.loading = false
          this.visibleOfferDetailDialog = true
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOffer.ManyRetrieveError') })
        },
        complete: () => console.info('Export Offers query successfully')
      })
    }
  }
}
