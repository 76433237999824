import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { DocumentServiceGateway } from 'src/app/application/gateway/DocumentServiceGateway';
import { ForwarderGateway } from 'src/app/application/gateway/ForwarderGateway';
import { ImportOrderDocumentGateway } from 'src/app/application/gateway/ImportOrderDocumentGateway';
import { ImportOrderGateway } from 'src/app/application/gateway/ImportOrderGateway';
import { UserJoinRequestGateway } from 'src/app/application/gateway/UserJoinRequestGateway';
import { OperationDocumentTypeGateway } from 'src/app/application/gateway/OperationDocumentTypeGateway';
import { ForwarderOrderListItem } from 'src/app/domain/models/ForwarderOrder';
import { AssignExporterImporter, ForwarderRelations } from 'src/app/domain/models/ForwarderReview';
import { GenericOrderDetail, ImportOrderDocument, UploadImportOrderDocumentRequest } from 'src/app/domain/models/ImportOrder';
import { OperationDocumentType } from 'src/app/domain/models/Operation';
import { BasicUser } from 'src/app/domain/models/User';
import { UserJoinRequest } from 'src/app/domain/models/UserJoinRequest';
import { Routes } from 'src/app/infrastructure/routes';
import { ErrorMessageUtil } from 'src/app/ui/util/error-message-util';

@Component({
  selector: 'app-forwarder-import-order',
  templateUrl: './forwarder-import-order.component.html',
  styleUrls: ['./forwarder-import-order.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class ForwarderImportOrderComponent {

  profile: BasicUser | undefined
  statuses: any[] = [];
  orders: ForwarderOrderListItem[] = []

  uploadFile: any[] = []
  visibleUploadExcel: boolean = false
  loading: boolean = false
  visibleInvite: boolean = false
  submit: boolean = false

  userJoinRequestFormGroup: FormGroup = this._formBuilder.group({
    guestEmail: ['', [Validators.required, Validators.email]],
  })

  visibleUploadDocuments: boolean = false
  orderForwarderSelected: any
  documents: ImportOrderDocument[] = []
  documentTypeId: string = ""
  operationDocumentTypes: OperationDocumentType[] = []

  visibleRelations: boolean = false
  forwarderRelations: ForwarderRelations | undefined
  importerSelected: number | undefined
  exporterSelected: number | undefined

  constructor(
    private _authService: AuthGateway,
    private _messageService: MessageService,
    private _router: Router,
    private _importOrderService: ImportOrderGateway,
    private _forwarderGatewayService: ForwarderGateway,
    private _userJoinRequestService: UserJoinRequestGateway,
    private _formBuilder: FormBuilder,
    private _operationDocumentTypeService: OperationDocumentTypeGateway,
    private _importOrderDocumentService: ImportOrderDocumentGateway,
    private _documentService: DocumentServiceGateway,
    private _confirmationService: ConfirmationService,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.profile = this._authService.getUser()

    this.statuses = [
      { label: 'Created', value: 0 },
      { label: 'Pending Verify Pallets', value: 1 },
      { label: 'QC Pallets Verified', value: 2 },
      { label: 'Order Confirmed', value: 3 },
      { label: 'Payment Confirmed', value: 4 }
    ];

    this.loadExportDocumentType()
    this.loadForwarderRelations()
    this.getImportOrders()
  }

  loadForwarderRelations() {
    this._forwarderGatewayService.forwarderRelations().subscribe({
      next: (v) => {
        this.forwarderRelations = v
      },
      error: (e) => {
        console.log(e)
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('ForwarderPage.Messages.ErrorLoadRelations') });
      },
      complete: () => console.info('Forwarder relations query successfully')
    })
  }

  getImportOrders() {
    this._importOrderService.getMbOrders().subscribe({
      next: (v) => {
        this.orders = v
      },
      error: (e) => {
        console.log(e)
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.ExportOffer.SingleRetrieveError') });
      },
      complete: () => console.info('ExportOffer query successfully')
    })
  }

  getStatusSeverity(status: number): string {
    if (status === 1 || status === 2) {
      return 'warning';
    } else if (status === 3 || status === 4) {
      return 'success';
    } else {
      return 'info';
    }
  }

  getStatusIcon(status: number): string {
    if (status === 0 || status === 1 || status === 2) {
      return 'pi pi-exclamation-triangle';
    } else if (status === 3 || status === 4) {
      return 'pi pi-check';
    } else {
      return '';
    }
  }

  getDefaultLoadingInfo() {
    return 'CommonLabels.LoadingInfo'
  }

  getSpeciesName(orderDetail: GenericOrderDetail): string {
    if(this.profile) {
      switch(this.profile.lang) {
        case 'es':
          return orderDetail.speciesNameEs
        case 'en':
          return orderDetail.speciesNameEn
        default:
          return orderDetail.speciesNameEn
      }
    }
    return ''
  }

  redirectToDocumentation(importOrderId: number) {
    this._router.navigate([Routes.forwarderImportOrderEdit, importOrderId])
  }

  redirectToDetail(orderId: string) {
    this._router.navigate([Routes.forwarderImportOrderDetail, orderId])
  }

  onSelect(event: any, uploader: any){
    this.uploadFile = uploader.files
  }

  onRemove(event: any, uploader: any) {
    this.uploadFile = uploader.files
  }

  onClear(event: any, uploader: any) {
    this.uploadFile = uploader.files
  }

  sendFiles() {
    this.loading = true
    if (this.uploadFile[0]) {
      const request: any = {
        file: this.uploadFile[0]
      }

      this._forwarderGatewayService.uploadLoadFile(request).subscribe({
        next: (v) => {
          console.log(v)
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: this.translate.instant('MessageService.ProgramRequest.CreateSuccess') })
            this.loading = false
            this.getImportOrders()
            this.visibleUploadExcel = false
        },
        error: (e) => {
          this.loading = false
          console.log(e)
          let errorMessage = ''
            const lang = this.translate.currentLang
            switch(lang) {
              case 'es':
                errorMessage = e.error.messageEs
                break
              case 'en':
                errorMessage = e.error.messageEn
                break
              default:
                errorMessage = this.translate.instant('MessageService.ProgramRequest.CreateError')
            }
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: errorMessage })
        },
        complete: () => console.info('Program request created successfully')
      })
    }else{

      this._messageService.add({ severity: 'error',
        summary: this.translate.instant('MessageService.Summary.FailedAction'),
        detail: this.translate.instant('MessageService.FailFileAction')
       })
      this.loading = false
    }
  }

  getFirstPalletsConfirmed(selected: ForwarderOrderListItem) {
    return selected.details?.length > 0 ? selected.details[0].palletsQuantity : 0
  }

  saveUserJoinRequest() {
    this.loading = true
    this.submit = true
    if(this.userJoinRequestFormGroup.valid) {
      let request: UserJoinRequest = {
        hostUsername: this._authService.getUsername(),
        guestEmail: this.userJoinRequestFormGroup.controls['guestEmail'].value.toString().trim(),
        isForwarderRequest: true
      }

      this._userJoinRequestService.create(request).subscribe({
        next: (v) => {
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: this.translate.instant('MessageService.UserJoinRequest.CreateSuccess') });
          this.submit = false
          this.loading = false
          this.visibleInvite = false
        },
        error: (e) => {
          this.loading = false

          const responseError = ErrorMessageUtil.getResponseError(e.error, this.profile?.lang);
          let errorDetail = responseError ? responseError : this.translate.instant('MessageService.UserJoinRequest.CreateError')

          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail:  errorDetail});
        },
        complete: () => console.info('User Join Request created successfully')
      })
    }
    else {
      this.loading = false
    }
  }

  getErrorMessage(fieldName: string): string {
    if (this.userJoinRequestFormGroup.controls[fieldName].hasError('required')) {
      return this.translate.instant('FormErrorMessage.FieldValueRequired')
    }
    if (this.userJoinRequestFormGroup.controls[fieldName].hasError('email')) {
      return this.translate.instant('FormErrorMessage.EmailFieldEmailFormatError')
    }
    return ''
  }

  openDocuments(guid: any) {
    this.orderForwarderSelected = guid
    this.loadDocuments()
    this.visibleUploadDocuments = true
  }

  loadExportDocumentType() {
    this.operationDocumentTypes =  []
    this._operationDocumentTypeService.getAll().subscribe({
      next: (v) => {
        this.operationDocumentTypes = v.map((item) => {
          return {
            ...item,
            description: this.translate.currentLang === 'es' ? item.nameEs : item.name
          }
        })
        this.loading = false
      },
      error: (e) => {
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.ExportOfferDocument.ManyRetrieveError') });
        console.log(e)
        this.loading = false
      },
      complete: () =>  {
        this.loading = false
        console.info('Operation Document types query successfully')
      }
    })
  }

  onSelectDocumentType(event: any) {
    this.documentTypeId = event.value
  }

  onUploadDocumentPdf(fileUpload: any) {
    if (this.orderForwarderSelected && this.documentTypeId)
    {
      this.loading = true
      let request: UploadImportOrderDocumentRequest = {
        guid: this.orderForwarderSelected,
        document: fileUpload.files[0],
        documentType: 0,
        operationDocumentTypeId: this.documentTypeId
      }

      this._importOrderDocumentService.uploadImportOrderDocument(request).subscribe({
        next: (v) => {
          this.loadDocuments()
          this.documentTypeId = ''
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: this.translate.instant('ForwarderPage.Messages.SuccessUploadDocument') })
        },
        error: (e) => {
          console.log(e)
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('ForwarderPage.Messages.ErrorUploadDocument') })
        },
        complete: () =>  {
          this.loading = false
          console.info('Export Invoice uploaded successfully')
        }
      })
      fileUpload.clear()
    }
  }

  loadDocuments() {
    if (this.orderForwarderSelected)
    {
      this.loading = true
      this.documents = []
      this._importOrderDocumentService.getAllImportOrderDocuments(this.orderForwarderSelected, 0).subscribe({
        next: (v) => {
          console.log(v)
          this.documents = v
          this.loading = false
        },
        error: (e) => {
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('ForwarderPage.Messages.ErrorLoadDocument') });

          this.loading = false
          console.log(e)
        },
        complete: () =>  {
          this.loading = false
          console.info('Documents query successfully')
        }
      })
    }
  }

  getImportOrderDetailDocument(doc: any) {
    this._documentService.getImportOrderDetailDocument(doc.guid).subscribe((resultBlob: any) => {

      const contentType = resultBlob.headers.get('Content-Type');
      const blob = new Blob([resultBlob.body], { type: contentType });
      const downloadURL = window.URL.createObjectURL(blob);

      if (contentType === 'application/pdf') {
        window.open(downloadURL)
      } else {
        // Para otros tipos de archivos, forzar la descarga
        const anchor = window.document.createElement('a');
        anchor.href = downloadURL;
        anchor.download = doc.name;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      }

      window.URL.revokeObjectURL(downloadURL);
    })
  }

  deleteDocument (doc: any){
    this._confirmationService.confirm({
      message: this.translate.instant('ForwarderPage.ForwarderUserOriginReview.DialogDocument.Title'),
      header: this.translate.instant('ForwarderPage.ForwarderUserOriginReview.DialogDocument.Message'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loading = true
        this._importOrderDocumentService.deleteImportOrderDocument(doc.guid).subscribe(
          {
            next: () => {
              this._messageService.add({ severity: 'success', summary: this.translate.instant('MessageService.Summary.SuccessAction'), detail: this.translate.instant('ForwarderPage.Messages.SuccessDeleteDocument') });
              this.loadDocuments()
            },
            error: (e) => {
              console.log(e)
              this._messageService.add({ severity: 'error', summary: this.translate.instant('MessageService.Summary.FailedAction'), detail: this.translate.instant('ForwarderPage.Messages.ErrorDeleteDocument') });
              this.loading = false
            },
            complete: () => {
              this.loading = false
            }
          }
        )
      },
      reject: (type: any) => {}
    })
  }

  openAssignImporterExporter(order: ForwarderOrderListItem) {
    this.orderForwarderSelected = order
    this.visibleRelations = true
    this.importerSelected = undefined
    this.exporterSelected = undefined
    if (order.importer.id > 0) this.importerSelected = order.importer.id
    this.exporterSelected = order.exporter?.id
  }

  setImporter(event: any) {
    console.log(event.value)
    this.importerSelected = event.value
  }

  setExporter(event: any) {
    console.log(event.value)
    this.exporterSelected = event.value
  }

  assignImporterExporter() {
    this._confirmationService.confirm({
      message: 'Desea asignar esta información a la oferta?',
      header: 'Asignación de importador y exportador',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loading = true
        var request: AssignExporterImporter = {importOrderGuid: this.orderForwarderSelected.id }
        if (this.importerSelected) request.importerId = this.importerSelected
        if (this.exporterSelected) request.exporterId = this.exporterSelected

        this._forwarderGatewayService.assignExporterImporter(request).subscribe(
          {
            next: () => {
              this._messageService.add({ severity: 'success', summary: this.translate.instant('MessageService.Summary.SuccessAction'), detail: 'Información asignada' });
              this.getImportOrders()
              this.visibleRelations = false
              this.importerSelected = undefined
              this.exporterSelected = undefined
            },
            error: (e) => {
              console.log(e)
              this._messageService.add({ severity: 'error', summary: this.translate.instant('MessageService.Summary.FailedAction'), detail: 'Error' });
              this.loading = false
            },
            complete: () => {
              this.loading = false
            }
          }
        )
      },
      reject: (type: any) => {}
    })
  }

}
