import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Port } from "src/app/domain/models/Port";

@Injectable({providedIn: 'root'})
export abstract class PortGateway {
  abstract getAll(): Observable<Port[]>
  abstract getOriginPorts(): Observable<Port[]>
  abstract getDestinationPortsByCountry(countryId: number): Observable<Port[]>
}
