import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Issue } from "src/app/domain/models/Issue";
import { Routes } from "src/app/infrastructure/routes";
import { IssueGateway } from "../gateway/IssueGateway";

@Injectable({providedIn: 'root'})
export class IssueService extends IssueGateway {

  constructor(private _http: HttpClient) {
    super()
  }

  create(issue: Issue): Observable<Issue> {
    return this._http.post<Issue>(Routes.issueApi + '/Create', issue)
  }

}
