import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { DocumentServiceGateway } from 'src/app/application/gateway/DocumentServiceGateway';
import { Delivery } from 'src/app/domain/models/Delivery';
import { DeliveryDiscount } from 'src/app/domain/models/DeliveryDiscount';
import { GenericOrderDetail, ImportOrder } from 'src/app/domain/models/ImportOrder';
import { Payment } from 'src/app/domain/models/Payment';
import { PaymentQualityControl } from 'src/app/domain/models/PaymentQualityControl';
import { BasicUser } from 'src/app/domain/models/User';
import { Routes } from 'src/app/infrastructure/routes';


@Component({
  selector: 'app-import-order-payments',
  templateUrl: './import-order-payments.component.html',
  styleUrls: ['./import-order-payments.component.css']
})
export class ImportOrderPaymentsComponent implements OnInit {
  @Input()
  payments: Payment[] = []

  @Input()
  discounts: DeliveryDiscount[] = []

  @Input()
  delivery: Delivery | undefined

  @Input()
  importOrderDetails: GenericOrderDetail[] = []

  @Input()
  importOrder: ImportOrder | undefined

  profile: BasicUser | undefined

  loading: boolean = false

  @Input()
  paymentsQualityControl: PaymentQualityControl[] =[]

  @Input()
  isClosedSale: boolean = false

  @Input()
  directSaleProgramId: number | undefined

  constructor(
    private _router: Router,
    private _documentService: DocumentServiceGateway,
    private _authService: AuthGateway
    ){

  }

  ngOnInit(): void {
    this.profile = this._authService.getUser()
  }

  goToPayments() {
    if(this.importOrder) {
      if(this.isClosedSale) {
        this._router.navigate(['/' + Routes.userClosedSalePaymentsPage, this.importOrder.guid, this.directSaleProgramId])
      }
      else
      {
        this._router.navigate(['/' + Routes.userPaymentsPage, this.importOrder.guid])
      }
    }
  }

  getOrderTotalPrice(): number {
    let total = 0
    if(this.importOrderDetails) {
      this.importOrderDetails.forEach(x => {
        total += this.getDetailTotalPrice(x)
      })
    }
    return total
  }

  getPaymentDocument(paymentId: number) {
    this._documentService.getPaymentDocument(paymentId).subscribe((resultBlob: any) => {
      var downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }


  getDetailTotalPrice(detail: GenericOrderDetail): number {
    return detail.palletsQuantity > 0 && detail.boxesPerPallet > 0 ? detail.palletsQuantity * detail.boxesPerPallet * detail.price : detail.boxesQuantity * detail.price
  }

  get qcReviewCost(): number {
    let total = 0
    if(this.importOrderDetails) {
      this.importOrderDetails.forEach(x => {
        let tax = x.qcTaxPrice ?  x.qcTaxPrice : 0
        total += x.qcGeneratedPrice ? x.qcGeneratedPrice + tax  : 0
      })
    }
    return total
  }

  getPalletPrice()
  {
    let total = 0

    if(this.importOrderDetails) {
      this.importOrderDetails.forEach(x => {
        total += this.getDetailPalletPrice(x)
      })
    }
    return total
  }

  getDetailPalletPrice(detail: GenericOrderDetail): number {
    let total = 0

    if(detail.palletPrices)
    {
      detail.palletPrices.forEach(x => {
        total += x.price ? x.boxPerPallets * x.price : 0;
      })
      return total
    }
    return 0
  }

  get totalCost(): number {

    let orderPrice = !this.isClosedSale ? this.getOrderTotalPrice() : this.getPalletPrice()
    if(this.delivery) {
      return orderPrice +
        (this.delivery.oceanFreight ? this.delivery.oceanFreight : 0) +
        (this.delivery.inland ? this.delivery.inland : 0) +
        (this.delivery.localCharges ? this.delivery.localCharges : 0) +
        (this.delivery.customs ? this.delivery.customs : 0) +
        (this.delivery.otherCosts ? this.delivery.otherCosts : 0) +
        (this.delivery.surcharge ? this.delivery.surcharge : 0) +
        (this.delivery.handling ? this.delivery.handling : 0) +
        (this.delivery.gateOut ? this.delivery.gateOut : 0) +
        this.qcReviewCost
    }
    return 0
  }

  get totalPayments(): number {
    let total = 0
    if(this.payments) {
      this.payments.forEach(x => {
        total += x.amount
      })
    }
    return total
  }

  get totalPaymentsQc(): number {
    let total = 0
    if(this.paymentsQualityControl){
      this.paymentsQualityControl.forEach(x => {
        total += x.value;
      })
    }
    return total
  }

  get balance(): number {
    return this.totalPayments - this.totalCost + this.totalDiscounts
  }

  get absoluteBalance(): number {
    return Math.abs(this.balance)
  }

  get totalDiscounts(): number {
    let total = 0
    if(this.discounts) {
      this.discounts.forEach(x => {
        total += x.amount
      })
    }
    return total
  }
}
