<app-user-dashboard [userMenuActiveItemIndex]="0">
  <p-breadcrumb class="max-w-full" [model]="breadcrumbMenuItems" [home]="breadcrumbHome"></p-breadcrumb>
  <div style="margin-top: 30px;">
    <div class="container" *ngIf="importOrderInfo">
      <section class='sectionLeft'>
        <h2 class="title">{{'QcOrderPaymentPage.title' | translate}}</h2>
        <h4 class="subtitle">{{'QcOrderPaymentPage.subtitle' | translate}}</h4>
        <section class='sectionDescription'>
          <span class="titleSection">{{'QcOrderPaymentPage.LabelContact' | translate}}:</span>
          <section class='rowShipment'>
            <section class="sectionInfoShipment">
              <section class='sectionData'>
                <span >{{'QcOrderPaymentPage.companyNameText' | translate}}:</span>
                <h3>{{profileData.companyName}}</h3>
              </section>
              <section class='sectionData'>
                <span >{{'QcOrderPaymentPage.contactNameText' | translate}}:</span>
                <h3>{{profileData.contactName}}</h3>
              </section>
              <section class='sectionData'>
                <span >{{'QcOrderPaymentPage.phoneNumberText' | translate}}:</span>
                <h3 *ngIf="importOrderInfo.importOrder.importFromCountry">{{profileData.contactPhone}}</h3>
              </section>
            </section>
            <section class='sectionDataBtn'>
              <button class="btnSecond" (click)="editContactInformationHandler()">
                {{'QcOrderPaymentPage.btnEditLabel' | translate}}
              </button>
            </section>
          </section>
          <span class="titleSection paddingTop">{{'QcOrderPaymentPage.LabelPayment' | translate}}:</span>
          <section class='rowGray'>
            <!-- <label class="custom-checkbox disabled">
              <input type="checkbox" [(ngModel)]="transBankIsChecked" (change)="openModalTransBank()" disabled>
              <span class="checkmark"></span>
              TRANSBANK (Chile)
            </label> -->
            <label class="custom-checkbox">
              <input type="checkbox" [(ngModel)]="fruztyPayIsChecked" (change)="onChangeFruztyPay()" disabled>
              <span class="checkmark"></span>
              {{'QcOrderPaymentPage.bankTransferFruzty' | translate}}.
            </label>
            <span *ngIf="showWarning" class="warning-message">
              {{'QcOrderPaymentPage.messagePaymentInput' | translate}}.
            </span>
            <span *ngIf="!showWarning" class="warning-message-hidden">
              .
            </span>
          </section>

        <div *ngIf="fruztyPayIsChecked" style="margin-top: 3em; display:flex; background-color: #6da043; flex:1; border-radius:10px;">
          <div class="col-12 md:col-8 lg:col-8" style="padding: 20px;">
            <div style="color: white;padding-bottom:.5em;">
              <h3>{{'UserPages.UserPurchaseOrders.RegisterPaymentPage.BankDataText' | translate}}</h3>
              <p>{{'UserPages.UserPurchaseOrders.RegisterPaymentPage.DescriptionText' | translate}}:</p>
            </div>
            <div class="" style="border-radius: 20px; background-color: #527832; padding: 20px; color: white;">
              <div class="grid">
                <div class="col-6">
                  <h3 style="padding-bottom:.3em">BENEFICIARY COMPANY</h3>
                  <p>Name: <span style="font-weight: bolder;">Comercial Fruzty SpA</span></p>
                  <p>Address: <span style="font-weight: bolder;">Rosario norte 100 piso 301, Las Condes.</span></p>
                </div>
                <div class="col-6">
                  <h3 style="padding-bottom:.3em">BENEFICIARY BANK</h3>
                  <p>Name: <span style="font-weight: bolder;">Santander Chile</span></p>
                  <p>Address: <span style="font-weight: bolder;">Bandera #140, Santiago, Chile</span></p>
                  <p>Account: <span style="font-weight: bolder;">5103341428</span></p>
                  <p>Switf code: <span style="font-weight: bolder;">BSCHCLRMXXX</span></p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 md:col-4 lg:col-4 flex justify-content-end background-payment-image" style="margin: 0px; padding: 0px;">

          </div>
        </div>

        </section>
      </section>
      <section class='sectionRight'>
        <section class='columnPayment'>
          <h2 class="title">{{'QcOrderPaymentPage.titleDetail' | translate}}</h2>
          <section class='sectionFlexEnd'>
            <button class="btnSecond" (click)="navigateToOrderDetailPage()">
              {{'QcOrderPaymentPage.btnSecondLabel' | translate}}
            </button>
          </section>
          <section class="rowQcAlt">
            <section class='sectionDataLeft'>
              <span >{{'QcOrderPaymentPage.qcCompanyText' | translate}}:</span>
              <h3 >{{importOrderInfo.qualityControlCompany?.profile?.companyName}}</h3>
            </section>
            <section class='sectionDataRight'>
              <span >{{'QcOrderPaymentPage.qcCompanyValueText' | translate}}:</span>
              <h3  *ngIf="importOrderInfo.qcGeneratedPrice">$ {{formatNumber(importOrderInfo.qcGeneratedPrice)}}</h3>
            </section>
          </section>
          <div class="card">
            <section class="row spaceBotton">
              <p class="fontSpan">{{'QcOrderPaymentPage.qcPriceText' | translate}}</p>
              <p class="fontSpan" *ngIf="importOrderInfo.qcGeneratedPrice"><span>{{currency}} </span>$ {{formatNumber(importOrderInfo.qcGeneratedPrice)}}</p>
            </section>
            <section class="row">
              <p class="fontSubTotal">{{'QcOrderPaymentPage.subtotalPriceText' | translate}}</p>
              <p class="fontSubTotal" *ngIf="importOrderInfo.qcGeneratedPrice"><span>{{currency}} </span>$ {{formatNumber(importOrderInfo.qcGeneratedPrice)}}</p>
            </section>
            <section class="row">
              <p class="fontSpan">{{'QcOrderPaymentPage.taxesCostText' | translate}}</p>
              <p class="fontSpan"><span>{{currency}} </span>$ {{formatNumber(importOrderInfo.qcTaxPrice)}}</p>
            </section>
            <section class="row spaceTop spaceBotton">
              <p class="fontTotal">{{'QcOrderPaymentPage.totalPriceText' | translate}}</p>
              <p class="fontTotal" *ngIf="importOrderInfo.qcGeneratedPrice"><span>{{currency}} </span>$ {{formatNumber(importOrderInfo.qcGeneratedPrice + importOrderInfo.qcTaxPrice)}}</p>
            </section>
            <button class="btnMain"
              (click)="openCreatePaymentDialog()"
            >
              {{'QcOrderPaymentPage.btnMainLabel' | translate}}
            </button>
          </div>
        </section>
      </section>
    </div>
  </div>
  <div id="modalTransBank" class="modal">
    <div class="modal-content">
      <span class="close" (click)="closeModalTransBank()">&times;</span>
      <p>Este es el contenido del modal.</p>
    </div>
  </div>
  <div id="modalConfirmation" class="modal">
    <div class="modal-content">
      <span class="close" (click)="closeModalConfirmation()">X</span>
      <h2 class="title">{{'QcOrderPaymentPage.confirmationModalTitle' | translate}}</h2>
      <p>{{'QcOrderPaymentPage.confirmationModalSubtitle' | translate}}.</p>
      <div class="flexCenterContainer">
        <h2 class="titleLarge">{{'QcOrderPaymentPage.confirmationModalMainTitle' | translate}}</h2>
        <img src="../../../../../../assets/img/qc_confirmed.png" alt="palletIcon">
        <div class="spaceBotton"></div>
        <button class="btnMain spaceTop" (click)="navigateToOrderDetailPage()">{{'QcOrderPaymentPage.confirmationModalBtnAccept' | translate}}
        </button>
        <div class="spaceBotton"></div>
      </div>
    </div>
  </div>

  <div id="modalEditContactInformation" class="modal">
    <div class="modal-content-sm">
      <span class="close" (click)="closeModalEditContactInformation()" onKeyPress="">X</span>
      <h2 class="title">{{'QcOrderPaymentPage.editContactInformationModalTitle' | translate}}</h2>
      <div class="field mt-6">
        <label for="companyName">{{'QcOrderPaymentPage.companyNameText' | translate}}</label>
        <input id="companyName" type="text" pInputText [(ngModel)]="profileData.companyName" class="w-full"/>
      </div>
      <div class="field">
        <label for="contactName">{{'QcOrderPaymentPage.contactNameText' | translate}}</label>
        <input id="contactName" type="text" pInputText [(ngModel)]="profileData.contactName" class="w-full"/>
      </div>
      <div class="field">
        <label for="contactPhone">{{'QcOrderPaymentPage.phoneNumberText' | translate}}</label>
        <input id="contactPhone" pInputText [(ngModel)]="profileData.contactPhone" class="w-full"/>
      </div>
      <div class="spaceBotton"></div>
      <div class="flexCenterContainer">
        <button class="btnMain spaceTop w-full" (click)="closeModalEditContactInformation()">
          {{'QcOrderPaymentPage.editContactInformationModalBtnSave' | translate}}
        </button>
        <div class="spaceBotton"></div>
      </div>
    </div>
  </div>

  <p-dialog #createDialog header="{{'UserPages.UserPurchaseOrders.RegisterPaymentPage.RegiserPaymentDialog.DialogHeader' | translate}}" [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleCreatePaymentDialog" [style]="{width: '50%'}">
    <form [formGroup]="formGroup">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-6 lg:col-6">
          <label for="swiftId">Swift No</label>
          <input id="swiftId" formControlName="swiftId" type="text" pInputText/>
          <small *ngIf="submit && formGroup.controls['swiftId'].invalid" class="p-error">{{getErrorMessage('swiftId')}}</small>
        </div>
        <div class="field col-12 md:col-6 lg:col-6">
          <label for="amount">{{'UserPages.UserPurchaseOrders.RegisterPaymentPage.RegiserPaymentDialog.ValueLabel' | translate}} (USD)</label>
          <p-inputNumber id="amount" formControlName="amount" [min]="0" mode="currency" currency="USD" locale="en-US"/>
          <small *ngIf="submit && formGroup.controls['amount'].invalid" class="p-error">{{getErrorMessage('amount')}}</small>
        </div>
        <div class="field col-12">
          <label for="description">{{'UserPages.UserPurchaseOrders.RegisterPaymentPage.RegiserPaymentDialog.DescriptionLabel' | translate}}</label>
          <input id="description" formControlName="description" type="text" pInputText/>
        </div>
        <div class="field col-12">
          <p-fileUpload
          (onSelect)="onSelect($event)"
          [showUploadButton]="false"
          [multiple]="false"
          accept=".pdf,image/*"
          [maxFileSize]="1000000"
          chooseLabel="{{'UserPages.UserPurchaseOrders.RegisterPaymentPage.RegiserPaymentDialog.SelectImageButton' | translate}}"
          cancelLabel="{{'UserPages.UserPurchaseOrders.RegisterPaymentPage.RegiserPaymentDialog.CancelImagesButton' | translate}}"/>
        </div>
      </div>
    </form>
    <div>
      <p style="font-weight: bold; font-size: 12px;">{{'UserPages.UserPurchaseOrders.RegisterPaymentPage.RegiserPaymentDialog.WarningMessage' | translate}}</p>
    </div>
    <ng-template pTemplate="footer">
      <p-button label="{{'UserPages.UserPurchaseOrders.RegisterPaymentPage.RegiserPaymentDialog.CloseModalButton' | translate}}" [loading]="loading" (onClick)="visibleCreatePaymentDialog = false" styleClass="p-button-outlined"></p-button>
      <p-button label="{{'UserPages.UserPurchaseOrders.RegisterPaymentPage.RegiserPaymentDialog.RegisterButton' | translate}}" [loading]="loading" (onClick)="createPayment()"></p-button>
    </ng-template>
  </p-dialog>

</app-user-dashboard>
<p-blockUI [blocked]="loading"></p-blockUI>

<p-toast></p-toast>
<p-confirmDialog></p-confirmDialog>
