import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './ui/components/components.module';
import { PagesModule } from './ui/pages/pages.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RequestInterceptor } from './infrastructure/events/RequestInterceptor';
import { ResponseInterceptor } from './infrastructure/events/ResponseInterceptor';
import { SecurityApiService } from './application/service/SecurityApiService';
import { SecurityGateway } from './application/gateway/SecurityGateway';
import { AuthGateway } from './application/gateway/AuthGateway';
import { AuthApiService } from './application/service/AuthService';
import { ProfileGateway } from './application/gateway/ProfileGateway';
import { ProfileService } from './application/service/ProfileService';
import { CountryGateway } from './application/gateway/CountryGateway';
import { CountryService } from './application/service/CountryService';
import { SpeciesGateway } from './application/gateway/SpeciesGateway';
import { SpeciesService } from './application/service/SpeciesService';
import { FridgeStorageGateway } from './application/gateway/FridgeStorageGateway';
import { FridgeStorageService } from './application/service/FridgeStorageService';
import { ConsigneeGateway } from './application/gateway/ConsigneeGateway';
import { ConsigneeService } from './application/service/ConsigneeService';
import { NotifierGateway } from './application/gateway/NotifierGateway';
import { NotifierService } from './application/service/NotifierService';
import { UserGateway } from './application/gateway/UserGateway';
import { UserService } from './application/service/UserService';
import { ProductCertificateGateway } from './application/gateway/ProductCertificateGateway';
import { ProductCertificateService } from './application/service/ProductCertificateService';
import { ExportOfferGateway } from './application/gateway/ExportOfferGateway';
import { ExportOfferService } from './application/service/ExportOfferService';
import { VarietyGateway } from './application/gateway/VarietyGateway';
import { VarietyService } from './application/service/VarietyService';
import { ProductCertificateTypeGateway } from './application/gateway/ProductCertificateTypeGateway';
import { ProductCertificateTypeService } from './application/service/ProductCertificateTypeService';
import { ImportOrderGateway } from './application/gateway/ImportOrderGateway';
import { ImportOrderService } from './application/service/ImportOrderService';
import { PortGateway } from './application/gateway/PortGateway';
import { PortService } from './application/service/PortService';
import { OfferNegotiationGateway } from './application/gateway/OfferNegotiationGateway';
import { OfferNegotiationService } from './application/service/OfferNegotiationService';
import { PalletGateway } from './application/gateway/PalletGateway';
import { PalletService } from './application/service/PalletService';
import { EstimationGateway } from './application/gateway/EstimationGateway';
import { EstimationService } from './application/service/EstimationService';
import { SuggestionGateway } from './application/gateway/SuggestionGateway';
import { SuggestionService } from './application/service/SuggestionService';
import { NotificationGateway } from './application/gateway/NotificationGateway';
import { NotificationService } from './application/service/NotificationService';
import { ExportOfferSuggestionGateway } from './application/gateway/ExportOfferSuggestionGateway';
import { ExportOfferSuggestionService } from './application/service/ExportOfferSuggestionService';
import { DeliveryGateway } from './application/gateway/DeliveryGateway';
import { DeliveryService } from './application/service/DeliveryService';
import { PaymentGateway } from './application/gateway/PaymentGateway';
import { PaymentService } from './application/service/PaymentService';
import { OperationGateway } from './application/gateway/OperationGateWay';
import { OperationService } from './application/service/OperationService';
import { ImportRequestGateway } from './application/gateway/ImportRequestGateway';
import { ImportRequestService } from './application/service/ImportRequestService';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { UserJoinRequestGateway } from './application/gateway/UserJoinRequestGateway';
import { UserJoinRequestService } from './application/service/UserJoinRequestService';
import { QualityControlCompanyGateway } from './application/gateway/QualityControlCompanyGateway';
import { QualityControlCompanyService } from './application/service/QualityControlCompanyService';
import { ExportOfferDocumentGateway } from './application/gateway/ExportOfferDocumentGateway';
import { ExportOfferDocumentService } from './application/service/ExportOfferDocumentService';
import { DocumentServiceGateway } from './application/gateway/DocumentServiceGateway';
import { DocumentService } from './application/service/DocumentService';
import { ImportOrderDetailGateway } from './application/gateway/ImportOrderDetailGateway';
import { ImportOrderDetailService } from './application/service/ImportOrderDetailService';
import { DeliveryDiscountGateway } from './application/gateway/DeliveryDiscountGateway';
import { DeliveryDiscountService } from './application/service/DeliveryDiscountService';
import { FrozenFruitExportOfferGateway } from './application/gateway/FrozenFruitExportOfferGateway';
import { FrozenFruitExportOfferService } from './application/service/FrozenFruitExportOfferService';
import { FrozenFruitPalletService } from './application/service/FrozenFruitPalletService';
import { FrozenFruitPalletGateway } from './application/gateway/FrozenFruitPalletGateway';
import { GenericOrderDetailGateway } from './application/gateway/GenericOrderDetailGateway';
import { GenericOrderDetailService } from './application/service/GenericOrderDetailService';
import { InvitationCodeGateway } from './application/gateway/InvitationCodeGateway';
import { InvitationCodeService } from './application/service/InvitationCodeService';
import { PackagingGateway } from './application/gateway/PackagingGateway';
import { PackagingService } from './application/service/PackagingService';
import { QualityControlReviewService } from './application/service/QualityControlReviewService';
import { QualityControlReviewGateway } from './application/gateway/QualityControlReviewGateway';
import { DirectSaleProgramGateway } from './application/gateway/DirectSaleProgramGateway';
import { DirectSaleProgramService } from './application/service/DirectSaleProgramService';
import { ExportInvoiceGateway } from './application/gateway/ExportInvoiceGateway';
import { ExportInvoiceService } from './application/service/ExportInvoiceService';
import { OperationDocumentTypeGateway } from './application/gateway/OperationDocumentTypeGateway';
import { OperationDocumentTypeService } from './application/service/OperationDocumentTypeService';
import { ForwarderService } from './application/service/ForwarderService';
import { ForwarderGateway } from './application/gateway/ForwarderGateway';
import { CompanyService } from './application/service/CompanyService';
import { CompanyGateway } from './application/gateway/CompanyGateway';
import { ProgramRequestGateway } from './application/gateway/ProgramRequestGateway';
import { ProgramRequestService } from './application/service/ProgramRequestService';
import { ImportOrderDocumentGateway } from './application/gateway/ImportOrderDocumentGateway';
import { ImportOrderDocumentService } from './application/service/ImportOrderDocumentService';
import { CustomAgentService } from './application/service/CustomAgentService';
import { CustomAgentGateway } from './application/gateway/CustomAgentGateway';
import { PaymentQualityControlGateway } from './application/gateway/PaymentQualityControlGateway';
import { PaymentQualityControlService } from './application/service/PaymentQualityControlService';
import { AirportGateway } from './application/gateway/AirportGateway';
import { AirportService } from './application/service/AirService';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    ComponentsModule,
    PagesModule,
    NoopAnimationsModule
  ],
  providers: [
    {provide: AuthGateway, useClass: AuthApiService},
    {provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi:true},
    {provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true},
    {provide: ProfileGateway, useClass: ProfileService},
    {provide: CountryGateway, useClass: CountryService},
    {provide: SpeciesGateway, useClass: SpeciesService},
    {provide: FridgeStorageGateway, useClass: FridgeStorageService},
    {provide: ConsigneeGateway, useClass: ConsigneeService},
    {provide: NotifierGateway, useClass: NotifierService},
    {provide: ProductCertificateGateway, useClass: ProductCertificateService},
    {provide: ExportOfferGateway, useClass: ExportOfferService},
    {provide: VarietyGateway, useClass: VarietyService},
    {provide: ProductCertificateTypeGateway, useClass: ProductCertificateTypeService},
    {provide: ImportOrderGateway, useClass: ImportOrderService},
    {provide: PalletGateway, useClass: PalletService},
    {provide: EstimationGateway, useClass: EstimationService},
    {provide: PortGateway, useClass: PortService},
    {provide: OfferNegotiationGateway, useClass: OfferNegotiationService},
    {provide: ExportOfferSuggestionGateway, useClass: ExportOfferSuggestionService},
    {provide: NotificationGateway, useClass: NotificationService},
    {provide: PaymentGateway, useClass: PaymentService},
    {provide: SuggestionGateway, useClass: SuggestionService},
    {provide: DeliveryGateway, useClass: DeliveryService},
    {provide: SecurityGateway, useClass: SecurityApiService},
    {provide: UserGateway, useClass: UserService},
    {provide: OperationGateway, useClass: OperationService},
    {provide: ImportRequestGateway, useClass: ImportRequestService},
    {provide: UserJoinRequestGateway, useClass: UserJoinRequestService},
    {provide: QualityControlCompanyGateway, useClass: QualityControlCompanyService},
    {provide: ExportOfferDocumentGateway, useClass: ExportOfferDocumentService},
    {provide: DocumentServiceGateway, useClass: DocumentService},
    {provide: ImportOrderDetailGateway, useClass: ImportOrderDetailService},
    {provide: DeliveryDiscountGateway, useClass: DeliveryDiscountService},
    {provide: FrozenFruitExportOfferGateway, useClass: FrozenFruitExportOfferService},
    {provide: FrozenFruitPalletGateway, useClass: FrozenFruitPalletService},
    {provide: GenericOrderDetailGateway, useClass: GenericOrderDetailService},
    {provide: InvitationCodeGateway, useClass: InvitationCodeService},
    {provide: PackagingGateway, useClass: PackagingService},
    {provide: QualityControlReviewGateway, useClass: QualityControlReviewService},
    {provide: DirectSaleProgramGateway, useClass: DirectSaleProgramService},
    {provide: ExportInvoiceGateway, useClass: ExportInvoiceService},
    {provide: OperationDocumentTypeGateway, useClass: OperationDocumentTypeService},
    {provide: ForwarderGateway, useClass: ForwarderService},
    {provide: CompanyGateway, useClass: CompanyService},
    {provide: ProgramRequestGateway, useClass: ProgramRequestService},
    {provide: ImportOrderDocumentGateway, useClass: ImportOrderDocumentService},
    {provide: CustomAgentGateway, useClass: CustomAgentService},
    {provide: PaymentQualityControlGateway, useClass: PaymentQualityControlService},
    {provide: AirportGateway, useClass: AirportService}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
