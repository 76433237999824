import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CreateSuggestion, SuggestionInformation } from "src/app/domain/models/ExportOfferSuggestion";
import { Routes } from "src/app/infrastructure/routes";
import { SuggestionGateway } from "../gateway/SuggestionGateway";

@Injectable({providedIn: 'root'})
export class SuggestionService extends SuggestionGateway {

  constructor(private _http: HttpClient) {
    super()
  }

  create(request: CreateSuggestion): Observable<SuggestionInformation> {
    return this._http.post<any>(Routes.suggestionApi + '/Create', request)
  }

}
