<p-menu #menu [model]="offerMenuItems" [popup]="true"></p-menu>
<div>
  <p-dataView #dv [value]="offers"
    [rows]="5"
    [paginator]="true"
    [loading]="loading"
    emptyMessage="{{'FrozenFruitExportOffersPage.NotResultsMessages' | translate}}">
    <ng-template let-offer pTemplate="listItem">
      <div class="col-12" style="margin-bottom: 20px;">
        <p-card class="relative">
          <div class="absolute top-0 right-0" style="padding-top: 20px; padding-right: 10px;">
            <p-button icon="pi pi-ellipsis-v" (onClick)="setSelected(offer); menu.toggle($event)" styleClass="p-button-rounded" [style]="{'background-color': 'lightgrey'}"></p-button>
          </div>

          <div class="grid flex align-items-center">
            <div class="col-12 md:col-2 lg:col-2 flex justify-content-center align-items-center">
              <img [src]="getExportOfferPictureUrl(offer)" style="border-radius: 7px; width: 100%; max-width: 300px; height: auto;" alt="Product picture" *ngIf="getExportOfferPictureUrl(offer)"/>
              <span style="border-radius: 7px; padding: 5px; font-weight: bold;" *ngIf="!getExportOfferPictureUrl(offer)">{{'UserPages.UserExportOffers.NotAvailablePictureLabel' | translate}}</span>
            </div>
            <div class="col-12 md:col-7 lg:col-7 p-5">
              <div class="grid">
                <div class="col-12"><h2 style="text-transform: uppercase;">{{offer.speciesName + ' - ' + offer.varietyName}}</h2></div>
                <div class="col-12 md:col-6 lg:col-6">Id: {{offer.id}}</div>
                <div class="col-12 md:col-6 lg:col-6">{{'FrozenFruitExportOffersPage.PalletQuantityHeader' | translate}}: {{offer.palletQuantity}}</div>
                <div class="col-12 md:col-6 lg:col-6">{{'FrozenFruitExportOffersPage.HarvestDateHeader' | translate}}: {{offer.harvestDate | date: 'dd-MM-y'}}</div>
                <div class="col-12 md:col-6 lg:col-6">{{'FrozenFruitExportOffersPage.CreatedHeader' | translate}}: {{offer.created | date: 'dd-MM-yyyy'}}</div>
                <div class="col-12 md:col-6 lg:col-6">{{'FrozenFruitExportOffersPage.NetKilosHeader' | translate}}: {{offer.netKilos ? offer.netKilos + ' Kg.' : 'No disponible.'}}</div>
                <div class="col-12 md:col-6 lg:col-6">{{'FrozenFruitExportOffersPage.LastModifiedHeader' | translate}}: {{offer.lastModified ? (offer.lastModified | date: 'dd-MM-yyyy HH:mm') : (offer.created | date: 'dd-MM-yyyy')}}</div>
                <div class="col-12 md:col-6 lg:col-6">{{'FrozenFruitExportOffersPage.BoxTypeHeader' | translate}}: {{getBoxType(offer.boxType)}}</div>
                <div class="col-12 md:col-6 lg:col-6">{{'FrozenFruitExportOffersPage.QualityControlHeader' | translate}}: {{getIncludedLabel(offer.isQualityControl)}}</div>
                <div class="col-12 md:col-6 lg:col-6">{{'FrozenFruitExportOffersPage.FridgeStorageHeader' | translate}}: {{offer.fridgeStorageName}}</div>
              </div>
            </div>
            <div class="col-12 md:col-1 lg:col-1 flex justify-content-center align-items-center">
              <span style="font-size: 1.5rem; color: #8c2b87; font-weight: bold;">{{offer.price | currency: 'USD':'US$'}} {{offer.incoterms}}</span>
            </div>
            <div class="col-12 md:col-2 lg:col-2 flex justify-content-end align-items-center">
              <p-button label="{{'FrozenFruitExportOffersPage.SeeMoreButtonLabel' | translate}}" (onClick)="showOfferDetailDialog(offer.id)"/>
            </div>
          </div>
        </p-card>
      </div>
    </ng-template>
  </p-dataView>
</div>

<p-dialog [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleOfferDetailDialog" [style]="{width: '80%'}">
  <app-frozen-fruit-export-offer-detail [offer]="selected"></app-frozen-fruit-export-offer-detail>
  <ng-template pTemplate="footer">
    <p-button label="{{'UserPages.UserExportOffers.CloseModalButton' | translate}}" (onClick)="visibleOfferDetailDialog = false"></p-button>
  </ng-template>
</p-dialog>

<p-toast></p-toast>

<p-confirmDialog acceptLabel="" rejectLabel="" rejectButtonStyleClass="p-button-outlined" [style]="{width: '50vw'}"></p-confirmDialog>
