import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Packaging } from "src/app/domain/models/Packaging";
import { Routes } from "src/app/infrastructure/routes";
import { PackagingGateway } from "../gateway/PackagingGateway";

@Injectable({providedIn: 'root'})
export class PackagingService extends PackagingGateway {

  constructor(private _http: HttpClient) {
    super()
  }

  getAll(): Observable<Packaging[]> {
    return this._http.get<Packaging[]>(Routes.packagingApi + '/GetAll')
  }

}
