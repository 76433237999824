<app-user-dashboard [userMenuActiveItemIndex]="0" [activeItem]="'fridgestorages'">
  <p-card [style]="{'min-height':'200px'}">
    <ng-template pTemplate="header">
      <div class="flex justify-content-start flex-column align-items-start" style="padding: 15px;">
        <h2>{{'UserPages.UserFridgestorages.PageTitle' | translate}}</h2>
        <p>{{'UserPages.UserFridgestorages.PageDescriptionText' | translate}}</p>
      </div>
    </ng-template>
    <p-table
      [loading]="loading"
      [value]="fridgestorages"
      [paginator]="true"
      [rows]="10"
      [showCurrentPageReport]="true"
      [rowsPerPageOptions]="[10, 25, 50]"
      responsiveLayout="stack"
      [breakpoint]="'600px'">
      <ng-template pTemplate="caption">
        <div class="flex align-items-center justify-content-start">
          <p-button label="{{'UserPages.UserFridgestorages.NewButtonLabel' | translate}}" icon="pi pi-plus" class="mr-2" (click)="goToCreateForm()"/>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
          <tr>
            <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserFridgestorages.FridgestorageTable.StorageTypeHeader' | translate}}</div></th>
            <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserFridgestorages.FridgestorageTable.NameHeader' | translate}}</div></th>
            <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserFridgestorages.FridgestorageTable.ContactHeader' | translate}}</div></th>
            <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserFridgestorages.FridgestorageTable.EmailHeader' | translate}}</div></th>
            <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserFridgestorages.FridgestorageTable.CityHeader' | translate}}</div></th>
            <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserFridgestorages.FridgestorageTable.CountryHeader' | translate}}</div></th>
            <th></th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-fridgeStorage>
          <tr [pSelectableRow]="fridgeStorage">
            <td><div class="flex justify-content-center align-items-center">{{getStorageType(fridgeStorage.storageType)}}</div></td>
            <td><div class="flex justify-content-center align-items-center">{{fridgeStorage.name}}</div></td>
            <td><div class="flex justify-content-center align-items-center">{{fridgeStorage.contactName}}</div></td>
            <td><div class="flex justify-content-center align-items-center">{{fridgeStorage.contactEmail}}</div></td>
            <td><div class="flex justify-content-center align-items-center">{{fridgeStorage.contactCity}}</div></td>
            <td><div class="flex justify-content-center align-items-center">{{fridgeStorage.contactCountry ? (fridgeStorage.contactCountry | countryDescription) : ''}}</div></td>
            <td>
              <div class="flex justify-content-end align-items-center gap-2">
                <p-button label="{{'UserPages.UserFridgestorages.FridgestorageTable.EditButton' | translate}}" icon="pi pi-pencil" (click)="goToEditForm(fridgeStorage.id)" class="mr-2"/>
                <p-button label="{{'UserPages.UserFridgestorages.FridgestorageTable.DeleteButton' | translate}}" icon="pi pi-trash" (click)="onDelete(fridgeStorage.id)" class="mr-2"/>
              </div>
            </td>
          </tr>
      </ng-template>
    </p-table>
  </p-card>
</app-user-dashboard>

<p-toast></p-toast>
<p-confirmDialog acceptLabel="{{'UserPages.UserFridgestorages.FridgestorageTable.YesLabel' | translate}}" rejectLabel="{{'UserPages.UserFridgestorages.FridgestorageTable.NotLabel' | translate}}" rejectButtonStyleClass="p-button-outlined" [style]="{width: '50vw'}"></p-confirmDialog>
