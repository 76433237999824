import { Injectable } from "@angular/core";
import { Image } from "primeng/image";
import { Observable } from "rxjs";
import { Country } from "src/app/domain/models/Country";
import { DirectSaleProgram } from "src/app/domain/models/DirectSaleProgram";
import { AddOrderClosedSaleRequest, BasicExportOffer, ClosedSaleCreateRequest, DirectSaleCreateRequest, EditDirectSale, ExportCloseSaleFilter, ExportOffer, ExportOfferAddtionalInfo, ExportOfferCreateRequest, ExportOfferInformation, ExportOfferListItem, MarketplaceResult, NewExportOffer, OfferPicture, UpdatePicturesAndCertificateTypesRequest, ValidateDirectSale, ExportOfferAd } from "src/app/domain/models/ExportOffer";
import { EditBasicOffer } from "src/app/domain/models/ForwarderOrder";
import { ProductCertificateType } from "src/app/domain/models/ProductCertificateType";

@Injectable({providedIn: 'root'})
export abstract class ExportOfferGateway {
  abstract getAll(): Observable<ExportOfferInformation[]>
  abstract getAllForMarketplace(countryId?: number): Observable<MarketplaceResult>
  abstract getAllByUsername(): Observable<ExportOfferListItem[]>
  abstract create(offer: NewExportOffer): Observable<ExportOffer>
  abstract getById(exportOfferId: number): Observable<ExportOffer>
  abstract update(offer: ExportOffer): Observable<ExportOffer>
  abstract updateAdmin(offer: ExportOffer): Observable<ExportOffer>
  abstract duplicate(exportOfferId: number): Observable<ExportOffer>
  abstract desactive(importOrderId: number): Observable<any>
  abstract active(importOrderId: number): Observable<any>
  abstract getSuggestionList(importOrderDetailId: any, countryId: number, varietyId: number): Observable<ExportOfferInformation[]>
  abstract createClosedSale(request: ClosedSaleCreateRequest): Observable<ExportOffer>
  abstract getAllClosedSaleByExporter(exporterUsername: string): Observable<ExportOfferInformation[]>
  abstract addOrderClosedSale(request: AddOrderClosedSaleRequest): Observable<ExportOffer>
  abstract updatePicturesAndCertificateTypes(request: UpdatePicturesAndCertificateTypesRequest): Observable<any>
  abstract getAllPicturesById(id: number): Observable<OfferPicture[]>
  abstract createBasicExportOffer(request: ExportOfferCreateRequest): Observable<number>
  abstract getProductCertificateTypesByExportOfferId(exportOfferId: number): Observable<ProductCertificateType[]>
  abstract updateBasicExportOffer(basicExportOffer: BasicExportOffer): Observable<any>
  abstract getBasicExportOffer(exportOfferId: any): Observable<BasicExportOffer>
  abstract getExportOfferAddtionalInfo(exportOfferId: any): Observable<ExportOfferAddtionalInfo>
  abstract updateExportOfferAdditionalInfo(exportOfferAdditionalInfo: ExportOfferAddtionalInfo): Observable<any>
  abstract createBasicDirectSale(request: DirectSaleCreateRequest): Observable<any>
  abstract getAllClosedSaleByExporterFilter(exportCloseSaleFilter: ExportCloseSaleFilter): Observable<ExportOfferInformation[]>
  abstract GetAllExportOfferToCloseSale(exporterUsername: string): Observable<ExportOfferInformation[]>
  abstract deleteOffer(exportOrderId: any): Observable<any>
  abstract editDirectProgramSale(request: EditDirectSale): Observable<DirectSaleProgram>
  abstract validateEditDirectProgramSale(request: ValidateDirectSale): Observable<null>
  abstract getAllForMarketPlaceAds(): Observable<ExportOfferAd[]>
  abstract getAdsMarketplaceResult(offerAdsCode: string ): Observable<MarketplaceResult>
  abstract getCountriesAvailable(): Observable<Country[]>
  abstract getAdsOfferDetail(adsCode: string ): Observable<ExportOfferAd>
  abstract getOfferDetail(adsCode: string ): Observable<ExportOfferAd[]>
  abstract getOfferAdsCodeQrUrl(code: string): Observable<any>
  abstract getOfferDetailMarketplace(exportOfferGuid: string): Observable<ExportOfferInformation>
  abstract getOfferMarketplaceCodeQrUrl(adsCode: string): Observable<any>
  abstract getOfferImportRequestCodeQrUrl(exportOfferGuid: string): Observable<any>
}
