import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { BasicUser } from 'src/app/domain/models/User';
import { Routes } from 'src/app/infrastructure/routes';

@Component({
  selector: 'app-frozen-fruit-export-offers-inactive',
  templateUrl: './frozen-fruit-export-offers-inactive.component.html',
  styleUrls: ['./frozen-fruit-export-offers-inactive.component.css']
})
export class FrozenFruitExportOffersInactiveComponent implements OnInit {

  profile: BasicUser | undefined

  tabMenuItems: MenuItem[] = []
  tabMenuActiveItem!: MenuItem

  constructor(private _router: Router, private _authService: AuthGateway,) {

  }

  ngOnInit(): void {
    this.profile = this._authService.getUser()

    if(this.profile) {
      let tabMenuItemActive = 'Active'
      let tabMenuItemInactive = 'Inactive'

      switch(this.profile.lang) {
        case 'en':
          tabMenuItemActive = 'Active'
          tabMenuItemInactive = 'Inactive'
          break
        case 'es':
          tabMenuItemActive = 'Activas'
          tabMenuItemInactive = 'Inactivas'
          break
      }

      this.tabMenuItems = [
        { label: tabMenuItemActive, command: () => { this.goToActiveExportOffers() } },
        { label: tabMenuItemInactive, command: () =>  {  } },
      ]
      this.tabMenuActiveItem = this.tabMenuItems[1]
    }
  }

  goToCreate() {
    this._router.navigate(['/' + Routes.userFrozenFruitExportOfferCreate])
  }

  goToActiveExportOffers() {
    this._router.navigate(['/' + Routes.userFrozenFruitExportOffersActivePage])
  }
}
