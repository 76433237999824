import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Company, EditCompany, NewCompany } from "src/app/domain/models/Company";

@Injectable({providedIn: 'root'})
export abstract class CompanyGateway {
  abstract getAll(): Observable<Company[]>
  abstract getAllByCompanyType(companyType: any): Observable<Company[]>
  abstract getById(id: number): Observable<Company>
  abstract create(company: NewCompany): Observable<Company>
  abstract update(company: EditCompany): Observable<Company>
  abstract delete(id: number): Observable<Company>
  abstract getUsersCompanyByProfile(): Observable<Company>
}
