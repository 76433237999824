import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { FrozenFruitExportOfferGateway } from 'src/app/application/gateway/FrozenFruitExportOfferGateway';
import { FrozenFruitExportOffer, FrozenFruitExportOfferDocuments, FrozenFruitExportOfferUploadDocumentsRequest } from 'src/app/domain/models/FrozenFruitExportOffer';
import { Routes } from 'src/app/infrastructure/routes';

@Component({
  selector: 'app-frozen-fruit-export-offer-edit-documents',
  templateUrl: './frozen-fruit-export-offer-edit-documents.component.html',
  styleUrls: ['./frozen-fruit-export-offer-edit-documents.component.css'],
  providers: [MessageService]
})
export class FrozenFruitExportOfferEditDocumentsComponent implements OnInit {

  frozenFruitExportOfferId: number | undefined
  exportOffer: FrozenFruitExportOfferDocuments | undefined

  loading: boolean = false

  pesticideAnalysisDocument: any
  heavyMetalsAnalysisDocument: any
  microbiologicalAnalysisDocument: any
  waterAnalysisDocument: any

  constructor(private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _messageService: MessageService,
    public translate: TranslateService,
    private _frozenFruitExportOfferService: FrozenFruitExportOfferGateway,) {
      this._activatedRoute.params.subscribe(params => {
        this.frozenFruitExportOfferId = params['frozenFruitExportOfferId']
      })
  }

  ngOnInit(): void {
    this.loadDocuments()
  }

  loadDocuments() {
    if(this.frozenFruitExportOfferId) {
      this.loading = true
      this._frozenFruitExportOfferService.getDocumentsById(this.frozenFruitExportOfferId).subscribe({
        next: (v) => {
          console.log(v)
          this.exportOffer = v
          this.loading = false
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOffer.SingleRetrieveError') })
        },
        complete: () => console.info('Frozen fruit export offer query successfully')
      })
    }
  }

  saveDocuments() {
    if(this.exportOffer) {
      const request: FrozenFruitExportOfferUploadDocumentsRequest = {
        frozenFruitExportOfferId: this.exportOffer.id,
        pesticideAnalysisDocument: this.pesticideAnalysisDocument,
        heavyMetalsAnalysisDocument: this.heavyMetalsAnalysisDocument,
        microbiologicalAnalysisDocument: this.microbiologicalAnalysisDocument,
        waterAnalysisDocument: this.waterAnalysisDocument
      }

      this.loading = true
      this._frozenFruitExportOfferService.uploadDocuments(request).subscribe({
        next: (v) => {
          this.loading = false
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: this.translate.instant('MessageService.ExportOffer.EditSuccess') })
          this.loadDocuments()
        },
        error: (e) => {
          this.loading = false
          console.log(e)
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOffer.EditError') })
        },
        complete: () => console.info('Pictures uploaded successfully.')
      })
    }
  }

  onSelectPesticideAnalysisDocument(event: any, uploader: any) {
    if(this.exportOffer) {
      const document = uploader.files[0]
      this.pesticideAnalysisDocument = uploader.files[0]
      this.exportOffer.pesticideAnalysisDocumentName = document.name
      this.exportOffer.pesticideAnalysisDocumentSize = document.size
      uploader.clear()
    }
  }

  onRemovePesticideAnalysisDocument(event: any) {
    if(this.exportOffer) {
      this.pesticideAnalysisDocument = null
      this.exportOffer.pesticideAnalysisDocumentName = null
      this.exportOffer.pesticideAnalysisDocumentSize = null
    }
  }

  onSelectHeavyMetalsAnalysisDocument(event: any, uploader: any) {
    if(this.exportOffer) {
      const document = uploader.files[0]
      this.heavyMetalsAnalysisDocument = document
      this.exportOffer.heavyMetalsAnalysisDocumentName = document.name
      this.exportOffer.heavyMetalsAnalysisDocumentSize = document.size
    }
  }

  onRemoveHeavyMetalsAnalysisDocument(event: any) {
    if(this.exportOffer) {
      this.heavyMetalsAnalysisDocument = null
      this.exportOffer.heavyMetalsAnalysisDocumentName = null
      this.exportOffer.heavyMetalsAnalysisDocumentSize = null
    }
  }

  onSelectMicrobiologicalAnalysisDocument(event: any, uploader: any) {
    if(this.exportOffer) {
      const document = uploader.files[0]
      this.microbiologicalAnalysisDocument = document
      this.exportOffer.microbiologicalAnalysisDocumentName = document.name
      this.exportOffer.microbiologicalAnalysisDocumentSize = document.size
    }
  }

  onRemoveMicrobiologicalAnalysisDocument(event: any) {
    if(this.exportOffer) {
      this.microbiologicalAnalysisDocument = null
      this.exportOffer.microbiologicalAnalysisDocumentName = null
      this.exportOffer.microbiologicalAnalysisDocumentSize = null
    }
  }

  onSelectWaterAnalysisDocument(event: any, uploader: any) {
    if(this.exportOffer) {
      const document = uploader.files[0]
      this.waterAnalysisDocument = document
      this.exportOffer.waterAnalysisDocumentName = document.name
      this.exportOffer.waterAnalysisDocumentSize = document.size
    }
  }

  onRemoveWaterAnalysisDocument(event: any) {
    if(this.exportOffer) {
      this.waterAnalysisDocument = null
      this.exportOffer.waterAnalysisDocumentName = null
      this.exportOffer.waterAnalysisDocumentSize = null
    }
  }

  goToFrozenFruitExportOffersPage() {
    this._router.navigate(['/' + Routes.userFrozenFruitExportOffersActivePage])
  }

  getFileSize(size?: number | null): number {
    return size ? Math.trunc(size / 1000) : 0
  }

  getPesticideAnalysisDocumentName() {
    return this.exportOffer?.pesticideAnalysisDocumentName ? this.exportOffer?.pesticideAnalysisDocumentName : null
  }

  getHeavyMetalsAnalysisDocumentName() {
    return this.exportOffer?.heavyMetalsAnalysisDocumentName ? this.exportOffer?.heavyMetalsAnalysisDocumentName : null
  }

  getMicrobiologicalAnalysisDocumentName() {
    return this.exportOffer?.microbiologicalAnalysisDocumentName ? this.exportOffer?.microbiologicalAnalysisDocumentName : null
  }

  getWaterAnalysisDocumentName() {
    return this.exportOffer?.waterAnalysisDocumentName ? this.exportOffer?.waterAnalysisDocumentName : null
  }
}
