import { Component } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
  selector: 'app-user-import-orders-confirmed',
  templateUrl: './user-import-orders-confirmed.component.html',
  styleUrls: ['./user-import-orders-confirmed.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class UserImportOrdersConfirmedComponent {
}
