<app-admin-dashboard>
  <p-breadcrumb class="max-w-full" [model]="breadcrumbMenuItems" [home]="breadcrumbHome"></p-breadcrumb>
  <p-card *ngIf="order">
    <h1><a [routerLink]="'/admin-manage-orders'"><i class="pi pi-chevron-left"></i></a> Detalles de la orden</h1>

    <p-table [value]="details">
      <ng-template pTemplate="caption">
        <div class="flex align-items-center justify-content-between">
          <h3>Código de pedido: {{getOrderTitle()}} / Fecha de pedido: {{order.importOrder.created | date:
            'dd-MM-yyyy'}}</h3>
          <div class="flex gap-2">
            <p-button icon="pi pi-upload" label="Loading data" pTooltip="Loading data" (onClick)="goToDelivery()" *ngIf="(order.importOrder.status===3 || order.importOrder.status===4)"></p-button>
            <p-button icon="pi pi-file-edit" label="Operation" pTooltip="Operación" (onClick)="goToOperation()" *ngIf="(order.importOrder.status===3 || order.importOrder.status===4)"></p-button>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th colspan="2">
            <div class="flex justify-content-center align-items-center">
              Producto
            </div>
          </th>
          <th>
            <div class="flex justify-content-center align-items-center">
              Frigorífico
            </div>
          </th>
          <th>
            <div class="flex justify-content-center align-items-center">
              Precio Unit. Caja
            </div>
          </th>
          <th>
            <div class="flex justify-content-center align-items-center">
              Cajas x Pallet
            </div>
          </th>
          <th>
            <div class="flex justify-content-center align-items-center">
              Pallets
            </div>
          </th>
          <th>
            <div class="flex justify-content-center align-items-center">
              Cajas totales
            </div>
          </th>
          <th>
            <div class="flex justify-content-center align-items-center">
              Precio total
            </div>
          </th>
          <th>
            <div class="flex justify-content-center align-items-center">
              Costo fruta
            </div>
          </th>
          <th>
            <div class="flex justify-content-center align-items-center">
              Exportador
            </div>
          </th>
          <th>
            <div class="flex justify-content-center align-items-center">
              Estado
            </div>
          </th>
          <th>
            <div class="flex justify-content-center align-items-center">
              Acciones
            </div>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-detail>
        <tr>
          <td>
            <img [src]="detail.varietyUrlIcon" style="border-radius: 7px; width: 100%; max-width: 100px; height: auto;" alt=""/>
          </td>
          <td>
            <div class="flex justify-content-start align-items-center">
              <div>
                <p>{{ detail.speciesNameEs }} / {{ detail.varietyName }}</p>
              </div>
            </div>
          </td>
          <td>
            <div class="flex justify-content-center align-items-center">
              {{ detail.fridgeStorageName + " " + detail.fridgeStorageCity + ', ' + (detail.fridgeStorageCountry | countryDescription) }}
            </div>
          </td>
          <td>
            <div class="flex justify-content-center align-items-center">
              {{ detail.price | currency:'USD':'US$' }}
            </div>
          </td>
          <td>
            <div class="flex justify-content-center align-items-center">
              {{ detail.boxesPerPallet > 0 ? detail.boxesPerPallet : 'N.A.' }}
            </div>
          </td>
          <td>
            <div class="flex justify-content-center align-items-center">
              {{ detail.boxesPerPallet > 0 ? detail.palletsQuantity : 'N.A.'}}
            </div>
          </td>
          <td>
            <div class="flex justify-content-center align-items-center">
              {{ getDetailTotalCases(detail) }}
            </div>
          </td>
          <td>
            <div class="flex justify-content-center align-items-center">
              <span style="font-weight: bold;">{{ getDetailTotalPrice(detail) | currency:'USD':'US$' }}</span>
            </div>
          </td>
          <td>
            <div class="flex justify-content-center align-items-center">
              <span style="font-weight: bold;">{{ getDetailPalletPrice(detail) | currency:'USD':'US$' }}</span>
            </div>
          </td>
          <td>
            <div class="flex justify-content-center align-items-center">
              {{ detail.exporterCompanyName }}
            </div>
          </td>
          <td>
            <div class="flex justify-content-center align-items-center">
              <p-tag *ngIf="detail.status == 1" value="Pend. Exportador" severity="warning" icon="pi pi-exclamation-triangle"></p-tag>
              <p-tag *ngIf="detail.status == 2" value="Pend. Admin" severity="warning" icon="pi pi-exclamation-triangle"></p-tag>
              <p-tag *ngIf="detail.status == 3" value="Verif. parcial" severity="success" icon="pi pi-check"></p-tag>
              <p-tag *ngIf="detail.status == 4" value="Verificado" severity="success" icon="pi pi-check"></p-tag>
            </div>
          </td>
          <td>
            <div class="flex justify-content-center align-items-center">
              <div class="flex justify-content-start gap-2">
                <p-button label="Verificar pallets" (onClick)="goToVerifyPallets(detail.id)" *ngIf="detail.status === 2"></p-button>
                <p-button label="Completar pallets" (onClick)="goToCompleteOrder(detail.id)" *ngIf="!order?.importOrder?.isFrozenFruit && detail.status == 3"></p-button>
                <p-button icon="pi pi-search" pTooltip="Ver oferta" (onClick)="viewExportOfferDetailDialog(detail)" styleClass="p-button-outlined"></p-button>
                <p-button icon="pi pi-table" pTooltip="Ver pallets" (onClick)="goToEditPallets(detail.id)" styleClass="p-button-outlined p-button-secondary" *ngIf="!order?.importOrder?.isFrozenFruit"></p-button>
                <p-button icon="pi pi-search-minus" pTooltip="Remover Pago QC" tooltipPosition="bottom" (onClick)="removeQcPayment(detail.id)" styleClass="p-button p-button-danger" *ngIf="detail.qcCompanyId && !detail.IsQcPaid"></p-button>
              </div>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="summary">
        <div class="flex align-items-center justify-content-between">
          Precio total de la orden: {{ getOrderTotalPrice() | currency:'USD':'US$' }}
        </div>
      </ng-template>
    </p-table>
    
    <div style="margin-top: 50px;" *ngIf="order?.importOrder?.status == 1">
      <p-card header="Pagos QC">
        <div class="grid">
          <div class="col-6">
            <p-table [value]="paymentsQc">
              <ng-template pTemplate="header">
                <tr>
                  <th>
                    <div class="flex justify-content-center align-items-center">Detalle de orden</div>
                  </th>
                  <th>
                    <div class="flex justify-content-center align-items-center">Monto</div>
                  </th>
                  <th>
                    <div class="flex justify-content-center align-items-center">Descripción</div>
                  </th>
                  <th>
                    <div class="flex justify-content-center align-items-center">Ver</div>
                  </th>
                  <th>
                    <div class="flex justify-content-center align-items-center">Aprobar</div>
                  </th>
                  <th>
                    <div class="flex justify-content-center align-items-center">Registrado</div>
                  </th>
                  <th></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-payment>
                <tr>
                  <td>
                    <div class="flex justify-content-center align-items-center">
                      {{ payment.importOrderDetailId}}
                    </div>
                  </td>
                  <td>
                    <div class="flex justify-content-center align-items-center">
                      {{ payment.value | currency:'USD':'US$' }}
                    </div>
                  </td>
                  <td>
                    <div class="flex justify-content-center align-items-center">
                      {{ payment.description }}
                    </div>
                  </td>
                  <td>
                    <div class="justify-content-center align-items-center">
                      <i *ngIf="payment.guid" (click)="downloadQcDocument(payment.guid)" pTooltip="Document PDF" class="pi pi-file-pdf" style="font-size: 2rem; cursor: pointer;margin-left: 10px;"></i>
                    </div>
                  </td>
                  <td>
                    <div class="flex justify-content-center align-items-center">
                      {{ payment.isApproved ? 'Si' : 'No' }}
                    </div>
                  </td>
                  <td>
                    <div class="flex justify-content-center align-items-center">
                      {{ payment.created | date: 'dd-MM-yyyy' }}
                    </div>
                  </td>
                  <td>
                    <div class="flex justify-content-center align-items-center">
                      <p-button label="Aprobar" (onClick)="approveQcPayment(payment.id)" [disabled]="payment.isApproved" [loading]="loading"></p-button>
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </p-card>
    </div>

    <div id="purshaseInfo" *ngIf="order?.importOrder?.status > 2" style="margin-top: 50px;">
      <div class="grid">
        <div class="col-6" >
          <div>
            <p-card header="Información de facturación">
              <h3>Importador</h3>
              <div class="grid">
                <div class="col-6">Nombre del Importador:</div>
                <div class="col-6">{{order.importOrder.importer.companyName}}</div>
                <div class="col-6">Nombre de contacto:</div>
                <div class="col-6">{{order.importOrder.importer.contactName}}</div>
                <div class="col-6">Email:</div>
                <div class="col-6">{{order.importOrder.importer.contactEmail}}</div>
                <div class="col-6">País:</div>
                <div class="col-6">{{order.importOrder.importer.contactCountry | countryDescription}}</div>
              </div>
              <p-divider></p-divider>
              <h3>Consignee</h3>
              <div class="grid">
                <div class="col-6">Nombre Consignee:</div>
                <div class="col-6">{{delivery?.consigneeCompanyName}}</div>
                <div class="col-6">Nombre de contacto:</div>
                <div class="col-6">{{delivery?.consigneeContactName}}</div>
                <div class="col-6">Dirección de contacto:</div>
                <div class="col-6">{{delivery?.consigneeContactAddress}}, {{delivery?.consigneeContactCity}}</div>
                <div class="col-6">País:</div>
                <div class="col-6">{{delivery?.consigneeContactCountry | countryDescription}}</div>
                <div class="col-6">Email:</div>
                <div class="col-6">{{delivery?.consigneeContactEmail}}</div>
              </div>
              <p-divider></p-divider>
              <h3>Notify</h3>
              <div class="grid">
                <div class="col-6">Nombre Notifier:</div>
                <div class="col-6">{{delivery?.notifierCompanyName}}</div>
                <div class="col-6">Nombre de contacto:</div>
                <div class="col-6">{{delivery?.notifierContactName}}</div>
                <div class="col-6">Dirección de contacto:</div>
                <div class="col-6">{{delivery?.notifierContactAddress}}, {{delivery?.notifierContactCity}}</div>
                <div class="col-6">País:</div>
                <div class="col-6">{{delivery?.notifierContactCountry | countryDescription}}</div>
                <div class="col-6">Email:</div>
                <div class="col-6">{{delivery?.notifierContactEmail}}</div>
              </div>
            </p-card>
          </div>

          <div style="margin-top: 30px;">
            <p-card header="Información del envío">
              <div class="grid">
                <div class="col-6">BL No:</div>
                <div class="col-6">{{delivery?.bl ? delivery?.bl : '(Post Loading information)' }}</div>
                <div class="col-6">Booking No:</div>
                <div class="col-6">{{delivery?.booking ? delivery?.booking : '(Post Loading information)' }}</div>
                <div class="col-6">Delivery instructions:</div>
                <div class="col-6">{{delivery?.deliveryInstructions ? delivery?.deliveryInstructions : '(Post Loading information)' }}</div>
                <div class="col-6">Freight payable at:</div>
                <div class="col-6">{{delivery?.freightPayableAt ? delivery?.freightPayableAt : '(Post Loading information)' }}</div>
                <div class="col-6">Shipped on board:</div>
                <div class="col-6">{{delivery?.shippedOnBoard ? delivery?.shippedOnBoard : '(Post Loading information)' }}</div>
                <div class="col-6">Place of issue:</div>
                <div class="col-6">{{delivery?.placeIssue ? delivery?.placeIssue : '(Post Loading information)' }}</div>
                <div class="col-6">Date of issue:</div>
                <div class="col-6">{{delivery?.dateIssue ? (delivery?.dateIssue | date: 'dd-MM-yyyy') : '(Post Loading information)' }}</div>
                <div class="col-6">Excess value declaration:</div>
                <div class="col-6">{{delivery?.excessValueDeclaration ? delivery?.excessValueDeclaration : '(Post Loading information)' }}</div>
                <div class="col-6">ETA:</div>
                <div class="col-6">{{delivery?.eta ? (delivery?.eta | date: 'dd-MM-yyyy')  : '(Post Loading information)' }}</div>
                <div class="col-6">ETD:</div>
                <div class="col-6">{{delivery?.etd ? (delivery?.etd | date: 'dd-MM-yyyy')  : '(Post Loading information)' }}</div>
              </div>
            </p-card>
          </div>

          <div>
            <p-card header="Información del Forwarder">
              <div class="grid" *ngIf="forwarder !== null">
                <div class="col-6">Forwarder:</div>
                <div class="col-6">{{forwarder?.companyName ? forwarder?.companyName : '(Post Loading information)' }}</div>
                <div class="col-6">Nombre del contacto:</div>
                <div class="col-6">{{forwarder?.contactName ? forwarder?.contactName : '(Post Loading information)' }}</div>
                <div class="col-6">Teléfono del contacto:</div>
                <div class="col-6">{{forwarder?.contactPhone ? forwarder?.contactPhone : '(Post Loading information)' }}</div>
                <div class="col-6">Email del contacto:</div>
                <div class="col-6">{{forwarder?.contactEmail ? forwarder?.contactEmail : '(Post Loading information)' }}</div>
                <div class="col-6">País:</div>
                <div class="col-6">{{forwarder?.country ? (forwarder?.country | countryDescription ) : '(Post Loading information)' }}</div>
              </div>
              <div class="grid" *ngIf="forwarder === null">
                <div class="col-8">
                  <p-dropdown id="forwarder"
                    [options]="companyForwarders"
                    [filter]="true"
                    optionValue="id"
                    optionLabel="companyName"
                    filterBy="companyName"
                    [showClear]="true"
                    placeholder="Seleccione un Forwarder"
                    [style]="{'minWidth':'100%'}"
                    (onChange)="onChangeCompanyForwarder($event)">
                </p-dropdown>
                </div>
                <div class="col-4">
                  <p-button label="Asignar Forwarder" (onClick)="assignForwarder()" [disabled]="!selectedCompanyForwarder" [loading]="loading"></p-button>
                </div>
              </div>
            </p-card>
          </div>

          <div>
            <p-card header="Información del Custom Agent">
              <div class="grid" *ngIf="customAgent !== null">
                <div class="col-6">Custom Agent:</div>
                <div class="col-6">{{customAgent?.companyName ? customAgent?.companyName : '(Post Loading information)' }}</div>
                <div class="col-6">Nombre del contacto:</div>
                <div class="col-6">{{customAgent?.contactName ? customAgent?.contactName : '(Post Loading information)' }}</div>
                <div class="col-6">Teléfono del contacto:</div>
                <div class="col-6">{{customAgent?.contactPhone ? customAgent?.contactPhone : '(Post Loading information)' }}</div>
                <div class="col-6">Email del contacto:</div>
                <div class="col-6">{{customAgent?.contactEmail ? customAgent?.contactEmail : '(Post Loading information)' }}</div>
                <div class="col-6">País:</div>
                <div class="col-6">{{customAgent?.country ? (customAgent?.country | countryDescription ) : '(Post Loading information)' }}</div>
              </div>
              <div class="grid" *ngIf="customAgent === null">
                <div class="col-8">
                  <p-dropdown id="customAgent"
                    [options]="companyCustomAgent"
                    [filter]="true"
                    optionValue="id"
                    optionLabel="companyName"
                    filterBy="companyName"
                    [showClear]="true"
                    placeholder="Seleccione un Custom Agent"
                    [style]="{'minWidth':'100%'}"
                    (onChange)="onChangeCompanyCustomAgent($event)">
                </p-dropdown>
                </div>
                <div class="col-4">
                  <p-button label="Asignar Custom Agent" (onClick)="assignCustomAgent()" [disabled]="!selectedCompanyCustomAgent" [loading]="loading"></p-button>
                </div>
              </div>
            </p-card>
          </div>
        </div>

        <div class="col-6">
          <div>
            <p-card header="Método de envío">
              <div class="grid">
                <div class="col-6">Place of receipt:</div>
                <div class="col-6">{{delivery?.placeReceipt ? delivery?.placeReceipt : '(As Booking info)' }}</div>
                <div class="col-6">Port of loading:</div>
                <div class="col-6">{{delivery?.departurePort ? delivery?.departurePort?.name : '(As Booking info)' }}
                </div>
                <div class="col-6">Port of discharge:</div>
                <div class="col-6">{{delivery?.destinationPort ? delivery?.destinationPort?.name : '(As Booking info)' }}
                </div>

                <div class="col-6">Airport of loading:</div>
                <div class="col-6">{{delivery?.departureAirport ? delivery?.departureAirport?.name : '(As Booking info)' }}</div>
                <div class="col-6">Airport of discharge:</div>
                <div class="col-6">{{delivery?.destinationAirport ? delivery?.destinationAirport?.name : '(As Booking info)' }}</div>

                <div class="col-6">Place of delivery:</div>
                <div class="col-6">{{delivery?.placeDelivery ? delivery?.placeDelivery : '(As Booking info)' }}</div>
                <div class="col-6">Vessel name/Flight Number:</div>
                <div class="col-6">{{delivery?.ship ? delivery?.ship : '(As Booking info)' }}</div>
                <div class="col-6">Voyage:</div>
                <div class="col-6">{{delivery?.voyageNumber ? delivery?.voyageNumber : '(As Booking info)' }}</div>
                <div class="col-6">Type of movement:</div>
                <div class="col-6">{{delivery?.typeMovement ? delivery?.typeMovement : '(As Booking info)' }}</div>
              </div>
            </p-card>
          </div>

          <div style="margin-top: 30px;">
            <p-card header="Pagos QC">
              <div class="grid">
                <div class="col-12">
                  <p-table [value]="paymentsQc">
                    <ng-template pTemplate="header">
                      <tr>
                        <th>
                          <div class="flex justify-content-center align-items-center">Detalle de orden</div>
                        </th>
                        <th>
                          <div class="flex justify-content-center align-items-center">Monto</div>
                        </th>
                        <th>
                          <div class="flex justify-content-center align-items-center">Descripción</div>
                        </th>
                        <th>
                          <div class="flex justify-content-center align-items-center">Ver</div>
                        </th>
                        <th>
                          <div class="flex justify-content-center align-items-center">Aprobar</div>
                        </th>
                        <th>
                          <div class="flex justify-content-center align-items-center">Registrado</div>
                        </th>
                        <th></th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-payment>
                      <tr>
                        <td>
                          <div class="flex justify-content-center align-items-center">
                            {{ payment.importOrderDetailId}}
                          </div>
                        </td>
                        <td>
                          <div class="flex justify-content-center align-items-center">
                            {{ payment.value | currency:'USD':'US$' }}
                          </div>
                        </td>
                        <td>
                          <div class="flex justify-content-center align-items-center">
                            {{ payment.description }}
                          </div>
                        </td>
                        <td>
                          <div class="justify-content-center align-items-center">
                            <i *ngIf="payment.guid" (click)="downloadQcDocument(payment.guid)" pTooltip="Document PDF" class="pi pi-file-pdf" style="font-size: 2rem; cursor: pointer;margin-left: 10px;"></i>
                          </div>
                        </td>
                        <td>
                          <div class="flex justify-content-center align-items-center">
                            {{ payment.isApproved ? 'Si' : 'No' }}
                          </div>
                        </td>
                        <td>
                          <div class="flex justify-content-center align-items-center">
                            {{ payment.created | date: 'dd-MM-yyyy' }}
                          </div>
                        </td>
                        <td>
                          <div class="flex justify-content-center align-items-center">
                            <p-button label="Aprobar" (onClick)="approveQcPayment(payment.id)" [disabled]="payment.isApproved" [loading]="loading"></p-button>
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
              </div>
            </p-card>
          </div>

          <div style="margin-top: 30px;">
            <p-card header="Pagos">
              <div class="grid">
                <div class="col-12">
                  <p-table [value]="payments">
                    <ng-template pTemplate="header">
                      <tr>
                        <th>
                          <div class="flex justify-content-center align-items-center">Monto</div>
                        </th>
                        <th>
                          <div class="flex justify-content-center align-items-center">Descripcion</div>
                        </th>
                        <th>
                          <div class="flex justify-content-center align-items-center">Ver</div>
                        </th>
                        <th>
                          <div class="flex justify-content-center align-items-center">Aprobar</div>
                        </th>
                        <th>
                          <div class="flex justify-content-center align-items-center">Registrado</div>
                        </th>
                        <th></th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-payment>
                      <tr>
                        <td>
                          <div class="flex justify-content-center align-items-center">
                            {{ payment.amount | currency:'USD':'US$' }}
                          </div>
                        </td>
                        <td>
                          <div class="flex justify-content-center align-items-center">
                            {{ payment.description }}
                          </div>
                        </td>
                        <td>
                          <div class="justify-content-center align-items-center">
                            <i *ngIf="payment.id" (click)="getPaymentDocument(payment.id)" pTooltip="Document PDF" class="pi pi-file-pdf" style="font-size: 2rem; cursor: pointer;"></i>
                          </div>
                        </td>
                        <td>
                          <div class="flex justify-content-center align-items-center">
                            {{ payment.isApproved ? 'Si' : 'No' }}
                          </div>
                        </td>
                        <td>
                          <div class="flex justify-content-center align-items-center">
                            {{ payment.created | date: 'dd-MM-yyyy' }}
                          </div>
                        </td>
                        <td>
                          <div class="flex justify-content-center align-items-center">
                            <p-button label="Aprobar" (onClick)="approvePayment(payment.id)" [disabled]="payment.isApproved" [loading]="loading"></p-button>
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
              </div>
            </p-card>
          </div>

          <div style="margin-top: 30px;">
            <p-card header="Balance">
              <div class="grid">
                <div class="col-12"><h3>Costs</h3></div>
                <div class="col-6 flex justify-content-end align-items-center">Subtotal:</div>
                <div class="col-6 flex justify-content-end align-items-center" style="color: red">
                  <span *ngIf="!isClosedSale">
                    {{ getOrderTotalPrice() | currency:'USD':'US$' }}
                  </span>
                  <span *ngIf="isClosedSale">
                    {{ getOrderTotalPalletPrice() | currency:'USD':'US$' }}
                  </span>
                </div>
                <div class="col-6 flex justify-content-end align-items-center">Ocean freight:</div>
                <div class="col-6 flex justify-content-end align-items-center" style="color: red">{{delivery?.oceanFreight | currency:'USD':'US$'}}</div>
                <div class="col-6 flex justify-content-end align-items-center">Pickup:</div>
                <div class="col-6 flex justify-content-end align-items-center" style="color: red">{{delivery?.inland | currency:'USD':'US$'}}</div>
                <div class="col-6 flex justify-content-end align-items-center">Local charges:</div>
                <div class="col-6 flex justify-content-end align-items-center" style="color: red">{{delivery?.localCharges | currency:'USD':'US$'}}</div>
                <div class="col-6 flex justify-content-end align-items-center">Customs:</div>
                <div class="col-6 flex justify-content-end align-items-center" style="color: red">{{delivery?.customs | currency:'USD':'US$'}}</div>
                <div class="col-6 flex justify-content-end align-items-center">Surcharge:</div>
                <div class="col-6 flex justify-content-end align-items-center" style="color: red">{{delivery?.surcharge | currency:'USD':'US$'}}</div>
                <div class="col-6 flex justify-content-end align-items-center">Handling:</div>
                <div class="col-6 flex justify-content-end align-items-center" style="color: red">{{delivery?.handling | currency:'USD':'US$'}}</div>
                <div class="col-6 flex justify-content-end align-items-center">Gate out:</div>
                <div class="col-6 flex justify-content-end align-items-center" style="color: red">{{delivery?.gateOut | currency:'USD':'US$'}}</div>
                <div class="col-6 flex justify-content-end align-items-center">Others costs:</div>
                <div class="col-6 flex justify-content-end align-items-center" style="color: red">{{delivery?.otherCosts | currency:'USD':'US$'}}</div>
                <div class="col-6 flex justify-content-end align-items-center">QC Origin Review:</div>
                <div class="col-6 flex justify-content-end align-items-center" style="color: red">{{qcReviewCost | currency:'USD':'US$'}}</div>
                <div class="col-6 flex justify-content-end align-items-center">Costo total:</div>
                <div class="col-6 flex justify-content-end align-items-center"><span style="font-weight: bold;">{{totalCost | currency:'USD':'US$'}}</span></div>
              </div>
              <p-divider></p-divider>
              <div class="grid">
                <div class="col-12"><h3>Payments</h3></div>
                <ng-container *ngFor="let payment of payments">
                  <div class="col-6 flex justify-content-end align-items-center">{{payment.description}}:</div>
                  <div class="col-6 flex justify-content-end align-items-center" style="color: green">{{payment.amount | currency:'USD':'US$'}}</div>
                </ng-container>
                <div class="col-6 flex justify-content-end align-items-center">Payments Origin QC:</div>
                <div class="col-6 flex justify-content-end align-items-center" style="color: green">{{totalQcPayments | currency:'USD':'US$'}}</div>
                <div class="col-6 flex justify-content-end align-items-center">Total payments:</div>
                <div class="col-6 flex justify-content-end align-items-center"><span style="font-weight: bold;">{{totalPayments | currency:'USD':'US$'}}</span></div>
              </div>
              <p-divider></p-divider>
              <div class="grid">
                <div class="col-12"><h3>Discounts</h3></div>
                <ng-container *ngFor="let discount of discounts">
                  <div class="col-6 flex justify-content-end align-items-center">{{discount.description}}:</div>
                  <div class="col-6 flex justify-content-end align-items-center" style="color: green">{{discount.amount | currency:'USD':'US$'}}</div>
                </ng-container>
                <div class="col-6 flex justify-content-end align-items-center">Total discounts:</div>
                <div class="col-6 flex justify-content-end align-items-center"><span style="font-weight: bold;">{{totalDiscounts | currency:'USD':'US$'}}</span></div>
              </div>
              <p-divider></p-divider>
              <div class="grid">
                <div class="col-6 flex justify-content-end align-items-center">Balance:</div>
                <div class="col-6 flex justify-content-end align-items-center"><span [style]="{'font-weight': 'bold', 'color': balance < 0 ? 'red' : 'green'}">{{ absoluteBalance | currency:'USD':'US$' }}</span></div>
              </div>
            </p-card>
          </div>
        </div>
      </div>
    </div>

    <div id="OperationInfo" style="margin-top: 50px;">
      <p-card header="Documentos">
        <div>
          <p-table
            [value]="order.operationDocuments"
            responsiveLayout="stack"
            [breakpoint]="'600px'">
            <ng-template pTemplate="caption">
              <h3>{{ 'DocumentComponent.OperationDocumentsTitle' | translate }}</h3>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th><div class="flex justify-content-center">{{ 'DocumentComponent.NameHeader' | translate }}</div></th>
                <th><div class="flex justify-content-center">{{ 'DocumentComponent.CreatedHeader' | translate }}</div></th>
                <th><div class="flex justify-content-center">{{ 'DocumentComponent.SizeHeader' | translate }}</div></th>
                <th><div class="flex justify-content-center">{{ 'DocumentComponent.ViewHeader' | translate }}</div></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-document>
              <tr>
                <td><div class="flex justify-content-center">{{ document.name }}</div></td>
                <td><div class="flex justify-content-center">{{ document.created | date:'dd-MM-yyyy'}}</div></td>
                <td><div class="flex justify-content-center">{{ document.size / 1000 }} KB</div></td>
                <td>
                  <div class="flex justify-content-center">
                    <p-button *ngIf="document.documentType === 5" (onClick)="getOperationDocument(document.id)" pTooltip="View PDF" icon="pi pi-file-pdf" styleClass="p-button-link p-button-lg" [disabled]="document.size < 1"></p-button>
                  </div>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="summary">
              <div class="flex align-items-center justify-content-between" *ngIf="order.operationDocuments.length < 1">
                {{ 'DocumentComponent.EmptyResult' | translate }}
              </div>
            </ng-template>
          </p-table>
        </div>
        <div style="margin-top: 40px;">
          <p-table
            [value]="order.exportOfferDocuments"
            responsiveLayout="stack"
            [breakpoint]="'600px'">
            <ng-template pTemplate="caption">
              <h3>{{ 'DocumentComponent.ExportOfferDocumentsTitle' | translate }}</h3>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th><div class="flex justify-content-center">{{ 'DocumentComponent.NameHeader' | translate }}</div></th>
                <th><div class="flex justify-content-center">{{ 'DocumentComponent.CreatedHeader' | translate }}</div></th>
                <th><div class="flex justify-content-center">{{ 'DocumentComponent.SizeHeader' | translate }}</div></th>
                <th><div class="flex justify-content-center">{{ 'DocumentComponent.ViewHeader' | translate }}</div></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-document>
              <tr>
                <td><div class="flex justify-content-center">{{ document.name }}</div></td>
                <td><div class="flex justify-content-center">{{ document.created | date:'dd-MM-yyyy'}}</div></td>
                <td><div class="flex justify-content-center">{{ document.size / 1000 }} KB</div></td>
                <td>
                  <div class="flex justify-content-center">
                    <p-button *ngIf="document.documentType === 7" (onClick)="getExportOfferDocument(document.id, order.importOrder.guid)" pTooltip="View PDF" icon="pi pi-file-pdf" styleClass="p-button-link p-button-lg" [disabled]="document.size < 1"></p-button>
                    <p-button *ngIf="document.documentType === 8" (onClick)="getFrozenFruitPesticideAnalysisDocument(document.id)" pTooltip="View PDF" icon="pi pi-file-pdf" styleClass="p-button-link p-button-lg" [disabled]="document.size < 1"></p-button>
                    <p-button *ngIf="document.documentType === 9" (onClick)="getFrozenFruitHeavyMetalsAnalysisDocument(document.id)" pTooltip="View PDF" icon="pi pi-file-pdf" styleClass="p-button-link p-button-lg" [disabled]="document.size < 1"></p-button>
                    <p-button *ngIf="document.documentType === 10" (onClick)="getFrozenFruitMicrobiologicalAnalysisDocument(document.id)" pTooltip="View PDF" icon="pi pi-file-pdf" styleClass="p-button-link p-button-lg" [disabled]="document.size < 1"></p-button>
                    <p-button *ngIf="document.documentType === 11" (onClick)="getFrozenFruitWaterAnalysisDocument(document.id)" pTooltip="View PDF" icon="pi pi-file-pdf" styleClass="p-button-link p-button-lg" [disabled]="document.size < 1"></p-button>
                  </div>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="summary">
              <div class="flex align-items-center justify-content-between" *ngIf="order.exportOfferDocuments.length < 1">
                {{ 'DocumentComponent.EmptyResult' | translate }}
              </div>
            </ng-template>
          </p-table>
        </div>
        <div style="margin-top: 40px;">
          <p-table
            [value]="order.productCertificateDocuments"
            responsiveLayout="stack"
            [breakpoint]="'600px'">
            <ng-template pTemplate="caption">
              <h3>{{ 'DocumentComponent.ProductCertificateDocumentsTitle' | translate }}</h3>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th><div class="flex justify-content-center">{{ 'DocumentComponent.NameHeader' | translate }}</div></th>
                <th><div class="flex justify-content-center">{{ 'DocumentComponent.CreatedHeader' | translate }}</div></th>
                <th><div class="flex justify-content-center">{{ 'DocumentComponent.SizeHeader' | translate }}</div></th>
                <th><div class="flex justify-content-center">{{ 'DocumentComponent.ViewHeader' | translate }}</div></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-document>
              <tr>
                <td><div class="flex justify-content-center">{{ document.name }}</div></td>
                <td><div class="flex justify-content-center">{{ document.created | date:'dd-MM-yyyy'}}</div></td>
                <td><div class="flex justify-content-center">{{ document.size / 1000 }} KB</div></td>
                <td>
                  <div class="flex justify-content-center">
                    <p-button *ngIf="document.documentType === 6" (onClick)="getProductCertificateDocument(document.id, order.importOrder.guid)" pTooltip="View PDF" icon="pi pi-file-pdf" styleClass="p-button-link p-button-lg" [disabled]="document.size < 1"></p-button>
                  </div>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="summary">
              <div class="flex align-items-center justify-content-between" *ngIf="order.productCertificateDocuments.length < 1">
                {{ 'DocumentComponent.EmptyResult' | translate }}
              </div>
            </ng-template>
          </p-table>
        </div>
        <div style="margin-top: 40px;">
          <p-table
            [value]="order.qualityControlDocuments"
            responsiveLayout="stack"
            [breakpoint]="'600px'">
            <ng-template pTemplate="caption">
              <h3>{{ 'DocumentComponent.QCDocumentsTitle' | translate }}</h3>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th><div class="flex justify-content-center">{{ 'DocumentComponent.NameHeader' | translate }}</div></th>
                <th><div class="flex justify-content-center">{{ 'DocumentComponent.CreatedHeader' | translate }}</div></th>
                <th><div class="flex justify-content-center">{{ 'DocumentComponent.SizeHeader' | translate }}</div></th>
                <th><div class="flex justify-content-center">{{ 'DocumentComponent.ViewHeader' | translate }}</div></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-document>
              <tr>
                <td><div class="flex justify-content-center">{{ document.name }}</div></td>
                <td><div class="flex justify-content-center">{{ document.created | date:'dd-MM-yyyy'}}</div></td>
                <td><div class="flex justify-content-center">{{ document.size / 1000 }} KB</div></td>
                <td>
                  <div class="flex justify-content-center">
                    <p-button *ngIf="document.documentType === 1" (onClick)="getQCReviewDocument(document.id)" pTooltip="View PDF" icon="pi pi-file-pdf" styleClass="p-button-link p-button-lg" [disabled]="document.size < 1"></p-button>
                    <p-button *ngIf="document.documentType === 2" (onClick)="getQCConsolidationDocument(document.id)" pTooltip="View PDF" icon="pi pi-file-pdf" styleClass="p-button-link p-button-lg" [disabled]="document.size < 1"></p-button>
                    <p-button *ngIf="document.documentType === 3" (onClick)="getFrozenFruitQCReviewDocument(document.id)" pTooltip="View PDF" icon="pi pi-file-pdf" styleClass="p-button-link p-button-lg" [disabled]="document.size < 1"></p-button>
                    <p-button *ngIf="document.documentType === 4" (onClick)="getFrozenFruitQCConsolidationDocument(document.id)" pTooltip="View PDF" icon="pi pi-file-pdf" styleClass="p-button-link p-button-lg" [disabled]="document.size < 1"></p-button>
                  </div>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="summary">
              <div class="flex align-items-center justify-content-between" *ngIf="order.qualityControlDocuments.length < 1">
                {{ 'DocumentComponent.EmptyResult' | translate }}
              </div>
            </ng-template>
          </p-table>
        </div>
      </p-card>
    </div>

    <div id="StatusInfo" style="margin-top: 40px;">
      <p-card header="Status de la orden">
        <p-timeline [value]="statuses" align="right">
          <ng-template pTemplate="content" let-status>
            <p-tag [value]="status.name" *ngIf="order.importOrder.status === status.value"></p-tag>
            <span *ngIf="order.importOrder.status !== status.value">{{ status.name }}</span>
          </ng-template>
        </p-timeline>
      </p-card>
    </div>
  </p-card>
</app-admin-dashboard>
<p-toast></p-toast>

<p-dialog header="Editar documento" [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleEditDocumentDialog" [style]="{width: '50%'}">
  <form [formGroup]="operationFormGroup">
    <div class="p-fluid formgrid grid">
      <div class="field col-12">
        <label for="name" style="font-weight: bolder;">Nombre del documento *</label>
        <input pInputText id="name" type="text" [maxLength]="100" placeholder="Nombre del documento" formControlName="name" style="width: 100%;"/>
        <small *ngIf="operationFormGroup.controls['name'].invalid" class="p-error">{{getErrorMessage(operationFormGroup, 'name')}}</small>
      </div>
      <div class="field col-12">
        <label for="name" style="font-weight: bolder;">Archivo PDF del documento *</label>
        <p-fileUpload
          #fileUploader
          [showUploadButton]="false"
          [multiple]="false"
          accept=".pdf"
          name="pdf"
          [maxFileSize]="5000000"
          chooseLabel="Cargar"
          (onSelect)="onSelect($event)"/>
      </div>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <p-button label="Cancelar" (onClick)="visibleEditDocumentDialog = false" styleClass="p-button-outlined" [loading]="loading"></p-button>
    <p-button label="Guardar" (onClick)="saveDocument()" [loading]="loading" [disabled]="!selectedFile"></p-button>
  </ng-template>
</p-dialog>

<p-confirmDialog acceptLabel="Aceptar" rejectLabel="Cancelar" rejectButtonStyleClass="p-button-outlined" [style]="{width: '50vw'}"></p-confirmDialog>

<p-dialog [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleExportOfferDialog" [style]="{width: '80%'}">
  <app-export-offer-detail [offer]="selectedExportOffer" [productCertificateTypes]="selectedProductCertificateTypes" [packagingTypes]="selectedPackagingTypes"></app-export-offer-detail>
  <ng-template pTemplate="footer">
    <p-button label="{{'UserPages.UserBargaining.OpenTab.BargainingDetail.AcceptNegotiationModal.CloseModalButton' | translate }}" (onClick)="visibleExportOfferDialog = false"></p-button>
  </ng-template>
</p-dialog>
