<app-admin-dashboard>
    <p-breadcrumb></p-breadcrumb>
    <form [formGroup]="formGroup">
        <p-card>
            <ng-template pTemplate="header">
                <div class="flex justify-content-between flex-wrap align-items-center" style="padding: 15px;">
                    <h2>Nueva Especie</h2>
                </div>
            </ng-template>
            <ng-template pTemplate="body">
                <div class="p-fluid formgrid grid">
                    <div class="field col-12 md:col-4 lg:col-3">
                        <input pInputText placeholder="Nombre" formControlName="name">
                        <small *ngIf="submit && formGroup.controls['name'].invalid" class="p-error">{{getErrorMessage()}}</small>
                    </div>
                    <div class="flex col-12">
                            <p-fileUpload
                            #fileUploader
                            [showUploadButton]="false"
                            [multiple]="false"
                            accept=".jpg, .jpeg, .png"
                            name="img"
                            style="width: 50%"
                            [maxFileSize]="5000000"
                            chooseLabel="Cargar"
                            (onSelect)="onSelect($event)"/>
                    </div>
                </div>

            </ng-template>
            <ng-template pTemplate="footer">
                <div class="flex align-items-center justify-content-start m-2 col-8" >
                    <p-button label="Crear" (onClick)="save()" styleClass="p-button-outlined" [loading]="loading">
                    </p-button>
                </div>
            </ng-template>
        </p-card>
    </form>
</app-admin-dashboard>
<p-toast></p-toast>
