import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, ConfirmEventType, MenuItem, MessageService } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { DeliveryGateway } from 'src/app/application/gateway/DeliveryGateway';
import { DocumentServiceGateway } from 'src/app/application/gateway/DocumentServiceGateway';
import { ExportOfferDocumentGateway } from 'src/app/application/gateway/ExportOfferDocumentGateway';
import { ExportOfferGateway } from 'src/app/application/gateway/ExportOfferGateway';
import { FridgeStorageGateway } from 'src/app/application/gateway/FridgeStorageGateway';
import { ProductCertificateGateway } from 'src/app/application/gateway/ProductCertificateGateway';
import { ProductCertificateTypeGateway } from 'src/app/application/gateway/ProductCertificateTypeGateway';
import { Consignee } from 'src/app/domain/models/Consignee';
import { Delivery } from 'src/app/domain/models/Delivery';
import { ExportOffer, OfferPicture } from 'src/app/domain/models/ExportOffer';
import { ExportOfferDocument, NewExportOfferDocument } from 'src/app/domain/models/ExportOfferDocument';
import { FridgeStorage } from 'src/app/domain/models/FridgeStorage';
import { Notifier } from 'src/app/domain/models/Notifier';
import { NewProductCertificate, ProductCertificate } from 'src/app/domain/models/ProductCertificate';
import { ProductCertificateType } from 'src/app/domain/models/ProductCertificateType';
import { Profile } from 'src/app/domain/models/Profile';
import { Species } from 'src/app/domain/models/Species';
import { BasicUser } from 'src/app/domain/models/User';
import { Routes } from 'src/app/infrastructure/routes';
import { ErrorMessageUtil } from 'src/app/ui/util/error-message-util';
import { ExportOfferUtils } from 'src/app/ui/util/export-offer-utils';
import { Lang } from 'src/app/ui/util/langs';
import { SpeciesUtils } from 'src/app/ui/util/species-utils';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';

interface Picture {
  picture: OfferPicture
  file: any
}

@Component({
  selector: 'app-user-edit-closed-sale',
  templateUrl: './user-edit-closed-sale.component.html',
  styleUrls: ['./user-edit-closed-sale.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class UserEditClosedSaleComponent implements OnInit {
  breadcrumbMenuItems: MenuItem[] = [{ label: 'Detalle de oferta de exportación' }]
  breadcrumbHome: MenuItem = { label: 'Mis Ofertas de exportación'}

  exportOfferId: number | undefined
  exportOffer: ExportOffer | undefined
  qualityControlDate: Date | null | undefined

  importers: Profile[] = []
  consignees: Consignee[] = []
  notifies: Notifier[] = []
  delivery: Delivery | undefined
  basePallets: any[] = ExportOfferUtils.getBasePallets(Lang.SPANISH)
  marksOptions: any[] = ExportOfferUtils.getMarksOptions(Lang.SPANISH)
  pictures: Picture[] = []
  removedPictures: number[] = []

  loading: boolean = false
  submit: boolean = false

  incotermsOptions: any[] = ExportOfferUtils.getIncotermsClosedSaleOptions()
  fridgeStorages: FridgeStorage[] = []

  profile: BasicUser | undefined

  documents: any[] = []
  documentDialogVisible: boolean = false
  submitDocument: boolean = false

  productCertificateDialogVisible: boolean = false
  submitProductCertificate: boolean = false
  productCertificates: any[] = []
  productCertificatePdfFile: any
  certificateTypes: ProductCertificateType[] = []

  formGroup: FormGroup = this._formBuilder.group({
    paymentAgainstBL: [100],
    caseQuantityPerPallet: [null, Validators.required],
    listPrice: [null, Validators.required],
    incoterm: [null, Validators.required],
    fridgeStorageId: [null, Validators.required],
    basePallets: [null],
    marks: [false]
  })

  productCertificateFormGroup: FormGroup = this._formBuilder.group({
    name: ['', Validators.required],
    documentIssueDate: [null],
    expirationDate: [null],
    productCertificateTypeId: ['', Validators.required]
  })

  documentPdfFile: any
  documentFormGroup: FormGroup = this._formBuilder.group({
    name: ['', Validators.required]
  })

  constructor(private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _messageService: MessageService,
    private _confirmationService: ConfirmationService,
    private _authService: AuthGateway,
    private _exportOfferService: ExportOfferGateway,
    private _fridgeStorageService: FridgeStorageGateway,
    public translate: TranslateService,
    private _exportOfferDocumentService: ExportOfferDocumentGateway,
    private _productCertificateTypeService: ProductCertificateTypeGateway,
    private _productCertificateService: ProductCertificateGateway,
    private _documentService: DocumentServiceGateway) {
    this._activatedRoute.params.subscribe(params => {
      this.exportOfferId = params['exportOfferId']
    })
  }

  ngOnInit(): void {
    this.profile = this._authService.getUser()

    let breadcrumbStep1ItemLabel1 = 'Load export offer'
    let breadcrumbStep1ItemLabel2 = 'Load pallets'
    let homeItemLabel = 'My direct sales'

    if(this.profile) {
      switch(this.profile.lang) {
        case 'en':
          breadcrumbStep1ItemLabel1 = 'Edit direct sale export offer'
          homeItemLabel = 'My direct sales'
          break
        case 'es':
          breadcrumbStep1ItemLabel1 = 'Editar producto venta directa'
          homeItemLabel = 'Mis ventas directas'
          break
      }
    }

    this.breadcrumbMenuItems = [{ label: breadcrumbStep1ItemLabel1 }]
    this.breadcrumbHome = { label: homeItemLabel, routerLink: '/' + Routes.userClosedSales}

    this.loadExportOffer()
    this.loadFridgeStorages()
    this.loadProductCertificateTypes()
  }

  loadExportOffer() {
    if(this.exportOfferId) {
      this._exportOfferService.getById(this.exportOfferId).subscribe({
        next: (v) => {
          this.exportOffer = v
          this.qualityControlDate = this.exportOffer.qualityControlDate
          this.loadForm()
        },
        error: (e) => {
          console.log(e)
          this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudo recuperar la oferta de exportación.' });
        },
        complete: () => console.info('Export Offer query successfully')
      })
    }
  }

  loadForm() {
    if(this.profile) {
      this.basePallets = ExportOfferUtils.getBasePallets(this.profile.lang)
      this.marksOptions = ExportOfferUtils.getMarksOptions(this.profile.lang)
    }

    if(this.exportOffer) {
      this.formGroup.controls['paymentAgainstBL'].setValue(this.exportOffer.paymentAgainstBL)
      this.formGroup.controls['caseQuantityPerPallet'].setValue(this.exportOffer.caseQuantityPerPallet)
      this.formGroup.controls['listPrice'].setValue(this.exportOffer.listPrice)
      this.formGroup.controls['incoterm'].setValue(this.exportOffer.typeSale)
      this.formGroup.controls['fridgeStorageId'].setValue(this.exportOffer.fridgeStorageId)
      this.formGroup.controls['basePallets'].setValue(this.exportOffer.basePallets)
      this.formGroup.controls['marks'].setValue(this.exportOffer.marks)

      this.exportOffer.offerPictures?.forEach(async p => {
        p.isNew = false
        this.pictures.push({picture: p, file: null})
      })

      this.loadProductCertificates()
      this.loadDocuments()
    }

  }

  loadFridgeStorages() {
    this.fridgeStorages = []
    this._fridgeStorageService.getAllByStorageType(1).subscribe({
      next: (v) => {
        this.fridgeStorages = v
        this.loading = false
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.FridgeStorage.ManyRetrieveError') })
      },
      complete: () => console.info('Species Sizes query successfully')
    })
  }

  loadProductCertificateTypes() {
    this._productCertificateTypeService.getAll().subscribe({
      next: (v) => {
        this.certificateTypes = v
        this.loading = false
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.ProductCertificateType.ManyRetrieveError') })
      },
      complete: () => console.info('ProductCertificateTypes query successfully')
    })
  }

  getSpeciesName(species: Species | undefined): string {
    if(this.profile)
      return SpeciesUtils.getSpeciesName(this.profile.lang, species)
    return ''
  }

  save() {
    this.loading = true
    this.submit = true
    if(this.formGroup.valid && this.exportOffer) {
      this.exportOffer.paymentAgainstBL = this.formGroup.controls['paymentAgainstBL'].value
      this.exportOffer.caseQuantityPerPallet = this.formGroup.controls['caseQuantityPerPallet'].value
      this.exportOffer.listPrice = this.formGroup.controls['listPrice'].value
      this.exportOffer.typeSale = this.formGroup.controls['incoterm'].value
      this.exportOffer.fridgeStorageId = this.formGroup.controls['fridgeStorageId'].value
      this.exportOffer.basePallets = this.formGroup.controls['basePallets'].value
      this.exportOffer.marks = this.formGroup.controls['marks'].value

      this.exportOffer.pictures = []
      if(this.pictures) {
        this.pictures.forEach(p => {
          if (p.picture.isNew)
            this.exportOffer?.pictures.push(p.file)
        })
      }
      this.exportOffer.removedPictures = this.removedPictures

      this._exportOfferService.update(this.exportOffer).subscribe({
        next: (v) => {
          this.exportOffer = v
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: this.translate.instant('MessageService.ExportOffer.EditSuccess') })
          this.submit = false
          this.loading = false
        },
        error: (e) => {
          this.loading = false
          console.log(e)
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOffer.EditError') });
        },
        complete: () => console.info('Export Offer update successfully')
      })

    }
    else {
      this.loading = false
    }
  }

  goToClosedSalesPage() {
    this._router.navigate(['/' + Routes.userClosedSales])
  }

  getErrorMessage(formGroup: FormGroup, fieldName: string): string {
    return ErrorMessageUtil.getErrorMessage(formGroup, this.translate, fieldName)
  }

  getColor(color?: string): string {
    if(this.profile)
      return ExportOfferUtils.getColorName(this.profile.lang, color)
    return ''
  }

  getBoxType(boxType?: string): string {
    if(this.profile)
      return ExportOfferUtils.getBoxTypeName(this.profile.lang, boxType)
    return ''
  }

  onSelectImage(event: any, uploader: any): void {
    this.pictures.push(
      {
        picture: {id: 0,
                  url: uploader.files[0].objectURL.changingThisBreaksApplicationSecurity,
                  isNew: true},
        file: uploader.files[0]
      })
    uploader.clear()
  }

  getImageUrl(picture: Picture): any {
    if(picture) {
      if(!picture.picture.isNew && picture.picture.url) {
        return environment.apiUrl + picture.picture.url
      }
      else {
        return picture.file.objectURL
      }
    }
    return ''
  }

  getImageName(picture: Picture): string {
    let fileName = ''
    if(!picture.picture.isNew && picture.picture.url) {
      const urlParts: string[] = picture.picture.url.split('/')
      fileName = urlParts[urlParts.length-1]
    }
    else {
      return picture.file.name
    }
    return fileName
  }

  onRemoveImage(index: number): void {
    if(this.exportOffer && this.pictures[index]) {
      this.removedPictures.push(this.pictures[index].picture.id)
      this.pictures.splice(index, 1)
    }
  }

  onSelectProductCertificateFile(event: any) {
    this.productCertificatePdfFile = event.files[0]
  }

  onClearProductCertificateFile(event: any) {
    this.productCertificatePdfFile = null
  }

  openProductCertificateCreateDialog(formDirective: FormGroupDirective, fileUploader: FileUpload) {
    this.selectedProductCertificate = undefined
    fileUploader.clear()
    formDirective.resetForm()
    this.productCertificateFormGroup.controls['name'].setValue('')
    this.productCertificateFormGroup.controls['productCertificateTypeId'].setValue('')
    this.productCertificateFormGroup.controls['documentIssueDate'].setValue('')
    this.productCertificateFormGroup.controls['expirationDate'].setValue('')
    this.productCertificatePdfFile = null
    this.submitProductCertificate = false
    this.productCertificateDialogVisible = true
  }

  selectedProductCertificate: ProductCertificate | undefined
  openProductCertificateEditDialog(certificate: ProductCertificate, fileUploader: FileUpload) {
    this.selectedProductCertificate = certificate
    this.productCertificateFormGroup.controls['name'].setValue(this.selectedProductCertificate.name)
    this.productCertificateFormGroup.controls['productCertificateTypeId'].setValue(this.selectedProductCertificate.productCertificateTypeId)
    this.productCertificateFormGroup.controls['documentIssueDate'].setValue(this.selectedProductCertificate.documentIssueDate ? new Date(this.selectedProductCertificate.documentIssueDate) : null)
    this.productCertificateFormGroup.controls['expirationDate'].setValue(this.selectedProductCertificate.expirationDate ? new Date(this.selectedProductCertificate.expirationDate) : null)

    if(this.selectedProductCertificate.pdfUrl) {
      let uri = this.selectedProductCertificate.pdfUrl.trim().replace(" ", "%20")
      uri = environment.apiUrl + uri
      fetch(uri).then(r => r.blob()).then(blob => {
        if(blob && this.selectedProductCertificate) {
          let filename = uri.substring(uri.lastIndexOf('/')+1)
          filename = filename.replace("%20", " ")
          let pdf = new File([blob], filename)
          fileUploader.clear()
          fileUploader.files = [pdf]
          this.productCertificatePdfFile = pdf
        }
      })
    }

    this.productCertificateDialogVisible = true
  }

  saveProductCertificate() {
    this.submitProductCertificate = true
    if(this.productCertificateFormGroup.valid) {
      this.loading = true
      if(this.selectedProductCertificate) {
        this.selectedProductCertificate.name = this.productCertificateFormGroup.controls['name'].value
        this.selectedProductCertificate.productCertificateTypeId = this.productCertificateFormGroup.controls['productCertificateTypeId'].value
        this.selectedProductCertificate.documentIssueDate = this.productCertificateFormGroup.controls['documentIssueDate'].value
        this.selectedProductCertificate.expirationDate = this.productCertificateFormGroup.controls['expirationDate'].value
        this.selectedProductCertificate.pdf = this.productCertificatePdfFile ? this.productCertificatePdfFile : this.selectedProductCertificate.pdf

        this._productCertificateService.update(this.selectedProductCertificate).subscribe({
          next: (v) => {
            this.selectedProductCertificate = v
            this.loadProductCertificates()
            this._messageService.add({ severity: 'success',
              summary: this.translate.instant('MessageService.Summary.SuccessAction'),
              detail: this.translate.instant('MessageService.ProductCertificate.EditSuccess') })
            this.loading = false
            this.submitProductCertificate = false
            this.productCertificateDialogVisible = false
          },
          error: (e) => {
            this.loading = false
            this.submitProductCertificate = false
            console.log(e)
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.ProductCertificate.EditError') });
          },
          complete: () =>  {
            this.loading = false
            this.submitProductCertificate = false
            console.info('Product Certificate update successfully') }
        })
      }
      else {
        let newCertificate: NewProductCertificate = {
          name: this.productCertificateFormGroup.controls['name'].value,
          productCertificateTypeId: this.productCertificateFormGroup.controls['productCertificateTypeId'].value,
          documentIssueDate: this.productCertificateFormGroup.controls['documentIssueDate'].value,
          expirationDate: this.productCertificateFormGroup.controls['expirationDate'].value,
          pdf: this.productCertificatePdfFile,
          exportOfferId: this.exportOfferId
        }

        this._productCertificateService.create(newCertificate).subscribe({
          next: (v) => {
            this.selectedProductCertificate = v
            this.loadProductCertificates()
            this._messageService.add({ severity: 'success',
              summary: this.translate.instant('MessageService.Summary.SuccessAction'),
              detail: this.translate.instant('MessageService.ProductCertificate.CreateSuccess') })
            this.loading = false
            this.submitProductCertificate = false
            this.productCertificateDialogVisible = false
          },
          error: (e) => {
            this.loading = false
            this.submitProductCertificate = false
            console.log(e)
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.ProductCertificate.CreateError') });
          },
          complete: () =>  {
            this.loading = false
            this.submitProductCertificate = false
            console.info('Product Certificate create successfully')
          }
        })
      }
    }
    else {
      this.loading = false
    }
  }

  onDeleteProductCertificate(certificate: ProductCertificate) {
    this._confirmationService.confirm({
      message: this.translate.instant('MessageService.ProductCertificate.ConfirmDeleteDialogMessage'),
      header: this.translate.instant('MessageService.ProductCertificate.ConfirmDeleteDialogHeader'),
      icon: 'pi pi-info-circle',
      accept: () => {
        this._productCertificateService.delete(certificate.id).subscribe({
          next: (v) => {
            this.selectedProductCertificate = undefined
            this.loadProductCertificates()
            this._messageService.add({ severity: 'info',
              summary: this.translate.instant('MessageService.Summary.SuccessAction'),
              detail: this.translate.instant('MessageService.ProductCertificate.DeleteSuccess') })

          },
          error: (e) => {
            console.log(e)
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.ProductCertificate.DeleteError') });
          },
          complete: () => console.info('Product Certificate delete successfully')
        })
      },
      reject: (type: any) => {
          switch (type) {
              case ConfirmEventType.REJECT:
                //this._messageService.add({ severity: 'error', summary: 'Rejected', detail: 'Se canceló la eliminación' })
                break;
              case ConfirmEventType.CANCEL:
                //this._messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Se canceló la eliminación' })
                break;
          }
      }
    })
  }

  loadProductCertificates() {
    this.productCertificates =  []
    if(this.exportOffer) {
      this._productCertificateService.getAllByExportOfferId(this.exportOffer.id).subscribe({
        next: (v) => {
          this.productCertificates = v
          this.loading = false
        },
        error: (e) => {
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ProductCertificate.ManyRetrieveError') });
          console.log(e)
          this.loading = false
        },
        complete: () =>  {
          this.loading = false
          console.info('Product Certificates query successfully')
        }
      })
    }
  }

  onSelectDocumentFile(event: any) {
    this.documentPdfFile = event.files[0]
  }

  onClearDocumentFile(event: any) {
    this.documentPdfFile = null
  }

  selectedDocument: ExportOfferDocument | undefined
  openDocumentCreateDialog(formDirective: FormGroupDirective, fileUploader: FileUpload) {
    fileUploader.clear()
    formDirective.resetForm()
    this.documentFormGroup.controls['name'].setValue('')
    this.documentPdfFile = null
    this.selectedDocument = undefined
    this.submitDocument = false
    this.documentDialogVisible = true
  }

  openDocumentEditDialog(document: ExportOfferDocument, fileUploader: FileUpload) {
    this.selectedDocument = document
    this.documentFormGroup.controls['name'].setValue(this.selectedDocument.name)
    if(this.selectedDocument.pdfUrl) {
      let uri = this.selectedDocument.pdfUrl.trim().replace(" ", "%20")
      uri = environment.apiUrl + uri
      fetch(uri).then(r => r.blob()).then(blob => {
        if(blob && this.selectedDocument) {
          let filename = uri.substring(uri.lastIndexOf('/')+1)
          filename = filename.replace("%20", " ")
          let pdf = new File([blob], filename)
          fileUploader.clear()
          fileUploader.files = [pdf]
          this.documentPdfFile = pdf
        }
      })
    }
    this.documentDialogVisible = true
  }

  onDeleteDocument(document: ExportOfferDocument) {
    this._confirmationService.confirm({
      message: this.translate.instant('MessageService.ExportOfferDocument.ConfirmDeleteDialogMessage'),
      header: this.translate.instant('MessageService.ExportOfferDocument.ConfirmDeleteDialogHeader'),
      icon: 'pi pi-info-circle',
      accept: () => {
        this._exportOfferDocumentService.delete(document.id).subscribe({
          next: (v) => {
            this.selectedDocument = undefined
            this.loadDocuments()
            this._messageService.add({ severity: 'info',
              summary: this.translate.instant('MessageService.Summary.SuccessAction'),
              detail: this.translate.instant('MessageService.ExportOfferDocument.DeleteSuccess') })

          },
          error: (e) => {
            console.log(e)
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.ExportOfferDocument.DeleteError') });
          },
          complete: () => console.info('Export Offer Document delete successfully')
        })
      },
      reject: (type: any) => {
          switch (type) {
              case ConfirmEventType.REJECT:
                //this._messageService.add({ severity: 'error', summary: 'Rejected', detail: 'Se canceló la eliminación' })
                break;
              case ConfirmEventType.CANCEL:
                //this._messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Se canceló la eliminación' })
                break;
          }
      }
    })
  }

  saveDocument() {
    this.submitDocument = true
    if(this.documentFormGroup.valid) {
      this.loading = true
      if(this.selectedDocument) {
        this.selectedDocument.name = this.documentFormGroup.controls['name'].value
        this.selectedDocument.pdf = this.documentPdfFile ? this.documentPdfFile : this.selectedDocument.pdf

        this._exportOfferDocumentService.update(this.selectedDocument).subscribe({
          next: (v) => {
            this.selectedDocument = v
            this.loadDocuments()
            this._messageService.add({ severity: 'success',
              summary: this.translate.instant('MessageService.Summary.SuccessAction'),
              detail: this.translate.instant('MessageService.ExportOfferDocument.EditSuccess') })
            this.loading = false
            this.submitDocument = false
            this.documentDialogVisible = false
          },
          error: (e) => {
            this.loading = false
            this.submitDocument = false
            console.log(e)
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.ExportOfferDocument.EditError') });
          },
          complete: () =>  {
            this.loading = false
            this.submitDocument = false
            console.info('Document update successfully') }
        })
      }
      else {
        let newDocument: NewExportOfferDocument = {
          name: this.documentFormGroup.controls['name'].value,
          pdf: this.documentPdfFile,
          exportOfferId: this.exportOfferId
        }

        this._exportOfferDocumentService.create(newDocument).subscribe({
          next: (v) => {
            this.selectedDocument = v
            this.loadDocuments()
            this._messageService.add({ severity: 'success',
              summary: this.translate.instant('MessageService.Summary.SuccessAction'),
              detail: this.translate.instant('MessageService.ExportOfferDocument.CreateSuccess') })
            this.loading = false
            this.submitDocument = false
            this.documentDialogVisible = false
          },
          error: (e) => {
            this.loading = false
            this.submitDocument = false
            console.log(e)
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.ExportOfferDocument.CreateError') });
          },
          complete: () =>  {
            this.loading = false
            this.submitDocument = false
            console.info('Document create successfully')
          }
        })
      }
    }
    else {
      this.loading = false
    }
  }

  loadDocuments() {
    this.documents =  []
    if(this.exportOffer) {
      this._exportOfferDocumentService.getAllByExportOffer(this.exportOffer.id).subscribe({
        next: (v) => {
          this.documents = v
          this.loading = false
        },
        error: (e) => {
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOfferDocument.ManyRetrieveError') });
          console.log(e)
          this.loading = false
        },
        complete: () =>  {
          this.loading = false
          console.info('Export Offer Documents query successfully')
        }
      })
    }
  }

  getProductCertificateDocument(productCertificateId: number) {
    this._documentService.getProductCertificateDocument2(productCertificateId).subscribe((resultBlob: any) => {
      var downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }

  getExportOfferDocument(exportOfferDocumentId: number) {
    this._documentService.getExportOfferDocument2(exportOfferDocumentId).subscribe((resultBlob: any) => {
      var downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }

  get paymentBLTitle() {
    return ExportOfferUtils.getPaymentBLClosedSaleTitle(this.formGroup.controls['paymentAgainstBL'].value);
  }

  get paymentBLItems() {
    return ExportOfferUtils.getPaymentBLClosedSaleValues(this.formGroup.controls['incoterm'].value);
  }

  get showPaymentBLPercent() {
    return ExportOfferUtils.showPaymentBLClosedSalePercent(this.formGroup.controls['incoterm'].value)
  }

  get finalPaymentPercent(): number {
    if(this.formGroup.controls['paymentAgainstBL'].value)
      return 100 - this.formGroup.controls['paymentAgainstBL'].value
    return 0
  }

  get formatQualityControlDate(): string {
    if(this.qualityControlDate)
      return moment(this.qualityControlDate).format('MM/DD/YYYY')
    return ""
  }
}
