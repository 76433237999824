<app-admin-dashboard >
    <p-breadcrumb class="max-w-full" [model]="breadcrumbMenuItems" [home]="breadcrumbHome"></p-breadcrumb>
    <form [formGroup]="formGroup">
      <p-card>
        <ng-template pTemplate="header">
          <div class="flex justify-content-between flex-wrap align-items-center" style="padding: 15px;">
            <h2>Detalle de Oferta de Exportación</h2>
            <!--p-button label="Editar"></p-button-->
          </div>
        </ng-template>
        <ng-template pTemplate="body">
          <div class="p-fluid formgrid grid">
            <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
              <label for="species">Especie *</label>
              <p-dropdown id="species"
                formControlName="speciesId"
                [options]="species"
                optionLabel="name"
                optionValue="id"
                [filter]="true"
                filterBy="name"
                [showClear]="true"
                placeholder="Especie"
                (onChange)="loadSpeciesParams()">
              </p-dropdown>
            </div>
            <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
              <label for="variety">Variedad *</label>
              <p-dropdown id="variety"
                formControlName="varietyId"
                [options]="varieties"
                optionLabel="name"
                optionValue="id"
                [filter]="true"
                filterBy="name"
                [showClear]="true"
                placeholder="Variedad"></p-dropdown>
            </div>
            <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
              <label for="caseQuantityPerPallet">Cajas por pallet *</label>
              <p-inputNumber inputId="caseQuantityPerPallet" formControlName="caseQuantityPerPallet" placeholder="Cajas por pallet"></p-inputNumber>
              <small *ngIf="submit && formGroup.controls['caseQuantityPerPallet'].invalid" class="p-error">{{getErrorMessage('caseQuantityPerPallet')}}</small>
            </div>
            <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
              <label for="listPrice">Precio unitario caja *</label>
              <p-inputNumber inputId="listPrice" formControlName="listPrice" mode="currency" currency="USD" locale="en-US"></p-inputNumber>
            </div>
            <div class="field col-12 md:col-3 lg:col-3">
              <label for="incoterm">Modalidad de venta *</label>
              <p-dropdown inputId="incoterm" formControlName="incoterm" [options]="incotermsOptions" optionLabel="label" optionValue="value" placeholder="Modalidad de venta"></p-dropdown>
              <small *ngIf="formGroup.controls['incoterm'].invalid" class="p-error">{{getErrorMessage('incoterm')}}</small>
            </div>
            <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
              <label for="palletQuantity">Cantidad de pallets *</label>
              <p-inputNumber inputId="palletQuantity" formControlName="palletQuantity" placeholder="Cantidad de pallets"></p-inputNumber>
            </div>
            <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
              <label for="fridgeStorage">Frigorífico *</label>
              <p-dropdown id="fridgeStorage"
                formControlName="fridgeStorageId"
                [options]="fridgeStorages"
                optionLabel="name"
                optionValue="id"
                [filter]="true"
                filterBy="name"
                [showClear]="true"
                placeholder="Seleccione un frigorífico">
              </p-dropdown>
              <small *ngIf="submit && formGroup.controls['fridgeStorageId'].invalid" class="p-error">{{getErrorMessage('fridgeStorageId')}}</small>
            </div>
          </div>

          <h2>Descripción del producto</h2>
          <div class="p-fluid formgrid grid">
            <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
              <label for="harvestDate">Fecha de cosecha *</label>
              <p-calendar
                inputId="harvestDate"
                formControlName="harvestDate"
                appendTo="body"
                [showButtonBar]="true"
                [showIcon]="true"
                [showWeek]="true"
                [firstDayOfWeek]="1"></p-calendar>
              <small *ngIf="submit && formGroup.controls['harvestDate'].invalid" class="p-error">{{getErrorMessage('harvestDate')}}</small>
            </div>
            <!-- <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
              <label for="kilosPerBox">Kilogramos por caja *</label>
              <input pInputText inputId="kilosPerBox" formControlName="kilosPerBox" placeholder="Kilos por caja"/>
              <small *ngIf="submit && formGroup.controls['kilosPerBox'].invalid" class="p-error">{{getErrorMessage('kilosPerBox')}}</small>
            </div> -->
            <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
              <label for="netKilos">Kilos Netos *</label>
              <input pInputText inputId="netKilos" formControlName="netKilos" placeholder="Kilos netos"/>
              <small *ngIf="submit && formGroup.controls['netKilos'].invalid" class="p-error">{{getErrorMessage('netKilos')}}</small>
            </div>
            <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
              <label for="grossKilos">Kilos brutos *</label>
              <input pInputText inputId="grossKilos" formControlName="grossKilos" placeholder="Kilos brutos"/>
              <small *ngIf="submit && formGroup.controls['grossKilos'].invalid" class="p-error">{{getErrorMessage('grossKilos')}}</small>
            </div>
            <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
              <label for="boxType">Tipo de caja *</label>
              <p-dropdown inputId="boxType" formControlName="boxType" [options]="boxTypes" optionLabel="label" optionValue="value" placeholder="Tipo de caja"></p-dropdown>
              <small *ngIf="submit && formGroup.controls['boxType'].invalid" class="p-error">{{getErrorMessage('boxType')}}</small>
            </div>
            <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
              <label for="color">Color</label>
              <p-dropdown id="color" formControlName="color" [options]="colors" optionLabel="label" optionValue="value" placeholder="Color"></p-dropdown>
            </div>
            <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
              <label for="speciesSize">Calibre</label>
              <p-dropdown id="speciesSize" formControlName="speciesSize" [options]="sizes" optionLabel="name" optionValue="name" placeholder="Calibre"></p-dropdown>
            </div>
            <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
              <label for="readyToLoadDate">Fecha listo para carga</label>
              <p-calendar
                inputId="readyToLoadDate"
                formControlName="readyToLoadDate"
                appendTo="body"
                [showButtonBar]="true"
                [showIcon]="true"
                [showWeek]="true"
                [firstDayOfWeek]="1"></p-calendar>
            </div>
            <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
              <label for="shelfLife">Duración (días)</label>
              <p-inputNumber inputId="shelfLife" formControlName="shelfLife" placeholder="Duración" [min]="1" [max]="1000"  ></p-inputNumber>
            </div>
            <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
              <label for="basePallets">Base Pallets</label>
              <p-dropdown inputId="basePallets" formControlName="basePallets" [options]="basePallets" optionLabel="label" optionValue="value" placeholder="Kilos por caja"></p-dropdown>
            </div>
            <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
              <label for="isQualityControl">¿Tiene Control de calidad?</label>
              <p-dropdown id="isQualityControl" formControlName="isQualityControl" [options]="qualityControlOptions" optionLabel="label" optionValue="value" placeholder="Control de calidad"></p-dropdown>
            </div>
            <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
              <label for="qualityControlDate">Fecha control de calidad</label>
              <p-calendar
                inputId="qualityControlDate"
                formControlName="qualityControlDate"
                appendTo="body"
                [showButtonBar]="true"
                [showIcon]="true"
                [showWeek]="true"
                [firstDayOfWeek]="1"></p-calendar>
            </div>
            <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
              <label for="marks">¿Tiene marcas?</label>
              <p-dropdown id="marks" formControlName="marks" [options]="marksOptions" optionLabel="label" optionValue="value" placeholder="Marcas"></p-dropdown>
            </div>
          </div>

          <h2>Mercados destino</h2>
          <div class="p-fluid formgrid grid">
            <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
              <label for="marketCountry1">Mercado 1 *</label>
              <p-dropdown inputId="marketCountry1" formControlName="marketCountry1Id" [options]="countries" optionValue="id" optionLabel="name" placeholder="Primer mercado de destino"></p-dropdown>
              <small *ngIf="submit && formGroup.controls['marketCountry1Id'].invalid" class="p-error">{{getErrorMessage('marketCountry1Id')}}</small>
            </div>
            <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
              <label for="marketCountry2">Mercado 2</label>
              <p-dropdown inputId="marketCountry2" formControlName="marketCountry2Id" [options]="countries" optionValue="id" optionLabel="name" placeholder="Segundo mercado de destino"></p-dropdown>
            </div>
            <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
              <label for="marketCountry3">Mercado 3</label>
              <p-dropdown inputId="marketCountry3" formControlName="marketCountry3Id" [options]="countries" optionValue="id" optionLabel="name" placeholder="Tercer mercado de destino"></p-dropdown>
            </div>
            <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;"></div>
          </div>

          <h2>Certificaciones</h2>
          <div class="col-12 md:col-6 lg:col-6">
            <p-table [value]="certificateTypes" [(selection)]="selectedCertifications" dataKey="id">
              <ng-template pTemplate="header">
                <tr>
                  <th style="width: 4rem">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                  </th>
                  <th>Nombre</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-certification>
                <tr>
                  <td>
                    <p-tableCheckbox [value]="certification"></p-tableCheckbox>
                  </td>
                  <td>{{ certification.name }}</td>
                </tr>
              </ng-template>
            </p-table>
          </div>

          <h2>Imágenes</h2>
          <p-table [value]="pictures">
            <ng-template pTemplate="caption">
              <div class="flex justify-content-start align-items-center gap-4">
                <p-fileUpload
                  #imagesUploader
                  mode="basic"
                  chooseLabel="Cargar"
                  [fileLimit]="1"
                  [auto]="true"
                  accept=".jpg, .jpeg, .png"
                  [maxFileSize]="3000000"
                  (onSelect)="onSelectImage($event, imagesUploader)"
                  [disabled]="pictures.length === 3"></p-fileUpload>
              </div>
            </ng-template>
            <ng-template pTemplate="header">
            </ng-template>
            <ng-template pTemplate="body" let-picture let-rowIndex="rowIndex">
              <tr>
                <td>
                  <img [src]="getImageUrl(picture)" style="border-radius: 7px; width: 100%; max-width: 100px; height: auto;"/>
                </td>
                <td>
                  {{getImageName(picture)}}
                </td>
                <td>
                  <div class="flex justify-content-end align-items-center gap-4">
                    <p-button icon="pi pi-times" (click)="onRemoveImage(rowIndex)"></p-button>
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>

          <div class="flex align-items-center justify-content-end" style="margin-top: 50px;">
            <p-button label="Regresar" (click)="goToExportOffersPage()" styleClass="p-button-outlined"></p-button>
            <p-button label="Guardar" [loading]="loading" (click)="save()" [style]="{'margin-left': '20px'}"></p-button>
          </div>
        </ng-template>
      </p-card>
    </form>
  </app-admin-dashboard>
  <p-toast></p-toast>
