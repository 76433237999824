import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { CountryGateway } from 'src/app/application/gateway/CountryGateway';
import { ProfileGateway } from 'src/app/application/gateway/ProfileGateway';
import { UserGateway } from 'src/app/application/gateway/UserGateway';
import { Country } from 'src/app/domain/models/Country';
import { Profile } from 'src/app/domain/models/Profile';
import { Routes } from 'src/app/infrastructure/routes';
import { ProfileUtils } from 'src/app/ui/util/profile-utils';

@Component({
  selector: 'app-admin-edit-user',
  templateUrl: './admin-edit-user.component.html',
  styleUrls: ['./admin-edit-user.component.css'],
  providers: [MessageService]
})
export class AdminEditUserComponent implements OnInit {
  profileTypeOptions: any[] = ProfileUtils.getProfileTypeOptions()
  profileId: number | undefined
  profile: Profile | undefined
  countries: Country[] = []

  loading: boolean = false
  submit: boolean = false

  formGroup: FormGroup = this._formBuilder.group({
    profileType: ['', Validators.required],
    contactName: ['', Validators.required],
    contactLastName: [null, Validators.required],
    contactCountryId: [null, Validators.required],
    contactCity: [''],
    contactAddress: [''],
    contactPhone: [''],
    contactEmail: ['', [Validators.required, Validators.email]],
    vat: [''],
    companyName: [''],
    sagCode: [null],
    legalRepresentativeName: [''],
    legalRepresentativeNumber: [null],
    hasTwoAuthetication: [false],
    isVerified: [false]
  })

  constructor(private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _messageService: MessageService,
    private _formBuilder: FormBuilder,
    private _profileService: ProfileGateway,
    private _countryService: CountryGateway,
    private _authService: AuthGateway,
    private _userService: UserGateway) {
    this._activatedRoute.params.subscribe(params => {
      this.profileId = params['profileId']
    })
  }

  ngOnInit(): void {
    this.loadCountries()
    this.loadProfile()

  }

  loadForm() {
    if(this.profile) {
      this.formGroup.controls["profileType"].setValue(this.profile.type)
      this.formGroup.controls["contactName"].setValue(this.profile.contactName)
      this.formGroup.controls["contactLastName"].setValue(this.profile.contactLastName)
      this.formGroup.controls["contactEmail"].setValue(this.profile.contactEmail)
      this.formGroup.controls["contactCountryId"].setValue(this.profile.contactCountryId)
      this.formGroup.controls["contactCity"].setValue(this.profile.contactCity)
      this.formGroup.controls["contactAddress"].setValue(this.profile.contactAddress)
      this.formGroup.controls["contactPhone"].setValue(this.profile.contactPhone)
      this.formGroup.controls["vat"].setValue(this.profile.vat)
      this.formGroup.controls["companyName"].setValue(this.profile.companyName)
      this.formGroup.controls["sagCode"].setValue(this.profile.sagCode)
      this.formGroup.controls["legalRepresentativeName"].setValue(this.profile.legalRepresentativeName)
      this.formGroup.controls["legalRepresentativeNumber"].setValue(this.profile.legalRepresentativeNumber)
      this.formGroup.controls["hasTwoAuthetication"].setValue(this.profile.hasTwoAuthetication)
      this.formGroup.controls["isVerified"].setValue(this.profile.isVerified)
    }
  }

  loadProfile(): void {
    if(this.profileId) {
      this._profileService.getById(this.profileId).subscribe({
        next: (v) => {
          this.profile = v
          this.loadForm()
        },
        error: (e) => {
          console.log(e)
          this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudo recuperar el perfil.' });
        },
        complete: () =>  {
          console.info('Profile query successfully')
        }
      })
    }
  }

  loadCountries(): void {
    this._countryService.getAll().subscribe({
      next: (v) => {
        this.countries = v
      },
      error: (e) => {
        this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudo recuperar la lista de paises.' });
        console.log(e)
      },
      complete: () => console.info('Country query successfully')
    })
  }

  save(): void {
    this.submit = true
    if(this.profile && this.formGroup.valid) {
      this.loading = true

      this.profile.type = this.formGroup.controls["profileType"].value
      this.profile.contactName = this.formGroup.controls["contactName"].value
      this.profile.contactLastName = this.formGroup.controls["contactLastName"].value
      this.profile.contactEmail = this.formGroup.controls["contactEmail"].value
      this.profile.contactCountryId = this.formGroup.controls["contactCountryId"].value
      this.profile.contactCity = this.formGroup.controls["contactCity"].value
      this.profile.contactAddress = this.formGroup.controls["contactAddress"].value
      this.profile.contactPhone = this.formGroup.controls["contactPhone"].value
      this.profile.vat = this.formGroup.controls["vat"].value
      this.profile.companyName = this.formGroup.controls["companyName"].value
      this.profile.sagCode = this.formGroup.controls["sagCode"].value
      this.profile.legalRepresentativeName = this.formGroup.controls["legalRepresentativeName"].value
      this.profile.hasTwoAuthetication = this.formGroup.controls["hasTwoAuthetication"].value
      this.profile.isVerified = this.formGroup.controls["isVerified"].value
      this.profile.updaterUsername = this._authService.getUsername()

      console.log("this.profile ==j ", this.profile)
      console.log("this.formGroup ==j ", this.formGroup.controls["contactLastName"].value)

      this._profileService.update(this.profile).subscribe({
        next: (v) => {
          this.profile = v
          this._messageService.add({ severity: 'success', summary: 'Acción exitosa', detail: 'El usuario fue actualizado con éxito.' });
          this.loadForm()
          this.submit = false
          this.loading = false
        },
        error: (e) => {
          this.submit = false
          this.loading = false
          console.log(e)
          this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudo actualizar el usuario. Inténtelo de nuevo.' });
        },
        complete: () => console.info('Profile updated successfully')
      })
    }
    else {
      this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'Hay errores en el formulario.' })
      this.loading = false
    }
  }

  resetPassword() {
    if(this.profile) {
      let request = {
        username: this.profile.contactEmail
      }
      this._userService.resetPasswordRequestToken(request).subscribe({
        next: (v) => {
          this._messageService.add({ severity: 'success', summary: 'Acción exitosa', detail: 'Se ha solicitado el restablecimiento de contraseña correctamente.' })
          this.submit = false
          this.loading = false
        },
        error: (e) => {
          this.loading = false
          console.log(e)
          this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudo solicitar el restablecimiento de contraseña.' });
        },
        complete: () => {
          console.info('Reset password request successfully')
        }
      })
    }
  }

  cancel(): void {
    this._router.navigate(['/' + Routes.adminUsers])
  }

  getErrorMessage(fieldName: string): string {
    if (this.formGroup.controls[fieldName].hasError('required')) {
      return 'Este campo es requerido.';
    }
    if (this.formGroup.controls[fieldName].hasError('email')) {
      return 'Formato de correo electrónico incorrecto.';
    }
    return '';
  }

}
