<p-card>
    <div class="grid">
       <div class="col-12 md:col-6 flex justify-content-between align-items-center" style="padding-right: 20px; padding-bottom: 20px; border-right: 1px solid #ccc; border-bottom: 1px solid #ccc;">
        <div class="w-full grid">
          <div class="col-12 md:col-4 flex justify-content-between gap-1">
            <div style="width: 100%; max-width: 80px;" class="flex  align-items-center">
              <span style="border-radius: 7px; padding: 5px; font-weight: bold; font-size: small; width: 100%;" *ngIf="offer.pictures.length < 1">
                {{'UserPages.UserExportOffers.NotAvailablePictureLabel' | translate}}
              </span>
              <img [src]="getImageUrl(offer)" style="border-radius: 7px; width: 100%; max-width: 50px; height: auto;" alt="" *ngIf="offer.pictures.length > 0"/>
            </div>
            <div style="width: 100%; max-width: 105px;" class="flex justify-content-start flex-column" >
              <h2 style="text-transform: uppercase; font-size: medium;" >{{offer.exportOffer.variety.species | speciesName}}</h2>
              <span style="text-transform: uppercase; font-size: medium;">{{offer.exportOffer.variety.name}}</span>
            </div>
          </div>

          <div class="col-12 md:col-3 flex align-items-center justify-content-center">
            <span style="font-weight: bolder; color: #8c2b87; font-size: small;">{{'UserPages.UserClosedSale.programText' | translate}} ID: {{offer.directSalePrograms[0].id}} </span>
          </div>

          <div class="col-12 md:col-5 flex justify-content-between">
            <div class="flex align-items-center justify-content-center" *ngIf="offer.directSalePrograms[0].type === 0">
              <img  style="width: 100%; max-width: 38px; height: 24px;" alt="" src="../../../../assets/img/container_icon_2.png" >
              <span style="font-size: small; font-weight: bold; margin-left: 5px;">{{offer.directSalePrograms[0].containersPerWeek * getTotalWeekRow(offer.directSalePrograms[0]) }} </span>
            </div>
            <div class="flex align-items-center justify-content-center" *ngIf="offer.directSalePrograms[0].type === 1">
              <span class="material-symbols-outlined" style="color: #8c2c85;">pallet</span>
              <span style="font-size: small; font-weight: bold; margin-left: 5px;">{{offer.directSalePrograms[0].palletsPerWeek * getTotalWeekRow(offer.directSalePrograms[0]) }} </span>
            </div>
            <div class="flex align-items-center justify-content-end">
              <span style="font-weight: bolder; color: #8c2b87; font-size: small; height: auto;" >{{offer.exportOffer.listPrice | currency: 'USD':'US$'}}/{{offer.exportOffer.typeSale}}</span>
            </div>
          </div>
        </div>
      </div>
      
      <div class="col-12 md:col-6 flex align-items-center" style="padding-bottom: 20px; border-bottom: 1px solid #ccc;">
        <div class="w-full grid" style="margin-left: 10px;">
          <div class="col-12 md:col-9 flex justify-content-between align-items-center">
            <div class="flex justify-content-start align-items-center">
              <img [src]="'../../../../assets/img/flags/' + offer.exportOffer.country?.code + '.png'" height="50px" alt=""/>
              <div class="flex flex-column" style="margin-left: 10px;">
                <span style="font-weight: bolder; font-size: small;">{{offer.exportOffer.country?.name}}</span>
                <!-- <span style="font-size: small;">{{'UserPages.UserClosedSale.ProductDetail.OriginPortText' | translate}} (?)</span> -->
              </div>
            </div>
            <span class="material-symbols-outlined" style="color: #6da043;" *ngIf="offer.directSalePrograms[0].type === 0">directions_boat</span>
            <span class="material-symbols-outlined" style="color: #6da043;" *ngIf="offer.directSalePrograms[0].type === 1">flight</span>
            <span class="material-symbols-outlined" style="color: #6da043;">arrow_forward_ios</span>

            <div class="flex justify-content-start align-items-center" *ngIf="offer.directSalePrograms[0].type === 0">
              <img [src]="'../../../../assets/img/flags/' + offer.directSalePrograms[0].destinationCountry.code + '.png'" height="50px" alt=""/>
              <div class="flex flex-column" style="margin-left: 10px;">
                <span style="font-weight: bolder; font-size: small;">{{offer.directSalePrograms[0].destinationCountry.name}}</span>
                <span style="font-size: small;">
                  <!-- {{'UserPages.UserClosedSale.ProductDetail.DestinationPortText' | translate}} -->
                  {{offer.directSalePrograms[0].dischargePort.name}}
                </span>
              </div>
            </div>

            <div class="flex justify-content-start align-items-center" *ngIf="offer.directSalePrograms[0].type === 1">
              <img [src]="'../../../../assets/img/flags/' + offer.directSalePrograms[0].destinationCountry.code + '.png'" height="50px" alt=""/>
              <div class="flex flex-column" style="margin-left: 10px;">
                <span style="font-weight: bolder; font-size: small;">{{offer.directSalePrograms[0].destinationCountry.name}}</span>
                <span style="font-size: small;">
                  <!-- {{'UserPages.UserClosedSale.ProductDetail.DestinationPortText' | translate}} -->
                  {{offer.directSalePrograms[0].airport?.name}}
                </span>
              </div>
            </div>

          </div>
          <div class="col-12 md:col-3 flex justify-content-end align-items-center">
            <p-tag *ngIf="offer.directSalePrograms[0].isActive" [value]="getStatusValue(1)" severity="success" [style]="{ 'display': 'grid', 'width': '80%', 'min-width': '60px', 'background-color': '#C3DBA2', 'color': '#4D6A34'}"></p-tag>
            <p-tag *ngIf="!offer.directSalePrograms[0].isActive" [value]="getStatusValue(0)" [style]="{ 'display': 'grid', 'width': '80%', 'min-width': '60px', 'background-color': '#F1D0D0', 'color': '#9E4343'}"></p-tag>
            <div style="margin-left: 5px;">
              <p-button 
              [icon]="offer.expanded ? 'pi pi-angle-up' : 'pi pi-angle-down'" 
              styleClass="p-button-rounded p-button-outlined"
              (click)="setVisibleOrderDetail(offer)"
              >
              </p-button>
            </div>
          </div>
        </div>
      </div>
      <div  *ngIf="offer.expanded" class="col-12 md:col-6">
        <div>
          <div class="grid">
            <div class="col-12">
              <span class="section-title">{{'UserPages.UserClosedSale.ProductDetailText' | translate}}</span>
            </div>
            <div class="col-6 flex flex-row align-items-center">
              <span class="material-symbols-outlined info-icon">
                pallet
                </span>
               <span class="info-text">
                {{'UserPages.UserClosedSale.ProductDetail.CasePerPalletsText' | translate}}: 
                 {{offer.exportOffer.caseQuantityPerPallet}}
               </span>
            </div>
            <div class="col-6 flex flex-row align-items-center">
              <div class="flex align-items-center">
                <span class="material-symbols-outlined info-icon">science</span>
                <span class="info-text">
                  {{'UserPages.UserClosedSale.ProductDetail.QualityControlCriteriaText' | translate}}:
                  {{getIncludedLabel(offer.exportOffer.isQualityControl)}}
                  <p-button *ngIf="offer.exportOffer?.qualityDocuments?.length" (onClick)="getExportOfferQualityDocument(offer.exportOffer.qualityDocuments)" pTooltip="{{'UserPages.UserExportOffers.EditExportOffer.ViewQualityDocument' | translate}}" [style]="{ 'margin': 0, 'padding': 0 }" icon="pi pi-file-pdf" styleClass="p-button-link p-button-lg"></p-button>
                </span>
              </div>
            </div>
            <div class="col-6 flex flex-row align-items-center">
              <span class="material-symbols-outlined info-icon" >
                weight
                </span>
                <span class="info-text">
                  {{'UserPages.UserClosedSale.ProductDetail.NetKilosText' | translate}}:
                  {{offer.exportOffer.netKilos ? offer.exportOffer.netKilos + ' kg' : '-' }}
                </span>
            </div>
            <div class="col-6 flex flex-row align-items-center" *ngIf="offer.directSalePrograms[0].type === 0">
              <img class="info-icon" style="width: 100%; max-width: 25px; height: 20px;" alt="" src="../../../../assets/img/container_icon_2.png" >
                <span class="info-text">
                  {{'UserPages.UserClosedSale.ProductDetail.ProgramContainersText' | translate}}:
                  {{offer.directSalePrograms[0].containersPerWeek ?  offer.directSalePrograms[0].containersPerWeek : '-'}}
                </span> 
            </div>
            <div class="col-6 flex flex-row align-items-center" *ngIf="offer.directSalePrograms[0].type === 1">
              <span class="material-symbols-outlined info-icon">
                pallet
                </span>
                <span class="info-text">
                  {{'UserPages.UserClosedSale.ProductDetail.ProgramPalletsText' | translate}}:
                  {{offer.directSalePrograms[0].palletsPerWeek ?  offer.directSalePrograms[0].palletsPerWeek : '-'}}
                </span> 
            </div>
            <div class="col-6 flex flex-row align-items-center">
              <span class="material-symbols-outlined info-icon">
                inventory_2
                </span>
                <span class="info-text">
                  {{'UserPages.UserClosedSale.ProductDetail.BoxTypeText' | translate}}:
                  {{offer.exportOffer.caseSize ? offer.exportOffer.caseSize  : '-'}}
                </span>
            </div>
            <div class="col-6 flex flex-row align-items-center">
              <span class="material-symbols-outlined info-icon" >
                paid
                </span>
              <span class="info-text">
                {{'UserPages.UserClosedSale.ProductDetail.PaymentMethodText' | translate}}: 
                 {{offer.exportOffer.paymentAgainstBL + '%' + '/' + offer.exportOffer.typeSale }}
              </span>
            </div>
            <div class="col-6 flex flex-row align-items-center">
              <span class="material-symbols-outlined info-icon" >
                date_range
                </span>
              <span class="info-text">
                {{'UserPages.UserClosedSale.ProductDetail.WeekNumberText' | translate}}: 
                 {{getTotalWeekRow(offer.directSalePrograms[0]) }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="offer.expanded" class="col-12 md:col-6">
        <div class="grid" style="margin-left: 5px;">
          <div class="col-5">
            <span class="section-title">{{'UserPages.UserClosedSale.AgreementText' | translate}}</span>
            <div *ngIf="profile?.type == 1" class="col-12 flex flex-row align-items-center">
              <span class="material-symbols-outlined info-icon" style="font-size: 17px;">
                person
                </span>
              <span class="info-text-program">
                Importer:
              </span>
              <span class="program-info">
                {{offer.directSalePrograms[0].importerCompanyName}}
              </span>
            </div>
            <div *ngIf="profile?.type == 2" class="col-12 flex flex-row align-items-center">
              <span class="material-symbols-outlined info-icon" style="font-size: 17px;">
                person
                </span>
              <span class="info-text-program">
                Exporter:
              </span>
              <span class="program-info">
                {{offer.exportOffer.exporter.companyName}}
              </span>
            </div>
            <div class="col-12 flex flex-row align-items-center">
              <span class="material-symbols-outlined info-icon" style="font-size: 17px;">
                person
                </span>
              <span class="info-text-program">
                Consignee:
              </span>
              <span class="program-info">
                {{offer.directSalePrograms[0].consigneeCompanyName}}
              </span>
            </div>
            <div class="col-12 flex flex-row align-items-center">
              <span class="material-symbols-outlined info-icon">
                person
              </span>
              <span class="info-text-program">
                Notify:
              </span>
              <span class="program-info">
                {{offer.directSalePrograms[0].notifyCompanyName}}
              </span>
            </div>
          </div>
          <div class="col-6">
            <span class="section-title">{{'UserPages.UserClosedSale.week/yearText' | translate}}</span>
            <div class="col-12">
              <div class="grid">
                <div class="col-6 flex flex-column">
                  <div class="flex flex-row justify-content-start gap-1">
                    <div class="flex align-items-center" style="width: 67px; margin-right: 5px;">
                      <span class="material-symbols-outlined info-icon">
                        calendar_today
                      </span>
                      <span class="info-text">
                        {{'UserPages.UserClosedSale.FromProgramText' | translate}}
                      </span>
                    </div>
                    <div class="flex align-items-center">
                      <span class="info-text">
                        {{offer.directSalePrograms[0].startWeek}} / {{offer.directSalePrograms[0].startWeekYear}}
                    </span>
                    </div>
                  </div>
                  <div class="flex flex-row justify-content-start gap-1">
                    <div class="flex align-items-center" style="width: 67px; margin-right: 5px;">
                      <span class="material-symbols-outlined info-icon">
                        calendar_today
                      </span>
                      <span class="info-text">
                        {{'UserPages.UserClosedSale.ToProgramText' | translate}}
                      </span>
                    </div>
                    <div class="flex align-items-center">
                      <span class="info-text">
                        {{offer.directSalePrograms[0].endWeek}} / {{offer.directSalePrograms[0].endWeekYear}}
                    </span>
                    </div>
                  </div>
                  <div>
                    <div style="margin-top: 10px;">
                      <p-button pTooltip="{{'DirectSaleActions.ViewProgramText' | translate}}" styleClass="p-button-outlined p-button-sm" [style]="{'height': '30px'}" (onClick)="goToDirectSaleProgramOrders(offer.directSalePrograms[0].guid)">
                        <span class="material-symbols-outlined" style="font-size: 25px;">
                          visibility
                        </span>
                        <span style="margin-left: 6px">
                          {{'UserPages.UserClosedSale.ProgramTitle' | translate}}
                        </span>
                      </p-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-1 flex flex-column">
            <div class="flex justify-content-end" pTooltip="{{'DirectSaleActions.ViewActionText' | translate}}"  tooltipPosition="bottom">
              <p-menu #menuView [model]="getMenuItemsForView(offer)" [popup]="true" appendTo="body"/>
                  <a class="round-icon view-icon" (click)="menuView.toggle($event)">
                    <span class="material-symbols-outlined">
                      visibility
                    </span>
                  </a>
            </div>
            <div *ngIf="profile?.type == 1" class="flex justify-content-end" pTooltip="{{'DirectSaleActions.EditActionText' | translate}}"  tooltipPosition="bottom">
                <p-menu #menuEdit [model]="getMenuItemsForEdit(offer)" [popup]="true" appendTo="body"/>
                <a class="round-icon edit-icon"  (click)="menuEdit.toggle($event)">
                  <span class="material-symbols-outlined">
                    edit
                  </span>
                </a>
            </div>
            <div *ngIf="profile?.type == 1" class="flex justify-content-end" pTooltip="{{'DirectSaleActions.CopyActionText' | translate}}"  tooltipPosition="bottom" >
              <p-menu #menuCopy [model]="getMenuItemsForCopy(offer)" [popup]="true" appendTo="body"/>
              <a class="round-icon edit-icon"  (click)="menuCopy.toggle($event)">
                <span class="material-symbols-outlined" >
                  file_copy
                </span>
              </a>
            </div>
            <div *ngIf="profile?.type == 1" class="flex justify-content-end" pTooltip="{{'DirectSaleActions.DeleteActionText' | translate}}"  tooltipPosition="bottom">
              <p-menu #menuDelete [model]="getMenuItemForDelete(offer)" [popup]="true" appendTo="body"/>
              <a class="round-icon delete-icon" (click)="menuDelete.toggle($event)">
                <span class="material-symbols-outlined">
                  delete
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </p-card>
