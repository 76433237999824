<!-- <app-user-dashboard [userMenuActiveItemIndex]="0" [activeItem]="'externalMarketplace'" [fullScreen]="true"> -->
  <app-user-dashboard [fullScreen]="true">
    <app-content-body>
      <div class="grid justify-content-center">
        <div class="col-11">
          <p-panel>
            <form [formGroup]="formGroup">
              <div class="p-fluid grid">
                <div class="col-12 md:col-4 lg:col-2">
                  <span class="p-float-label">
                    <p-dropdown
                      [options]="originCountries"
                      formControlName="countryFromId"
                      optionLabel="name"
                      optionValue="id"
                      (onChange)="loadExportOffers()"
                      (onClear)="clearSpotSearchParams()"
                      [filter]="true"
                      filterBy="name"
                      [showClear]="true"
                      placeholder="{{'MarketplacePage.Spot.ImportFromFieldPlaceholder' | translate}}"
                      class="p-inputtext-sm">
                      <ng-template pTemplate="selectedItem">
                        <div class="flex align-items-center gap-2" *ngIf="selectedCountryFrom">
                          <img [src]="'../../../../assets/img/flags/' + selectedCountryFrom.code + '.png'" style="width: 18px" alt="flag"/>
                          <div>{{ selectedCountryFrom | countryDescription }}</div>
                        </div>
                      </ng-template>
                      <ng-template let-country pTemplate="item">
                        <div class="flex align-items-center gap-2">
                          <img [src]="'../../../../assets/img/flags/' + country.code + '.png'" style="width: 18px" alt="flag"/>
                          <div>{{ country | countryDescription }}</div>
                        </div>
                      </ng-template>
                    </p-dropdown>
                    <label>{{'MarketplacePage.Spot.ImportFromFieldLabel' | translate}}</label>
                  </span>
                </div>
                <div class="col-12 md:col-4 lg:col-6">
                  <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input type="text" pInputText (input)="filterGlobal($event)"
                      placeholder="{{'MarketplacePage.Spot.SearchProducts' | translate}}" class="p-inputtext-sm" />
                  </span>
                </div>
              </div>
            </form>
          </p-panel>
        </div>

        <div class="col-11">
          <p-panel>
              <p-table #dt1
              [value]="offers"
              dataKey="adsCode"
              [paginator]="true"
              [rows]="10"
              [showCurrentPageReport]="true"
              [rowsPerPageOptions]="[10, 25, 50]"
              [loading]="loading"
              [globalFilterFields]="['variety.species.name', 'variety.name', 'speciesSize']"
              responsiveLayout="stack"
              [breakpoint]="'600px'">

              <ng-template pTemplate="header">
                <tr>
                  <th>
                    <div class="flex align-items-center">
                      {{'MarketplacePage.Spot.MarketPlaceTable.CountryHeader' | translate}}
                    </div>
                  </th>
                  <th>
                    <div class="flex align-items-center">
                      {{'MarketplacePage.Spot.MarketPlaceTable.SpecieHeader' | translate}}
                      <p-columnFilter field="variety.species.nameEs" matchMode="in" display="menu"
                        [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                        <ng-template pTemplate="header">
                          <div class="px-3 pt-3 pb-0">
                            <span class="font-bold">{{'MarketplacePage.Spot.MarketPlaceTable.SelectSpecieText' |
                              translate}}</span>
                          </div>
                        </ng-template>
                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                          <p-multiSelect [options]="filterSpecies" [ngModel]="value"
                            placeholder="{{'MarketplacePage.Spot.MarketPlaceTable.SelectText' | translate}}"
                            (onChange)="filter($event.value)" optionValue="nameEs"
                            optionLabel="nameEs"></p-multiSelect>
                        </ng-template>
                      </p-columnFilter>
                    </div>
                  </th>
                  <th pSortableColumn="variety.name">
                    <div class="flex align-items-center">
                      {{'MarketplacePage.Spot.MarketPlaceTable.VarietyHeader' | translate}}
                      <p-columnFilter field="variety.name" matchMode="in" display="menu"
                        [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                        <ng-template pTemplate="header">
                          <div class="px-3 pt-3 pb-0">
                            <span class="font-bold">{{'MarketplacePage.Spot.MarketPlaceTable.SelectVarietyText' |
                              translate}}</span>
                          </div>
                        </ng-template>
                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                          <p-multiSelect [options]="filterVarieties" [ngModel]="value"
                            placeholder="{{'MarketplacePage.Spot.MarketPlaceTable.SelectText' | translate}}"
                            (onChange)="filter($event.value)" optionValue="name" optionLabel="name"></p-multiSelect>
                        </ng-template>
                      </p-columnFilter>
                      <p-sortIcon field="variety.name"></p-sortIcon>
                    </div>
                  </th>
                  <th pSortableColumn="speciesSize">
                    <div class="flex align-items-center">
                      {{'MarketplacePage.Spot.MarketPlaceTable.SizeHeaderHeader' | translate}}
                      <p-columnFilter type="text" field="offers.speciesSize" display="menu"
                        [showAddButton]="false" [showMatchModes]="false" [showOperator]="false"
                        [matchMode]="'contains'"></p-columnFilter>
                      <p-sortIcon field="speciesSize"></p-sortIcon>
                    </div>
                  </th>
                  <th>
                    <div class="flex align-items-center">
                      {{'MarketplacePage.Spot.MarketPlaceTable.QualityControlHeader' | translate}}
                      <p-columnFilter type="boolean" field="offers.isQualityControl" display="menu"
                        [showAddButton]="false" [showMatchModes]="false" [showOperator]="false"></p-columnFilter>
                    </div>
                  </th>
                  <th pSortableColumn="harvestDate">
                    <div class="flex align-items-center">
                      {{'MarketplacePage.Spot.MarketPlaceTable.HarvestDateHeader' | translate}}
                      <p-columnFilter type="date" field="HarvestDateHeader" [locale]="locale"
                        display="menu" [showAddButton]="false" [showMatchModes]="false"
                        [showOperator]="false"></p-columnFilter>
                      <p-sortIcon field="harvestDate"></p-sortIcon>
                    </div>
                  </th>
                  <!-- <th>
                    <div class="flex align-items-center">
                      {{'MarketplacePage.Spot.MarketPlaceTable.LocationHeader' | translate}}
                      <p-columnFilter type="text" field="offers.fridgeStorage.contactCountry.name" display="menu"
                        [showAddButton]="false" [showMatchModes]="false" [showOperator]="false"
                        [matchMode]="'contains'"></p-columnFilter>
                    </div>
                  </th> -->
                  <th>
                    <div class="flex align-items-center">
                      {{'MarketplacePage.Spot.MarketPlaceTable.CasePerPalletsHeader' | translate}}
                    </div>
                  </th>
                  <th pSortableColumn="listPrice">
                    <div class="flex align-items-center">
                      {{'MarketplacePage.Spot.MarketPlaceTable.ListPrice' | translate}}
                      <p-sortIcon field="listPrice"></p-sortIcon>
                    </div>
                  </th>
                  <th pSortableColumn="availablePallets">
                    <div class="flex align-items-center">
                      {{'MarketplacePage.Spot.MarketPlaceTable.AvailablePalletsHeader' | translate}}
                      <p-sortIcon field="availablePallets"></p-sortIcon>
                    </div>
                  </th>
                  <th colspan="2">
                    <div class="flex align-items-center">
                      {{'MarketplacePage.Spot.MarketPlaceTable.SeeAdOffer' | translate}}
                    </div>
                  </th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-offer>
                <tr [pSelectableRow]="offer">
                  <!--
                  -->
                  <td><img [src]="'../../../../assets/img/flags/' + offer.country.code + '.png'" height="50px" alt=""/></td>
                  <td><img [src]="offer.variety.urlIcon" height="70px" style="padding: 2px; border-radius: 7px;" alt=""/></td>
                  <td>{{offer.variety.name}}</td>
                  <td>{{offer.speciesSize}}</td>
                  <td>{{offer.isQualityControl ? 'Si' : 'No'}}</td>
                  <td>{{offer.harvestDate | date: 'dd-MM-yyyy'}}</td>
                  <td>{{offer.caseQuantityPerPallet}}</td>
                  <td>{{offer.listPrice | currency: 'USD':'US$'}}{{offer.typeSale}}</td>
                  <td>{{offer.availablePallets}}</td>
                  <td><p-button icon="pi pi-eye" pTooltip="{{'MarketplacePage.Spot.MarketPlaceTable.SeeAdOffer' | translate}}" tooltipPosition="left"
                      (onClick)="navigateToDetail(offer.adsCode)"></p-button>
                  </td>
                </tr>
              </ng-template>

              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="18">{{'MarketplacePage.Spot.MarketPlaceTable.EmptyResultsText' | translate}}</td>
                </tr>
              </ng-template>
            </p-table>
          </p-panel>
        </div>
      </div>
    </app-content-body>
  </app-user-dashboard>
<!-- </app-user-dashboard> -->
<p-toast></p-toast>
<app-footer-home></app-footer-home>

