import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService, TreeNode } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { ProfileGateway } from 'src/app/application/gateway/ProfileGateway';
import { Profile } from 'src/app/domain/models/Profile';
import { Routes } from 'src/app/infrastructure/routes';
import { rutVerifierDigit } from 'src/app/ui/util/custom-validators';

@Component({
  selector: 'app-user-company-info',
  templateUrl: './user-company-info.component.html',
  styleUrls: ['./user-company-info.component.css'],
  providers: [MessageService]
})
export class UserCompanyInfoComponent implements OnInit {

  uploadedFiles: any[] = []
  products: any
  selectedProducts: TreeNode | undefined
  submit: boolean = false
  loading: boolean = false
  disabledFields: boolean = true

  profile: Profile | undefined

  formGroup: FormGroup = this._formBuilder.group({
    companyName: [{value: '', disabled: true}, Validators.required],
    vat: [{value: null, disabled: true}, [Validators.required]],
    sag: [{value: null, disabled: true}],
    legalRepresentative: [{value: '', disabled: true}, Validators.required],
    legalRepresentativeId: [{value: '', disabled: true}, Validators.required]
  })


  constructor(private _router: Router,
    private _formBuilder: FormBuilder,
    private _messageService: MessageService,
    private _authService: AuthGateway,
    private _profileService: ProfileGateway,
    public translate: TranslateService) {
  }

  ngOnInit(): void {

    this._profileService.getByUsername().subscribe({
      next: (v) => {
        this.profile = v
        this.loadForm()
        this.formGroup.controls['vat'].addValidators(rutVerifierDigit(this.profile.type, this.profile.contactCountryId))
      },
      error: (e) => {
        console.log(e)
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.ProfileMessageService.SingleRetrieveError') });
      },
      complete: () => console.info('Profile query successfully')
    })
  }

  loadForm() {
    if(this.profile) {
      this.formGroup.controls["companyName"].setValue(this.profile.companyName)
      this.formGroup.controls["vat"].setValue(this.profile.vat)
      this.formGroup.controls["sag"].setValue(this.profile.sagCode)
      this.formGroup.controls["legalRepresentative"].setValue(this.profile.legalRepresentativeName)
      this.formGroup.controls["legalRepresentativeId"].setValue(this.profile.legalRepresentativeNumber)
    }
  }

  validateSII() {
    /*
    if(this.profile && this.profile.contactCountry.code == 'CL' && !this.formGroup.getError('verifierDigitError')) {
      let rut = this.formGroup.controls["vat"].value
      this._profileService.validateSII(rut).subscribe({
        next: (v) => {
          console.log(v)
        },
        error: (e) => {
          console.log(e)
          this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'Error validando el RUT en SII API' });
        },
        complete: () => console.info('VAT validation query successfully')
      })
    }*/
  }

  save(): void {
    this.loading = true
    this.submit = true

    if(this.formGroup.valid && this.profile) {
      this.profile.companyName = this.formGroup.controls["companyName"].value
      this.profile.vat = this.formGroup.controls["vat"].value
      this.profile.sagCode = this.formGroup.controls["sag"].value
      this.profile.legalRepresentativeName = this.formGroup.controls["legalRepresentative"].value
      this.profile.legalRepresentativeNumber = this.formGroup.controls["legalRepresentativeId"].value
      this.profile.updaterUsername = this._authService.getUsername()
      this.profile.pdf = this.uploadedFiles[0]

      this._profileService.update(this.profile).subscribe({
        next: (v) => {
          this.profile = v
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: this.translate.instant('MessageService.ProfileMessageService.EditSuccess') });
          this.loadForm()
          this.submit = false
          this.loading = false
        },
        error: (e) => {
          this.loading = false
          console.log(e)
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ProfileMessageService.EditError') });
        },
        complete: () => console.info('Profile update successfully')
      })
    }
    else {
      this.loading = false
    }
  }

  onSelect(event: any) {
    this.uploadedFiles = []
    for(let file of event.files) {
        this.uploadedFiles.push(file);
    }
  }

  setEnableEdit() {
    this.disabledFields = !this.disabledFields
    let fields = ["companyName", "vat", "sag", "legalRepresentative", "legalRepresentativeId"]

    if(this.disabledFields) {
      fields.forEach(x => this.formGroup.controls[x].disable())
    }
    else {
      fields.forEach(x => this.formGroup.controls[x].enable())
    }
  }

  getErrorMessage(fieldName: string): string {
    if (this.formGroup.controls[fieldName].hasError('required')) {
      return this.translate.instant('FormErrorMessage.FieldValueRequired')
    }
    if (this.formGroup.controls[fieldName].hasError('email')) {
      return this.translate.instant('FormErrorMessage.EmailFieldEmailFormatError')
    }
    if (this.formGroup.controls[fieldName].hasError('verifierDigitError')) {
      return this.translate.instant('FormErrorMessage.RutError')
    }
    return ''
  }

  get verifierDigitError(): boolean {
    return this.formGroup.getError('verifierDigitError')
  }

  onCancel() {
    this._router.navigate(['/'+Routes.userPanelPage])
  }
}
