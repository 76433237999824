import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { FrozenFruitExportOfferGateway } from 'src/app/application/gateway/FrozenFruitExportOfferGateway';
import { FrozenFruitExportOffer, FrozenFruitExportOfferUploadPicturesRequest } from 'src/app/domain/models/FrozenFruitExportOffer';
import { Routes } from 'src/app/infrastructure/routes';
import { environment } from 'src/environments/environment';

interface Picture {
  url?: string
  file?: any
}

@Component({
  selector: 'app-frozen-fruit-export-offer-edit-pictures',
  templateUrl: './frozen-fruit-export-offer-edit-pictures.component.html',
  styleUrls: ['./frozen-fruit-export-offer-edit-pictures.component.css'],
  providers: [MessageService]
})
export class FrozenFruitExportOfferEditPicturesComponent implements OnInit {

  frozenFruitExportOfferId: number | undefined
  exportOffer: FrozenFruitExportOffer | undefined

  loading: boolean = false

  pictures: any[] = [
    {
      url: null,
      file: null
    },
    {
      url: null,
      file: null
    },
    {
      url: null,
      file: null
    }
  ]

  uploadedImages: any[] = []

  constructor(private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _messageService: MessageService,
    public translate: TranslateService,
    private _frozenFruitExportOfferService: FrozenFruitExportOfferGateway,) {
    this._activatedRoute.params.subscribe(params => {
      this.frozenFruitExportOfferId = params['frozenFruitExportOfferId']
    })
  }

  ngOnInit(): void {
    this.loadFrozenFruitExportOffer()
  }

  loadFrozenFruitExportOffer() {
    if(this.frozenFruitExportOfferId) {
      this.loading = true
      this._frozenFruitExportOfferService.getById(this.frozenFruitExportOfferId).subscribe({
        next: (v) => {
          this.exportOffer = v
          this.loadPictures()
          this.loading = false
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOffer.SingleRetrieveError') })
        },
        complete: () => console.info('Frozen fruit export offer query successfully')
      })
    }
  }

  loadPictures() {
    if(this.exportOffer) {
      if(this.exportOffer.pictureUrl1)
        this.pictures[0] = { url: environment.apiUrl + this.exportOffer.pictureUrl1 }

      if(this.exportOffer.pictureUrl2)
        this.pictures[1] = { url: environment.apiUrl + this.exportOffer.pictureUrl2 }

      if(this.exportOffer.pictureUrl3)
        this.pictures[2] = { url: environment.apiUrl + this.exportOffer.pictureUrl3 }
    }
  }

  onSelectImage(event: any, uploader: any, index: number): void {
    this.pictures[index] =
    {
      url: uploader.files[0].objectURL.changingThisBreaksApplicationSecurity,
      file: uploader.files[0]
    }
    uploader.clear()
  }

  onRemoveImage(index: number): void {
    if(this.pictures[index]) {
      this.pictures[index] =
      {
        url: null,
        file: null
      }
    }
  }

  savePictures() {
    if(this.exportOffer) {

      const request: FrozenFruitExportOfferUploadPicturesRequest = {
        frozenFruitExportOfferId: this.exportOffer.id,
        picture1: this.pictures[0].file ? this.pictures[0].file : null,
        picture2: this.pictures[1].file ? this.pictures[1].file : null,
        picture3: this.pictures[2].file ? this.pictures[2].file : null,
      }

      this.loading = true
      this._frozenFruitExportOfferService.uploadProductPictures(request).subscribe({
        next: (v) => {
          this.loading = false
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: this.translate.instant('MessageService.ExportOffer.EditSuccess') })
        },
        error: (e) => {
          this.loading = false
          console.log(e)
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOffer.EditError') })
        },
        complete: () => console.info('Pictures uploaded successfully.')
      })
    }
  }

  goToFrozenFruitExportOffersPage() {
    this._router.navigate(['/' + Routes.userFrozenFruitExportOffersActivePage])
  }

  getImageUrl(picture: Picture): any {
    if(picture) {
      if(picture.file) {
        return picture.file.objectURL
      }
      else {
        return picture.url
      }
    }
    return null
  }

  getImageName(picture: Picture): any {
    if(picture) {
      if(picture.file) {
        return picture.file.name
      }
      else if(picture.url) {
        const urlParts: string[] = picture.url.split('/')
        return urlParts[urlParts.length-1]
      }
    }
    return null
  }
}
