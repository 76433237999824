import { Component, Input } from '@angular/core';
import { Exporter } from 'src/app/domain/models/Profile';

@Component({
  selector: 'app-exporter-info-modal',
  templateUrl: './exporter-info-modal.component.html',
  styleUrls: ['./exporter-info-modal.component.css']
})
export class ExporterInfoModalComponent {

  @Input()
  exporterInfo: Exporter | undefined

}
