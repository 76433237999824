import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ForwarderGateway } from "../gateway/ForwarderGateway";
import { AssignExporterImporter, ForwarderRelations, ForwarderReview, ForwarderReviewItemList, ForwarderUserCreateRequest } from "src/app/domain/models/ForwarderReview";
import { Routes } from "src/app/infrastructure/routes";
import { AssignForwarder, ProfileCompayList } from "src/app/domain/models/Company";

@Injectable({providedIn: 'root'})
export class ForwarderService extends ForwarderGateway {

  constructor(private _http: HttpClient) {
    super()
  }

  getAllByStatusAndReviewType(reviewType: number): Observable<ForwarderReviewItemList[]> {
    return this._http.get<ForwarderReviewItemList[]>(Routes.forwarderReviewApi + '/GetAllByStatusAndReviewType', {params: {reviewType}})
  }

  getByReviewId(guid: string): Observable<ForwarderReview> {
    return this._http.get<ForwarderReview>(Routes.forwarderReviewApi + '/GetByReviewId', {params: {guid}})
  }
  
  createUser(request: ForwarderUserCreateRequest): Observable<any> {
    return this._http.post<any>(Routes.forwarderReviewApi + '/CreateUser', request)
  }
  
  getForwardersByAdmin(): Observable<ProfileCompayList[]> {
    return this._http.get<ProfileCompayList[]>(Routes.profileCompanyApi + '/GetCompanyUsersByAdmin')
  }

  assignForwarder(request: AssignForwarder): Observable<any> {
    return this._http.put<any>(Routes.forwarderReviewApi + '/AssignForwarder', request)
  }
  
  uploadLoadFile(request: any): Observable<any> {
    return this._http.post<any>(Routes.forwarderApi + '/UploadLoad', this.getRequestFile(request));
  }
  
  getRequestFile(request: any){
    const formData = new FormData();
    
    if(request) {
      if(request.file) formData.append("file", request.file)
      }
    return formData;
  }

  forwarderRelations(): Observable<ForwarderRelations> {
    return this._http.get<ForwarderRelations>(Routes.forwarderApi + '/GetForwarderRelations')
  }

  assignExporterImporter(request: AssignExporterImporter): Observable<any> {
    return this._http.put<any>(Routes.forwarderApi + '/AssignExporterImporter', request);
  }

}
