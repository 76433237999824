import { Component } from '@angular/core';



@Component({
  selector: 'app-forwarder-create-order-page',
  templateUrl: './forwarder-create-order-page.component.html',
  styleUrls: ['./forwarder-create-order-page.component.css']
})
export class ForwarderCreateOrderPageComponent {
  EXPORTERS = 'exporters'
  CREATE_EXPORT_OFFER = 'create-export-offer'

  step: string = this.EXPORTERS

  goToExporters() {
    this.step = this.EXPORTERS
  }

  goToCreateExportOfferStep() {
    this.step = this.CREATE_EXPORT_OFFER
  }
}
