import { Component, Inject, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { EstimationGateway } from 'src/app/application/gateway/EstimationGateway';
import { ExportOfferGateway } from 'src/app/application/gateway/ExportOfferGateway';
import { ImportOrderGateway } from 'src/app/application/gateway/ImportOrderGateway';
import { OfferNegotiationGateway } from 'src/app/application/gateway/OfferNegotiationGateway';
import { PortGateway } from 'src/app/application/gateway/PortGateway';
import { CountryService } from 'src/app/application/service/CountryService';
import { Country } from 'src/app/domain/models/Country';
import { ExportOffer, ExportOfferInformation, MarketplaceResult } from 'src/app/domain/models/ExportOffer';
import { ImportOrder } from 'src/app/domain/models/ImportOrder';
import { NewNegotiation } from 'src/app/domain/models/OfferNegotiation';
import { Port } from 'src/app/domain/models/Port';
import { Estimation } from 'src/app/domain/models/Route';
import { Routes } from 'src/app/infrastructure/routes';
import { environment } from '../../../../environments/environment'
import { ExportOfferUtils } from '../../util/export-offer-utils';
import { OrderUtils } from '../../util/order-utils';
import { ImportRequestService } from 'src/app/application/service/ImportRequestService';
import { Variety } from 'src/app/domain/models/Variety';
import { Species } from 'src/app/domain/models/Species';
import { ImportRequest, ImportRequestInformation, ImportRequestMarketplace } from 'src/app/domain/models/ImportRequest';
import { VarietyGateway } from 'src/app/application/gateway/VarietyGateway';
import { SpeciesGateway } from 'src/app/application/gateway/SpeciesGateway';
import { Lang } from '../../util/langs';
import { BasicUser } from 'src/app/domain/models/User';
import { ProfileGateway } from 'src/app/application/gateway/ProfileGateway';
import { ImportRequestUtil } from '../../util/import-request-util';
import { TranslateService } from '@ngx-translate/core';
import { SpeciesUtils } from '../../util/species-utils';
import { ProductCertificateType } from 'src/app/domain/models/ProductCertificateType';
import { Packaging } from 'src/app/domain/models/Packaging';

const ViewTypes = {
	Grid: "grid",
	Cards: "cards"
}

export interface OfferDropdown {
  id: number,
  name: string,
  exportOffer: ExportOffer
}

@Component({
  selector: 'app-marketplace-page',
  templateUrl: './marketplace-page.component.html',
  styleUrls: ['./marketplace-page.component.css'],
  providers: [MessageService]
})
export class MarketplacePageComponent implements OnInit {

  viewType: string = ViewTypes.Grid
  viewTypeOptions: any[] = []
  loading: boolean = false
  loadingImportRequest: boolean = false

  selected: ExportOfferInformation | undefined
  selectedProductCertificateTypes: ProductCertificateType[] = []
  selectedPackagingTypes: Packaging[] = []

  selectedImportRequest: ImportRequestInformation | undefined
  selectedCountryFrom: Country | undefined
  selectedCountryTo: Country | undefined
  selectedImportRequestCountryFrom: Country | undefined
  selectedImportRequestCountryTo: Country | undefined

  visibleImportRequestDialog = false;

  tabIndex = 0;
  activeTab = 0;

  portFormGroup: FormGroup = this._formBuilder.group({
    countryFromId: ['', Validators.required],
    portToId: ['', Validators.required],
    countryToId: ['', Validators.required],
  })

  importRequestForm: FormGroup = this._formBuilder.group({
    speciesId: [''],
    varietyId: [''],
    countryToId: [''],
    week: [null],
    countryFromId: ['']
  })

  searchCountries: Array<number> = []

  marketplaceResult: MarketplaceResult | undefined
  offers: ExportOfferInformation[] = []
  originCountries: Country[] = []
  originCountriesOptions: any[] = []
  destinationPorts: Port[] = []
  destinationCountries: Country[] = []
  estimations: Estimation[] = []

  varieties: Variety[] = []
  species!: Species[]
  filterSpecies: Species[] = []
  filterVarieties: Variety[] = []
  selectedSpecies: Species | undefined
  selectedVariety: Variety | undefined

  requests: ImportRequestInformation[] = []
  requestOffers: OfferDropdown[] = []

  visibleOfferDetailDialog: boolean = false
  pallets: number = 0
  responsiveOptions!: any[];
  selectedOffer: OfferDropdown | undefined;

  incotermsOptions: any[] = ExportOfferUtils.getIncotermsOptions()

  submitNegotiation = false
  negotiationFormGroup: FormGroup = this._formBuilder.group({
    negociationPrice: ['', Validators.required],
    negociationIncoterm: ['', Validators.required],
    negociationPallets: [null, Validators.required],
    negotiationPaymentAgainstBL: [null, Validators.required]
  })

  profile: BasicUser | undefined

  years: any[] = []

  paymentBLItems: any[] = []

  sortOptions: any = []
  sortOrder!: number;
  sortField!: string;

  getYears() {
    let current = new Date().getFullYear()
    this.years.push({label: current, value: current})
    let next = current + 1
    this.years.push({label: next, value: next})
  }

  constructor(@Inject(LOCALE_ID) public locale: string,
    private _router: Router,
    private _formBuilder: FormBuilder,
    private _messageService: MessageService,
    private _authService: AuthGateway,
    private _importOrderService: ImportOrderGateway,
    private _exportOfferService: ExportOfferGateway,
    private _portService: PortGateway,
    private _countryService: CountryService,
    private _offerNegotiationService: OfferNegotiationGateway,
    private _estimationService: EstimationGateway,
    private _importRequestService: ImportRequestService,
    private _varietyService: VarietyGateway,
    private _speciesService: SpeciesGateway,
    public translate: TranslateService
    ) {
      this.loadOfferInfo()
  }

  ngOnInit(): void {

    this.profile = this._authService.getUser()

    let sortOptionsLabel1 = 'Price from lowest to highest'
    let sortOptionsLabel2 = 'Price from highest to lowest'
    let sortOptionsLabel3 = 'Shelf Life from lowest to highest'
    let sortOptionsLabel4 = 'Shelf Life from highest to lowest'
    let sortOptionsLabel5 = 'Harvest date from lowest to highest'
    let sortOptionsLabel6 = 'Harvest date from highest to lowest'
    let sortOptionsLabel7 = 'Available pallets from lowest to highest'
    let sortOptionsLabel8 = 'Available pallets from highest to lowest'

    let viewTypeOptionsLabel1 = 'Grid'
    let viewTypeOptionsLabel2 = 'Classic Marketplace'

    if(this.profile) {
      switch(this.profile.lang) {
        case 'en':
          sortOptionsLabel1 = 'Price from lowest to highest'
          sortOptionsLabel2 = 'Price from highest to lowest'
          sortOptionsLabel3 = 'Shelf Life from lowest to highest'
          sortOptionsLabel4 = 'Shelf Life from highest to lowest'
          sortOptionsLabel5 = 'Harvest date from lowest to highest'
          sortOptionsLabel6 = 'Harvest date from highest to lowest'
          sortOptionsLabel7 = 'Available pallets from lowest to highest'
          sortOptionsLabel8 = 'Available pallets from highest to lowest'
          viewTypeOptionsLabel1 = 'Grid'
          viewTypeOptionsLabel2 = 'Classic Marketplace'
          break
        case 'es':
          sortOptionsLabel1 = 'Precio menor a mayor'
          sortOptionsLabel2 = 'Precio mayor a menor'
          sortOptionsLabel3 = 'ShelfLife disponibles menor a mayor'
          sortOptionsLabel4 = 'ShelfLife disponibles mayor a menor'
          sortOptionsLabel5 = 'Fecha de cosecha menor a mayor'
          sortOptionsLabel6 = 'Fecha de cosecha mayor a menor'
          sortOptionsLabel7 = 'Pallets disponibles menor a mayor'
          sortOptionsLabel8 = 'Pallets disponibles mayor a menor'
          viewTypeOptionsLabel1 = 'Grilla'
          viewTypeOptionsLabel2 = 'Marketplace clásico'
          break
      }
    }

    this.viewTypeOptions = [
      {
        label: viewTypeOptionsLabel1,
        value: ViewTypes.Grid
      },
      {
        label: viewTypeOptionsLabel2,
        value: ViewTypes.Cards
      }
    ]

    this.sortOptions = [
      { label: sortOptionsLabel1, value: 'exportOffer.listPrice' },
      { label: sortOptionsLabel2, value: '!exportOffer.listPrice' },
      { label: sortOptionsLabel3, value: 'exportOffer.shelfLife' },
      { label: sortOptionsLabel4, value: '!exportOffer.shelfLife' },
      { label: sortOptionsLabel5, value: 'exportOffer.harvestDate' },
      { label: sortOptionsLabel6, value: '!exportOffer.harvestDate' },
      { label: sortOptionsLabel7, value: 'exportOffer.availablePallets' },
      { label: sortOptionsLabel8, value: '!exportOffer.availablePallets' }
    ]

    this.responsiveOptions = [
      {
          breakpoint: '1199px',
          numVisible: 1,
          numScroll: 1
      },
      {
          breakpoint: '991px',
          numVisible: 1,
          numScroll: 1
      },
      {
          breakpoint: '767px',
          numVisible: 1,
          numScroll: 1
      }
    ]

    //this.importRequestForm.controls['year'].setValue(new Date())
    this.importRequestForm.controls['week'].setValue(1)

    // this.loadOriginCountries()
    this.loadCountries()
    this.loadSpecies()
    this.loadFilterSpecies();
    this.getYears()

    //load result with parameters saved in localStorage
    if(localStorage.getItem('_marketplace_country_from_param') && localStorage.getItem('_marketplace_country_from_param') != 'null') {
      this.portFormGroup.controls['countryFromId'].setValue(parseInt(this.getLocalstorageNumber('_marketplace_country_from_param')))
      this.loadOfferInfo()

      if(localStorage.getItem('_marketplace_country_to_param') && localStorage.getItem('_marketplace_country_to_param') != 'null') {
        this.portFormGroup.controls['countryToId'].setValue(parseInt(this.getLocalstorageNumber('_marketplace_country_to_param')))
        this.loadDestinationPorts()

        if(localStorage.getItem('_marketplace_port_to_param') && localStorage.getItem('_marketplace_port_to_param') != 'null') {
          this.portFormGroup.controls['portToId'].setValue(parseInt(this.getLocalstorageNumber('_marketplace_port_to_param')))
          this.loadEstimations()
        }
      }
    }
  }

  loadSessionAdCode(){
    var sessionAdCode = sessionStorage.getItem("AdsCode");
    this.loading = true
    if(sessionAdCode){
      this._exportOfferService.getAdsMarketplaceResult(sessionAdCode).subscribe({
        next: (v) => {
          this.marketplaceResult = v
          this.offers = this.marketplaceResult.exportOffers
          if(v.exportOffers.length > 0)
            {
              this.portFormGroup.controls['countryFromId'].setValue(v.exportOffers[0].exportOffer.countryId)
            }
          this.getOriginCountries()
          sessionStorage.removeItem("AdsCode");
          this.loading = false
        },
        error: (e) => {
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOffer.ManyRetrieveError') })
        }
      })
    }
  }

  loadOfferInfo()
  {
    var sessionAdCode = sessionStorage.getItem("AdsCode");
    if(sessionAdCode){
      this.loadSessionAdCode();
    }else{
      this.loadExportOffers();
    }
  }

  loadExportOffers() {
    this.loading = true
    this._exportOfferService.getAllForMarketplace(this.portFormGroup.controls['countryFromId'].value).subscribe({
      next: (v) => {
        this.marketplaceResult = v
        this.offers = this.marketplaceResult.exportOffers
        this.configureOffers()
        this.loadEstimations()
        this.loadFilterSpecies()
        this.loadFilterVarieties()
        this.getOriginCountries()
        this.loading = false
        localStorage.setItem("_marketplace_country_from_param", this.portFormGroup.controls['countryFromId'].value)
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.ExportOffer.ManyRetrieveError') })
      },
      complete: () => {
        console.info('Marketplace query successfully')
      }
    })
  }

  getLocalstorageNumber(value?: string): string {
    if(!value)
      return '0'
    else
      return localStorage.getItem(value)!
  }

  configureOffers() {
    if(this.offers) {
      this.offers.forEach(x => {
        x.requiredPallets = 0
        x.exportOffer.harvestDate = new Date(x.exportOffer.harvestDate)
        x.exportOffer.pictures = x.pictures
      })
    }
  }

  //Se obtienen los países de origen a partir de los países de las ofertas existentes.
  getOriginCountries() {
    if(this.marketplaceResult) {
      this.originCountries = []
      this.originCountries = this.marketplaceResult.originCountries

      this.selectedCountryFrom = this.originCountries.find(x => x.id === this.portFormGroup.controls['countryFromId'].value)
    }
  }

  loadCountries() {
    this._countryService.getAll().subscribe({
      next: (v) => {
        this.destinationCountries = v

        localStorage.setItem("_marketplace_country_to_param", this.portFormGroup.controls['countryToId'].value)
        this.selectedCountryTo = this.destinationCountries.find(x => x.id === this.portFormGroup.controls['countryToId'].value)

        let allCountriesLabel = 'All countries'
        if(this.profile) {
          switch(this.profile.lang) {
            case 'en':
              allCountriesLabel = 'All countries'
              break

            case 'es':
              allCountriesLabel = 'Todos los países'
              break
          }
        }

        this.originCountriesOptions.push({name: allCountriesLabel, nameEs: allCountriesLabel, id: 0, code: 'WORLD'})
        this.destinationCountries.forEach(x => {
          this.originCountriesOptions.push({
              name: x.name,
              nameEs: x.nameEs,
              code: x.code,
              id: x.id
            })
        })
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Country.DestinationManyRetrieveError') })
      },
      complete: () => {
        console.info('Destination Countries query successfully')
      }
    })
  }

  loadDestinationPorts() {
    if(this.portFormGroup.controls['countryToId'].value) {
      this.selectedCountryTo = this.destinationCountries.find(x => x.id === this.portFormGroup.controls['countryToId'].value)
      this._portService.getDestinationPortsByCountry(this.portFormGroup.controls['countryToId'].value).subscribe({
        next: (v) => {
          this.destinationPorts = v
          localStorage.setItem("_marketplace_country_to_param", this.portFormGroup.controls['countryToId'].value)
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.Port.DestinationPortManyRetrieveError') })
        },
        complete: () => console.info('Destination Ports query successfully')
      })
    }
  }

  loadEstimations() {
    if(this.portFormGroup.controls['countryFromId'].value && this.portFormGroup.controls['portToId'].value) {
      this._estimationService.getAllByFilter(this.portFormGroup.controls['countryFromId'].value, this.portFormGroup.controls['portToId'].value).subscribe({
        next: (v) => {
          this.estimations = v
          localStorage.setItem("_marketplace_port_to_param", this.portFormGroup.controls['portToId'].value)
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.Estimation.ManyRetrieveError') })
        },
        complete: () => console.info('Destination Ports query successfully')
      })
    }
  }

  @ViewChild('dt1') dataTable: any

  filterGlobal(event: any) {
    this.dataTable.filterGlobal(event.target.value, 'contains')
  }

  getTotalPrice(offerInfo: ExportOfferInformation, event: any) {
    let requiredPallets = event.value ?? offerInfo.requiredPallets;
    if(requiredPallets > offerInfo.exportOffer.availablePallets){
      offerInfo.allowPurchase = false;
    }else{
      offerInfo.allowPurchase = true;
    }

    offerInfo.totalPrice = ExportOfferUtils.getTotalPrice(offerInfo.exportOffer, requiredPallets )
  }

  getOrderTitle(order: ImportOrder): string {
    return OrderUtils.getOrderTitle(order.id)
  }

  goToPurshaseOrders() {
    this._router.navigate([(Routes.purchaseOrdersPage)])
  }

  goToImportRequesDetail(importRequestGuid: string) {
    this._router.navigate(['/',Routes.importRequestDetail, importRequestGuid])
  }

  getHeader(offer: ExportOffer): string {
    if(this.profile)
      return SpeciesUtils.getSpeciesName(this.profile.lang, offer.variety.species).toUpperCase()
    return ''
  }

  getSubheader(offer: ExportOffer): string {
    return offer.variety && offer.variety.name ? offer.variety.name.toUpperCase() : ''
  }

  totalPrice = 0
  getTotalPriceOfferDialog(exportOffer: ExportOffer, event: any) {
    this.totalPrice = ExportOfferUtils.getTotalPrice(exportOffer, event.value)
  }

  get availablePallets(): number {
    if(this.selected && this.selected.exportOffer && this.selected.exportOffer.availablePallets) {
      return this.selected.exportOffer.availablePallets - this.pallets
    }
    return 0
  }

  get initPallets(): number {
    if(this.selected) {
      return this.selected.exportOffer?.palletQuantity ? this.selected.exportOffer?.palletQuantity : 0
    }
    return 0
  }

  getAvailablePallets(exportOffer: ExportOffer, requiredPallets: number) : number {
    if(exportOffer && exportOffer.availablePallets && requiredPallets) {
      return exportOffer.availablePallets - requiredPallets
    }
    return exportOffer.availablePallets ? exportOffer.availablePallets : 0
  }

  getExportOfferImageUrl(offer: ExportOfferInformation, index: number) {

    return ExportOfferUtils.getImageUrl(offer.exportOffer, offer.pictures,  index)
  }

  getExportImageUrlCar(url: string ){
    return environment.apiUrl + url;
  }

  getImageForMarketPlace(offer: ExportOfferInformation)
  {
    return ExportOfferUtils.getImageUrlIcon(offer.exportOffer);
  }

  showOfferDetailDialog() {
    if(this.portFormGroup.valid) {
      this.negotiationFormGroup.controls['negociationPrice'].setValue(this.selected?.exportOffer.listPrice)
      if(this.selected?.exportOffer.productCertificateTypes)
        this.selectedProductCertificateTypes = this.selected?.exportOffer.productCertificateTypes

      if(this.selected?.exportOffer.packagingTypes)
        this.selectedPackagingTypes = this.selected?.exportOffer.packagingTypes

      this.visibleOfferDetailDialog = true
    }
    else {
      this.selected = undefined
      this._messageService.add({ severity: 'warn',
        summary: this.translate.instant('MessageService.Summary.FailedAction'),
        detail: this.translate.instant('MarketplacePage.Spot.SearchParamError') })
    }
  }

  // showOfferDetailDialog2(offer: ExportOfferInformation) {
  //   this.selected = offer
  //   this.showOfferDetailDialog()
  // }


  showOfferDetailDialog2(offer: ExportOfferInformation) {

    this._router.navigate([Routes.productDetail, offer.exportOffer.guid])
  }

  showImportRequestDialog(importRequest: ImportRequestInformation){
    this.selectedImportRequest = importRequest
    if(this.selectedImportRequest.importRequest.productCertificateTypes)
        this.selectedProductCertificateTypes = this.selectedImportRequest.importRequest.productCertificateTypes

      if(this.selectedImportRequest.importRequest.packagingTypes)
        this.selectedPackagingTypes = this.selectedImportRequest.importRequest.packagingTypes

    if(this.importRequestForm.valid){
      this.loadOffersForImportRequest();
      this.visibleImportRequestDialog = true;
    }else{
      this.selected = undefined;
      this._messageService.add({ severity: 'warn',
        summary: this.translate.instant('MessageService.Summary.FailedAction'),
        detail: this.translate.instant('MarketplacePage.WishList.SearchParamError') })
    }
  }

  closeOfferDetailDialog() {
    this.visibleOfferDetailDialog = false
  }

  createOrder() {
    if(this.selected) {
      if(this.pallets < 1) {
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MarketplacePage.Spot.PalletsQuantityGreaterZeroError') })
        return
      }

      if(this.pallets > this.selected.exportOffer.availablePallets) {
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MarketplacePage.Spot.AvailablePalletsError') })
        return
      }

      let offerRequest = {
        palletQuantity: this.pallets,
        exportOfferId: this.selected?.exportOffer?.id,
        importUsername: this._authService.getUsername(),
        countryToId: this.portFormGroup.controls['countryToId'].value,
        portToId: this.portFormGroup.controls['portToId'].value
      }

      this._importOrderService.create(offerRequest).subscribe({
        next: (v) => {
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: this.translate.instant('MessageService.ImportOrder.CreateSuccess') })
          this._router.navigate([(Routes.purchaseOrdersPage)])
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ImportOrder.CreateError') + e.error })
        },
        complete: () => console.info('Import Orders create successfully')
      })
    }
    else {
      this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'Seleccione un contenedor de destino.' })
    }
  }

  goToOrders(){
    this._router.navigate([(Routes.purchaseOrdersPage)])
  }

  createOrderFromList(offer: ExportOfferInformation) {

    if(this.portFormGroup.valid) {

      if(offer.requiredPallets) {
        if(offer.requiredPallets > offer.exportOffer.availablePallets) {
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MarketplacePage.Spot.AvailablePalletsError') })
          return
        }

        let offerRequest = {
          importOrderId: null,
          palletQuantity: offer.requiredPallets,
          exportOfferId: offer.exportOffer.id,
          importUsername: this._authService.getUsername(),
          countryToId: this.portFormGroup.controls['countryToId'].value,
          portToId: this.portFormGroup.controls['portToId'].value,
        }

        this._importOrderService.create(offerRequest).subscribe({
          next: (v) => {
            this._messageService.add({ severity: 'success',
              summary: this.translate.instant('MessageService.Summary.SuccessAction'),
              detail: this.translate.instant('MessageService.ImportOrder.CreateSuccess') })
            offer.exportOffer.availablePallets -= offer.requiredPallets ? offer.requiredPallets : 0;
            offer.requiredPallets = 0;
            offer.totalPrice = 0;
            //this._router.navigate([(Routes.purchaseOrdersPage)])
          },
          error: (e) => {
            console.log(e)
            this.loading = false
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.ImportOrder.CreateError') + e.error })
          },
          complete: () => console.info('Import Orders create successfully')
        })
      }
      else {
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MarketplacePage.Spot.InvalidPalletQuantityError') })
        return
      }
    }
    else {
      this._messageService.add({ severity: 'error',
        summary: this.translate.instant('MessageService.Summary.FailedAction'),
        detail: this.translate.instant('MarketplacePage.Spot.ImportOrderParamsError') })
      return
    }
  }

  createNegotiation() {
    this.submitNegotiation = true
    this.loading = true
    if(this.selected && this.negotiationFormGroup.valid) {

      if(this.pallets > this.selected.exportOffer.availablePallets) {
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MarketplacePage.Spot.AvailablePalletsError') })
        return
      }

      let negotiation: NewNegotiation = {
        pallets: this.negotiationFormGroup.controls["negociationPallets"].value,
        unitPrice: this.negotiationFormGroup.controls["negociationPrice"].value,
        typeSale: this.negotiationFormGroup.controls["negociationIncoterm"].value,
        exportOfferId: this.selected?.exportOffer?.id,
        username: this._authService.getUsername(),
        portToId: this.portFormGroup.controls['portToId'].value,
        //portFromId: this.portFormGroup.controls['portFromId'].value,
        countryToId: this.portFormGroup.controls['countryToId'].value,
        paymentAgainstBL: this.negotiationFormGroup.controls["negotiationPaymentAgainstBL"].value
      }

      this._offerNegotiationService.create(negotiation).subscribe({
        next: (v) => {
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: this.translate.instant('MessageService.ImportOrder.CreateSuccess') })
          this.loading = false
          this._router.navigate([(Routes.userBargaining)])
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ImportOrder.CreateError') + e.error })
        },
        complete: () => console.info('Offer Negotiation create successfully')
      })
    }
    else {
      this.loading = false
    }
  }

  getErrorMessage(fieldName: string): string {
    if (this.negotiationFormGroup.controls[fieldName].hasError('required')) {
      return this.translate.instant('FormErrorMessage.FieldValueRequired')
    }
    if (this.negotiationFormGroup.controls[fieldName].hasError('email')) {
      return this.translate.instant('FormErrorMessage.EmailFieldEmailFormatError')
    }
    return ''
  }

  loadImportRequests(){
    this.selectedImportRequestCountryTo = this.destinationCountries.find(x => x.id == this.importRequestForm.controls['countryToId'].value)
    this.loadingImportRequest = true;

    const countryToId = this.importRequestForm.controls['countryToId'].value
    const varietyId = this.importRequestForm.controls['varietyId'].value
    const week = this.importRequestForm.controls['week'].value
    const countryFromId = this.importRequestForm.controls['countryFromId'].value

    if(countryToId && varietyId && week) {
      const request = {
        countryToId: countryToId,
        varietyId: varietyId,
        week: week,
        countryFromId: countryFromId
      }


      this._importRequestService.getAllForMarketplace(request).subscribe({
        next: (v) => {
          this.loadingImportRequest = false
          this.requests = v
        },
        error: (e) => {
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ImportRequest.ManyRetrieveError') + e.error })
          this.loadingImportRequest = false

        },
        complete: () => console.info('Import request query successfully')
      })
    }
    else {
      this._importRequestService.getAllForMarketplaceByDefault().subscribe({
        next: (v) => {
          this.loadingImportRequest = false
          console.log("requests", v)
          this.requests = v
        },
        error: (e) => {
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ImportRequest.ManyRetrieveError') + e.error })
          this.loadingImportRequest = false

        },
        complete: () => console.info('Import request query successfully')
      })
    }
  }

  clearImportRequestSearchParams() {
    this.importRequestForm.controls['speciesId'].setValue(null)
    this.importRequestForm.controls['countryId1'].setValue(null)
    this.importRequestForm.controls['varietyId'].setValue(null)
    this.importRequestForm.controls['week'].setValue(null)
    this.importRequestForm.controls['year'].setValue(null)
    this.loadImportRequests()
  }

  loadSpecies() {
    this._speciesService.getAllByProductType(1).subscribe({
      next: (v) => {
        this.species = v
        this.loading = false
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Species.ManyRetrieveError') })
      },
      complete: () => console.info('Species query successfully')
    })
  }

  loadFilterSpecies() {
    this.filterSpecies = []
    if(this.offers) {
      this.offers.forEach(o => {
        const species = this.filterSpecies.find(x => x.id === o.exportOffer.variety.speciesId)
        if(!species)
          this.filterSpecies.push(o.exportOffer.variety.species)
      })
    }
  }

  loadVarieties() {
    this.selectedSpecies = this.species.find(x => x.id === this.importRequestForm.controls['speciesId'].value)
    this.varieties = []
    this._varietyService.getAllBySpecies(this.importRequestForm.controls['speciesId'].value).subscribe({
      next: (v) => {
        this.varieties = v
        this.loading = false
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Variety.ManyRetrieveError') })
      },
      complete: () => console.info('Variety query successfully')
    })
  }

  onChangeVariety() {
    this.selectedVariety = this.varieties.find(x => x.id === this.importRequestForm.controls['varietyId'].value)
  }

  onChangeImportRequestCountryFrom() {
    this.selectedImportRequestCountryFrom = this.originCountriesOptions.find(x => x.id == this.importRequestForm.controls['countryFromId'].value)
  }

  get allCountriesText(): string {
    if(this.profile) {
      switch(this.profile.lang) {
        case 'en':
          return 'All countries'
        case 'es':
          return 'Todos los países'
        default:
          return 'All countries'
      }
    }
    return 'All countries'
  }

  getSpeciesName(species: Species): string {
    if(this.profile)
      return SpeciesUtils.getSpeciesName(this.profile.lang, species)
    return ''
  }

  loadFilterVarieties(){
    this.filterVarieties= []
    if(this.offers) {
      this.offers.forEach(o => {
        const variety = this.filterSpecies.find(x => x.id === o.exportOffer.varietyId)
        if(!variety)
          this.filterVarieties.push(o.exportOffer.variety)
      })
    }
  }

  switchHeaders(event: any) {
    this.activeTab = event.index;
    if(this.activeTab == 1){
      this.loadSpecies();
      this.loadImportRequests()
    }
  }

  loadOffersForImportRequest(){
    this.requestOffers = []
    if(this.selectedImportRequest){
      this._importRequestService.getAllOfferForRequest(this.selectedImportRequest.importRequest.id).subscribe({
        next: (v) => {
          v.forEach(offer => {
            this.requestOffers.push({
              id: offer.id,
              name: 'ID: ' + offer.id + ' - ' +offer.variety.name + ' - PALLETS: ' + offer.availablePallets,
              exportOffer: offer
            })
          })
        },
        error: (e) => {
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOffer.ManyRetrieveError') })
        },
        complete: () => console.info('Export offers query successfully')
      })
    }
  }

  sendOfferToRequest()
  {
    if(this.selectedImportRequest && this.selectedOffer)
    {
      this._importRequestService.AddExportOffer(this.selectedImportRequest.importRequest.id, this.selectedOffer.id).subscribe({
        next: (v) => {
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: this.translate.instant('MessageService.ImportRequest.ApplyOfferSuccess') })
          this.visibleImportRequestDialog = false;
        },
        error: (e) => {
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ImportRequest.ApplyOfferError') })
        },
        complete: () => console.info('Send offer query successfully')
      })}
  }

  closeOfferToRequestDialog()
  {
    this.visibleImportRequestDialog = false;
  }

  completeUrl(url: string){
    return ExportOfferUtils.completeUrl(url);
  }

  getSpeciesColor(value: string): string {
    return ExportOfferUtils.getColorName(Lang.SPANISH, value)
  }

  onSortChange(event: any) {
    let value = event.value;
    this.loading = true
    if (value.indexOf('!') === 0) {
        this.sortOrder = -1;
        this.sortField = value.substring(1, value.length);
    } else {
        this.sortOrder = 1;
        this.sortField = value;
    }
    this.loading = false
  }

  getColor(color?: string) {
    return ExportOfferUtils.getColorName(Lang.SPANISH, color)
  }

  getBoxType(boxType?: string): string {
    return ExportOfferUtils.getBoxTypeName(this.translate.currentLang, boxType)
  }

  getBasePallets(basePallets?: string) {
    return ExportOfferUtils.getBasePalletsName(Lang.SPANISH, basePallets)
  }

  getBooleanLabel(value?: boolean) {
    return Lang.getBooleanLabel(Lang.SPANISH, value)
  }

  getIncoterms(value: string) {
    return ExportOfferUtils.getIncotermsName(value)
  }

  clearSpotSearchParams() {
    this.portFormGroup.controls['countryFromId'].setValue(null)
    this.portFormGroup.controls['countryToId'].setValue(null)
    this.portFormGroup.controls['portToId'].setValue(null)
    this.destinationPorts = []
    this.estimations = []
    this.loadExportOffers()
  }

  getTotalPalletsImportRequest(importRequest: ImportRequest): number {
    return ImportRequestUtil.getTotalPallets(importRequest)
  }

  getTotalWeeksImportRequest(importRequest: ImportRequest): number {
    return ImportRequestUtil.getTotalWeeks(importRequest)
  }

  getTotalContainersImportRequest(importRequest: ImportRequest): number {
    return ImportRequestUtil.getTotalContainers(importRequest)
  }

  getNoAvailableLabel() {
    return this.translate.instant('ExportOffer.ExportOfferDetail.NoAvailable')
  }

  getPaymentsBLItems()
  {
    this.paymentBLItems = ExportOfferUtils.getPaymentBLValues(this.negotiationFormGroup.controls['negociationIncoterm'].value);
  }

  // get paymentBLItems() {
  //   return ExportOfferUtils.getPaymentBLValues(this.negotiationFormGroup.controls['negociationIncoterm'].value);
  // }

  get showPaymentBLPercent() {
    return ExportOfferUtils.showPaymentBLPercent(this.negotiationFormGroup.controls['negociationIncoterm'].value)
  }

  get finalPaymentPercent(): number {
    if(this.negotiationFormGroup.controls['negotiationPaymentAgainstBL'].value)
      return 100 - this.negotiationFormGroup.controls['negotiationPaymentAgainstBL'].value
    return 0
  }

  get paymentBLTitle() {
    if (this.negotiationFormGroup.controls['negotiationPaymentAgainstBL'].value)
      return ExportOfferUtils.getPaymentBLTitle(this.negotiationFormGroup.controls['negotiationPaymentAgainstBL'].value);
    return 'UserPages.UserBargaining.OpenTab.BargainingDetail.MakeCounterOfferModal.PaymentAgainstBLLabel'
  }

  get paymentBLTitleImportRequest() {
    if (this.selectedImportRequest && this.selectedImportRequest?.importRequest.paymentAgainstBL)
      return ExportOfferUtils.getPaymentBLTitle(this.selectedImportRequest.importRequest.paymentAgainstBL);
    return 'UserPages.UserBargaining.OpenTab.BargainingDetail.MakeCounterOfferModal.PaymentAgainstBLLabel'
  }

  get finalPaymentSubjectQC(): number {
    if(this.selectedImportRequest && this.selectedImportRequest.importRequest.paymentAgainstBL)
      return 100 - this.selectedImportRequest.importRequest.paymentAgainstBL
    return 0
  }

  onChangeIncoterm(event: any)
  {
    this.getPaymentsBLItems()
  }

  getAvailableLabel() {
    return this.translate.instant('ExportOffer.ExportOfferDetail.NoAvailable')
  }
}
