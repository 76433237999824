import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
  } from "@angular/common/http";
  import { Observable, tap } from "rxjs";
  import { Router } from "@angular/router";
  import { Routes } from "../routes";
  import { Injectable } from "@angular/core";
import { AuthGateway } from "src/app/application/gateway/AuthGateway";

  @Injectable()
  export class ResponseInterceptor implements HttpInterceptor {
    public constructor(
      private router: Router,
      private authService: AuthGateway
    ) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(req).pipe(tap(
          () => {
          },
          error => {
            if (error instanceof HttpErrorResponse) {
              if (error.status === 401) {
                this.authService.logout();
                this.router.navigate([Routes.login]).then()
              } else if (error.status === 403) {
                this.router.navigate([Routes.forbiddenPage]).then()
              }
            }
          }
        )
      )
    }
  }
