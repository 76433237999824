<app-user-dashboard [userMenuActiveItemIndex]="0">
  <p-breadcrumb class="max-w-full" [model]="breadcrumbMenuItems" [home]="breadcrumbHome"></p-breadcrumb>
  <div style="margin-top: 30px;">
    <div class="container" *ngIf="importOrderInfo">
      <section class='sectionLeft'>
        <h2 class="title">{{'QcOrderDetailPage.title' | translate}}</h2>
        <h4 class="subtitle">{{'QcOrderDetailPage.subtitle' | translate}}</h4>
        <section class='sectionDescription'>
          <section class='rowShipment'>
            <figure class='figureIcon'>
              <img src="../../../../assets/img/pallet_icon.png" alt="palletIcon">
            </figure>
            <section class='sectionData'>
              <span >{{'QcOrderDetailPage.orderText' | translate}}:</span>
              <h3>{{padNumberWithZeros(importOrderInfo.sequence)}}</h3>
            </section>
            <section class='sectionData'>
              <span >{{'QcOrderDetailPage.originText' | translate}}:</span>
              <h3 *ngIf="importOrderInfo.importOrder.importFromCountry && profile && profile.lang === 'en'">{{importOrderInfo.importOrder.importFromCountry.name}}</h3>
              <h3 *ngIf="importOrderInfo.importOrder.importFromCountry && profile && profile.lang === 'es'">{{importOrderInfo.importOrder.importFromCountry.nameEs}}</h3>
              <h5 *ngIf="importOrderInfo.importOrder.portFrom">{{importOrderInfo.importOrder.portFrom.name}}</h5>
              <h5 style="color:gray" *ngIf="!importOrderInfo.importOrder.portFrom">---</h5>
            </section>
            <section class='sectionData'>
              <span >{{'QcOrderDetailPage.destinationText' | translate}}:</span>
              <h3 *ngIf="importOrderInfo.importOrder.importToCountry && profile && profile.lang === 'en'">{{importOrderInfo.importOrder.importToCountry.name}}</h3>
              <h3 *ngIf="importOrderInfo.importOrder.importToCountry && profile && profile.lang === 'es'">{{importOrderInfo.importOrder.importToCountry.nameEs}}</h3>
              <h5 *ngIf="importOrderInfo.importOrder.portTo">{{importOrderInfo.importOrder.portTo.name}}</h5>
              <h5 style="color:gray" *ngIf="!importOrderInfo.importOrder.portTo">---</h5>
            </section>
            <section class='sectionData'>
              <span >{{'QcOrderDetailPage.palletsNumText' | translate}}:</span>
              <h3 >{{importOrderInfo.palletsQuantity}}</h3>
            </section>
          </section>
          <section class="productsTable">
            <div class="headerTable">
              <div class="cellColumnSmall">{{'QcOrderDetailPage.productText' | translate}}</div>
              <div class="cellColumn">{{'QcOrderDetailPage.specieText' | translate}}</div>
              <div class="cellColumn">{{'QcOrderDetailPage.varietyText' | translate}}</div>
              <div class="cellColumnSmall">{{'QcOrderDetailPage.boxesPerPalletText' | translate}}</div>
            </div>
            <div class="bodyTable">
              <div class="rowTable">
                <div class="cellColumnSmall"><figure class="figureProduct">
                  <img *ngIf="importOrderInfo.species" src={{importOrderInfo.species.imageUrl}} alt="product" class="imgProduct">
                </figure></div>
                <div class="cellColumn">
                  <h3 *ngIf="importOrderInfo.species && profile && profile.lang === 'en'">{{importOrderInfo.species.name}}</h3>
                  <h3 *ngIf="importOrderInfo.species && profile && profile.lang === 'es'">{{importOrderInfo.species.nameEs}}</h3>
                </div>
                <div class="cellColumn" *ngIf="importOrderInfo.variety"><h3>{{importOrderInfo.variety.name}}</h3></div>
                <div class="cellColumnSmall"><h3>{{importOrderInfo.caseQuantityPerPallet}}</h3></div>
              </div>
            </div>
          </section>
          <!--
          <section class="rowAlignRight">
            <section class="rowQc">
              <section class='sectionData'>
                <h3 class='titleSectionData'>QC</h3>
              </section>
              <section class='sectionData'>
                <span >{{qcCompanyNameText}}:</span>
                <h3 >{{qcCompanyName}}</h3>
              </section>
              <section class='sectionData'>
                <span >{{qcCompanyPriceText}}:</span>
                <h3 >$ {{formatNumber(qcCompanyPrice)}}</h3>
              </section>
            </section>
          </section>
        -->
        </section>
      </section>
      <section class='sectionRight'>
        <section class='columnPayment'>
          <h2 class="title">{{'QcOrderDetailPage.titleDetail' | translate}}</h2>
          <section class='sectionFlexEnd'>
            <button class="btnSecond" (click)="changeQc()">
              {{'QcOrderDetailPage.btnSecondLabel' | translate}}
            </button>
          </section>
          <section class="rowQcAlt">
            <section class='sectionDataLeft'>
              <span >{{'QcOrderDetailPage.qcCompanyText' | translate}}:</span>
              <h3 >{{importOrderInfo.qualityControlCompany?.profile?.companyName}}</h3>
            </section>
            <section class='sectionDataRight'>
              <span >{{'QcOrderDetailPage.qcCompanyValueText' | translate}}:</span>
              <h3  *ngIf="importOrderInfo.qcGeneratedPrice">$ {{formatNumber(importOrderInfo.qcGeneratedPrice)}}</h3>
            </section>
          </section>
          <div class="card">
            <section class="row spaceBotton">
              <p class="fontSpan">{{'QcOrderDetailPage.qcPriceText' | translate}}</p>
              <p class="fontSpan" *ngIf="importOrderInfo.qcGeneratedPrice"><span>{{currency}} </span>$ {{formatNumber(importOrderInfo.qcGeneratedPrice)}}</p>
            </section>
            <section class="row">
              <p class="fontSubTotal">{{'QcOrderDetailPage.subtotalPriceText' | translate}}</p>
              <p class="fontSubTotal" *ngIf="importOrderInfo.qcGeneratedPrice"><span>{{currency}} </span>$ {{formatNumber(importOrderInfo.qcGeneratedPrice)}}</p>
            </section>
            <section class="row">
              <p class="fontSpan">{{'QcOrderDetailPage.taxesCostText' | translate}}</p>
              <p class="fontSpan"><span>{{currency}} </span>$ {{formatNumber(importOrderInfo.qcTaxPrice)}}</p>
            </section>
            <section class="row spaceTop spaceBotton">
              <p class="fontTotal">{{'QcOrderDetailPage.totalPriceText' | translate}}</p>
              <p class="fontTotal" *ngIf="importOrderInfo.qcGeneratedPrice"><span>{{currency}} </span>$ {{formatNumber(importOrderInfo.qcGeneratedPrice +importOrderInfo.qcTaxPrice )}}</p>
            </section>
            <button class="btnMain" (click)="navigateToQcOrderPayment()">
              {{'QcOrderDetailPage.btnMainLabel' | translate}}
            </button>
          </div>
        </section>
      </section>
    </div>
  </div>
</app-user-dashboard>
<p-blockUI [blocked]="loading"></p-blockUI>
