<p-table [value]="productCertificateTypes"
  [(selection)]="selectedProductCertificateTypes"
  dataKey="id"
  [tableStyle]="{'min-width': '50rem'}"
  [loading]="loading">
  <ng-template pTemplate="header">
      <tr>
        <th style="width: 4rem">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th>Name</th>
        <th></th>
      </tr>
  </ng-template>
  <ng-template pTemplate="body" let-productCertificateType>
      <tr>
        <td>
            <p-tableCheckbox [value]="productCertificateType"></p-tableCheckbox>
        </td>
        <td>{{productCertificateType.name}}</td>
        <td><input *ngIf="productCertificateType.isOther" [(ngModel)]="otherCertificateType" type="text" pInputText placeholder="{{'UserPages.UserExportOffers.NewExportOffer.WhichOther' | translate}}" style="width: 15rem;" /></td>
      </tr>
  </ng-template>
</p-table>
