import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Routes } from "src/app/infrastructure/routes";
import { DocumentServiceGateway } from "../gateway/DocumentServiceGateway";

@Injectable({providedIn: 'root'})
export class DocumentService extends DocumentServiceGateway {

  constructor(private _http: HttpClient) {
    super()
  }

  getOperationDocument(operationDocumentId: number): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type':  'application/pdf'
    })

    return this._http.get<any>(Routes.documentServiceApi + '/GetOperationDocument', {params: {operationDocumentId}, headers: headers, observe: 'response', responseType: 'blob' as 'json'})
  }

  getProductCertificateDocument(productCertificateId: number, importOrderId: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type':  'application/pdf'
    })

    return this._http.get<any>(Routes.documentServiceApi + '/GetProductCertificateDocument', {params: {productCertificateId, importOrderId}, headers: headers, observe: 'response', responseType: 'blob' as 'json'})
  }

  getExportOfferDocument(exportOfferDocumentId: number, importOrderId: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type':  'application/pdf'
    })

    return this._http.get<any>(Routes.documentServiceApi + '/GetExportOfferDocument', {params: {exportOfferDocumentId, importOrderId}, headers: headers, observe: 'response', responseType: 'blob' as 'json'})
  }

  getProductCertificateDocument2(productCertificateId: number): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type':  'application/pdf'
    })

    return this._http.get<any>(Routes.documentServiceApi + '/GetProductCertificateDocument2', {params: {productCertificateId}, headers: headers, observe: 'response', responseType: 'blob' as 'json'})
  }

  getExportOfferDocument2(exportOfferDocumentId: number): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type':  'application/pdf'
    })

    return this._http.get<any>(Routes.documentServiceApi + '/GetExportOfferDocument2', {params: {exportOfferDocumentId}, headers: headers, observe: 'response', responseType: 'blob' as 'json'})
  }

  getQCReviewDocument(importOrderDetailId: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type':  'application/pdf'
    })

    return this._http.get<any>(Routes.documentServiceApi + '/GetQCReviewDocument', {params: {importOrderDetailId}, headers: headers, observe: 'response', responseType: 'blob' as 'json'})
  }

  getQCConsolidationDocument(importOrderDetailId: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type':  'application/pdf'
    })

    return this._http.get<any>(Routes.documentServiceApi + '/GetQCConsolidationDocument', {params: {importOrderDetailId}, headers: headers, observe: 'response', responseType: 'blob' as 'json'})
  }

  getPaymentDocument(paymentId: number): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type':  'application/pdf'
    })

    return this._http.get<any>(Routes.documentServiceApi + '/GetPaymentDocument', {params: {paymentId}, headers: headers, observe: 'response', responseType: 'blob' as 'json'})
  }

  getFrozenFruitQCReviewDocument(importOrderDetailId: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type':  'application/pdf'
    })

    return this._http.get<any>(Routes.documentServiceApi + '/GetFrozenFruitQCReviewDocument', {params: {importOrderDetailId}, headers: headers, observe: 'response', responseType: 'blob' as 'json'})
  }

  getFrozenFruitQCConsolidationDocument(importOrderDetailId: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type':  'application/pdf'
    })

    return this._http.get<any>(Routes.documentServiceApi + '/GetFrozenFruitQCConsolidationDocument', {params: {importOrderDetailId}, headers: headers, observe: 'response', responseType: 'blob' as 'json'})
  }

  getFrozenFruitPesticideAnalysisDocument(importOrderDetailId: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type':  'application/pdf'
    })

    return this._http.get<any>(Routes.documentServiceApi + '/GetFrozenFruitPesticideAnalysisDocument', {params: {importOrderDetailId}, headers: headers, observe: 'response', responseType: 'blob' as 'json'})
  }

  getFrozenFruitHeavyMetalsAnalysisDocument(importOrderDetailId: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type':  'application/pdf'
    })

    return this._http.get<any>(Routes.documentServiceApi + '/GetFrozenFruitHeavyMetalsAnalysisDocument', {params: {importOrderDetailId}, headers: headers, observe: 'response', responseType: 'blob' as 'json'})
  }

  getFrozenFruitMicrobiologicalAnalysisDocument(importOrderDetailId: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type':  'application/pdf'
    })

    return this._http.get<any>(Routes.documentServiceApi + '/GetFrozenFruitMicrobiologicalAnalysisDocument', {params: {importOrderDetailId}, headers: headers, observe: 'response', responseType: 'blob' as 'json'})
  }

  getFrozenFruitWaterAnalysisDocument(importOrderDetailId: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type':  'application/pdf'
    })

    return this._http.get<any>(Routes.documentServiceApi + '/GetFrozenFruitWaterAnalysisDocument', {params: {importOrderDetailId}, headers: headers, observe: 'response', responseType: 'blob' as 'json'})
  }

  getOriginQCReviewDocument(reviewGuid: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type':  'application/pdf'
    })

    return this._http.get<any>(Routes.documentServiceApi + '/GetOriginQCReviewDocument', {params: {reviewGuid}, headers: headers, observe: 'response', responseType: 'blob' as 'json'})
  }

  getOriginQCConsolidationDocument(reviewGuid: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type':  'application/pdf'
    })

    return this._http.get<any>(Routes.documentServiceApi + '/GetOriginQCConsolidationDocument', {params: {reviewGuid}, headers: headers, observe: 'response', responseType: 'blob' as 'json'})
  }

  getExportInvoiceDocument(exportInvoiceId: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type':  'application/pdf'
    })

    return this._http.get<any>(Routes.documentServiceApi + '/GetExportInvoiceDocument', {params: {exportInvoiceId}, headers: headers, observe: 'response', responseType: 'blob' as 'json'})
  }

  getImportOrderDetailDocument(documentId: string): Observable<any> {
    // const headers = new HttpHeaders({
    //   'Content-Type':  'application/octet-stream'
    // })

    // return this._http.get<any>(Routes.documentServiceApi + '/GetImportOrderDocument', {params: {documentId}, headers: headers, observe: 'response', responseType: 'blob' as 'json'})
    return this._http.get<any>(Routes.documentServiceApi + '/GetImportOrderDocument', {
      params: { documentId },
      observe: 'response',
      responseType: 'blob' as 'json'
    });
  }

  getOCProgramRequestDocument(documentId: string, typeDocument: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type':  'application/pdf'
    })

    return this._http.get<any>(Routes.documentServiceApi + '/GetOCProgramRequestDocument', {params: {documentId, typeDocument}, headers: headers, observe: 'response', responseType: 'blob' as 'json'})
  }

  getQCPaymentPdf(paymentQcId: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type':  'application/pdf'
    })

    return this._http.get<any>(Routes.documentServiceApi + '/GetQCPaymentPdf', {params: {paymentQcId}, headers: headers, observe: 'response', responseType: 'blob' as 'json'})
  }
}
