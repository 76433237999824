import { Pipe, PipeTransform } from '@angular/core';
import { Port } from '../domain/models/Port';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'portName'
})
export class PortNamePipe implements PipeTransform {

  constructor(public translate: TranslateService) {}

  transform(ports: Port[]): Port[] {
    return ports.map(port => {
      const locode = port.locode ? "(" + port.locode + ")" : '';

      if (port.name === null || port.name === undefined) return this.translate.instant('CommonLabels.NotAvailableLabel')
      
      return {
        ...port,
        fullname: `${port.name} ${locode}`.trim()
      };
    });
  }


  // transform(value: Port): string {

  //   console.log("value recibido ==☻ ", value)
  //   const fullname = value.name ? `${value.name} ${value.locode ? "(" + value.locode + ")" : ''}`.trim() : this.translate.instant('CommonLabels.NotAvailableLabel');
  //   return fullname
  // }

}

