import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthGateway } from '../application/gateway/AuthGateway';

@Pipe({
  name: 'countryDescription'
})
export class CountryDescriptionPipe implements PipeTransform {

  constructor(private _authService: AuthGateway,
    public translate: TranslateService, ) {}

  transform(country: any): string {

    let pageLang = this.translate.currentLang

    const profile = this._authService.getUser()
    if(profile) pageLang = profile.lang
    // if(!profile)
    //   return this.translate.instant('CommonLabels.NotAvailableLabel')

    if (country === undefined || country === null) return '';

    if (typeof country === 'object' && 'nameEs' in country && 'name' in country) {
      if (pageLang === undefined) return country.name; //Se ubica aquí por páginas donde el lang no esté cargado
      return pageLang === 'es' ? country.nameEs : country.name;
      // switch(profile.lang) {
      //   case 'es':
      //     return country.nameEs
      //   case 'en':
      //     return country.name
      //   default:
      //     return country.name
      // }
    } else {
      // Si country no tiene las propiedades esperadas, simplemente retornar el mismo valor
      return this.translate.instant('CommonLabels.NotAvailableLabel');
    }
  }

}
