import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { NewExportOfferDocument, ExportOfferDocument } from "src/app/domain/models/ExportOfferDocument";
import { Routes } from "src/app/infrastructure/routes";
import { ExportOfferDocumentGateway } from "../gateway/ExportOfferDocumentGateway";

@Injectable({providedIn: 'root'})
export class ExportOfferDocumentService extends ExportOfferDocumentGateway {

  constructor(private _http: HttpClient) {
    super()
  }

  create(document: NewExportOfferDocument): Observable<ExportOfferDocument> {
    return this._http.post<ExportOfferDocument>(Routes.exportOfferDocumentApi + '/Create', this.getFormDataCreate(document))
  }

  update(document: ExportOfferDocument): Observable<ExportOfferDocument> {
    return this._http.put<ExportOfferDocument>(Routes.exportOfferDocumentApi + '/Update', this.getFormDataUpdate(document))
  }

  delete(exportOfferDocumentId: number): Observable<any> {
    return this._http.delete<any>(Routes.exportOfferDocumentApi + '/Delete', {params: {exportOfferDocumentId}})
  }

  getAllByExportOffer(exportOfferId: number): Observable<ExportOfferDocument[]> {
    return this._http.get<ExportOfferDocument[]>(Routes.exportOfferDocumentApi + '/GetAllByExportOfferId', {params: {exportOfferId}})
  }

  getFormDataCreate(document: NewExportOfferDocument): FormData {
    let formData = new FormData()
    if(document) {
      formData.append("name", document.name ? document.name.trim() : '')
      formData.append("exportOfferId", document.exportOfferId ? document.exportOfferId.toString() : '')
      formData.append('pdf', document.pdf)
    }
    return formData
  }

  getFormDataUpdate(document: ExportOfferDocument): FormData {
    let formData = new FormData()
    if(document) {
      formData.append("id", document.id ? document.id.toString() : '')
      formData.append("name", document.name ? document.name.trim() : '')
      formData.append("exportOfferId", document.exportOfferId ? document.exportOfferId.toString() : '')
      formData.append('pdf', document.pdf)
    }
    return formData
  }
}
