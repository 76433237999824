import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { GenericOrderDetail } from "src/app/domain/models/ImportOrder";
import { Routes } from "src/app/infrastructure/routes";
import { GenericOrderDetailGateway } from "../gateway/GenericOrderDetailGateway";

@Injectable({providedIn: 'root'})
export class GenericOrderDetailService extends GenericOrderDetailGateway {

  constructor(private _http: HttpClient) {
    super()
  }

  getAllPendingForQCReviews(): Observable<GenericOrderDetail[]> {
    return this._http.get<GenericOrderDetail[]>(Routes.genericOrderDetailApi + '/GetAllPendingForQCReviews')
  }

  getAllReviewedOriginQCReviews(): Observable<GenericOrderDetail[]> {
    return this._http.get<GenericOrderDetail[]>(Routes.genericOrderDetailApi + '/GetAllReviewedOriginQCReviews')
  }

}
