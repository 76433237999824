
<p-tabView>
  <p-tabPanel header="{{'TabVerifyPallets.verifyPalletsHeader' | translate}}">
    <p-card *ngIf="dto && detail">

      <div class="flex justify-content-between align-items-center" style="margin-left: 15px;">
        <h2>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.PageTitle1' | translate}}</h2>
      </div>

      <form [formGroup]="directSaleFormGroup">
        <div class="grid" style="margin-top: 10px; margin-left: 15px; margin-right: 15px;" *ngIf="!success">
          <div class="col-12 md:col-9 card-summary">
              <div class="grid" style="height: 100%;">
                <div class="col-12 md:col-7 flex justify-content-between">
                  <div class="flex align-items-center justify-content-start">
                    <div>
                      <img [src]="getImageUrl(detail.offerImageUrl)" style="border-radius: 7px; width: 100%; max-width: 65px; height: auto;" alt=""/>
                    </div>
                    <div class="flex align-items-center ">
                      <div class="flex flex-column justify-content-start gap-1">
                        <span style="text-transform: uppercase; font-size: medium; font-weight: bold;">{{detail | speciesName}}</span>
                        <span style="text-transform: uppercase; font-size: medium;">{{detail.varietyName}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="flex align-items-center">
                    <div class="flex flex-column justify-content-start gap-1">
                      <span style="color: #8c2b87;font-size: small;">{{orderType}} order N {{padWithLeadingZeros(detail.importOrderSequence)}}</span>
                      <span style="color: #8c2b87; font-size: small;">{{orderType}} order detail N {{padWithLeadingZeros(detail.sequence)}}</span>
                    </div>
                  </div>
                  <div class="flex align-items-center">
                      <span class="material-symbols-outlined" style="color: #8c2b87">pallet</span>
                      <span style="margin-left: 10px;">{{detail.palletQuantity}}</span>
                  </div>
                </div>
                <div class="col-12 md:col-5 flex justify-content-between align-items-center">

                  <div class="flex justify-content-start align-items-center"  *ngIf="detail.transportType === 0">
                    <img [src]="'../../../../../assets/img/flags/' + detail.countryFromCode + '.png'" height="40px" alt=""/>
                    <div class="flex flex-column" style="margin-left: 10px;">
                      <span style="font-weight: bolder; font-size: small;">{{detail.countryFromName}}</span>
                      <!-- <span style="font-size: smaller;" *ngIf="!detail.portFromName">{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.OriginPortText' | translate }} (?)</span> -->
                      <span style="font-size: smaller;" *ngIf="detail.portFromName">{{detail.portFromName}}</span>
                    </div>
                  </div>

                  <div class="flex justify-content-start align-items-center"  *ngIf="detail.transportType === 1">
                    <img [src]="'../../../../../assets/img/flags/' + detail.countryFromCode + '.png'" height="40px" alt=""/>
                    <div class="flex flex-column" style="margin-left: 10px;">
                      <span style="font-weight: bolder; font-size: small;">{{detail.countryFromName}}</span>
                      <!-- <span style="font-size: smaller;" *ngIf="!detail.portFromName">{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.OriginPortText' | translate }} (?)</span> -->
                      <span style="font-size: smaller;" *ngIf="detail.portFromName">{{detail.fromAirport?.name}}</span>
                    </div>
                  </div>

                  <div class="flex justify-content-between">
                    <span class="material-symbols-outlined" style="color: #6da043;"  *ngIf="detail.transportType === 0">directions_boat</span>
                    <span class="material-symbols-outlined" style="color: #6da043;"  *ngIf="detail.transportType === 1">flight</span>
                    <span class="material-symbols-outlined" style="color: #6da043;">arrow_forward_ios</span>
                  </div>
                  <div class="flex justify-content-start align-items-center" *ngIf="detail.transportType === 0">
                    <img [src]="'../../../../../assets/img/flags/' + detail.countryToCode + '.png'" height="40px" alt=""/>
                    <div class="flex flex-column" style="margin-left: 10px;">
                      <span style="font-weight: bolder; font-size: small; ">{{detail.countryToName}}</span>
                      <span style="font-size: smaller;">{{detail.portToName}}</span>
                    </div>
                  </div>

                  <div class="flex justify-content-start align-items-center" *ngIf="detail.transportType === 1" >
                    <img [src]="'../../../../../assets/img/flags/' + detail.countryToCode + '.png'" height="40px" alt=""/>
                    <div class="flex flex-column" style="margin-left: 10px;">
                      <span style="font-weight: bolder; font-size: small; ">{{detail.countryToName}}</span>
                      <span style="font-size: smaller;">{{detail.destinationAirPort?.name}}</span>
                    </div>
                  </div>

                </div>
              </div>
          </div>
          <div class="col-12 md:col-3">
            <div class="flex flex-column align-items-end gap-4 justify-content-end">
              <p-button [style]="{'width': '100%'}" label="{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelButton' | translate }}" icon="pi pi-upload" (click)="showDialog()" *ngIf="detail && (isCreate || isEdit) && !success"/>
              <div class="p-fluid formgrid grid" *ngIf="detail.isClosedSale" [style]="{'width': '100%', 'max-width': '198px'}">
                <div class="col-12 field">
                  <span class="p-float-label">
                    <p-dropdown id="fridgeStorage"
                      formControlName="fridgeStorageId"
                      [options]="fridgeStorageOptions"
                      optionValue="id"
                      optionLabel="label"
                      [filter]="true"
                      filterBy="name"
                      [showClear]="true"
                      (onChange)="onChangeFridgestorage()"
                      placeholder="{{'UserPages.UserExportOffers.NewExportOffer.FridstorageFieldPlaceholder' | translate}}"
                      emptyMessage="{{'UserPages.UserExportOffers.NewExportOffer.FridgestorageEmptyMessage' | translate}}"
                      class="p-inputtext-sm">
                    </p-dropdown>
                    <label for="float-label">{{'UserPages.UserExportOffers.NewExportOffer.FridstorageFieldLabel' | translate}} *</label>
                    <small *ngIf="submit && directSaleFormGroup.controls['fridgeStorageId'].invalid" class="p-error">{{'fridgeStorageId' | formErrorMessage: directSaleFormGroup}}</small>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="detail && (isCreate || isEdit) && !success" class="m-4 container-width">
          <div style="margin-top: 40px;">
            <p-table
              [value]="pallets"
              dataKey="dataKey"
              responsiveLayout="stack"
              [breakpoint]="'600px'"
              styleClass="p-datatable-gridlines"
              [scrollable]="true"
              [resizableColumns]="true"
              columnResizeMode="expand"
              scrollHeight="600px">
              <ng-template pTemplate="header">
                <tr>
                  <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.FolioHeader' | translate}}</th>
                  <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.SpeciesHeader' | translate}}</th>
                  <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.VarietyHeader' | translate}}</th>
                  <th *ngIf="exportOfferId && profile?.type === 1">{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.ProducerHeader' | translate}}</th>
                  <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.SpeciesSizeHeader' | translate}}</th>
                  <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.CategoryHeader' | translate}}</th>
                  <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.HarvestDateHeader' | translate}}</th>
                  <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.QualityControlDateHeader' | translate}}</th>
                  <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.PackagingHeader' | translate}}</th>
                  <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.PalletTypeHeader' | translate}}</th>
                  <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.TagHeader' | translate}}</th>
                  <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.BagTypeHeader' | translate}}</th>
                  <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.BoxTypeHeader' | translate}}</th>
                  <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.NetWeightHeader' | translate}}</th>
                  <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.ColdStorageHeader' | translate}}</th>
                  <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.BoxePerPalletHeader' | translate}}</th>
                  <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.MarksHeader' | translate}}</th>
                  <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.GradeHeader' | translate}}</th>
                  <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.ContainerNumberHeader' | translate}}</th>
                  <th>ETD</th>
                  <th>ETA</th>
                  <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadWeekHeader' | translate}}</th>
                  <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.OriginCountryHeader' | translate}}</th>
                  <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.DestinationCountryHeader' | translate}}</th>
                  <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.TemptaleHeader' | translate}}</th>
                  <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.Cert1Header' | translate}}</th>
                  <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.Cert2Header' | translate}}</th>
                  <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.Cert3Header' | translate}}</th>
                  <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadWeekHeader' | translate}}</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-pallet let-editing="editing" let-ri="rowIndex">
                <tr>
                  <td [pEditableColumn]="pallet.palletNumber" pEditableColumnField="palletNumber">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input pInputText type="text" [(ngModel)]="pallet.palletNumber" maxlength="20" required [readOnly]="isEdit && !this.isClosedSale"/>
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{pallet.palletNumber}}
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td [pEditableColumn]="pallet.excelImportSpecies" pEditableColumnField="excelImportSpecies">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input pInputText type="text" [(ngModel)]="pallet.excelImportSpecies" maxlength="100" required/>
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{pallet.excelImportSpecies}}
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td [pEditableColumn]="pallet.excelImportVariety" pEditableColumnField="excelImportVariety">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input pInputText type="text" [(ngModel)]="pallet.excelImportVariety" maxlength="100" required/>
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{pallet.excelImportVariety}}
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td [pEditableColumn]="pallet.producer" pEditableColumnField="producer" *ngIf="exportOfferId && profile?.type === 1">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input pInputText type="text" [(ngModel)]="pallet.producer" maxlength="200" required/>
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{pallet.producer}}
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td [pEditableColumn]="pallet.speciesSize" pEditableColumnField="speciesSize">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input pInputText type="text" [(ngModel)]="pallet.speciesSize" maxlength="30"/>
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{pallet.speciesSize}}
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td [pEditableColumn]="pallet.excelImportLoadWeek" pEditableColumnField="category">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input pInputText type="text" [(ngModel)]="pallet.excelImportLoadWeek" maxlength="50"/>
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{pallet.category}}
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td [pEditableColumn]="pallet.excelImportHarvestDate" pEditableColumnField="excelImportHarvestDate">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input pInputText type="text" [(ngModel)]="pallet.excelImportHarvestDate" maxlength="50"/>
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{pallet.excelImportHarvestDate}}
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td [pEditableColumn]="pallet.excelImportQualityControlDate" pEditableColumnField="excelImportQualityControlDate">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input pInputText type="text" [(ngModel)]="pallet.excelImportQualityControlDate" maxlength="50"/>
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{pallet.excelImportQualityControlDate}}
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td [pEditableColumn]="pallet.excelImportPackaging" pEditableColumnField="excelImportPackaging">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input pInputText type="text" [(ngModel)]="pallet.excelImportPackaging" maxlength="50"/>
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{pallet.excelImportPackaging}}
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td [pEditableColumn]="pallet.excelImportPalletType" pEditableColumnField="excelImportPalletType">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input pInputText type="text" [(ngModel)]="pallet.excelImportPalletType" maxlength="50"/>
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{pallet.excelImportPalletType}}
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td [pEditableColumn]="pallet.excelImportTag" pEditableColumnField="excelImportTag">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input pInputText type="text" [(ngModel)]="pallet.excelImportTag" maxlength="50"/>
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{pallet.excelImportTag}}
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td [pEditableColumn]="pallet.excelImportBagType" pEditableColumnField="excelImportBagType">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input pInputText type="text" [(ngModel)]="pallet.excelImportBagType" maxlength="50"/>
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{pallet.excelImportBagType}}
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td [pEditableColumn]="pallet.excelImportBoxSize" pEditableColumnField="excelImportBoxSize">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input pInputText type="text" [(ngModel)]="pallet.excelImportBoxSize" maxlength="50"/>
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{pallet.excelImportBoxSize}}
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td [pEditableColumn]="pallet.excelImportNetWeight" pEditableColumnField="excelImportNetWeight">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input pInputText type="text" [(ngModel)]="pallet.excelImportNetWeight" maxlength="50"/>
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{pallet.excelImportNetWeight}}
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td [pEditableColumn]="pallet.excelImportFridgeStorage" pEditableColumnField="excelImportFridgeStorage">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input pInputText type="text" [(ngModel)]="pallet.excelImportFridgeStorage" maxlength="50"/>
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{pallet.excelImportFridgeStorage}}
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td [pEditableColumn]="pallet.excelImportBoxesPerPallet" pEditableColumnField="excelImportBoxesPerPallet">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input pInputText type="text" [(ngModel)]="pallet.excelImportBoxesPerPallet" maxlength="50"/>
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{pallet.excelImportBoxesPerPallet}}
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td [pEditableColumn]="pallet.excelImportMark" pEditableColumnField="excelImportMark">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input pInputText type="text" [(ngModel)]="pallet.excelImportMark" maxlength="50"/>
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{pallet.excelImportMark}}
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td [pEditableColumn]="pallet.excelImportGrade" pEditableColumnField="excelImportGrade">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input pInputText type="text" [(ngModel)]="pallet.excelImportGrade" maxlength="50"/>
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{pallet.excelImportGrade}}
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td [pEditableColumn]="pallet.excelImportContainerNumber" pEditableColumnField="excelImportContainerNumber">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input pInputText type="text" [(ngModel)]="pallet.excelImportContainerNumber" maxlength="50"/>
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{pallet.excelImportContainerNumber}}
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td>{{ dto.etd ? (dto.etd | date:'dd-MM-yyyy') : '(Post loading information)' }}</td>
                  <td>{{ dto.eta ? (dto.eta | date:'dd-MM-yyyy') : '(Post loading information)'}}</td>
                  <td>{{ dto.week ? dto.week : '(Post loading information)'}}</td>
                  <td [pEditableColumn]="pallet.excelImportOriginCountry" pEditableColumnField="excelImportOriginCountry">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input pInputText type="text" [(ngModel)]="pallet.excelImportOriginCountry" maxlength="50"/>
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{pallet.excelImportOriginCountry}}
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td>{{ dto.destinationPortName ? dto.destinationPortName : '(Post loading information)'}}</td>
                  <td [pEditableColumn]="pallet.excelImportTemptale" pEditableColumnField="excelImportTemptale">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input pInputText type="text" [(ngModel)]="pallet.excelImportTemptale" maxlength="50"/>
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{pallet.excelImportTemptale}}
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td [pEditableColumn]="pallet.excelImportCertificate1" pEditableColumnField="excelImportCertificate1">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input pInputText type="text" [(ngModel)]="pallet.excelImportCertificate1" maxlength="50"/>
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{pallet.excelImportCertificate1}}
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td [pEditableColumn]="pallet.excelImportCertificate2" pEditableColumnField="excelImportCertificate1">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input pInputText type="text" [(ngModel)]="pallet.excelImportCertificate2" maxlength="50"/>
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{pallet.excelImportCertificate2}}
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td [pEditableColumn]="pallet.excelImportCertificate3" pEditableColumnField="excelImportCertificate3">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input pInputText type="text" [(ngModel)]="pallet.excelImportCertificate3" maxlength="50"/>
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{pallet.excelImportCertificate3}}
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td [pEditableColumn]="pallet.excelImportLoadWeek" pEditableColumnField="excelImportLoadWeek">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input pInputText type="text" [(ngModel)]="pallet.excelImportLoadWeek" maxlength="50"/>
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{pallet.excelImportLoadWeek}}
                      </ng-template>
                    </p-cellEditor>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>

          <div
          style="margin-top: 40px;" *ngIf="detail.isClosedSale"
          class="flex justify-content-end"
          >
            <p-button
            (onClick)="showPalletPriceModal()"
            class="m-4"
            label="{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.PricePalletButton' | translate}}"
            icon="pi pi-dollar"
            >
            </p-button>
            <p-button
            *ngIf="currentPrices.length > 0"
            class="m-4"
            (onClick)="showCurrentPalletPriceModal()"
            label="{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.ViewPricePalletButton' | translate}}"
            severity="secondary"
            icon="pi pi-eye"

            >
            </p-button>
          </div>

          <div style="margin-top: 40px;" *ngIf="detail.isClosedSale">
            <div class="p-fluid formgrid mr-2 ml-2 grid">
              <div class="field col-12 md:col-4 lg:col-4" style="margin-bottom: 40px;">
                <label for="harvestDate" style="font-weight: bolder;">{{'UserPages.UserExportOffers.NewExportOffer.HarvestDateFieldLabel' | translate}} *</label>
                <p-calendar
                  inputId="harvestDate"
                  formControlName="harvestDate"
                  appendTo="body"
                  [showButtonBar]="true"
                  [showIcon]="true"
                  placeholder="{{'UserPages.UserExportOffers.NewExportOffer.HarvestDateFieldPlaceholder' | translate}}"
                  [showWeek]="true"
                  [firstDayOfWeek]="1"></p-calendar>
                <small *ngIf="submit && directSaleFormGroup.controls['harvestDate'].invalid" class="p-error">{{'harvestDate' | formErrorMessage: directSaleFormGroup}}</small>
              </div>
              <div class="field col-12 md:col-4 lg:col-4" style="margin-bottom: 40px;">
                <label for="shelfLife" style="font-weight: bolder;">Shelf Life *</label>
                <p-inputNumber inputId="shelfLife" formControlName="shelfLife"  [min]="1" [max]="1000" placeholder="Shelf life"></p-inputNumber>
                <small *ngIf="submit && directSaleFormGroup.controls['shelfLife'].invalid" class="p-error">{{'shelfLife' | formErrorMessage: directSaleFormGroup}}</small>
              </div>
              <div class="field col-12 md:col-4 lg:col-4" style="margin-bottom: 40px;">
                <label for="readyToLoadDate" style="font-weight: bolder;">{{'UserPages.UserExportOffers.NewExportOffer.ReadyToLoadDateFieldLabel' | translate}}</label>
                <p-calendar
                  inputId="readyToLoadDate"
                  formControlName="readyToLoadDate"
                  appendTo="body"
                  [showButtonBar]="true"
                  [showIcon]="true"
                  placeholder="{{'UserPages.UserExportOffers.NewExportOffer.ReadyToLoadDateFieldPlaceholder' | translate}}"
                  [showWeek]="true"
                  [firstDayOfWeek]="1"></p-calendar>
                <small *ngIf="submit && directSaleFormGroup.controls['readyToLoadDate'].invalid" class="p-error">{{'readyToLoadDate' | formErrorMessage: directSaleFormGroup}}</small>
              </div>
            </div>
          </div>

          <div style="margin-top: 30px;" *ngIf="detail.isClosedSale && !detail.isSelectOriginQCCompany">
            <h3>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.OriginQCReviewTitle' | translate}}</h3>
            <p-table
              [value]="originQCCompanies"
              selectionMode="single"
              [(selection)]="selectedOriginQCCompany"
              [paginator]="true"
              [rows]="5"
              responsiveLayout="scroll">
              <ng-template pTemplate="header">
                <tr>
                  <th>QC Company</th>
                  <th>{{'ContainerOrderPage.QCCompanyModal.QCCompanyBasePricePerPalletHeader' | translate}}</th>
                  <th>{{'ContainerOrderPage.QCCompanyModal.QCCompanyBasePricePerContainerHeader' | translate}}</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-company>
                <tr [pSelectableRow]="company">
                  <td>{{company.profile?.companyName}}</td>
                  <td>{{company.basePricePerPallet | currency: 'USD':'US$'}}</td>
                  <td>{{company.basePricePerContainer | currency: 'USD':'US$'}}</td>
                </tr>
              </ng-template>
            </p-table>
          </div>

          <!--div style="margin-top: 30px;">
            <h3>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.DestinationQCReviewTitle' | translate}}</h3>
            <p-table
              [value]="destinationQCCompanies"
              selectionMode="single"
              [(selection)]="selectedDestinationQCCompany"
              [paginator]="true"
              [rows]="5"
              responsiveLayout="scroll">
              <ng-template pTemplate="header">
                <tr>
                  <th>{{'ContainerOrderPage.QCCompanyModal.QCCompanyNameHeader' | translate}}</th>
                  <th>{{'ContainerOrderPage.QCCompanyModal.QCCompanyBasePricePerPalletHeader' | translate}}</th>
                  <th>{{'ContainerOrderPage.QCCompanyModal.QCCompanyBasePricePerContainerHeader' | translate}}</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-company>
                <tr [pSelectableRow]="company">
                  <td>{{company.profile?.companyName}}</td>
                  <td>{{company.basePricePerPallet | currency: 'USD':'US$'}}</td>
                  <td>{{company.basePricePerContainer | currency: 'USD':'US$'}}</td>
                </tr>
              </ng-template>
            </p-table>
          </div-->

          <div class="grid" style="margin-top: 40px;">
            <div class="col-6"></div>
            <div class="col-6">
              <div class="grid p-fluid">
                <div class="col-6">
                  <p-button label="{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.BackButton' | translate}}" (onClick)="onCancel()" styleClass="p-button-outlined" [loading]="loading"></p-button>
                </div>
                <div class="col-6">
                  <p-button label="{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.SaveButton' | translate}}" (onClick)="save()" [loading]="loading"></p-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div *ngIf="detail && !isCreate && !isEdit && !success" class="m-4 container-width">
        <p-table
          [value]="pallets"
          [tableStyle]="{ 'min-width': '50rem' }"
          [loading]="loading"
          responsiveLayout="stack"
          [breakpoint]="'600px'"
          styleClass="p-datatable-gridlines"
          [scrollable]="true"
          [resizableColumns]="true"
          columnResizeMode="expand"
          scrollHeight="600px">
          <ng-template pTemplate="header">
            <tr>
              <th>Status</th>
              <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.FolioHeader' | translate}}</th>
              <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.SpeciesHeader' | translate}}</th>
              <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.VarietyHeader' | translate}}</th>
              <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.ProducerHeader' | translate}}</th>
              <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.SpeciesSizeHeader' | translate}}</th>
              <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.CategoryHeader' | translate}}</th>
              <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.HarvestDateHeader' | translate}}</th>
              <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.QualityControlDateHeader' | translate}}</th>
              <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.PackagingHeader' | translate}}</th>
              <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.PalletTypeHeader' | translate}}</th>
              <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.TagHeader' | translate}}</th>
              <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.BagTypeHeader' | translate}}</th>
              <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.BoxTypeHeader' | translate}}</th>
              <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.NetWeightHeader' | translate}}</th>
              <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.ColdStorageHeader' | translate}}</th>
              <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.BoxePerPalletHeader' | translate}}</th>
              <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.MarksHeader' | translate}}</th>
              <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.GradeHeader' | translate}}</th>
              <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.ContainerNumberHeader' | translate}}</th>
              <th>ETD</th>
              <th>ETA</th>
              <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadWeekHeader' | translate}}</th>
              <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.OriginCountryHeader' | translate}}</th>
              <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.DestinationCountryHeader' | translate}}</th>
              <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.TemptaleHeader' | translate}}</th>
              <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.Cert1Header' | translate}}</th>
              <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.Cert2Header' | translate}}</th>
              <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.Cert3Header' | translate}}</th>
              <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadWeekHeader' | translate}}</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-pallet>
            <tr>
              <td>
                <i class="pi pi-hourglass" *ngIf="pallet.status == 1" pTooltip="Pending"></i>
                <i class="pi pi-check" style="color: slateblue" *ngIf="pallet.status == 2" pTooltip="Approved"></i>
                <i class="pi pi-times" style="color: darkred" *ngIf="pallet.status == 3" pTooltip="Rejected"></i>
              </td>
              <td class="flexbox">
                <p>{{ pallet.palletNumber.slice(0, -4) }}</p>
                <p [class.blur]="!hideBlur">{{ pallet.palletNumber.slice(-4) }}</p>
              </td>
              <td>{{pallet.excelImportSpecies}}</td>
              <td>{{pallet.excelImportVariety}}</td>
              <td>
                <p  [class.blur]="!hideBlur">
                  {{pallet.producer}}
                </p>
              </td>
              <!--
                <td [ngStyle]="{'filter': 'blur(5px)'}">{{pallet.producer}}</td>
                <td *ngIf="exportOfferId && profile?.type === 1" [ngStyle]="{'filter': 'blur(5px)'}">{{pallet.producer}}</td>
              -->
              <td>{{pallet.speciesSize}}</td>
              <td>{{pallet.category}}</td>
              <td>{{pallet.excelImportHarvestDate}}</td>
              <td>{{pallet.excelImportQualityControlDate}}</td>
              <td>{{pallet.excelImportPackaging}}</td>
              <td>{{pallet.excelImportPalletType}}</td>
              <td>{{pallet.excelImportTag}}</td>
              <td>
                <p  [class.blur]="!hideBlur">
                {{pallet.excelImportBagType}}
                </p>
              </td>
              <td>
                <p  [class.blur]="!hideBlur">
                  {{pallet.excelImportBoxSize}}
                </p>
              </td>
              <td>
                <p  [class.blur]="!hideBlur">
                  {{pallet.excelImportNetWeight}}
                </p>
              </td>
              <td>
                <p  [class.blur]="!hideBlur">
                  {{pallet.excelImportFridgeStorage}}
                </p>
              </td>
              <td>
                <p  [class.blur]="!hideBlur">
                  {{pallet.excelImportBoxesPerPallet}}
                </p>
              </td>
              <td>
                <p  [class.blur]="!hideBlur">
                  {{pallet.excelImportMark}}
                </p>
              </td>
              <td>
                <p  [class.blur]="!hideBlur">
                  {{pallet.excelImportGrade}}
                </p>
              </td>
              <td>
                <p  [class.blur]="!hideBlur">
                  {{pallet.excelImportContainerNumber}}
                </p>
              </td>
              <td>
                <p  [class.blur]="!hideBlur">
                  {{ dto.etd ? (dto.etd | date:'dd-MM-yyyy') : '(Post loading information)'}}
                </p>
              </td>
              <td>
                <p  [class.blur]="!hideBlur">
                  {{ dto.eta ? (dto.eta | date:'dd-MM-yyyy') : '(Post loading information)'}}
                </p>
              </td>
              <td>
                <p  [class.blur]="!hideBlur">
                  {{ dto.week ? dto.week : '(Post loading information)'}}
                </p>
              </td>
              <td>
                <p  [class.blur]="!hideBlur">
                  {{pallet.excelImportOriginCountry}}
                </p>
              </td>
              <td>
                <p  [class.blur]="!hideBlur">
                  {{ dto.destinationPortCountry ? dto.destinationPortCountry : '(Post loading information)'}}
                </p>
              </td>
              <td>
                <p  [class.blur]="!hideBlur">
                  {{pallet.excelImportTemptale}}
                </p>
              </td>
              <td>
                <p  [class.blur]="!hideBlur">
                  {{pallet.excelImportCertificate1}}
                </p>
              </td>
              <td>
                <p  [class.blur]="!hideBlur">
                  {{pallet.excelImportCertificate2}}
                </p>
              </td>
              <td>
                <p  [class.blur]="!hideBlur">
                  {{pallet.excelImportCertificate3}}
                </p>
              </td>
              <td>
                <p  [class.blur]="!hideBlur">
                  {{pallet.excelImportLoadWeek}}
                </p>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>

      <div class="grid col-12" style="margin-top: 50px;" *ngIf="dto.importOrderDetail.isQCPaid ==  false && this.profile?.type == 2">
        <div class="col-6">
          <div *ngIf="itemQCSelected">
            <h3>Empresa QC</h3>
            <p>{{itemQCSelected?.profile?.CompanyName}}</p>
            <div>
              <p *ngIf="itemQCSelected?.isConsolidateContainer" >
                {{'QCQuestions.HasConsolidatedContainer' | translate}}
              </p>
              <p *ngIf="!itemQCSelected?.isConsolidateContainer" >
                {{'QCQuestions.NotHasConsolidatedContainer' | translate}}
              </p>
            </div>
            <div>
              <p *ngIf="itemQCSelected?.hasQCSystem" >
                {{'QCQuestions.HasQcSystem' | translate}}
              </p>
              <p *ngIf="!itemQCSelected?.hasQCSystem" >
                {{'QCQuestions.NotHasQcSystem' | translate}}
              </p>
              <p-table
              [value]="companySpeciesSelected"
              [paginator]="true"
              [rows]="5"
              >
              <ng-template pTemplate="header" >
                <th>{{'QCDataTable.Name' | translate}}</th>
                <th>{{'QCDataTable.Species' | translate}}</th>
                <th>{{'QCDataTable.Standard' | translate}}</th>
                <th>{{'QCDataTable.Tolerance' | translate}}</th>
                <th>{{'QCDataTable.QCPrice' | translate}}</th>
                <th>{{'QCDataTable.ContainerPrice' | translate}}</th>
              </ng-template>
              <ng-template pTemplate="body" let-companySpecie>
                <tr>
                  <td style="text-align: center;">{{itemQCSelected?.profile?.companyName}}</td>
                  <td style="text-align: center;">{{companySpecie.species.name}}</td>
                  <td style="text-align: center;">{{companySpecie.standards}}</td>
                  <td style="text-align: center;">{{companySpecie.tolerance}}</td>
                  <td style="text-align: center;">{{companySpecie.qcCost  | currency: 'USD':'US$' }}</td>
                  <td style="text-align: center;">{{companySpecie.qcCostPerContainer | currency: 'USD':'US$'}}</td>
                </tr>
              </ng-template>
              </p-table>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="col-12 flex justify-content-end">
            <p-button label="QC Company" (onClick)="showSelectQCCompany()" styleClass="p-button-outlined"></p-button>
            <p-button label="{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.ConfirmQcPurchase' | translate}}" (click)="onAccept()" [style]="{'margin-left': '30px'}"></p-button>
          </div>
        </div>
      </div>

      <div *ngIf="success">
        <div class="flex align-items-center justify-content-center flex-column">
          <i class="pi pi-check-circle" style="font-size: 2.5rem"></i>
          <h1>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.SuccessMessageText' | translate}}</h1>
          <p-button label="{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.AcceptButton' | translate}}" (click)="onCancel()"></p-button>
        </div>
      </div>
    </p-card>

  </p-tabPanel>

  <p-tabPanel header="{{'TabVerifyPallets.RevisionQCHeader' | translate}}">
    <p-card *ngIf="dto && detail">
      <div style="margin-top: 100px;">
        <div style="margin-top: 30px;">
          <h2>Origin QC Reviews</h2>
          <p-table
            [value]="originQCReviews"
            [paginator]="true"
            [rows]="5"
            responsiveLayout="scroll">
            <ng-template pTemplate="header">
              <tr>
                <th>Status</th>
                <th>QC Company</th>
                <th>QC Date</th>
                <th>Price</th>
                <th>Observations</th>
                <th>Review Document</th>
                <th>Consolidation Document</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-review>
              <tr>
                <td>
                  <p-tag severity="warning" value="Created" *ngIf="review.status === 0"></p-tag>
                  <p-tag severity="warning" value="Pending" *ngIf="review.status === 1"></p-tag>
                  <p-tag severity="success" value="Reviewed" *ngIf="review.status === 2"></p-tag>
                  <p-tag severity="danger" value="Cancelled" *ngIf="review.status === 3"></p-tag>
                </td>
                <td>{{review.qualityControlCompanyName}}</td>
                <td>{{review.qualityControlDate ? (review.qualityControlDate | date: 'dd-MM-yyyy') : 'Pending review'}}</td>
                <td>{{review.price ? (review.price | currency: 'USD':'US$') : 'Pending review'}}</td>
                <td>{{review.observations ? review.observations : 'No observations'}}</td>
                <td><p-button [disabled]="review.status !== 2" pTooltip="View file" icon="pi pi-file-pdf" (onClick)="viewOriginQCReviewDocument(review)" styleClass="p-button-outlined"></p-button></td>
                <td><p-button [disabled]="review.status !== 2" pTooltip="View file" icon="pi pi-file-pdf" (onClick)="viewOriginQCConsolidationDocument(review)" styleClass="p-button-outlined"></p-button></td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </p-card>

  </p-tabPanel>

  <p-tabPanel header="{{'TabVerifyPallets.DocumentsHeader' | translate}}">
    <p-card *ngIf="dto && detail">
      <div style="margin-top: 50px;" *ngIf="exportOfferId && profile?.type === 1">
        <h2>{{'UserPages.UserExportOffers.EditExportOffer.PageTitle6' | translate}}</h2>
        <p>{{'UserPages.UserExportOffers.EditExportOffer.PageSubtitle5' | translate}}</p>
        <app-export-offer-product-certificates-crud [exportOfferId]="exportOfferId"></app-export-offer-product-certificates-crud>
      </div>

      <div style="margin-top: 50px;" *ngIf="exportOfferId && profile?.type === 1">
        <h2>{{'UserPages.UserExportOffers.NewExportOffer.PageTitle6' | translate}}</h2>
        <p>{{'UserPages.UserExportOffers.NewExportOffer.PageSubtitle6' | translate}}</p>
        <app-export-offer-documents-crud [exportOfferId]="exportOfferId"></app-export-offer-documents-crud>
      </div>
    </p-card>
  </p-tabPanel>
</p-tabView>

<!-- dialog for QC Companies  -->
<p-dialog header="QC Company" [(visible)]="visibleQCCompany" [style]="{width: '40vw'}">
  <p>{{'ContainerOrderPage.QCCompanyModal.Text' | translate}}</p>
  <p-table [value]="qcCompanies"
    [selection]="itemQCSelected"
    selectionMode="single"
    (onRowSelect)="onQCCompanyTableRowSelect($event)"
    [paginator]="true"
    [rows]="5"
    responsiveLayout="scroll">
    <ng-template pTemplate="header">
      <tr>
        <th>{{'ContainerOrderPage.QCCompanyModal.QCCompanyNameHeader' | translate}}</th>
        <th>{{'ContainerOrderPage.QCCompanyModal.QCCompanyBasePricePerPalletHeader' | translate}}</th>
        <th>{{'ContainerOrderPage.QCCompanyModal.QCCompanyBasePricePerContainerHeader' | translate}}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-company>
      <tr [pSelectableRow]="company">
        <td>{{company.profile?.companyName}}</td>
        <td>{{company.basePricePerPallet | currency: 'USD':'US$'}}</td>
        <td>{{company.basePricePerContainer | currency: 'USD':'US$'}}</td>
      </tr>
    </ng-template>
  </p-table>
  <ng-template pTemplate="footer">
    <p-button label="Cerrar" (onClick)="visibleQCCompany = false" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>

<!-- dialog for NoQC  -->
<p-dialog [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleDisclaimerQCDialog" [style]="{ 'min-width': '500px', 'max-width': '900px'}">
  <div class="grid flex align-items-center" style="padding: 0 50px;">
    <div class="col-12 md:col-12 flex justify-content-center align-items-start flex-column" style="font-size: 1.2rem">
      <p style="color: #4C4C4C; font-weight: bolder; margin: 0 auto">
        <span>{{'ContainerOrderPage.QCCompanyModal.TitleDisclaimer' | translate}}</span>
      </p>

      <p style="text-align: center; font-weight: 400; margin: 40px 7px 0">
        {{'ContainerOrderPage.QCCompanyModal.SentenceDisclaimerOne' | translate}}
      </p>

      <p style="text-align: center; font-weight: bolder; margin: 12px 0">
        {{'ContainerOrderPage.QCCompanyModal.SentenceDisclaimerTwo' | translate}}
      </p>

      <div class="col-12 flex justify-content-center align-items-center">
        <img src="../../../../assets/img/disclaimer_icon_fruzty_.png" style="width: 100%; max-width: 100px;" alt="fruzty-logo"/>
      </div>

      <div class="col-12 flex justify-content-evenly align-items-center" style="margin: 20px auto">
        <button pButton (click)="withoutNOQC()" style="color: #6EA042; border-radius: 5px; background-color: #FFFFFF; width: auto; max-width: 180px; height: 40px; border: 1px solid #6EA042">
          {{'ContainerOrderPage.QCCompanyModal.ButtonWithoutQCDisclaimer' | translate}}
        </button>
        <button pButton (click)="withNOQC()" style="background-color: #6EA042; border-radius: 5px; color: #FFFFFF; width: auto; max-width: 180px; height: 40px;">
          {{'ContainerOrderPage.QCCompanyModal.ButtonWithQCDisclaimer' | translate}}
        </button>
      </div>
    </div>
  </div>
</p-dialog>

<!-- dialog for new prices  -->
<p-dialog header="Detalle de sus pallets" [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visiblePalletsPrice" [style]="{width: '800px'}">
  <p-table
  [value]="palletsPrice"
  [paginator]="true"
  [rows]="10"
  [loading]="loading"
  responsiveLayout="stack"
  [breakpoint]="'600px'"
  [showCurrentPageReport]="true"
  [rowsPerPageOptions]="[10, 25, 50]"
  >
  <ng-template pTemplate="header">
    <tr>
      <th>
        {{'UserPages.UserPurchaseOrders.VerifyPalletsPage.PalletPriceModal.CategoryHeader' | translate}}
      </th>
      <th>
        {{'UserPages.UserPurchaseOrders.VerifyPalletsPage.PalletPriceModal.SpeciesSizeHeader' | translate}}
      </th>
      <th>
        {{'UserPages.UserPurchaseOrders.VerifyPalletsPage.PalletPriceModal.BoxQuantityHeader' | translate}}
      </th>
      <th>
        {{'UserPages.UserPurchaseOrders.VerifyPalletsPage.PalletPriceModal.PriceHeader' | translate}}
      </th>
    </tr>
  </ng-template>
  <ng-template  pTemplate="body" let-palletPrice>
    <tr>
      <td>
        {{palletPrice.category}}
      </td>
      <td>
        {{palletPrice.speciesSize}}
      </td>
      <td>
        {{palletPrice.boxPerPallets}}
      </td>
      <td>
        <p-inputNumber
        [(ngModel)]="palletPrice.price"
        placeholder="{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.PalletPriceModal.PricePlaceholder' | translate}}"
        mode="currency" currency="USD" locale="en-US" [min]="0"
        class="p-inputtext-sm"></p-inputNumber>
      </td>
    </tr>
  </ng-template>
  </p-table>
  <ng-template pTemplate="footer">
    <p-button label="{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.PalletPriceModal.ConfirmButton' | translate}}" (click)="confirmPalletPrice()"></p-button>
  </ng-template>
</p-dialog>

<!-- dialog for view current price -->
<p-dialog header="{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.PalletPriceModal.CurrentPricesHeader' | translate}}" [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleCurrentPalletsPrice" [style]="{width: '800px'}">
  <p-table
  [value]="currentPrices"
  [paginator]="true"
  [rows]="10"
  [loading]="loading"
  responsiveLayout="stack"
  [breakpoint]="'600px'"
  [showCurrentPageReport]="true"
  [rowsPerPageOptions]="[10, 25, 50]"
  >
  <ng-template pTemplate="header">
    <tr>
      <th>
        {{'UserPages.UserPurchaseOrders.VerifyPalletsPage.PalletPriceModal.CategoryHeader' | translate}}
      </th>
      <th>
        {{'UserPages.UserPurchaseOrders.VerifyPalletsPage.PalletPriceModal.SpeciesSizeHeader' | translate}}
      </th>
      <th>
        {{'UserPages.UserPurchaseOrders.VerifyPalletsPage.PalletPriceModal.BoxQuantityHeader' | translate}}
      </th>
      <th>
        {{'UserPages.UserPurchaseOrders.VerifyPalletsPage.PalletPriceModal.PriceHeader' | translate}}
      </th>
    </tr>
  </ng-template>
  <ng-template  pTemplate="body" let-palletPrice>
    <tr>
      <td>
        {{palletPrice.category}}
      </td>
      <td>
        {{palletPrice.speciesSize}}
      </td>
      <td>
        {{palletPrice.boxPerPallets}}
      </td>
      <td>
        {{palletPrice.price | currency: 'USD'}}
      </td>
    </tr>
  </ng-template>
  </p-table>
</p-dialog>

<p-dialog header="{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.DialogTitle' | translate}}" [(visible)]="visible" [modal]="true" [style]="{width: '50vw'}">
  <form [formGroup]="palletFormGroup">
    <div class="p-fluid formgrid grid">
      <div class="field col-12" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.LoadFileFieldLabel' | translate}}</label>
        <p-fileUpload
          #excelUploader
          mode="basic"
          [multiple]="false"
          [auto]="true"
          chooseLabel="{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.LoadFileFieldPlaceholder' | translate}}"
          name="demo[]"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          [maxFileSize]="1000000"
          (onSelect)="onSelectExcelFile(excelUploader)">
        </p-fileUpload>
        <p *ngIf="excelFile">{{excelFile.name}}</p>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.SheetFiedlLabel' | translate}}</label>
        <p-dropdown [options]="excelSheets" [(ngModel)]="selectedSheet" [ngModelOptions]="{standalone: true}" [disabled]="!excelFile"></p-dropdown>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.StartRowFieldLabel' | translate}} *</label>
        <p-inputNumber formControlName="startRow" [min]="1" [maxlength]="10" [disabled]="!excelFile" placeholder=""/>
        <small *ngIf="palletFormGroup.controls['startRow'].invalid" class="p-error">{{getErrorMessage(palletFormGroup, 'startRow')}}</small>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;"></div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.FolioColumnFieldLabel' | translate}} *</label>
        <input type="text" formControlName="folioColumn" pInputText maxlength="1" placeholder=""/>
        <small *ngIf="palletFormGroup.controls['folioColumn'].invalid" class="p-error">{{getErrorMessage(palletFormGroup, 'folioColumn')}}</small>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.SpeciesColumnFieldLabel' | translate}} *</label>
        <input type="text" formControlName="speciesColumn" pInputText maxlength="1" placeholder=""/>
        <small *ngIf="palletFormGroup.controls['speciesColumn'].invalid" class="p-error">{{getErrorMessage(palletFormGroup, 'speciesColumn')}}</small>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.VarietyColumnFieldLabel' | translate}} *</label>
        <input type="text" formControlName="varietyColumn" pInputText maxlength="1" placeholder=""/>
        <small *ngIf="palletFormGroup.controls['varietyColumn'].invalid" class="p-error">{{getErrorMessage(palletFormGroup, 'varietyColumn')}}</small>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.ProducerColumnFieldLabel' | translate}} *</label>
        <input type="text" formControlName="producerColumn" pInputText maxlength="1" placeholder=""/>
        <small *ngIf="palletFormGroup.controls['producerColumn'].invalid" class="p-error">{{getErrorMessage(palletFormGroup, 'producerColumn')}}</small>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.SpeciesSizeColumnFieldLabel' | translate}}</label>
        <input type="text" formControlName="speciesSizeColumn" pInputText maxlength="1" placeholder=""/>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>Category</label>
        <input type="text" formControlName="categoryColumn" pInputText maxlength="1" placeholder=""/>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.HarvestDateColumnFielLabel' | translate}}</label>
        <input type="text" formControlName="harvestDateColumn" pInputText maxlength="1" placeholder=""/>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.QualityControlDateColumnFielLabel' | translate}}</label>
        <input type="text" formControlName="qualityControlDateColumn" pInputText maxlength="1" placeholder=""/>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.PackagingColumnFielLabel' | translate}}</label>
        <input type="text" formControlName="packagingColumn" pInputText maxlength="1" placeholder=""/>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.PalletTypeColumnFielLabel' | translate}}</label>
        <input type="text" formControlName="palletTypeColumn" pInputText maxlength="1" placeholder=""/>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.TagColumnFielLabel' | translate}}</label>
        <input type="text" formControlName="tagColumn" pInputText maxlength="1" placeholder=""/>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.BagTypeColumnFielLabel' | translate}}</label>
        <input type="text" formControlName="bagTypeColumn" pInputText maxlength="1" placeholder=""/>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.BoxSizeColumnFielLabel' | translate}}</label>
        <input type="text" formControlName="boxSizeColumn" pInputText maxlength="1" placeholder=""/>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.NetWeightColumnFielLabel' | translate}}</label>
        <input type="text" formControlName="netWeightColumn" pInputText maxlength="1" placeholder=""/>
      </div>
      <!--div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.FridgeStorageColumnFielLabel' | translate}}</label>
        <input type="text" formControlName="fridgeStorageColumn" pInputText maxlength="1" placeholder=""/>
      </div-->
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.BoxesPerPalletColumnFielLabel' | translate}}</label>
        <input type="text" formControlName="boxesPerPalletColumn" pInputText maxlength="1" placeholder=""/>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.MarkColumnFielLabel' | translate}}</label>
        <input type="text" formControlName="markColumn" pInputText maxlength="1" placeholder=""/>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.ContainerNumberColumnFielLabel' | translate}}</label>
        <input type="text" formControlName="containerNumberColumn" pInputText maxlength="1" placeholder=""/>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.GradeColumnFielLabel' | translate}}</label>
        <input type="text" formControlName="gradeColumn" pInputText maxlength="1" placeholder=""/>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.TemptaleColumnFielLabel' | translate}}</label>
        <input type="text" formControlName="temptaleColumn" pInputText maxlength="1" placeholder=""/>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.OriginCountryColumnFielLabel' | translate}}</label>
        <input type="text" formControlName="originCountryColumn" pInputText maxlength="1" placeholder=""/>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.Certificate1ColumnFielLabel' | translate}}</label>
        <input type="text" formControlName="certificate1Column" pInputText maxlength="1" placeholder=""/>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.Certificate2ColumnFielLabel' | translate}}</label>
        <input type="text" formControlName="certificate2Column" pInputText maxlength="1" placeholder=""/>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.Certificate3ColumnFielLabel' | translate}}</label>
        <input type="text" formControlName="certificate3Column" pInputText maxlength="1" placeholder=""/>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.LoadWeekColumnFielLabel' | translate}}</label>
        <input type="text" formControlName="loadWeekColumn" pInputText maxlength="1" placeholder=""/>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;"></div>
      <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;"></div>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <p-button label="{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.LoadButton' | translate}}" (click)="onLoadExcel2()" [disabled]="!excelFile"></p-button>
  </ng-template>
</p-dialog>

<p-dialog header="{{'UserPages.UserExportOffers.NewExportOffer.NewFriedStorageModal.ModalTitle' | translate}}" [(visible)]="loadFridgestorageDialogVisible" [style]="{width: '70%'}" [modal]="true">
  <form [formGroup]="fridgestorageFormGroup">
    <div class="p-fluid formgrid grid" style="padding: 20px;">
      <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
        <label for="fridgestorageName">{{'UserPages.UserExportOffers.NewExportOffer.NewFriedStorageModal.NameFieldLabel' | translate}} *</label>
        <input id="fridgestorageName" formControlName="fridgestorageName" type="text" pInputText [maxlength]="300" required autofocus placeholder="{{'UserPages.UserExportOffers.NewExportOffer.NewFriedStorageModal.NameFieldPlaceholder' | translate}}"/>
        <small *ngIf="submit && fridgestorageFormGroup.controls['fridgestorageName'].invalid" class="p-error">{{getErrorMessage(fridgestorageFormGroup, 'fridgestorageName')}}</small>
      </div>
      <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
        <label for="fridgestorageVat">{{'UserPages.UserExportOffers.NewExportOffer.NewFriedStorageModal.VatFieldLabel' | translate}}</label>
        <input id="fridgestorageVat" pInputText formControlName="fridgestorageVat" [maxlength]="20" placeholder="{{'UserPages.UserExportOffers.NewExportOffer.NewFriedStorageModal.VatFieldPlaceholder' | translate}}"/>
      </div>
      <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
        <label for="fridgestorageContactName">{{'UserPages.UserExportOffers.NewExportOffer.NewFriedStorageModal.ContactNameFieldLabel' | translate}} *</label>
        <input id="fridgestorageContactName" formControlName="fridgestorageContactName" type="text" [maxlength]="300" pInputText required placeholder="{{'UserPages.UserExportOffers.NewExportOffer.NewFriedStorageModal.ContactNameFieldPlaceholder' | translate}}"/>
        <small *ngIf="submit && fridgestorageFormGroup.controls['fridgestorageContactName'].invalid" class="p-error">{{getErrorMessage(fridgestorageFormGroup, 'fridgestorageContactName')}}</small>
      </div>
      <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
        <label for="fridgestorageContactEmail">{{'UserPages.UserExportOffers.NewExportOffer.NewFriedStorageModal.ContactEmailFieldLabel' | translate}} *</label>
        <input id="fridgestorageContactEmail" formControlName="fridgestorageContactEmail" type="text" [maxlength]="100" pInputText required placeholder="{{'UserPages.UserExportOffers.NewExportOffer.NewFriedStorageModal.ContactEmailFieldPlaceholder' | translate}}"/>
        <small *ngIf="submit && fridgestorageFormGroup.controls['fridgestorageContactEmail'].invalid" class="p-error">{{getErrorMessage(fridgestorageFormGroup, 'fridgestorageContactEmail')}}</small>
      </div>
      <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
        <label for="fridgestorageContactAddress">{{'UserPages.UserExportOffers.NewExportOffer.NewFriedStorageModal.FactoryAddressLabel' | translate}} *</label>
        <input id="fridgestorageContactAddress" formControlName="fridgestorageContactAddress" type="text" [maxlength]="300" pInputText required placeholder="{{'UserPages.UserExportOffers.NewExportOffer.NewFriedStorageModal.FactoryAddressPlaceholder' | translate}}"/>
        <small *ngIf="submit && fridgestorageFormGroup.controls['fridgestorageContactAddress'].invalid" class="p-error">{{getErrorMessage(fridgestorageFormGroup, 'fridgestorageContactAddress')}}</small>
      </div>
      <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
        <label for="fridgestorageContactCity">{{'UserPages.UserExportOffers.NewExportOffer.NewFriedStorageModal.ContactCityFieldLabel' | translate}} *</label>
        <input id="fridgestorageContactCity" formControlName="fridgestorageContactCity" type="text" [maxlength]="100" pInputText required placeholder="{{'UserPages.UserExportOffers.NewExportOffer.NewFriedStorageModal.ContactCityFieldPlaceholder' | translate}}"/>
        <small *ngIf="submit && fridgestorageFormGroup.controls['fridgestorageContactCity'].invalid" class="p-error">{{getErrorMessage(fridgestorageFormGroup, 'fridgestorageContactCity')}}</small>
      </div>
      <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
        <label for="fridgestorageContactCountryId">{{'UserPages.UserExportOffers.NewExportOffer.NewFriedStorageModal.CountryFieldLabel' | translate}} *</label>
        <p-dropdown
            id="fridgestorageContactCountryId"
            [options]="countries"
            formControlName="fridgestorageContactCountryId"
            optionLabel="name"
            optionValue="id"
            [filter]="true"
            filterBy="name"
            [showClear]="true"
            placeholder="{{'UserPages.UserExportOffers.NewExportOffer.NewFriedStorageModal.CountryFieldPlaceholder' | translate}}"
            appendTo="body">
        </p-dropdown>
        <small *ngIf="submit && fridgestorageFormGroup.controls['fridgestorageContactCountryId'].invalid" class="p-error">{{getErrorMessage(fridgestorageFormGroup, 'fridgestorageContactCountryId')}}</small>
      </div>
      <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
        <label for="fridgestorageContactPhone">{{'UserPages.UserExportOffers.NewExportOffer.NewFriedStorageModal.FridgeStorageContactPhoneLabel' | translate}} *</label>
        <input id="fridgestorageContactPhone" formControlName="fridgestorageContactPhone" type="text" pInputText [maxlength]="50" placeholder="{{'UserPages.UserExportOffers.NewExportOffer.NewFriedStorageModal.FridgeStorageContactPhonePlaceholder' | translate}}"/>
        <small *ngIf="submit && fridgestorageFormGroup.controls['fridgestorageContactPhone'].invalid" class="p-error">{{getErrorMessage(fridgestorageFormGroup, 'fridgestorageContactPhone')}}</small>
      </div>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <p-button label="{{'UserPages.UserExportOffers.NewExportOffer.NewFriedStorageModal.CloseButton' | translate}}" (onClick)="onCancelNewFridgestorage()" styleClass="p-button-outlined"></p-button>
    <p-button label="{{'UserPages.UserExportOffers.NewExportOffer.NewFriedStorageModal.SaveButton' | translate}}" (onClick)="saveFridgestorage()" [loading]="loading"></p-button>
  </ng-template>
</p-dialog>

<p-toast></p-toast>
