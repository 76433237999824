<app-admin-dashboard>
    <p-card>
        <ng-template pTemplate="header">
            <div class="flex justify-content-between flex-wrap align-items-center" style="padding: 15px;">
                <h2>Ofertas de Exportación</h2>
            </div>
        </ng-template>
        <p-table
        #dt1
        [value]="exportOffers" [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
        selectionMode="single"
        dataKey="exportOfferInfo.exportOffer.id"
        [rowsPerPageOptions]="[10, 25, 50]"
        [loading]="loading"
        [globalFilterFields]="[
        'exportOffer.id',
        'exportOffer.exporter.contactName',
        'exportOffer.variety.species.name',
        'exportOffer.variety.name',
        'exportOffer.created']"
            >
            <ng-template pTemplate="header">
                <tr>
                    <th>
                        <div class="flex justify-content-center align-items-center">
                            ID
                            <p-columnFilter type="text" field="exportOffer.id" display="menu" [showAddButton]="false" [showMatchModes]="false" [showOperator]="false"></p-columnFilter>
                        </div>
                    </th>
                    <th>
                        <div class="flex justify-content-center align-items-center">
                            Exportador
                            <p-columnFilter type="text" field="exportOffer.exporter.contactName" display="menu" [showAddButton]="false" [showMatchModes]="true" [showOperator]="false"></p-columnFilter>
                        </div>
                    </th>
                    <th>
                        <div class="flex justify-content-center align-items-center">
                            Especie
                            <p-columnFilter type="text" field="exportOffer.variety.species.name" display="menu" [showAddButton]="false" [showMatchModes]="true" [showOperator]="false"></p-columnFilter>
                        </div>
                    </th>
                    <th>
                        <div class="flex justify-content-center align-items-center">
                            Variedad
                            <p-columnFilter type="text" field="exportOffer.variety.name" display="menu" [showAddButton]="false" [showMatchModes]="true" [showOperator]="false"></p-columnFilter>
                        </div>
                    </th>
                    <th>
                      <div class="flex justify-content-center align-items-center">
                          Venta directa
                          <p-columnFilter type="boolean" field="exportOffer.isClosedSale" display="menu" [showAddButton]="false" [showMatchModes]="false" [showOperator]="false"></p-columnFilter>
                      </div>
                    </th>
                    <th>
                        <div class="flex justify-content-center align-items-center">
                            Fecha de carga
                            <!-- <p-columnFilter type="date" field="exportOffer.created" display="menu" [showAddButton]="false" [showMatchModes]="true" [showOperator]="false"></p-columnFilter> -->
                            <p-columnFilter type="date" [field]="'exportOffer.created'" display="menu" [showAddButton]="false" [showMatchModes]="true" [showOperator]="false">
                                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <p-calendar #fd
                                  [ngModel]="value"
                                  dateFormat="dd-mm-yyyy"
                                  [showIcon]="true"
                                  [showOnFocus]="false"
                                  (onSelect)="setup(fd.value, 'exportOffer.created')"
                                  [showWeek]="true"
                                  [firstDayOfWeek]="1">
                                </p-calendar>
                                </ng-template>
                              </p-columnFilter>
                        </div>
                    </th>
                    <th>
                        <div class="flex justify-content-center align-items-center">
                            Precio
                        </div>
                    </th>
                    <th>
                        <div class="flex justify-content-center align-items-center">
                            Acciones
                        </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-exportOfferInfo>
                <tr [pSelectableRow]="exportOfferInfo">
                    <td>
                        <div class="flex justify-content-center align-items-center" style="font-weight: bold;">
                            <p>{{exportOfferInfo.exportOffer.id}}</p>
                        </div>
                    </td>
                    <td>
                        <div class="flex justify-content-center align-items-center">
                            <p>{{exportOfferInfo.exportOffer.exporter.contactName}}</p>
                        </div>
                    </td>
                    <td>
                        <div class="flex justify-content-center align-items-center">
                            <p>{{exportOfferInfo.exportOffer.variety.species.name}}</p>
                        </div>
                    </td>
                    <td>
                        <div class="flex justify-content-center align-items-center">
                            <p>{{exportOfferInfo.exportOffer.variety.name}}</p>
                        </div>
                    </td>
                    <td>
                      <div class="flex justify-content-center align-items-center">
                          <p>{{exportOfferInfo.exportOffer.isClosedSale ? 'Si': 'No'}}</p>
                      </div>
                    </td>
                    <td>
                        <div class="flex justify-content-center align-items-center">
                            {{exportOfferInfo.exportOffer.created | date: 'dd-MM-yyyy'}}
                        </div>
                    </td>
                    <td>
                        <div class="flex justify-content-center align-items-center">
                            {{exportOfferInfo.exportOffer.listPrice}}
                        </div>
                    </td>
                    <td>
                        <div class="flex justify-content-center align-items-center gap-1">
                            <p-button icon="pi pi-pencil" (onClick)="goToAdminEdit(exportOfferInfo.exportOffer.id)" pTooltip="Editar"></p-button>
                            <p-button icon="pi pi-eye-slash" *ngIf="exportOfferInfo.exportOffer.isActive" pTooltip="Desactivar"
                                (onClick)="desactivate(exportOfferInfo.exportOffer)" styleClass="p-button-warning"></p-button>
                            <p-button icon="pi pi-eye" *ngIf="!exportOfferInfo.exportOffer.isActive" pTooltip="Activar"
                            styleClass="p-button-secondary" (onClick)="activate(exportOfferInfo.exportOffer)" ></p-button>
                            <p-button icon="pi pi-trash" *ngIf="!exportOfferInfo.exportOffer.isClosedSale" pTooltip="Eliminar" styleClass="p-button-danger" (onClick)="deleteOffer(exportOfferInfo.exportOffer)" ></p-button>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </p-card>
</app-admin-dashboard>
<p-toast></p-toast>
<p-confirmDialog [style]="{width: '30vw'}"></p-confirmDialog>
