import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SuggestionInformation, SuggestionListItem } from "src/app/domain/models/ExportOfferSuggestion";
import { Routes } from "src/app/infrastructure/routes";
import { ExportOfferSuggestionGateway } from "../gateway/ExportOfferSuggestionGateway";

@Injectable({providedIn: 'root'})
export class ExportOfferSuggestionService extends ExportOfferSuggestionGateway {

  constructor(private _http: HttpClient) {
    super()
  }

  getByProfileId(): Observable<SuggestionListItem[]> {
    return this._http.get<SuggestionListItem[]>(Routes.exportOfferSuggestionApi + '/GetByProfileId')
  }

  getById(suggestionId: number): Observable<SuggestionInformation> {
    return this._http.get<SuggestionInformation>(Routes.exportOfferSuggestionApi + '/GetById', {params: {suggestionId}})
  }

  acceptSuggestion(suggestionExportOfferId: number, suggestionId: number): Observable<any> {
    return this._http.put<any>(Routes.exportOfferSuggestionApi + '/AcceptSuggestion', {}, {params: {suggestionExportOfferId, suggestionId}})
  }

}
