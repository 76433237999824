import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { PalletGateway } from 'src/app/application/gateway/PalletGateway';
import { ImportOrderDetail, ImportOrderDetailInformation } from 'src/app/domain/models/ImportOrder';
import { BasicUser } from 'src/app/domain/models/User';
import { Routes } from 'src/app/infrastructure/routes';

@Component({
  selector: 'app-user-closed-sale-exporter-verify-pallets',
  templateUrl: './user-closed-sale-exporter-verify-pallets.component.html',
  styleUrls: ['./user-closed-sale-exporter-verify-pallets.component.css'],
  providers: [MessageService]
})
export class UserClosedSaleExporterVerifyPalletsComponent implements OnInit {
  breadcrumbMenuItems: MenuItem[] = []
  breadcrumbHome: MenuItem = {}

  orderDetailId: number | undefined
  directSaleProgramId: any | undefined

  importOrderDetailInformationDto: ImportOrderDetailInformation | undefined

  profile: BasicUser | undefined

  constructor(private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _messageService: MessageService,
    private _formBuilder: FormBuilder,
    private _authService: AuthGateway,
    private _palletService: PalletGateway,
    public translate: TranslateService) {
      this._activatedRoute.params.subscribe(params => {
        this.orderDetailId = params['orderDetailId']
        this.directSaleProgramId = params['directSaleProgramId']
      })
  }

  ngOnInit(): void {
    this.profile = this._authService.getUser()

    let breadcrumbHomeItemLabel = 'My direct sales'
    let breadcrumbMenuItemLabel1 = 'Direct sale order program'
    let breadcrumbMenuItemLabel2 = 'Import order detail'
    let breadcrumbMenuItemLabel3 = 'Verify pallets'

    if(this.profile) {
      switch(this.profile.lang) {
        case 'en':
          breadcrumbHomeItemLabel = 'My direct sales'
          breadcrumbMenuItemLabel1 = 'Direct sale order program'
          breadcrumbMenuItemLabel2 = 'Import order detail'
          breadcrumbMenuItemLabel3 = 'Verify pallets'
          break
        case 'es':
          breadcrumbHomeItemLabel = 'Mis ventas directas'
          breadcrumbMenuItemLabel1 = 'Programa de venta directa'
          breadcrumbMenuItemLabel2 = 'Detalle de orden de importación'
          breadcrumbMenuItemLabel3 = 'Verificar  pallets'
          break
      }
    }

    if(this.profile?.profileType == 'Exporter')
      this.breadcrumbHome = {label: breadcrumbHomeItemLabel, routerLink: '/' + Routes.userClosedSales }
    else if(this.profile?.profileType == 'Importer')
      this.breadcrumbHome = {label: breadcrumbHomeItemLabel, command: () => this.goToDirectSaleProgram() }

    this.breadcrumbMenuItems = [
      {label: breadcrumbMenuItemLabel1, command: () => this.goToDirectSaleProgram()},
      {label: breadcrumbMenuItemLabel2, command: () => { this.goToImportOrderPage() }},
      {label: breadcrumbMenuItemLabel3, disabled: true}
    ]

    this.loadImportOrderDetailInformation()
  }

  loadImportOrderDetailInformation() {
    if(this.orderDetailId) {
      this._palletService.getByImportOrderDetailId(this.orderDetailId).subscribe({
        next: (v) => {
          this.importOrderDetailInformationDto = v
        },
        error: (e) => {
          console.log(e)
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ImportOrderDetail.SingleRetrieveError') });
        },
        complete: () => console.info('Order Detail query successfully')
      })
    }
  }

  goToImportOrderPage() {
    if(this.importOrderDetailInformationDto?.importOrderDetail) {
      this._router.navigate(['/' + Routes.userClosedSaleImportOrderDetailPage, this.importOrderDetailInformationDto?.importOrderDetail.importOrderId, this.directSaleProgramId])
    }
  }

  goToDirectSaleProgram() {
    if(this.profile) {
      if(this.profile.profileType == 'Importer') {
        this._router.navigate(['/' + Routes.importerClosedSaleOrdersPage])
      }
      else if(this.profile.profileType == 'Exporter') {
        this._router.navigate(['/' + Routes.exporterClosedSaleOrdersPage, this.directSaleProgramId])
      }
    }
  }
}
