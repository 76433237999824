<app-admin-dashboard>
  <p-table [value]="masterLoads"
    dataKey="product.pallet.id"
    [loading]="loading"
    [paginator]="true"
    [rows]="100"
    [rowsPerPageOptions]="[100, 200, 500, 1000]"
    [scrollable]="true"
    [resizableColumns]="true"
    columnResizeMode="expand"
    scrollHeight="800px"
    [tableStyle]="{ 'min-width': '50rem' }"
    stateStorage="session" stateKey="dt-masterload-session"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="Mostrando desde {first} hasta {last} de {totalRecords} resultados"
    styleClass="p-datatable-gridlines">
    <ng-template pTemplate="caption">
      <div class="flex justify-content-between">
        <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success mr-2" pTooltip="XLS" tooltipPosition="bottom"></button>
        <p-dropdown [options]="years" [(ngModel)]="year" (onChange)="loadMasterLoads()" placeholder="Seleccione un año"></p-dropdown>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th>Id Orden</th>
        <th>Id Detalle</th>
        <th>Id Pallet</th>
        <th>Pallet No</th>
        <th>Tipo de Transporte</th>
        <th>Especie</th>
        <th>Variedad</th>
        <th>Grower</th>
        <th>Calibre</th>
        <th>Fecha de cosecha</th>
        <th>Fecha de control de calidad</th>
        <th>Embalaje</th>
        <th>Tipo de pallet</th>
        <th>Etiqueta</th>
        <th>Tipo de bolsa</th>
        <th>Tamaño de caja</th>
        <th>Peso neto</th>
        <th>Frigorífico</th>
        <th>Cajas por pallet</th>
        <th>Marcas</th>
        <th>Contenedor No</th>
        <th>Grado</th>
        <th>Temptale</th>
        <th>País de origen</th>
        <th>Certificate 1</th>
        <th>Certificate 2</th>
        <th>Certificate 3</th>
        <th>Semana de carga</th>
        <th>BL No</th>
        <th>Booking No</th>
        <th>Container No</th>
        <th>Exporter</th>
        <th>Exporter email</th>
        <th>Importer</th>
        <th>Importer email</th>
        <th>Consignee</th>
        <th>Consignee email</th>
        <th>Notify</th>
        <th>Notify email</th>
        <th>Instrucciones</th>
        <th>Peso bruto</th>
        <th>CBM</th>
        <th>Temperatura</th>
        <th>Place of receipt</th>
        <th>Port of loading</th>
        <th>Port of discharge</th>
        <th>airport of loading</th>
        <th>airport of discharge</th>
        <th>Week</th>
        <th>Place of delivery</th>
        <th>Vessel name/Flight Number</th>
        <th>Voyage</th>
        <th>Type of movement</th>
        <th>ETD</th>
        <th>ETA</th>
        <th>Ocean freight (USD)</th>
        <th>Inland (USD)</th>
        <th>Local charges (USD)</th>
        <th>Customs (USD)</th>
        <th>Surcharge (USD)</th>
        <th>Others (USD)</th>
        <th>Freight payable at</th>
        <th>Shipped on board</th>
        <th>Place of issue</th>
        <th>Date of issue</th>
        <th>Excess value declaration</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-product>
      <tr>
        <td>{{ product.pallet.importOrderDetail.importOrder.sequence  }}</td>
        <td>{{ product.pallet.importOrderDetailId  }}</td>
        <td>{{ product.pallet.id }}</td>
        <td>{{ product.pallet.palletNumber }}</td>
        <td>{{ getTransportName(product.pallet.importOrderDetail.importOrder.transportType) }}</td>
        <td>{{ product.pallet.excelImportSpecies }}</td>
        <td>{{ product.pallet.excelImportVariety }}</td>
        <td>{{ product.pallet.producer }}</td>
        <td>{{ product.pallet.speciesSize }}</td>
        <td>{{ product.pallet.excelImportHarvestDate }}</td>
        <td>{{ product.pallet.excelImportQualityControlDate }}</td>
        <td>{{ product.pallet.excelImportPackaging }}</td>
        <td>{{ product.pallet.excelImportPalletType }}</td>
        <td>{{ product.pallet.excelImportTag }}</td>
        <td>{{ product.pallet.excelImportBagType }}</td>
        <td>{{ product.pallet.excelImportBoxSize }}</td>
        <td>{{ product.pallet.excelImportNetWeight }}</td>
        <td>{{ product.pallet.excelImportFridgeStorage }}</td>
        <td>{{ product.pallet.excelImportBoxesPerPallet }}</td>
        <td>{{ product.pallet.excelImportMark }}</td>
        <td>{{ product.pallet.excelImportContainerNumber }}</td>
        <td>{{ product.pallet.excelImportGrade }}</td>
        <td>{{ product.pallet.excelImportTemptale }}</td>
        <td>{{ product.pallet.excelImportOriginCountry }}</td>
        <td>{{ product.pallet.excelImportCertificate1 }}</td>
        <td>{{ product.pallet.excelImportCertificate2 }}</td>
        <td>{{ product.pallet.excelImportCertificate3 }}</td>
        <td>{{ product.pallet.excelImportLoadWeek }}</td>
        <td>{{ product.delivery?.bl }}</td>
        <td>{{ product.delivery?.booking }}</td>
        <td>{{ product.delivery?.containerNumber }}</td>
        <td>{{ product.pallet.importOrderDetail?.exportOffer?.exporter?.companyName }}</td>
        <td>{{ product.pallet.importOrderDetail?.exportOffer?.exporter?.contactEmail }}</td>
        <td>{{ product.pallet.importOrderDetail?.importOrder?.importer?.companyName }}</td>
        <td>{{ product.pallet.importOrderDetail?.importOrder?.importer?.contactEmail }}</td>
        <td>{{ product.delivery?.consigneeCompanyName }}</td>
        <td>{{ product.delivery?.consigneeContactEmail }}</td>
        <td>{{ product.delivery?.notifierCompanyName }}</td>
        <td>{{ product.delivery?.notifierContactEmail }}</td>
        <td>{{ product.delivery?.deliveryInstructions }}</td>
        <td>{{ product.delivery?.grossWeight }}</td>
        <td>{{ product.delivery?.containerCbm }}</td>
        <td>{{ product.delivery?.containerTemperature }}</td>
        <td>{{ product.delivery?.placeReceipt }}</td>
        <td>{{ product.delivery?.departurePort?.name }}</td>
        <td>{{ product.delivery?.destinationPort?.name }}</td>
        <td>{{ product.delivery?.departureAirport?.name }}</td>
        <td>{{ product.delivery?.destinationAirport?.name }}</td>
        <td>{{ product.delivery?.week }}</td>
        <td>{{ product.delivery?.placeDelivery }}</td>
        <td>{{ product.delivery?.ship }}</td>
        <td>{{ product.delivery?.voyageNumber }}</td>
        <td>{{ product.delivery?.typeMovement }}</td>
        <td>{{ product.delivery?.etd | date: 'dd-MM-yyyy' }}</td>
        <td>{{ product.delivery?.eta | date: 'dd-MM-yyyy' }}</td>
        <td>{{ product.delivery?.oceanFreight }}</td>
        <td>{{ product.delivery?.inland }}</td>
        <td>{{ product.delivery?.localCharges }}</td>
        <td>{{ product.delivery?.customs }}</td>
        <td>{{ product.delivery?.surcharge }}</td>
        <td>{{ product.delivery?.otherCosts }}</td>
        <td>{{ product.delivery?.freightPayableAt }}</td>
        <td>{{ product.delivery?.shippedOnBoard }}</td>
        <td>{{ product.delivery?.placeIssue }}</td>
        <td>{{ product.delivery?.dateIssue | date: 'dd-MM-yyyy' }}</td>
        <td>{{ product.delivery?.excessValueDeclaration }}</td>
      </tr>
    </ng-template>
| </p-table>
</app-admin-dashboard>
