import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { FridgeStorage, NewFridgeStorage } from "src/app/domain/models/FridgeStorage";

@Injectable({providedIn: 'root'})
export abstract class FridgeStorageGateway {
  abstract getAll(): Observable<FridgeStorage[]>
  abstract getAllAvailable(): Observable<Array<FridgeStorage>>
  abstract getById(id: number): Observable<FridgeStorage>
  abstract create(fridgestorage: NewFridgeStorage): Observable<FridgeStorage>
  abstract update(fridgestorage: FridgeStorage): Observable<FridgeStorage>
  abstract delete(id: number): Observable<FridgeStorage>
  abstract getAllByStorageType(storageType: any): Observable<FridgeStorage[]>
}
