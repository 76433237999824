import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ProgramRequest, ProgramRequestInformation } from 'src/app/domain/models/ProgramRequest';
import { BasicUser } from 'src/app/domain/models/User';
import { Routes } from 'src/app/infrastructure/routes';
import { ProgramRequestUtil } from 'src/app/ui/util/programRequestUtil';

@Component({
  selector: 'app-import-program-request-list',
  templateUrl: './import-program-request-list.component.html',
  styleUrls: ['./import-program-request-list.component.css']
})
export class ImportProgramRequestListComponent {

  @Input()
  programRequestInfo: ProgramRequestInformation[] = []
  selectedProgramRequest: ProgramRequestInformation | undefined

  @Output()
  visibleInviteEvent = new EventEmitter;

  profile: BasicUser | undefined

  programRequestStatusItem: any[] = []
  loading: boolean = false

  visibleProgramRequestDetailDialog: boolean = false
  visibleInvite: boolean = false;

  constructor(
    private _router: Router
  )
  {
    this.programRequestStatusItem = [
      { label: this.profile?.lang === "es" ? "Activa" : "Active", value: 0 },
      { label: this.profile?.lang === "es" ? "Solicitado" : "Required", value: 1 },
      { label: this.profile?.lang === "es" ? "Rechazado" : "Rejected", value: 2 }
    ]
  }

  get allCountriesText(): string {
    if(this.profile) {
      switch(this.profile.lang) {
        case 'en':
          return 'All countries'
        case 'es':
          return 'Todos los países'
        default:
          return 'All countries'
      }
    }
    return 'All countries'
  }

  getTotalPalletsImportRequest(programRequest: ProgramRequest): number {
    return ProgramRequestUtil.getTotalPallets(programRequest)
  }

  getTotalContainersImportRequest(programRequest: ProgramRequest): number {
    return ProgramRequestUtil.getTotalContainers(programRequest)
  }

  selectProgramRequestDetail(programRequestInfo: ProgramRequestInformation)
  {
    this.selectedProgramRequest = programRequestInfo; 
    this.visibleProgramRequestDetailDialog = true
  }

  goToEditProgramRequest(programRequestId: string)
  {
    this._router.navigate(['/' + Routes.userProgramRequestEdit, programRequestId])
  }

  getStatusValue(status: number): string {
    const statusObj = this.programRequestStatusItem.find(item => item.value === status);
    return statusObj ? statusObj.label : '';
  }

  goToProgramRequestCreate()
  {
    this._router.navigate(['/' + Routes.userProgramRequestCreate])
  }

  inviteEvent()
  {
    this.visibleInviteEvent.emit();
  } 
}
