import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Port } from "src/app/domain/models/Port";
import { Routes } from "src/app/infrastructure/routes";
import { PortGateway } from "../gateway/PortGateway";

@Injectable({providedIn: 'root'})
export class PortService extends PortGateway {

  constructor(private _http: HttpClient) {
    super()
  }

  getAll(): Observable<Port[]> {
    return this._http.get<Port[]>(Routes.portApi + '/GetAll')
  }

  getOriginPorts(): Observable<Port[]> {
    return this._http.get<Port[]>(Routes.portApi + '/GetOriginPorts')
  }

  getDestinationPortsByCountry(countryId: number): Observable<Port[]> {
    return this._http.get<Port[]>(Routes.portApi + '/GetDestinationPortByCountry', {params: {countryId}})
  }
}
