import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ImportOrderConfirmPurchaseRequest } from "src/app/domain/models/Delivery";
import { AddImportoOrderToProgramWeek, DirectSaleProgram } from "src/app/domain/models/DirectSaleProgram";
import { EditBasicOffer, ForwarderOrderListItem } from "src/app/domain/models/ForwarderOrder";
import { ClosedSaleWeek, ClosedSaleWeekRequest, ImportOrderContainerItem, ImportOrder, ImportOrderDetail, ImportOrderInformation, ImportOrderListItem, DirectSaleProgramExporter, ChatMessage, ImportOrderReviewMessage, ImportOrderReviewMessageCreate } from "src/app/domain/models/ImportOrder";
import { LoadOrderListItem } from "src/app/domain/models/LoadOrder";

@Injectable({providedIn: 'root'})
export abstract class ImportOrderGateway {
  abstract getAllByImporter(): Observable<ImportOrderListItem[]>
  abstract getAllByExporter(): Observable<ImportOrderListItem[]>
  abstract getAllForShoppingCart(): Observable<ImportOrderContainerItem[]>
  abstract getAllClosedSale(directSaleProgramId: any, starWeek: number, endWeek: number, year: number): Observable<DirectSaleProgramExporter>
  abstract removeDetail(importOrderId: any, importDetailId: any): Observable<any>
  abstract confirmOrder(importOrderId: any, qualityControlCompanyId: any): Observable<any>
  abstract create(exportOfferRequest: any): Observable<ImportOrderInformation[]>
  abstract moveDetail(importOrderId: any, importDetailId: any): Observable<any>
  abstract getById(guid: any): Observable<ImportOrderInformation>
  abstract getAllByStatus(status: string): Observable<ImportOrderInformation[]>
  abstract confirmPurchase(confirmPurchaseRequest: ImportOrderConfirmPurchaseRequest): Observable<any>
  abstract getAllClosedSaleByImporter(starWeek: number, endWeek: number, year: number, directSaleProgramId: number): Observable<ClosedSaleWeek[]>
  abstract getAllClosedSaleByExporter(): Observable<ImportOrderInformation[]>
  abstract updateClosedSaleWeek(request: ClosedSaleWeekRequest): Observable<any>
  abstract getAllForFrozenFruitShoppingCart(): Observable<ImportOrderContainerItem[]>
  abstract getAllMyLoads(): Observable<LoadOrderListItem[]>
  abstract addImportoOrderToProgramWeek(request :AddImportoOrderToProgramWeek): Observable<ImportOrder[]>
  abstract assingForwarder(orderId: string, companyId: number): Observable<any>
  abstract assingCustomAgent(orderId: string, companyId: number): Observable<any>
  abstract getReviewMessages(orderId: string): Observable<ImportOrderReviewMessage[]>
  abstract addReviewMessages(request: ImportOrderReviewMessageCreate): Observable<any>
  // abstract getMbOrders(): Observable<ImportOrderListItem[]>
  abstract getForwarderOrderById(guid: any):  Observable<ImportOrderInformation> 
  abstract getMbOrders(): Observable<ForwarderOrderListItem[]>
  // abstract getForwarderOrderById(importOrderId: any): Observable<ImportOrderListItem>
  abstract updateImportAndDetailMbl(basicForwarderOffer: EditBasicOffer): Observable<any>
  abstract getAllByImporterMbl(): Observable<ImportOrderListItem[]>
  abstract getAllByExporterMbl(): Observable<ImportOrderListItem[]>
  abstract getAllByPendingApprovedAdmin(): Observable<ImportOrderInformation[]>
  abstract cancelPurchase(importOrderId: string): Observable<any> 
}
