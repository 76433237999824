<app-dashboard-layout>
  <app-content-header>
    <app-home-header></app-home-header>
  </app-content-header>
  <app-content-body>
    <div class="flex">
      {{'SignupPage.StepText' | translate }} 2 {{'SignupPage.AllStepText' | translate }} 3
        <br/>
        {{'SignupPage.CompanyInformationText' | translate }}
    </div>
    <div class="flex flex-wrap flex-column justify-content-center align-items-center">
      <h3>{{'SignupPage.JoinUsTitle' | translate }}</h3>
      <form [formGroup]="formGroup">
        <div style="margin-bottom: 30px;">
          {{'SignupPage.SingupPageCompany.CompanyPleaseInformationText' | translate }}
        </div>
        <div class="p-fluid formgrid grid" style="width: 800px;">
          <div class="field col-12">
            <label for="companyName">{{'SignupPage.SingupPageCompany.RutFieldLabel' | translate }} *</label>
            <input id="companyName" formControlName="companyName" type="text" required pInputText/>
            <small *ngIf="submit && formGroup.controls['companyName'].invalid" class="p-error">{{getErrorMessage('companyName')}}</small>
          </div>
          <div class="field col-6">
            <label for="vat">{{'SignupPage.SingupPageCompany.CompanyNameFieldLabel' | translate }} *</label>
            <p-inputNumber id="vat" formControlName="vat" [allowEmpty]="true"/>
            <small *ngIf="submit && formGroup.controls['vat'].invalid" class="p-error">{{getErrorMessage('vat')}}</small>
          </div>
          <div class="field col-6">
            <label for="sag">{{'SignupPage.SingupPageCompany.SagCodeFieldLabel' | translate }}</label>
            <p-inputNumber id="sag" formControlName="sag"/>
          </div>
          <div class="field col-12">
            <label for="legalRepresentative">{{'SignupPage.SingupPageCompany.CompanyLegalRepresentantNameFieldLabel' | translate }} *</label>
            <input id="legalRepresentative" formControlName="legalRepresentative" type="text" required pInputText/>
            <small *ngIf="submit && formGroup.controls['legalRepresentative'].invalid" class="p-error">{{getErrorMessage('legalRepresentative')}}</small>
          </div>
          <div class="field col-12">
            <label for="legalRepresentativeId">{{'SignupPage.SingupPageCompany.CompanyLegalRepresentantNuipFieldLabel' | translate }} *</label>
            <p-inputNumber id="legalRepresentativeId" formControlName="legalRepresentativeId"/>
            <small *ngIf="submit && formGroup.controls['legalRepresentativeId'].invalid" class="p-error">{{getErrorMessage('legalRepresentativeId')}}</small>
          </div>
          <div class="field col-12">
            <label>{{'SignupPage.SingupPageCompany.CompanyLegalDocumentFieldLabel' | translate }}</label>
            <p-fileUpload
              (onSelect)="onSelect($event)"
              [showUploadButton]="false"
              [multiple]="false"
              accept=".pdf"
              [maxFileSize]="1000000"
              chooseLabel="Seleccionar"
              cancelLabel="Cancelar"/>
          </div>
          <div class="field col-12">
            <label>{{'SignupPage.SingupPageCompany.SelectProductsText' | translate }}</label>
            <p-tree [value]="products" selectionMode="checkbox" [(selection)]="selectedProducts"></p-tree>
          </div>
          <div class="field col-12">&nbsp;</div>
          <div class="field col-6">
            <button pButton pRipple class="p-button-secondary justify-content-center w-full">Registrarme</button>
          </div>
          <div class="field col-6">
            <button pButton pRipple (click)="goToSignUpAddInfo()" class="justify-content-center w-full">Siguiente</button>
          </div>
        </div>
      </form>
    </div>
  </app-content-body>
</app-dashboard-layout>
