import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { CountryGateway } from 'src/app/application/gateway/CountryGateway';
import { FridgeStorageGateway } from 'src/app/application/gateway/FridgeStorageGateway';
import { FrozenFruitExportOfferGateway } from 'src/app/application/gateway/FrozenFruitExportOfferGateway';
import { SpeciesGateway } from 'src/app/application/gateway/SpeciesGateway';
import { VarietyGateway } from 'src/app/application/gateway/VarietyGateway';
import { Country } from 'src/app/domain/models/Country';
import { FridgeStorage } from 'src/app/domain/models/FridgeStorage';
import { FrozenFruitExportOffer, FrozenFruitExportOfferCreateRequest, FrozenFruitExportOfferUploadDocumentsRequest, FrozenFruitExportOfferUploadPicturesRequest } from 'src/app/domain/models/FrozenFruitExportOffer';
import { Species } from 'src/app/domain/models/Species';
import { BasicUser } from 'src/app/domain/models/User';
import { Variety } from 'src/app/domain/models/Variety';
import { Routes } from 'src/app/infrastructure/routes';
import { ErrorMessageUtil } from 'src/app/ui/util/error-message-util';
import { ExportOfferUtils } from 'src/app/ui/util/export-offer-utils';
import { Lang } from 'src/app/ui/util/langs';
import { SpeciesUtils } from 'src/app/ui/util/species-utils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-frozen-fruit-export-offer-create',
  templateUrl: './frozen-fruit-export-offer-create.component.html',
  styleUrls: ['./frozen-fruit-export-offer-create.component.css'],
  providers: [MessageService]
})
export class FrozenFruitExportOfferCreateComponent implements OnInit {

  currentStep: number = 1
  step1: number = 1
  step2: number = 2
  step3: number = 3
  step4: number = 4
  success: number = 5

  submit: boolean = false
  submit2: boolean = false
  loading: boolean = false

  profile: BasicUser | undefined

  species: Species[] = []
  selectedSpecies: Species | undefined

  varieties: Variety[] = []
  selectedVariety: Variety | undefined

  incotermsOptions: any[] = []
  productStackingTypeOptions: any[] = []
  certifiedPalletOptions: any[] = []
  marksOptions: any[] = []
  qualityControlOptions: any[] = []

  fridgeStorages: FridgeStorage[] = []
  selectedFridgeStorage: FridgeStorage | undefined

  countries: Country[] = []
  selectedMarketCountry1: Country | undefined
  selectedMarketCountry2: Country | undefined
  selectedMarketCountry3: Country | undefined

  created: FrozenFruitExportOffer | undefined

  uploadedImages: any[] = []
  uploadedDocuments: any[] = [null, null, null, null]

  pesticideAnalysisDocument: any
  heavyMetalsAnalysisDocument: any
  microbiologicalAnalysisDocument: any
  waterAnalysisDocument: any

  requiredFormGroup: FormGroup = this._formBuilder.group({
    speciesId: [null, Validators.required],
    varietyId: [null, Validators.required],
    category: [null, Validators.required],
    format: [null, Validators.required],
    size: [null, Validators.required],
    price: [null, Validators.required],
    incoterms: [null, Validators.required],
    productStackingType: [null, Validators.required],
    containersQuantity: [null],
    palletQuantity: [null],
    boxesPerPallet: [null],
    boxesQuantity: [null],
    fridgeStorageId: [null, Validators.required],
  },{
    validators: [this.requiredBoxesQuantityForm(), this.requiredBoxesPerPalletForm()]
  })

  aditionalInfoFormGroup: FormGroup = this._formBuilder.group({
    harvestDate: [null],
    processDate: [null],
    expirationDate: [null],
    grossKilos: [null],
    netKilos: [null],
    mainPackaging: [null],
    boxType: [null],
    boxSize: [null],
    boxColor: [null],
    bagType: [null],
    bagColor: [null],
    basePallet: [null],
    isCertifiedPallet: [false],
    isQualityControl: [false],
    isMarks: [false],
    qualityControlDate: [null],
    marketCountry1Id: [null],
    marketCountry2Id: [null],
    marketCountry3Id: [null],
  })

  constructor(private _router: Router,
    private _messageService: MessageService,
    private _formBuilder: FormBuilder,
    public translate: TranslateService,
    private _authService: AuthGateway,
    private _varietyService: VarietyGateway,
    private _speciesService: SpeciesGateway,
    private _fridgeStorageService: FridgeStorageGateway,
    private _frozenFruitExportOfferService: FrozenFruitExportOfferGateway,
    private _countryService: CountryGateway,) {

  }

  ngOnInit(): void {
    this.profile = this._authService.getUser()
    this.loadSpecies()
    this.loadFridgeStorages()
    this.loadCountries()
    this.incotermsOptions = ExportOfferUtils.getIncotermsOptions()
    if(this.profile) {
      this.productStackingTypeOptions = ExportOfferUtils.getProductStackingType(this.profile.lang)
      this.certifiedPalletOptions = Lang.getBooleanYesNoOptions(this.profile.lang)
      this.marksOptions = Lang.getBooleanYesNoOptions(this.profile.lang)
      this.qualityControlOptions = Lang.getBooleanYesNoOptions(this.profile.lang)
    }

  }

  get apiUrl(): string {
    return environment.apiUrl
  }

  createAndGoToStep2() {
    this.submit = true
    if(this.requiredFormGroup.valid && this.profile) {

      let palletQuantity = 0
      let boxesPerPallet = 0
      let boxesQuantity = 0

      if(this.requiredFormGroup.controls['productStackingType'].value == 1) {
        boxesQuantity = this.requiredFormGroup.controls['boxesQuantity'].value
      }

      if(this.requiredFormGroup.controls['productStackingType'].value == 2) {
        palletQuantity = this.requiredFormGroup.controls['palletQuantity'].value
        boxesPerPallet = this.requiredFormGroup.controls['boxesPerPallet'].value
      }

      const request: FrozenFruitExportOfferCreateRequest = {
        varietyId: this.requiredFormGroup.controls['varietyId'].value,
        category: this.requiredFormGroup.controls['category'].value,
        format: this.requiredFormGroup.controls['format'].value,
        size: this.requiredFormGroup.controls['size'].value,
        price: this.requiredFormGroup.controls['price'].value,
        incoterms: this.requiredFormGroup.controls['incoterms'].value,
        productStackingType: this.requiredFormGroup.controls['productStackingType'].value,
        containersQuantity: this.requiredFormGroup.controls['containersQuantity'].value,
        palletsQuantity: 0,
        boxesQuantity: boxesQuantity,
        boxesPerPallet: boxesPerPallet,
        fridgeStorageId: this.requiredFormGroup.controls['fridgeStorageId'].value,
        isCertifiedPallet: false,
        isQualityControl: false,
        isMarks: false
      }

      this.loading = true
      this._frozenFruitExportOfferService.create(request).subscribe({
        next: (v) => {
          this.created = v
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: this.translate.instant('MessageService.ExportOffer.CreateSuccess') })
          this.submit = false
          this.loading = false
          this.currentStep = this.step2
        },
        error: (e) => {
          console.log(e)
          this.submit = false
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOffer.CreateError') })
        },
        complete: () => console.info('Frozen fruit export offer created successfully')
      })
    }
    else {
      this.loading = false
    }
  }

  updateAndGoToStep3() {
    this.submit2 = true
    if(this.aditionalInfoFormGroup.valid && this.created) {
      this.created.harvestDate = this.aditionalInfoFormGroup.controls['harvestDate'].value
      this.created.processDate = this.aditionalInfoFormGroup.controls['processDate'].value
      this.created.expirationDate = this.aditionalInfoFormGroup.controls['expirationDate'].value
      this.created.grossKilos = this.aditionalInfoFormGroup.controls['grossKilos'].value
      this.created.netKilos = this.aditionalInfoFormGroup.controls['netKilos'].value
      this.created.mainPackaging = this.aditionalInfoFormGroup.controls['mainPackaging'].value
      this.created.boxType = this.aditionalInfoFormGroup.controls['boxType'].value
      this.created.boxSize =  this.aditionalInfoFormGroup.controls['boxSize'].value
      this.created.boxColor = this.aditionalInfoFormGroup.controls['boxColor'].value
      this.created.bagType = this.aditionalInfoFormGroup.controls['bagType'].value
      this.created.bagColor = this.aditionalInfoFormGroup.controls['bagColor'].value
      this.created.basePallet = this.aditionalInfoFormGroup.controls['basePallet'].value
      this.created.isCertifiedPallet = this.aditionalInfoFormGroup.controls['isCertifiedPallet'].value
      this.created.isQualityControl = this.aditionalInfoFormGroup.controls['isQualityControl'].value
      this.created.isMarks = this.aditionalInfoFormGroup.controls['isMarks'].value
      this.created.qualityControlDate = this.aditionalInfoFormGroup.controls['qualityControlDate'].value
      this.created.marketCountry1Id = this.aditionalInfoFormGroup.controls['marketCountry1Id'].value
      this.created.marketCountry2Id = this.aditionalInfoFormGroup.controls['marketCountry2Id'].value
      this.created.marketCountry3Id = this.aditionalInfoFormGroup.controls['marketCountry3Id'].value

      this.created.fridgeStorage = undefined
      this.created.variety = undefined

      this.loading = true
      this._frozenFruitExportOfferService.update(this.created).subscribe({
        next: (v) => {
          this.created = v
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: this.translate.instant('MessageService.ExportOffer.EditSuccess') })
          this.submit2 = false
          this.loading = false
          this.currentStep = this.step3
        },
        error: (e) => {
          console.log(e)
          this.submit2= false
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOffer.EditError') })
        },
        complete: () => console.info('Frozen fruit export offer updated successfully')
      })
    }
  }

  onSelectImage(event: any, uploader: any) {
    this.uploadedImages = uploader.files
  }

  onRemoveImage(event: any, uploader: any) {
    this.uploadedImages = uploader.files
  }

  onClearImageUploader(event: any, uploader: any) {
    this.uploadedImages = uploader.files
  }

  uploadImagesAndGoToStep4() {
    if(this.created) {
      const request: FrozenFruitExportOfferUploadPicturesRequest = {
        frozenFruitExportOfferId: this.created.id,
        picture1: this.uploadedImages[0] ? this.uploadedImages[0] : null,
        picture2: this.uploadedImages[1] ? this.uploadedImages[1] : null,
        picture3: this.uploadedImages[2] ? this.uploadedImages[2] : null
      }

      this.loading = true
      this._frozenFruitExportOfferService.uploadProductPictures(request).subscribe({
        next: (v) => {
          this.loading = false
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: this.translate.instant('MessageService.ExportOffer.EditSuccess') })
          this.currentStep = this.step4
        },
        error: (e) => {
          this.loading = false
          console.log(e)
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOffer.EditError') })
        },
        complete: () => console.info('Pictures uploaded successfully.')
      })
    }
  }

  onSelectPesticideAnalysisDocument(event: any, uploader: any) {
    this.pesticideAnalysisDocument = uploader.files[0]
    uploader.clear()
  }

  onRemovePesticideAnalysisDocument(event: any) {
    this.pesticideAnalysisDocument = null
  }

  onSelectHeavyMetalsAnalysisDocument(event: any, uploader: any) {
    this.heavyMetalsAnalysisDocument = uploader.files[0]
    uploader.clear()
  }

  onRemoveHeavyMetalsAnalysisDocument(event: any) {
    this.heavyMetalsAnalysisDocument = null
  }

  onSelectMicrobiologicalAnalysisDocument(event: any, uploader: any) {
    this.microbiologicalAnalysisDocument = uploader.files[0]
    uploader.clear()
  }

  onRemoveMicrobiologicalAnalysisDocument(event: any) {
    this.microbiologicalAnalysisDocument = null
  }

  onSelectWaterAnalysisDocument(event: any, uploader: any) {
    this.waterAnalysisDocument = uploader.files[0]
  }

  onRemoveWaterAnalysisDocument(event: any) {
    this.waterAnalysisDocument = null
  }

  getFileSize(file?: any | null): number {
    return file ? Math.trunc(file.size / 1000) : 0
  }

  getPesticideAnalysisDocumentName() {
    return this.pesticideAnalysisDocument ? this.pesticideAnalysisDocument.name : null
  }

  getHeavyMetalsAnalysisDocumentName() {
    return this.heavyMetalsAnalysisDocument ? this.heavyMetalsAnalysisDocument.name : null
  }

  getMicrobiologicalAnalysisDocumentName() {
    return this.microbiologicalAnalysisDocument ? this.microbiologicalAnalysisDocument.name : null
  }

  getWaterAnalysisDocumentName() {
    return this.waterAnalysisDocument ? this.waterAnalysisDocument.name : null
  }

  uploadDocumentsAndGoToSuccess() {
    if(this.created) {
      const request: FrozenFruitExportOfferUploadDocumentsRequest = {
        frozenFruitExportOfferId: this.created.id,
        pesticideAnalysisDocument: this.pesticideAnalysisDocument,
        heavyMetalsAnalysisDocument: this.heavyMetalsAnalysisDocument,
        microbiologicalAnalysisDocument: this.microbiologicalAnalysisDocument,
        waterAnalysisDocument: this.waterAnalysisDocument
      }

      this.loading = true
      this._frozenFruitExportOfferService.uploadDocuments(request).subscribe({
        next: (v) => {
          this.loading = false
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: this.translate.instant('MessageService.ExportOffer.EditSuccess') })
          this.currentStep = this.success
        },
        error: (e) => {
          this.loading = false
          console.log(e)
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOffer.EditError') })
        },
        complete: () => console.info('Pictures uploaded successfully.')
      })
    }
  }

  goToFrozenFruitExportOffersPage() {
    this._router.navigate(['/' + Routes.userFrozenFruitExportOffersActivePage])
  }

  getErrorMessage(formGroup: FormGroup, fieldName: string): string {
    return ErrorMessageUtil.getErrorMessage(formGroup, this.translate, fieldName)
  }

  getSpeciesName(species: Species): string {
    if(this.profile)
      return SpeciesUtils.getSpeciesName(this.profile.lang, species)
    return ''
  }

  loadSpeciesParams() {
    this.selectedSpecies = this.species.find(x => x.id === this.requiredFormGroup.controls['speciesId'].value)
    this.loadVarieties()
  }

  onChangeVariety() {
    this.selectedVariety = this.varieties.find(x => x.id === this.requiredFormGroup.controls['varietyId'].value)
  }

  loadSpecies() {
    this.loading = true
    this._speciesService.getAllByProductType(2).subscribe({
      next: (v) => {
        this.species = v
        this.loading = false
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Species.ManyRetrieveError') })
      },
      complete: () => console.info('Species query successfully')
    })
  }

  loadVarieties() {
    this.varieties = []
    this.loading = true
    this._varietyService.getAllBySpecies(this.requiredFormGroup.controls['speciesId'].value).subscribe({
      next: (v) => {
        this.varieties = v
        this.loading = false
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Variety.ManyRetrieveError') })
      },
      complete: () => console.info('Variety query successfully')
    })
  }

  loadFridgeStorages() {
    this.fridgeStorages = []
    this._fridgeStorageService.getAllByStorageType(2).subscribe({
      next: (v) => {
        this.fridgeStorages = v
        this.loading = false
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.FridgeStorage.ManyRetrieveError') })
      },
      complete: () => console.info('Frigde Storage query successfully')
    })
  }

  loadCountries() {
    this.countries = []
    this._countryService.getAll().subscribe({
      next: (v) => {
        this.countries = v
        this.loading = false
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Country.ManyRetrieveError') })
      },
      complete: () => console.info('Country query successfully')
    })
  }

  onChangeFridgeStorage() {
    this.selectedFridgeStorage = this.fridgeStorages.find(x => x.id === this.requiredFormGroup.controls['fridgeStorageId'].value)
  }

  onChangeMarketCountry1() {
    this.selectedMarketCountry1 = this.countries.find(x => x.id === this.aditionalInfoFormGroup.controls['marketCountry1Id'].value)
  }

  onChangeMarketCountry2() {
    this.selectedMarketCountry2 = this.countries.find(x => x.id === this.aditionalInfoFormGroup.controls['marketCountry2Id'].value)
  }

  onChangeMarketCountry3() {
    this.selectedMarketCountry3 = this.countries.find(x => x.id === this.aditionalInfoFormGroup.controls['marketCountry3Id'].value)
  }

  get isRequiredBoxesQuantity(): boolean {
    return this.requiredFormGroup.getError('requiredBoxesQuantity')
  }

  requiredBoxesQuantityForm(): ValidatorFn {
    return (control:AbstractControl): ValidationErrors | null => {
      const stackingType = control.get('productStackingType')?.value
      const boxesQuantity = control.get('boxesQuantity')?.value
      return stackingType && stackingType === 1 && !boxesQuantity ? {requiredBoxesQuantity : true} : null
    }
  }

  getBoxesQuantityRequiredErrorMessage(): string {
    return 'This field is required.'
  }

  get isRequiredPalletQuantity(): boolean {
    return this.requiredFormGroup.getError('requiredPalletQuantity')
  }

  requiredPalletQuantityForm(): ValidatorFn {
    return (control:AbstractControl): ValidationErrors | null => {
      const stackingType = control.get('productStackingType')?.value
      const palletQuantity = control.get('palletQuantity')?.value
      return stackingType && stackingType === 2 && !palletQuantity ? {requiredPalletQuantity : true} : null
    }
  }

  getPalletQuantityRequiredErrorMessage(): string {
    return 'This field is required.'
  }

  get isRequiredBoxesPerPallet(): boolean {
    return this.requiredFormGroup.getError('requiredBoxesPerPallet')
  }

  requiredBoxesPerPalletForm(): ValidatorFn {
    return (control:AbstractControl): ValidationErrors | null => {
      const stackingType = control.get('productStackingType')?.value
      const boxesPerPallet = control.get('boxesPerPallet')?.value
      return stackingType && stackingType === 2 && !boxesPerPallet ? {requiredBoxesPerPallet : true} : null
    }
  }

  getBoxesPerPalletRequiredErrorMessage(): string {
    return 'This field is required.'
  }

  requiredBoxesPerPallet(): ValidatorFn {
    return (control:AbstractControl) : ValidationErrors | null => {
      if(this.requiredFormGroup) {
        const stackingType = this.requiredFormGroup.controls['productStackingType'].value
        const boxesPerPallet = control.value
        return stackingType && stackingType === 2 && !boxesPerPallet ? {requiredBoxesPerPallet : true} : null
      }
      return null
    }
  }

  getBoxesPerPalletErrorMessage(): string {
    if (this.requiredFormGroup.controls['boxesPerPallet'].hasError('requiredBoxesPerPallet')) {
      return 'This field is required.'
    }
    return ''
  }
}
