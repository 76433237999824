import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'fruzty-frontend';


  constructor(private primeConfig: PrimeNGConfig,
    public translate: TranslateService){
      translate.addLangs(['es', 'en']);
      translate.setDefaultLang('es');

      this.primeConfig.setTranslation({
        startsWith: 'Empieza con',
        contains: 'Contiene',
        notContains: 'No contiene',
        endsWith: 'Acaba en',
        equals: 'Igual',
        notEquals: 'No igual',
        noFilter: 'Sin filtro',
        lt: 'Menor que',
        apply: 'Aplicar',
        clear: 'Limpiar',
      });
  }

  ngOnInit(): void {
      this.primeConfig.ripple = true;
  }
}
