<div class="grid" style="margin-top: 30px; margin-left: 30px;">
  <div class="col-12">
    <h2>{{'FrozenFruitExportOfferDetailComponent.SpeciesDescriptionTitle' | translate}}</h2>
  </div>
  <div class="col-6">
    <div class="grid" style="border-right: 3px solid grey;">
      <div class="col-12 md:col-4 lg:col-4"><span style="font-weight: bolder">{{'FrozenFruitExportOfferDetailComponent.SpecieDescriptionDescriptionLabel' | translate}}:</span></div>
      <div class="col-12 md:col-8 lg:col-8">{{speciesDescription?.description ? speciesDescription?.description : getAvailableLabel()}}</div>
      <div class="col-12 md:col-4 lg:col-4"><span style="font-weight: bolder">{{'FrozenFruitExportOfferDetailComponent.TechnicalCharacteristicsLabel' | translate}}:</span></div>
      <div class="col-12 md:col-8 lg:col-8">{{speciesDescription?.technicalCharacteristics ? speciesDescription?.technicalCharacteristics : getAvailableLabel()}}</div>
      <div class="col-12 md:col-4 lg:col-4"><span style="font-weight: bolder">{{'FrozenFruitExportOfferDetailComponent.ExportedVarietiesLabel' | translate}}:</span></div>
      <div class="col-12 md:col-8 lg:col-8">{{offer?.variety?.species?.exportVarieties}}</div>
    </div>
  </div>
  <div class="col-6">
    <div class="grid" style="margin-left: 20px;">
      <div class="col-12 md:col-4 lg:col-4"><span style="font-weight: bolder">{{'FrozenFruitExportOfferDetailComponent.QualityControlCriteriaLabel' | translate}}:</span></div>
      <div class="col-12 md:col-8 lg:col-8">{{speciesDescription?.qualityControlCriteria ? speciesDescription?.qualityControlCriteria : getAvailableLabel()}}</div>
      <div class="col-12 md:col-4 lg:col-4"><span style="font-weight: bolder">{{'FrozenFruitExportOfferDetailComponent.HarvestZoneChileLabel' | translate}}:</span></div>
      <div class="col-12 md:col-8 lg:col-8">{{speciesDescription?.harvestAreaChile ? speciesDescription?.harvestAreaChile : getAvailableLabel()}}</div>
      <div class="col-12 md:col-4 lg:col-4"><span style="font-weight: bolder">{{'FrozenFruitExportOfferDetailComponent.HarvestDateChileLabel' | translate}}:</span></div>
      <div class="col-12 md:col-8 lg:col-8">{{offer?.variety?.species?.harvestPeriod}}</div>
    </div>
  </div>
</div>
<div class="grid" style="margin-top: 40px; margin-left: 30px;">
  <div class="col-12">
    <h2>{{'FrozenFruitExportOfferDetailComponent.ProductDescriptionTitle' | translate}}</h2>
  </div>
  <div class="col-12 md:col-4 lg:col-4 grid product-description-block1-small product-description-block1-large">
    <div class="col-6">
      <span style="font-weight: bolder;">{{'FrozenFruitExportOfferDetailComponent.SpeciesLabel' | translate}}:</span>
    </div>
    <div class="col-6">
      {{offer?.variety?.species?.nameEs}}
    </div>
    <div class="col-6">
      <span style="font-weight: bolder;">{{'FrozenFruitExportOfferDetailComponent.VarietyLabel' | translate}}:</span>
    </div>
    <div class="col-6">
      <div class="flex align-items-center">
        <img [src]="offer?.variety?.urlIcon" style="width: 18px" alt="Variety icon"/> {{offer?.variety?.name}}
      </div>
    </div>
    <div class="col-6">
      <span style="font-weight: bolder;">{{'FrozenFruitExportOfferDetailComponent.CategoryLabel' | translate}}:</span>
    </div>
    <div class="col-6">
      {{offer?.category ? offer?.category : getAvailableLabel()}}
    </div>
    <div class="col-6">
      <span style="font-weight: bolder;">{{'FrozenFruitExportOfferDetailComponent.OriginCountryLabel' | translate}}:</span>
    </div>
    <div class="col-6">
      {{offer?.fridgeStorage?.contactCity + ', ' + (offer?.fridgeStorage?.contactCountry | countryDescription) }}
    </div>
    <div class="col-6">
      <span style="font-weight: bolder;">{{'FrozenFruitExportOfferDetailComponent.HarvestDateLabel' | translate}}:</span>
    </div>
    <div class="col-6">
      {{offer?.harvestDate ? (offer?.harvestDate | date: 'dd-MM-yyyy') : getAvailableLabel()}}
    </div>
    <div class="col-6">
      <span style="font-weight: bolder;">{{'FrozenFruitExportOfferDetailComponent.ProcessDateLabel' | translate}}:</span>
    </div>
    <div class="col-6">
      {{offer?.processDate ? (offer?.processDate | date: 'dd-MM-yyyy') : getAvailableLabel()}}
    </div>
    <div class="col-6">
      <span style="font-weight: bolder;">{{'FrozenFruitExportOfferDetailComponent.ExpirationDateLabel' | translate}}:</span>
    </div>
    <div class="col-6">
      {{offer?.expirationDate ? (offer?.expirationDate | date: 'dd-MM-yyyy') : getAvailableLabel()}}
    </div>
    <div class="col-6">
      <span style="font-weight: bolder;">{{'FrozenFruitExportOfferDetailComponent.SpeciesSizeLabel' | translate}}:</span>
    </div>
    <div class="col-6">
      {{offer?.size ? offer?.size : getAvailableLabel()}}
    </div>
  </div>
  <div class="col-12 md:col-4 lg:col-4 grid product-description-block2-small product-description-block2-large">
    <div class="col-6">
      <span style="font-weight: bolder;">{{'FrozenFruitExportOfferDetailComponent.NetKilosLabel' | translate}}:</span>
    </div>
    <div class="col-6">
      {{offer?.netKilos ? offer?.netKilos + ' Kg.' : getAvailableLabel()}}
    </div>
    <div class="col-6">
      <span style="font-weight: bolder;">{{'FrozenFruitExportOfferDetailComponent.GrossKilosLabel' | translate}}:</span>
    </div>
    <div class="col-6">
      {{offer?.grossKilos ? offer?.grossKilos + ' Kg.' : getAvailableLabel()}}
    </div>
    <div class="col-6">
      <span style="font-weight: bolder;">{{'FrozenFruitExportOfferDetailComponent.FormatLabel' | translate}}:</span>
    </div>
    <div class="col-6">
      {{offer?.format ? offer?.format : getAvailableLabel()}}
    </div>
    <div class="col-6">
      <span style="font-weight: bolder;">{{'FrozenFruitExportOfferDetailComponent.MainPackagingLabel' | translate}}:</span>
    </div>
    <div class="col-6">
      {{offer?.mainPackaging ? offer?.mainPackaging : getAvailableLabel()}}
    </div>
    <div class="col-6">
      <span style="font-weight: bolder;">{{'FrozenFruitExportOfferDetailComponent.CaseColorLabel' | translate}}:</span>
    </div>
    <div class="col-6">
      {{offer?.boxColor ? offer?.boxColor : getAvailableLabel()}}
    </div>
    <div class="col-6">
      <span style="font-weight: bolder;">{{'FrozenFruitExportOfferDetailComponent.BoxTypeLabel' | translate}}:</span>
    </div>
    <div class="col-6">
      {{offer?.boxType ? offer?.boxType : getAvailableLabel()}}
    </div>
    <div class="col-6">
      <span style="font-weight: bolder;">{{'FrozenFruitExportOfferDetailComponent.CaseSizeLabel' | translate}}:</span>
    </div>
    <div class="col-6">
      {{offer?.boxSize ? offer?.boxSize : getAvailableLabel()}}
    </div>
    <div class="col-6">
      <span style="font-weight: bolder;">{{'FrozenFruitExportOfferDetailComponent.BasePalletsLabel' | translate}}:</span>
    </div>
    <div class="col-6">
      {{offer?.basePallet ? getBasePallets(offer?.basePallet) : getAvailableLabel()}}
    </div>
    <div class="col-6">
      <span style="font-weight: bolder;">{{'FrozenFruitExportOfferDetailComponent.MarksLabel' | translate}}:</span>
    </div>
    <div class="col-6">
      {{getBooleanLabel(offer?.isMarks)}}
    </div>
  </div>
  <div class="col-12 md:col-4 lg:col-4 grid product-description-block3-small product-description-block3-large">
    <div class="col-6">
      <span style="font-weight: bolder;">{{'FrozenFruitExportOfferDetailComponent.LoadDateLabel' | translate}}:</span>
    </div>
    <div class="col-6">
      {{offer?.loadDate ? (offer?.loadDate | date: 'dd-MM-yyyy') : getAvailableLabel()}}
    </div>
    <div class="col-6">
      <span style="font-weight: bolder;">{{'FrozenFruitExportOfferDetailComponent.QualityControlLabel' | translate}}:</span>
    </div>
    <div class="col-6">
      {{getBooleanLabel(offer?.isQualityControl)}}
    </div>
    <div class="col-6">
      <span style="font-weight: bolder;">{{'FrozenFruitExportOfferDetailComponent.QualityControlDateLabel' | translate}}:</span>
    </div>
    <div class="col-6">
      {{offer?.qualityControlDate ? (offer?.qualityControlDate | date: 'dd-MM-yyyy') : getAvailableLabel()}}
    </div>
    <div class="col-6">
      <span style="font-weight: bolder;">{{'FrozenFruitExportOfferDetailComponent.OriginCountryLabel' | translate}}:</span>
    </div>
    <div class="col-6">
      <div class="flex align-items-center">
        <img [src]="'../../../../assets/img/flags/' + offer?.fridgeStorage?.contactCountry?.code + '.png'" style="width: 18px" alt=""/> {{ offer?.fridgeStorage?.contactCountry | countryDescription }}
      </div>
    </div>
    <div class="col-6">
      <span style="font-weight: bolder;">{{'FrozenFruitExportOfferDetailComponent.MarketCountry1Label' | translate}}:</span>
    </div>
    <div class="col-6">
      <div class="flex align-items-center" *ngIf="offer?.marketCountry1">
        <img [src]="'../../../../assets/img/flags/' + offer?.marketCountry1?.code + '.png'" style="width: 18px" alt=""/> {{ offer?.marketCountry1 | countryDescription }}
      </div>
      <div *ngIf="!offer?.marketCountry1">
        {{'ExportOffer.ExportOfferDetail.NoAvailable' | translate}}
      </div>
    </div>
    <div class="col-6">
      <span style="font-weight: bolder;">{{'FrozenFruitExportOfferDetailComponent.MarketCountry2Label' | translate}}:</span>
    </div>
    <div class="col-6">
      <div class="flex align-items-center" *ngIf="offer?.marketCountry2">
        <img [src]="'../../../../assets/img/flags/' + offer?.marketCountry2?.code + '.png'" style="width: 18px" alt=""/> {{ offer?.marketCountry2 | countryDescription }}
      </div>
      <div *ngIf="!offer?.marketCountry2">
        {{'ExportOffer.ExportOfferDetail.NoAvailable' | translate}}
      </div>
    </div>
    <div class="col-6">
      <span style="font-weight: bolder;">{{'FrozenFruitExportOfferDetailComponent.MarketCountry3Label' | translate}}:</span>
    </div>
    <div class="col-6">
      <div class="flex align-items-center" *ngIf="offer?.marketCountry3">
        <img [src]="'../../../../assets/img/flags/' + offer?.marketCountry3?.code + '.png'" style="width: 18px" alt=""/> {{ offer?.marketCountry3 | countryDescription }}
      </div>
      <div *ngIf="!offer?.marketCountry3">
        {{'ExportOffer.ExportOfferDetail.NoAvailable' | translate}}
      </div>
    </div>
  </div>
</div>
