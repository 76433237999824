import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { AirportGateway } from 'src/app/application/gateway/AirportGateway';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { ConsigneeGateway } from 'src/app/application/gateway/ConsigneeGateway';
import { CountryGateway } from 'src/app/application/gateway/CountryGateway';
import { DirectSaleProgramGateway } from 'src/app/application/gateway/DirectSaleProgramGateway';
import { ExportOfferGateway } from 'src/app/application/gateway/ExportOfferGateway';
import { FridgeStorageGateway } from 'src/app/application/gateway/FridgeStorageGateway';
import { NotifierGateway } from 'src/app/application/gateway/NotifierGateway';
import { PackagingGateway } from 'src/app/application/gateway/PackagingGateway';
import { PortGateway } from 'src/app/application/gateway/PortGateway';
import { ProfileGateway } from 'src/app/application/gateway/ProfileGateway';
import { ProgramRequestGateway } from 'src/app/application/gateway/ProgramRequestGateway';
import { SpeciesGateway } from 'src/app/application/gateway/SpeciesGateway';
import { VarietyGateway } from 'src/app/application/gateway/VarietyGateway';
import { Airport } from 'src/app/domain/models/Airport';
import { Consignee } from 'src/app/domain/models/Consignee';
import { Country } from 'src/app/domain/models/Country';
import { DirectSaleProgram } from 'src/app/domain/models/DirectSaleProgram';
import { BasicExportOffer, DirectSaleCreateRequest, EditDirectSale, OfferPicture, UpdatePicturesAndCertificateTypesRequest, ValidateDirectSale } from 'src/app/domain/models/ExportOffer';
import { FridgeStorage, NewFridgeStorage } from 'src/app/domain/models/FridgeStorage';
import { Notifier } from 'src/app/domain/models/Notifier';
import { Packaging } from 'src/app/domain/models/Packaging';
import { Port } from 'src/app/domain/models/Port';
import { Importer } from 'src/app/domain/models/Profile';
import { ProgramRequestInformation } from 'src/app/domain/models/ProgramRequest';
import { Species } from 'src/app/domain/models/Species';
import { BasicUser } from 'src/app/domain/models/User';
import { Variety } from 'src/app/domain/models/Variety';
import { Routes } from 'src/app/infrastructure/routes';
import { ExportOfferCertificateTypeComponent } from 'src/app/ui/components/export-offer/export-offer-certificate-type/export-offer-certificate-type.component';
import { ExportOfferPictureUploaderComponent } from 'src/app/ui/components/export-offer/export-offer-picture-uploader/export-offer-picture-uploader.component';
import { ExportOfferUtils } from 'src/app/ui/util/export-offer-utils';
import { formLimit } from 'src/app/ui/util/form-limit';
import { ProgramRequestUtil } from 'src/app/ui/util/programRequestUtil';
import { BasicSelect } from 'src/app/ui/util/select-utils';
import { transportTypes } from 'src/app/ui/util/transportType.Utils';
import { environment } from 'src/environments/environment';

interface Resume {
  importer?: string
  consignee?: string
  notify?: string
  destinationPort: string
  containersPerWeek: number
  palletsPerWeek: number
  type: number
  starWeek: string
  endWeek: string
  species?: Species
  variety?: string
  price: number
  incoterms: string
}

interface Picture {
  picture: OfferPicture
  file: any
}

@Component({
  selector: 'app-user-direct-sale-form',
  templateUrl: './user-direct-sale-form.component.html',
  styleUrls: ['./user-direct-sale-form.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class UserDirectSaleFormComponent implements OnInit {

  breadcrumbItems: MenuItem[] = []
  breadcrumbHomeItem: MenuItem = {}

  guid: string | undefined
  exportOffer: BasicExportOffer | undefined

  profile: BasicUser | undefined

  importers: Importer[] = []
  consignees: Consignee[] = []
  notifies: Notifier[] = []

  species: Species[] = []
  varieties: Variety[] = []
  selectedSpecies: Species | undefined
  selectedVariety: Variety | undefined
  incotermsOptions: any[] = []
  fridgeStorages: FridgeStorage[] = []
  fridgeStorageOptions: any[] = []
  countries: Country[] = []
  packagingList: Packaging[] = []
  packagingSelected: number[] = []
  selectedCountryTo: Country | undefined
  ports: Port[] = []

  loading: boolean = false
  submit: boolean = false
  confirmSaveDialogVisible: boolean = false
  confirmEditDialogVisible: boolean = false

  isEdit: boolean = false
  isFromProgramRequest: boolean = false

  programType: number = 0

  resume: Resume | undefined

  directSaleProgramId: string | undefined;
  programRequestId: string | undefined;
  directSaleProgramEdit: DirectSaleProgram | undefined;
  programRequestInformation: ProgramRequestInformation | undefined;

  programTypes: BasicSelect[] = transportTypes

  step: number = 1
  step1: number = 1
  step2: number = 2
  step3: number = 3
  step4: number = 4
  finalStep: number = 5

  startWeekMin: number = 1;
  endWeekMin: number = 1;

  startWeekMax: number = 52; 
  endWeekMax: number = 52; 

  startYearBeforeEdit: number | undefined;
  EndYearBeforeEdit: number | undefined;

  pictures: Picture[] = []
  removedPictures: number[] = []


  airports: Airport[] = []
  uploadedImages: any[] = []

  formLimit = formLimit

  step1FormGroup: FormGroup = this._formBuilder.group({
    type: [0, Validators.required],
    importerId: [null, Validators.required],
    consigneeId: [null, Validators.required],
    notifyId: [null, Validators.required],
    containersPerWeek: [0, Validators.required],
    palletsPerWeek: [0, Validators.required],
    startWeek: [null, [Validators.required]],
    startWeekYear: [this.currentYear, [Validators.required]],
    endWeek: [null],
    endWeekYear: [null],
    importToCountryId: [null, Validators.required],
    portToId: [null, Validators.required],
    airportId: [null],
    startDate: [null],
    endDate: [null]
  },
  {
    validators: [this.endWeekValidator(), this.endWeekYearValidator()]
  })

  step2FormGroup: FormGroup = this._formBuilder.group({
    speciesId: [null, Validators.required],
    varietyId: [null, Validators.required],
    caseQuantityPerPallet: [null, Validators.required],
    listPrice: [null, Validators.required],
    incoterms: ['EXW', Validators.required],
    paymentAgainstBL: [null, Validators.required],
    netKilos: [null],
    packaging: [null],
    otherPackaging: [null],
    speciesSize: [null],
  })



  constructor(private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _messageService: MessageService,
    public translate: TranslateService,
    private _authService: AuthGateway,
    private _formBuilder: FormBuilder,
    private _profileService: ProfileGateway,
    private _consigneeService: ConsigneeGateway,
    private _notifyService: NotifierGateway,
    private _varietyService: VarietyGateway,
    private _speciesService: SpeciesGateway,
    private _fridgeStorageService: FridgeStorageGateway,
    private _countryService: CountryGateway,
    private _packagingService: PackagingGateway,
    private _portService: PortGateway,
    private _exportOfferService: ExportOfferGateway,
    private _directSaleService: DirectSaleProgramGateway,
    private _programRequestService: ProgramRequestGateway,
    private _airportService: AirportGateway
  ) {
    this._activatedRoute.params.subscribe(params => {
      this.guid = params['guid']
    })

    this._activatedRoute.params.subscribe(params => {
      if(params['directSaleProgramId'])
        this.directSaleProgramId = params['directSaleProgramId']
    })

    this._activatedRoute.params.subscribe(params => {
      this.programRequestId = params['programRequestId']
    })
  }

  ngOnInit(): void {
    this.profile = this._authService.getUser()
    if(this.profile) {
      let breadcrumbStep1ItemLabel1 = 'Load direct sale'
      let homeItemLabel = 'My direct sales'

      switch(this.profile.lang) {
        case 'en':
          breadcrumbStep1ItemLabel1 = 'Load direct sale'
          homeItemLabel = 'My direct sales'
          break
        case 'es':
          breadcrumbStep1ItemLabel1 = 'Cargar venta directa'
          homeItemLabel = 'Mis ventas directas'
          break
      }

      this.breadcrumbItems = [{ label: breadcrumbStep1ItemLabel1, disabled: true }]
      this.breadcrumbHomeItem = { label: homeItemLabel, routerLink: '/' + Routes.userClosedSales };
    }

    this.getImporters()
    this.getSpecies()
    this.getPackagings()
    this.getCountries()

    
    if(this.directSaleProgramId)
      { 
        this.getDirectSaleProgram()
      }

    if(this.programRequestId)
      {
        this.getRequestProgram()
      }
  }

  getCardHeader(): string {
    if(this.directSaleProgramEdit)
      return this.translate.instant('DirectSaleFormPage.EditDirectSaleTitle')
    else
      return this.translate.instant('DirectSaleFormPage.CreateTitle')
  }

  getCardSubheader(): string {
    if(this.directSaleProgramEdit)
      return this.translate.instant('DirectSaleFormPage.EditDirectSaleText')
    else
      return this.translate.instant('DirectSaleFormPage.CreateSubtitle')
  }

  getImageUrl(picture: Picture): any {
    if(picture) {
      if(!picture.picture.isNew && picture.picture.url) {
        return environment.apiUrl + picture.picture.url
      }
      else {
        return picture.file.objectURL
      }
    }
    return ''
  }

  getImageName(picture: Picture): string {
    let fileName = ''
    if(!picture.picture.isNew && picture.picture.url) {
      const urlParts: string[] = picture.picture.url.split('/')
      fileName = urlParts[urlParts.length-1]
    }
    else {
      return picture.file.name
    }
    return fileName
  }

  onRemoveImageEdit(index: number): void {
    if(this.exportOffer && this.pictures[index]) {
      this.removedPictures.push(this.pictures[index].picture.id)
      this.pictures.splice(index, 1)
    }
  }

  getDirectSaleProgram() {

    if(this.directSaleProgramId)
      {
        this.loading = true;
        this._directSaleService.getByGuid(this.directSaleProgramId).subscribe({
          next: (v) => {
            this.directSaleProgramEdit = v
            this.loadForm()
          },
          error: () => {
            this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: 'Failed retrieving sale program'});
          }
        })
      }
  }

  getAirPorts(){

    this.selectedCountryTo = this.countries.find(x => x.id == this.step1FormGroup.controls['importToCountryId'].value)

    if(this.step1FormGroup.controls['importToCountryId'].value) {
      this._airportService.getDestinationAirportsByCountry(this.step1FormGroup.controls['importToCountryId'].value).subscribe({
        next: (v) => {
          this.airports = v
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.Port.DestinationPortManyRetrieveError') })
        },
        complete: () => console.info('Destination Ports query successfully')
      })
    }
  }

  changeValidators(){
    if(this.programType === 0){
      this.step1FormGroup.controls['portToId'].setValidators([Validators.required]); 
      this.step1FormGroup.controls['airportId'].clearValidators();                
    } else {                
      this.step1FormGroup.controls['airportId'].setValidators([Validators.required]); 
      this.step1FormGroup.controls['portToId'].clearValidators();               
    }
    
    this.step1FormGroup.controls['portToId'].updateValueAndValidity();
    this.step1FormGroup.controls['airportId'].updateValueAndValidity();
  }

  getRequestProgram()
  {
    if(this.programRequestId)
      {
        this.loading = true;
        this._programRequestService.GetById(this.programRequestId).subscribe({
          next: (v) => {
            this.programRequestInformation = v
            this.loadFormProgram()
          },
          error: () => {
            this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: 'Failed retrieving sale program'});
          }
        })
      }
  }

  onChangeStartDate(event: any)
  {
    this.step1FormGroup.controls['startWeek'].setValue(this.getWeekNumber(event))
    let year = this.getYearDate(event)
    this.step1FormGroup.controls['startWeekYear'].setValue(year)
  }

  onChangeEndDate(event: any)
  {
    this.step1FormGroup.controls['endWeek'].setValue(this.getWeekNumber(event))
    let year =this.getYearDate(event)
    this.step1FormGroup.controls['endWeekYear'].setValue(year)
  }

  getWeekNumber(date: Date): number {
    return ProgramRequestUtil.ISO8601_week_no(date);
    // const startDate = new Date(date.getFullYear(), 0, 1);
    // const days = Math.floor((date.valueOf() - startDate.valueOf()) / (24 * 60 * 60 * 1000));
    // const weekNumber = Math.ceil((days + startDate.getDay() + 1) / 7);
    // return weekNumber;
  }

  getYearDate(date: Date)
  {
    return date.getFullYear()
  }

  
  loadFormProgram()
  {
    if(this.programRequestId)
      {
        this.isFromProgramRequest = true;
        this.step1FormGroup.controls['importerId'].setValue(
          this.programRequestInformation?.programRequest.importer.id
        );
        this.getConsignees()
        this.getNotifies()
        this.step1FormGroup.controls['consigneeId'].setValue(
          this.programRequestInformation?.programRequest.consigneeId
        );

        this.step1FormGroup.controls['notifyId'].setValue(
          this.programRequestInformation?.programRequest.notifyId
        );

        if( this.programRequestInformation?.programRequest.palletQuantity)
          {
            let containersPerWeek = this.programRequestInformation?.programRequest.palletQuantity / 20;
            this.step1FormGroup.controls['containersPerWeek'].setValue(
              containersPerWeek
            );
          }

        
        if(this.programRequestInformation?.programRequest.palletsPerWeek){
          this.step1FormGroup.controls['palletsPerWee'].setValue(
            this.programRequestInformation?.programRequest.palletsPerWeek
          )
        }

        this.selectedCountryTo = this.countries.find(
          x => x.id === this.programRequestInformation?.programRequest.countryToId
        )

        this.step1FormGroup.controls['importToCountryId'].setValue(
          this.programRequestInformation?.programRequest.countryToId
        );
        
        this.step1FormGroup.controls['startWeek'].setValue(
          this.programRequestInformation?.programRequest.startWeek
        );

        this.step1FormGroup.controls['startWeekYear'].setValue(
          this.programRequestInformation?.programRequest.startWeekYear
        );

        this.step1FormGroup.controls['endWeek'].setValue(
          this.programRequestInformation?.programRequest.endWeek
        );

        this.step1FormGroup.controls['endWeekYear'].setValue(
          this.programRequestInformation?.programRequest.endWeekYear
        );

        this.selectedSpecies = this.species.find(
          x => x.id  === this.programRequestInformation?.programRequest.variety.speciesId
        );
        
        this.step2FormGroup.controls['speciesId'].setValue(
          this.programRequestInformation?.programRequest.variety.speciesId
        );

        this.getVarieties()        
        this.getPorts();

        this.step1FormGroup.controls['portToId'].setValue(
          this.programRequestInformation?.programRequest.portToId
        );

        this.step2FormGroup.controls['varietyId'].setValue(
          this.programRequestInformation?.programRequest.varietyId
        );

        this.step2FormGroup.controls['listPrice'].setValue(
          this.programRequestInformation?.programRequest.pricePerCase
        );

        this.step2FormGroup.controls['caseQuantityPerPallet'].setValue(
          this.programRequestInformation?.programRequest.caseQuantityPerPallet
        );

        this.step2FormGroup.controls['incoterms'].setValue(
          this.programRequestInformation?.programRequest.typeSale
        );

        this.step2FormGroup.controls['paymentAgainstBL'].setValue(
          this.programRequestInformation?.programRequest.paymentAgainstBL
        );

        this.step2FormGroup.controls['speciesSize'].setValue(
          this.programRequestInformation?.programRequest.speciesSize
        );

        this.step2FormGroup.controls['netKilos'].setValue(
          this.programRequestInformation?.programRequest?.netKilos
        );

        this.step2FormGroup.controls['packaging'].setValue(this.programRequestInformation?.programRequest.packagingTypes 
          ? this.programRequestInformation?.programRequest.packagingTypes[0] : null )

          this.step2FormGroup.controls['otherPackaging'].setValue(this.programRequestInformation?.programRequest.otherPackaging);

      }
  }

  loadForm()
  {
    if(this.directSaleProgramId)
      {
        console.log(this.directSaleProgramEdit);
        this.isEdit = true;
        this.step1FormGroup.controls['importerId'].setValue(this.directSaleProgramEdit?.importerId);
        this.programType = this.directSaleProgramEdit?.type ?? 0;
        this.getConsignees()
        this.getNotifies()
        this.step1FormGroup.controls['consigneeId'].setValue(this.directSaleProgramEdit?.consigneeId);
        this.step1FormGroup.controls['notifyId'].setValue(this.directSaleProgramEdit?.notifyId);
        this.step1FormGroup.controls['containersPerWeek'].setValue(this.directSaleProgramEdit?.containersPerWeek);
        this.step1FormGroup.controls['palletsPerWeek'].setValue(this.directSaleProgramEdit?.palletsPerWeek);
        this.selectedCountryTo = this.countries.find(
          x => x.id === this.directSaleProgramEdit?.destinationCountryId
        )

        this.step1FormGroup.controls['importToCountryId'].setValue(this.directSaleProgramEdit?.destinationCountryId);
        
        this.step1FormGroup.controls['startWeek'].setValue(this.directSaleProgramEdit?.startWeek);
        this.step1FormGroup.controls['startWeekYear'].setValue(this.directSaleProgramEdit?.startWeekYear);
        this.step1FormGroup.controls['endWeek'].setValue(this.directSaleProgramEdit?.endWeek);
        this.step1FormGroup.controls['endWeekYear'].setValue(this.directSaleProgramEdit?.endWeekYear);
        
        this.startYearBeforeEdit = this.directSaleProgramEdit?.startWeekYear;
        this.EndYearBeforeEdit = this.directSaleProgramEdit?.endWeekYear;

        this.selectedSpecies = this.species.find(
          x => x.id  === this.directSaleProgramEdit?.exportOffer?.variety.speciesId
        );
        
        this.step2FormGroup.controls['speciesId'].setValue(this.directSaleProgramEdit?.exportOffer?.variety.speciesId);
        this.getVarieties() 
        this.programType === 0 ? this.getPorts(): this.getAirPorts();

        this.step1FormGroup.controls['portToId'].setValue(this.directSaleProgramEdit?.dischargePortId);
        this.step1FormGroup.controls['airportId'].setValue(this.directSaleProgramEdit?.airportId)
        this.step2FormGroup.controls['varietyId'].setValue(this.directSaleProgramEdit?.exportOffer?.varietyId);
        this.step2FormGroup.controls['listPrice'].setValue(this.directSaleProgramEdit?.exportOffer?.listPrice);
        this.step2FormGroup.controls['caseQuantityPerPallet'].setValue(this.directSaleProgramEdit?.exportOffer?.caseQuantityPerPallet);
        this.step2FormGroup.controls['incoterms'].setValue(this.directSaleProgramEdit?.exportOffer?.typeSale)
        this.step2FormGroup.controls['paymentAgainstBL'].setValue(this.directSaleProgramEdit?.exportOffer?.paymentAgainstBL);
        this.step2FormGroup.controls['speciesSize'].setValue(this.directSaleProgramEdit?.exportOffer?.speciesSize);
        this.step2FormGroup.controls['netKilos'].setValue(this.directSaleProgramEdit?.exportOffer?.netKilos);
        this.step2FormGroup.controls['packaging'].setValue(this.directSaleProgramEdit?.exportOffer?.packagingTypes 
          ? this.directSaleProgramEdit?.exportOffer.packagingTypes[0] : null )
        if (this.directSaleProgramEdit?.exportOffer?.packagingTypes) {
          this.packagingSelected[0] = this.directSaleProgramEdit?.exportOffer.packagingTypes[0].id
        }
        this.step2FormGroup.controls['otherPackaging'].setValue(this.directSaleProgramEdit?.exportOffer?.otherPackaging);
      }
  }

  getImporters() {
    this.importers = []
    this.loading = true
    if(this.profile) {
      this._profileService.getAllImportersByExporterId().subscribe({
        next: (v) => {
          this.importers = v
          this.incotermsOptions = ExportOfferUtils.getIncotermsClosedSaleOptions()
          this.getYears()
          this.loading = false
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.Importer.ManyRetrieveError') })
        },
        complete: () => console.info('Importers query successfully')
      })
    }
  }

  onChangeImporter() {
    this.getConsignees()
    this.getNotifies()
  }

  getConsignees() {
    if(this.step1FormGroup.controls['importerId'].value) {
      this._consigneeService.getAllForClosedSale(this.step1FormGroup.controls['importerId'].value).subscribe({
        next: (v) => {
          this.consignees = v
          this.loading = false
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.Consignee.ManyRetrieveError') })
        },
        complete: () => console.info('Consignees query successfully')
      })
    }
  }

  getNotifies() {
    if(this.step1FormGroup.controls['importerId'].value) {
      this._notifyService.getAllForClosedSale(this.step1FormGroup.controls['importerId'].value).subscribe({
        next: (v) => {
          this.notifies = v
          this.loading = false
        },
        error: (e) => {
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.Notify.ManyRetrieveError') })
        },
        complete: () => console.info('Notifies query successfully')
      })
    }
  }

  backStep1() {
    this.step = this.step1
  }

  goToStep2() {
    this.submit = true
    if(this.step1FormGroup.valid) {

      if(this.isEdit && this.directSaleProgramEdit)
        {
          let validateEditDirectProgramSale: ValidateDirectSale = {
            directSaleProgramId: this.directSaleProgramEdit.guid,
            startWeek: this.step1FormGroup.controls['startWeek'].value,
            startWeekYear: this.step1FormGroup.controls['startWeekYear'].value,
            endWeek: this.step1FormGroup.controls['endWeek'].value,
            endWeekYear: this.step1FormGroup.controls['endWeekYear'].value,
          }
          this._exportOfferService.validateEditDirectProgramSale(validateEditDirectProgramSale).subscribe({
            next:(v) => {
              this.submit = false
              this.step = this.step2
            },
            error: (e) => {
              this.submit = false
              console.log(e)
              if(e.status == 400)
                {
                  this._messageService.add({ severity: 'error',
                  summary: this.translate.instant('MessageService.Summary.FailedAction'),
                  detail: this.translate.instant('MessageService.DirectSaleEdit.InvalidWeekDecreaseError') })

                  //  this.startWeekMax = this.directSaleProgramEdit?.startWeek ? this.directSaleProgramEdit?.startWeek: 1;
                  //  this.endWeekMin = this.directSaleProgramEdit?.endWeek ? this.directSaleProgramEdit?.endWeek: 52; 

                }else{
                  this._messageService.add({ severity: 'error',
                  summary: this.translate.instant('MessageService.Summary.FailedAction'),
                  detail: this.translate.instant('MessageService.DirectSaleEdit.ErrorWeekValidation') })
                }

            }
          })
        }else{
          this.submit = false
          this.step = this.step2
        }

    }
    else {
      this._messageService.add({ severity: 'error',
        summary: this.translate.instant('MessageService.Summary.FailedAction'),
        detail: this.translate.instant('MessageService.FormMessages.FormInvalid') });
    }
  }

  backStep2() {
    this.step = this.step2
  }

  goToStep3() {
    this.submit = true
    if(this.step2FormGroup.valid) {
      this.submit = false
      this.step = this.step3
    }
    else {
      this._messageService.add({ severity: 'error',
        summary: this.translate.instant('MessageService.Summary.FailedAction'),
        detail: this.translate.instant('MessageService.FormMessages.FormInvalid') });
    }
  }

  backStep3() {
    this.step = this.step3
  }

  onConfirmSave() {
    if(this.step1FormGroup.valid && this.step2FormGroup.valid) {
      const importer = this.importers.find(x => x.id === this.step1FormGroup.controls['importerId'].value)
      const consignee = this.consignees.find(x => x.id === this.step1FormGroup.controls['consigneeId'].value)
      const notify = this.notifies.find(x => x.id === this.step1FormGroup.controls['notifyId'].value)
      const destinationPort = this.ports.find(x => x.id === this.step1FormGroup.controls['portToId'].value)
      const species = this.species.find(x => x.id === this.step2FormGroup.controls['speciesId'].value)
      const variety = this.varieties.find(x => x.id === this.step2FormGroup.controls['varietyId'].value)
      this.resume = {
        importer: importer ? importer.companyName : '',
        consignee: consignee ? consignee.companyName : '',
        notify: notify ? notify.companyName: '',
        destinationPort: destinationPort ? destinationPort.name + ' ' + destinationPort.country?.name : '',
        containersPerWeek: this.step1FormGroup.controls['containersPerWeek'].value,
        palletsPerWeek: this.step1FormGroup.controls['palletsPerWeek'].value,
        type: this.programType,
        starWeek: this.step1FormGroup.controls['startWeek'].value + '-' + this.step1FormGroup.controls['startWeekYear'].value,
        endWeek: this.step1FormGroup.controls['endWeek'].value + '-' + this.step1FormGroup.controls['endWeekYear'].value,
        species: species,
        variety: variety?.name,
        price: this.step2FormGroup.controls['listPrice'].value,
        incoterms: this.step2FormGroup.controls['incoterms'].value
      }
      this.confirmSaveDialogVisible = true
    }
  }

  save() {
    this.confirmSaveDialogVisible = false
    this.loading = true
    if(this.step1FormGroup.valid && this.step2FormGroup.valid) {
      if(!this.exportOffer) {
        const request: DirectSaleCreateRequest = {
          type: this.programType,
          importerId: this.step1FormGroup.controls['importerId'].value,
          consigneeId: this.step1FormGroup.controls['consigneeId'].value,
          notifyId: this.step1FormGroup.controls['notifyId'].value,
          containersPerWeek: this.step1FormGroup.controls['containersPerWeek'].value,
          palletsPerWeek: this.step1FormGroup.controls['palletsPerWeek'].value,
          closedSaleStartWeek: this.step1FormGroup.controls['startWeek'].value,
          closedSaleStartWeekYear: this.step1FormGroup.controls['startWeekYear'].value,
          closedSaleEndWeek: this.step1FormGroup.controls['endWeek'].value,
          closedSaleEndWeekYear: this.step1FormGroup.controls['endWeekYear'].value,
          importToCountryId: this.step1FormGroup.controls['importToCountryId'].value,
          portToId: this.step1FormGroup.controls['portToId'].value,
          varietyId: this.step2FormGroup.controls['varietyId'].value,
          caseQuantityPerPallet: this.step2FormGroup.controls['caseQuantityPerPallet'].value,
          listPrice: this.step2FormGroup.controls['listPrice'].value,
          typeSale: this.step2FormGroup.controls['incoterms'].value,
          paymentAgainstBL: this.step2FormGroup.controls['paymentAgainstBL'].value,
          netKilos: this.step2FormGroup.controls['netKilos'].value,
          speciesSize: this.step2FormGroup.controls['speciesSize'].value,
          otherPackaging: this.step2FormGroup.controls['otherPackaging'].value,
          packagings: this.packagingSelected,
          programRequestId: this.programRequestInformation ? this.programRequestInformation.programRequest.id : undefined,
          airportId: this.step1FormGroup.controls['airportId'].value
        }

        this._exportOfferService.createBasicDirectSale(request).subscribe({
          next: (v) => {
            this.guid = v
            this._messageService.add({ severity: 'success',
              summary: this.translate.instant('MessageService.Summary.SuccessAction'),
              detail: this.translate.instant('MessageService.ExportOffer.CreateSuccess') })
            this.submit = false
            this.loading = false
            this.step = this.step3
          },
          error: (e) => {
            this.loading = false
            console.log(e)
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.ExportOffer.CreateError') })
          },
          complete: () => console.info('Export Offer created successfully')
        })
      }
    }
  }

  onConfirmEdit() {
    if(this.step1FormGroup.valid && this.step2FormGroup.valid) {
      const importer = this.importers.find(x => x.id === this.step1FormGroup.controls['importerId'].value)
      const consignee = this.consignees.find(x => x.id === this.step1FormGroup.controls['consigneeId'].value)
      const notify = this.notifies.find(x => x.id === this.step1FormGroup.controls['notifyId'].value)
      const destinationPort = this.ports.find(x => x.id === this.step1FormGroup.controls['portToId'].value)
      const species = this.species.find(x => x.id === this.step2FormGroup.controls['speciesId'].value)
      const variety = this.varieties.find(x => x.id === this.step2FormGroup.controls['varietyId'].value)
      this.resume = {
        importer: importer ? importer.companyName : '',
        consignee: consignee ? consignee.companyName : '',
        notify: notify ? notify.companyName: '',
        destinationPort: destinationPort ? destinationPort.name + ' ' + destinationPort.country?.name : '',
        containersPerWeek: this.step1FormGroup.controls['containersPerWeek'].value,
        palletsPerWeek: this.step1FormGroup.controls['palletsPerWeek'].value,
        starWeek: this.step1FormGroup.controls['startWeek'].value + '-' + this.step1FormGroup.controls['startWeekYear'].value,
        endWeek: this.step1FormGroup.controls['endWeek'].value + '-' + this.step1FormGroup.controls['endWeekYear'].value,
        species: species,
        variety: variety?.name,
        price: this.step2FormGroup.controls['listPrice'].value,
        incoterms: this.step2FormGroup.controls['incoterms'].value,
        type: this.programType
      }
      this.confirmEditDialogVisible = true
    }
  }

  edit()
  {
    this.confirmEditDialogVisible = false
    this.loading = true

    if(this.step1FormGroup.valid && this.step2FormGroup.valid)
      {
        if(this.directSaleProgramEdit)
        {
            
          const request: EditDirectSale = {
            directSaleProgramId: this.directSaleProgramEdit.guid,
            importerId: this.step1FormGroup.controls['importerId'].value,
            consigneeId: this.step1FormGroup.controls['consigneeId'].value,
            notifyId: this.step1FormGroup.controls['notifyId'].value,
            containersPerWeek: this.step1FormGroup.controls['containersPerWeek'].value,
            palletsPerWeek: this.step1FormGroup.controls['palletsPerWeek'].value,
            startWeek: this.step1FormGroup.controls['startWeek'].value,
            startWeekYear: this.step1FormGroup.controls['startWeekYear'].value,
            endWeek: this.step1FormGroup.controls['endWeek'].value,
            endWeekYear: this.step1FormGroup.controls['endWeekYear'].value,
            importToCountryId: this.step1FormGroup.controls['importToCountryId'].value,
            portToId: this.step1FormGroup.controls['portToId'].value,
            varietyId: this.step2FormGroup.controls['varietyId'].value,
            caseQuantityPerPallet: this.step2FormGroup.controls['caseQuantityPerPallet'].value,                
            listPrice: this.step2FormGroup.controls['listPrice'].value,
            typeSale: this.step2FormGroup.controls['incoterms'].value,
            paymentAgainstBL: this.step2FormGroup.controls['paymentAgainstBL'].value,
            netKilos: this.step2FormGroup.controls['netKilos'].value,
            speciesSize: this.step2FormGroup.controls['speciesSize'].value,
            otherPackaging: this.step2FormGroup.controls['otherPackaging'].value,
            packagings: this.packagingSelected,
            airportId: this.step1FormGroup.controls['airportId'].value,
          }

          this._exportOfferService.editDirectProgramSale(
            request
          ).subscribe({
            next: (v) => {
              this.guid = v.exportOffer?.guid
              this._messageService.add({ severity: 'success',
                summary: this.translate.instant('MessageService.Summary.SuccessAction'),
                detail: 'Direct sale updated' })
              this.submit = false
              this.loading = false
              this.step = this.step3
            },
            error: (e) => {
              this.loading = false
              console.log(e)
              this._messageService.add({ severity: 'error',
                summary: this.translate.instant('MessageService.Summary.FailedAction'),
                detail: 'Error updating direct sale' })
            }
          })
      }
    }
  }
  

  @ViewChild(ExportOfferPictureUploaderComponent) pictureUploaderComponent: ExportOfferPictureUploaderComponent | undefined
  @ViewChild(ExportOfferCertificateTypeComponent) exportOfferCertificateTypeComponent: ExportOfferCertificateTypeComponent | undefined
  finish() {
    console.log(this.guid);
    if(this.guid && this.exportOfferCertificateTypeComponent) {

      const result = this.exportOfferCertificateTypeComponent?.getSelectedProductCertificateTypes()
      let certificateTypeIds: number[] = []
      result.productCertificateTypes.forEach(x => {
        certificateTypeIds.push(x.id)
      })

      const request: UpdatePicturesAndCertificateTypesRequest = {
        exportOfferId: this.guid,
        pictures: this.uploadedImages,
        certificateTypeIds: certificateTypeIds,
        otherCertificateType: result.otherCertificateType
      }
      this.loading = true
      this._exportOfferService.updatePicturesAndCertificateTypes(request).subscribe({
        next: (v) => {
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: this.translate.instant('MessageService.ExportOffer.EditSuccess') })
          this.submit = false
          this.loading = false
          
          if(this.directSaleProgramEdit)
            {
              this.goToDirectSalesPage()
            }else{
              this.step = this.finalStep
            }
        },
        error: (e) => {
          this.submit = false
          this.loading = false
          console.log(e)
          let errorMessage = ''
          const lang = this.translate.currentLang
          switch(lang) {
            case 'es':
              errorMessage = e.error.messageEs
              break
            case 'en':
              errorMessage = e.error.messageEn
              break
            default:
              errorMessage = e.error.messageEn
          }
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: errorMessage ?? this.translate.instant('MessageService.ExportOffer.EditError') });
        },
        complete: () => console.info('Export Offer update successfully')
      })
    }
  }


  onChangeSpecies() {
    this.selectedSpecies = this.species.find(x => x.id === this.step2FormGroup.controls['speciesId'].value)
    this.getVarieties()
  }

  getSpecies() {
    this._speciesService.getAllByProductType(1).subscribe({
      next: (v) => {
        this.species = v
        this.loading = false
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Species.ManyRetrieveError') })
      },
      complete: () => console.info('Species query successfully')
    })
  }

  onChangeVariety() {
    this.selectedVariety = this.varieties.find(x => x.id === this.step2FormGroup.controls['varietyId'].value)
  }

  getVarieties() {
    if(this.step2FormGroup.controls['speciesId'].value) {
      this.varieties = []
      this._varietyService.getAllBySpecies(this.step2FormGroup.controls['speciesId'].value).subscribe({
        next: (v) => {
          this.varieties = v
          if(this.directSaleProgramEdit)
            {
              this.selectedVariety = this.varieties.find(
                x => x.id ===this.directSaleProgramEdit?.exportOffer?.varietyId
              )
            }
            if(this.programRequestInformation)
              {
                this.selectedVariety = this.varieties.find(
                  x => x.id === this.programRequestInformation?.programRequest.varietyId
                )
              }
          this.loading = false
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.Variety.ManyRetrieveError') })
        },
        complete: () => console.info('Variety query successfully')
      })
    }
  }

  get paymentBLTitle() {
    return ExportOfferUtils.getPaymentBLClosedSaleTitle(this.step2FormGroup.controls['paymentAgainstBL'].value);
  }

  get paymentBLItems() {
    return ExportOfferUtils.getPaymentBLClosedSaleValues(this.step2FormGroup.controls['incoterms'].value);
  }

  get paymentBLPercent() {
    return ExportOfferUtils.showPaymentBLClosedSalePercent(this.step2FormGroup.controls['incoterms'].value)
  }

  get finalPaymentPercent(): number {
    if(this.step2FormGroup.controls['paymentAgainstBL'].value)
      return 100 - this.step2FormGroup.controls['paymentAgainstBL'].value
    return 0
  }

  getCountries() {
    this.countries = []
    this._countryService.getAll().subscribe({
      next: (v) => {
        this.countries = v
        this.loading = false
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Country.ManyRetrieveError') })
      },
      complete: () => console.info('Country query successfully')
    })
  }

  getPackagings() {
    this._packagingService.getAll().subscribe({
      next: (v) => {
        this.packagingList = v
        this.loading = false
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Packaging.ManyRetrieveError') })
      },
      complete: () => console.info('Packaging query successfully')
    })
  }

  filterPackaging(event: any) {
    if (event?.isOther === true) {
      this.step2FormGroup.controls['otherPackaging'].enable()
    } else {
      this.step2FormGroup.controls['otherPackaging'].setValue('')
      this.step2FormGroup.controls['otherPackaging'].disable()
    }
    if (event) this.packagingSelected[0] = event.id
  }

  onChangeImportToCountry() {
    if(this.programType === 0){
      this.getPorts();
    }else{
      this.getAirPorts()
    }
    this.changeValidators()
  }

  getPorts() {
    this.selectedCountryTo = this.countries.find(x => x.id == this.step1FormGroup.controls['importToCountryId'].value)

    if(this.step1FormGroup.controls['importToCountryId'].value) {
      this._portService.getDestinationPortsByCountry(this.step1FormGroup.controls['importToCountryId'].value).subscribe({
        next: (v) => {
          this.ports = v
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.Port.DestinationPortManyRetrieveError') })
        },
        complete: () => console.info('Destination Ports query successfully')
      })
    }
  }

  years: any[] = []
  getYears() {
    let current = new Date().getFullYear()
    this.years.push({label: current, value: current})
    let next = current + 1
    this.years.push({label: next, value: next})
  }

  get currentYear(): number {
    return new Date().getFullYear()
  }

  endWeekYearValidator(): ValidatorFn {
    return (control: AbstractControl) : ValidationErrors | null => {
      if(!this.step1FormGroup)
        return null

      if(!this.step1FormGroup.controls['startWeekYear'].value)
        return null

      if(!this.step1FormGroup.controls['endWeekYear'].value)
        return null

      let startWeekYear = this.step1FormGroup.controls['startWeekYear'].value
      let endWeekYear = this.step1FormGroup.controls['endWeekYear'].value

      return endWeekYear && startWeekYear > endWeekYear ? {endWeekYearRangeError:true}: null;
    }
  }

  endWeekValidator(): ValidatorFn {
    return (control: AbstractControl) : ValidationErrors | null => {
      if(!this.step1FormGroup)
        return null

      if(!this.step1FormGroup.controls['startWeekYear'].value)
        return null

      if(!this.step1FormGroup.controls['endWeekYear'].value)
        return null

      if(!this.step1FormGroup.controls['startWeek'].value)
        return null

      if(!this.step1FormGroup.controls['endWeek'].value)
        return null

      let startWeek = this.step1FormGroup.controls['startWeek'].value
      let endWeek = this.step1FormGroup.controls['endWeek'].value
      let startWeekYear = this.step1FormGroup.controls['startWeekYear'].value
      let endWeekYear = this.step1FormGroup.controls['endWeekYear'].value

      return (startWeekYear === endWeekYear && startWeek > endWeek) ? {endWeekRangeError:true} : null;
    }
  }

  onChangeStartYear(event: any)
  {
    if(this.isEdit == true)
      {
        if(event.value <= this.step1FormGroup.controls['endWeekYear'].value)
          {
            if(this.startYearBeforeEdit)
              {
                if(event.value != this.startYearBeforeEdit) {
                   this.startWeekMin = 1;
                   this.startWeekMax = 52;
                   this.step1FormGroup.controls['startWeek'].setValue(1)
                }
              }
          }else{
            this._messageService.add({ severity: 'error',
            summary: this.translate.instant('Error'),
            detail: this.translate.instant('The start year cant be bigger than the end year') })
            this.step1FormGroup.controls['startWeekYear'].setValue(this.startYearBeforeEdit)
    
          } 
      }
  }

  onChangeEndYear(event: any)
  {

    if(this.isEdit == true)
      {
        if(this.EndYearBeforeEdit)
          {
            if(this.EndYearBeforeEdit <= event.value )
              {

                  if(event.value >= this.step1FormGroup.controls['startWeekYear'].value)
                      {
                        if(event.value != this.EndYearBeforeEdit) {
                          this.endWeekMin = this.startWeekMax;
                          this.endWeekMax = 52;
                          this.step1FormGroup.controls['endWeek'].setValue(52)
                        }
                        
                      }else{
    
                        this._messageService.add({ severity: 'error',
                        summary: this.translate.instant('Error'),
                        detail: this.translate.instant('The start year cant be greater than the end year') })
                        this.step1FormGroup.controls['endWeekYear'].setValue(this.EndYearBeforeEdit);
                      }
              
          
              }else{
                this._messageService.add({ severity: 'error',
                summary: this.translate.instant('Error'),
                detail: this.translate.instant('The number of weeks cant be decrease') })
          
                this.step1FormGroup.controls['endWeekYear'].setValue(this.EndYearBeforeEdit);
              }
          }
      }  
  }


  goToDirectSalesPage() {
    this._router.navigate(['/' + Routes.userClosedSales])
  }

  get totalWeeks(): number {
    let startWeek = this.step1FormGroup.controls['startWeek'].value
    let startWeekYear = this.step1FormGroup.controls['startWeekYear'].value
    let endWeek = this.step1FormGroup.controls['endWeek'].value
    let endWeekYear = this.step1FormGroup.controls['endWeekYear'].value

    return ExportOfferUtils.getTotalWeeks(startWeek, startWeekYear, endWeek, endWeekYear)
  }

  get totalPallets(): number {
    if(this.programType === 0){
      return this.totalContainers * 20
    }else{
      return this.step1FormGroup.controls['palletsPerWeek'].value
    }
  }

  get totalContainers(): number {
    let containersPerWeek = this.step1FormGroup.controls['containersPerWeek'].value
    return this.totalWeeks * containersPerWeek
  }

  get totalProgramPallets(): number {
    let palletsPerWeek = this.step1FormGroup.controls['palletsPerWeek'].value
    return palletsPerWeek * this.totalWeeks;
  }

  goToDirectSaleEditAdditionalInfoPage() {
    if(this.guid) {
      this._router.navigate(['/' + Routes.userDirectSaleEditAdditionalInfo, this.guid])
    }
  }

  onSelectImage(event: any, uploader: any) {
    this.uploadedImages = uploader.files
  }

  onRemoveImage(event: any, uploader: any) {
    this.uploadedImages = uploader.files
  }

  onClearImageUploader(event: any, uploader: any) {
    this.uploadedImages = uploader.files
  }
}
