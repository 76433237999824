import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { BasicUser } from 'src/app/domain/models/User';
import { Routes } from 'src/app/infrastructure/routes';

@Component({
  selector: 'app-export-offer-documents-page',
  templateUrl: './export-offer-documents-page.component.html',
  styleUrls: ['./export-offer-documents-page.component.css']
})
export class ExportOfferDocumentsPageComponent implements OnInit {
  breadcrumbItems: MenuItem[] = []
  breadcrumbHomeItem: MenuItem = {}

  exportOfferId: number | undefined

  profile: BasicUser | undefined

  constructor(private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _authService: AuthGateway,) {
    this._activatedRoute.params.subscribe(params => {
      this.exportOfferId = params['exportOfferId']
    })
  }

  ngOnInit(): void {
    this.profile = this._authService.getUser()

    let breadcrumbMenuItemLabel1 = 'Export offer'
    let breadcrumbMenuItemLabel2 = 'Export offer documentation'
    let breadcrumbHomeMenuItemLabel = 'My export offers'

    if(this.profile) {
      switch(this.profile.lang) {
        case 'en':
          breadcrumbMenuItemLabel1 = 'Upload new offer'
          breadcrumbMenuItemLabel2 = 'Export offer documentation'
          breadcrumbHomeMenuItemLabel = 'My export offers'
          break
        case 'es':
          breadcrumbMenuItemLabel1 = 'Oferta de exportación'
          breadcrumbMenuItemLabel2 = 'Documentación de oferta de exportación'
          breadcrumbHomeMenuItemLabel = 'Mis ofertas de exportación'
          break
      }
    }

    this.breadcrumbItems = [{ label: breadcrumbMenuItemLabel2, disabled: true}]
    this.breadcrumbHomeItem = { label: breadcrumbHomeMenuItemLabel, routerLink: '/' + Routes.userExportOffers };
  }
}
