import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { FrozenFruitQualityControlReview, FrozenFruitQualityControlReviewUpdate, QualityControlReview, QualityControlReviewItemList, QualityControlReviewUpdate, UploadQualityControlReviewPdfRequest } from "src/app/domain/models/QualityControlReview";

@Injectable({providedIn: 'root'})
export abstract class QualityControlReviewGateway {
  abstract verifyFreshFruitOrigin(request: QualityControlReviewUpdate): Observable<any>
  abstract verifyFrozenFruitOrigin(request: FrozenFruitQualityControlReviewUpdate): Observable<any>
  abstract getAllByStatusAndReviewType(status: number, reviewType: number): Observable<QualityControlReviewItemList[]>
  abstract getByIdFreshFruit(guid: string): Observable<QualityControlReview>
  abstract getByIdFrozenFruit(guid: string): Observable<FrozenFruitQualityControlReview>
  abstract uploadOriginQCReviewPdf(request: UploadQualityControlReviewPdfRequest): Observable<any>
  abstract uploadOriginQCConsolidationPdf(request: UploadQualityControlReviewPdfRequest): Observable<any>
  abstract getAll(): Observable<QualityControlReviewItemList[]>
}
