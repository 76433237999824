import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { FrozenFruitExportOfferGateway } from 'src/app/application/gateway/FrozenFruitExportOfferGateway';
import { FrozenFruitExportOffer } from 'src/app/domain/models/FrozenFruitExportOffer';
import { FrozenFruitImportOrderCreateRequest } from 'src/app/domain/models/ImportOrder';
import { BasicUser } from 'src/app/domain/models/User';

@Component({
  selector: 'app-frozen-fruit-export-offer-detail',
  templateUrl: './frozen-fruit-export-offer-detail.component.html',
  styleUrls: ['./frozen-fruit-export-offer-detail.component.css'],
  providers: [MessageService]
})
export class FrozenFruitExportOfferDetailComponent implements OnInit {

  @Input()
  offer: FrozenFruitExportOffer | undefined

  @Input()
  countryFromId: number | undefined

  @Input()
  countryToId: number | undefined

  @Input()
  portToId: number | undefined

  profile: BasicUser | undefined

  @Input()
  isPurchase: boolean = false

  containers: number = 1
  loading: boolean = false

  @Output()
  successAddShoppingCartEvent = new EventEmitter<boolean>();

  constructor(private _authService: AuthGateway,
    private _messageService: MessageService,
    public translate: TranslateService,
    private _frozenFruitExportOfferService: FrozenFruitExportOfferGateway) {
    this.profile = _authService.getUser()
  }

  ngOnInit(): void {
  }

  getHeader(): string {
    let header = ''
    if(this.profile)
    {
      switch(this.profile.lang) {
        case 'en':
          header = this.offer?.variety && this.offer.variety.species && this.offer.variety.species.name ? this.offer.variety.species.name.toUpperCase() : ''
          break

        case 'es':
          header = this.offer?.variety && this.offer.variety.species && this.offer.variety.species.nameEs ? this.offer.variety.species.nameEs.toUpperCase() : ''
          break
      }
    }
    return header
  }

  getSubheader(): string {
    return this.offer?.variety && this.offer.variety.name ? this.offer.variety.name.toUpperCase() : ''
  }

  totalPrice = 0
  getTotalPrice(event: any): number {
    const containers = event.value
    if(this.offer)
      this.totalPrice = this.offer.boxesPerPallet > 0 ? this.offer.boxesPerPallet * 20 * this.offer.price * containers : this.offer.boxesQuantity * this.offer.price * containers
    return 0
  }

  addShoppingCart() {
    if(this.offer && this.countryFromId && this.countryToId && this.portToId) {
      const request: FrozenFruitImportOrderCreateRequest = {
        frozenFruitExportOfferId: this.offer.id,
        importFromCountryId: this.countryFromId,
        importToCountryId: this.countryToId,
        portToId: this.portToId,
        containersQuantity: this.containers
      }

      this.loading = true
      this._frozenFruitExportOfferService.addFrozenFruitShoppingCart(request).subscribe({
        next: (v) => {
          this.loading = false
          this.successAddShoppingCartEvent.emit(true)
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOffer.SingleRetrieveError') })
          this.successAddShoppingCartEvent.emit(false)
        },
        complete: () => console.info('Frozen fruit export offer query successfully')
      })
    }
  }
}
