import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { ImportOrderGateway } from 'src/app/application/gateway/ImportOrderGateway';
import { OfferNegotiationGateway } from 'src/app/application/gateway/OfferNegotiationGateway';
import { ImportRequestService } from 'src/app/application/service/ImportRequestService';
import { ExportOffer, ExportOfferInformation } from 'src/app/domain/models/ExportOffer';
import { ImportRequest, ImportRequestInformation } from 'src/app/domain/models/ImportRequest';
import { NewNegotiation } from 'src/app/domain/models/OfferNegotiation';
import { Packaging } from 'src/app/domain/models/Packaging';
import { ProductCertificateType } from 'src/app/domain/models/ProductCertificateType';
import { Routes } from 'src/app/infrastructure/routes';
import { ExportOfferUtils } from 'src/app/ui/util/export-offer-utils';
import { Lang } from 'src/app/ui/util/langs';

@Component({
  selector: 'app-view-import-request-offers',
  templateUrl: './view-import-request-offers.component.html',
  styleUrls: ['./view-import-request-offers.component.css'],
  providers: [MessageService]
})
export class ViewImportRequestOffersComponent implements OnInit {

  exportOffers: ExportOfferInformation[] = []
  loading: boolean = false

  visibleOfferDetailDialog: boolean = false;
  enabledPurchased: boolean = true;

  selected: ExportOfferInformation | undefined
  selectedProductCertificateTypes: ProductCertificateType[] = []
  selectedPackagingTypes: Packaging[] = []

  pallets: number = 0
  responsiveOptions: any[] | undefined;
  importRequestInfo: ImportRequestInformation | undefined;

  incotermsOptions: any[] = ExportOfferUtils.getIncotermsOptions()

  submitNegotiation = false
  negotiationFormGroup: FormGroup = this._formBuilder.group({
    negociationPrice: ['', Validators.required],
    negociationIncoterm: ['', Validators.required],
    negociationPallets: [null, Validators.required],
    negotiationPaymentAgainstBL: [null, Validators.required]
  })

  configureOffers() {
    if(this.exportOffers) {
      this.exportOffers.forEach(x => {
        x.requiredPallets = 0
        x.exportOffer.pictures = x.pictures
      })
    }
  }

  showOfferDetailDialog() {
    this.negotiationFormGroup.controls['negociationPrice'].setValue(this.selected?.exportOffer.listPrice)

    if(this.selected?.exportOffer.productCertificateTypes)
      this.selectedProductCertificateTypes = this.selected?.exportOffer.productCertificateTypes

    if(this.selected?.exportOffer.packagingTypes)
      this.selectedPackagingTypes = this.selected?.exportOffer.packagingTypes

    this.visibleOfferDetailDialog = true

    // else {
    //   this.selected = undefined
    //   this._messageService.add({ severity: 'warn', summary: 'Falta definir los parámetros', detail: 'Por favor diligencie los parámetros de origen y destino' })
    // }
  }

  importRequestId: number | undefined
  constructor(
    private _importRequestService: ImportRequestService,
    private _router: Router,
    private _messageService: MessageService,
    private _activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _offerNegotiationService: OfferNegotiationGateway,
    private _authService: AuthGateway,
    private _importOrderService: ImportOrderGateway,
    public translate: TranslateService
  ){
    this._activatedRoute.params.subscribe(
      params => {
        this.importRequestId = params['importRequestId']
      }
    )
  }

  ngOnInit(): void {
    this.getImportRequestInfo();
  }


  getImportRequestInfo(){
    this.loading = true;

    if(this.importRequestId){
      this._importRequestService.getById(this.importRequestId).subscribe(
        {
          next: (v) => {
            this.loading = false
            this.importRequestInfo = v
            this.exportOffers = v.offers
            this.configureOffers()
          },
          error: (e) => {
            this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudieron recuperar las ofertas.' });
          },
          complete: () => {
            this.loading = false
          }
        }
      )
    }
  }

  showOfferDetailDialog2(offer: ExportOfferInformation) {
    this.selected = offer
    this.showOfferDetailDialog()
  }

  closeOfferDetailDialog() {
    this.visibleOfferDetailDialog = false
  }

  createNegotiation() {
    this.submitNegotiation = true
    if(this.selected && this.negotiationFormGroup.valid) {

      if(this.pallets > this.selected.exportOffer.availablePallets) {
        this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'El número de pallets requerido es mayor al número de pallets disponibles.' })
        return
      }

      if(this.importRequestInfo){
        let negotiation: NewNegotiation = {
          pallets: this.negotiationFormGroup.controls["negociationPallets"].value,
          unitPrice: this.negotiationFormGroup.controls["negociationPrice"].value,
          typeSale: this.negotiationFormGroup.controls["negociationIncoterm"].value,
          exportOfferId: this.selected?.exportOffer?.id,
          username: this._authService.getUsername(),
          portToId: this.importRequestInfo.importRequest.portId,
          countryToId: this.importRequestInfo.importRequest.countryId,
          paymentAgainstBL: this.negotiationFormGroup.controls["negotiationPaymentAgainstBL"].value,
        }

        this._offerNegotiationService.create(negotiation).subscribe({
          next: (v) => {
            this._messageService.add({ severity: 'success', summary: 'Confirmed', detail: 'Se creó la negociación correctamente.' })
            this._router.navigate([(Routes.userBargaining)])
          },
          error: (e) => {
            console.log(e)
            this.loading = false
            this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudo crear la negociación. ' + e.error })
          },
          complete: () => console.info('Offer Negotiation create successfully')
        })
      }
    }
  }

  totalPrice = 0
  getTotalPriceOfferDialog(exportOffer: ExportOffer, event: any) {
    this.totalPrice = ExportOfferUtils.getTotalPrice(exportOffer, event.value)
  }

  getColor(color?: string) {
    return ExportOfferUtils.getColorName(Lang.SPANISH, color)
  }

  getIncoterms(value: string) {
    return ExportOfferUtils.getIncotermsName(value)
  }

  createOrder() {
    if(this.selected) {
      if(this.pallets > this.selected.exportOffer.availablePallets) {
        this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'El número de pallets requerido es mayor al número de pallets disponibles.' })
      }else{
        if(this.importRequestInfo){
          let offerRequest = {
            palletQuantity: this.pallets,
            exportOfferId: this.selected?.exportOffer?.id,
            importUsername: this._authService.getUsername(),
            countryToId: this.importRequestInfo.importRequest.countryId,
            portToId: this.importRequestInfo.importRequest.portId
          }

          this._importOrderService.create(offerRequest).subscribe({
            next: (v) => {
              this._messageService.add({ severity: 'success', summary: 'Confirmed', detail: 'Se creó la orden correctamente.' })
              this._router.navigate([(Routes.purchaseOrdersPage)])
            },
            error: (e) => {
              console.log(e)
              this.loading = false
              this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudo crear la órden. ' + e.error })
            },
            complete: () => console.info('Import Orders create successfully')
          })
        }
        else {
          this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'Seleccione un contenedor de destino.' })
        }
      }
    }
  }

  getErrorMessage(fieldName: string): string {
    if (this.negotiationFormGroup.controls[fieldName].hasError('required')) {
      return 'Este campo es requerido.';
    }
    if (this.negotiationFormGroup.controls[fieldName].hasError('email')) {
      return 'Formato de correo electrónico incorrecto.';
    }
    return '';
  }

  get availablePallets(): number {
    if(this.selected && this.selected.exportOffer && this.selected.exportOffer.availablePallets) {
      return this.selected.exportOffer.availablePallets - this.pallets
    }
    return 0
  }

  getTotalPrice(offerInfo: ExportOfferInformation, event: any) {
    const requiredPallets = event.value;
    offerInfo.totalPrice = ExportOfferUtils.getTotalPrice(offerInfo.exportOffer, requiredPallets)
  }

  getHeader(offer: ExportOffer): string {
    return offer.variety && offer.variety.species && offer.variety.species.name ? offer.variety.species.name.toUpperCase() : ''
  }

  getSubheader(offer: ExportOffer): string {
    return offer.variety && offer.variety.name ? offer.variety.name.toUpperCase() : ''
  }

  getNoAvailableLabel() {
    return this.translate.instant('ExportOffer.ExportOfferDetail.NoAvailable')
  }

  get paymentBLItems() {
    return ExportOfferUtils.getPaymentBLValues(this.negotiationFormGroup.controls['negociationIncoterm'].value);
  }

  get showPaymentBLPercent() {
    return ExportOfferUtils.showPaymentBLPercent(this.negotiationFormGroup.controls['negociationIncoterm'].value)
  }

  get finalPaymentPercent(): number {
    if(this.negotiationFormGroup.controls['negotiationPaymentAgainstBL'].value)
      return 100 - this.negotiationFormGroup.controls['negotiationPaymentAgainstBL'].value
    return 0
  }

  get paymentBLTitle() {
    if (this.negotiationFormGroup.controls['negotiationPaymentAgainstBL'].value)
      return ExportOfferUtils.getPaymentBLTitle(this.negotiationFormGroup.controls['negotiationPaymentAgainstBL'].value);
    return 'UserPages.UserBargaining.OpenTab.BargainingDetail.MakeCounterOfferModal.PaymentAgainstBLLabel'
  }
}
