<app-dashboard-layout>
  <app-content-body>
    <p-card>
      <div id="account" *ngIf="!success">
        <div class="grid align-items-center">
          <div class="col-12 md:col-4">
          </div>
          <div class="col-12 md:col-4 flex justify-content-center align-items-center">
              <img src="../../../../assets/img/fruzty-logo-slogan.png" width="250" height="111" alt=""/>
          </div>
          <div class="col-12 md:col-4">
            <div class="flex justify-content-end align-items-center">
              <app-switch-lang></app-switch-lang>
            </div>
          </div>
        </div>

        <div class="flex flex-wrap flex-column justify-content-center align-items-center">
          <h2>{{'SignupPage.JoinUsTitle' | translate }}</h2>
          <div style="margin-bottom: 40px;" class="flex flex-column justify-content-center align-items-center">
            <p>{{'SignupPage.HeaderText' | translate }}.</p>
            <p *ngIf="userJoinRequest">
              <span style="font-weight: bolder;">{{userJoinRequest.hostName}}</span> {{'SignupPage.From' | translate }} <span style="font-weight: bolder;">{{userJoinRequest.hostCompanyName}}</span> {{'SignupPage.JoinRequestText' | translate }}
            </p>
          </div>

          <form [formGroup]="formGroup">
            <div class="flex flex-wrap flex-column justify-content-center align-items-center">
              <div class="w-full md:w-8 lg:w-8 p-fluid formgrid grid">
                <div class="field col-12 flex justify-content-center flex-column">
                  <h2>{{'SignupPage.FormTitle' | translate }}</h2>
                  <p>{{'SignupPage.FillFormText' | translate }} {{'SignupPage.FieldSuggestion' | translate }}.</p>
                </div>
                <div class="field col-12 md:col-6 lg:col-6" style="margin-bottom: 60px;">
                  <label for="profileType" style="font-weight: bold;">{{'SignupPage.ProfileFieldLabel' | translate }} *</label>
                  <p-dropdown inputId="profileType" formControlName="profileType" [options]="profileTypeOptions" (onChange)="onProfileTypeChange()" placeholder="{{'SignupPage.ProfileFieldPlaceholder' | translate }}" optionLabel="label" optionValue="value"></p-dropdown>
                  <small *ngIf="submit && formGroup.controls['profileType'].invalid" class="p-error">{{getErrorMessage('profileType')}}</small>
                </div>
                <div class="field col-12 md:col-6 lg:col-6" style="margin-bottom: 60px;">
                  <label for="companyName" style="font-weight: bold;">{{'SignupPage.CompanyNameFieldLabel' | translate }} *</label>
                  <input id="companyName" formControlName="companyName" type="text" maxlength="50" required pInputText placeholder="{{'SignupPage.CompanyNameFieldPlaceholder' | translate }}"/>
                  <small *ngIf="submit && formGroup.controls['companyName'].invalid" class="p-error">{{getErrorMessage('companyName')}}</small>
                </div>
                <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
                  <label for="firstname" style="font-weight: bold;">{{'SignupPage.NameFieldLabel' | translate }} *</label>
                  <input id="firstname" formControlName="firstname" type="text" maxlength="120" required pInputText placeholder="{{'SignupPage.NameFieldPlaceholder' | translate }}"/>
                  <small *ngIf="submit && formGroup.controls['firstname'].invalid" class="p-error">{{getErrorMessage('firstname')}}</small>
                </div>
                <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
                  <label for="lastname" style="font-weight: bold;">{{'SignupPage.LastNameFieldLabel' | translate }} *</label>
                  <input id="lastname" formControlName="lastname" type="text" maxlength="120" required pInputText placeholder="{{'SignupPage.LastNameFieldPlaceholder' | translate }}"/>
                  <small *ngIf="submit && formGroup.controls['lastname'].invalid" class="p-error">{{getErrorMessage('lastname')}}</small>
                </div>
                <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
                  <label for="country" style="font-weight: bold;">{{'SignupPage.CountryFieldLabel' | translate }} *</label>
                  <p-dropdown id="country"
                    formControlName="country"
                    [options]="countries"
                    optionLabel="name"
                    optionValue="id"
                    [filter]="true"
                    filterBy="name"
                    [showClear]="true"
                    placeholder="{{'SignupPage.CountryFieldPlaceholder' | translate }}"
                    (onChange)="onChangeCountry()">
                    <ng-template pTemplate="selectedItem">
                      <div class="flex align-items-center gap-2" *ngIf="selectedCountry">
                        <img [src]="'../../../../assets/img/flags/' + selectedCountry.code + '.png'" style="width: 18px" alt="flag"/>
                        <div>{{ selectedCountry | countryDescription }}</div>
                      </div>
                    </ng-template>
                    <ng-template let-country pTemplate="item">
                      <div class="flex align-items-center gap-2">
                        <img [src]="'../../../../assets/img/flags/' + country.code + '.png'" style="width: 18px" alt="flag"/>
                        <div>{{ country | countryDescription }}</div>
                      </div>
                    </ng-template>
                  </p-dropdown>
                  <small *ngIf="submit && formGroup.controls['country'].invalid" class="p-error">{{getErrorMessage('country')}}</small>
                </div>
                <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
                  <label for="city" style="font-weight: bold;">{{'SignupPage.CityFieldLabel' | translate }} *</label>
                  <input id="city" formControlName="city" type="text" required pInputText placeholder="{{'SignupPage.CityFieldPlaceholder' | translate }}"/>
                  <small *ngIf="submit && formGroup.controls['city'].invalid" class="p-error">{{getErrorMessage('city')}}</small>
                </div>
                <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
                  <label for="address" style="font-weight: bold;">{{'SignupPage.AddressFieldLabel' | translate }} *</label>
                  <input id="address" formControlName="address" type="text" required pInputText maxlength="50" placeholder="{{'SignupPage.AddressFieldPlaceholder' | translate }}"/>
                  <small *ngIf="submit && formGroup.controls['address'].invalid" class="p-error">{{getErrorMessage('address')}}</small>
                </div>
                <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
                  <label for="phone" style="font-weight: bold;">{{'SignupPage.PhoneFieldLabel' | translate }} *</label>
                  <input id="phone" formControlName="phone" type="text" required pInputText maxlength="50" placeholder="{{'SignupPage.PhoneFieldPlaceholder' | translate }}"/>
                  <small *ngIf="submit && formGroup.controls['phone'].invalid" class="p-error">{{getErrorMessage('phone')}}</small>
                </div>
                <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;" *ngIf="!userJoinRequestId">
                  <label for="invitationCode" style="font-weight: bold;">{{'SignupPage.InvitationCodeFieldLabel' | translate }} *</label>
                  <input id="invitationCode" formControlName="invitationCode" type="text" required pInputText maxlength="50" placeholder="{{'SignupPage.InvitationCodeFieldPlaceholder' | translate }}"/>
                  <small *ngIf="submit && formGroup.controls['invitationCode'].invalid" class="p-error">{{getErrorMessage('invitationCode')}}</small>
                  <small>{{ 'SignupPage.MessageInvitationCodeSignup' | translate }}</small>
                </div>
                <div class="field col-12">
                  <p-divider></p-divider>
                </div>
                <div class="field col-12 xl:col-6 lg:col-6">
                  <label for="email" style="font-weight: bold;">{{'SignupPage.EmailFieldLabel' | translate }} *</label>
                  <input id="email" formControlName="email" type="text" required pInputText maxlength="150" placeholder="{{'SignupPage.EmailFieldPlaceholder' | translate }}"/>
                  <small *ngIf="submit && formGroup.controls['email'].invalid" class="p-error">{{getErrorMessage('email')}}</small>
                  <small>{{ 'SignupPage.MessageEmailSignup'  | translate }}</small>
                </div>
                <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
                  <label for="email2" style="font-weight: bold;">{{'SignupPage.ConfirmEmailFieldLabel' | translate }} *</label>
                  <input id="email2" formControlName="email2" type="text" required pInputText maxlength="150" placeholder="{{'SignupPage.ConfirmEmailFieldPlaceholder' | translate }}"/>
                  <small *ngIf="submit && formGroup.controls['email2'].invalid" class="p-error">{{getErrorMessage('email2')}}&nbsp;</small>
                  <small *ngIf="submit && emaildMatchError" class="p-error">{{getEmailMissMatchMessage()}}</small>
                  <small>{{ 'SignupPage.MessageEmailSignup'  | translate }}</small>
                </div>
                <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
                  <label for="password" style="font-weight: bold;">{{'SignupPage.PasswordFieldLabel' | translate }} *</label>
                  <p-password id="password"
                    formControlName="password"
                    [toggleMask]="true"
                    [feedback]="true"
                    strongLabel="{{'SignupPage.PasswordStrongLabel' | translate }}"
                    weakLabel="{{'SignupPage.PasswordWeakLabel' | translate }}"
                    mediumLabel="{{'SignupPage.PasswordMediumLabel' | translate }}"
                    [appendTo]="'body'">
                    <ng-template pTemplate="header">
                      <h6>{{'SignupPage.PasswordTitle' | translate }}</h6>
                    </ng-template>
                    <ng-template pTemplate="footer">
                        <p-divider></p-divider>
                        <p class="mt-2">{{'SignupPage.PasswordSubtitle' | translate }}</p>
                        <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                          <li>{{'SignupPage.PasswordSuggestion1' | translate }}</li>
                          <li>{{'SignupPage.PasswordSuggestion2' | translate }}</li>
                          <li>{{'SignupPage.PasswordSuggestion3' | translate }}</li>
                          <li>{{'SignupPage.PasswordSuggestion4' | translate }}</li>
                        </ul>
                    </ng-template>
                  </p-password>
                  <small *ngIf="submit && formGroup.controls['password'].invalid" class="p-error">{{getErrorMessage('password')}}</small>
                </div>
                <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 60px;">
                  <label for="password2" style="font-weight: bold;">{{'SignupPage.ConfirmPasswordFieldLabel' | translate }} *</label>
                  <p-password id="password2"
                    formControlName="password2"
                    [toggleMask]="true"
                    [feedback]="true"
                    strongLabel="{{'SignupPage.PasswordStrongLabel' | translate }}"
                    weakLabel="{{'SignupPage.PasswordWeakLabel' | translate }}"
                    mediumLabel="{{'SignupPage.PasswordMediumLabel' | translate }}"
                    [appendTo]="'body'">
                    <ng-template pTemplate="header">
                      <h6>{{'SignupPage.ConfirmPasswordTitle' | translate }}</h6>
                    </ng-template>
                    <ng-template pTemplate="footer">
                        <p-divider></p-divider>
                        <p class="mt-2">{{'SignupPage.PasswordSubtitle' | translate }}</p>
                        <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                          <li>{{'SignupPage.PasswordSuggestion1' | translate }}</li>
                          <li>{{'SignupPage.PasswordSuggestion2' | translate }}</li>
                          <li>{{'SignupPage.PasswordSuggestion3' | translate }}</li>
                          <li>{{'SignupPage.PasswordSuggestion4' | translate }}</li>
                        </ul>
                    </ng-template>
                  </p-password>
                  <small *ngIf="submit && formGroup.controls['password2'].invalid" class="p-error">{{getErrorMessage('password2')}}&nbsp;</small>
                  <small *ngIf="submit && passwordMatchError" class="p-error">{{getPasswordMissMatchMessage()}}</small>
                </div>
              </div>
            </div>
          </form>

          <form [formGroup]="consigneeNotifyFormGroup" *ngIf="userJoinRequest && formGroup.controls['profileType'].value === 2">
            <div class="flex flex-wrap flex-column justify-content-center align-items-center">
              <div class="w-full md:w-8 lg:w-8 p-fluid formgrid grid">
                <div class="field col-12">
                  <p-divider></p-divider>
                </div>
                <div class="field col-12 flex justify-content-center flex-column">
                  <h2>{{'SignupPage.ConsigneeForm.FormTitle' | translate }}</h2>
                  <p>{{'SignupPage.ConsigneeForm.FormText' | translate }} {{'SignupPage.FieldSuggestion' | translate }}.</p>
                </div>
                <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 60px;">
                  <label for="consigneeCompanyName" style="font-weight: bold;">{{'SignupPage.ConsigneeForm.NameLabel' | translate }} *</label>
                  <input id="consigneeCompanyName" formControlName="consigneeCompanyName" type="text" required pInputText placeholder="{{'SignupPage.ConsigneeForm.NameLabel' | translate }}"/>
                  <small *ngIf="submit && consigneeNotifyFormGroup.controls['consigneeCompanyName'].invalid" class="p-error">{{getConsigneeNotifyErrorMessage('consigneeCompanyName')}}</small>
                </div>
                <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 60px;">
                  <label for="consigneeVat" style="font-weight: bold;">{{'SignupPage.ConsigneeForm.VatIdLabel' | translate }} *</label>
                  <input id="consigneeVat" formControlName="consigneeVat" type="text" required pInputText placeholder="{{'SignupPage.ConsigneeForm.VatIdLabel' | translate }}"/>
                  <small *ngIf="submit && consigneeNotifyFormGroup.controls['consigneeVat'].invalid" class="p-error">{{getConsigneeNotifyErrorMessage('consigneeVat')}}</small>
                </div>
                <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 60px;">
                  <label for="consigneeContactCountryId" style="font-weight: bold;">{{'SignupPage.ConsigneeForm.CountryLabel' | translate }} *</label>
                  <p-dropdown id="consigneeContactCountryId"
                  formControlName="consigneeContactCountryId"
                  [options]="countries"
                  optionLabel="name"
                  optionValue="id"
                  [filter]="true"
                  filterBy="name"
                  [showClear]="true"
                  placeholder="{{'SignupPage.ConsigneeForm.CountryPlaceholder' | translate }}"
                  (onChange)="onChangeCountryConsignee()">
                  <ng-template pTemplate="selectedItem">
                    <div class="flex align-items-center gap-2" *ngIf="selectedCountryConsignee">
                      <img [src]="'../../../../assets/img/flags/' + selectedCountryConsignee.code + '.png'" style="width: 18px" alt="flag"/>
                      <div>{{ selectedCountryConsignee | countryDescription }}</div>
                    </div>
                  </ng-template>
                  <ng-template let-country pTemplate="item">
                    <div class="flex align-items-center gap-2">
                      <img [src]="'../../../../assets/img/flags/' + country.code + '.png'" style="width: 18px" alt="flag"/>
                      <div>{{ country | countryDescription }}</div>
                    </div>
                  </ng-template>
                </p-dropdown>
                  <small *ngIf="submit && consigneeNotifyFormGroup.controls['consigneeContactCountryId'].invalid" class="p-error">{{getConsigneeNotifyErrorMessage('consigneeContactCountryId')}}</small>
                </div>
                <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 60px;">
                  <label for="consigneeContactName" style="font-weight: bold;">{{'SignupPage.ConsigneeForm.ContactNameLabel' | translate }} *</label>
                  <input id="consigneeContactName" formControlName="consigneeContactName" type="text" required pInputText maxlength="200" placeholder="{{'SignupPage.ConsigneeForm.ContactNameLabel' | translate }}" maxlength="150"/>
                  <small *ngIf="submit && consigneeNotifyFormGroup.controls['consigneeContactName'].invalid" class="p-error">{{getConsigneeNotifyErrorMessage('consigneeContactName')}}</small>
                </div>
                <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 60px;">
                  <label for="consigneeContactAddress" style="font-weight: bold;">{{'SignupPage.ConsigneeForm.ContactAddressLabel' | translate }} *</label>
                  <input id="consigneeContactAddress" formControlName="consigneeContactAddress" type="text" required pInputText maxlength="100" placeholder="{{'SignupPage.ConsigneeForm.ContactAddressLabel' | translate }}" maxlength="150"/>
                  <small *ngIf="submit && consigneeNotifyFormGroup.controls['consigneeContactAddress'].invalid" class="p-error">{{getConsigneeNotifyErrorMessage('consigneeContactAddress')}}</small>
                </div>
                <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 60px;">
                  <label for="consigneeContactCity" style="font-weight: bold;">{{'SignupPage.ConsigneeForm.ContactCityLabel' | translate }} *</label>
                  <input id="consigneeContactCity" formControlName="consigneeContactCity" type="text" required pInputText maxlength="100" placeholder="{{'SignupPage.ConsigneeForm.ContactCityLabel' | translate }}"/>
                  <small *ngIf="submit && consigneeNotifyFormGroup.controls['consigneeContactCity'].invalid" class="p-error">{{getConsigneeNotifyErrorMessage('consigneeContactCity')}}</small>
                </div>
                <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 60px;">
                  <label for="consigneeContactEmail" style="font-weight: bold;">{{'SignupPage.ConsigneeForm.ContactEmailLabel' | translate }} *</label>
                  <input id="consigneeContactEmail" formControlName="consigneeContactEmail" type="text" required pInputText maxlength="100" placeholder="{{'SignupPage.ConsigneeForm.ContactEmailLabel' | translate }}" maxlength="150"/>
                  <small *ngIf="submit && consigneeNotifyFormGroup.controls['consigneeContactEmail'].invalid" class="p-error">{{getConsigneeNotifyErrorMessage('consigneeContactEmail')}}</small>
                </div>
                <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 60px;">
                  <label for="consigneeContactPhone" style="font-weight: bold;">{{'SignupPage.ConsigneeForm.ContactPhoneLabel' | translate }} *</label>
                  <input id="consigneeContactPhone" formControlName="consigneeContactPhone" type="text" required pInputText maxlength="30" placeholder="{{'SignupPage.ConsigneeForm.ContactPhoneLabel' | translate }}"/>
                  <small *ngIf="submit && consigneeNotifyFormGroup.controls['consigneeContactPhone'].invalid" class="p-error">{{getConsigneeNotifyErrorMessage('consigneeContactPhone')}}</small>
                </div>
              </div>
            </div>
            <div class="flex flex-wrap flex-column justify-content-center align-items-center" style="margin-bottom: 50px;">
              <div class="w-full md:w-8 lg:w-8 p-fluid formgrid grid">
                <div class="field col-12">
                  <p-divider></p-divider>
                </div>
                <div class="field col-12 flex justify-content-center flex-column">
                  <h2>{{'SignupPage.NotifyForm.FormTitle' | translate }}</h2>
                  <p>{{'SignupPage.NotifyForm.FormText' | translate }} {{'SignupPage.FieldSuggestion' | translate }}.</p>
                </div>
                <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 60px;">
                  <label for="notifyCompanyName" style="font-weight: bold;">{{'SignupPage.NotifyForm.NameLabel' | translate }} *</label>
                  <input id="notifyCompanyName" formControlName="notifyCompanyName" type="text" required pInputText maxlength="200" placeholder="{{'SignupPage.NotifyForm.NameLabel' | translate }}"/>
                  <small *ngIf="submit && consigneeNotifyFormGroup.controls['notifyCompanyName'].invalid" class="p-error">{{getConsigneeNotifyErrorMessage('notifyCompanyName')}}</small>
                </div>
                <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 60px;">
                  <label for="notifyVat" style="font-weight: bold;">{{'SignupPage.NotifyForm.VatIdLabel' | translate }} *</label>
                  <input id="notifyVat" formControlName="notifyVat" type="text" required pInputText placeholder="{{'SignupPage.NotifyForm.VatIdLabel' | translate }}"/>
                  <small *ngIf="submit && consigneeNotifyFormGroup.controls['notifyVat'].invalid" class="p-error">{{getConsigneeNotifyErrorMessage('notifyVat')}}</small>
                </div>
                <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 60px;">
                  <label for="notifyContactCountry" style="font-weight: bold;">{{'SignupPage.NotifyForm.CountryLabel' | translate }} *</label>
                  <p-dropdown id="notifyContactCountryId"
                    formControlName="notifyContactCountryId"
                    [options]="countries"
                    optionLabel="name"
                    optionValue="id"
                    [filter]="true"
                    filterBy="name"
                    [showClear]="true"
                    placeholder="{{'SignupPage.NotifyForm.CountryPlaceholder' | translate }}"
                    (onChange)="onChangeCountryNotify()">
                    <ng-template pTemplate="selectedItem">
                      <div class="flex align-items-center gap-2" *ngIf="selectedCountryNotify">
                        <img [src]="'../../../../assets/img/flags/' + selectedCountryNotify.code + '.png'" style="width: 18px" alt="flag"/>
                        <div>{{ selectedCountryNotify | countryDescription }}</div>
                      </div>
                    </ng-template>
                    <ng-template let-country pTemplate="item">
                      <div class="flex align-items-center gap-2">
                        <img [src]="'../../../../assets/img/flags/' + country.code + '.png'" style="width: 18px" alt="flag"/>
                        <div>{{ country | countryDescription }}</div>
                      </div>
                    </ng-template>
                  </p-dropdown>
                  <small *ngIf="submit && consigneeNotifyFormGroup.controls['notifyContactCountryId'].invalid" class="p-error">{{getConsigneeNotifyErrorMessage('notifyContactCountryId')}}</small>
                </div>
                <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 60px;">
                  <label for="notifyContactName" style="font-weight: bold;">{{'SignupPage.NotifyForm.ContactNameLabel' | translate }} *</label>
                  <input id="notifyContactName" formControlName="notifyContactName" type="text" required pInputText placeholder="{{'SignupPage.NotifyForm.ContactNameLabel' | translate }}" maxlength="150"/>
                  <small *ngIf="submit && consigneeNotifyFormGroup.controls['notifyContactName'].invalid" class="p-error">{{getConsigneeNotifyErrorMessage('notifyContactName')}}</small>
                </div>
                <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 60px;">
                  <label for="notifyContactAddress" style="font-weight: bold;">{{'SignupPage.NotifyForm.ContactAddressLabel' | translate }} *</label>
                  <input id="notifyContactAddress" formControlName="notifyContactAddress" type="text" required pInputText placeholder="{{'SignupPage.NotifyForm.ContactAddressLabel' | translate }}" maxlength="150"/>
                  <small *ngIf="submit && consigneeNotifyFormGroup.controls['notifyContactAddress'].invalid" class="p-error">{{getConsigneeNotifyErrorMessage('notifyContactAddress')}}</small>
                </div>
                <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 60px;">
                  <label for="notifyContactCity" style="font-weight: bold;">{{'SignupPage.NotifyForm.ContactCityLabel' | translate }} *</label>
                  <input id="notifyContactCity" formControlName="notifyContactCity" type="text" required pInputText placeholder="{{'SignupPage.NotifyForm.ContactCityLabel' | translate }}"/>
                  <small *ngIf="submit && consigneeNotifyFormGroup.controls['notifyContactCity'].invalid" class="p-error">{{getConsigneeNotifyErrorMessage('notifyContactCity')}}</small>
                </div>
                <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 60px;">
                  <label for="notifyContactEmail" style="font-weight: bold;">{{'SignupPage.NotifyForm.ContactEmailLabel' | translate }} *</label>
                  <input id="notifyContactEmail" formControlName="notifyContactEmail" type="text" required pInputText placeholder="{{'SignupPage.NotifyForm.ContactEmailLabel' | translate }}" maxlength="150"/>
                  <small *ngIf="submit && consigneeNotifyFormGroup.controls['notifyContactEmail'].invalid" class="p-error">{{getConsigneeNotifyErrorMessage('notifyContactEmail')}}</small>
                </div>
                <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 60px;">
                  <label for="notifyContactPhone" style="font-weight: bold;">{{'SignupPage.NotifyForm.ContactPhoneLabel' | translate }} *</label>
                  <input id="notifyContactPhone" formControlName="notifyContactPhone" type="text" required pInputText placeholder="{{'SignupPage.NotifyForm.ContactPhoneLabel' | translate }}"/>
                  <small *ngIf="submit && consigneeNotifyFormGroup.controls['notifyContactPhone'].invalid" class="p-error">{{getConsigneeNotifyErrorMessage('notifyContactPhone')}}</small>
                </div>
              </div>
            </div>
          </form>

          <div class="w-full md:w-8 lg:w-8 p-fluid grid">
            <div class="col-6">
              <p-button (onClick)="goToHome()" label="{{'SignupPage.ReturnButton' | translate }}" styleClass="p-button-outlined"/>
            </div>
            <div class="col-6">
              <p-button [loading]="loading" (onClick)="submitForm()" label="{{'SignupPage.SubmitButton' | translate }}"/>
            </div>
          </div>
        </div>
      </div>
      <div id="success" *ngIf="success">
        <div class="flex align-items-center justify-content-center flex-column">
          <img src="../../../../assets/img/fruzty-logo-slogan.png" width="250" height="111" alt=""/>
          <i class="pi pi-check-circle" style="font-size: 2.5rem"></i>
          <h1>{{'SignupPage.WelcomeTitle' | translate }}</h1>
          <h2>{{'SignupPage.WelcomeText' | translate }}</h2>
          <p-button label="{{'SignupPage.AcceptButton' | translate }}" (onClick)="goToLogin()"></p-button>
        </div>
      </div>
    </p-card>
  </app-content-body>
</app-dashboard-layout>
<app-footer-home></app-footer-home>
<p-toast></p-toast>


