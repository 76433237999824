import { Component } from '@angular/core';

@Component({
  selector: 'app-frozen-fruit-export-offer-add-cart',
  templateUrl: './frozen-fruit-export-offer-add-cart.component.html',
  styleUrls: ['./frozen-fruit-export-offer-add-cart.component.css']
})
export class FrozenFruitExportOfferAddCartComponent {

}
