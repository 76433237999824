import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { CustomAgentGateway } from 'src/app/application/gateway/CustomAgentGateway';
import { CompanyService } from 'src/app/application/service/CompanyService';
import { AssignCustomAgent, ProfileCompayList } from 'src/app/domain/models/Company';
import { CustomAgentReviewItemList } from 'src/app/domain/models/CustomAgentReview';
import { BasicUser } from 'src/app/domain/models/User';
import { Routes } from 'src/app/infrastructure/routes';

@Component({
  selector: 'app-custom-agent-reviews',
  templateUrl: './custom-agent-reviews.component.html',
  styleUrls: ['./custom-agent-reviews.component.css'],
  providers: [MessageService]
})
export class CustomAgentReviewsComponent implements OnInit {

  tabMenuItems: MenuItem[] = []
  activeItem: MenuItem = {}

  loading: boolean = false
  visibleAssignDialog: boolean = false

  profile: BasicUser | undefined
  reviews: CustomAgentReviewItemList[] = []
  customAgents: ProfileCompayList[] = []
  selectedCustomAgentOrder: any | undefined
  selectedCustomAgentGuid: string | undefined

  constructor(private _router: Router,
    private _messageService: MessageService,
    private _authService: AuthGateway,
    public _companyService: CompanyService,
    public translate: TranslateService,
    private _customAgentService: CustomAgentGateway) {
  }

  ngOnInit(): void {
    this.profile = this._authService.getUser()

    this.tabMenuItems = [
      { label: 'Pending', command: () =>  this._router.navigate(['/'+Routes.customAgentUserReviews]) },
      { label: 'Reviewed', command: () =>  this._router.navigate(['/'+Routes.customAgentUserOriginReviewed]) }
    ]

    this.activeItem = this.tabMenuItems[0]

    this.loadCustomAgents()
    this.loadOriginReviews()

  }

  loadCustomAgents() {
    this._customAgentService.getCustomAgentsByAdmin().subscribe({
      next: (v) => {
        this.customAgents = v
      },
      error: (e) => {
        let errorMessage = ''
        const lang = this.translate.currentLang
        switch(lang) {
          case 'es':
            errorMessage = e.error.messageEs
            break
          case 'en':
            errorMessage = e.error.messageEn
            break
          default:
            errorMessage = this.translate.instant('CustomAgentPage.Messages.ErrorLoadCustomAgents')
        }
        console.log(e)
        this._messageService.add({ severity: 'error', summary: this.translate.instant('MessageService.Summary.FailedAction'), detail: errorMessage });
      },
      complete: () =>  {
        this.loading = false
        console.info('Profile query successfully')
      }
    })
  }

  loadOriginReviews() {
    this._customAgentService.getAllByStatusAndReviewType(1).subscribe({
      next: (v) => {
        this.reviews = v.filter(x => x.customAgentDocuments === null || x.customAgentDocuments?.length === 0)
        this.loading = false
      },
      error: (e) => {
        let errorMessage = ''
        const lang = this.translate.currentLang
        switch(lang) {
          case 'es':
            errorMessage = e.error.messageEs
            break
          case 'en':
            errorMessage = e.error.messageEn
            break
          default:
            errorMessage = this.translate.instant('CustomAgentPage.Messages.ErrorLoadReviews')
        }
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: errorMessage });

        this.loading = false
        console.log(e)
      },
      complete: () =>  {
        this.loading = false
        console.info('Reviews query successfully')
      }
    })
  }

  goToReviewDocuments(review: CustomAgentReviewItemList) {
    if(review)
      this._router.navigate(['/' + Routes.customAgentUserOriginReview, review.guid])
  }

  openDialog(review: CustomAgentReviewItemList) {
    this.selectedCustomAgentOrder = undefined
    this.selectedCustomAgentGuid = review.guid
    this.visibleAssignDialog = true
  }

  assignUser() {
    if (this.selectedCustomAgentOrder && this.selectedCustomAgentGuid) {
      const assignCustomAgent: AssignCustomAgent = {
        CustomAgentReviewGuid: this.selectedCustomAgentGuid,
        profileId: this.selectedCustomAgentOrder
      }
      this._customAgentService.assignCustomAgent(assignCustomAgent).subscribe({
        next: (v) => {
          this._messageService.add({ severity: 'success', summary: this.translate.instant('MessageService.Summary.SuccessAction'), detail: this.translate.instant('CustomAgentPage.Messages.SuccessAssignCustomAgent') });
          this.visibleAssignDialog = false
          this.loadOriginReviews()
        },
        error: (e) => {
          let errorMessage = ''
          const lang = this.translate.currentLang
          switch(lang) {
            case 'es':
              errorMessage = e.error.messageEs
              break
            case 'en':
              errorMessage = e.error.messageEn
              break
            default:
              errorMessage = e.error.messageEn
          }
          if(e.status === 500){
            this._messageService.add({ severity: 'error', summary: this.translate.instant('MessageService.Summary.FailedAction'), detail: this.translate.instant('CustomAgentPage.Messages.ErrorAssignCustomAgent') })
          }
          else {
            this._messageService.add({ severity: 'error', summary: this.translate.instant('MessageService.Summary.FailedAction'), detail: errorMessage });
          }
          this.loading = false
          console.log(e)
        },
        complete: () =>  {
          this.loading = false
          console.info('Custom Agent query successfully')
        }
      })
    }
    
  }

  messagePending(review: any): string {
    if(review?.messages)
      return review?.messages > 0 ? review?.messages?.toString() : '';
    return ''
  }

}