import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { CountryGateway } from 'src/app/application/gateway/CountryGateway';
import { ProfileGateway } from 'src/app/application/gateway/ProfileGateway';
import { Country } from 'src/app/domain/models/Country';
import { ProfileCreateRequest } from 'src/app/domain/models/Profile';
import { Routes } from 'src/app/infrastructure/routes';
import { ProfileUtils } from 'src/app/ui/util/profile-utils';

@Component({
  selector: 'app-admin-create-user',
  templateUrl: './admin-create-user.component.html',
  styleUrls: ['./admin-create-user.component.css'],
  providers: [MessageService]
})
export class AdminCreateUserComponent implements OnInit {

  profileTypeOptions: any[] = ProfileUtils.getProfileTypeOptions()
  countries: Country[] = []

  loading: boolean = false
  submit: boolean = false
  success: boolean = false

  formGroup: FormGroup = this._formBuilder.group({
    profileType: ['', Validators.required],
    contactName: ['', Validators.required],
    contactLastName: [null, Validators.required],
    contactCountryId: [null, Validators.required],
    contactCity: ['', null],
    contactAddress: [''],
    contactPhone: [''],
    contactEmail: ['', [Validators.required, Validators.email]],
    vat: [''],
    companyName: [''],
    sagCode: [null],
    legalRepresentativeName: [''],
    legalRepresentativeNumber: [null],
    hasTwoAuthetication: [false],
    isVerified: [false],
    isSendEmail: [true]
  })

  constructor(private _router: Router,
    private _formBuilder: FormBuilder,
    private _messageService: MessageService,
    private _countryService: CountryGateway,
    private _profileService: ProfileGateway) {

  }

  ngOnInit(): void {
    this.loadCountries()
  }

  loadCountries(): void {
    this._countryService.getAll().subscribe({
      next: (v) => {
        this.countries = v
      },
      error: (e) => {
        this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudo recuperar la lista de países.' });
        console.log(e)
      },
      complete: () => console.info('Country query successfully')
    })
  }

  save() {
    this.submit = true
    console.log(this.formGroup.valid)
    if(this.formGroup.valid) {
      const profile: ProfileCreateRequest = {
        name: this.formGroup.controls['contactName'].value,
        lastName: this.formGroup.controls['contactLastName'].value,
        email: this.formGroup.controls['contactEmail'].value,
        companyName: this.formGroup.controls['companyName'].value,
        contactCountryId: this.formGroup.controls['contactCountryId'].value,
        type: this.formGroup.controls['profileType'].value,
        contactAddress: this.formGroup.controls['contactAddress'].value,
        contactCity: this.formGroup.controls['contactCity'].value,
        isSendEmail: this.formGroup.controls['isSendEmail'].value,
        contactPhone: this.formGroup.controls['contactPhone'].value,
        legalRepresentativeName: this.formGroup.controls['legalRepresentativeName'].value,
        legalRepresentativeNumber: this.formGroup.controls['legalRepresentativeNumber'].value ? this.formGroup.controls['legalRepresentativeNumber'].value.toString() : '',
        vat: this.formGroup.controls['vat'].value,
        sagCode: this.formGroup.controls['sagCode'].value ? this.formGroup.controls['sagCode'].value.toString() : ''
      }

      this.loading = true
      this._profileService.create(profile).subscribe({
        next: (v) => {
          this.success = true
          this.loading = false
          this.submit = false
          this._messageService.add({ severity: 'success', summary: 'Acción exitosa', detail: 'El usuario fue creado con éxito.' });
        },
        error: (e) => {
          this.loading = false
          this.submit = false
          this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudo crear el usuario. ' + e.error.messageEs });
          console.log(e)
        },
        complete: () => console.info('Profile created successfully')
      })
    }else{
      this.findInvalidControls()
    }
  }

  cancel(): void {
    this._router.navigate(['/' + Routes.adminUsers])
  }

  getErrorMessage(fieldName: string): string {
    if (this.formGroup.controls[fieldName].hasError('required')) {
      return 'Este campo es requerido.';
    }
    if (this.formGroup.controls[fieldName].hasError('email')) {
      return 'Formato de correo electrónico incorrecto.';
    }
    return '';
  }

  goToUsers() {
    this._router.navigate(['/' + Routes.adminUsers])
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.formGroup.controls;
    for (const name in controls) {
        if (controls[name].invalid) {
            console.log(name)
            invalid.push(name);
        }
    }
    return invalid;
}
}
