<p-table [value]="pictures" [loading]="loading">
  <ng-template pTemplate="body" let-picture let-rowIndex="rowIndex">
    <tr>
      <td>
        <p-fileUpload
          #imagesUploader
          mode="basic"
          chooseLabel="{{'UserPages.UserExportOffers.EditExportOffer.UploadButton' | translate}}"
          [fileLimit]="1"
          [auto]="true"
          accept=".jpg, .jpeg, .png"
          [maxFileSize]="3000000"
          (onSelect)="onSelectImage($event, imagesUploader, rowIndex)"
          [disabled]="picture.url"></p-fileUpload>
      </td>
      <td>
        <img [src]="getImageUrl(picture)" style="border-radius: 7px; width: 100%; max-width: 100px; height: auto;" alt="" *ngIf="getImageUrl(picture)"/>
      </td>
      <td>
        <span *ngIf="getImageName(picture)">{{getImageName(picture)}}</span>
      </td>
      <td>
        <div class="flex justify-content-end align-items-center gap-4">
          <p-button icon="pi pi-times" (click)="onRemoveImage(rowIndex)" *ngIf="picture.url"></p-button>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
