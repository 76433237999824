<app-user-dashboard [userMenuActiveItemIndex]="0" [activeItem]="'notifications'">
  <p-card [style]="{'min-height':'400px'}">
    <ng-template pTemplate="header">
      <div class="flex justify-content-between flex-wrap align-items-center" style="padding: 15px;">
        <h2>{{'UserPages.Notifications.UserNotificationsConfig.PageTitle' | translate}}</h2>
      </div>
    </ng-template>
    <div class="flex justify-content-start flex-wrap align-items-center" style="margin-bottom: 30px; padding: 15px;">
      {{'UserPages.Notifications.UserNotificationsConfig.MarkNotificationText' | translate}}:
    </div>
    <div class="card flex justify-content-start align-items-center" style="padding: 15px">
      <div class="formgrid grid">
        <div class="field col-6" style="margin-bottom: 40px;">{{'UserPages.Notifications.UserNotificationsConfig.ReceiveExportNotificationsText' | translate}}</div>
        <div class="field col-6" style="margin-bottom: 40px;">
          <p-inputSwitch [(ngModel)]="newProducts"></p-inputSwitch>
        </div>
        <div class="field col-6" style="margin-bottom: 40px;">{{'UserPages.Notifications.UserNotificationsConfig.ReceiveImportNotificationsText' | translate}}</div>
        <div class="field col-6" style="margin-bottom: 40px;">
          <p-inputSwitch [(ngModel)]="newRequeriments"></p-inputSwitch>
        </div>
        <div class="field col-6" style="margin-bottom: 40px;"></div>
        <div class="field col-6 p-fluid" style="margin-bottom: 40px;">
          <p-button [loading]="loading" label="{{'UserPages.Notifications.UserNotificationsConfig.SaveButton' | translate}}" (click)="save()"></p-button>
        </div>
      </div>
    </div>
  </p-card>
</app-user-dashboard>
<p-toast></p-toast>
