<div class="surface-section px-4 py-8 md:px-6 lg:px-8 flex flex-column align-items-center">
  <div class="flex justify-content-end  w-full">
    <app-switch-lang></app-switch-lang>
  </div>
  <img src="../../../../assets/img/fruzty-logo-slogan.png" width="250" height="111" alt=""/>
  <h1>{{'SignupQCCompanyPage.Title' | translate }}</h1>
  <p>{{'SignupQCCompanyPage.Text1' | translate }}</p>

  <div style="margin-top: 40px;" *ngIf="!success">
    <form [formGroup]="formGroup">
      <div class="flex align-items-center flex-column">
        <div class="w-full md:w-8 lg:w-8 p-fluid formgrid grid">
          <div class="field col-12 md:col-6 lg:col-6" style="margin-bottom: 40px;">
            <label for="companyName" style="font-weight: bold;">{{'SignupQCCompanyPage.CompanyNameFieldLabel' | translate }} *</label>
            <input id="companyName" formControlName="companyName" type="text" maxlength="50" required pInputText placeholder="{{'SignupQCCompanyPage.CompanyNameFieldPlaceholder' | translate }}"/>
            <small *ngIf="submit && formGroup.controls['companyName'].invalid" class="p-error">{{getErrorMessage(formGroup, 'companyName')}}</small>
          </div>
          <div class="field col-12 md:col-6 lg:col-6" style="margin-bottom: 40px;">
            <label for="vat" style="font-weight: bold;">{{'SignupQCCompanyPage.VATID' | translate }}</label>
            <input id="vat" formControlName="vat" type="text" maxlength="50" pInputText placeholder="{{'SignupQCCompanyPage.VATID' | translate }}"/>
            <small *ngIf="submit && formGroup.controls['vat'].invalid" class="p-error">{{getErrorMessage(formGroup, 'vat')}}</small>
          </div>
          <div class="field col-12 md:col-6 lg:col-6" style="margin-bottom: 40px;">
            <label for="name" style="font-weight: bold;">{{'SignupQCCompanyPage.NameFieldLabel' | translate }} *</label>
            <input id="name" formControlName="name" type="text" maxlength="120" required pInputText placeholder="{{'SignupQCCompanyPage.NameFieldPlaceholder' | translate }}"/>
            <small *ngIf="submit && formGroup.controls['name'].invalid" class="p-error">{{getErrorMessage(formGroup, 'name')}}</small>
          </div>
          <div class="field col-12 md:col-6 lg:col-6" style="margin-bottom: 40px;">
            <label for="lastname" style="font-weight: bold;">{{'SignupQCCompanyPage.LastNameFieldLabel' | translate }} *</label>
            <input id="lastname" formControlName="lastname" type="text" maxlength="120" required pInputText placeholder="{{'SignupQCCompanyPage.LastNameFieldPlaceholder' | translate }}"/>
            <small *ngIf="submit && formGroup.controls['lastname'].invalid" class="p-error">{{getErrorMessage(formGroup, 'lastname')}}</small>
          </div>
          <div class="field col-12 md:col-6 lg:col-6" style="margin-bottom: 40px;">
            <label for="country" style="font-weight: bold;">{{'SignupQCCompanyPage.CountryFieldLabel' | translate }} *</label>
            <p-dropdown id="country"
              formControlName="countryId"
              [options]="countries"
              optionLabel="name"
              optionValue="id"
              [filter]="true"
              filterBy="name"
              [showClear]="true"
              placeholder="{{'SignupQCCompanyPage.CountryFieldPlaceholder' | translate }}"
              (onChange)="onChangeCountry()">
              <ng-template pTemplate="selectedItem">
                <div class="flex align-items-center gap-2" *ngIf="selectedCountry">
                    <img [src]="'../../../../assets/img/flags/' + selectedCountry.code + '.png'" style="width: 18px" alt=""/>
                    <div>{{ selectedCountry | countryDescription }}</div>
                </div>
              </ng-template>
              <ng-template let-country pTemplate="item">
                  <div class="flex align-items-center gap-2">
                      <img [src]="'../../../../assets/img/flags/' + country.code + '.png'" style="width: 18px" alt=""/>
                      <div>{{ country | countryDescription }}</div>
                  </div>
              </ng-template>
            </p-dropdown>
            <small *ngIf="submit && formGroup.controls['countryId'].invalid" class="p-error">{{getErrorMessage(formGroup, 'countryId')}}</small>
          </div>
          <div class="field col-12 md:col-6 lg:col-6" style="margin-bottom: 40px;">
            <label for="city" style="font-weight: bold;">{{'SignupQCCompanyPage.CityFieldLabel' | translate }} *</label>
            <input id="city" formControlName="city" type="text" required pInputText placeholder="{{'SignupQCCompanyPage.CityFieldPlaceholder' | translate }}"/>
            <small *ngIf="submit && formGroup.controls['city'].invalid" class="p-error">{{getErrorMessage(formGroup, 'city')}}</small>
          </div>
          <div class="field col-12 md:col-6 lg:col-6" style="margin-bottom: 40px;">
            <label for="address" style="font-weight: bold;">{{'SignupQCCompanyPage.AddressFieldLabel' | translate }} *</label>
            <input id="address" formControlName="address" type="text" required pInputText maxlength="50" placeholder="{{'SignupQCCompanyPage.AddressFieldPlaceholder' | translate }}"/>
            <small *ngIf="submit && formGroup.controls['address'].invalid" class="p-error">{{getErrorMessage(formGroup, 'address')}}</small>
          </div>
          <div class="field col-12 md:col-6 lg:col-6" style="margin-bottom: 40px;">
            <label for="phone" style="font-weight: bold;">{{'SignupQCCompanyPage.PhoneFieldLabel' | translate }} *</label>
            <input id="phone" formControlName="phone" type="text" required pInputText maxlength="50" placeholder="{{'SignupQCCompanyPage.PhoneFieldPlaceholder' | translate }}"/>
            <small *ngIf="submit && formGroup.controls['phone'].invalid" class="p-error">{{getErrorMessage(formGroup, 'phone')}}</small>
          </div>
          <div class="field col-12 md:col-6 lg:col-6" style="margin-bottom: 40px;">
            <label for="email" style="font-weight: bold;">{{'SignupQCCompanyPage.EmailFieldLabel' | translate }} *</label>
            <input id="email" formControlName="email" type="text" required pInputText maxlength="150" placeholder="{{'SignupQCCompanyPage.EmailFieldPlaceholder' | translate }}"/>
            <small *ngIf="submit && formGroup.controls['email'].invalid" class="p-error">{{getErrorMessage(formGroup, 'email')}}</small>
          </div>
          <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
            <label for="email2" style="font-weight: bold;">{{'SignupPage.ConfirmEmailFieldLabel' | translate }} *</label>
            <input id="email2" formControlName="email2" type="text" required pInputText maxlength="150" placeholder="{{'SignupPage.ConfirmEmailFieldPlaceholder' | translate }}"/>
            <small *ngIf="submit && formGroup.controls['email2'].invalid" class="p-error">{{getErrorMessage(formGroup, 'email2')}}&nbsp;</small>
            <small *ngIf="submit && emaildMatchError" class="p-error">{{getEmailMissMatchMessage()}}</small>
          </div>
          <div class="col-12" style="margin-bottom: 40px;"></div>
          <div class="field col-12 md:col-6 lg:col-6" style="margin-bottom: 40px;">
            <label for="hasQCSystem" style="font-weight: bold;">{{'SignupQCCompanyPage.HasQCSystemLabel' | translate }}</label>&nbsp;
            <p-inputSwitch formControlName="hasQCSystem"></p-inputSwitch>
          </div>
          <div class="field col-12 md:col-6 lg:col-6" style="margin-bottom: 40px;">
            <label for="isConsolidateContainer" style="font-weight: bold;">{{'SignupQCCompanyPage.IsConsolidateContainerLabel' | translate }}</label>&nbsp;
            <p-inputSwitch formControlName="isConsolidateContainer"></p-inputSwitch>
          </div>
          <div class="field col-12 md:col-6 lg:col-6" *ngIf="formGroup.controls['isConsolidateContainer'].value">
            <label for="consolidationContainerCost" style="font-weight: bold;">{{'SignupQCCompanyPage.ConsolidationContainerCostLabel' | translate }}</label>
            <p-inputNumber inputId="consolidationContainerCost" formControlName="consolidationContainerCost" mode="currency" currency="USD" locale="en-US" [min]="0" placeholder="{{'SignupQCCompanyPage.ConsolidationContainerCostLabel' | translate }}"></p-inputNumber>
          </div>
          <div class="field col-12 md:col-6 lg:col-6" *ngIf="formGroup.controls['isConsolidateContainer'].value">
            <label for="consolidationContainerPoints" style="font-weight: bold;">{{'SignupQCCompanyPage.ConsolidationContainerPointsLabel' | translate }}</label>
            <input id="consolidationContainerPoints" formControlName="consolidationContainerPoints" type="text" required pInputText maxlength="150" placeholder="{{'SignupQCCompanyPage.ConsolidationContainerPointsLabel' | translate }}"/>
          </div>
          <div class="field col-12 md:col-6 lg:col-6" style="margin-bottom: 40px;">
            <label for="basePricePerPallet" style="font-weight: bolder;">{{'SignupQCCompanyPage.BasePricePerPalletLabel' | translate }} (USD) *</label>
            <p-inputNumber inputId="basePricePerPallet" formControlName="basePricePerPallet" mode="currency" currency="USD" locale="en-US" [min]="0" placeholder="{{'SignupQCCompanyPage.BasePricePerPalletLabel' | translate }}"></p-inputNumber>
            <small *ngIf="submit && formGroup.controls['basePricePerPallet'].invalid" class="p-error">{{getErrorMessage(formGroup, 'basePricePerPallet')}}</small>
          </div>
          <div class="field col-12 md:col-6 lg:col-6" style="margin-bottom: 40px;">
            <label for="basePricePerContainer" style="font-weight: bolder;">{{'SignupQCCompanyPage.BasePricePerContainerLabel' | translate }} (USD) *</label>
            <p-inputNumber inputId="basePricePerContainer" formControlName="basePricePerContainer" mode="currency" currency="USD" locale="en-US" [min]="0" placeholder="{{'SignupQCCompanyPage.BasePricePerContainerLabel' | translate }}"></p-inputNumber>
            <small *ngIf="submit && formGroup.controls['basePricePerContainer'].invalid" class="p-error">{{getErrorMessage(formGroup, 'basePricePerContainer')}}</small>
          </div>
        </div>
        <div class="w-full md:w-8 lg:w-8 p-fluid grid" style="margin-top: 40px;">
          <div class="col-12">
            <h2>{{'SignupQCCompanyPage.QCSpeciesSection.Title' | translate }}</h2>
            <p>{{'SignupQCCompanyPage.QCSpeciesSection.Text1' | translate }}</p>
            <p-table
              [value]="products"
              responsiveLayout="stack"
              [breakpoint]="'600px'">
              <ng-template pTemplate="caption">
                <div class="flex align-items-center justify-content-start">
                  <p-button label="{{'SignupQCCompanyPage.NewButtonLabel' | translate }}" icon="pi pi-plus" class="mr-2" (onClick)="openProductDialogCreate(productFormDirective)"/>
                </div>
              </ng-template>
              <ng-template pTemplate="header">
                <tr>
                  <th>{{'SignupQCCompanyPage.QCSpeciesSection.SpeciesHeader' | translate }}</th>
                  <th>{{'SignupQCCompanyPage.QCSpeciesSection.StandarsHeader' | translate }}</th>
                  <th>{{'SignupQCCompanyPage.QCSpeciesSection.ToleranceHeader' | translate }}</th>
                  <th></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-product>
                <tr>
                  <td>
                    <div *ngIf="product.species">
                      <img [src]="product.species.imageUrl" style="width: 18px" alt=""/>&nbsp;
                      {{ getSpeciesName(product.species) }}
                    </div>
                  </td>
                  <td>{{ product.standards }}</td>
                  <td>{{ product.tolerance }}</td>
                  <td>
                    <div class="flex justify-content-end align-items-center gap-2">
                      <p-button label="{{'SignupQCCompanyPage.EditButtonLabel' | translate }}" icon="pi pi-pencil" class="mr-2" (onClick)="openProductDialogEdit(product)"/>
                      <p-button label="{{'SignupQCCompanyPage.DeleteButtonLabel' | translate }}" icon="pi pi-trash" class="mr-2" (onClick)="onRemoveProduct(product)"/>
                    </div>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="summary">
                <div *ngIf="products.length < 1">
                  <small class="p-error">{{'SignupQCCompanyPage.QCSpeciesSection.Text2' | translate }}</small>
                </div>
              </ng-template>
            </p-table>
          </div>
        </div>
        <div class="w-full md:w-8 lg:w-8 p-fluid grid" style="margin-top: 40px;">
          <div class="col-12">
            <h2>{{'SignupQCCompanyPage.QCOperationSection.Title' | translate }}</h2>
            <p>{{'SignupQCCompanyPage.QCOperationSection.Text1' | translate }}</p>
            <p-table [value]="operations"
              responsiveLayout="stack"
              [breakpoint]="'600px'">
              <ng-template pTemplate="caption">
                <div class="flex align-items-center justify-content-start">
                  <p-button label="{{'SignupQCCompanyPage.NewButtonLabel' | translate }}" icon="pi pi-plus" class="mr-2" (onClick)="openOperationDialogCreate(operationFormDirective)"/>
                </div>
              </ng-template>
              <ng-template pTemplate="header">
                <tr>
                  <th>{{'SignupQCCompanyPage.QCOperationSection.CountryHeader' | translate }}</th>
                  <th>{{'SignupQCCompanyPage.QCOperationSection.InspectorQuantityHeader' | translate }}</th>
                  <th></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-operation>
                <tr>
                  <td>
                    <div *ngIf="operation.country">
                      <img [src]="'../../../../assets/img/flags/' + operation.country.code + '.png'" style="width: 18px" alt=""/>&nbsp;
                      {{ operation.country | countryDescription }}
                    </div>
                  </td>
                  <td>{{ operation.inspectorQuantity }}</td>
                  <td>
                    <div class="flex justify-content-end align-items-center gap-2">
                      <p-button label="{{'SignupQCCompanyPage.EditButtonLabel' | translate }}" icon="pi pi-pencil" class="mr-2" (onClick)="openOperationDialogEdit(operation)"/>
                      <p-button label="{{'SignupQCCompanyPage.DeleteButtonLabel' | translate }}" icon="pi pi-trash" class="mr-2" (onClick)="onRemoveOperation(operation)"/>
                    </div>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="summary">
                <div *ngIf="operations.length < 1">
                  <small class="p-error">{{'SignupQCCompanyPage.QCOperationSection.Text2' | translate }}</small>
                </div>
              </ng-template>
            </p-table>
          </div>
        </div>
        <!--div class="w-full md:w-8 lg:w-8 p-fluid grid" style="margin-top: 40px;">
          <div class="col-12">
            <h2>{{'SignupQCCompanyPage.QCMarketsSection.Title' | translate }}</h2>
            <p>{{'SignupQCCompanyPage.QCMarketsSection.Text1' | translate }}</p>
            <p-table [value]="markets"
              responsiveLayout="stack"
              [breakpoint]="'600px'">
              <ng-template pTemplate="caption">
                <div class="flex align-items-center justify-content-start">
                  <p-button label="{{'SignupQCCompanyPage.NewButtonLabel' | translate }}" icon="pi pi-plus" class="mr-2" (onClick)="openMarketDialogCreate(marketFormDirective)" />
                </div>
              </ng-template>
              <ng-template pTemplate="header">
                <tr>
                  <th>{{'SignupQCCompanyPage.QCMarketsSection.CountryHeader' | translate }}</th>
                  <th></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-market>
                <tr>
                  <td>
                    <div *ngIf="market.country">
                      <img [src]="'../../../../assets/img/flags/' + market.country.code + '.png'" style="width: 18px" alt=""/>&nbsp;
                      {{ market.country | countryDescription }}
                    </div>
                  </td>
                  <td>
                    <div class="flex justify-content-end align-items-center gap-2">
                      <p-button label="{{'SignupQCCompanyPage.EditButtonLabel' | translate }}" icon="pi pi-pencil" class="mr-2" (onClick)="openMarketDialogEdit(market)" />
                      <p-button label="{{'SignupQCCompanyPage.DeleteButtonLabel' | translate }}" icon="pi pi-trash" class="mr-2" (onClick)="onRemoveMarket(market)" />
                    </div>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="summary">
                <div *ngIf="markets.length < 1">
                  <small class="p-error">{{'SignupQCCompanyPage.QCMarketsSection.Text2' | translate }}</small>
                </div>
              </ng-template>
            </p-table>
          </div>
        </div-->
        <div class="w-full md:w-8 lg:w-8 p-fluid grid" style="margin-top: 40px;">
          <div class="col-6">
            <p-button [loading]="loading" (onClick)="goToHome()" label="{{'SignupQCCompanyPage.ReturnButton' | translate }}" styleClass="p-button-outlined"/>
          </div>
          <div class="col-6">
            <p-button [loading]="loading" (onClick)="submitForm()" label="{{'SignupQCCompanyPage.SubmitButton' | translate }}"/>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div style="margin-top: 40px;" *ngIf="success">
    <p>{{'SignupQCCompanyPage.SuccessText' | translate }}</p>
  </div>
</div>
<div class="surface-section px-4 py-8 md:px-6 lg:px-8 flex justify-content-end">
  <p>2023, Fruzty.</p>
</div>

<p-dialog header="{{'SignupQCCompanyPage.QCSpeciesForm.Header' | translate }}" [(visible)]="productDialogVisible" [style]="{width: '70%'}" [modal]="true">
  <form [formGroup]="productFormGroup" #productFormDirective="ngForm">
    <div class="p-fluid formgrid grid" style="padding: 20px;">
      <div class="field col-12 md:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label for="species" style="font-weight: bolder;">{{'SignupQCCompanyPage.QCSpeciesForm.SpeciesFieldLabel' | translate }} *</label>
        <p-dropdown id="species"
          formControlName="speciesId"
          [options]="species"
          optionLabel="nameEs"
          optionValue="id"
          [filter]="true"
          filterBy="name"
          [showClear]="true"
          placeholder="{{'SignupQCCompanyPage.QCSpeciesForm.SpeciesFieldPlaceholder' | translate }}"
          (onChange)="onChangeSpecies()"
          appendTo="body">
          <ng-template pTemplate="selectedItem">
            <div class="flex align-items-center gap-2" *ngIf="selectedSpecies">
                <img [src]="selectedSpecies.imageUrl" style="width: 18px" alt=""/>
                <div>{{ getSpeciesName(selectedSpecies) }}</div>
            </div>
          </ng-template>
          <ng-template let-specie pTemplate="item">
              <div class="flex align-items-center gap-2">
                  <img [src]="specie.imageUrl" style="width: 18px" alt=""/>
                  <div>{{ getSpeciesName(specie) }}</div>
              </div>
          </ng-template>
        </p-dropdown>
        <small *ngIf="submitProduct && productFormGroup.controls['speciesId'].invalid" class="p-error">{{getErrorMessage(productFormGroup, 'speciesId')}}</small>
      </div>
      <!--div class="field col-12 md:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label for="qcCost" style="font-weight: bolder;">{{'SignupQCCompanyPage.QCSpeciesForm.CostPerPalletFieldLabel' | translate }} (USD) *</label>
        <p-inputNumber inputId="qcCost" formControlName="qcCost" mode="currency" currency="USD" locale="en-US" [min]="0" placeholder="{{'SignupQCCompanyPage.QCSpeciesForm.CostPerPalletFieldLabel' | translate }}"></p-inputNumber>
        <small *ngIf="submitProduct && productFormGroup.controls['qcCost'].invalid" class="p-error">{{getErrorMessage(productFormGroup, 'qcCost')}}</small>
      </div-->
      <!--div class="field col-12 md:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label for="qcCostPerContainer" style="font-weight: bolder;">{{'SignupQCCompanyPage.QCSpeciesForm.CostPerContainerFieldLabel' | translate }} (USD)</label>
        <p-inputNumber inputId="qcCostPerContainer" formControlName="qcCostPerContainer" mode="currency" currency="USD" locale="en-US" [min]="0" placeholder="{{'SignupQCCompanyPage.QCSpeciesForm.CostPerContainerFieldLabel' | translate }}"></p-inputNumber>
      </div-->
      <div class="field col-12" style="margin-bottom: 40px;">
        <label for="standards" style="font-weight: bolder;">{{'SignupQCCompanyPage.QCSpeciesForm.StandarsFieldLabel' | translate }} *</label>
        <input id="standards" formControlName="standards" type="text" required pInputText placeholder="{{'SignupQCCompanyPage.QCSpeciesForm.StandarsFieldLabel' | translate }}"/>
        <small *ngIf="submitProduct && productFormGroup.controls['standards'].invalid" class="p-error">{{getErrorMessage(productFormGroup, 'standards')}}</small>
      </div>
      <div class="field col-12" style="margin-bottom: 40px;">
        <label for="tolerance" style="font-weight: bolder;">{{'SignupQCCompanyPage.QCSpeciesForm.ToleranceFieldLabel' | translate }} *</label>
        <input id="tolerance" formControlName="tolerance" type="text" required pInputText placeholder="{{'SignupQCCompanyPage.QCSpeciesForm.ToleranceFieldLabel' | translate }}"/>
        <small *ngIf="submitProduct && productFormGroup.controls['tolerance'].invalid" class="p-error">{{getErrorMessage(productFormGroup, 'tolerance')}}</small>
      </div>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <p-button label="{{'SignupQCCompanyPage.QCSpeciesForm.CloseDialogButtonLabel' | translate }}" (onClick)="productDialogVisible = false" styleClass="p-button-outlined"></p-button>
    <p-button label="{{'SignupQCCompanyPage.QCSpeciesForm.SaveButtonLabel' | translate }}" (onClick)="saveProduct()" [loading]="loading"></p-button>
  </ng-template>
</p-dialog>

<p-dialog header="{{'SignupQCCompanyPage.QCOperationForm.Header' | translate }}" [(visible)]="operationDialogVisible" [style]="{width: '70%'}" [modal]="true">
  <form [formGroup]="operationFormGroup" #operationFormDirective="ngForm">
    <div class="p-fluid formgrid grid" style="padding: 20px;">
      <div class="field col-12 md:col-6 lg:col-6" style="margin-bottom: 40px;">
        <label for="operationCountryId" style="font-weight: bolder;">{{'SignupQCCompanyPage.QCOperationForm.CountryFieldLabel' | translate }} *</label>
        <p-dropdown
          inputId="operationCountryId"
          formControlName="countryId"
          [options]="countries"
          optionValue="id"
          optionLabel="name"
          placeholder="{{'SignupQCCompanyPage.QCOperationForm.CountryFieldPlaceholder' | translate }}"
          [showClear]="true"
          (onChange)="onChangeOperationCountry()"
          appendTo="body">
          <ng-template pTemplate="selectedItem">
            <div class="flex align-items-center gap-2" *ngIf="selectedOperationCountry">
              <img [src]="'../../../../assets/img/flags/' + selectedOperationCountry.code + '.png'" style="width: 18px" alt=""/>
              <div>{{ selectedOperationCountry | countryDescription }}</div>
            </div>
          </ng-template>
          <ng-template let-country pTemplate="item">
            <div class="flex align-items-center gap-2">
              <img [src]="'../../../../assets/img/flags/' + country.code + '.png'" style="width: 18px" alt=""/>
              <div>{{ country | countryDescription }}</div>
            </div>
          </ng-template>
        </p-dropdown>
        <small *ngIf="submitOperation && operationFormGroup.controls['countryId'].invalid" class="p-error">{{getErrorMessage(operationFormGroup, 'countryId')}}</small>
      </div>
      <div class="field col-12 md:col-6 lg:col-6" style="margin-bottom: 40px;">
        <label for="inspectorQuantity" style="font-weight: bolder;">{{'SignupQCCompanyPage.QCOperationForm.InspectorQuantityFieldLabel' | translate }} *</label>
        <p-inputNumber inputId="inspectorQuantity" formControlName="inspectorQuantity" [min]="1" placeholder="{{'SignupQCCompanyPage.QCOperationForm.InspectorQuantityFieldLabel' | translate }}"></p-inputNumber>
        <small *ngIf="submitOperation && operationFormGroup.controls['inspectorQuantity'].invalid" class="p-error">{{getErrorMessage(operationFormGroup, 'inspectorQuantity')}}</small>
      </div>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <p-button label="{{'SignupQCCompanyPage.QCOperationForm.CloseDialogButtonLabel' | translate }}" (onClick)="operationDialogVisible = false" styleClass="p-button-outlined"></p-button>
    <p-button label="{{'SignupQCCompanyPage.QCOperationForm.SaveButtonLabel' | translate }}" (onClick)="saveOperation()" [loading]="loading"></p-button>
  </ng-template>
</p-dialog>

<p-dialog header="{{'SignupQCCompanyPage.QCMarketForm.Header' | translate }}" [(visible)]="marketDialogVisible" [style]="{width: '70%'}" [modal]="true">
  <form [formGroup]="marketFormGroup" #marketFormDirective="ngForm">
    <div class="p-fluid formgrid grid" style="padding: 20px;">
      <div class="field col-12" style="margin-bottom: 40px;">
        <label for="marketCountryId" style="font-weight: bolder;">{{'SignupQCCompanyPage.QCMarketForm.CountryFieldLabel' | translate }} *</label>
        <p-dropdown
          inputId="marketCountryId"
          formControlName="countryId"
          [options]="countries"
          optionValue="id"
          optionLabel="name"
          placeholder="{{'SignupQCCompanyPage.QCMarketForm.CountryFieldPlaceholder' | translate }}"
          [showClear]="true"
          (onChange)="onChangeMarketCountry()"
          appendTo="body">
          <ng-template pTemplate="selectedItem">
            <div class="flex align-items-center gap-2" *ngIf="selectedMarketCountry">
              <img [src]="'../../../../assets/img/flags/' + selectedMarketCountry.code + '.png'" style="width: 18px" alt=""/>
              <div>{{ selectedMarketCountry | countryDescription }}</div>
            </div>
          </ng-template>
          <ng-template let-country pTemplate="item">
            <div class="flex align-items-center gap-2">
              <img [src]="'../../../../assets/img/flags/' + country.code + '.png'" style="width: 18px" alt=""/>
              <div>{{ country | countryDescription }}</div>
            </div>
          </ng-template>
        </p-dropdown>
        <small *ngIf="submitMarket && marketFormGroup.controls['countryId'].invalid" class="p-error">{{getErrorMessage(marketFormGroup, 'countryId')}}</small>
      </div>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <p-button label="{{'SignupQCCompanyPage.QCMarketForm.CloseDialogButtonLabel' | translate }}" (onClick)="marketDialogVisible = false" styleClass="p-button-outlined"></p-button>
    <p-button label="{{'SignupQCCompanyPage.QCMarketForm.SaveButtonLabel' | translate }}" (onClick)="saveMarket()" [loading]="loading"></p-button>
  </ng-template>
</p-dialog>
<app-footer-home></app-footer-home>
<p-toast></p-toast>
