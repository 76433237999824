import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Routes } from "src/app/infrastructure/routes";
import { OperationDocumentType } from "src/app/domain/models/Operation";
import { OperationDocumentTypeGateway } from "../gateway/OperationDocumentTypeGateway";

@Injectable({providedIn: 'root'})
export class OperationDocumentTypeService extends OperationDocumentTypeGateway {

  constructor(private _http: HttpClient) {
    super()
  }

  getAll(): Observable<OperationDocumentType[]> {
    return this._http.get<OperationDocumentType[]>(Routes.operationDocumentTypeApi + '/GetAll')
  }


}
