import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, MessageService } from 'primeng/api';
import readXlsxFile, { readSheetNames } from 'read-excel-file';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { PalletGateway } from 'src/app/application/gateway/PalletGateway';
import { GenericOrderDetail, ImportOrderDetail, ImportOrderDetailInformation, UploadImportOrderDetailPdfRequest } from 'src/app/domain/models/ImportOrder';
import { CreatePalletsRequest, NewPallet, Pallet } from 'src/app/domain/models/Pallet';
import { Species } from 'src/app/domain/models/Species';
import { BasicUser } from 'src/app/domain/models/User';
import { Routes } from 'src/app/infrastructure/routes';
import { ErrorMessageUtil } from 'src/app/ui/util/error-message-util';
import { SpeciesUtils } from 'src/app/ui/util/species-utils';

@Component({
  selector: 'app-admin-edit-pallets',
  templateUrl: './admin-edit-pallets.component.html',
  styleUrls: ['./admin-edit-pallets.component.css'],
  providers: [MessageService]
})
export class AdminEditPalletsComponent implements OnInit {

  breadcrumbMenuItems: MenuItem[] = []
  breadcrumbHome: MenuItem

  orderDetailId: number | undefined
  importOrderDetailInformationDto: ImportOrderDetailInformation | undefined
  detail: GenericOrderDetail | undefined

  excelFile: any
  excelSheets: any
  selectedSheet: string = ''
  visible: boolean = false

  profile: BasicUser | undefined
  pallets: Pallet  [] = []
  qcReviewPdf: any
  qcContainerConsolidationPdf: any

  submit: boolean = false
  loading: boolean = false

  columns: string[] = [
    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
    'AA', 'AB', 'AC', 'AD', 'AE', 'AF', 'AG', 'AH', 'AI', 'AJ', 'AK', 'AL', 'AM', 'AN', 'AO', 'AP', 'AQ', 'AR', 'AS', 'AT', 'AU', 'AV', 'AW', 'AX', 'AY', 'AZ',
    'BA', 'BB', 'BC', 'BD', 'BE', 'BF', 'BG', 'BH', 'BI', 'BJ', 'BK', 'BL', 'BM', 'BN', 'BO', 'BP', 'BQ', 'BR', 'BS', 'BT', 'BU', 'BV', 'BW', 'BX', 'BY', 'BZ',
    'CA', 'CB', 'CC', 'CD', 'CE', 'CF', 'CG', 'CH', 'CI', 'CJ', 'CK', 'CL', 'CM', 'CN', 'CO', 'CP', 'CQ', 'CR', 'CS', 'CT', 'CU', 'CV', 'CW', 'CX', 'CY', 'CZ',
    'DA', 'DB', 'DC', 'DD', 'DE', 'DF', 'DG', 'DH', 'DI', 'DJ', 'DK', 'DL', 'DM', 'DN', 'DO', 'DP', 'DQ', 'DR', 'DS', 'DT', 'DU', 'DV', 'DW', 'DX', 'DY', 'DZ',
    'EA', 'EB', 'EC', 'ED', 'EE', 'EF', 'EG', 'EH', 'EI', 'EJ', 'EK', 'EL', 'EM', 'EN', 'EO', 'EP', 'EQ', 'ER', 'ES', 'ET', 'EU', 'EV', 'EW', 'EX', 'EY', 'EZ',
    'FA', 'FB', 'FC', 'FD', 'FE', 'FF', 'FG', 'FH', 'FI', 'FJ', 'FK', 'FL', 'FM', 'FN', 'FO', 'FP', 'FQ', 'FR', 'FS', 'FT', 'FU', 'FV', 'FW', 'FX', 'FY', 'FZ',
    'GA', 'GB', 'GC', 'GD', 'GE', 'GF', 'GG', 'GH', 'GI', 'GJ', 'GK', 'GL', 'GM', 'GN', 'GO', 'GP', 'GQ', 'GR', 'GS', 'GT', 'GU', 'GV', 'GW', 'GX', 'GY', 'GZ',
    'HA', 'HB', 'HC', 'HD', 'HE', 'HF', 'HG', 'HH', 'HI', 'HJ', 'HK', 'HL', 'HM', 'HN', 'HO', 'HP', 'HQ', 'HR', 'HS', 'HT', 'HU', 'HV', 'HW', 'HX', 'HY', 'HZ',
    'IA', 'IB', 'IC', 'ID', 'IE', 'IF', 'IG', 'IH', 'II', 'IJ', 'IK', 'IL', 'IM', 'IN', 'IO', 'IP', 'IQ', 'IR', 'IS', 'IT', 'IU', 'IV', 'IW', 'IX', 'IY', 'IZ',
    'JA', 'JB', 'JC', 'JD', 'JE', 'JF', 'JG', 'JH', 'JI', 'JJ', 'JK', 'JL', 'JM', 'JN', 'JO', 'JP', 'JQ', 'JR', 'JS', 'JT', 'JU', 'JV', 'JW', 'JX', 'JY', 'JZ',
    'KA', 'KB', 'KC', 'KD', 'KE', 'KF', 'KG', 'KH', 'KI', 'KJ', 'KK', 'KL', 'KM', 'KN', 'KO', 'KP', 'KQ', 'KR', 'KS', 'KT', 'KU', 'KV', 'KW', 'KX', 'KY', 'KZ',
    'LA', 'LB', 'LC', 'LD', 'LE', 'LF', 'LG', 'LH', 'LI', 'LJ', 'LK', 'LL', 'LM', 'LN', 'LO', 'LP', 'LQ', 'LR', 'LS', 'LT', 'LU', 'LV', 'LW', 'LX', 'LY', 'LZ',
    'MA', 'MB', 'MC', 'MD', 'ME', 'MF', 'MG', 'MH', 'MI', 'MJ', 'MK', 'ML', 'MM', 'MN', 'MO', 'MP', 'MQ', 'MR', 'MS', 'MT', 'MU', 'MV', 'MW', 'MX', 'MY', 'MZ',
    'NA', 'NB', 'NC', 'ND', 'NE', 'NF', 'NG', 'NH', 'NI', 'NJ', 'NK', 'NL', 'NM', 'NN', 'NO', 'NP', 'NQ', 'NR', 'NS', 'NT', 'NU', 'NV', 'NW', 'NX', 'NY', 'NZ',
    'OA', 'OB', 'OC', 'OD', 'OE', 'OF', 'OG', 'OH', 'OI', 'OJ', 'OK', 'OL', 'OM', 'ON', 'OO', 'OP', 'OQ', 'OR', 'OS', 'OT', 'OU', 'OV', 'OW', 'OX', 'OY', 'OZ',
    'PA', 'PB', 'PC', 'PD', 'PE', 'PF', 'PG', 'PH', 'PI', 'PJ', 'PK', 'PL', 'PM', 'PN', 'PO', 'PP', 'PQ', 'PR', 'PS', 'PT', 'PU', 'PV', 'PW', 'PX', 'PY', 'PZ',
    'QA', 'QB', 'QC', 'QD', 'QE', 'QF', 'QG', 'QH', 'QI', 'QJ', 'QK', 'QL', 'QM', 'QN', 'QO', 'QP', 'QQ', 'QR', 'QS', 'QT', 'QU', 'QV', 'QW', 'QX', 'QY', 'QZ',
    'RA', 'RB', 'RC', 'RD', 'RE', 'RF', 'RG', 'RH', 'RI', 'RJ', 'RK', 'RL', 'RM', 'RN', 'RO', 'RP', 'RQ', 'RR', 'RS', 'RT', 'RU', 'RV', 'RW', 'RX', 'RY', 'RZ',
    'SA', 'SB', 'SC', 'SD', 'SE', 'SF', 'SG', 'SH', 'SI', 'SJ', 'SK', 'SL', 'SM', 'SN', 'SO', 'SP', 'SQ', 'SR', 'SS', 'ST', 'SU', 'SV', 'SW', 'SX', 'SY', 'SZ',
    'TA', 'TB', 'TC', 'TD', 'TE', 'TF', 'TG', 'TH', 'TI', 'TJ', 'TK', 'TL', 'TM', 'TN', 'TO', 'TP', 'TQ', 'TR', 'TS', 'TT', 'TU', 'TV', 'TW', 'TX', 'TY', 'TZ',
    'UA', 'UB', 'UC', 'UD', 'UE', 'UF', 'UG', 'UH', 'UI', 'UJ', 'UK', 'UL', 'UM', 'UN', 'UO', 'UP', 'UQ', 'UR', 'US', 'UT', 'UU', 'UV', 'UW', 'UX', 'UY', 'UZ',
    'VA', 'VB', 'VC', 'VD', 'VE', 'VF', 'VG', 'VH', 'VI', 'VJ', 'VK', 'VL', 'VM', 'VN', 'VO', 'VP', 'VQ', 'VR', 'VS', 'VT', 'VU', 'VV', 'VW', 'VX', 'VY', 'VZ',
    'WA', 'WB', 'WC', 'WD', 'WE', 'WF', 'WG', 'WH', 'WI', 'WJ', 'WK', 'WL', 'WM', 'WN', 'WO', 'WP', 'WQ', 'WR', 'WS', 'WT', 'WU', 'WV', 'WW', 'WX', 'WY', 'WZ',
    'XA', 'XB', 'XC', 'XD', 'XE', 'XF', 'XG', 'XH', 'XI', 'XJ', 'XK', 'XL', 'XM', 'XN', 'XO', 'XP', 'XQ', 'XR', 'XS', 'XT', 'XU', 'XV', 'XW', 'XX', 'XY', 'XZ',
    'YA', 'YB', 'YC', 'YD', 'YE', 'YF', 'YG', 'YH', 'YI', 'YJ', 'YK', 'YL', 'YM', 'YN', 'YO', 'YP', 'YQ', 'YR', 'YS', 'YT', 'YU', 'YV', 'YW', 'YX', 'YY', 'YZ',
    'ZA', 'ZB', 'ZC', 'ZD', 'ZE', 'ZF', 'ZG', 'ZH', 'ZI', 'ZJ', 'ZK', 'ZL', 'ZM', 'ZN', 'ZO', 'ZP', 'ZQ', 'ZR', 'ZS', 'ZT', 'ZU', 'ZV', 'ZW', 'ZX', 'ZY', 'ZZ'
  ]

  palletFormGroup: FormGroup = this._formBuilder.group({
    startRow: [2, Validators.required],
    folioColumn: ['A', Validators.required],
    speciesColumn: ['B', Validators.required],
    varietyColumn: ['C', Validators.required],
    producerColumn: ['D', Validators.required],
    speciesSizeColumn: ['E'],
    harvestDateColumn: ['F'],
    qualityControlDateColumn: ['G'],
    packagingColumn: ['H'],
    palletTypeColumn: ['I'],
    tagColumn: ['J'],
    bagTypeColumn: ['K'],
    boxSizeColumn: ['L'],
    netWeightColumn: ['M'],
    fridgeStorageColumn: ['N'],
    boxesPerPalletColumn: ['O'],
    markColumn: ['P'],
    containerNumberColumn: ['Q'],
    gradeColumn: ['R'],
    temptaleColumn: ['S'],
    originCountryColumn: ['T'],
    certificate1Column: ['U'],
    certificate2Column: ['V'],
    certificate3Column: ['W'],
    loadWeekColumn: ['X'],
    category: ['Y']
  })

  formGroup: FormGroup = this._formBuilder.group({
    qualityControlDate: [null, Validators.required]
  })

  constructor(private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _messageService: MessageService,
    private _formBuilder: FormBuilder,
    private _authService: AuthGateway,
    private _palletService: PalletGateway,
    public translate: TranslateService) {
    this._activatedRoute.params.subscribe(params => {
      this.orderDetailId = params['orderDetailId']

      this.loadImportOrderDetailInformation()
    })

    this.breadcrumbHome = {label: 'Ordenes de compra', routerLink: ['/' + Routes.adminManageOrderPage]}
  }

  ngOnInit(): void {
    this.profile = this._authService.getUser()
  }

  loadImportOrderDetailInformation() {
    if(this.orderDetailId) {
      this._palletService.getByImportOrderDetailId(this.orderDetailId).subscribe({
        next: (v) => {
          this.importOrderDetailInformationDto = v
          this.detail = this.importOrderDetailInformationDto.importOrderDetail
          this.pallets = this.importOrderDetailInformationDto.pallets
          this.formGroup.controls['qualityControlDate'].setValue(this.detail.qualityControlDate ? new Date(this.detail.qualityControlDate) : null)
          this.breadcrumbMenuItems = [{label: 'Detalle de orden', routerLink: ['/' + Routes.adminOrderDetail, this.detail.importOrderId]}]
        },
        error: (e) => {
          console.log(e)
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ImportOrderDetail.SingleRetrieveError') });
        },
        complete: () => console.info('Order Detail query successfully')
      })
    }
  }

  showDialog() {
    this.excelFile = null
    this.excelSheets = null
    this.visible = true
  }

  getSpeciesName(species: Species | undefined): string {
    if(this.profile)
      return SpeciesUtils.getSpeciesName(this.profile.lang, species)
    return ''
  }

  onSelectExcelFile(uploader: any) {
    this.excelFile = uploader.files[0]
    readSheetNames(uploader.files[0]).then((sheetNames) => {
      this.excelSheets = sheetNames
    })

    uploader.clear()
  }

  onLoadExcel() {
    this.loading = true
    if(this.palletFormGroup.valid) {
      if(!this.excelFile)
        return

      if(!this.selectedSheet)
        return

      readXlsxFile(this.excelFile, {sheet: this.selectedSheet}).then((rows) => {
        if(rows) {
          console.log(rows)
          this.pallets.forEach(pallet => {
            pallet.palletNumber = ''
            pallet.excelImportSpecies = ''
            pallet.excelImportVariety = ''
            pallet.speciesSize = ''
            pallet.producer = ''
            pallet.excelImportHarvestDate = ''
            pallet.excelImportQualityControlDate = ''
            pallet.excelImportPackaging = ''
            pallet.excelImportPalletType = ''
            pallet.excelImportTag = ''
            pallet.excelImportBagType = ''
            pallet.excelImportBoxSize = ''
            pallet.excelImportNetWeight = ''
            pallet.excelImportFridgeStorage = ''
            pallet.excelImportBoxesPerPallet = ''
            pallet.excelImportMark = ''
            pallet.excelImportContainerNumber = ''
            pallet.excelImportGrade = ''
            pallet.excelImportTemptale = ''
            pallet.excelImportOriginCountry = ''
            pallet.excelImportCertificate1 = ''
            pallet.excelImportCertificate2 = ''
            pallet.excelImportCertificate3 = ''
            pallet.excelImportLoadWeek = ''
          })

          rows.forEach(row => {
            for(let k=0; k < 23; k++) {
              if(row[k]) {
                let value = row[k]
                if(value instanceof Date) {
                  value = formatDate(value, 'dd/MM/yyyy', 'en-US')
                }
                else if (value) {
                  value = value.toString()
                }
                row[k] = value
              }
            }
          })

          let startRow = this.palletFormGroup.controls['startRow'].value
          let folioColumn = this.palletFormGroup.controls['folioColumn'].value
          let speciesColumn = this.palletFormGroup.controls['speciesColumn'].value
          let varietyColumn = this.palletFormGroup.controls['varietyColumn'].value
          let producerColumn = this.palletFormGroup.controls['producerColumn'].value
          let speciesSizeColumn = this.palletFormGroup.controls['speciesSizeColumn'].value
          let harvestDateColumn = this.palletFormGroup.controls['harvestDateColumn'].value
          let qualityControlDateColumn = this.palletFormGroup.controls['qualityControlDateColumn'].value
          let packagingColumn = this.palletFormGroup.controls['packagingColumn'].value
          let palletTypeColumn = this.palletFormGroup.controls['palletTypeColumn'].value
          let tagColumn = this.palletFormGroup.controls['tagColumn'].value
          let bagTypeColumn = this.palletFormGroup.controls['bagTypeColumn'].value
          let boxSizeColumn = this.palletFormGroup.controls['boxSizeColumn'].value
          let netWeightColumn = this.palletFormGroup.controls['netWeightColumn'].value
          let fridgeStorageColumn = this.palletFormGroup.controls['fridgeStorageColumn'].value
          let boxesPerPalletColumn = this.palletFormGroup.controls['boxesPerPalletColumn'].value
          let markColumn = this.palletFormGroup.controls['markColumn'].value
          let containerNumberColumn = this.palletFormGroup.controls['containerNumberColumn'].value
          let gradeColumn = this.palletFormGroup.controls['gradeColumn'].value
          let temptaleColumn = this.palletFormGroup.controls['temptaleColumn'].value
          let originCountryColumn = this.palletFormGroup.controls['originCountryColumn'].value
          let certificate1Column = this.palletFormGroup.controls['certificate1Column'].value
          let certificate2Column = this.palletFormGroup.controls['certificate2Column'].value
          let certificate3Column = this.palletFormGroup.controls['certificate3Column'].value
          let loadWeekColumn = this.palletFormGroup.controls['loadWeekColumn'].value

          const rowStartIndex = startRow - 1
          let j = 0

          let cellValue = (row: any, column: any, pallet: any, property: string) => {
            if(column) {
              const index =  this.columns.indexOf(column.toUpperCase())
              pallet[property] = row[index] ? row[index].toString() : ''
            }
          }

          for(let i = rowStartIndex; i < rows.length; i++) {
            const row = rows[i]
            let pallet = this.pallets[j]
            if(pallet) {
              cellValue(row, folioColumn, pallet, 'palletNumber')
              cellValue(row, speciesColumn, pallet, 'excelImportSpecies')
              cellValue(row, varietyColumn, pallet, 'excelImportVariety')
              cellValue(row, producerColumn, pallet, 'producer')
              cellValue(row, speciesSizeColumn, pallet, 'speciesSize')
              cellValue(row, harvestDateColumn, pallet, 'excelImportHarvestDate')
              cellValue(row, qualityControlDateColumn, pallet, 'excelImportQualityControlDate')
              cellValue(row, packagingColumn, pallet, 'excelImportPackaging')
              cellValue(row, palletTypeColumn, pallet, 'excelImportPalletType')
              cellValue(row, tagColumn, pallet, 'excelImportTag')
              cellValue(row, bagTypeColumn, pallet, 'excelImportBagType')
              cellValue(row, boxSizeColumn, pallet, 'excelImportBoxSize')
              cellValue(row, netWeightColumn, pallet, 'excelImportNetWeight')
              cellValue(row, fridgeStorageColumn, pallet, 'excelImportFridgeStorage')
              cellValue(row, boxesPerPalletColumn, pallet, 'excelImportBoxesPerPallet')
              cellValue(row, markColumn, pallet, 'excelImportMark')
              cellValue(row, containerNumberColumn, pallet, 'excelImportContainerNumber')
              cellValue(row, gradeColumn, pallet, 'excelImportGrade')
              cellValue(row, temptaleColumn, pallet, 'excelImportTemptale')
              cellValue(row, originCountryColumn, pallet, 'excelImportOriginCountry')
              cellValue(row, certificate1Column, pallet, 'excelImportCertificate1')
              cellValue(row, certificate2Column, pallet, 'excelImportCertificate2')
              cellValue(row, certificate3Column, pallet, 'excelImportCertificate3')
              cellValue(row, loadWeekColumn, pallet, 'excelImportLoadWeek')
            }
            j++
          }
        }
      })
      this.loading = false
      this.visible = false
    } else {
      this.loading = false
    }
  }

  onSelectQCReviewPdf(event: any) {
    this.qcReviewPdf = event.files[0]
  }

  onSelectQCConsolidationPdf(event: any) {
    this.qcContainerConsolidationPdf = event.files[0]
  }

  save() {
    this.loading = true
    this.submit = true
    if(this.detail && this.formGroup.valid) {
      if(!this.isValidDatatable()) {
        this.loading = false
        return
      }

      const request: CreatePalletsRequest = {
        pallets: this.pallets,
        importOrderDetailId: this.detail.id
      }

      this._palletService.update(request).subscribe({
        next: (v) => {
          this.loadImportOrderDetailInformation()
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: this.translate.instant('MessageService.Pallet.EditSuccess') })
          this.saveQCReviewPdf()
          this.saveQCConsolidationPdf()
          this.submit = false
          this.loading = false
        },
        error: (e) => {
          console.log(e)
          this.submit = false
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.Pallet.EditError') + e.error })
        },
        complete: () => console.info('Pallets updated successfully')
      })
    }
    else {
      this.loading = false
    }
  }

  private isValidDatatable(): boolean {
    let isValid = true

    if(!this.pallets)
      return false

    this.pallets.forEach(x => {
      if(!x.palletNumber || x.palletNumber.length < 1) {
        isValid = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Pallet.IncompletePalletNumbersError') })
        return
      }

      if(!x.excelImportSpecies || x.excelImportSpecies.length < 1) {
        isValid = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Pallet.IncompleteSpeciesError') })
        return
      }

      if(!x.excelImportVariety || x.excelImportVariety.length < 1) {
        isValid = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Pallet.IncompleteVarietyError') })
        return
      }

      if(!x.producer || x.producer.length < 1) {
        isValid = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Pallet.IncompleteProducerError') })
        return
      }

      if(!isValid) return
    })

    return isValid
  }

  saveQCReviewPdf() {
    if(this.qcReviewPdf && this.detail) {
      let qcReviewPdfRequest: UploadImportOrderDetailPdfRequest = {
        importOrderDetailId: this.detail.id,
        pdf: this.qcReviewPdf
      }
      this._palletService.uploadQCReviewPdf(qcReviewPdfRequest).subscribe({
        next: (v) => {
          this._messageService.add({ severity: 'success', summary: 'Acción exitosa', detail: 'El PDF de inspección fue subido con éxito.' })
        },
        error: (e) => {
          console.log(e)
          this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudo subir el PDF de inspección.' });
        },
        complete: () =>  {
          this.loading = false
          console.info('QCReviewPdf uploaded successfully')
        }
      })
    }
  }

  saveQCConsolidationPdf() {
    if(this.qcContainerConsolidationPdf && this.detail) {
      let qcContainerConsolidationPdfRequest: UploadImportOrderDetailPdfRequest = {
        importOrderDetailId: this.detail.id,
        pdf: this.qcContainerConsolidationPdf
      }
      this._palletService.uploadQCContainerConsolidationPdf(qcContainerConsolidationPdfRequest).subscribe({
        next: (v) => {
          this._messageService.add({ severity: 'success', summary: 'Acción exitosa', detail: 'El PDF de consolidación fue subido con éxito.' })
        },
        error: (e) => {
          console.log(e)
          this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudo subir el PDF de consolidación.' });
        },
        complete: () =>  {
          this.loading = false
          console.info('QCConsolidationPdf uploaded successfully')
        }
      })
    }
  }

  onCancel() {
    if(this.detail) {
      this._router.navigate(['/' + Routes.adminOrderDetail, this.detail.importOrderId])
    }
  }

  getErrorMessage(formGroup: FormGroup, fieldName: string): string {
    return ErrorMessageUtil.getErrorMessage(formGroup, this.translate, fieldName)
  }
}
