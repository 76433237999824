import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AssignForwarder, ProfileCompayList } from "src/app/domain/models/Company";
import { AssignExporterImporter, ForwarderRelations, ForwarderReview, ForwarderReviewItemList, ForwarderUserCreateRequest } from "src/app/domain/models/ForwarderReview";

@Injectable({providedIn: 'root'})
export abstract class ForwarderGateway {
  abstract getAllByStatusAndReviewType(reviewType: number): Observable<ForwarderReviewItemList[]>
  abstract getByReviewId(reviewId: any): Observable<ForwarderReview>
  abstract createUser(request: ForwarderUserCreateRequest): Observable<any>
  abstract getForwardersByAdmin(): Observable<ProfileCompayList[]>
  abstract assignForwarder(request: AssignForwarder): Observable<any>
  abstract uploadLoadFile(request: any): Observable<any>
  abstract forwarderRelations(): Observable<ForwarderRelations>
  abstract assignExporterImporter(request: AssignExporterImporter): Observable<any>
}
