<app-user-dashboard [userMenuActiveItemIndex]="0" [activeItem]="'forwarderReviews'">
    <p-card>
        <ng-template pTemplate="header">
            <div class="flex justify-content-between flex-wrap align-items-center" style="padding: 15px;">
                <h2>{{ 'ForwarderPage.EditPage.Title' | translate }}</h2>
            </div>
        </ng-template>
        <ng-template pTemplate="body">
            <div id="form">
                <form [formGroup]="formGroup">
                    <p-fieldset legend="{{ 'ForwarderPage.EditPage.LegendCompany' | translate }}">
                    <div class="p-fluid formgrid grid">
                            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
                                <label for="companyName" style="font-weight: bold;">{{ 'ForwarderPage.EditPage.CompanyName' | translate }}</label>
                                <input id="companyName" formControlName="companyName" type="text" required pInputText placeholder="Nombre de contacto"/>
                                <small *ngIf="submit && formGroup.controls['companyName'].invalid" class="p-error">{{getErrorMessage(formGroup, 'companyName')}}</small>
                            </div>
                            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
                                <label for="country" style="font-weight: bold;">{{ 'ForwarderPage.EditPage.ContactCountry' | translate }}</label>
                                <p-dropdown id="country"
                                    formControlName="countryId"
                                    [options]="countries"
                                    optionValue="id"
                                    optionLabel="name"
                                    [filter]="true"
                                    filterBy="name"
                                    [showClear]="true"
                                    placeholder="{{ 'ForwarderPage.EditPage.SelectContactCountry' | translate }}"
                                    (onChange)="onChangeCountry()">
                                    <ng-template pTemplate="selectedItem">
                                        <div class="flex align-items-center gap-2" *ngIf="selectedCountry">
                                            <img [src]="'../../../../assets/img/flags/' + selectedCountry.code + '.png'" style="width: 18px" alt=""/>
                                            <div>{{ selectedCountry | countryDescription }}</div>
                                        </div>
                                    </ng-template>
                                    <ng-template let-country pTemplate="item">
                                        <div class="flex align-items-center gap-2">
                                            <img [src]="'../../../../assets/img/flags/' + country.code + '.png'" style="width: 18px" alt=""/>
                                            <div>{{ country | countryDescription }}</div>
                                        </div>
                                    </ng-template>
                                </p-dropdown>
                                <small *ngIf="submit && formGroup.controls['countryId'].invalid" class="p-error">{{getErrorMessage(formGroup, 'countryId')}}</small>
                            </div>
                            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
                                <label for="contactName" style="font-weight: bold;">{{ 'ForwarderPage.EditPage.ContactName' | translate }}</label>
                                <input id="contactName" formControlName="contactName" type="text" required pInputText placeholder="{{ 'ForwarderPage.EditPage.ContactName' | translate }}"/>
                                <small *ngIf="submit && formGroup.controls['contactName'].invalid" class="p-error">{{getErrorMessage(formGroup, 'contactName')}}</small>
                            </div>
                            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
                                <label for="contactLastName" style="font-weight: bold;">{{ 'ForwarderPage.EditPage.ContactLastName' | translate }}</label>
                                <input id="contactLastName" formControlName="contactLastName" type="text" required pInputText placeholder="{{ 'ForwarderPage.EditPage.ContactLastName' | translate }}"/>
                                <small *ngIf="submit && formGroup.controls['contactLastName'].invalid" class="p-error">{{getErrorMessage(formGroup, 'contactLastName')}}</small>
                            </div>
                            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
                                <label for="contactEmail" style="font-weight: bold;">{{ 'ForwarderPage.EditPage.ContactEmail' | translate }}</label>
                                <input id="contactEmail" formControlName="contactEmail" type="text" required pInputText placeholder="{{ 'ForwarderPage.EditPage.ContactEmail' | translate }}"/>
                                <small *ngIf="submit && formGroup.controls['contactEmail'].invalid" class="p-error">{{getErrorMessage(formGroup, 'contactEmail')}}</small>
                            </div>
                        </div>
                    </p-fieldset>
                    
                    <p-fieldset legend="{{ 'ForwarderPage.EditPage.LegendUsers' | translate }}">
                        <div class="p-fluid formgrid grid">
                            <div class="field col-12 xl:col-12 lg:col-12" style="margin-bottom: 40px;">
                                <p-table [value]="users" [tableStyle]="{'min-width': '50rem'}">
                                    <ng-template pTemplate="caption">
                                        <div class="flex justify-content-end flex-wrap align-items-center" style="padding: 15px;">
                                            <p-button [loading]="loading" label="{{ 'ForwarderPage.EditPage.ButtonCreateUser' | translate }}" (onClick)="visibleDialog = true"/>
                                        </div>
                                        </ng-template>
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th>{{ 'ForwarderPage.EditPage.Table.ContactName' | translate }}</th>
                                            <th>{{ 'ForwarderPage.EditPage.Table.ContactEmail' | translate }}</th>
                                            <th>{{ 'ForwarderPage.EditPage.Table.ContactPhone' | translate }}</th>
                                            <th style="text-align: center;">{{ 'ForwarderPage.EditPage.Table.ForwarderType' | translate }}</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-user let-rowIndex="rowIndex">
                                        <tr>
                                            <td>{{user.profile.contactName}}</td>
                                            <td>{{user.contactEmail}}</td>
                                            <td>{{user.contactPhone}}</td>
                                            <td>
                                                <div class="flex justify-content-end align-items-center gap-4">
                                                    <span>{{user.isAdmin ? ('ForwarderPage.EditPage.LabelAdmin' | translate) : ('ForwarderPage.EditPage.LabelUser' | translate)}}</span>
                                                    <p-button *ngIf="!user.isAdmin" pRipple icon="pi pi-lock" (onClick)="onSelectAdmin(user)" pTooltip="{{ 'ForwarderPage.EditPage.TooltipAssignAdmin' | translate }}"></p-button>
                                                    <p-button *ngIf="user.id === 0" pRipple icon="pi pi-times" (onClick)="removeUser(rowIndex)" styleClass="p-button-danger" pTooltip="{{ 'ForwarderPage.EditPage.TooltipRemoveUser' | translate }}"></p-button>
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                        </div>
                    </p-fieldset>

                </form>
                <div class="grid" style="padding: 20px;">
                    <div class="col-6 col-offset-6">
                        <div class="grid p-fluid">
                            <div class="col-12 md:col-6 lg:col-6">
                                <p-button [loading]="loading" label="{{ 'ForwarderPage.EditPage.ButtonSave' | translate }}" (onClick)="editCompany()"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-card>
</app-user-dashboard>

<p-dialog [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleDialog" [style]="{width: '80%'}">
    <ng-template pTemplate="body">
        <form [formGroup]="formUserGroup">
            <div class="p-fluid formgrid grid">
                <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
                    <label for="userContactName" style="font-weight: bold;">{{ 'ForwarderPage.EditPage.Modal.ContactName' | translate }}</label>
                    <input id="userContactName" formControlName="userContactName" type="text" required pInputText placeholder="{{ 'ForwarderPage.EditPage.Modal.ContactName' | translate }}"/>
                    <small *ngIf="submit && formUserGroup.controls['userContactName'].invalid" class="p-error">{{getErrorMessage(formUserGroup, 'userContactName')}}</small>
                </div>
                <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
                    <label for="userContactLastName" style="font-weight: bold;">{{ 'ForwarderPage.EditPage.Modal.ContactLastName' | translate }}</label>
                    <input id="userContactLastName" formControlName="userContactLastName" type="text" required pInputText placeholder="{{ 'ForwarderPage.EditPage.Modal.ContactLastName' | translate }}"/>
                    <small *ngIf="submit && formUserGroup.controls['userContactLastName'].invalid" class="p-error">{{getErrorMessage(formUserGroup, 'userContactLastName')}}</small>
                </div>
                <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
                    <label for="userContactEmail" style="font-weight: bold;">{{ 'ForwarderPage.EditPage.Modal.ContactEmail' | translate }}</label>
                    <input id="userContactEmail" formControlName="userContactEmail" type="text" pInputText placeholder="{{ 'ForwarderPage.EditPage.Modal.ContactEmail' | translate }}"/>
                    <small *ngIf="submit && formUserGroup.controls['userContactEmail'].invalid" class="p-error">{{getErrorMessage(formUserGroup, 'userContactEmail')}}</small>
                </div>
                <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
                    <label for="contactCountry" style="font-weight: bold;">{{ 'ForwarderPage.EditPage.Modal.ContactCountry' | translate }}</label>
                    <p-dropdown id="contactCountry"
                        formControlName="userCountryId"
                        [options]="countries"
                        optionValue="id"
                        optionLabel="name"
                        [filter]="true"
                        filterBy="name"
                        [showClear]="true"
                        placeholder="{{ 'ForwarderPage.EditPage.SelectContactCountry' | translate }}"
                        (onChange)="onChangeUserCountry()">
                        <ng-template pTemplate="selectedItem">
                            <div class="flex align-items-center gap-2" *ngIf="selectedUserCountry">
                                <img [src]="'../../../../assets/img/flags/' + selectedUserCountry.code + '.png'" style="width: 18px" alt=""/>
                                <div>{{ selectedUserCountry | countryDescription }}</div>
                            </div>
                        </ng-template>
                        <ng-template let-country pTemplate="item">
                            <div class="flex align-items-center gap-2">
                                <img [src]="'../../../../assets/img/flags/' + country.code + '.png'" style="width: 18px" alt=""/>
                                <div>{{ country | countryDescription }}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                    <small *ngIf="submit && formUserGroup.controls['userCountryId'].invalid" class="p-error">{{getErrorMessage(formUserGroup, 'userCountryId')}}</small>
                </div>
                <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
                    <label for="userContactCity" style="font-weight: bold;">{{ 'ForwarderPage.EditPage.Modal.ContactCity' | translate }}</label>
                    <input id="userContactCity" formControlName="userContactCity" type="text"  pInputText placeholder="{{ 'ForwarderPage.EditPage.Modal.ContactCity' | translate }}"/>
                    <small *ngIf="submit && formUserGroup.controls['userContactCity'].invalid" class="p-error">{{getErrorMessage(formUserGroup, 'userContactCity')}}</small>
                </div>
                <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
                    <label for="userContactAddress" style="font-weight: bold;">{{ 'ForwarderPage.EditPage.Modal.ContactAddress' | translate }}</label>
                    <input id="userContactAddress" formControlName="userContactAddress" type="text" pInputText placeholder="{{ 'ForwarderPage.EditPage.Modal.ContactAddress' | translate }}"/>
                </div>
                <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
                    <label for="userContactPhone" style="font-weight: bold;">{{ 'ForwarderPage.EditPage.Modal.ContactPhone' | translate }}</label>
                    <input id="userContactPhone" formControlName="userContactPhone" type="text" pInputText placeholder="{{ 'ForwarderPage.EditPage.Modal.ContactPhone' | translate }}"/>
                </div>
            </div>
        </form>
    </ng-template>
    <ng-template pTemplate="footer">
        <p-button [loading]="loading" label="{{ 'ForwarderPage.EditPage.Modal.ButtonSave' | translate }}" (onClick)="saveUser()"/>
    </ng-template>
</p-dialog>

<p-toast></p-toast>