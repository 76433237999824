import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { DocumentServiceGateway } from 'src/app/application/gateway/DocumentServiceGateway';
import { ImportOrderInformation } from 'src/app/domain/models/ImportOrder';
import { BasicUser } from 'src/app/domain/models/User';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-import-order-operation',
  templateUrl: './import-order-operation.component.html',
  styleUrls: ['./import-order-operation.component.css'],
  providers: [MessageService]
})
export class ImportOrderOperationComponent implements OnInit {
  @Input()
  order: ImportOrderInformation | undefined

  profile: BasicUser | undefined

  constructor(private _messageService: MessageService,
    private _documentService: DocumentServiceGateway,
    public translate: TranslateService,
    private _authService: AuthGateway) {
  }

  ngOnInit(): void {
    this.profile = this._authService.getUser()
  }

  getOpenFile(url: string){
    window.open(environment.apiUrl + url, '_blank');
  }

  get environment() {
    return environment
  }

  getDocumentType(documentType: number): string {
    let documentTypeName = ''
    if(this.profile) {
      switch(this.profile.lang) {
        case 'en':
          switch(documentType) {
            case 1:
              documentTypeName = 'Fresh fruit QC review'
            break
            case 2:
              documentTypeName = 'Fresh fruit QC consolidation'
            break
            case 3:
              documentTypeName = 'Frozen fruit QC review'
            break
            case 4:
              documentTypeName = 'Frozen fruit QC consolidation'
            break
            case 5:
              documentTypeName = 'Operation document'
            break
            case 6:
              documentTypeName = 'Product certificate document'
            break
            case 7:
              documentTypeName = 'Export offer document'
            break
          }
          break
        case 'es':
          switch(documentType) {
            case 1:
              documentTypeName = 'Revisión control de calidad fruta fresca'
            break
            case 2:
              documentTypeName = 'Consolidación control de calidad fruta fresca'
            break
            case 3:
              documentTypeName = 'Revisión control de calidad fruta congelada'
            break
            case 4:
              documentTypeName = 'Consolidación control de calidad fruta congelada'
            break
            case 5:
              documentTypeName = 'Documento de operación'
            break
            case 6:
              documentTypeName = 'Documento certificación de producto'
            break
            case 7:
              documentTypeName = 'Documento técnico de oferta de exportación'
            break
          }
          break
      }
    }
    return documentTypeName
  }

  getOperationDocument(documentId: number) {
    this._documentService.getOperationDocument(documentId).subscribe((resultBlob: any) => {
      var downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }

  getProductCertificateDocument(productCertificateId: number, importOrderId: any) {
    this._documentService.getProductCertificateDocument(productCertificateId, importOrderId).subscribe((resultBlob: any) => {
      var downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }

  getExportOfferDocument(exportOfferDocumentId: number, importOrderId: any) {
    this._documentService.getExportOfferDocument(exportOfferDocumentId, importOrderId).subscribe((resultBlob: any) => {
      var downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }

  getQCReviewDocument(importOrderDetailId: any) {
    this._documentService.getQCReviewDocument(importOrderDetailId).subscribe((resultBlob: any) => {
      var downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }

  getQCConsolidationDocument(importOrderDetailId: any) {
    this._documentService.getQCConsolidationDocument(importOrderDetailId).subscribe((resultBlob: any) => {
      var downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }

  viewOriginQCReviewDocument(qcReviewGuid: string) {
    this._documentService.getOriginQCReviewDocument(qcReviewGuid).subscribe((resultBlob: any) => {
      const downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }

  viewOriginQCConsolidationDocument(qcReviewGuid: string) {
    this._documentService.getOriginQCConsolidationDocument(qcReviewGuid).subscribe((resultBlob: any) => {
      const downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }

  getPaymentDocument(paymentId: number) {
    this._documentService.getPaymentDocument(paymentId).subscribe((resultBlob: any) => {
      var downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }

  getFrozenFruitQCReviewDocument(importOrderDetailId: any) {
    this._documentService.getFrozenFruitQCReviewDocument(importOrderDetailId).subscribe((resultBlob: any) => {
      var downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }

  getFrozenFruitQCConsolidationDocument(importOrderDetailId: any) {
    this._documentService.getFrozenFruitQCConsolidationDocument(importOrderDetailId).subscribe((resultBlob: any) => {
      var downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }

  getFrozenFruitPesticideAnalysisDocument(importOrderDetailId: any) {
    this._documentService.getFrozenFruitPesticideAnalysisDocument(importOrderDetailId).subscribe((resultBlob: any) => {
      var downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }

  getFrozenFruitHeavyMetalsAnalysisDocument(importOrderDetailId: any) {
    this._documentService.getFrozenFruitHeavyMetalsAnalysisDocument(importOrderDetailId).subscribe((resultBlob: any) => {
      var downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }

  getFrozenFruitMicrobiologicalAnalysisDocument(importOrderDetailId: any) {
    this._documentService.getFrozenFruitMicrobiologicalAnalysisDocument(importOrderDetailId).subscribe((resultBlob: any) => {
      var downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }

  getFrozenFruitWaterAnalysisDocument(importOrderDetailId: any) {
    this._documentService.getFrozenFruitWaterAnalysisDocument(importOrderDetailId).subscribe((resultBlob: any) => {
      var downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }
}
