import { Component, Input, OnInit } from '@angular/core';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { BasicUser } from 'src/app/domain/models/User';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.css']
})
export class DisclaimerComponent implements OnInit {
  visibleDisclaimerENGDialog: boolean = false
  visibleDisclaimerESPDialog: boolean = false

  profile: BasicUser | undefined

  isVisible: boolean = true

  constructor(
    private _authService: AuthGateway,
  ){
  }

  ngOnInit(): void {
    this.profile = this._authService.getUser()

    const disclaimValue = localStorage.getItem('_dsclm_sh')
    let showModal = true
    if (disclaimValue) {
      const parseValue = JSON.parse(disclaimValue);
      if (typeof parseValue !== 'boolean') showModal = true
      if (parseValue) showModal = false
    }
    if (showModal) this.openDisclaimerDialog()

    this.loadBoolean();

  }

  openDisclaimerDialog() {
    localStorage.setItem('_dsclm_sh', JSON.stringify(true))
    switch(this.profile?.lang) {
      case 'en':
        this.visibleDisclaimerENGDialog = true
        break

      case 'es':
        this.visibleDisclaimerESPDialog = true
        break

      default:
         this.visibleDisclaimerENGDialog = true
    }
  }

  closeDisclaimerDialog() {
    this.visibleDisclaimerENGDialog = false
    this.visibleDisclaimerESPDialog = false
  }

  closeDisclaimer(): void {
    this.isVisible = false;
    sessionStorage.setItem('_disclaimer_', JSON.stringify(this.isVisible));
  }

  loadBoolean() {
    const storedDisclaimer = sessionStorage.getItem('_disclaimer_');
    if (storedDisclaimer !== null) {
      this.isVisible = JSON.parse(storedDisclaimer);
    }
  }
}
