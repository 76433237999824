<form [formGroup]="_formGroupFilter">
  <div class="flex flex-column align-items-center gap-3">
    <div>
      <span>{{'FilterExportOffer.TitleLabel' | translate}}</span>
    </div>
    <div>
      <p-calendar formControlName="harvestDate" placeholder="{{'FilterExportOffer.HarvestDatePlaceholder' | translate}}" [showIcon]="true" [style]="{'background-color': '#EDEFF2', 'width': '100%'}" [showButtonBar]="true"></p-calendar>
    </div>
    <div class="w-full">
      <input id="netKilos" formControlName="netKilos" type="text" pInputText placeholder="{{'FilterExportOffer.KilosPerBoxPlaceholder' | translate}}" [style]="{'background-color': '#EDEFF2', 'width': '100%'}"/>
    </div>
    <div class="w-full">
      <p-dropdown inputId="marketCountry"
        formControlName="marketCountryId"
        [options]="countries"
        optionValue="id"
        optionLabel="name"
        placeholder="{{'FilterExportOffer.MarketPlaceholder' | translate}}"
        [showClear]="true"
        [style]="{'background-color': '#EDEFF2', 'minWidth':'100%'}"
        (onChange)="onChangeMarketCountry()">
        <ng-template pTemplate="selectedItem">
          <div class="flex align-items-center gap-2" *ngIf="selectedMarketCountry">
            <img [src]="'../../../../assets/img/flags/' + selectedMarketCountry.code + '.png'" style="width: 18px" alt=""/>
            <div>{{ selectedMarketCountry | countryDescription }}</div>
          </div>
        </ng-template>
        <ng-template let-country pTemplate="item">
            <div class="flex align-items-center gap-2">
              <img [src]="'../../../../assets/img/flags/' + country.code + '.png'" style="width: 18px" alt=""/>
              <div>{{ country | countryDescription }}</div>
            </div>
        </ng-template>
      </p-dropdown>
    </div>
    <div class="w-full">
      <p-dropdown id="fridgeStorage"
        formControlName="fridgeStorageId"
        [options]="fridgeStorages"
        optionLabel="name"
        optionValue="id"
        [filter]="true"
        filterBy="name"
        [showClear]="true"
        [style]="{'background-color': '#EDEFF2', 'minWidth':'100%'}"
        placeholder="{{'FilterExportOffer.FridstoragePlaceholder' | translate}}">
      </p-dropdown>
    </div>
    <div class="w-full">
      <input id="speciesSize" formControlName="speciesSize" type="text" pInputText placeholder="{{'FilterExportOffer.SpeciesSizePlaceholder' | translate}}" [style]="{'background-color': '#EDEFF2', 'width': '100%'}"/>
    </div>
    <div class="w-full">
      <input id="price" formControlName="price" type="text" pInputText placeholder="{{'FilterExportOffer.PricePlaceholder' | translate}}" [style]="{'background-color': '#EDEFF2', 'width': '100%'}"/>
    </div>
    <div class="w-full" style="margin-top: 30px; text-align: center; color: #FFF;">
      <p-button label="{{'FilterExportOffer.ApplyFiltersText' | translate}}" class="w-full" (onClick)="applyFilters()">
        <span class="material-symbols-outlined">tune</span>
      </p-button>
    </div>
  </div>
</form>
