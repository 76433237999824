<app-user-dashboard [userMenuActiveItemIndex]="0" [activeItem]="'orders'">
  <p-card [style]="{'min-height':'200px'}">
    <ng-template pTemplate="header">
      <div class="flex justify-content-between flex-wrap align-items-center" style="padding: 15px;">
        <h2>{{'UserPages.UserPurchaseOrders.PageTitle' | translate}}</h2>
      </div>
      <div>
        <p-tabMenu [model]="tabMenuItems" [scrollable]="true" [activeItem]="activeItem" [style]="{padding: '15px'}"></p-tabMenu>
      </div>
    </ng-template>
  </p-card>
</app-user-dashboard>

<p-toast></p-toast>
<p-confirmDialog acceptLabel="{{'UserPages.UserPurchaseOrders.YesLabel' | translate}}" rejectLabel="{{'UserPages.UserPurchaseOrders.NotLabel' | translate}}" rejectButtonStyleClass="p-button-outlined" [style]="{width: '50vw'}"></p-confirmDialog>
