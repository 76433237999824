<app-user-dashboard [userMenuActiveItemIndex]="0" [activeItem]="'support'">
  <div class="grid">
    <div class="col-12">
      <p-card>
        <span style="font-weight: bold; font-size: 1.5em;">{{'UserPages.UserSupport.PageTitle' | translate}}</span>
      </p-card>
    </div>
    <div class="col-12 md:col-8 lg:col-8">
      <p-card>
        <div class="flex justify-content-start flex-wrap align-items-center" style="margin-bottom: 30px; padding: 15px;">
          <form [formGroup]="formGroup">
            <div class="p-fluid formgrid grid">
              <div class="field col-12" style="margin-bottom: 30px;">
                <label for="title" style="font-weight: bold;">{{'UserPages.UserSupport.SubjectFieldLabel' | translate}} *</label>
                <input id="title" formControlName="title" type="text" required pInputText placeholder="{{'UserPages.UserSupport.SubjectFieldPlaceholder' | translate}}"/>
                <small *ngIf="submit && formGroup.controls['title'].invalid" class="p-error">{{getErrorMessage('title')}}</small>
              </div>
              <div class="field col-12">
                <label for="description" style="font-weight: bold;">{{'UserPages.UserSupport.DescriptionFieldLabel' | translate}} *</label>
                <textarea id="description" formControlName="description" rows="5" pInputTextarea [autoResize]="true" placeholder="{{'UserPages.UserSupport.DescriptionFieldPlaceholder' | translate}}"></textarea>
                <small *ngIf="submit && formGroup.controls['description'].invalid" class="p-error">{{getErrorMessage('description')}}</small>
              </div>
              <div class="field col-12 xl:col-6 lg:col-6"></div>
              <div class="field col-12 xl:col-6 lg:col-6">
                <p-button [loading]="loading" label="{{'UserPages.UserSupport.SendButton' | translate}}" (click)="save()"/>
              </div>
              <div class="field col-12">
                <p-message *ngIf="success" severity="success" text="{{'UserPages.UserSupport.SuccessMessage' | translate}}" ></p-message>
              </div>
            </div>
          </form>
        </div>
      </p-card>
    </div>
    <div class="col-12 md:col-4 lg:col-4">
      <div class="grid">
        <div class="col-12">
          <p-card header="{{'UserPages.UserSupport.FrecuentQuestions' | translate}}"></p-card>
        </div>
      </div>
    </div>
  </div>

</app-user-dashboard>
<p-toast></p-toast>
