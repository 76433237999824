import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { CompanyGateway } from 'src/app/application/gateway/CompanyGateway';
import { CountryGateway } from 'src/app/application/gateway/CountryGateway';
import { ProfileGateway } from 'src/app/application/gateway/ProfileGateway';
import { Company, EditCompany, ProfileCompayList } from 'src/app/domain/models/Company';
import { Country } from 'src/app/domain/models/Country';
import { Profile } from 'src/app/domain/models/Profile';
import { Routes } from 'src/app/infrastructure/routes';

@Component({
  selector: 'app-admin-edit-custom-agent',
  templateUrl: './admin-edit-custom-agent.component.html',
  styleUrls: ['./admin-edit-custom-agent.component.css'],
  providers: [MessageService]
})
export class AdminEditCustomAgentComponent implements OnInit {

  countries: Country[] = []
  selectedCountry: Country | undefined
  customAgentId: number | undefined
  customAgent: Company | undefined

  profiles: Profile[] = []
  users: ProfileCompayList[] = []
  selectedUserAdmin: any

  loading: boolean = false
  submit: boolean = false
  success: boolean = false

  formGroup: FormGroup = this._formBuilder.group({
    companyName: ['', Validators.required],
    contactName: ['', Validators.required],
    contactLastName: [null, Validators.required],
    contactPhone: [null, Validators.required],
    contactEmail: ['', [Validators.required, Validators.email]],
    countryId: [null, Validators.required],
    profileId: [null]
  })

  constructor(private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _messageService: MessageService,
    private _countryService: CountryGateway,
    private _companyService: CompanyGateway,
    private _profileService: ProfileGateway,
    public translate: TranslateService) {
      this._activatedRoute.params.subscribe(params => {
        this.customAgentId = params['customAgentId']
      })
  }

  ngOnInit(): void {
    this.loadCountries()
    this.loadProfiles()
    this.loadCustomAgent()
  }

  loadProfiles() {
    this._profileService.getAll().subscribe({
      next: (v) => {
        this.profiles = v.filter(x => x.type === 6)        
        this.profiles = this.profiles.map((item) => {
          return {
            ...item,
            fullname: `${item.contactName} ${item.contactLastName}`
          }
        })
      },
      error: (e) => {
        console.log(e)
        this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudieron recuperar las perfiles.' });
      },
      complete: () =>  {
        this.loading = false
        console.info('Profile query successfully')
      }
    })
  }

  loadForm() {
    if(this.customAgent) {
      this.customAgentId = this.customAgent.id
      this.formGroup.controls["companyName"].setValue(this.customAgent.companyName)
      this.formGroup.controls["contactName"].setValue(this.customAgent.contactName)
      this.formGroup.controls["contactLastName"].setValue(this.customAgent.contactLastName)
      this.formGroup.controls["contactPhone"].setValue(this.customAgent.contactPhone)
      this.formGroup.controls["contactEmail"].setValue(this.customAgent.contactEmail)
      this.formGroup.controls["countryId"].setValue(this.customAgent.countryId)

      if (this.customAgent.countryId != null) this.onChangeCountry()
    }
  }

  loadCustomAgent(): void {
    if(this.customAgentId) {
      this._companyService.getById(this.customAgentId).subscribe({
        next: (v) => {
          this.customAgent = v
          if (this.customAgent.users) this.users = this.customAgent.users
          this.loadForm()
        },
        error: (e) => {
          console.log(e)
          this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudo recuperar el Custom Agent.' });
        },
        complete: () =>  {
          console.info('Profile query successfully')
        }
      })
    }
  }

  loadCountries(): void {
    this._countryService.getAll().subscribe({
      next: (v) => {
        this.countries = v
      },
      error: (e) => {
        this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudo recuperar la lista de países.' });
        console.log(e)
      },
      complete: () => console.info('Country query successfully')
    })
  }

  getErrorMessage(fieldName: string): string {
    if (this.formGroup.controls[fieldName].hasError('required')) {
      return 'Este campo es requerido.';
    }
    if (this.formGroup.controls[fieldName].hasError('email')) {
      return 'Formato de correo electrónico incorrecto.';
    }
    return '';
  }

  goToBack(): void {
    this._router.navigate(['/' + Routes.adminCustomAgent])
  }

  editCompany(): void{
    this.submit = true
    if(this.formGroup.valid) {
      const company: EditCompany = {
        id: this.customAgentId,
        companyName: this.formGroup.controls['companyName'].value,
        contactName: this.formGroup.controls['contactName'].value,
        contactLastName: this.formGroup.controls['contactLastName'].value,
        contactPhone: this.formGroup.controls['contactPhone'].value,
        contactEmail: this.formGroup.controls['contactEmail'].value,
        countryId: this.formGroup.controls['countryId'].value,
        companyTypeId: 1, // Custom Agent
        users: this.users.map((item) => { return { ...item, company: this.customAgent}})
      }

      this.loading = true
      this._companyService.update(company).subscribe({
        next: (v) => {
          this.success = true
          this.loading = false
          this.submit = false
          this._messageService.add({ severity: 'success', summary: 'Acción exitosa', detail: 'El Custom Agent fue actualizado con éxito.' });
        },
        error: (e) => {
          this.loading = false
          this.submit = false
          this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudo crear el Custom Agent. ' + e.error.messageEs });
          console.log(e)
        },
        complete: () => console.info('Custom Agent created successfully')
      })
    } else {
      this.findInvalidControls()
      this._messageService.add({ severity: 'error',
        summary: this.translate.instant('MessageService.Summary.FailedAction'),
        detail: this.translate.instant('MessageService.FormMessages.FormInvalid') })
    }
  }

  onChangeCountry() {
    this.selectedCountry = this.countries.find(x => x.id === this.formGroup.controls['countryId'].value)
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.formGroup.controls;
    for (const name in controls) {
        if (controls[name].invalid) {
            console.log(name)
            invalid.push(name);
        }
    }
    return invalid;
  }

  onSelectAdmin(selected: ProfileCompayList) {
    this.selectedUserAdmin = selected.profile.id

    this.users.forEach(user => {
      user.isAdmin = user.profile.id === this.selectedUserAdmin;
    });
  }

  addCustomAgent() {
    let profile = this.formGroup.controls['profileId'].value
    if (profile)
    {
      if (this.users.some((item) => item.profile.id === profile.id))
      {
        this._messageService.add({ severity: 'info', summary: 'Custom Agent Selected', detail: 'El usuario ya se encuentra agregado en la lista' });
        return;
      }

      let newUser: ProfileCompayList = {
        isAdmin: false,
        profile: profile,
        id: 0,
        companyId: this.customAgentId ?? 0,
        profileId: profile.id
      }
      this.users.push(newUser)
    }
  }

  removeUser(idx: number) {
    this.users.splice(idx, 1)
  }

}