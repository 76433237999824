import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Consignee } from "src/app/domain/models/Consignee";

@Injectable({providedIn: 'root'})
export abstract class ConsigneeGateway {
  abstract getAll(): Observable<Array<Consignee>>
  abstract getAllByUsername(username: string): Observable<Array<Consignee>>
  abstract getById(id: number): Observable<Consignee>
  abstract create(consignee: Consignee): Observable<Consignee>
  abstract update(consignee: Consignee): Observable<Consignee>
  abstract delete(id: number): Observable<Consignee>
  abstract getAllForClosedSale(importerId: number): Observable<Array<Consignee>>
}
