<app-user-dashboard [fullScreen]="true">
  <app-content-body>

    <div class="bg-container">
      <div class="container" *ngIf="info">
        <div class="col-12">
          <p-breadcrumb class="max-w-full" [model]="breadcrumbItems"></p-breadcrumb>
        </div>
        <div class="section-top">
          <div class="column-left-top">
            <app-import-request-carousel [offer]="info"></app-import-request-carousel>

            <div class="section-description">

              <div class="section-description-info">

                <div class="rowInfo">
                  <div class="container-info">
                    <span class="label-info">{{'MarketplacePage.WishList.ImportRequestModal.ImportRequestDescription.RequiredProductCertificatesLabel' | translate}}:</span>
                    <section *ngIf="info.importRequest?.productCertificateTypes">
                      <ng-container *ngFor="let productCertificateType of info.importRequest.productCertificateTypes">
                        <h3 class="data-main-info" *ngIf="!productCertificateType.isOther">{{ productCertificateType.name }}</h3>
                        <h3 class="data-main-info" *ngIf="productCertificateType.isOther">{{ info.importRequest.otherCertificateType }}</h3>
                      </ng-container>
                    </section>
                    <span *ngIf="info.importRequest.productCertificateTypes?.length == 0" class="text-info">{{ 'UserPages.UserExportOffers.NotResultsMessages' | translate }}</span>
                  </div>

                  <div class="container-info">
                    <span class="label-info">{{'MarketplacePage.WishList.ImportRequestModal.ImportRequestDescription.RequiredPackagingLabel' | translate}}:</span>
                    <section *ngIf="info.importRequest?.packagingTypes">
                      <ng-container *ngFor="let packagingType of info.importRequest.packagingTypes">
                        <h3 class="data-main-info" *ngIf="!packagingType.isOther">{{ packagingType.name }}</h3>
                      </ng-container>
                    </section>
                    <span *ngIf="info.importRequest.packagingTypes?.length == 0" class="text-info">{{ 'UserPages.UserExportOffers.NotResultsMessages' | translate }}</span>
                  </div>

                </div>

              </div>

            </div>
          </div>

          <div class="column-right-top">
            <div>
              <div class="header-card">
                <div class="section-titles">

                  <h2 class="title-product" >
                    {{getHeader()}} / {{getSubheader()}}
                    <!--
                      {{info.importRequest.variety.species.name}} / {{info.importRequest.variety.name}}
                    -->
                  </h2>
                  <!--
                    <p class="title-price">{{'MarketplacePage.WishList.ImportRequestModal.ImportRequestDescription.Total' | translate}} {{ totalPrice | currency: 'USD':'US$'}}
                      {{getIncoterms(info.importRequest.typeSale)}}
                    </p>
                  -->

                </div>
                <div class="section-networks">
                  <app-social-media-share [url]="urlRoute"></app-social-media-share>
                </div>
              </div>

              <div class="section-countries">


                <div class="container-countries">

                  <div class="container-span">
                    <h5 class="span-info">{{'MarketplacePage.WishList.OriginCountryFieldPlaceholder' | translate}}:</h5>
                    <section class="country-section" *ngIf="info.importRequest">
                      <figure class="figure-country">
                        <img *ngIf="info.importRequest.countryFrom" class="img-country" [src]="'../../../../assets/img/flags/' + info.importRequest.countryFrom.code + '.png'" alt=""/>
                        <img *ngIf="!info.importRequest.countryFrom" class="img-country" [src]="'../../../../assets/img/flags/' + allCountries.code + '.png'" alt=""/>
                      </figure>
                      <div class="section-country-text">
                        <section *ngIf="info.importRequest.countryFrom">
                          {{info.importRequest.countryFrom.name }}
                        </section>
                        <section *ngIf="!info.importRequest.countryFrom">
                          {{'ImportRequestDetailComponent.OriginCountryAll' | translate}}
                        </section>
                        <section *ngIf="info.importRequest.countryFrom?.locale">
                          {{info.importRequest.countryFrom?.locale }}
                        </section>
                      </div>
                    </section>
                  </div>
                </div>

                <div class="container-countries">
                  <div class="container-span">
                    <h5 class="span-info">{{'MarketplacePage.WishList.DestinationCountryFieldPlaceholder' | translate}}:</h5>
                  </div>
                  <section class="country-section" *ngIf="info.importRequest">
                    <figure class="figure-country">
                      <img class="img-country" [src]="'../../../../assets/img/flags/' + info.importRequest.country?.code + '.png'" alt=""/>
                    </figure>
                    <div class="section-country-text">
                      <section>
                        {{info.importRequest.country?.name }}
                      </section>
                      <section *ngIf="info.importRequest.country?.locale">
                        {{info.importRequest.country?.locale }}
                      </section>
                    </div>
                  </section>
                </div>

                <div class="container-qr">
                  <app-qr-image [imageQrData]="imageData" width="100px" height="100px"></app-qr-image>
                </div>

              </div>

              <div class="section-main-info">

                <div class="container-main-info">
                  <h5 class="span-info">{{'MarketplacePage.WishList.ImportRequestModal.ImportRequestDescription.CasePerPalletsText' | translate}}:</h5>
                  <h3 class="data-main-info">
                    {{info.importRequest.caseQuantityPerPallet }}
                  </h3>
                </div>

                <div class="container-main-info">
                  <h5 class="span-info">{{'MarketplacePage.WishList.ImportRequestModal.ImportRequestDescription.PricePerCase' | translate}}:</h5>
                  <h3 class="data-main-info">
                    {{ info.importRequest.pricePerCase | currency:'USD':'US$'}}
                    {{ info.importRequest.pricePerCase ? info.importRequest.typeSale : getNoAvailableLabel() }}
                  </h3>
                </div>

                <div class="container-main-info">
                  <h5 class="span-info">{{'MarketplacePage.WishList.ImportRequestModal.ImportRequestDescription.SpeciesSizeText' | translate}}:</h5>
                  <h3 class="data-main-info">
                    {{ info.importRequest.speciesSize ? info.importRequest.speciesSize : getNoAvailableLabel() }}
                  </h3>
                </div>

                <div class="container-main-info">
                  <h5 class="span-info">{{'MarketplacePage.WishList.ImportRequestModal.ImportRequestDescription.BoxTypeText' | translate}}:</h5>
                  <h3 class="data-main-info">
                    {{ info.importRequest.boxType ? info.importRequest.boxType : getNoAvailableLabel() }}
                  </h3>
                </div>

                <div class="container-main-info">
                  <h5 class="span-info">{{'MarketplacePage.WishList.ImportRequestModal.ImportRequestDescription.PaymentAgainstBLLabel' | translate}}:</h5>
                  <h3 class="data-main-info">
                    {{ info.importRequest.paymentAgainstBL>= 0 ? info.importRequest.paymentAgainstBL + '%' : getNoAvailableLabel() }}
                  </h3>
                </div>

              </div>

            <div>
              <h3>{{'MarketplacePage.WishList.ImportRequestModal.CardTitle' | translate}}</h3>
              <p-fieldset legend="{{'MarketplacePage.WishList.ImportRequestModal.FieldSetLegend' | translate}}">
                <section class="section-dropdown">
                  <p-dropdown [options]="requestOffers" [(ngModel)]="selectedOffer" placeholder="{{'MarketplacePage.WishList.ImportRequestModal.OfferSelectFieldPlaceholder' | translate}}"
                    optionLabel="name" [filter]="true" filterBy="name" [showClear]="true"
                    [style]="{'minWidth':'100%'}"
                    class="p-inputtext-sm dropdown-input">
                  </p-dropdown>
                  <p-button class="margin-left" label="{{'MarketplacePage.WishList.ImportRequestModal.SendButton' | translate}}" (onClick)="sendOfferToRequest()"></p-button>
                </section>
              </p-fieldset>

              <div *ngIf="selectedOffer" class="col-12">
                <div class="grid">
                  <div class="col-12">
                    <h3>{{'MarketplacePage.WishList.ImportRequestModal.OfferDescription.Title' | translate}}</h3>
                  </div>

                  <div  class="container-info-selected">


                      <div class="container-main-info">
                        <h5 class="span-info">{{'MarketplacePage.WishList.ImportRequestModal.ImportRequestDescription.CasePerPalletsText' | translate}}:</h5>
                        <h3 class="data-main-info">
                          {{selectedOffer.exportOffer.caseQuantityPerPallet }}
                        </h3>
                      </div>

                      <div class="container-main-info">
                        <h5 class="span-info">{{'MarketplacePage.WishList.ImportRequestModal.ImportRequestDescription.PricePerCase' | translate}}:</h5>
                        <h3 class="data-main-info">
                          {{ selectedOffer.exportOffer.listPrice | currency:'USD':'US$'}}
                          {{ selectedOffer.exportOffer.listPrice ? info.importRequest.typeSale : getNoAvailableLabel() }}
                        </h3>
                      </div>

                      <div class="container-main-info">
                        <h5 class="span-info">{{'MarketplacePage.WishList.ImportRequestModal.ImportRequestDescription.SpeciesSizeText' | translate}}:</h5>
                        <h3 class="data-main-info">
                          {{ selectedOffer.exportOffer.speciesSize ? selectedOffer.exportOffer.speciesSize : getNoAvailableLabel() }}
                        </h3>
                      </div>

                      <div class="container-main-info">
                        <h5 class="span-info">{{'MarketplacePage.WishList.ImportRequestModal.ImportRequestDescription.BoxTypeText' | translate}}:</h5>
                        <h3 class="data-main-info">
                          {{ selectedOffer.exportOffer.boxType ? selectedOffer.exportOffer.boxType : getNoAvailableLabel() }}
                        </h3>
                      </div>

                      <div class="container-main-info">
                        <h5 class="span-info">{{'MarketplacePage.WishList.ImportRequestModal.ImportRequestDescription.PaymentAgainstBLLabel' | translate}}:</h5>
                        <h3 class="data-main-info">
                          {{ selectedOffer.exportOffer.paymentAgainstBL ? selectedOffer.exportOffer.paymentAgainstBL + '%'  : getNoAvailableLabel() }}
                        </h3>
                      </div>


                  </div>

                </div>

              </div>
            </div>

              <div class="flex justify-content-between">
                <!--
                  <app-request-import-request [exportOfferGuid]="exportOfferInfo.exportOffer.guid"></app-request-import-request>
                  <app-qr-image [imageQrData]="imageData" width="120px" height="120px"></app-qr-image>
                -->
              </div>
              <div class="container-bottom">
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </app-content-body>
</app-user-dashboard>
<p-toast></p-toast>
<app-footer-home></app-footer-home>
