<app-admin-dashboard>
  <p-card>
    <ng-template pTemplate="header">
      <div class="flex justify-content-between flex-wrap align-items-center" style="padding-left: 15px;">
        <h2>Información de Pre-BL</h2>
      </div>
    </ng-template>
    <ng-template pTemplate="body">
      <div *ngIf="deliveryInformation">
        <form [formGroup]="formGroup">
          <p-fieldset legend="Información de la orden de compra" [toggleable]="true" [collapsed]="true">
            <h2>Importador</h2>
            <div class="p-fluid formgrid grid" *ngIf="importer">
              <div class="field col-12 md:col-4 lg:col-3">
                <label>Nombre de contacto</label>
                <input [ngModel]="importer.contactName" [ngModelOptions]="{standalone: true}" type="text" pInputText readonly/>
              </div>
              <div class="field col-12 md:col-4 lg:col-3">
                <label>Empresa importadora</label>
                <input [ngModel]="importer.companyName" [ngModelOptions]="{standalone: true}" type="text" pInputText readonly/>
              </div>
              <div class="field col-12 md:col-4 lg:col-3">
                <label>VAT</label>
                <input [ngModel]="importer.vat" [ngModelOptions]="{standalone: true}" type="text" pInputText readonly/>
              </div>
              <div class="field col-12 md:col-4 lg:col-3">
                <label>Nombre representante legal</label>
                <input [ngModel]="importer.legalRepresentativeName" [ngModelOptions]="{standalone: true}" type="text" pInputText readonly/>
              </div>
              <div class="field col-12 md:col-4 lg:col-3">
                <label>Id representante legal</label>
                <input [ngModel]="importer.legalRepresentativeNumber" [ngModelOptions]="{standalone: true}" type="text" pInputText readonly/>
              </div>
              <div class="field col-12 md:col-4 lg:col-3">
                <label>Email de contacto</label>
                <input [ngModel]="importer.contactEmail" [ngModelOptions]="{standalone: true}" type="text" pInputText readonly/>
              </div>
              <div class="field col-12 md:col-4 lg:col-3">
                <label>Teléfono de contacto</label>
                <input [ngModel]="importer.contactPhone" [ngModelOptions]="{standalone: true}" type="text" pInputText readonly/>
              </div>
              <div class="field col-12 md:col-4 lg:col-3">
                <label>Dirección de contacto</label>
                <input [ngModel]="importer.contactAddress" [ngModelOptions]="{standalone: true}" type="text" pInputText readonly/>
              </div>
              <div class="field col-12 md:col-4 lg:col-3">
                <label>Ciudad de contacto</label>
                <input [ngModel]="importer.contactCity" [ngModelOptions]="{standalone: true}" type="text" pInputText readonly/>
              </div>
              <div class="field col-12 md:col-4 lg:col-3">
                <label>País</label>
                <input [ngModel]="importer.country" [ngModelOptions]="{standalone: true}" type="text" pInputText readonly/>
              </div>
            </div>
            <p-divider></p-divider>
            <h2>Detalle de orden de compra</h2>
            <div class="p-fluid formgrid grid">
              <div class="field col-12 md:col-4 lg:col-3">
                <label>Fecha de creación</label>
                <input [ngModel]="deliveryInformation.importOrderCreated | date: 'dd-MM-yyyy'" [ngModelOptions]="{standalone: true}" type="text" pInputText readonly/>
              </div>
            </div>
            <p-table [value]="orderDetails"
              [paginator]="true"
              [rows]="10"
              [showCurrentPageReport]="true"
              [rowsPerPageOptions]="[10, 25, 50]"
              [loading]="loading"
              responsiveLayout="stack" [breakpoint]="'960px'" [tableStyle]="{'min-width': '100%'}">
              <ng-template pTemplate="header">
                <tr>
                  <th>
                    <div class="flex justify-content-center align-items-center">Especie / Variedad</div>
                  </th>
                  <th>
                    <div class="flex justify-content-center align-items-center">Exportador</div>
                  </th>
                  <th>
                    <div class="flex justify-content-center align-items-center">Precio unitario</div>
                  </th>
                  <th>
                    <div class="flex justify-content-center align-items-center">Cajas por pallet</div>
                  </th>
                  <th>
                    <div class="flex justify-content-center align-items-center">Pallets</div>
                  </th>
                  <th>
                    <div class="flex justify-content-center align-items-center">Cajas totales</div>
                  </th>
                  <th>
                    <div *ngIf="isCloseSale" class="flex justify-content-center align-items-center">Precio programado</div>
                    <div *ngIf="!isCloseSale" class="flex justify-content-center align-items-center">Precio total</div>
                  </th>
                  <th *ngIf="isCloseSale">
                    <div class="flex justify-content-center align-items-center">Costo fruta</div>
                  </th>
                  <th></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-orderDetail>
                <tr>
                  <td>
                    <div class="flex justify-content-center align-items-center">{{orderDetail.speciesNameEs}} / {{orderDetail.varietyName}}</div>
                  </td>
                  <td>
                    <div class="flex justify-content-center align-items-center">{{orderDetail.exporterCompanyName}}</div>
                  </td>
                  <td>
                    <div class="flex justify-content-center align-items-center">{{orderDetail.price | currency: 'USD':'US$'}}</div>
                  </td>
                  <td>
                    <div class="flex justify-content-center align-items-center">{{orderDetail.boxesPerPallet}}</div>
                  </td>
                  <td>
                    <div class="flex justify-content-center align-items-center">{{orderDetail.palletsQuantity}}</div>
                  </td>
                  <td>
                    <div class="flex justify-content-center align-items-center">{{getDetailTotalCases(orderDetail)}}</div>
                  </td>
                  <td>
                    <div class="flex justify-content-center align-items-center">{{ getDetailTotalPrice(orderDetail) | currency:'USD':'US$' }}</div>
                  </td>
                  <td *ngIf="isCloseSale">
                    <div class="flex justify-content-center align-items-center">{{ getDetailPalletPrice(orderDetail) | currency:'USD':'US$' }}</div>
                  </td>
                  <td>
                    <div class="flex justify-content-center align-items-center">
                      <p-button label="Ver oferta" (onClick)="openExportOfferDialog(orderDetail)"></p-button>
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
            <p-divider></p-divider>
            <h2>Consignee</h2>
            <div class="p-fluid formgrid grid">
              <div class="field col-12 md:col-4 lg:col-3">
                <label for="consigneeCompanyName">Nombre de empresa</label>
                <input id="consigneeCompanyName" formControlName="consigneeCompanyName" type="text" pInputText pInputText maxlength="150"/>
              </div>
              <div class="field col-12 md:col-4 lg:col-3">
                <label for="consigneeContactName">Nombre de contacto</label>
                <input id="consigneeContactName" formControlName="consigneeContactName" type="text" pInputText pInputText maxlength="150"/>
              </div>
              <div class="field col-12 md:col-4 lg:col-3">
                <label for="consigneeVAT">VAT</label>
                <input id="consigneeVAT" formControlName="consigneeVAT" type="text" pInputText/>
              </div>
              <div class="field col-12 md:col-4 lg:col-3">
                <label for="consigneeContactAddress">Dirección de contacto</label>
                <input id="consigneeContactAddress" formControlName="consigneeContactAddress" type="text" pInputText pInputText maxlength="150"/>
              </div>
              <div class="field col-12 md:col-4 lg:col-3">
                <label for="consigneeContactCity">Ciudad</label>
                <input id="consigneeContactCity" formControlName="consigneeContactCity" type="text" pInputText/>
              </div>
              <div class="field col-12 md:col-4 lg:col-3">
                <label for="consigneeContactCountryId">País</label>
                <p-dropdown id="consigneeContactCountryId" formControlName="consigneeContactCountryId" [options]="countries" optionLabel="name" optionValue="id" placeholder="Selecciones un país"></p-dropdown>
              </div>
              <div class="field col-12 md:col-4 lg:col-3">
                <label for="consigneeContactEmail">Email de contacto</label>
                <input id="consigneeContactEmail" formControlName="consigneeContactEmail" type="text" pInputText pInputText maxlength="150"/>
              </div>
              <div class="field col-12 md:col-4 lg:col-3">
                <label for="consigneeContactPhone">Teléfono de contacto</label>
                <input id="consigneeContactPhone" formControlName="consigneeContactPhone" type="text" pInputText/>
              </div>
            </div>
            <p-divider></p-divider>
            <h2>Notify </h2>
            <div class="p-fluid formgrid grid">
              <div class="field col-12 md:col-4 lg:col-3">
                <label for="notifierCompanyName">Nombre de empresa</label>
                <input id="notifierCompanyName" formControlName="notifierCompanyName" type="text" pInputText maxlength="150"/>
              </div>
              <div class="field col-12 md:col-4 lg:col-3">
                <label for="notifierContactName">Nombre de contacto</label>
                <input id="notifierContactName" formControlName="notifierContactName" type="text" pInputText pInputText maxlength="150"/>
              </div>
              <div class="field col-12 md:col-4 lg:col-3">
                <label for="notifierVAT">VAT</label>
                <input id="notifierVAT" formControlName="notifierVAT" type="text" pInputText/>
              </div>
              <div class="field col-12 md:col-4 lg:col-3">
                <label for="notifierContactAddress">Dirección de contacto</label>
                <input id="notifierContactAddress" formControlName="notifierContactAddress" type="text" pInputText pInputText maxlength="150"/>
              </div>
              <div class="field col-12 md:col-4 lg:col-3">
                <label for="notifierContactCity">Ciudad</label>
                <input id="notifierContactCity" formControlName="notifierContactCity" type="text" pInputText/>
              </div>
              <div class="field col-12 md:col-4 lg:col-3">
                <label for="notifierContactCountryId">País</label>
                <p-dropdown id="notifierContactCountryId" formControlName="notifierContactCountryId" [options]="countries" optionLabel="name" optionValue="id" placeholder="Selecciones un país"></p-dropdown>
              </div>
              <div class="field col-12 md:col-4 lg:col-3">
                <label for="notifierContactEmail">Email de contacto</label>
                <input id="notifierContactEmail" formControlName="notifierContactEmail" type="text" pInputText pInputText maxlength="150"/>
              </div>
              <div class="field col-12 md:col-4 lg:col-3">
                <label for="notifierContactPhone">Teléfono de contacto</label>
                <input id="notifierContactPhone" formControlName="notifierContactPhone" type="text" pInputText/>
              </div>
            </div>
          </p-fieldset>

          <h2>Pre-BL</h2>

          <div class="p-fluid formgrid grid">
            <div class="field col-12 md:col-4 lg:col-3">
              <label for="bl">MBL No</label>
              <input id="bl" formControlName="mbl" type="text" pInputText/>
            </div>
            <div class="field col-12 md:col-4 lg:col-3">
              <label for="bl">BL No</label>
              <input id="bl" formControlName="bl" type="text" pInputText/>
            </div>
            <div class="field col-12 md:col-4 lg:col-3">
              <label for="booking">Booking No</label>
              <input id="booking" formControlName="booking" type="text" pInputText/>
            </div>
            <div class="field col-12 md:col-4 lg:col-3">
              <label for="containerNumber">Container N°</label>
              <input id="containerNumber" formControlName="containerNumber" type="text" pInputText/>
            </div>
            <div class="col-12">
              <p-divider></p-divider>
            </div>
            <div class="field col-12 md:col-4 lg:col-3">
              <label for="grossWeight">Gross Weight</label>
              <input id="grossWeight" formControlName="grossWeight" type="text" pInputText/>
            </div>
            <div class="field col-12 md:col-4 lg:col-3">
              <label for="containerCbm">CBM</label>
              <p-inputNumber id="containerCbm" 
                formControlName="containerCbm" 
                [min]="0" 
                [max]="100" 
                [useGrouping]="false" 
                [maxlength]="7"
                [maxFractionDigits]="2"
                locale="en-US"
                mode="decimal"  
                [suffix]="'%'"
                />
            </div>
            <div class="field col-12 md:col-4 lg:col-3">
              <label for="containerTemperature">Temperature °C</label>
              <p-inputNumber id="containerTemperature" 
                formControlName="containerTemperature" 
                [useGrouping]="false"
                [maxFractionDigits]="2"
                locale="en-US" 
                [maxlength]="5"  
                placeholder="°C" 
                mode="decimal"/>
            </div>            
            <div class="field col-12 md:col-4 lg:col-3">
              <label for="deliveryInstructions">Instructions</label>
              <input id="deliveryInstructions" formControlName="deliveryInstructions" type="text" pInputText/>
            </div>
            <div class="col-12">
              <p-divider></p-divider>
            </div>
            <div class="field col-12 md:col-4 lg:col-3">
              <label for="placeReceipt">Place of receipt</label>
              <input id="placeReceipt" formControlName="placeReceipt" type="text" pInputText/>
            </div>
            <div class="field col-12 md:col-4 lg:col-3">
              <label for="departurePortId">Port of loading *</label>
              <p-dropdown id="departurePortId" formControlName="departurePortId" [options]="ports | portName" optionLabel="fullname" optionValue="id" placeholder="Seleccione un puerto" [filter]="true" filterBy="fullname"></p-dropdown>
              <small *ngIf="submit && formGroup.controls['departurePortId'].invalid" class="p-error">{{getErrorMessage(formGroup, 'departurePortId')}}</small>
            </div>
            <div class="field col-12 md:col-4 lg:col-3">
              <label for="destinationPortId">Port of discharge</label>
              <p-dropdown id="destinationPortId" formControlName="destinationPortId" [options]="ports | portName" optionLabel="fullname" optionValue="id" placeholder="Seleccione un puerto" [filter]="true" filterBy="fullname"></p-dropdown>
              <small *ngIf="submit && formGroup.controls['destinationPortId'].invalid" class="p-error">{{getErrorMessage(formGroup, 'destinationPortId')}}</small>
            </div>

            <div class="field col-12 md:col-4 lg:col-3" *ngIf="delivery?.transportType == 1" >
              <label for="departureAirportId" style="font-weight: bold;">{{ 'ForwarderPage.ForwarderDelivery.Delivery.OriginAirport' | translate }} *</label>
              <p-dropdown id="departureAirportId" formControlName="departureAirportId" [options]="airPorts | portName" optionLabel="fullname" optionValue="id" placeholder="{{ 'ForwarderPage.ForwarderDelivery.Delivery.PortSelect' | translate }}"></p-dropdown>
              <small *ngIf="submit && formGroup.controls['departureAirportId'].invalid" class="p-error">{{getErrorMessage(formGroup, 'departureAirportId')}}</small>
            </div>
            <div class="field col-12 md:col-4 lg:col-3" *ngIf="delivery?.transportType == 1">
              <label for="destinationAirportId">{{ 'ForwarderPage.ForwarderDelivery.Delivery.DestinationAirport' | translate }}</label>
              <p-dropdown id="destinationAirportId" formControlName="destinationAirportId" [options]="airPorts | portName" optionLabel="fullname" optionValue="id" placeholder="{{ 'ForwarderPage.ForwarderDelivery.Delivery.PortSelect' | translate }}"></p-dropdown>
              <small *ngIf="submit && formGroup.controls['destinationAirportId'].invalid" class="p-error">{{getErrorMessage(formGroup, 'destinationAirportId')}}</small>
            </div>

            <div class="field col-12 md:col-4 lg:col-3">
              <label for="week">Week</label>
              <p-inputNumber id="week" formControlName="week" [useGrouping]="false" [min]="1" [max]="52" [maxlength]="5"/>
            </div>
            <div class="field col-12 md:col-4 lg:col-3">
              <label for="placeDelivery">Place of delivery</label>
              <input id="placeDelivery" formControlName="placeDelivery" type="text" pInputText/>
            </div>
            <div class="field col-12 md:col-4 lg:col-3">
              <label for="ship">Vessel name/Flight Number</label>
              <input id="ship" formControlName="ship" type="text" pInputText/>
            </div>
            <div class="field col-12 md:col-4 lg:col-3">
              <label for="voyageNumber">Voyage</label>
              <input id="voyageNumber" formControlName="voyageNumber" type="text" pInputText/>
            </div>
            <div class="field col-12 md:col-4 lg:col-3">
              <label for="typeMovement">Type of movement</label>
              <input id="typeMovement" formControlName="typeMovement" type="text" pInputText/>
            </div>
            <div class="field col-12 md:col-4 lg:col-3">
              <label for="etd">ETD</label>
              <p-calendar
                inputId="etd"
                formControlName="etd"
                appendTo="body"
                [showButtonBar]="true"
                [showIcon]="true"
                [showWeek]="true"
                [firstDayOfWeek]="1"></p-calendar>
            </div>
            <div class="field col-12 md:col-4 lg:col-3">
              <label for="eta">ETA</label>
              <p-calendar
                inputId="eta"
                formControlName="eta"
                appendTo="body"
                [showButtonBar]="true"
                [showIcon]="true"
                [showWeek]="true"
                [firstDayOfWeek]="1"></p-calendar>
            </div>
            <div class="field col-12 md:col-4 lg:col-3">
              <label for="stackingStart">Stacking start</label>
              <p-calendar
                inputId="stackingStart"
                formControlName="stackingStart"
                appendTo="body"
                [showButtonBar]="true"
                [showIcon]="true"
                [showWeek]="true"
                [showTime]="true"
                [firstDayOfWeek]="1"></p-calendar>
            </div>
            <div class="field col-12 md:col-4 lg:col-3">
              <label for="stackingEnd">Stacking end</label>
              <p-calendar
                inputId="stackingEnd"
                formControlName="stackingEnd"
                appendTo="body"
                [showButtonBar]="true"
                [showIcon]="true"
                [showWeek]="true"
                [showTime]="true"
                [firstDayOfWeek]="1"></p-calendar>
            </div>
            <div class="field col-12 md:col-4 lg:col-3">
              <label for="dus">DUS</label>
              <input id="dus" formControlName="dus" type="text" pInputText/>
            </div>
            <div class="field col-12 md:col-4 lg:col-3">
              <label for="sps">SPS</label>
              <input id="sps" formControlName="sps" type="text" pInputText/>
            </div>
            <div class="field col-12 md:col-4 lg:col-3">
              <label for="cutoffDate">Cutoff date</label>
              <p-calendar
                inputId="cutoffDate"
                formControlName="cutoffDate"
                appendTo="body"
                [showButtonBar]="true"
                [showIcon]="true"
                [showWeek]="true"
                [showTime]="true"
                [firstDayOfWeek]="1"></p-calendar>
            </div>
            <div class="field col-12 md:col-4 lg:col-3">
              <label for="carrierName">Carrier name</label>
              <input id="carrierName" formControlName="carrierName" type="text" pInputText/>
            </div>
            <div class="field col-12 md:col-4 lg:col-3">
              <label for="departurePatentTruck">Departure license truck</label>
              <input id="departurePatentTruck" formControlName="departurePatentTruck" type="text" pInputText/>
            </div>
            <div class="field col-12 md:col-4 lg:col-3">
              <label for="departurePatentCar">Departure license car</label>
              <input id="departurePatentCar" formControlName="departurePatentCar" type="text" pInputText/>
            </div>
            <div class="field col-12 md:col-4 lg:col-3">
              <label for="ot">OT</label>
              <input id="ot" formControlName="ot" type="text" pInputText/>
            </div>
            <div class="field col-12 md:col-4 lg:col-3">
              <label for="ref1">Ref 1</label>
              <input id="ref1" formControlName="ref1" type="text" pInputText/>
            </div>
            <div class="field col-12 md:col-4 lg:col-3">
              <label for="ref2">Ref 2</label>
              <input id="ref2" formControlName="ref2" type="text" pInputText/>
            </div>
            <div class="field col-12 md:col-4 lg:col-3">
              <label for="ctr">CTR</label>
              <input id="ctr" formControlName="ctr" type="text" pInputText/>
            </div>
            <div class="col-12">
              <p-divider></p-divider>
            </div>
            <div class="field col-12 md:col-4 lg:col-3">
              <label for="oceanFreight">Ocean freight (USD)</label>
              <p-inputNumber id="oceanFreight" formControlName="oceanFreight" [min]="0" mode="currency" currency="USD" locale="en-US"/>
            </div>
            <div class="field col-12 md:col-4 lg:col-3">
              <label for="inland">Pickup (USD)</label>
              <p-inputNumber id="inland" formControlName="inland" [min]="0" mode="currency" currency="USD" locale="en-US"/>
            </div>
            <div class="field col-12 md:col-4 lg:col-3">
              <label for="localCharges">Local charges (USD)</label>
              <p-inputNumber id="localCharges" formControlName="localCharges" [min]="0" mode="currency" currency="USD" locale="en-US"/>
            </div>
            <div class="field col-12 md:col-4 lg:col-3">
              <label for="customs">Customs (USD)</label>
              <p-inputNumber id="customs" formControlName="customs" [min]="0" mode="currency" currency="USD" locale="en-US"/>
            </div>
            <div class="field col-12 md:col-4 lg:col-3">
              <label for="surcharge">Surcharge (USD)</label>
              <p-inputNumber id="surcharge" formControlName="surcharge" [min]="0" mode="currency" currency="USD" locale="en-US"/>
            </div>
            <div class="field col-12 md:col-4 lg:col-3">
              <label for="surcharge">Handling (USD)</label>
              <p-inputNumber id="handling" formControlName="handling" [min]="0" mode="currency" currency="USD" locale="en-US"/>
            </div>
            <div class="field col-12 md:col-4 lg:col-3">
              <label for="surcharge">Gate out (USD)</label>
              <p-inputNumber id="gateOut" formControlName="gateOut" [min]="0" mode="currency" currency="USD" locale="en-US"/>
            </div>
            <div class="field col-12 md:col-4 lg:col-3">
              <label for="otherCosts">Others (USD)</label>
              <p-inputNumber id="otherCosts" formControlName="otherCosts" [min]="0" mode="currency" currency="USD" locale="en-US"/>
            </div>
            <div class="field col-12 md:col-4 lg:col-3">
              <label>Total cost (USD)</label>
              <p-inputNumber [ngModel]="totalCost" [ngModelOptions]="{standalone: true}"  mode="currency" currency="USD" locale="en-US" [readonly]="true"/>
            </div>
            <div class="col-12" style="margin-bottom: 40px;">

              <p-table [value]="discounts" responsiveLayout="stack" [breakpoint]="'600px'">
                <ng-template pTemplate="caption">
                  <h2>Discounts</h2>
                  <div class="flex align-items-center justify-content-start">
                    <p-button label="New" icon="pi pi-plus" class="mr-2" (onClick)="openDiscountCreateDialog(discountFormDirective)"/>
                  </div>
                </ng-template>
                <ng-template pTemplate="header">
                  <tr>
                    <th><div class="flex justify-content-center align-items-center">Description</div></th>
                    <th><div class="flex justify-content-center align-items-center">Amount</div></th>
                    <th></th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-discount>
                  <tr>
                    <td><div class="flex justify-content-center align-items-center">{{discount.description}}</div></td>
                    <td><div class="flex justify-content-center align-items-center">{{discount.amount | currency: 'USD':'US$'}}</div></td>
                    <td>
                      <div class="flex justify-content-end align-items-center gap-2">
                        <p-button label="Edit" icon="pi pi-pencil" class="mr-2" (onClick)="openDiscountEditDialog(discount)"/>
                        <p-button label="Delete" icon="pi pi-trash" class="mr-2" (onClick)="deleteDiscount(discount.id)"/>
                      </div>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                  <div class="flex align-items-center justify-content-end">
                    <div class="grid">
                      <div class="col-6 flex align-items-center justify-content-end"><span>Total operation cost:</span></div>
                      <div class="col-6 flex align-items-center justify-content-end">{{totalCost | currency: 'USD':'US$'}}</div>
                      <div class="col-6 flex align-items-center justify-content-end"><span>Total discounts:</span></div>
                      <div class="col-6 flex align-items-center justify-content-end">{{totalDiscounts | currency: 'USD':'US$'}}</div>
                      <div class="col-6 flex align-items-center justify-content-end"><span>TOTAL:</span></div>
                      <div class="col-6 flex align-items-center justify-content-end">{{ (totalCost - totalDiscounts) | currency: 'USD':'US$' }}</div>
                    </div>
                  </div>
                </ng-template>
              </p-table>

            </div>
            <div class="col-12">
              <p-divider></p-divider>
            </div>
            <div class="field col-12 md:col-4 lg:col-3">
              <label for="freightPayableAt">Freight payable at</label>
              <input id="freightPayableAt" formControlName="freightPayableAt" type="text" pInputText/>
            </div>
            <div class="field col-12 md:col-4 lg:col-3">
              <label for="shippedOnBoard">Shipped on board</label>
              <p-calendar
                inputId="shippedOnBoard"
                formControlName="shippedOnBoard"
                appendTo="body"
                [showButtonBar]="true"
                [showIcon]="true"
                [showWeek]="true"
                [firstDayOfWeek]="1"></p-calendar>
            </div>
            <div class="field col-12 md:col-4 lg:col-3">
              <label for="placeIssue">Place of issue</label>
              <input id="placeIssue" formControlName="placeIssue" type="text" pInputText/>
            </div>
            <div class="field col-12 md:col-4 lg:col-3">
              <label for="dateIssue">Date of issue</label>
              <p-calendar
                inputId="dateIssue"
                formControlName="dateIssue"
                appendTo="body"
                [showButtonBar]="true"
                [showIcon]="true"
                [showWeek]="true"
                [firstDayOfWeek]="1"></p-calendar>
            </div>
            <div class="field col-12 md:col-4 lg:col-3">
              <label for="excessValueDeclaration">Excess value declaration</label>
              <input id="excessValueDeclaration" formControlName="excessValueDeclaration" type="text" pInputText/>
            </div>
            <div class="field col-12 md:col-4 lg:col-3">
              <label for="seal">Seal</label>
              <input id="seal" formControlName="seal" type="text" pInputText/>
            </div>
            <div class="col-12">
              <p-divider></p-divider>
            </div>
            <div class="col-6"></div>
            <div class="col-6 grid">
              <div class="col-6"><p-button label="Regresar" (onClick)="goToOrderDetail()" styleClass="p-button-outlined"></p-button></div>
              <div class="col-6"><p-button label="Guardar" [loading]="loading" (onClick)="save()"></p-button></div>
            </div>
          </div>
        </form>
      </div>
    </ng-template>
  </p-card>
</app-admin-dashboard>

<p-toast></p-toast>

<p-dialog [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleOfferDetailDialog" [style]="{width: '80%'}">
  <app-export-offer-detail [offer]="selectedExportOffer" [productCertificateTypes]="selectedProductCertificateTypes" [packagingTypes]="selectedPackagingTypes"></app-export-offer-detail>
  <app-frozen-fruit-export-offer-detail [offer]="frozenFruitExportOffer"></app-frozen-fruit-export-offer-detail>
  <ng-template pTemplate="footer">
    <p-button label="Cerrar" (click)="visibleOfferDetailDialog = false"></p-button>
  </ng-template>
</p-dialog>

<p-dialog header="Discount" [(visible)]="discountDialogVisible" [style]="{width: '30%'}" [modal]="true">
  <form [formGroup]="discountFormGroup" #discountFormDirective="ngForm">
    <div class="p-fluid formgrid grid" style="padding: 20px;">
      <div class="field col-12" style="margin-bottom: 40px;">
        <label for="description" style="font-weight: bolder;">Description *</label>
        <input id="description" formControlName="description" type="text" required pInputText [maxlength]="500" placeholder="Description"/>
        <small *ngIf="submitDiscount && discountFormGroup.controls['description'].invalid" class="p-error">{{getErrorMessage(discountFormGroup, 'description')}}</small>
      </div>
      <div class="field col-12" style="margin-bottom: 40px;">
        <label for="amount" style="font-weight: bolder;">Amount *</label>
        <p-inputNumber id="amount" formControlName="amount" mode="currency" currency="USD" locale="en-US"/>
        <small *ngIf="submitDiscount && discountFormGroup.controls['amount'].invalid" class="p-error">{{getErrorMessage(discountFormGroup, 'amount')}}</small>
      </div>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <p-button label="Back" (onClick)="discountDialogVisible = false" styleClass="p-button-outlined"></p-button>
    <p-button label="Save" (onClick)="saveDiscount()" [loading]="loading"></p-button>
  </ng-template>
</p-dialog>
