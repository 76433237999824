import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { CountryGateway } from 'src/app/application/gateway/CountryGateway';
import { QualityControlCompanyGateway } from 'src/app/application/gateway/QualityControlCompanyGateway';
import { SpeciesGateway } from 'src/app/application/gateway/SpeciesGateway';
import { Country } from 'src/app/domain/models/Country';
import { QualityControlCompanyCreateRequest, QualityControlCompanyMarket, QualityControlCompanyOperation, QualityControlCompanySpecies } from 'src/app/domain/models/QCCompany';
import { Species } from 'src/app/domain/models/Species';
import { Routes } from 'src/app/infrastructure/routes';
import { emailMatchValidator, passwordMatchValidator } from '../../util/custom-validators';
import { ErrorMessageUtil } from '../../util/error-message-util';
import { SpeciesUtils } from '../../util/species-utils';
import { formLimit } from '../../util/form-limit';

@Component({
  selector: 'app-signup-qccompany-page',
  templateUrl: './signup-qccompany-page.component.html',
  styleUrls: ['./signup-qccompany-page.component.css'],
  providers: [MessageService]
})
export class SignupQccompanyPageComponent implements OnInit {

  countries: Country[] = []
  selectedCountry: Country | undefined
  selectedOperationCountry: Country | undefined
  selectedMarketCountry: Country | undefined

  species: Species[] = []
  selectedSpecies: Species | undefined
  products: any[] = []
  operations: any[] = []
  markets: any[] = []

  submit: boolean = false
  loading: boolean = false
  success: boolean = false

  productDialogVisible: boolean = false
  operationDialogVisible: boolean = false
  marketDialogVisible: boolean = false

  submitProduct: boolean = false
  submitOperation: boolean = false
  submitMarket: boolean = false

  formLimit = formLimit

  formGroup: FormGroup = this._formBuilder.group({
    vat: [''],
    name: ['', Validators.required],
    lastname: ['', Validators.required],
    companyName: ['', Validators.required],
    countryId: [null, Validators.required],
    city: ['', Validators.required],
    address: ['', Validators.required],
    phone: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    email2: ['', [Validators.required, Validators.email]],
    hasQCSystem: [false, Validators.required],
    isConsolidateContainer: [false, Validators.required],
    consolidationContainerCost: [null],
    consolidationContainerPoints: [null],
    basePricePerPallet: [null, Validators.required],
    basePricePerContainer: [null, Validators.required],
  },
  {
    validators: [emailMatchValidator('email', 'email2')]
  })

  productFormGroup: FormGroup = this._formBuilder.group({
    speciesId: [null, Validators.required],
    standards: [null, Validators.required],
    tolerance: [null, Validators.required],
    qcCost: [0],
    qcCostPerContainer: [0],
  })

  operationFormGroup: FormGroup = this._formBuilder.group({
    countryId: [null, Validators.required],
    inspectorQuantity: [null, Validators.required]
  })

  marketFormGroup: FormGroup = this._formBuilder.group({
    countryId: [null, Validators.required],
  })

  constructor(private _router: Router,
    private _formBuilder: FormBuilder,
    private _messageService: MessageService,
    private _countryGateway: CountryGateway,
    private _speciesService: SpeciesGateway,
    private _qualityControlCompanyService: QualityControlCompanyGateway,
    public translate: TranslateService) {

  }

  ngOnInit(): void {
    this.loadCountries()
    this.loadSpecies()
  }

  loadCountries() {
    this._countryGateway.getAll().subscribe({
      next: (v) => {
        this.countries = v
      },
      error: (e) => {
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Country.ManyRetrieveError') });
        console.log(e)
      },
      complete: () =>  {
        this.loading = false
        console.info('Countries query complete.')
      }
    })
  }

  onChangeCountry() {
    this.selectedCountry = this.countries.find(x => x.id === this.formGroup.controls['countryId'].value)
  }

  loadSpecies() {
    this._speciesService.getAll().subscribe({
      next: (v) => {
        this.species = v
        this.loading = false
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Species.ManyRetrieveError') })
      },
      complete: () => console.info('Species query successfully')
    })
  }

  onChangeSpecies() {
    this.selectedSpecies = this.species.find(x => x.id === this.productFormGroup.controls['speciesId'].value)
  }

  getSpeciesName(species: Species): string {
    return SpeciesUtils.getSpeciesName(this.translate.currentLang, species)
  }

  goToHome() {
    this._router.navigate([Routes.homePage])
  }

  submitForm(): void {
    this.submit = true
    if(this.formGroup.valid) {

      if(this.products.length < 1) {
        this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('SignupQCCompanyPage.QCSpeciesSection.Text2') })
        return
      }

      if(this.operations.length < 1) {
        this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('SignupQCCompanyPage.QCOperationSection.Text2') })
        return
      }

      /**if(this.markets.length < 1) {
        this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('SignupQCCompanyPage.QCMarketsSection.Text2') });
      }**/

      this.products.forEach(x => { x.species = null })
      this.operations.forEach(x => { x.country = null })
      //this.markets.forEach(x => { x.country = null })

      let request: QualityControlCompanyCreateRequest = {
        vat: this.formGroup.controls['vat'].value,
        companyName: this.formGroup.controls['companyName'].value,
        contactName: this.formGroup.controls['name'].value,
        contactLastName: this.formGroup.controls['lastname'].value,
        contactEmail: this.formGroup.controls['email'].value,
        contactPhone: this.formGroup.controls['phone'].value,
        contactAddress: this.formGroup.controls['address'].value,
        contactCountryId: this.formGroup.controls['countryId'].value,
        contactCity: this.formGroup.controls['city'].value,
        hasQCSystem: this.formGroup.controls['hasQCSystem'].value,
        isConsolidateContainer: this.formGroup.controls['isConsolidateContainer'].value,
        qualityControlCompanySpecies: this.products,
        qualityControlCompanyOperations: this.operations,
        qualityControlCompanyMarkets: this.markets,
        basePricePerPallet: this.formGroup.controls['basePricePerPallet'].value,
        basePricePerContainer: this.formGroup.controls['basePricePerContainer'].value
      }

      if(this.formGroup.controls['isConsolidateContainer'].value) {
        request.consolidationContainerCost = this.formGroup.controls['consolidationContainerCost'].value
        request.consolidationContainerPoints = this.formGroup.controls['consolidationContainerPoints'].value
      }

      this.loading = true
      this._qualityControlCompanyService.create(request).subscribe({
        next: (v) => {
          this.success = true
          this.loading = false
          this.submit = false
        },
        error: (e) => {
          this.products.forEach(x => { this.setSpecies(x) })
          this.operations.forEach(x => { this.setOperationCountry(x) })
          this.markets.forEach(x => { this.setMarketCountry(x) })

          this.success = false
          this.loading = false
          this.submit = false
          let errorMessage = ''
          switch(this.translate.currentLang) {
            case 'es':
              errorMessage = e.error.messageEs
              break
            case 'en':
              errorMessage = e.error.messageEn
              break
            default:
              errorMessage = e.error.messageEn
          }
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: errorMessage });
          console.log(e)
        },
        complete: () =>  {
          this.loading = false
          this.submit = false
          console.info('QualityControlCompany created successfully')
        }
      })
    }
    else {
      this.loading = false
      this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.RequiredFieldText') });
    }
  }

  getErrorMessage(formGroup: FormGroup, fieldName: string): string {
    return ErrorMessageUtil.getErrorMessage(formGroup, this.translate, fieldName)
  }

  openProductDialogCreate(formDirective: FormGroupDirective) {
    this.selectedProduct = undefined
    formDirective.resetForm()
    this.submitProduct = false
    this.productFormGroup.controls['speciesId'].setValue('')
    this.productFormGroup.controls['standards'].setValue('')
    this.productFormGroup.controls['tolerance'].setValue('')
    this.productFormGroup.controls['qcCost'].setValue(0)
    this.productFormGroup.controls['qcCostPerContainer'].setValue(0)
    this.productDialogVisible = true
  }

  selectedProduct: QualityControlCompanySpecies | undefined
  openProductDialogEdit(product: QualityControlCompanySpecies) {
    this.selectedProduct = product
    this.productFormGroup.controls['speciesId'].setValue(this.selectedProduct.speciesId)
    this.productFormGroup.controls['standards'].setValue(this.selectedProduct.standards)
    this.productFormGroup.controls['tolerance'].setValue(this.selectedProduct.tolerance)
    this.productFormGroup.controls['qcCost'].setValue(this.selectedProduct.qcCost)
    this.productFormGroup.controls['qcCostPerContainer'].setValue(this.selectedProduct.qcCostPerContainer)
    this.submitProduct = false
    this.productDialogVisible = true
  }

  onRemoveProduct(product: QualityControlCompanySpecies) {
    const index = this.products.indexOf(product)
    this.products.splice(index, 1);
  }

  saveProduct() {
    this.submitProduct = true
    const species = this.species.find(x => x.id === this.productFormGroup.controls['speciesId'].value)
    if(this.productFormGroup.valid) {
      if(this.selectedProduct) {
        this.selectedProduct.speciesId = this.productFormGroup.controls['speciesId'].value
        this.selectedProduct.standards = this.productFormGroup.controls['standards'].value
        this.selectedProduct.tolerance = this.productFormGroup.controls['tolerance'].value
        this.selectedProduct.qcCost = this.productFormGroup.controls['qcCost'].value
        this.selectedProduct.qcCostPerContainer = this.productFormGroup.controls['qcCostPerContainer'].value
        this.selectedProduct.species = species
        this.productDialogVisible = false
      }
      else {
        let product: QualityControlCompanySpecies = {
          speciesId: this.productFormGroup.controls['speciesId'].value,
          standards: this.productFormGroup.controls['standards'].value,
          tolerance: this.productFormGroup.controls['tolerance'].value,
          qcCost: this.productFormGroup.controls['qcCost'].value,
          qcCostPerContainer: this.productFormGroup.controls['qcCostPerContainer'].value
        }
        product.species = species
        this.products.push(product)
        this.productDialogVisible = false
      }
    }
  }

  onChangeOperationCountry() {
    this.selectedOperationCountry = this.countries.find(x => x.id === this.operationFormGroup.controls['countryId'].value)
  }

  openOperationDialogCreate(formDirective: FormGroupDirective) {
    this.selectedOperation = undefined
    formDirective.resetForm()
    this.submitOperation = false
    this.operationFormGroup.controls['countryId'].setValue('')
    this.operationFormGroup.controls['inspectorQuantity'].setValue(1)
    this.operationDialogVisible = true
  }

  selectedOperation: QualityControlCompanyOperation | undefined
  openOperationDialogEdit(operation: QualityControlCompanyOperation) {
    this.selectedOperation = operation
    this.operationFormGroup.controls['countryId'].setValue(this.selectedOperation.countryId)
    this.operationFormGroup.controls['inspectorQuantity'].setValue(this.selectedOperation.inspectorQuantity)
    this.submitOperation = false
    this.operationDialogVisible = true
  }

  onRemoveOperation(operation: QualityControlCompanyOperation) {
    const index = this.operations.indexOf(operation)
    this.operations.splice(index, 1);
  }

  saveOperation() {
    this.submitOperation = true
    const country = this.countries.find(x => x.id === this.operationFormGroup.controls['countryId'].value)
    if(this.operationFormGroup.valid) {
      if(this.selectedOperation) {
        this.selectedOperation.countryId = this.operationFormGroup.controls['countryId'].value
        this.selectedOperation.inspectorQuantity = this.operationFormGroup.controls['inspectorQuantity'].value
        this.selectedOperation.country = country
        this.operationDialogVisible = false
      }
      else {
        let operation: QualityControlCompanyOperation = {
          countryId: this.operationFormGroup.controls['countryId'].value,
          inspectorQuantity: this.operationFormGroup.controls['inspectorQuantity'].value
        }
        operation.country = country
        this.operations.push(operation)
        this.operationDialogVisible = false
      }
    }
  }

  onChangeMarketCountry() {
    this.selectedMarketCountry = this.countries.find(x => x.id === this.marketFormGroup.controls['countryId'].value)
  }

  openMarketDialogCreate(formDirective: FormGroupDirective) {
    this.selectedMarket = undefined
    formDirective.resetForm()
    this.submitMarket = false
    this.marketFormGroup.controls['countryId'].setValue('')
    this.marketDialogVisible = true
  }

  selectedMarket: QualityControlCompanyMarket | undefined
  openMarketDialogEdit(market: QualityControlCompanyMarket) {
    this.selectedMarket = market
    this.marketFormGroup.controls['countryId'].setValue(this.selectedMarket.countryId)
    this.submitMarket = false
    this.marketDialogVisible = true
  }

  onRemoveMarket(market: QualityControlCompanyMarket) {
    const index = this.markets.indexOf(market)
    this.markets.splice(index, 1);
  }

  saveMarket() {
    this.submitMarket = true
    const country = this.countries.find(x => x.id === this.marketFormGroup.controls['countryId'].value)
    if(this.marketFormGroup.valid) {
      if(this.selectedMarket) {
        this.selectedMarket.countryId = this.marketFormGroup.controls['countryId'].value
        this.selectedMarket.country = country
        this.marketDialogVisible = false
      }
      else {
        let market: QualityControlCompanyMarket = {
          countryId: this.marketFormGroup.controls['countryId'].value
        }
        market.country = country
        this.markets.push(market)
        this.marketDialogVisible = false
      }
    }
  }

  setSpecies(product: QualityControlCompanySpecies) {
    const species = this.species.find(x => x.id === this.productFormGroup.controls['speciesId'].value)
    product.species = species
  }

  setOperationCountry(operation: QualityControlCompanyOperation) {
    const country = this.countries.find(x => x.id === this.operationFormGroup.controls['countryId'].value)
    operation.country = country
  }

  setMarketCountry(market: QualityControlCompanyMarket) {
    const country = this.countries.find(x => x.id === this.marketFormGroup.controls['countryId'].value)
    market.country = country
  }

  get emaildMatchError(): boolean {
    return this.formGroup.getError('emailMismatch')
  }

  getEmailMissMatchMessage() {
    const lang = this.translate.currentLang
    switch(lang) {
      case 'en':
        return 'Email does not match.'
      case 'es':
        return 'Los emails no coinciden.'
      default:
        return 'Email does not match.'
    }
  }
}
