import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { UserJoinRequest } from "src/app/domain/models/UserJoinRequest"
import { Routes } from "src/app/infrastructure/routes"
import { UserJoinRequestGateway } from "../gateway/UserJoinRequestGateway"

@Injectable({providedIn: 'root'})
export class UserJoinRequestService extends UserJoinRequestGateway {
  constructor(private _http: HttpClient) {
    super()
  }

  create(request: UserJoinRequest): Observable<UserJoinRequest> {
    return this._http.post<any>(Routes.userJoinRequestApi + '/Create', request)
  }

  getById(id: string): Observable<UserJoinRequest> {
    return this._http.get<any>(Routes.userJoinRequestApi + '/GetById', {params: {id}})
  }
}
