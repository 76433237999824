import { Component } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Routes } from 'src/app/infrastructure/routes';

@Component({
  selector: 'app-container-order-page',
  templateUrl: './container-order-page.component.html',
  styleUrls: ['./container-order-page.component.css']
})
export class ContainerOrderPageComponent {
  pallets: any[] = [{id: 1},{id: 2}]
  breadcrumbItems: MenuItem[] = []
  home: MenuItem

  constructor() {
    this.breadcrumbItems = [{ label: 'Container' }, { label: 'Confirmar orden', disabled: true }]
    this.home = { label: 'Ordenes', routerLink: '/' + Routes.purchaseOrdersPage }
  }
}
