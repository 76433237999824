import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { ImportOrderGateway } from 'src/app/application/gateway/ImportOrderGateway';
import { Delivery } from 'src/app/domain/models/Delivery';
import { ImportOrderInformation } from 'src/app/domain/models/ImportOrder';
import { OperationInformation } from 'src/app/domain/models/Operation';
import { OperationDocument } from 'src/app/domain/models/OperationDocument';
import { BasicUser } from 'src/app/domain/models/User';
import { Routes } from 'src/app/infrastructure/routes';

@Component({
  selector: 'app-user-closed-sale-import-order-detail',
  templateUrl: './user-closed-sale-import-order-detail.component.html',
  styleUrls: ['./user-closed-sale-import-order-detail.component.css'],
  providers: [MessageService]
})
export class UserClosedSaleImportOrderDetailComponent implements OnInit {
  breadcrumbMenuItems: MenuItem[] = []
  breadcrumbHome: MenuItem = {}

  orderId: any | undefined
  directSaleProgramId: any | undefined
  order: ImportOrderInformation | undefined
  profile: BasicUser | undefined
  delivery: Delivery | undefined
  operationInformation: OperationInformation | undefined;
  documents: OperationDocument[] = []

  loading: boolean = false

  constructor(private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _messageService: MessageService,
    private _importOrderService: ImportOrderGateway,
    private _authService: AuthGateway,
    public translate: TranslateService
    ) {
      let breadcrumbHomeItemLabel = 'My direct sales'
      let breadcrumbMenuItemLabel1 = 'Direct sale order program'
      let breadcrumbMenuItemLabel2 = 'Import order detail'

      this.profile = this._authService.getUser()
      if(this.profile) {
        switch(this.profile.lang) {
          case 'en':
            breadcrumbHomeItemLabel = 'My direct sales'
            breadcrumbMenuItemLabel1 = 'Direct sale order program'
            breadcrumbMenuItemLabel2 = 'Import order detail'
            break
          case 'es':
            breadcrumbHomeItemLabel = 'Mis ventas directas'
            breadcrumbMenuItemLabel1 = 'Programa de venta directa'
            breadcrumbMenuItemLabel2 = 'Detalle de orden de importación'
            break
        }
      }

      this._activatedRoute.params.subscribe(params => {
        this.orderId = params['orderId']
        this.directSaleProgramId = params['directSaleProgramId']

        if(this.profile?.profileType == 'Exporter')
          this.breadcrumbHome = {label: breadcrumbHomeItemLabel, routerLink: '/' + Routes.userClosedSales }
        else if(this.profile?.profileType == 'Importer')
          this.breadcrumbHome = {label: breadcrumbHomeItemLabel, command: () => this.goToDirectSaleProgram() }

        this.breadcrumbMenuItems = [
          {label: breadcrumbMenuItemLabel1, command: () => this.goToDirectSaleProgram() },
          {label: breadcrumbMenuItemLabel2, disabled: true}
        ]
      })
  }

  ngOnInit(): void {
    this.loadInfo()
  }

  loadInfo() {
    if(this.orderId) {
      this._importOrderService.getById(this.orderId).subscribe({
        next: (v) => {
          this.order = v
        },
        error: (e) => {
          console.log(e)
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ImportOrder.SingleRetrieveError') });
        },
        complete: () => console.info('Order query successfully')
      })
    }
  }

  goToDirectSaleProgram() {
    if(this.profile) {
      if(this.profile.profileType == 'Importer') {
        this._router.navigate(['/' + Routes.importerClosedSaleOrdersPage])
      }
      else if (this.profile.profileType == 'Exporter') {
        this._router.navigate(['/' + Routes.exporterClosedSaleOrdersPage, this.directSaleProgramId])
      }
    }
  }
}
