<app-user-dashboard [userMenuActiveItemIndex]="0">
  <p-breadcrumb class="max-w-full" [model]="breadcrumbMenuItems" [home]="breadcrumbHome"></p-breadcrumb>
  <form [formGroup]="formGroup">
    <p-card>
      <ng-template pTemplate="header">
        <div class="flex justify-content-between flex-wrap align-items-center" style="padding: 15px;">
          <h1>{{'UserPages.UserExportOffers.EditExportOffer.PageTitle' | translate}}</h1>
        </div>
        <p style="margin-left: 15px;">{{'UserPages.UserExportOffers.EditExportOffer.PageSubtitle' | translate}}</p>
      </ng-template>
      <ng-template pTemplate="body">

        <h2>{{'UserPages.UserExportOffers.EditExportOffer.PageTitle2' | translate}}</h2>
        <p>{{'UserPages.UserExportOffers.EditExportOffer.PageSubtitle2' | translate}}</p>
        <div class="p-fluid formgrid grid mr-2 ml-2" style="margin-top: 40px;">
          <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
            <label for="species" style="font-weight: bolder;">{{'UserPages.UserExportOffers.EditExportOffer.SpecieFieldPlaceholder' | translate}}</label>
            <input pInputText inputId="species" [value]="getSpeciesName(exportOffer?.variety?.species)" readonly/>
          </div>
          <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
            <label for="variety" style="font-weight: bolder;">{{'UserPages.UserExportOffers.EditExportOffer.VarietyFieldLabel' | translate}} *</label>
            <p-dropdown id="variety"
              formControlName="varietyId"
              [options]="varieties"
              optionLabel="name"
              optionValue="id"
              [filter]="true"
              filterBy="name"
              [showClear]="true"
              placeholder="{{'UserPages.UserExportOffers.EditExportOffer.VarietyFieldPlaceholder' | translate}}"
              (onChange)="onChangeVariety()"
              emptyMessage="{{'UserPages.UserExportOffers.EditExportOffer.SpecieFieldLabel' | translate}}">
              <ng-template pTemplate="selectedItem">
                <div class="flex align-items-center gap-2" *ngIf="selectedVariety">
                    <img [src]="selectedVariety.urlIcon" style="width: 18px" alt=""/>
                    <div>{{ selectedVariety.name }}</div>
                </div>
              </ng-template>
              <ng-template let-variety pTemplate="item">
                  <div class="flex align-items-center gap-2">
                      <img [src]="variety.urlIcon" style="width: 18px" alt=""/>
                      <div>{{ variety.name }}</div>
                  </div>
              </ng-template>
            </p-dropdown>
          </div>
          <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
            <label for="caseQuantityPerPallet" style="font-weight: bolder;">{{'UserPages.UserExportOffers.EditExportOffer.CasePerPalletsFieldLabel' | translate}} *</label>
            <p-inputNumber inputId="caseQuantityPerPallet" formControlName="caseQuantityPerPallet" placeholder="{{'UserPages.UserExportOffers.EditExportOffer.CasePerPalletsFieldPlaceholder' | translate}}"></p-inputNumber>
            <small *ngIf="submit && formGroup.controls['caseQuantityPerPallet'].invalid" class="p-error">{{getErrorMessage(formGroup, 'caseQuantityPerPallet')}}</small>
          </div>
          <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
            <label for="listPrice" style="font-weight: bolder;">{{'UserPages.UserExportOffers.EditExportOffer.ListPriceFieldLabel' | translate}} *</label>
            <p-inputNumber inputId="listPrice" [min]="0" formControlName="listPrice" mode="currency" currency="USD" locale="en-US"></p-inputNumber>
          </div>
          <div class="field col-12 md:col-3 lg:col-3">
            <label for="incoterm" style="font-weight: bolder;">{{'UserPages.UserExportOffers.EditExportOffer.IncotermsFieldLabel' | translate}} *</label>
            <p-dropdown inputId="incoterm" formControlName="incoterm" [options]="incotermsOptions" optionLabel="label" optionValue="value" placeholder="{{'UserPages.UserExportOffers.EditExportOffer.IncotermsFieldPlaceholder' | translate}}"></p-dropdown>
            <small *ngIf="formGroup.controls['incoterm'].invalid" class="p-error">{{getErrorMessage(formGroup, 'incoterm')}}</small>
          </div>
          <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
            <label for="availablePallets" style="font-weight: bolder;">{{'UserPages.UserExportOffers.EditExportOffer.AvailablePalletsLabel' | translate}} *</label>
            <p-inputNumber inputId="availablePallets" formControlName="availablePallets" [min]="1" placeholder="{{'UserPages.UserExportOffers.EditExportOffer.AvailablePalletsPlaceholder' | translate}}"></p-inputNumber>
            <small *ngIf="formGroup.controls['availablePallets'].invalid" class="p-error">{{getErrorMessage(formGroup, 'availablePallets')}}</small>
          </div>
          <div class="field col-12 md:col-4 lg:col-4" style="margin-bottom: 40px;">
            <label for="fridgeStorage" style="font-weight: bolder;">{{'UserPages.UserExportOffers.EditExportOffer.FridstorageFieldLabel' | translate}} *</label>
            <p-dropdown id="fridgeStorage"
              formControlName="fridgeStorageId"
              [options]="fridgeStorages"
              optionLabel="name"
              optionValue="id"
              [filter]="true"
              filterBy="name"
              [showClear]="true"
              placeholder="{{'UserPages.UserExportOffers.EditExportOffer.FridstorageFieldPlaceholder' | translate}}"
              emptyMessage="{{'UserPages.UserExportOffers.EditExportOffer.FridgestorageEmptyMessage' | translate}}"
              (onChange)="onChangeFridgestorage()">
              <ng-template pTemplate="selectedItem">
                <div class="flex align-items-center gap-2" *ngIf="selectedFridgestorage">
                  <img [src]="'../../../../assets/img/flags/' + selectedFridgestorage.contactCountry?.code + '.png'" style="width: 18px" alt=""/>
                  <div>{{ selectedFridgestorage.name + ', ' + selectedFridgestorage.contactAddress + ' - ' +  (selectedFridgestorage.contactCountry | countryDescription)}}</div>
                </div>
              </ng-template>
              <ng-template let-fridgestorage pTemplate="item">
                  <div class="flex align-items-center gap-2">
                    <img [src]="'../../../../assets/img/flags/' + fridgestorage.contactCountry.code + '.png'" style="width: 18px" alt=""/>
                    <div>{{ fridgestorage.name + ', ' + fridgestorage.contactAddress + ' - ' +  (fridgestorage.contactCountry | countryDescription)}}</div>
                  </div>
              </ng-template>
            </p-dropdown>
            <small *ngIf="submit && formGroup.controls['fridgeStorageId'].invalid" class="p-error">{{getErrorMessage(formGroup, 'fridgeStorageId')}}</small>
          </div>
        </div>

        <p-divider></p-divider>
        <h2>{{'UserPages.UserExportOffers.EditExportOffer.PageTitle4' | translate}}</h2>
        <p>{{'UserPages.UserExportOffers.EditExportOffer.PageSubtitle3' | translate}}</p>
        <div class="p-fluid formgrid grid mr-2 ml-2" style="margin-top: 40px;">
          <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
            <label for="harvestDate" style="font-weight: bolder;">{{'UserPages.UserExportOffers.EditExportOffer.HarvestDateFieldLabel' | translate}} *</label>
            <p-calendar
              inputId="harvestDate"
              formControlName="harvestDate"
              appendTo="body"
              [showButtonBar]="true"
              [showIcon]="true"
              placeholder="{{'UserPages.UserExportOffers.EditExportOffer.HarvestDateFieldPlaceholder' | translate}}"
              [showWeek]="true"
              [firstDayOfWeek]="1"></p-calendar>
            <small *ngIf="submit && formGroup.controls['harvestDate'].invalid" class="p-error">{{getErrorMessage(formGroup, 'harvestDate')}}</small>
          </div>
          <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
            <label for="shelfLife" style="font-weight: bolder;">Shelf Life (días) *</label>
            <p-inputNumber inputId="shelfLife" formControlName="shelfLife" placeholder="Duración" [min]="1" [max]="1000"></p-inputNumber>
            <small *ngIf="submit && formGroup.controls['shelfLife'].invalid" class="p-error">{{getErrorMessage(formGroup, 'shelfLife')}}</small>
          </div>
          <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
            <label for="netKilos" style="font-weight: bolder;">{{'UserPages.UserExportOffers.EditExportOffer.NetKiloFieldLabel' | translate}} *</label>
            <input pInputText inputId="netKilos" formControlName="netKilos" [maxlength]="formLimit.netKilosLimit" placeholder="{{'UserPages.UserExportOffers.EditExportOffer.NetKiloFieldPlaceholder' | translate}}"/>
            <small *ngIf="submit && formGroup.controls['netKilos'].invalid" class="p-error">{{getErrorMessage(formGroup, 'netKilos')}}</small>
          </div>
          <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
            <label for="grossKilos" style="font-weight: bolder;">{{'UserPages.UserExportOffers.EditExportOffer.GrossKiloFieldLabel' | translate}} *</label>
            <input pInputText inputId="grossKilos" formControlName="grossKilos" [maxlength]="formLimit.grossKilosLimit" placeholder="{{'UserPages.UserExportOffers.EditExportOffer.GrossKiloFieldPlaceholder' | translate}}"/>
            <small *ngIf="submit && formGroup.controls['grossKilos'].invalid" class="p-error">{{getErrorMessage(formGroup, 'grossKilos')}}</small>
          </div>
          <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
            <label for="boxType" style="font-weight: bolder;">{{'UserPages.UserExportOffers.EditExportOffer.BoxTypeFieldLabel' | translate}} *</label>
            <p-dropdown inputId="boxType" formControlName="boxType" [options]="boxTypes" optionLabel="label" optionValue="value" placeholder="{{'UserPages.UserExportOffers.EditExportOffer.BoxTypeFieldPlaceholder' | translate}}"></p-dropdown>
            <small *ngIf="submit && formGroup.controls['boxType'].invalid" class="p-error">{{getErrorMessage(formGroup, 'boxType')}}</small>
          </div>
          <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
            <label for="caseSize" style="font-weight: bolder;">{{'UserPages.UserExportOffers.EditExportOffer.CaseSize' | translate}}</label>
            <input pInputText inputId="caseSize"  [maxlength]="formLimit.caseSizeLimit" formControlName="caseSize" placeholder="{{'UserPages.UserExportOffers.EditExportOffer.CaseSize' | translate}}"/>
            <small *ngIf="submit && formGroup.controls['caseSize'].invalid" class="p-error">{{getErrorMessage(formGroup, 'caseSize')}}</small>
          </div>
          <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
            <label for="color" style="font-weight: bolder;">{{'UserPages.UserExportOffers.EditExportOffer.ColorFieldLabel' | translate}}</label>
            <p-dropdown id="color"
              formControlName="color"
              [options]="colors"
              optionLabel="label"
              optionValue="value"
              placeholder="{{'UserPages.UserExportOffers.EditExportOffer.ColorFieldPlaceholder' | translate}}"
              [showClear]="true"
              (onChange)="onChangeColor()">
              <ng-template pTemplate="selectedItem">
                <div class="flex align-items-center gap-2" *ngIf="selectedColor">
                  <div><span [style]="{'background-color': selectedColor.value}" class="border-circle w-6rem h-6rem">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> {{ selectedColor.label }}</div>
                </div>
              </ng-template>
              <ng-template let-color pTemplate="item">
                  <div class="flex align-items-center gap-2">
                    <div><span [style]="{'background-color': color.value}" class="border-circle w-6rem h-6rem">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> {{ color.label }}</div>
                  </div>
              </ng-template>
            </p-dropdown>
          </div>
          <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
            <label for="speciesSize" style="font-weight: bolder;">{{'UserPages.UserExportOffers.EditExportOffer.SpeciesSizeFieldLabel' | translate}}</label>
            <input pInputText [maxlength]="formLimit.speciesSizeLimit"  formControlName="speciesSize" placeholder="{{'UserPages.UserExportOffers.EditExportOffer.SpeciesSizeFieldPlaceholder' | translate}}"/>
          </div>
          <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
            <label for="sizeMillimeters" style="font-weight: bolder;">{{'UserPages.UserExportOffers.EditExportOffer.SpeciesSizeMillimetersFieldLabel' | translate}}</label>
            <p-inputNumber inputId="sizeMillimeters" formControlName="sizeMillimeters" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2" suffix=" mm" locale="en-US" placeholder="{{'UserPages.UserExportOffers.EditExportOffer.SpeciesSizeMillimetersFieldPlaceholder' | translate}}"></p-inputNumber>
          </div>
          <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
            <label for="category" style="font-weight: bolder;">{{'UserPages.UserExportOffers.EditExportOffer.CategoryFieldLabel' | translate}}</label>
            <input pInputText inputId="category" [maxlength]="formLimit.categoryLimit" formControlName="category" placeholder="{{'UserPages.UserExportOffers.EditExportOffer.CategoryFieldPlaceholder' | translate}}"/>
          </div>
          <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
            <label for="readyToLoadDate" style="font-weight: bolder;">{{'UserPages.UserExportOffers.EditExportOffer.ReadyToLoadDateFieldLabel' | translate}}</label>
            <p-calendar
              inputId="readyToLoadDate"
              formControlName="readyToLoadDate"
              appendTo="body"
              [showButtonBar]="true"
              [showIcon]="true"
              placeholder="{{'UserPages.UserExportOffers.EditExportOffer.ReadyToLoadDateFieldPlaceholder' | translate}}"
              [showWeek]="true"
              [firstDayOfWeek]="1"></p-calendar>
          </div>
          <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
            <label for="basePallets" style="font-weight: bolder;">{{'UserPages.UserExportOffers.EditExportOffer.BasePalletsFieldLabel' | translate}}</label>
            <p-dropdown inputId="basePallets" formControlName="basePallets" [options]="basePallets" optionLabel="label" optionValue="value" placeholder="{{'UserPages.UserExportOffers.EditExportOffer.BasePalletsFieldPlaceholder' | translate}}"></p-dropdown>
          </div>
          <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
            <label for="isQualityControl" style="font-weight: bolder;">{{'UserPages.UserExportOffers.EditExportOffer.HasControlQualityFieldLabel' | translate}}</label>
            <p-dropdown id="isQualityControl" formControlName="isQualityControl" [options]="qualityControlOptions" optionLabel="label" optionValue="value" placeholder="{{'UserPages.UserExportOffers.EditExportOffer.HasControlQualityFieldPlaceholder' | translate}}"></p-dropdown>
          </div>
          <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
            <label for="qualityControlDate" style="font-weight: bolder;">{{'UserPages.UserExportOffers.EditExportOffer.QualityControlDateFieldLabel' | translate}}</label>
            <p-calendar
              inputId="qualityControlDate"
              formControlName="qualityControlDate"
              appendTo="body"
              [showButtonBar]="true"
              [showIcon]="true"
              placeholder="{{'UserPages.UserExportOffers.EditExportOffer.QualityControlDateFieldPlaceholder' | translate}}"
              [showWeek]="true"
              [firstDayOfWeek]="1"></p-calendar>
          </div>
          <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
            <label for="marks" style="font-weight: bolder;">{{'UserPages.UserExportOffers.EditExportOffer.HasMarksFieldLabel' | translate}}</label>
            <p-dropdown id="marks" formControlName="marks" [options]="marksOptions" optionLabel="label" optionValue="value" placeholder="{{'UserPages.UserExportOffers.EditExportOffer.HasMarksFieldPlaceholder' | translate}}"></p-dropdown>
          </div>
        </div>

        <p-divider></p-divider>
        <h2>{{'UserPages.UserExportOffers.EditExportOffer.PageTitle5' | translate}}</h2>
        <p>{{'UserPages.UserExportOffers.EditExportOffer.PageSubtitle4' | translate}}</p>
        <div class="p-fluid formgrid grid mr-2 ml-2" style="margin-bottom: 40px;">
          <div class="field col-12 md:col-4 lg:col-4">
            <label for="marketCountry1" style="font-weight: bolder;">{{'UserPages.UserExportOffers.EditExportOffer.MarketCountry1FieldLabel' | translate}} *</label>
            <p-dropdown inputId="marketCountry1" formControlName="marketCountry1Id" [options]="countries" optionValue="id" optionLabel="name" placeholder="{{'UserPages.UserExportOffers.EditExportOffer.MarketCountry1FieldPlaceholder' | translate}}"></p-dropdown>
            <small *ngIf="submit && formGroup.controls['marketCountry1Id'].invalid" class="p-error">{{getErrorMessage(formGroup, 'marketCountry1Id')}}</small>
          </div>
          <div class="field col-12 md:col-4 lg:col-4">
            <label for="marketCountry2" style="font-weight: bolder;">{{'UserPages.UserExportOffers.EditExportOffer.MarketCountry2FieldLabel' | translate}} </label>
            <p-dropdown inputId="marketCountry2" formControlName="marketCountry2Id" [options]="countries" optionValue="id" optionLabel="name" placeholder="{{'UserPages.UserExportOffers.EditExportOffer.MarketCountry2FieldPlaceholder' | translate}}"></p-dropdown>
          </div>
          <div class="field col-12 md:col-4 lg:col-4">
            <label for="marketCountry3" style="font-weight: bolder;">{{'UserPages.UserExportOffers.EditExportOffer.MarketCountry3FieldLabel' | translate}}</label>
            <p-dropdown inputId="marketCountry3" formControlName="marketCountry3Id" [options]="countries" optionValue="id" optionLabel="name" placeholder="{{'UserPages.UserExportOffers.EditExportOffer.MarketCountry3FieldPlaceholder' | translate}}"></p-dropdown>
          </div>
        </div>

        <h2>{{'UserPages.UserExportOffers.EditExportOffer.PageTitle6' | translate}}</h2>
        <p>{{'UserPages.UserExportOffers.EditExportOffer.PageSubtitle5' | translate}}</p>
        <p-table
          [loading]="loading"
          [value]="productCertificates"
          [paginator]="true"
          [rows]="10"
          [showCurrentPageReport]="true"
          [rowsPerPageOptions]="[10, 25, 50]"
          responsiveLayout="stack"
          [breakpoint]="'600px'">
          <ng-template pTemplate="caption">
            <div class="flex align-items-center justify-content-start">
              <p-button label="{{'UserPages.UserProductCertificates.NewButtonLabel' | translate}}" icon="pi pi-plus" (onClick)="openProductCertificateCreateDialog(formDirective, productCertificateFileUploader)" class="mr-2"/>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
              <tr>
                <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserProductCertificates.NameHeader' | translate}}</div></th>
                <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserProductCertificates.CertificateTypeHeader' | translate}}</div></th>
                <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserProductCertificates.IssueDateHeader' | translate}}</div></th>
                <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserProductCertificates.ExpireDateHeader' | translate}}</div></th>
                <th><div class="flex justify-content-center align-items-center">PDF</div></th>
                <th></th>
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-certificate>
            <tr [pSelectableRow]="certificate">
              <td><div class="flex justify-content-center align-items-center">{{certificate.name}}</div></td>
              <td><div class="flex justify-content-center align-items-center">{{certificate.productCertificateType.name}}</div></td>
              <td><div class="flex justify-content-center align-items-center">{{certificate.documentIssueDate | date:'dd-MM-yyyy'}}</div></td>
              <td><div class="flex justify-content-center align-items-center">{{certificate.expirationDate | date:'dd-MM-yyyy'}}</div></td>
              <td><div class="flex justify-content-center align-items-center">
                <i (click)="getProductCertificateDocument(certificate.id)" pTooltip="Document PDF" class="pi pi-file-pdf" style="font-size: 2rem; cursor: pointer;"></i>
              </div></td>
              <td>
                <div class="flex justify-content-end align-items-center gap-2">
                  <p-button label="{{'UserPages.UserProductCertificates.EditButtonLabel' | translate}}" icon="pi pi-pencil" (onClick)="openProductCertificateEditDialog(certificate, productCertificateFileUploader)" class="mr-2"/>
                  <p-button label="{{'UserPages.UserProductCertificates.DeleteButtonLabel' | translate}}" icon="pi pi-trash" (onClick)="onDeleteProductCertificate(certificate)" class="mr-2"/>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>

        <p-divider></p-divider>
        <h2>{{'UserPages.UserExportOffers.NewExportOffer.PageTitle6' | translate}}</h2>
        <p>{{'UserPages.UserExportOffers.NewExportOffer.PageSubtitle6' | translate}}</p>
        <p-table [value]="documents" responsiveLayout="stack" [breakpoint]="'600px'">
          <ng-template pTemplate="caption">
            <div class="flex align-items-center justify-content-start">
              <p-button label="{{'UserPages.UserExportOfferDocument.NewButtonLabel' | translate}}" icon="pi pi-plus" class="mr-2" (onClick)="openDocumentCreateDialog(documentFormDirective, documentFileUploader)"/>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
              <tr>
                <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserExportOfferDocument.NameHeader' | translate}}</div></th>
                <th><div class="flex justify-content-center align-items-center">PDF</div></th>
                <th></th>
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-document>
              <tr>
                <td><div class="flex justify-content-center align-items-center">{{ document.name }}</div></td>
                <td>
                  <div class="flex justify-content-center align-items-center">
                    <i (click)="getExportOfferDocument(document.id)" pTooltip="Document PDF" class="pi pi-file-pdf" style="font-size: 2rem; cursor: pointer;"></i>
                  </div>
                </td>
                <td>
                  <div class="flex justify-content-end align-items-center gap-2">
                    <p-button label="{{'UserPages.UserExportOfferDocument.EditButtonLabel' | translate}}" icon="pi pi-pencil" (onClick)="openDocumentEditDialog(document, documentFileUploader)" class="mr-2"/>
                    <p-button label="{{'UserPages.UserExportOfferDocument.DeleteButtonLabel' | translate}}" icon="pi pi-trash" (onClick)="onDeleteDocument(document)" class="mr-2"/>
                  </div>
                </td>
              </tr>
          </ng-template>
        </p-table>

        <p-divider></p-divider>
        <h2>{{'UserPages.UserExportOffers.EditExportOffer.PageTitle7' | translate}}</h2>
        <p>{{'UserPages.UserExportOffers.EditExportOffer.PageSubtitle7' | translate}}</p>
        <p-table [value]="pictures">
          <ng-template pTemplate="caption">
            <div class="flex justify-content-start align-items-center gap-4">
              <p-fileUpload
                #imagesUploader
                mode="basic"
                chooseLabel="{{'UserPages.UserExportOffers.EditExportOffer.UploadButton' | translate}}"
                [fileLimit]="1"
                [auto]="true"
                accept=".jpg, .jpeg, .png"
                [maxFileSize]="3000000"
                (onSelect)="onSelectImage($event, imagesUploader)"
                [disabled]="pictures.length === 3"></p-fileUpload>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
          </ng-template>
          <ng-template pTemplate="body" let-picture let-rowIndex="rowIndex">
            <tr>
              <td>
                <img [src]="getImageUrl(picture)" style="border-radius: 7px; width: 100%; max-width: 100px; height: auto;" alt=""/>
              </td>
              <td>
                {{getImageName(picture)}}
              </td>
              <td>
                <div class="flex justify-content-end align-items-center gap-4">
                  <p-button icon="pi pi-times" (click)="onRemoveImage(rowIndex)"></p-button>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>

        <div class="grid" style="margin-top: 70px;">
          <div class="col-6"></div>
          <div class="col-6">
            <div class="p-fluid formgrid grid">
              <div class="col-6">
                <p-button label="{{'UserPages.UserExportOffers.EditExportOffer.BackButton' | translate}}" (click)="goToExportOffersPage()" styleClass="p-button-outlined"></p-button>
              </div>
              <div class="col-6">
                <p-button label="{{'UserPages.UserExportOffers.EditExportOffer.SaveButton' | translate}}" (onClick)="save()" [loading]="loading"></p-button>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </p-card>
  </form>
</app-user-dashboard>
<p-toast></p-toast>

<p-dialog header="{{'UserPages.UserProductCertificates.PageTitle' | translate}}" [(visible)]="productCertificateDialogVisible" [style]="{width: '50%'}" [modal]="true">
  <form [formGroup]="productCertificateFormGroup" #formDirective="ngForm">
    <div class="p-fluid formgrid grid" style="padding: 20px;">
      <div class="field col-12 md:col-6 lg:col-6" style="margin-bottom: 40px;">
        <label for="name" style="font-weight: bolder;">{{'UserPages.UserProductCertificates.Form.NameLabel' | translate}} *</label>
        <input id="name" [maxlength]="formLimit.nameLimit" formControlName="name" type="text" required pInputText placeholder="{{'UserPages.UserProductCertificates.Form.NameLabel' | translate}}"/>
        <small *ngIf="submitProductCertificate && productCertificateFormGroup.controls['name'].invalid" class="p-error">{{getErrorMessage(productCertificateFormGroup, 'name')}}</small>
      </div>
      <div class="field col-12 md:col-6 lg:col-6" style="margin-bottom: 40px;">
        <label for="certificateType" style="font-weight: bolder;">{{'UserPages.UserProductCertificates.Form.CertificateTypeLabel' | translate}} *</label>
        <p-dropdown inputId="certificateType" formControlName="productCertificateTypeId" [options]="certificateTypes" optionLabel="name" optionValue="id" placeholder="{{'UserPages.UserProductCertificates.Form.CertificateTypeLabel' | translate}}"></p-dropdown>
        <small *ngIf="submitProductCertificate && productCertificateFormGroup.controls['productCertificateTypeId'].invalid" class="p-error">{{getErrorMessage(productCertificateFormGroup, 'productCertificateTypeId')}}</small>
      </div>
      <div class="field col-12 md:col-6 lg:col-6" style="margin-bottom: 40px;">
        <label for="issueDate" style="font-weight: bolder;">{{'UserPages.UserProductCertificates.Form.IssueDateLabel' | translate}} </label>
        <p-calendar
          id="issueDate"
          appendTo="body"
          formControlName="documentIssueDate"
          [showButtonBar]="true"
          [showIcon]="true"
          placeholder="{{'UserPages.UserProductCertificates.Form.IssueDateLabel' | translate}}"
          [showWeek]="true"
          [firstDayOfWeek]="1"></p-calendar>
      </div>
      <div class="field col-12 md:col-6 lg:col-6" style="margin-bottom: 40px;">
        <label for="expirationDate" style="font-weight: bolder;">{{'UserPages.UserProductCertificates.Form.ExpireDateLabel' | translate}}</label>
        <p-calendar
          id="expirationDate"
          appendTo="body"
          formControlName="expirationDate"
          [showButtonBar]="true"
          [showIcon]="true"
          placeholder="{{'UserPages.UserProductCertificates.Form.ExpireDateLabel' | translate}}"
          [showWeek]="true"
          [firstDayOfWeek]="1"></p-calendar>
      </div>
      <div class="field col-12" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserProductCertificates.Form.LoadDocumentLabel' | translate}}</label>
        <p-fileUpload
          #productCertificateFileUploader
          (onSelect)="onSelectProductCertificateFile($event)"
          (onClear)="onClearProductCertificateFile($event)"
          [showUploadButton]="false"
          [multiple]="false"
          accept=".pdf"
          [maxFileSize]="5000000"
          chooseLabel="{{'UserPages.UserProductCertificates.Form.SelectFile' | translate}}"
          cancelLabel="{{'UserPages.UserProductCertificates.Form.CancelSelectFile' | translate}}"/>
      </div>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <p-button label="{{'UserPages.UserProductCertificates.Form.BackButtonLabel' | translate}}" (onClick)="productCertificateDialogVisible = false" styleClass="p-button-outlined"></p-button>
    <p-button label="{{'UserPages.UserProductCertificates.Form.SaveButtonLabel' | translate}}" (onClick)="saveProductCertificate()" [loading]="loading"></p-button>
  </ng-template>
</p-dialog>


<p-dialog header="{{'UserPages.UserExportOfferDocument.PageTitle' | translate}}" [(visible)]="documentDialogVisible" [style]="{width: '50%'}" [modal]="true">
  <form [formGroup]="documentFormGroup" #documentFormDirective="ngForm">
    <div class="p-fluid formgrid grid" style="padding: 20px;">
      <div class="field col-12 md:col-12 lg:col-12" style="margin-bottom: 40px;">
        <label for="name" style="font-weight: bolder;">{{'UserPages.UserExportOfferDocument.Form.NameLabel' | translate}} *</label>
        <input id="name" [maxlength]="formLimit.nameLimit" formControlName="name" type="text" required pInputText placeholder="{{'UserPages.UserExportOfferDocument.Form.NameLabel' | translate}}"/>
        <small *ngIf="submitDocument && documentFormGroup.controls['name'].invalid" class="p-error">{{getErrorMessage(documentFormGroup, 'name')}}</small>
      </div>
      <div class="field col-12" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserExportOfferDocument.Form.LoadDocumentLabel' | translate}}</label>
        <p-fileUpload
          #documentFileUploader
          (onSelect)="onSelectDocumentFile($event)"
          (onClear)="onClearDocumentFile($event)"
          [showUploadButton]="false"
          [multiple]="false"
          accept=".pdf"
          [maxFileSize]="5000000"
          chooseLabel="{{'UserPages.UserExportOfferDocument.Form.SelectFile' | translate}}"
          cancelLabel="{{'UserPages.UserExportOfferDocument.Form.CancelSelectFile' | translate}}"/>
      </div>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <p-button label="{{'UserPages.UserExportOfferDocument.Form.BackButtonLabel' | translate}}" (onClick)="documentDialogVisible = false" styleClass="p-button-outlined"></p-button>
    <p-button label="{{'UserPages.UserExportOfferDocument.Form.SaveButtonLabel' | translate}}" (onClick)="saveDocument()" [loading]="loading"></p-button>
  </ng-template>
</p-dialog>

<p-confirmDialog acceptLabel="Yes" rejectLabel="No" rejectButtonStyleClass="p-button-outlined" [style]="{width: '50vw'}"></p-confirmDialog>
