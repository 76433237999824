import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Delivery, DeliveryInformation, UpdateDeliveryRequest } from "src/app/domain/models/Delivery";
import { Routes } from "src/app/infrastructure/routes";
import { DeliveryGateway } from "../gateway/DeliveryGateway";

@Injectable({providedIn: 'root'})
export class DeliveryService extends DeliveryGateway {

  constructor(private _http: HttpClient) {
    super()
  }

  getById(deliveryId: number): Observable<Delivery> {
    return this._http.get<Delivery>(Routes.deliveryApi + '/GetById', {params: {deliveryId}})
  }

  getByImportOrderId(importOrderId: any): Observable<DeliveryInformation> {
    return this._http.get<DeliveryInformation>(Routes.deliveryApi + '/GetByImportOrderId', {params: {importOrderId}})
  }
  
  update(deliveryDto: UpdateDeliveryRequest): Observable<DeliveryInformation> {
    return this._http.put<DeliveryInformation>(Routes.deliveryApi + '/Update', deliveryDto)
  }
  
  removeNotification(importOrderId: any, notificationType: string): Observable<any> {
    return this._http.get<any>(Routes.deliveryApi + '/RemoveNotification', {params:{guid:  importOrderId, notificationType: notificationType }})
  }
}
