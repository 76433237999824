import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ChangePassword, ResetPassword, TokenResetPassword, User } from "src/app/domain/models/User";
import { Routes } from "src/app/infrastructure/routes";
import { UserGateway } from "../gateway/UserGateway";

@Injectable({providedIn: 'root'})
export class UserService extends UserGateway {

  constructor(private _http: HttpClient) {
    super()
  }

  updatePassword(changePassword: ChangePassword): Observable<any> {
    return this._http.patch<any>(Routes.userApi + '/UpdatePassword', changePassword)
  }

  resetPasswordRequestToken(request: TokenResetPassword): Observable<any> {
    return this._http.post<any>(Routes.userApi + '/ResetPasswordRequestToken', request)
  }

  resetPassword(request: ResetPassword): Observable<any> {
    return this._http.post<any>(Routes.userApi + '/ResetPassword', request)
  }
}
