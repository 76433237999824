<p-panel>
  <h2>Frozen fruit Marketplace</h2>
  <div class="grid">
    <div class="col-12">
      <form [formGroup]="portFormGroup">
        <div class="p-fluid grid">
          <div class="col-12 md:col-2 lg:col-2">
            <span class="p-float-label">
              <p-dropdown
                [options]="originCountries"
                formControlName="countryFromId"
                optionLabel="name"
                optionValue="id"
                (onChange)="loadExportOffers()"
                (onClear)="clearSpotSearchParams()"
                [filter]="true"
                filterBy="name"
                [showClear]="true"
                placeholder="{{'MarketplacePage.Spot.ImportFromFieldPlaceholder' | translate}}"
                class="p-inputtext-sm">
                <ng-template pTemplate="selectedItem">
                  <div class="flex align-items-center gap-2" *ngIf="selectedCountryFrom">
                    <img [src]="'../../../../assets/img/flags/' + selectedCountryFrom.code + '.png'" style="width: 18px" alt="flag"/>
                    <div>{{ selectedCountryFrom | countryDescription }}</div>
                  </div>
                </ng-template>
                <ng-template let-country pTemplate="item">
                  <div class="flex align-items-center gap-2">
                    <img [src]="'../../../../assets/img/flags/' + country.code + '.png'" style="width: 18px" alt="flag"/>
                    <div>{{ country | countryDescription }}</div>
                  </div>
                </ng-template>
              </p-dropdown>
              <label>{{'MarketplacePage.Spot.ImportFromFieldLabel' | translate}}</label>
            </span>
          </div>
          <div class="col-12 md:col-2 lg:col-2">
            <span class="p-float-label">
              <p-dropdown
                [options]="destinationCountries"
                formControlName="countryToId"
                optionLabel="name"
                optionValue="id"
                (onChange)="loadDestinationPorts()"
                [filter]="true"
                filterBy="name"
                [showClear]="false"
                placeholder="{{'MarketplacePage.Spot.ImportFromFieldPlaceholder' | translate}}"
                class="p-inputtext-sm">
                <ng-template pTemplate="selectedItem">
                  <div class="flex align-items-center gap-2" *ngIf="selectedCountryTo">
                    <img [src]="'../../../../assets/img/flags/' + selectedCountryTo.code + '.png'" style="width: 18px" alt="flag"/>
                    <div>{{ selectedCountryTo | countryDescription }}</div>
                  </div>
                </ng-template>
                <ng-template let-country pTemplate="item">
                  <div class="flex align-items-center gap-2">
                    <img [src]="'../../../../assets/img/flags/' + country.code + '.png'" style="width: 18px" alt="flag"/>
                    <div>{{ country | countryDescription }}</div>
                  </div>
                </ng-template>
              </p-dropdown>
              <label>{{'MarketplacePage.Spot.ImportToFieldLabel' | translate}}</label>
            </span>
          </div>
          <div class="col-12 md:col-2 lg:col-2">
            <span class="p-float-label">
              <p-dropdown
                [options]="destinationPorts"
                formControlName="portToId"
                optionLabel="name"
                optionValue="id"
                (onChange)="loadEstimations()"
                [filter]="true"
                filterBy="name"
                [showClear]="false"
                placeholder="{{'MarketplacePage.Spot.ToPortFieldLabel' | translate}}"
                emptyMessage="{{'MarketplacePage.Spot.EmptyResultsText' | translate}}"
                class="p-inputtext-sm"></p-dropdown>
              <label>{{'MarketplacePage.Spot.ToPortFieldPlaceholder' | translate}}</label>
            </span>
          </div>
          <div class="col-12 md:col-4 lg:col-4">
            <span class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <input type="text" pInputText (input)="filterGlobal($event, resultDataTable)" placeholder="{{'MarketplacePage.Spot.SearchProducts' | translate}}" class="p-inputtext-sm" />
            </span>
          </div>
          <div class="col-12 md:col-2 lg:col-2">
            <span class="p-float-label">
              <p-dropdown
                [(ngModel)]="viewType"
                [options]="viewTypeOptions"
                optionLabel="label"
                optionValue="value"
                class="p-inputtext-sm"
                [ngModelOptions]="{standalone: true}"></p-dropdown>
              <label>{{'MarketplacePage.Spot.ViewLikeLabel' | translate}}</label>
            </span>
          </div>
          <div class="col-12 md:col-2 lg:col-2">
            <p-fieldset legend="{{'MarketplacePage.Spot.NavyListLabel' | translate}}">
              <ng-container *ngFor="let e of estimations">
                <p>{{e.shippingCompany.name}}</p>
              </ng-container>
            </p-fieldset>
          </div>
          <div class="col-12 md:col-2 lg:col-2">
            <p-fieldset legend="{{'MarketplacePage.Spot.TimeInTransitListLabel' | translate}}">
              <ng-container *ngFor="let e of estimations;  let i = index">
                <p>{{e.transitTime}} <p-tag *ngIf="i == 0" severity="success" value="Best Deal"></p-tag></p>
              </ng-container>
            </p-fieldset>
          </div>
          <div class="col-12 md:col-2 lg:col-2">
            <p-fieldset legend="Ocean Freight">
              <ng-container *ngFor="let e of estimations;  let i = index">
                <p>{{e.rate | currency: 'USD':'US$'}} <p-tag *ngIf="i == 0" severity="success" value="Best Deal"></p-tag></p>
              </ng-container>
            </p-fieldset>
          </div>
          <div class="col-12 md:col-4 lg:col-4"></div>
          <div class="col-12 md:col-2 lg:col-2">
            <button
              icon="pi pi-shopping-cart"
              pButton
              pRipple
              (click)="goToShoppingCart()"
              style="background-color: #8c2b87;"
              label="{{'MarketplacePage.Spot.OrderContainers' | translate}}"
              *ngIf="profile?.profileType === 'Importer'"></button>
          </div>
        </div>
      </form>
    </div>
    <div class="col-12">
      <p-table #resultDataTable
        [value]="exportOffers"
        dataKey="id"
        [paginator]="true"
        [rows]="10"
        [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[10, 25, 50]"
        [loading]="loading"
        [globalFilterFields]="['varietyName', 'speciesName', 'size', 'locationCountry', 'locationCity']"
        responsiveLayout="stack"
        [breakpoint]="'600px'">

        <ng-template pTemplate="header">
          <tr>
            <th>
              <div class="flex align-items-center">
                {{'MarketplacePage.Spot.MarketPlaceTable.CountryHeader' | translate}}
              </div>
            </th>
            <th>
              <div class="flex align-items-center">
                {{'MarketplacePage.Spot.MarketPlaceTable.SpecieHeader' | translate}}
                <p-columnFilter
                  field="speciesName"
                  matchMode="in"
                  display="menu"
                  [showMatchModes]="false"
                  [showOperator]="false"
                  [showAddButton]="false">
                  <ng-template pTemplate="header">
                    <div class="px-3 pt-3 pb-0">
                      <span class="font-bold">{{'MarketplacePage.Spot.MarketPlaceTable.SelectSpecieText' | translate}}</span>
                    </div>
                  </ng-template>
                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                    <p-multiSelect
                      [options]="filterSpecies"
                      [ngModel]="value"
                      placeholder="{{'MarketplacePage.Spot.MarketPlaceTable.SelectText' | translate}}"
                      (onChange)="filter($event.value)"
                      optionValue="nameEs"
                      optionLabel="nameEs"></p-multiSelect>
                  </ng-template>
                </p-columnFilter>
              </div>
            </th>
            <th pSortableColumn="varietyName">
              <div class="flex align-items-center">
                {{'MarketplacePage.Spot.MarketPlaceTable.VarietyHeader' | translate}}
                <p-columnFilter
                  field="varietyName"
                  matchMode="in"
                  display="menu"
                  [showMatchModes]="false"
                  [showOperator]="false"
                  [showAddButton]="false">
                  <ng-template pTemplate="header">
                    <div class="px-3 pt-3 pb-0">
                      <span class="font-bold">{{'MarketplacePage.Spot.MarketPlaceTable.SelectVarietyText' | translate}}</span>
                    </div>
                  </ng-template>
                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                    <p-multiSelect
                      [options]="filterVarieties"
                      [ngModel]="value"
                      placeholder="{{'MarketplacePage.Spot.MarketPlaceTable.SelectText' | translate}}"
                      (onChange)="filter($event.value)"
                      optionValue="name"
                      optionLabel="name"></p-multiSelect>
                  </ng-template>
                </p-columnFilter>
                <p-sortIcon field="varietyName"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="size">
              <div class="flex align-items-center">
                {{'MarketplacePage.Spot.MarketPlaceTable.SizeHeaderHeader' | translate}}
                <p-columnFilter
                  type="text"
                  field="size"
                  display="menu"
                  [showAddButton]="false"
                  [showMatchModes]="false"
                  [showOperator]="false"
                  [matchMode]="'contains'"></p-columnFilter>
                <p-sortIcon field="size"></p-sortIcon>
              </div>
            </th>
            <th>
              <div class="flex align-items-center">
                {{'MarketplacePage.Spot.MarketPlaceTable.QualityControlHeader' | translate}}
                <p-columnFilter
                  type="boolean"
                  field="isQualityControl"
                  display="menu"
                  [showAddButton]="false"
                  [showMatchModes]="false"
                  [showOperator]="false"></p-columnFilter>
              </div>
            </th>
            <th pSortableColumn="harvestDate">
              <div class="flex align-items-center">
                {{'MarketplacePage.Spot.MarketPlaceTable.HarvestDateHeader' | translate}}
                <p-columnFilter
                  type="date"
                  field="exportOffer.HarvestDateHeader"
                  display="menu"
                  [showAddButton]="false"
                  [showMatchModes]="false"
                  [showOperator]="false"></p-columnFilter>
                <p-sortIcon field="harvestDate"></p-sortIcon>
              </div>
            </th>
            <th>
              <div class="flex align-items-center">
                {{'MarketplacePage.Spot.MarketPlaceTable.LocationHeader' | translate}}
                <p-columnFilter
                  type="text"
                  field="locationCountry"
                  display="menu"
                  [showAddButton]="false"
                  [showMatchModes]="false"
                  [showOperator]="false"
                  [matchMode]="'contains'"></p-columnFilter>
              </div>
            </th>
            <th pSortableColumn="palletsQuantity">
              <div class="flex align-items-center">
                Containers
              </div>
            </th>
            <th>
              <div class="flex align-items-center">
                {{'MarketplacePage.Spot.MarketPlaceTable.CasePerPalletsHeader' | translate}}
              </div>
            </th>
            <th>
              <div class="flex align-items-center">
                Total cases
              </div>
            </th>
            <th pSortableColumn="price">
              <div class="flex align-items-center">
                {{'MarketplacePage.Spot.MarketPlaceTable.ListPrice' | translate}}
                <p-sortIcon field="price"></p-sortIcon>
              </div>
            </th>
            <th>
              <div class="flex align-items-center">
                {{'MarketplacePage.Spot.MarketPlaceTable.TotalPriceHeader' | translate}}
              </div>
            </th>
            <th colspan="2">
              <div class="flex align-items-center">
              </div>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-exportOffer>
          <tr [pSelectableRow]="exportOffer">
            <td><img [src]="'../../../../assets/img/flags/' + exportOffer.locationCountryCode + '.png'" height="50px" alt="country flag"/></td>
            <td><img [src]="exportOffer.varietyIconUrl" height="70px" style="padding: 2px; border-radius: 7px;" alt="variety icon"/></td>
            <td>{{exportOffer.varietyName}}</td>
            <td>{{exportOffer.size}}</td>
            <td>{{exportOffer.isQualityControl ? 'Si' : 'No'}}</td>
            <td>{{exportOffer.harvestDate | date: 'dd-MM-yyyy'}}</td>
            <td>{{exportOffer.locationCity  + ', ' + (exportOffer.locationCountry | countryDescription)}}</td>
            <td>{{exportOffer.containersQuantity}}</td>
            <td>{{exportOffer.boxesPerPallet ? exportOffer.boxesPerPallet : 'N.A.'}}</td>
            <td>{{getTotalCases(exportOffer)}}</td>
            <td>{{exportOffer.price | currency: 'USD':'US$'}}</td>
            <td><span style="font-weight: bold;">{{getTotalPrice(exportOffer) | currency: 'USD':'US$'}}</span></td>
            <td>
              <p-button
                icon="pi pi-eye"
                pTooltip="View export offer detail"
                (onClick)="showDetailModal(exportOffer.id)"
                [disabled]="portFormGroup.controls['countryFromId'].invalid || portFormGroup.controls['portToId'].invalid || portFormGroup.controls['countryToId'].invalid"></p-button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="18">{{'MarketplacePage.Spot.MarketPlaceTable.EmptyResultsText' | translate}}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</p-panel>

<p-dialog [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleDetailDialog" [style]="{width: '80%'}">
  <app-frozen-fruit-export-offer-detail
    [offer]="selected"
    [isPurchase]="true"
    [countryFromId]="countryFromId"
    [countryToId]="countryToId"
    [portToId]="portToId"
    (successAddShoppingCartEvent)="addShoppingCart($event)"></app-frozen-fruit-export-offer-detail>
  <ng-template pTemplate="footer">
    <p-button label="{{'UserPages.UserExportOffers.CloseModalButton' | translate}}" (onClick)="visibleDetailDialog = false"></p-button>
  </ng-template>
</p-dialog>

<p-toast></p-toast>
