import { ImportRequest } from "src/app/domain/models/ImportRequest"

export class ImportRequestUtil {

  static getTotalPallets(importRequest: ImportRequest): number {
    const pallets = importRequest.palletQuantity
    const startWeek = importRequest.fromWeek
    const startWeekYear = importRequest.fromYearWeek
    const endWeek = importRequest.toWeek
    const endWeekYear = importRequest.toYearWeek

    if(startWeekYear && endWeekYear && startWeekYear === endWeekYear && endWeek > startWeek) {
      return ((endWeek - startWeek) + 1) * pallets
    }

    if (startWeekYear && endWeekYear && endWeekYear == startWeekYear + 1) {
      return ((52 - startWeek) + endWeek + 1) * pallets
    }

    return 0
  }

  static getTotalWeeks(importRequest: ImportRequest): number {
    const startWeek = importRequest.fromWeek
    const startWeekYear = importRequest.fromYearWeek
    const endWeek = importRequest.toWeek
    const endWeekYear = importRequest.toYearWeek

    if(startWeekYear && endWeekYear && startWeekYear === endWeekYear && endWeek > startWeek) {
      return (endWeek - startWeek) + 1
    }

    if (startWeekYear && endWeekYear && endWeekYear == startWeekYear + 1) {
      return ((52 - startWeek) + endWeek) + 1
    }
    return 1
  }

  static getTotalContainersPerWeek(importRequest: ImportRequest): number {
    const pallets = importRequest.palletQuantity

    if(pallets % 20 == 0) {
      return pallets / 20
    }
    return 0
  }

  static getTotalContainers(importRequest: ImportRequest): number {
    const pallets = ImportRequestUtil.getTotalPallets(importRequest)

    if(pallets % 20 == 0) {
      return pallets / 20
    }
    return 0
  }
}
