import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { CountryGateway } from 'src/app/application/gateway/CountryGateway';
import { ProfileGateway } from 'src/app/application/gateway/ProfileGateway';
import { SecurityGateway } from 'src/app/application/gateway/SecurityGateway';
import { UserJoinRequestGateway } from 'src/app/application/gateway/UserJoinRequestGateway';
import { Consignee } from 'src/app/domain/models/Consignee';
import { Country } from 'src/app/domain/models/Country';
import { Notifier } from 'src/app/domain/models/Notifier';
import { SingupRequest } from 'src/app/domain/models/Profile';
import { UserJoinRequest } from 'src/app/domain/models/UserJoinRequest';
import { Routes } from 'src/app/infrastructure/routes';
import { emailMatchValidator, passwordMatchValidator } from 'src/app/ui/util/custom-validators';
import { ErrorMessageUtil } from 'src/app/ui/util/error-message-util';

export class Step {
  static ACCOUNT: string = 'account'
  static CONSIGNEE: string = 'consignee'
  static NOTIFY: string = 'notify'
}

@Component({
  selector: 'app-signup-page-contact',
  templateUrl: './signup-page-contact.component.html',
  styleUrls: ['./signup-page-contact.component.css'],
  providers: [MessageService]
})
export class SignupPageContactComponent implements OnInit {
  profileTypeOptions: any[] = []
  submit: boolean = false
  loading: boolean = false
  success: boolean = false
  loginError: boolean = false

  step: string = Step.ACCOUNT

  countries: Country[] = []
  selectedCountry: Country | undefined
  selectedCountryNotify: Country | undefined
  selectedCountryConsignee: Country | undefined

  userJoinRequestId: string | undefined
  userJoinRequest: UserJoinRequest | undefined

  formGroup: FormGroup = this._formBuilder.group({
    profileType: [1, Validators.required],
    firstname: ['', Validators.required],
    lastname: ['', Validators.required],
    companyName: ['', Validators.required],
    country: [null, Validators.required],
    city: ['', Validators.required],
    address: ['', Validators.required],
    phone: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    email2: ['', [Validators.required, Validators.email]],
    password: ['', Validators.required],
    password2: ['', [Validators.required]],
    invitationCode: [null],
  },
  {
    validators: [passwordMatchValidator('password', 'password2'), emailMatchValidator('email', 'email2')]
  })

  consigneeNotifyFormGroup: FormGroup = this._formBuilder.group({
    consigneeCompanyName: ['', Validators.required],
    consigneeVat: ['', Validators.required],
    consigneeContactCountryId: ['', Validators.required],
    consigneeContactCity: ['', Validators.required],
    consigneeContactName: ['', Validators.required],
    consigneeContactAddress: ['', Validators.required],
    consigneeContactPhone: [''],
    consigneeContactEmail: ['', [Validators.required, Validators.email]],
    notifyCompanyName: ['', Validators.required],
    notifyVat: ['', Validators.required],
    notifyContactCountryId: ['', Validators.required],
    notifyContactCity: ['', Validators.required],
    notifyContactName: ['', Validators.required],
    notifyContactAddress: ['', Validators.required],
    notifyContactPhone: [''],
    notifyContactEmail: ['', [Validators.required, Validators.email]],
  })

  constructor(private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _messageService: MessageService,
    private _securityGateway: SecurityGateway,
    private _countryGateway: CountryGateway,
    private _userJoinRequestService: UserJoinRequestGateway,
    private router: Router,
    private _authService: AuthGateway,
    private _profileService: ProfileGateway,
    public translate: TranslateService) {

    this._activatedRoute.params.subscribe(params => {
      this.userJoinRequestId = params['userJoinRequestId']

      if(this.userJoinRequestId)
        this.loadUserJoinRequest()
      else
        this.formGroup.controls['invitationCode'].addValidators(Validators.required)
    })
  }

  ngOnInit(): void {
    this.loadCountries()
    this.profileTypeOptions = [
      {label: 'Exporter / Exportador', value: 1},
      {label: 'Importer / Importador', value: 2},
    ]
  }

  loadUserJoinRequest() {
    if(this.userJoinRequestId) {
      this._userJoinRequestService.getById(this.userJoinRequestId).subscribe({
        next: (v) => {
          this.userJoinRequest = v
          this.formGroup.controls['profileType'].setValue(2)
          this.formGroup.controls['email'].setValue(this.userJoinRequest.guestEmail)
          this.formGroup.controls['email2'].setValue(this.userJoinRequest.guestEmail)
        },
        error: (e) => {
          const responseError = ErrorMessageUtil.getResponseError(e.error, this.translate.currentLang);
          let errorDetail = responseError ? responseError : this.translate.instant('MessageService.UserJoinRequest.SingleRetrieveError')

          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: errorDetail });

          this.formGroup.controls['invitationCode'].addValidators(Validators.required)
          this.userJoinRequestId = undefined
          console.log(e)
        },
        complete: () =>  {
          this.loading = false
          console.info('UserJoinRequest query complete.')
        }
      })
    }
  }

  loadCountries() {
    this._countryGateway.getAll().subscribe({
      next: (v) => {
        this.countries = v
      },
      error: (e) => {
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Country.ManyRetrieveError') });
        console.log(e)
      },
      complete: () =>  {
        this.loading = false
        console.info('Countries query complete.')
      }
    })
  }

  onProfileTypeChange() {

  }

  goToHome() {
    this._router.navigate([Routes.homePage])
  }

  submitForm(): void {
    this.submit = true
    if(this.formGroup.valid) {
      console.log("Formulario si es válido")
      this.loading = true

      let consignee: Consignee | undefined
      let notify: Notifier | undefined

      if(this.userJoinRequestId && this.formGroup.controls['profileType'].value == 2 && this.consigneeNotifyFormGroup.valid) {
        consignee = {
          contactName: this.consigneeNotifyFormGroup.controls['consigneeContactName'].value,
          contactAddress: this.consigneeNotifyFormGroup.controls['consigneeContactAddress'].value,
          companyName: this.consigneeNotifyFormGroup.controls['consigneeCompanyName'].value,
          contactCity: this.consigneeNotifyFormGroup.controls['consigneeContactCity'].value,
          contactCountryId: this.consigneeNotifyFormGroup.controls['consigneeContactCountryId'].value,
          contactEmail: this.consigneeNotifyFormGroup.controls['consigneeContactEmail'].value,
          contactPhone: this.consigneeNotifyFormGroup.controls['consigneeContactPhone'].value,
          vat: this.consigneeNotifyFormGroup.controls['consigneeVat'].value
        }

        notify = {
          contactName: this.consigneeNotifyFormGroup.controls['notifyContactName'].value,
          contactAddress: this.consigneeNotifyFormGroup.controls['notifyContactAddress'].value,
          companyName: this.consigneeNotifyFormGroup.controls['notifyCompanyName'].value,
          contactCity: this.consigneeNotifyFormGroup.controls['notifyContactCity'].value,
          contactCountryId: this.consigneeNotifyFormGroup.controls['notifyContactCountryId'].value,
          contactEmail: this.consigneeNotifyFormGroup.controls['notifyContactEmail'].value,
          contactPhone: this.consigneeNotifyFormGroup.controls['notifyContactPhone'].value,
          vat: this.consigneeNotifyFormGroup.controls['notifyVat'].value
        }
      }
      else if(this.userJoinRequestId && this.formGroup.controls['profileType'].value == 2 && !this.consigneeNotifyFormGroup.valid) {
        this.loading = false
        return
      }

      let request: SingupRequest = {
        companyName: this.formGroup.controls['companyName'].value,
        name: this.formGroup.controls['firstname'].value,
        lastName: this.formGroup.controls['lastname'].value,
        email: this.formGroup.controls['email'].value,
        password: this.formGroup.controls['password'].value,
        profileType: this.formGroup.controls['profileType'].value,
        contactCountryId: this.formGroup.controls['country'].value,
        contactCity: this.formGroup.controls['city'].value,
        contactAddress: this.formGroup.controls['address'].value,
        contactPhone: this.formGroup.controls['phone'].value,
        invitationCode: this.formGroup.controls['invitationCode'].value,
        userJoinRequestId: this.userJoinRequestId,
        consignee: consignee,
        notify: notify
      }

      this._securityGateway.signup(request).subscribe({
        next: (v) => {
          this.loading = false
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: this.translate.instant('SignupPage.SuccessMessage') });
          this.success = true
          this.redirectAuthCode()
        },
        error: (e) => {
          this.loading = false
          this.submit = false
          this.success = false
          let errorMessage = ''
          switch(this.translate.currentLang) {
            case 'es':
              errorMessage = e.error.messageEs
              break
            case 'en':
              errorMessage = e.error.messageEn
              break
            default:
              errorMessage = e.error.messageEn
          }
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: errorMessage });
          console.log(e)
        },
        complete: () =>  { console.info('complete') }
      })
    }
  }

  goToLogin() {
    this._router.navigate([Routes.login])
  }

  getErrorMessage(fieldName: string): string {
    return ErrorMessageUtil.getErrorMessage(this.formGroup, this.translate, fieldName)
  }

  getConsigneeNotifyErrorMessage(fieldName: string): string {
    return ErrorMessageUtil.getErrorMessage(this.consigneeNotifyFormGroup, this.translate, fieldName)
  }

  get passwordMatchError(): boolean {
    return this.formGroup.getError('passwordMismatch')
  }

  get emaildMatchError(): boolean {
    return this.formGroup.getError('emailMismatch')
  }

  getPasswordMissMatchMessage() {
    const lang = this.translate.currentLang
    switch(lang) {
      case 'en':
        return 'Password does not match.'
      case 'es':
        return 'Las contraseñas no coinciden.'
      default:
        return 'Password does not match.'
    }
  }

  getEmailMissMatchMessage() {
    const lang = this.translate.currentLang
    switch(lang) {
      case 'en':
        return 'Email does not match.'
      case 'es':
        return 'Los emails no coinciden.'
      default:
        return 'Email does not match.'
    }
  }

  onChangeCountry() {
    this.selectedCountry = this.countries.find(x => x.id == this.formGroup.controls['country'].value)
  }

  onChangeCountryNotify() {
    this.selectedCountryNotify = this.countries.find(x => x.id == this.consigneeNotifyFormGroup.controls['notifyContactCountryId'].value)
  }

  onChangeCountryConsignee() {
    this.selectedCountryConsignee = this.countries.find(x => x.id == this.consigneeNotifyFormGroup.controls['consigneeContactCountryId'].value)
  }

  redirectAuthCode(){
    var sessionAdCode = sessionStorage.getItem("AdsCode");
     if(sessionAdCode){
      this._securityGateway.doLogin(
        this.formGroup.controls['email'].value, 
        this.formGroup.controls['password'].value, 
        undefined ).subscribe({
          next: this.handleSuccessResponse.bind(this),
          error: this.handleErrorResponse.bind(this) 
      })
     }
  }

  handleSuccessResponse(data: any) {
    if (data?.requiredAuthCode) {
      this.loading = false;
    }
    else if (data?.requiredValidateToken) {
      this.loading = false;
      this._messageService.add({ severity: 'error',
        summary: this.translate.instant('MessageService.Summary.FailedAction'),
        detail: data?.msg ?? this.translate.instant('LoginPage.LoginErrorMessage') })
    }
    else {
      this._authService.authenticate(data.data.accessToken)
      this._profileService.getBasicUser().subscribe({
        next: (v) => {
          const profile = v
          localStorage.setItem('_auth_user', JSON.stringify(profile))
          this.translate.use(profile.lang)
          localStorage.removeItem('_marketplace_port_to_param')
          localStorage.removeItem('_marketplace_country_to_param')
          localStorage.removeItem('_marketplace_country_from_param')
          localStorage.setItem('_dsclm_sh', JSON.stringify(false))

          this.loading = false;
          if (this._authService.getRole() == 'User') {
            switch(profile.type) {
              case 1:
                this.router.navigate([Routes.marketplacePage]).then(() => { })
              break
              case 2:
                this.router.navigate([Routes.marketplacePage]).then(() => { })
              break
              break
              default:
                this.router.navigate([Routes.homePage]).then(() => { })
            }
          }
          else if (this._authService.getRole() == 'Admin') {
            this.router.navigate([Routes.adminManageOrderPage]).then(() => { })
          }
        },
        error: (e) => {
          this.loading = false;
          console.log(e)
        }
      })
    }
  }

  handleErrorResponse(error: any) {

    this.loginError = true;
    this.loading = false;
    
  }
}
