<p-card *ngIf="paymentsInformation">
  <h2>{{'UserPages.UserPurchaseOrders.RegisterPaymentPage.PageTitle' | translate}}</h2>
  <p-table [value]="payments" responsiveLayout="stack" [breakpoint]="'600px'">
    <ng-template pTemplate="caption">
      <div class="flex align-items-center justify-content-between">
        <p>{{'UserPages.UserPurchaseOrders.RegisterPaymentPage.PaymentBeforeText' | translate}}: {{limitPaymentDate | date: 'dd-MM-yyyy HH:mm'}}</p>
        <p-button label="{{'UserPages.UserPurchaseOrders.PaymentsPage.OrderDetailPage.PaymentButton' | translate}}" (onClick)="openCreatePaymentDialog()"></p-button>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th>
          <div class="flex justify-content-center align-items-center">{{'UserPages.UserPurchaseOrders.RegisterPaymentPage.PaymentTable.ValueHeader' | translate}}</div>
        </th>
        <th>
          <div class="flex justify-content-center align-items-center">{{'UserPages.UserPurchaseOrders.RegisterPaymentPage.PaymentTable.DescriptionHeader' | translate}}</div>
        </th>
        <th>
          <div class="flex justify-content-center align-items-center">{{'UserPages.UserPurchaseOrders.RegisterPaymentPage.PaymentTable.SeeHeader' | translate}}</div>
        </th>
        <th>
          <div class="flex justify-content-center align-items-center">{{'UserPages.UserPurchaseOrders.RegisterPaymentPage.PaymentTable.ApprovedHeader' | translate}}</div>
        </th>
        <th>
          <div class="flex justify-content-center align-items-center">{{'UserPages.UserPurchaseOrders.RegisterPaymentPage.PaymentTable.CreatedHeader' | translate}}</div>
        </th>
        <th>
          <div class="flex justify-content-center align-items-center">
            {{'UserPages.UserPurchaseOrders.RegisterPaymentPage.PaymentTable.LoadDocumentHeader' | translate}}
          </div>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-payment>
      <tr>
        <td>
          <div class="flex justify-content-center align-items-center">
            {{ payment.amount | currency:'USD':'US$' }}
          </div>
        </td>
        <td>
          <div class="flex justify-content-center align-items-center">
            {{ payment.description }}
          </div>
        </td>
        <td>
          <div class="flex justify-content-center align-items-center">
            <p-button pRounded icon="pi pi-file-pdf" (onClick)="getPaymentDocument(payment.id)" [disabled]="disableButton(payment)"></p-button>
          </div>
        </td>
        <td>
          <div class="flex justify-content-center align-items-center">
            {{ getIsApproved(payment.isApproved) }}
          </div>
        </td>
        <td>
          <div class="flex justify-content-center align-items-center">
            {{ payment.created | date: 'dd-MM-yyyy' }}
          </div>
        </td>
        <td>
          <div class="flex justify-content-center align-items-center">
            <p-button icon="pi pi-upload" (onClick)="openUploadPaymentDialog(payment)"></p-button>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="summary">
      <p>{{'UserPages.UserPurchaseOrders.RegisterPaymentPage.TotalCost' | translate}}: {{totalPrice | currency:'USD':'US$'}}</p>
      <p>{{'UserPages.UserPurchaseOrders.RegisterPaymentPage.TotalPayments' | translate}}:<span style="font-weight: bold;"> {{totalPayments | currency:'USD':'US$'}}</span></p>
    </ng-template>
  </p-table>

  <div class="grid" style="margin-top: 20px;">
    <div class="col-12 md:col-8 lg:col-8" style="background-color: #6da043; padding: 20px;">
      <div style="color: white;">
        <h3>{{'UserPages.UserPurchaseOrders.RegisterPaymentPage.BankDataText' | translate}}</h3>
        <p>{{'UserPages.UserPurchaseOrders.RegisterPaymentPage.DescriptionText' | translate}}:</p>
      </div>
      <div class="" style="border-radius: 20px; background-color: #527832; padding: 20px; color: white;">
        <div class="grid">
          <div class="col-6">
            <h3>BENEFICIARY COMPANY</h3>
            <p>Name: <span style="font-weight: bolder;">Comercial Fruzty SpA</span></p>
            <p>Address: <span style="font-weight: bolder;">Rosario norte 100 piso 301, Las Condes.</span></p>
          </div>
          <div class="col-6">
            <h3>BENEFICIARY BANK</h3>
            <p>Name: <span style="font-weight: bolder;">Santander Chile</span></p>
            <p>Address: <span style="font-weight: bolder;">Bandera #140, Santiago, Chile</span></p>
            <p>Account: <span style="font-weight: bolder;">5103341428</span></p>
            <p>Switf code: <span style="font-weight: bolder;">BSCHCLRMXXX</span></p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 md:col-4 lg:col-4 flex justify-content-end background-payment-image" style="background-color: #6da043; margin: 0px; padding: 0px;">

    </div>
  </div>
</p-card>

<p-toast></p-toast>

<p-confirmDialog acceptLabel="Yes" rejectLabel="No" rejectButtonStyleClass="p-button-outlined" [style]="{width: '50vw'}"></p-confirmDialog>

<p-dialog #createDialog header="{{'UserPages.UserPurchaseOrders.RegisterPaymentPage.RegiserPaymentDialog.DialogHeader' | translate}}" [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleCreatePaymentDialog" [style]="{width: '50%'}">
  <form [formGroup]="formGroup">
    <div class="p-fluid formgrid grid">
      <div class="field col-12 md:col-6 lg:col-6">
        <label for="swiftId">Swift No</label>
        <input id="swiftId" formControlName="swiftId" type="text" pInputText/>
        <small *ngIf="submit && formGroup.controls['swiftId'].invalid" class="p-error">{{getErrorMessage('swiftId')}}</small>
      </div>
      <div class="field col-12 md:col-6 lg:col-6">
        <label for="amount">{{'UserPages.UserPurchaseOrders.RegisterPaymentPage.RegiserPaymentDialog.ValueLabel' | translate}} (USD)</label>
        <p-inputNumber id="amount" formControlName="amount" [min]="0" mode="currency" currency="USD" locale="en-US"/>
        <small *ngIf="submit && formGroup.controls['amount'].invalid" class="p-error">{{getErrorMessage('amount')}}</small>
      </div>
      <div class="field col-12">
        <label for="description">{{'UserPages.UserPurchaseOrders.RegisterPaymentPage.RegiserPaymentDialog.DescriptionLabel' | translate}}</label>
        <input id="description" formControlName="description" type="text" pInputText/>
      </div>
      <div class="field col-12">
        <p-fileUpload
        (onSelect)="onSelect($event)"
        [showUploadButton]="false"
        [multiple]="false"
        accept=".pdf,image/*"
        [maxFileSize]="1000000"
        chooseLabel="{{'UserPages.UserPurchaseOrders.RegisterPaymentPage.RegiserPaymentDialog.SelectImageButton' | translate}}"
        cancelLabel="{{'UserPages.UserPurchaseOrders.RegisterPaymentPage.RegiserPaymentDialog.CancelImagesButton' | translate}}"/>
      </div>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <p-button label="{{'UserPages.UserPurchaseOrders.RegisterPaymentPage.RegiserPaymentDialog.CloseModalButton' | translate}}" [loading]="loading" (onClick)="visibleCreatePaymentDialog = false" styleClass="p-button-outlined"></p-button>
    <p-button label="{{'UserPages.UserPurchaseOrders.RegisterPaymentPage.RegiserPaymentDialog.RegisterButton' | translate}}" [loading]="loading" (onClick)="createPayment()"></p-button>
  </ng-template>
</p-dialog>


<p-dialog #uploadDialog header="{{'UserPages.UserPurchaseOrders.RegisterPaymentPage.LoadDocumentDialog.DialogHeader' | translate}}" [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleUploadPaymentDialog" [style]="{width: '50%'}">
  <!-- <form [formGroup]="formGroup"> -->
    <div class="p-fluid formgrid grid">
      <div class="field col-12">
        <p-fileUpload
        (onSelect)="onSelect($event)"
        [showUploadButton]="false"
        [multiple]="false"
        accept=".pdf,image/*"
        [maxFileSize]="1000000"
        chooseLabel="{{'UserPages.UserPurchaseOrders.RegisterPaymentPage.LoadDocumentDialog.SelectImageButton' | translate}}"
        cancelLabel="{{'UserPages.UserPurchaseOrders.RegisterPaymentPage.LoadDocumentDialog.CancelImagesButton' | translate}}"/>
      </div>
    </div>
  <!-- </form> -->
  <ng-template pTemplate="footer">
    <p-button label="{{'UserPages.UserPurchaseOrders.RegisterPaymentPage.LoadDocumentDialog.CloseModalButton' | translate}}" [loading]="loading" (onClick)="visibleUploadPaymentDialog = false" styleClass="p-button-outlined"></p-button>
    <p-button label="{{'UserPages.UserPurchaseOrders.RegisterPaymentPage.LoadDocumentDialog.LoadButton' | translate}}" [loading]="loading" (onClick)="uploadPayment()"></p-button>
  </ng-template>
</p-dialog>
