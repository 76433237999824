import { Component, Input } from '@angular/core';
import { ExportOffer, ExportOfferAd } from 'src/app/domain/models/ExportOffer';
import { ExportOfferUtils } from 'src/app/ui/util/export-offer-utils';

@Component({
  selector: 'app-export-offer-detail-carousel',
  templateUrl: './export-offer-detail-carousel.component.html',
  styleUrls: ['./export-offer-detail-carousel.component.css']
})
export class ExportOfferDetailCarouselComponent {

  @Input()
  offer: ExportOffer | undefined

  @Input()
  offerAd: ExportOfferAd | undefined

  responsiveOptions: any = [
    {
        breakpoint: '1199px',
        numVisible: 1,
        numScroll: 1
    },
    {
        breakpoint: '991px',
        numVisible: 1,
        numScroll: 1
    },
    {
        breakpoint: '767px',
        numVisible: 1,
        numScroll: 1
    }
  ]

  position: string = "left"

  getPictures(): any {
    if(this.offer){

      if(this.offer?.offerPictures && this.offer?.offerPictures.length > 0){
        return this.offer.offerPictures
      }

      if(this.offer?.pictures && this.offer?.pictures.length > 0)
        return this.offer.pictures

      return []
    }
    else if(this.offerAd){

      if(this.offerAd?.pictures && this.offerAd?.pictures.length > 0)
      {
        return this.offerAd.pictures
      }

      return []
    }
    return []
  }

  completeUrl(url: string): string{
    if(url)
      return ExportOfferUtils.completeUrl(url);
    return ''
  }

  getExportOfferImageUrl() {
    if (this.offer) return ExportOfferUtils.getImageUrl(this.offer, this.offer.pictures, 0)
    return ""
  }

  getExportOfferAdImageUrl() {
    if (this.offerAd) return ExportOfferUtils.getImageAdUrl(this.offerAd, this.offerAd.pictures, 0)
    return ""
  }
}
