<app-user-dashboard [userMenuActiveItemIndex]="0" [activeItem]="'export-offers'">
  <p-breadcrumb class="max-w-full" [model]="breadcrumbMenuItems" [home]="breadcrumbHome"></p-breadcrumb>
  <form [formGroup]="formGroup">
    <p-card>
      <ng-template pTemplate="header">
        <div class="flex justify-content-between flex-wrap align-items-center" style="padding: 15px;">
          <h2>{{'UserPages.UserClosedSale.UserEditClosedSale.PageTitle' | translate}}</h2>
        </div>
      </ng-template>
      <ng-template pTemplate="body">
        <h2>{{'UserPages.UserClosedSale.UserEditClosedSale.PageTitle2' | translate}}</h2>
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
            <label for="species">{{'UserPages.UserClosedSale.UserEditClosedSale.SpecieFieldLabel' | translate}}</label>
            <input pInputText inputId="species" [value]="getSpeciesName(exportOffer?.variety?.species)" readonly/>
          </div>
          <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
            <label for="variety">{{'UserPages.UserClosedSale.UserEditClosedSale.VarietyFieldLabel' | translate}}</label>
            <input pInputText inputId="variety" [value]="exportOffer?.variety?.name" readonly/>
          </div>
          <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
            <label for="caseQuantityPerPallet">{{'UserPages.UserClosedSale.UserEditClosedSale.CasePerPalletsFieldLabel' | translate}} *</label>
            <p-inputNumber inputId="caseQuantityPerPallet" formControlName="caseQuantityPerPallet" placeholder="{{'UserPages.UserClosedSale.UserEditClosedSale.CasePerPalletsFieldLabel' | translate}}"></p-inputNumber>
            <small *ngIf="submit && formGroup.controls['caseQuantityPerPallet'].invalid" class="p-error">{{getErrorMessage(formGroup, 'caseQuantityPerPallet')}}</small>
          </div>
          <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
            <label for="listPrice">{{'UserPages.UserClosedSale.UserEditClosedSale.ListPriceFieldLabel' | translate}} *</label>
            <p-inputNumber inputId="listPrice" formControlName="listPrice" mode="currency" currency="USD" locale="en-US" placeholder="{{'UserPages.UserClosedSale.UserEditClosedSale.ListPriceFieldPlaceholder' | translate}}"></p-inputNumber>
            <small *ngIf="submit && formGroup.controls['listPrice'].invalid" class="p-error">{{getErrorMessage(formGroup, 'listPrice')}}</small>
          </div>
          <div class="field col-12 md:col-3 lg:col-3">
            <label for="incoterm">{{'UserPages.UserClosedSale.UserEditClosedSale.IncotermsFieldLabel' | translate}} *</label>
            <p-dropdown inputId="incoterm" formControlName="incoterm" [options]="incotermsOptions" optionLabel="label" optionValue="value" placeholder="{{'UserPages.UserClosedSale.UserEditClosedSale.IncotermsFieldPlaceholder' | translate}}"></p-dropdown>
            <small *ngIf="submit && formGroup.controls['incoterm'].invalid" class="p-error">{{getErrorMessage(formGroup, 'incoterm')}}</small>
          </div>
          <div class="field col-12 md:col-3 lg:col-3">
            <label for="paymentAgainstBL">{{ paymentBLTitle | translate }} *</label>
            <p-dropdown id="paymentAgainstBL" formControlName="paymentAgainstBL" [options]="paymentBLItems" optionLabel="label" optionValue="value" placeholder="{{'UserPages.UserExportOffers.NewExportOffer.PaymentAgainstBLClosedSaleLabel' | translate}}"></p-dropdown>
            <small *ngIf="submit && formGroup.controls['paymentAgainstBL'].invalid" class="p-error">{{getErrorMessage(formGroup, 'paymentAgainstBL')}}</small>
            <small *ngIf="showPaymentBLPercent">{{'UserPages.UserExportOffers.NewExportOffer.FinalPaymentSubjectQCClosedSaleReview' | translate}} {{finalPaymentPercent}}%</small>
          </div>
          <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
            <label for="palletQuantity">{{'UserPages.UserClosedSale.UserEditClosedSale.PalletQuantityFieldLabel' | translate}}</label>
            <input pInputText inputId="palletQuantity" [value]="exportOffer?.palletQuantity" readonly/>
          </div>
          <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
            <label for="fridgeStorage">{{'UserPages.UserClosedSale.UserEditClosedSale.FridstorageFieldLabel' | translate}} *</label>
            <p-dropdown id="fridgeStorage"
              formControlName="fridgeStorageId"
              [options]="fridgeStorages"
              optionLabel="name"
              optionValue="id"
              [filter]="true"
              filterBy="name"
              [showClear]="true"
              placeholder="{{'UserPages.UserClosedSale.UserEditClosedSale.FridstorageFieldPlaceholder' | translate}}">
            </p-dropdown>
            <small *ngIf="submit && formGroup.controls['fridgeStorageId'].invalid" class="p-error">{{getErrorMessage(formGroup, 'fridgeStorageId')}}</small>
          </div>
        </div>
        <p-divider></p-divider>
        <h2>{{'UserPages.UserClosedSale.UserEditClosedSale.PageTitle3' | translate}}</h2>
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
            <label for="harvestDate">{{'UserPages.UserClosedSale.UserEditClosedSale.HarvestDateFieldLabel' | translate}}</label>
            <input pInputText inputId="harvestDate" value="{{exportOffer?.harvestDate | date: 'dd-MM-yyyy'}}" readonly/>
          </div>
          <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
            <label for="shelfLife">Shelf life ({{'UserPages.UserClosedSale.UserEditClosedSale.DayShelflifeText' | translate}})</label>
            <input pInputText inputId="shelfLife" [value]="exportOffer?.shelfLife" readonly/>
          </div>
          <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
            <label for="netKilos">{{'UserPages.UserClosedSale.UserEditClosedSale.NetKiloFieldLabel' | translate}}</label>
            <input pInputText inputId="netKilos" [value]="exportOffer?.netKilos" readonly/>
          </div>
          <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
            <label for="grossKilos">{{'UserPages.UserClosedSale.UserEditClosedSale.GrossKiloFieldLabel' | translate}}</label>
            <input pInputText inputId="grossKilos" [value]="exportOffer?.grossKilos" readonly/>
          </div>
          <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
            <label for="boxType">{{'UserPages.UserClosedSale.UserEditClosedSale.BoxTypeFieldLabel' | translate}}</label>
            <input pInputText inputId="boxType" [value]="getBoxType(exportOffer?.boxType)" readonly/>
          </div>
          <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
            <label for="packagings">{{'UserPages.UserExportOffers.NewExportOffer.PackagingLabel' | translate}}</label>
            <input pInputText inputId="packagings" [value]="exportOffer?.packaging" readonly/>
          </div>
          <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
            <label for="caseSize">{{'UserPages.UserExportOffers.EditExportOffer.CaseSize' | translate}}</label>
            <input pInputText inputId="caseSize" [value]="exportOffer?.caseSize" readonly/>
          </div>
          <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
            <label for="speciesSize">{{'UserPages.UserClosedSale.UserEditClosedSale.SpeciesSizeFieldLabel' | translate}}</label>
            <input pInputText inputId="speciesSize" [value]="exportOffer?.speciesSize" placeholder="{{'UserPages.UserClosedSale.UserEditClosedSale.SpeciesSizeFieldPlaceholder' | translate}}" readonly/>
          </div>
          <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
            <label for="sizeMillimeters">{{'UserPages.UserClosedSale.UserEditClosedSale.SpeciesSizeMillimetersFieldLabel' | translate}}</label>
            <input pInputText inputId="speciesSize" [value]="exportOffer?.sizeMillimeters + ' mm'" placeholder="{{'UserPages.UserClosedSale.UserEditClosedSale.SpeciesSizeMillimetersFieldPlaceholder' | translate}}" readonly/>
          </div>
          <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
            <label for="basePallets">{{'UserPages.UserClosedSale.UserEditClosedSale.BasePalletsFieldLabel' | translate}}</label>
            <p-dropdown inputId="basePallets" formControlName="basePallets" [options]="basePallets" optionLabel="label" optionValue="value" placeholder="{{'UserPages.UserClosedSale.UserEditClosedSale.BasePalletsFieldPlaceholder' | translate}}"></p-dropdown>
          </div>
          <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
            <label for="isQualityControl">{{'UserPages.UserClosedSale.UserEditClosedSale.isQualityControlFieldLabel' | translate}}</label>
            <input pInputText inputId="isQualityControl" [value]="exportOffer?.isQualityControl ? 'Si': 'No'" readonly/>
          </div>
          <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
            <label for="qualityControlDate">{{'UserPages.UserClosedSale.UserEditClosedSale.QualityControlDateFieldLabel' | translate}}</label>
            <input pInputText inputId="qualityControlDate" [value]="formatQualityControlDate" readonly/>
          </div>
          <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
            <label for="marks">{{'UserPages.UserClosedSale.UserEditClosedSale.HasMarksFieldLabel' | translate}}</label>
            <p-dropdown id="marks" formControlName="marks" [options]="marksOptions" optionLabel="label" optionValue="value" placeholder="Marcas"></p-dropdown>
          </div>
        </div>

        <h2>{{'UserPages.UserClosedSale.UserEditClosedSale.ImagesTitle' | translate}}</h2>
        <p-table [value]="pictures">
          <ng-template pTemplate="caption">
            <div class="flex justify-content-start align-items-center gap-4">
              <p-fileUpload
                #imagesUploader
                mode="basic"
                chooseLabel="{{'UserPages.UserClosedSale.UserEditClosedSale.LoadButton' | translate}}"
                [fileLimit]="1"
                [auto]="true"
                accept=".jpg, .jpeg, .png"
                [maxFileSize]="3000000"
                (onSelect)="onSelectImage($event, imagesUploader)"
                [disabled]="pictures.length === 3"></p-fileUpload>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
          </ng-template>
          <ng-template pTemplate="body" let-picture let-rowIndex="rowIndex">
            <tr>
              <td>
                <img [src]="getImageUrl(picture)" style="border-radius: 7px; width: 100%; max-width: 100px; height: auto;" alt=""/>
              </td>
              <td>
                {{getImageName(picture)}}
              </td>
              <td>
                <div class="flex justify-content-end align-items-center gap-4">
                  <p-button icon="pi pi-times" (click)="onRemoveImage(rowIndex)"></p-button>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>

        <div style="margin-top: 50px;">
          <h2>{{'UserPages.UserExportOffers.EditExportOffer.PageTitle6' | translate}}</h2>
          <p>{{'UserPages.UserExportOffers.EditExportOffer.PageSubtitle5' | translate}}</p>
          <p-table
            [loading]="loading"
            [value]="productCertificates"
            [paginator]="true"
            [rows]="10"
            [showCurrentPageReport]="true"
            [rowsPerPageOptions]="[10, 25, 50]"
            responsiveLayout="stack"
            [breakpoint]="'600px'">
            <ng-template pTemplate="caption">
              <div class="flex align-items-center justify-content-start">
                <p-button label="{{'UserPages.UserProductCertificates.NewButtonLabel' | translate}}" icon="pi pi-plus" (onClick)="openProductCertificateCreateDialog(formDirective, productCertificateFileUploader)" class="mr-2"/>
              </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                  <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserProductCertificates.NameHeader' | translate}}</div></th>
                  <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserProductCertificates.CertificateTypeHeader' | translate}}</div></th>
                  <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserProductCertificates.IssueDateHeader' | translate}}</div></th>
                  <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserProductCertificates.ExpireDateHeader' | translate}}</div></th>
                  <th><div class="flex justify-content-center align-items-center">PDF</div></th>
                  <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-certificate>
              <tr [pSelectableRow]="certificate">
                <td><div class="flex justify-content-center align-items-center">{{certificate.name}}</div></td>
                <td><div class="flex justify-content-center align-items-center">{{certificate.productCertificateType.name}}</div></td>
                <td><div class="flex justify-content-center align-items-center">{{certificate.documentIssueDate | date:'dd-MM-yyyy'}}</div></td>
                <td><div class="flex justify-content-center align-items-center">{{certificate.expirationDate | date:'dd-MM-yyyy'}}</div></td>
                <td><div class="flex justify-content-center align-items-center">
                  <i (click)="getProductCertificateDocument(certificate.id)" pTooltip="Document PDF" class="pi pi-file-pdf" style="font-size: 2rem; cursor: pointer;"></i>
                </div></td>
                <td>
                  <div class="flex justify-content-end align-items-center gap-2">
                    <p-button label="{{'UserPages.UserProductCertificates.EditButtonLabel' | translate}}" icon="pi pi-pencil" (onClick)="openProductCertificateEditDialog(certificate, productCertificateFileUploader)" class="mr-2"/>
                    <p-button label="{{'UserPages.UserProductCertificates.DeleteButtonLabel' | translate}}" icon="pi pi-trash" (onClick)="onDeleteProductCertificate(certificate)" class="mr-2"/>
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>

        <div style="margin-top: 50px;">
          <h2>{{'UserPages.UserExportOffers.NewExportOffer.PageTitle6' | translate}}</h2>
          <p>{{'UserPages.UserExportOffers.NewExportOffer.PageSubtitle6' | translate}}</p>
          <p-table [value]="documents" responsiveLayout="stack" [breakpoint]="'600px'">
            <ng-template pTemplate="caption">
              <div class="flex align-items-center justify-content-start">
                <p-button label="{{'UserPages.UserExportOfferDocument.NewButtonLabel' | translate}}" icon="pi pi-plus" class="mr-2" (onClick)="openDocumentCreateDialog(documentFormDirective, documentFileUploader)"/>
              </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                  <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserExportOfferDocument.NameHeader' | translate}}</div></th>
                  <th><div class="flex justify-content-center align-items-center">PDF</div></th>
                  <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-document>
                <tr>
                  <td><div class="flex justify-content-center align-items-center">{{ document.name }}</div></td>
                  <td>
                    <div class="flex justify-content-center align-items-center">
                      <i (click)="getExportOfferDocument(document.id)" pTooltip="Document PDF" class="pi pi-file-pdf" style="font-size: 2rem; cursor: pointer;"></i>
                    </div>
                  </td>
                  <td>
                    <div class="flex justify-content-end align-items-center gap-2">
                      <p-button label="{{'UserPages.UserExportOfferDocument.EditButtonLabel' | translate}}" icon="pi pi-pencil" (onClick)="openDocumentEditDialog(document, documentFileUploader)" class="mr-2"/>
                      <p-button label="{{'UserPages.UserExportOfferDocument.DeleteButtonLabel' | translate}}" icon="pi pi-trash" (onClick)="onDeleteDocument(document)" class="mr-2"/>
                    </div>
                  </td>
                </tr>
            </ng-template>
          </p-table>
        </div>

        <div class="grid" style="margin-top: 70px;">
          <div class="col-6"></div>
          <div class="col-6">
            <div class="p-fluid formgrid grid">
              <div class="col-6">
                <p-button label="{{'UserPages.UserExportOffers.EditExportOffer.BackButton' | translate}}" (click)="goToClosedSalesPage()" [loading]="loading" styleClass="p-button-outlined"></p-button>
              </div>
              <div class="col-6">
                <p-button label="{{'UserPages.UserExportOffers.EditExportOffer.SaveButton' | translate}}" (onClick)="save()" [loading]="loading"></p-button>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </p-card>
  </form>
</app-user-dashboard>

<p-dialog header="{{'UserPages.UserProductCertificates.PageTitle' | translate}}" [(visible)]="productCertificateDialogVisible" [style]="{width: '50%'}" [modal]="true">
  <form [formGroup]="productCertificateFormGroup" #formDirective="ngForm">
    <div class="p-fluid formgrid grid" style="padding: 20px;">
      <div class="field col-12 md:col-6 lg:col-6" style="margin-bottom: 40px;">
        <label for="name" style="font-weight: bolder;">{{'UserPages.UserProductCertificates.Form.NameLabel' | translate}} *</label>
        <input id="name" formControlName="name" type="text" required pInputText placeholder="{{'UserPages.UserProductCertificates.Form.NameLabel' | translate}}"/>
        <small *ngIf="submitProductCertificate && productCertificateFormGroup.controls['name'].invalid" class="p-error">{{getErrorMessage(productCertificateFormGroup, 'name')}}</small>
      </div>
      <div class="field col-12 md:col-6 lg:col-6" style="margin-bottom: 40px;">
        <label for="certificateType" style="font-weight: bolder;">{{'UserPages.UserProductCertificates.Form.CertificateTypeLabel' | translate}} *</label>
        <p-dropdown inputId="certificateType" formControlName="productCertificateTypeId" [options]="certificateTypes" optionLabel="name" optionValue="id" placeholder="{{'UserPages.UserProductCertificates.Form.CertificateTypeLabel' | translate}}"></p-dropdown>
        <small *ngIf="submitProductCertificate && productCertificateFormGroup.controls['productCertificateTypeId'].invalid" class="p-error">{{getErrorMessage(productCertificateFormGroup, 'productCertificateTypeId')}}</small>
      </div>
      <div class="field col-12 md:col-6 lg:col-6" style="margin-bottom: 40px;">
        <label for="issueDate" style="font-weight: bolder;">{{'UserPages.UserProductCertificates.Form.IssueDateLabel' | translate}} </label>
        <p-calendar
          id="issueDate"
          appendTo="body"
          formControlName="documentIssueDate"
          [showButtonBar]="true"
          [showIcon]="true"
          placeholder="{{'UserPages.UserProductCertificates.Form.IssueDateLabel' | translate}}"
          [showWeek]="true"
          [firstDayOfWeek]="1"></p-calendar>
      </div>
      <div class="field col-12 md:col-6 lg:col-6" style="margin-bottom: 40px;">
        <label for="expirationDate" style="font-weight: bolder;">{{'UserPages.UserProductCertificates.Form.ExpireDateLabel' | translate}}</label>
        <p-calendar
          id="expirationDate"
          appendTo="body"
          formControlName="expirationDate"
          [showButtonBar]="true"
          [showIcon]="true"
          placeholder="{{'UserPages.UserProductCertificates.Form.ExpireDateLabel' | translate}}"
          [showWeek]="true"
          [firstDayOfWeek]="1"></p-calendar>
      </div>
      <div class="field col-12" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserProductCertificates.Form.LoadDocumentLabel' | translate}}</label>
        <p-fileUpload
          #productCertificateFileUploader
          (onSelect)="onSelectProductCertificateFile($event)"
          (onClear)="onClearProductCertificateFile($event)"
          [showUploadButton]="false"
          [multiple]="false"
          accept=".pdf"
          [maxFileSize]="5000000"
          chooseLabel="{{'UserPages.UserProductCertificates.Form.SelectFile' | translate}}"
          cancelLabel="{{'UserPages.UserProductCertificates.Form.CancelSelectFile' | translate}}"/>
      </div>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <p-button label="{{'UserPages.UserProductCertificates.Form.BackButtonLabel' | translate}}" (onClick)="productCertificateDialogVisible = false" styleClass="p-button-outlined"></p-button>
    <p-button label="{{'UserPages.UserProductCertificates.Form.SaveButtonLabel' | translate}}" (onClick)="saveProductCertificate()" [loading]="loading"></p-button>
  </ng-template>
</p-dialog>

<p-dialog header="{{'UserPages.UserExportOfferDocument.PageTitle' | translate}}" [(visible)]="documentDialogVisible" [style]="{width: '50%'}" [modal]="true">
  <form [formGroup]="documentFormGroup" #documentFormDirective="ngForm">
    <div class="p-fluid formgrid grid" style="padding: 20px;">
      <div class="field col-12 md:col-12 lg:col-12" style="margin-bottom: 40px;">
        <label for="name" style="font-weight: bolder;">{{'UserPages.UserExportOfferDocument.Form.NameLabel' | translate}} *</label>
        <input id="name" formControlName="name" type="text" required pInputText placeholder="{{'UserPages.UserExportOfferDocument.Form.NameLabel' | translate}}"/>
        <small *ngIf="submitDocument && documentFormGroup.controls['name'].invalid" class="p-error">{{getErrorMessage(documentFormGroup, 'name')}}</small>
      </div>
      <div class="field col-12" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserExportOfferDocument.Form.LoadDocumentLabel' | translate}}</label>
        <p-fileUpload
          #documentFileUploader
          (onSelect)="onSelectDocumentFile($event)"
          (onClear)="onClearDocumentFile($event)"
          [showUploadButton]="false"
          [multiple]="false"
          accept=".pdf"
          [maxFileSize]="5000000"
          chooseLabel="{{'UserPages.UserExportOfferDocument.Form.SelectFile' | translate}}"
          cancelLabel="{{'UserPages.UserExportOfferDocument.Form.CancelSelectFile' | translate}}"/>
      </div>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <p-button label="{{'UserPages.UserExportOfferDocument.Form.BackButtonLabel' | translate}}" (onClick)="documentDialogVisible = false" styleClass="p-button-outlined"></p-button>
    <p-button label="{{'UserPages.UserExportOfferDocument.Form.SaveButtonLabel' | translate}}" (onClick)="saveDocument()" [loading]="loading"></p-button>
  </ng-template>
</p-dialog>

<p-toast></p-toast>

<p-confirmDialog acceptLabel="Yes" rejectLabel="No" rejectButtonStyleClass="p-button-outlined" [style]="{width: '50vw'}"></p-confirmDialog>
