import { Injectable } from "@angular/core";
import { ProgramRequestGateway } from "../gateway/ProgramRequestGateway";
import { CreateProgramRequest, ProgramRequest, ProgramRequestInformation, UpdateProgramRequest } from "src/app/domain/models/ProgramRequest";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Routes } from "src/app/infrastructure/routes";

@Injectable({providedIn: 'root'})
export class ProgramRequestService extends ProgramRequestGateway {
    constructor( private _http: HttpClient)
    {
        super()
    }


    create(createProgramRequest: CreateProgramRequest): Observable<ProgramRequest> {
        return this._http.post<ProgramRequest>(Routes.programRequestApi + '/Create', this.getNewProgramRequestFormData(createProgramRequest));
    }

    update(updateProgramRequest: ProgramRequest): Observable<ProgramRequest> {
        return this._http.put<ProgramRequest>(Routes.programRequestApi + '/Update', this.getFormData(updateProgramRequest));
    }

    getAllByUsername(): Observable<ProgramRequestInformation[]> {
        
        return this._http.get<ProgramRequestInformation[]>(Routes.programRequestApi + '/GetAllByUsername');
    }

    
    getAllByExporterUsername(): Observable<ProgramRequestInformation[]> {
        
        return this._http.get<ProgramRequestInformation[]>(Routes.programRequestApi + '/GetAllByExporterUsername');
    }

    GetById(guid: string): Observable<ProgramRequestInformation> {
        
        return this._http.get<ProgramRequestInformation>(Routes.programRequestApi + '/GetProgramRequestByGuid', { params: {guid}} );
    }

    getAllByFilter(request: any): Observable<ProgramRequestInformation[]> {
        
        return this._http.post<ProgramRequestInformation[]>(Routes.programRequestApi + '/GetAllByFilter', this.getFilterForm(request) );
    }

    updateStatus(request: UpdateProgramRequest): Observable<ProgramRequest> {
        return this._http.put<ProgramRequest>(Routes.programRequestApi + '/UpdateStatus',  request);
    }

    getNewProgramRequestFormData(request: CreateProgramRequest)
    {
        const formData = new FormData();

        if(request)
            {
                formData.append('importerUsername', request.importerUsername);
                formData.append('exporterId', request.exporterId.toString());
                formData.append('varietyId', request.varietyId.toString());
                formData.append('consigneeId', request.consigneeId.toString());
                formData.append('notifyId', request.notifyId.toString());
                formData.append('startWeek', request.startWeek.toString());
                formData.append('startWeekYear', request.startWeekYear.toString());
                formData.append('endWeek', request.endWeek.toString());
                formData.append('endWeekYear', request.endWeekYear.toString());
                formData.append('countryId', request.countryId.toString());
                formData.append('countryToId', request.countryToId.toString());
                formData.append('portToId', request.portToId.toString());
                formData.append('importPurchaseOrder', request.importPurchaseOrder);
                formData.append('pricePerCase', request.pricePerCase.toString());
                formData.append('paymentAgainstBL', request.paymentAgainstBL.toString());
                formData.append('palletQuantity', request.palletQuantity.toString());
                formData.append('caseQuantityPerPallet', request.caseQuantityPerPallet.toString())
                formData.append('boxType', request.boxType)
                formData.append('typeSale', request.typeSale)
                formData.append('basePallets', request.basePallets ? request.basePallets : "")
                formData.append("packaging", request.packaging && 
                    request.packaging.length > 0 ? JSON.stringify(request.packaging) : "[]")
                formData.append("productCertificateType", request.productCertificateType && 
                request.productCertificateType.length > 0 ? JSON.stringify(request.productCertificateType) : "[]")

                formData.append('netKilos', request.netKilos)
                    
                
                if(request.speciesSize)
                    {
                        formData.append('speciesSize', request.speciesSize)
                    }
                
                if(request.category)
                {
                    formData.append('category', request.category)
                }

                if(request.otherPackaging)
                    {
                        formData.append('otherPackaging', request.otherPackaging)
                    }

                if(request.otherCertificate)
                    {
                        formData.append('otherCertificate', request.otherCertificate)
                    }

                formData.append('basePallets', request.basePallets)

                if(request.pictures) 
                {

                    request.pictures.forEach((p: any) => {
                    formData.append("pictures", p)
                })
                }


                if(request.dataSheetPdf)
                {
                    formData.append("dataSheetPdf", request.dataSheetPdf)
                }

                if(request.importPurchaseOrderPdf)
                {
                    formData.append("importPurchaseOrderPdf", request.importPurchaseOrderPdf)
                }
            }

            return formData;
    }

    getFormData(request: ProgramRequest)
    {
        const formData = new FormData();

        if(request) {
            formData.append('id', request.id.toString());
            formData.append("importerUsername", request.importerUsername ? request.importerUsername : '')
            formData.append('exporterId', request.exporterId.toString());
            formData.append('varietyId', request.varietyId.toString());
            formData.append('consigneeId', request.consigneeId.toString());
            formData.append('notifyId', request.notifyId.toString());
            formData.append('startWeek', request.startWeek.toString());
            formData.append('startWeekYear', request.startWeekYear.toString());
            formData.append('endWeek', request.endWeek.toString());
            formData.append('endWeekYear', request.endWeekYear.toString());
            formData.append('countryId', request.countryId.toString());
            formData.append('countryToId', request.countryToId.toString());
            formData.append('portToId', request.portToId.toString());
            formData.append('importPurchaseOrder', request.importPurchaseOrder);
            formData.append('pricePerCase', request.pricePerCase.toString());
            formData.append('paymentAgainstBL', request.paymentAgainstBL.toString());
            formData.append('palletQuantity', request.palletQuantity.toString());
            formData.append('caseQuantityPerPallet', request.caseQuantityPerPallet.toString())
            formData.append('boxType', request.boxType)
            formData.append('typeSale', request.typeSale)
            formData.append('basePallets', request.basePallets ? request.basePallets : "")
            formData.append("otherPackaging", request.otherPackaging ? request.otherPackaging.toString() : '')
            formData.append("packaging", request.packaging && 
                request.packaging.length > 0 ? JSON.stringify(request.packaging) : "[]")
            formData.append("productCertificateType", request.productCertificateType && 
            request.productCertificateType.length > 0 ? JSON.stringify(request.productCertificateType) : "[]")

            formData.append('netKilos', request.netKilos)
            if(request.pictures) {
                request.pictures.forEach((p: any) => {
                    formData.append("pictures", p)
                })
                }
    
                if(request.removedPictures) {
                request.removedPictures?.forEach(p => {
                    formData.append("removedPictures", p.toString())
                })
                }

                                
            if(request.speciesSize)
                {
                    formData.append('speciesSize', request.speciesSize)
                }
            
            if(request.category)
            {
                formData.append('category', request.category)
            }

            if(request.otherPackaging)
                {
                    formData.append('otherPackaging', request.otherPackaging)
                }

            if(request.otherCertificate)
                {
                    formData.append('otherCertificate', request.otherCertificate)
                }

            formData.append('basePallets', request.basePallets)

            if(request.pictures) 
            {

                request.pictures.forEach((p: any) => {
                formData.append("pictures", p)
            })
            }


            if(request.dataSheetPdf)
            {
                formData.append("dataSheetPdf", request.dataSheetPdf)
            }

            if(request.importPurchaseOrderPdf)
            {
                formData.append("importPurchaseOrderPdf", request.importPurchaseOrderPdf)
            }

                
        }

        return formData;
    }

    getFilterForm(request: any)
    {
        const formData = new FormData()
        
        if(request){
            if(request.speciesId)
                {
                    formData.append('speciesId', request.speciesId.toString())
                }
            if(request.varietyId)
                {
                    formData.append('varietyId', request.varietyId.toString())
                }
            if(request.importerId)
                {
                    formData.append('importerId', request.importerId.toString())
                }
            if(request.status)
                {
                    formData.append('status', request.status.toString())
                }
            if(request.id)
                {
                    formData.append('id', request.id.toString())
                }

            if(request.kilosPerBox){
                formData.append('kilosPerBox', request.kilosPerBox)
                }
            if(request.marketId){
                formData.append('marketId', request.marketId.toString())
                }
            if(request.speciesSize){
                formData.append('speciesSize', request.speciesSize.toString())
            }
            if(request.price){
                formData.append('price', request.price.toString())
            }
        }

        return formData;
    }
}