import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-qr-image',
  templateUrl: './qr-image.component.html',
  styleUrls: ['./qr-image.component.css']
})
export class QrImageComponent {

  @Input()
  imageQrData: any

  @Input()
  width: string = 'auto';

  @Input()
  height: string = 'auto';
}
