import { Component,Input } from '@angular/core';
import { ImportRequest, ImportRequestInformation } from 'src/app/domain/models/ImportRequest';

import { ExportOfferUtils } from 'src/app/ui/util/export-offer-utils';

@Component({
  selector: 'app-import-request-carousel',
  templateUrl: './import-request-carousel.component.html',
  styleUrls: ['./import-request-carousel.component.css']
})
export class ImportRequestCarouselComponent {

  @Input()
  offer: ImportRequestInformation | undefined

  @Input()
  offerAd: ImportRequest | undefined

  responsiveOptions: any = [
    {
        breakpoint: '1199px',
        numVisible: 1,
        numScroll: 1
    },
    {
        breakpoint: '991px',
        numVisible: 1,
        numScroll: 1
    },
    {
        breakpoint: '767px',
        numVisible: 1,
        numScroll: 1
    }
  ]

  position: string = "left"

  getPictures(): any {
    if(this.offer){

      if(this.offer?.pictures && this.offer?.pictures.length > 0){
        return this.offer.pictures
      }

      if(this.offer?.pictures && this.offer?.pictures.length > 0)
        return this.offer.pictures

      return []
    }
    else if(this.offerAd){

      if(this.offerAd?.pictures && this.offerAd?.pictures.length > 0)
      {
        return this.offerAd.pictures
      }

      return []
    }
    return []
  }

  completeUrl(url: string): string{
    if(url)
      return ExportOfferUtils.completeUrl(url);
    return ''
  }


}
