import { Component, Inject, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ExportOfferGateway } from 'src/app/application/gateway/ExportOfferGateway';
import { PortGateway } from 'src/app/application/gateway/PortGateway';
import { CountryService } from 'src/app/application/service/CountryService';
import { Country } from 'src/app/domain/models/Country';
import { MarketplaceResult, ExportOfferAd } from 'src/app/domain/models/ExportOffer';
import { Variety } from 'src/app/domain/models/Variety';
import { Species } from 'src/app/domain/models/Species';
import { ImportRequestInformation } from 'src/app/domain/models/ImportRequest';
import { TranslateService } from '@ngx-translate/core';
import { Routes } from 'src/app/infrastructure/routes';
import { BasicUser } from 'src/app/domain/models/User';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';

@Component({
  selector: 'app-external-marketplace-page',
  templateUrl: './external-marketplace-page.component.html',
  styleUrls: ['./external-marketplace-page.component.css'],
  providers: [MessageService]
})
export class ExternalMarketplacePageComponent implements OnInit {
  formGroup: FormGroup = this._formBuilder.group({
    countryFromId: ['', Validators.required],
    portToId: ['', Validators.required],
    countryToId: ['', Validators.required],
  })

  loading: boolean = false

  selectedImportRequest: ImportRequestInformation | undefined
  selectedCountryFrom: Country | undefined
  selectedCountryTo: Country | undefined

  adsCode: string | undefined

  marketplaceResult: MarketplaceResult | undefined

  offers: ExportOfferAd[] = []
  originCountries: Country[] = []
  originCountriesOptions: any[] = []

  species!: Species[]
  filterSpecies: Species[] = []
  filterVarieties: Variety[] = []
  selectedSpecies: Species | undefined

  visibleOfferDetailDialog: boolean = false
  pallets: number = 0

  negotiationFormGroup: FormGroup = this._formBuilder.group({
    negociationPrice: ['', Validators.required],
    negociationIncoterm: ['', Validators.required],
    negociationPallets: [null, Validators.required],
    negotiationPaymentAgainstBL: [null, Validators.required]
  })

  constructor(@Inject(LOCALE_ID) public locale: string,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _messageService: MessageService,
    private _exportOfferService: ExportOfferGateway,
    private _portService: PortGateway,
    private _countryService: CountryService,
    public translate: TranslateService
    ) {
      this.loadExportOffers()

  }

  ngOnInit(): void {

    this.loadCountries()
    this.loadExportOffers()
  }


  loadExportOffers() {
    this.loading = true
    this._exportOfferService.getAllForMarketPlaceAds().subscribe({
      next: (v) => {
        this.offers = v
        this.loading = false
      },
      error: (e) => {
        this.loading = false
        this._messageService.add({ severity: 'error',
        summary: this.translate.instant('MessageService.Summary.FailedAction'),
        detail: this.translate.instant('MessageService.ExportOffer.ManyRetrieveError') })
      }
    })
        if(this.adsCode) {
    }
  }

  loadCountries() {
    this._exportOfferService.getCountriesAvailable().subscribe({
      next: (v) => {
        this.originCountries = v
        this.selectedCountryTo = this.originCountries.find(x => x.id === this.formGroup.controls['countryToId'].value)
      },
      error: (e) => {
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Country.DestinationManyRetrieveError') })
      }
    })
  }

  @ViewChild('dt1') dataTable: any


  filterGlobal(event: any) {
    this.dataTable.filterGlobal(event.target.value, 'contains')
  }

  clearSpotSearchParams() {
    this.formGroup.controls['countryFromId'].setValue(null)
    this.loadExportOffers()
  }

  navigateToDetail(adsCode: string) {
      this._router.navigate(['/'+ Routes.offerDetail, adsCode])
  }
}
