<app-user-dashboard [userMenuActiveItemIndex]="0" [activeItem]="'user-load-orders'">
  <p-card [style]="{'min-height':'200px'}">
    <ng-template pTemplate="header">
      <div class="grid">
        <div class="col-12 md:col-12 lg:col-12">
          <div class="p-fluid grid flex align-items-center justify-content-evenly">
            <div class="flex justify-content-between flex-wrap align-items-center" style="padding: 15px;">
              <h2>{{'UserPages.UserLoadOrders.PageTitle' | translate}}</h2>
            </div>
            <!-- <div class="col-12 md:col-2 lg:col-2">
              <h2>{{'UserPages.UserPurchaseOrders.PageTitle' | translate}}</h2>
            </div> -->
            <div class="col-12 md:col-8 lg:col-8">
              <span class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input type="text" pInputText placeholder="{{'UserPages.UserLoadOrders.SearchProducts' | translate}}" class="p-inputtext-sm" [(ngModel)]="searchTerm" (input)="filterOrders()" />
              </span>
            </div>
            <div class="col-12 md:col-2 lg:col-2 flex justify-content-between">
              <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success mr-2" pTooltip="XLS" tooltipPosition="bottom" [style]="{'width':'300px'}">{{ 'UserPages.UserLoadOrders.ExportExcel' | translate }}</button>
            </div>
          </div>
          <p-divider></p-divider>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="body">
      <p-table [value]="shipments" dataKey="exporterCompanyName" sortField="exporterCompanyName" sortMode="single" rowGroupMode="subheader" groupRowsBy="exporterCompanyName" [tableStyle]="{'min-width': '60rem'}">
        <ng-template pTemplate="header">
          <tr>
            <th>
              <div class="flex justify-content-center align-items-center">
                {{'UserPages.UserLoadOrders.MainTable.OrderHeader' | translate}}
              </div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">
                {{'UserPages.UserLoadOrders.MainTable.ContainerHeader' | translate}}
                <p-columnFilter type="text" field="container" display="menu" [showAddButton]="false" [showMatchModes]="false" [showOperator]="false"></p-columnFilter>
              </div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">
                {{'UserPages.UserLoadOrders.MainTable.OriginPortHeader' | translate}}
              </div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">
                {{'UserPages.UserLoadOrders.MainTable.DestinationPortHeader' | translate}}
              </div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">
                {{'UserPages.UserLoadOrders.MainTable.PalletsHeader' | translate}}
              </div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">
                {{'UserPages.UserLoadOrders.MainTable.StatusHeader' | translate}}
              </div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">
                {{'UserPages.UserLoadOrders.MainTable.WeeksHeader' | translate}}
              </div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">
                {{'UserPages.UserLoadOrders.MainTable.ETDHeader' | translate}}
              </div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">
                {{'UserPages.UserLoadOrders.MainTable.ETAHeader' | translate}}
              </div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">
                {{'UserPages.UserLoadOrders.MainTable.ConsigneeHeader' | translate}}
              </div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">
                {{'UserPages.UserLoadOrders.MainTable.DetailHeader' | translate}}
              </div>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="groupheader" let-order let-rowIndex="rowIndex" let-expanded="expanded">
          <tr>
            <td colspan="12">
              <div class="flex justify-content-start align-items-center">
                <button type="button" pButton pRipple [pRowToggler]="order" class="p-button-text p-button-rounded p-button-plain mr-2" [style]="{'color': '#fff', 'background-color': '#672572','width': '1.5rem', 'height': '1.5rem', 'margin': '0 5px'}"  [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                <span class="font-bold ml-2">{{ order.exporterCompanyName }}</span>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-order let-rowIndex="rowIndex" let-rowData.viewDetails="false">
            <tr>
              <td>
                <div class="flex justify-content-center align-items-center">
                  <span>{{ order.sequence }}</span>
                </div>
              </td>
              <td>
                <div class="flex justify-content-center align-items-center">
                  <span>{{ order.containerNumber ? order.containerNumber : DefaultLoadingInfoText }}</span>
                </div>
              </td>
              <td>
                <div class="flex justify-content-center align-items-center">
                  <span>
                    {{ order.originPort ? order.originPort?.name : DefaultLoadingInfoText }}<br>{{ order.originPort ? (order.originPort?.country | countryDescription) : ''}}
                  </span>
                </div>
              </td>
              <td>
                <div class="flex justify-content-center align-items-center">
                  <span>
                    {{ order.destinationPort ? order.destinationPort?.name : DefaultLoadingInfoText }}<br>{{ order.destinationPort ? (order.destinationPort?.country | countryDescription) : ''}}
                  </span>
                </div>
              </td>
              <td>
                <div class="flex justify-content-center align-items-center">
                  <span>{{ order.totalPallets }}</span>
                  <!-- <p-tag [severity]="getPalletsSeverity(order)" [style]="{'margin': '0 5px', 'color': '#fff'}">
                    <div class="flex align-items-center gap-2">
                      <i class="pi pi-eye text-md"></i>
                    </div>
                  </p-tag> -->
                </div>
              </td>
              <td>
                <div class="flex justify-content-center align-items-center">
                  <p-tag *ngIf="order.status != null && statuses[order.status]" [value]="statuses[order.status].label" [severity]="getStatusSeverity(order.status)" [icon]="getStatusIcon(order.status)">
                  </p-tag>
                </div>
              </td>
              <td>
                <div class="flex justify-content-center align-items-center">
                  <span>{{ order.week ? order.week : DefaultLoadingInfoText }}</span>
                </div>
              </td>
              <td>
                <div class="flex justify-content-center align-items-center">
                  <span>{{ order.etd ? (order.etd | date: 'dd-MM-yyyy') : DefaultLoadingInfoText }}</span>
                </div>
              </td>
              <td>
                <div class="flex justify-content-center align-items-center">
                  <span>{{ order.eta ? (order.eta | date: 'dd-MM-yyyy') : DefaultLoadingInfoText }}</span>

                </div>
              </td>
              <td>
                <div class="flex justify-content-center align-items-center">
                  <span>{{ order.consignee ? order.consignee : DefaultLoadingInfoText }}</span>
                </div>
              </td>
              <td>
                <div class="flex justify-content-center align-items-center">
                  <span style="color: #672572; font-weight: bold">{{'UserPages.UserLoadOrders.Products' | translate}}</span>
                  <i (click)="order.viewDetails = !order.viewDetails" [ngClass]="order.viewDetails ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" [style]="{'color': '#fff', 'background-color': '#672572','width': '1.5rem', 'height': '1.5rem', 'margin': '0 5px', 'border-radius': '50%', 'display': 'flex', 'justify-content': 'center', 'align-items': 'center', 'cursor': 'pointer'}" ></i>
                  <!-- <button type="button" pButton pRipple [pRowToggler]="order" class="p-button-text p-button-rounded p-button-plain" [style]="{'color': '#fff', 'background-color': '#672572','width': '1.5rem', 'height': '1.5rem', 'margin': '0 5px'}" [icon]="order.viewDetails ? 'pi pi-chevron-down' : 'pi pi-chevron-right'">
                  </button> -->
                </div>
              </td>
            </tr>
            <ng-container *ngTemplateOutlet="extensiontemplate; context: order"></ng-container>
            <ng-template #extensiontemplate>
              <tr *ngIf="order.viewDetails">
                <td colspan="12">
                  <div class="p-3">
                    <p-table [value]="order.details" dataKey="id">
                      <ng-template pTemplate="header">
                        <tr>
                          <th pSortableColumn="varietyName">{{'UserPages.UserLoadOrders.SubTable.Specie/VarietyHeader' | translate}} <p-sortIcon field="varietyName"></p-sortIcon></th>
                          <th pSortableColumn="palletsQuantity">{{'UserPages.UserLoadOrders.SubTable.PalletsHeader' | translate}} <p-sortIcon field="palletsQuantity"></p-sortIcon></th>
                          <th pSortableColumn="boxesPerPallet">{{'UserPages.UserLoadOrders.SubTable.BoxHeader' | translate}} <p-sortIcon field="boxesPerPallet"></p-sortIcon></th>
                          <th *ngIf="profile?.type === 1" pSortableColumn="exporterCompanyName">{{'UserPages.UserLoadOrders.SubTable.ImporterHeader' | translate}} <p-sortIcon field="exporterCompanyName"></p-sortIcon></th>
                          <th *ngIf="profile?.type !== 1" pSortableColumn="exporterCompanyName">{{'UserPages.UserLoadOrders.SubTable.ExporterHeader' | translate}} <p-sortIcon field="exporterCompanyName"></p-sortIcon></th>
                          <th>{{'UserPages.UserLoadOrders.SubTable.DocumentationHeader' | translate}}</th>
                          <th pSortableColumn="status">{{'UserPages.UserLoadOrders.SubTable.StatusHeader' | translate}} <p-sortIcon field="status"></p-sortIcon></th>
                          <th pSortableColumn="status">Pallets <p-sortIcon field="status"></p-sortIcon></th>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-detail>
                        <tr>
                          <td>
                            <div class="flex justify-content-start align-items-center">
                              <img [src]="detail.varietyUrlIcon" style="border-radius: 7px; width: 100%; max-width: 50px; height: auto;" alt="Url varietyIcon"/>
                              {{getSpeciesName(detail)}}<br>{{ detail.varietyName }}
                            </div>
                          </td>
                          <td>{{ detail.palletsQuantity }}</td>
                          <td>{{ detail.boxesQuantity }}</td>
                          <td>{{ detail.exporterCompanyName }}</td>
                          <td>
                              <p-tag [severity]="getDocumentationSeverity(detail)" [style]="{'margin': '0 auto', 'color': '#fff', 'cursor': 'pointer', 'height': '40px', 'width': '40px' }" (click)="redirectToDocumentation(order.id)">
                                <div class="flex align-items-center gap-2">
                                  <i class="pi pi-copy text-md"></i>
                                </div>
                              </p-tag>
                          </td>
                          <td>
                            <div class="flex justify-content-start align-items-center">
                              <p-tag *ngIf="detail.status == 1" value="Pend. Export." severity="warning" icon="pi pi-exclamation-triangle"></p-tag>
                              <p-tag *ngIf="detail.status == 2" value="Pend. QC" severity="warning" icon="pi pi-exclamation-triangle"></p-tag>
                              <p-tag *ngIf="detail.status == 3" value="Verif. Partial" severity="success" icon="pi pi-check"></p-tag>
                              <p-tag *ngIf="detail.status == 4" value="Verif." severity="success" icon="pi pi-check"></p-tag>
                            </div>
                          </td>
                          <td>
                            <div class="flex justify-content-start align-items-center">
                              <p-button pTooltip="{{'UserPages.UserPurchaseOrders.PaymentsPage.OrderDetailPage.DetailOrderTable.SeePalletsButton' | translate}}" tooltipPosition="bottom" icon="pi pi-table" (onClick)="viewPallets(detail.id)" [style]="{'background-color': '#8c2b87'}"></p-button>
                            </div>
                          </td>
                        </tr>
                      </ng-template>
                    </p-table>
                  </div>
                </td>
              </tr>
            </ng-template>
        </ng-template>
      </p-table>
    </ng-template>
  </p-card>
</app-user-dashboard>

<p-dialog header="Pallets" [(visible)]="showPallets" [style]="{width: '80%'}" [modal]="true">
  <div style="margin-top: 40px;">
    <p-table
      [value]="pallets"
      dataKey="dataKey"
      responsiveLayout="stack"
      [breakpoint]="'600px'"
      styleClass="p-datatable-gridlines"
      [scrollable]="true"
      [resizableColumns]="true"
      columnResizeMode="expand"
      scrollHeight="600px">
      <ng-template pTemplate="header">
        <tr>
          <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.FolioHeader' | translate}}</th>
          <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.SpeciesHeader' | translate}}</th>
          <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.VarietyHeader' | translate}}</th>
          <th *ngIf="profile?.type === 1">{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.ProducerHeader' | translate}}</th>
          <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.SpeciesSizeHeader' | translate}}</th>
          <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.HarvestDateHeader' | translate}}</th>
          <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.QualityControlDateHeader' | translate}}</th>
          <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.PackagingHeader' | translate}}</th>
          <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.PalletTypeHeader' | translate}}</th>
          <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.TagHeader' | translate}}</th>
          <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.BagTypeHeader' | translate}}</th>
          <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.BoxTypeHeader' | translate}}</th>
          <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.NetWeightHeader' | translate}}</th>
          <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.ColdStorageHeader' | translate}}</th>
          <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.BoxePerPalletHeader' | translate}}</th>
          <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.MarksHeader' | translate}}</th>
          <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.GradeHeader' | translate}}</th>
          <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.ContainerNumberHeader' | translate}}</th>
          <th>ETD</th>
          <th>ETA</th>
          <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadWeekHeader' | translate}}</th>
          <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.OriginCountryHeader' | translate}}</th>
          <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.DestinationCountryHeader' | translate}}</th>
          <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.TemptaleHeader' | translate}}</th>
          <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.Cert1Header' | translate}}</th>
          <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.Cert2Header' | translate}}</th>
          <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.Cert3Header' | translate}}</th>
          <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadWeekHeader' | translate}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-pallet let-editing="editing" let-ri="rowIndex">
        <tr>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="pallet.palletNumber" maxlength="20" required [readOnly]="isEdit"/>
              </ng-template>
              <ng-template pTemplate="output">
                {{pallet.palletNumber}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="pallet.excelImportSpecies" maxlength="100" required/>
              </ng-template>
              <ng-template pTemplate="output">
                {{pallet.excelImportSpecies}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="pallet.excelImportVariety" maxlength="100" required/>
              </ng-template>
              <ng-template pTemplate="output">
                {{pallet.excelImportVariety}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td *ngIf="profile?.type === 1">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="pallet.producer" maxlength="200" required/>
              </ng-template>
              <ng-template pTemplate="output">
                {{pallet.producer}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="pallet.speciesSize" maxlength="30"/>
              </ng-template>
              <ng-template pTemplate="output">
                {{pallet.speciesSize}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="pallet.excelImportHarvestDate" maxlength="50"/>
              </ng-template>
              <ng-template pTemplate="output">
                {{pallet.excelImportHarvestDate}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="pallet.excelImportQualityControlDate" maxlength="50"/>
              </ng-template>
              <ng-template pTemplate="output">
                {{pallet.excelImportQualityControlDate}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="pallet.excelImportPackaging" maxlength="50"/>
              </ng-template>
              <ng-template pTemplate="output">
                {{pallet.excelImportPackaging}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="pallet.excelImportPalletType" maxlength="50"/>
              </ng-template>
              <ng-template pTemplate="output">
                {{pallet.excelImportPalletType}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="pallet.excelImportTag" maxlength="50"/>
              </ng-template>
              <ng-template pTemplate="output">
                {{pallet.excelImportTag}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="pallet.excelImportBagType" maxlength="50"/>
              </ng-template>
              <ng-template pTemplate="output">
                {{pallet.excelImportBagType}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="pallet.excelImportBoxSize" maxlength="50"/>
              </ng-template>
              <ng-template pTemplate="output">
                {{pallet.excelImportBoxSize}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="pallet.excelImportNetWeight" maxlength="50"/>
              </ng-template>
              <ng-template pTemplate="output">
                {{pallet.excelImportNetWeight}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="pallet.excelImportFridgeStorage" maxlength="50"/>
              </ng-template>
              <ng-template pTemplate="output">
                {{pallet.excelImportFridgeStorage}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="pallet.excelImportBoxesPerPallet" maxlength="50"/>
              </ng-template>
              <ng-template pTemplate="output">
                {{pallet.excelImportBoxesPerPallet}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="pallet.excelImportMark" maxlength="50"/>
              </ng-template>
              <ng-template pTemplate="output">
                {{pallet.excelImportMark}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="pallet.excelImportGrade" maxlength="50"/>
              </ng-template>
              <ng-template pTemplate="output">
                {{pallet.excelImportGrade}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="pallet.excelImportContainerNumber" maxlength="50"/>
              </ng-template>
              <ng-template pTemplate="output">
                {{pallet.excelImportContainerNumber}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>{{ orderDetailInformation?.etd ? (orderDetailInformation?.etd | date:'dd-MM-yyyy') : '(Post loading information)' }}</td>
          <td>{{ orderDetailInformation?.eta ? (orderDetailInformation?.eta | date:'dd-MM-yyyy') : '(Post loading information)'}}</td>
          <td>{{ orderDetailInformation?.week ? orderDetailInformation?.week : '(Post loading information)'}}</td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="pallet.excelImportOriginCountry" maxlength="50"/>
              </ng-template>
              <ng-template pTemplate="output">
                {{pallet.excelImportOriginCountry}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>{{ orderDetailInformation?.destinationPortName ? orderDetailInformation?.destinationPortName : '(Post loading information)'}}</td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="pallet.excelImportTemptale" maxlength="50"/>
              </ng-template>
              <ng-template pTemplate="output">
                {{pallet.excelImportTemptale}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="pallet.excelImportCertificate1" maxlength="50"/>
              </ng-template>
              <ng-template pTemplate="output">
                {{pallet.excelImportCertificate1}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="pallet.excelImportCertificate2" maxlength="50"/>
              </ng-template>
              <ng-template pTemplate="output">
                {{pallet.excelImportCertificate2}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="pallet.excelImportCertificate3" maxlength="50"/>
              </ng-template>
              <ng-template pTemplate="output">
                {{pallet.excelImportCertificate3}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="pallet.excelImportLoadWeek" maxlength="50"/>
              </ng-template>
              <ng-template pTemplate="output">
                {{pallet.excelImportLoadWeek}}
              </ng-template>
            </p-cellEditor>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-dialog>
