<p-table
  [loading]="loading"
  [value]="documents"
  [paginator]="true"
  [rows]="10"
  [showCurrentPageReport]="true"
  [rowsPerPageOptions]="[10, 25, 50]"
  responsiveLayout="stack"
  [breakpoint]="'600px'">
  <ng-template pTemplate="caption">
    <div class="flex align-items-center justify-content-start">
      <p-button label="{{'UserPages.UserExportOfferDocument.NewButtonLabel' | translate}}" icon="pi pi-plus" class="mr-2" (onClick)="openDocumentCreateDialog(documentFormDirective, documentFileUploader)"/>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
      <tr>
        <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserExportOfferDocument.NameHeader' | translate}}</div></th>
        <th><div class="flex justify-content-center align-items-center">PDF</div></th>
        <th></th>
      </tr>
  </ng-template>
  <ng-template pTemplate="body" let-document>
      <tr>
        <td><div class="flex justify-content-center align-items-center">{{ document.name }}</div></td>
        <td>
          <div class="flex justify-content-center align-items-center">
            <p-button pTooltip="Document PDF" (onClick)="getExportOfferDocument(document.id)" icon="pi pi-file-pdf" styleClass="p-button-outlined"></p-button>
          </div>
        </td>
        <td>
          <div class="flex justify-content-end align-items-center gap-2">
            <p-button label="{{'UserPages.UserExportOfferDocument.EditButtonLabel' | translate}}" icon="pi pi-pencil" (onClick)="openDocumentEditDialog(document, documentFileUploader)" class="mr-2"/>
            <p-button label="{{'UserPages.UserExportOfferDocument.DeleteButtonLabel' | translate}}" icon="pi pi-trash" (onClick)="onDeleteDocument(document)" class="mr-2"/>
          </div>
        </td>
      </tr>
  </ng-template>
</p-table>

<p-dialog header="{{'UserPages.UserExportOfferDocument.Form.Title' | translate}}" [(visible)]="documentDialogVisible" [style]="{width: '50%'}" [modal]="true">
  <form [formGroup]="documentFormGroup" #documentFormDirective="ngForm">
    <div class="p-fluid formgrid grid" style="padding: 20px;">
      <div class="field col-12 md:col-12 lg:col-12" style="margin-bottom: 40px;">
        <label for="name" style="font-weight: bolder;">{{'UserPages.UserExportOfferDocument.Form.NameLabel' | translate}} *</label>
        <input id="name" formControlName="name" type="text" required pInputText placeholder="{{'UserPages.UserExportOfferDocument.Form.NameLabel' | translate}}"/>
        <small *ngIf="submitDocument && documentFormGroup.controls['name'].invalid" class="p-error">{{getErrorMessage(documentFormGroup, 'name')}}</small>
      </div>
      <div class="field col-12" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserExportOfferDocument.Form.LoadDocumentLabel' | translate}}</label>
        <p-fileUpload
          #documentFileUploader
          (onSelect)="onSelectDocumentFile($event)"
          (onClear)="onClearDocumentFile($event)"
          [showUploadButton]="false"
          [multiple]="false"
          accept=".pdf"
          [maxFileSize]="5000000"
          chooseLabel="{{'UserPages.UserExportOfferDocument.Form.SelectFile' | translate}}"
          cancelLabel="{{'UserPages.UserExportOfferDocument.Form.CancelSelectFile' | translate}}"/>
      </div>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <p-button label="{{'UserPages.UserExportOfferDocument.Form.BackButtonLabel' | translate}}" (onClick)="documentDialogVisible = false" styleClass="p-button-outlined"></p-button>
    <p-button label="{{'UserPages.UserExportOfferDocument.Form.SaveButtonLabel' | translate}}" (onClick)="saveDocument()" [loading]="loading"></p-button>
  </ng-template>
</p-dialog>

<p-toast></p-toast>
<p-confirmDialog acceptLabel="Yes" rejectLabel="No" rejectButtonStyleClass="p-button-outlined" [style]="{width: '50vw'}"></p-confirmDialog>
