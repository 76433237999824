import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MenuItem, MessageService, SelectItem } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { ConsigneeGateway } from 'src/app/application/gateway/ConsigneeGateway';
import { CountryGateway } from 'src/app/application/gateway/CountryGateway';
import { ExportOfferGateway } from 'src/app/application/gateway/ExportOfferGateway';
import { ImportOrderGateway } from 'src/app/application/gateway/ImportOrderGateway';
import { NotifierGateway } from 'src/app/application/gateway/NotifierGateway';
import { PortGateway } from 'src/app/application/gateway/PortGateway';
import { ProfileGateway } from 'src/app/application/gateway/ProfileGateway';
import { VarietyGateway } from 'src/app/application/gateway/VarietyGateway';
import { Consignee } from 'src/app/domain/models/Consignee';
import { Country } from 'src/app/domain/models/Country';
import { AddOrderClosedSaleRequest, ExportOffer, ExportOfferInformation } from 'src/app/domain/models/ExportOffer';
import { ClosedSaleWeek, ClosedSaleWeekRequest, ImportOrder, ImportOrderDetail, ImportOrderInformation } from 'src/app/domain/models/ImportOrder';
import { Notifier } from 'src/app/domain/models/Notifier';
import { Packaging } from 'src/app/domain/models/Packaging';
import { PalletPrice } from 'src/app/domain/models/Pallet';
import { Port } from 'src/app/domain/models/Port';
import { ProductCertificateType } from 'src/app/domain/models/ProductCertificateType';
import { Importer, Profile } from 'src/app/domain/models/Profile';
import { Species } from 'src/app/domain/models/Species';
import { BasicUser } from 'src/app/domain/models/User';
import { Variety } from 'src/app/domain/models/Variety';
import { Routes } from 'src/app/infrastructure/routes';
import { isDivisor } from 'src/app/ui/util/custom-validators';
import { ErrorMessageUtil } from 'src/app/ui/util/error-message-util';
import { OrderUtils } from 'src/app/ui/util/order-utils';
import { SpeciesUtils } from 'src/app/ui/util/species-utils';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { DirectSaleProgramGateway } from 'src/app/application/gateway/DirectSaleProgramGateway';
import { FridgeStorage } from 'src/app/domain/models/FridgeStorage';
import { FridgeStorageGateway } from 'src/app/application/gateway/FridgeStorageGateway';
import { AddImportoOrderToProgramWeek } from 'src/app/domain/models/DirectSaleProgram';
import { ProgramRequestInformation } from 'src/app/domain/models/ProgramRequest';
import { CompanyGateway } from 'src/app/application/gateway/CompanyGateway';
import { Company } from 'src/app/domain/models/Company';
import { ProgramRequestUtil } from 'src/app/ui/util/programRequestUtil';
import { formLimit } from 'src/app/ui/util/form-limit';

@Component({
  selector: 'app-exporter-closed-sale-orders',
  templateUrl: './exporter-closed-sale-orders.component.html',
  styleUrls: ['./exporter-closed-sale-orders.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class ExporterClosedSaleOrdersComponent implements OnInit {
  breadcrumbItems: MenuItem[] = []
  breadcrumbHomeItem: MenuItem = {}

  directSaleProgramId: any | undefined
  exportOfferId: number | undefined
  exportOffer: ExportOffer | undefined
  selectedProductCertificateTypes: ProductCertificateType[] = []
  selectedPackagingTypes: Packaging[] = []

  weekDtos: ClosedSaleWeek[] = []

  startWeek: number = 0
  endWeek: number = 0
  year: number = 0
  selectedMonth: number = 0

  profile: BasicUser | undefined
  selectedImporter: Profile | undefined
  selectedConsignee: Consignee | undefined
  selectedNotify: Notifier | undefined
  selectedProgramRequest: ProgramRequestInformation | undefined
  importers: Importer[] = []
  consignees: Consignee[] = []
  notifies: Notifier[] = []
  countries: Country[] = []
  ports: Port[] = []
  fridgeStorages: FridgeStorage[] = []

  sortOptions: SelectItem[] = []
  companyForwarders: Company[] = []
  selectedOrderGuid: string = ""
  selectedCompanyForwarder: number | undefined
  visibleAssignForwarderDialog: boolean = false
  companyCustomAgents: Company[] = []
  selectedCompanyCustomAgent: number | undefined
  visibleAssignCustomAgentDialog: boolean = false

  sortOrder!: number
  sortField!: string

  visibleCurrentPalletsPrice: boolean = false

  offerMenuitems: MenuItem[] = []

  filterFormGroup: FormGroup = this._formBuilder.group({
    week: [null],
    speciesId: [null],
    varietyId: [null],
  })

  othersFiltersForm: FormGroup = this._formBuilder.group({
    harvestDate: [''],
    kilosPerBox: [''],
    speciesSize: [''],
    shelfLife: [''],
    price: [''],
    fridgeStorageId: ['']
  })

  species: Species[] = []
  varieties: Variety[] = []
  sidebarVisible: boolean = false

  weekQueryDtos: ClosedSaleWeek[] = []
  loading: boolean = false
  submit: boolean = false
  visibleExportOfferDialog: boolean = false
  visibleAddProgramationDialog: boolean = false
  editWeek: boolean = false
  submitWeekForm: boolean = false
  visibleEditWeek: boolean = false
  visibleAddContainers: boolean = false;
  selectedSpecies: Species | undefined
  selectedVariety: Variety | undefined

  currentPrices: PalletPrice[] = []

  formGroup: FormGroup = this._formBuilder.group({
    palletQuantity: [20, [Validators.required, isDivisor(20)]],
    importToCountryId: [null, Validators.required],
    portToId: [null, Validators.required],
    startWeek: [null, Validators.required],
    startWeekYear: [null, Validators.required]
  })

  statuses: any[] = []

  weekFormGroup: FormGroup = this._formBuilder.group({
    importOrderId: [null, Validators.required],
    week: [null, Validators.required],
    year: [null, Validators.required]
  })

  addContainersForm: FormGroup = this._formBuilder.group({
    containersNumber: [null, Validators.required]
  })

  selectedWeek: number = 0;
  selectedYear: number = 0;

  directSaleStartWeek: number = 0
  directSaleEndWeek: number = 0
  directSaleStartYear: number = 0
  directSaleEndYear: number = 0

  formLimit = formLimit

  constructor(private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _messageService: MessageService,
    private _authService: AuthGateway,
    private _formBuilder: FormBuilder,
    private _exportOfferService: ExportOfferGateway,
    private _importOrderService: ImportOrderGateway,
    private _profileService: ProfileGateway,
    private _countryService: CountryGateway,
    private _consigneeService: ConsigneeGateway,
    private _notifyService: NotifierGateway,
    private _portService: PortGateway,
    private _varietyService: VarietyGateway,
    private _fridgeStorageService: FridgeStorageGateway,
    private _companyService: CompanyGateway,
    private _confirmationService: ConfirmationService,
    public translate: TranslateService) {
    this._activatedRoute.params.subscribe(params => {
      this.directSaleProgramId = params['directSaleProgramId']
    })
    let now = new Date()
    this.startWeek = this.getFirstWeek(now.getMonth(), now.getFullYear())
    this.endWeek = this.getLastWeek(now.getMonth(), now.getFullYear())
    this.year = now.getFullYear()
    moment.locale('es')
  }

  ngOnInit(): void {
    this.profile = this._authService.getUser()

    if(this.profile) {
      let breadcrumbStep1ItemLabel1 = 'Direct sale order program'
      let homeItemLabel = 'My direct sales'

      switch(this.profile.lang) {
        case 'en':
          breadcrumbStep1ItemLabel1 = 'Direct sale order program'
          homeItemLabel = 'My direct sales'
          break
        case 'es':
          breadcrumbStep1ItemLabel1 = 'Programación de ordenes de venta directa'
          homeItemLabel = 'Mis ventas directas'
          break
      }

      this.breadcrumbItems = [{ label: breadcrumbStep1ItemLabel1, disabled: true }]
      this.breadcrumbHomeItem = { label: homeItemLabel, routerLink: '/' + Routes.userClosedSales };
    }

    let sortOptionItemLabel1 = 'Week from highest to lowest'
    let sortOptionItemLabel2 = 'Week from lowest to highest'
    let offerMenuItemLabel1 = 'See Offer'
    let offerMenuItemLabel2 = 'Add programme'

    if(this.profile) {
      switch(this.profile.lang) {
        case 'en':
          sortOptionItemLabel1 = 'Week from highest to lowest'
          sortOptionItemLabel2 = 'Week from lowest to highest'
          offerMenuItemLabel1 = 'See Offer'
          offerMenuItemLabel2 = 'Add programme'
          break
        case 'es':
          sortOptionItemLabel1 = 'Semana de mayor a menor'
          sortOptionItemLabel2 = 'Semana de menor a mayor'
          offerMenuItemLabel1 = 'Ver oferta'
          offerMenuItemLabel2 = 'Adicionar programación'
          break
      }
    }

    this.statuses = [
      { label: 'Created', value: 0 },
      { label: 'Pending Verify Pallets', value: 1 },
      { label: 'QC Pallets Verified', value: 2 },
      { label: 'Order Confirmed', value: 3 },
      { label: 'Payment Confirmed', value: 4 },
      { label: 'Cancelled', value: 5 }
    ];

    this.sortOptions = [
      { label: sortOptionItemLabel1, value: '!importOrder.closedSaleWeek' },
      { label: sortOptionItemLabel2, value: 'importOrder.closedSaleWeek' }
    ]

    this.offerMenuitems = [
      {
          label: offerMenuItemLabel1,
          command: () => {this.visibleExportOfferDialog = true}
      },
      {
          label: offerMenuItemLabel2,
          command: () => {this.visibleAddProgramationDialog = true}
      }
    ]

    this.loadCountries()
    this.loadImporters()
    this.loadImportOrders()
    this.loadCompanies(0) // Forwarders
    this.loadCompanies(1) // Custom Agents
  }

  showOfferDetailDialog() {
    if(this.exportOfferId) {
      this._exportOfferService.getById(this.exportOfferId).subscribe({
        next: (v) => {
          this.exportOffer = v
          this.loading = false

          if(this.exportOffer.productCertificateTypes)
            this.selectedProductCertificateTypes = this.exportOffer.productCertificateTypes

          if(this.exportOffer.packagingTypes)
            this.selectedPackagingTypes = this.exportOffer.packagingTypes

          this.visibleExportOfferDialog = true
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOffer.ManyRetrieveError') })
        },
        complete: () => console.info('Export Offers query successfully')
      })
    }
  }


  loadImportOrders() {
    if(this.directSaleProgramId) {
      this.loading = true
      this._importOrderService.getAllClosedSale(
        this.directSaleProgramId,
        this.startWeek,
        this.endWeek,
        this.year
      ).subscribe({
        next: (v) => {
          this.exportOfferId = v.exportOfferId
          this.weekQueryDtos = v.weeks
          this.weekDtos = v.weeks
          this.directSaleStartWeek = v.startWeek
          this.directSaleEndWeek = v.endWeek
          this.directSaleStartYear = v.startYear
          this.directSaleEndYear = v.endYear
          this.getYears()
          this.loading = false
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudieron recuperar las ordenes.' });
        },
        complete: () => console.info('Import Order query successfully')
      })
    }
  }

  get startWeekValue() {
     return this.getAsFormatPeriod(this.directSaleStartWeek,this.directSaleStartYear);
  }

  get endWeekValue() {
    return this.getAsFormatPeriod(this.directSaleEndWeek ,this.directSaleEndYear);
 }

  addOrdersToWeek()
  {
    this.loading = true;
      let request: AddImportoOrderToProgramWeek = {
        directSaleProgramGuid: this.directSaleProgramId,
        week: this.selectedWeek,
        year: this.selectedYear,
        numberOrders: this.addContainersForm.controls['containersNumber'].value
      }

      this._importOrderService.addImportoOrderToProgramWeek(request).subscribe({
        next: (v) => {
          this._messageService.add({ severity: 'success',
          summary: this.translate.instant('MessageService.Summary.SuccessAction'),
          detail: this.translate.instant('MessageService.ImportOrder.CreateSuccess') })
          this.loading = false;
          this.visibleAddContainers = false;
          this.loadImportOrders();
        },

        error: (e) => {
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ImportOrder.CreateError') })
            this.loading = false;

        }
      })
  }

  showAddContainers(week: number, year: number)
  {
    this.selectedWeek = week;
    this.selectedYear = year;

    this.visibleAddContainers = true;

  }

  getPalletPrice(detail: ImportOrderDetail): number {
    let total = 0

    if(detail.palletPrices)
    {
      detail.palletPrices.forEach(x => {
        total += x.price ? x.boxPerPallets * x.price : 0;
      })
      return total
    }
    return 0
  }

  onSortChange(event: any) {
    let value = event.value;

    if (value.indexOf('!') === 0) {
        this.sortOrder = -1;
        this.sortField = value.substring(1, value.length);
    } else {
        this.sortOrder = 1;
        this.sortField = value;
    }
  }

  getDetailTotalPrice(detail: ImportOrderDetail): number {
    return OrderUtils.getDetailTotalPrice(detail)
  }

  loadCountries() {
    this.countries = []
    this._countryService.getAll().subscribe({
      next: (v) => {
        this.countries = v
        this.loading = false
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudieron recuperar los países.' })
      },
      complete: () => console.info('Country query successfully')
    })
  }

  loadImporters() {
    this.importers = []
    if(this.profile) {
      this._profileService.getAllImportersByExporterId().subscribe({
        next: (v) => {
          this.importers = v
          this.loading = false
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudieron recuperar los importadores.'})
        },
        complete: () => console.info('Importers query successfully')
      })
    }
  }

  loadConsigneesNotifies() {
    this.loadConsignees()
    this.loadNotifies()
  }

  loadConsignees() {
    if(this.selectedImporter) {
      this._consigneeService.getAllForClosedSale(this.selectedImporter.id).subscribe({
        next: (v) => {
          this.consignees = v
          this.loading = false
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudieron recuperar los consignees.'})
        },
        complete: () => console.info('Consignees query successfully')
      })
    }
  }

  loadNotifies() {
    if(this.selectedImporter) {
      this._notifyService.getAllForClosedSale(this.selectedImporter.id).subscribe({
        next: (v) => {
          this.notifies = v
          this.loading = false
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudieron recuperar los notify.'})
        },
        complete: () => console.info('Notifies query successfully')
      })
    }
  }

  loadPorts() {
    if(this.formGroup.controls['importToCountryId'].value) {
      this._portService.getDestinationPortsByCountry(this.formGroup.controls['importToCountryId'].value).subscribe({
        next: (v) => {
          this.ports = v
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudieron recuperar los puertos de destino.' })
        },
        complete: () => console.info('Destination Ports query successfully')
      })
    }
  }

  saveClosedSale() {
    this.loading = true
    this.submit = true
    if(this.formGroup.valid && this.exportOffer) {

      if(!this.selectedImporter) {
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail:  this.translate.instant('MessageService.Importer.NullImporterError') })
        this.loading = false
        return
      }

      if(!this.selectedConsignee) {
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Consignee.NullConsigneeError') })
        this.loading = false
        return
      }

      if(!this.selectedNotify) {
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Notify.NullNotifyError') })
        this.loading = false
        return
      }

      let addOrderClosedSale: AddOrderClosedSaleRequest = {
        exportOfferId: this.exportOffer.id,
        importToCountryId: this.formGroup.controls['importToCountryId'].value,
        portToId: this.formGroup.controls['portToId'].value,
        importerId: this.selectedImporter.id,
        consigneeId: this.selectedConsignee.id ? this.selectedConsignee.id : 0,
        notifyId: this.selectedNotify.id ? this.selectedNotify.id : 0,
        palletsQuantity: this.formGroup.controls['palletQuantity'].value,
        week: this.formGroup.controls['startWeek'].value,
        year: this.formGroup.controls['startWeekYear'].value,
      }

      this._exportOfferService.addOrderClosedSale(addOrderClosedSale).subscribe({
        next: (v) => {
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: this.translate.instant('MessageService.ImportOrder.CreateSuccess') })
          this.submit = false
          this.loading = false
          this.visibleAddProgramationDialog = false
          this.loadImportOrders()
        },
        error: (e) => {
          this.submit = false
          this.loading = false
          console.log(e)
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ImportOrder.CreateError') })
        },
        complete: () => console.info('Import Order to Closed Sale created successfully')
      })
    }
    else {
      this.loading = false
      this._messageService.add({ severity: 'error',
        summary: this.translate.instant('MessageService.Summary.FailedAction'),
        detail: this.translate.instant('MessageService.FormMessages.FormInvalid')})
    }
  }

  getFridgeStorages() {
    this.fridgeStorages = []
    this._fridgeStorageService.getAllByStorageType(1).subscribe({
      next: (v) => {
        this.fridgeStorages = v
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.FridgeStorage.ManyRetrieveError') })
      },
      complete: () => console.info('Fridge Storage query successfully')
    })
  }

  getErrorMessage(fieldName: string): string {
    return ErrorMessageUtil.getErrorMessage(this.formGroup, this.translate, fieldName, 20)
  }

  get totalPallets(): number {
    return this.formGroup.controls['palletQuantity'].value
  }

  get totalWeeks(): number {
    return 1
  }

  get containersPerWeek(): number {
    let pallets = this.formGroup.controls['palletQuantity'].value
    if(pallets && pallets % 20 == 0) {
      return pallets / 20
    }
    return 0
  }

  years: any[] = []
  getYears() {
    let current = new Date().getFullYear()
    this.years.push({label: current, value: current})
    let next = current + 1
    this.years.push({label: next, value: next})
  }

  goToImportOrderDetail(orderId: any) {
    this._router.navigate(['/' + Routes.userClosedSaleImportOrderDetailPage, orderId, this.directSaleProgramId])
  }

  goToVerifyPalletsPage(detailId: any) {
    this._router.navigate(['/' + Routes.userClosedSaleExporterVerifyPallets, detailId, this.directSaleProgramId])
  }

  onMonthChange(event: any): void {
    this.selectedMonth = event.month
    this.startWeek = this.getFirstWeek(event.month - 1, event.year)
    this.endWeek = this.getLastWeek(event.month - 1, event.year)
    this.year = event.year
    this.loadImportOrders()
  }

  onEditWeek(order: ImportOrder) {

    this.weekFormGroup.controls['importOrderId'].setValue(order.guid)
    this.weekFormGroup.controls['week'].setValue(order.closedSaleWeek)
    this.weekFormGroup.controls['year'].setValue(order.closedSaleWeekYear)
    this.editWeek = true
    this.visibleEditWeek = true
  }

  saveWeek() {
    this.loading = true
    this.submitWeekForm = true
    if(this.weekFormGroup.valid) {
      let request: ClosedSaleWeekRequest = {
        importOrderId: this.weekFormGroup.controls['importOrderId'].value,
        week: this.weekFormGroup.controls['week'].value,
        year: this.weekFormGroup.controls['year'].value
      }

      this._importOrderService.updateClosedSaleWeek(request).subscribe({
        next: (v) => {
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: 'Week saved successfully' })
          this.submitWeekForm = false
          this.loading = false
          this.editWeek = false
          this.visibleEditWeek = false;
          this.loadImportOrders()
        },
        error: (e) => {
          this.submitWeekForm = false
          this.loading = false
          this.editWeek = false
          this.visibleEditWeek = false;
          console.log(e)
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: 'Week save error' })
        },
        complete: () => console.info('Import Order to Closed Sale created successfully')
      })
    }
    else {
      this.loading = false
    }

  }
  loadVarieties() {
    this.varieties = []
    if(this.filterFormGroup.controls['speciesId'].value) {
      this._varietyService.getAllBySpecies(this.filterFormGroup.controls['speciesId'].value).subscribe({
        next: (v) => {
          this.varieties = v
          this.loading = false
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.Variety.ManyRetrieveError') })
        },
        complete: () => console.info('Variety query successfully')
      })
    }
  }

  loadSpeciesParams() {
    this.selectedSpecies = this.species.find(x => x.id === this.filterFormGroup.controls['speciesId'].value)
    this.loadVarieties()
  }

  getSpeciesName(species: Species): string {
    if(this.profile)
      return SpeciesUtils.getSpeciesName(this.profile.lang, species)
    return ''
  }

  onChangeVariety() {
    this.selectedVariety = this.varieties.find(x => x.id === this.filterFormGroup.controls['varietyId'].value)
  }

  getFirstWeek(month: number, year: number): number {
    return moment().month(month).year(year).startOf('month').week()
  }

  getLastWeek(month: number, year: number): number {
    let first = moment().month(month).year(year).startOf('month').week()
    let last = moment().month(month).year(year).endOf('month').week()

    return (month == 11 && last < first) ? 52 : last
  }

  getWeekNumber(day: number, month: number, year:number): boolean {
    const date = new Date(year,month, day)

    var weekNumber = ProgramRequestUtil.ISO8601_week_no(date)

    let weekFormat = this.getAsFormatPeriod(weekNumber, year)

    return (weekFormat >= this.startWeekValue) && (weekFormat <= this.endWeekValue)

  }



  getLength(value: number): number {
     return value.toString().length;
  }

  getAsFormatPeriod(weekNumber: number, year: number ): number {
      let digits = this.getLength(weekNumber);

      let weekNumberString = weekNumber.toString()

      if(digits <= 1){

         weekNumberString = this.zeroPad(weekNumber, 2)
      }

      let periodString = year.toString() + weekNumberString.toString()
      return parseInt(periodString);
  }

  zeroPad(num: number, places: number) {
    var zero = places - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join("0") + num;
  }

  onFilter() {
    this.loading = true
    this.weekDtos = this.weekQueryDtos

    const week = this.filterFormGroup.controls['week'].value

    if(week) {
      this.weekDtos = this.weekDtos.filter(x => x.week === week)
    }

    const speciesId = this.filterFormGroup.controls['speciesId'].value
    if(speciesId) {
      let filtered:ClosedSaleWeek[]  = []
      this.weekDtos.forEach(w => {
        let week: ClosedSaleWeek = {
          importOrdersDtos: [],
          week: w.week,
          year: w.year,
          startWeek: w.startWeek,
          endWeek: w.endWeek,
          endYear: w.endYear,
          startYear: w.startYear
        }
        w.importOrdersDtos.forEach(i => {
          i.details.forEach(d => {
            if(d.speciesId == speciesId)
              week.importOrdersDtos.push(i)
          })
        })
        if(week.importOrdersDtos.length > 0)
          filtered.push(week)
      })
      this.weekDtos = filtered
    }

    const varietyId = this.filterFormGroup.controls['varietyId'].value
    if(varietyId) {
      let filtered:ClosedSaleWeek[]  = []
      this.weekDtos.forEach(w => {
        let week: ClosedSaleWeek = {
          importOrdersDtos: [],
          week: w.week,
          year: w.year,
          startWeek: w.startWeek,
          endWeek: w.endWeek,
          endYear: w.endYear,
          startYear: w.startYear
        }
        w.importOrdersDtos.forEach(i => {
          i.details.forEach(d => {
            if(d.varietyId == varietyId)
              week.importOrdersDtos.push(i)
          })
        })
        if(week.importOrdersDtos.length > 0)
          filtered.push(week)
      })
      this.weekDtos = filtered
    }

    const fridgeStorageId = this.othersFiltersForm.controls['fridgeStorageId'].value;
    const harvestDate = this.othersFiltersForm.controls['harvestDate'].value;
    const price = this.othersFiltersForm.controls['price'].value;
    const shelfLife = this.othersFiltersForm.controls['shelfLife'].value;
    const speciesSize = this.othersFiltersForm.controls['speciesSize'].value;
    const kilosPerBox = this.othersFiltersForm.controls['kilosPerBox'].value;

    let filteredOthers:ClosedSaleWeek[] = []

    this.weekDtos.forEach(
      (week) => {

      let weekNew: ClosedSaleWeek = {
        importOrdersDtos: [],
        week: week.week,
        year: week.year,
        startWeek: week.startWeek,
        endWeek: week.endWeek,
        endYear: week.endYear,
        startYear: week.startYear
      }

      week.importOrdersDtos.forEach(i => {
            let priceFilter = true;
            let harvestFilter = true;
            let speciesSizeFilter = true;
            let kilosPerBoxFilter = true;
            let shelfLifeFilter = true;
            let fridgeStorageFilter = true;
            i.details.forEach(d => {

              if(price)
                {
                  let findPrice = d.palletPrices?.find(x => x.price == price);
                  priceFilter = findPrice || d.price == price ? true : false;
                }

              if(harvestDate)
                {
                  console.log(new Date(d.harvestDate).toString())
                  let registerDate = new Date(d.harvestDate)
                  let selectedDate = new Date(harvestDate)
                  harvestFilter = (registerDate.getDay() == selectedDate.getDay() &&
                                   registerDate.getMonth() == selectedDate.getMonth()  &&
                                   registerDate.getFullYear() == selectedDate.getFullYear()
                                  )
                }

              if(shelfLife)
                {
                  shelfLifeFilter = d.shelfLife = shelfLife
                }

              if(kilosPerBox)
                {
                  kilosPerBoxFilter = d.exportOffer.kilosPerBox = kilosPerBox
                }

              if(speciesSize)
                {
                  let findSpeciesSize = d.palletPrices?.find(x => x.speciesSize == speciesSize);

                  speciesSizeFilter = findSpeciesSize || d.exportOffer.speciesSize == speciesSize ? true : false;
                }

              if(fridgeStorageId)
                {
                  fridgeStorageFilter = d.fridgeStorageId = fridgeStorageId;
                }
          });

            if(priceFilter &&
              speciesSizeFilter &&
              kilosPerBoxFilter &&
              shelfLifeFilter &&
              harvestFilter &&
              fridgeStorageFilter){
                weekNew.importOrdersDtos.push(i)
              }

          });

          if(weekNew.importOrdersDtos.length > 0)
            {
              filteredOthers.push(weekNew)
            }
      });

    this.weekDtos = filteredOthers;
    this.loading = false;
  }

  onClearFilter() {
    this.filterFormGroup.controls['week'].setValue(null)
    this.filterFormGroup.controls['speciesId'].setValue(null)
    this.filterFormGroup.controls['varietyId'].setValue(null)
    this.weekDtos = this.weekQueryDtos
  }

  getImageUrl(offer: ExportOffer): string {
    return offer.offerPictures && offer.offerPictures.length > 0 ? environment.apiUrl + offer.offerPictures[0].url : ''
  }

  get totalPrice() {
    let total = 0
    if(this.weekDtos) {
      this.weekDtos.forEach(x => {
        x.importOrdersDtos.forEach(i => {
          i.details.forEach(d => {
            total += d.caseQuantityPerPallet * d.price * d.palletsQuantity
          })
        })
      })
    }
    return total
  }

  get totalBoxes() {
    let total = 0
    if(this.weekDtos) {
      this.weekDtos.forEach(x => {
        x.importOrdersDtos.forEach(i => {
          i.details.forEach(d => {
            total += d.caseQuantityPerPallet * d.palletsQuantity
          })
        })
      })
    }
    return total
  }

  setVisibleOrderDetail(order: ImportOrderInformation) {
    if (order) {
      order.expanded = !order.expanded
    }
  }

  get totalPalletsHistoric() {
    let total = 0
    if(this.weekDtos) {
      this.weekDtos.forEach(x => {
        x.importOrdersDtos.forEach(i => {
          i.details.forEach(d => {
            total += d.palletsQuantity
          })
        })
      })
    }
    return total
  }

  get totalContainers() {
    let total = 0
    if(this.weekDtos) {
      total = this.totalPalletsHistoric / 20
    }
    return total
  }

  showCurrentPalletPriceModal(detail: ImportOrderDetail) {
      // this.calculateTablePrice()
      this.currentPrices = detail.palletPrices ? detail.palletPrices : []
      this.visibleCurrentPalletsPrice = true
  }

  getStatusSeverity(status: number): string {
    if (status === 1 || status === 2) {
      return 'warning';
    } else if (status === 3 || status === 4) {
      return 'success';
      } else if(status === 5){
        return 'error';
    } else {
      return 'info';
    }
  }

  getStatusIcon(status: number): string {
    if (status === 0 || status === 1 || status === 2) {
      return 'pi pi-exclamation-triangle';
    } else if (status === 3 || status === 4) {
      return 'pi pi-check';
    } else {
      return 'pi pi-times';
    }
  }

  loadCompanies(type: number) {
    this._companyService.getAllByCompanyType(type).subscribe({
      next: (v) => {
        if (type === 0) this.companyForwarders = v
        if (type === 1) this.companyCustomAgents = v
      },
      error: (e) => {
        console.log(e)
        let erroMessage = ''
        if (type === 0) erroMessage = this.translate.instant('ForwarderPage.Messages.ErrorLoadForwarders')
        if (type === 1) erroMessage = this.translate.instant('CustomAgentPage.Messages.ErrorLoadCustomAgents')
        this._messageService.add({ severity: 'error', summary: this.translate.instant('MessageService.Summary.FailedAction'), detail: erroMessage });
      },
      complete: () =>  {
        this.loading = false
        console.info('Company query successfully')
      }
    })
  }

  onAssignForwarder(order: ImportOrder) {
    this.visibleAssignForwarderDialog = true
    this.selectedOrderGuid = order.guid
  }

  assignForwarder() {
    if (this.selectedCompanyForwarder && this.selectedOrderGuid)
    {
      let importOrderId = this.selectedOrderGuid
      let company = this.selectedCompanyForwarder
      this._confirmationService.confirm({
        message: this.translate.instant('ForwarderPage.QuestionAssignForwarder'),
        header: this.translate.instant('ForwarderPage.TitleSection'),
        icon: 'pi pi-info-circle',
        accept: () => {
          this._importOrderService.assingForwarder(importOrderId, company).subscribe({
            next: (v) => {
              this.loadImportOrders()
              this.visibleAssignForwarderDialog = false
              this.loading = false
              this._messageService.add({ severity: 'success', summary: this.translate.instant('MessageService.Summary.SuccessAction'), detail: this.translate.instant('ForwarderPage.SuccessAssignForwarder') });
            },
            error: (e) => {
              console.log(e)
              this.loading = false
              this._messageService.add({ severity: 'error', summary: this.translate.instant('MessageService.Summary.FailedAction'), detail: this.translate.instant('ForwarderPage.ErrorAssignForwarder') });
            },
            complete: () => {
              this.loading = false
              console.info('Forwarder assigned successfully')
            }
          })
        },
        reject: (type: any) => {}
      })
    }
  }

  onAssignCustomAgent(order: ImportOrder) {
    this.visibleAssignCustomAgentDialog = true
    this.selectedOrderGuid = order.guid
  }

  assignCustomAgent() {
    if (this.selectedCompanyCustomAgent && this.selectedOrderGuid)
    {
      let importOrderId = this.selectedOrderGuid
      let company = this.selectedCompanyCustomAgent
      this._confirmationService.confirm({
        message: this.translate.instant('CustomAgentPage.QuestionAssignCustomAgent'),
        header: this.translate.instant('CustomAgentPage.TitleSection'),
        icon: 'pi pi-info-circle',
        accept: () => {
          this._importOrderService.assingCustomAgent(importOrderId, company).subscribe({
            next: (v) => {
              this.loadImportOrders()
              this.visibleAssignCustomAgentDialog = false
              this.loading = false
              this._messageService.add({ severity: 'success', summary: this.translate.instant('MessageService.Summary.SuccessAction'), detail: this.translate.instant('CustomAgentPage.SuccessAssignCustomAgent') });
            },
            error: (e) => {
              console.log(e)
              this.loading = false
              this._messageService.add({ severity: 'error', summary: this.translate.instant('MessageService.Summary.FailedAction'), detail: this.translate.instant('CustomAgentPage.ErrorAssignCustomAgent') });
            },
            complete: () => {
              this.loading = false
              console.info('Custom Agent assigned successfully')
            }
          })
        },
        reject: (type: any) => {}
      })
    }
  }

}
