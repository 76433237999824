import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthGateway } from '../application/gateway/AuthGateway';
import { ExportOfferUtils } from '../ui/util/export-offer-utils';

@Pipe({
  name: 'colorName'
})
export class ColorNamePipe implements PipeTransform {

  constructor(private _authService: AuthGateway, public translate: TranslateService) {

  }

  transform(color: string | undefined | null): string {
    if(!color)
      return this.translate.instant('CommonLabels.NotAvailableLabel')

    const profile = this._authService.getUser()
    if(!profile)
      return this.translate.instant('CommonLabels.NotAvailableLabel')

    const colors = ExportOfferUtils.getColors(profile.lang)
    return colors.find(x => x.value == color) ? colors.find(x => x.value == color).label : ''
  }
}
