import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { BasicUser } from 'src/app/domain/models/User';
import { Routes } from 'src/app/infrastructure/routes';

@Component({
  selector: 'app-user-direct-sale-documents-page',
  templateUrl: './user-direct-sale-documents-page.component.html',
  styleUrls: ['./user-direct-sale-documents-page.component.css']
})
export class UserDirectSaleDocumentsPageComponent implements OnInit {
  breadcrumbItems: MenuItem[] = []
  breadcrumbHomeItem: MenuItem = {}

  exportOfferId: number | undefined

  profile: BasicUser | undefined

  constructor(private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _authService: AuthGateway,) {
    this._activatedRoute.params.subscribe(params => {
      this.exportOfferId = params['exportOfferId']
    })
  }

  ngOnInit(): void {
    this.profile = this._authService.getUser()
    if(this.profile) {
      let breadcrumbStep1ItemLabel1 = 'Direct sale documents'
      let homeItemLabel = 'My direct sales'

      switch(this.profile.lang) {
        case 'en':
          breadcrumbStep1ItemLabel1 = 'Direct sale documents'
          homeItemLabel = 'My direct sales'
          break
        case 'es':
          breadcrumbStep1ItemLabel1 = 'Documentos venta directa'
          homeItemLabel = 'Mis ventas directas'
          break
      }

      this.breadcrumbItems = [{ label: breadcrumbStep1ItemLabel1, disabled: true }]
      this.breadcrumbHomeItem = { label: homeItemLabel, routerLink: '/' + Routes.userClosedSales }
    }
  }
}
