import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function passwordMatchValidator(source: string, target: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const sourceCtrl = control.get(source);
    const targetCtrl = control.get(target);

    return sourceCtrl && targetCtrl && sourceCtrl.value !== targetCtrl.value
      ? { passwordMismatch: true }
      : null;
  }
}

export function emailMatchValidator(source: string, target: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const sourceCtrl = control.get(source);
    const targetCtrl = control.get(target);

    return sourceCtrl && targetCtrl && sourceCtrl.value !== targetCtrl.value
      ? { emailMismatch: true }
      : null;
  }
}

export function createPasswordStrengthValidator(): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {

      const value = control.value;

      if (!value) {
          return null;
      }

      const hasUpperCase = /[A-Z]+/.test(value);

      const hasLowerCase = /[a-z]+/.test(value);

      const hasNumeric = /[0-9]+/.test(value);

      const hasSpecialChar = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value);

      const passwordValid = hasUpperCase && hasLowerCase && hasNumeric && hasSpecialChar;

      return !passwordValid ? {passwordStrength:true}: null;
  }
}

export function rutVerifierDigit(type: number, countryId: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if(type == 1 && countryId == 1) {
      const sourceCtrl = control.value
      if(sourceCtrl) {
        const rutParts = sourceCtrl.split("-")
        let rutWithoutVerifierDigit = rutParts[0]
        const verifierDigit = rutParts[1]

        if(isNaN(rutWithoutVerifierDigit))
          return { verifierDigitError: true }

        if(isNaN(verifierDigit) && verifierDigit != 'k')
          return { verifierDigitError: true }

        var M=0,S=1;
        for(;rutWithoutVerifierDigit;rutWithoutVerifierDigit=Math.floor(rutWithoutVerifierDigit/10))
          S=(S+rutWithoutVerifierDigit%10*(9-M++%6))%11;
        let calculated = S?S-1:'k';

        return verifierDigit.toString() != calculated ? { verifierDigitError: true } : null
      }
      else {
        return null
      }
    }
    else {
      return null
    }
  }
}

export function isDivisor(divisor: number): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {

      const dividend = control.value;

      if (!dividend) {
          return null;
      }

      return dividend % divisor !== 0 ? {divisor:true}: null;
  }
}
