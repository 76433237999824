import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Port } from "src/app/domain/models/Port";
import { Routes } from "src/app/infrastructure/routes";
import { AirportGateway } from "../gateway/AirportGateway";
import { Airport } from "src/app/domain/models/Airport";

@Injectable({providedIn: 'root'})
export class AirportService extends AirportGateway {

    constructor(private _http: HttpClient){
        super()
    }

    getAll(): Observable<Airport[]> {
        return this._http.get<Port[]>(Routes.airportApi + '/GetAll')
      }
    
      getOriginAirports(): Observable<Port[]> {
        return this._http.get<Airport[]>(Routes.airportApi + '/GetOriginAirports')
      }
    
      getDestinationAirportsByCountry(countryId: number): Observable<Port[]> {
        return this._http.get<Airport[]>(Routes.airportApi + '/GetDestinationAirportsByCountry', {params: {countryId}})
      }
}