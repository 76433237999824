<app-user-dashboard [userMenuActiveItemIndex]="0" [activeItem]="'marketplace'">
  <p-card>
    <div *ngIf="exportOffer">
      <h2>{{'FrozenFruitExportOfferForm.EditSubtitle4' | translate}}</h2>
      <p-card>
        <div class="grid">
          <div class="col-2 flex justify-content-center align-items-center">{{'FrozenFruitExportOfferForm.PesticideAnalysisDocumentLabel' | translate}}</div>
          <div class="col-2 flex justify-content-center align-items-center">
            <p-fileUpload
              #pesticideAnalysisDocumentUploader
              mode="basic"
              chooseLabel="{{'UserPages.UserExportOffers.EditExportOffer.UploadButton' | translate}}"
              [fileLimit]="1"
              [auto]="true"
              accept=".pdf"
              [maxFileSize]="5000000"
              (onSelect)="onSelectPesticideAnalysisDocument($event, pesticideAnalysisDocumentUploader)"
              [disabled]="getPesticideAnalysisDocumentName() != null"></p-fileUpload>
          </div>
          <div class="col-4 flex justify-content-center align-items-center">
            <span *ngIf="getPesticideAnalysisDocumentName()">{{getPesticideAnalysisDocumentName()}}</span>
          </div>
          <div class="col-2 flex justify-content-center align-items-center">
            <span *ngIf="getPesticideAnalysisDocumentName()">{{getFileSize(exportOffer.pesticideAnalysisDocumentSize)}} KB</span>
          </div>
          <div class="col-2 flex justify-content-end align-items-center">
            <p-button icon="pi pi-times" (onClick)="onRemovePesticideAnalysisDocument($event)" *ngIf="getPesticideAnalysisDocumentName()"></p-button>
          </div>
          <div class="col-12"><p-divider></p-divider></div>
          <div class="col-2 flex justify-content-center align-items-center">{{'FrozenFruitExportOfferForm.HeavyMetalsAnalysisDocumentLabel' | translate}}</div>
          <div class="col-2 flex justify-content-center align-items-center">
            <p-fileUpload
              #heavyMetalsAnalysisDocumentUploader
              mode="basic"
              chooseLabel="{{'UserPages.UserExportOffers.EditExportOffer.UploadButton' | translate}}"
              [fileLimit]="1"
              [auto]="true"
              accept=".pdf"
              [maxFileSize]="5000000"
              (onSelect)="onSelectHeavyMetalsAnalysisDocument($event, heavyMetalsAnalysisDocumentUploader)"
              [disabled]="getHeavyMetalsAnalysisDocumentName() != null"></p-fileUpload>
          </div>
          <div class="col-4 flex justify-content-center align-items-center">
            <span *ngIf="getHeavyMetalsAnalysisDocumentName()">{{getHeavyMetalsAnalysisDocumentName()}}</span>
          </div>
          <div class="col-2 flex justify-content-center align-items-center">
            <span *ngIf="getHeavyMetalsAnalysisDocumentName()">{{getFileSize(exportOffer.heavyMetalsAnalysisDocumentSize)}} KB</span>
          </div>
          <div class="col-2 flex justify-content-end align-items-center">
            <p-button icon="pi pi-times" (onClick)="onRemoveHeavyMetalsAnalysisDocument($event)" *ngIf="getHeavyMetalsAnalysisDocumentName()"></p-button>
          </div>

          <div class="col-12"><p-divider></p-divider></div>
          <div class="col-2 flex justify-content-center align-items-center">{{'FrozenFruitExportOfferForm.MicrobiologicalAnalysisDocumentLabel' | translate}}</div>
          <div class="col-2 flex justify-content-center align-items-center">
            <p-fileUpload
              #microbiologicalAnalysisDocumentUploader
              mode="basic"
              chooseLabel="{{'UserPages.UserExportOffers.EditExportOffer.UploadButton' | translate}}"
              [fileLimit]="1"
              [auto]="true"
              accept=".pdf"
              [maxFileSize]="5000000"
              (onSelect)="onSelectMicrobiologicalAnalysisDocument($event, microbiologicalAnalysisDocumentUploader)"
              [disabled]="getMicrobiologicalAnalysisDocumentName() != null"></p-fileUpload>
          </div>
          <div class="col-4 flex justify-content-center align-items-center">
            <span *ngIf="getMicrobiologicalAnalysisDocumentName()">{{getMicrobiologicalAnalysisDocumentName()}}</span>
          </div>
          <div class="col-2 flex justify-content-center align-items-center">
            <span *ngIf="getMicrobiologicalAnalysisDocumentName()">{{getFileSize(exportOffer.microbiologicalAnalysisDocumentSize)}} KB</span>
          </div>
          <div class="col-2 flex justify-content-end align-items-center">
            <p-button icon="pi pi-times" (onClick)="onRemoveMicrobiologicalAnalysisDocument($event)" *ngIf="getMicrobiologicalAnalysisDocumentName()"></p-button>
          </div>

          <div class="col-12"><p-divider></p-divider></div>
          <div class="col-2 flex justify-content-center align-items-center">{{'FrozenFruitExportOfferForm.WaterAnalysisDocumentLabel' | translate}}</div>
          <div class="col-2 flex justify-content-center align-items-center">
            <p-fileUpload
              #waterAnalysisDocumentUploader
              mode="basic"
              chooseLabel="{{'UserPages.UserExportOffers.EditExportOffer.UploadButton' | translate}}"
              [fileLimit]="1"
              [auto]="true"
              accept=".pdf"
              [maxFileSize]="5000000"
              (onSelect)="onSelectWaterAnalysisDocument($event, waterAnalysisDocumentUploader)"
              [disabled]="getWaterAnalysisDocumentName() != null"></p-fileUpload>
          </div>
          <div class="col-4 flex justify-content-center align-items-center">
            <span *ngIf="getWaterAnalysisDocumentName()">{{exportOffer.waterAnalysisDocumentName}}</span>
          </div>
          <div class="col-2 flex justify-content-center align-items-center">
            <span *ngIf="getWaterAnalysisDocumentName()">{{getFileSize(exportOffer.waterAnalysisDocumentSize)}} KB</span>
          </div>
          <div class="col-2 flex justify-content-end align-items-center">
            <p-button icon="pi pi-times" (onClick)="onRemoveWaterAnalysisDocument($event)" *ngIf="getWaterAnalysisDocumentName()"></p-button>
          </div>
        </div>
      </p-card>

      <div class="grid" style="margin-top: 40px;">
        <div class="col-12 md:col-6 lg:6"></div>
        <div class="col-12 md:col-6 lg:6">
          <div class="p-fluid formgrid grid">
            <div class="col-6">
              <p-button label="{{'FrozenFruitExportOfferForm.BackButtonLabel' | translate}}" [disabled]="loading" styleClass="p-button-outlined" (onClick)="goToFrozenFruitExportOffersPage()"></p-button>
            </div>
            <div class="col-6">
              <p-button label="{{'FrozenFruitExportOfferForm.SaveButtonLabel' | translate}}" [loading]="loading" (onClick)="saveDocuments()"></p-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </p-card>
</app-user-dashboard>

<p-toast></p-toast>
