import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DirectSaleProgramCreateRequest, DirectSaleProgram, DirectSaleImporterFilter } from "src/app/domain/models/DirectSaleProgram";
import { Routes } from "src/app/infrastructure/routes";
import { DirectSaleProgramGateway } from "../gateway/DirectSaleProgramGateway";
import { ExportOfferInformation } from "src/app/domain/models/ExportOffer";

@Injectable({providedIn: 'root'})
export class DirectSaleProgramService extends DirectSaleProgramGateway {

  constructor(private _http: HttpClient) {
    super()
  }

  createWithOrders(request: DirectSaleProgramCreateRequest): Observable<DirectSaleProgram> {
    return this._http.post<DirectSaleProgram>(Routes.directSaleProgramApi + '/Create', request)
  }

  desactive(guid: any): Observable<DirectSaleProgram> {
    return this._http.get<DirectSaleProgram>(Routes.directSaleProgramApi + '/DeactivateProgram', { params :{guid}})
  }
  getByGuid(directSaleProgramGuid: string): Observable<DirectSaleProgram> {
    return this._http.get<DirectSaleProgram>(Routes.directSaleProgramApi + '/GetDirectSaleProgramByDirectSaleById', { params :{directSaleProgramGuid}})
  }

  getDirectSaleImporter(): Observable<ExportOfferInformation[]> {
      
    return this._http.get<ExportOfferInformation[]>(Routes.directSaleProgramApi + '/GetDirectSaleImporter')
  }

  getDirectSaleImporterFilter(request: DirectSaleImporterFilter): Observable<ExportOfferInformation[]>
  {
    return this._http.post<ExportOfferInformation[]>(
      Routes.directSaleProgramApi + '/GetDirectSaleImporterFilter', 
      this.getFormDataCloseSaleForm(request)
    )
  }

  getFormDataCloseSaleForm(request: DirectSaleImporterFilter)
  {
    const formData = new FormData()
    if(request){

      if(request.speciesId){
        formData.append('speciesId', request.speciesId.toString())
      }
      if(request.exporterId){
          formData.append('importerId', request.exporterId.toString())
      }
      if(request.exportOfferId){
        formData.append('exportOfferId', request.exportOfferId.toString())
      }
      if(request.isActive == 0 || request.isActive == 1)
        {
      formData.append('isActive', request.isActive.toString())
      }
      if(request.kilosPerBox){
      formData.append('kilosPerBox', request.kilosPerBox)
      }
      if(request.harvestDate) {
        if(request.harvestDate instanceof Date)
          {
            request.harvestDate.toDateString()
            formData.append("harvestDate", new Date(request.harvestDate).toDateString())
          }

      }
      else {
        formData.append("harvestDate", '')
      }
      if(request.marketId){
      formData.append('marketId', request.marketId.toString())
      }
      if(request.speciesSize){
      formData.append('speciesSize', request.speciesSize.toString())
      }
      if(request.shelfLife){
      formData.append('shelfLife', request.shelfLife.toString())
      }
      if(request.price){
      formData.append('price', request.price.toString())
      }
    }

    return formData
  }

}
