import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { DeliveryGateway } from 'src/app/application/gateway/DeliveryGateway';
import { BasicUser } from 'src/app/domain/models/User';
import { Routes } from 'src/app/infrastructure/routes';

@Component({
  selector: 'app-user-closed-sale-payments',
  templateUrl: './user-closed-sale-payments.component.html',
  styleUrls: ['./user-closed-sale-payments.component.css'],
  providers: [MessageService]
})
export class UserClosedSalePaymentsComponent implements OnInit  {

  breadcrumbMenuItems: MenuItem[] = []
  breadcrumbHome: MenuItem = {}

  importOrderId: any | undefined
  directSaleProgramId: any | undefined

  profile: BasicUser | undefined

  constructor(private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _messageService: MessageService,
    private _authService: AuthGateway,
    private _deliveryService: DeliveryGateway,
    public translate: TranslateService) {
    this._activatedRoute.params.subscribe(params => {
      this.importOrderId = params['importOrderId']
      this.directSaleProgramId = params['directSaleProgramId']
    })
  }

  ngOnInit(): void {
    this.profile = this._authService.getUser()

    let breadcrumbHomeItemLabel = 'My direct sales'
    let breadcrumbMenuItemLabel1 = 'Direct sale order program'
    let breadcrumbMenuItemLabel2 = 'Import order detail'
    let breadcrumbMenuItemLabel3 = 'Payment registry'

    if(this.profile) {
      switch(this.profile.lang) {
        case 'en':
          breadcrumbHomeItemLabel = 'My direct sales'
          breadcrumbMenuItemLabel1 = 'Direct sale order program'
          breadcrumbMenuItemLabel2 = 'Import order detail'
          breadcrumbMenuItemLabel3 = 'Payment registry'

          break
        case 'es':
          breadcrumbHomeItemLabel = 'Mis ventas directas'
          breadcrumbMenuItemLabel1 = 'Programa de venta directa'
          breadcrumbMenuItemLabel2 = 'Detalle de orden de importación'
          breadcrumbMenuItemLabel3 = 'Registro de pago'
          break
      }

      if(this.profile?.profileType == 'Exporter')
        this.breadcrumbHome = {label: breadcrumbHomeItemLabel, routerLink: '/' + Routes.userClosedSales }
      else if(this.profile?.profileType == 'Importer')
        this.breadcrumbHome = {label: breadcrumbHomeItemLabel, command: () => this.goToDirectSaleProgram() }

      this.breadcrumbMenuItems = [
        {label: breadcrumbMenuItemLabel1, command: () => this.goToDirectSaleProgram()},
        {label: breadcrumbMenuItemLabel2, command: () => { this.goToImportOrderPage() }},
        {label: breadcrumbMenuItemLabel3, disabled: true}
      ]
    }
  }

  goToImportOrderPage() {
    this._router.navigate(['/' + Routes.userClosedSaleImportOrderDetailPage, this.importOrderId, this.directSaleProgramId])
  }

  goToDirectSaleProgram() {
    if(this.profile) {
      if(this.profile.profileType == 'Importer') {
        this._router.navigate(['/' + Routes.importerClosedSaleOrdersPage])
      }
      else if(this.profile.profileType == 'Exporter') {
        this._router.navigate(['/' + Routes.exporterClosedSaleOrdersPage, this.directSaleProgramId])
      }
    }
  }
}
