import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { QualityControlReviewUpdate, FrozenFruitQualityControlReviewUpdate, QualityControlReviewItemList, QualityControlReview, FrozenFruitQualityControlReview, UploadQualityControlReviewPdfRequest } from "src/app/domain/models/QualityControlReview";
import { Routes } from "src/app/infrastructure/routes";
import { QualityControlReviewGateway } from "../gateway/QualityControlReviewGateway";

@Injectable({providedIn: 'root'})
export class QualityControlReviewService extends QualityControlReviewGateway {

  constructor(private _http: HttpClient) {
    super()
  }

  verifyFreshFruitOrigin(request: QualityControlReviewUpdate): Observable<any> {
    return this._http.put<any>(Routes.qcReviewApi + '/VerifyFreshFruitOrigin', request)
  }

  verifyFrozenFruitOrigin(request: FrozenFruitQualityControlReviewUpdate): Observable<any> {
    return this._http.put<any>(Routes.qcReviewApi + '/VerifyFrozenFruitOrigin', request)
  }

  getAllByStatusAndReviewType(status: number, reviewType: number): Observable<QualityControlReviewItemList[]> {
    return this._http.get<QualityControlReviewItemList[]>(Routes.qcReviewApi + '/GetAllByStatusAndReviewType', {params: {status, reviewType}})
  }

  getByIdFreshFruit(guid: string): Observable<QualityControlReview> {
    return this._http.get<QualityControlReview>(Routes.qcReviewApi + '/GetByIdFreshFruit', {params: {guid}})
  }

  getByIdFrozenFruit(guid: string): Observable<FrozenFruitQualityControlReview> {
    return this._http.get<FrozenFruitQualityControlReview>(Routes.qcReviewApi + '/GetByIdFrozenFruit', {params: {guid}})
  }

  uploadOriginQCReviewPdf(request: UploadQualityControlReviewPdfRequest): Observable<any> {
    const formData = new FormData()
    formData.append("qualityControlReviewGuid", request.qualityControlReviewGuid.toString())
    formData.append("pdf", request.pdf)

    return this._http.put<any>(Routes.qcReviewApi + '/UploadOriginQCReviewPdf', formData)
  }

  uploadOriginQCConsolidationPdf(request: UploadQualityControlReviewPdfRequest): Observable<any> {
    const formData = new FormData()
    formData.append("qualityControlReviewGuid", request.qualityControlReviewGuid.toString())
    formData.append("pdf", request.pdf)

    return this._http.put<any>(Routes.qcReviewApi + '/UploadOriginQCConsolidationPdf', formData)
  }

  getAll(): Observable<QualityControlReviewItemList[]> {
    return this._http.get<QualityControlReviewItemList[]>(Routes.qcReviewApi + '/GetAll')
  }
}
