import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { DeliveryGateway } from 'src/app/application/gateway/DeliveryGateway';
import { DocumentServiceGateway } from 'src/app/application/gateway/DocumentServiceGateway';
import { PaymentGateway } from 'src/app/application/gateway/PaymentGateway';
import { Payment, PaymentCreateRequest, PaymentsInformation, PaymentUploadFile } from 'src/app/domain/models/Payment';
import { BasicUser } from 'src/app/domain/models/User';
import { Lang } from 'src/app/ui/util/langs';

@Component({
  selector: 'app-payments-component',
  templateUrl: './payments-component.component.html',
  styleUrls: ['./payments-component.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class PaymentsComponentComponent implements OnInit {

  @Input()
  importOrderId: any

  paymentsInformation: PaymentsInformation | undefined
  payments: Payment[] = []
  selectedPayment: Payment | undefined

  loading: boolean = false
  submit: boolean = false
  limitPaymentDate: Date | undefined
  uploadedFiles: any[] = []

  visibleCreatePaymentDialog: boolean = false
  visibleUploadPaymentDialog: boolean = false

  profile: BasicUser | undefined

  formGroup: FormGroup = this._formBuilder.group({
    swiftId: ['', Validators.required],
    amount: ['', Validators.required],
    description: [null]
  })

  constructor(private _router: Router,
    private _formBuilder: FormBuilder,
    private _messageService: MessageService,
    private _confirmationService: ConfirmationService,
    private _paymentService: PaymentGateway,
    private _deliveryService: DeliveryGateway,
    private _authService: AuthGateway,
    public translate: TranslateService,
    private _documentService: DocumentServiceGateway){
  }

  ngOnInit(): void {
    this.profile = this._authService.getUser()
    this.loadPayments();
  }

  loadPayments() {
    this._paymentService.getAllByImportOrderId(this.importOrderId).subscribe({
      next: (v) => {
        this.paymentsInformation = v
        this.payments = this.paymentsInformation.payments
        this.limitPaymentDate = new Date(this.paymentsInformation.deliveryCreated)
        this.limitPaymentDate.setHours(new Date(this.paymentsInformation.deliveryCreated).getHours() + 12)
        console.log(v)
      },
      error: (e) => {
        console.log(e)
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Payment.ManyRetrieveError') });
      },
      complete: () => {
        this.loading = false
        console.info('Payments query successfully')
      }
    })
  }

  openCreatePaymentDialog() {
    this.resetCreatePaymentForm()
    this.visibleCreatePaymentDialog = true
  }

  resetCreatePaymentForm() {
    this.formGroup.controls['swiftId'].setValue(null)
    this.formGroup.controls['amount'].setValue(null)
    this.formGroup.controls['description'].setValue(null)
    this.submit = false
    this.loading = false
    this.formGroup.reset()
    this.uploadedFiles = [];
  }

  getIsApproved(isApproved: boolean): string {
    if(this.profile)
      return Lang.getBooleanLabel(this.profile.lang, isApproved)
    return ''
  }

  getPaymentDocument(paymentId: number) {
    this._documentService.getPaymentDocument(paymentId).subscribe((resultBlob: any) => {
      var downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }

  disableButton(payment: Payment){
    return payment.pdfLength == 0;
  }

  openUploadPaymentDialog(payment: Payment) {
    this.selectedPayment = payment
    this.resetCreatePaymentForm()
    this.visibleUploadPaymentDialog = true
  }

  get totalPrice(): number {
    if(this.paymentsInformation) {
      return this.paymentsInformation.totalPrice
    }
    return 0
  }

  get totalPayments(): number {
    if(this.paymentsInformation) {
      return this.paymentsInformation.totalPayments
    }
    return 0
  }

  createPayment() {
    this.submit = true
    this.loading = true
    if(this.formGroup.valid) {
      this._confirmationService.confirm({
        message: this.translate.instant('MessageService.Payment.ConfirmPaymentDialogMessage'),
        header: this.translate.instant('MessageService.Payment.ConfirmPaymnetDialogHeader'),
        icon: 'pi pi-info-circle',
        acceptLabel: this.translate.instant('MessageService.Payment.ButtonAcceptLabel'),
        rejectLabel: this.translate.instant('MessageService.Payment.ButtonRejectLabel'),
        accept: () => {
          if(this.paymentsInformation) {
            const payment: Payment = {
              swiftId: this.formGroup.controls['swiftId'].value,
              amount: this.formGroup.controls['amount'].value,
              isApproved: false,
              description: this.formGroup.controls['description'].value
            }

            const request: PaymentCreateRequest = {
              importOrderId: this.paymentsInformation.importOrderId,
              payment: payment,
              pdf: this.uploadedFiles[0]
            }

            this._paymentService.create(request).subscribe({
              next: (v) => {
                this.submit = false
                this.loading = false
                this.loadPayments()
                this.visibleCreatePaymentDialog = false
                this._messageService.add({ severity: 'success',
                  summary: this.translate.instant('MessageService.Summary.SuccessAction'),
                  detail: this.translate.instant('MessageService.Payment.CreateSuccess') })
              },
              error: (e) => {
                console.log(e)
                this.submit = false
                this.loading = false
                this.visibleCreatePaymentDialog = false
                this._messageService.add({ severity: 'error',
                  summary: this.translate.instant('MessageService.Summary.FailedAction'),
                  detail: this.translate.instant('MessageService.Payment.CreateError') + e.error });
              },
              complete: () => {
                this.loading = false
                this.submit = false
                console.info('Payment created successfully')
              }
            })

          }
        },
        reject: (type: any) => {
            switch (type) {
                case ConfirmEventType.REJECT:
                  this.resetCreatePaymentForm()
                  break;
                case ConfirmEventType.CANCEL:
                  this.resetCreatePaymentForm()
                  break;
            }
        }
      })
    }
    else {
      this.loading = false
    }
  }

  onSelect(event: any) {
    this.uploadedFiles = []
    for(let file of event.files) {
        this.uploadedFiles.push(file);
    }
  }

  uploadPayment(){
    if(this.selectedPayment) {
      this.loading = true
      const paymentUpload : PaymentUploadFile = {
       paymentId : this.selectedPayment.id,
       pdf: this.uploadedFiles[0]
      }

      this._paymentService.uploadFile(paymentUpload).subscribe({
        next: (v) => {
          this.loading = false
          this.loadPayments()
          this.visibleUploadPaymentDialog = false
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: this.translate.instant('MessageService.Payment.UploadSwiftSuccess') })
        },
        error: (e) => {
          this.loading = false
          this.visibleUploadPaymentDialog = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.Payment.UploadSwiftError') + e.error });
        },
        complete: () => {
          this.loading = false
          console.info('Payment upload successfully')
        }
      })
    }
  }

  getErrorMessage(fieldName: string): string {
    if (this.formGroup.controls[fieldName].hasError('required')) {
      return this.translate.instant('FormErrorMessage.FieldValueRequired')
    }
    if (this.formGroup.controls[fieldName].hasError('email')) {
      return this.translate.instant('FormErrorMessage.EmailFieldEmailFormatError')
    }
    return ''
  }
}
