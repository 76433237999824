<app-user-dashboard [fullScreen]="true">
  <app-content-body>
    <div class="bg-container">
      <div class="container" *ngIf="exportOfferInfo">
        <div class="col-12">
          <p-breadcrumb class="max-w-full" [model]="breadcrumbItems"></p-breadcrumb>
        </div>
        <div class="section-top">
          <div class="column-left-top">
            <app-export-offer-detail-carousel [offer]="exportOfferInfo.exportOffer"></app-export-offer-detail-carousel>

            <div class="section-description">
              <h2 class="title-section">{{'ExportOffer.ExportOfferDetail.SpecieDescription.Title' | translate}}</h2>
              <div class="section-description-info">
                <div class="rowInfo">
                  <div class="container-info">
                    <span class="label-info">{{'ExportOffer.ExportOfferDetail.SpecieDescription.DescriptionText' | translate}}:</span>
                    <p class="text-info">{{speciesDescription.description ? speciesDescription.description : getAvailableLabel() }}</p>
                  </div>
                  <div class="container-info">
                    <span class="label-info">{{'ExportOffer.ExportOfferDetail.SpecieDescription.TechnicalCharacteristicsText' | translate}}:</span>
                    <p class="text-info">{{speciesDescription.technicalCharacteristics ? speciesDescription.technicalCharacteristics : getAvailableLabel()}}</p>
                  </div>
                </div>
                <div class="rowInfo">
                  <div class="container-info">
                    <span class="label-info">{{'ExportOffer.ExportOfferDetail.SpecieDescription.ExportedVarietiesText' | translate}}:</span>
                    <p class="text-info">{{exportOfferInfo.exportOffer.variety.species.exportVarieties}}</p>
                  </div>
                  <div class="container-info">
                    <span class="label-info">{{'ExportOffer.ExportOfferDetail.SpecieDescription.QualityControlCriteriaText' | translate}}:</span>
                    <p class="text-info">{{speciesDescription.qualityControlCriteria ? speciesDescription.qualityControlCriteria : getAvailableLabel()}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="column-right-top">
            <div>
              <div class="header-card">
                <div class="section-titles">

                  <h2 class="title-product">
                    {{exportOfferInfo.exportOffer.variety.species.name}} / {{exportOfferInfo.exportOffer.variety.name}}
                  </h2>
                  <p class="title-price" *ngIf="profile?.profileType === 'Importer'">{{'MarketplacePage.WishList.ExportOfferModal.TotalUpperCaseText' | translate}} {{ totalPrice | currency: 'USD':'US$'}}
                    {{getIncoterms(exportOfferInfo.exportOffer.typeSale)}}
                  </p>
                </div>
                <div class="section-networks">
                  <app-social-media-share [url]="urlRoute"></app-social-media-share>
                </div>
              </div>

              <div class="section-main-info">

                <div class="container-main-info">
                  <h5 class="span-info">{{'MarketplacePage.WishList.ExportOfferModal.CasePerPalletsText' | translate}}:</h5>
                  <h3 class="data-main-info">
                    {{exportOfferInfo.exportOffer.caseQuantityPerPallet }}
                  </h3>
                </div>

                <div class="container-main-info">
                  <h5 class="span-info">{{'MarketplacePage.WishList.ExportOfferModal.SpeciesSizeText' | translate}}:</h5>
                  <h3 class="data-main-info">
                    {{ exportOfferInfo.exportOffer.speciesSize ? exportOfferInfo.exportOffer.speciesSize : getNoAvailableLabel() }}
                  </h3>
                </div>

                <div class="container-main-info">
                  <h5 class="span-info">{{'ExportOffer.ExportOfferDetail.ShelfLife' | translate}}:</h5>
                  <h3 class="data-main-info">
                    {{ exportOfferInfo.exportOffer.shelfLife ? exportOfferInfo.exportOffer.shelfLife +  " " + translate.instant('MarketplacePage.WishList.ExportOfferModal.DayShelflifeText') : getNoAvailableLabel() }}
                  </h3>
                </div>

                <div class="container-main-info">
                  <h5 class="span-info">{{'MarketplacePage.WishList.ExportOfferModal.AvailablePalletsText' | translate}}:</h5>
                  <h3 class="data-main-info">
                    {{ exportOfferInfo.exportOffer.availablePallets }}
                  </h3>
                </div>

                <div class="container-main-info">
                  <h5 class="span-info">{{'MarketplacePage.WishList.ExportOfferModal.NetKilosText' | translate}}:</h5>
                  <h3 class="data-main-info">
                    {{ exportOfferInfo.exportOffer.netKilos ? exportOfferInfo.exportOffer.netKilos + ' Kg' : getNoAvailableLabel() }}
                  </h3>
                </div>

                <div class="container-main-info">
                  <h5 class="span-info">{{'MarketplacePage.WishList.ExportOfferModal.Packaging' | translate}}:</h5>
                  <h3 class="data-main-info">
                    {{ exportOfferInfo.exportOffer.netKilos ? getPackagingTypes(exportOfferInfo.exportOffer.packagingTypes) : getNoAvailableLabel() }}
                  </h3>
                </div>

                <div class="container-main-info">
                  <h5 class="span-info">{{'MarketplacePage.WishList.ExportOfferModal.UnitPrice' | translate}}:</h5>
                  <h3 class="data-main-info" *ngIf="exportOfferInfo.exportOffer.listPrice">
                    {{ exportOfferInfo.exportOffer.listPrice | currency: 'USD':'US$' }}
                    {{ exportOfferInfo.exportOffer.typeSale ? exportOfferInfo.exportOffer.typeSale : "" }}
                  </h3>
                  <h3 class="data-main-info" *ngIf="!exportOfferInfo.exportOffer.listPrice">
                    getNoAvailableLabel()
                  </h3>
                </div>

                <div class="container-main-info">
                  <h5 class="span-info">{{'MarketplacePage.WishList.ExportOfferModal.PaymentAgainstOffer' | translate}}:</h5>
                  <h3 class="data-main-info">
                    {{ exportOfferInfo.exportOffer.netKilos ? exportOfferInfo.exportOffer.paymentAgainstBL + ' %' : getNoAvailableLabel() }}
                  </h3>
                </div>

                <div class="container-main-info">
                  <h5 class="span-info">{{'ExportOffer.ExportOfferDetail.AvailableProductCertificates2' | translate}}:</h5>
                  <h3 class="data-main-info" *ngIf="exportOfferInfo.certificates">{{ getCertificatesName(exportOfferInfo.certificates) }}</h3>
                  <span *ngIf="!exportOfferInfo.certificates" style="font-weight: bolder;">{{ 'UserPages.UserExportOffers.NotResultsMessages' | translate }}</span>
                </div>
              </div>


              <div class="p-fluid formgrid grid align-items-start" *ngIf="profile?.profileType === 'Importer'"
                style="margin-top: 15px;">
                <div class="col-12" style="margin-bottom: 15px;">
                  <h2 class="data-main-info" >{{'MarketplacePage.WishList.ExportOfferModal.PurchaseForm.Title' | translate}}</h2>
                </div>
                  <div class="P-field col-12 md:col-4 lg:col-4">
                    <form [formGroup]="purchaseForm">
                      <label for="palletsId">Pallets</label>
                      <p-inputNumber
                      #palletsId
                      formControlName="pallets"
                      [showButtons]="false" [min]="1"
                      [max]="maxSale" [size]="2"
                      buttonLayout="horizontal"
                      spinnerMode="horizontal" [step]="1"
                      decrementButtonIcon="pi pi-minus" (onBlur)="onInputSale($event)" (onInput)="onInputSale($event)"
                      class="p-inputtext-sm"></p-inputNumber>
                      <small *ngIf="!allowPurchase == true"
                      class="p-error">{{getPalletsErrorMessage('pallets')}}</small>
                      <small *ngIf="purchaseForm.controls['pallets'].invalid"
                      class="p-error">{{getPurchaseMessage('pallets')}}</small>
                    </form>
                  </div>
                  <div class="field col-12 md:col-12 lg:col-4">
                    <p-button
                    [style]="{'margin-top':'25px'}"
                    label="{{'MarketplacePage.WishList.ExportOfferModal.PurchaseForm.PurchaseNowButton' | translate}}"
                    (onClick)="createOrder()"
                    [disabled]="!allowPurchase"
                    [loading]="loading"></p-button>
                  </div>
              </div>

                <form [formGroup]="negotiationFormGroup" *ngIf="profile?.profileType === 'Importer'"
                  style="margin-top: 30px;">
                  <div class="grid">
                    <div class="col-12">
                      <h2 class="data-main-info">{{'MarketplacePage.WishList.ExportOfferModal.NegotiationFieldSetLegend' | translate}}</h2>
                    </div>
                    <div class="col-12">
                      <div class="p-fluid formgrid grid flex align-items-start">
                        <div class="field col-12 md:col-4 lg:col-4">
                          <label for="negociation-price">{{'MarketplacePage.WishList.ExportOfferModal.NegotiationPriceFieldLable' | translate}}</label>
                          <p-inputNumber inputId="negociation-price" formControlName="negociationPrice" [min]="0"
                            mode="currency" currency="USD" locale="en-US" placeholder="{{'MarketplacePage.WishList.ExportOfferModal.NegotiationPriceFieldPlaceholder' | translate}}"
                            class="p-inputtext-sm"></p-inputNumber>
                          <small *ngIf="submitNegotiation && negotiationFormGroup.controls['negociationPrice'].invalid"
                            class="p-error">{{getErrorMessage('negociationPrice')}}</small>
                        </div>
                        <div class="field col-12 md:col-4 lg:col-4">
                          <label for="negotiation-pallets">{{'MarketplacePage.WishList.ExportOfferModal.PalletsFieldLabel' | translate}}</label>
                          <p-inputNumber inputId="negotiation-pallets" formControlName="negociationPallets" [min]="1" (onBlur)="onInputNegotiation($event)"
                          (onInput)="onInputNegotiation($event)"
                            [max]="maxSale" class="p-inputtext-sm"></p-inputNumber>
                          <small *ngIf="submitNegotiation && negotiationFormGroup.controls['negociationPallets'].invalid"
                            class="p-error">{{getErrorMessage('negociationPallets')}}</small>
                            <p style="font-size: 11px;" *ngIf="!allowNegotiation"
                            class="p-error">{{getPalletsErrorMessage('negociationPrice')}}</p>
                        </div>
                        <div class="field col-12 md:col-4 lg:col-4">
                          <label for="negociation-incoterm">{{'MarketplacePage.WishList.ExportOfferModal.SellModeFieldLabel' | translate}}</label>
                          <p-dropdown inputId="negociation-incoterm" formControlName="negociationIncoterm"
                            (onChange)="onChangeIncoterm($event)" [options]="incotermsOptions" optionLabel="label" optionValue="value"
                            placeholder="{{'MarketplacePage.WishList.ExportOfferModal.SellModeFieldPlaceholder' | translate}}" appendTo="body" class="p-inputtext-sm"></p-dropdown>
                          <small *ngIf="submitNegotiation && negotiationFormGroup.controls['negociationIncoterm'].invalid" class="p-error">{{getErrorMessage('negociationIncoterm')}}</small>
                        </div>
                        <div class="field col-12 md:col-8 lg:col-8">
                          <label for="negociation-paymentAgainstBL" style="font-size: calc(100% - 4px);">{{ paymentBLTitle | translate }} *</label>
                          <p-dropdown id="negociation-paymentAgainstBL" formControlName="negotiationPaymentAgainstBL" [options]="paymentBLItems" optionLabel="label" optionValue="value" placeholder="{{'UserPages.UserBargaining.OpenTab.BargainingDetail.MakeCounterOfferModal.PaymentAgainstBLLabel' | translate}}" appendTo="body"></p-dropdown>
                          <small *ngIf="submitNegotiation && negotiationFormGroup.controls['negotiationPaymentAgainstBL'].invalid" class="p-error">{{getErrorMessage('negotiationPaymentAgainstBL')}}</small>
                          <small *ngIf="showPaymentBLPercent">{{'UserPages.UserExportOffers.NewExportOffer.FinalPaymentSubjectQCReview' | translate}} {{finalPaymentPercent}}%</small>
                        </div>
                        <div class="field col-12 md:col-4 lg:col-4">
                          <label>&nbsp;</label>
                          <p-button
                          label="{{'MarketplacePage.WishList.ExportOfferModal.CreateNegotiationButton' | translate}}"
                          (onClick)="createNegotiation()"
                          [loading]="loading"
                          [disabled]="profile?.profileType !== 'Importer'||!allowNegotiation"
                          [style]="{'background-color': '#8c2b87'}">
                        </p-button>
                          <label *ngIf="submitNegotiation && !negotiationFormGroup.valid">&nbsp;</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

              <div class="container-bottom">
                <div>
                  <app-request-import-request *ngIf="profile?.profileType === 'Importer'" [exportOfferGuid]="exportOfferInfo.exportOffer.guid"></app-request-import-request>
                </div>
                <app-qr-image [imageQrData]="imageData" width="120px" height="120px"></app-qr-image>
              </div>
            </div>
          </div>
        </div>
        <!--
        <app-export-offer-detail-description [offer]="exportOfferAd"></app-export-offer-detail-description>
        -->


        <div class="section-bottom">
          <div class="section-description">
            <h2 class="title-section">{{'ExportOffer.ExportOfferDetail.ProductDescription' | translate}}</h2>
            <div class="section-description-info">

              <div class="container-description-info">
                <span class="span-info" >
                  {{'ExportOffer.ExportOfferDetail.SpeciesText' | translate}}:
                </span>
                <h4 class="small-data-info">
                  {{ exportOfferInfo.exportOffer.variety.species.name }}
                </h4>
              </div>

              <div class="container-description-info">
                <span class="span-info" >
                  {{'ExportOffer.ExportOfferDetail.VarietyText' | translate}}:
                </span>
                <div class="flex align-items-center">
                  <div class="flex align-items-center">
                    <img [src]="exportOfferInfo.exportOffer.variety.urlIcon" style="width: 18px;margin-right:.5em;" alt="Variety icon"/>
                    <h4 class="small-data-info">
                      {{exportOfferInfo.exportOffer.variety.name}}
                    </h4>
                  </div>
                </div>
              </div>

              <div class="container-description-info">
                <span class="span-info" >
                  {{'ExportOffer.ExportOfferDetail.CategoryText' | translate}}:
                </span>
                <h4 class="small-data-info">
                  {{exportOfferInfo.exportOffer.category ? exportOfferInfo.exportOffer.category : getAvailableLabel()}}
                </h4>
              </div>

              <div class="container-description-info">
                <span class="span-info" >
                  {{'ExportOffer.ExportOfferDetail.LocationLabel' | translate}}:
                </span>
                <h4 class="small-data-info">
                  {{exportOfferInfo.exportOffer.fridgeStorage ? exportOfferInfo.exportOffer.fridgeStorage.contactCity + ', ' + (exportOfferInfo.exportOffer.fridgeStorage.contactCountry | countryDescription) : exportOfferInfo.exportOffer.country?.name}}
                </h4>
              </div>

              <div class="container-description-info">
                <span class="span-info" >
                  {{'ExportOffer.ExportOfferDetail.HarvestDateText' | translate}}:
                </span>
                <h4 class="small-data-info">
                  {{exportOfferInfo.exportOffer.harvestDate ? (exportOfferInfo.exportOffer.harvestDate | date: 'dd-MM-yyyy') : getAvailableLabel()}}
                </h4>
              </div>

              <div class="container-description-info">
                <span class="span-info" >
                  {{'ExportOffer.ExportOfferDetail.ShelfLife' | translate}}:
                </span>
                <h4 class="small-data-info">
                  {{exportOfferInfo.exportOffer.shelfLife ? exportOfferInfo.exportOffer.shelfLife + ' ' + translate.instant('ExportOffer.ExportOfferDetail.DayShelflifeText') : getAvailableLabel()}}
                </h4>
              </div>

              <div class="container-description-info">
                <span class="span-info" >
                  {{'ExportOffer.ExportOfferDetail.CasePerPalletsText' | translate}}:
                </span>
                <h4 class="small-data-info">
                  {{exportOfferInfo.exportOffer.caseQuantityPerPallet}}
                </h4>
              </div>

              <div class="container-description-info">
                <span class="span-info" >
                  {{'ExportOffer.ExportOfferDetail.SpeciesSizeText' | translate}}:
                </span>
                <h4 class="small-data-info">
                  {{exportOfferInfo.exportOffer.speciesSize ? exportOfferInfo.exportOffer.speciesSize : getAvailableLabel()}}
                </h4>
              </div>

              <div class="container-description-info">
                <span class="span-info" >
                  {{'ExportOffer.ExportOfferDetail.SpeciesSizeMillimetersFieldText' | translate}}:
                </span>
                <h4 class="small-data-info">
                  {{exportOfferInfo.exportOffer.sizeMillimeters ? exportOfferInfo.exportOffer.sizeMillimeters + ' mm.' : getAvailableLabel()}}
                </h4>
              </div>

              <div class="container-description-info">
                <span class="span-info" >
                  {{'ExportOffer.ExportOfferDetail.NetKilosText' | translate}}:
                </span>
                <h4 class="small-data-info">
                  {{exportOfferInfo.exportOffer.netKilos ? exportOfferInfo.exportOffer.netKilos + ' Kg.' : getAvailableLabel()}}
                </h4>
              </div>

              <div class="container-description-info">
                <span class="span-info" >
                  {{'ExportOffer.ExportOfferDetail.GrossKilosText' | translate}}:
                </span>
                <h4 class="small-data-info">
                  {{exportOfferInfo.exportOffer.grossKilos ? exportOfferInfo.exportOffer.grossKilos + ' Kg.' : getAvailableLabel()}}
                </h4>
              </div>

              <div class="container-description-info">
                <span class="span-info" >
                  {{'ExportOffer.ExportOfferDetail.BoxTypeText' | translate}}:
                </span>
                <h4 class="small-data-info">
                  {{exportOfferInfo.exportOffer.boxType ? getBoxType(exportOfferInfo.exportOffer.boxType) : getAvailableLabel()}}
                </h4>
              </div>

              <div class="container-description-info">
                <span class="span-info" >
                  {{'ExportOffer.ExportOfferDetail.CaseSize' | translate}}:
                </span>
                <h4 class="small-data-info">
                  {{exportOfferInfo.exportOffer.caseSize ? exportOfferInfo.exportOffer.caseSize : getAvailableLabel()}}
                </h4>
              </div>

              <div class="container-description-info">
                <span class="span-info" >
                  {{'ExportOffer.ExportOfferDetail.BasePalletsText' | translate}}:
                </span>
                <h4 class="small-data-info">
                  {{exportOfferInfo.exportOffer.basePallets ? getBasePallets(exportOfferInfo.exportOffer.basePallets) : getAvailableLabel()}}
                </h4>
              </div>

              <div class="container-description-info">
                <span class="span-info" >
                  {{'ExportOffer.ExportOfferDetail.ColorText' | translate}}:
                </span>
                <h4 class="small-data-info">
                  {{exportOfferInfo.exportOffer.color ? exportOfferInfo.exportOffer.color : getAvailableLabel()}}
                </h4>
              </div>

              <div class="container-description-info">
                <span class="span-info" >
                  {{'ExportOffer.ExportOfferDetail.MarksText' | translate}}:
                </span>
                <h4 class="small-data-info">
                  {{exportOfferInfo.exportOffer.marks ? markTrue : "No"}}
                </h4>
              </div>

              <div class="container-description-info">
                <span class="span-info" >
                  {{'ExportOffer.ExportOfferDetail.ReadyToLoadDateText' | translate}}:
                </span>
                <h4 class="small-data-info">
                  {{exportOfferInfo.exportOffer.readyToLoadDate ? (exportOfferInfo.exportOffer.readyToLoadDate | date: 'dd-MM-yyyy') : getAvailableLabel()}}
                </h4>
              </div>

              <div class="container-description-info">
                <span class="span-info" >
                  {{'MarketplacePage.WishList.ExportOfferModal.Packaging' | translate}}:
                </span>
                <h4 class="small-data-info">
                  {{ exportOfferInfo.exportOffer.netKilos ? getPackagingTypes(exportOfferInfo.exportOffer.packagingTypes) : getNoAvailableLabel() }}
                </h4>
              </div>

              <div class="container-description-info">
                <span class="span-info" >
                  {{'MarketplacePage.WishList.ExportOfferModal.qualityControlText' | translate}}:
                </span>
                <h4  *ngIf="exportOfferInfo.exportOffer.qualityDocuments.length <= 0" class="small-data-info">{{getNoAvailableLabel()}}</h4>
                <p-button *ngIf="exportOfferInfo.exportOffer.qualityDocuments.length > 0" (onClick)="getExportOfferQualityDocument(exportOfferInfo.exportOffer.qualityDocuments)" pTooltip="{{'UserPages.UserExportOffers.EditExportOffer.ViewQualityDocument' | translate}}" [style]="{ 'margin': 0, 'padding': 0 }" icon="pi pi-file-pdf" styleClass="p-button-link p-button-lg"></p-button>
              </div>

              <div class="container-description-info">
                <span class="span-info" >
                  {{'ExportOffer.ExportOfferDetail.QualityControlDateText' | translate}}:
                </span>
                <h4 class="small-data-info">
                  {{exportOfferInfo.exportOffer.qualityControlDate ? (exportOfferInfo.exportOffer.qualityControlDate | date: 'dd-MM-yyyy') : getAvailableLabel()}}
                </h4>
              </div>

              <div class="container-description-info">
                <span class="span-info" >
                  {{'ExportOffer.ExportOfferDetail.OriginCountryText' | translate}}:
                </span>
                <div class="flex align-items-center">
                  <div class="flex align-items-center">
                    <img [src]="'../../../../assets/img/flags/' + (exportOfferInfo.exportOffer.country?.code) + '.png'" style="width: 18px; margin-right:10px" alt=""/>
                    <h4 class="small-data-info">
                      {{exportOfferInfo.exportOffer.country | countryDescription}}
                    </h4>
                  </div>
                </div>
              </div>

              <div class="container-description-info">
                <span class="span-info" >
                  {{'ExportOffer.ExportOfferDetail.MarketCountry1Text' | translate}}:
                </span>
                <div class="flex align-items-center">
                  <div class="flex align-items-center">
                    <img [src]="'../../../../assets/img/flags/' + exportOfferInfo.exportOffer.marketCountryFirst?.code + '.png'" style="width: 18px" alt=""/>
                    <h4 class="small-data-info">
                      {{exportOfferInfo.exportOffer.marketCountryFirst ? (exportOfferInfo.exportOffer.marketCountryFirst | countryDescription) : getAvailableLabel()}}
                    </h4>
                  </div>
                </div>
              </div>

              <div class="container-description-info">
                <span class="span-info" >
                  {{'ExportOffer.ExportOfferDetail.MarketCountry2Text' | translate}}:
                </span>
                <div class="flex align-items-center">
                  <div class="flex align-items-center" *ngIf="exportOfferInfo.exportOffer.marketCountrySecond">
                    <img [src]="'../../../../assets/img/flags/' + exportOfferInfo.exportOffer.marketCountrySecond.code + '.png'" style="width: 18px" alt=""/>
                    <h4 class="small-data-info">
                      {{exportOfferInfo.exportOffer.marketCountrySecond ? (exportOfferInfo.exportOffer.marketCountrySecond | countryDescription) : getAvailableLabel()}}
                    </h4>
                  </div>
                  <div class="flex align-items-center"  *ngIf="!exportOfferInfo.exportOffer.marketCountrySecond">
                    <h4 class="small-data-info">
                      {{'ExportOffer.ExportOfferDetail.NoAvailable' | translate}}
                    </h4>
                  </div>
                </div>
              </div>

              <div class="container-description-info">
                <span class="span-info" >
                  {{'ExportOffer.ExportOfferDetail.MarketCountry3Text' | translate}}:
                </span>
                <div class="flex align-items-center">
                  <div class="flex align-items-center" *ngIf="exportOfferInfo.exportOffer.marketCountryThird">
                    <img [src]="'../../../../assets/img/flags/' + exportOfferInfo.exportOffer.marketCountryThird.code + '.png'" style="width: 18px" alt=""/>
                    <h4 class="small-data-info">
                      {{exportOfferInfo.exportOffer.marketCountryThird ? (exportOfferInfo.exportOffer.marketCountryThird | countryDescription) : getAvailableLabel()}}
                    </h4>
                  </div>
                  <div class="flex align-items-center" *ngIf="!exportOfferInfo.exportOffer.marketCountryThird">
                    <h4 class="small-data-info">
                      {{'ExportOffer.ExportOfferDetail.NoAvailable' | translate}}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p-toast></p-toast>
  </app-content-body>
</app-user-dashboard>
<app-footer-home></app-footer-home>

<p-dialog header="{{'PurchaseDestinationDialog.Header' | translate}}" [(visible)]="visibleDestinationConfirm" [style]="{width: '700px'}" [modal]="true">

  <form [formGroup]="destinationFormGroup">
    <div class="p-fluid formgrid mr-2 ml-2 grid">
      <div class="field col-12 md:col-6 lg:col-6" style="margin-top: 20px;">
        <label for="countryToId" style="font-weight: bolder; font-size: 12px;">{{'MarketplacePage.Spot.ImportToFieldPlaceholder' | translate}} *</label>
        <p-dropdown
          appendTo="body"
          id="countryToId"
          [options]="destinationCountries"
          formControlName="countryToId"
          optionLabel="name"
          optionValue="id"
          (onChange)="loadDestinationPorts()"
          [filter]="true"
          filterBy="name"
          [showClear]="false"
          placeholder="{{'MarketplacePage.Spot.ImportToFieldPlaceholder' | translate}}"
          class="p-inputtext-sm">
          <ng-template pTemplate="selectedItem">
            <div class="flex align-items-center gap-2" *ngIf="selectedCountryTo">
              <img [src]="'../../../../assets/img/flags/' + selectedCountryTo.code + '.png'" style="width: 18px" alt="flag"/>
              <div style="font-size: 12px;">{{ selectedCountryTo | countryDescription }}</div>
            </div>
          </ng-template>
          <ng-template let-country pTemplate="item">
            <div class="flex align-items-center gap-2">
              <img [src]="'../../../../assets/img/flags/' + country.code + '.png'" style="width: 18px" alt="flag"/>
              <div style="font-size: 12px;"> {{ country | countryDescription }}</div>
            </div>
          </ng-template>
        </p-dropdown>
        <small *ngIf="submit && destinationFormGroup.controls['countryToId'].invalid" class="p-error">{{'countryToId' | formErrorMessage: destinationFormGroup}}</small>
      </div>
      <div class="field col-12 md:col-6 lg:col-6" style="margin-top: 20px;">
        <label for="portToId" style="font-weight: bolder; font-size: 12px;">{{'MarketplacePage.Spot.ToPortFieldLabel' | translate}} *</label>
        <p-dropdown
          id="portToId"
          appendTo="body"
          [options]="destinationPorts"
          formControlName="portToId"
          optionLabel="name"
          optionValue="id"
          [filter]="true"
          filterBy="name"
          (onChange)="selectDestinationPort()"
          [showClear]="false"
          placeholder="{{'MarketplacePage.Spot.ToPortFieldLabel' | translate}}"
          emptyMessage="{{'MarketplacePage.Spot.EmptyResultsText' | translate}}"
          class="p-inputtext-sm">
          <ng-template let-port pTemplate="item">
              <div style="font-size: 12px;"> {{port.name}}</div>
          </ng-template>
        </p-dropdown>
          <small *ngIf="submit && destinationFormGroup.controls['portToId'].invalid" class="p-error">{{'portToId' | formErrorMessage: destinationFormGroup}}</small>
      </div>
    </div>
  </form>

  <ng-template pTemplate="footer">
    <div class="flex justify-content-end gap-2">
      <p-button [style]="{'background-color': '#8c2b87'}"  styleClass="p-button-sm" label="Cancelar" (click)="visibleDestinationConfirm=false">
      </p-button>
      <p-button
      [style]="{'color': '#8c2b87'}" label="Confirmar compra"
      [loading]="loading" styleClass="p-button-outlined p-button-sm"
      (click)="validateDestinationForm()">
      </p-button>
    </div>
  </ng-template>

</p-dialog>
