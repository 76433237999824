import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { SpeciesService } from 'src/app/application/service/SpeciesService';

import { Species } from 'src/app/domain/models/Species';
import { Routes } from 'src/app/infrastructure/routes';
import { SpeciesUtils } from 'src/app/ui/util/species-utils';

@Component({
  selector: 'app-admin-manage-species',
  templateUrl: './admin-manage-species.component.html',
  styleUrls: ['./admin-manage-species.component.css'],
  providers: [MessageService]
})
export class AdminManageSpeciesComponent implements OnInit {

  species: Species[] = []
  loading: boolean = false

  constructor(
    private _speciesService: SpeciesService,
    private _router: Router,
    private _messageService: MessageService
  ){

  }

  ngOnInit(): void {
    this.getSpecies()
  }

  getSpecies(){
    this.loading = true;
    this.species = [];
    this._speciesService.getAll().subscribe({
      next: (v) => {
        this.loading = false;
        this.species = v;
      },
      error: (e) => {
        this._messageService.add({
          severity: 'error', 
          summary: 'Accion fallido', 
          detail: 'Error al consultar especies' })
      },
      complete: () => {
        this.loading = false
      }
    })
  }

  getIconUrl(url: string){
    return SpeciesUtils.getImageIcon(url);
  }

  goToCreateSpecies(){
    this._router.navigate(['/' + Routes.adminSpeciesNew]);
  }
}
