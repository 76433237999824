import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { PalletGateway } from 'src/app/application/gateway/PalletGateway';
import { Delivery } from 'src/app/domain/models/Delivery';
import { ImportOrderDetail, ImportOrderDetailInformation } from 'src/app/domain/models/ImportOrder';
import { BasicUser } from 'src/app/domain/models/User';
import { Routes } from 'src/app/infrastructure/routes';
import { ImportOrderStatusEnum } from 'src/app/ui/util/order-utils';

@Component({
  selector: 'app-verify-pallets-page',
  templateUrl: './verify-pallets-page.component.html',
  styleUrls: ['./verify-pallets-page.component.css'],
  providers: [MessageService]
})
export class VerifyPalletsPageComponent implements OnInit {
  breadcrumbMenuItems: MenuItem[] = []
  breadcrumbHome: MenuItem = {}

  orderDetailId: any | undefined
  exportOfferId: number | undefined

  dto: ImportOrderDetailInformation | undefined

  profile: BasicUser | undefined

  constructor(private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _messageService: MessageService,
    private _palletService: PalletGateway,
    private _authService: AuthGateway,
    public translate: TranslateService) {
      this._activatedRoute.params.subscribe(params => {
        this.orderDetailId = params['orderDetailId']
        this.exportOfferId = params['offerId']

        this.loadImportOrderDetailInformation()
      })
  }

  ngOnInit(): void {
    this.profile = this._authService.getUser()

    let breadcrumbMenuItemLabel1 = 'Import order detail'
    let breadcrumbMenuItemLabel2 = 'Verify pallets'
    let breadcrumbHomeItemLabel = 'My import orders'

    if(this.profile) {
      switch(this.profile.lang) {
        case 'en':
          breadcrumbMenuItemLabel1 = 'Import order detail'
          breadcrumbMenuItemLabel2 = 'Verify pallets'
          breadcrumbHomeItemLabel = 'My import orders'
          break
        case 'es':
          breadcrumbMenuItemLabel1 = 'Detalle de orden de importación'
          breadcrumbMenuItemLabel2 = 'Verificar  pallets'
          breadcrumbHomeItemLabel = 'Ordenes de importación'
          break
      }
    }

    this.breadcrumbMenuItems = [
      {label: breadcrumbMenuItemLabel1, command: () => { this.goToImportOrderPage() }},
      {label: breadcrumbMenuItemLabel2, disabled: true}
    ]
    this.breadcrumbHome = {label: breadcrumbHomeItemLabel, command: () => this.goToImportOrderList() }

    this.loadImportOrderDetailInformation()
  }

  loadImportOrderDetailInformation() {
    if(this.orderDetailId) {
      this._palletService.getByImportOrderDetailId(this.orderDetailId).subscribe({
        next: (v) => {
          this.dto = v
        },
        error: (e) => {
          console.log(e)
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ImportOrderDetail.SingleRetrieveError') });
        },
        complete: () => console.info('Order Detail query successfully')
      })
    }
  }

  goToImportOrderPage() {
    if(this.dto) {
      this._router.navigate(['/' + Routes.userOrderDetailPage, this.dto.importOrderDetail.importOrderId])
    }
  }

  goToImportOrderList() {
    if(this.dto) {
      const status = this.dto.importOrderDetail.importOrderStatus
      // let route = Routes.userImportOrdersPending
      // switch(status) {
      //   case ImportOrderStatusEnum.PendingVerifyPallets:
      //     route = Routes.userImportOrdersPending
      //     break
      //   case ImportOrderStatusEnum.AdminPalletsVerified:
      //     route = Routes.userImportOrdersApproved
      //     break
      //   case ImportOrderStatusEnum.ImporterOrderConfirmed:
      //     route = Routes.userImportOrdersConfirmed
      //     break
      //   case ImportOrderStatusEnum.ImporterPaymentConfirmed:
      //     route = Routes.userImportOrdersConfirmed
      //     break
      //   case ImportOrderStatusEnum.Created:
      //     route = Routes.userImportOrdersCreated
      //     break
      // }
      // this._router.navigate(['/' + route])
      this._router.navigate(['/' + Routes.userImportOrdersCurrent])
    }
  }
}
