<app-admin-dashboard>
  <p-card>
    <ng-template pTemplate="header">
      <div class="flex justify-content-between flex-wrap align-items-center" style="padding: 15px;">
        <h2>Órdenes de compra</h2>
      </div>
      <div>
        <p-tabMenu [model]="tabMenuItems" [activeItem]="activeItem" [style]="{padding: '15px'}"></p-tabMenu>
      </div>
    </ng-template>
    <ng-template pTemplate="body">
      <p-table [value]="orders"
        dataKey="importOrder.id"
        [paginator]="true"
        [rows]="10"
        [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[10, 25, 50]"
        [loading]="loading"
        [globalFilterFields]="['sequence']"
        >
        <ng-template pTemplate="header">
          <tr>
            <th>
              <div class="flex justify-content-center align-items-center">
                ID
                <p-columnFilter type="text" field="sequence" display="menu"></p-columnFilter>
              </div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">
                Tipo de orden
                <p-columnFilter field="importOrder.isClosedSale" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                  <ng-template pTemplate="header">
                      <div class="px-3 pt-3 pb-0">
                          <span class="font-bold">Tipo de orden</span>
                      </div>
                  </ng-template>
                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                      <p-multiSelect [options]="orderTypeOptions" placeholder="Any" (onChange)="filter($event.value)" optionLabel="label" optionValue="value" option>
                      </p-multiSelect>
                  </ng-template>
                </p-columnFilter>
              </div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">
                Importador
              </div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">
                Tipo de producto
              </div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">
                Producto
              </div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">
                Total
              </div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">
                DateTime Creación
              </div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">
                Tiempo restante
              </div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">
                Acciones
              </div>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-order>
          <tr>
            <td>
              <div class="flex justify-content-center align-items-center">{{order.importOrder.sequence}}</div>
            </td>
            <td>
              <div class="flex justify-content-center align-items-center">{{order.importOrder.isClosedSale ? 'Venta directa' : 'Marketplace'}}</div>
            </td>
            <td>
              <div class="flex justify-content-center align-items-center">{{order.importOrder.importer.companyName}}</div>
            </td>
            <td>
              <!-- <div class="flex justify-content-center align-items-center">{{order.importOrder.isClosedSale ? 'Venta directa' : 'Marketplace'}}</div> -->
              <div class="flex justify-content-center align-items-center">{{order.importOrder.isFrozenFruit ? 'Fruta congelada' : 'Fruta fresca'}}</div>
            </td>
            <!-- <td>
            </td> -->
            <td>
              <div class="flex flex-column justify-content-center align-items-center">
                <img [src]="getImageUrl(order)" style="border-radius: 7px; width: 100%; max-width: 100px; height: auto;" alt=""/>
                <div>
                  <ng-container *ngFor="let d of order.genericDetails">
                    <p>{{d.speciesNameEs}} - {{d.varietyName}}</p>
                  </ng-container>
                </div>
              </div>
            </td>
            <td>
              <div *ngIf="!order.importOrder.isClosedSale" class="flex justify-content-center align-items-center">
                <span style="font-weight: bold;">{{getOrderTotalPrice(order.genericDetails) | currency: 'USD':'US$'}}</span>
              </div>
              <div *ngIf="order.importOrder.isClosedSale" class="flex justify-content-center align-items-center">
                <span style="font-weight: bold;">{{getOrderPalletPrice(order.genericDetails) | currency: 'USD':'US$'}}</span>
              </div>
            </td>
            <td>
              <div class="flex justify-content-center align-items-center">
                {{order.importOrder.created | date: 'dd-MM-yyyy HH:mm'}}
              </div>
            </td>
            <td>
              <div class="flex justify-content-center flex-column align-items-center" *ngIf="activeItem.label === 'Pendientes confirmar pallets'">
                <p>Confirmar pallets antes de: </p>
                <div>
                  <app-timer [orderDateTarget]="order.importOrder.pendingPalletDate" [showSeconds]="false"></app-timer>
                </div>
              </div>
              <div class="flex justify-content-center flex-column align-items-center" *ngIf="activeItem.label === 'Compras confirmada' && order.importOrder.status == 3">
                <p>Confirmar pago antes de: </p>
                <div>
                  <app-timer [orderDateTarget]="order.importOrder.pendingPaymentDate" [showSeconds]="false"></app-timer>
                </div>
              </div>
            </td>
            <td>
              <div class="flex justify-content-center align-items-center gap-4">
                <p-button icon="pi pi-search" pTooltip="Ver detalle de órden" (onClick)="goToOrderDetailPage(order.importOrder.guid)" />
                <p-button icon="pi pi-upload" pTooltip="Loading data" (onClick)="goToDelivery(order.importOrder.guid)" *ngIf="(order.importOrder.status===3 || order.importOrder.status===4)"></p-button>
                <p-button icon="pi pi-file-edit" pTooltip="Operación" (onClick)="goToOperation(order.importOrder.guid)" *ngIf="(order.importOrder.status===3 || order.importOrder.status===4)  && paidRegistered"></p-button>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </ng-template>
  </p-card>
</app-admin-dashboard>
