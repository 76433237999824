import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ExportOfferDocument, NewExportOfferDocument } from "src/app/domain/models/ExportOfferDocument";

@Injectable({providedIn: 'root'})
export abstract class ExportOfferDocumentGateway {
  abstract create(document: NewExportOfferDocument): Observable<ExportOfferDocument>
  abstract update(document: ExportOfferDocument): Observable<ExportOfferDocument>
  abstract delete(exportOfferDocumentId: number): Observable<any>
  abstract getAllByExportOffer(exportOfferId: number): Observable<ExportOfferDocument[]>
}
