import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { ProfileGateway } from 'src/app/application/gateway/ProfileGateway';
import { Profile } from 'src/app/domain/models/Profile';

@Component({
  selector: 'app-user-notifications-config',
  templateUrl: './user-notifications-config.component.html',
  styleUrls: ['./user-notifications-config.component.css'],
  providers: [MessageService]
})
export class UserNotificationsConfigComponent implements OnInit {
  newProducts: boolean | undefined = false
  newRequeriments: boolean | undefined = false
  submit: boolean = false
  loading: boolean = false

  profile: Profile | undefined

  constructor(private _messageService: MessageService,
    private _authService: AuthGateway,
    private _profileService: ProfileGateway,
    public translate: TranslateService) {

  }

  ngOnInit(): void {
    this._profileService.getByUsername().subscribe({
      next: (v) => {
        this.profile = v
        this.loadForm()
      },
      error: (e) => {
        console.log(e)
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.ProfileMessageService.SingleRetrieveError') });
      },
      complete: () => console.info('Profile query successfully')
    })
  }

  loadForm() {
    if(this.profile) {
      this.newProducts = this.profile.newExportOffers
      this.newRequeriments = this.profile.newImportRequirements
    }
  }

  save() {
    if(this.profile) {
      this.profile.newExportOffers = this.newProducts
      this.profile.newImportRequirements = this.newRequeriments
      this.profile.updaterUsername = this._authService.getUsername()
      this._profileService.update(this.profile).subscribe({
        next: (v) => {
          this.profile = v
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: this.translate.instant('MessageService.ProfileMessageService.EditSuccess') });
          this.loadForm()
          this.submit = false
          this.loading = false
        },
        error: (e) => {
          this.loading = false
          console.log(e)
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ProfileMessageService.EditError') });
        },
        complete: () => console.info('Profile query successfully')
      })
    } else {
      this.loading = false
    }
  }
}
