import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { UserGateway } from 'src/app/application/gateway/UserGateway';

@Component({
  selector: 'app-reset-password-page',
  templateUrl: './reset-password-request-page.component.html',
  styleUrls: ['./reset-password-request-page.component.css'],
  providers: [MessageService]
})
export class ResetPasswordRequestPageComponent {

  submit: boolean = false
  loading: boolean = false
  success: boolean = false

  formGroup: FormGroup = this._formBuilder.group({
    email: ['', [Validators.required, Validators.email]]
  })

  constructor(private _formBuilder: FormBuilder,
    private _messageService: MessageService,
    private _userService: UserGateway,
    public translate: TranslateService) {

  }

  onSubmit() {
    this.loading = true
    this.submit = true

    if(this.formGroup.valid) {
      let request = {
        username: this.formGroup.controls['email'].value
      }
      this._userService.resetPasswordRequestToken(request).subscribe({
        next: (v) => {
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: this.translate.instant('ResetPasswordRequestPage.SuccessMessage'), })
          this.success = true
          this.submit = false
          this.loading = false
        },
        error: (e) => {
          this.success = false
          this.loading = false
          console.log(e)
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('ResetPasswordRequestPage.ErrorMessage') });
        },
        complete: () => {
          console.info('Reset password request successfully')
        }
      })
    }
    else {
      this.loading = false
    }
  }

  getErrorMessage(fieldName: string): string {
    if (this.formGroup.controls[fieldName].hasError('required')) {
      return this.translate.instant('FormErrorMessage.FieldValueRequired')
    }
    if (this.formGroup.controls[fieldName].hasError('email')) {
      return this.translate.instant('FormErrorMessage.EmailFieldEmailFormatError')
    }
    return ''
  }
}
