import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem, MessageService, ConfirmationService, ConfirmEventType } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { Routes } from 'src/app/infrastructure/routes';
import { BasicUser } from 'src/app/domain/models/User';
import { TranslateService } from '@ngx-translate/core';
import { ImportOrderDetail, ImportOrderInformation } from 'src/app/domain/models/ImportOrder';
import { ImportOrderDetailGateway } from 'src/app/application/gateway/ImportOrderDetailGateway';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Payment, PaymentCreateRequest, PaymentsInformation, PaymentUploadFile } from 'src/app/domain/models/Payment';
import { PaymentGateway } from 'src/app/application/gateway/PaymentGateway';
import { ProfileGateway } from 'src/app/application/gateway/ProfileGateway';
import { ProfileForQcPayment } from 'src/app/domain/models/Profile';
import { PaymentQualityControlService } from 'src/app/application/service/PaymentQualityControlService';
import { CreatePaymentQualityControlRequest } from 'src/app/domain/models/PaymentQualityControl';


@Component({
  selector: 'app-qc-order-payment',
  templateUrl: './qc-order-payment.component.html',
  styleUrls: ['./qc-order-payment.component.css'],
  providers: [MessageService, ConfirmationService]

})
export class QcOrderPaymentComponent {
  breadcrumbMenuItems: MenuItem[] = []
  breadcrumbHome: MenuItem = {}

  orderId: any | undefined
  order: ImportOrderInformation | undefined
  profile: BasicUser | undefined

  loading: boolean = false


  taxes: number = 19
  currency: string = "USD"

  qcCompanyName: string = "Delta Quality"
  qcCompanyPrice: number = 100000

  iconProduct: string = 'icon'

  importOrderInfo: ImportOrderDetail | undefined

  transBankIsChecked: boolean = false
  fruztyPayIsChecked: boolean = true

  showWarning: boolean = false;


  visibleCreatePaymentDialog: boolean = false
  submit: boolean = false
  formGroup: FormGroup = this._formBuilder.group({
    swiftId: ['', Validators.required],
    amount: ['', Validators.required],
    description: [null]
  })
  uploadedFiles: any[] = []
  paymentsInformation: PaymentsInformation | undefined
  importOrderId: any
  payments: Payment[] = []
  limitPaymentDate: Date | undefined


  profileData: ProfileForQcPayment = {
    contactName: "",
    companyName:"",
    contactPhone:""
  }

  constructor(private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _messageService: MessageService,
    private _authService: AuthGateway,
    public translate: TranslateService,
    private _importOrderDetailService: ImportOrderDetailGateway,

    private _formBuilder: FormBuilder,
    private _confirmationService: ConfirmationService,
    private _paymentService: PaymentGateway,
    private _paymentQualityControlService: PaymentQualityControlService,
    private _profileService: ProfileGateway


    ) {
      this._activatedRoute.params.subscribe(params => {
        this.orderId = params['orderId']
      })

  }


  ngOnInit(): void {
    this.loadInfo()
    this.loadProfileData();


    this.profile = this._authService.getUser()

    let breadcrumbMenuItemLabel1 = 'Import order detail'
    let breadcrumbMenuItemLabel2 = 'Verify pallets'
    let breadcrumbHomeItemLabel = 'My import orders'
    let breadcrumbMenuItemLabel3 = 'Quality control payment details'
    let breadcrumbMenuItemLabel4 = 'Confirm quality control payment'

    if(this.profile) {
      switch(this.profile.lang) {
        case 'en':
          breadcrumbMenuItemLabel1 = 'Import order detail'
          breadcrumbMenuItemLabel2 = 'Verify pallets'
          breadcrumbHomeItemLabel = 'My import orders'
          breadcrumbMenuItemLabel3 = 'Quality control payment details'
          breadcrumbMenuItemLabel4 = 'Confirm quality control payment'
          
          break
          case 'es':
            breadcrumbMenuItemLabel1 = 'Detalle de orden de importación'
            breadcrumbMenuItemLabel2 = 'Verificar  pallets'
            breadcrumbMenuItemLabel3 = 'Detalle pago control de calidad'
            breadcrumbMenuItemLabel4 = 'Confirmar el pago del control de calidad'
            breadcrumbHomeItemLabel = 'Ordenes de importación'
          break
      }
    }

    this.breadcrumbMenuItems = [
      {label: breadcrumbMenuItemLabel1, command: () => { this.goToImportOrderPage() }},
      {label: breadcrumbMenuItemLabel2, command: () => { this.goToVerifyPalletsPage() }},
      {label: breadcrumbMenuItemLabel3, command: () => { this.goToQcPaymentDetail() }},
      {label: breadcrumbMenuItemLabel4, disabled: true}
    ]
    this.breadcrumbHome = {label: breadcrumbHomeItemLabel, command: () => this.goToImportOrderList() }

  }

  loadInfo() {
    if(this.orderId) {
      this.profile = this._authService.getUser()
      this.loadImportOrderDetailInfo()
    }
  }
  
  loadProfileData() {
    if(this.orderId) {
      this._profileService.getByUsername().subscribe({
        next: (v) => {
          this.profileData.contactName = v.contactName
          this.profileData.companyName = v.companyName
          this.profileData.contactPhone = v.contactPhone
        },
        error: (e) => {
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.QcOrderDetail.LoadInfoError') });
        }
      })
      this.loadImportOrderDetailInfo()
    }
  }

  loadImportOrderDetailInfo() {
    if(this.orderId) {
      this._importOrderDetailService.getQcPayment(this.orderId).subscribe({
        next: (v) => {
          this.importOrderInfo = v
        },
        error: (e) => {
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.QcOrderDetail.LoadInfoError') });
        }
      })
    }
  }

  goToImportOrderPage() {
    if(this.importOrderInfo) {
      this._router.navigate(['/' + Routes.userOrderDetailPage, this.importOrderInfo.importOrderId])
    }
  }

  goToVerifyPalletsPage() {
    if(this.importOrderInfo) {
      this._router.navigate(['/' + Routes.verifyPalletsPage, this.orderId])
    }
  }

  goToQcPaymentDetail()
  {
    this._router.navigate(['/' + Routes.qcOrderDetailPage, this.orderId])
  }

  navigateToOrderDetailPage() {
    this._router.navigate(['/' + Routes.userImportOrdersCurrent])

  }

  onChangeFruztyPay() {
    if (this.fruztyPayIsChecked) {
      this.showWarning = false; // Ocultar advertencia si el botón está habilitado
    }
  }

  editContactInformationHandler() {
    this.openModalEditContactInformation()
  }

  btnPaymentHandler() {
    if (this.fruztyPayIsChecked) {
      this.showWarning = false; // Ocultar advertencia si el botón está habilitado
      this.openModalConfirmation()
    } else {
      this.showWarning = true; // Mostrar advertencia si el botón está deshabilitado
    }
  }

  // Método para abrir el modal
  openModalConfirmation() {
    // Lógica para abrir el modal
    const modal = document.getElementById('modalConfirmation');
    if (modal) {
      modal.style.display = 'flex';
    }

  }

  // Método para cerrar el modal
  closeModalConfirmation() {
    const modal = document.getElementById('modalConfirmation');
    if (modal) {
      modal.style.display = 'none';
    }
  }
  
  openModalEditContactInformation() {
    const modal = document.getElementById('modalEditContactInformation');
    if (modal) {
      modal.style.display = 'flex';
    }
  }

  closeModalEditContactInformation() {
    const modal = document.getElementById('modalEditContactInformation');
    if (modal) {
      modal.style.display = 'none';
    }
  }

  // Método para abrir el modal
  openModalTransBank() {
    if (this.transBankIsChecked) {
      // Lógica para abrir el modal
      const modal = document.getElementById('modalTransBank');
      if (modal) {
        modal.style.display = 'block';
      }
    }
  }

  // Método para cerrar el modal
  closeModalTransBank() {
    const modal = document.getElementById('modalTransBank');
    if (modal) {
      modal.style.display = 'none';
    }
  }


  formatNumber(num: number) {
    if (num > 1000) {
        return num.toLocaleString();
    } else {
        return num.toString();
    }
  }

  padNumberWithZeros(num: number) {
    let numStr = num.toString();
    while (numStr.length < 7) {
        numStr = '0' + numStr;
    }
    return numStr;
  }

  goToImportOrderList() {
    if(this.order) {
      const status = this.order?.importOrder.status
      // let route = Routes.userImportOrdersPending
      // switch(status) {
      //   case ImportOrderStatusEnum.PendingVerifyPallets:
      //     route = Routes.userImportOrdersPending
      //     break
      //   case ImportOrderStatusEnum.AdminPalletsVerified:
      //     route = Routes.userImportOrdersApproved
      //     break
      //   case ImportOrderStatusEnum.ImporterOrderConfirmed:
      //     route = Routes.userImportOrdersConfirmed
      //     break
      //   case ImportOrderStatusEnum.ImporterPaymentConfirmed:
      //     route = Routes.userImportOrdersConfirmed
      //     break
      //   case ImportOrderStatusEnum.Created:
      //     route = Routes.userImportOrdersCreated
      //     break
      // }
      this._router.navigate(['/' + Routes.userImportOrdersCurrent])
    }
  }


  openCreatePaymentDialog() {
    this.resetCreatePaymentForm()
    this.visibleCreatePaymentDialog = true
    if(this.importOrderInfo){
      let price = this.importOrderInfo.qcGeneratedPrice ? this.importOrderInfo.qcGeneratedPrice : 0;
      this.formGroup.controls['amount'].setValue( price + this.importOrderInfo.qcTaxPrice);
    }
  }

  createPayment() {
    if(this.formGroup.valid) {
      this._confirmationService.confirm({
        message: this.translate.instant('MessageService.Payment.ConfirmPaymentDialogMessage'),
        header: this.translate.instant('MessageService.Payment.ConfirmPaymnetDialogHeader'),
        icon: 'pi pi-info-circle',
        acceptLabel: this.translate.instant('MessageService.Payment.ButtonAcceptLabel'),
        rejectLabel: this.translate.instant('MessageService.Payment.ButtonRejectLabel'),
        accept: () => {
          const request: CreatePaymentQualityControlRequest = {
            importOrderDetailId: this.orderId,
            swiftId: this.formGroup.controls['swiftId'].value,
            value: this.formGroup.controls['amount'].value,
            description: this.formGroup.controls['description'].value,
            pdf: this.uploadedFiles[0],
            paymentMethod: this.fruztyPayIsChecked ? 1 : 0,
            contactName: this.profileData.contactName,
            companyName: this.profileData.companyName,
            contactPhone: this.profileData.contactPhone
          }

          this._paymentQualityControlService.create(request).subscribe({
            next: (v) => {
              this.submit = false
              this.loading = false
              this.visibleCreatePaymentDialog = false
              this._messageService.add({ severity: 'success',
                summary: this.translate.instant('MessageService.Summary.SuccessAction'),
                detail: this.translate.instant('MessageService.Payment.CreateSuccess') });
              this.openModalConfirmation()
            },
            error: (e) => {
              this.submit = false
              this.loading = false
              this.visibleCreatePaymentDialog = false
              this._messageService.add({ severity: 'error',
                summary: this.translate.instant('MessageService.Summary.FailedAction'),
                detail: this.translate.instant('MessageService.Payment.CreateError') + e.error });
            },
            complete: () => {
              this.loading = false
              this.submit = false
            }
          })
        },
        reject: (type: any) => {
          switch (type) {
            case ConfirmEventType.REJECT:
              this.resetCreatePaymentForm()
              break;
            case ConfirmEventType.CANCEL:
              this.resetCreatePaymentForm()
              break;
          }
        }
      })
    }
    else {
      this.loading = false
    }
  }

  resetCreatePaymentForm() {
    this.formGroup.controls['swiftId'].setValue(null)
    this.formGroup.controls['amount'].setValue(null)
    this.formGroup.controls['description'].setValue(null)
    this.submit = false
    this.loading = false
    this.formGroup.reset()
    this.uploadedFiles = [];
  }


  onSelect(event: any) {
    this.uploadedFiles = []
    for(let file of event.files) {
        this.uploadedFiles.push(file);
    }
  }

  getErrorMessage(fieldName: string): string {
    if (this.formGroup.controls[fieldName].hasError('required')) {
      return this.translate.instant('FormErrorMessage.FieldValueRequired')
    }
    if (this.formGroup.controls[fieldName].hasError('email')) {
      return this.translate.instant('FormErrorMessage.EmailFieldEmailFormatError')
    }
    return ''
  }
}
