import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CreateProfileSpecies, Exporter, ExporterImporterCreate, ExporterImportersDto, Importer, Profile, ProfileContactInfo, ProfileCreateRequest, TwoFactorCode } from "src/app/domain/models/Profile";
import { Species } from "src/app/domain/models/Species";
import { BasicUser } from "src/app/domain/models/User";

@Injectable({providedIn: 'root'})
export abstract class ProfileGateway {
    abstract create(request: ProfileCreateRequest): Observable<Profile>
    abstract update(person: Profile): Observable<Profile>
    abstract updateContactInfo(request: ProfileContactInfo): Observable<any>
    abstract getContactInfo(): Observable<ProfileContactInfo>
    abstract getByUsername(): Observable<Profile>
    abstract getById(profileId: number): Observable<Profile>
    abstract validateSII(rut: string): Observable<any>
    abstract updateSpecies(createProfileSpecies: CreateProfileSpecies): Observable<Species[]>
    abstract deactivate(): Observable<any>
    abstract getProfileType(): Observable<any>
    abstract getAll(): Observable<Profile[]>
    abstract toogleIsActivate(profile: Profile): Observable<Profile>
    abstract getAllImportersByExporterId(): Observable<Importer[]>
    abstract getAllExportersByImporterId(): Observable<Exporter[]>
    abstract toggleTwoAuth(twoFactorCode: TwoFactorCode): Observable<any>
    abstract resendCode(username: string, password: string): Observable<any>
    abstract getBasicUser(): Observable<BasicUser>
    abstract getAllImportersForExporterAsociation(exporterId: number): Observable<ExporterImportersDto>
    abstract toogleExporterImporterAsociation(request: ExporterImporterCreate): Observable<any>
    abstract changeLanguaje(languaje: string): Observable<any>
    abstract sendValidationToken(profileId: number): Observable<any>
}
