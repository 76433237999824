
<p-card>
  <ng-template pTemplate="header">
    <div class="flex justify-content-center flex-wrap align-items-center" style="padding: 15px;">
      <h2>{{'InvitationCodePage.MessageRequestCode' | translate}}</h2>
    </div>
  </ng-template>
  <form [formGroup]="formGroup">
    <div class="flex flex-wrap flex-column justify-content-center align-items-center">
      <div class="w-full md:w-10 lg:w-6 p-fluid formgrid grid">
        <!-- <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
          <label for="language" style="font-weight: bold;">{{'InvitationCodePage.LanguageFieldLabel' | translate }} *</label>
          <p-dropdown
            [options]="langs"
            inputId="language"
            optionLabel="label"
            optionValue="lang"
            formControlName="language"
            (onChange)="switchLang()"
            [showClear]="false"
            placeholder="{{ 'InvitationCodePage.LanguageFieldPlaceholder' | translate}}">
            <ng-template pTemplate="selectedItem">
              <div class="flex align-items-center">
                <img [src]="'../../../../assets/img/flags/' + lang.icon" style="width: 18px" alt="" *ngIf="lang"/>
                <span>&nbsp;{{lang?.label}}</span>
              </div>
            </ng-template>
            <ng-template let-lang pTemplate="item">
              <div class="flex align-items-center">
                <img [src]="'../../../../assets/img/flags/' + lang.icon" style="width: 18px" alt=""/>
                <span>&nbsp;{{lang.label}}</span>
              </div>
            </ng-template>
          </p-dropdown>
          <small *ngIf="submit && formGroup.controls['language'].invalid" class="p-error">{{getErrorMessage('language')}}</small>
        </div> -->
        <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
          <label for="fullname" style="font-weight: bold;">{{'InvitationCodePage.FullNameLabel' | translate }} *</label>
          <input id="fullname" formControlName="fullname" type="text" maxlength="120" required pInputText placeholder="{{'InvitationCodePage.FullNameFieldPlaceholder' | translate }}" [(ngModel)]="fullname"/>
          <small *ngIf="submit && formGroup.controls['fullname'].invalid" class="p-error">{{getErrorMessage('fullname')}}</small>
        </div>
        <div class="field col-12 md:col-6 lg:col-6" style="margin-bottom: 20px;">
          <label for="email" style="font-weight: bold;">{{'InvitationCodePage.EmailFieldLabel' | translate }} *</label>
          <input id="email" formControlName="email" type="text" maxlength="50" required pInputText placeholder="{{'InvitationCodePage.EmailFieldPlaceholder' | translate }}" [(ngModel)]="email"/>
          <small *ngIf="submit && formGroup.controls['email'].invalid" class="p-error">{{getErrorMessage('email')}}</small>
        </div>
        <div class="field col-12 md:col-12 lg:col-12" style="margin-bottom: 20px;">
          <div class=" w-full">
            <p-button class="p-button p-button-primary" (onClick)="generateCode()" [loading]="loading">{{'InvitationCodePage.RequestCode' | translate}}</p-button>
          </div>
        </div>
        <div class="field col-12 md:col-12 lg:col-12" style="margin-bottom: 20px;" *ngIf="showBackLogin">
          <div class=" w-full">
            <p-button label="{{'InvitationCodePage.BackButton' | translate}}" [disabled]="loading" styleClass="p-button-outlined" (onClick)="onCancel()"/>
          </div>
        </div>
      </div>

    </div>
  </form>
</p-card>
