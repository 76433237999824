<app-user-dashboard [userMenuActiveItemIndex]="0" [activeItem]="'corporative'">
  <p-card *ngIf="user">
    <ng-template pTemplate="header">
      <div class="flex justify-content-between flex-wrap align-items-center" style="padding: 15px;">
        <h1>{{'UserPages.UserPanel.PageTitle' | translate}}</h1>
      </div>
    </ng-template>
    <div class="grid">
      <div class="col-4">
        <p-card>
          <div class="flex justify-content-center align-items-center flex-column">
            <h2>{{'UserPages.UserPanel.ContactInfoLabel' | translate}}</h2>
            <i class="pi pi-user-edit" style="font-size: 5rem; color: #682172;"></i>
            <p-button label="{{'UserPages.UserPanel.EditButtonLabel' | translate}}" icon="pi pi-pencil" [style]="{'margin-top': '20px'}" (onClick)="goToContactInfoPage()"></p-button>
          </div>
        </p-card>
      </div>
      <div class="col-4">
        <p-card>
          <div class="flex justify-content-center align-items-center flex-column">
            <h2>{{'UserPages.UserPanel.CompanyInfoLabel' | translate}}</h2>
            <i class="pi pi-sitemap" style="font-size: 5rem; color: #682172;"></i>
            <p-button label="{{'UserPages.UserPanel.EditButtonLabel' | translate}}" icon="pi pi-pencil" [style]="{'margin-top': '20px'}" (onClick)="goToCompanyInfoPage()"></p-button>
          </div>
        </p-card>
      </div>
      <div class="col-4" *ngIf="user.profileType == 'Importer'">
        <p-card>
          <div class="flex justify-content-center align-items-center flex-column">
            <h2>{{'UserPages.UserPanel.ConsigneeLabel' | translate}}</h2>
            <i class="pi pi-users" style="font-size: 5rem; color: #682172;"></i>
            <p-button label="{{'UserPages.UserPanel.EditButtonLabel' | translate}}" icon="pi pi-pencil" [style]="{'margin-top': '20px'}" (onClick)="goToConsigneesPage()"></p-button>
          </div>
        </p-card>
      </div>
      <div class="col-4" *ngIf="user.profileType == 'Importer'">
        <p-card>
          <div class="flex justify-content-center align-items-center flex-column">
            <h2>{{'UserPages.UserPanel.NotifyLabel' | translate}}</h2>
            <i class="pi pi-users" style="font-size: 5rem; color: #682172;"></i>
            <p-button label="{{'UserPages.UserPanel.EditButtonLabel' | translate}}" icon="pi pi-pencil" [style]="{'margin-top': '20px'}" (onClick)="goToNotifiesPage()"></p-button>
          </div>
        </p-card>
      </div>
      <div class="col-4" *ngIf="user.profileType == 'Exporter' || user.profileType == 'Importer'">
        <p-card>
          <div class="flex justify-content-center align-items-center flex-column">
            <h2>{{'UserPages.UserPanel.FavoriteSpeciesLabel' | translate}}</h2>
            <span class="material-symbols-outlined" style="font-size: 5rem; color: #682172;">nutrition</span>
            <p-button label="{{'UserPages.UserPanel.EditButtonLabel' | translate}}" icon="pi pi-pencil" [style]="{'margin-top': '20px'}" (onClick)="goToUserProducts()"></p-button>
          </div>
        </p-card>
      </div>
      <div class="col-4" *ngIf="user.profileType == 'Exporter'">
        <p-card>
          <div class="flex justify-content-center align-items-center flex-column">
            <h2>{{'UserPages.UserPanel.FridgeStorageLabel' | translate}}</h2>
            <i class="pi pi-fw pi-truck" style="font-size: 5rem; color: #682172;"></i>
            <p-button label="{{'UserPages.UserPanel.EditButtonLabel' | translate}}" icon="pi pi-pencil" [style]="{'margin-top': '20px'}" (onClick)="goToUserFridgestorages()"></p-button>
          </div>
        </p-card>
      </div>
      <div class="col-4" *ngIf="user.profileType == 'Exporter'">
        <p-card>
          <div class="flex justify-content-center align-items-center flex-column">
            <h2>{{'UserPages.UserPanel.ProductCertificates' | translate}}</h2>
            <i class="pi pi-fw pi-verified" style="font-size: 5rem; color: #682172;"></i>
            <p-button label="{{'UserPages.UserPanel.EditButtonLabel' | translate}}" icon="pi pi-pencil" [style]="{'margin-top': '20px'}" (onClick)="goToUserCertificates()"></p-button>
          </div>
        </p-card>
      </div>
      <div class="col-4">
        <p-card>
          <div class="flex justify-content-center align-items-center flex-column">
            <h2>{{'UserPages.UserPanel.SecuritySettings' | translate}}</h2>
            <i class="pi pi-lock" style="font-size: 5rem; color: #682172;"></i>
            <p-button label="{{'UserPages.UserPanel.EditButtonLabel' | translate}}" icon="pi pi-pencil" [style]="{'margin-top': '20px'}" (onClick)="goToSecurityOptions()"></p-button>
          </div>
        </p-card>
      </div>
    </div>
  </p-card>
</app-user-dashboard>
