<app-admin-dashboard>
  <p-card>
    <h2>Crear usuario</h2>
    <div id="form" *ngIf="!success">
      <form [formGroup]="formGroup">
        <p-fieldset legend="Información de contacto">
          <div class="p-fluid formgrid grid">
            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
              <label for="profileType" style="font-weight: bold;">Perfil *</label>
              <p-dropdown inputId="profileType" formControlName="profileType" [options]="profileTypeOptions" optionLabel="label" optionValue="value" placeholder="Seleccione un perfil"></p-dropdown>
              <small *ngIf="submit && formGroup.controls['profileType'].invalid" class="p-error">{{getErrorMessage('profileType')}}</small>
            </div>
            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;"></div>
            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
              <label for="contactName" style="font-weight: bold;">Nombre de contacto *</label>
              <input id="contactName" formControlName="contactName" type="text" required pInputText placeholder="Nombre de contacto"/>
              <small *ngIf="submit && formGroup.controls['contactName'].invalid" class="p-error">{{getErrorMessage('contactName')}}</small>
            </div>
            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
              <label for="contactLastName" style="font-weight: bold;">Apellido de contacto *</label>
              <input id="contactLastName" formControlName="contactLastName" type="text" required pInputText placeholder="Nombre de contacto"/>
              <small *ngIf="submit && formGroup.controls['contactLastName'].invalid" class="p-error">{{getErrorMessage('contactLastName')}}</small>
            </div>
            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
              <label for="contactEmail" style="font-weight: bold;">Correo electrónico (username) *</label>
              <input id="contactEmail" formControlName="contactEmail" type="text" pInputText placeholder="Correo electrónico de contacto"/>
              <small *ngIf="submit && formGroup.controls['contactEmail'].invalid" class="p-error">{{getErrorMessage('contactEmail')}}</small>
            </div>
            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
              <label for="country" style="font-weight: bold;">País *</label>
              <p-dropdown id="contactCountry"
                formControlName="contactCountryId"
                [options]="countries"
                optionValue="id"
                optionLabel="name"
                [filter]="true"
                filterBy="name"
                [showClear]="true"
                placeholder="Seleccione un país">
              </p-dropdown>
              <small *ngIf="submit && formGroup.controls['contactCountryId'].invalid" class="p-error">{{getErrorMessage('contactCountryId')}}</small>
            </div>
            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
              <label for="contactCity" style="font-weight: bold;">Ciudad </label>
              <input id="contactCity" formControlName="contactCity" type="text"  pInputText placeholder="Ciudad"/>
              <small *ngIf="submit && formGroup.controls['contactCity'].invalid" class="p-error">{{getErrorMessage('contactCity')}}</small>
            </div>
            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
              <label for="contactAddress" style="font-weight: bold;">Dirección </label>
              <input id="contactAddress" formControlName="contactAddress" type="text" pInputText placeholder="Dirección de contacto"/>
              <small *ngIf="submit && formGroup.controls['contactAddress'].invalid" class="p-error">{{getErrorMessage('contactAddress')}}</small>
            </div>
            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
              <label for="contactPhone" style="font-weight: bold;">Teléfono</label>
              <input id="contactPhone" formControlName="contactPhone" type="text" pInputText placeholder="Teléfono de contacto"/>
            </div>
          </div>
        </p-fieldset>
        <p-fieldset legend="Información de empresa">
          <div class="p-fluid formgrid grid" style="padding: 20px;">
            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
              <label for="vat" style="font-weight: bold;">VAT ID </label>
              <input pInputText id="vat" formControlName="vat" type="text" maxlength="11" placeholder="RUT o VAT de la Empresa"/>
              <small *ngIf="submit && formGroup.controls['vat'].invalid" class="p-error">{{getErrorMessage('vat')}}</small>
            </div>
            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
              <label for="companyName" style="font-weight: bold;">Nombre de la empresa </label>
              <input pInputText id="companyName" formControlName="companyName" type="text" placeholder="Nombre de la Empresa"/>
              <small *ngIf="submit && formGroup.controls['companyName'].invalid" class="p-error">{{getErrorMessage('companyName')}}</small>
            </div>
            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
              <label for="sagCode" style="font-weight: bold;">SAG</label>
              <p-inputNumber id="sagCode" formControlName="sagCode" [useGrouping]="false" placeholder="Código SAG"/>
            </div>
            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
              <label for="legalRepresentativeName" style="font-weight: bold;">Nombre Representante Legal </label>
              <input pInputText id="legalRepresentativeName" formControlName="legalRepresentativeName" type="text" placeholder="Nombre del Representante Legal"/>
              <small *ngIf="submit && formGroup.controls['legalRepresentativeName'].invalid" class="p-error">{{getErrorMessage('legalRepresentativeName')}}</small>
            </div>
            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
              <label for="legalRepresentativeNumber" style="font-weight: bold;">Id Representante Legal </label>
              <p-inputNumber id="legalRepresentativeNumber" formControlName="legalRepresentativeNumber" [useGrouping]="false" placeholder="Número de identificación del Representante Legal"/>
              <small *ngIf="submit && formGroup.controls['legalRepresentativeNumber'].invalid" class="p-error">{{getErrorMessage('legalRepresentativeNumber')}}</small>
            </div>
            <!-- <div class="col-12 flex align-items-center" style="margin-bottom: 40px;">
              <label>Notificar al usuario por email:&nbsp;&nbsp;</label>
              <p-inputSwitch formControlName="isSendEmail"></p-inputSwitch>
            </div> -->
          </div>
        </p-fieldset>
        <div class="grid" style="padding: 20px;">
          <div class="col-6"></div>
          <div class="col-6">
            <div class="grid p-fluid">
              <div class="col-12 md:col-6 lg:col-6">
                <p-button label="Regresar" (onClick)="cancel()" [loading]="loading" styleClass="p-button-outlined"></p-button>
              </div>
              <div class="col-12 md:col-6 lg:col-6">
                <p-button [loading]="loading" label="Guardar" (onClick)="save()"/>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div id="success" *ngIf="success">
      <p>Se ha creado el usuario con éxito.</p>
      <p-button label="Aceptar" (onClick)="goToUsers()"></p-button>
    </div>
  </p-card>
</app-admin-dashboard>

<p-toast></p-toast>
