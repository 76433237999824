<app-admin-dashboard>
  <p-card>
    <ng-template pTemplate="header">
      <div class="flex justify-content-between flex-wrap align-items-center" style="padding: 15px;">
        <h2>{{'InvitationCodePage.MessageRequestCode' | translate}}</h2>
      </div>
    </ng-template>
    <form [formGroup]="formGroup">
      <div class="flex flex-wrap flex-column justify-content-center align-items-center">
        <div class="w-full md:w-4 lg:w-4 p-fluid formgrid grid">
          <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
            <label for="language" style="font-weight: bold;">{{'InvitationCodePage.LanguageFieldLabel' | translate }} *</label>
            <p-dropdown
              [options]="langs"
              inputId="language"
              optionLabel="label"
              optionValue="lang"
              formControlName="language"
              (onChange)="switchLang()"
              [showClear]="false"
              placeholder="{{ 'InvitationCodePage.LanguageFieldPlaceholder' | translate}}">
              <ng-template pTemplate="selectedItem">
                <div class="flex align-items-center">
                  <img [src]="'../../../../assets/img/flags/' + lang.icon" style="width: 18px" alt="" *ngIf="lang"/>
                  <span>&nbsp;{{lang?.label}}</span>
                </div>
              </ng-template>
              <ng-template let-lang pTemplate="item">
                <div class="flex align-items-center">
                  <img [src]="'../../../../assets/img/flags/' + lang.icon" style="width: 18px" alt=""/>
                  <span>&nbsp;{{lang.label}}</span>
                </div>
              </ng-template>
            </p-dropdown>
            <small *ngIf="submit && formGroup.controls['language'].invalid" class="p-error">{{getErrorMessage('language')}}</small>
          </div>
          <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
            <label for="fullname" style="font-weight: bold;">{{'InvitationCodePage.FullNameLabel' | translate }} *</label>
            <input id="fullname" formControlName="fullname" type="text" maxlength="120" required pInputText placeholder="{{'InvitationCodePage.FullNameFieldPlaceholder' | translate }}" [(ngModel)]="fullname"/>
            <small *ngIf="submit && formGroup.controls['fullname'].invalid" class="p-error">{{getErrorMessage('fullname')}}</small>
          </div>
          <div class="field col-12 md:col-12 lg:col-12" style="margin-bottom: 20px;">
            <label for="email" style="font-weight: bold;">{{'InvitationCodePage.EmailFieldLabel' | translate }} *</label>
            <input id="email" formControlName="email" type="text" maxlength="50" required pInputText placeholder="{{'InvitationCodePage.EmailFieldPlaceholder' | translate }}" [(ngModel)]="email"/>
            <small *ngIf="submit && formGroup.controls['email'].invalid" class="p-error">{{getErrorMessage('email')}}</small>
          </div>
          <div class="field col-12 md:col-12 lg:col-12" style="margin-bottom: 20px;">
            <div class=" w-full">
              <p-button class="p-button p-button-primary" (onClick)="generateCode()" [loading]="loading">{{'InvitationCodePage.GenerateCode'  | translate}}</p-button>
            </div>
          </div>
        </div>

      </div>
    </form>

    <p-table *ngIf="requestCodes.length > 0"
      [value]="requestCodes"
      [rowsPerPageOptions]="[10, 25, 50]"
      [rows]="10"
      [paginator]="true"
      [loading]="loading"
      [globalFilterFields]="['id', 'code', 'email']">
      <ng-template pTemplate="caption">
        <div class="flex justify-content-between flex-wrap align-items-center" style="padding: 15px;">
          <h3>Solicitudes de códigos de invitación</h3>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th>
            <div class="flex justify-content-center align-items-center">
              {{'InvitationCodePage.Table.Id' | translate}}
              <p-columnFilter type="text" field="id" display="menu" [showAddButton]="false" [showMatchModes]="false" [showOperator]="false"></p-columnFilter>
            </div>
          </th>
          <th>
            <div class="flex justify-content-center align-items-center">
              {{'InvitationCodePage.Table.Language' | translate}}
            </div>
          </th>
          <th>
            <div class="flex justify-content-center align-items-center">
              {{'InvitationCodePage.Table.Fullname' | translate}}
            </div>
          </th>
          <th>
            <div class="flex justify-content-center align-items-center">
              {{'InvitationCodePage.Table.Email' | translate}}
              <p-columnFilter type="text" field="email" display="menu" [showAddButton]="false" [showMatchModes]="false" [showOperator]="false"></p-columnFilter>
            </div>
          </th>
          <th>
            <div class="flex justify-content-center align-items-center">
              {{'InvitationCodePage.Table.Actions' | translate}}
            </div>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-request>
        <tr>
          <td>
            <div class="flex justify-content-center align-items-center" style="font-weight: bold;">
              <p>{{request.id}}</p>
            </div>
          </td>
          <td>
            <div class="flex justify-content-center align-items-center">
                <img [src]="'../../../../assets/img/flags/' + (request.language === 'es' ? 'ES.png' : 'GB.png')" style="width: 18px" alt=""/>
                <span>&nbsp;{{showLanguage(request.language)}}</span>
            </div>
          </td>
          <td>
            <div class="flex justify-content-center align-items-center">
              <p>{{request.fullname}}</p>
            </div>
          </td>
          <td>
            <div class="flex justify-content-center align-items-center">
              <p>{{request.email}}</p>
            </div>
          </td>
          <td>
            <div class="flex justify-content-center align-items-center">
              <p-button label="Generar" (onClick)="createCode(request)" [style]="{'background-color': '#6E9F41'}" icon="pi pi-check-circle"></p-button>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <p-table
      [value]="invitationCodes"
      [rowsPerPageOptions]="[10, 25, 50]"
      [rows]="10"
      [paginator]="true"
      [loading]="loading"
      [globalFilterFields]="['id', 'code', 'email']">
      <ng-template pTemplate="caption">
        <div class="flex justify-content-between flex-wrap align-items-center" style="padding: 15px;">
          <h3>Códigos de invitación generados</h3>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th>
            <div class="flex justify-content-center align-items-center">
              {{'InvitationCodePage.Table.Id' | translate}}
              <p-columnFilter type="text" field="id" display="menu" [showAddButton]="false" [showMatchModes]="false" [showOperator]="false"></p-columnFilter>
            </div>
          </th>
          <th>
            <div class="flex justify-content-center align-items-center">
              {{'InvitationCodePage.Table.Code' | translate}}
            </div>
          </th>
          <th>
            <div class="flex justify-content-center align-items-center">
              {{'InvitationCodePage.Table.Language' | translate}}
            </div>
          </th>
          <th>
            <div class="flex justify-content-center align-items-center">
              {{'InvitationCodePage.Table.Fullname' | translate}}
            </div>
          </th>
          <th>
            <div class="flex justify-content-center align-items-center">
              {{'InvitationCodePage.Table.Email' | translate}}
              <p-columnFilter type="text" field="email" display="menu" [showAddButton]="false" [showMatchModes]="false" [showOperator]="false"></p-columnFilter>
            </div>
          </th>
          <th>
            <div class="flex justify-content-center align-items-center">
              {{'InvitationCodePage.Table.Actions' | translate}}
            </div>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-code>
        <tr>
          <td>
            <div class="flex justify-content-center align-items-center" style="font-weight: bold;">
              <p>{{code.id}}</p>
            </div>
          </td>
          <td>
            <div class="flex justify-content-center align-items-center">
                <p>{{code.code}}</p>
            </div>
          </td>
          <td>
            <div class="flex justify-content-center align-items-center">
                <img [src]="'../../../../assets/img/flags/' + (code.language === 'es' ? 'ES.png' : 'GB.png')" style="width: 18px" alt=""/>
                <span>&nbsp;{{showLanguage(code.language)}}</span>
            </div>
          </td>
          <td>
            <div class="flex justify-content-center align-items-center">
              <p>{{code.fullname}}</p>
            </div>
          </td>
          <td>
            <div class="flex justify-content-center align-items-center">
              <p>{{code.email}}</p>
            </div>
          </td>
          <td>
            <div class="flex justify-content-center align-items-center">
              <p-button label="Inactivar" (onClick)="inactiveCode(code.id)" [style]="{'background-color': 'red'}" icon="pi pi-times"></p-button>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>

  </p-card>
</app-admin-dashboard>

<p-toast></p-toast>
<p-confirmDialog acceptLabel="Si" rejectLabel="No" rejectButtonStyleClass="p-button-outlined" [style]="{width: '50vw'}"></p-confirmDialog>
