import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { ImportOrderGateway } from 'src/app/application/gateway/ImportOrderGateway';
import { GenericOrderDetail, ImportOrderListItem } from 'src/app/domain/models/ImportOrder';
import { BasicUser } from 'src/app/domain/models/User';
import { Routes } from 'src/app/infrastructure/routes';
import { OrderUtils } from 'src/app/ui/util/order-utils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-import-orders-forwarder',
  templateUrl: './user-import-orders-forwarder.component.html',
  styleUrls: ['./user-import-orders-forwarder.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class UserImportOrdersForwarderComponent implements OnInit {
  tabMenuItems: MenuItem[] = []
  activeItem: MenuItem = {}
  orders: ImportOrderListItem[] = []
  profile: BasicUser | undefined

  loading: boolean = false
  statuses: any[] = [];

  @Input()
  activeItemIndex = 2

  constructor(private _router: Router,
    private _authService: AuthGateway,
    private _messageService: MessageService,
    private _importOrderService: ImportOrderGateway,
    public translate: TranslateService) {
  }

  ngOnInit(): void {
    let tabMenuItemInProcessLabel = 'In process'
    let tabMenuItemPurchaseLabel = 'Possible purchases'
    let tabMenuItemForwarderOrderLabel = 'Forwarder orders'

    this.profile = this._authService.getUser()

    if(this.profile) {
      switch(this.profile.lang) {
        case 'en':
          tabMenuItemInProcessLabel = 'In process'
          tabMenuItemPurchaseLabel = 'Possible purchases'
          tabMenuItemForwarderOrderLabel = 'Forwarder orders'
          break
        case 'es':
          tabMenuItemInProcessLabel = 'En proceso'
          tabMenuItemPurchaseLabel = 'Posibles compras'
          tabMenuItemForwarderOrderLabel = 'Ordenes Forwarder'
          break
      }
    }

    this.statuses = [
      { label: 'Created', value: 0 },
      { label: 'Pending Verify Pallets', value: 1 },
      { label: 'QC Pallets Verified', value: 2 },
      { label: 'Order Confirmed', value: 3 },
      { label: 'Payment Confirmed', value: 4 }
    ];

    this.tabMenuItems = [
      { label: tabMenuItemInProcessLabel, command: () =>  this._router.navigate(['/'+Routes.userImportOrdersCurrent]) },
      { label: tabMenuItemPurchaseLabel, command: () =>  this._router.navigate(['/'+Routes.userImportOrdersPurchase]) },
      { label: tabMenuItemForwarderOrderLabel, command: () =>  this._router.navigate(['/'+Routes.userImportOrdersForwarder]) },

    ]
    this.activeItem = this.tabMenuItems[this.activeItemIndex]
    this.getAll()
  }

  getAll() {
    if(this.profile) {
      if(this.profile.profileType == 'Exporter') {
        this.getAllByExporter()
      }
      else if(this.profile.profileType == 'Importer') {
        this.getAllByImporter()
      }
    }
  }

  getAllByImporter() {
    this.loading = true
    this._importOrderService.getAllByImporterMbl().subscribe({
      next: (v) => {
        this.orders = v
        this.loading = false
      },
      error: (e) => {
        this.loading = false
        console.log(e)
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.ImportOrder.ManyRetrieveError') });
      },
      complete: () => console.info('Import Order query successfully')
    })
  }

  getAllByExporter() {
    this.loading = true
    this._importOrderService.getAllByExporterMbl().subscribe({
      next: (v) => {
        this.orders = v
        this.loading = false
      },
      error: (e) => {
        this.loading = false
        console.log(e)
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.ImportOrder.ManyRetrieveError') });
      },
      complete: () => console.info('Import Order query successfully')
    })
  }

  getOrderTitle(order: ImportOrderListItem): string {
    return OrderUtils.getOrderTitle(order.sequence)
  }

  getPictureUrl(pictureUrl: ImportOrderListItem): string {
    return environment.apiUrl + pictureUrl
  }

  getSpeciesName(detail: GenericOrderDetail): string {
    if(this.profile) {
      switch(this.profile.lang) {
        case 'es':
          return detail.speciesNameEs
        case 'en':
          return detail.speciesNameEn
        default:
          return detail.speciesNameEn
      }
    }
    return ''
  }

  getStatusSeverity(status: number): string {
    if (status === 1 || status === 2) {
      return 'warning';
    } else if (status === 3 || status === 4) {
      return 'success';
    } else {
      return 'info';
    }
  }

  getStatusIcon(status: number): string {
    if (status === 0 || status === 1 || status === 2) {
      return 'pi pi-exclamation-triangle';
    } else if (status === 3 || status === 4) {
      return 'pi pi-check';
    } else {
      return '';
    }
  }

  goToOrderDetailPage(orderId: any) {
    this._router.navigate(['/' + Routes.userOrderDetailPage, orderId])
  }

}
