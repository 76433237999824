import { Component, Input } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import readXlsxFile, { readSheetNames } from 'read-excel-file';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { CountryGateway } from 'src/app/application/gateway/CountryGateway';
import { DocumentServiceGateway } from 'src/app/application/gateway/DocumentServiceGateway';
import { FridgeStorageGateway } from 'src/app/application/gateway/FridgeStorageGateway';
import { PalletGateway } from 'src/app/application/gateway/PalletGateway';
import { Country } from 'src/app/domain/models/Country';
import { ExportOfferInformation } from 'src/app/domain/models/ExportOffer';
import { FridgeStorage, NewFridgeStorage } from 'src/app/domain/models/FridgeStorage';
import { GenericOrderDetail, ImportOrderDetail, ImportOrderDetailInformation } from 'src/app/domain/models/ImportOrder';
import { NewPallet, CreatePalletsRequest, PalletPrice, CreatePalletPriceRequest } from 'src/app/domain/models/Pallet';
import { QualityControlCompany, QualityControlCompanySpecies, UpdateControlCompanyRequest } from 'src/app/domain/models/QCCompany';
import { QualityControlReview } from 'src/app/domain/models/QualityControlReview';
import { Species } from 'src/app/domain/models/Species';
import { BasicUser } from 'src/app/domain/models/User';
import { Routes } from 'src/app/infrastructure/routes';
import { ErrorMessageUtil } from 'src/app/ui/util/error-message-util';
import { OrderUtils } from 'src/app/ui/util/order-utils';
import { SpeciesUtils } from 'src/app/ui/util/species-utils';
import { environment } from 'src/environments/environment';
import {ImportOrderContainerItem } from 'src/app/domain/models/ImportOrder';
import { QualityControlCompanyGateway } from 'src/app/application/gateway/QualityControlCompanyGateway';
import { ImportOrderDetailGateway } from 'src/app/application/gateway/ImportOrderDetailGateway';
import { ProfileGateway } from 'src/app/application/gateway/ProfileGateway';


@Component({
  selector: 'app-verify-pallets-component',
  templateUrl: './verify-pallets-component.component.html',
  styleUrls: ['./verify-pallets-component.component.css']
})
export class VerifyPalletsComponentComponent {

  visibleQCCompany: boolean = false
  visibleDisclaimerQCDialog: boolean = false

  qcCompanies: QualityControlCompany[] = []
  itemQCSelected: any;
  itemBeforeSelect: any;
  companySpeciesSelected: QualityControlCompanySpecies[] = []

  noQCCompany: QualityControlCompany = {
    id: 0,
    profileId: 0,
    isConsolidateContainer: false,
    basePricePerContainer: 0,
    hasQCSystem: false,
    pricePerPallet: 0,
    pricePerContainer: 0,
    basePricePerPallet: 0,
    profile: {
      id: 0,
      companyName: 'NO QC',
      vat: 0,
      type: 0,
      contactName: '',
      contactEmail: '',
      contactPhone: '',
      contactAddress: '',
      contactCity: '',
      contactCountryId: 0,
      lang:'',
      hasTwoAuthetication: false,
      updaterUsername: ''

    }
  }

  @Input()
  isClosedSale: boolean = false

  @Input()
  exportOfferId: number | undefined

  @Input()
  directSaleProgramId: any

  @Input()
  dto: ImportOrderDetailInformation | undefined

  detail: GenericOrderDetail | undefined

  destinationQCCompanies: QualityControlCompany[] = []
  selectedDestinationQCCompany: QualityControlCompany | undefined
  originQCCompanies: QualityControlCompany[] = []
  selectedOriginQCCompany: QualityControlCompany | undefined
  originQCReviews: QualityControlReview[] = []
  destinationQCReviews: QualityControlReview[] = []
  fridgeStorages: any[] = []

  existCurrentPrices: boolean = false;
  hideBlur: boolean = false;

  visible: boolean = false
  excelFile: any
  excelSheets: any
  selectedSheet: string = ''
  startRow: number = 1
  folioColumn: string = ''
  caliberColumn: string = ''
  producerColumn: string = ''
  folios: any[] = []
  readedFolios: any[] = []
  readedCalibers: any[] = []
  readedCaliberMillimeters: any[] = []
  columns: string[] = [
    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
    'AA', 'AB', 'AC', 'AD', 'AE', 'AF', 'AG', 'AH', 'AI', 'AJ', 'AK', 'AL', 'AM', 'AN', 'AO', 'AP', 'AQ', 'AR', 'AS', 'AT', 'AU', 'AV', 'AW', 'AX', 'AY', 'AZ',
    'BA', 'BB', 'BC', 'BD', 'BE', 'BF', 'BG', 'BH', 'BI', 'BJ', 'BK', 'BL', 'BM', 'BN', 'BO', 'BP', 'BQ', 'BR', 'BS', 'BT', 'BU', 'BV', 'BW', 'BX', 'BY', 'BZ',
    'CA', 'CB', 'CC', 'CD', 'CE', 'CF', 'CG', 'CH', 'CI', 'CJ', 'CK', 'CL', 'CM', 'CN', 'CO', 'CP', 'CQ', 'CR', 'CS', 'CT', 'CU', 'CV', 'CW', 'CX', 'CY', 'CZ',
    'DA', 'DB', 'DC', 'DD', 'DE', 'DF', 'DG', 'DH', 'DI', 'DJ', 'DK', 'DL', 'DM', 'DN', 'DO', 'DP', 'DQ', 'DR', 'DS', 'DT', 'DU', 'DV', 'DW', 'DX', 'DY', 'DZ',
    'EA', 'EB', 'EC', 'ED', 'EE', 'EF', 'EG', 'EH', 'EI', 'EJ', 'EK', 'EL', 'EM', 'EN', 'EO', 'EP', 'EQ', 'ER', 'ES', 'ET', 'EU', 'EV', 'EW', 'EX', 'EY', 'EZ',
    'FA', 'FB', 'FC', 'FD', 'FE', 'FF', 'FG', 'FH', 'FI', 'FJ', 'FK', 'FL', 'FM', 'FN', 'FO', 'FP', 'FQ', 'FR', 'FS', 'FT', 'FU', 'FV', 'FW', 'FX', 'FY', 'FZ',
    'GA', 'GB', 'GC', 'GD', 'GE', 'GF', 'GG', 'GH', 'GI', 'GJ', 'GK', 'GL', 'GM', 'GN', 'GO', 'GP', 'GQ', 'GR', 'GS', 'GT', 'GU', 'GV', 'GW', 'GX', 'GY', 'GZ',
    'HA', 'HB', 'HC', 'HD', 'HE', 'HF', 'HG', 'HH', 'HI', 'HJ', 'HK', 'HL', 'HM', 'HN', 'HO', 'HP', 'HQ', 'HR', 'HS', 'HT', 'HU', 'HV', 'HW', 'HX', 'HY', 'HZ',
    'IA', 'IB', 'IC', 'ID', 'IE', 'IF', 'IG', 'IH', 'II', 'IJ', 'IK', 'IL', 'IM', 'IN', 'IO', 'IP', 'IQ', 'IR', 'IS', 'IT', 'IU', 'IV', 'IW', 'IX', 'IY', 'IZ',
    'JA', 'JB', 'JC', 'JD', 'JE', 'JF', 'JG', 'JH', 'JI', 'JJ', 'JK', 'JL', 'JM', 'JN', 'JO', 'JP', 'JQ', 'JR', 'JS', 'JT', 'JU', 'JV', 'JW', 'JX', 'JY', 'JZ',
    'KA', 'KB', 'KC', 'KD', 'KE', 'KF', 'KG', 'KH', 'KI', 'KJ', 'KK', 'KL', 'KM', 'KN', 'KO', 'KP', 'KQ', 'KR', 'KS', 'KT', 'KU', 'KV', 'KW', 'KX', 'KY', 'KZ',
    'LA', 'LB', 'LC', 'LD', 'LE', 'LF', 'LG', 'LH', 'LI', 'LJ', 'LK', 'LL', 'LM', 'LN', 'LO', 'LP', 'LQ', 'LR', 'LS', 'LT', 'LU', 'LV', 'LW', 'LX', 'LY', 'LZ',
    'MA', 'MB', 'MC', 'MD', 'ME', 'MF', 'MG', 'MH', 'MI', 'MJ', 'MK', 'ML', 'MM', 'MN', 'MO', 'MP', 'MQ', 'MR', 'MS', 'MT', 'MU', 'MV', 'MW', 'MX', 'MY', 'MZ',
    'NA', 'NB', 'NC', 'ND', 'NE', 'NF', 'NG', 'NH', 'NI', 'NJ', 'NK', 'NL', 'NM', 'NN', 'NO', 'NP', 'NQ', 'NR', 'NS', 'NT', 'NU', 'NV', 'NW', 'NX', 'NY', 'NZ',
    'OA', 'OB', 'OC', 'OD', 'OE', 'OF', 'OG', 'OH', 'OI', 'OJ', 'OK', 'OL', 'OM', 'ON', 'OO', 'OP', 'OQ', 'OR', 'OS', 'OT', 'OU', 'OV', 'OW', 'OX', 'OY', 'OZ',
    'PA', 'PB', 'PC', 'PD', 'PE', 'PF', 'PG', 'PH', 'PI', 'PJ', 'PK', 'PL', 'PM', 'PN', 'PO', 'PP', 'PQ', 'PR', 'PS', 'PT', 'PU', 'PV', 'PW', 'PX', 'PY', 'PZ',
    'QA', 'QB', 'QC', 'QD', 'QE', 'QF', 'QG', 'QH', 'QI', 'QJ', 'QK', 'QL', 'QM', 'QN', 'QO', 'QP', 'QQ', 'QR', 'QS', 'QT', 'QU', 'QV', 'QW', 'QX', 'QY', 'QZ',
    'RA', 'RB', 'RC', 'RD', 'RE', 'RF', 'RG', 'RH', 'RI', 'RJ', 'RK', 'RL', 'RM', 'RN', 'RO', 'RP', 'RQ', 'RR', 'RS', 'RT', 'RU', 'RV', 'RW', 'RX', 'RY', 'RZ',
    'SA', 'SB', 'SC', 'SD', 'SE', 'SF', 'SG', 'SH', 'SI', 'SJ', 'SK', 'SL', 'SM', 'SN', 'SO', 'SP', 'SQ', 'SR', 'SS', 'ST', 'SU', 'SV', 'SW', 'SX', 'SY', 'SZ',
    'TA', 'TB', 'TC', 'TD', 'TE', 'TF', 'TG', 'TH', 'TI', 'TJ', 'TK', 'TL', 'TM', 'TN', 'TO', 'TP', 'TQ', 'TR', 'TS', 'TT', 'TU', 'TV', 'TW', 'TX', 'TY', 'TZ',
    'UA', 'UB', 'UC', 'UD', 'UE', 'UF', 'UG', 'UH', 'UI', 'UJ', 'UK', 'UL', 'UM', 'UN', 'UO', 'UP', 'UQ', 'UR', 'US', 'UT', 'UU', 'UV', 'UW', 'UX', 'UY', 'UZ',
    'VA', 'VB', 'VC', 'VD', 'VE', 'VF', 'VG', 'VH', 'VI', 'VJ', 'VK', 'VL', 'VM', 'VN', 'VO', 'VP', 'VQ', 'VR', 'VS', 'VT', 'VU', 'VV', 'VW', 'VX', 'VY', 'VZ',
    'WA', 'WB', 'WC', 'WD', 'WE', 'WF', 'WG', 'WH', 'WI', 'WJ', 'WK', 'WL', 'WM', 'WN', 'WO', 'WP', 'WQ', 'WR', 'WS', 'WT', 'WU', 'WV', 'WW', 'WX', 'WY', 'WZ',
    'XA', 'XB', 'XC', 'XD', 'XE', 'XF', 'XG', 'XH', 'XI', 'XJ', 'XK', 'XL', 'XM', 'XN', 'XO', 'XP', 'XQ', 'XR', 'XS', 'XT', 'XU', 'XV', 'XW', 'XX', 'XY', 'XZ',
    'YA', 'YB', 'YC', 'YD', 'YE', 'YF', 'YG', 'YH', 'YI', 'YJ', 'YK', 'YL', 'YM', 'YN', 'YO', 'YP', 'YQ', 'YR', 'YS', 'YT', 'YU', 'YV', 'YW', 'YX', 'YY', 'YZ',
    'ZA', 'ZB', 'ZC', 'ZD', 'ZE', 'ZF', 'ZG', 'ZH', 'ZI', 'ZJ', 'ZK', 'ZL', 'ZM', 'ZN', 'ZO', 'ZP', 'ZQ', 'ZR', 'ZS', 'ZT', 'ZU', 'ZV', 'ZW', 'ZX', 'ZY', 'ZZ'
  ]


  profile: BasicUser | undefined

  pallets: NewPallet  [] = []

  palletsPrice: PalletPrice[] = []

  currentPrices: PalletPrice[] = []

  visiblePalletsPrice: boolean = false
  visibleCurrentPalletsPrice: boolean = false

  fridgeStorageOptions: any[] = []
  loadFridgestorageDialogVisible: boolean = false
  countries: Country[] = []

  fridgestorageFormGroup: FormGroup = this._formBuilder.group({
    fridgestorageName: [null, Validators.required],
    fridgestorageVat: [null],
    fridgestorageContactName: [null, Validators.required],
    fridgestorageContactAddress: [null, Validators.required],
    fridgestorageContactCity: [null, Validators.required],
    fridgestorageContactEmail: [null, [Validators.required, Validators.email]],
    fridgestorageContactPhone: [null, Validators.required],
    fridgestorageContactCountryId: [null, Validators.required]
  })

  isEdit: boolean = false

  orderType: string = 'Import'

  palletFormGroup: FormGroup = this._formBuilder.group({
    startRow: [2, Validators.required],
    folioColumn: ['A', Validators.required],
    speciesColumn: ['B', Validators.required],
    varietyColumn: ['C', Validators.required],
    producerColumn: ['D', Validators.required],
    speciesSizeColumn: ['E'],
    categoryColumn: ['F'],
    harvestDateColumn: ['G'],
    qualityControlDateColumn: ['H'],
    packagingColumn: ['I'],
    palletTypeColumn: ['J'],
    tagColumn: ['K'],
    bagTypeColumn: ['L'],
    boxSizeColumn: ['M'],
    netWeightColumn: ['N'],
    fridgeStorageColumn: ['O'],
    boxesPerPalletColumn: ['O'],
    markColumn: ['P'],
    containerNumberColumn: ['Q'],
    gradeColumn: ['R'],
    temptaleColumn: ['S'],
    originCountryColumn: ['T'],
    certificate1Column: ['U'],
    certificate2Column: ['V'],
    certificate3Column: ['W'],
    loadWeekColumn: ['X'],

  })

  directSaleFormGroup: FormGroup = this._formBuilder.group({
    fridgeStorageId: [null, Validators.required],
    harvestDate: [null, Validators.required],
    shelfLife: [null, Validators.required],
    readyToLoadDate: [null]
  })

  submit: boolean = false
  success: boolean = false
  loading: boolean = false
  isCreate: boolean = false

  constructor(private _router: Router,
    private _messageService: MessageService,
    private _formBuilder: FormBuilder,
    private _palletService: PalletGateway,
    private _authService: AuthGateway,
    public translate: TranslateService,
    private _documentService: DocumentServiceGateway,
    private _fridgeStorageService: FridgeStorageGateway,
    private _qualityControlService: QualityControlCompanyGateway,
    private _countryService: CountryGateway,
    private _importDetailService: ImportOrderDetailGateway,
  ) {
  }

  ngOnInit(): void {
    this.profile = this._authService.getUser()
    this.getLabelsOrder()
    
    if(this.dto) {
      this.detail = this.dto.importOrderDetail
      if(this.dto.importOrderDetail.orderStatus === 4){
        this.hideBlur = true
      }else{
        this.hideBlur = false;
      }
      this.pallets = this.dto.pallets
      this.destinationQCCompanies = this.dto.destinationQCCompanies
      this.selectedDestinationQCCompany = this.dto.destinationQCCompany
      this.originQCCompanies = this.dto.originQCCompanies
      this.selectedOriginQCCompany = this.dto.originQCCompany
      this.originQCReviews = this.dto.originQCReviews
      this.destinationQCReviews = this.dto.destinationQCReviews
      this.loadDirectSaleForm()
      if(this.pallets && this.pallets.length > 0 && this.profile?.profileType == 'Exporter') {

        let i = 0
        this.pallets.forEach(x => {
          x.dataKey = i
          i++
        })

        this.isEdit = true
      }
      else {
        if(this.profile?.profileType == 'Exporter') {
          this.isCreate = true
          this.generatePallets()
        }
      }

      if(this.detail) {
        this.exportOfferId = this.detail.exportOfferId
      }
    }
    this.getFridgeStorages()
    this.getPriceConfiguration()
    this.loadCountries()
    this.loadQualityControlCompany();
  }

  loadDirectSaleForm() {
    if(this.detail) {
      this.directSaleFormGroup.controls['harvestDate'].setValue(this.detail.harvestDate ? new Date(this.detail.harvestDate) : null)
      this.directSaleFormGroup.controls['fridgeStorageId'].setValue(this.detail.fridgeStorageId)
      this.directSaleFormGroup.controls['readyToLoadDate'].setValue(this.detail.readyToLoadDate ? new Date(this.detail.readyToLoadDate) : null)
      this.directSaleFormGroup.controls['shelfLife'].setValue(this.detail.shelfLife)
    }
  }

  loadQualityControlCompany()
  {

    if(this.detail){
      this.loading = false
      this._qualityControlService.getAllByOperationCountryId(this.detail.exportOfferCountryId).subscribe({
        next: (v) => {
          this.qcCompanies = v;
          this.qcCompanies.push(this.noQCCompany);
          this.assignQcCompany()
          this.loading = false
        },
        error: (e) => {
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.Country.ManyRetrieveError') })
        },
      })
    }
  }

  assignQcCompany(){
    if(this.detail && this.qcCompanies)
    {
      var qcSelected = this.qcCompanies.find( x => x.id == this.detail?.qcCompanyId )
      if(qcSelected)
      {
        this.itemQCSelected = qcSelected;
        this.companySpeciesSelected = qcSelected.qualityControlCompanySpecies ? qcSelected.qualityControlCompanySpecies : [] ;
      }
    }
  }

  setQcBefore()
  {
    if(this.detail && this.qcCompanies)
      {
        var qcSelected = this.qcCompanies.find( x => x.id == this.itemBeforeSelect?.id )
        if(qcSelected)
        {
          this.itemQCSelected = qcSelected;
          this.companySpeciesSelected = qcSelected.qualityControlCompanySpecies ? qcSelected.qualityControlCompanySpecies : [] ;
        }
      }
  }


  loadCountries() {
    this.countries = []
    this._countryService.getAll().subscribe({
      next: (v) => {
        this.countries = v
        this.loading = false

      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Country.ManyRetrieveError') })
      },
      complete: () => console.info('Country query successfully')
    })
  }

  getImageUrl(url?: string): string {
    if(url){
      return environment.apiUrl + url
    }else{
      return  ''
    }

  }

  getPriceConfiguration()
  {
    this.palletsPrice = []
    if(this.detail)
      {
        this.loading = true
        this._palletService.getPalletPriceConfiguration(this.detail.id).subscribe({
          next: (v) => {
            this.currentPrices = v
            this.loading = false
          },
          error: (e) => {
            this.loading = false
            this._messageService.add({
              severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: e.message
            })
          }
        })

      }
  }

  getFridgeStorages() {
    this.fridgeStorages = []
    this._fridgeStorageService.getAllByStorageType(1).subscribe({
      next: (v) => {
        this.fridgeStorages = v

        this.fridgeStorages.push({
          id: 0,
          label: this.translate.instant('MessageService.FridgeStorage.CreateOptionLabel') + '...'
        })

        this.fridgeStorageOptions = this.fridgeStorages.map((item) => {
          return {
            ...item,
            label: item.label ?? item.name + ', ' + item.contactAddress + ' - ' +  item.contactCountry?.name
          }
        })
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.FridgeStorage.ManyRetrieveError') })
      },
      complete: () => console.info('Fridge Storage query successfully')
    })
  }

  onChangeFridgestorage() {
    const selected = this.directSaleFormGroup.controls['fridgeStorageId'].value
    if(selected === 0) {
      this.openNewFridgestorageDialog()
    }
  }

  openNewFridgestorageDialog() {
    this.fridgestorageFormGroup.controls["fridgestorageName"].setValue(''),
    this.fridgestorageFormGroup.controls["fridgestorageVat"].setValue(''),
    this.fridgestorageFormGroup.controls["fridgestorageContactName"].setValue(''),
    this.fridgestorageFormGroup.controls["fridgestorageContactAddress"].setValue(''),
    this.fridgestorageFormGroup.controls["fridgestorageContactEmail"].setValue(''),
    this.fridgestorageFormGroup.controls["fridgestorageContactPhone"].setValue(''),
    this.fridgestorageFormGroup.controls["fridgestorageContactCity"].setValue(''),
    this.fridgestorageFormGroup.controls["fridgestorageContactCountryId"].setValue('')
    this.loadFridgestorageDialogVisible = true
  }

  saveFridgestorage() {
    this.submit = true
    this.loading = true
    if(this.fridgestorageFormGroup.valid) {
      const newFridgeStorage: NewFridgeStorage = {
        name: this.fridgestorageFormGroup.controls["fridgestorageName"].value,
        vat: this.fridgestorageFormGroup.controls["fridgestorageVat"].value,
        contactName: this.fridgestorageFormGroup.controls["fridgestorageContactName"].value,
        contactAddress: this.fridgestorageFormGroup.controls["fridgestorageContactAddress"].value,
        contactEmail: this.fridgestorageFormGroup.controls["fridgestorageContactEmail"].value,
        contactPhone: this.fridgestorageFormGroup.controls["fridgestorageContactPhone"].value,
        contactCity: this.fridgestorageFormGroup.controls["fridgestorageContactCity"].value,
        contactCountryId: this.fridgestorageFormGroup.controls["fridgestorageContactCountryId"].value,
        storageType: 1
      }

      this._fridgeStorageService.create(newFridgeStorage).subscribe({
        next: (v) => {
          this.getFridgeStorages()
          this.loadFridgestorageDialogVisible = false
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: this.translate.instant('MessageService.FridgeStorage.CreateSuccess') })
          this.loading = false
          this.submit = false
        },
        error: (e) => {
          this.loading = false
          this.submit = false
          console.log(e)
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.FridgeStorage.CreateError') });
        },
        complete: () =>  {
          this.loading = false
          this.submit = false
          console.info('FridgeStorage created successfully')
        }
      })
    }
    else {
      this.loading = false
    }
  }

  confirmPalletPrice()
  {
    // console.log(this.palletsPrice)
    if(this.palletsPrice && this.detail){
      let createPalletPriceRequest: CreatePalletPriceRequest  = {
        importOrderDetailId: this.detail.id,
        palletPriceConfig: this.palletsPrice
      }
      this._palletService.createPalletPrice(createPalletPriceRequest).subscribe({
        next: (v) => {
          this._messageService.add({
            severity: 'success',
            summary: 'Confirmado',
            detail: 'Configuración de precios realizada exitosamente'
          })
          this.getPriceConfiguration()
        },
        error: (e) =>
        {
          this._messageService.add({
            severity: 'success',
            summary: 'Error',
            detail: 'Error al intentar guardar configuración de precios'
          })
        }
      })
    }

    this.visiblePalletsPrice = false
  }

  generatePallets() {
    
    if(this.detail) {
      for(let i = 0; i < this.detail.palletsQuantity; i++) {
        this.pallets.push({
          dataKey: i,
          palletNumber: '',
          palletTypeId: 1
        })
      }
    }
  }

  showDialog() {
    this.excelFile = null
    this.excelSheets = null
    this.startRow = 1
    this.folioColumn = ''
    this.caliberColumn = ''
    this.producerColumn = ''
    this.visible = true
  }

  onSelectExcelFile(uploader: any) {
    this.excelFile = uploader.files[0]
    readSheetNames(uploader.files[0]).then((sheetNames) => {
      this.excelSheets = sheetNames
    })

    uploader.clear()
  }

  padWithLeadingZeros(id: number): string {
    return OrderUtils.padWithLeadingZeros(id)
  }

  onLoadExcel2() {
    this.loading = true
    if(this.palletFormGroup.valid) {
      if(!this.excelFile)
        return

      if(!this.selectedSheet)
        return

      readXlsxFile(this.excelFile, {sheet: this.selectedSheet}).then((rows) => {
        if(rows) {
          this.pallets.forEach(pallet => {
            if(this.isCreate || this.isClosedSale) {
              pallet.palletNumber = ''
            }
            pallet.excelImportSpecies = ''
            pallet.excelImportVariety = ''
            pallet.speciesSize = ''
            pallet.category = ''
            pallet.producer = ''
            pallet.excelImportHarvestDate = ''
            pallet.excelImportQualityControlDate = ''
            pallet.excelImportPackaging = ''
            pallet.excelImportPalletType = ''
            pallet.excelImportTag = ''
            pallet.excelImportBagType = ''
            pallet.excelImportBoxSize = ''
            pallet.excelImportNetWeight = ''
            pallet.excelImportFridgeStorage = ''
            pallet.excelImportBoxesPerPallet = ''
            pallet.excelImportMark = ''
            pallet.excelImportContainerNumber = ''
            pallet.excelImportGrade = ''
            pallet.excelImportTemptale = ''
            pallet.excelImportOriginCountry = ''
            pallet.excelImportCertificate1 = ''
            pallet.excelImportCertificate2 = ''
            pallet.excelImportCertificate3 = ''
            pallet.excelImportLoadWeek = ''
          })

          rows.forEach(row => {
            for(let k=0; k < 23; k++) {
              if(row[k]) {
                let value = row[k]
                if(value instanceof Date) {
                  value.setDate(value.getDate() + 1)
                  value = moment(value).format('DD/MM/YYYY')
                }
                else if (value) {
                  value = value.toString()
                }
                row[k] = value
              }
            }
          })

          let startRow = this.palletFormGroup.controls['startRow'].value
          let folioColumn = this.palletFormGroup.controls['folioColumn'].value
          let speciesColumn = this.palletFormGroup.controls['speciesColumn'].value
          let categoryColumn = this.palletFormGroup.controls['categoryColumn'].value
          let varietyColumn = this.palletFormGroup.controls['varietyColumn'].value
          let producerColumn = this.palletFormGroup.controls['producerColumn'].value
          let speciesSizeColumn = this.palletFormGroup.controls['speciesSizeColumn'].value
          let harvestDateColumn = this.palletFormGroup.controls['harvestDateColumn'].value
          let qualityControlDateColumn = this.palletFormGroup.controls['qualityControlDateColumn'].value
          let packagingColumn = this.palletFormGroup.controls['packagingColumn'].value
          let palletTypeColumn = this.palletFormGroup.controls['palletTypeColumn'].value
          let tagColumn = this.palletFormGroup.controls['tagColumn'].value
          let bagTypeColumn = this.palletFormGroup.controls['bagTypeColumn'].value
          let boxSizeColumn = this.palletFormGroup.controls['boxSizeColumn'].value
          let netWeightColumn = this.palletFormGroup.controls['netWeightColumn'].value
          let fridgeStorageColumn = this.palletFormGroup.controls['fridgeStorageColumn'].value
          let boxesPerPalletColumn = this.palletFormGroup.controls['boxesPerPalletColumn'].value
          let markColumn = this.palletFormGroup.controls['markColumn'].value
          let containerNumberColumn = this.palletFormGroup.controls['containerNumberColumn'].value
          let gradeColumn = this.palletFormGroup.controls['gradeColumn'].value
          let temptaleColumn = this.palletFormGroup.controls['temptaleColumn'].value
          let originCountryColumn = this.palletFormGroup.controls['originCountryColumn'].value
          let certificate1Column = this.palletFormGroup.controls['certificate1Column'].value
          let certificate2Column = this.palletFormGroup.controls['certificate2Column'].value
          let certificate3Column = this.palletFormGroup.controls['certificate3Column'].value
          let loadWeekColumn = this.palletFormGroup.controls['loadWeekColumn'].value

          const rowStartIndex = startRow - 1
          let j = 0

          let cellValue = (row: any, column: any, pallet: any, property: string) => {
            if(column) {
              const index =  this.columns.indexOf(column.toUpperCase())
              pallet[property] = row[index] ? row[index].toString() : ''
            }
          }

          for(let i = rowStartIndex; i < rows.length; i++) {
            const row = rows[i]
            let pallet = this.pallets[j]
            if(pallet) {

              if(this.isCreate || this.isClosedSale) {
                cellValue(row, folioColumn, pallet, 'palletNumber')
              }
              cellValue(row, speciesColumn, pallet, 'excelImportSpecies')
              cellValue(row, varietyColumn, pallet, 'excelImportVariety')
              cellValue(row, producerColumn, pallet, 'producer')
              cellValue(row, speciesSizeColumn, pallet, 'speciesSize')
              cellValue(row, categoryColumn, pallet, 'category')
              cellValue(row, harvestDateColumn, pallet, 'excelImportHarvestDate')
              cellValue(row, qualityControlDateColumn, pallet, 'excelImportQualityControlDate')
              cellValue(row, packagingColumn, pallet, 'excelImportPackaging')
              cellValue(row, palletTypeColumn, pallet, 'excelImportPalletType')
              cellValue(row, tagColumn, pallet, 'excelImportTag')
              cellValue(row, bagTypeColumn, pallet, 'excelImportBagType')
              cellValue(row, boxSizeColumn, pallet, 'excelImportBoxSize')
              cellValue(row, netWeightColumn, pallet, 'excelImportNetWeight')
              //cellValue(row, fridgeStorageColumn, pallet, 'excelImportFridgeStorage')
              pallet.excelImportFridgeStorage = this.detail?.fridgeStorageName
              cellValue(row, boxesPerPalletColumn, pallet, 'excelImportBoxesPerPallet')
              cellValue(row, markColumn, pallet, 'excelImportMark')
              cellValue(row, containerNumberColumn, pallet, 'excelImportContainerNumber')
              cellValue(row, gradeColumn, pallet, 'excelImportGrade')
              cellValue(row, temptaleColumn, pallet, 'excelImportTemptale')
              cellValue(row, originCountryColumn, pallet, 'excelImportOriginCountry')
              cellValue(row, certificate1Column, pallet, 'excelImportCertificate1')
              cellValue(row, certificate2Column, pallet, 'excelImportCertificate2')
              cellValue(row, certificate3Column, pallet, 'excelImportCertificate3')
              cellValue(row, loadWeekColumn, pallet, 'excelImportLoadWeek')

            }
            j++
          }
        }

        if(this.isClosedSale)
          {
            this.calculateTablePrice()
            this.showPalletPriceModal()

          }
      })
      this.loading = false
      this.visible = false
    } else {
      this.loading = false
    }
  }

  save() {
    if(this.isClosedSale) {
      this.saveClosedSale()
    }
    else {
      this.saveMarketplaceSale()
    }
  }

  calculateTablePrice()
  {
    this.palletsPrice = []
    if(this.detail){
      this.loading = true
      this._palletService.getPalletPriceConfigurationFromArray(this.pallets).subscribe({
        next: (v) => {
          this.palletsPrice = v
          this.loading = false
          this.visiblePalletsPrice = true
        },
        error: (e) => {
          this.loading = false
          this._messageService.add({
            severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: e.message
          })
        }
      })
    }
  }

  saveMarketplaceSale() {
    this.submit = true
    this.loading = true
    if(this.detail) {

      if(!this.isValidDatatable()) {
        this.loading = false
        return
      }

      const request: CreatePalletsRequest = {
        pallets: this.pallets,
        importOrderDetailId: this.detail.id,
        destinationQCCompanyId: this.selectedDestinationQCCompany?.id
      }

      if(this.isEdit) {
        this._palletService.update(request).subscribe({
          next: (v) => {
            this._messageService.add({ severity: 'success',
              summary: this.translate.instant('MessageService.Summary.SuccessAction'),
              detail: this.translate.instant('MessageService.Pallet.EditSuccess') })
            this.success = true
            this.submit = false
            this.loading = false
          },
          error: (e) => {
            console.log(e)
            this.success = false
            this.submit = false
            this.loading = false
            let errorMessage = ''
            switch(this.profile?.lang) {
              case 'es':
                errorMessage = e.error.messageEs
                break
              case 'en':
                errorMessage = e.error.messageEn
                break
            }
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.Pallet.EditError') + ' ' + errorMessage })
          },
          complete: () => console.info('Pallets updated successfully')
        })
      }
      else {
        this._palletService.Create(request).subscribe({
          next: (v) => {
            this._messageService.add({ severity: 'success',
              summary: this.translate.instant('MessageService.Summary.SuccessAction'),
              detail: this.translate.instant('MessageService.Pallet.CreateSuccess') })
            this.success = true
            this.submit = false
            this.loading = false
          },
          error: (e) => {
            console.log(e)
            this.success = false
            this.submit = false
            this.loading = false
            let errorMessage = ''
            switch(this.profile?.lang) {
              case 'es':
                errorMessage = e.error.messageEs
                break
              case 'en':
                errorMessage = e.error.messageEn
                break
            }
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.Pallet.CreateError') + ' ' + errorMessage })
          },
          complete: () => console.info('Pallets created successfully')
        })
      }
    }
    else {
      this.loading = false
    }
  }

  saveClosedSale() {
    this.submit = true
    this.loading = true
    if(this.detail) {
      if(this.directSaleFormGroup.valid) {
        if(!this.isValidDatatable()) {
          this.loading = false
          return
        }

        const request: CreatePalletsRequest = {
          pallets: this.pallets,
          importOrderDetailId: this.detail.id,
          originQCCompanyId: this.selectedOriginQCCompany?.id,
          fridgeStorageId: this.directSaleFormGroup.controls['fridgeStorageId'].value,
          harvestDate: this.directSaleFormGroup.controls['harvestDate'].value,
          shelfLife: this.directSaleFormGroup.controls['shelfLife'].value,
          readyToLoadDate: this.directSaleFormGroup.controls['readyToLoadDate'].value
        }

        if(this.isEdit) {
          this._palletService.update(request).subscribe({
            next: (v) => {
              this._messageService.add({ severity: 'success',
                summary: this.translate.instant('MessageService.Summary.SuccessAction'),
                detail: this.translate.instant('MessageService.Pallet.EditSuccess') })
              this.success = true
              this.submit = false
              this.loading = false
            },
            error: (e) => {
              console.log(e)
              this.success = false
              this.submit = false
              this.loading = false
              this._messageService.add({ severity: 'error',
                summary: this.translate.instant('MessageService.Summary.FailedAction'),
                detail: this.translate.instant('MessageService.Pallet.EditError') + e.error })
            },
            complete: () => console.info('Pallets updated successfully')
          })
        }
        else {
          this._palletService.createClosedSale(request).subscribe({
            next: (v) => {
              this._messageService.add({ severity: 'success',
                summary: this.translate.instant('MessageService.Summary.SuccessAction'),
                detail: this.translate.instant('MessageService.Pallet.CreateSuccess') })
              this.success = true
              this.submit = false
              this.loading = false
            },
            error: (e) => {
              console.log(e)
              this.success = false
              this.submit = false
              this.loading = false
              this._messageService.add({ severity: 'error',
                summary: this.translate.instant('MessageService.Summary.FailedAction'),
                detail: this.translate.instant('MessageService.Pallet.CreateError') + e.error })
            },
            complete: () => console.info('Pallets created successfully')
          })
        }
      }
      else {
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.FormMessages.FormInvalid') })
        this.loading = false
      }
    }
    else {
      this.loading = false
    }
  }

  private isValidDatatable(): boolean {
    let isValid = true

    if(!this.pallets)
      return false

    this.pallets.forEach(x => {
      if(!x.palletNumber || x.palletNumber.length < 1) {
        isValid = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Pallet.IncompletePalletNumbersError') })
        return
      }

      if(!x.excelImportSpecies || x.excelImportSpecies.length < 1) {
        isValid = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Pallet.IncompleteSpeciesError') })
        return
      }

      if(!x.excelImportVariety || x.excelImportVariety.length < 1) {
        isValid = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Pallet.IncompleteVarietyError') })
        return
      }

      if(!x.producer || x.producer.length < 1) {
        isValid = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Pallet.IncompleteProducerError') })
        return
      }

      if(!isValid) return
    })

    return isValid
  }

  showPalletPriceModal() {
    if(this.isClosedSale){
      this.calculateTablePrice()
      // this.visiblePalletsPrice = true
    }

  }

  showCurrentPalletPriceModal() {
    if(this.isClosedSale){
      // this.calculateTablePrice()
      this.visibleCurrentPalletsPrice = true
    }
  }

  onCancelNewFridgestorage() {
    this.directSaleFormGroup.controls['fridgeStorageId'].setValue(null)
    this.loadFridgestorageDialogVisible = false
    this.submit = false
    this.loading = false
  }

  onCancel() {
    if(this.detail) {
      if(this.isClosedSale) {
        this._router.navigate(['/' + Routes.exporterClosedSaleOrdersPage, this.directSaleProgramId])
      }
      else {
        this._router.navigate(['/' + Routes.userOrderDetailPage, this.detail.importOrderId])
      }
    }
  }

  onAccept() {
    if (this.detail) {
      this._router.navigate(['/' + Routes.qcOrderDetailPage, this.detail.id])
    }
  }

  getErrorMessage(formGroup: FormGroup, fieldName: string): string {
    return ErrorMessageUtil.getErrorMessage(formGroup, this.translate, fieldName)
  }

  goToImportOrderPage() {
    if(this.detail) {
      this._router.navigate(['/' + Routes.userOrderDetailPage, this.detail.importOrderId])
    }
  }

  getSpeciesName(species: Species | undefined): string {
    if(this.profile)
      return SpeciesUtils.getSpeciesName(this.profile.lang, species)
    return ''
  }

  viewOriginQCReviewDocument(review: QualityControlReview) {
    this._documentService.getOriginQCReviewDocument(review.guid).subscribe((resultBlob: any) => {
      const downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }

  viewOriginQCConsolidationDocument(review: QualityControlReview) {
    this._documentService.getOriginQCConsolidationDocument(review.guid).subscribe((resultBlob: any) => {
      const downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }

  get showTypeOrderLabel(): string {
    if (this.profile?.type === 1) return 'UserPages.UserPurchaseOrders.VerifyPalletsPage.ImportOrderSequenceExportLabel'
    return 'UserPages.UserPurchaseOrders.VerifyPalletsPage.ImportOrderSequenceLabel'
  }

  get showTypeOrderDetailLabel(): string {
    if (this.profile?.type === 1) return 'UserPages.UserPurchaseOrders.VerifyPalletsPage.ImportOrderDetailSequenceExportLabel'
    return 'UserPages.UserPurchaseOrders.VerifyPalletsPage.ImportOrderDetailSequenceLabel'
  }

  getLabelsOrder() {
    if(this.profile?.profileType == 'Exporter') {
      this.orderType = 'Export'
    }
  }


  withoutNOQC() {
    this.visibleDisclaimerQCDialog = false
    this.loading = true;

    if(this.detail){  
      this._importDetailService.removeQcPayment( this.detail.id).subscribe({
        next: (v) => {
          this.loading = false
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: this.translate.instant('MessageService.QualityControlPayment.UpdateQcCompanySuccess') })
        },
        error: (e) => {
          this.loading = false;
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.QualityControlPayment.UpdateQcCompanyError') })
            this.itemBeforeSelect = this.itemQCSelected;
        }
      })
    }
  }

  withNOQC() {
    this.visibleDisclaimerQCDialog = false
    this.setQcBefore()
    // this.visibleQCCompany = true
  }

  showSelectQCCompany() {
    this.visibleQCCompany = true
  }


  onQCCompanyTableRowSelect(event: any) {
    this.itemBeforeSelect = this.itemQCSelected;
    if(this.detail){

      //Si selecciona NOQC
      if (event.data.id == 0 && event.data.profileId == 0) {
        this.visibleQCCompany = false
        this.visibleDisclaimerQCDialog = true
        this.companySpeciesSelected = [];
      }
      else 
      {
        const company = event.data
        this.itemQCSelected = company

        var request: UpdateControlCompanyRequest = {
          importOrderDetailGuId: this.detail.id,
          qualityControlCompanyId: company.id
        }

    
        this.loading = true;
        this._importDetailService.updateQcCompany(request).subscribe({
          next: (v) => {
            this.companySpeciesSelected = company.qualityControlCompanySpecies;
            this.visibleQCCompany = false
            this.loading = false
            this._messageService.add({ severity: 'success',
              summary: this.translate.instant('MessageService.Summary.SuccessAction'),
              detail: this.translate.instant('MessageService.QualityControlPayment.UpdateQcCompanySuccess') })
          },
          error: (e) => {
            this.loading = false;
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.QualityControlPayment.UpdateQcCompanyError') })
              this.itemBeforeSelect = this.itemQCSelected;
          }
        })
      }
    }
  }
}
