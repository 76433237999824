import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Country } from "src/app/domain/models/Country";

@Injectable({providedIn: 'root'})
export abstract class CountryGateway {
    abstract getAll(): Observable<Array<Country>>
    abstract getById(id: number): Observable<Country>
    abstract create(country: Country): Observable<Country>
    abstract update(country: Country): Observable<Country>
    abstract delete(id: number): Observable<Country>
    abstract getAllByIsOrigin(isOrigin: boolean): Observable<Array<Country>>
}
