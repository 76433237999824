import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { OperationDocumentTypeGateway } from 'src/app/application/gateway/OperationDocumentTypeGateway';
import { OperationService } from 'src/app/application/service/OperationService';
import { OperationDocumentType, UploadOperationFileRequest } from 'src/app/domain/models/Operation';
import { Routes } from 'src/app/infrastructure/routes';


interface UploadFile {
  type: number;
  name: string;
}

interface UploadEvent {
  originalEvent: Event;
  files: File[];
}

@Component({
  selector: 'app-admin-operation',
  templateUrl: './admin-operation.component.html',
  styleUrls: ['./admin-operation.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class AdminOperationComponent implements OnInit {

  orderId: any | undefined
  uploadedFiles: any[] = []
  loading: boolean = false
  name: string = ""
  documentTypeId: string = ""

  operationDocumentTypes: OperationDocumentType[] = []

  files: UploadFile[] = [
    { type: 5, name:'Instructivo'},
    { type: 0, name:'Factura de exportacion'},
    { type: 3, name:'Certificado fitosanitario'},
    { type: 1, name:'BL'},
    { type: 4, name:'Certificado de origen'},
    { type: 6, name:'Booking'},
  ]

  selectedUploadFile: UploadFile = { type: 5, name:'Instructivo'};

  constructor( private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _operationService: OperationService,
    private _messageService: MessageService,
    public translate: TranslateService,
    private _operationDocumentTypeService: OperationDocumentTypeGateway){}

  ngOnInit(): void {
    this.loadExportDocumentType()
    this._activatedRoute.params.subscribe(params => {
      this.orderId = params['orderId']
    })
  }

  loadExportDocumentType() {
    this.operationDocumentTypes =  []
    this._operationDocumentTypeService.getAll().subscribe({
      next: (v) => {
        this.operationDocumentTypes = v
        this.loading = false
      },
      error: (e) => {
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.ExportOfferDocument.ManyRetrieveError') });
        console.log(e)
        this.loading = false
      },
      complete: () =>  {
        this.loading = false
        console.info('Operation Document types query successfully')
      }
    })
  }

  onSelect(event: any) {
    this.uploadedFiles = []
    for(let file of event.files) {
        this.uploadedFiles.push(file);
    }

    if(event.files[0])
      this.name = event.files[0].name
  }

  upload(){
    this.loading = true
    if (!this.documentTypeId) {
      this.loading = false;
      this._messageService.add({ severity: 'error', summary: 'Accion fallida', detail: 'Debe seleccionar tipo de documento' })
      return;
    }

    if(!this.uploadedFiles[0]){
      this.loading = false;
      this._messageService.add({ severity: 'error', summary: 'Accion fallida', detail: 'Por favor cargue un documento' })

    }else{
      console.log("aqui estamos ==> ", this.documentTypeId)
      const uploadFileRequest: UploadOperationFileRequest = {
        orderId: this.orderId ? this.orderId: 0,
        name: this.name,
        pdf: this.uploadedFiles[0],
        operationDocumentTypeId: this.documentTypeId
      }
      console.log("aqui uploadFileRequest ==> ", uploadFileRequest)
      this._operationService.uploadFile(uploadFileRequest).subscribe({
        next: (v) => {
          this._messageService.add({ severity: 'success', summary: 'Confirmed', detail: 'Se registró el documento correctamente.' })
          this.loading = false
        },
        error: (e) => {
          this.loading = false
          console.log(e)
          this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudo recuperar el documento.' });
        },
        complete: () => {
          this.loading = false
          console.log('Se registró el documento correctamente.')
        }
      })
    }

  }

  goToOrderDetail() {
    this._router.navigate(['/' + Routes.adminOrderDetail, this.orderId])
  }

  onSelectDocumentType(event: any) {
    this.documentTypeId = event.value
  }
}
