import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { NewProductCertificate, ProductCertificate } from "src/app/domain/models/ProductCertificate";
import { Routes } from "src/app/infrastructure/routes";
import { ProductCertificateGateway } from "../gateway/ProductCertificateGateway";

@Injectable({providedIn: 'root'})
export class ProductCertificateService extends ProductCertificateGateway {

  constructor(private _http: HttpClient) {
    super()
  }

  getAll(): Observable<ProductCertificate[]> {
    throw new Error("Method not implemented.");
  }

  getAllByUsername(): Observable<ProductCertificate[]> {
    return this._http.get<ProductCertificate[]>(Routes.productCertificateApi + '/GetAllByProfile')
  }

  getAllByExportOfferId(exportOfferId: number): Observable<ProductCertificate[]> {
    return this._http.get<ProductCertificate[]>(Routes.productCertificateApi + '/GetAllByExportOfferId', {params: {exportOfferId}})
  }

  getById(productCertificateId: number): Observable<ProductCertificate> {
    return this._http.get<ProductCertificate>(Routes.productCertificateApi + '/GetById', {params: {productCertificateId}})
  }

  create(productCertificate: NewProductCertificate): Observable<ProductCertificate> {
    return this._http.post<ProductCertificate>(Routes.productCertificateApi + '/Create', this.getNewFormData(productCertificate))
  }

  update(productCertificate: ProductCertificate): Observable<ProductCertificate> {
    return this._http.put<ProductCertificate>(Routes.productCertificateApi + '/Update', this.getFormData(productCertificate))
  }

  delete(productCertificateId: number): Observable<ProductCertificate> {
    return this._http.delete<ProductCertificate>(Routes.productCertificateApi + '/Delete', {params: {productCertificateId}})
  }

  getNewFormData(certificate: NewProductCertificate) {
    const formData = new FormData()
    formData.append("name", certificate.name)
    formData.append("pdf", certificate.pdf ? certificate.pdf : '')
    formData.append("documentIssueDate", certificate.documentIssueDate ? certificate.documentIssueDate.toDateString() : '')
    formData.append("expirationDate", certificate.expirationDate ? certificate.expirationDate.toDateString() : '')
    formData.append("productCertificateTypeId", certificate.productCertificateTypeId.toString())
    formData.append("exportOfferId", certificate.exportOfferId ? certificate.exportOfferId.toString() : '')
    return formData
  }

  getFormData(certificate: ProductCertificate) {
    const formData = new FormData()
    formData.append("id", certificate.id.toString())
    formData.append("name", certificate.name)
    formData.append("username", certificate.username)
    formData.append("pdf", certificate.pdf ? certificate.pdf : '')
    formData.append("documentIssueDate", certificate.documentIssueDate ? certificate.documentIssueDate.toDateString() : '')
    formData.append("expirationDate", certificate.expirationDate ? certificate.expirationDate.toDateString() : '')
    formData.append("productCertificateTypeId", certificate.productCertificateTypeId.toString())
    return formData
  }

}
