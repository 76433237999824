import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, FilterMatchMode, MenuItem, MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { ImportOrderGateway } from 'src/app/application/gateway/ImportOrderGateway';
import { PalletGateway } from 'src/app/application/gateway/PalletGateway';
import { ProfileGateway } from 'src/app/application/gateway/ProfileGateway';
import { GenericOrderDetail, ImportOrder, ImportOrderDetailInformation } from 'src/app/domain/models/ImportOrder';
import { LoadOrderListItem } from 'src/app/domain/models/LoadOrder';
import { MasterLoad, NewPallet } from 'src/app/domain/models/Pallet';
import { BasicUser } from 'src/app/domain/models/User';
import { Routes } from 'src/app/infrastructure/routes';
import { ImportOrderStatus, OrderUtils } from 'src/app/ui/util/order-utils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-load-orders',
  templateUrl: './user-load-orders.component.html',
  styleUrls: ['./user-load-orders.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class UserLoadOrdersComponent implements OnInit {
  DefaultLoadingInfoText: string = "(Post Loading information)"
  masterLoads: MasterLoad[] = []

  tabMenuItems: MenuItem[] = []
  activeItem: MenuItem = {}
  orders: LoadOrderListItem[] = []
  filteredOrders: LoadOrderListItem[] = []
  profile: BasicUser | undefined
  orderDetailInformation: ImportOrderDetailInformation | undefined
  pallets: NewPallet  [] = []
  shipments: LoadOrderListItem[] = []

  loading: boolean = false
  statuses: any[] = [];
  showPallets: boolean = false
  isEdit: boolean = true

  searchTerm: string = '';


  @Input()
  activeItemIndex = 0

  constructor(private _router: Router,
    private _authService: AuthGateway,
    private _messageService: MessageService,
    private _importOrderService: ImportOrderGateway,
    private _palletService: PalletGateway,
    public translate: TranslateService) {
  }

  ngOnInit(): void {
    this.profile = this._authService.getUser()

    this.statuses = [
      { label: 'Created', value: 0 },
      { label: 'Pending Verify Pallets', value: 1 },
      { label: 'QC Pallets Verified', value: 2 },
      { label: 'Order Confirmed', value: 3 },
      { label: 'Payment Confirmed', value: 4 }
    ];

    this._importOrderService.getAllMyLoads().subscribe({
      next: (v) => {
        this.orders = v
        this.filteredOrders = v
        console.log("orders: ",v)

        this.generateShipmentData(this.filteredOrders)

        this.loading = false
      },
      error: (e) => {
        this.loading = false
        console.log(e)
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.ImportOrder.ManyRetrieveError') });
      },
      complete: () => console.info('Load Order query successfully')
    })
  }

  getPalletsSeverity(order: LoadOrderListItem): string {
    return 'success'
  }

  getStatusSeverity(status: number): string {
    if (status === 1 || status === 2) {
      return 'warning';
    } else if (status === 3 || status === 4) {
      return 'success';
    } else {
      return 'info';
    }
  }

  getStatusIcon(status: number): string {
    if (status === 0 || status === 1 || status === 2) {
      return 'pi pi-exclamation-triangle';
    } else if (status === 3 || status === 4) {
      return 'pi pi-check';
    } else {
      return '';
    }
  }

  getDocumentationSeverity(detail: GenericOrderDetail): string {
    return 'success';
  }

  exportExcel() {
    var titleExporImpor = this.profile?.type === 1 ? "Importer" : "Exporter";
    import('xlsx').then((xlsx) => {
      let sheets: any[] = []
      if(this.filteredOrders) {
        this.filteredOrders.forEach(x => {
          x.details.forEach(detail => {
            let row = {
              "Order": x.sequence,
              "Container number": x.containerNumber,
              "Port of loading": x.originPort?.country,
              "Port of discharge": x.destinationPort?.country,
              "Pallets confirmados": x.totalPallets,
              "Week": x.week,
              "ETA": x.eta,
              "ETD": x.etd,
              "Species": detail.speciesNameEn,
              "Pallet quantity": detail.palletsQuantity,
              "Boxes quantity": detail.boxesQuantity,
              [titleExporImpor]: detail.exporterCompanyName
            }
            sheets.push(row)
          });
        })
      }
      const worksheet = xlsx.utils.json_to_sheet(sheets);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'MyLoads');
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    var FileSaver = require('file-saver');
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  getSpeciesName(orderDetail: GenericOrderDetail): string {
    if(this.profile) {
      switch(this.profile.lang) {
        case 'es':
          return orderDetail.speciesNameEs
        case 'en':
          return orderDetail.speciesNameEn
        default:
          return orderDetail.speciesNameEn
      }
    }
    return ''
  }

  redirectToDocumentation(orderId: number) {

    this._router.navigate(['/' + Routes.userOrderDetailPage, orderId], { fragment: 'user-order-documentation' });
  }

  filterOrders() {
    if (!this.searchTerm.trim()) {
      // Si el término de búsqueda está vacío, mostrar todos los pedidos
      this.filteredOrders = this.orders;
    } else {
      // Filtrar los pedidos basados en el término de búsqueda
      this.filteredOrders = this.orders.filter(order =>
        order.details.some(detail =>
          // Object.values(detail).some(val => {
            detail.speciesNameEn?.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
            detail.speciesNameEs?.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
            detail.varietyName?.toLowerCase().includes(this.searchTerm.toLowerCase())
          // }
        ))
      // )
    }

    this.generateShipmentData(this.filteredOrders)

  }

  viewPallets(orderDetailId: number) {
    if(orderDetailId) {
      this.loading = true
      this._palletService.getByImportOrderDetailId(orderDetailId).subscribe({
        next: (v) => {
          this.orderDetailInformation = v
          this.pallets = this.orderDetailInformation.pallets.filter(x => x.status === 2)
          this.showPallets = true
        },
        error: (e) => {
          console.log(e)
          this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudo recuperar el detalle de orden.' });
        },
        complete: () =>  {
          this.loading = false
          console.info('Import Order Detail query successfully')
        }
      })
    }
  }

  generateShipmentData(data: LoadOrderListItem[]) {
    this.shipments = []
    data.forEach(shipment => {
      shipment.details.forEach(detail => {
        this.shipments.push({
          ...shipment,
          details: [detail],
          exporterCompanyName: detail.exporterCompanyName
        });
      });
    });
  }

  getOrderOrigin(order: LoadOrderListItem)
  {

    if(order.transportType === 0){
      return order.originPort ? order.originPort?.name : this.DefaultLoadingInfoText
    }else{
      return order.originAirport ? order.originAirport?.name : this.DefaultLoadingInfoText
    }
  }

  getOrderDestiny(order: LoadOrderListItem)
  {
    if(order.transportType === 0){
      return order.destinationPort ? order.destinationPort?.name : this.DefaultLoadingInfoText
    }else{
      return order.destinationAirport ? order.destinationAirport?.name : this.DefaultLoadingInfoText
    }
  }


}
