<app-user-dashboard [userMenuActiveItemIndex]="0" [activeItem]="'export-offers-suggestions'">
  <p-card [style]="{'min-height':'200px'}">
    <ng-template pTemplate="header">
      <div class="flex justify-content-between align-items-center" style="padding: 15px;">
        <h2>{{'UserPages.UserExportOfferSugesstionPage.PageTitle'| translate}}</h2>
      </div>
      <div class="flex justify-content-between align-items-center" style="padding: 15px;">
        <p>{{'UserPages.UserExportOfferSugesstionPage.PageDescriptionText'| translate}}</p>
      </div>
    </ng-template>
    <ng-template pTemplate="body">
      <p-table
        [value]="suggestions"
        [paginator]="true"
        [rows]="10"
        [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[10, 25, 50]"
        [loading]="loading"
        responsiveLayout="stack"
        [breakpoint]="'600px'">
        <ng-template pTemplate="header">
          <tr>
            <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserExportOfferSugesstionPage.SuggestionTable.ProductHeader'| translate}}</div></th>
            <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserExportOfferSugesstionPage.SuggestionTable.OrderCodeHeader'| translate}}</div></th>
            <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserExportOfferSugesstionPage.SuggestionTable.OriginHeader'| translate}}</div></th>
            <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserExportOfferSugesstionPage.SuggestionTable.DestinationHeader'| translate}}</div></th>
            <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserExportOfferSugesstionPage.SuggestionTable.MissinPalletsHeader'| translate}}</div></th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-suggestion>
          <tr>
            <td><div class="flex justify-content-center align-items-center">{{suggestion.speciesNameEn}} - {{suggestion.varietyName}}</div></td>
            <td><div class="flex justify-content-center align-items-center">{{padWithLeadingZeros(suggestion.importOrderSequence)}}</div></td>
            <td><div class="flex justify-content-center align-items-center">{{suggestion.fridgeStorageCountry | countryDescription}}</div></td>
            <td><div class="flex justify-content-center align-items-center">{{suggestion.portToName}} / {{suggestion.portToCountry}}</div></td>
            <td><div class="flex justify-content-center align-items-center">{{suggestion.palletsQuantity}}</div></td>
            <td>
              <div class="flex align-items-center justify-content-end gap-4">
                <p-button label="{{'UserPages.UserExportOfferSugesstionPage.SuggestionTable.SeeOfferButton'| translate}}" (onClick)="goToSuggestions(suggestion.id)"></p-button>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </ng-template>
  </p-card>
</app-user-dashboard>

<p-dialog [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleOfferDetailDialog" [style]="{width: '80%'}">
  <app-export-offer-detail [offer]="exportOffer" [productCertificateTypes]="selectedProductCertificateTypes" [packagingTypes]="selectedPackagingTypes"></app-export-offer-detail>
  <ng-template pTemplate="footer">
    <p-button label="{{'UserPages.UserExportOfferSugesstionPage.SuggestionTable.CloseButton'| translate}}" (click)="visibleOfferDetailDialog = false"></p-button>
  </ng-template>
</p-dialog>

<p-toast></p-toast>
