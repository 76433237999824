import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { ImportOrderGateway } from 'src/app/application/gateway/ImportOrderGateway';
import { ImportOrderInformation } from 'src/app/domain/models/ImportOrder';
import { BasicUser } from 'src/app/domain/models/User';

@Component({
  selector: 'app-forwarder-order-detail',
  templateUrl: './forwarder-order-detail.component.html',
  styleUrls: ['./forwarder-order-detail.component.css'],
  providers:[MessageService]
})
export class ForwarderOrderDetailComponent {

  orderId: any | undefined
  order: ImportOrderInformation | undefined
  profile: BasicUser | undefined

  loading: boolean = false

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _messageService: MessageService,
    private _authService: AuthGateway,
    private _importOrderService: ImportOrderGateway,
    public translate: TranslateService
    ) {
      this._activatedRoute.params.subscribe(params => {
        this.orderId = params['orderId']
      })
  }

  ngOnInit(): void {
    this.loadInfo()
  }

  loadInfo() {
    if(this.orderId) {
      this.profile = this._authService.getUser()
      this.loadImportOrder()
    }
  }

  loadImportOrder() {
    this.loading = true
    if(this.orderId) {
      this._importOrderService.getForwarderOrderById(this.orderId).subscribe({
        next: (v) => {
          this.order = v
          this.loading = false
        },
        error: (e) => {
          console.log(e)
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ImportOrder.SingleRetrieveError') });
          this.loading = false
        },
        complete: () => console.info('Import Order query successfully')
      })
    }
  }

}
