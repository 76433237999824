<app-user-dashboard [userMenuActiveItemIndex]="0" [activeItem]="'corporative'">
  <p-card [style]="{'min-height':'200px'}">
    <ng-template pTemplate="header">
      <div class="flex justify-content-between flex-wrap align-items-center" style="padding: 15px;">
        <h2>{{title}}</h2>
      </div>
    </ng-template>
    <form [formGroup]="formGroup">
      <div class="p-fluid formgrid grid" style="padding: 20px;">
        <div class="field col-12 md:col-6 lg:col-6" style="margin-bottom: 40px;">
          <label for="name" style="font-weight: bolder;">{{'UserPages.UserProductCertificates.Form.NameLabel' | translate}} *</label>
          <input id="name" formControlName="name" type="text" required pInputText placeholder="{{'UserPages.UserProductCertificates.Form.NameLabel' | translate}}"/>
          <small *ngIf="submit && formGroup.controls['name'].invalid" class="p-error">{{getErrorMessage('name')}}</small>
        </div>
        <div class="field col-12 md:col-6 lg:col-6" style="margin-bottom: 40px;">
          <label for="certificateType" style="font-weight: bolder;">{{'UserPages.UserProductCertificates.Form.CertificateTypeLabel' | translate}} *</label>
          <p-dropdown inputId="certificateType" formControlName="productCertificateTypeId" [options]="productCertificateTypes" optionLabel="name" optionValue="id" placeholder="{{'UserPages.UserProductCertificates.Form.CertificateTypeLabel' | translate}}"></p-dropdown>
          <small *ngIf="submit && formGroup.controls['productCertificateTypeId'].invalid" class="p-error">{{getErrorMessage('productCertificateTypeId')}}</small>
        </div>
        <div class="field col-12 md:col-6 lg:col-6" style="margin-bottom: 40px;">
          <label for="issueDate" style="font-weight: bolder;">{{'UserPages.UserProductCertificates.Form.IssueDateLabel' | translate}} </label>
          <p-calendar
            id="issueDate"
            appendTo="body"
            formControlName="documentIssueDate"
            [showButtonBar]="true"
            [showIcon]="true"
            placeholder="{{'UserPages.UserProductCertificates.Form.IssueDateLabel' | translate}}"
            [showWeek]="true"
            [firstDayOfWeek]="1"></p-calendar>
        </div>
        <div class="field col-12 md:col-6 lg:col-6" style="margin-bottom: 40px;">
          <label for="expirationDate" style="font-weight: bolder;">{{'UserPages.UserProductCertificates.Form.ExpireDateLabel' | translate}}</label>
          <p-calendar
            id="expirationDate"
            appendTo="body"
            formControlName="expirationDate"
            [showButtonBar]="true"
            [showIcon]="true"
            placeholder="{{'UserPages.UserProductCertificates.Form.ExpireDateLabel' | translate}}"
            [showWeek]="true"
            [firstDayOfWeek]="1"></p-calendar>
        </div>
        <div class="field col-12" style="margin-bottom: 40px;">
          <label>{{'UserPages.UserProductCertificates.Form.LoadDocumentLabel' | translate}}</label>
          <p-fileUpload
            #fileUploader
            (onSelect)="onSelect($event)"
            [showUploadButton]="false"
            [multiple]="false"
            accept=".pdf"
            [maxFileSize]="5000000"
            chooseLabel="{{'UserPages.UserProductCertificates.Form.SelectFile' | translate}}"
            cancelLabel="{{'UserPages.UserProductCertificates.Form.CancelSelectFile' | translate}}"/>
        </div>
        <div class="col-12">
          <div class="grid" style="margin-top: 30px;">
            <div class="col-12 md:col-6 lg:col-6"></div>
            <div class="col-12 md:col-6 lg:col-6">
              <div class="grid p-fluid">
                <div class="col-6">
                  <p-button label="{{'UserPages.UserProductCertificates.Form.BackButtonLabel' | translate}}" [disabled]="loading" styleClass="p-button-outlined" (click)="cancel()"/>
                </div>
                <div class="col-6">
                  <p-button label="{{'UserPages.UserProductCertificates.Form.SaveButtonLabel' | translate}}" [loading]="loading" (click)="save()"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </p-card>
</app-user-dashboard>

<p-toast></p-toast>
