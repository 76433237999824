import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { ProfileGateway } from 'src/app/application/gateway/ProfileGateway';
import { Exporter, ExporterImporterCreate, Importer } from 'src/app/domain/models/Profile';
import { Routes } from 'src/app/infrastructure/routes';

@Component({
  selector: 'app-admin-exporter-importers-page',
  templateUrl: './admin-exporter-importers-page.component.html',
  styleUrls: ['./admin-exporter-importers-page.component.css'],
  providers: [MessageService]
})
export class AdminExporterImportersPageComponent implements OnInit {

  exporterId: number | undefined
  importers: Importer[] = []
  exporter: Exporter | undefined

  loading: boolean = false

  constructor(private _router: Router,
    private _messageService: MessageService,
    private _activatedRoute: ActivatedRoute,
    public translate: TranslateService,
    private _profileService: ProfileGateway) {
    this._activatedRoute.params.subscribe(params => {
      this.exporterId = params['exporterId']
    })
  }

  ngOnInit(): void {
    this.loadImporters()
  }

  loadImporters() {
    if(this.exporterId) {
      this.loading = true
      this._profileService.getAllImportersForExporterAsociation(this.exporterId).subscribe({
        next: (v) => {
          console.log(v)
          this.importers = v.importers
          this.exporter = v.exporter
          this.loading = false
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: 'No se pudieron recuperar los importers' })
        },
        complete: () => console.info('Importers query successfully')
      })
    }
  }

  onToogleAsociation(importerId: number, event: any) {
    if(this.exporterId) {
      const request: ExporterImporterCreate = {
        exporterId: this.exporterId,
        importerId: importerId,
        isAsociated: event.checked
      }

      this.loading = true
      this._profileService.toogleExporterImporterAsociation(request).subscribe({
        next: (v) => {
          this.loading = false
          this.loadImporters()
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: 'No se pudo actualizar la asociación' })
        },
        complete: () => console.info('ExporterImporter Updated successfully')
      })
    }
  }

  goToAdminUsers() {
    this._router.navigate(['/'+Routes.adminUsers])
  }
}
