import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { IssueService } from 'src/app/application/service/IssueService';

@Component({
  selector: 'app-user-support',
  templateUrl: './user-support.component.html',
  styleUrls: ['./user-support.component.css'],
  providers: [MessageService]
})
export class UserSupportComponent implements OnInit {
  loading: boolean = false
  submit: boolean = false
  success: boolean = false

  formGroup: FormGroup = this._formBuilder.group({
    title: ['', Validators.required],
    description: ['', Validators.required]
  })

  constructor(private _formBuilder: FormBuilder,
    private _messageService: MessageService,
    private _authService: AuthGateway,
    private _issueService: IssueService,
    public translate: TranslateService) {

  }

  ngOnInit(): void {
  }

  save() {
    this.loading = true
    this.submit = true
    if(this.formGroup.valid) {
      let issue = {
        username: this._authService.getUsername(),
        title: this.formGroup.controls['title'].value,
        description: this.formGroup.controls['description'].value
      }

      this._issueService.create(issue).subscribe({
        next: (v) => {
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: this.translate.instant('UserPages.UserSupport.CreateSuccess') });
          this.submit = false
          this.loading = false
          this.success = true
        },
        error: (e) => {
          this.loading = false
          this.success = false
          console.log(e)
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('UserPages.UserSupport.CreateError') });
        },
        complete: () => console.info('Issue query successfully')
      })
    }
    else {
      this.loading = false
      this.success = false
    }
  }

  getErrorMessage(fieldName: string): string {
    if (this.formGroup.controls[fieldName].hasError('required')) {
      return this.translate.instant('FormErrorMessage.FieldValueRequired')
    }
    if (this.formGroup.controls[fieldName].hasError('email')) {
      return this.translate.instant('FormErrorMessage.EmailFieldEmailFormatError')
    }
    return ''
  }
}
