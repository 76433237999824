<app-user-dashboard [userMenuActiveItemIndex]="0" [activeItem]="'corporative'">
  <p-card [style]="{'min-height':'200px'}">
    <ng-template pTemplate="header">
      <div class="flex justify-content-start align-items-start flex-column" style="margin-left: 15px;">
        <h2>{{title}}</h2>
        <p>{{'UserPages.UserConsignees.ConsigneeForm.RequiredFieldText' | translate}}</p>
      </div>
    </ng-template>
    <form [formGroup]="formGroup">
      <div class="p-fluid formgrid grid" style="padding: 15px;">
        <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
          <label for="name"
            style="font-weight: bolder;">{{'UserPages.UserConsignees.ConsigneeForm.ConsigneeNameFieldLabel' | translate}} *</label>
          <input maxlength="{{formLimit.nameLimit}}" id="name" formControlName="name" type="text" required pInputText
            placeholder="{{'UserPages.UserConsignees.ConsigneeForm.ConsigneeNameFieldPlaceholder' | translate}}" />
          <small *ngIf="submit && formGroup.controls['name'].invalid"
            class="p-error">{{getErrorMessage('name')}}</small>
        </div>
        <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
          <label for="vat" style="font-weight: bolder;">{{'UserPages.UserConsignees.ConsigneeForm.VatFieldLabel' | translate}} *</label>
          <input id="vat" formControlName="vat" maxlength="{{formLimit.vatLimit}}" type="text" required pInputText placeholder="VAT" />
          <small *ngIf="submit && formGroup.controls['vat'].invalid" class="p-error">{{getErrorMessage('vat')}}</small>
        </div>
        <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
          <label for="country" style="font-weight: bolder;">{{'UserPages.UserConsignees.ConsigneeForm.CountryFieldLabel' | translate}} *</label>
          <p-dropdown id="country" formControlName="contactCountry" [options]="countries" optionLabel="name"
            [filter]="true" filterBy="name" [showClear]="true" placeholder="{{'UserPages.UserConsignees.ConsigneeForm.CountryFieldPlaceholder' | translate}}"
            (onChange)="onChangeCountry()">
            <ng-template pTemplate="selectedItem">
              <div class="flex align-items-center gap-2" *ngIf="selectedCountry">
                <img [src]="'../../../../assets/img/flags/' + selectedCountry.code + '.png'" style="width: 18px" alt=""/>
                <span>{{ selectedCountry | countryDescription }}</span>
              </div>
            </ng-template>
            <ng-template let-country pTemplate="item">
              <div class="flex align-items-center gap-2">
                <img [src]="'../../../../assets/img/flags/' + country.code + '.png'" style="width: 18px" alt=""/>
                <div>{{ country | countryDescription }}</div>
              </div>
            </ng-template>
          </p-dropdown>
          <small *ngIf="submit && formGroup.controls['contactCountry'].invalid"
            class="p-error">{{getErrorMessage('contactCountry')}}</small>
        </div>
        <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
          <label for="contactName" style="font-weight: bolder;">{{'UserPages.UserConsignees.ConsigneeForm.ContactNameFieldLabel' | translate}} *</label>
          <input id="contactName"  maxlength="{{formLimit.nameLimit}}" formControlName="contactName" type="text" required pInputText
            placeholder="{{'UserPages.UserConsignees.ConsigneeForm.ContactNameFieldPlaceholder' | translate}}" maxlength="150" />
          <small *ngIf="submit && formGroup.controls['contactName'].invalid"
            class="p-error">{{getErrorMessage('contactName')}}</small>
        </div>
        <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
          <label for="contactAddress" style="font-weight: bolder;">{{'UserPages.UserConsignees.ConsigneeForm.AddressContactFieldLabel' | translate}} *</label>
          <input id="contactAddress" maxlength="{{formLimit.addressLimit}}" formControlName="contactAddress" type="text" required pInputText
            placeholder="{{'UserPages.UserConsignees.ConsigneeForm.AddressContactFieldPlaceholder' | translate}}" maxlength="150" />
          <small *ngIf="submit && formGroup.controls['contactAddress'].invalid"
            class="p-error">{{getErrorMessage('contactAddress')}}</small>
        </div>
        <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
          <label for="city" style="font-weight: bolder;">{{'UserPages.UserConsignees.ConsigneeForm.CityFieldLabel' | translate}} *</label>
          <input id="city" formControlName="contactCity" maxlength="{{formLimit.cityLimit}}" type="text" required pInputText placeholder="{{'UserPages.UserConsignees.ConsigneeForm.CityFieldPlaceholder' | translate}}" />
          <small *ngIf="submit && formGroup.controls['contactCity'].invalid"
            class="p-error">{{getErrorMessage('contactCity')}}</small>
        </div>
        <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
          <label for="contactEmail" style="font-weight: bolder;">{{'UserPages.UserConsignees.ConsigneeForm.ContactEmailFieldLabel' | translate}} *</label>
          <input id="contactEmail" formControlName="contactEmail" maxlength="{{formLimit.mailLimit}}"  type="text" required pInputText
            placeholder="{{'UserPages.UserConsignees.ConsigneeForm.ContactEmailFieldPlaceholder' | translate}}" maxlength="150" />
          <small *ngIf="submit && formGroup.controls['contactEmail'].invalid"
            class="p-error">{{getErrorMessage('contactEmail')}}</small>
        </div>
        <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
          <label for="contactPhone" style="font-weight: bolder;">{{'UserPages.UserConsignees.ConsigneeForm.ContactPhoneFieldLabel' | translate}} *</label>
          <input id="contactPhone"  maxlength="{{formLimit.phoneLimit}}" formControlName="contactPhone" type="text" required pInputText
            placeholder="{{'UserPages.UserConsignees.ConsigneeForm.ContactPhoneFieldPlaceholder' | translate}}" />
          <small *ngIf="submit && formGroup.controls['contactPhone'].invalid"
            class="p-error">{{getErrorMessage('contactPhone')}}</small>
        </div>
        <div class="col-12">
          <div class="grid" style="margin-top: 30px;">
            <div class="col-12 md:col-6 lg:col-6"></div>
            <div class="col-12 md:col-6 lg:col-6">
              <div class="grid p-fluid">
                <div class="col-6">
                  <p-button label="{{'UserPages.UserConsignees.ConsigneeForm.BackButton' | translate}}" [disabled]="loading" styleClass="p-button-outlined" (click)="cancel()" />
                </div>
                <div class="col-6">
                  <p-button label="{{'UserPages.UserConsignees.ConsigneeForm.SaveButton' | translate}}" [loading]="loading" (click)="save()" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </p-card>
</app-user-dashboard>

<p-toast></p-toast>
