import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { FrozenFruitExportOfferGateway } from 'src/app/application/gateway/FrozenFruitExportOfferGateway';
import { ImportOrderGateway } from 'src/app/application/gateway/ImportOrderGateway';
import { FrozenFruitExportOffer } from 'src/app/domain/models/FrozenFruitExportOffer';
import { ImportOrderDetailContainerItem, ImportOrderContainerItem, ImportOrder } from 'src/app/domain/models/ImportOrder';
import { QualityControlCompany } from 'src/app/domain/models/QCCompany';
import { BasicUser } from 'src/app/domain/models/User';
import { Routes } from 'src/app/infrastructure/routes';
import { OrderUtils } from 'src/app/ui/util/order-utils';

@Component({
  selector: 'app-frozen-fruit-purchase-orders-page',
  templateUrl: './frozen-fruit-purchase-orders-page.component.html',
  styleUrls: ['./frozen-fruit-purchase-orders-page.component.css'],
  providers: [ConfirmationService, MessageService]
})
export class FrozenFruitPurchaseOrdersPageComponent implements OnInit {
  loading: boolean = false
  visibleQCCompany: boolean = false
  waitConfirmation: boolean = false
  visibleOfferDetailDialog: boolean = false

  breadcrumbItems: MenuItem[] = []
  home: MenuItem

  profile: BasicUser | undefined

  orders: ImportOrderContainerItem[] = []
  qcCompanies: QualityControlCompany[] = []

  frozenFruitExportOffer: FrozenFruitExportOffer | undefined

  constructor(private _router: Router,
    private _authService: AuthGateway,
    private _messageService: MessageService,
    private _importOrderService: ImportOrderGateway,
    private _frozenFruitExportOfferService: FrozenFruitExportOfferGateway,
    public translate: TranslateService) {
    this.profile = this._authService.getUser()

    let breadcrumbItemLabel1 = 'Container'
    let breadcrumbItemLabel2 = ''
    let breadcrumbHomeItemLabel = ''

    if(this.profile) {
      switch(this.profile.lang) {
        case 'en':
          breadcrumbItemLabel1 = 'Container'
          breadcrumbItemLabel2 = 'Confirm order'
          breadcrumbHomeItemLabel = 'My orders'
          break
        case 'es':
          breadcrumbItemLabel1 = 'Container'
          breadcrumbItemLabel2 = 'Confirmar orden'
          breadcrumbHomeItemLabel = 'Mis órdenes'
          break
      }
    }

    this.breadcrumbItems = [{ label: breadcrumbItemLabel1, disabled: true }, { label: breadcrumbItemLabel2, disabled: true }]
    this.home = { label: breadcrumbHomeItemLabel, routerLink: ['/' + Routes.userImportOrdersCurrent] }
  }

  ngOnInit(): void {
    this.loadImportOrders()
  }

  loadImportOrders() {
    if(this.profile) {
      this.loading = true
      this._importOrderService.getAllForFrozenFruitShoppingCart().subscribe({
        next: (v) => {
          console.log(v)
          this.orders = v
          this.loading = false
          this.configureOrders()
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ImportOrder.ManyRetrieveError') })
        },
        complete: () => console.info('Import Orders query successfully')
      })
    }
  }

  configureOrders(): void {
    if(this.orders) {
      this.orders.forEach(o => {
        o.expanded = false
      })
    }
  }

  goToMarketplace() {
    this._router.navigate([Routes.marketplacePage])
  }

  getOrderTitle(order: ImportOrderContainerItem): string {
    return OrderUtils.getOrderTitle(order.sequence)
  }

  getOrderTotalPrice(order: ImportOrderContainerItem): number {
    return order.totalPrice + order.oceanFreight + this.getQCPrice(order)
  }

  setVisibleOrderDetail(order: ImportOrderContainerItem) {
    if (order) {
      order.expanded = !order.expanded
    }
  }

  getTotalBoxex(detail: ImportOrderDetailContainerItem): number {
    return detail.boxesPerPallet > 0 ? 20 * detail.boxesPerPallet : detail.boxesQuantity
  }

  getDetailTotalPrice(detail: ImportOrderDetailContainerItem): number {
    let total = 0
    if(detail) {
      if(detail.boxesPerPallet > 0) {
        total = detail.boxesPerPallet * 20 * detail.price
      }
      else {
        total = detail.boxesQuantity * detail.price
      }
    }
    return total
  }

  confirmOrder(order: ImportOrderContainerItem) {
    this.waitConfirmation = true
    const qualityControlCompanyId = order.qualityControlCompany ? order.qualityControlCompany.id : ''
    this._importOrderService.confirmOrder(order.id, qualityControlCompanyId).subscribe({
      next: (v) => {
        this._messageService.add({ severity: 'success',
          summary: this.translate.instant('MessageService.Summary.SuccessAction'),
          detail: this.translate.instant('ContainerOrderPage.OrderConfirmationSuccess') })
        this.loadImportOrders()
        this.waitConfirmation = false
      },
      error: (e) => {
        console.log(e)
        this.waitConfirmation = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('ContainerOrderPage.OrderConfirmationError') })
      },
      complete: () => console.info('Import Order confirmed successfully')
    })
  }

  selectImportOrder: ImportOrderContainerItem | undefined
  showSelectQCCompany(order: ImportOrderContainerItem) {
    this.selectImportOrder = order
    this.qcCompanies = order.qcCompanies
    this.visibleQCCompany = true
  }

  onQCCompanyTableRowSelect(event: any) {
    const company = event.data
    if(this.selectImportOrder)
      this.selectImportOrder.qualityControlCompany = company
    this.visibleQCCompany = false
  }

  getQCPrice(order: ImportOrderContainerItem): number {
    return order.qualityControlCompany ? order.qualityControlCompany.basePricePerContainer : 0
  }

  showOfferDetailDialog(frozenFruitExportOfferId: number) {
    this.loading = true
    this._frozenFruitExportOfferService.getById(frozenFruitExportOfferId).subscribe({
      next: (v) => {
        this.frozenFruitExportOffer = v
        this.loading = false
        this.visibleOfferDetailDialog = true
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.ExportOffer.SingleRetrieveError') })
      },
      complete: () => console.info('Frozen fruit export offer query successfully')
    })
  }
}
