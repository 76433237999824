import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { ChatMessage } from 'src/app/domain/models/ImportOrder';
import { BasicUser } from 'src/app/domain/models/User';

@Component({
  selector: 'app-user-import-order-chat',
  templateUrl: './user-import-order-chat.component.html',
  styleUrls: ['./user-import-order-chat.component.css']
})
export class UserImportOrderChatComponent {

  user: BasicUser | undefined
  limitCharacters: number = 300
  
  @ViewChild('chatBox') private chatBox!: ElementRef;

  @Input()
  messages: ChatMessage[] = [];
  @Input()
  loading: boolean = false;

  @Output() messageSent = new EventEmitter<ChatMessage>();


  formGroup: FormGroup = this._formBuilder.group({
    message: ['', [Validators.required, Validators.maxLength(this.limitCharacters)]]
  })

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  constructor(private _formBuilder: FormBuilder,
    private _authService: AuthGateway){
      this.user = this._authService.getUser()
    }

  scrollToBottom(): void {
    try {
      this.chatBox.nativeElement.scrollTop = this.chatBox.nativeElement.scrollHeight;
    } catch(err) {
      console.error('Error scrolling to bottom', err);
    }
  }

  sendMessage() {
    if(this.formGroup.valid) {
      const message: ChatMessage = {
        sender: this.user?.username.toString() ?? "Unknown User",
        message: this.formGroup.controls['message'].value,
        created: new Date()
      };
      this.messageSent.emit(message);
      this.formGroup.reset();
    }
  }

}
