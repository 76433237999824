<app-user-dashboard [userMenuActiveItemIndex]="0" [activeItem]="'negotiations'">
  <p-card [style]="{'min-height':'200px'}">
    <ng-template pTemplate="header">
      <div class="flex justify-content-between flex-wrap align-items-center" style="padding: 15px;">
        <h2>{{'UserPages.UserBargaining.OpenTab.BargainingDetail.PageTitle' | translate}}</h2>
        <p-tag severity="info" value="{{'UserPages.UserBargaining.OpenTab.BargainingDetail.OpenNegotiation' | translate}}" *ngIf="negotiation?.offerNegotiation?.status === 0"></p-tag>
        <p-tag severity="danger" value="{{'UserPages.UserBargaining.OpenTab.BargainingDetail.RejectedNegotiation' | translate}}" *ngIf="negotiation?.offerNegotiation?.status === 1"></p-tag>
        <p-tag severity="success" value="{{'UserPages.UserBargaining.OpenTab.BargainingDetail.ApprovedNegotiation' | translate}}" *ngIf="negotiation?.offerNegotiation?.status === 2"></p-tag>
        <div class="flex justify-content-end align-items-center gap-4">
          <button icon="pi pi-shopping-cart" pButton pRipple (click)="goToOrders()"
                        style="background-color: #8c2b87;"
                        label="{{'MarketplacePage.Spot.OrderContainers' | translate}}"
                        *ngIf="profile?.profileType === 'Importer'"></button>
          <p-button label="{{'UserPages.UserBargaining.OpenTab.BargainingDetail.SeeOffer' | translate}}" (onClick)="openExportOfferDialog()"></p-button>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="body">
      <p-card>
        <ng-template pTemplate="header">
          <div class="grid">
            <div class="col-6">
              <div class="flex justify-content-center">
                <h2>{{'UserPages.UserBargaining.OpenTab.BargainingDetail.YourNegotiationText' | translate}}</h2>
              </div>
            </div>
            <div class="col-6">
              <div class="flex justify-content-center">
                <h2>{{'UserPages.UserBargaining.OpenTab.BargainingDetail.CounterPartText' | translate}}</h2>
              </div>
            </div>
          </div>
        </ng-template>

        <div class="grid">
          <ng-container *ngFor="let detail of details; let i = index">
            <ng-container *ngIf="detail.negotiatorUsername == username">
              <div class="col-6">
                <p-card [header]="getHeader(detail)" [subheader]="getCreated(detail)">
                  <div class="p-fluid grid">
                    <div class="col-12 md:col-6 lg:col-6">
                      {{negotiation?.exportOffer?.variety?.species?.name?.toUpperCase()}} - {{negotiation?.exportOffer?.variety?.name?.toUpperCase()}}
                    </div>
                    <div class="col-12 md:col-6 lg:col-6">
                      &#8226; {{'UserPages.UserBargaining.OpenTab.BargainingDetail.PalletsText' | translate}}: {{detail.pallets}}
                    </div>
                    <div class="col-12 md:col-6 lg:col-6">
                      &#8226; {{'UserPages.UserBargaining.OpenTab.BargainingDetail.IncotermsText' | translate}}: {{detail.typeSale}}
                    </div>
                    <div class="col-12 md:col-6 lg:col-6">
                      &#8226; {{'UserPages.UserBargaining.OpenTab.BargainingDetail.PaymentAgainstBLText' | translate}}: {{detail.paymentAgainstBL}}%
                    </div>
                    <div class="col-12">
                      {{'UserPages.UserBargaining.OpenTab.BargainingDetail.SuggestedPrice' | translate}}: <span style="font-weight: bold;">{{detail.unitPrice | currency: 'USD':'US$'}}</span>
                    </div>
                    <div class="col-12" *ngIf="detail.message">
                      <p-fieldset legend="Mensaje">
                        {{detail.message}}
                      </p-fieldset>
                    </div>
                  </div>
                </p-card>
              </div>
              <div class="col-6">
              </div>
            </ng-container>
            <ng-container *ngIf="detail.negotiatorUsername != username">
              <div class="col-6">
              </div>
              <div class="col-6">
                <p-card [header]="getHeader(detail)" [subheader]="getCreated(detail)">
                  <div class="p-fluid grid">
                    <div class="col-12 md:col-6 lg:col-6">
                      {{negotiation?.exportOffer?.variety?.species?.name?.toUpperCase()}} - {{negotiation?.exportOffer?.variety?.name?.toUpperCase()}}
                    </div>
                    <div class="col-12 md:col-6 lg:col-6">
                      &#8226; {{'UserPages.UserBargaining.OpenTab.BargainingDetail.PalletsText' | translate}}: {{detail.pallets}}
                    </div>
                    <div class="col-12 md:col-6 lg:col-6">
                      &#8226; {{'UserPages.UserBargaining.OpenTab.BargainingDetail.IncotermsText' | translate}}: {{detail.typeSale}}
                    </div>                    
                    <div class="col-12 md:col-6 lg:col-6">
                      &#8226; {{'UserPages.UserBargaining.OpenTab.BargainingDetail.PaymentAgainstBLText' | translate}}: {{detail.paymentAgainstBL}}%
                    </div>
                    <div class="col-12">
                      {{'UserPages.UserBargaining.OpenTab.BargainingDetail.SuggestedPrice' | translate}}: <span style="font-weight: bold;">{{detail.unitPrice | currency: 'USD':'US$'}}</span>
                    </div>
                    <div class="col-6" *ngIf="negotiation?.offerNegotiation?.status == 0 && i == details.length - 1">
                      <p-button label="{{'UserPages.UserBargaining.OpenTab.BargainingDetail.RejectButton' | translate}}" (onClick)="rejectOfferNegotiation()" [loading]="loading" styleClass="p-button-outlined"></p-button>
                    </div>
                    <div class="col-6" *ngIf="negotiation?.offerNegotiation?.status == 0 && i == details.length - 1">
                      <p-button label="{{'UserPages.UserBargaining.OpenTab.BargainingDetail.AcceptButton' | translate}}" (onClick)="approveOfferNegotiation(detail.id)" [loading]="loading"></p-button>
                    </div>
                    <div class="col-12" *ngIf="negotiation?.offerNegotiation?.status == 0 && i == details.length - 1" style="margin-top: 20px;">
                      <p-button label="{{'UserPages.UserBargaining.OpenTab.BargainingDetail.MakeCounterOfferModal.ModalHeaderText' | translate }}" [style]="{'background-color': '#8c2b87'}" (onClick)="openBargainingDialog()"></p-button>
                    </div>
                  </div>
                </p-card>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </p-card>
    </ng-template>
  </p-card>
</app-user-dashboard>

<p-toast></p-toast>

<p-dialog header="{{'UserPages.UserBargaining.OpenTab.BargainingDetail.MakeCounterOfferModal.ModalHeaderText' | translate }}" [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleBargainingDialog" [style]="{width: '50%'}">
  <form [formGroup]="formGroup">
    <div class="p-fluid formgrid grid" style="padding: 20px;">
      <div class="field col-12 md:col-6 lg:col-6" style="margin-bottom: 40px;">
        <label for="price" style="font-weight: bolder;">{{'UserPages.UserBargaining.OpenTab.BargainingDetail.MakeCounterOfferModal.NegotiationPriceFieldLable' | translate}} *</label>
        <p-inputNumber id="price" formControlName="negotiationPrice" [min]="0" mode="currency" currency="USD" locale="en-US" placeholder="{{'UserPages.UserBargaining.OpenTab.BargainingDetail.MakeCounterOfferModal.NegotiationPriceFieldPlaceholder' | translate }}"/>
        <small *ngIf="submit && formGroup.controls['negotiationPrice'].invalid" class="p-error">{{getErrorMessage('negotiationPrice')}}</small>
      </div>
      <div class="field col-12 md:col-6 lg:col-6" style="margin-bottom: 40px;">
        <label for="pallets" style="font-weight: bolder;">{{'UserPages.UserBargaining.OpenTab.BargainingDetail.MakeCounterOfferModal.PalletsFieldLabel' | translate}} *</label>
        <p-inputNumber id="pallets" formControlName="negotiationPallets" placeholder="{{'UserPages.UserBargaining.OpenTab.BargainingDetail.MakeCounterOfferModal.PalletsFielPlaceholder' | translate }}"/>
        <small *ngIf="submit && formGroup.controls['negotiationPallets'].invalid" class="p-error">{{getErrorMessage('negotiationPallets')}}</small>
      </div>
      <div class="field col-12 md:col-6 lg:col-6" style="margin-bottom: 40px;">
        <label for="incoterm" style="font-weight: bolder;">{{'UserPages.UserBargaining.OpenTab.BargainingDetail.MakeCounterOfferModal.SellModeFieldLabel' | translate}} *</label>
        <p-dropdown id="incoterm"  (onChange)="onChangeIncoterm($event)" formControlName="negotiationIncoterms" [options]="incotermsOptions" optionLabel="label" optionValue="value" appendTo="body" [showClear]="true" placeholder="{{'UserPages.UserBargaining.OpenTab.BargainingDetail.MakeCounterOfferModal.SellModeFieldPlaceholder' | translate }}"></p-dropdown>
        <small *ngIf="submit && formGroup.controls['negotiationIncoterms'].invalid" class="p-error">{{getErrorMessage('negotiationIncoterms')}}</small>
      </div>
      <div class="field col-12 md:col-6 lg:col-6" style="margin-bottom: 40px;">
        <label for="paymentAgainstBL" style="font-weight: bolder; font-size: calc(100% - 4px); white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ paymentBLTitle | translate }} *</label>
        <p-dropdown id="negotiationPaymentAgainstBL" formControlName="negotiationPaymentAgainstBL" [options]="paymentBLItems" optionLabel="label" optionValue="value" placeholder="{{'UserPages.UserBargaining.OpenTab.BargainingDetail.MakeCounterOfferModal.PaymentAgainstBLLabel' | translate}}"></p-dropdown>
        <small *ngIf="submit && formGroup.controls['negotiationPaymentAgainstBL'].invalid" class="p-error">{{getErrorMessage('negotiationPaymentAgainstBL')}}</small>
        <small *ngIf="showPaymentBLPercent">{{'UserPages.UserExportOffers.NewExportOffer.FinalPaymentSubjectQCReview' | translate}} {{finalPaymentPercent}}%</small>
      </div>
    </div>
  </form>
  <div class="grid" style="padding: 20px;">
    <div class="col-12">
      <p><span style="font-weight: bold;">{{'UserPages.UserBargaining.OpenTab.BargainingDetail.MakeCounterOfferModal.PaymentAgainstBLLabel' | translate}}: </span><span style="color: #8c2b87; font-weight: bold;">{{ formGroup.controls['negotiationPaymentAgainstBL'].value }}%</span></p>
      <p><span style="font-weight: bold;">{{'UserPages.UserBargaining.OpenTab.BargainingDetail.MakeCounterOfferModal.SubtotalText' | translate}}: </span><span style="color: #8c2b87; font-weight: bold;">{{totalCounterOffer | currency: 'USD':'US$'}}</span></p>
      <p>* {{'UserPages.UserBargaining.OpenTab.BargainingDetail.MakeCounterOfferModal.InfoMessageText' | translate}}.</p>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <p-button label="{{'UserPages.UserBargaining.OpenTab.BargainingDetail.MakeCounterOfferModal.CloseModalButton' | translate }}" (onClick)="closeBargainingDialog()"  [loading]="loading" styleClass="p-button-outlined"></p-button>
    <p-button label="{{'UserPages.UserBargaining.OpenTab.BargainingDetail.MakeCounterOfferModal.SendModalButton' | translate }}" (onClick)="saveNegotiationDetail()" [loading]="loading"></p-button>
  </ng-template>
</p-dialog>


<p-dialog [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleExportOfferDialog" [style]="{width: '80%'}">
  <app-export-offer-detail [offer]="selectedExportOffer" [productCertificateTypes]="selectedProductCertificateTypes" [packagingTypes]="selectedPackagingTypes"></app-export-offer-detail>
  <ng-template pTemplate="footer">
    <p-button label="{{'UserPages.UserBargaining.OpenTab.BargainingDetail.AcceptNegotiationModal.CloseModalButton' | translate }}" (click)="visibleExportOfferDialog = false"></p-button>
  </ng-template>
</p-dialog>

<p-dialog [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleRedirectDialog" [style]="{width: '80%'}">
  <p>{{'UserPages.UserBargaining.OpenTab.BargainingDetail.AcceptNegotiationModal.DescriptionText' | translate}}</p>
  <ng-template pTemplate="footer">
    <p-button label="{{'UserPages.UserBargaining.OpenTab.BargainingDetail.AcceptNegotiationModal.GoToContainerButton' | translate }}" (onClick)="redirectToPurchaseOrdersPage()"></p-button>
    <p-button label="{{'UserPages.UserBargaining.OpenTab.BargainingDetail.AcceptNegotiationModal.CloseModalButton' | translate }}" (click)="visibleRedirectDialog = false"></p-button>
  </ng-template>
</p-dialog>
