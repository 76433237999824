export class ProfileUtils {
  static getTypeName(profileType: number): string {
    switch(profileType) {
      case 1:
        return 'Exporter'
      case 2:
        return 'Importer'
      case 3:
        return 'Admin'
      case 4:
        return 'QCUser'
      case 5:
        return 'Forwarder'
      case 6:
        return 'CustomAgent'
      default: ''
    }
    return ''
  }

  static getProfileTypeOptions(): any[] {
    return [
      {label: 'Exportador', value: 1},
      {label: 'Importador', value: 2},
      {label: 'Admin', value: 3},
      {label: 'QCUser', value: 4},
      {label: 'Forwarder', value: 5},
      {label: 'Custom Agent', value: 6},
    ]
  }
}
