<div style="margin-top: 20px;">
  <p-card>
    <div class="grid" style="font-size: small;">
      <div class="col-12 md:col-4" style="margin-left: 10px; border-right: 2px solid #bbb; padding-left: 10px;">
        <h3>Consignee</h3>
        <div class="grid">
          <div class="col-12 md:col-6 wordwrap" style="font-weight: bolder;">{{'UserPages.UserPurchaseOrders.PaymentsPage.ConsigneeNameText' | translate}}:</div>
          <div class="col-12 md:col-6 wordwrap">{{delivery?.consigneeCompanyName}}</div>
          <div class="col-12 md:col-6 wordwrap" style="font-weight: bolder;">{{'UserPages.UserPurchaseOrders.PaymentsPage.ConsigneeContactNameText' | translate}}:</div>
          <div class="col-12 md:col-6 wordwrap">{{delivery?.consigneeContactName}}</div>
          <div class="col-12 md:col-6 wordwrap" style="font-weight: bolder;">{{'UserPages.UserPurchaseOrders.PaymentsPage.ConsigneeContactAddressLabel' | translate}}:</div>
          <div class="col-12 md:col-6 wordwrap">{{delivery?.consigneeContactAddress}}, {{delivery?.consigneeContactCity}}</div>
          <div class="col-12 md:col-6 wordwrap" style="font-weight: bolder;">{{'UserPages.UserPurchaseOrders.PaymentsPage.ConsigneeCountryText' | translate}}:</div>
          <div class="col-12 md:col-6 wordwrap">{{delivery?.consigneeContactCountry | countryDescription}}</div>
          <div class="col-12 md:col-6 wordwrap" style="font-weight: bolder;">{{'UserPages.UserPurchaseOrders.PaymentsPage.ConsigneeEmailText' | translate}}:</div>
          <div class="col-12 md:col-6 wordwrap" style="height: auto;">{{delivery?.consigneeContactEmail}}</div>
        </div>
      </div>
      <div class="col-12 md:col-4" style="margin-left: 10px; border-right: 2px solid #bbb; padding-right: 10px;">
        <h3>Notify</h3>
        <div class="grid">
          <div class="col-12 md:col-6 wordwrap" style="font-weight: bolder;">{{'UserPages.UserPurchaseOrders.PaymentsPage.NotifyNameText' | translate}}:</div>
          <div class="col-12 md:col-6 wordwrap">{{delivery?.notifierCompanyName}}</div>
          <div class="col-12 md:col-6 wordwrap" style="font-weight: bolder;">{{'UserPages.UserPurchaseOrders.PaymentsPage.NotifyContactNameText' | translate}}:</div>
          <div class="col-12 md:col-6 wordwrap">{{delivery?.notifierContactName}}</div>
          <div class="col-12 md:col-6 wordwrap" style="font-weight: bolder;">{{'UserPages.UserPurchaseOrders.PaymentsPage.NotifyContactAddressLabel' | translate}}:</div>
          <div class="col-12 md:col-6 wordwrap">{{delivery?.notifierContactAddress}}, {{delivery?.notifierContactCity}}</div>
          <div class="col-12 md:col-6 wordwrap" style="font-weight: bolder;">{{'UserPages.UserPurchaseOrders.PaymentsPage.NotifyCountryText' | translate}}:</div>
          <div class="col-12 md:col-6 wordwrap">{{delivery?.notifierContactCountry | countryDescription}}</div>
          <div class="col-12 md:col-6 wordwrap" style="font-weight: bolder;">{{'UserPages.UserPurchaseOrders.PaymentsPage.NotifyEmailText' | translate}}:</div>
          <div class="col-12 md:col-6 wordwrap">{{delivery?.notifierContactEmail}}</div>
        </div>
      </div>
    </div>
  </p-card>
</div>


