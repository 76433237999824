<app-user-dashboard [userMenuActiveItemIndex]="0">
  <p-breadcrumb class="max-w-full" [model]="breadcrumbMenuItems" [home]="breadcrumbHome"></p-breadcrumb>
  <div>
    <h2>{{'UserPages.UserPurchaseOrders.ConfirmPurchasePage.PageTitle' | translate}}</h2>
    <p>{{'UserPages.UserPurchaseOrders.ConfirmPurchasePage.PageDescriptionText' | translate}}</p>
  </div>

    <p-card>
      <div id="forms" *ngIf="!success">
        <form [formGroup]="formGroup">
          <h2>{{'UserPages.UserPurchaseOrders.ConfirmPurchasePage.ConsigneePanel.Title' | translate}}</h2>
          <div class="p-fluid formgrid grid" style="padding: 20px;">
            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
              <label>{{'UserPages.UserPurchaseOrders.ConfirmPurchasePage.ConsigneePanel.SelectConsigneeProfileFieldLabel' | translate}}</label>
              <p-dropdown id="consignees"
                [(ngModel)]="selectedConsignee"
                [ngModelOptions]="{standalone: true}"
                [options]="consignees"
                optionLabel="companyName"
                (onChange)="loadConsigneeForm()"
                placeholder="{{'UserPages.UserPurchaseOrders.ConfirmPurchasePage.ConsigneePanel.SelectConsigneeProfileFieldPlaceholder' | translate}}">
              </p-dropdown>
            </div>
            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;"></div>
            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
              <label for="consigneeCompanyName">{{'UserPages.UserPurchaseOrders.ConfirmPurchasePage.ConsigneePanel.ConsigneeNameFieldLabel' | translate}} *</label>
              <input id="consigneeCompanyName" formControlName="consigneeCompanyName" type="text" required pInputText placeholder="{{'UserPages.UserPurchaseOrders.ConfirmPurchasePage.ConsigneePanel.ConsigneeNameFieldPlaceholder' | translate}} "/>
              <small *ngIf="submit && formGroup.controls['consigneeCompanyName'].invalid" class="p-error">{{getErrorMessage('consigneeCompanyName')}}</small>
            </div>
            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
              <label for="consigneeVat">{{'UserPages.UserPurchaseOrders.ConfirmPurchasePage.ConsigneePanel.VatFieldLabel' | translate}} *</label>
              <input id="consigneeVat" formControlName="consigneeVat" type="text" required pInputText placeholder="{{'UserPages.UserPurchaseOrders.ConfirmPurchasePage.ConsigneePanel.VatFieldPlaceholder' | translate}}"/>
              <small *ngIf="submit && formGroup.controls['consigneeVat'].invalid" class="p-error">{{getErrorMessage('consigneeVat')}}</small>
            </div>
            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
              <label for="consigneeContactCountry">{{'UserPages.UserPurchaseOrders.ConfirmPurchasePage.ConsigneePanel.CountryFieldLabel' | translate}} *</label>
              <p-dropdown id="consigneeContactCountry"
                formControlName="consigneeContactCountryId"
                [options]="countries"
                optionLabel="name"
                optionValue="id"
                [filter]="true"
                filterBy="name"
                [showClear]="true"
                placeholder="{{'UserPages.UserPurchaseOrders.ConfirmPurchasePage.ConsigneePanel.CountryFieldPlaceholder' | translate}}">
              </p-dropdown>
              <small *ngIf="submit && formGroup.controls['consigneeContactCountryId'].invalid" class="p-error">{{getErrorMessage('consigneeContactCountryId')}}</small>
            </div>
            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
              <label for="consigneeContactName">{{'UserPages.UserPurchaseOrders.ConfirmPurchasePage.ConsigneePanel.ContactNameFieldLabel' | translate}} *</label>
              <input id="consigneeContactName" formControlName="consigneeContactName" type="text" required pInputText placeholder="{{'UserPages.UserPurchaseOrders.ConfirmPurchasePage.ConsigneePanel.ContactNameFieldPlaceholder' | translate}}"/>
              <small *ngIf="submit && formGroup.controls['consigneeContactName'].invalid" class="p-error">{{getErrorMessage('consigneeContactName')}}</small>
            </div>
            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
              <label for="consigneeContactAddress">{{'UserPages.UserPurchaseOrders.ConfirmPurchasePage.ConsigneePanel.AddressContactFieldLabel' | translate}} *</label>
              <input id="consigneeContactAddress" formControlName="consigneeContactAddress" type="text" required pInputText placeholder="{{'UserPages.UserPurchaseOrders.ConfirmPurchasePage.ConsigneePanel.AddressContactFieldPlaceholder' | translate}}"/>
              <small *ngIf="submit && formGroup.controls['consigneeContactAddress'].invalid" class="p-error">{{getErrorMessage('consigneeContactAddress')}}</small>
            </div>
            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
              <label for="consigneeContactCity">{{'UserPages.UserPurchaseOrders.ConfirmPurchasePage.ConsigneePanel.CityFieldLabel' | translate}} *</label>
              <input id="consigneeContactCity" formControlName="consigneeContactCity" type="text" required pInputText placeholder="{{'UserPages.UserPurchaseOrders.ConfirmPurchasePage.ConsigneePanel.CityFieldPlaceholder' | translate}}"/>
              <small *ngIf="submit && formGroup.controls['consigneeContactCity'].invalid" class="p-error">{{getErrorMessage('consigneeContactCity')}}</small>
            </div>
            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
              <label for="consigneeContactEmail">{{'UserPages.UserPurchaseOrders.ConfirmPurchasePage.ConsigneePanel.ContactEmailFieldLabel' | translate}} *</label>
              <input id="consigneeContactEmail" formControlName="consigneeContactEmail" type="text" required pInputText placeholder="{{'UserPages.UserPurchaseOrders.ConfirmPurchasePage.ConsigneePanel.ContactEmailFieldPlaceholder' | translate}}"/>
              <small *ngIf="submit && formGroup.controls['consigneeContactEmail'].invalid" class="p-error">{{getErrorMessage('consigneeContactEmail')}}</small>
            </div>
            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
              <label for="consigneeContactPhone">{{'UserPages.UserPurchaseOrders.ConfirmPurchasePage.ConsigneePanel.ContactPhoneFieldLabel' | translate}} *</label>
              <input id="consigneeContactPhone" formControlName="consigneeContactPhone" type="text" pInputText placeholder="{{'UserPages.UserPurchaseOrders.ConfirmPurchasePage.ConsigneePanel.ContactPhoneFieldPlaceholder' | translate}}"/>
              <small *ngIf="submit && formGroup.controls['consigneeContactPhone'].invalid" class="p-error">{{getErrorMessage('consigneeContactPhone')}}</small>
            </div>
          </div>
          <p-divider></p-divider>
          <h2>{{'UserPages.UserPurchaseOrders.ConfirmPurchasePage.NotifyPanel.Title' | translate}}</h2>
          <div class="p-fluid formgrid grid" style="padding: 20px;">
            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
              <label>{{'UserPages.UserPurchaseOrders.ConfirmPurchasePage.NotifyPanel.SelectNotifierProfileFieldLabel' | translate}}</label>
              <p-dropdown id="notifiers"
                [(ngModel)]="selectedNotifier"
                [ngModelOptions]="{standalone: true}"
                [options]="notifiers"
                optionLabel="companyName"
                (onChange)="loadNotifierForm()"
                placeholder="{{'UserPages.UserPurchaseOrders.ConfirmPurchasePage.NotifyPanel.SelectNotifierProfileFieldPlaceholder' | translate}}">
              </p-dropdown>
            </div>
            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;"></div>
            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
              <label for="notifierCompanyName">{{'UserPages.UserPurchaseOrders.ConfirmPurchasePage.NotifyPanel.NotifyNameFieldLabel' | translate}} *</label>
              <input id="notifierCompanyName" formControlName="notifierCompanyName" type="text" required pInputText placeholder="{{'UserPages.UserPurchaseOrders.ConfirmPurchasePage.NotifyPanel.NotifyNameFieldPlaceholder' | translate}} "/>
              <small *ngIf="submit && formGroup.controls['notifierCompanyName'].invalid" class="p-error">{{getErrorMessage('notifierCompanyName')}}</small>
            </div>
            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
              <label for="notifierVat">{{'UserPages.UserPurchaseOrders.ConfirmPurchasePage.NotifyPanel.VatFieldLabel' | translate}} *</label>
              <input id="notifierVat" formControlName="notifierVat" type="text" required pInputText placeholder="{{'UserPages.UserPurchaseOrders.ConfirmPurchasePage.NotifyPanel.VatFieldPlaceholder' | translate}}"/>
              <small *ngIf="submit && formGroup.controls['notifierVat'].invalid" class="p-error">{{getErrorMessage('notifierVat')}}</small>
            </div>
            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
              <label for="notifierContactCountry">{{'UserPages.UserPurchaseOrders.ConfirmPurchasePage.NotifyPanel.CountryFieldLabel' | translate}} *</label>
              <p-dropdown id="notifierContactCountry"
                formControlName="notifierContactCountryId"
                [options]="countries"
                optionLabel="name"
                optionValue="id"
                [filter]="true"
                filterBy="name"
                [showClear]="true"
                placeholder="{{'UserPages.UserPurchaseOrders.ConfirmPurchasePage.NotifyPanel.CountryFieldPlaceholder' | translate}}">
              </p-dropdown>
              <small *ngIf="submit && formGroup.controls['notifierContactCountryId'].invalid" class="p-error">{{getErrorMessage('notifierContactCountryId')}}</small>
            </div>
            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
              <label for="notifierContactName">{{'UserPages.UserPurchaseOrders.ConfirmPurchasePage.NotifyPanel.ContactNameFieldLabel' | translate}} *</label>
              <input id="notifierContactName" formControlName="notifierContactName" type="text" required pInputText placeholder="{{'UserPages.UserPurchaseOrders.ConfirmPurchasePage.NotifyPanel.ContactNameFieldPlaceholder' | translate}}"/>
              <small *ngIf="submit && formGroup.controls['notifierContactName'].invalid" class="p-error">{{getErrorMessage('notifierContactName')}}</small>
            </div>
            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
              <label for="notifierContactAddress">{{'UserPages.UserPurchaseOrders.ConfirmPurchasePage.NotifyPanel.AddressContactFieldLabel' | translate}} *</label>
              <input id="notifierContactAddress" formControlName="notifierContactAddress" type="text" required pInputText placeholder="{{'UserPages.UserPurchaseOrders.ConfirmPurchasePage.NotifyPanel.AddressContactFieldPlaceholder' | translate}}"/>
              <small *ngIf="submit && formGroup.controls['notifierContactAddress'].invalid" class="p-error">{{getErrorMessage('notifierContactAddress')}}</small>
            </div>
            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
              <label for="notifierContactCity">{{'UserPages.UserPurchaseOrders.ConfirmPurchasePage.NotifyPanel.CityFieldLabel' | translate}} *</label>
              <input id="notifierContactCity" formControlName="notifierContactCity" type="text" required pInputText placeholder="{{'UserPages.UserPurchaseOrders.ConfirmPurchasePage.NotifyPanel.CityFieldPlaceholder' | translate}}"/>
              <small *ngIf="submit && formGroup.controls['notifierContactCity'].invalid" class="p-error">{{getErrorMessage('notifierContactCity')}}</small>
            </div>
            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
              <label for="notifierContactEmail">{{'UserPages.UserPurchaseOrders.ConfirmPurchasePage.NotifyPanel.ContactEmailFieldLabel' | translate}} *</label>
              <input id="notifierContactEmail" formControlName="notifierContactEmail" type="text" required pInputText placeholder="{{'UserPages.UserPurchaseOrders.ConfirmPurchasePage.NotifyPanel.ContactEmailFieldPlaceholder' | translate}}"/>
              <small *ngIf="submit && formGroup.controls['notifierContactEmail'].invalid" class="p-error">{{getErrorMessage('notifierContactEmail')}}</small>
            </div>
            <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
              <label for="notifierContactPhone">{{'UserPages.UserPurchaseOrders.ConfirmPurchasePage.NotifyPanel.ContactPhoneFieldLabel' | translate}} *</label>
              <input id="notifierContactPhone" formControlName="notifierContactPhone" type="text" pInputText placeholder="{{'UserPages.UserPurchaseOrders.ConfirmPurchasePage.NotifyPanel.ContactPhoneFieldPlaceholder' | translate}}"/>
              <small *ngIf="submit && formGroup.controls['notifierContactPhone'].invalid" class="p-error">{{getErrorMessage('notifierContactPhone')}}</small>
            </div>
          </div>
          <p-divider></p-divider>
          <div class="grid">
            <div class="col-6"></div>
            <div class="col-6">
              <div class="p-fluid grid">
                <div class="col-6">
                  <p-button label="{{'UserPages.UserPurchaseOrders.ConfirmPurchasePage.BackButton' | translate}}" (onClick)="onCancel()" [loading]="loading" styleClass="p-button-outlined"></p-button>
                </div>
                <div class="col-6">
                  <p-button label="{{'UserPages.UserPurchaseOrders.ConfirmPurchasePage.ConfirmPurchaseButton' | translate}}" [loading]="loading" (onClick)="confirmPurchase()"></p-button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div id="success" *ngIf="success">
        <div class="flex align-items-center justify-content-center flex-column">
          <i class="pi pi-check-circle" style="font-size: 2.5rem"></i>
          <h1>{{'UserPages.UserPurchaseOrders.ConfirmPurchasePage.SuccessPanel.Title' | translate}}</h1>
          <p-button label="{{'UserPages.UserPurchaseOrders.ConfirmPurchasePage.SuccessPanel.AcceptButton' | translate}}" (onClick)="goToImportOrderPage()"></p-button>
        </div>
      </div>
    </p-card>

</app-user-dashboard>

<p-toast></p-toast>
<p-confirmDialog acceptLabel="{{'UserPages.UserPurchaseOrders.ConfirmPurchasePage.ConfirmDialog.YesLabel' | translate}}" rejectLabel="{{'UserPages.UserPurchaseOrders.ConfirmPurchasePage.ConfirmDialog.NotLabel' | translate}}" rejectButtonStyleClass="p-button-outlined" [style]="{width: '50vw'}"></p-confirmDialog>
