import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { ImportRequest, ImportRequestInformation } from 'src/app/domain/models/ImportRequest';
import { BasicUser } from 'src/app/domain/models/User';
import { ExportOfferUtils } from 'src/app/ui/util/export-offer-utils';
import { ImportRequestUtil } from 'src/app/ui/util/import-request-util';
import { Lang } from 'src/app/ui/util/langs';

@Component({
  selector: 'app-import-request-detail',
  templateUrl: './import-request-detail.component.html',
  styleUrls: ['./import-request-detail.component.css']
})
export class ImportRequestDetailComponent implements OnInit {

  @Input()
  importRequestInformation: ImportRequestInformation | undefined

  profile: BasicUser | undefined

  responsiveOptions: any[] = [
    {
        breakpoint: '1199px',
        numVisible: 1,
        numScroll: 1
    },
    {
        breakpoint: '991px',
        numVisible: 1,
        numScroll: 1
    },
    {
        breakpoint: '767px',
        numVisible: 1,
        numScroll: 1
    }
  ]

  constructor(private _authService: AuthGateway,
    public translate: TranslateService) {

  }

  ngOnInit(): void {
    this.profile = this._authService.getUser()
  }

  completeUrl(url: string){
    return ExportOfferUtils.completeUrl(url);
  }

  getColor(color?: string): string {
    if(this.profile)
      return ExportOfferUtils.getColorName(this.profile.lang, color)
    return ''
  }

  getBoxType(boxType: string): string {
    if(this.profile)
      return ExportOfferUtils.getBoxTypeName(this.profile.lang, boxType)
    return ''
  }

  getTotalPalletsImportRequest(importRequest: ImportRequest): number {
    return ImportRequestUtil.getTotalPallets(importRequest)
  }

  getTotalWeeksImportRequest(importRequest: ImportRequest): number {
    return ImportRequestUtil.getTotalWeeks(importRequest)
  }

  getTotalContainersPerWeek(importRequest: ImportRequest) {
    return ImportRequestUtil.getTotalContainersPerWeek(importRequest)
  }

  getTotalContainersImportRequest(importRequest: ImportRequest): number {
    return ImportRequestUtil.getTotalContainers(importRequest)
  }

  get availableLabel(): String {
    return this.translate.instant('ExportOffer.ExportOfferDetail.NoAvailable')
  }
}
