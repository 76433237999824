import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Routes } from "../../../infrastructure/routes";
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { SecurityGateway } from 'src/app/application/gateway/SecurityGateway';
import { DialogService } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { ProfileGateway } from 'src/app/application/gateway/ProfileGateway';
import { InputNumber } from 'primeng/inputnumber';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css'],
  providers: [DialogService, MessageService]
})
export class LoginPageComponent implements OnInit {
  visibleDialog: boolean = false
  submit: boolean = false
  loginError: boolean = false
  loading: boolean = false
  showLogin: boolean = true;
  showAuthentication: boolean = false;
  autheticationCode: string | undefined
  emailUser: string = '';
  codeError: boolean = false;

  formGroup: FormGroup = this._formBuilder.group({
    email: ['', Validators.required],
    password: ['', Validators.required],
    authCode1: [null],
    authCode2: [null],
    authCode3: [null],
    authCode4: [null],
    authCode5: [null],
    authCode6: [null]
  })

  constructor(private _router: Router,
    private _formBuilder: FormBuilder,
    private router: Router,
    private _securityGateway: SecurityGateway,
    private _authService: AuthGateway,
    public dialog: DialogService,
    private _messageService: MessageService,
    private _profileService: ProfileGateway,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    //this.showAuthentication = true;
  }

  handleSubmit() {
    this.submit = true
    this.loginError = false
    this.loading = true
    if (this.showAuthentication) {
      this.handleAuthCode();
    }
    if (this.formGroup.valid) {

      this._securityGateway.doLogin(
        this.formGroup.controls['email'].value,
        this.formGroup.controls['password'].value,
        this.autheticationCode
      ).subscribe({
        next: this.handleSuccessResponse.bind(this),
        error: this.handleErrorResponse.bind(this)
      })
    } else {
      this.loading = false
    }
  }

  handleSuccessResponse(data: any) {
    if (data?.requiredAuthCode) {
      this.showLogin = false;
      this.showAuthentication = true;
      this.loading = false;
    }
    else if (data?.requiredValidateToken) {
      this.loading = false;
      this._messageService.add({ severity: 'error',
        summary: this.translate.instant('MessageService.Summary.FailedAction'),
        detail: data?.msg ?? this.translate.instant('LoginPage.LoginErrorMessage') })
    }
    else {
      this._authService.authenticate(data.data.accessToken)
      this._profileService.getBasicUser().subscribe({
        next: (v) => {
          const profile = v
          localStorage.setItem('_auth_user', JSON.stringify(profile))
          this.translate.use(profile.lang)
          localStorage.removeItem('_marketplace_port_to_param')
          localStorage.removeItem('_marketplace_country_to_param')
          localStorage.removeItem('_marketplace_country_from_param')
          localStorage.setItem('_dsclm_sh', JSON.stringify(false))

          this.loading = false;
          if (this._authService.getRole() == 'User') {
            switch(profile.type) {
              case 1:
                this.router.navigate([Routes.userExportOffers]).then(() => { })
              break
              case 2:
                this.router.navigate([Routes.userImportRequest]).then(() => { })
              break
              case 4:
                this.router.navigate([Routes.qcUserReviews]).then(() => { })
              break
              case 5:
                this.router.navigate([Routes.forwarderUserReviews]).then(() => { })
              break
              case 6:
                this.router.navigate([Routes.customAgentUserReviews]).then(() => { })
              break
              default:
                this.router.navigate([Routes.homePage]).then(() => { })
            }
          }
          else if (this._authService.getRole() == 'Admin') {
            this.router.navigate([Routes.adminManageOrderPage]).then(() => { })
          }
        },
        error: (e) => {
          this.loading = false;
          console.log(e)
        }
      })
    }
  }

  handleErrorResponse(error: any) {
    if (this.showAuthentication) {
      this.codeError = true;
      this.loading = false;
    } else {
      this.loginError = true;
      this.loading = false;
    }
  }

  get resetPasswordRoute(): string {
    return '/reset-password-request'
  }

  get signupPageRoute(): string {
    return '/signup'
  }

  goToMarketplace() {
    this._router.navigate([Routes.externalMarketplace])
  }

  goToSignupPage() {
    this._router.navigate([Routes.signup])
  }

  getErrorMessage(fieldName: string): string {
    if (this.formGroup.controls[fieldName].hasError('required')) {
      return this.translate.instant('FormErrorMessage.FieldValueRequired')
    }
    if (this.formGroup.controls[fieldName].hasError('email')) {
      return this.translate.instant('FormErrorMessage.EmailFieldEmailFormatError')
    }
    return ''
  }

  handleAuthCode() {
    if (this.showAuthentication) {
      let digit1 = this.formGroup.controls['authCode1'].value;
      let digit2 = this.formGroup.controls['authCode2'].value;
      let digit3 = this.formGroup.controls['authCode3'].value;
      let digit4 = this.formGroup.controls['authCode4'].value;
      let digit5 = this.formGroup.controls['authCode5'].value;
      let digit6 = this.formGroup.controls['authCode6'].value;

      this.autheticationCode = "" + digit1 + digit2 + digit3 + digit4 + digit5 + digit6;
      this.emailUser = this.formGroup.controls['email'].value;
    } else {
      this.autheticationCode = undefined;
    }
  }

  handleFocusNext(inputNumber: InputNumber) {
    inputNumber.input.nativeElement.focus()
  }

  clear(auth: string) {
    this.formGroup.controls[auth].setValue(null);
  }

  onPaste(event: ClipboardEvent) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData?.getData('text')
    if(pastedText?.length === 6 && !isNaN(parseInt(pastedText))) {
      const digits = [...pastedText]
      this.formGroup.controls['authCode1'].setValue(digits[0])
      this.formGroup.controls['authCode2'].setValue(digits[1])
      this.formGroup.controls['authCode3'].setValue(digits[2])
      this.formGroup.controls['authCode4'].setValue(digits[3])
      this.formGroup.controls['authCode5'].setValue(digits[4])
      this.formGroup.controls['authCode6'].setValue(digits[5])
    }
  }

  resendCode() {
    this.loading = true;
    this._profileService.resendCode(this.formGroup.controls['email'].value,
      this.formGroup.controls['password'].value).subscribe({
        next: (v) => {
          this.loading = false;
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: this.translate.instant('LoginPage.ResendCodeSuccess') })
        },
        error: (e) => {
          this.loading = false;
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('LoginPage.ResendCodeError') })
        }
      })
  }
}
