import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { DocumentServiceGateway } from 'src/app/application/gateway/DocumentServiceGateway';
import { Country } from 'src/app/domain/models/Country';
import { ExportOffer } from 'src/app/domain/models/ExportOffer';
import { Species } from 'src/app/domain/models/Species';
import { BasicUser } from 'src/app/domain/models/User';
import { ExportOfferUtils } from 'src/app/ui/util/export-offer-utils';
import { Lang } from 'src/app/ui/util/langs';
import { SpeciesUtils } from 'src/app/ui/util/species-utils';

@Component({
  selector: 'app-export-offer-detail-description',
  templateUrl: './export-offer-detail-description.component.html',
  styleUrls: ['./export-offer-detail-description.component.css']
})
export class ExportOfferDetailDescriptionComponent implements OnInit {

  @Input()
  offer: ExportOffer | undefined

  user: BasicUser | undefined

  // speciesDescription: any

  constructor(
    private _authService: AuthGateway,
    private _documentService: DocumentServiceGateway,
    public translate: TranslateService) {
      this.user = this._authService.getUser()
  }

  ngOnInit(): void {
    // this.getSpeciesDescription()
  }

  // getSpeciesDescription() {
  //   if(this.user && this.offer) {

  //     switch(this.user.lang) {
  //       case 'en':
  //         this.speciesDescription = {
  //           description: this.offer.variety?.species?.descriptionEn,
  //           technicalCharacteristics: this.offer.variety?.species?.technicalCharacteristicsEn,
  //           qualityControlCriteria: this.offer?.variety?.species?.qualityControlCriteriaEn,
  //           harvestAreaChile: this.offer?.variety?.species?.harvestAreaChileEn
  //         }
  //         break
  //       case 'es':
  //         this.speciesDescription = {
  //           description: this.offer.variety?.species?.descriptionEs,
  //           technicalCharacteristics: this.offer.variety?.species?.technicalCharacteristicsEs,
  //           qualityControlCriteria: this.offer?.variety?.species?.qualityControlCriteriaEs,
  //           harvestAreaChile: this.offer?.variety?.species?.harvestAreaChileEs
  //         }
  //         break
  //     }
  //   }
  // }

  get speciesDescription() {
    if(this.user && this.offer) {

      switch(this.user.lang) {
        case 'en':
          return {
            description: this.offer.variety?.species?.descriptionEn,
            technicalCharacteristics: this.offer.variety?.species?.technicalCharacteristicsEn,
            qualityControlCriteria: this.offer?.variety?.species?.qualityControlCriteriaEn,
            harvestAreaChile: this.offer?.variety?.species?.harvestAreaChileEn
          }
          break
        case 'es':
          return  {
            description: this.offer.variety?.species?.descriptionEs,
            technicalCharacteristics: this.offer.variety?.species?.technicalCharacteristicsEs,
            qualityControlCriteria: this.offer?.variety?.species?.qualityControlCriteriaEs,
            harvestAreaChile: this.offer?.variety?.species?.harvestAreaChileEs
          }
          break
      }
    }
    return  {
      description: this.getAvailableLabel(),
      technicalCharacteristics: this.getAvailableLabel(),
      qualityControlCriteria: this.getAvailableLabel(),
      harvestAreaChile: this.getAvailableLabel()
    }
  }

  getHarvestPeriod(specie?: Species) {
    let specieHarvestPeriod: string | undefined = ""
    if(this.user && this.offer && specie) {
      switch(this.user.lang) {
        case 'en':
          specieHarvestPeriod = specie?.harvestPeriod
          break
        case 'es':
          specieHarvestPeriod = specie?.harvestPeriodEs
          break
      }
    }
    return specieHarvestPeriod
  }

  getBoxType(boxType?: string): string {
    return this.user ? ExportOfferUtils.getBoxTypeName(this.user.lang, boxType) : ''
  }

  getColor(color?: string) {
    return this.user ? ExportOfferUtils.getColorName(this.user.lang, color) : ''
  }

  getBasePallets(basePallets?: string) {
    return this.user ? ExportOfferUtils.getBasePalletsName(this.user.lang, basePallets) : ''
  }

  getBooleanLabel(value?: boolean) {
    return this.user ? Lang.getBooleanLabel(this.user.lang, value) : ''
  }

  getAvailableLabel() {
    return this.translate.instant('ExportOffer.ExportOfferDetail.NoAvailable')
  }

  get speciesName(): string {
    if(this.user && this.offer)
      return SpeciesUtils.getSpeciesName(this.user.lang, this.offer.variety?.species)
    return ''
  }

  getExportOfferQualityDocument(documents: any[] | undefined) {
    if (documents == undefined || documents.length <= 0) return;

    let docId = documents[0].id

    this._documentService.getExportOfferDocument2(docId).subscribe((resultBlob: any) => {
      var downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }
}
