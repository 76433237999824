import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { ProfileGateway } from 'src/app/application/gateway/ProfileGateway';
import { Profile } from 'src/app/domain/models/Profile';
import { BasicUser } from 'src/app/domain/models/User';
import { Routes } from 'src/app/infrastructure/routes';
import { ProfileUtils } from 'src/app/ui/util/profile-utils';

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.css'],
  providers: [ConfirmationService, MessageService]
})
export class AdminUsersComponent implements OnInit {

  profiles: Profile[] = []
  selected: Profile | null = null
  profile: BasicUser | undefined

  loading: boolean = false

  constructor(private _router: Router,
    private _messageService: MessageService,
    private _confirmationService: ConfirmationService,
    private _authService: AuthGateway,
    private _profileService: ProfileGateway){
  }

  ngOnInit(): void {
    this.profile = this._authService.getUser()
    this.loadProfiles()
  }

  loadProfiles() {
    this._profileService.getAll().subscribe({
      next: (v) => {
        this.profiles = v
      },
      error: (e) => {
        console.log(e)
        this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudieron recuperar las perfiles.' });
      },
      complete: () =>  {
        this.loading = false
        console.info('Profile query successfully')
      }
    })
  }

  getProfileTypeName(profileType: number): string {
    return ProfileUtils.getTypeName(profileType)
  }

  goToEditUser(id: number) {
    this._router.navigate(['/' + Routes.adminEditUser, id])
  }

  toogleIsActive(profileSelected: any) {
    if(profileSelected) {
      this._confirmationService.confirm({
        message: `¿Confirma ${profileSelected?.isActive === true ? 'Desactivar' : 'Activar'} el usuario?`,
        header: `Confirmación de ${profileSelected?.isActive === true ? 'desactivación' : 'activación'}`,
        icon: 'pi pi-info-circle',
        accept: () => {
          const request: Profile = {
            ...profileSelected,
            updaterUsername: ""
          }

          this._profileService.toogleIsActivate(request).subscribe({
              next: (v) => {
                this.selected = null
                this._messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'Usuario actualizado' })
                this.loadProfiles()
              },
              error: (e) => {
                console.log(e)
                this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudo activar/desactivar el usuario.' });
              },
              complete: () => console.info('Usuario deactived successfully')
            })
        },
        reject: (type: any) => {
          switch (type) {
            case ConfirmEventType.REJECT:
              break;
            case ConfirmEventType.CANCEL:
              break;
          }
        }
      })
    }
  }

  sendValidationToken(profileId: number) {
    if(profileId) {
      this._confirmationService.confirm({
        message: '¿Desea reenviar token de invitación el usuario?',
        header: 'Confirmación de reenvio',
        icon: 'pi pi-info-circle',
        accept: () => {
            this._profileService.sendValidationToken(profileId).subscribe({
              next: (v) => {
                this.selected = null
                this._messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'Correo enviado' })
                this.loadProfiles()
              },
              error: (e) => {
                console.log(e)
                let errorMessage = ''
                switch(this.profile?.lang) {
                  case 'es':
                    errorMessage = e.error.messageEs
                    break
                  case 'en':
                    errorMessage = e.error.messageEn
                    break
                  default:
                    errorMessage = 'No fue posible enviar correo de invitación'
                }
                this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: errorMessage });
              },
              complete: () => console.info('Process successfully')
            })
        },
        reject: (type: any) => {
          switch (type) {
            case ConfirmEventType.REJECT:
                this._messageService.add({ severity: 'error', summary: 'Rejected', detail: 'Se canceló el envío de invitación' })
                break;
            case ConfirmEventType.CANCEL:
                this._messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Se canceló el envío de invitación' })
                break;
          }
        }
      })
    }
  }

  goToCreateUser() {
    this._router.navigate(['/' + Routes.adminCreateUserPage])
  }

  goToExporterImporters(exporterId: number) {
    this._router.navigate(['/' + Routes.adminExporterImportersPage, exporterId])
  }
}
