<app-admin-dashboard>
  <div style="margin-top: 30px;">
    <p-card *ngIf="review">
      <h2>Origin QC Review Documents</h2>
      <div>
        <p-fieldset legend="Import order detail properties" [toggleable]="true" [collapsed]="true">
          <div class="grid">
            <div class="col-3">Import order Id:</div>
            <div class="col-3"><span style="font-weight: bolder;">{{ review.importOrderSequence }}</span></div>
            <div class="col-3">Import order detail Id:</div>
            <div class="col-3"><span style="font-weight: bolder;">{{ review.importOrderDetailSequence }}</span></div>

            <div class="col-3">Species / Variety:</div>
            <div class="col-3">
              <span style="font-weight: bolder;">
                {{ review | speciesName }} - {{ review.varietyName }}
              </span>
            </div>
            <div class="col-3">Coldstorage:</div>
            <div class="col-3">
              <span style="font-weight: bolder;">
                {{ review.fridgeStorageName }} {{ review.fridgeStorageCity }}, {{ review.fridgeStorageCountryName }}
              </span>
            </div>

            <div class="col-3">Pallets quantity:</div>
            <div class="col-3">
              <span style="font-weight: bolder;">
                {{ review.palletsQuantity }}
              </span>
            </div>
            <div class="col-3">Case per pallet:</div>
            <div class="col-3">
              <span style="font-weight: bolder;">
                {{ review.boxesPerPallet }}
              </span>
            </div>

            <div class="col-3">Departure port:</div>
            <div class="col-3">
              <span style="font-weight: bolder;">
                {{ review.portFromName }}
              </span>
            </div>
            <div class="col-3">Destination port:</div>
            <div class="col-3">
              <span style="font-weight: bolder;">
                {{ review.portToName }}
              </span>
            </div>
          </div>
        </p-fieldset>
      </div>

      <div style="margin-top: 50px;">
        <h3>Origin QC review document</h3>
        <p-fileUpload
          #QCReviewPdfUploader
          (onSelect)="onSelectReviewDocumentPdf($event)"
          [showUploadButton]="false"
          [multiple]="false"
          accept=".pdf"
          [maxFileSize]="5000000"
          chooseLabel="{{'UserPages.UserProductCertificates.Form.SelectFile' | translate}}"
          cancelLabel="{{'UserPages.UserProductCertificates.Form.CancelSelectFile' | translate}}">
          <ng-template pTemplate="content" let-files>
            <div>
              <p-button label="View file" icon="pi pi-file-pdf" (onClick)="viewOriginQCReviewDocument()"></p-button>
            </div>
          </ng-template>
        </p-fileUpload>
      </div>

      <div style="margin-top: 50px;">
        <h3>Origin QC consolidation document</h3>
        <p-fileUpload
          #QCReviewPdfUploader
          (onSelect)="onSelectConsolidationDocumentPdf($event)"
          [showUploadButton]="false"
          [multiple]="false"
          accept=".pdf"
          [maxFileSize]="5000000"
          chooseLabel="{{'UserPages.UserProductCertificates.Form.SelectFile' | translate}}"
          cancelLabel="{{'UserPages.UserProductCertificates.Form.CancelSelectFile' | translate}}">
          <ng-template pTemplate="content" let-files>
            <div>
              <p-button label="View file" icon="pi pi-file-pdf" (onClick)="viewOriginQCConsolidationDocument()"></p-button>
            </div>
          </ng-template>
        </p-fileUpload>
      </div>
    </p-card>
  </div>
</app-admin-dashboard>

<p-toast></p-toast>
