<app-user-dashboard [fullScreen]="true">
  <app-content-body>

    <div class="bg-container">
      <div class="container" *ngIf="exportOfferAd">
        <div class="col-12">
          <p-breadcrumb class="max-w-full" [model]="breadcrumbItems"></p-breadcrumb>
        </div>
        <div class="section-top">
          <div class="column-left-top">
            <app-export-offer-detail-carousel [offerAd]="exportOfferAd"></app-export-offer-detail-carousel>

            <div class="section-description">
              <h2 class="title-section">{{'ExportOffer.ExportOfferDetail.SpecieDescription.Title' | translate}}</h2>
              <div class="section-description-info">
                <div class="rowInfo">
                  <div class="container-info">
                    <span class="label-info">{{'ExportOffer.ExportOfferDetail.SpecieDescription.DescriptionText' | translate}}:</span>
                    <p class="text-info">{{speciesDescription.description ? speciesDescription.description : getAvailableLabel() }}</p>
                  </div>
                  <div class="container-info">
                    <span class="label-info">{{'ExportOffer.ExportOfferDetail.SpecieDescription.TechnicalCharacteristicsText' | translate}}:</span>
                    <p class="text-info">{{speciesDescription.technicalCharacteristics ? speciesDescription.technicalCharacteristics : getAvailableLabel()}}</p>
                  </div>
                </div>
                <div class="rowInfo">
                  <div class="container-info">
                    <span class="label-info">{{'ExportOffer.ExportOfferDetail.SpecieDescription.ExportedVarietiesText' | translate}}:</span>
                    <p class="text-info">{{exportOfferAd.variety.species.exportVarieties}}</p>
                  </div>
                  <div class="container-info">
                    <span class="label-info">{{'ExportOffer.ExportOfferDetail.SpecieDescription.QualityControlCriteriaText' | translate}}:</span>
                    <p class="text-info">{{speciesDescription.qualityControlCriteria ? speciesDescription.qualityControlCriteria : getAvailableLabel()}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="column-right-top">
            <div class="header-card">
              <div class="section-titles">

                <h2 class="title-product">
                  {{exportOfferAd.variety.species.name}} / {{exportOfferAd.variety.name}}
                </h2>
                <p class="title-price">{{'MarketplacePage.WishList.ExportOfferModal.TotalUpperCaseText' | translate}} {{ totalPrice | currency: 'USD':'US$'}}
                  {{getIncoterms(exportOfferAd.typeSale)}}
                </p>
              </div>
              <app-social-media-share [url]="urlRoute">
              </app-social-media-share>
            </div>

            <div class="section-main-info">

              <div class="container-main-info">
                <h5 class="span-info">{{'MarketplacePage.WishList.ExportOfferModal.CasePerPalletsText' | translate}}:</h5>
                <h3 class="data-main-info">
                  {{exportOfferAd.caseQuantityPerPallet }}
                </h3>
              </div>

              <div class="container-main-info">
                <h5 class="span-info">{{'MarketplacePage.WishList.ExportOfferModal.SpeciesSizeText' | translate}}:</h5>
                <h3 class="data-main-info">
                  {{ exportOfferAd.speciesSize ? exportOfferAd.speciesSize : getNoAvailableLabel() }}
                </h3>
              </div>

              <div class="container-main-info">
                <h5 class="span-info">{{'ExportOffer.ExportOfferDetail.ShelfLife' | translate}}:</h5>
                <h3 class="data-main-info">
                  {{ exportOfferAd.shelfLife ? exportOfferAd.shelfLife +  " " + translate.instant('MarketplacePage.WishList.ExportOfferModal.DayShelflifeText') : getNoAvailableLabel() }}
                </h3>
              </div>

              <div class="container-main-info">
                <h5 class="span-info">{{'MarketplacePage.WishList.ExportOfferModal.AvailablePalletsText' | translate}}:</h5>
                <h3 class="data-main-info">
                  {{ exportOfferAd.availablePallets }}
                </h3>
              </div>

              <div class="container-main-info">
                <h5 class="span-info">{{'MarketplacePage.WishList.ExportOfferModal.NetKilosText' | translate}}:</h5>
                <h3 class="data-main-info">
                  {{ exportOfferAd.netKilos ? exportOfferAd.netKilos + ' Kg' : getNoAvailableLabel() }}
                </h3>
              </div>

              <div class="container-main-info">
                <h5 class="span-info">{{'ExportOffer.ExportOfferDetail.AvailableProductCertificates2' | translate}}:</h5>
                <section *ngIf="selectedProductCertificateTypes.length > 0">
                  <ng-container *ngFor="let productCertificateType of selectedProductCertificateTypes">
                    <h3 class="data-main-info" *ngIf="!productCertificateType.isOther">{{ productCertificateType.name }}</h3>
                    <h3 class="data-main-info" *ngIf="productCertificateType.isOther">{{ exportOfferAd.otherCertificateType }}</h3>
                  </ng-container>
                </section>
                <span *ngIf="selectedProductCertificateTypes.length == 0" style="font-weight: bolder;">{{ 'UserPages.UserExportOffers.NotResultsMessages' | translate }}</span>
              </div>

            </div>
            <div class="container-bottom">
              <div class="field col-12 md:col-12 lg:col-6">
                <p-button label="{{'MarketplacePage.WishList.ExportOfferModal.PurchaseForm.PurchaseNowButton' | translate}}" (onClick)="navigateToLogin()" [loading]="loading"></p-button>
              </div>
              <app-qr-image [imageQrData]="imageData" width="160px" height="160px"></app-qr-image>
            </div>
          </div>
        </div>
        <!--
        <app-export-offer-detail-description [offer]="exportOfferAd"></app-export-offer-detail-description>
        -->


        <div class="section-bottom">
          <div class="section-description">
            <h2 class="title-section">{{'ExportOffer.ExportOfferDetail.ProductDescription' | translate}}</h2>
            <div class="section-description-info">

              <div class="container-description-info">
                <span class="span-info" >
                  {{'ExportOffer.ExportOfferDetail.SpeciesText' | translate}}:
                </span>
                <h4 class="small-data-info">
                  {{ exportOfferAd.variety.species.name }}
                </h4>
              </div>

              <div class="container-description-info">
                <span class="span-info" >
                  {{'ExportOffer.ExportOfferDetail.VarietyText' | translate}}:
                </span>
                <div class="flex align-items-center">
                  <div class="flex align-items-center">
                    <img [src]="exportOfferAd.variety.urlIcon" style="width: 18px;margin-right:.5em;" alt="Variety icon"/>
                    <h4 class="small-data-info">
                      {{exportOfferAd.variety.name}}
                    </h4>
                  </div>
                </div>
              </div>

              <div class="container-description-info">
                <span class="span-info" >
                  {{'ExportOffer.ExportOfferDetail.CategoryText' | translate}}:
                </span>
                <h4 class="small-data-info">
                  {{exportOfferAd.category ? exportOfferAd.category : getAvailableLabel()}}
                </h4>
              </div>

              <div class="container-description-info">
                <span class="span-info" >
                  {{'ExportOffer.ExportOfferDetail.LocationLabel' | translate}}:
                </span>
                <h4 class="small-data-info">
                  {{exportOfferAd.fridgeStorage ? exportOfferAd.fridgeStorage.contactCity + ', ' + (exportOfferAd.fridgeStorage.contactCountry | countryDescription) : exportOfferAd.country.name}}
                </h4>
              </div>

              <div class="container-description-info">
                <span class="span-info" >
                  {{'ExportOffer.ExportOfferDetail.HarvestDateText' | translate}}:
                </span>
                <h4 class="small-data-info">
                  {{exportOfferAd.harvestDate ? (exportOfferAd.harvestDate | date: 'dd-MM-yyyy') : getAvailableLabel()}}
                </h4>
              </div>

              <div class="container-description-info">
                <span class="span-info" >
                  {{'ExportOffer.ExportOfferDetail.ShelfLife' | translate}}:
                </span>
                <h4 class="small-data-info">
                  {{exportOfferAd.shelfLife ? exportOfferAd.shelfLife + ' ' + translate.instant('ExportOffer.ExportOfferDetail.DayShelflifeText') : getAvailableLabel()}}
                </h4>
              </div>

              <div class="container-description-info">
                <span class="span-info" >
                  {{'ExportOffer.ExportOfferDetail.CasePerPalletsText' | translate}}:
                </span>
                <h4 class="small-data-info">
                  {{exportOfferAd.caseQuantityPerPallet}}
                </h4>
              </div>

              <div class="container-description-info">
                <span class="span-info" >
                  {{'ExportOffer.ExportOfferDetail.SpeciesSizeText' | translate}}:
                </span>
                <h4 class="small-data-info">
                  {{exportOfferAd.speciesSize ? exportOfferAd.speciesSize : getAvailableLabel()}}
                </h4>
              </div>

              <div class="container-description-info">
                <span class="span-info" >
                  {{'ExportOffer.ExportOfferDetail.SpeciesSizeMillimetersFieldText' | translate}}:
                </span>
                <h4 class="small-data-info">
                  {{exportOfferAd.sizeMillimeters ? exportOfferAd.sizeMillimeters + ' mm.' : getAvailableLabel()}}
                </h4>
              </div>

              <div class="container-description-info">
                <span class="span-info" >
                  {{'ExportOffer.ExportOfferDetail.NetKilosText' | translate}}:
                </span>
                <h4 class="small-data-info">
                  {{exportOfferAd.netKilos ? exportOfferAd.netKilos + ' Kg.' : getAvailableLabel()}}
                </h4>
              </div>

              <div class="container-description-info">
                <span class="span-info" >
                  {{'ExportOffer.ExportOfferDetail.GrossKilosText' | translate}}:
                </span>
                <h4 class="small-data-info">
                  {{exportOfferAd.grossKilos ? exportOfferAd.grossKilos + ' Kg.' : getAvailableLabel()}}
                </h4>
              </div>

              <div class="container-description-info">
                <span class="span-info" >
                  {{'ExportOffer.ExportOfferDetail.BoxTypeText' | translate}}:
                </span>
                <h4 class="small-data-info">
                  {{exportOfferAd.boxType ? getBoxType(exportOfferAd.boxType) : getAvailableLabel()}}
                </h4>
              </div>

              <div class="container-description-info">
                <span class="span-info" >
                  {{'ExportOffer.ExportOfferDetail.CaseSize' | translate}}:
                </span>
                <h4 class="small-data-info">
                  {{exportOfferAd.caseSize ? exportOfferAd.caseSize : getAvailableLabel()}}
                </h4>
              </div>

              <div class="container-description-info">
                <span class="span-info" >
                  {{'ExportOffer.ExportOfferDetail.BasePalletsText' | translate}}:
                </span>
                <h4 class="small-data-info">
                  {{exportOfferAd.basePallets ? getBasePallets(exportOfferAd.basePallets) : getAvailableLabel()}}
                </h4>
              </div>

              <div class="container-description-info">
                <span class="span-info" >
                  {{'ExportOffer.ExportOfferDetail.BasePalletsText' | translate}}:
                </span>
                <h4 class="small-data-info">
                  {{exportOfferAd.basePallets ? getBasePallets(exportOfferAd.basePallets) : getAvailableLabel()}}
                </h4>
              </div>

              <div class="container-description-info">
                <span class="span-info" >
                  {{'ExportOffer.ExportOfferDetail.ColorText' | translate}}:
                </span>
                <h4 class="small-data-info">
                  {{exportOfferAd.color ? exportOfferAd.color : getAvailableLabel()}}
                </h4>
              </div>

              <div class="container-description-info">
                <span class="span-info" >
                  {{'ExportOffer.ExportOfferDetail.MarksText' | translate}}:
                </span>
                <h4 class="small-data-info">
                  {{exportOfferAd.marks ? exportOfferAd.marks : getAvailableLabel()}}
                </h4>
              </div>

              <div class="container-description-info">
                <span class="span-info" >
                  {{'ExportOffer.ExportOfferDetail.qualityControlText' | translate}}:
                </span>
                <h4 class="small-data-info">
                  {{getIncludedLabel(exportOfferAd.isQualityControl)}}
                </h4>
                <p-button *ngIf="exportOfferAd.qualityDocuments.length > 0" (onClick)="getExportOfferQualityDocument(exportOfferAd.qualityDocuments)" pTooltip="{{'UserPages.UserExportOffers.EditExportOffer.ViewQualityDocument' | translate}}" [style]="{ 'margin': 0, 'padding': 0 }" icon="pi pi-file-pdf" styleClass="p-button-link p-button-lg"></p-button>
              </div>

              <div class="container-description-info">
                <span class="span-info" >
                  {{'ExportOffer.ExportOfferDetail.QualityControlDateText' | translate}}:
                </span>
                <h4 class="small-data-info">
                  {{exportOfferAd.qualityControlDate ? (exportOfferAd.qualityControlDate | date: 'dd-MM-yyyy') : getAvailableLabel()}}
                </h4>
              </div>

              <div class="container-description-info">
                <span class="span-info" >
                  {{'ExportOffer.ExportOfferDetail.OriginCountryText' | translate}}:
                </span>
                <div class="flex align-items-center">
                  <div class="flex align-items-center">
                    <img [src]="'../../../../assets/img/flags/' + (exportOfferAd.country.code) + '.png'" style="width: 18px; margin-right:10px" alt=""/>
                    <h4 class="small-data-info">
                      {{exportOfferAd.country | countryDescription}}
                    </h4>
                  </div>
                </div>
              </div>

              <div class="container-description-info">
                <span class="span-info" >
                  {{'ExportOffer.ExportOfferDetail.MarketCountry1Text' | translate}}:
                </span>
                <div class="flex align-items-center">
                  <div class="flex align-items-center">
                    <img [src]="'../../../../assets/img/flags/' + exportOfferAd.marketCountryFirst.code + '.png'" style="width: 18px" alt=""/>
                    <h4 class="small-data-info">
                      {{exportOfferAd.marketCountryFirst ? (exportOfferAd.marketCountryFirst | countryDescription) : getAvailableLabel()}}
                    </h4>
                  </div>
                </div>
              </div>

              <div class="container-description-info">
                <span class="span-info" >
                  {{'ExportOffer.ExportOfferDetail.MarketCountry2Text' | translate}}:
                </span>
                <div class="flex align-items-center">
                  <div class="flex align-items-center" *ngIf="exportOfferAd.marketCountrySecond">
                    <img [src]="'../../../../assets/img/flags/' + exportOfferAd.marketCountrySecond.code + '.png'" style="width: 18px" alt=""/>
                    <h4 class="small-data-info">
                      {{exportOfferAd.marketCountrySecond ? (exportOfferAd.marketCountrySecond | countryDescription) : getAvailableLabel()}}
                    </h4>
                  </div>
                  <div class="flex align-items-center"  *ngIf="!exportOfferAd.marketCountrySecond">
                    <h4 class="small-data-info">
                      {{'ExportOffer.ExportOfferDetail.NoAvailable' | translate}}
                    </h4>
                  </div>
                </div>
              </div>

              <div class="container-description-info">
                <span class="span-info" >
                  {{'ExportOffer.ExportOfferDetail.MarketCountry3Text' | translate}}:
                </span>
                <div class="flex align-items-center">
                  <div class="flex align-items-center" *ngIf="exportOfferAd.marketCountryThird">
                    <img [src]="'../../../../assets/img/flags/' + exportOfferAd.marketCountryThird.code + '.png'" style="width: 18px" alt=""/>
                    <h4 class="small-data-info">
                      {{exportOfferAd.marketCountryThird ? (exportOfferAd.marketCountryThird | countryDescription) : getAvailableLabel()}}
                    </h4>
                  </div>
                  <div class="flex align-items-center" *ngIf="!exportOfferAd.marketCountryThird">
                    <h4 class="small-data-info">
                      {{'ExportOffer.ExportOfferDetail.NoAvailable' | translate}}
                    </h4>
                  </div>
                </div>
              </div>


              <div class="container-description-info">
                <span class="span-info" >
                </span>
                <div class="flex align-items-center">
                  <div class="flex align-items-center">
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <p-toast></p-toast>
  </app-content-body>
</app-user-dashboard>
<app-footer-home></app-footer-home>
