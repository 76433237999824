import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { DocumentServiceGateway } from 'src/app/application/gateway/DocumentServiceGateway';
import { ExportOfferDocumentGateway } from 'src/app/application/gateway/ExportOfferDocumentGateway';
import { ExportOfferDocument, NewExportOfferDocument } from 'src/app/domain/models/ExportOfferDocument';
import { ErrorMessageUtil } from 'src/app/ui/util/error-message-util';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-export-offer-documents-crud',
  templateUrl: './export-offer-documents-crud.component.html',
  styleUrls: ['./export-offer-documents-crud.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class ExportOfferDocumentsCrudComponent implements OnInit {
  @Input()
  exportOfferId: any | undefined

  documents: any[] = []

  documentDialogVisible: boolean = false
  submitDocument: boolean = false
  loading: boolean = false

  selectedDocument: ExportOfferDocument | undefined
  documentPdfFile: any

  documentFormGroup: FormGroup = this._formBuilder.group({
    name: ['', Validators.required]
  })

  constructor(private _formBuilder: FormBuilder,
    private _messageService: MessageService,
    private _confirmationService: ConfirmationService,
    private _exportOfferDocumentService: ExportOfferDocumentGateway,
    private _documentService: DocumentServiceGateway,
    public translate: TranslateService) {

  }

  ngOnInit(): void {
    this.getDocuments()
  }

  getDocuments() {
    if(this.exportOfferId) {
      this.documents =  []
      this.loading = true
      this._exportOfferDocumentService.getAllByExportOffer(this.exportOfferId).subscribe({
        next: (v) => {
          this.documents = v
          this.loading = false
        },
        error: (e) => {
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOfferDocument.ManyRetrieveError') });
          console.log(e)
          this.loading = false
        },
        complete: () =>  {
          this.loading = false
          console.info('Export Offer Documents query successfully')
        }
      })
    }
  }

  openDocumentCreateDialog(formDirective: FormGroupDirective, fileUploader: FileUpload) {
    fileUploader.clear()
    formDirective.resetForm()
    this.documentFormGroup.controls['name'].setValue('')
    this.documentPdfFile = null
    this.selectedDocument = undefined
    this.submitDocument = false
    this.documentDialogVisible = true
  }

  openDocumentEditDialog(document: ExportOfferDocument, fileUploader: FileUpload) {
    this.selectedDocument = document
    this.documentFormGroup.controls['name'].setValue(this.selectedDocument.name)
    if(this.selectedDocument.pdfUrl) {
      let uri = this.selectedDocument.pdfUrl.trim().replace(" ", "%20")
      uri = environment.apiUrl + uri
      fetch(uri).then(r => r.blob()).then(blob => {
        if(blob && this.selectedDocument) {
          let filename = uri.substring(uri.lastIndexOf('/')+1)
          filename = filename.replace("%20", " ")
          let pdf = new File([blob], filename)
          fileUploader.clear()
          fileUploader.files = [pdf]
          this.documentPdfFile = pdf
        }
      })
    }
    this.documentDialogVisible = true
  }

  onDeleteDocument(document: ExportOfferDocument) {
    this._confirmationService.confirm({
      message: this.translate.instant('MessageService.ExportOfferDocument.ConfirmDeleteDialogMessage'),
      header: this.translate.instant('MessageService.ExportOfferDocument.ConfirmDeleteDialogHeader'),
      icon: 'pi pi-info-circle',
      accept: () => {
        this._exportOfferDocumentService.delete(document.id).subscribe({
          next: (v) => {
            this.selectedDocument = undefined
            this.getDocuments()
            this._messageService.add({ severity: 'info',
              summary: this.translate.instant('MessageService.Summary.SuccessAction'),
              detail: this.translate.instant('MessageService.ExportOfferDocument.DeleteSuccess') })

          },
          error: (e) => {
            console.log(e)
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.ExportOfferDocument.DeleteError') });
          },
          complete: () => console.info('Export Offer Document delete successfully')
        })
      },
      reject: (type: any) => {
          switch (type) {
              case ConfirmEventType.REJECT:
                //this._messageService.add({ severity: 'error', summary: 'Rejected', detail: 'Se canceló la eliminación' })
                break;
              case ConfirmEventType.CANCEL:
                //this._messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Se canceló la eliminación' })
                break;
          }
      }
    })
  }

  saveDocument() {
    this.submitDocument = true
    if(this.documentFormGroup.valid) {
      this.loading = true
      if(this.selectedDocument) {
        this.selectedDocument.name = this.documentFormGroup.controls['name'].value
        this.selectedDocument.pdf = this.documentPdfFile ? this.documentPdfFile : this.selectedDocument.pdf

        this._exportOfferDocumentService.update(this.selectedDocument).subscribe({
          next: (v) => {
            this.selectedDocument = v
            this.getDocuments()
            this._messageService.add({ severity: 'success',
              summary: this.translate.instant('MessageService.Summary.SuccessAction'),
              detail: this.translate.instant('MessageService.ExportOfferDocument.EditSuccess') })
            this.loading = false
            this.submitDocument = false
            this.documentDialogVisible = false
          },
          error: (e) => {
            this.loading = false
            this.submitDocument = false
            console.log(e)
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.ExportOfferDocument.EditError') });
          },
          complete: () =>  {
            this.loading = false
            this.submitDocument = false
            console.info('Document update successfully') }
        })
      }
      else {
        let newDocument: NewExportOfferDocument = {
          name: this.documentFormGroup.controls['name'].value,
          pdf: this.documentPdfFile,
          exportOfferId: this.exportOfferId
        }

        this._exportOfferDocumentService.create(newDocument).subscribe({
          next: (v) => {
            this.selectedDocument = v
            this.getDocuments()
            this._messageService.add({ severity: 'success',
              summary: this.translate.instant('MessageService.Summary.SuccessAction'),
              detail: this.translate.instant('MessageService.ExportOfferDocument.CreateSuccess') })
            this.loading = false
            this.submitDocument = false
            this.documentDialogVisible = false
          },
          error: (e) => {
            this.loading = false
            this.submitDocument = false
            console.log(e)
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.ExportOfferDocument.CreateError') });
          },
          complete: () =>  {
            this.loading = false
            this.submitDocument = false
            console.info('Document create successfully')
          }
        })
      }
    }
    else {
      this.loading = false
    }
  }

  onSelectDocumentFile(event: any) {
    this.documentPdfFile = event.files[0]
  }

  onClearDocumentFile(event: any) {
    this.documentPdfFile = null
  }

  getErrorMessage(formGroup: FormGroup, fieldName: string): string {
    return ErrorMessageUtil.getErrorMessage(formGroup, this.translate, fieldName)
  }

  getExportOfferDocument(exportOfferDocumentId: number) {
    this._documentService.getExportOfferDocument2(exportOfferDocumentId).subscribe((resultBlob: any) => {
      var downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }
}
