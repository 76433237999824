import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { FrozenFruitPalletGateway } from 'src/app/application/gateway/FrozenFruitPalletGateway';
import { FrozenFruitPallet, FrozenFruitPalletList, FrozenFruitPalletQCOriginReviewRequest } from 'src/app/domain/models/FrozenFruitPallet';
import { GenericOrderDetail } from 'src/app/domain/models/ImportOrder';
import { BasicUser } from 'src/app/domain/models/User';
import { Routes } from 'src/app/infrastructure/routes';
import { ErrorMessageUtil } from 'src/app/ui/util/error-message-util';

@Component({
  selector: 'app-admin-frozen-fruit-verify-pallets',
  templateUrl: './admin-frozen-fruit-verify-pallets.component.html',
  styleUrls: ['./admin-frozen-fruit-verify-pallets.component.css'],
  providers: [MessageService]
})
export class AdminFrozenFruitVerifyPalletsComponent implements OnInit {

  breadcrumbMenuItems: MenuItem[] = []
  breadcrumbHome: MenuItem

  orderDetailId: number | undefined
  dto: FrozenFruitPalletList | undefined
  orderDetail!: GenericOrderDetail
  pallets: FrozenFruitPallet[] = []
  approvedPallets: FrozenFruitPallet[] = []

  profile: BasicUser | undefined

  submit: boolean = false
  loading: boolean = false

  formGroup: FormGroup = this._formBuilder.group({
    qualityControlDate: [null, Validators.required],
    originQCReviewPrice: [null, Validators.required],
    qcObservations: [null]
  })

  constructor(private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    public translate: TranslateService,
    private _messageService: MessageService,
    private _authService: AuthGateway,
    private _frozenFruitPalletService: FrozenFruitPalletGateway) {
    this._activatedRoute.params.subscribe(params => {
      this.orderDetailId = params['orderDetailId']
    })

    this.breadcrumbHome = {label: 'Ordenes de compra', routerLink: ['/' + Routes.adminManageOrderPage]}
  }

  ngOnInit(): void {
    this.profile = this._authService.getUser()
    this.loadPallets()
  }

  loadPallets() {
    if(this.orderDetailId) {
      this._frozenFruitPalletService.getAllByFrozenFruitOrderDetail(this.orderDetailId).subscribe({
        next: (v) => {
          console.log(v)
          this.dto = v
          this.orderDetail = this.dto.orderDetail
          this.pallets = this.dto.pallets
          this.loadForm()
          this.loading = false
        },
        error: (e) => {
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: 'Could not retrieve the import order detail' })
          this.loading = false
          console.log(e)
        },
        complete: () =>  {
          this.loading = false
          console.info('Import order detail query successfully')
        }
      })
    }
  }

  loadForm() {
    if(this.orderDetail) {
      this.formGroup.controls['qualityControlDate'].setValue(this.orderDetail.qualityControlDate ? new Date(this.orderDetail.qualityControlDate) : null)
      this.formGroup.controls['qcObservations'].setValue(this.orderDetail.originQCObservations)
      this.formGroup.controls['originQCReviewPrice'].setValue(this.orderDetail.originQCReviewPrice ? this.orderDetail.originQCReviewPrice : null)
    }
  }

  savePallets() {
    if(this.orderDetailId) {

      this.submit = true
      if(this.formGroup.valid) {
        let approvedPalletIds: number[] = []

        this.approvedPallets.forEach(x => {
          if(x.id)
            approvedPalletIds.push(x.id)
        })

        let request: FrozenFruitPalletQCOriginReviewRequest = {
          frozenFruitOrderDetailId: this.orderDetailId,
          approvedPalletIds: approvedPalletIds,
          qcReviewPrice: this.formGroup.controls['originQCReviewPrice'].value,
          qualityControlDate: this.formGroup.controls['qualityControlDate'].value,
          qcObservations: this.formGroup.controls['qcObservations'].value
        }

        this.loading = true
        this._frozenFruitPalletService.qcCompanyVerifyPallets(request).subscribe({
          next: (v) => {
            this.loadPallets()
            this._messageService.add({ severity: 'success', summary: 'Acción exitosa', detail: 'Los pallets fueron actualizados con éxito.' })
            this.submit = false
            this.loading = false
          },
          error: (e) => {
            console.log(e)
            this.submit = false
            this.loading = false
            this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudieron actualizar los pallets.' })
          },
          complete: () =>  {
            console.info('Import Order Detail updated successfully')
          }
        })
      }
      else {
        this.loading = false
      }
    }
  }

  back() {
    this._router.navigate(['/' + Routes.adminManageOrderPage])
  }

  getErrorMessage(formGroup: FormGroup, fieldName: string): string {
    return ErrorMessageUtil.getErrorMessage(formGroup, this.translate, fieldName)
  }
}
