import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AuthGateway } from '../application/gateway/AuthGateway';
import { Species } from '../domain/models/Species';
import { Lang } from '../ui/util/langs';

@Pipe({
  name: 'formErrorMessage'
})
export class FormErrorMessagePipe implements PipeTransform {

  constructor(private translateService: TranslateService) {
  }

  transform(fieldName: string, formGroup: FormGroup, divisor?: number): string {
    if(!formGroup)
      return ''

    if(!formGroup.controls[fieldName])
      return ''

    if (formGroup.controls[fieldName].hasError('required')) {
      return this.translateService.instant('FormErrorMessage.FieldValueRequired')
    }

    if (formGroup.controls[fieldName].hasError('email')) {
      return this.translateService.instant('FormErrorMessage.EmailFieldEmailFormatError')
    }

    if (formGroup.controls[fieldName].hasError('divisor') && divisor) {
      return this.translateService.instant('FormErrorMessage.DivisorError') + divisor;
    }

    return '';
  }
}
