<!--
<div>
    <div>
        <span class="share-text">{{'Share.ShareText' | translate}}</span>
    </div>
    <div class="flex justify-content-start gap-1">
        <div class="share-button" (click)="clickFace()">
            <img src="../../../../assets/img/social_media/facebook_icon.svg" width="32" height="32">
        </div>
        <div class="share-button" (click)="clickWapp()">
            <img src="../../../../assets/img/social_media/whatsApp_icon.svg" width="32" height="32">
        </div>
        <div class="share-button" (click)="clickLinkedIn()">
            <img src="../../../../assets/img/social_media/linkedin_icon.svg" width="32" height="32">
        </div>
        <div class="share-button" (click)="copyUrl()">
            <img src="../../../../assets/img/social_media/copy_icon.svg" width="32" height="32">
        </div>
</div>
</div>
-->

<div class="section-networks">
  <p class="span-networks">
    {{'ExportOffer.ExportOfferDetail.Share' | translate}}:
  </p>
  <section class="section-social-networks">
    <div class="figure-circle color-wpp" (click)="clickWapp()">
      <i class="pi pi-whatsapp icon-network"></i>
    </div>
    <div class="figure-circle color-fb" (click)="clickFace()">
      <i class="pi pi-facebook icon-network"></i>
    </div>
    <div class="figure-circle color-lin" (click)="clickLinkedIn()">
      <i class="pi pi-linkedin icon-network"></i>
    </div>
    <div class="figure-circle color-copy" (click)="copyUrl()">
      <i class="pi pi-copy icon-network"></i>
    </div>
  </section>
</div>

<p-toast></p-toast>
