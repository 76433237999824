import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { ProductCertificateGateway } from 'src/app/application/gateway/ProductCertificateGateway';
import { ProductCertificate } from 'src/app/domain/models/ProductCertificate';
import { Routes } from 'src/app/infrastructure/routes';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-product-certificates',
  templateUrl: './user-product-certificates.component.html',
  styleUrls: ['./user-product-certificates.component.css'],
  providers: [ConfirmationService, MessageService]
})
export class UserProductCertificatesComponent implements OnInit {

  certificates: any[] = []
  selected: any | null = null
  loading: boolean = true

  constructor(private _router: Router,
    private _messageService: MessageService,
    private _confirmationService: ConfirmationService,
    private _authService: AuthGateway,
    private _productCertificateService: ProductCertificateGateway,
    public translate: TranslateService) {

  }

  ngOnInit(): void {
    this.getAll()
  }

  getAll() {
    this.loading = true
    this._productCertificateService.getAllByUsername().subscribe({
      next: (v) => {
        this.certificates = v
        this.loading = false
      },
      error: (e) => {
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.ProductCertificate.ManyRetrieveError') });
        console.log(e)
        this.loading = false
      },
      complete: () =>  {
        this.loading = false
        console.info('Product Certificates query successfully')
      }
    })
  }

  goToCreateForm() {
    this._router.navigate([Routes.productCertificateForm, 0])
  }

  goToEditForm(id: number) {
    this._router.navigate([Routes.productCertificateForm, id])
  }

  onDelete(id: number) {
    this._confirmationService.confirm({
      message: this.translate.instant('MessageService.ProductCertificate.ConfirmDeleteDialogMessage'),
      header: this.translate.instant('MessageService.ProductCertificate.ConfirmDeleteDialogHeader'),
      icon: 'pi pi-info-circle',
      accept: () => {
        this._productCertificateService.delete(id).subscribe({
          next: (v) => {
            this.selected = null
            this._messageService.add({ severity: 'info',
              summary: this.translate.instant('MessageService.Summary.SuccessAction'),
              detail: this.translate.instant('MessageService.ProductCertificate.DeleteSuccess') })
            this.getAll()
          },
          error: (e) => {
            console.log(e)
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.ProductCertificate.DeleteError') });
          },
          complete: () => console.info('Product Certificate delete successfully')
        })
      },
      reject: (type: any) => {
          switch (type) {
              case ConfirmEventType.REJECT:
                //this._messageService.add({ severity: 'error', summary: 'Rejected', detail: 'Se canceló la eliminación' })
                break;
              case ConfirmEventType.CANCEL:
                //this._messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Se canceló la eliminación' })
                break;
          }
      }
    })
  }

  getPdfUrl(certificate: ProductCertificate): string {
    if(certificate.pdfUrl) {
      let uri = certificate.pdfUrl.trim().replace(" ", "%20")
      return environment.apiUrl + uri
    }
    return ''
  }

}
