import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ProductCertificateType } from "src/app/domain/models/ProductCertificateType";
import { Routes } from "src/app/infrastructure/routes";
import { ProductCertificateTypeGateway } from "../gateway/ProductCertificateTypeGateway";

@Injectable({providedIn: 'root'})
export class ProductCertificateTypeService extends ProductCertificateTypeGateway {

  constructor(private _http: HttpClient) {
    super()
  }

  getAll(): Observable<ProductCertificateType[]> {
    return this._http.get<ProductCertificateType[]>(Routes.productCertificateTypeApi + '/GetAll')
  }

}
