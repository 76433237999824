<p-blockUI [blocked]="loading">Cargando</p-blockUI>
<app-dashboard-layout>
  <app-content-header>
    <app-home-header></app-home-header>
  </app-content-header>
  <app-content-body>
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="home" [style]="{'margin-bottom':'20px'}"></p-breadcrumb>
    <h2>{{'ContainerOrderPage.PageTitle' | translate}}</h2>
    <div *ngIf="orders.length <= 0">
      <p>{{'ContainerOrderPage.NotContainersMessageText' | translate}}</p>
      <p-button label="{{'ContainerOrderPage.GoToMarketPlaceButton' | translate}}" (click)="goToMarketplace()"></p-button>
    </div>
    <div *ngFor="let order of orders">
      <p-card>
        <div class="grid">
          <div class="col-12 flex justify-content-center align-items-center">
            <div class="col-8 flex justify-content-start align-items-start flex-row">
              <div *ngIf="getOrderTotalQuantity(order) === 20" style="color: #6da043; font-weight: bold;">
                <i class="pi pi-check-circle" style="font-size: 1.5rem;"></i>&nbsp;&nbsp;
                <span>{{'ContainerOrderPage.ContainersTable.ContanierCompleteMessageText' | translate}}</span>
              </div>

              <div *ngIf="getOrderTotalQuantity(order) < 20" style="color: #fbc02d;">
                <i class="pi pi-times-circle" style="font-size: 1.5rem;"></i>&nbsp;&nbsp;
                <span>{{'ContainerOrderPage.ContainersTable.ContanierIncompleteMessageText' | translate}}</span>
              </div>

              <div *ngIf="getOrderTotalQuantity(order) > 20" style="color:#d32f2f;">
                <i class="pi pi-exclamation-triangle" style="font-size: 1.5rem;"></i>&nbsp;&nbsp;
                <span>{{'ContainerOrderPage.ContainersTable.ContanierSurpassMessageText' | translate}}</span>
              </div>


            </div>
            <div class="col-4 flex justify-content-center align-items-center" style="background: #F0E1E1; font-weight: bold; font-size: 1rem; border-radius: 8px; padding: 6px">
                <i class="pi pi-stopwatch" style="font-size: 1.5rem; color: #8A1B1B; margin: 0 5px; font-weight: bold"></i>&nbsp;&nbsp;
                <span style="color: #000; font-weight: bold;">{{'ContainerOrderPage.ContainersTable.ExpireInText' | translate}}</span><app-timer style="color: #8A1B1B; font-weight: bold;" [orderDateTarget]="order.created" [hoursTimer]="1"></app-timer>
            </div>
          </div>
          <div class="col-12 md:col-2 lg:col-2"></div>
          <div class="col-12 md:col-1 lg:col-1 flex justify-content-center align-items-center"><span style="font-weight: bold;">{{'ContainerOrderPage.ContainersTable.RequestDateHeader' | translate}}</span> </div>
          <div class="col-12 md:col-1 lg:col-1 flex justify-content-center align-items-center"><span style="font-weight: bold;">{{'ContainerOrderPage.ContainersTable.OriginCountryHeader' | translate}}</span></div>
          <div class="col-12 md:col-2 lg:col-2 flex justify-content-center align-items-center"><span style="font-weight: bold;">{{'ContainerOrderPage.ContainersTable.DestinationCountryHeader' | translate}}</span></div>
          <div class="col-12 md:col-1 lg:col-1 flex justify-content-center align-items-center"><span style="font-weight: bold;">{{'ContainerOrderPage.ContainersTable.PalletsHeader' | translate}}</span></div>
          <div class="col-12 md:col-1 lg:col-1 flex justify-content-center align-items-center" style="font-weight: bold;">{{'ContainerOrderPage.ContainersTable.ProductPriceHeader' | translate}}</div>
          <div class="col-12 md:col-1 lg:col-1 flex justify-content-center align-items-center" style="font-weight: bold;">{{'ContainerOrderPage.ContainersTable.QCPriceeHeader' | translate}}</div>
          <div class="col-12 md:col-1 lg:col-1 flex justify-content-center align-items-center" style="font-weight: bold;">Ocean freight</div>
          <div class="col-12 md:col-1 lg:col-1 flex justify-content-center align-items-center"><span style="font-weight: bold;">{{'ContainerOrderPage.ContainersTable.TotalPriceHeader' | translate}}</span></div>
          <div class="col-12 md:col-1 lg:col-1 flex justify-content-end align-items-center"><span style="font-weight: bold;">{{'ContainerOrderPage.ContainersTable.ActionsHeader' | translate}}</span></div>

          <div class="col-12 md:col-2 lg:col-2">
            <div class="grid">
              <div class="col-4">
                <img src="../../../../assets/icons/container-icon.png" style="width: 100%; height: auto;" alt=""/>
              </div>
              <div class="col-8 flex align-items-center">
                <span style="font-weight: bold;">{{ 'ContainerOrderPage.OrderLabel' | translate }} : {{ getOrderTitle(order) }}</span>
              </div>
            </div>
          </div>
          <div class="col-12 md:col-1 lg:col-1 flex justify-content-center align-items-center">{{ order.created | date: 'dd-MM-yyyy' }}</div>
          <div class="col-12 md:col-1 lg:col-1 flex justify-content-center align-items-center">{{ order.countryFrom }}</div>
          <div class="col-12 md:col-2 lg:col-2 flex justify-content-center align-items-center">{{ order.countryTo + ' / ' + order.portTo }}</div>
          <div class="col-12 md:col-1 lg:col-1 flex justify-content-center align-items-center">{{ order.totalPallets }}</div>
          <div class="col-12 md:col-1 lg:col-1 flex justify-content-center align-items-center">{{ order.totalPrice | currency: 'USD':'US$' }}</div>
          <div class="col-12 md:col-1 lg:col-1 flex justify-content-center align-items-center">{{ getQCPrice(order) | currency: 'USD':'US$' }}</div>
          <div class="col-12 md:col-1 lg:col-1 flex justify-content-center align-items-center">{{ order.oceanFreight | currency: 'USD':'US$' }}</div>
          <div class="col-12 md:col-1 lg:col-1 flex justify-content-center align-items-center" style="font-weight: bold;">{{ getOrderTotalPrice(order) | currency: 'USD':'US$' }}</div>
          <div class="col-12 md:col-1 lg:col-1 flex justify-content-end align-items-center">
            <div class="flex align-items-center justify-content-end">
              <button type="button" pButton pRipple pTooltip="{{'ContainerOrderPage.ContainersTable.DetailsButtonLabel' | translate}}" (click)="setVisibleOrderDetail(order)" class="p-button-text p-button-rounded p-button-plain mr-2" [icon]="order.expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" style="background-color: #8d2c87; color: #fff"></button>
            </div>
          </div>

          <div *ngIf="order.expanded" class="col-12">
            <p-table [value]="order.details" dataKey="id">
              <ng-template pTemplate="header">
                <tr>
                  <th>
                    <div class="flex justify-content-center align-items-center" style="font-weight: bold;">{{'ContainerOrderPage.OrderDetailTable.ProductTextHeader' | translate}}</div>
                  </th>
                  <th>
                    <div class="flex justify-content-center align-items-center" style="font-weight: bold;">{{'ContainerOrderPage.OrderDetailTable.NameTexHeader' | translate}}</div>
                  </th>
                  <th>
                    <div class="flex justify-content-center align-items-center" style="font-weight: bold;">{{'ContainerOrderPage.OrderDetailTable.ListPriceHeader' | translate}}</div>
                  </th>
                  <th>
                    <div class="flex justify-content-center align-items-center" style="font-weight: bold;">{{'ContainerOrderPage.OrderDetailTable.CasePerPalletsHeader' | translate}}</div>
                  </th>
                  <th>
                    <div class="flex justify-content-center align-items-center" style="font-weight: bold;">{{'ContainerOrderPage.OrderDetailTable.PalletsHeader' | translate}}</div>
                  </th>
                  <th>
                    <div class="flex justify-content-center align-items-center" style="font-weight: bold;">{{'ContainerOrderPage.OrderDetailTable.TotalCasesHeader' | translate}}</div>
                  </th>
                  <th>
                    <div class="flex justify-content-center align-items-center" style="font-weight: bold;">{{'ContainerOrderPage.OrderDetailTable.FruitCostHeader' | translate}}</div>
                  </th>
                  <th>
                    <div class="flex justify-content-center align-items-center" style="font-weight: bold;">{{'ContainerOrderPage.OrderDetailTable.ActionsHeader' | translate}}</div>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-detail>
                <tr>
                  <td><div class="flex justify-content-center align-items-center"><img [src]="detail.varietyUrlIcon" style="border-radius: 7px; width: 100%; max-width: 50px; height: auto;" alt="Variety icon"/></div></td>
                  <td><div class="flex justify-content-center align-items-center">{{ detail.speciesName + ' / ' + detail.varietyName }}</div></td>
                  <td><div class="flex justify-content-center align-items-center">{{ detail.price | currency: 'USD':'US$' }} {{ detail.incoterms }}</div></td>
                  <td><div class="flex justify-content-center align-items-center">{{ detail.boxesPerPallet }}</div></td>
                  <td><div class="flex justify-content-center align-items-center">{{ detail.palletsQuantity }}</div></td>
                  <td><div class="flex justify-content-center align-items-center">{{ getOrderDetailTotalCases(detail) }}</div></td>
                  <td><div class="flex justify-content-center align-items-center">{{ getDetailTotalPrice(detail) | currency: 'USD':'US$' }} {{ detail.incoterms }}</div></td>
                  <td>
                    <div class="flex justify-content-center align-items-center gap-4">
                      <p-button icon="pi pi-minus" pTooltip="{{'ContainerOrderPage.OrderDetailTable.DeleteToolTipText' | translate}}" (onClick)="removeDetail(detail.id, order.id)" styleClass="p-button-rounded p-button-danger p-button-sm"></p-button>
                      <p-button icon="pi pi-arrows-v" pTooltip="{{'ContainerOrderPage.OrderDetailTable.MoveDetailContainerToolTipText' | translate}}" (onClick)="showMoveToAnotherContainerDialog(detail)" [disabled]="orders.length < 1" styleClass="p-button-rounded p-button-sm" [style]="{'background-color':'#CDCCCC'}"></p-button>
                      <p-button icon="pi pi-eye" pTooltip="{{'ContainerOrderPage.OrderDetailTable.SeeOfferToolTipText' | translate}}" (onClick)="showOffer(detail)" styleClass="p-button-rounded p-button-info p-button-sm"></p-button>
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>

          <div class="col-12 flex justify-content-center align-items-center">
            <div class="grid w-full">
              <!-- <div class="col-6 flex justify-content-start align-items-center">
                <p-tag [style]="{ 'background': '#EBEDF4'}">
                  {{'ContainerOrderPage.ContainersTable.StimatedCostText' | translate}}
                </p-tag>
              </div> -->
              <div class="col-6 flex justify-content-start align-items-center" style="color: #000; background: #EBEDF4; font-weight: bold; font-size: 0.8rem; padding: 8px; border-radius: 5px">
                <i class="pi pi-exclamation-triangle" style="font-size: 1.5rem; color: #672572; margin: 0 5px; font-weight: bold"></i>&nbsp;&nbsp;
                <span>{{'ContainerOrderPage.ContainersTable.StimatedCostText' | translate}}</span>
              </div>
              <div class="col-6 flex justify-content-end align-items-center">
                <p-button label="QC Company" (onClick)="showSelectQCCompany(order)" *ngIf="order.qcCompanies && order.qcCompanies.length > 0 && !order.qualityControlCompany"></p-button>
                <p-button
                  label="{{'ContainerOrderPage.ContainersTable.MakeOrderButton' | translate}}"
                  (onClick)="confirmOrder(order)"
                  [disabled]="getOrderTotalQuantity(order) > 20 || getOrderTotalQuantity(order) < 20"
                  [loading]="waitConfirmation"
                  *ngIf="(order.qcCompanies && order.qcCompanies.length > 0 && order.qualityControlCompany)"></p-button>
              </div>
              <div class="col-12 flex justify-content-end align-items-center" style="font-weight: bolder;" *ngIf="order.qcCompanies && order.qcCompanies.length > 0 && !order.qualityControlCompany">
                {{'ContainerOrderPage.QCCompanySuggestionText' | translate}}
              </div>
            </div>
          </div>
        </div>
      </p-card>
      <div class="col-12"></div>
    </div>
  </app-content-body>
</app-dashboard-layout>

<p-confirmDialog [style]="{width: '30vw'}"></p-confirmDialog>

<p-dialog header="{{'ContainerOrderPage.OrderDetailTable.DialogHeader' | translate}}" [(visible)]="visibleMoveToAnotherContainer" [style]="{width: '40vw'}">
  <div class="p-fluid formgrid grid">
    <div class="field col-12">
      <label for="detinationContainer">{{'ContainerOrderPage.OrderDetailTable.DestinationContainerLabel' | translate}}</label>
      <p-dropdown
        inputId="detinationContainer"
        [(ngModel)]="selectedOrder"
        [showClear]="true"
        appendTo="body"
        [options]="orders"
        placeholder="{{'ContainerOrderPage.DetinationContainerPlaceholder' | translate}}">
        <ng-template pTemplate="selectedItem">
          <div class="flex align-items-center gap-2" *ngIf="selectedOrder">
            <div>{{ getOrderTitle(selectedOrder) }}</div>
          </div>
        </ng-template>
        <ng-template let-order pTemplate="item">
          <div class="flex align-items-center gap-2">
            <div>{{ getOrderTitle(order) }}</div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <p-button label="{{'ContainerOrderPage.OrderDetailTable.CloseButton' | translate}}" (onClick)="visibleMoveToAnotherContainer = false" styleClass="p-button-text"></p-button>
    <p-button label="{{'ContainerOrderPage.OrderDetailTable.MoveButton' | translate}}" (onClick)="moveDetail()"></p-button>
  </ng-template>
</p-dialog>

<p-dialog [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleOfferDetailDialog" [style]="{width: '80%'}">
  <app-export-offer-detail [offer]="selectedExportOffer" [productCertificateTypes]="selectedProductCertificateTypes" [packagingTypes]="selectedPackagingTypes"></app-export-offer-detail>
  <ng-template pTemplate="footer">
    <p-button label="{{'ContainerOrderPage.OrderDetailTable.CloseButton' | translate}}" (click)="visibleOfferDetailDialog = false"></p-button>
  </ng-template>
</p-dialog>

<p-toast></p-toast>

<p-dialog [(visible)]="waitConfirmation" [modal]="true" [closable]="false" [style]="{ width: '30vw' }" [draggable]="false" [resizable]="false">
  <p class="m-0 flex align-items-center">
    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
    &nbsp;{{'ContainerOrderPage.CreatingOrderWaitMessage' | translate}}
  </p>
</p-dialog>

<p-dialog header="QC Company" [(visible)]="visibleQCCompany" [style]="{width: '40vw'}">
  <p>{{'ContainerOrderPage.QCCompanyModal.Text' | translate}}</p>
  <p-table [value]="qcCompanies"
    [selection]="itemQCSelected"
    selectionMode="single"
    (onRowSelect)="onQCCompanyTableRowSelect($event)"
    [paginator]="true"
    [rows]="5"
    responsiveLayout="scroll">
    <ng-template pTemplate="header">
      <tr>
        <th>{{'ContainerOrderPage.QCCompanyModal.QCCompanyNameHeader' | translate}}</th>
        <th>{{'ContainerOrderPage.QCCompanyModal.QCCompanyBasePricePerPalletHeader' | translate}}</th>
        <th>{{'ContainerOrderPage.QCCompanyModal.QCCompanyBasePricePerContainerHeader' | translate}}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-company>
      <tr [pSelectableRow]="company">
        <td>{{company.profile?.companyName}}</td>
        <td>{{company.basePricePerPallet | currency: 'USD':'US$'}}</td>
        <td>{{company.basePricePerContainer | currency: 'USD':'US$'}}</td>
      </tr>
    </ng-template>
  </p-table>
  <ng-template pTemplate="footer">
    <p-button label="Cerrar" (onClick)="visibleQCCompany = false" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>

<p-dialog [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleDisclaimerQCDialog" [style]="{ 'min-width': '500px', 'max-width': '900px'}">
  <div class="grid flex align-items-center" style="padding: 0 50px;">
    <div class="col-12 md:col-12 flex justify-content-center align-items-start flex-column" style="font-size: 1.2rem">
      <p style="color: #4C4C4C; font-weight: bolder; margin: 0 auto">
        <span>{{'ContainerOrderPage.QCCompanyModal.TitleDisclaimer' | translate}}</span>
      </p>

      <p style="text-align: center; font-weight: 400; margin: 40px 7px 0">
        {{'ContainerOrderPage.QCCompanyModal.SentenceDisclaimerOne' | translate}}
      </p>

      <p style="text-align: center; font-weight: bolder; margin: 12px 0">
        {{'ContainerOrderPage.QCCompanyModal.SentenceDisclaimerTwo' | translate}}
      </p>

      <div class="col-12 flex justify-content-center align-items-center">
        <img src="../../../../assets/img/disclaimer_icon_fruzty_.png" style="width: 100%; max-width: 100px;" alt="fruzty-logo"/>
      </div>

      <div class="col-12 flex justify-content-evenly align-items-center" style="margin: 20px auto">
        <button pButton (click)="withoutNOQC()" style="color: #6EA042; border-radius: 5px; background-color: #FFFFFF; width: auto; max-width: 180px; height: 40px; border: 1px solid #6EA042">
          {{'ContainerOrderPage.QCCompanyModal.ButtonWithoutQCDisclaimer' | translate}}
        </button>
        <button pButton (click)="withNOQC()" style="background-color: #6EA042; border-radius: 5px; color: #FFFFFF; width: auto; max-width: 180px; height: 40px;">
          {{'ContainerOrderPage.QCCompanyModal.ButtonWithQCDisclaimer' | translate}}
        </button>
      </div>
    </div>
  </div>
</p-dialog>
