<app-user-dashboard [userMenuActiveItemIndex]="0" [activeItem]="'direct-sale'">
  <p-tabView>
    <p-tabPanel header="{{'ProgramTab.ProgramTabView' | translate}}">
      <p-card>
        <div class="grid" style="margin-left: 15px; margin-right: 15px;">
          <div class="col-12 md:col-3">
            <h1>{{'UserPages.UserClosedSale.ProgramTitle' | translate}}</h1>
          </div>
          <div class="col-12 md:col-5 p-fluid flex align-items-center">
            <span class="p-input-icon-right">
              <input pInputText inputId="global_search" placeholder="Ingrese la información a consultar" class="p-inputtext-sm"/>
              <i class="pi pi-search"></i>
            </span>
          </div>
          <div *ngIf="profile?.type == 1" class="col-12 md:col-4 flex justify-content-end align-items-center gap-4">
            <p-button label="Invitar a Fruzty" icon="pi pi-user" styleClass="p-button-secondary" (onClick)="visibleInvite = true"></p-button>
            <p-button label="Nueva venta directa" icon="pi pi-plus-circle" (onClick)="goToDirectSaleCreatePage()"></p-button>
          </div>
        </div>
        <p-divider></p-divider>
        <form [formGroup]="filterForm">
          <div class="grid p-fluid">
            <div class="col-12 md:col">
              <p-dropdown 
              placeholder="{{'UserPages.UserClosedSale.DirectSaleFilters.SpeciesFilter' | translate}}" 
              class="p-inputtext-sm"
              [options]="species"
              optionLabel="nameEs"
              formControlName="speciesId"
              optionValue="id"
              (onChange)="loadVarieties()"
              [filter]="true"
              filterBy="name"
              [style]="{'background-color': '#EDEFF2'}"
              [showClear]="true"
              >
              <ng-template pTemplate="selectedItem">
                <div class="flex align-items-center gap-2" *ngIf="selectedSpecies">
                    <img [src]="selectedSpecies.imageUrl" style="width: 18px" alt=""/>
                    <div>{{ getSpeciesName(selectedSpecies) }}</div>
                </div>
              </ng-template>
              <ng-template let-specie pTemplate="item">
                  <div class="flex align-items-center gap-2">
                      <img [src]="specie.imageUrl" style="width: 18px" alt=""/>
                      <div>{{ getSpeciesName(specie) }}</div>
                  </div>
              </ng-template>
            </p-dropdown>
            </div>
            <div class="col-12 md:col">
              <p-dropdown 
              placeholder="{{'UserPages.UserClosedSale.DirectSaleFilters.VarietyFilter' | translate}}" 
              class="p-inputtext-sm"
              optionLabel="name"
              optionValue="id"
              formControlName="varietyId"
              [options]="varieties"
              [filter]="true"
              [showClear]="true"
              filterBy="name"
              [style]="{'background-color': '#EDEFF2'}"
              (onChange)="onChangeVariety()"
              >
              <ng-template pTemplate="selectedItem">
                <div class="flex align-items-center gap-2" *ngIf="selectedVariety">
                  <img [src]="selectedVariety.urlIcon" style="width: 18px"/>
                  <div>{{ selectedVariety.name }}</div>
                </div>
              </ng-template>
              <ng-template let-variety pTemplate="item">
                  <div class="flex align-items-center gap-2">
                      <img [src]="variety.urlIcon" style="width: 18px"/>
                      <div>{{ variety.name }}</div>
                  </div>
              </ng-template>
            </p-dropdown>
            </div>
            <div class="col-12 md:col">
              <input id="code" 
              formControlName="exportOfferId" 
              type="text" 
              pInputText 
              placeholder="{{'UserPages.UserClosedSale.DirectSaleFilters.ExportOfferCode' | translate}}"
              style="background-color: #EDEFF2; height: 46px;" (keyup)="executeFilter()"
              >
            </div>
            <div *ngIf="profile?.type == 1" class="col-12 md:col">
              <p-dropdown 
              placeholder="{{'UserPages.UserClosedSale.DirectSaleFilters.ImporterFilter' | translate}}" 
              class="p-inputtext-sm"
              optionLabel="companyName"
              optionValue="id"
              formControlName="importerId"
              [options]="importers"
              (onChange)="executeFilter()"
              [style]="{'background-color': '#EDEFF2'}"
              [showClear]="true"
              ></p-dropdown>
            </div>
            <div *ngIf="profile?.type == 2" class="col-12 md:col">
              <p-dropdown 
              placeholder="{{'UserPages.UserClosedSale.DirectSaleFilters.ExporterFilter' | translate}}" 
              class="p-inputtext-sm"
              optionLabel="companyName"
              optionValue="id"
              formControlName="exporterId"
              [options]="exporters"
              (onChange)="executeFilter()"
              [style]="{'background-color': '#EDEFF2'}"
              [showClear]="true"
              ></p-dropdown>
            </div>
            <div class="col-12 md:col">
              <p-dropdown 
              placeholder="{{'UserPages.UserClosedSale.DirectSaleFilters.StatusFilter' | translate}}"
              [options]="statusArray"
              formControlName="isActive"
              optionLabel="label"
              optionValue="value"
              [showClear]="true" 
              [autoDisplayFirst]="false"
              (onChange)="executeFilter()"
              [style]="{'background-color': '#EDEFF2'}"
              class="p-inputtext-sm">
            </p-dropdown>
            </div>
            <div class="col-12 md:col">
              <p-button (click)="sidebarVisible = true" 
              [style]="{'background-color': '#FFF', 'border': '1px solid #8c2b87', 'color': '#8c2b87', 'height': '46px'}"
              label="{{'UserPages.UserClosedSale.DirectSaleFilters.AdvancedFilters' | translate}}" class="p-inputtext-sm" styleClass="p-button-outlined">
              <span class="material-symbols-outlined" style="margin-right: 6px;">
                add
                </span>
              </p-button>
            </div>
            <div class="col-12 md:col">
              <p-button label="{{'UserPages.UserClosedSale.DirectSaleFilters.CleanFilters' | translate}}" styleClass="p-button-outlined" [style]="{'background-color': '#FFF', 'border': '1px solid #8c2b87', 'color': '#8c2b87', 'height': '46px'}" (click)="cleanFilter()">
                <span class="material-symbols-outlined" style="margin-right: 6px;">mop</span>
              </p-button>
            </div>
          </div>
        </form>
        <div style="margin-top: 20px;">
          <p-dataView #dv [value]="offers" [rows]="5" [paginator]="true" emptyMessage="{{'UserPages.UserClosedSale.NotInformationMessageText' | translate}}" [loading]="loading">
            <ng-template let-offer pTemplate="listItem">
              <div class="col-12" style="margin-bottom: 20px;">
                <app-direct-sale-detail
                 [offer]="offer"
                 (ShowFirstConfirmEvent)="showFirstConfirm($event)"
                 (ShowDirectSaleProgramCreateModal)="showDirectSaleProgramCreateModal($event)"
                 (ShowOfferDetailDialogEvent)="showOfferDetailDialog($event)"
                 
                >                
              </app-direct-sale-detail>
              </div>
            </ng-template>
          </p-dataView>
        </div>
      </p-card>
    </p-tabPanel>
    <p-tabPanel *ngIf="profile?.type == 1" header="{{'ProgramTab.ProgramRequestOrder' | translate}}">
      <p-card>
        <div class="grid" style="margin-left: 15px; margin-right: 15px;">
          <div class="col-12 md:col-3">
            <h1>{{'ProgramTab.ProgramRequestOrder' | translate}}</h1>
          </div>
          <div class="col-12 md:col-5 p-fluid flex align-items-center">
            <span class="p-input-icon-right">
              <input pInputText inputId="global_search" placeholder="Ingrese la información a consultar" class="p-inputtext-sm"/>
              <i class="pi pi-search"></i>
            </span>
          </div>
          <!-- <div class="col-12 md:col-4 flex justify-content-end align-items-center gap-4">
            <p-button label="Invitar a Fruzty" icon="pi pi-user" styleClass="p-button-secondary" (onClick)="visibleInvite = true"></p-button>
            <p-button label="Nueva venta directa" icon="pi pi-plus-circle" (onClick)="goToProgramRequestCreate()"></p-button>
          </div> -->
        </div>

        <div style="margin-top: 20px;">
          <app-filter-component
          [statusArray]="programRequestStatusItem"
          (ExecuteFilterEvent)="filterProgramRequestEvent($event)"
          (AdvanceFilterEvent)="showAdvancedFilter($event)"
          (CleanFilterEvent)="cleanFilterPurchaseOrder()"
          >

          </app-filter-component>
          <p-dataView #dv [value]="programRequestInfo" [rows]="5" [paginator]="true" emptyMessage="{{'UserPages.UserClosedSale.NotInformationMessageText' | translate}}" [loading]="loading">
            <ng-template let-info pTemplate="listItem">
              <div class="col-12" style="margin-bottom: 20px;">
                <p-card>
                  <div class="grid">
                     <div class="col-12 md:col-6 flex justify-content-between align-items-center" style="padding-right: 20px; padding-bottom: 20px; border-right: 1px solid #ccc; border-bottom: 1px solid #ccc;">
                      <div class="w-full grid">
                        <div class="col-12 md:col-4 flex justify-content-between gap-1">
                          <div style="width: 100%; max-width: 80px;" class="flex  align-items-center">
                            <span style="border-radius: 7px; padding: 5px; font-weight: bold; font-size: small; width: 100%;" *ngIf="info.pictures.length < 1">
                              {{'UserPages.UserExportOffers.NotAvailablePictureLabel' | translate}}
                            </span>
                            <img [src]="getImageUrl(info)" style="border-radius: 7px; width: 100%; max-width: 50px; height: auto;" alt="" *ngIf="info.pictures.length > 0"/>
                          </div>
                          <div style="width: 100%; max-width: 105px;" class="flex justify-content-start flex-column" >
                            <h2 style="text-transform: uppercase; font-size: medium;" >{{info.programRequest.variety.species | speciesName}}</h2>
                            <span style="text-transform: uppercase; font-size: medium;">{{info.programRequest.variety.name}}</span>
                          </div>
                        </div>
    
                        <div class="col-12 md:col-3 flex align-items-center justify-content-center">
                          <span style="font-weight: bolder; color: #8c2b87; font-size: small;">Id: {{info.programRequest.id}} </span>
                        </div>
    
                        <div class="col-12 md:col-5 flex justify-content-between">
                          <div class="flex align-items-center justify-content-center">
                            <img  style="width: 100%; max-width: 38px; height: 24px;" alt="" src="../../../../assets/img/container_icon_2.png" >
                            <span style="font-size: small; font-weight: bold; margin-left: 5px;">
                              {{info.programRequest.palletQuantity * getTotalWeekRowProgramRequest(info.programRequest) }}
                            </span>
                          </div>
                          <div class="flex align-items-center justify-content-end">
                            <span style="font-weight: bolder; color: #8c2b87; font-size: small; height: auto;" >{{info.programRequest.pricePerCase | currency: 'USD':'US$'}}/{{info.programRequest.typeSale}}</span>
                          </div>
                        </div>
    
                      </div>
    
                    </div>
                    <div class="col-12 md:col-6 flex align-items-center" style="padding-bottom: 20px; border-bottom: 1px solid #ccc;">
                      <div class="grid w-full" style="margin-left: 10px;">
                        <div class="col-12 md:col-8 flex justify-content-between align-items-center gap-2">
                          <div class="flex justify-content-start align-items-center">
                            <img [src]="'../../../../assets/img/flags/' + info.programRequest.country.code + '.png'" height="50px" alt=""/>
                            <div class="flex flex-column" style="margin-left: 10px;">
                              <span style="font-weight: bolder; font-size: small;">{{info.programRequest.country.name}}</span>
                            </div>
                          </div>
                          <!-- <span class="material-symbols-outlined" style="color: #6da043;">directions_boat</span> -->
                          <span class="material-symbols-outlined" style="color: #6da043;"> flight</span>
                          <span class="material-symbols-outlined" style="color: #6da043;">arrow_forward_ios</span>
                          <div class="flex justify-content-start align-items-center">
                            <img [src]="'../../../../assets/img/flags/' + info.programRequest.countryTo.code + '.png'" height="50px" alt=""/>
                            <div class="flex flex-column" style="margin-left: 10px;">
                              <span style="font-weight: bolder; font-size: small;">{{info.programRequest.countryTo.name}}</span>
                              <span style="font-size: small;">
                                <!-- {{'UserPages.UserClosedSale.ProductDetail.DestinationPortText' | translate}} -->
                                {{info.programRequest.portTo?.name}}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 md:col-4 flex justify-content-end align-items-center">
                            <p-tag *ngIf="info.programRequest.programRequestStatus == 0" [value]="getStatusValue(info.programRequest.programRequestStatus)" [style]="{ 'display': 'grid', 'width': '100%', 'min-width': '60px', 'background-color': '#F1D0D0', 'color': '#9E4343', 'font-size':'small'}" ></p-tag>
                            <p-tag *ngIf="info.programRequest.programRequestStatus == 1" [value]="getStatusValue(info.programRequest.programRequestStatus)" [style]="{ 'display': 'grid', 'width': '100%', 'min-width': '60px', 'background-color': '#C3DBA2', 'color': '#4D6A34'}"></p-tag>
                            <p-tag *ngIf="info.programRequest.programRequestStatus == 2" [value]="getStatusValue(info.programRequest.programRequestStatus)" [style]="{ 'display': 'grid', 'width': '100%', 'min-width': '60px', 'background-color': '#D7D7D7', 'color': '#6C7A8F'}"></p-tag>
                            <div style="margin-left: 5px;">
                              <p-button 
                                [icon]="info.expanded ? 'pi pi-angle-up' : 'pi pi-angle-down'" 
                                styleClass="p-button-rounded p-button-outlined"
                                (click)="setVisibleProgramRequestDetail(info)"
                                >
                              </p-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    <div  *ngIf="info.expanded" class="col-12 md:col-6">
                      <div>
                        <div class="grid">
                          <div class="col-12">
                            <span class="section-title">{{'UserPages.UserClosedSale.ProductDetailText' | translate}}</span>
                          </div>
                          <div class="col-6 flex flex-row align-items-center">
                            <span class="material-symbols-outlined info-icon">
                              pallet
                              </span>
                             <span class="info-text">
                              {{'UserPages.UserClosedSale.ProductDetail.CasePerPalletsText' | translate}}: 
                               {{info.programRequest.caseQuantityPerPallet}}
                             </span>
                          </div>
                          <div class="col-6 flex flex-row align-items-center">
                            <span class="material-symbols-outlined info-icon" >
                              weight
                              </span>
                              <span class="info-text">
                                {{'UserPages.UserClosedSale.ProductDetail.NetKilosText' | translate}}:
                                {{info.programRequest.netKilos ? info.programRequest.netKilos + ' kg' : '-' }}
                              </span>
                          </div>
                          <div class="col-6 flex flex-row align-items-center">
                            <img class="info-icon" style="width: 100%; max-width: 25px; height: 20px;" alt="" src="../../../../assets/img/container_icon_2.png" >
                              <span class="info-text">
                                {{'UserPages.UserClosedSale.ProductDetail.ProgramContainersText' | translate}}:
                                {{info.programRequest.palletQuantity ?  info.programRequest.palletQuantity / 20 : '-'}}
                              </span> 
                          </div>
                          <div class="col-6 flex flex-row align-items-center">
                            <span class="material-symbols-outlined info-icon">
                              inventory_2
                              </span>
                              <span class="info-text">
                                {{'UserPages.UserClosedSale.ProductDetail.BoxTypeText' | translate}}:
                                {{info.programRequest.boxType ? info.programRequest.boxType  : '-'}}
                              </span>
                          </div>
                          <div class="col-6 flex flex-row align-items-center">
                            <span class="material-symbols-outlined info-icon" >
                              paid
                              </span>
                            <span class="info-text">
                              {{'UserPages.UserClosedSale.ProductDetail.PaymentMethodText' | translate}}: 
                               {{info.programRequest.paymentAgainstBL + '%' + '/' + info.programRequest.typeSale }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="info.expanded" class="col-12 md:col-6">
                      <div class="grid">
                        <div class="col-5">
                          <span class="section-title">{{'UserPages.UserClosedSale.AgreementText' | translate}}</span>
                          <div class="col-12 flex flex-row align-items-center">
                            <span class="material-symbols-outlined info-icon" style="font-size: 17px;">
                              person
                              </span>
                            <span class="info-text-program">
                              Importer:
                            </span>
                            <span class="program-info">
                              {{info.programRequest.importer.companyName}}
                            </span>
                          </div>
                          <div class="col-12 flex flex-row align-items-center">
                            <span class="material-symbols-outlined info-icon" style="font-size: 17px;">
                              person
                              </span>
                            <span class="info-text-program">
                              Consignee:
                            </span>
                            <span class="program-info">
                              {{info.programRequest.consignee.companyName}}
                            </span>
                          </div>
                          <div class="col-12 flex flex-row align-items-center">
                            <span class="material-symbols-outlined info-icon">
                              person
                            </span>
                            <span class="info-text-program">
                              Notify:
                            </span>
                            <span class="program-info">
                              {{info.programRequest.notify.companyName}}
                            </span>
                          </div>
                        </div>
                        <div class="col-5">
                          <span class="section-title">{{'UserPages.UserClosedSale.week/yearText' | translate}}</span>
                          <div class="col-12">
                            <div class="grid">
                              <div class="col-6 flex flex-column col-12 ">
                                <div class="flex flex-row justify-content-start gap-1">
                                  <div class="flex align-items-center" style="width: 67px;">
                                    <span class="material-symbols-outlined info-icon">
                                      calendar_today
                                    </span>
                                    <span class="info-text">
                                      {{'UserPages.UserClosedSale.FromProgramText' | translate}}
                                    </span>
                                  </div>
                                  <div class="flex align-items-center">
                                    <span class="info-text">
                                      {{info.programRequest.startWeek}} / {{info.programRequest.startWeekYear}}
                                  </span>
                                  </div>
                                </div>
                                <div class="flex flex-row justify-content-start gap-1">
                                  <div class="flex align-items-center" style="width: 67px;">
                                    <span class="material-symbols-outlined info-icon">
                                      calendar_today
                                    </span>
                                    <span class="info-text">
                                      {{'UserPages.UserClosedSale.ToProgramText' | translate}}
                                    </span>
                                  </div>
                                  <div class="flex align-items-center">
                                    <span class="info-text">
                                      {{info.programRequest.endWeek}} / {{info.programRequest.endWeekYear}}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-2 flex flex-column">
                          <div class="flex justify-content-end mb-1" pTooltip="{{'PurchaseOrderExporterActions.ViewActionText' | translate}}" >
                            <button (click)="selectProgramRequestDetail(info)" pButton label="{{'PurchaseOrderExporterActions.ViewActionText' | translate}}" class="p-button-outlined p-button-sm" icon="pi pi-eye" size="small"  [style]="{ 'width': '100%', 'height': '25px'}">
                              
                            </button>
                          </div>
                          <div *ngIf="info.programRequest.programRequestStatus == 0" class="flex justify-content-end  mb-1" pTooltip="{{'PurchaseOrderExporterActions.ApproveText' | translate}}"  tooltipPosition="bottom">
                              <button (click)="goToDirectSaleCreateFrom(info.programRequest.guid)" pButton label="{{'PurchaseOrderExporterActions.ApproveText' | translate}}" class="p-button-sm" icon="pi pi-check" size="small"  [style]="{ 'width': '100%', 'height': '25px'}">
                                
                              </button>
                          </div>
                          <div  *ngIf="info.programRequest.programRequestStatus == 0" class="flex justify-content-end  mb-1" pTooltip="{{'PurchaseOrderExporterActions.RejectText' | translate}}"  tooltipPosition="bottom" >
                            <button pButton (click)="updateProgramRequest(info.programRequest.guid, 2)" label="{{'PurchaseOrderExporterActions.RejectText' | translate}}"  class="p-button-sm p-button-danger"  severity="danger" icon="pi pi-times" size="small"  [style]="{ 'width': '100%', 'height': '25px'}" >
                              
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </p-card>
              </div>
            </ng-template>
          </p-dataView>
        </div>
      </p-card>
    </p-tabPanel>

    <!-- tab para importador  -->
    <p-tabPanel *ngIf="profile?.type == 2" header="{{'ProgramTab.ProgramRequestOrder' | translate}}">
      <app-import-program-request-list 
      [programRequestInfo]="programRequestInfo"
      (visibleInviteEvent)="showInviteImporter()"
      >

      </app-import-program-request-list>
    </p-tabPanel>
  </p-tabView>
</app-user-dashboard>

<p-dialog header="{{'UserPages.UserClosedSale.DialogHeader' | translate}}" [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleOfferDetailDialog" [style]="{width: '80%'}">
  <app-export-offer-detail [offer]="selectedExportOffer" [productCertificateTypes]="selectedProductCertificateTypes" [packagingTypes]="selectedPackagingTypes"></app-export-offer-detail>
  <ng-template pTemplate="footer">
    <p-button label="{{'UserPages.UserClosedSale.ModalCloseButton' | translate}}" (onClick)="closeOfferDetailDialog()"></p-button>
  </ng-template>
</p-dialog>

<p-toast></p-toast>

<p-confirmDialog acceptLabel="{{'UserPages.UserClosedSale.YesLabel' | translate}}" rejectLabel="{{'UserPages.UserClosedSale.NotLabel' | translate}}" rejectButtonStyleClass="p-button-outlined" [style]="{width: '50vw'}"></p-confirmDialog>

<p-dialog header="{{'UserPages.UserClosedSale.ModalTitle' | translate}}" [(visible)]="visibleInvite" [style]="{width: '50vw'}">
  <p>{{'UserPages.UserClosedSale.ModalDescriptionText' | translate}}</p>
  <form [formGroup]="userJoinRequestFormGroup">
    <div class="p-fluid formgrid grid">
      <div class="field col-12" style="margin-bottom: 40px;">
        <label for="guestEmail">{{'UserPages.UserClosedSale.EmailModalFieldLabel' | translate}} *</label>
        <input pInputText inputId="guestEmail" formControlName="guestEmail" placeholder="{{'UserPages.UserClosedSale.EmailModalFieldPlaceholder' | translate}}"/>
        <small *ngIf="submit && userJoinRequestFormGroup.controls['guestEmail'].invalid" class="p-error">{{getErrorMessage('guestEmail')}}</small>
      </div>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <p-button label="{{'UserPages.UserClosedSale.CancelModalButton' | translate}}" (onClick)="visibleInvite = false" [loading]="loading" styleClass="p-button-outlined"></p-button>
    <p-button label="{{'UserPages.UserClosedSale.SendModalButton' | translate}}" (onClick)="saveUserJoinRequest()" [loading]="loading"></p-button>
  </ng-template>
</p-dialog>

<p-dialog header="{{'DirectSaleProgramModal.HeaderCreateTitle' | translate}}" [(visible)]="directSaleProgramModalVisible" [style]="{width: '70vw'}" [modal]="false">
  <div *ngIf="selectedExportOfferInformation">
  <form [formGroup]="directSaleProgramFormGroup" #directSaleProgramFormDirective="ngForm">
    <div class="p-fluid formgrid mr-2 ml-2 grid">
      <div class="field col-12 md:col-6 lg:col-4">
        <label for="importerId" style="font-weight: bolder;">{{'DirectSaleFormPage.ImporterFieldLabel' | translate}} *</label>
          <p-dropdown id="importerId"
          formControlName="importerId"
          [options]="importers"
          optionValue="id"
          [(ngModel)]="selectedExportOfferInformation.directSalePrograms[0].importerId"
          optionLabel="companyName"
          [filter]="true"
          filterBy="companyName"
          [showClear]="false"
          placeholder="{{'DirectSaleFormPage.ImporterFieldLabel' | translate}}"
          (onChange)="onChangeImporter()">
        </p-dropdown>
        <small *ngIf="submit && directSaleProgramFormGroup.controls['importerId'].invalid" class="p-error">{{'importerId' | formErrorMessage: directSaleProgramFormGroup}}</small>
      </div>
      <div class="field col-12 md:col-6 lg:col-4">
        <label for="consigneeId" style="font-weight: bolder;">{{'DirectSaleFormPage.ConsigneeFieldLabel' | translate}} *</label>
        <p-dropdown id="consigneeId"
          formControlName="consigneeId"
          [options]="consignees"
          optionValue="id"
          [(ngModel)]="selectedExportOfferInformation.directSalePrograms[0].consigneeId"
          optionLabel="companyName"
          [filter]="true"
          filterBy="companyName"
          [showClear]="false"
          placeholder="{{'DirectSaleFormPage.ConsigneeFieldLabel' | translate}}">
        </p-dropdown>
        <small *ngIf="submit && directSaleProgramFormGroup.controls['consigneeId'].invalid" class="p-error">{{'consigneeId' | formErrorMessage: directSaleProgramFormGroup}}</small>
      </div>
      <div class="field col-12 md:col-6 lg:col-4">
        <label for="notifyId" style="font-weight: bolder;">{{'DirectSaleFormPage.NotifyFieldLabel' | translate}} *</label>
        <p-dropdown id="notifyId"
          formControlName="notifyId"
          [options]="notifies"
          optionValue="id"
          optionLabel="companyName"
          [(ngModel)]="selectedExportOfferInformation.directSalePrograms[0].notifyId"
          [filter]="true"
          filterBy="companyName"
          [showClear]="false"
          placeholder="{{'DirectSaleFormPage.NotifyFieldLabel' | translate}}">
        </p-dropdown>
        <small *ngIf="submit && directSaleProgramFormGroup.controls['notifyId'].invalid" class="p-error">{{'notifyId' | formErrorMessage: directSaleProgramFormGroup}}</small>
      </div>
      <div class="field col-12 md:col-6 lg:col-4">
        <label style="font-weight: bolder;" for="importToCountryId">{{'DirectSaleFormPage.DestinationCountryFieldLabel' | translate}} *</label>
        <p-dropdown id="importToCountryId"
          [options]="countries"
          formControlName="importToCountryId"
          optionLabel="name"
          optionValue="id"
          [(ngModel)]="selectedExportOfferInformation.directSalePrograms[0].destinationCountryId"
          (onChange)="onChangeImportToCountry()"
          [filter]="true"
          filterBy="name"
          [showClear]="true"
          placeholder="{{'DirectSaleFormPage.DestinationCountryFieldLabel' | translate}}">
          <ng-template pTemplate="selectedItem">
            <div class="flex align-items-center gap-2" *ngIf="selectedCountryTo">
              <img [src]="'../../../../assets/img/flags/' + selectedCountryTo.code + '.png'" style="width: 18px" alt=""/>
              <div>{{ selectedCountryTo | countryDescription }}</div>
            </div>
          </ng-template>
          <ng-template let-country pTemplate="item">
            <div class="flex align-items-center gap-2">
              <img [src]="'../../../../assets/img/flags/' + country.code + '.png'" style="width: 18px" alt=""/>
              <div>{{ country | countryDescription }}</div>
            </div>
          </ng-template>
        </p-dropdown>
        <small *ngIf="submit && directSaleProgramFormGroup.controls['importToCountryId'].invalid" class="p-error">{{'importToCountryId' | formErrorMessage: directSaleProgramFormGroup}}</small>
      </div>
      <div class="field col-12 md:col-6 lg:col-4">
        <label style="font-weight: bolder;" for="portToId">{{'DirectSaleFormPage.DestinationPortFieldLabel' | translate}} *</label>
        <p-dropdown id="portToId"
          [options]="ports"
          formControlName="portToId"
          optionLabel="name"
          optionValue="id"
          [filter]="true"
          filterBy="name"
          [showClear]="true"
          placeholder="{{'DirectSaleFormPage.DestinationPortFieldLabel' | translate}}"></p-dropdown>
        <small *ngIf="submit && directSaleProgramFormGroup.controls['portToId'].invalid" class="p-error">{{'portToId' | formErrorMessage: directSaleProgramFormGroup}}</small>
      </div>
      <div class="field col-12 md:col-6 lg:col-4">
        <label style="font-weight: bolder;" for="containersPerWeek">{{'DirectSaleFormPage.ContainersPerWeekFieldLabel' | translate}} *</label>
        <p-inputNumber id="containersPerWeek" formControlName="containersPerWeek" [showButtons]="false" [useGrouping]="false" [min]="1" [max]="100000" placeholder="{{'DirectSaleFormPage.ContainersPerWeekFieldLabel' | translate}}"/>
        <small *ngIf="submit && directSaleProgramFormGroup.controls['containersPerWeek'].invalid" class="p-error">{{'containersPerWeek' | formErrorMessage: directSaleProgramFormGroup}}</small>
      </div>
      <div class="field col-12 md:col-6 lg:col-4">
        <label style="font-weight: bolder;" for="startWeek">{{'DirectSaleFormPage.StartWeekFieldLabel' | translate}} *</label>
        <p-inputNumber id="startWeek" formControlName="startWeek" [showButtons]="true" [useGrouping]="false" [min]="1" [max]="52" placeholder="{{'DirectSaleFormPage.StartWeekFieldLabel' | translate}}"/>
        <small *ngIf="submit && directSaleProgramFormGroup.controls['startWeek'].invalid" class="p-error">{{'startWeek' | formErrorMessage: directSaleProgramFormGroup}}</small>
        <small *ngIf="submit && directSaleProgramFormGroup.getError('endWeekRangeError')" class="p-error">{{'MessageService.ExportOffer.ClosedSaleWeekRangeError' | translate}}</small>
      </div>
      <div class="field col-12 md:col-6 lg:col-4">
        <label style="font-weight: bolder;" for="startWeekYear">{{'DirectSaleFormPage.StartYearFieldLabel' | translate}} *</label>
        <p-dropdown 
        id="startWeekYear"  
        formControlName="startWeekYear" 
        [options]="years" 
        optionLabel="label" 
        optionValue="value" 
        placeholder="{{'DirectSaleFormPage.StartYearFieldLabel' | translate}}">
      </p-dropdown>
        <small *ngIf="submit && directSaleProgramFormGroup.controls['startWeekYear'].invalid" class="p-error">{{'startWeekYear' | formErrorMessage: directSaleProgramFormGroup}}</small>
        <small *ngIf="submit && directSaleProgramFormGroup.getError('endWeekYearRangeError')" class="p-error">{{'MessageService.ExportOffer.ClosedSaleYearRangeError' | translate}}</small>
      </div>
      <div class="field col-12 md:col-6 lg:col-4">
        <label style="font-weight: bolder;" for="endWeek">{{'DirectSaleFormPage.EndWeekFieldLabel' | translate}}</label>
        <p-inputNumber id="endWeek" formControlName="endWeek" [showButtons]="true" [useGrouping]="false" [min]="1" [max]="52" placeholder="{{'DirectSaleFormPage.EndWeekFieldLabel' | translate}}"/>
        <small *ngIf="submit && directSaleProgramFormGroup.getError('endWeekRangeError')" class="p-error">{{'MessageService.ExportOffer.ClosedSaleWeekRangeError' | translate}}</small>
      </div>
      <div class="field col-12 md:col-6 lg:col-4">
        <label style="font-weight: bolder;" for="endWeekYear">{{'DirectSaleFormPage.EndYearFieldLabel' | translate}}</label>
        <p-dropdown 
        id="endWeekYear" 
        formControlName="endWeekYear" 
        [options]="years" 
        optionLabel="label" 
        optionValue="value" 
        placeholder="{{'DirectSaleFormPage.EndYearFieldLabel' | translate}}"></p-dropdown>
        <small *ngIf="submit && directSaleProgramFormGroup.getError('endWeekYearRangeError')" class="p-error">{{'MessageService.ExportOffer.ClosedSaleYearRangeError' | translate}}</small>
      </div>
    </div>
    <div class="grid mr-2 ml-2" style="margin-top: 30px;">
      <div class="col-12 md:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'DirectSaleFormPage.TotalWeeksLabel' | translate}}: </label>
        <span style="font-weight: bolder; font-size: 1.5em;"> {{ totalWeeks }}</span>
      </div>
      <div class="col-12 md:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'DirectSaleFormPage.TotalPalletsScheduledLabel' | translate}}: </label>
        <span style="font-weight: bolder; font-size: 1.5em;"> {{totalPallets}}</span>
      </div>
      <div class="col-12 md:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'DirectSaleFormPage.TotalContainersLabel' | translate}}: </label>
        <span style="font-weight: bolder; font-size: 1.5em;"> {{totalContainers}}</span>
      </div>
    </div>
  </form>
  </div>
  <ng-template pTemplate="footer">
    <p-button label="{{'DirectSaleProgramModal.CancelButtonLabel' | translate}}" (onClick)="directSaleProgramModalVisible = false" [loading]="loading" styleClass="p-button-outlined"></p-button>
    <p-button label="{{'DirectSaleProgramModal.SaveButtonLabel' | translate}}" (onClick)="saveDirectSaleProgram()" [loading]="loading"></p-button>
  </ng-template>
</p-dialog>

<p-dialog header="{{'UserPages.UserImportRequest.ImportRequestTable.ImportRequestDialog.Header' | translate}}" [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleProgramRequestDetailDialog" [style]="{width: '80%'}">
  <!-- <div class="grid"> -->
    <app-program-request-detail [programRequestInformation]="selectedProgramRequest"></app-program-request-detail>
  <!-- </div> -->
  <ng-template pTemplate="footer">
    <p-button label="{{'UserPages.UserImportRequest.ImportRequestTable.ImportRequestDialog.CloseButton' | translate}}" (onClick)="visibleProgramRequestDetailDialog = false"></p-button>
  </ng-template>
</p-dialog>

<p-dialog [resizable]="false" [modal]="false" [draggable]="false" [(visible)]="visibleDeleteConfirmOne" [style]="{width: '800px'}" [dismissableMask]="true">
  <div class="w-full">
      <div style="margin-top: 20px;" class="flex justify-content-center">
          <span class="material-symbols-outlined" style="font-size: 100px; color: #8B2222;">
            delete
            </span>
      </div>
      <h2 style="text-align: center;">{{'ConfirmDeleteDirectSaleOne.ConfirmTextOne'| translate}}</h2>
    <div style="margin-top: 40px" class="w-full">
      <div class="flex align-items-center justify-content-center gap-4">
        <p-button label="{{'ConfirmDeleteDirectSaleOne.CancelDeleteButtonOne'| translate}}" styleClass="p-button-outlined" (onClick)="visibleDeleteConfirmOne = false"></p-button>
        <p-button label="{{'ConfirmDeleteDirectSaleOne.ConfirmButtonOne'| translate}}"(onClick)="showSecondConfirm()"></p-button>
      </div>
    </div>
  </div>
</p-dialog>

<p-dialog [resizable]="false" [modal]="false" [draggable]="false" [(visible)]="visibleDeleteConfirmTwo" [style]="{width: '800px'}" [dismissableMask]="true" >

  <div class="w-full">
    <div style="margin-top: 20px;" class="flex justify-content-center">
      <span class="material-symbols-outlined" style="font-size: 100px; color: #8B2222;">
        delete
        </span>
    </div>
    <h2 style="text-align: center;">{{'ConfirmDeleteDirectSaleTwo.ConfirmTitleTwo'| translate}}</h2>
    <div class="flex justify-content-center">
      <span style="text-align: center;">{{'ConfirmDeleteDirectSaleTwo.ConfirmTextTwo'| translate}}</span>
    </div>
    <div style="margin-top: 40px" class="w-full">
      <div class="flex align-items-center justify-content-center gap-4">
        <p-button label="{{'ConfirmDeleteDirectSaleTwo.CancelDeleteButtonTwo'| translate}}" styleClass="p-button-outlined" (onClick)="visibleDeleteConfirmTwo = false"></p-button>
        <p-button label="{{'ConfirmDeleteDirectSaleTwo.ConfirmButtonTwo'| translate}}"(onClick)="deactivateProgram()"></p-button>
      </div>
    </div>
  </div>
</p-dialog>

<p-dialog [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleDeleteConfirmTwo" [style]="{width: '800px'}">

  <div class="w-full">
    <div style="margin-top: 20px;" class="flex justify-content-center">
      <span class="material-symbols-outlined" style="font-size: 100px; color: #8B2222;">
        delete
        </span>
    </div>
    <h2 style="text-align: center;">{{'ConfirmDeleteDirectSaleTwo.ConfirmTitleTwo'| translate}}</h2>
    <div class="flex justify-content-center">
      <span style="text-align: center;">{{'ConfirmDeleteDirectSaleTwo.ConfirmTextTwo'| translate}}</span>
    </div>
    <div style="margin-top: 40px" class="w-full">
      <div class="flex align-items-center justify-content-center gap-4">
        <p-button label="{{'ConfirmDeleteDirectSaleTwo.CancelDeleteButtonTwo'| translate}}" styleClass="p-button-outlined" (onClick)="visibleDeleteConfirmTwo = false"></p-button>
        <p-button label="{{'ConfirmDeleteDirectSaleTwo.ConfirmButtonTwo'| translate}}"(onClick)="deactivateProgram()"></p-button>
      </div>
    </div>
  </div>
</p-dialog>

<p-dialog [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleCreateConfirm" [style]="{width: '800px'}">

  <div class="w-full">
    <div>
      <h2 style="text-align: center;">{{'ConfirmCreateSale.Title'| translate}}</h2>
    </div>
    <div style="margin-top: 20px;" class="flex justify-content-center">
      <img [src]="'../../../../assets/img/create-direct-sale-icon.png'" style="width: 200px" alt=""/>
    </div>
    <div style="margin-top: 40px" class="w-full">
      <div class="flex align-items-center justify-content-center gap-4">
        <p-button label="{{'ConfirmCreateSale.EmptyButtonLabel'| translate}}" styleClass="p-button-outlined" (onClick)="goToDirectSaleCreatePage()"></p-button>
        <p-button label="{{'ConfirmCreateSale.FromTemplateLabel'| translate}}"(onClick)="deactivateProgram()"></p-button>
      </div>
    </div>
  </div>
</p-dialog>

<p-sidebar [(visible)]="sidebarVisible" position="right">
  <h3 style="text-align: center;">{{'AdvancedFilters.SideBarTitle' | translate}}</h3>

  <form [formGroup]="othersFiltersForm">
    <div class="col-12 flex justify-content-center flex-column">
      <div class="col-12 flex justify-content-center flex-column">
        <input pInputText inputId="netKilos" formControlName="kilosPerBox" 
        placeholder="{{'AdvancedFilters.KilosPerBoxFieldPlaceholder' | translate}}"/>
      </div>
      <div class="col-12 flex justify-content-center flex-column">
        <p-dropdown
        [options]="countries"
        formControlName="marketId"
        optionLabel="name"
        optionValue="id"
        (onChange)="executeFilter()"
        [filter]="true"
        filterBy="name"
        autoWidth="false" [style]="{'width':'100%'}"
        [showClear]="true"
        placeholder="{{'AdvancedFilters.MarketPlaceholder' | translate}}"
        class="p-inputtext-sm">
        <ng-template pTemplate="selectedItem">
          <div class="flex align-items-center gap-2" *ngIf="selectedCountryFrom">
            <img [src]="'../../../../assets/img/flags/' + selectedCountryFrom.code + '.png'" style="width: 18px" alt="flag"/>
            <div>{{ selectedCountryFrom | countryDescription }}</div>
          </div>
        </ng-template>
        <ng-template let-country pTemplate="item">
          <div class="flex align-items-center gap-2">
            <img [src]="'../../../../assets/img/flags/' + country.code + '.png'" style="width: 18px" alt="flag"/>
            <div>{{ country | countryDescription }}</div>
          </div>
        </ng-template>
      </p-dropdown>
      </div>
      <div class="col-12 flex justify-content-center flex-column">
        <input pInputText formControlName="speciesSize" placeholder="{{'AdvancedFilters.SpeciesSizeFieldPlaceholder' | translate}}"/>
      </div>
      <div class="col-12 flex justify-content-center flex-column">
        <input pInputText formControlName="shelfLife" placeholder="{{'AdvancedFilters.ShelfLife' | translate}}"/>
      </div>
      <div class="col-12 flex justify-content-center flex-column">
        <input pInputText formControlName="price" placeholder="{{'AdvancedFilters.PricePlaceholder' | translate}}"/>
      </div>
        <div class="col-12 flex justify-content-center">
          <p-button label="{{'AdvancedFilters.ApplyFiltersText' | translate}}" styleClass="p-button" (onClick)="executeFilter()">
            <span class="material-symbols-outlined">
              tune
              </span>
          </p-button>
        </div>
    </div>
  </form>
</p-sidebar>

<p-sidebar [(visible)]="sidebarPurchaseOrderVisible" position="right">
  <h3 style="text-align: center;">{{'AdvancedFilters.SideBarTitle' | translate}}</h3>

  <form [formGroup]="othersFiltersPurchaseOrderForm">
    <div class="col-12 flex justify-content-center flex-column">
      <div class="col-12 flex justify-content-center flex-column">
        <input pInputText inputId="netKilos" formControlName="kilosPerBox" 
        placeholder="{{'AdvancedFilters.KilosPerBoxFieldPlaceholder' | translate}}"/>
      </div>
      <div class="col-12 flex justify-content-center flex-column">
        <p-dropdown
        [options]="countries"
        formControlName="marketId"
        optionLabel="name"
        optionValue="id"
        (onChange)="executeFilter()"
        [filter]="true"
        filterBy="name"
        autoWidth="false" [style]="{'width':'100%'}"
        [showClear]="true"
        placeholder="{{'AdvancedFilters.MarketPlaceholder' | translate}}"
        class="p-inputtext-sm">
        <ng-template pTemplate="selectedItem">
          <div class="flex align-items-center gap-2" *ngIf="selectedCountryFrom">
            <img [src]="'../../../../assets/img/flags/' + selectedCountryFrom.code + '.png'" style="width: 18px" alt="flag"/>
            <div>{{ selectedCountryFrom | countryDescription }}</div>
          </div>
        </ng-template>
        <ng-template let-country pTemplate="item">
          <div class="flex align-items-center gap-2">
            <img [src]="'../../../../assets/img/flags/' + country.code + '.png'" style="width: 18px" alt="flag"/>
            <div>{{ country | countryDescription }}</div>
          </div>
        </ng-template>
      </p-dropdown>
      </div>
      <div class="col-12 flex justify-content-center flex-column">
        <input pInputText formControlName="speciesSize" placeholder="{{'AdvancedFilters.SpeciesSizeFieldPlaceholder' | translate}}"/>
      </div>
      <div class="col-12 flex justify-content-center flex-column">
        <input pInputText formControlName="price" placeholder="{{'AdvancedFilters.PricePlaceholder' | translate}}"/>
      </div>
        <div class="col-12 flex justify-content-center">
          <p-button label="{{'AdvancedFilters.ApplyFiltersText' | translate}}" styleClass="p-button" (onClick)="executeFilterProgramRequest()">
            <span class="material-symbols-outlined">
              tune
              </span>
          </p-button>
        </div>
    </div>
  </form>
</p-sidebar>