<div class="bg-container" >
  <div class="container" *ngIf="isVisible">
    <div class="item">
      <a [routerLink]="'/'"><img src="../../../../assets/img/beta_logo_disclaimer.png" class="logo-icon" alt="fruzty-logo"/></a>
    </div>
    <div class="item padding-lat">
      <p>{{ 'HomeHeader.Disclaimer.Message' | translate }}</p>
    </div>
    <div class="item">
      <div class="section-btn">
        <button pButton (click)="openDisclaimerDialog()" class="ghost-btn">{{ 'HomeHeader.Disclaimer.MoreInfo' | translate }}</button>
        <button (click)="closeDisclaimer()" class="circle-figure">
          <i class="pi pi-times" style="font-size: 1rem"></i>
        </button>
      </div>
    </div>
  <!--
  <div class="grid flex align-items-center">
    <div class="col-4 md:col-1 flex justify-content-center align-items-center">
      <a [routerLink]="'/'"><img src="../../../../assets/img/beta_logo_disclaimer.png" style="width: 100px;" alt="fruzty-logo"/></a>
    </div>
    <div class="col-8 md:col-9 flex justify-content-center align-items-center">
      <p>{{ 'HomeHeader.Disclaimer.Message' | translate }}</p>
    </div>
    <div class="col-12 md:col-2 flex justify-content-center align-items-center">
      <button pButton (click)="openDisclaimerDialog()" style="background-color: #3C1A43; border: 1px solid #FFFFFF; border-radius: 5px; color: #FFFFFF; width: auto; max-width: 180px; height: 40px;">{{ 'HomeHeader.Disclaimer.MoreInfo' | translate }}</button>
    </div>

  </div>
-->
</div>


<p-dialog [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleDisclaimerESPDialog" [style]="{width: '80%'}">
  <div class="grid flex align-items-center" style="padding: 0 20px;">
    <div class="col-12 md:col-4 flex justify-content-center align-items-center">
      <img src="../../../../assets/img/beta_image_modal.png" style="width: 100%; max-width: 450px;" alt="fruzty-logo"/>
    </div>
    <div class="col-12 md:col-8 flex justify-content-center align-items-start flex-column" style="font-size: 1.2rem">
      <p style="color: #6EC116; margin-bottom: 40px; font-weight: bolder;">{{ 'HomeHeader.Disclaimer.Greeting' | translate }},</p>

      <p style="text-align: justify; margin: 7px 0">
        Le damos la bienvenida a la <span style="background-color: #6EC116; color: #FFF">versión beta de Fruzty.com.</span> Tenga en cuenta que esta versión preliminar podría no incluir todas las funcionalidades y características finales que tendrá la plataforma completa. Estamos trabajando arduamente para mejorar y completar nuestra oferta de servicios.
      </p>
      <p style="text-align: justify; margin: 7px 0">
        Durante este período de pruebas, apreciamos su paciencia y comprensión ante cualquier inconveniente que pueda experimentar. Sus comentarios y sugerencias son de gran valor para nopsotros y serán fundamentales para perfeccionar nuestra plataforma. <span style="color: #672572; font-weight: bolder;">Por favor, comparta sus impresiones y reporte cualquier problema a info@fruzty.com</span>
      </p>
      <p style="text-align: justify; margin: 7px 0">
        Agradecemos su participación activa en esta fase crucial de desarrollo y le agradecemos por contribuir al futuro de Fruzty.com
      </p>
      <div style="margin: 20px auto">
        <button pButton (click)="closeDisclaimerDialog()" style="background-color: #6EC116; border-radius: 5px; color: #FFFFFF; width: auto; max-width: 180px; height: 40px;">{{ 'HomeHeader.Disclaimer.Aceptation' | translate }}</button>
      </div>
    </div>
  </div>
</p-dialog>


<p-dialog [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleDisclaimerENGDialog" [style]="{width: '80%'}">
  <div class="grid flex align-items-center" style="padding: 0 20px;">
    <div class="col-12 md:col-4 flex justify-content-center align-items-center">
      <img src="../../../../assets/img/beta_image_modal.png" style="width: 100%; max-width: 450px;" alt="fruzty-logo"/>
    </div>
    <div class="col-12 md:col-8 flex justify-content-center align-items-start flex-column" style="font-size: 1.2rem">
      <p style="color: #6EC116; margin-bottom: 40px; font-weight: bolder;">{{ 'HomeHeader.Disclaimer.Greeting' | translate }},</p>

      <p style="text-align: justify; margin: 7px 0">
        Welcome to the  <span style="background-color: #6EC116; color: #FFF">beta version of Fruzty.com.</span> Please be aware that this preliminary version may not include all the functionalities and features that the full version will offer. We are working hard to improve and complete our service.
      </p>
      <p style="text-align: justify; margin: 7px 0">
        During this trial period, we appreciate your patience and understanding for any inconvenience you may encounter. Your feedback and suggestions are invaluable to us and will be essential in refining our platform. <span style="color: #672572; font-weight: bolder;">Please share your impressions and report any issues to info@fruzty.com</span>
      </p>
      <p style="text-align: justify; margin: 7px 0">
        We thank you for your active cooperation in this crucial phase of development and appreciate your contribution to the future of Fruzty.com.
      </p>
      <div style="margin: 20px auto">
        <button pButton (click)="closeDisclaimerDialog()" style="background-color: #6EC116; border-radius: 5px; color: #FFFFFF; width: auto; max-width: 180px; height: 40px;">{{ 'HomeHeader.Disclaimer.Aceptation' | translate }}</button>
      </div>
    </div>
  </div>
</p-dialog>
