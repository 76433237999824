export const formLimit = {
    nameLimit: 50,
    mailLimit: 50,
    phoneLimit: 50,
    addressLimit:50,
    cityLimit:50,
    vatLimit:50,
    companyName: 50,
    purchaseOrderLimit: 50,
    netKilosLimit: 50,
    boxType: 10,
    speciesSizeLimit: 50,
    categoryLimit: 50,
    basePallets: 50,
    otherCertificateLimit: 50,
    otherPackagingLimit: 50,
    grossKilosLimit:50,
    caseSizeLimit: 50
}