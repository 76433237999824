<p-table [value]="orders"
  [paginator]="true"
  [rows]="10"
  [showCurrentPageReport]="true"
  [rowsPerPageOptions]="[10, 25, 50]"
  [loading]="loading"
  responsiveLayout="stack"
  [breakpoint]="'600px'">
  <ng-template pTemplate="header">
    <tr>
      <th colspan="2">
        <div class="flex justify-content-center align-items-center">
          {{'UserPages.UserPurchaseOrders.PendingTable.Specie/VarietyHeader' | translate}}
        </div>
      </th>
      <th>
        <div class="flex justify-content-center align-items-center">
          {{'UserPages.UserPurchaseOrders.PendingTable.TotalHeader' | translate}}
        </div>
      </th>
      <th>
        <div class="flex justify-content-center align-items-center">
          {{'UserPages.UserPurchaseOrders.PendingTable.CodeHeader' | translate}}
        </div>
      </th>
      <th>
        <div class="flex justify-content-center align-items-center">
          {{'UserPages.UserPurchaseOrders.PendingTable.DateOrder' | translate}}
        </div>
      </th>
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-order>
    <tr>
      <td>
        <div style="margin-top: 2px;">
          <img [src]="getImageUrl(order)" style="border-radius: 7px; width: 100%; max-width: 100px; height: auto;"/>
        </div>
      </td>
      <td>
        <div class="flex justify-content-center align-items-center">
          <div>
            <ng-container *ngFor="let d of order.details">
              <p>{{d.species?.name}} / {{d.variety?.name}}</p>
            </ng-container>
          </div>
        </div>
      </td>
      <td>
        <div class="flex justify-content-center align-items-center">
          <span style="font-weight: bold;">{{getOrderTotalPrice(order.details) | currency: 'USD':'US$'}}</span>
        </div>
      </td>
      <td>
        <div class="flex justify-content-center align-items-center">
          {{getOrderTitle(order)}}
        </div>
      </td>
      <td>
        <div class="flex justify-content-center align-items-center">
          {{order.importOrder.created | date: 'dd-MM-yyyy'}}
        </div>
      </td>
      <td>
        <div class="flex justify-content-end align-items-center gap-4">
          <p-button label="{{'UserPages.UserPurchaseOrders.PendingTable.SeeMoreButton' | translate}}" (onClick)="goToOrderDetailPage(order.importOrder.id)"/>
          <p-button *ngIf="order.importOrder.status == 2 && !order.importOrder.isClosedSale && profileType == 'Importer'" label="{{'UserPages.UserPurchaseOrders.PendingTable.ConfirmPurchase' | translate}}" (onClick)="goToConfirmPurchase(order.importOrder)"/>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
