<p-dropdown
  [options]="langs"
  optionLabel="label"
  [(ngModel)]="lang"
  (onChange)="switchLang()"
  [showClear]="showClear"
  [placeholder]="placeholder"
  appendTo="body"
  styleClass="p-button-sm"
  class="p-button-sm">
  <ng-template pTemplate="selectedItem">
    <div class="flex align-items-center">
      <img [src]="'../../../../assets/img/flags/' + lang.icon" style="width: 18px" alt="" *ngIf="lang"/>
      <span *ngIf="!showOnlyIcon">&nbsp;{{lang?.label}}</span>
    </div>
  </ng-template>
  <ng-template let-lang pTemplate="item">
    <div class="flex align-items-center">
      <img [src]="'../../../../assets/img/flags/' + lang.icon" style="width: 18px" alt=""/>
      <span *ngIf="!showOnlyIcon">&nbsp;{{lang.label}}</span>
    </div>
  </ng-template>
</p-dropdown>

<p-toast></p-toast>
