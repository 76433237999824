<div *ngIf="programRequestInformation">
    <div class="grid">
        <div class="col-12 md:col-4 lg:col-4">
            <p-carousel [value]="programRequestInformation.pictures" [numVisible]="1" [numScroll]="1" [circular]="false"
              [responsiveOptions]="responsiveOptions">
              <ng-template let-picture pTemplate="item">
                <div class="mb-3" style="height: 50%;">
                  <img [src]="completeUrl(picture.url)" style="border-radius: 7px; width: 100%; height: auto;"
                    alt="Image" />
                </div>
              </ng-template>
            </p-carousel>
        </div>
        <div class="col-12 md:col-8 lg:col-8">
          <p-card header="{{programRequestInformation.programRequest.variety.species | speciesName}}" 
            subheader="{{programRequestInformation.programRequest.variety.name}}">
            
            <div class="grid">
                <div class="col-6">{{'ProgramRequestDetail.CasePerPalletsText' | translate }}</div>
                <div class="col-6">
                    {{programRequestInformation.programRequest.caseQuantityPerPallet}}
                </div>
                <div class="col-6">{{'ProgramRequestDetail.PaymentAgainstBLLabel' | translate }}</div>
                <div class="col-6">
                    {{programRequestInformation.programRequest.paymentAgainstBL}}
                </div>
                <div class="col-6">{{'ImportRequestDetailComponent.PricePerCase' | translate }}</div>
                <div class="col-6">
                  <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{
                    programRequestInformation.programRequest.pricePerCase | currency: 'USD':'US$' }}</span>
                </div>
                
                <div class="col-6">{{'ProgramRequestDetail.PalletsPerWeekFieldLabel' | translate }}</div>
                <div class="col-6">
                  <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{
                    programRequestInformation.programRequest.palletQuantity }}</span>
                </div>

                <div class="col-6">{{'ProgramRequestDetail.ProductCertificateLabel' | translate }}</div>
                <div class="col-6" >
                  <span style="font-weight: bold;"*ngIf="programRequestInformation.programRequest.productCertificateTypes.length > 0">
                    {{getCertificatesName(programRequestInformation.programRequest.productCertificateTypes)}}
                  </span>
                  <span  *ngIf="programRequestInformation.programRequest.productCertificateTypes.length == 0">
                    {{'ProgramRequestDetail.NotResultsMessages' | translate }}
                  </span>
                </div>
                <div class="col-6">
                  {{'ProgramRequestDetail.PackagingAvailable' | translate }}
                </div>
                <div class="col-6">
                  <span style="font-weight: bold;"*ngIf="programRequestInformation.programRequest.packagingTypes.length > 0">
                    {{getPackagingTypes(programRequestInformation.programRequest.packagingTypes)}}
                  </span>
                  <span *ngIf="programRequestInformation.programRequest.packagingTypes.length == 0">
                    {{'ProgramRequestDetail.NotResultsMessages' | translate }}
                  </span>
                </div>
              </div>
            </p-card>
        </div>
    </div>
</div>
