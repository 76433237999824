import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ImportOrder, ImportOrderDetail, ImportOrderInformation } from 'src/app/domain/models/ImportOrder';
import { Routes } from 'src/app/infrastructure/routes';
import { OrderUtils } from 'src/app/ui/util/order-utils';

@Component({
  selector: 'app-user-import-order-list',
  templateUrl: './user-import-order-list.component.html',
  styleUrls: ['./user-import-order-list.component.css']
})
export class UserImportOrderListComponent {
  @Input()
  loading: boolean = false

  @Input()
  orders: ImportOrderInformation[] = []

  @Input()
  profileType!: string

  constructor(private _router: Router) {}

  getImageUrl(order: ImportOrderInformation): string {
    return OrderUtils.getImageUrl(order)
  }

  getOrderTotalPrice(details: ImportOrderDetail[]): number {
    return OrderUtils.getOrderTotalPrice(details)
  }

  getOrderTitle(order: ImportOrderInformation): string {
    return OrderUtils.getOrderTitle(order.importOrder.id)
  }

  goToOrderDetailPage(orderId: number) {
    this._router.navigate(['/' + Routes.userOrderDetailPage, orderId])
  }

  goToConfirmPurchase(order: ImportOrder) {
    this._router.navigate(['/' + Routes.userConfirmPurchasePage, order.guid])
  }
}
