import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { CompanyGateway } from 'src/app/application/gateway/CompanyGateway';
import { Company } from 'src/app/domain/models/Company';
import { BasicUser } from 'src/app/domain/models/User';
import { Routes } from 'src/app/infrastructure/routes';

@Component({
  selector: 'app-admin-custom-agents',
  templateUrl: './admin-custom-agents.component.html',
  styleUrls: ['./admin-custom-agents.component.css'],
  providers: [ConfirmationService, MessageService]
})
export class AdminCustomAgentsComponent implements OnInit {
  companies: Company[] = []
  profile: BasicUser | undefined

  loading: boolean = false

  constructor(private _router: Router,
    private _messageService: MessageService,
    private _confirmationService: ConfirmationService,
    private _authService: AuthGateway,
    private _companyService: CompanyGateway,
    public translate: TranslateService){
  }

  ngOnInit(): void {
    this.profile = this._authService.getUser()
    this.loadCompanies()
  }

  loadCompanies() {
    this._companyService.getAllByCompanyType(1).subscribe({
      next: (v) => {
        this.companies = v
        console.log(this.companies)
      },
      error: (e) => {
        console.log(e)
        this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se posible recuperar los custom agents.' });
      },
      complete: () =>  {
        this.loading = false
        console.info('Company query successfully')
      }
    })
  }

  goToCreateCustomAgent() {
    this._router.navigate(['/' + Routes.adminCreateCustomAgentPage])
  }

  getAvailableLabel() {
    return this.translate.instant('ExportOffer.ExportOfferDetail.NoAvailable')
  }

  goToEditCustomAgent(id: number) {
    this._router.navigate(['/' + Routes.adminEditCustomAgentPage, id])
  }
}