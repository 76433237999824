import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { UserGateway } from 'src/app/application/gateway/UserGateway';
import { passwordMatchValidator } from '../../util/custom-validators';

@Component({
  selector: 'app-reset-password-page',
  templateUrl: './reset-password-page.component.html',
  styleUrls: ['./reset-password-page.component.css'],
  providers: [MessageService]
})
export class ResetPasswordPageComponent implements OnInit {

  submit: boolean = false
  loading: boolean = false
  success: boolean = false
  token: string = ''

  formGroup: FormGroup = this._formBuilder.group({
    newPassword: [null, Validators.required],
    confirmPassword: [null, Validators.required],
  },
  {
    validator: passwordMatchValidator('newPassword', 'confirmPassword'),
  })

  constructor(private _formBuilder: FormBuilder,
    private _activatedRoute: ActivatedRoute,
    private _messageService: MessageService,
    private _userService: UserGateway,
    public translate: TranslateService) {

  }

  ngOnInit(): void {
    this._activatedRoute.params.subscribe(params => {
      this.token = params['token']
    })
  }

  onSubmit() {
    this.loading = true
    this.submit = true

    if(this.formGroup.valid) {
      let request = {
        token: this.token,
        password: this.formGroup.controls['newPassword'].value
      }

      this._userService.resetPassword(request).subscribe({
        next: (v) => {
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: this.translate.instant('ResetPasswordPage.SuccessMessage') })
          this.success = true
          this.submit = false
          this.loading = false
        },
        error: (e) => {
          this.success = false
          this.loading = false
          console.log(e)
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('ResetPasswordPage.ErrorMessage') });
        },
        complete: () => {
          console.info('Reset password update successfully')
        }
      })
    }
    else {
      this.loading = false
    }
  }

  getErrorMessage(fieldName: string): string {
    if (this.formGroup.controls[fieldName].hasError('required')) {
      return this.translate.instant('FormErrorMessage.FieldValueRequired')
    }
    if (this.formGroup.controls[fieldName].hasError('email')) {
      return this.translate.instant('FormErrorMessage.EmailFieldEmailFormatError')
    }
    return ''
  }

  get passwordMatchError(): boolean {
    return (
      this.formGroup.getError('mismatch')
    );
  }
}
