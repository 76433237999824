import { Species } from "src/app/domain/models/Species";
import { environment } from "src/environments/environment";
import { Lang } from "./langs";

export class SpeciesUtils {

    static getImageIcon(url: string){
        return environment.apiUrl + url;
    }

    static getSpeciesName(lang: string, species?: Species): string {
      let name = ''
      if(species) {
        switch(lang) {
          case Lang.ENGLISH:
            name = species.name ? species.name : ''
            break

          case Lang.SPANISH:
            name = species.nameEs ? species.nameEs : ''
            break

          default:
            name = species.name ? species.name : ''
        }
      }
      return name
    }
}
