import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { ImportOrderGateway } from 'src/app/application/gateway/ImportOrderGateway';
import { GenericOrderDetail, ImportOrderDetail, ImportOrderInformation } from 'src/app/domain/models/ImportOrder';
import { Routes } from 'src/app/infrastructure/routes';
import { OrderUtils } from 'src/app/ui/util/order-utils';

@Component({
  selector: 'app-admin-manage-orders-page',
  templateUrl: './admin-manage-orders-page.component.html',
  styleUrls: ['./admin-manage-orders-page.component.css'],
  providers: [MessageService]
})
export class AdminManageOrdersPageComponent implements OnInit {
  tabMenuItems: MenuItem[] = [
    {id: "1", label: 'Pendientes confirmar pallets', command: () => this.getPendingVerifyPallets()},
    {id: "0", label: 'Pendientes por aprobar QC pago', command: () => this.getAllByPendingQcPaymentApproved()},
    {id: "2", label: 'Compra confirmada', command: () => this.getImporterOrderConfirmed()},
    {id: "3", label: 'Expiradas', command: () => this.getExpireds()},
    {id: "4", label: 'Canceladas', command: () => this.getCancelleds()},
    {id: "5",label: 'Creadas', command: () => this.getCreatedOrders()}
  ]
  activeItem = this.tabMenuItems[0];

  loading: boolean = false
  paidRegistered: boolean = true

  orders: ImportOrderInformation[] = []
  isCloseSale: boolean = false

  orderTypeOptions: any[] = [
    {
      label: 'Venta directa',
      value: true
    },
    {
      label: 'Marketplace',
      value: false
    }
  ]

  constructor(private _router: Router,
    private _authService: AuthGateway,
    private _messageService: MessageService,
    private _importOrderService: ImportOrderGateway,
    private route: ActivatedRoute) {

  }

  ngOnInit(): void {

    this.route.queryParams.subscribe((params) => {
      if(params['page']){
        let index = this.tabMenuItems.findIndex(x => x.id === params['page'].toString());
        index != -1 ? this.activeItem = this.tabMenuItems[index] : this.activeItem = this.tabMenuItems[0];
      }
    })
    this.getPendingVerifyPallets()
  }

  getAllByStatus(status: string) {
    this.orders = []
    this.loading = true
    this._importOrderService.getAllByStatus(status).subscribe({
      next: (v) => {
        this.orders = v
      },
      error: (e) => {
        console.log(e)
        this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudieron recuperar las órdenes.' });
      },
      complete: () =>  {
        this.loading = false
        console.info('Import Order query successfully')
      }
    })
  }

  getAllByPendingQcPaymentApproved() {
    this.orders = []
    this.loading = true
    this._importOrderService.getAllByPendingApprovedAdmin().subscribe({
      next: (v) => {
        console.log(v);
        this.orders = v
      },
      error: (e) => {
        console.log(e)
        this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudieron recuperar las órdenes.' });
      },
      complete: () =>  {
        this.loading = false
        console.info('Import Order query successfully')
      }
    })
  }



  getImageUrl(order: ImportOrderInformation): string {
    return OrderUtils.getImageUrl(order)
  }

  getOrderTotalPrice(details: GenericOrderDetail[]): number {
    return OrderUtils.getOrdetTotalPriceGeneric(details)
  }

  getOrderPalletPrice(details: GenericOrderDetail[]): number {
    let total = 0;
    details.forEach(element => {
      total += this.getDetailPalletPrice(element)
    });

    return total;
  }

  getDetailPalletPrice(detail: GenericOrderDetail): number {
    let total = 0

    if(detail.palletPrices)
    {
      detail.palletPrices.forEach(x => {
        total += x.price ? x.boxPerPallets * x.price : 0;
      })
      return total
    }
    return 0
  }

  getOrderTitle(order: ImportOrderInformation): string {
    return OrderUtils.getOrderTitle(order.importOrder.id)
  }

  getCreatedOrders() {
    this.getAllByStatus('Created')
  }

  getPendingVerifyPallets() {
    this.getAllByStatus('PendingVerifyPallets')
  }

  getImporterOrderConfirmed() {
    this.getAllByStatus('ImporterOrderConfirmed,ImporterPaymentConfirmed')
  }

  getAdminPalletsVerified() {
    this.getAllByStatus('AdminPalletsVerified')
  }

  getExpireds() {
    this.getAllByStatus('Expired')
  }

  getCancelleds() {
    this.getAllByStatus('Cancelled')
  }

  goToOrderDetailPage(orderId: any) {
    this._router.navigate(['/' + Routes.adminOrderDetail, orderId])
  }

  goToDelivery(orderId: any) {
    this._router.navigate(['/' + Routes.adminDelivery, orderId])
  }

  goToOperation(orderId: any) {
    this._router.navigate(['/' + Routes.adminOperation, orderId])
  }
}
