<div class="chat-container">
  <div #chatBox class="chat-box">
    <div *ngFor="let msg of messages" [ngClass]="{'auth-message': msg.sender === this.user?.username, 'other-message': msg.sender !== this.user?.username}" class="chat-message">
      <div class="message-header">
        <span class="message-sender">{{ msg.sender }}</span>
        <span class="message-time">{{ msg.created | date: 'dd-MM-yyyy HH:mm' }}</span>
      </div>
      <div class="message-content">
        {{ msg.message }}
      </div>
    </div>
  </div>
  <form [formGroup]="formGroup">
    <div class="input-box">
      <input type="text" pInputText formControlName="message" placeholder="{{ 'ChatNotification.MessagePlaceholder' | translate }}" />
      <button pButton type="button" label="{{ 'ChatNotification.SendButton' | translate }}" (click)="sendMessage()" [loading]="loading" [disabled]="!formGroup.get('message')?.value"></button>
    </div>
    <div *ngIf="formGroup.controls['message'].errors?.['maxlength']">
      <small class="error">{{ 'ChatNotification.Messages.ErrorLimitMessage' | translate }}: {{limitCharacters}}</small>
    </div>
  </form>
</div>