import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomePageComponent } from './home-page/home-page.component';
import { ComponentsModule } from "../components/components.module";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ForbiddenPageComponent } from './forbidden-page/forbidden-page.component';
import { TableModule } from 'primeng/table';
import { SidebarModule } from 'primeng/sidebar';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { LoginPageComponent } from './login-page/login-page.component';
import { TabMenuModule } from 'primeng/tabmenu';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { SignupPageContactComponent } from './signup-page/signup-page-contact/signup-page-contact.component';
import { SignupPageCompanyComponent } from './signup-page/signup-page-company/signup-page-company.component';
import { PanelModule } from 'primeng/panel';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { SignupPageImportAddInfoComponent } from './signup-page/signup-page-import-add-info/signup-page-import-add-info.component';
import { FileUploadModule } from 'primeng/fileupload';
import { TreeSelectModule } from 'primeng/treeselect';
import { ToolbarModule } from 'primeng/toolbar';
import { DialogModule } from 'primeng/dialog';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { MenuModule } from 'primeng/menu';
import { PanelMenuModule } from 'primeng/panelmenu';
import { UserContactInfoComponent } from './user-dashboard-pages/user-contact-info/user-contact-info.component';
import { UserCompanyInfoComponent } from './user-dashboard-pages/user-company-info/user-company-info.component';
import { UserProductsComponent } from './user-dashboard-pages/user-products/user-products.component';
import { UserFridgestoragesComponent } from './user-dashboard-pages/user-fridgestorages/user-fridgestorages.component';
import { UserConsigneesComponent } from './user-dashboard-pages/user-consignees/user-consignees.component';
import { UserNotifiesComponent } from './user-dashboard-pages/user-notifies/user-notifies.component';
import { FridgestorageFormComponent } from './user-dashboard-pages/user-fridgestorages/fridgestorage-form/fridgestorage-form.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { AvatarModule } from 'primeng/avatar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ConsigneeFormComponent } from './user-dashboard-pages/user-consignees/consignee-form/consignee-form.component';
import { NotifierFormComponent } from './user-dashboard-pages/user-notifies/notifier-form/notifier-form.component';
import { UserChangePasswordComponent } from './user-dashboard-pages/user-change-password/user-change-password.component';
import { ResetPasswordRequestPageComponent } from './reset-password-request-page/reset-password-request-page.component';
import { ResetPasswordPageComponent } from './reset-password-page/reset-password-page.component';
import { UserNotificationsConfigComponent } from './user-dashboard-pages/user-notifications/user-notifications-config.component';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { UserSupportComponent } from './user-dashboard-pages/user-support/user-support.component';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MarketplacePageComponent } from './marketplace-page/marketplace-page.component';
import { ImageModule } from 'primeng/image';
import { UserExportOffersComponent } from './user-dashboard-pages/user-export-offers/user-export-offers.component';
import { DataViewModule, DataViewLayoutOptions } from 'primeng/dataview';
import { NewExportOfferComponent } from './user-dashboard-pages/user-export-offers/new-export-offer/new-export-offer.component';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { CalendarModule } from 'primeng/calendar';
import { UserProductCertificatesComponent } from './user-dashboard-pages/user-product-certificates/user-product-certificates.component';
import { UserPurchaseOrdersComponent } from './user-dashboard-pages/user-purchase-orders/user-purchase-orders.component';
import { ProductCertificateFormComponent } from './user-dashboard-pages/user-product-certificates/product-certificate-form/product-certificate-form.component';
import { PurchaseOrdersPageComponent } from './purchase-orders-page/purchase-orders-page.component';
import { ContainerOrderPageComponent } from './container-order-page/container-order-page.component';
import { FieldsetModule } from 'primeng/fieldset';
import { TagModule } from 'primeng/tag';
import { VerifyPalletsPageComponent } from './user-dashboard-pages/user-purchase-orders/verify-pallets-page/verify-pallets-page.component';
import { AccordionModule } from 'primeng/accordion';
import { TooltipModule } from 'primeng/tooltip';
import { OrderDetailComponent } from './user-dashboard-pages/user-purchase-orders/order-detail/order-detail.component';
import { EditExportOfferComponent } from './user-dashboard-pages/user-export-offers/edit-export-offer/edit-export-offer.component';
import { AdminManageOrdersPageComponent } from './admin-dashboard-pages/admin-manage-orders-page/admin-manage-orders-page.component';
import { UserBargainingComponent } from './user-dashboard-pages/user-bargaining/user-bargaining.component';
import { BargainingDetailComponent } from './user-dashboard-pages/user-bargaining/bargaining-detail/bargaining-detail.component';
import { TimelineModule } from 'primeng/timeline';
import { AdminOrderDetailComponent } from './admin-dashboard-pages/admin-manage-orders-page/order-detail/admin-order-detail.component';
import { BadgeModule } from 'primeng/badge';
import { AdminVerifyPalletsComponent } from './admin-dashboard-pages/admin-manage-orders-page/admin-verify-pallets/admin-verify-pallets.component';
import { AdminCompleteOrderComponent } from './admin-dashboard-pages/admin-manage-orders-page/admin-complete-order/admin-complete-order.component';
import { UserNotificationsComponent } from './user-dashboard-pages/user-notifications/notifications/user-notifications/user-notifications.component';
import { UserExportOfferSuggestionsComponent } from './user-dashboard-pages/user-export-offer-suggestions/user-export-offer-suggestions.component';
import { AdminDeliveryComponent } from './admin-dashboard-pages/admin-manage-orders-page/admin-delivery/admin-delivery.component';
import { ConfirmPurchasePageComponent } from './user-dashboard-pages/user-purchase-orders/confirm-purchase-page/confirm-purchase-page.component';
import { PaymentsPageComponent } from './user-dashboard-pages/user-purchase-orders/payments-page/payments-page.component';
import { OfferSuggestionsComponent } from './user-dashboard-pages/user-export-offer-suggestions/offer-suggestions/offer-suggestions.component';
import { AdminOperationComponent } from './admin-dashboard-pages/admin-manage-orders-page/admin-operation/admin-operation.component';
import { CarouselModule } from 'primeng/carousel';
import { AdminManageExportOffersPageComponent } from './admin-dashboard-pages/admin-manage-export-offers-page/admin-manage-export-offers-page.component';
import { AdminEditExportOfferComponent } from './admin-dashboard-pages/admin-manage-export-offers-page/admin-edit-export-offer/admin-edit-export-offer.component';
import { AdminManageSpeciesComponent } from './admin-dashboard-pages/admin-manage-species/admin-manage-species.component';
import { AdminNewSpeciesComponent } from './admin-dashboard-pages/admin-manage-species/admin-new-species/admin-new-species.component';
import { AdminUsersComponent } from './admin-dashboard-pages/admin-users/admin-users.component';
import { AdminEditUserComponent } from './admin-dashboard-pages/admin-users/admin-edit-user/admin-edit-user.component';
import { UserImportRequestComponent } from './user-dashboard-pages/user-import-request/user-import-request.component';
import { NewImportRequestComponent } from './user-dashboard-pages/user-import-request/new-import-request/new-import-request.component';
import { EditImportRequestComponent } from './user-dashboard-pages/user-import-request/edit-import-request/edit-import-request.component';
import { ViewImportRequestOffersComponent } from './user-dashboard-pages/user-import-request/view-import-request-offers/view-import-request-offers.component';
import { TabViewModule } from 'primeng/tabview';
import { UserClosedSaleComponent } from './user-dashboard-pages/user-closed-sale/user-closed-sale.component';
import { UserCreateClosedSaleComponent } from './user-dashboard-pages/user-closed-sale/create-closed-sale/user-create-closed-sale/user-create-closed-sale.component';
import { UserEditClosedSaleComponent } from './user-dashboard-pages/user-closed-sale/user-edit-closed-sale/user-edit-closed-sale.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { ExporterClosedSaleOrdersComponent } from './user-dashboard-pages/user-closed-sale/exporter-closed-sale-orders/exporter-closed-sale-orders.component';
import { UserClosedSaleImportOrderDetailComponent } from './user-dashboard-pages/user-closed-sale/user-closed-sale-import-order-detail/user-closed-sale-import-order-detail.component';
import { UserClosedSaleExporterVerifyPalletsComponent } from './user-dashboard-pages/user-closed-sale/user-closed-sale-exporter-verify-pallets/user-closed-sale-exporter-verify-pallets.component';
import { ImporterClosedSaleOrdersComponent } from './user-dashboard-pages/user-closed-sale/importer-closed-sale-orders/importer-closed-sale-orders.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { UserPanelComponent } from './user-dashboard-pages/user-panel/user-panel.component';
import { UserImportOrdersPendingComponent } from './user-dashboard-pages/user-purchase-orders/user-import-orders-pending/user-import-orders-pending.component';
import { UserImportOrdersApprovedComponent } from './user-dashboard-pages/user-purchase-orders/user-import-orders-approved/user-import-orders-approved.component';
import { UserImportOrdersConfirmedComponent } from './user-dashboard-pages/user-purchase-orders/user-import-orders-confirmed/user-import-orders-confirmed.component';
import { UserImportOrdersCreatedComponent } from './user-dashboard-pages/user-purchase-orders/user-import-orders-created/user-import-orders-created.component';
import { UserImportOrdersCurrentComponent } from './user-dashboard-pages/user-purchase-orders/user-import-orders-current/user-import-orders-current.component';
import { UserImportOrdersPurchaseComponent } from './user-dashboard-pages/user-purchase-orders/user-import-orders-purchase/user-import-orders-purchase.component';
import { PaymentsComponentComponent } from './user-dashboard-pages/user-purchase-orders/payments-page/payments-component/payments-component.component';
import { UserClosedSalePaymentsComponent } from './user-dashboard-pages/user-closed-sale/user-closed-sale-payments/user-closed-sale-payments.component';
import { OrderDetailComponentComponent } from './user-dashboard-pages/user-purchase-orders/order-detail/order-detail-component/order-detail-component.component';
import { VerifyPalletsComponentComponent } from './user-dashboard-pages/user-purchase-orders/verify-pallets-page/verify-pallets-component/verify-pallets-component.component';
import { AdminNotificationsComponent } from './admin-dashboard-pages/admin-notifications/admin-notifications.component';
import { AdminMasterLoadPageComponent } from './admin-dashboard-pages/admin-master-load-page/admin-master-load-page.component';
import { BlockUIModule } from 'primeng/blockui';
import { InputMaskModule } from 'primeng/inputmask';
import { AdminEditPalletsComponent } from './admin-dashboard-pages/admin-manage-orders-page/admin-edit-pallets/admin-edit-pallets.component';
import { SignupQccompanyPageComponent } from './signup-qccompany-page/signup-qccompany-page.component';
import { QcUserReviewsComponent } from './qc-user-dashboard-pages/qc-user-reviews/qc-user-reviews.component';
import { QcUserOriginReviewComponent } from './qc-user-dashboard-pages/qc-user-origin-review/qc-user-origin-review.component';
import { QcUserOriginReviewedComponent } from './qc-user-dashboard-pages/qc-user-origin-reviewed/qc-user-origin-reviewed.component';
import { ForwarderCreateOrderPageComponent } from './forwarder-pages/forwarder-create-order-page/forwarder-create-order-page.component';
import { AdminCreateUserComponent } from './admin-dashboard-pages/admin-users/admin-create-user/admin-create-user.component';
import { AdminExporterImportersPageComponent } from './admin-dashboard-pages/admin-users/admin-exporter-importers-page/admin-exporter-importers-page.component';
import { FrozenFruitExportOffersComponent } from './user-dashboard-pages/user-frozen-fruit-export-offer/frozen-fruit-export-offers/frozen-fruit-export-offers.component';
import { FrozenFruitExportOfferCreateComponent } from './user-dashboard-pages/user-frozen-fruit-export-offer/frozen-fruit-export-offer-create/frozen-fruit-export-offer-create.component';
import { FrozenFruitExportOffersActiveComponent } from './user-dashboard-pages/user-frozen-fruit-export-offer/frozen-fruit-export-offers-active/frozen-fruit-export-offers-active.component';
import { FrozenFruitExportOffersInactiveComponent } from './user-dashboard-pages/user-frozen-fruit-export-offer/frozen-fruit-export-offers-inactive/frozen-fruit-export-offers-inactive.component';
import { FrozenFruitExportOfferEditComponent } from './user-dashboard-pages/user-frozen-fruit-export-offer/frozen-fruit-export-offer-edit/frozen-fruit-export-offer-edit.component';
import { FrozenFruitExportOfferEditPicturesComponent } from './user-dashboard-pages/user-frozen-fruit-export-offer/frozen-fruit-export-offer-edit-pictures/frozen-fruit-export-offer-edit-pictures.component';
import { FrozenFruitExportOfferEditDocumentsComponent } from './user-dashboard-pages/user-frozen-fruit-export-offer/frozen-fruit-export-offer-edit-documents/frozen-fruit-export-offer-edit-documents.component';
import { FrozenFruitExportOfferMarketplaceComponent } from './marketplace-page/frozen-fruit-export-offer-marketplace/frozen-fruit-export-offer-marketplace.component';
import { FrozenFruitExportOfferAddCartComponent } from './marketplace-page/frozen-fruit-export-offer-add-cart/frozen-fruit-export-offer-add-cart.component';
import { FrozenFruitPurchaseOrdersPageComponent } from './purchase-orders-page/frozen-fruit-purchase-orders-page/frozen-fruit-purchase-orders-page.component';
import { FrozenFruitVerifyPalletsComponent } from './user-dashboard-pages/user-purchase-orders/verify-pallets-page/frozen-fruit-verify-pallets/frozen-fruit-verify-pallets.component';
import { QcUserFrozenFruitOriginReviewComponent } from './qc-user-dashboard-pages/qc-user-frozen-fruit-origin-review/qc-user-frozen-fruit-origin-review.component';
import { AdminFrozenFruitVerifyPalletsComponent } from './admin-dashboard-pages/admin-manage-orders-page/admin-frozen-fruit-verify-pallets/admin-frozen-fruit-verify-pallets.component';
import { AdminInvitationCodesComponent } from './admin-dashboard-pages/admin-invitation-codes/admin-invitation-codes.component';
import { ProductCertificateCrudComponent } from './user-dashboard-pages/user-product-certificates/product-certificate-crud/product-certificate-crud.component';
import { ExportOfferEditAdditionalInfoComponent } from './user-dashboard-pages/user-export-offers/export-offer-edit-additional-info/export-offer-edit-additional-info.component';
import { ExportOfferProductCertificatesCrudComponent } from './user-dashboard-pages/user-export-offers/export-offer-product-certificates-crud/export-offer-product-certificates-crud.component';
import { ExportOfferDocumentsCrudComponent } from './user-dashboard-pages/user-export-offers/export-offer-documents-crud/export-offer-documents-crud.component';
import { ExportOfferDocumentsPageComponent } from './user-dashboard-pages/user-export-offers/export-offer-documents-page/export-offer-documents-page.component';
import { UserLoadOrdersComponent } from './user-dashboard-pages/user-purchase-orders/user-load-orders/user-load-orders.component';
import { QcUserReviewDocumentsComponent } from './qc-user-dashboard-pages/qc-user-review-documents/qc-user-review-documents.component';
import { AdminManageQcReviewsComponent } from './admin-dashboard-pages/admin-manage-qc-reviews/admin-manage-qc-reviews.component';
import { AdminQcReviewComponent } from './admin-dashboard-pages/admin-qc-review/admin-qc-review.component';
import { AdminQcReviewDocumentsComponent } from './admin-dashboard-pages/admin-qc-review-documents/admin-qc-review-documents.component';
import { SignupInvitationcodeComponent } from './signup-page/signup-invitationcode/signup-invitationcode.component';
import { UserDirectSaleFormComponent } from './user-dashboard-pages/user-closed-sale/user-direct-sale-form/user-direct-sale-form.component';
import { UserDirectSaleDocumentsPageComponent } from './user-dashboard-pages/user-closed-sale/user-direct-sale-documents-page/user-direct-sale-documents-page.component';
import { UserDirectSaleEditAdditionalInfoComponent } from './user-dashboard-pages/user-closed-sale/user-direct-sale-edit-additional-info/user-direct-sale-edit-additional-info.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { OrderDetailTabComponent } from '../components/order-detail-tab/order-detail-tab.component';
import { SignupValidationtokenComponent } from './signup-page/signup-validationtoken/signup-validationtoken.component';
import { ExportOfferFiltersComponent } from './user-dashboard-pages/user-export-offers/export-offer-filters/export-offer-filters.component';
import { ForwarderUserReviewsComponent } from './forwarder-user-dashboard-pages/forwarder-user-reviews/forwarder-user-reviews.component';
import { ForwarderUserOriginReviewComponent } from './forwarder-user-dashboard-pages/forwarder-user-origin-review/forwarder-user-origin-review.component';
import { AdminForwardersComponent } from './admin-dashboard-pages/admin-forwarders/admin-forwarders.component';
import { AdminCreateForwarderComponent } from './admin-dashboard-pages/admin-forwarders/admin-create-forwarder/admin-create-forwarder.component';
import { AdminEditForwarderComponent } from './admin-dashboard-pages/admin-forwarders/admin-edit-forwarder/admin-edit-forwarder.component';
import { ForwarderUserOriginReviewedComponent } from './forwarder-user-dashboard-pages/forwarder-user-origin-reviewed/forwarder-user-origin-reviewed.component';
import { ForwarderUsersComponent } from './forwarder-user-dashboard-pages/forwarder-users/forwarder-users.component';
import { UserProgramRequestCreateComponent } from './user-dashboard-pages/user-program-request/user-program-request-create/user-program-request-create.component';
import { UserProgramEditComponent } from './user-dashboard-pages/user-program-request/user-program-edit/user-program-edit.component';
import { ForwarderDeliveryComponent } from './forwarder-user-dashboard-pages/forwarder-delivery/forwarder-delivery.component';
import { AdminCustomAgentsComponent } from './admin-dashboard-pages/admin-custom-agents/admin-custom-agents.component';
import { AdminCreateCustomAgentComponent } from './admin-dashboard-pages/admin-custom-agents/admin-create-custom-agent/admin-create-custom-agent.component';
import { AdminEditCustomAgentComponent } from './admin-dashboard-pages/admin-custom-agents/admin-edit-custom-agent/admin-edit-custom-agent.component';
import { CustomAgentUsersComponent } from './custom-agent-dashboard-pages/custom-agent-users/custom-agent-users.component';
import { CustomAgentReviewsComponent } from './custom-agent-dashboard-pages/custom-agent-reviews/custom-agent-reviews.component';
import { CustomAgentOriginReviewedComponent } from './custom-agent-dashboard-pages/custom-agent-origin-reviewed/custom-agent-origin-reviewed.component';
import { CustomAgentOriginReviewComponent } from './custom-agent-dashboard-pages/custom-agent-origin-review/custom-agent-origin-review.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ForwarderImportOrderComponent } from './forwarder-user-dashboard-pages/forwarder-import-order/forwarder-import-order.component';
import { ForwarderEditImportOrderComponent } from './forwarder-user-dashboard-pages/forwarder-import-order/forwarder-edit-import-order/forwarder-edit-import-order.component';
import { ForwarderOrderDetailComponent } from './forwarder-user-dashboard-pages/forwarder-import-order/forwarder-order-detail/forwarder-order-detail.component';
import { UserImportOrdersForwarderComponent } from './user-dashboard-pages/user-purchase-orders/user-import-orders-forwarder/user-import-orders-forwarder.component';
import { OrderNotFoundComponent } from './order-not-found/order-not-found.component';
import { QcOrderDetailComponent } from './user-dashboard-pages/user-purchase-orders/qc-order-detail/qc-order-detail.component';
import { QcOrderPaymentComponent } from './user-dashboard-pages/user-purchase-orders/qc-order-payment/qc-order-payment.component';
import { ExternalMarketplacePageComponent } from './external-marketplace-page/external-marketplace-page.component';
import { OfferDetailComponent } from './offer-detail/offer-detail.component';
import { ImportRequestDetailPageComponent } from './marketplace-page/import-request-detail-page/import-request-detail-page.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { ComexVirtualPageComponent } from './comex-virtual-page/comex-virtual-page.component';

@NgModule({

    declarations: [
        HomePageComponent,
        ForbiddenPageComponent,
        LoginPageComponent,
        SignupPageContactComponent,
        SignupPageCompanyComponent,
        SignupPageImportAddInfoComponent,
        UserContactInfoComponent,
        UserCompanyInfoComponent,
        UserProductsComponent,
        UserFridgestoragesComponent,
        UserConsigneesComponent,
        UserNotifiesComponent,
        FridgestorageFormComponent,
        ConsigneeFormComponent,
        NotifierFormComponent,
        UserChangePasswordComponent,
        ResetPasswordRequestPageComponent,
        ResetPasswordPageComponent,
        UserNotificationsConfigComponent,
        UserSupportComponent,
        MarketplacePageComponent,
        UserExportOffersComponent,
        NewExportOfferComponent,
        UserProductCertificatesComponent,
        UserPurchaseOrdersComponent,
        ProductCertificateFormComponent,
        PurchaseOrdersPageComponent,
        ContainerOrderPageComponent,
        VerifyPalletsPageComponent,
        OrderDetailComponent,
        EditExportOfferComponent,
        AdminManageOrdersPageComponent,
        UserBargainingComponent,
        BargainingDetailComponent,
        AdminOrderDetailComponent,
        AdminVerifyPalletsComponent,
        AdminCompleteOrderComponent,
        UserNotificationsComponent,
        UserExportOfferSuggestionsComponent,
        AdminDeliveryComponent,
        ConfirmPurchasePageComponent,
        PaymentsPageComponent,
        OfferSuggestionsComponent,
        AdminOperationComponent,
        AdminManageExportOffersPageComponent,
        AdminEditExportOfferComponent,
        AdminManageSpeciesComponent,
        AdminNewSpeciesComponent,
        AdminUsersComponent,
        AdminEditUserComponent,
        UserImportRequestComponent,
        NewImportRequestComponent,
        EditImportRequestComponent,
        ViewImportRequestOffersComponent,
        UserClosedSaleComponent,
        UserClosedSaleComponent,
        UserCreateClosedSaleComponent,
        UserEditClosedSaleComponent,
        ExporterClosedSaleOrdersComponent,
        UserClosedSaleImportOrderDetailComponent,
        UserClosedSaleExporterVerifyPalletsComponent,
        ImporterClosedSaleOrdersComponent,
        UserPanelComponent,
        UserImportOrdersPendingComponent,
        UserImportOrdersApprovedComponent,
        UserImportOrdersConfirmedComponent,
        UserImportOrdersCreatedComponent,
        UserImportOrdersCurrentComponent,
        UserLoadOrdersComponent,
        UserImportOrdersPurchaseComponent,
        PaymentsComponentComponent,
        UserClosedSalePaymentsComponent,
        OrderDetailComponentComponent,
        VerifyPalletsComponentComponent,
        AdminNotificationsComponent,
        AdminMasterLoadPageComponent,
        AdminEditPalletsComponent,
        SignupQccompanyPageComponent,
        QcUserReviewsComponent,
        QcUserOriginReviewComponent,
        QcUserOriginReviewedComponent,
        ForwarderCreateOrderPageComponent,
        AdminCreateUserComponent,
        AdminExporterImportersPageComponent,
        FrozenFruitExportOffersComponent,
        FrozenFruitExportOfferCreateComponent,
        FrozenFruitExportOffersActiveComponent,
        FrozenFruitExportOffersInactiveComponent,
        FrozenFruitExportOfferEditComponent,
        FrozenFruitExportOfferEditPicturesComponent,
        FrozenFruitExportOfferEditDocumentsComponent,
        FrozenFruitExportOfferMarketplaceComponent,
        FrozenFruitExportOfferAddCartComponent,
        FrozenFruitPurchaseOrdersPageComponent,
        FrozenFruitVerifyPalletsComponent,
        QcUserFrozenFruitOriginReviewComponent,
        AdminFrozenFruitVerifyPalletsComponent,
        AdminInvitationCodesComponent,
        ProductCertificateCrudComponent,
        ExportOfferEditAdditionalInfoComponent,
        ExportOfferProductCertificatesCrudComponent,
        ExportOfferDocumentsCrudComponent,
        ExportOfferDocumentsPageComponent,
        QcUserReviewDocumentsComponent,
        AdminManageQcReviewsComponent,
        AdminQcReviewComponent,
        AdminQcReviewDocumentsComponent,
        SignupInvitationcodeComponent,
        UserDirectSaleFormComponent,
        UserDirectSaleDocumentsPageComponent,
        UserDirectSaleEditAdditionalInfoComponent,
        SignupValidationtokenComponent,
        ExportOfferFiltersComponent,
        ForwarderUserReviewsComponent,
        ForwarderUserOriginReviewComponent,
        AdminForwardersComponent,
        AdminCreateForwarderComponent,
        AdminEditForwarderComponent,
        ForwarderUserOriginReviewedComponent,
        ForwarderUsersComponent,
        UserProgramRequestCreateComponent,
        UserProgramEditComponent,
        ForwarderDeliveryComponent,
        AdminCustomAgentsComponent,
        AdminCreateCustomAgentComponent,
        AdminEditCustomAgentComponent,
        CustomAgentUsersComponent,
        CustomAgentReviewsComponent,
        CustomAgentOriginReviewedComponent,
        CustomAgentOriginReviewComponent,
        ForwarderImportOrderComponent,
        ForwarderEditImportOrderComponent,
        ForwarderOrderDetailComponent,
        UserImportOrdersForwarderComponent,
        OrderNotFoundComponent,
        QcOrderDetailComponent,
        QcOrderPaymentComponent,
        ExternalMarketplacePageComponent,
        OfferDetailComponent,
        ProductDetailComponent,
        ImportRequestDetailPageComponent,
        ComexVirtualPageComponent,
    ],
    imports: [
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: httpTranslateLoader,
          deps: [HttpClient]
        }
      }),
        CommonModule,
        ComponentsModule,
        ReactiveFormsModule,
        FormsModule,
        TableModule,
        SidebarModule,
        ButtonModule,
        CardModule,
        DynamicDialogModule,
        TabMenuModule,
        InputTextModule,
        PasswordModule,
        PanelModule,
        DividerModule,
        DropdownModule,
        InputNumberModule,
        FileUploadModule,
        TreeSelectModule,
        ToolbarModule,
        DialogModule,
        MessagesModule,
        MessageModule,
        ToastModule,
        MenuModule,
        PanelMenuModule,
        ConfirmDialogModule,
        AvatarModule,
        ProgressSpinnerModule,
        ToggleButtonModule,
        InputTextareaModule,
        InputSwitchModule,
        ImageModule,
        DataViewModule,
        BreadcrumbModule,
        CalendarModule,
        FieldsetModule,
        TagModule,
        AccordionModule,
        TooltipModule,
        TimelineModule,
        BadgeModule,
        CarouselModule,
        TabViewModule,
        MultiSelectModule,
        BlockUIModule,
        InputMaskModule,
        FontAwesomeModule,
        SidebarModule,
        OverlayPanelModule
    ],
    providers: [
    ]
})
export class PagesModule {
}

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
