import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { FrozenFruitExporterVerifyPalletsRequest, FrozenFruitPalletList, FrozenFruitPalletQCOriginReviewRequest } from "src/app/domain/models/FrozenFruitPallet";
import { UploadImportOrderDetailPdfRequest } from "src/app/domain/models/ImportOrder";

@Injectable({providedIn: 'root'})
export abstract class FrozenFruitPalletGateway {
  abstract createFromExporterVerify(request: FrozenFruitExporterVerifyPalletsRequest): Observable<any>
  abstract qcCompanyVerifyPallets(request: FrozenFruitPalletQCOriginReviewRequest): Observable<any>
  abstract getAllByFrozenFruitOrderDetail(frozenFruitOrderDetailId: number): Observable<FrozenFruitPalletList>
  abstract uploadQCReviewPdf(request: UploadImportOrderDetailPdfRequest): Observable<any>
  abstract uploadQCContainerConsolidationPdf(request: UploadImportOrderDetailPdfRequest): Observable<any>
}
