<app-user-dashboard [userMenuActiveItemIndex]="0" [activeItem]="'corporative'">
  <p-card [style]="{'min-height':'200px'}">
    <ng-template pTemplate="header">
      <div class="flex justify-content-between flex-wrap align-items-center" style="padding: 15px;">
        <h2>{{'UserPages.UserCompanyInfo.PageTitle' | translate}}</h2>
        <p-button label="{{'UserPages.UserCompanyInfo.EditButton' | translate}}" icon="pi pi-file-edit" (click)="setEnableEdit()" styleClass="p-button-text"></p-button>
      </div>
      <p style="margin-left: 15px;">{{'UserPages.UserCompanyInfo.RequiredFieldText' | translate}}</p>
    </ng-template>

    <form [formGroup]="formGroup">
      <div class="p-fluid formgrid grid" style="padding: 20px;">
        <div class="field col-12 md:col-6 lg:col-6" style="margin-bottom: 40px;">
          <label for="vat" style="font-weight: bolder;">{{'UserPages.UserCompanyInfo.RutFieldLabel' | translate}} *</label>
          <input pInputText id="vat" formControlName="vat" type="text" maxlength="11" (keyup.enter)="validateSII()" (focusout)="validateSII()" placeholder="{{'UserPages.UserCompanyInfo.RutFieldPlaceholder' | translate}}"/>
          <small *ngIf="submit && formGroup.controls['vat'].invalid" class="p-error">{{getErrorMessage('vat')}}</small>
          <small *ngIf="verifierDigitError" class="p-error">{{'UserPages.UserCompanyInfo.RutFormatError' | translate}}</small>
        </div>
        <div class="field col-12 md:col-6 lg:col-6" style="margin-bottom: 40px;">
          <label for="companyName" style="font-weight: bolder;">{{'UserPages.UserCompanyInfo.CompanyNameFieldLabel' | translate}} *</label>
          <input pInputText id="companyName" formControlName="companyName" type="text" required placeholder="{{'UserPages.UserCompanyInfo.CompanyNameFieldPlaceholder' | translate}}"/>
          <small *ngIf="submit && formGroup.controls['companyName'].invalid" class="p-error">{{getErrorMessage('companyName')}}</small>
        </div>
        <div class="field col-12 md:col-6 lg:col-6" style="margin-bottom: 40px;" *ngIf="profile?.type == 1">
          <label for="sag" style="font-weight: bolder;">{{'UserPages.UserCompanyInfo.SagCodeFieldLabel' | translate}}</label>
          <p-inputNumber id="sag" formControlName="sag" placeholder="{{'UserPages.UserCompanyInfo.SagCodeFieldPlaceholder' | translate}}"/>
        </div>
        <div class="field col-12 md:col-6 lg:col-6" style="margin-bottom: 40px;">
          <label for="legalRepresentative" style="font-weight: bolder;">{{'UserPages.UserCompanyInfo.CompanyLegalRepresentantNameFieldLabel' | translate}} *</label>
          <input pInputText id="legalRepresentative" formControlName="legalRepresentative" type="text" required placeholder="{{'UserPages.UserCompanyInfo.CompanyLegalRepresentantNameFieldPlaceholder' | translate}}"/>
          <small *ngIf="submit && formGroup.controls['legalRepresentative'].invalid" class="p-error">{{getErrorMessage('legalRepresentative')}}</small>
        </div>
        <div class="field col-12 md:col-6 lg:col-6" style="margin-bottom: 40px;">
          <label for="legalRepresentativeId" style="font-weight: bolder;">{{'UserPages.UserCompanyInfo.CompanyLegalRepresentantNuipFieldLabel' | translate}} *</label>
          <p-inputNumber id="legalRepresentativeId" formControlName="legalRepresentativeId" placeholder="{{'UserPages.UserCompanyInfo.CompanyLegalRepresentantNuipFieldPlaceholder' | translate}}"/>
          <small *ngIf="submit && formGroup.controls['legalRepresentativeId'].invalid" class="p-error">{{getErrorMessage('legalRepresentativeId')}}</small>
        </div>
        <div class="field col-12" style="margin-bottom: 40px;">
          <label>{{'UserPages.UserCompanyInfo.CompanyLegalDocumentFieldLabelPart1' | translate}} <span style="font-weight: bolder;">PDF</span> {{'UserPages.UserCompanyInfo.CompanyLegalDocumentFieldLabelPart2' | translate}}.</label>
          <p-fileUpload
            (onSelect)="onSelect($event)"
            [showUploadButton]="false"
            [multiple]="false"
            accept=".pdf"
            [maxFileSize]="5000000"
            chooseLabel="{{'UserPages.UserCompanyInfo.SelectImageButton' | translate}}"
            cancelLabel="{{'UserPages.UserCompanyInfo.CancelImagesButton' | translate}}"
            [disabled]="disabledFields"/>
        </div>
        <div class="col-12">
          <div class="grid" style="margin-top: 30px;">
            <div class="col-12 md:col-6 lg:col-6"></div>
            <div class="col-12 md:col-6 lg:col-6">
              <div class="grid p-fluid">
                <div class="col-6">
                  <p-button label="{{'UserPages.UserCompanyInfo.BackButton' | translate}}" [disabled]="loading" styleClass="p-button-outlined" (click)="onCancel()"/>
                </div>
                <div class="col-6">
                  <p-button label="{{'UserPages.UserCompanyInfo.SaveButton' | translate}}" [loading]="loading" (click)="save()" [disabled]="disabledFields"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </p-card>
</app-user-dashboard>

<p-toast></p-toast>
