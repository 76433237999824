<app-dashboard-layout>
  <app-content-body>
    <div class="flex justify-content-center align-items-center flex-column flex-wrap">
      <div class="flex justify-content-end w-full mr-2">
        <app-switch-lang></app-switch-lang>
      </div>
      <img src="../../../../assets/img/fruzty-logo-slogan.png" width="480" height="214"/>
      <p-card header="{{'ResetPasswordPage.CardHeader' | translate}}"
        subheader="{{'ResetPasswordPage.CardSubheader' | translate}}" [style]="{'width': '30rem', 'margin-top': '8em'}">
        <form [formGroup]="formGroup">
          <div class="p-fluid formgrid grid">
            <div class="field col-12" style="margin-bottom: 40px;">
              <label for="newPassword">{{'ResetPasswordPage.NewPasswordFieldLabel' | translate}} *</label>
              <p-password [toggleMask]="true" id="newPassword" formControlName="newPassword" [minlength]="8">
                <ng-template pTemplate="header">
                  <h6>{{'ResetPasswordPage.SubtitlePassword' | translate}}</h6>
                </ng-template>
                <ng-template pTemplate="footer">
                  <p-divider></p-divider>
                  <p class="mt-2">{{'ResetPasswordPage.PasswordSubtitle' | translate}}</p>
                  <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                    <li>{{'ResetPasswordPage.PasswordSuggestion1' | translate}}</li>
                    <li>{{'ResetPasswordPage.PasswordSuggestion2' | translate}}</li>
                    <li>{{'ResetPasswordPage.PasswordSuggestion3' | translate}}</li>
                    <!-- <li>Al menos un caracter especial</li> -->
                    <li>{{'ResetPasswordPage.PasswordSuggestion4' | translate}}</li>
                  </ul>
                </ng-template>
              </p-password>
              <small *ngIf="submit && formGroup.controls['newPassword'].invalid" class="p-error">{{getErrorMessage('newPassword')}}</small>
            </div>
            <div class="field col-12" style="margin-bottom: 40px;">
              <label for="confirmPassword">{{'ResetPasswordPage.ConfirmPasswordFieldLabel' | translate}} *</label>
              <p-password [toggleMask]="true" id="confirmPassword" formControlName="confirmPassword" [minlength]="8" [feedback]="false"></p-password>
              <small *ngIf="submit && formGroup.controls['confirmPassword'].invalid" class="p-error">{{getErrorMessage('confirmPassword')}}</small>
              <small *ngIf="submit && passwordMatchError" class="p-error">{{'ResetPasswordPage.PasswordErrorMessageText' | translate}}</small>
            </div>
            <div class="field col-12">
              <p-button label="{{'ResetPasswordPage.SendButton' | translate}}" [loading]="loading" (click)="onSubmit()"/>
            </div>
            <div class="field col-12">
              <p-message *ngIf="success" severity="success" text="{{'ResetPasswordPage.SuccessMessage' | translate}}"></p-message>
            </div>
            <div class="field col-12 flex justify-content-between ">
              <a [routerLink]="'/'">{{'ResetPasswordPage.BackLink' | translate}}</a>
              <a *ngIf="success" [routerLink]="'/login'">{{'ResetPasswordPage.LoginLink' | translate}}</a>
            </div>
          </div>
        </form>
      </p-card>
    </div>
  </app-content-body>
</app-dashboard-layout>
