import { AfterViewInit, Component, ElementRef, Input, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { delay } from 'rxjs';
import { ImportOrder, ImportOrderInformation } from 'src/app/domain/models/ImportOrder';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.css']
})
export class TimerComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input()
  orderDateTarget!: Date
  @Input()
  hoursTimer: number = 0

  @Input()
  showSeconds = true

  date: any;
  now: any;
  targetDate!: any;
  targetTime!: any;
  difference!: number;
  daysDifference!: number;
  hourDifference!: number;
  minutesDifference!: number;
  secondsDifference!: number;
  processing: boolean = true;
  delay: number = 0;
  appliedDelay: boolean = false
  interval: any

  days: number = 0
  hours: number = 0
  minutes: number = 0
  seconds: number = 0

  ngOnInit(): void {
    const orderDate = new Date(this.orderDateTarget);
    this.delay = this.hoursTimer > 0 ? 59 - orderDate.getSeconds() : 0;
     this.targetDate = this.addHoursAndSeconds(orderDate, this.hoursTimer, this.delay);

    this.targetDate = this.addSeconds(orderDate, this.delay);
    this.targetTime = this.targetDate.getTime();

    this.initClock()
  }

  ngAfterViewInit() {

  }

  initClock() {
    this.interval = setInterval(() => {
      this.tickTock();
      this.difference = this.targetTime - this.now;
      this.daysDifference = this.difference / (1000 * 60 * 60 * 24);
      this.hourDifference = this.difference / (1000 * 60 * 60)
      this.minutesDifference = this.difference / (1000 * 60 )
      this.secondsDifference = this.difference / (1000)

      !isNaN(this.days)
        ? this.processing = false
        : this.processing = true;

    }, 1000);
  }

  ngOnDestroy(): void {
      if(this.interval){
        clearInterval(this.interval);
      }
  }

  tickTock() {
    const currDate = Date.now();
    this.date = new Date(currDate);
    this.now = this.date.getTime();
    if(this.date > this.targetDate){
      this.days = 0;
      this.hours = 0;
      this.minutes = 0;
      this.seconds = 0;
    }else{
      this.days = Math.floor(this.daysDifference);
      this.hours = Math.floor(this.daysDifference) > 0 ? 23 - this.date.getHours(): Math.floor(this.hourDifference)
      this.minutes = Math.floor(this.hourDifference) > 0 ? 60 - this.date.getMinutes(): Math.floor(this.minutesDifference)
      this.seconds = Math.floor(this.minutesDifference) > 0 ? 60 - (this.date.getSeconds())  : Math.floor(this.secondsDifference)
    }
  }

  addHoursAndSeconds(date: Date, hours: number, seconds: number = 0): Date {
    date.setTime(date.getTime() + (hours * 60 * 60 * 1000) + (seconds * 1000));
    return date;
  }

  addSeconds(date: Date, seconds: number): Date{
    date.setTime(date.getTime() + seconds * 1000);
    return date;
  }

  applyDelay(): number{
    if(!this.appliedDelay){
      this.appliedDelay = true;
      return this.delay;
    }

    return 0;
  }
}
