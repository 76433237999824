import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ImportOrderConfirmPurchaseRequest } from "src/app/domain/models/Delivery";
import { AssignOrderCompanyRequest, ChatMessage, ClosedSaleWeek, ClosedSaleWeekRequest, DirectSaleProgramExporter, ImportOrder, ImportOrderContainerItem, ImportOrderDetail, ImportOrderInformation, ImportOrderListItem, ImportOrderReviewMessage, ImportOrderReviewMessageCreate } from "src/app/domain/models/ImportOrder";
import { Routes } from "src/app/infrastructure/routes";
import { ImportOrderGateway } from "../gateway/ImportOrderGateway";
import { LoadOrderListItem } from "src/app/domain/models/LoadOrder";
import { AddImportoOrderToProgramWeek, DirectSaleProgram } from "src/app/domain/models/DirectSaleProgram";
import { EditBasicOffer, ForwarderOrderListItem } from "src/app/domain/models/ForwarderOrder";

@Injectable({providedIn: 'root'})
export class ImportOrderService extends ImportOrderGateway {

  constructor(private _http: HttpClient) {
    super()
  }

  getAllByImporter(): Observable<ImportOrderListItem[]> {
    return this._http.get<ImportOrderListItem[]>(Routes.importOrderApi + '/GetAllByImporter')
  }

  getAllForShoppingCart(): Observable<ImportOrderContainerItem[]> {
    return this._http.get<ImportOrderContainerItem[]>(Routes.importOrderApi + '/GetAllForOrdersView')
  }

  getAllClosedSale(directSaleProgramId: any, startWeek: number, endWeek: number, year: number): Observable<DirectSaleProgramExporter> {
    return this._http.get<DirectSaleProgramExporter>(Routes.importOrderApi + '/GetAllClosedSale', 
    {params: {
      directSaleProgramId,
      startWeek,
      endWeek,
      year
    }})
  }

  removeDetail(importOrderId: any, importDetailId: any): Observable<any> {
    return this._http.put<any>(Routes.importOrderApi + '/RemoveDetail', {}, {params: {importOrderId, importDetailId}})
  }

  confirmOrder(importOrderId: any, qualityControlCompanyId: any): Observable<any> {
    return this._http.put<any>(Routes.importOrderApi + '/ConfirmOrder', {}, {params: {importOrderId, qualityControlCompanyId}})
  }

  create(request: any): Observable<ImportOrderInformation[]> {
    return this._http.post<any>(Routes.importOrderApi + '/Create', request)
  }

  moveDetail(importOrderId: any, importDetailId: any): Observable<any> {
    return this._http.put<any>(Routes.importOrderApi + '/MoveDetail', {}, {params: {importOrderId, importDetailId}})
  }

  getAllByExporter(): Observable<ImportOrderListItem[]> {
    return this._http.get<ImportOrderListItem[]>(Routes.importOrderApi + '/GetAllByExporter')
  }

  getById(guid: any): Observable<ImportOrderInformation> {
    return this._http.get<ImportOrderInformation>(Routes.importOrderApi + '/GetById', {params: {guid}})
  }

  getAllByStatus(status: string): Observable<ImportOrderInformation[]> {
    return this._http.get<ImportOrderInformation[]>(Routes.importOrderApi + '/GetAllByStatus', {params: {status}})
  }

  confirmPurchase(confirmPurchaseRequest: ImportOrderConfirmPurchaseRequest): Observable<any> {
    return this._http.put<any>(Routes.importOrderApi + '/ConfirmPurchase', confirmPurchaseRequest)
  }

  getAllClosedSaleByImporter(startWeek: number, endWeek: number, year: number, directSaleProgramId: number): Observable<ClosedSaleWeek[]> {
    return this._http.get<ClosedSaleWeek[]>(Routes.importOrderApi + '/GetAllClosedSaleByImporter', {params: {startWeek, endWeek, year, directSaleProgramId}})
  }

  getAllClosedSaleByExporter(): Observable<ImportOrderInformation[]> {
    return this._http.get<ImportOrderInformation[]>(Routes.importOrderApi + '/GetAllClosedSaleByExporter')
  }

  updateClosedSaleWeek(request: ClosedSaleWeekRequest): Observable<any> {
    return this._http.put<any>(Routes.importOrderApi + '/UpdateClosedSaleWeek', request)
  }

  getAllForFrozenFruitShoppingCart(): Observable<ImportOrderContainerItem[]> {
    return this._http.get<ImportOrderContainerItem[]>(Routes.importOrderApi + '/GetAllForFrozenFruitShoppingCart')
  }

  getAllMyLoads(): Observable<LoadOrderListItem[]> {
    return this._http.get<LoadOrderListItem[]>(Routes.importOrderApi + '/GetAllMyLoads')
  }
  
  addImportoOrderToProgramWeek(request :AddImportoOrderToProgramWeek): Observable<ImportOrder[]>
  {
    return this._http.post<ImportOrder[]>(
      Routes.importOrderApi + '/AddImportoOrderToProgramWeek',
      request
    )
  }
  
  assingForwarder(orderId: string, companyId: number): Observable<any> {
    const assignData: AssignOrderCompanyRequest = {
      orderId,
      companyId,
      companyType: 0
    }
    return this._http.put<any>(Routes.importOrderApi + '/AssignOrderCompany', assignData);
  }
  
  assingCustomAgent(orderId: string, companyId: number): Observable<any> {
    const assignData: AssignOrderCompanyRequest = {
      orderId,
      companyId,
      companyType: 1
    }
    return this._http.put<any>(Routes.importOrderApi + '/AssignOrderCompany', assignData);
  }
  
  getReviewMessages(importOrderId: string): Observable<ImportOrderReviewMessage[]> {
    return this._http.get<ImportOrderReviewMessage[]>(Routes.importOrderReviewMessageApi + '/GetByImportOrder', {params: {importOrderId}})
  }

  addReviewMessages(request: ImportOrderReviewMessageCreate): Observable<any> {
    return this._http.post<any>(Routes.importOrderReviewMessageApi + '/Create', request)
  }
  
  getMbOrders(): Observable<ForwarderOrderListItem[]> {
    return this._http.get<ForwarderOrderListItem[]>(Routes.importOrderApi + '/GetMbOrders')
  }

  // getForwarderOrderById(guid: any): Observable<ImportOrderListItem> {
  //   return this._http.get<ImportOrderListItem>(Routes.importOrderApi + '/GetForwarderOrderById', {params: {guid}})
  // }

  updateImportAndDetailMbl(basicForwarderOffer: EditBasicOffer): Observable<any> {
    return this._http.put<any>(Routes.importOrderApi + '/UpdateImportAndDetailMbl', basicForwarderOffer)
  }

  getForwarderOrderById(guid: any): Observable<ImportOrderInformation> {
    return this._http.get<ImportOrderInformation>(Routes.importOrderApi + '/GetForwarderOrderById', {params: {guid}})
  }

  getAllByImporterMbl(): Observable<ImportOrderListItem[]>{
    return this._http.get<ImportOrderListItem[]>(Routes.importOrderApi + '/GetAllByImporterMbl')
  }

  getAllByExporterMbl(): Observable<ImportOrderListItem[]>
  {
    return this._http.get<ImportOrderListItem[]>(Routes.importOrderApi + '/GetAllByExporterMbl')
  }

  getAllByPendingApprovedAdmin(): Observable<ImportOrderInformation[]> {
    return this._http.get<ImportOrderInformation[]>(Routes.importOrderApi + '/GetAllByPendingPaymentApproved')
  }

  cancelPurchase(importOrderId: string): Observable<any> {
    return this._http.put<any>(Routes.importOrderApi + '/CancelOrder', {}, {params: {importOrderId}})
  }
}
