import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { DeliveryGateway } from 'src/app/application/gateway/DeliveryGateway';
import { Delivery } from 'src/app/domain/models/Delivery';
import { BasicUser } from 'src/app/domain/models/User';
import { Routes } from 'src/app/infrastructure/routes';

@Component({
  selector: 'app-payments-page',
  templateUrl: './payments-page.component.html',
  styleUrls: ['./payments-page.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class PaymentsPageComponent implements OnInit {
  breadcrumbMenuItems: MenuItem[] = []
  breadcrumbHome: MenuItem = {}

  importOrderId: any | undefined
  profile: BasicUser | undefined

  constructor(private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _authService: AuthGateway,
    private _messageService: MessageService,
    private _deliveryService: DeliveryGateway,
    public translate: TranslateService) {

    this._activatedRoute.params.subscribe(params => {
      this.importOrderId = params['importOrderId']
    })
  }

  ngOnInit(): void {
    this.profile = this._authService.getUser()
    let breadcrumbMenuItemLabel1 = 'Import order detail'
    let breadcrumbMenuItemLabel2 = 'Payment registry'
    let breadcrumbHomeItemLabel = 'My import orders'

    if(this.profile) {
      switch(this.profile.lang) {
        case 'en':
          breadcrumbMenuItemLabel1 = 'Import order detail'
          breadcrumbMenuItemLabel2 = 'Payment registry'
          breadcrumbHomeItemLabel = 'My import orders'
          break
        case 'es':
          breadcrumbMenuItemLabel1 = 'Detalle de orden de importación'
          breadcrumbMenuItemLabel2 = 'Registro de pago'
          breadcrumbHomeItemLabel = 'Ordenes de importación'
          break
      }
    }

    this.breadcrumbMenuItems = [
      {label: breadcrumbMenuItemLabel1, command: () => { this.goToImportOrderPage() }},
      {label: breadcrumbMenuItemLabel2, disabled: true}
    ]
    this.breadcrumbHome = {label: breadcrumbHomeItemLabel, routerLink: ['/' + Routes.userImportOrdersCurrent]}
  }

  goToImportOrderPage() {
    if(this.importOrderId) {
      this._router.navigate(['/' + Routes.userOrderDetailPage, this.importOrderId])
    }
  }
}
