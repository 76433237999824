import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { NewProductCertificate, ProductCertificate } from "src/app/domain/models/ProductCertificate";

@Injectable({providedIn: 'root'})
export abstract class ProductCertificateGateway {
  abstract getAll(): Observable<Array<ProductCertificate>>
  abstract getAllByUsername(): Observable<Array<ProductCertificate>>
  abstract getById(id: number): Observable<ProductCertificate>
  abstract create(productCertificate: NewProductCertificate): Observable<ProductCertificate>
  abstract update(productCertificate: ProductCertificate): Observable<ProductCertificate>
  abstract delete(id: number): Observable<ProductCertificate>
  abstract getAllByExportOfferId(exportOfferId: number): Observable<Array<ProductCertificate>>
}
