<p-dialog 
  [(visible)]="isVisible" 
  (onHide)="afterHide()" 
  position="top-right" 
  class="notification-container" 
  [modal]="true" 
  [draggable]="false" 
  [resizable]="false"
  [style]="{ 
    width: '30rem', 
    marginTop: '100px', 
    height: 'calc(100vh - 140px)' 
  }"
>
  <ng-template pTemplate="header">
    <div class="inline-flex align-items-center justify-content-center gap-1">
      <span class="font-bold white-space-nowrap">
        {{ 'UserMenuComponent.NotificationsMenuItems.NotificationsLabel' | translate }}
      </span>
      <p-button icon="pi pi pi-fw pi-cog" 
        pTooltip="{{'UserMenuComponent.NotificationsMenuItems.NotificationConfigLabel' | translate}}" 
        tooltipPosition="bottom" 
        badgeClass="p-badge-warning" 
        styleClass="p-button-text p-button-secondary p-button-md"
        (onClick)="goToConfiguration()"
      ></p-button>
    </div>
  </ng-template>

  <ng-template pTemplate="content">
    <div *ngFor="let notification of notifications">
      <div class="grid justify-content-start align-items-center line-height-1 notification-item"
        [ngClass]="notification.status === 0 ? 'active-notification' : 'inactive-notification'"
      >
        <div class="col-12">
          <div class="flex align-items-center">
            <div class="notification-status flex"
              [ngClass]="notification.status === 0 ? 'active-icon' : 'inactive-icon'"
            ></div>
            <div class="flex pl-2">
              <span class="notification-title">{{ notification.titleEs }}</span>
            </div>
          </div>
        </div>
        <div class="col-9 pl-4">
          <span class="notification-description">{{ notification.descriptionEs }}</span>
        </div>
        <div class="col-3">
          <p-button 
            class="see-more" 
            label="{{'UserPages.Notifications.UserNotifications.SeeMoreButton' | translate}}"
            (onClick)="goToNotificationDetail(notification)"
          />
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <div class="justify-content-start text-left footer">
      <p-button styleClass="p-button-link" class="all-notifications-button" (onClick)="goToAllNotifications()">
        {{'UserPages.Notifications.UserNotifications.SeeAllNotifications' | translate}}
      </p-button>
    </div>
  </ng-template>
</p-dialog>

<p-messages></p-messages>