<app-user-dashboard [fullScreen]="true" [userMenuActiveItemIndex]="0" [activeItem]="'marketplace'">
  <div class="bg-container">
    <p-tabView (onChange)="switchHeaders($event);" [(activeIndex)]="tabIndex" [style]="{'background-color': '#F4F4F4'}">
      <p-tabPanel #fruitStand header="Fresh fruit">
          <div class="col-12">
            <p-panel>
              <div class="grid">
                <div class="col-12 md:col-6 lg:col-6">
                  <form [formGroup]="portFormGroup">
                    <div class="p-fluid grid">
                      <div class="col-12 md:col-4 lg:col-4">
                          <p-dropdown
                            [options]="originCountries"
                            formControlName="countryFromId"
                            optionLabel="name"
                            optionValue="id"
                            (onChange)="loadExportOffers()"
                            (onClear)="clearSpotSearchParams()"
                            [filter]="true"
                            filterBy="name"
                            [style]="{'height': '40px', 'background-color': '#F4F4F4', border:'1px solid #DDDDDD'}"
                            [showClear]="true"
                            placeholder="{{'MarketplacePage.Spot.ImportFromFieldPlaceholder' | translate}}"
                            class="p-inputtext-sm">
                            <ng-template pTemplate="selectedItem">
                              <div class="flex align-items-center gap-2" *ngIf="selectedCountryFrom">
                                <img [src]="'../../../../assets/img/flags/' + selectedCountryFrom.code + '.png'" style="width: 18px" alt="flag"/>
                                <div style="font-size:12px">{{ selectedCountryFrom | countryDescription }}</div>
                              </div>
                            </ng-template>
                            <ng-template let-country pTemplate="item">
                              <div class="flex align-items-center gap-2">
                                <img [src]="'../../../../assets/img/flags/' + country.code + '.png'" style="width: 12px" alt="flag"/>
                                <div style="font-size:12px" >{{ country | countryDescription }}</div>
                              </div>
                            </ng-template>
                          </p-dropdown>
                      </div>
                      <div class="col-12 md:col-4 lg:col-4">
                          <p-dropdown
                            [options]="destinationCountries"
                            formControlName="countryToId"
                            optionLabel="name"
                            optionValue="id"
                            (onChange)="loadDestinationPorts()"
                            [style]="{'height': '40px' , 'background-color': '#F4F4F4', border:'1px solid #DDDDDD'}"
                            [filter]="true"
                            filterBy="name"
                            [showClear]="false"
                            placeholder="{{'MarketplacePage.Spot.ImportToFieldPlaceholder' | translate}}"
                            class="p-inputtext-sm">
                            <ng-template pTemplate="selectedItem">
                              <div class="flex align-items-center gap-2" *ngIf="selectedCountryTo">
                                <img [src]="'../../../../assets/img/flags/' + selectedCountryTo.code + '.png'" style="width: 18px" alt="flag"/>
                                <div style="font-size:12px">{{ selectedCountryTo | countryDescription }}</div>
                              </div>
                            </ng-template>
                            <ng-template let-country pTemplate="item">
                              <div class="flex align-items-center gap-2">
                                <img [src]="'../../../../assets/img/flags/' + country.code + '.png'" style="width: 18px" alt="flag"/>
                                <div style="font-size:12px"> {{ country | countryDescription }}</div>
                              </div>
                            </ng-template>
                          </p-dropdown>
                      </div>
                      <div class="col-12 md:col-4 lg:col-4">
                          <p-dropdown
                            [options]="destinationPorts"
                            formControlName="portToId"
                            optionLabel="name"
                            optionValue="id"
                            (onChange)="loadEstimations()"
                            [filter]="true"
                            filterBy="name"
                            [style]="{'height': '40px' , 'background-color': '#F4F4F4', border:'1px solid #DDDDDD'}"
                            [showClear]="false"
                            placeholder="{{'MarketplacePage.Spot.ToPortFieldLabel' | translate}}"
                            emptyMessage="{{'MarketplacePage.Spot.EmptyResultsText' | translate}}"
                            class="p-inputtext-sm"></p-dropdown>
                          <!-- <label style="font-size:12px">{{'MarketplacePage.Spot.ToPortFieldPlaceholder' | translate}}</label> -->
                      </div>
                      <div class="col-12 md:col-4 lg:col-4">
                        <p-fieldset [style]="{'font-size': '13px', 'padding': '0px', 'color': 'gray'}" legend="{{'MarketplacePage.Spot.NavyListLabel' | translate}}">
                          <ng-container *ngFor="let e of estimations">
                            <p  class="estimation-text">{{e.shippingCompany.name}}</p>
                          </ng-container>
                        </p-fieldset>
                      </div>
                      <div class="col-12 md:col-4 lg:col-4">
                        <p-fieldset  [style]="{'font-size': '12px', 'text-color': 'gray', 'padding': '0px'}" legend="{{'MarketplacePage.Spot.TimeInTransitListLabel' | translate}}">
                          <ng-container *ngFor="let e of estimations;  let i = index">
                            <p  class="estimation-text">{{e.transitTime}} <p-tag [style]="{'font-size': '9px'}" *ngIf="i == 0" severity="success" value="Best Deal"></p-tag></p>
                          </ng-container>
                        </p-fieldset>
                      </div>
                      <div class="col-12 md:col-4 lg:col-4">
                        <p-fieldset  [style]="{'font-size': '12px', 'color': 'gray', 'padding': '0px'}" legend="Ocean Freight">
                          <ng-container *ngFor="let e of estimations;  let i = index">
                            <p class="estimation-text" >{{e.rate | currency: 'USD':'US$'}} <p-tag [style]="{'font-size': '9px'}" *ngIf="i == 0" severity="success"
                                value="Best Deal"></p-tag>
                            </p>
                          </ng-container>
                        </p-fieldset>
                      </div>
                    </div>
                  </form>
                </div>

                <div class="col-12 md:col-6 lg:col-6">
                  <div class="p-fluid grid">
                    <div class="col-12 md:col-8 lg:col-8">
                      <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input style="font-size: 12px" type="text" pInputText (input)="filterGlobal($event)"
                          placeholder="{{'MarketplacePage.Spot.SearchProducts' | translate}}" class="p-inputtext-sm" [style]="{'height': '40px', 'background-color': '#F4F4F4', border:'1px solid #DDDDDD'}" />
                      </span>
                    </div>
                    <div class="col-12 md:col-4 lg:col-4">
                        <p-dropdown [(ngModel)]="viewType" [options]="viewTypeOptions" optionLabel="label" placeholder="{{'MarketplacePage.Spot.ViewLikeLabel' | translate}}"
                          optionValue="value" class="p-inputtext-sm" [style]="{'height': '40px', 'background-color': '#F4F4F4', border:'1px solid #DDDDDD'}"></p-dropdown>
                    </div>
                    <div class="col-12 md:col-8 lg:col-8"></div>
                    <div class="col-12 md:col-4 lg:col-4"></div>
                    <div class="col-12 md:col-8 lg:col-8"></div>
                    <div class="col-12 md:col-4 lg:col-4 justify-content-center">
                      <button icon="pi pi-shopping-cart" pButton pRipple (click)="goToOrders()"
                        style="background-color: #8c2b87;"
                        label="{{'MarketplacePage.Spot.OrderContainers' | translate}}"
                        *ngIf="profile?.profileType === 'Importer'"[style]="{'height': '40px', 'font-size': '12px'}"></button>
                    </div>
                  </div>
                </div>
              </div>
            </p-panel>
              <p-panel [style]="{'margin-top': '20px'}">
                <div class="col-12">
                  <div id="gridView" *ngIf="viewType == 'grid'" >
                    <p-table #dt1
                      [value]="offers"
                      dataKey="exportOffer.id"
                      [paginator]="true"
                      [rows]="10"
                      [showCurrentPageReport]="true"
                      [rowsPerPageOptions]="[10, 25, 50]"
                      [loading]="loading"
                      [globalFilterFields]="['exportOffer.variety.species.name', 'exportOffer.variety.name', 'exportOffer.speciesSize', 'exportOffer.fridgeStorage.contactCity']"
                      responsiveLayout="stack"
                      [breakpoint]="'600px'">
                      <ng-template pTemplate="header">
                        <tr [ngClass]="'marketplace-table'">
                          <th style="text-align:-webkit-center">
                            <div class="flex align-items-center title-column">
                              {{'MarketplacePage.Spot.MarketPlaceTable.CountryHeader' | translate}}
                            </div>
                          </th>
                          <th style="text-align:-webkit-center">
                            <div class="flex align-items-center title-column">
                              {{'MarketplacePage.Spot.MarketPlaceTable.SpecieHeader' | translate}}
                              <p-columnFilter field="exportOffer.variety.species.nameEs" matchMode="in" display="menu"
                                [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                                <ng-template pTemplate="header">
                                  <div class="px-3 pt-3 pb-0">
                                    <span class="font-bold">{{'MarketplacePage.Spot.MarketPlaceTable.SelectSpecieText' |
                                      translate}}</span>
                                  </div>
                                </ng-template>
                                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                  <p-multiSelect [options]="filterSpecies" [ngModel]="value"
                                    placeholder="{{'MarketplacePage.Spot.MarketPlaceTable.SelectText' | translate}}"
                                    (onChange)="filter($event.value)" optionValue="nameEs"
                                    optionLabel="nameEs"></p-multiSelect>
                                </ng-template>
                              </p-columnFilter>
                            </div>
                          </th>
                          <th style="text-align:left" pSortableColumn="exportOffer.variety.name">
                            <div class="flex align-items-center title-column">
                              {{'MarketplacePage.Spot.MarketPlaceTable.VarietyHeader' | translate}}
                              <p-columnFilter field="exportOffer.variety.name" matchMode="in" display="menu"
                                [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                                <ng-template pTemplate="header">
                                  <div class="px-3 pt-3 pb-0">
                                    <span class="font-bold">{{'MarketplacePage.Spot.MarketPlaceTable.SelectVarietyText' |
                                      translate}}</span>
                                  </div>
                                </ng-template>
                                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                  <p-multiSelect [options]="filterVarieties" [ngModel]="value"
                                    placeholder="{{'MarketplacePage.Spot.MarketPlaceTable.SelectText' | translate}}"
                                    (onChange)="filter($event.value)" optionValue="name" optionLabel="name"></p-multiSelect>
                                </ng-template>
                              </p-columnFilter>
                              <p-sortIcon field="exportOffer.variety.name"></p-sortIcon>
                            </div>
                          </th>
                          <th style="text-align:-webkit-center" pSortableColumn="exportOffer.speciesSize">
                            <div class="flex align-items-center title-column">
                              {{'MarketplacePage.Spot.MarketPlaceTable.SizeHeaderHeader' | translate}}
                              <p-columnFilter type="text" field="exportOffer.speciesSize" display="menu"
                                [showAddButton]="false" [showMatchModes]="false" [showOperator]="false"
                                [matchMode]="'contains'"></p-columnFilter>
                              <p-sortIcon field="exportOffer.speciesSize"></p-sortIcon>
                            </div>
                          </th>
                          <th style="text-align:-webkit-center">
                            <div class="flex align-items-center title-column">
                              {{'MarketplacePage.Spot.MarketPlaceTable.QualityControlHeader' | translate}}
                              <p-columnFilter type="boolean" field="exportOffer.isQualityControl" display="menu"
                                [showAddButton]="false" [showMatchModes]="false" [showOperator]="false"></p-columnFilter>
                            </div>
                          </th>
                          <th style="text-align:-webkit-center" pSortableColumn="exportOffer.harvestDate">
                            <div class="flex align-items-center title-column">
                              {{'MarketplacePage.Spot.MarketPlaceTable.HarvestDateHeader' | translate}}
                              <p-columnFilter type="date" field="exportOffer.HarvestDateHeader" [locale]="locale"
                                display="menu" [showAddButton]="false" [showMatchModes]="false"
                                [showOperator]="false"></p-columnFilter>
                              <p-sortIcon field="exportOffer.harvestDate"></p-sortIcon>
                            </div>
                          </th>
                          <th style="text-align:left">
                            <div class="flex align-items-center title-column">
                              {{'MarketplacePage.Spot.MarketPlaceTable.LocationHeader' | translate}}
                              <p-columnFilter type="text" field="exportOffer.fridgeStorage.contactCountry.name" display="menu"
                                [showAddButton]="false" [showMatchModes]="false" [showOperator]="false"
                                [matchMode]="'contains'"></p-columnFilter>
                            </div>
                          </th>
                          <th style="text-align:-webkit-center">
                            <div class="flex align-items-center title-column">
                              {{'MarketplacePage.Spot.MarketPlaceTable.CasePerPalletsHeader' | translate}}
                            </div>
                          </th>
                          <th style="text-align:-webkit-center" pSortableColumn="exportOffer.listPrice">
                            <div class="flex align-items- title-column">
                              {{'MarketplacePage.Spot.MarketPlaceTable.ListPrice' | translate}}
                              <p-sortIcon field="exportOffer.listPrice"></p-sortIcon>
                            </div>
                          </th>
                          <th style="text-align:-webkit-center" pSortableColumn="exportOffer.availablePallets">
                            <div class="flex align-items-center title-column">
                              {{'MarketplacePage.Spot.MarketPlaceTable.AvailablePalletsHeader' | translate}}
                              <p-sortIcon field="exportOffer.availablePallets"></p-sortIcon>
                            </div>
                          </th>
                          <th style="text-align:-webkit-center">
                            <div class="flex align-items-center title-column">
                              {{'MarketplacePage.Spot.MarketPlaceTable.PalletRequiredHeader' | translate}}
                            </div>
                          </th>
                          <th style="text-align:-webkit-center">
                            <div class="flex align-items-center title-column">
                              {{'MarketplacePage.Spot.MarketPlaceTable.TotalPriceHeader' | translate}}
                            </div>
                          </th>
                          <th style="text-align:-webkit-center" colspan="1">
                            <div class="flex align-items-center title-column">
                              {{'MarketplacePage.Spot.MarketPlaceTable.OperationHeader' | translate}}
                            </div>
                          </th>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-offer >
                        <tr [pSelectableRow]="offer" [ngClass]="'marketplace-table'" class="row-table">
                          <td style="text-align:center" [ngClass]="'td-table'"><img [src]="'../../../../assets/img/flags/' + offer.exportOffer.fridgeStorage?.contactCountry.code + '.png'" height="40px" alt=""/></td>
                          <td style="text-align:center">
                            <img  [src]="offer.exportOffer.variety.urlIcon" height="50px" width="50px" [style]="{'padding': '2px', 'border-radius': '7px'}" alt=""/>
                          </td>
                          <td style="text-align:left">{{offer.exportOffer.variety.name}}</td>
                          <td style="text-align:center">{{offer.exportOffer.speciesSize}}</td>
                          <td style="text-align:center">{{offer.exportOffer.isQualityControl ? 'Si' : 'No'}}</td>
                          <td style="text-align:center">{{offer.exportOffer.harvestDate | date: 'dd-MM-yyyy'}}</td>
                          <td style="text-align:left">{{offer.exportOffer.fridgeStorage?.contactCity ? offer.exportOffer.fridgeStorage?.contactCity +
                            ', ' + (offer.exportOffer.fridgeStorage?.contactCountry | countryDescription) : ''}}</td>
                          <td style="text-align:center">{{offer.exportOffer.caseQuantityPerPallet}}</td>
                          <td style="text-align:center"><div>{{offer.exportOffer.listPrice | currency: 'USD':'US$'}}</div>
                              <div>
                                {{offer.exportOffer.typeSale}}
                              </div>
                            </td>
                          <td style="text-align:center">{{offer.exportOffer.availablePallets}}</td>
                          <td style="text-align:center">
                              <div style="width: 100%;">
                                <p-inputNumber [(ngModel)]="offer.requiredPallets" [showButtons]="false" [min]="0"
                                [max]="offer.exportOffer.availablePallets" [size]="2" buttonLayout="horizontal"
                                spinnerMode="horizontal" [step]="1" (onInput)="getTotalPrice(offer, $event)"
                                (onBlur)="getTotalPrice(offer, $event)"
                                class="p-input-table"
                                [inputStyle]="{'height': '10px', 'width': '55px', 'font-size': '12px'}"
                                [disabled]="profile?.profileType === 'Exporter' || portFormGroup.controls['countryFromId'].invalid || portFormGroup.controls['portToId'].invalid || portFormGroup.controls['countryToId'].invalid"></p-inputNumber>
                              </div>
                          </td>
                          <td style="text-align:center"><span style="font-weight: bold;">{{offer.totalPrice | currency: 'USD':'US$'}}</span></td>
                          <td style="text-align:center">
                            <div class="flex justify-content-center gap-1">
                              <p-button icon="pi pi-cart-plus" [pTooltip]="'MarketplacePage.Spot.MarketPlaceTable.TooltipCar' | translate"
                              (onClick)="createOrderFromList(offer)"
                              class="button-table"

                              [style]="{'height': '35px',  'width':'40px'}"
                              [disabled]="profile?.profileType === 'Exporter' || portFormGroup.controls['countryFromId'].invalid ||
                              portFormGroup.controls['portToId'].invalid ||
                              portFormGroup.controls['countryToId'].invalid ||
                              !offer.allowPurchase
                              "></p-button>

                              <p-button icon="pi pi-eye" [pTooltip]="'MarketplacePage.Spot.MarketPlaceTable.TooltipSeeDetail' | translate" tooltipPosition="left"
                              class="button-table"
                              (onClick)="showOfferDetailDialog2(offer)"
                              [style]="{'height': '35px', 'width':'40px'}"
                              [disabled]="portFormGroup.controls['countryFromId'].invalid || portFormGroup.controls['portToId'].invalid || portFormGroup.controls['countryToId'].invalid"></p-button>
                            </div>
                          </td>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="emptymessage">
                        <tr>
                          <td colspan="18">{{'MarketplacePage.Spot.MarketPlaceTable.EmptyResultsText' | translate}}</td>
                        </tr>
                      </ng-template>
                    </p-table>
                  </div>
                </div>
                <div id="cardView" *ngIf="viewType == 'cards'">
                  <p-dataView #dvViewCards [value]="offers" layout="grid" [rows]="20" [loading]="loading" [paginator]="true"
                    emptyMessage="{{'MarketplacePage.Spot.MarketPlaceTable.EmptyResultsText' | translate}}"
                    [sortField]="sortField" [sortOrder]="sortOrder" [loading]="loading">
                    <ng-template pTemplate="header">
                      <div class="flex flex-column md:flex-row md:justify-content-between">
                        <p-dropdown [options]="sortOptions" placeholder="Ordenar por" (onChange)="onSortChange($event)"
                          styleClass="mb-2"></p-dropdown>
                      </div>
                    </ng-template>
                    <ng-template let-offer pTemplate="gridItem">
                      <div class="col-12 sm:col-6 lg:col-12 xl:col-4 p-2">
                        <div class="p-4 border-1 surface-border surface-card border-round">
                          <div class="flex flex-column">
                            <div class="flex justify-content-center" style="font-size: 2em; font-weight: 700;">
                              {{offer.exportOffer.variety.species.name.toUpperCase()}}</div>
                            <div class="flex justify-content-center"
                              style="font-size: 1.5em; font-weight: 400; color: #0009; margin-bottom: 10px;">
                              {{offer.exportOffer.variety.name.toUpperCase()}}</div>
                          </div>
                          <img class="w-9 sm:w-16rem xl:w-16rem shadow-2 block xl:block mx-auto border-round" [src]="getExportOfferImageUrl(offer, 0)" alt=""/>
                          <div class="grid" style="margin-top: 10px;">
                            <div class="col-6">{{'MarketplacePage.Spot.MarketPlaceCards.HarvestDateText' | translate}}:
                            </div>
                            <div class="col-6">{{offer.exportOffer.harvestDate | date: 'dd-MM-yyyy'}}</div>

                            <div class="col-6">Shelf life:</div>
                            <div class="col-6">{{offer.exportOffer.shelfLife}} días</div>

                            <div class="col-6">{{'MarketplacePage.Spot.MarketPlaceCards.ColorText' | translate}}:</div>
                            <div class="col-6">{{getSpeciesColor(offer.exportOffer.color)}}</div>

                            <div class="col-6">{{'MarketplacePage.Spot.MarketPlaceCards.KilosPerBoxText' | translate}}:
                            </div>
                            <div class="col-6">{{offer.exportOffer.kilosPerBox}}</div>

                            <div class="col-6">{{'MarketplacePage.Spot.MarketPlaceCards.SpeciesSizeText' | translate}}:
                            </div>
                            <div class="col-6">{{offer.exportOffer.speciesSize}}</div>

                            <div class="col-6">{{'MarketplacePage.Spot.MarketPlaceCards.AvailablePalletsText' | translate}}:
                            </div>
                            <div class="col-6">{{offer.exportOffer.availablePallets}}</div>

                            <div class="col-6 flex align-items-center">
                              {{'MarketplacePage.Spot.MarketPlaceCards.ListPriceText' | translate}}:</div>
                            <div class="col-6">
                              <span
                                style="font-size: 1.5rem; color: #8c2b87; font-weight: bold;">{{offer.exportOffer.listPrice
                                | currency:'USD':'US$' }}</span>
                            </div>
                          </div>
                          <div class="flex justify-content-center align-items-center" style="margin-top: 10px;" >
                            <p-button label="{{'MarketplacePage.Spot.MarketPlaceCards.PurchaseNowButton' | translate}}"
                              (onClick)="showOfferDetailDialog2(offer)"></p-button>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </p-dataView>
                </div>
              </p-panel>
            </div>

      </p-tabPanel>

        <p-tabPanel #wishList header="{{'MarketplacePage.WishList.TabPanelHeader' | translate}}" [style]="{'background-color': '#F4F4F4'}" >
          <div class="col-12">
            <p-panel>
              <form [formGroup]="importRequestForm">
                <div class="grid p-fluid">
                  <div class="col-12 md:col-2 lg:col-2">
                      <p-dropdown class="p-dropdown"
                        [options]="species"
                        formControlName="speciesId"
                        optionLabel="nameEs"
                        optionValue="id"
                        [style]="{'height': '40px', 'background-color': '#F4F4F4' }"
                        (onChange)="loadVarieties()"
                        [filter]="true"
                        filterBy="name"
                        [showClear]="true"
                        placeholder="{{'MarketplacePage.WishList.EspecieFieldLabel' | translate}}" class="p-inputtext-sm">
                        <ng-template pTemplate="selectedItem">
                          <div class="flex align-items-center gap-2" *ngIf="selectedSpecies">
                              <img [src]="selectedSpecies.imageUrl" style="width: 18px" alt=""/>
                              <div  style="font-size:12px">{{ getSpeciesName(selectedSpecies) }}</div>
                          </div>
                        </ng-template>
                        <ng-template let-specie pTemplate="item">
                            <div class="flex align-items-center gap-2">
                                <img [src]="specie.imageUrl" style="width: 18px" alt=""/>
                                <div  style="font-size:12px">{{ getSpeciesName(specie) }}</div>
                            </div>
                        </ng-template>
                      </p-dropdown>
                  </div>
                  <div class="col-12 md:col-2 lg:col-2">
                      <p-dropdown class="p-dropdown"
                        [options]="varieties"
                        formControlName="varietyId"
                        optionLabel="name"
                        optionValue="id"
                        [filter]="true"
                        [style]="{'height': '40px', 'font-size': '12px', 'background-color': '#F4F4F4' }"
                        filterBy="name"
                        [showClear]="true"
                        placeholder="{{'MarketplacePage.WishList.VarietyFieldLabel' | translate}}"
                        class="p-inputtext-sm"
                        (onChange)="onChangeVariety()">
                        <ng-template pTemplate="selectedItem">
                          <div class="flex align-items-center gap-2" *ngIf="selectedVariety">
                            <img [src]="selectedVariety.urlIcon" style="width: 18px"/>
                            <div  style="font-size:12px">{{ selectedVariety.name }}</div>
                          </div>
                        </ng-template>
                        <ng-template let-variety pTemplate="item">
                            <div class="flex align-items-center gap-2">
                                <img [src]="variety.urlIcon" style="width: 18px"/>
                                <div  style="font-size:12px">{{ variety.name }}</div>
                            </div>
                        </ng-template>
                      </p-dropdown>
                  </div>
                  <div class="col-12 md:col-2 lg:col-2">

                      <p-inputNumber id="week" formControlName="week" [showButtons]="true" [useGrouping]="false" [min]="1"
                        [max]="52" placeholder="{{'MarketplacePage.WishList.WeekFieldPlaceholder' | translate}}"
                        [style]="{'height': '40px', 'background-color': '#F4F4F4'}"
                        class="p-inputtext-sm" />
                  </div>
                  <div class="col-12 md:col-2 lg:col-2">
                      <p-dropdown  class="p-inputtext-sm"
                        [options]="originCountriesOptions"
                        formControlName="countryFromId"
                        optionLabel="name"
                        optionValue="id"
                        [filter]="true"
                        filterBy="name"
                        [style]="{'height': '40px', 'font-size': '12px', 'background-color': '#F4F4F4' }"
                        [showClear]="true"
                        (onChange)="onChangeImportRequestCountryFrom()">
                        <ng-template pTemplate="selectedItem">
                          <div class="flex align-items-center gap-2" *ngIf="selectedImportRequestCountryFrom">
                            <img [src]="'../../../../assets/img/flags/' + selectedImportRequestCountryFrom.code + '.png'"
                              style="width: 18px" alt=""/>
                            <div  style="font-size:12px">{{ selectedImportRequestCountryFrom | countryDescription }}</div>
                          </div>
                          <div class="flex align-items-center gap-2" *ngIf="!selectedImportRequestCountryFrom">
                            <img [src]="'../../../../assets/img/flags/WORLD.png'"
                              style="width: 18px" alt=""/>
                            <div  style="font-size:12px">{{ allCountriesText }}</div>
                          </div>
                        </ng-template>
                        <ng-template let-country pTemplate="item">
                          <div class="flex align-items-center gap-2">
                            <img [src]="'../../../../assets/img/flags/' + country.code + '.png'"
                              style="width: 18px" alt=""/>
                            <div>{{ country | countryDescription }}</div>
                          </div>
                        </ng-template>
                      </p-dropdown>
                  </div>
                  <div class="col-12 md:col-2 lg:col-2">
                      <p-dropdown class="p-inputtext-sm"
                        [options]="destinationCountries"
                        formControlName="countryToId"
                        optionLabel="name"
                        optionValue="id"
                        (onChange)="loadImportRequests()"
                        [filter]="true"
                        filterBy="name"
                        [style]="{'height': '40px', 'font-size': '12px', 'background-color': '#F4F4F4' }"
                        [showClear]="true"
                        placeholder="{{'MarketplacePage.WishList.DestinationCountryFieldPlaceholder' | translate}}">
                        <ng-template pTemplate="selectedItem">
                          <div class="flex align-items-center gap-2" *ngIf="selectedImportRequestCountryTo">
                            <img [src]="'../../../../assets/img/flags/' + selectedImportRequestCountryTo.code + '.png'" style="width: 18px" alt=""/>
                            <div  style="font-size:12px">{{ selectedImportRequestCountryTo | countryDescription }}</div>
                          </div>
                        </ng-template>
                        <ng-template let-country pTemplate="item">
                          <div class="flex align-items-center gap-2">
                            <img [src]="'../../../../assets/img/flags/' + country.code + '.png'" style="width: 18px" alt=""/>
                            <div  style="font-size:12px">{{ country | countryDescription }}</div>
                          </div>
                        </ng-template>
                      </p-dropdown>
                  </div>
                  <div class="col-12 md:col-2 lg:col-2 flex align-items-center">
                    <div class="flex justify-content-start align-items-center gap-4">
                      <p-button icon="pi pi-search" pTooltip="{{'MarketplacePage.WishList.SearchButton' | translate}}"
                        (onClick)="loadImportRequests()" styleClass="p-button-sm"></p-button>
                      <p-button icon="pi pi-times" pTooltip="{{'MarketplacePage.WishList.CleanFilterButton' | translate}}"
                        (onClick)="clearImportRequestSearchParams()" styleClass="p-button-outlined p-button-sm"></p-button>
                    </div>
                  </div>
                </div>
              </form>
            </p-panel>
            <p-panel [style]="{'margin-top': '20px'}">
              <div class="col-12">
                <div id="gridViewImportRequest">
                  <p-table
                    #dt2
                    [value]="requests"
                    dataKey="importRequest.id"
                    [paginator]="true"
                    [rows]="10"
                    [showCurrentPageReport]="true"
                    [rowsPerPageOptions]="[10, 25, 50]"
                    [loading]="loadingImportRequest"
                    [scrollable]="true"
                    scrollHeight="50vh"
                    responsiveLayout="stack"
                    [breakpoint]="'600px'"
                    >
                    <ng-template pTemplate="header">
                      <tr class="marketplace-table">
                        <th>
                          <div class="flex justify-content-center aling-items-center">
                            {{'MarketplacePage.WishList.WishListTable.CountryHeader' | translate}}
                          </div>
                        </th>
                        <th>
                          <div class="flex justify-content-center aling-items-center">
                            {{'MarketplacePage.WishList.WishListTable.SpecieHeader' | translate}}
                          </div>
                        </th>
                        <th>
                          <div class="flex justify-content-center aling-items-center">
                            {{'MarketplacePage.WishList.WishListTable.VarietyHeader' | translate}}
                          </div>
                        </th>
                        <th>
                          <div class="flex justify-content-center align-items-center">
                            {{'MarketplacePage.WishList.WishListTable.SpeciesSizeHeader' | translate}}
                          </div>
                        </th>
                        <th>
                          <div class="flex justify-content-center align-items-center">
                            {{'MarketplacePage.WishList.WishListTable.WeekRangeHeader' | translate}}
                          </div>
                        </th>
                        <th>
                          <div class="flex justify-content-center align-items-center">
                            {{'MarketplacePage.WishList.WishListTable.IncotermsHeder' | translate}}
                          </div>
                        </th>
                        <th>
                          <div class="flex justify-content-center align-items-center">
                            {{'MarketplacePage.WishList.WishListTable.Netkilo' | translate}}
                          </div>
                        </th>
                        <th>
                          <div class="flex justify-content-center align-items-center">
                            {{'MarketplacePage.WishList.WishListTable.BoxTypeHeader' | translate}}
                          </div>
                        </th>
                        <th>
                          <div class="flex justify-content-center align-items-center">
                            {{'MarketplacePage.WishList.WishListTable.CasePerPalletsHeader' | translate}}
                          </div>
                        </th>
                        <th>
                          <div class="flex justify-content-center align-items-center">
                            {{'MarketplacePage.WishList.WishListTable.PalletsPeerWeekHeader' | translate}}
                          </div>
                        </th>
                        <th>
                          <div class="flex justify-content-center align-items-center">
                            {{'MarketplacePage.WishList.WishListTable.TotalPalletsHeader' | translate}}
                          </div>
                        </th>
                        <th>
                          <div class="flex justify-content-center align-items-center">
                            {{'MarketplacePage.WishList.WishListTable.TotalContainerHeader' | translate}}
                          </div>
                        </th>
                        <th>
                          <div class="flex justify-content-center align-items-center">
                            {{'MarketplacePage.WishList.WishListTable.PricePerCase' | translate}}
                          </div>
                        </th>
                        <th colspan="2">
                          <div class="flex justify-content-center align-items-center">
                            {{'MarketplacePage.WishList.WishListTable.ActionsHeader' | translate}}
                          </div>
                        </th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-request>
                      <tr [pSelectableRow]="request"  class="marketplace-table">
                        <td>
                          <div class="flex justify-content-center align-items-center">
                            <img [src]="'../../../../assets/img/flags/' + request.importRequest.country.code + '.png'"
                              height="48px" width="48px" alt=""/>
                          </div>
                        </td>
                        <td>
                          <div class="flex justify-content-center align-items-center">
                            <img [src]="request.importRequest.variety.urlIcon" width="50px" height="50px"
                              style="padding: 2px; border-radius: 7px;" alt=""/>
                          </div>
                        </td>
                        <td>
                          <div class="flex justify-content-center align-items-center">
                            {{request.importRequest.variety.name}}
                          </div>
                        </td>
                        <td>
                          <div class="flex justify-content-center align-items-center">
                            {{request.importRequest.speciesSize}}
                          </div>
                        </td>
                        <td>
                          <div class="flex justify-content-center align-items-center flex-column">
                            <p>{{ request.importRequest.fromWeek }} ({{request.importRequest.fromYearWeek }}) -
                              {{request.importRequest.toWeek}} ({{request.importRequest.toYearWeek }})</p>
                            <p style="margin: 0px;">{{getTotalWeeksImportRequest(request.importRequest)}} {{'MarketplacePage.WishList.WishListTable.WeekLabel' | translate }}</p>
                          </div>
                        </td>
                        <td>
                          <div class="flex justify-content-center align-items-center">
                            {{request.importRequest.typeSale}}
                          </div>
                        </td>
                        <td>
                          <div class="flex justify-content-center align-items-center">
                            {{request.importRequest.netKilos }}
                          </div>
                        </td>
                        <td>
                          <div class="flex justify-content-center align-items-center">
                            {{getBoxType(request.importRequest.boxType)}}
                          </div>
                        </td>
                        <td>
                          <div class="flex justify-content-center align-items-center">
                            {{request.importRequest.caseQuantityPerPallet}}
                          </div>
                        </td>
                        <td>
                          <div class="flex justify-content-center align-items-center">
                            {{request.importRequest.palletQuantity}}
                          </div>
                        </td>
                        <td>
                          <div class="flex justify-content-center align-items-center">
                            {{getTotalPalletsImportRequest(request.importRequest)}}
                          </div>
                        </td>
                        <td>
                          <div class="flex justify-content-center align-items-center">
                            {{getTotalContainersImportRequest(request.importRequest)}}
                          </div>
                        </td>
                        <td>
                          <div class="flex justify-content-center align-items-center">
                            {{request.importRequest.pricePerCase | currency: 'USD':'US$'}}
                          </div>
                        </td>
                        <td>
                          <div class="flex justify-content-center align-items-center">
                            <!--
                            <p-button
                            class="button-table"
                            styleClass="p-button-sm"
                            [style]="{'height': '35px', 'width':'40px'}"
                              icon="pi pi-cart-plus" pTooltip="{{'MarketplacePage.WishList.WishListTable.ButtonModal' | translate}}"
                              tooltipPosition="left" (onClick)="showImportRequestDialog(request)"></p-button>
                            -->
                            <p-button
                            *ngIf="request.importRequest.guid"
                            class="button-table"
                            styleClass="p-button-sm"
                            [style]="{'height': '35px', 'width':'40px'}"
                              icon="pi pi-cart-plus" pTooltip="{{'MarketplacePage.WishList.WishListTable.ButtonModal' | translate}}"
                              tooltipPosition="left" (onClick)="goToImportRequesDetail(request.importRequest.guid)"></p-button>
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                      <tr>
                        <td colspan="6">{{'MarketplacePage.WishList.WishListTable.EmptyResultsText' | translate}}</td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
              </div>
            </p-panel>
          </div>
        </p-tabPanel>
      </p-tabView>
  </div>
</app-user-dashboard>

<p-toast></p-toast>

<p-dialog [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleImportRequestDialog" [style]="{width: '70%'}">
  <div *ngIf="selectedImportRequest">
    <div class="grid">
      <div class="col-4">
        <p-carousel [value]="selectedImportRequest.pictures" [numVisible]="1" [numScroll]="1" [circular]="false"
          [responsiveOptions]="responsiveOptions">
          <ng-template let-picture pTemplate="item">
            <div class="mb-3" style="height: 50%;">
              <img [src]="completeUrl(picture.url)" style="border-radius: 7px; width: 100%; height: auto;"
                alt="Image" />
            </div>
          </ng-template>
        </p-carousel>
      </div>
      <div class="col-8">
        <div class="grid">
          <div class="col-12">
            <p-card header="{{'MarketplacePage.WishList.ImportRequestModal.CardTitle' | translate}}">
              <div class="grid">
                <div class="col-12">
                  <p-fieldset legend="{{'MarketplacePage.WishList.ImportRequestModal.FieldSetLegend' | translate}}">
                    <p-dropdown [options]="requestOffers" [(ngModel)]="selectedOffer" placeholder="{{'MarketplacePage.WishList.ImportRequestModal.OfferSelectFieldPlaceholder' | translate}}"
                      optionLabel="name" [filter]="true" filterBy="name" [showClear]="true"
                      [style]="{'minWidth':'100%'}" class="p-inputtext-sm">
                    </p-dropdown>
                  </p-fieldset>
                </div>
                <div *ngIf="selectedOffer" class="col-12">
                  <div class="grid">
                    <div class="col-12">
                      <h2>{{'MarketplacePage.WishList.ImportRequestModal.OfferDescription.Title' | translate}}</h2>
                    </div>
                    <div class="col-12 md:col-3 lg:col-3">{{'MarketplacePage.WishList.ImportRequestModal.OfferDescription.SpeciesLabel' | translate}}:</div>
                    <div class="col-12 md:col-3 lg:col-3">
                      <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{ selectedOffer.exportOffer.variety.species | speciesName }}</span>
                    </div>
                    <div class="col-12 md:col-3 lg:col-3">{{'MarketplacePage.WishList.ImportRequestModal.OfferDescription.VarietyLabel' | translate}}:</div>
                    <div class="col-12 md:col-3 lg:col-3">
                      <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{ selectedOffer.exportOffer.variety.name }}</span>
                    </div>
                    <div class="col-12 md:col-3 lg:col-3">{{'MarketplacePage.WishList.ImportRequestModal.OfferDescription.CasePerPalletsText' | translate}}:</div>
                    <div class="col-12 md:col-3 lg:col-3">
                      <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{
                        selectedOffer.exportOffer.caseQuantityPerPallet }}</span>
                    </div>
                    <div class="col-12 md:col-3 lg:col-3">{{'MarketplacePage.WishList.ImportRequestModal.OfferDescription.ListPriceText' | translate}}</div>
                    <div class="col-12 md:col-3 lg:col-3">
                      <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{
                        selectedOffer.exportOffer.listPrice | currency: 'USD':'US$' }}</span>
                    </div>
                    <div class="col-12 md:col-3 lg:col-3">{{'MarketplacePage.WishList.ImportRequestModal.OfferDescription.AvailablePalletsText' | translate}}:</div>
                    <div class="col-12 md:col-3 lg:col-3">
                      <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{
                        selectedOffer.exportOffer.availablePallets }}</span>
                    </div>
                    <div class="col-12 md:col-3 lg:col-3">Shelf life:</div>
                    <div class="col-12 md:col-3 lg:col-3">
                      <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{
                        selectedOffer.exportOffer.shelfLife }} {{'MarketplacePage.WishList.ImportRequestModal.OfferDescription.DayShelflifeText' | translate}}</span>
                    </div>
                    <div class="col-12 md:col-3 lg:col-3">{{'MarketplacePage.WishList.ImportRequestModal.OfferDescription.ColorText' | translate}}:</div>
                    <div class="col-12 md:col-3 lg:col-3">
                      <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{ selectedOffer.exportOffer.color | colorName }}</span>
                    </div>
                    <div class="col-12 md:col-3 lg:col-3">{{'MarketplacePage.WishList.ImportRequestModal.OfferDescription.SpeciesSizeText' | translate}}:</div>
                    <div class="col-12 md:col-3 lg:col-3">
                      <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{
                        selectedOffer.exportOffer.speciesSize }}</span>
                    </div>
                    <div class="col-12 md:col-3 lg:col-3">{{'MarketplacePage.WishList.ImportRequestModal.OfferDescription.SpeciesSizeMillimetersFieldText' | translate}}:</div>
                    <div class="col-12 md:col-3 lg:col-3">
                      <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{
                        selectedOffer.exportOffer.sizeMillimeters }} mm</span>
                    </div>
                    <div class="col-12 md:col-3 lg:col-3">{{'MarketplacePage.WishList.ImportRequestModal.OfferDescription.CategoryText' | translate}}:</div>
                    <div class="col-12 md:col-3 lg:col-3">
                      <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{
                        selectedOffer.exportOffer.category }}</span>
                    </div>
                    <div class="col-12 md:col-3 lg:col-3">{{'MarketplacePage.WishList.ImportRequestModal.OfferDescription.NetKilosLabel' | translate}}:</div>
                    <div class="col-12 md:col-3 lg:col-3">
                      <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{
                        selectedOffer.exportOffer.netKilos }} Kg</span>
                    </div>
                    <div class="col-12 md:col-3 lg:col-3">{{'MarketplacePage.WishList.ImportRequestModal.OfferDescription.CountryText' | translate}}</div>
                    <div class="col-12 md:col-3 lg:col-3">
                      <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{selectedOffer.exportOffer.country | countryDescription }}</span>
                    </div>
                  </div>
                  <div class="grid" style="margin-top: 20px;">
                    <div class="col-12 md:col-6 lg:col-6">{{'ExportOffer.ExportOfferDetail.AvailableProductCertificates' | translate}}:</div>
                    <div class="col-12 md:col-6 lg:col-6">
                      <ul *ngIf="selectedOffer.exportOffer.productCertificateTypes.length > 0">
                        <ng-container *ngFor="let productCertificateType of selectedOffer.exportOffer.productCertificateTypes">
                          <!-- <li>{{ productCertificateType.name }}&nbsp;<span *ngIf="productCertificateType.isOther" style="font-weight: bolder;">{{ selectedOffer.exportOffer.otherCertificateType }}</span></li> -->
                          <li *ngIf="!productCertificateType.isOther">{{ productCertificateType.name }}</li>
                          <li *ngIf="productCertificateType.isOther">{{ selectedOffer.exportOffer.otherCertificateType }}</li>
                        </ng-container>
                      </ul>
                      <span *ngIf="selectedOffer.exportOffer.productCertificateTypes.length == 0" style="font-weight: bolder;">{{ 'UserPages.UserExportOffers.NotResultsMessages' | translate }}</span>
                    </div>
                    <div class="col-12 md:col-6 lg:col-6">{{'ExportOffer.ExportOfferDetail.AvailablePackaging' | translate}}:</div>
                    <div class="col-12 md:col-6 lg:col-6">
                      <ul *ngIf="selectedOffer.exportOffer.packagingTypes.length > 0">
                        <ng-container *ngFor="let packagingType of selectedOffer.exportOffer.packagingTypes">
                          <!-- <li>{{ packagingType.name }}&nbsp;<span *ngIf="packagingType.isOther" style="font-weight: bolder;">{{ selectedOffer.exportOffer.otherPackaging }}</span></li> -->
                          <li *ngIf="!packagingType.isOther">{{ packagingType.name }}</li>
                          <li *ngIf="packagingType.isOther">{{ selectedOffer.exportOffer.otherPackaging }}</li>
                        </ng-container>
                      </ul>
                      <span *ngIf="selectedOffer.exportOffer.packagingTypes.length == 0" style="font-weight: bolder;">{{ 'UserPages.UserExportOffers.NotResultsMessages' | translate }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12" style="margin-top: 20px;">
                  <div class="grid" >
                    <div class="col-12">
                      <h2>{{'MarketplacePage.WishList.ImportRequestModal.ImportRequestDescription.Title' | translate}}</h2>
                    </div>
                    <div class="col-12 md:col-3 lg:col-3">{{'MarketplacePage.WishList.ImportRequestModal.OfferDescription.SpeciesLabel' | translate}}:</div>
                    <div class="col-12 md:col-3 lg:col-3">
                      <span style="border-radius: 7px; padding: 5px; font-weight: bold;">
                        {{ selectedImportRequest.importRequest.variety.species | speciesName }}
                      </span>
                    </div>
                    <div class="col-12 md:col-3 lg:col-3">{{'MarketplacePage.WishList.ImportRequestModal.ImportRequestDescription.VarietyText' | translate}}</div>
                    <div class="col-12 md:col-3 lg:col-3">
                      <span style="border-radius: 7px; padding: 5px; font-weight: bold;">
                        {{selectedImportRequest.importRequest.variety.name }}
                      </span>
                    </div>
                    <div class="col-12 md:col-3 lg:col-3">{{'MarketplacePage.WishList.ImportRequestModal.ImportRequestDescription.SellTypeText' | translate}}</div>
                    <div class="col-12 md:col-3 lg:col-3">
                      <span style="border-radius: 7px; padding: 5px; font-weight: bold;">
                        {{ selectedImportRequest.importRequest.typeSale }}
                      </span>
                    </div>
                    <div class="col-12 md:col-3 lg:col-3">{{'MarketplacePage.WishList.ImportRequestModal.ImportRequestDescription.CasePerPalletsText' | translate}}:</div>
                    <div class="col-12 md:col-3 lg:col-3">
                      <span style="border-radius: 7px; padding: 5px; font-weight: bold;">
                        {{ selectedImportRequest.importRequest.caseQuantityPerPallet }}
                        </span>
                    </div>
                    <div class="col-12 md:col-3 lg:col-3">{{'MarketplacePage.WishList.ImportRequestModal.ImportRequestDescription.PricePerCase' | translate}}:</div>
                    <div class="col-12 md:col-3 lg:col-3">
                      <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{
                        selectedImportRequest.importRequest.pricePerCase | currency: 'USD':'US$' }}
                      </span>
                    </div>
                    <div class="col-12 md:col-3 lg:col-3">{{'MarketplacePage.WishList.ImportRequestModal.ImportRequestDescription.SpeciesSizeText' | translate}}:</div>
                    <div class="col-12 md:col-3 lg:col-3">
                      <span style="border-radius: 7px; padding: 5px; font-weight: bold;">
                        {{selectedImportRequest.importRequest.speciesSize }}
                      </span>
                    </div>
                    <div class="col-12 md:col-3 lg:col-3">{{'MarketplacePage.WishList.ImportRequestModal.ImportRequestDescription.BoxTypeText' | translate}}:</div>
                    <div class="col-12 md:col-3 lg:col-3">
                      <span style="border-radius: 7px; padding: 5px; font-weight: bold;">
                        {{ getBoxType((selectedImportRequest.importRequest.boxType)) }}
                      </span>
                    </div>
                    <div class="col-12 md:col-3 lg:col-3">{{'MarketplacePage.WishList.ImportRequestModal.ImportRequestDescription.DestinationPortText' | translate}}:</div>
                    <div class="col-12 md:col-3 lg:col-3">
                      <span style="border-radius: 7px; padding: 5px; font-weight: bold;">
                        {{selectedImportRequest.importRequest.port.name }}
                      </span>
                    </div>
                    <!-- <div class="col-12 md:col-3 lg:col-3">{{'MarketplacePage.WishList.ImportRequestModal.ImportRequestDescription.PaymentAgainstBLLabel' | translate}}:</div> -->
                    <div class="col-12 md:col-3 lg:col-3">{{ paymentBLTitleImportRequest | translate }}</div>
                    <div class="col-12 md:col-3 lg:col-3">
                      <span style="border-radius: 7px; padding: 5px; font-weight: bold;">
                        {{selectedImportRequest.importRequest.paymentAgainstBL }} %
                      </span>
                    </div>
                    <div class="col-12 md:col-3 lg:col-3">{{'ExportOffer.ExportOfferDetail.FinalPaymentSubjectQC' | translate}}</div>
                    <div class="col-12 md:col-3 lg:col-3">
                      <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{ finalPaymentSubjectQC }} %</span>
                    </div>

                    <div *ngIf="selectedImportRequest.importRequest?.countryFrom !== null" class="col-12 md:col-3 lg:col-3">{{'ExportOffer.ExportOfferDetail.OriginCountryText' | translate}}</div>
                    <div *ngIf="selectedImportRequest.importRequest?.countryFrom === null" class="col-12 md:col-3 lg:col-3">{{'ImportRequestDetailComponent.OriginCountryAll' | translate }}</div>
                    <div class="col-12 md:col-3 lg:col-3">
                      <div class="flex align-items-center">
                        <img *ngIf="selectedImportRequest.importRequest?.countryFrom !== null" [src]="'../../../../assets/img/flags/' + selectedImportRequest.importRequest.countryFrom?.code + '.png'" style="width: 18px" alt=""/> {{selectedImportRequest.importRequest.countryFrom | countryDescription}}
                        <img *ngIf="selectedImportRequest.importRequest?.countryFrom === null" [src]="'../../../../assets/img/flags/WORLD.png'" style="width: 18px" alt=""/>
                      </div>
                    </div>

                    <div *ngIf="selectedImportRequest.importRequest?.country" class="col-12 md:col-3 lg:col-3">{{'ExportOffer.ExportOfferDetail.DestinationCountryText' | translate}}</div>
                    <div *ngIf="selectedImportRequest.importRequest?.country" class="col-12 md:col-3 lg:col-3">
                      <div class="flex align-items-center">
                        <img [src]="'../../../../assets/img/flags/' + selectedImportRequest.importRequest.country?.code + '.png'" style="width: 18px" alt=""/> {{selectedImportRequest.importRequest.country ? (selectedImportRequest.importRequest.country | countryDescription) : getAvailableLabel()}}
                      </div>
                    </div>

                  </div>

                  <div class="grid" style="margin-top: 20px;">
                    <div class="col-12 md:col-6 lg:col-6">{{'MarketplacePage.WishList.ImportRequestModal.ImportRequestDescription.RequiredProductCertificatesLabel' | translate}}:</div>
                    <div class="col-12 md:col-6 lg:col-6">
                      <ul *ngIf="selectedProductCertificateTypes.length > 0">
                        <ng-container *ngFor="let productCertificateType of selectedProductCertificateTypes">
                          <!-- <li>{{ productCertificateType.name }}&nbsp;<span *ngIf="productCertificateType.isOther" style="font-weight: bolder;">{{ selectedImportRequest.importRequest.otherCertificate }}</span></li> -->
                          <li *ngIf="!productCertificateType.isOther">{{ productCertificateType.name }}</li>
                          <li *ngIf="productCertificateType.isOther">{{ selectedImportRequest.importRequest.otherCertificate }}</li>
                        </ng-container>
                      </ul>
                      <span *ngIf="selectedProductCertificateTypes.length == 0" style="font-weight: bolder;">{{ 'UserPages.UserExportOffers.NotResultsMessages' | translate }}</span>
                    </div>
                    <div class="col-12 md:col-6 lg:col-6">{{'MarketplacePage.WishList.ImportRequestModal.ImportRequestDescription.RequiredPackagingLabel' | translate}}:</div>
                    <div class="col-12 md:col-6 lg:col-6">
                      <ul *ngIf="selectedPackagingTypes.length > 0">
                        <ng-container *ngFor="let packagingType of selectedPackagingTypes">
                          <!-- <li>{{ packagingType.name }}&nbsp;<span *ngIf="packagingType.isOther" style="font-weight: bolder;">{{ selectedImportRequest.importRequest.otherPackaging }}</span></li> -->
                          <li *ngIf="!packagingType.isOther">{{ packagingType.name }}</li>
                          <li *ngIf="packagingType.isOther">{{ selectedImportRequest.importRequest.otherPackaging }}</li>
                        </ng-container>
                      </ul>
                      <span *ngIf="selectedPackagingTypes.length == 0" style="font-weight: bolder;">{{ 'UserPages.UserExportOffers.NotResultsMessages' | translate }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </p-card>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <p-button label="{{'MarketplacePage.WishList.ImportRequestModal.CloseButton' | translate}}" (onClick)="closeOfferToRequestDialog()" styleClass="p-button-outlined"></p-button>
    <p-button label="{{'MarketplacePage.WishList.ImportRequestModal.SendButton' | translate}}" (onClick)="sendOfferToRequest()"></p-button>
  </ng-template>
</p-dialog>

<p-dialog [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleOfferDetailDialog"
  [style]="{width: '80%', heigth: 'auto'}">
  <div *ngIf="selected && selected.exportOffer">
    <div class="grid">
      <div class="col-12 md:col-6 lg:col-6">
        <app-export-offer-detail-carousel [offer]="selected.exportOffer"></app-export-offer-detail-carousel>
      </div>
      <div class="col-12 md:col-6 lg:col-6">
        <p-card [header]="getHeader(selected.exportOffer)" [subheader]="getSubheader(selected.exportOffer)">
          <div class="flex justify-content-between align-items-center" *ngIf="profile?.profileType === 'Importer'">
            <p style="font-size: 1.5rem; color: #8c2b87; font-weight: bold;">{{'MarketplacePage.WishList.ExportOfferModal.TotalUpperCaseText' | translate}} {{ totalPrice | currency: 'USD':'US$'}}
              {{getIncoterms(selected.exportOffer.typeSale)}}</p>
          </div>
          <div class="grid" style="margin-top: 30px;">
            <div class="col-12 md:col-3 lg:col-3">{{'MarketplacePage.WishList.ExportOfferModal.CasePerPalletsText' | translate}}:</div>
            <div class="col-12 md:col-3 lg:col-3">
              <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{
                selected.exportOffer.caseQuantityPerPallet }}</span>
            </div>
            <div class="col-12 md:col-3 lg:col-3">{{'MarketplacePage.WishList.ExportOfferModal.ListPriceText' | translate}}</div>
            <div class="col-12 md:col-3 lg:col-3">
              <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{
                selected.exportOffer.listPrice | currency: 'USD':'US$' }} {{selected.exportOffer.typeSale}}</span>
            </div>
            <div class="col-12 md:col-3 lg:col-3">{{'ExportOffer.ExportOfferDetail.PaymentAgainstBLLabel' | translate}}</div>
            <div class="col-12 md:col-3 lg:col-3">
              <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{ selected.exportOffer.paymentAgainstBL }} %</span>
            </div>
            <div class="col-12 md:col-3 lg:col-3">{{'MarketplacePage.WishList.ExportOfferModal.AvailablePalletsText' | translate}}:</div>
            <div class="col-12 md:col-3 lg:col-3">
              <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{ availablePallets }}</span>
            </div>
            <div class="col-12 md:col-3 lg:col-3">Shelf Life:</div>
            <div class="col-12 md:col-3 lg:col-3">
              <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{ selected.exportOffer.shelfLife ? selected.exportOffer.shelfLife +  " " + translate.instant('MarketplacePage.WishList.ExportOfferModal.DayShelflifeText') : getNoAvailableLabel() }}</span>
            </div>
            <div class="col-12 md:col-3 lg:col-3">{{'MarketplacePage.WishList.ExportOfferModal.ColorText' | translate}}:</div>
            <div class="col-12 md:col-3 lg:col-3">
              <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{
                selected.exportOffer.color ? getColor(selected.exportOffer.color) : getNoAvailableLabel()}}</span>
            </div>
            <div class="col-12 md:col-3 lg:col-3">{{'MarketplacePage.WishList.ExportOfferModal.NetKilosText' | translate}}:</div>
            <div class="col-12 md:col-3 lg:col-3">
              <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{
                selected.exportOffer.netKilos ? selected.exportOffer.netKilos + ' Kg.' : getNoAvailableLabel() }}</span>
            </div>
            <div class="col-12 md:col-3 lg:col-3">{{'MarketplacePage.WishList.ExportOfferModal.GrossKilosText' | translate}}:</div>
            <div class="col-12 md:col-3 lg:col-3">
              <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{
                selected.exportOffer.grossKilos ? selected.exportOffer.grossKilos + ' Kg.' : getNoAvailableLabel() }}</span>
            </div>
            <div class="col-12 md:col-3 lg:col-3">{{'MarketplacePage.WishList.ExportOfferModal.SpeciesSizeText' | translate}}:</div>
            <div class="col-12 md:col-3 lg:col-3">
              <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{
                selected.exportOffer.speciesSize ? selected.exportOffer.speciesSize : getNoAvailableLabel() }}</span>
            </div>
            <div class="col-12 md:col-3 lg:col-3">{{'MarketplacePage.WishList.ExportOfferModal.TypeSaleText' | translate}}:</div>
            <div class="col-12 md:col-3 lg:col-3">
              <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{
                selected.exportOffer.typeSale ? selected.exportOffer.typeSale : getNoAvailableLabel() }}</span>
            </div>
          </div>

          <div class="grid" style="margin-top: 10px;">
            <div class="col-12 md:col-6 lg:col-6">{{'ExportOffer.ExportOfferDetail.AvailableProductCertificates' | translate}}:</div>
            <div class="col-12 md:col-6 lg:col-6">
              <ul *ngIf="selectedProductCertificateTypes.length > 0">
                <ng-container *ngFor="let productCertificateType of selectedProductCertificateTypes">
                  <!-- <li>{{ productCertificateType.name }}&nbsp;<span *ngIf="productCertificateType.isOther" style="font-weight: bolder;">{{ selected.exportOffer.otherCertificateType }}</span></li> -->
                  <li *ngIf="!productCertificateType.isOther">{{ productCertificateType.name }}</li>
                  <li *ngIf="productCertificateType.isOther">{{ selected.exportOffer.otherCertificateType }}</li>
                </ng-container>
              </ul>
              <span *ngIf="selectedProductCertificateTypes.length == 0" style="font-weight: bolder;">{{ 'UserPages.UserExportOffers.NotResultsMessages' | translate }}</span>
            </div>
            <div class="col-12 md:col-6 lg:col-6">{{'ExportOffer.ExportOfferDetail.AvailablePackaging' | translate}}:</div>
            <div class="col-12 md:col-6 lg:col-6">
              <ul *ngIf="selectedPackagingTypes.length > 0">
                <ng-container *ngFor="let packagingType of selectedPackagingTypes">
                  <!-- <li>{{ packagingType.name }}&nbsp;<span *ngIf="packagingType.isOther" style="font-weight: bolder;">{{ selected.exportOffer.otherPackaging }}</span></li> -->
                  <li *ngIf="!packagingType.isOther">{{ packagingType.name }}</li>
                  <li *ngIf="packagingType.isOther">{{ selected.exportOffer.otherPackaging }}</li>
                </ng-container>
              </ul>
              <span *ngIf="selectedPackagingTypes.length == 0" style="font-weight: bolder;">{{ 'UserPages.UserExportOffers.NotResultsMessages' | translate }}</span>
            </div>
          </div>

          <div class="p-fluid formgrid grid align-items-end" *ngIf="profile?.profileType === 'Importer'"
            style="margin-top: 30px;">
            <div class="col-12">
              <h2>{{'MarketplacePage.WishList.ExportOfferModal.PurchaseForm.Title' | translate}}</h2>
            </div>
            <div class="field col-12 md:col-12 lg:col-6">
              <label for="pallets">Pallets</label>
              <p-inputNumber inputId="pallets" [(ngModel)]="pallets" [showButtons]="false" [min]="1"
                [max]="selected.exportOffer.availablePallets" [size]="2" buttonLayout="horizontal"
                spinnerMode="horizontal" [step]="1" decrementButtonClass="p-button-outlined"
                incrementButtonClass="p-button-outlined" incrementButtonIcon="pi pi-plus"
                decrementButtonIcon="pi pi-minus" (onInput)="getTotalPriceOfferDialog(selected.exportOffer, $event)"
                class="p-inputtext-sm"></p-inputNumber>
            </div>
            <div class="field col-12 md:col-12 lg:col-6">
              <p-button label="{{'MarketplacePage.WishList.ExportOfferModal.PurchaseForm.PurchaseNowButton' | translate}}" (onClick)="createOrder()" [loading]="loading"></p-button>
            </div>
          </div>

          <form [formGroup]="negotiationFormGroup" *ngIf="profile?.profileType === 'Importer'"
            style="margin-top: 30px;">
            <div class="grid">
              <div class="col-12">
                <h2>{{'MarketplacePage.WishList.ExportOfferModal.NegotiationFieldSetLegend' | translate}}</h2>
              </div>
              <div class="col-12">
                <div class="p-fluid formgrid grid flex align-items-start">
                  <div class="field col-12 md:col-6 lg:col-6">
                    <label for="negociation-price">{{'MarketplacePage.WishList.ExportOfferModal.NegotiationPriceFieldLable' | translate}}</label>
                    <p-inputNumber inputId="negociation-price" formControlName="negociationPrice" [min]="0"
                      mode="currency" currency="USD" locale="en-US" placeholder="{{'MarketplacePage.WishList.ExportOfferModal.NegotiationPriceFieldPlaceholder' | translate}}"
                      class="p-inputtext-sm"></p-inputNumber>
                    <small *ngIf="submitNegotiation && negotiationFormGroup.controls['negociationPrice'].invalid"
                      class="p-error">{{getErrorMessage('negociationPrice')}}</small>
                  </div>
                  <div class="field col-12 md:col-6 lg:col-6">
                    <label for="negotiation-pallets">{{'MarketplacePage.WishList.ExportOfferModal.PalletsFieldLabel' | translate}}</label>
                    <p-inputNumber inputId="negotiation-pallets" formControlName="negociationPallets" [min]="1"
                      [max]="selected.exportOffer.availablePallets" class="p-inputtext-sm"></p-inputNumber>
                    <small *ngIf="submitNegotiation && negotiationFormGroup.controls['negociationPallets'].invalid"
                      class="p-error">{{getErrorMessage('negociationPallets')}}</small>
                  </div>
                  <div class="field col-12 md:col-6 lg:col-6">
                    <label for="negociation-incoterm">{{'MarketplacePage.WishList.ExportOfferModal.SellModeFieldLabel' | translate}}</label>
                    <p-dropdown inputId="negociation-incoterm" formControlName="negociationIncoterm"
                      (onChange)="onChangeIncoterm($event)" [options]="incotermsOptions" optionLabel="label" optionValue="value"
                      placeholder="{{'MarketplacePage.WishList.ExportOfferModal.SellModeFieldPlaceholder' | translate}}" appendTo="body" class="p-inputtext-sm"></p-dropdown>
                    <small *ngIf="submitNegotiation && negotiationFormGroup.controls['negociationIncoterm'].invalid" class="p-error">{{getErrorMessage('negociationIncoterm')}}</small>
                  </div>
                  <div class="field col-12 md:col-6 lg:col-6">
                    <label for="negociation-paymentAgainstBL" style="font-size: calc(100% - 4px);">{{ paymentBLTitle | translate }} *</label>
                    <p-dropdown id="negociation-paymentAgainstBL" formControlName="negotiationPaymentAgainstBL" [options]="paymentBLItems" optionLabel="label" optionValue="value" placeholder="{{'UserPages.UserBargaining.OpenTab.BargainingDetail.MakeCounterOfferModal.PaymentAgainstBLLabel' | translate}}" appendTo="body"></p-dropdown>
                    <small *ngIf="submitNegotiation && negotiationFormGroup.controls['negotiationPaymentAgainstBL'].invalid" class="p-error">{{getErrorMessage('negotiationPaymentAgainstBL')}}</small>
                    <small *ngIf="showPaymentBLPercent">{{'UserPages.UserExportOffers.NewExportOffer.FinalPaymentSubjectQCReview' | translate}} {{finalPaymentPercent}}%</small>
                  </div>
                  <div class="field col-12 md:col-12 lg:col-12">
                    <label>&nbsp;</label>
                    <p-button label="{{'MarketplacePage.WishList.ExportOfferModal.CreateNegotiationButton' | translate}}" (onClick)="createNegotiation()" [loading]="loading" [style]="{'background-color': '#8c2b87'}"></p-button>
                    <label *ngIf="submitNegotiation && !negotiationFormGroup.valid">&nbsp;</label>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </p-card>
      </div>
    </div>
    <app-export-offer-detail-description [offer]="selected.exportOffer"></app-export-offer-detail-description>
  </div>
  <ng-template pTemplate="footer">
    <p-button label="{{'MarketplacePage.WishList.ExportOfferModal.CloseButton' | translate}}" (onClick)="closeOfferDetailDialog()"></p-button>
  </ng-template>
</p-dialog>
<app-footer-home></app-footer-home>
