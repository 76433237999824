import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { ImportRequestGateway } from 'src/app/application/gateway/ImportRequestGateway';
import { ExportOfferAd } from 'src/app/domain/models/ExportOffer';
import { ImportAndProgramByExportOffer } from 'src/app/domain/models/ImportRequest';
import { Packaging } from 'src/app/domain/models/Packaging';
import { ProductCertificateType } from 'src/app/domain/models/ProductCertificateType';
import { BasicUser } from 'src/app/domain/models/User';
import { ProgramRequestUtil } from '../../util/programRequestUtil';
import { Port } from 'src/app/domain/models/Port';
import { Country } from 'src/app/domain/models/Country';
import { PortGateway } from 'src/app/application/gateway/PortGateway';
import { CountryGateway } from 'src/app/application/gateway/CountryGateway';
import { Consignee } from 'src/app/domain/models/Consignee';
import { Notifier } from 'src/app/domain/models/Notifier';
import { ConsigneeGateway } from 'src/app/application/gateway/ConsigneeGateway';
import { NotifierGateway } from 'src/app/application/gateway/NotifierGateway';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';

@Component({
  selector: 'app-request-import-request',
  templateUrl: './request-import-request.component.html',
  styleUrls: ['./request-import-request.component.css']
})
export class RequestImportRequestComponent implements OnInit {

  adsCode: string | undefined

  profile: BasicUser | undefined

  destinationPorts: Port[] = []
  destinationCountries: Country[] = []

  @Input()
  exportOfferGuid: string | undefined

  selectedProductCertificateTypes: ProductCertificateType[] = []
  selectedPackagingTypes: Packaging[] = []

  exportOfferAd: ExportOfferAd | undefined

  loading: boolean = false
  submit: boolean = false

  startWeekMin: number = 1;
  endWeekMin: number = 1;

  startWeekMax: number = 52; 
  endWeekMax: number = 52;

  visibleRequestProgram: boolean = false
  selectedCountryTo: Country | undefined

  consignees: Consignee[] = []
  notifies: Notifier[] = []
  
  importRequestFormGroup: FormGroup = this._formBuilder.group({
    containersPerWeek: [null, Validators.required],
    startWeek: [null, [Validators.required]],
    startWeekYear: [this.currentYear, [Validators.required]],
    endWeek: [null],
    endWeekYear: [null],
    palletsQuantity:[null, [Validators.required]],
    startDate: [null],
    endDate: [null],
    countryToId: [null, [Validators.required]],
    portToId: [null, [Validators.required]],
    notifyId: [null, [Validators.required]],
    consigneeId: [null, [Validators.required]]
  },
  {
    validators: [this.endWeekValidator(), this.endWeekYearValidator()]
  })

  constructor(
    private _messageService: MessageService,
    private _formBuilder: FormBuilder,
    private _importRequestService: ImportRequestGateway,
    public translate: TranslateService,
    private _portService: PortGateway,
    private _countryService: CountryGateway,
    private _consigneeService: ConsigneeGateway,
    private _notifyService: NotifierGateway,
    private _authService: AuthGateway
  ){
    this.profile =  _authService.getUser()
  }


  get currentYear(): number {
    return new Date().getFullYear()
  }

  endWeekYearValidator(): ValidatorFn {
    return (control: AbstractControl) : ValidationErrors | null => {
      if(!this.importRequestFormGroup)
        return null

      if(!this.importRequestFormGroup.controls['startWeekYear'].value)
        return null

      if(!this.importRequestFormGroup.controls['endWeekYear'].value)
        return null

      let startWeekYear = this.importRequestFormGroup.controls['startWeekYear'].value
      let endWeekYear = this.importRequestFormGroup.controls['endWeekYear'].value

      return endWeekYear && startWeekYear > endWeekYear ? {endWeekYearRangeError:true}: null;
    }
  }

  ngOnInit(): void {
    this.getYears()
    this.getConsignees()
    this.getNotifies()
    this.loadCountries()
  }


  endWeekValidator(): ValidatorFn {
    return (control: AbstractControl) : ValidationErrors | null => {
      if(!this.importRequestFormGroup)
        return null

      if(!this.importRequestFormGroup.controls['startWeekYear'].value)
        return null

      if(!this.importRequestFormGroup.controls['endWeekYear'].value)
        return null

      if(!this.importRequestFormGroup.controls['startWeek'].value)
        return null

      if(!this.importRequestFormGroup.controls['endWeek'].value)
        return null

      let startWeek = this.importRequestFormGroup.controls['startWeek'].value
      let endWeek = this.importRequestFormGroup.controls['endWeek'].value
      let startWeekYear = this.importRequestFormGroup.controls['startWeekYear'].value
      let endWeekYear = this.importRequestFormGroup.controls['endWeekYear'].value

      return (startWeekYear === endWeekYear && startWeek > endWeek) ? {endWeekRangeError:true} : null;
    }
  }

  requestProgram()
  {
    this.submit = true;
    if(this.importRequestFormGroup.valid){
      let formData = this.importRequestFormGroup.value;

      let importAndProgramByExportOffer : ImportAndProgramByExportOffer  = 
      {
        ...formData
      }

      importAndProgramByExportOffer.exportOfferGuid = this.exportOfferGuid;
      importAndProgramByExportOffer.fromWeek = formData['startWeek'];
      importAndProgramByExportOffer.fromWeekYear = formData['startWeekYear'];
      importAndProgramByExportOffer.toWeek = formData['endWeek'];
      importAndProgramByExportOffer.toWeekYear = formData['endWeekYear'];
      
      this.loading = true;
      this._importRequestService.createImportRequestAndProgramByOffer(importAndProgramByExportOffer).subscribe({
        next: (v) => {
          this.loading = false;
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: this.translate.instant('MessageService.ImportRequest.ImportRequestDirectSuccess') })
        },
        error: (error) => {
          this.loading = false;
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOffer.ImportRequestDirectError') })
        }
      })


    }
  }

  onChangeStartDate(event: any)
  {
    this.importRequestFormGroup.controls['startWeek'].setValue(this.getWeekNumber(event))
    let year = this.getYearDate(event)
    this.importRequestFormGroup.controls['startWeekYear'].setValue(year)
  }

  onChangeEndDate(event: any)
  {
    this.importRequestFormGroup.controls['endWeek'].setValue(this.getWeekNumber(event))
    let year =this.getYearDate(event)
    this.importRequestFormGroup.controls['endWeekYear'].setValue(year)
  }
 
 
  getWeekNumber(date: Date): number {
    const weekNumber = ProgramRequestUtil.ISO8601_week_no(date)
    return weekNumber;
  }
 
  years: any[] = []
  getYears() {
    let current = new Date().getFullYear()
    this.years.push({label: current, value: current})
    let next = current + 1
    this.years.push({label: next, value: next})
  }
 
  getYearDate(date: Date)
  {
    return date.getFullYear()
  }

  loadCountries() {
    this._countryService.getAll().subscribe({
      next: (v) => {
        this.destinationCountries = v

        // localStorage.setItem("_marketplace_country_to_param", this.importRequestFormGroup.controls['countryToId'].value)
        this.selectedCountryTo = this.destinationCountries.find(x => x.id === this.importRequestFormGroup.controls['countryToId'].value)

      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Country.DestinationManyRetrieveError') })
      },
      complete: () => {
        console.info('Destination Countries query successfully')
      }
    })
  }

  loadDestinationPorts() {
    if(this.importRequestFormGroup.controls['countryToId'].value) {
      this._portService.getDestinationPortsByCountry(this.importRequestFormGroup.controls['countryToId'].value).subscribe({
        next: (v) => {
          this.destinationPorts = v
          this.selectedCountryTo = this.destinationCountries.find(x => x.id === this.importRequestFormGroup.controls['countryToId'].value)
        },
        error: (e) => {
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.Port.DestinationPortManyRetrieveError') })
        },
        complete: () => console.info('Destination Ports query successfully')
      })
    }
  }

  getConsignees() {
    if(this.profile) {
      this._consigneeService.getAllByUsername(this.profile.username).subscribe({
        next: (v) => {
          this.consignees = v
          this.loading = false
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.Consignee.ManyRetrieveError') })
        },
        complete: () => console.info('Consignees query successfully')
      })
    }
  }

  getNotifies() {
    if(this.profile) {
      this._notifyService.getAllByUsername(this.profile.username).subscribe({
        next: (v) => {
          this.notifies = v
          this.loading = false
        },
        error: (e) => {
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.Notify.ManyRetrieveError') })
        },
        complete: () => console.info('Notifies query successfully')
      })
    }
  }

}
