import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AssignCustomAgent, ProfileCompayList } from "src/app/domain/models/Company";
import { CustomAgentReview, CustomAgentReviewItemList, CustomAgentUserCreateRequest } from "src/app/domain/models/CustomAgentReview";

@Injectable({providedIn: 'root'})
export abstract class CustomAgentGateway {
  abstract getAllByStatusAndReviewType(reviewType: number): Observable<CustomAgentReviewItemList[]>
  abstract getByReviewId(reviewId: any): Observable<CustomAgentReview>
  abstract createUser(request: CustomAgentUserCreateRequest): Observable<any>
  abstract getCustomAgentsByAdmin(): Observable<ProfileCompayList[]>
  abstract assignCustomAgent(request: AssignCustomAgent): Observable<any>
}
