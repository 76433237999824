import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { PalletGateway } from 'src/app/application/gateway/PalletGateway';
import { ImportOrder } from 'src/app/domain/models/ImportOrder';
import { MasterLoad } from 'src/app/domain/models/Pallet';
import { TransportTypeUtils } from 'src/app/ui/util/transportType.Utils';

@Component({
  selector: 'app-admin-master-load-page',
  templateUrl: './admin-master-load-page.component.html',
  styleUrls: ['./admin-master-load-page.component.css'],
  providers: [MessageService]
})
export class AdminMasterLoadPageComponent implements OnInit {
  masterLoads: MasterLoad[] = []
  years: number[] = []
  year: number = new Date().getFullYear()

  loading: boolean = false

  constructor(private _messageService: MessageService,
    private _authService: AuthGateway,
    private _palletService: PalletGateway,
    public translateService: TranslateService
  ) {

  }

  ngOnInit(): void {
    this.getYears()
    this.loadMasterLoads()
  }

  getYears() {
    let current = new Date()
    let currentYear = current.getFullYear()

    for(let i = 2023; i <= currentYear; i++) {
      this.years.push(i)
    }
  }

  loadMasterLoads() {
    this.loading = true
    this._palletService.getMasterLoad(this.year).subscribe({
      next: (v) => {
        this.loading = false
        this.masterLoads = v
      },
      error: (e) => {
        this.loading = false
        console.log(e)
        this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudieron recuperar los Master Loads.' });
      },
      complete: () => {
        this.loading = false
        console.info('MasterLoad query successfully')
      }
    })
  }

  getTransportName(transportType: number)
  {
    return TransportTypeUtils.getTransportName(this.translateService.currentLang, transportType);
  }

  exportExcel() {
    import('xlsx').then((xlsx) => {
      let sheets: any[] = []
      if(this.masterLoads) {
        this.masterLoads.forEach(x => {
          let row = {
            "Pallet number": x.pallet.palletNumber,
            "transportType": this.getTransportName(x.pallet.importOrderDetail?.importOrder?.transportType),
            "Species": x.pallet.excelImportSpecies,
            "Variety": x.pallet.excelImportVariety,
            "Grower": x.pallet.producer,
            "Species size": x.pallet.speciesSize,
            "Harvest date": x.pallet.excelImportHarvestDate,
            "Quality control date": x.pallet.excelImportQualityControlDate,
            "Packaging": x.pallet.excelImportPackaging,
            "Pallet type": x.pallet.excelImportPalletType,
            "Tag": x.pallet.excelImportTag,
            "Bag type": x.pallet.excelImportBagType,
            "Box size": x.pallet.excelImportBoxSize,
            "Net weight": x.pallet.excelImportNetWeight,
            "Fridge Storage": x.pallet.excelImportFridgeStorage,
            "Boxes per pallet": x.pallet.excelImportBoxesPerPallet,
            "Mark": x.pallet.excelImportMark,
            "Container number (pallet)": x.pallet.excelImportContainerNumber,
            "Grade": x.pallet.excelImportGrade,
            "Temptale": x.pallet.excelImportTemptale,
            "Origin Country": x.pallet.excelImportOriginCountry,
            "Certificate 1": x.pallet.excelImportCertificate1,
            "Certificate 2": x.pallet.excelImportCertificate1,
            "Certificate 3": x.pallet.excelImportCertificate1,
            "Load Week": x.pallet.excelImportLoadWeek,
            "BL": x.delivery?.bl,
            "Booking": x.delivery?.booking,
            "Container number (preBL)": x.delivery?.containerNumber,
            "Exporter": x.pallet.importOrderDetail?.exportOffer?.exporter?.companyName,
            "Exporter email": x.pallet.importOrderDetail?.exportOffer?.exporter?.contactEmail,
            "Importer": x.pallet.importOrderDetail?.importOrder?.importer?.companyName,
            "Importer email": x.pallet.importOrderDetail?.importOrder?.importer?.contactEmail,
            "Consignee": x.delivery?.consigneeCompanyName,
            "Consignee email": x.delivery?.consigneeContactEmail,
            "Notify": x.delivery?.notifierCompanyName,
            "Notify email": x.delivery?.notifierContactEmail,
            "Instructions": x.delivery?.deliveryInstructions,
            "Gross Weight": x.delivery?.grossWeight,
            "CBM": x.delivery?.containerCbm,
            "Container temperature": x.delivery?.containerTemperature,
            "Place receipt": x.delivery?.placeReceipt,
            "Port of loading": x.delivery?.departurePort?.name,
            "Port of discharge": x.delivery?.destinationPort?.name,
            "Airport of loading": x.delivery.departureAirport?.name,
            "Airport of discharge": x.delivery.destinationAirport?.name,
            "Week (preBL)": x.delivery?.week,
            "Place delivery": x.delivery?.placeDelivery,
            "Vessel name/Flight Number": x.delivery?.ship,
            "Voyage": x.delivery?.voyageNumber,
            "Type of movement": x.delivery?.typeMovement,
            "ETD": x.delivery?.etd,
            "ETA": x.delivery?.eta,
            "Ocean freight (USD)": x.delivery?.oceanFreight,
            "Inland (USD)": x.delivery?.inland,
            "Local charges (USD)": x.delivery?.localCharges,
            "Customs (USD)": x.delivery?.customs,
            "Surcharge (USD)": x.delivery?.surcharge,
            "Others (USD)": x.delivery?.otherCosts,
            "Freight payable at": x.delivery?.freightPayableAt,
            "Shipped on board": x.delivery?.shippedOnBoard,
            "Place of issue": x.delivery?.placeIssue,
            "Date of issue": x.delivery?.dateIssue,
            "Excess value declaration": x.delivery?.excessValueDeclaration,
          }
          sheets.push(row)
        })
      }
      const worksheet = xlsx.utils.json_to_sheet(sheets);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'MasterLoad');
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
          type: EXCEL_TYPE
      });
      var FileSaver = require('file-saver');
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }
}
