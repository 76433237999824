import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { ProductCertificateGateway } from 'src/app/application/gateway/ProductCertificateGateway';
import { ProductCertificateTypeGateway } from 'src/app/application/gateway/ProductCertificateTypeGateway';
import { NewProductCertificate, ProductCertificate } from 'src/app/domain/models/ProductCertificate';
import { ProductCertificateType } from 'src/app/domain/models/ProductCertificateType';
import { Routes } from 'src/app/infrastructure/routes';

@Component({
  selector: 'app-product-certificate-form',
  templateUrl: './product-certificate-form.component.html',
  styleUrls: ['./product-certificate-form.component.css'],
  providers: [ConfirmationService, MessageService]
})
export class ProductCertificateFormComponent implements OnInit {

  title: string = ''
  submit = false
  loading = false
  productCertificateId = 0
  productCertificate: ProductCertificate | undefined
  uploadFile: any
  productCertificateTypes: ProductCertificateType[] = []

  formGroup: FormGroup = this._formBuilder.group({
    name: ['', Validators.required],
    documentIssueDate: [null],
    expirationDate: [null],
    productCertificateTypeId: ['', Validators.required]
  })

  constructor(private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _messageService: MessageService,
    private _authService: AuthGateway,
    private _productCertificateTypeService: ProductCertificateTypeGateway,
    private _productCertificateService: ProductCertificateGateway,
    public translate: TranslateService) {

  }

  ngOnInit(): void {
    this._activatedRoute.params.subscribe(params => {
      this.productCertificateId = params['productCertificateId']
    })

    this._productCertificateTypeService.getAll().subscribe({
      next: (v) => {
        this.productCertificateTypes = v
      },
      error: (e) => {
        console.log(e)
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.ProductCertificateType.ManyRetrieveError') });
      },
      complete: () => console.info('Product Certificate Types query successfully')
    })

    if(this.productCertificateId && this.productCertificateId > 0) {
      this.title = this.translate.instant('UserPages.UserProductCertificates.Form.EditTitle')
      this._productCertificateService.getById(this.productCertificateId).subscribe({
        next: (v) => {
          console.log(v)
          this.productCertificate = v
          this.loadForm()
        },
        error: (e) => {
          console.log(e)
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ProductCertificate.SingleRetrieveError') });
        },
        complete: () => console.info('Product Certificate query successfully')
      })
    }
    else {
      this.title = this.translate.instant('UserPages.UserProductCertificates.Form.CreateTitle')
    }
  }

  loadForm() {
    if(this.productCertificate) {
      this.formGroup.controls['name'].setValue(this.productCertificate.name)
      this.formGroup.controls['documentIssueDate'].setValue(this.productCertificate.documentIssueDate ? new Date(this.productCertificate.documentIssueDate) : null)
      this.formGroup.controls['expirationDate'].setValue(this.productCertificate.expirationDate ? new Date(this.productCertificate.expirationDate) : null)
      this.formGroup.controls['productCertificateTypeId'].setValue(this.productCertificate.productCertificateTypeId)
    }
  }

  onSelect(event: any) {
    this.uploadFile = event.files[0]
  }

  save() {
    this.submit = true
    this.loading = true
    if(this.formGroup.valid) {
      if(this.productCertificate) {
        //update
        this.productCertificate.name = this.formGroup.controls['name'].value
        this.productCertificate.productCertificateTypeId = this.formGroup.controls['productCertificateTypeId'].value
        this.productCertificate.documentIssueDate = this.formGroup.controls['documentIssueDate'].value
        this.productCertificate.expirationDate = this.formGroup.controls['expirationDate'].value
        this.productCertificate.pdf = this.uploadFile ? this.uploadFile : this.productCertificate.pdf

        this._productCertificateService.update(this.productCertificate).subscribe({
          next: (v) => {
            this.productCertificate = v
            this._messageService.add({ severity: 'success',
              summary: this.translate.instant('MessageService.Summary.SuccessAction'),
              detail: this.translate.instant('MessageService.ProductCertificate.EditSuccess') })
            this.loading = false
            this.submit = false
          },
          error: (e) => {
            this.loading = false
            console.log(e)
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.ProductCertificate.EditError') });
          },
          complete: () =>  {
            this.loading = false
            this.submit = false
            console.info('Product Certificate update successfully') }
        })
      }
      else {
        //create
        let newCertificate: NewProductCertificate = {
          name: this.formGroup.controls['name'].value,
          productCertificateTypeId: this.formGroup.controls['productCertificateTypeId'].value,
          documentIssueDate: this.formGroup.controls['documentIssueDate'].value,
          expirationDate: this.formGroup.controls['expirationDate'].value,
          pdf: this.uploadFile
        }

        this._productCertificateService.create(newCertificate).subscribe({
          next: (v) => {
            this.productCertificate = v
            this.productCertificateId = this.productCertificate.id ? this.productCertificate.id : 0
            this.title = this.translate.instant('UserPages.UserProductCertificates.Form.EditTitle')
            this._messageService.add({ severity: 'success',
              summary: this.translate.instant('MessageService.Summary.SuccessAction'),
              detail: this.translate.instant('MessageService.ProductCertificate.CreateSuccess') })
            this.loading = false
            this.submit = false
          },
          error: (e) => {
            this.loading = false
            console.log(e)
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.ProductCertificate.CreateError') });
          },
          complete: () =>  {
            this.loading = false
            this.submit = false
            console.info('Product Certificate create successfully')
          }
        })
      }
    }
    else {
      this.loading = false
    }
  }

  cancel() {
    this._router.navigate([Routes.userProductCertificates])
  }

  getErrorMessage(fieldName: string): string {
    if (this.formGroup.controls[fieldName].hasError('required')) {
      return this.translate.instant('FormErrorMessage.FieldValueRequired')
    }
    if (this.formGroup.controls[fieldName].hasError('email')) {
      return this.translate.instant('FormErrorMessage.EmailFieldEmailFormatError')
    }
    return ''
  }
}
