<div *ngIf="offer">
  <div class="grid">
    <div class="col-12 md:col-4 lg:col-4">
      <app-frozen-fruit-export-offer-detail-carousel [offer]="offer"></app-frozen-fruit-export-offer-detail-carousel>
    </div>
    <div class="col-12 md:col-8 lg:col-8">
      <p-card [header]="getHeader()" [subheader]="getSubheader()">
        <div class="grid">
          <div class="col-12 md:col-6 lg:col-6">Containers</div>
          <div class="col-12 md:col-6 lg:col-6">
            <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{ offer.containersQuantity }}</span>
          </div>
          <div class="col-12 md:col-6 lg:col-6" *ngIf="offer?.productStackingType === 2">{{'FrozenFruitExportOfferDetailComponent.TotalPalletsLabel' | translate}}:</div>
          <div class="col-12 md:col-6 lg:col-6" *ngIf="offer?.productStackingType === 2">
            <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{ offer.palletsQuantity }}</span>
          </div>
          <div class="col-12 md:col-6 lg:col-6" *ngIf="offer?.productStackingType === 2">{{'FrozenFruitExportOfferDetailComponent.BoxesPerPalletLabel' | translate}}:</div>
          <div class="col-12 md:col-6 lg:col-6" *ngIf="offer?.productStackingType === 2">
            <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{ offer.boxesPerPallet }}</span>
          </div>
          <div class="col-12 md:col-6 lg:col-6" *ngIf="offer?.productStackingType === 1">{{'FrozenFruitExportOfferDetailComponent.TotalBoxes' | translate}}:</div>
          <div class="col-12 md:col-6 lg:col-6" *ngIf="offer?.productStackingType === 1">
            <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{ offer.boxesQuantity }}</span>
          </div>
          <div class="col-12 md:col-6 lg:col-6">{{'FrozenFruitExportOfferDetailComponent.Price' | translate}}</div>
          <div class="col-12 md:col-6 lg:col-6">
            <span style="border-radius: 7px; padding: 5px; font-weight: bold;">{{ offer.price | currency: 'USD':'US$' }} - {{ offer.incoterms }}</span>
          </div>
        </div>
        <div class="p-fluid formgrid grid" *ngIf="profile?.profileType === 'Importer' && isPurchase" style="margin-top: 30px;">
          <div class="col-12">
            <h2>{{'MarketplacePage.WishList.ExportOfferModal.PurchaseForm.Title' | translate}}</h2>
            <p style="font-size: 1.5rem; color: #8c2b87; font-weight: bold;">{{'MarketplacePage.WishList.ExportOfferModal.TotalUpperCaseText' | translate}} {{ totalPrice | currency: 'USD':'US$'}} {{offer.incoterms}}</p>
          </div>
          <div class="field col-12 md:col-12 lg:col-6">
            <label for="containers">Containers</label>
            <p-inputNumber
              inputId="containers"
              [(ngModel)]="containers"
              [showButtons]="false"
              [min]="1"
              [max]="offer.containersQuantity"
              [size]="2"
              [step]="1"
              (onInput)="getTotalPrice($event)"
              class="p-inputtext-sm"></p-inputNumber>
          </div>
          <div class="col-12 md:col-12 lg:col-6 flex align-items-center p-fluid">
            <div class=" w-full">
              <p-button label="{{'MarketplacePage.WishList.ExportOfferModal.PurchaseForm.PurchaseNowButton' | translate}}" (onClick)="addShoppingCart()" [loading]="loading"></p-button>
            </div>
          </div>
        </div>
      </p-card>
    </div>
  </div>
  <app-frozen-fruit-export-offer-detail-description [offer]="offer"></app-frozen-fruit-export-offer-detail-description>
</div>

<p-toast></p-toast>
