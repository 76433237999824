<app-user-dashboard [fullScreen]="true">
  <div class="bg-container margin-top-negative" id="virtual-comex">
    <div class="container">
      <h1 class="title">{{'ToDoFruzty.slide_1.title' | translate}}</h1>
      <p class="text">
        {{'ToDoFruzty.slide_1.description' | translate}}
        <strong>
          {{'ToDoFruzty.slide_1.descriptionStrong' | translate}}
        </strong>
        {{'ToDoFruzty.slide_1.lastDescription' | translate}}
      </p>
      <div class="container-flex">
        <section class="column-left">
          <h2 class="subtitle">
            {{'ToDoFruzty.subtitle-items' | translate}}
            <i class="pi pi-verified figure-icon-large"></i>

          </h2>
          <p class="text-item">
            <i class="pi pi-check-circle figure-icon"></i>
            {{'ToDoFruzty.slide_1.item_1' | translate}}
          </p>
          <p class="text-item">
            <i class="pi pi-check-circle figure-icon"></i>
            {{'ToDoFruzty.slide_1.item_2' | translate}}
          </p>
          <p class="text-item">
            <i class="pi pi-check-circle figure-icon"></i>
            {{'ToDoFruzty.slide_1.item_3' | translate}}
          </p>
        </section>
        <section class="column-right">
          <figure class="figure-img">
            <img src="../../../../assets/img/to_do_fruzty/QPHF-01-T.png" style="width: 100%" alt="flag"/>

          </figure>

        </section>
      </div>
    </div>
  </div>
  <div class="bg-container-white" id="find-and-connect">
    <div class="container-align-right">
      <h1 class="title">{{'ToDoFruzty.slide_2.title' | translate}}</h1>
      <p class="text">
        {{'ToDoFruzty.slide_2.description' | translate}}
        <strong>
          {{'ToDoFruzty.slide_2.descriptionStrong' | translate}}
        </strong>
        {{'ToDoFruzty.slide_2.lastDescription' | translate}}
      </p>
      <div class="container-flex flex-reverse">
        <section class="column-right">
          <figure class="figure-img">
            <img src="../../../../assets/img/to_do_fruzty/QPHF-02-T_.png" style="width: 100%" alt="flag"/>

          </figure>

        </section>
        <section class="column-right-text">
          <h2 class="subtitle">
            {{'ToDoFruzty.subtitle-items' | translate}}
            <i class="pi pi-verified figure-icon-large"></i>
          </h2>
          <p class="text-item">
            <i class="pi pi-check-circle figure-icon"></i>
            {{'ToDoFruzty.slide_2.item_1' | translate}}
          </p>
          <p class="text-item">
            <i class="pi pi-check-circle figure-icon"></i>
            {{'ToDoFruzty.slide_2.item_2' | translate}}
          </p>
          <p class="text-item">
            <i class="pi pi-check-circle figure-icon"></i>
            {{'ToDoFruzty.slide_2.item_3' | translate}}
          </p>
        </section>
      </div>

    </div>
  </div>
  <div class="bg-container"  id="import-program">
    <div class="container">
      <h1 class="title">{{'ToDoFruzty.slide_3.title' | translate}}</h1>
      <p class="text">
        {{'ToDoFruzty.slide_3.description' | translate}}
      </p>
      <div class="container-flex">
        <section class="column-left">
          <h2 class="subtitle">
            {{'ToDoFruzty.subtitle-items' | translate}}
            <i class="pi pi-verified figure-icon-large"></i>
          </h2>
          <p class="text-item">
            <i class="pi pi-check-circle figure-icon"></i>
            {{'ToDoFruzty.slide_3.item_1' | translate}}
          </p>
          <p class="text-item">
            <i class="pi pi-check-circle figure-icon"></i>
            {{'ToDoFruzty.slide_3.item_2' | translate}}
          </p>
          <p class="text-item">
            <i class="pi pi-check-circle figure-icon"></i>
            {{'ToDoFruzty.slide_3.item_3' | translate}}
          </p>
        </section>
        <section class="column-right">
          <figure class="figure-img">
            <img src="../../../../assets/img/to_do_fruzty/QPHF-03-T.png" style="width: 100%" alt="flag"/>

          </figure>

        </section>
      </div>

    </div>
  </div>
</app-user-dashboard>

<app-footer-home></app-footer-home>

