<p-table
  [loading]="loading"
  [value]="productCertificates"
  [paginator]="true"
  [rows]="10"
  [showCurrentPageReport]="true"
  [rowsPerPageOptions]="[10, 25, 50]"
  responsiveLayout="stack"
  [breakpoint]="'600px'">
  <ng-template pTemplate="caption">
    <div class="flex align-items-center justify-content-start">
      <p-button label="{{'UserPages.UserProductCertificates.NewButtonLabel' | translate}}" icon="pi pi-plus" (onClick)="openProductCertificateCreateDialog(formDirective, productCertificateFileUploader)" class="mr-2"/>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
      <tr>
        <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserProductCertificates.NameHeader' | translate}}</div></th>
        <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserProductCertificates.CertificateTypeHeader' | translate}}</div></th>
        <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserProductCertificates.IssueDateHeader' | translate}}</div></th>
        <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserProductCertificates.ExpireDateHeader' | translate}}</div></th>
        <th><div class="flex justify-content-center align-items-center">PDF</div></th>
        <th></th>
      </tr>
  </ng-template>
  <ng-template pTemplate="body" let-certificate>
    <tr [pSelectableRow]="certificate">
      <td><div class="flex justify-content-center align-items-center">{{certificate.name}}</div></td>
      <td><div class="flex justify-content-center align-items-center">{{certificate.productCertificateType.name}}</div></td>
      <td><div class="flex justify-content-center align-items-center">{{certificate.documentIssueDate | date:'dd-MM-yyyy'}}</div></td>
      <td><div class="flex justify-content-center align-items-center">{{certificate.expirationDate | date:'dd-MM-yyyy'}}</div></td>
      <td><div class="flex justify-content-center align-items-center">
        <p-button pTooltip="Document PDF" (onClick)="getProductCertificateDocument(certificate.id)" icon="pi pi-file-pdf" styleClass="p-button-outlined"></p-button>
      </div></td>
      <td>
        <div class="flex justify-content-end align-items-center gap-2">
          <p-button label="{{'UserPages.UserProductCertificates.EditButtonLabel' | translate}}" icon="pi pi-pencil" (onClick)="openProductCertificateEditDialog(certificate, productCertificateFileUploader)" class="mr-2"/>
          <p-button label="{{'UserPages.UserProductCertificates.DeleteButtonLabel' | translate}}" icon="pi pi-trash" (onClick)="onDeleteProductCertificate(certificate)" class="mr-2"/>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>

<p-dialog header="{{'UserPages.UserProductCertificates.PageTitle' | translate}}" [(visible)]="productCertificateDialogVisible" [style]="{width: '50%'}" [modal]="true">
  <form [formGroup]="productCertificateFormGroup" #formDirective="ngForm">
    <div class="p-fluid formgrid grid" style="padding: 20px;">
      <div class="field col-12 md:col-6 lg:col-6" style="margin-bottom: 40px;">
        <label for="name" style="font-weight: bolder;">{{'UserPages.UserProductCertificates.Form.NameLabel' | translate}} *</label>
        <input id="name" formControlName="name" type="text" required pInputText placeholder="{{'UserPages.UserProductCertificates.Form.NameLabel' | translate}}"/>
        <small *ngIf="submitProductCertificate && productCertificateFormGroup.controls['name'].invalid" class="p-error">{{getErrorMessage(productCertificateFormGroup, 'name')}}</small>
      </div>
      <div class="field col-12 md:col-6 lg:col-6" style="margin-bottom: 40px;">
        <label for="certificateType" style="font-weight: bolder;">{{'UserPages.UserProductCertificates.Form.CertificateTypeLabel' | translate}} *</label>
        <p-dropdown inputId="certificateType" formControlName="productCertificateTypeId" [options]="certificateTypes" optionLabel="name" optionValue="id" placeholder="{{'UserPages.UserProductCertificates.Form.CertificateTypeLabel' | translate}}"></p-dropdown>
        <small *ngIf="submitProductCertificate && productCertificateFormGroup.controls['productCertificateTypeId'].invalid" class="p-error">{{getErrorMessage(productCertificateFormGroup, 'productCertificateTypeId')}}</small>
      </div>
      <div class="field col-12 md:col-6 lg:col-6" style="margin-bottom: 40px;">
        <label for="issueDate" style="font-weight: bolder;">{{'UserPages.UserProductCertificates.Form.IssueDateLabel' | translate}} </label>
        <p-calendar
          id="issueDate"
          appendTo="body"
          formControlName="documentIssueDate"
          [showButtonBar]="true"
          [showIcon]="true"
          placeholder="{{'UserPages.UserProductCertificates.Form.IssueDateLabel' | translate}}"
          [showWeek]="true"
          [firstDayOfWeek]="1"></p-calendar>
      </div>
      <div class="field col-12 md:col-6 lg:col-6" style="margin-bottom: 40px;">
        <label for="expirationDate" style="font-weight: bolder;">{{'UserPages.UserProductCertificates.Form.ExpireDateLabel' | translate}}</label>
        <p-calendar
          id="expirationDate"
          appendTo="body"
          formControlName="expirationDate"
          [showButtonBar]="true"
          [showIcon]="true"
          placeholder="{{'UserPages.UserProductCertificates.Form.ExpireDateLabel' | translate}}"
          [showWeek]="true"
          [firstDayOfWeek]="1"></p-calendar>
      </div>
      <div class="field col-12" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserProductCertificates.Form.LoadDocumentLabel' | translate}}</label>
        <p-fileUpload
          #productCertificateFileUploader
          (onSelect)="onSelectProductCertificateFile($event)"
          (onClear)="onClearProductCertificateFile($event)"
          [showUploadButton]="false"
          [multiple]="false"
          accept=".pdf"
          [maxFileSize]="5000000"
          chooseLabel="{{'UserPages.UserProductCertificates.Form.SelectFile' | translate}}"
          cancelLabel="{{'UserPages.UserProductCertificates.Form.CancelSelectFile' | translate}}"/>
      </div>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <p-button label="{{'UserPages.UserProductCertificates.Form.BackButtonLabel' | translate}}" (onClick)="productCertificateDialogVisible = false" styleClass="p-button-outlined"></p-button>
    <p-button label="{{'UserPages.UserProductCertificates.Form.SaveButtonLabel' | translate}}" (onClick)="saveProductCertificate()" [loading]="loading"></p-button>
  </ng-template>
</p-dialog>

<p-toast></p-toast>
<p-confirmDialog acceptLabel="Yes" rejectLabel="No" rejectButtonStyleClass="p-button-outlined" [style]="{width: '50vw'}"></p-confirmDialog>
