import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { CountryGateway } from 'src/app/application/gateway/CountryGateway';
import { NotifierGateway } from 'src/app/application/gateway/NotifierGateway';
import { Country } from 'src/app/domain/models/Country';
import { Notifier } from 'src/app/domain/models/Notifier';
import { Routes } from 'src/app/infrastructure/routes';
import { formLimit } from 'src/app/ui/util/form-limit';

@Component({
  selector: 'app-notifier-form',
  templateUrl: './notifier-form.component.html',
  styleUrls: ['./notifier-form.component.css'],
  providers: [MessageService]
})
export class NotifierFormComponent implements OnInit {
  title: string = ''
  countries: Country[] = []
  submit = false
  loading = false
  notifierId = 0
  notifier: Notifier | undefined

  formGroup: FormGroup = this._formBuilder.group({
    name: ['', Validators.required],
    vat: ['', Validators.required],
    contactCountryId: ['', Validators.required],
    contactCity: ['', Validators.required],
    contactName: ['', Validators.required],
    contactAddress: ['', Validators.required],
    contactPhone: [''],
    contactEmail: ['', [Validators.required, Validators.email]],
  })

  formLimit = formLimit

  constructor(private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _messageService: MessageService,
    private _authService: AuthGateway,
    private _countryService: CountryGateway,
    private _notifierService: NotifierGateway,
    public translate: TranslateService) {

  }

  ngOnInit(): void {
    this._activatedRoute.params.subscribe(params => {
      this.notifierId = params['notifierId']
    })

    this._countryService.getAll().subscribe({
      next: (v) => {
        this.countries = v
      },
      error: (e) => {
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Country.ManyRetrieveError') });
        console.log(e)
      },
      complete: () => console.info('Country query successfully')
    })

    if(this.notifierId && this.notifierId > 0) {
      this.title = this.translate.instant('UserPages.UserNotifies.NotifierForm.EditTitle')
      this._notifierService.getById(this.notifierId).subscribe({
        next: (v) => {
          console.log(v)
          this.notifier = v
          this.loadForm()
        },
        error: (e) => {
          console.log(e)
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.Notify.SingleRetrieveError') });
        },
        complete: () => console.info('Notify query successfully')
      })
    }
    else {
      this.title = this.translate.instant('UserPages.UserNotifies.NotifierForm.CreateTitle')
      this.notifier = {}
    }
  }

  loadForm() {
    if(this.notifier) {
      this.formGroup.controls["name"].setValue(this.notifier.companyName)
      this.formGroup.controls["vat"].setValue(this.notifier.vat)
      this.formGroup.controls["contactName"].setValue(this.notifier.contactName)
      this.formGroup.controls["contactAddress"].setValue(this.notifier.contactAddress)
      this.formGroup.controls["contactEmail"].setValue(this.notifier.contactEmail)
      this.formGroup.controls["contactPhone"].setValue(this.notifier.contactPhone)
      this.formGroup.controls["contactCity"].setValue(this.notifier.contactCity)
      this.formGroup.controls["contactCountryId"].setValue(this.notifier.contactCountry?.id)
    }
  }

  save() {
    this.submit = true
    this.loading = true
    if(this.formGroup.valid && this.notifier) {
      this.notifier.companyName = this.formGroup.controls["name"].value
      this.notifier.vat = this.formGroup.controls["vat"].value
      this.notifier.contactName = this.formGroup.controls["contactName"].value
      this.notifier.contactAddress = this.formGroup.controls["contactAddress"].value
      this.notifier.contactEmail = this.formGroup.controls["contactEmail"].value
      this.notifier.contactPhone = this.formGroup.controls["contactPhone"].value
      this.notifier.contactCity = this.formGroup.controls["contactCity"].value
      this.notifier.contactCountryId = this.formGroup.controls["contactCountryId"].value

      if(this.notifierId && this.notifierId > 0) {
        //update
        this._notifierService.update(this.notifier).subscribe({
          next: (v) => {
            this.notifier = v
            this._messageService.add({ severity: 'success',
              summary: this.translate.instant('MessageService.Summary.SuccessAction'),
              detail: this.translate.instant('MessageService.Notify.EditSuccess') })
            this.loading = false
            this.submit = false
          },
          error: (e) => {
            this.loading = false
            console.log(e)
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.Notify.EditError') });
          },
          complete: () =>  {
            this.loading = false
            this.submit = false
            console.info('Notify update successfully') }
        })
      }
      else {
        //create
        this.notifier.username = this._authService.getUsername()
        this._notifierService.create(this.notifier).subscribe({
          next: (v) => {
            this.notifier = v
            this.notifierId = this.notifier.id ? this.notifier.id : 0
            this.title = 'Editar Notify'
            this._messageService.add({ severity: 'success',
              summary: this.translate.instant('MessageService.Summary.SuccessAction'),
              detail: this.translate.instant('MessageService.Notify.CreateSuccess') })
            this.loading = false
            this.submit = false
          },
          error: (e) => {
            this.loading = false
            console.log(e)
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.Notify.CreateError') });
          },
          complete: () =>  {
            this.loading = false
            this.submit = false
            console.info('Notify create successfully')
          }
        })
      }
    }
    else {
      this.loading = false
    }
  }

  cancel() {
    this._router.navigate([Routes.userNotifiers])
  }

  getErrorMessage(fieldName: string): string {
    if (this.formGroup.controls[fieldName].hasError('required')) {
      return this.translate.instant('FormErrorMessage.FieldValueRequired')
    }
    if (this.formGroup.controls[fieldName].hasError('email')) {
      return this.translate.instant('FormErrorMessage.EmailFieldEmailFormatError')
    }
    return ''
  }
}
