import { Component, Input, OnInit } from '@angular/core';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { ExportOffer } from 'src/app/domain/models/ExportOffer';
import { ImportOrder, ImportOrderDetail } from 'src/app/domain/models/ImportOrder';
import { Packaging } from 'src/app/domain/models/Packaging';
import { ProductCertificateType } from 'src/app/domain/models/ProductCertificateType';
import { BasicUser } from 'src/app/domain/models/User';
import { ExportOfferUtils } from 'src/app/ui/util/export-offer-utils';
import { Lang } from 'src/app/ui/util/langs';

@Component({
  selector: 'app-export-offer-detail',
  templateUrl: './export-offer-detail.component.html',
  styleUrls: ['./export-offer-detail.component.css']
})
export class ExportOfferDetailComponent implements OnInit {

  @Input()
  offer: ExportOffer | undefined

  
  @Input()
  productCertificateTypes: ProductCertificateType[] = []
  
  @Input()
  packagingTypes: Packaging[] = []
  
  profile: BasicUser | undefined
  
  @Input()
  importOrder: ImportOrder | undefined

  negotiationPrice: number | undefined
  negotiationIncoterms: string | undefined

  itemFinded: ImportOrderDetail[] | undefined

  constructor(private _authService: AuthGateway) {
    this.profile = _authService.getUser()
  }

  ngOnInit(): void {
  }

  getHeader(): string {
    let header = ''
    if(this.profile)
    {
      switch(this.profile.lang) {
        case 'en':
          header = this.offer?.variety && this.offer.variety.species && this.offer.variety.species.name ? this.offer.variety.species.name.toUpperCase() : ''
          break

        case 'es':
          header = this.offer?.variety && this.offer.variety.species && this.offer.variety.species.nameEs ? this.offer.variety.species.nameEs.toUpperCase() : ''
          break
      }
    }
    return header
  }

  getSubheader(): string {
    return this.offer?.variety && this.offer.variety.name ? this.offer.variety.name.toUpperCase() : ''
  }

  get finalPaymentSubjectQC(): number {
    if(this.offer && this.offer.paymentAgainstBL)
      return 100 - this.offer.paymentAgainstBL
    return 0
  }

  getNegotiationPrice(): boolean {
    if (this.offer?.orderDetailsRelations && this.importOrder?.guid) {
      this.itemFinded = this.offer?.orderDetailsRelations.filter(item => item.importOrder.guid == this.importOrder?.guid)

      if (this.itemFinded && this.itemFinded.length > 0) {
        this.negotiationPrice = this.itemFinded[0].price ?? undefined
        this.negotiationIncoterms = this.itemFinded[0].incoterms ?? undefined

        // Se verifica si el valor o el Incoterms son diferentes para que gráficamente muestre que hubo negociación de precios
        if ((this.negotiationPrice && (this.negotiationPrice !== this.offer.listPrice)) || (this.negotiationIncoterms && (this.negotiationIncoterms !== this.offer.typeSale))) return true
      }
    }
    return false
  }
}
