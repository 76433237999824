import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Profile, SingupRequest, ValidateTokenRequest } from "src/app/domain/models/Profile";
import { Routes } from "src/app/infrastructure/routes";
import { AuthGateway } from "../gateway/AuthGateway";
import { SecurityGateway } from "../gateway/SecurityGateway";

@Injectable({providedIn: 'root'})
export class SecurityApiService extends SecurityGateway {

  constructor(private _http: HttpClient, private _authGateway: AuthGateway) {
      super();
  }

  doLogin(username: string, password: string, code: string | undefined): Observable<Object> {
    return this._http.post(Routes.securityApi + '/Login', {
      "username": username,
      "password": password,
      "code": code
    })
  }

  signup(request: SingupRequest): Observable<Profile> {
    return this._http.post<Profile>(Routes.securityApi + '/Signup', request);
  }

  doLogout(): Observable<any> {
    sessionStorage.clear()
    return this._http.get<any>(Routes.securityApi + '/Logout')
  }

  validateToken(request: ValidateTokenRequest): Observable<any> {
    return this._http.post<any>(Routes.securityApi + '/validateToken', request)
  }
}
