import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DeliveryDiscount, NewDeliveryDiscount } from "src/app/domain/models/DeliveryDiscount";

@Injectable({providedIn: 'root'})
export abstract class DeliveryDiscountGateway {
   abstract create(deliveryDiscount: NewDeliveryDiscount): Observable<DeliveryDiscount>
   abstract update(deliveryDiscount: DeliveryDiscount): Observable<DeliveryDiscount>
   abstract delete(id: number): Observable<any>
   abstract getAllByDeliveryId(importOrderId: any): Observable<DeliveryDiscount[]>
}
