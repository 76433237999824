import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { InvitationCodeGateway } from 'src/app/application/gateway/InvitationCodeGateway';
import { InvitationCodeCreate } from 'src/app/domain/models/InvitationCode';
import { Lang } from 'src/app/domain/models/Language';
import { Routes } from 'src/app/infrastructure/routes';
import { ErrorMessageUtil } from 'src/app/ui/util/error-message-util';

@Component({
  selector: 'app-requests-code',
  templateUrl: './requests-code.component.html',
  styleUrls: ['./requests-code.component.css']
})
export class RequestsCodeComponent implements OnInit {

  email: string = '';
  fullname: string = '';
  loading: boolean = false
  submit: boolean = false
  visibleDialog: boolean = true

  @Input()
  showBackLogin: boolean = false;

  formGroup: FormGroup = this._formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    fullname: ['', Validators.required]
  })

  constructor(private _router: Router,
    private _formBuilder: FormBuilder,
    private _messageService: MessageService,
    private _invitationCodeService: InvitationCodeGateway,
    public translate: TranslateService){
  }
  ngOnInit(): void {
  }

  generateCode() {
    if (this.fullname && this.email) {
      this.submit = true
      if(this.formGroup.valid) {
        let request: InvitationCodeCreate = {
          email: this.email,
          fullname: this.fullname,
          language: this.translate.currentLang ?? "en",
          isRequest: true
        }

        this.loading = true
        this._invitationCodeService.requestCode(request).subscribe({
          next: (v) => {
            this._messageService.add({ severity: 'success', summary: this.translate.instant('MessageService.Summary.SuccessAction'), detail: this.translate.instant('MessageService.InvitationCode.CreateSuccess') })
            this.submit = false
            this.loading = false
          },
          error: (e) => {
            console.log(e)
            this.submit = false
            let errorMessage = ''
            const lang = this.translate.currentLang
            switch(lang) {
              case 'es':
                errorMessage = e.error.messageEs
                break
              case 'en':
                errorMessage = e.error.messageEn
                break
              default:
                errorMessage = e.error.messageEn
            }
            if(e.status === 500){
              this._messageService.add({ severity: 'error', summary: this.translate.instant('MessageService.Summary.FailedAction'), detail: this.translate.instant('MessageService.InvitationCode.CreateError') })
            }
            else {
              this._messageService.add({ severity: 'error', summary: this.translate.instant('MessageService.Summary.FailedAction'), detail: errorMessage });
            }
            this.loading = false
          },
          complete: () =>  {
            console.info('InvitationCode generated successfully')
          }
        })
      }
      else {
        this.loading = false
      }
    }
  }

  getErrorMessage(fieldName: string): string {
    return ErrorMessageUtil.getErrorMessage(this.formGroup, this.translate, fieldName)
  }

  onCancel() {
    this._router.navigate(['/'+Routes.login])
  }

}
