import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
//import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-comex-virtual-page',
  templateUrl: './comex-virtual-page.component.html',
  styleUrls: ['./comex-virtual-page.component.css'],
  providers: [MessageService]
})
export class ComexVirtualPageComponent {

  constructor(
    //private _activatedRoute: ActivatedRoute
  ){
  }

  //ngOnInit() {
  //  this._activatedRoute.fragment.subscribe((fragment: string | null) => {
  //    if (fragment) this.jumpToSection(fragment);
  //  });
  //}
  //jumpToSection(section: string | null) {
  //  console.log("section::: ",section)
  //  if (section) document.getElementById(section)?.scrollIntoView({behavior: 'smooth'});
  //}



}
