import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AddNegotiation, NewNegotiation, OfferNegotiationInformation } from "src/app/domain/models/OfferNegotiation";

@Injectable({providedIn: 'root'})
export abstract class OfferNegotiationGateway {
  abstract create(negotiation: NewNegotiation): Observable<OfferNegotiationInformation>
  abstract addNegociation(negotiation: AddNegotiation): Observable<OfferNegotiationInformation>
  abstract getAllByProfile(username: string, searchBy: string, filterStatus: string): Observable<OfferNegotiationInformation[]>
  abstract getById(offerNegotiationId: number): Observable<OfferNegotiationInformation>
  abstract approve(offerNegotiationDetailId: number): Observable<OfferNegotiationInformation>
  abstract reject(offerNegotiationId: number): Observable<OfferNegotiationInformation>
}
