import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({providedIn: 'root'})
export abstract class DocumentServiceGateway {
  abstract getOperationDocument(operationDocumentId: number): Observable<any>
  abstract getProductCertificateDocument(productCertificateId: number, importOrderId: any): Observable<any>
  abstract getExportOfferDocument(exportOfferDocumentId: number, importOrderId: any): Observable<any>
  abstract getProductCertificateDocument2(productCertificateId: number): Observable<any>
  abstract getExportOfferDocument2(exportOfferDocumentId: number): Observable<any>
  abstract getQCReviewDocument(importOrderDetailId: number): Observable<any>
  abstract getQCConsolidationDocument(importOrderDetailId: number): Observable<any>
  abstract getPaymentDocument(paymentId: number): Observable<any>
  abstract getFrozenFruitQCReviewDocument(importOrderDetailId: any): Observable<any>
  abstract getFrozenFruitQCConsolidationDocument(importOrderDetailId: any): Observable<any>
  abstract getFrozenFruitPesticideAnalysisDocument(importOrderDetailId: any): Observable<any>
  abstract getFrozenFruitHeavyMetalsAnalysisDocument(importOrderDetailId: any): Observable<any>
  abstract getFrozenFruitMicrobiologicalAnalysisDocument(importOrderDetailId: any): Observable<any>
  abstract getFrozenFruitWaterAnalysisDocument(importOrderDetailId: any): Observable<any>
  abstract getOriginQCReviewDocument(reviewGuid: string): Observable<any>
  abstract getOriginQCConsolidationDocument(reviewGuid: string): Observable<any>
  abstract getExportInvoiceDocument(importOrderDetailId: any): Observable<any>
  abstract getImportOrderDetailDocument(documentGuid: string): Observable<any>
  abstract getOCProgramRequestDocument(documentGuid: string, typeDocument: string): Observable<any>
  abstract getQCPaymentPdf(paymentQcId: string): Observable<any>

}
