<app-user-dashboard>
    <div style="margin-top: 30px">
        <p-card [header]="getCardHeader()" [subheader]="getCardSubheader()">
        
            <div id="step1" style="margin-top: 40px;" *ngIf="step == step1">
                <form [formGroup]="step1FormGroup">
                <p-progressBar [value]="25" color="#8c2b87"></p-progressBar>
                <h2>{{'ProgramRequestPage.Step1Title' | translate}}</h2>
                <p>{{'ProgramRequestPage.Step1Subtitle' | translate}}</p>

                <div class="p-fluid formgrid grid">
                  <div class="field col-12 md:col-6 lg:col-4" style="margin-top: 20px;">
                    <label for="exporterId" style="font-weight: bolder;">{{'ProgramRequestPage.ExporterLabel' | translate}} *</label>
                    <p-dropdown id="exporterId"
                      formControlName="exporterId"
                      [options]="exporters"
                      optionValue="id"
                      optionLabel="companyName"
                      [filter]="true"
                      filterBy="companyName"
                      [showClear]="false"
                      placeholder="{{'ProgramRequestPage.ExporterLabel' | translate}}"
                      (onChange)="onChangeImporter()">
                    </p-dropdown>
                    <small *ngIf="submit && step1FormGroup.controls['exporterId'].invalid" class="p-error">{{'exporterId' | formErrorMessage: step1FormGroup}}</small>
                  </div>
                  <div class="field col-12 md:col-6 lg:col-4" style="margin-top: 20px;">
                    <label for="consigneeId" style="font-weight: bolder;">{{'DirectSaleFormPage.ConsigneeFieldLabel' | translate}} *</label>
                    <p-dropdown id="consigneeId"
                      formControlName="consigneeId"
                      [options]="consignees"
                      optionValue="id"
                      optionLabel="companyName"
                      [filter]="true"
                      [readonly]="isEdit"
                      filterBy="companyName"
                      [showClear]="false"
                      placeholder="{{'DirectSaleFormPage.ConsigneeFieldLabel' | translate}}">
                    </p-dropdown>
                    <small *ngIf="submit && step1FormGroup.controls['consigneeId'].invalid" class="p-error">{{'consigneeId' | formErrorMessage: step1FormGroup}}</small>
                  </div>
                  <div class="field col-12 md:col-6 lg:col-4" style="margin-top: 20px;">
                    <label for="notifyId" style="font-weight: bolder;">{{'DirectSaleFormPage.NotifyFieldLabel' | translate}} *</label>
                    <p-dropdown id="notifyId"
                      formControlName="notifyId"
                      [options]="notifies"
                      optionValue="id"
                      optionLabel="companyName"
                      [filter]="true"
                      [readonly]="isEdit"
                      filterBy="companyName"
                      [showClear]="false"
                      placeholder="{{'DirectSaleFormPage.NotifyFieldLabel' | translate}}">
                    </p-dropdown>
                    <small *ngIf="submit && step1FormGroup.controls['notifyId'].invalid" class="p-error">{{'notifyId' | formErrorMessage: step1FormGroup}}</small>
                  </div>
                </div>

                <h2>{{'ProgramRequestPage.ProductTitle' | translate}}</h2>

                <div class="p-fluid formgrid grid main-form">
                  <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
                    <label for="species">{{'UserPages.UserImportRequest.NewImportRequest.SpecieFieldLabel' | translate}} *</label>
                    <p-dropdown id="species"
                      formControlName="speciesId"
                      [options]="species"
                      optionValue="id"
                      [filter]="true"
                      filterBy="name"
                      [showClear]="true"
                      placeholder="{{'UserPages.UserImportRequest.NewImportRequest.SpecieFieldPlaceholder' | translate}}"
                      (onChange)="loadSpeciesParams()">
                      <ng-template pTemplate="selectedItem">
                        <div class="flex align-items-center gap-2" *ngIf="selectedSpecies">
                            <img [src]="selectedSpecies.imageUrl" style="width: 18px" alt=""/>
                            <div>{{ getSpeciesName(selectedSpecies) }}</div>
                        </div>
                      </ng-template>
                      <ng-template let-specie pTemplate="item">
                          <div class="flex align-items-center gap-2">
                              <img [src]="specie.imageUrl" style="width: 18px" alt=""/>
                              <div>{{ getSpeciesName(specie) }}</div>
                          </div>
                      </ng-template>
                    </p-dropdown>
                    <small *ngIf="submitStep1 && step1FormGroup.controls['speciesId'].invalid" class="p-error">{{getErrorMessage(step1FormGroup, 'speciesId')}}</small>
                  </div>
                  <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
                    <label for="variety">{{'UserPages.UserImportRequest.NewImportRequest.VarietyFieldLabel' | translate}} *</label>
                    <p-dropdown id="variety"
                      formControlName="varietyId"
                      [options]="varieties"
                      optionValue="id"
                      [filter]="true"
                      filterBy="name"
                      [showClear]="true"
                      (onChange)="onChangeVariety()"
                      placeholder="{{'UserPages.UserImportRequest.NewImportRequest.VarietyFieldPlaceholder' | translate}}">
                      <ng-template pTemplate="selectedItem">
                        <div class="flex align-items-center gap-2" *ngIf="selectedVariety">
                          <img [src]="selectedVariety.urlIcon" style="width: 18px"/>
                          <div>{{ selectedVariety.name }}</div>
                        </div>
                      </ng-template>
                      <ng-template let-variety pTemplate="item">
                          <div class="flex align-items-center gap-2">
                              <img [src]="variety.urlIcon" style="width: 18px"/>
                              <div>{{ variety.name }}</div>
                          </div>
                      </ng-template>
                    </p-dropdown>
                    <small *ngIf="submitStep1 && step1FormGroup.controls['varietyId'].invalid" class="p-error">{{getErrorMessage(step1FormGroup, 'varietyId')}}</small>
                  </div>
                  <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
                    <label for="caseQuantityPerPallet">{{'UserPages.UserImportRequest.NewImportRequest.CasePerPalletsFieldLabel' |
                      translate}} *</label>
                    <p-inputNumber inputId="caseQuantityPerPallet" formControlName="caseQuantityPerPallet" [min]="1"
                      placeholder="{{'UserPages.UserImportRequest.NewImportRequest.CasePerPalletsFieldPlaceholder' | translate}}"></p-inputNumber>
                    <small *ngIf="submitStep1 && step1FormGroup.controls['caseQuantityPerPallet'].invalid" class="p-error">{{getErrorMessage(step1FormGroup, 'caseQuantityPerPallet')}}</small>
                  </div>
                  <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
                    <label for="listPrice">{{'UserPages.UserImportRequest.NewImportRequest.PricePerCase' | translate}} *</label>
                    <p-inputNumber inputId="listPrice" [min]="0" formControlName="listPrice" mode="currency" currency="USD" locale="en-US" placeholder="{{'UserPages.UserImportRequest.NewImportRequest.PricePerCase' | translate}}"></p-inputNumber>
                    <small *ngIf="submitStep1 && step1FormGroup.controls['listPrice'].invalid" class="p-error">{{getErrorMessage(step1FormGroup, 'listPrice')}}</small>
                  </div>
                  <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
                    <label for="palletQuantity">{{'UserPages.UserImportRequest.NewImportRequest.PalletsPerWeekFieldLabel' |
                      translate}} *</label>
                    <p-inputNumber inputId="palletQuantity" formControlName="palletQuantity" [useGrouping]="false" [min]="20"
                      placeholder="{{'UserPages.UserImportRequest.NewImportRequest.PalletsPerWeekFieldPlaceholder' | translate}}"></p-inputNumber>
                    <small *ngIf="submitStep1 && step1FormGroup.controls['palletQuantity'].invalid" class="p-error">{{getErrorMessage(step1FormGroup, 'palletQuantity')}}</small>
                  </div>
                  <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
                    <label for="incoterms">{{'UserPages.UserImportRequest.NewImportRequest.IncotermsFieldLabel' | translate}} *</label>
                    <p-dropdown inputId="incoterms" formControlName="incoterms" [options]="incotermsOptions" optionLabel="label"
                      optionValue="value" placeholder="{{'UserPages.UserImportRequest.NewImportRequest.IncotermsFieldPlaceholder' | translate}}"></p-dropdown>
                    <small *ngIf="submitStep1 && step1FormGroup.controls['incoterms'].invalid" class="p-error">{{getErrorMessage(step1FormGroup, 'incoterms')}}</small>
                  </div>
                  <div class="field col-12 md:col-3 lg:col-3">
                    <label for="paymentAgainstBL" style="font-weight: bolder;">{{ paymentBLTitle | translate }} </label>
                    <p-dropdown id="paymentAgainstBL" formControlName="paymentAgainstBL" [options]="paymentBLItems" optionLabel="label" optionValue="value" placeholder="{{'UserPages.UserExportOffers.NewExportOffer.PaymentAgainstBLLabel' | translate}}"></p-dropdown>
                    <small *ngIf="submit && step1FormGroup.controls['paymentAgainstBL'].invalid" class="p-error">{{getErrorMessage(step1FormGroup, 'paymentAgainstBL')}}</small>
                    <small *ngIf="showPaymentBLPercent">{{'UserPages.UserExportOffers.NewExportOffer.PaymentMethod' | translate}} {{finalPaymentPercent}}%</small>
                  </div>
                  <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
                    <label for="countryId">{{'UserPages.UserImportRequest.NewImportRequest.OriginCountryFieldLabel' | translate}}</label>
                    <p-dropdown inputId="countryId" id="countries" [options]="countriesOrigin"
                      formControlName="countryId" optionLabel="name" optionValue="id" [filter]="true" filterBy="name" [showClear]="true" placeholder="{{'UserPages.UserImportRequest.NewImportRequest.OriginCountryFieldPlaceholder' | translate}}" (onChange)="onChangeCountryFrom()">
                      <ng-template pTemplate="selectedItem">
                        <div class="flex align-items-center gap-2" *ngIf="selectedCountryFrom">
                          <img [src]="'../../../../assets/img/flags/' + selectedCountryFrom.code + '.png'" style="width: 18px" alt=""/>
                          <span>{{ selectedCountryFrom | countryDescription }}</span>
                        </div>
                      </ng-template>
                      <ng-template let-country pTemplate="item">
                        <div class="flex align-items-center gap-2">
                          <img [src]="'../../../../assets/img/flags/' + country.code + '.png'" style="width: 18px" alt=""/>
                          <div>{{ country | countryDescription }}</div>
                        </div>
                      </ng-template>
                    </p-dropdown>
                  </div>
                  <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
                    <label for="countryToId">{{'UserPages.UserImportRequest.NewImportRequest.DestinationCountryFieldLabel' | translate}} *</label>
                    <p-dropdown inputId="countryToId" id="countries" [options]="countries" formControlName="countryToId"
                      optionLabel="name" optionValue="id" (onChange)="loadDestinationPorts()" [filter]="true" filterBy="name" [showClear]="true" placeholder="{{'UserPages.UserImportRequest.NewImportRequest.DestinationCountryFieldPlaceholder' | translate}}" (onChange)="onChangeCountryTo()">
                      <ng-template pTemplate="selectedItem">
                        <div class="flex align-items-center gap-2" *ngIf="selectedCountryTo">
                          <img [src]="'../../../../assets/img/flags/' + selectedCountryTo.code + '.png'" style="width: 18px" alt=""/>
                          <span>{{ selectedCountryTo | countryDescription }}</span>
                        </div>
                      </ng-template>
                      <ng-template let-country pTemplate="item">
                        <div class="flex align-items-center gap-2">
                          <img [src]="'../../../../assets/img/flags/' + country.code + '.png'" style="width: 18px" alt=""/>
                          <div>{{ country | countryDescription }}</div>
                        </div>
                      </ng-template>
                    </p-dropdown>
                    <small *ngIf="submitStep1 && step1FormGroup.controls['countryId'].invalid" class="p-error">{{getErrorMessage(step1FormGroup, 'countryId')}}</small>
                  </div>
                  <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
                    <label for="portToId">{{'UserPages.UserImportRequest.NewImportRequest.DestinationPortFieldLabel' | translate}} *</label>
                    <p-dropdown inputId="portToId" id="ports" [options]="destinationPorts" formControlName="portToId"
                      optionLabel="name" optionValue="id" [filter]="true" filterBy="name" [showClear]="true"
                      placeholder="{{'UserPages.UserImportRequest.NewImportRequest.DestinationPortFieldPlaceholder' | translate}}"></p-dropdown>
                    <small *ngIf="submitStep1 && step1FormGroup.controls['portToId'].invalid" class="p-error">{{getErrorMessage(step1FormGroup, 'portToId')}}</small>
                  </div>
                </div>
                <div class="p-fluid formgrid grid main-form">
                  <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
                    <label for="importPurchaseOrderText">{{'ProgramRequestPage.ImporterOrderPurchaseLabel' | translate}}</label>
                    <input 
                    pInputText 
                    inputId="importPurchaseOrderText" 
                    formControlName="importPurchaseOrder" 
                    placeholder="{{'ProgramRequestPage.ImporterOrderPurchaseLabel' | translate}}"/>
                  </div>
                </div>

                <div class="col-12">
                  <p-fileUpload name="demoPdf[]"
                    #imagesUploader
                    (onSelect)="onSelectPdf($event, imagesUploader)"
                    (onRemove)="onRemovePdf($event, imagesUploader)"
                    (onClear)="onClearPdf($event, imagesUploader)"
                    [showUploadButton]="false"
                    [multiple]="true"
                    accept=".pdf"
                    [fileLimit]="1"
                    [files]="uploadPurchaseOrderPdf"
                    [maxFileSize]="3000000"
                    chooseLabel="Seleccionar"
                    cancelLabel="Cancelar">
                    <ng-template pTemplate="toolbar">
                      <p-button *ngIf="this.programRequestInfo?.programRequest?.importPurchaseOrderUrl" label="View file" pTooltip="View file" icon="pi pi-file-pdf" styleClass="p-button-outlined" (onClick)="viewOCDocument('purchaseOrder')"></p-button>
                    </ng-template>
                    <ng-template pTemplate="content">
                    </ng-template>
                  </p-fileUpload>
                </div>
              </form>

              <div class="grid" style="margin-top: 30px;">
                <div class="col-6 justify-content-start">
                  <p-button styleClass="p-button-outlined" label="{{'UserPages.UserExportOffers.NewExportOffer.BackButton' | translate}}" (onClick)="back()" [loading]="loading" [style]="{'width': '145px'}"></p-button>
                </div>
                <div class="col-6 flex justify-content-end">
                  <p-button [style]="{'width': '145px'}" label="{{'UserPages.UserExportOffers.NewExportOffer.NextButton' | translate}}" (onClick)="showStep2()" [loading]="loading"></p-button>
                </div>
              </div>
            </div>

            <div  id="step2" style="margin-top: 40px;" *ngIf="step == step2">
                <form [formGroup]="step2FormGroup">
                  <div class="p-fluid formgrid grid">
                    <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
                      <label for="netKilos">{{'UserPages.UserImportRequest.NewImportRequest.NetKiloFieldLabel' | translate}} *</label>
                      <input pInputText inputId="netKilos" [maxlength]="formLimit.netKilosLimit" formControlName="netKilos" placeholder="{{'UserPages.UserImportRequest.NewImportRequest.NetKiloFieldPlaceholder' | translate}}" />
                      <small *ngIf="submitStep2 && step2FormGroup.controls['netKilos'].invalid" class="p-error">{{getErrorMessage(step2FormGroup, 'netKilos')}}</small>
                  </div>
                  <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
                      <label for="boxType">{{'UserPages.UserImportRequest.NewImportRequest.BoxTypeFieldLabel' | translate}} *</label>
                      <p-dropdown inputId="boxType" formControlName="boxType" [options]="boxTypes" optionLabel="label"
                          optionValue="value" placeholder="{{'UserPages.UserImportRequest.NewImportRequest.BoxTypeFieldPlaceholder' | translate}}"></p-dropdown>
                      <small *ngIf="submitStep2 && step2FormGroup.controls['boxType'].invalid" class="p-error">{{getErrorMessage(step2FormGroup, 'boxType')}}</small>
                      </div>
                      <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
                      <label for="speciesSize">{{'UserPages.UserImportRequest.NewImportRequest.SpeciesSizeFieldLabel' | translate}}</label>
                      <input pInputText inputId="speciesSize"  [maxlength]="formLimit.speciesSizeLimit" formControlName="speciesSize" placeholder="{{'UserPages.UserImportRequest.NewImportRequest.SpeciesSizeFieldPlaceholder' | translate}}">
                      <small *ngIf="submitStep2 && step2FormGroup.controls['speciesSize'].invalid" class="p-error">{{getErrorMessage(step2FormGroup, 'speciesSize')}}</small>
                      </div>
                  <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
                      <label for="category">{{'UserPages.UserImportRequest.NewImportRequest.CategoryFieldLabel' | translate}}</label>
                      <input pInputText [maxlength]="formLimit.categoryLimit" inputId="category" formControlName="category" placeholder="{{'UserPages.UserImportRequest.NewImportRequest.CategoryFieldPlaceholder' | translate}}" />
                      <small *ngIf="submitStep2 && step2FormGroup.controls['category'].invalid" class="p-error">{{getErrorMessage(step2FormGroup, 'category')}}</small>
                  </div>
                  <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
                      <label for="basePallets">{{'UserPages.UserImportRequest.NewImportRequest.BasePalletsFieldLabel' | translate}}</label>
                      <p-dropdown inputId="basePallets" formControlName="basePallets" [options]="basePallets" optionLabel="label"
                          optionValue="value" placeholder="{{'UserPages.UserImportRequest.NewImportRequest.BasePalletsFieldPlaceholder' | translate}}">
                      </p-dropdown>
                      <small *ngIf="submitStep2 && step2FormGroup.controls['basePallets'].invalid" class="p-error">{{getErrorMessage(step2FormGroup, 'basePallets')}}</small>
                  </div>
                  </div>
                    
                    <h2>{{'UserPages.UserImportRequest.NewImportRequest.PageTitle4' | translate}}</h2>
                    <div class="p-fluid formgrid grid">
                        <div class="field col-12 md:col-4 lg:col-3">
                            <label for="certificateType">{{'UserPages.UserProductCertificates.Form.CertificateTypeLabel' | translate}} *</label>
                            <p-multiSelect formControlName="productCertificateType" [(ngModel)]="certificateTypesSelected" [options]="certificateTypes" placeholder="{{'UserPages.UserProductCertificates.Form.SelectText' | translate}}" optionLabel="name" optionValue="id" option [showHeader]="false" (onChange)="filterMultiselect($event.value)" required>
                            </p-multiSelect>
                            <small *ngIf="submitStep2 && step2FormGroup.controls['productCertificateType'].invalid" class="p-error">{{getErrorMessage(step2FormGroup, 'productCertificateType')}}</small>
                        </div>
                        <div class="field col-12 md:col-4 lg:col-3">
                            <label for="otherCertificate">{{'UserPages.UserProductCertificates.Form.OtherLabel' | translate}}</label>
                            <input pInputText inputId="otherCertificate"  [maxlength]="formLimit.otherCertificateLimit" formControlName="otherCertificate" placeholder="{{'UserPages.UserProductCertificates.Form.OtherLabel' | translate}}"/>
                            <small *ngIf="submitStep2 && step2FormGroup.controls['otherCertificate'].invalid" class="p-error">{{getErrorMessage(step2FormGroup, 'otherCertificate')}}</small>
                        </div>
                    </div>
                    <h2>{{'UserPages.UserImportRequest.NewImportRequest.PageTitle5' | translate}}</h2>
                    <div class="p-fluid formgrid grid">
                        <div class="field col-12 md:col-4 lg:col-3">
                            <label for="packaging">{{'UserPages.UserImportRequest.NewImportRequest.PackagingLabel' | translate}} *</label>
                            <p-multiSelect formControlName="packaging" [(ngModel)]="packagingSelected" [options]="packagingList" placeholder="{{'UserPages.UserImportRequest.NewImportRequest.SelectText' | translate}}" optionLabel="name" optionValue="id" option [showHeader]="false" (onChange)="filterPackagingMultiselect($event.value)" required>
                            </p-multiSelect>
                            <small *ngIf="submitStep2 && step2FormGroup.controls['packaging'].invalid" class="p-error">{{getErrorMessage(step2FormGroup, 'packaging')}}</small>
                        </div>
                        <div class="field col-12 md:col-4 lg:col-3">
                            <label for="otherPackaging">{{'UserPages.UserImportRequest.NewImportRequest.OtherLabel' | translate}}</label>
                            <input pInputText inputId="otherPackaging"  [maxlength]="formLimit.otherPackagingLimit" formControlName="otherPackaging" placeholder="{{'UserPages.UserImportRequest.NewImportRequest.OtherLabel' | translate}}"/>
                            <small *ngIf="submitStep2 && step2FormGroup.controls['otherPackaging'].invalid" class="p-error">{{getErrorMessage(step2FormGroup, 'otherPackaging')}}</small>
                        </div>
                    </div>

                      <h2>
                        {{'UserPages.UserImportRequest.NewImportRequest.PageTitle3' | translate}}
                      </h2>
                      <div class="p-fluid formgrid grid">
                        <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
                          <label>{{'UserPages.UserImportRequest.NewImportRequest.StartWeekFieldLabel' | translate}} *</label>
                          <p-inputNumber id="startWeek" formControlName="startWeek" [showButtons]="true" [useGrouping]="false" [min]="1"
                            [max]="52" placeholder="{{'UserPages.UserImportRequest.NewImportRequest.StartWeekFieldPlaceholder' | translate}}" [required]="true"/>
                          <small *ngIf="submitStep2 && step2FormGroup.controls['startWeek'].invalid"
                            class="p-error">{{getErrorMessage(step2FormGroup, 'startWeek')}}</small>
                        </div>
                        <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
                          <label>{{'UserPages.UserImportRequest.NewImportRequest.StartYearFieldLabel' | translate}} *</label>
                          <p-dropdown id="startWeekYear" formControlName="startWeekYear" [options]="years" optionLabel="label"
                            optionValue="value" [showClear]="true" placeholder="{{'UserPages.UserImportRequest.NewImportRequest.StartYearFieldPlaceholder' | translate}}" [required]="true"></p-dropdown>
                          <small *ngIf="submitStep2 && step2FormGroup.controls['startWeekYear'].invalid"
                            class="p-error">{{getErrorMessage(step2FormGroup, 'startWeekYear')}}</small>
                        </div>
                        <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
                          <label>{{'UserPages.UserImportRequest.NewImportRequest.EndWeekFieldLabel' | translate}}</label>
                          <p-inputNumber id="endWeek" formControlName="endWeek" [showButtons]="true" [useGrouping]="false" [min]="1"
                            [max]="52" placeholder="{{'UserPages.UserImportRequest.NewImportRequest.EndWeekFieldPlaceholder' | translate}}" />
                        </div>
                        <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
                          <label>{{'UserPages.UserImportRequest.NewImportRequest.EndYearFieldLabel' | translate}}</label>
                          <p-dropdown id="endWeekYear" formControlName="endWeekYear" [options]="years" optionLabel="label"
                            optionValue="value" [showClear]="true" placeholder="{{'UserPages.UserImportRequest.NewImportRequest.EndYearFieldPlaceholder' | translate}}"></p-dropdown>
                        </div>
              
                        <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
                          <label>{{'UserPages.UserImportRequest.NewImportRequest.TotalWeeksText' | translate}}: </label>
                          <span style="font-weight: bolder; font-size: 1.5em;"> {{totalWeeks}}</span>
                        </div>
                        <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
                          <label>{{'UserPages.UserImportRequest.NewImportRequest.TotalPalletsScheduled' | translate}}: </label>
                          <span style="font-weight: bolder; font-size: 1.5em;"> {{totalPallets}}</span>
                        </div>
                        <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
                          <label>{{'UserPages.UserImportRequest.NewImportRequest.TotalContainersPerWeek' | translate}}: </label>
                          <span style="font-weight: bolder; font-size: 1.5em;"> {{containersPerWeek}}</span>
                        </div>
                        <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
                          <label>{{'UserPages.UserImportRequest.NewImportRequest.TotalContainers' | translate}}: </label>
                          <span style="font-weight: bolder; font-size: 1.5em;"> {{totalContainers}}</span>
                        </div>
                      </div>

                      <div class="col-12">
                        <h2>{{'ProgramRequestPage.SheetTitle' | translate}}</h2>
                        <p>{{'ProgramRequestPage.SheetSubtitle' | translate}}</p>
                        <p-fileUpload name="sheetPdf[]"
                            #imagesUploader
                            (onSelect)="onSelectSheetPdf($event, imagesUploader)"
                            (onRemove)="onRemoveSheetPdf($event, imagesUploader)"
                            (onClear)="onClearSheetPdf($event, imagesUploader)"
                            [showUploadButton]="false"
                            [multiple]="true"
                            accept=".pdf"
                            [fileLimit]="1"
                            [files]="uploadPdf"
                            [maxFileSize]="3000000"
                            chooseLabel="Seleccionar"
                            cancelLabel="Cancelar">
                            <ng-template pTemplate="toolbar">
                              <p-button *ngIf="this.programRequestInfo?.programRequest?.dataSheetUrl" label="View file" pTooltip="View file" icon="pi pi-file-pdf" styleClass="p-button-outlined" (onClick)="viewOCDocument('sheet')"></p-button>
                            </ng-template>
                            <ng-template pTemplate="content">
                            </ng-template>
                        </p-fileUpload>
                      </div>
                </form>
                <div class="grid" style="margin-top: 30px;">
                  <div class="col-6">
                    <div class="col-6 flex justify-content-start ">
                      <p-button styleClass="p-button-outlined" label="{{'UserPages.UserExportOffers.NewExportOffer.BackButton' | translate}}" (onClick)="showStep1()" [loading]="loading" [style]="{'width': '145px'}"></p-button>
                    </div>
                  </div>
                  <div class="col-6 flex justify-content-end">
                    <p-button [style]="{'width': '145px'}" label="{{'UserPages.UserExportOffers.NewExportOffer.NextButton' | translate}}" (onClick)="showStep3()" [loading]="loading"></p-button>
                  </div>
              </div>
            </div>

            <div id="step3" *ngIf="step == step3">
                <h2 style="color: #8c2b87;">{{'UserPages.UserExportOffers.NewExportOffer.StepLabel' | translate}} 3 {{'UserPages.UserExportOffers.NewExportOffer.StepOfLabel' | translate}} 3</h2>
                  <p-progressBar [value]="100" color="#8c2b87"></p-progressBar>
                  <ng-template pTemplate="header">
                  <div class="flex justify-content-between flex-wrap align-items-center" style="padding: 15px;">
                      <h2>{{'UserPages.UserImportRequest.NewImportRequest.Step2Title' | translate}}</h2>
                  </div>
                  </ng-template>
                  <div class="col-12">
                    <p-fileUpload name="demo[]"
                      #imagesUploader
                      (onSelect)="onSelectImage($event, imagesUploader)"
                      (onRemove)="onRemoveImage($event, imagesUploader)"
                      (onClear)="onClearImageUploader($event, imagesUploader)"
                      [showUploadButton]="false"
                      [multiple]="true"
                      accept=".jpg, .jpeg, .png"
                      [fileLimit]="3"
                      [files]="uploadedImages"
                      [maxFileSize]="3000000"
                      chooseLabel="Seleccionar"
                      cancelLabel="Cancelar">
                      <ng-template pTemplate="toolbar">
                      <div class="py-3"><h2>{{'UserPages.UserImportRequest.NewImportRequest.LoadImagesFieldLabel' | translate}}</h2></div>
                      </ng-template>
                      <ng-template pTemplate="content">
          
                      </ng-template>
                    </p-fileUpload>
                  </div>
                  <p-table [value]="pictures">
                    <ng-template pTemplate="caption">
                      <div class="flex justify-content-start align-items-center gap-4">
                        <p-fileUpload #imagesUploader
                          mode="basic"
                          chooseLabel="{{'UserPages.UserImportRequest.EditImportRequest.LoadButton' | translate}}"
                          [fileLimit]="1"
                          [auto]="true"
                          accept=".jpg, .jpeg, .png"
                          [maxFileSize]="3000000"
                          (onSelect)="onSelectImage($event, imagesUploader)"
                          [disabled]="pictures.length === 3"></p-fileUpload>
                      </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                    </ng-template>
                    <ng-template pTemplate="body" let-picture let-rowIndex="rowIndex">
                      <tr>
                        <td>
                          <img [src]="getImageUrl(picture)"
                            style="border-radius: 7px; width: 100%; max-width: 100px; height: auto;" />
                        </td>
                        <td>
                          {{getImageName(picture)}}
                        </td>
                        <td>
                          <div class="flex justify-content-end align-items-center gap-4">
                            <p-button icon="pi pi-times" (click)="onRemoveUploadImage(rowIndex)"></p-button>
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
          
                  <div class="grid" style="margin-top: 30px;">
                  <div class="col-6 flex justify-content-start">
                      <p-button [style]="{'width': '145px'}" styleClass="p-button-outlined"  label="{{'UserPages.UserExportOffers.NewExportOffer.BackButton' | translate}}" (onClick)="showStep2()" [loading]="loading"></p-button>
                  </div>
                  <div class="col-6 flex justify-content-end">
                          <p-button [style]="{'width': '145px'}" label="{{'UserPages.UserExportOffers.NewExportOffer.FinishButton' | translate}}" (onClick)="save()" [loading]="loading"></p-button>
                  </div>
                </div>
              </div>
              <div  id="finalStep" *ngIf="step == finalStep">
                <h2 style="text-align: center;">{{'ProgramRequestPage.ProgramRequestFinalStepTitle' | translate}}</h2>
                <div style="margin-top: 20px;" class="flex align-items-center justify-content-center flex-column">
                  <img src="../../../../../assets/img/create-export-offer-aditional-icon.png" alt="Icon" style="border-radius: 7px; width: 100%; max-width: 300px; height: auto;"/>
                  <p>{{'ProgramRequestPage.ProgramRequestFinalStepDescription' | translate}}</p>
                </div>
                <div style="margin-top: 20px;" class="flex align-items-center justify-content-center flex-column gap-4">
                  <p-button label="{{'ImportRequestFinalStep.FinalStepSkipAdditionalInfoButtonLabel' | translate}}" styleClass="p-button-outlined" (onClick)="goToCreateProgramRequest()"></p-button>
                </div>
            </div>
        </p-card>
    </div>
</app-user-dashboard>
