<app-user-dashboard [userMenuActiveItemIndex]="0" [activeItem]="'export-offers'">
  <div *ngIf="exportOffer">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="breadcrumbHomeItem"></p-breadcrumb>
    <div style="margin-top: 30px">
      <p-card>
        <form [formGroup]="formGroup">
          <h2 style="color: #8c2b87;">{{'UserPages.UserExportOffers.EditExportOffer.AdditionaInfoTitle' | translate}}</h2>
          <p-progressBar [value]="100" color="#8c2b87"></p-progressBar>
          <div class="p-fluid formgrid grid mr-2 ml-2" style="margin-top: 40px;">
            <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
              <label for="boxType" style="font-weight: bolder;">{{'UserPages.UserExportOffers.EditExportOffer.BoxTypeFieldLabel' | translate}}</label>
              <p-dropdown inputId="boxType" formControlName="boxType" [options]="boxTypes" optionLabel="label" optionValue="value" placeholder="{{'UserPages.UserExportOffers.EditExportOffer.BoxTypeFieldPlaceholder' | translate}}"></p-dropdown>
            </div>
            <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 40px;">
              <label for="caseSize" style="font-weight: bolder;">{{'UserPages.UserExportOffers.EditExportOffer.CaseSize' | translate}}</label>
              <input pInputText inputId="caseSize" [maxlength]="formLimit.caseSizeLimit" formControlName="caseSize" placeholder="{{'UserPages.UserExportOffers.EditExportOffer.CaseSize' | translate}}"/>
            </div>
            <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
              <label for="color" style="font-weight: bolder;">{{'UserPages.UserExportOffers.EditExportOffer.ColorFieldLabel' | translate}}</label>
              <p-dropdown id="color"
                formControlName="color"
                [options]="colors"
                optionLabel="label"
                optionValue="value"
                placeholder="{{'UserPages.UserExportOffers.EditExportOffer.ColorFieldPlaceholder' | translate}}"
                [showClear]="true"
                (onChange)="onChangeColor()">
                <ng-template pTemplate="selectedItem">
                  <div class="flex align-items-center gap-2" *ngIf="selectedColor">
                    <div><span [style]="{'background-color': selectedColor.value}" class="border-circle w-6rem h-6rem">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> {{ selectedColor.label }}</div>
                  </div>
                </ng-template>
                <ng-template let-color pTemplate="item">
                    <div class="flex align-items-center gap-2">
                      <div><span [style]="{'background-color': color.value}" class="border-circle w-6rem h-6rem">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> {{ color.label }}</div>
                    </div>
                </ng-template>
              </p-dropdown>
            </div>
            <!-- <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
              <label for="sizeMillimeters" style="font-weight: bolder;">{{'UserPages.UserExportOffers.EditExportOffer.SpeciesSizeMillimetersFieldLabel' | translate}}</label>
              <p-inputNumber inputId="sizeMillimeters" formControlName="sizeMillimeters" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2" suffix=" mm" locale="en-US" placeholder="{{'UserPages.UserExportOffers.EditExportOffer.SpeciesSizeMillimetersFieldPlaceholder' | translate}}"></p-inputNumber>
            </div> -->
            <!-- <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
              <label for="sizeMillimeters" style="font-weight: bolder;">{{'UserPages.UserExportOffers.EditExportOffer.SpeciesSizeMillimetersFieldLabel' | translate}}</label>
              <p-inputMask mask="99?.99" inputId="sizeMillimeters" formControlName="sizeMillimeters" placeholder="00.00"></p-inputMask>
            </div> -->

            <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
              <label style="font-weight: bolder;" for="sizeMillimeters">{{'DirectSaleFormPage.SpeciesSizeMillimetersFieldLabel' | translate}}</label>
              <p-inputNumber inputId="sizeMillimeters" formControlName="sizeMillimeters" [min]="0" mode="decimal" [maxFractionDigits]="2"  [minFractionDigits]="2" suffix=" mm" locale="en-US" placeholder="{{'UserPages.UserExportOffers.EditExportOffer.SpeciesSizeMillimetersFieldPlaceholder' | translate}}"></p-inputNumber>
            </div>

            <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
              <label for="basePallets" style="font-weight: bolder;">{{'UserPages.UserExportOffers.EditExportOffer.BasePalletsFieldLabel' | translate}}</label>
              <p-dropdown inputId="basePallets" formControlName="basePallets" [options]="basePallets" optionLabel="label" optionValue="value" placeholder="{{'UserPages.UserExportOffers.EditExportOffer.BasePalletsFieldPlaceholder' | translate}}"></p-dropdown>
            </div>
            <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
              <label for="isQualityControl" style="font-weight: bolder;">{{'UserPages.UserExportOffers.EditExportOffer.HasControlQualityFieldLabel' | translate}}</label>
              <p-dropdown id="isQualityControl" formControlName="isQualityControl" [options]="qualityControlOptions" optionLabel="label" optionValue="value" placeholder="{{'UserPages.UserExportOffers.EditExportOffer.HasControlQualityFieldPlaceholder' | translate}}" (onChange)="showUploadQualityDoc()"></p-dropdown>
              <p-button label="{{'UserPages.UserExportOffers.EditExportOffer.UploadQualityDocument' | translate}}" *ngIf="buttonQualityDoc" class="mr-3 p-button-raised" [style]="{'margin-top': '5px'}" (click)="visibleUploadQualityDoc = true" icon="pi pi-upload"></p-button>
            </div>
            <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
              <label for="qualityControlDate" style="font-weight: bolder;">{{'UserPages.UserExportOffers.EditExportOffer.QualityControlDateFieldLabel' | translate}}</label>
              <p-calendar
                inputId="qualityControlDate"
                formControlName="qualityControlDate"
                appendTo="body"
                [showButtonBar]="true"
                [showIcon]="true"
                placeholder="{{'UserPages.UserExportOffers.EditExportOffer.QualityControlDateFieldPlaceholder' | translate}}"
                [showWeek]="true"
                [firstDayOfWeek]="1"></p-calendar>
            </div>
            <div class="field col-12 md:col-4 lg:col-3" style="margin-bottom: 40px;">
              <label for="marks" style="font-weight: bolder;">{{'UserPages.UserExportOffers.EditExportOffer.HasMarksFieldLabel' | translate}}</label>
              <p-dropdown id="marks" formControlName="marks" [options]="marksOptions" optionLabel="label" optionValue="value" placeholder="{{'UserPages.UserExportOffers.EditExportOffer.HasMarksFieldPlaceholder' | translate}}"></p-dropdown>
            </div>
          </div>
          <div class="grid" style="margin-top: 30px;">
            <div class="col-6"></div>
            <div class="col-6">
              <div class="p-fluid formgrid grid">
                <div class="col-6"></div>
                <div class="col-6">
                  <p-button label="{{'UserPages.UserExportOffers.NewExportOffer.SaveButtonLabel' | translate}}" (onClick)="save()" [loading]="loading"></p-button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </p-card>
    </div>
  </div>
</app-user-dashboard>

<p-toast></p-toast>

<p-dialog [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleUploadQualityDoc" [style]="{width: '80%'}">
  <ng-template pTemplate="header">
    <h3>{{'UserPages.UserExportOffers.EditExportOffer.UploadQualityDocument' | translate}}</h3>
  </ng-template>
  <p-fileUpload name="sheetPdf[]"
      #docUploader
      (onSelect)="onSelect($event, docUploader)"
      (onRemove)="onRemove($event, docUploader)"
      (onClear)="onClear($event, docUploader)"
      [showUploadButton]="false"
      [multiple]="false"
      accept=".pdf"
      [fileLimit]="1"
      [files]="qualityDocument"
      [maxFileSize]="3000000"
      chooseLabel="{{'UserPages.UserExportOffers.EditExportOffer.SelectImageButton' | translate}}">
      <ng-template pTemplate="toolbar">
        <p-button *ngIf="this.exportOffer?.documents?.length" label="{{'UserPages.UserExportOffers.EditExportOffer.ViewQualityDocument' | translate}}" pTooltip="{{'UserPages.UserExportOffers.EditExportOffer.ViewQualityDocument' | translate}}" icon="pi pi-file-pdf" styleClass="p-button-outlined" (onClick)="getExportOfferQualityDocument(this.exportOffer?.documents)"></p-button>
      </ng-template>
      <ng-template pTemplate="content">
      </ng-template>
  </p-fileUpload>
  <ng-template pTemplate="footer">
      <p-button label="{{ 'UserPages.UserExportOffers.NewExportOffer.SaveButtonLabel' | translate }}" (onClick)="visibleUploadQualityDoc = false" [loading]="loading"></p-button>
  </ng-template>
</p-dialog>