<div>
    <div class="root  ">
      <div class="container">
        <div>
          <div class="number">
            4
          </div>
          <div class="number">
            0
          </div>
          <div class="number">
            4
          </div>
        </div>
        <div class="description">
          {{'OrderNotFoundPage.DescriptionText' | translate}}
        </div>
        <div
          class="sectionInfo">
          <div>
            <div class="descriptionError">
              {{'OrderNotFoundPage.DescriptionErrorText' | translate}}
            </div>
          </div>
          <div>
            <p-button label="{{'OrderNotFoundPage.ButtonLabel' | translate}}" (onClick)="redirect()"></p-button>
          </div>
        </div>
      </div>
      <div class="footer-logo">
        <img src="../../../../assets/img/fruzty-logo-slogan.png" width="250" height="111" alt=""/>
      </div>
    </div>
  </div>
