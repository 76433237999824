import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { OfferNegotiationGateway } from 'src/app/application/gateway/OfferNegotiationGateway';
import { ProfileGateway } from 'src/app/application/gateway/ProfileGateway';
import { ExportOffer } from 'src/app/domain/models/ExportOffer';
import { OfferNegotiationInformation } from 'src/app/domain/models/OfferNegotiation';
import { BasicUser } from 'src/app/domain/models/User';
import { Routes } from 'src/app/infrastructure/routes';
import { ExportOfferUtils } from 'src/app/ui/util/export-offer-utils';

@Component({
  selector: 'app-user-bargaining',
  templateUrl: './user-bargaining.component.html',
  styleUrls: ['./user-bargaining.component.css'],
  providers: [MessageService]
})
export class UserBargainingComponent implements OnInit {

  tabMenuItems: MenuItem[] = []

  loading: boolean = false

  negotiations: OfferNegotiationInformation[] = []

  searchBy: string = 'profile'

  profile: BasicUser | undefined

  constructor(private _router: Router,
    private _messageService: MessageService,
    private _authService: AuthGateway,
    private _profileService: ProfileGateway,
    private _negotiationService: OfferNegotiationGateway,
    public translate: TranslateService) {

      this.profile = _authService.getUser()
      this.buildTabMenu()
  }

  ngOnInit(): void {
    if(this.profile) {
      if(this.profile.profileType == 'Exporter')
          this.searchBy = 'offerOwner'

      this.loadNegotiations('Open')
    }
  }

  buildTabMenu() {
    let openLabel = ''
    let rejectLabel = ''
    let acceptLabel = ''

    if(this.profile) {
      switch(this.profile.lang) {
        case 'en':
          openLabel = 'Open'
          rejectLabel = 'Reject'
          acceptLabel = 'Accept'
          break
        case 'es':
          openLabel = 'Abiertas'
          rejectLabel = 'Rechazadas'
          acceptLabel = 'Aceptadas'
          break
      }
    }
    this.tabMenuItems = [
        {label: openLabel, command: () => this.loadNegotiations('Open')},
        {label: rejectLabel, command: () => this.loadNegotiations('Close')},
        {label: acceptLabel, command: () => this.loadNegotiations('Sell')}]
  }

  loadNegotiations(statusFilter: string) {
    this.loading = true
    this._negotiationService.getAllByProfile(this._authService.getUsername(), this.searchBy, statusFilter).subscribe({
      next: (v) => {
        this.negotiations = v
        this.loading = false
      },
      error: (e) => {
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Negotiation.ManyRetrieveError') });

        this.loading = false
        console.log(e)
      },
      complete: () =>  {
        this.loading = false
        console.info('Offer Negotiations query successfully')
      }
    })
  }

  getExportOfferImageUrl(offer: ExportOffer) {
    return ExportOfferUtils.getImageUrl(offer, offer.offerPictures,  0)
  }

  getTotalPrice(negotiation: OfferNegotiationInformation): number {
    if(negotiation && negotiation.offerNegotiationDetails[0]) {
      return negotiation.exportOffer.caseQuantityPerPallet * negotiation.offerNegotiationDetails[0].unitPrice * negotiation.offerNegotiationDetails[0].pallets
    }
    return 0
  }

  goToBargainingDetail(negotiationId: number) {
    this._router.navigate(['/' + Routes.bargainingDetail, negotiationId])
  }
}
