import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { ImportOrderGateway } from 'src/app/application/gateway/ImportOrderGateway';
import { ProfileGateway } from 'src/app/application/gateway/ProfileGateway';
import { ImportOrderInformation } from 'src/app/domain/models/ImportOrder';
import { BasicUser } from 'src/app/domain/models/User';
import { Routes } from 'src/app/infrastructure/routes';
import { ImportOrderStatus, OrderUtils } from 'src/app/ui/util/order-utils';

@Component({
  selector: 'app-user-import-orders-pending',
  templateUrl: './user-import-orders-pending.component.html',
  styleUrls: ['./user-import-orders-pending.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class UserImportOrdersPendingComponent {
}
