import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ExportOffer } from "src/app/domain/models/ExportOffer";
import { ImportAndProgramByExportOffer, ImportRequest, ImportRequestInformation, ImportRequestMarketplace, ImportRequestOffer, NewImportRequest } from "src/app/domain/models/ImportRequest";


@Injectable({providedIn: 'root'})
export abstract class ImportRequestGateway {
    abstract getAllForMarketplace(importRequestMarketplace: ImportRequestMarketplace): Observable<ImportRequestInformation[]>
    abstract getAllForMarketplaceByDefault(): Observable<ImportRequestInformation[]>
    abstract getAllByUsername(): Observable<ImportRequestInformation[]>
    abstract create(offer: NewImportRequest): Observable<ImportRequest>
    abstract getById(exportOfferId: number): Observable<ImportRequestInformation>
    abstract update(offer: ImportRequest): Observable<ImportRequest>
    abstract desactive(importOrderId: number): Observable<any>
    abstract active(importOrderId: number): Observable<any>
    abstract getAllOfferForRequest(importRequestId: number): Observable<ExportOffer[]>
    abstract AddExportOffer(importRequestId: number, offerId: number): Observable<ImportRequestOffer>
    abstract createImportRequestAndProgramByOffer(importAndProgramByExportOffer: ImportAndProgramByExportOffer) : Observable<any>
    abstract getByGuid(exportOfferGuid: string): Observable<ImportRequestInformation>
    abstract getImportRequestCodeQrUrl(guid: string): Observable<any>

}
