import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { CountryGateway } from 'src/app/application/gateway/CountryGateway';
import { FridgeStorageGateway } from 'src/app/application/gateway/FridgeStorageGateway';
import { Country } from 'src/app/domain/models/Country';
import { FridgeStorage } from 'src/app/domain/models/FridgeStorage';

@Component({
  selector: 'app-export-offer-filters',
  templateUrl: './export-offer-filters.component.html',
  styleUrls: ['./export-offer-filters.component.css']
})
export class ExportOfferFiltersComponent implements OnInit {

  @Output() filtersApplied = new EventEmitter<FormGroup>();
  @Output() closeSidebar = new EventEmitter<void>();

  selectedMarketCountry: Country | undefined

  countries: Country[] = []
  fridgeStorages: FridgeStorage[] = []

  _formGroupFilter: FormGroup = this._formBuilder.group({
    harvestDate: [null],
    netKilos: [''],
    marketCountryId: [null],
    fridgeStorageId: [null],
    speciesSize: [''],
    price: ['']
  })

  constructor(
    private _messageService: MessageService,
    private _formBuilder: FormBuilder,
    private _countryService: CountryGateway,
    private _fridgeStorageService: FridgeStorageGateway,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.loadCountries()
    this.loadFridgeStorages()
  }

  loadCountries() {
    this.countries = []
    this._countryService.getAll().subscribe({
      next: (v) => {
        this.countries = v
      },
      error: (e) => {
        console.log(e)
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Country.ManyRetrieveError') })
      },
      complete: () => console.info('Country query successfully')
    })
  }

  loadFridgeStorages() {
    this.fridgeStorages = []
    this._fridgeStorageService.getAllByStorageType(1).subscribe({
      next: (v) => {
        this.fridgeStorages = v
      },
      error: (e) => {
        console.log(e)
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.FridgeStorage.ManyRetrieveError') })
      },
      complete: () => console.info('Fridgestorage query successfully')
    })
  }

  onChangeMarketCountry() {
    this.selectedMarketCountry = this.countries.find(x => x.id === this._formGroupFilter.controls['marketCountryId'].value)
  }

  applyFilters() {
    this.filtersApplied.emit(this._formGroupFilter);
    this.closeSidebar.emit();
  }

}
