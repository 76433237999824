import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TreeNode } from 'primeng/api';
import { Routes } from 'src/app/infrastructure/routes';

@Component({
  selector: 'app-signup-page-company',
  templateUrl: './signup-page-company.component.html',
  styleUrls: ['./signup-page-company.component.css']
})
export class SignupPageCompanyComponent {

  uploadedFiles: any[] = []
  products: any
  selectedProducts: TreeNode | undefined
  submit: boolean = false

  formGroup: FormGroup = this._formBuilder.group({
    companyName: ['', Validators.required],
    vat: [null, Validators.required],
    sag: [null],
    legalRepresentative: ['', Validators.required],
    legalRepresentativeId: [null, Validators.required]
  })

  constructor(private _router: Router, private _formBuilder: FormBuilder) {
    this.products = [
        {
          "label": "Table Grapes",
          "data": "Table Grapes",
          "children": [
            {
              "label": "ARRA 15",
              "data": "ARRA 15"
            },
            {
              "label": "CRIMSON SEEDLESS",
              "data": "CRIMSON SEEDLESS"
            }
          ]
        },
        {
          "label": "Blueberry",
          "data": "Blueberry",
          "children": [
            {
              "id": 1,
              "label": "ALAPAHA",
              "data": "ALAPAHA"
            },
            {
              "label": "AURORA",
              "data": "AURORA"
            }
          ]
        },
      ]
  }

  goToSignUpAddInfo(): void {
    this.submit = true

    if(this.formGroup.valid)
      this._router.navigate([Routes.signupExporterAddInfo])
  }

  onSelect(event: any) {
    this.uploadedFiles = []
    for(let file of event.files) {
        this.uploadedFiles.push(file);
    }
  }

  getErrorMessage(fieldName: string): string {
    if (this.formGroup.controls[fieldName].hasError('required')) {
      return 'Este campo es requerido.';
    }
    if (this.formGroup.controls[fieldName].hasError('email')) {
      console.log("error en correo")
      return 'Formato de correo electrónico incorrecto.';
    }
    return '';
  }
}
