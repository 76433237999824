import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { PalletGateway } from 'src/app/application/gateway/PalletGateway';
import { GenericOrderDetail, ImportOrderDetail, ImportOrderDetailInformation, UploadImportOrderDetailPdfRequest } from 'src/app/domain/models/ImportOrder';
import { CreatePalletsRequest, Pallet, UpdatePalletsRequest } from 'src/app/domain/models/Pallet';
import { Routes } from 'src/app/infrastructure/routes';
import { ErrorMessageUtil } from 'src/app/ui/util/error-message-util';

@Component({
  selector: 'app-admin-verify-pallets',
  templateUrl: './admin-verify-pallets.component.html',
  styleUrls: ['./admin-verify-pallets.component.css'],
  providers: [MessageService]
})
export class AdminVerifyPalletsComponent implements OnInit {

  breadcrumbMenuItems: MenuItem[] = []
  breadcrumbHome: MenuItem

  orderDetailId: number | undefined
  orderDetail: GenericOrderDetail | undefined
  orderDetailInformation: ImportOrderDetailInformation | undefined

  pallets: Pallet[] = []
  verifiedPallets: Pallet[] = []
  qcReviewPdf: any
  qcContainerConsolidationPdf: any

  submit: boolean = false
  loading: boolean = false

  formGroup: FormGroup = this._formBuilder.group({
    qualityControlDate: [null, Validators.required],
    qcObservations: [null]
  })

  constructor(private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _messageService: MessageService,
    private _formBuilder: FormBuilder,
    private _authService: AuthGateway,
    private _palletService: PalletGateway,
    public translate: TranslateService) {

    this._activatedRoute.params.subscribe(params => {
      this.orderDetailId = params['orderDetailId']
    })

    this.breadcrumbHome = {label: 'Ordenes de compra', routerLink: ['/' + Routes.adminManageOrderPage]}
  }

  ngOnInit(): void {
    if(this.orderDetailId) {
      this.loading = true
      this._palletService.getByImportOrderDetailId(this.orderDetailId).subscribe({
        next: (v) => {
          this.orderDetailInformation = v
          this.orderDetail = this.orderDetailInformation.importOrderDetail
          this.pallets = this.orderDetailInformation.pallets
          this.loadForm()
          this.breadcrumbMenuItems = [{label: 'Detalle de orden', routerLink: ['/' + Routes.adminOrderDetail, this.orderDetailInformation.importOrderDetail.importOrderId]}]
        },
        error: (e) => {
          console.log(e)
          this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudo recuperar el detalle de orden.' });
        },
        complete: () =>  {
          this.loading = false
          console.info('Import Order Detail query successfully')
        }
      })
    }
  }

  loadForm() {
    if(this.orderDetail) {
      this.formGroup.controls['qualityControlDate'].setValue(this.orderDetail.qualityControlDate ? new Date(this.orderDetail.qualityControlDate) : null)
    }
  }

  onSelectQCReviewPdf(event: any) {
    this.qcReviewPdf = event.files[0]
  }

  onSelectQCConsolidationPdf(event: any) {
    this.qcContainerConsolidationPdf = event.files[0]
  }

  saveConfirmPallets() {
    this.submit = true
    this.loading = true
    if(this.orderDetailInformation && this.formGroup.valid) {

      this.verifiedPallets.forEach(x => {
        x.fridgeStorageVerified = true
      })

      const request: UpdatePalletsRequest = {
        importOrderDetailId: this.orderDetailInformation.importOrderDetail.id,
        pallets: this.verifiedPallets,
        qcUsername: this._authService.getUsername(),
        qualityControlDate: this.formGroup.controls['qualityControlDate'].value,
        qcObservations: this.formGroup.controls['qcObservations'].value,
        originQCReviewPrice: 0
      }

      this._palletService.adminVerifyPallets(request).subscribe({
        next: (v) => {
          this._messageService.add({ severity: 'success', summary: 'Acción exitosa', detail: 'Los pallets fueron actualizados con éxito.' })
          this.saveQCReviewPdf()
          this.saveQCConsolidationPdf()
          this.submit = false
          this.loading = false
          //this._router.navigate(['/' + Routes.adminOrderDetail, this.orderDetailInformation.importOrderDetail.importOrderId])
        },
        error: (e) => {
          console.log(e)
          this.submit = false
          this.loading = false
          this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudieron actualizar los pallets.' })
        },
        complete: () =>  {
          this.submit = false
          this.loading = false
          console.info('Import Order Detail updated successfully')
        }
      })
    }
    else {
      this.loading = false
    }
  }

  saveQCReviewPdf() {
    if(this.qcReviewPdf && this.orderDetailInformation) {
      let qcReviewPdfRequest: UploadImportOrderDetailPdfRequest = {
        importOrderDetailId: this.orderDetailInformation?.importOrderDetail.id,
        pdf: this.qcReviewPdf
      }
      this._palletService.uploadQCReviewPdf(qcReviewPdfRequest).subscribe({
        next: (v) => {
          this._messageService.add({ severity: 'success', summary: 'Acción exitosa', detail: 'El PDF de inspección fue subido con éxito.' })
        },
        error: (e) => {
          console.log(e)
          this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudo subir el PDF de inspección.' });
        },
        complete: () =>  {
          this.loading = false
          console.info('QCReviewPdf uploaded successfully')
        }
      })
    }
  }

  saveQCConsolidationPdf() {
    if(this.qcContainerConsolidationPdf && this.orderDetailInformation) {
      let qcContainerConsolidationPdfRequest: UploadImportOrderDetailPdfRequest = {
        importOrderDetailId: this.orderDetailInformation?.importOrderDetail.id,
        pdf: this.qcContainerConsolidationPdf
      }
      this._palletService.uploadQCContainerConsolidationPdf(qcContainerConsolidationPdfRequest).subscribe({
        next: (v) => {
          this._messageService.add({ severity: 'success', summary: 'Acción exitosa', detail: 'El PDF de consolidación fue subido con éxito.' })
        },
        error: (e) => {
          console.log(e)
          this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudo subir el PDF de consolidación.' });
        },
        complete: () =>  {
          this.loading = false
          console.info('QCConsolidationPdf uploaded successfully')
        }
      })
    }
  }

  cancel() {
    if(this.orderDetailInformation)
      this._router.navigate(['/' + Routes.adminOrderDetail, this.orderDetailInformation.importOrderDetail.importOrderId])
  }

  getErrorMessage(formGroup: FormGroup, fieldName: string): string {
    return ErrorMessageUtil.getErrorMessage(formGroup, this.translate, fieldName)
  }

}
