import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, ParamMap  } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { CountryGateway } from 'src/app/application/gateway/CountryGateway';
import { FridgeStorageGateway } from 'src/app/application/gateway/FridgeStorageGateway';
import { Country } from 'src/app/domain/models/Country';
import { FridgeStorage, NewFridgeStorage } from 'src/app/domain/models/FridgeStorage';
import { BasicUser } from 'src/app/domain/models/User';
import { Routes } from 'src/app/infrastructure/routes';
import { formLimit } from 'src/app/ui/util/form-limit';

interface StorageTypeOption {
  label: string
  value: any
}

@Component({
  selector: 'app-fridgestorage-form',
  templateUrl: './fridgestorage-form.component.html',
  styleUrls: ['./fridgestorage-form.component.css'],
  providers: [MessageService]
})
export class FridgestorageFormComponent implements OnInit {
  title: string = ''
  countries: Country[] = []
  submit = false
  loading = false
  fridgestorageId = 0
  fridgestorage: FridgeStorage | undefined

  profile: BasicUser | undefined
  storageTypes: StorageTypeOption[] = []
  selectedCountry: Country | undefined

  formLimit = formLimit
  formGroup: FormGroup = this._formBuilder.group({
    id: [null],
    name: ['', Validators.required],
    vat: [null],
    contactName: ['', Validators.required],
    contactAddress: ['', Validators.required],
    contactEmail: ['', [Validators.required, Validators.email]],
    contactPhone: ['', Validators.required],
    contactCity: ['', Validators.required],
    contactCountry: [null, Validators.required],
    storageType: [null, Validators.required]
  })

  constructor(private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _messageService: MessageService,
    private _authService: AuthGateway,
    private _countryService: CountryGateway,
    private _fridgestorageService: FridgeStorageGateway,
    public translate: TranslateService) {
      this._activatedRoute.params.subscribe(params => {
        this.fridgestorageId = params['fridgestorageId']
      })
  }

  ngOnInit(): void {
    this.profile = this._authService.getUser()

    if(this.profile) {
      let fridgeStorageTypeLabel = 'Cold storage (Fresh fruit)'
      let processingPlantTypeLabel = 'Processing plant (Frozen fruit)'

      switch(this.profile.lang) {
        case 'en':
          fridgeStorageTypeLabel = 'Cold storage (Fresh fruit)'
          processingPlantTypeLabel = 'Processing plant (Frozen fruit)'
          break
        case 'es':
          fridgeStorageTypeLabel = 'Frigorífico (Fruta fresca)'
          processingPlantTypeLabel = 'Planta de procesamiento (Fruta congelada)'
          break
      }

      this.storageTypes = [
        {label: fridgeStorageTypeLabel, value: 1},
        {label: processingPlantTypeLabel, value: 2}
      ]
    }

    this._countryService.getAll().subscribe({
      next: (v) => {
        this.countries = v
      },
      error: (e) => {
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Country.ManyRetrieveError') });
        console.log(e)
      },
      complete: () => console.info('Country query successfully')
    })

    if(this.fridgestorageId && this.fridgestorageId > 0) {
      this.title = this.translate.instant('UserPages.UserFridgestorages.FridgestorageForm.EditTitle')
      this._fridgestorageService.getById(this.fridgestorageId).subscribe({
        next: (v) => {
          this.fridgestorage = v
          this.loadForm()
        },
        error: (e) => {
          console.log(e)
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.FridgeStorage.SingleRetrieveError') });
        },
        complete: () => console.info('FridgeStorage query successfully')
      })
    }
    else {
      this.title = this.translate.instant('UserPages.UserFridgestorages.FridgestorageForm.CreateTitle')
    }
  }

  loadForm() {
    if(this.fridgestorage) {
      this.formGroup.controls["id"].setValue(this.fridgestorage.id)
      this.formGroup.controls["name"].setValue(this.fridgestorage.name)
      this.formGroup.controls["storageType"].setValue(this.fridgestorage.storageType)
      this.formGroup.controls["vat"].setValue(this.fridgestorage.vat)
      this.formGroup.controls["contactName"].setValue(this.fridgestorage.contactName)
      this.formGroup.controls["contactAddress"].setValue(this.fridgestorage.contactAddress)
      this.formGroup.controls["contactEmail"].setValue(this.fridgestorage.contactEmail)
      this.formGroup.controls["contactPhone"].setValue(this.fridgestorage.contactPhone)
      this.formGroup.controls["contactCity"].setValue(this.fridgestorage.contactCity)
      this.formGroup.controls["contactCountry"].setValue(this.fridgestorage.contactCountry)
      this.selectedCountry = this.countries.find(x => x.id == this.fridgestorage?.contactCountry?.id)
    }
  }

  save() {
    this.submit = true
    this.loading = true
    if(this.formGroup.valid) {

      if(this.fridgestorage && this.fridgestorageId && this.fridgestorageId > 0) {
        //update
        this.fridgestorage.name = this.formGroup.controls["name"].value
        this.fridgestorage.vat = this.formGroup.controls["vat"].value
        this.fridgestorage.contactName = this.formGroup.controls["contactName"].value
        this.fridgestorage.contactAddress = this.formGroup.controls["contactAddress"].value
        this.fridgestorage.contactEmail = this.formGroup.controls["contactEmail"].value
        this.fridgestorage.contactPhone = this.formGroup.controls["contactPhone"].value
        this.fridgestorage.contactCity = this.formGroup.controls["contactCity"].value
        this.fridgestorage.contactCountryId = this.formGroup.controls["contactCountry"].value.id
        this.fridgestorage.storageType = this.formGroup.controls["storageType"].value

        this._fridgestorageService.update(this.fridgestorage).subscribe({
          next: (v) => {
            this.fridgestorage = v
            this._messageService.add({ severity: 'success',
              summary: this.translate.instant('MessageService.Summary.SuccessAction'),
              detail: this.translate.instant('MessageService.FridgeStorage.EditSuccess') })
            this.loading = false
            this.submit = false
          },
          error: (e) => {
            this.loading = false
            console.log(e)
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.FridgeStorage.EditError') });
          },
          complete: () =>  {
            this.loading = false
            this.submit = false
            console.info('FridgeStorage query successfully') }
        })
      }
      else {
        //create
        const newFridgeStorage: NewFridgeStorage = {
          name: this.formGroup.controls["name"].value,
          vat: this.formGroup.controls["vat"].value,
          contactName: this.formGroup.controls["contactName"].value,
          contactAddress: this.formGroup.controls["contactAddress"].value,
          contactEmail: this.formGroup.controls["contactEmail"].value,
          contactPhone: this.formGroup.controls["contactPhone"].value,
          contactCity: this.formGroup.controls["contactCity"].value,
          contactCountryId: this.formGroup.controls["contactCountry"].value.id,
          storageType: this.formGroup.controls["storageType"].value
        }

        this._fridgestorageService.create(newFridgeStorage).subscribe({
          next: (v) => {
            this.fridgestorage = v
            this.fridgestorageId = this.fridgestorage.id ? this.fridgestorage.id : 0
            this.title = 'Editar Frigorífico'
            this._messageService.add({ severity: 'success',
              summary: this.translate.instant('MessageService.Summary.SuccessAction'),
              detail: this.translate.instant('MessageService.FridgeStorage.EditSuccess') })
            this.loading = false
            this.submit = false
          },
          error: (e) => {
            this.loading = false
            console.log(e)
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.FridgeStorage.EditError') });
          },
          complete: () =>  {
            this.loading = false
            this.submit = false
            console.info('FridgeStorage query successfully')
          }
        })
      }
    }
    else {
      this.loading = false
    }
  }

  cancel() {
    this._router.navigate([Routes.userFridgestorages])
  }

  getErrorMessage(fieldName: string): string {
    if (this.formGroup.controls[fieldName].hasError('required')) {
      return this.translate.instant('FormErrorMessage.FieldValueRequired')
    }
    if (this.formGroup.controls[fieldName].hasError('email')) {
      return this.translate.instant('FormErrorMessage.EmailFieldEmailFormatError')
    }
    return ''
  }

  onChangeCountry() {
    this.selectedCountry = this.countries.find(x => x.id == this.formGroup.controls['contactCountry'].value.id)
  }
}
