import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppNotification } from "src/app/domain/models/AppNotification";
import { Routes } from "src/app/infrastructure/routes";
import { NotificationGateway } from "../gateway/NotificationGateway";

@Injectable({providedIn: 'root'})
export class NotificationService extends NotificationGateway {

  constructor(private _http: HttpClient) {
    super()
  }

  getAllByProfile(): Observable<AppNotification[]> {
    return this._http.get<AppNotification[]>(Routes.notificationApi + '/GetAllByProfile')
  }

  getAllPendingByProfile(): Observable<AppNotification[]> {
    return this._http.get<AppNotification[]>(Routes.notificationApi + '/GetAllPendingByProfile')
  }

  getAllReadedByProfile(): Observable<AppNotification[]> {
    return this._http.get<AppNotification[]>(Routes.notificationApi + '/GetAllReadedByProfile')
  }

  setReaded(notificationId: number): Observable<any> {
    return this._http.put<any>(Routes.notificationApi + '/SetReaded', {}, {params: {notificationId}})
  }

  getPendingCount(): Observable<number> {
    return this._http.get<number>(Routes.notificationApi + '/GetPendingCount')
  }
}
