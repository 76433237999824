<app-user-dashboard [userMenuActiveItemIndex]="0" [activeItem]="'corporative'">
  <p-card [style]="{'min-height':'200px'}">
    <ng-template pTemplate="header">
      <div class="flex justify-content-between flex-wrap align-items-center" style="padding: 15px;">
        <h2>{{'UserPages.UserContactInfo.PageTitle' | translate}}</h2>
        <p-button label="{{'UserPages.UserContactInfo.EditButton' | translate}}" icon="pi pi-file-edit" (click)="setEnableEdit()" styleClass="p-button-text"></p-button>
      </div>
      <p style="margin-left: 15px;">{{'UserPages.UserContactInfo.RequiredFieldText' | translate}}</p>
    </ng-template>

    <form [formGroup]="formGroup">
      <div class="p-fluid formgrid grid" style="padding: 20px;">
        <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
          <label for="profileType" style="font-weight: bold;">{{'UserPages.UserContactInfo.ProfileFieldLabel' | translate}} *</label>
          <input inputId="profileType" type="text" [value]="profileType" pInputText [readonly]="true"/>
        </div>
        <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
          <label for="contactEmail" style="font-weight: bold;">{{'UserPages.UserContactInfo.EmailFieldLabel' | translate}} *</label>
          <input id="contactEmail"  maxlength="{{formLimit.mailLimit}}" formControlName="contactEmail" type="text" required pInputText   placeholder="{{'UserPages.UserContactInfo.EmailFieldPlaceholder' | translate}}"/>
          <small *ngIf="submit && formGroup.controls['contactEmail'].invalid" class="p-error">{{getErrorMessage('contactEmail')}}</small>
        </div>
        <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
          <label for="contactName" style="font-weight: bold;">{{'UserPages.UserContactInfo.NameFieldLabel' | translate}} *</label>
          <input id="contactName" maxlength="{{formLimit.nameLimit}}" formControlName="contactName" type="text" required pInputText placeholder="{{'UserPages.UserContactInfo.NameFieldPlaceholder' | translate}}"/>
          <small *ngIf="submit && formGroup.controls['contactName'].invalid" class="p-error">{{getErrorMessage('contactName')}}</small>
        </div>
        <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
          <label for="country" style="font-weight: bold;">{{'UserPages.UserContactInfo.CountryFieldLabel' | translate}} *</label>
          <p-dropdown id="country"
            formControlName="contactCountryId"
            [options]="countries"
            optionLabel="name"
            optionValue="id"
            [filter]="true"
            filterBy="name"
            [showClear]="true"
            placeholder="{{'UserPages.UserContactInfo.CountryFieldPlaceholder' | translate}}">
          </p-dropdown>
          <small *ngIf="submit && formGroup.controls['contactCountryId'].invalid" class="p-error">{{getErrorMessage('contactCountryId')}}</small>
        </div>
        <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
          <label for="contactCity" style="font-weight: bold;">{{'UserPages.UserContactInfo.CityFieldLabel' | translate}} *</label>
          <input id="contactCity"  maxlength="{{formLimit.cityLimit}}" formControlName="contactCity" type="text" required pInputText placeholder="{{'UserPages.UserContactInfo.CityFieldPlaceholder' | translate}}"/>
          <small *ngIf="submit && formGroup.controls['contactCity'].invalid" class="p-error">{{getErrorMessage('contactCity')}}</small>
        </div>
        <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
          <label for="contactAddress" style="font-weight: bold;">{{'UserPages.UserContactInfo.AddressFieldLabel' | translate}} *</label>
          <input id="contactAddress" maxlength="{{formLimit.addressLimit}}" formControlName="contactAddress" type="text" required pInputText placeholder="{{'UserPages.UserContactInfo.AddressContactFieldPlaceholder' | translate}}"/>
          <small *ngIf="submit && formGroup.controls['contactAddress'].invalid" class="p-error">{{getErrorMessage('contactAddress')}}</small>
        </div>
        <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
          <label for="contactPhone" style="font-weight: bold;">{{'UserPages.UserContactInfo.PhoneFieldLabel' | translate}}</label>
          <input id="contactPhone" maxlength="{{formLimit.phoneLimit}}" formControlName="contactPhone" type="text" required pInputText placeholder="{{'UserPages.UserContactInfo.PhoneFieldPlaceholder' | translate}}"/>
        </div>
        <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
          <label for="lang" style="font-weight: bold;">{{'UserPages.UserContactInfo.LangFieldLabel' | translate}} *</label>
          <p-dropdown id="lang"
            formControlName="lang"
            [options]="langs"
            optionLabel="label"
            optionValue="value"
            placeholder="{{'UserPages.UserContactInfo.LangFieldPlaceholder' | translate}}">
          </p-dropdown>
          <small *ngIf="submit && formGroup.controls['lang'].invalid" class="p-error">{{getErrorMessage('lang')}}</small>
        </div>
        <div class="col-12">
          <div class="grid" style="margin-top: 30px;">
            <div class="col-12 md:col-6 lg:col-6"></div>
            <div class="col-12 md:col-6 lg:col-6">
              <div class="grid p-fluid">
                <div class="col-6">
                  <p-button label="{{'UserPages.UserContactInfo.BackButton' | translate}}" [disabled]="loading" styleClass="p-button-outlined" (onClick)="onCancel()"/>
                </div>
                <div class="col-6">
                  <p-button label="{{'UserPages.UserContactInfo.SaveButton' | translate}}" [loading]="loading" (onClick)="save()" [disabled]="disabledFields"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

    <div class="formgrid grid" style="padding: 20px;">
      <div class="field col-12 xl:col-6 lg:col-6">
        <p-button [loading]="loading" (click)="deactiveAccount()" label="{{'UserPages.UserContactInfo.DeactivateAccountButton' | translate}}" styleClass="p-button-outlined"/>
      </div>
      <div class="field col-12 xl:col-6 lg:col-6"></div>
    </div>
  </p-card>
</app-user-dashboard>

<p-toast></p-toast>
<p-confirmDialog acceptLabel="{{'UserPages.UserContactInfo.ConfirmDialog' | translate}}" rejectLabel="{{'UserPages.UserContactInfo.CancelDialog' | translate}}" rejectButtonStyleClass="p-button-outlined" [style]="{width: '50vw'}"></p-confirmDialog>
