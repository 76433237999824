import { Component, Input, OnInit } from '@angular/core';
import { Delivery } from 'src/app/domain/models/Delivery';
import { DeliveryDiscount } from 'src/app/domain/models/DeliveryDiscount';
import { GenericOrderDetail, ImportOrder, ImportOrderDetail, ImportOrderInformation } from 'src/app/domain/models/ImportOrder';
import { Payment } from 'src/app/domain/models/Payment';
import { faShip, faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { DeliveryGateway } from 'src/app/application/gateway/DeliveryGateway';
import { GenericDocument } from 'src/app/domain/models/GenericDocument';
import { PaymentQualityControl } from 'src/app/domain/models/PaymentQualityControl';

@Component({
  selector: 'app-order-detail-tab',
  templateUrl: './order-detail-tab.component.html',
  styleUrls: ['./order-detail-tab.component.css']
})
export class OrderDetailTabComponent implements OnInit {

  @Input()
  importOrder: ImportOrder | undefined

  @Input()
  delivery: Delivery | undefined

  @Input()
  importOrderDetails: GenericOrderDetail[] = []

  @Input()
  discounts: DeliveryDiscount[] = []

  @Input()
  isClosedSale: boolean = false

  @Input()
  directSaleProgramId: number | undefined

  @Input()
  payments: Payment[] = []

  @Input()
  order: ImportOrderInformation | undefined

  @Input()
  typeDocuments: number = 0
  @Input()
  qualityControlDocuments: GenericDocument[] | undefined
  @Input()
  operationDocuments: GenericDocument[] | undefined
  @Input()
  productCertificateDocuments: GenericDocument[] | undefined
  @Input()
  exportOfferDocuments: GenericDocument[] | undefined
  @Input()
  invoices: GenericDocument[] = []
  @Input()
  forwarderDocuments: GenericDocument[] | undefined
  @Input()
  customAgentDocuments: GenericDocument[] | undefined

  @Input()
  paymentsQualityControl: PaymentQualityControl[] = []

  expanded: boolean = true;

  indexTab: number = 0
  indexBefore: number = 0

  icons = {
    faShip: faShip,
    faMoneyBill: faMoneyBill
  }

  constructor(
    private _router: Router,
    private _deliveryService: DeliveryGateway,
  ){

  }

  tabNotification = ["partyInfoNotification", "loadingInfoNotification", "documentsNotification", "reportsNotification", "paymentsNotification"]

  ngOnInit(): void {

  }

  removerNotification(e: any)
  {

    this._deliveryService.removeNotification(this.importOrder?.guid , this.tabNotification[this.indexTab]).subscribe({
      next: (v) => {
        if(this.delivery)
        {
          switch(this.tabNotification[this.indexTab])
            {
              case "partyInfoNotification":
                  this.delivery.partyInfoNotification = false;
                  break;
              case "loadingInfoNotification":
                  this.delivery.loadingInfoNotification = false;
                  break;
              case "documentsNotification":
                  this.delivery.documentsNotification = false;
                  break;
              case "reportsNotification":
                  this.delivery.reportsNotification = false;
                  break;
              case "paymentsNotification":
                  this.delivery.paymentsNotification = false;
                  break;
            }
        }
      }
    })
  }
}
