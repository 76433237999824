import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Company, EditCompany, NewCompany } from "src/app/domain/models/Company";
import { Routes } from "src/app/infrastructure/routes";
import { CompanyGateway } from "../gateway/CompanyGateway";

@Injectable({providedIn: 'root'})
export class CompanyService extends CompanyGateway {

  constructor(private _http: HttpClient) {
    super()
  }

  getAll(): Observable<Company[]> {
    return this._http.get<Company[]>(Routes.companyApi + '/GetAll')
  }

  getAllByCompanyType(companyType: any): Observable<Company[]> {
    return this._http.get<Company[]>(Routes.companyApi + '/GetAllByStorageType', { params: {companyType}})
  }

  getById(companyId: number): Observable<Company> {
    return this._http.get<Company>(Routes.companyApi + '/GetById', { params: {companyId}})
  }
  
  create(company: NewCompany): Observable<Company> {
    return this._http.post<Company>(Routes.companyApi + '/Create', company)
  }
  
  update(company: EditCompany): Observable<Company> {
    return this._http.put<Company>(Routes.companyApi + '/Update', company)
  }
  
  delete(companyId: number): Observable<Company> {
    return this._http.delete<Company>(Routes.companyApi + '/Delete', { params: {companyId}})
  }
  
  getUsersCompanyByProfile(): Observable<Company> {
    return this._http.get<Company>(Routes.companyApi + '/GetUsersCompanyByProfile')
  }

}
