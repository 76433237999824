import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Profile, SingupRequest, ValidateTokenRequest } from "src/app/domain/models/Profile";

@Injectable({providedIn: 'root'})
export abstract class SecurityGateway {
  abstract signup(request: SingupRequest): Observable<Profile>
  abstract doLogin(username: string, password: string, code: string | undefined): Observable<any>
  abstract doLogout(): Observable<any>
  abstract validateToken(request: ValidateTokenRequest): Observable<any>
}
