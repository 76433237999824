import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { QualityControlCompany, QualityControlCompanyCreateRequest } from "src/app/domain/models/QCCompany";
import { Routes } from "src/app/infrastructure/routes";
import { QualityControlCompanyGateway } from "../gateway/QualityControlCompanyGateway";

@Injectable({providedIn: 'root'})
export class QualityControlCompanyService extends QualityControlCompanyGateway {

  constructor(private _http: HttpClient) {
    super();
  }

  create(request: QualityControlCompanyCreateRequest): Observable<any> {
    return this._http.post<any>(Routes.qcCompanyApi + '/Create', request)
  }

  getAllByOperationCountryId(operationCountryId: number): Observable<QualityControlCompany[]> {
    return this._http.get<QualityControlCompany[]>(Routes.qcCompanyApi + '/GetAllByOperationCountryId', {params: {operationCountryId}})
  }
}
