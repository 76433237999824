import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { NewDeliveryDiscount, DeliveryDiscount } from "src/app/domain/models/DeliveryDiscount";
import { Routes } from "src/app/infrastructure/routes";
import { DeliveryDiscountGateway } from "../gateway/DeliveryDiscountGateway";

@Injectable({providedIn: 'root'})
export class DeliveryDiscountService extends DeliveryDiscountGateway {

  constructor(private _http: HttpClient) {
    super()
  }

  create(deliveryDiscount: NewDeliveryDiscount): Observable<DeliveryDiscount> {
    return this._http.post<DeliveryDiscount>(Routes.deliveryDiscountApi + '/Create', deliveryDiscount)
  }

  update(deliveryDiscount: DeliveryDiscount): Observable<DeliveryDiscount> {
    return this._http.put<DeliveryDiscount>(Routes.deliveryDiscountApi + '/Update', deliveryDiscount)
  }

  delete(id: number): Observable<any> {
    return this._http.delete<any>(Routes.deliveryDiscountApi + '/Delete', {params: {id}})
  }

  getAllByDeliveryId(importOrderId: any): Observable<DeliveryDiscount[]> {
    return this._http.get<DeliveryDiscount[]>(Routes.deliveryDiscountApi + '/GetAllByDeliveryId', {params: {importOrderId}})
  }

}
