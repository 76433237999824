<app-user-dashboard [userMenuActiveItemIndex]="0" [activeItem]="'qcReviews'">
  <p-card *ngIf="dto">
    <h2>Revisión QC pallets fruta congelada</h2>

    <div>
      <p-fieldset legend="Import order detail properties" [toggleable]="true" [collapsed]="true">
        <div class="grid">
          <div class="col-3">Import order Id:</div>
          <div class="col-3"><span style="font-weight: bolder;">{{orderDetail.importOrderSequence}}</span></div>
          <div class="col-3">Import order detail Id:</div>
          <div class="col-3"><span style="font-weight: bolder;">{{orderDetail.sequence}}</span></div>

          <div class="col-3">Species / Variety:</div>
          <div class="col-3">
            <span style="font-weight: bolder;">
              {{getSpeciesName(orderDetail)}} - {{orderDetail.varietyName}}
            </span>
          </div>
          <div class="col-3">Coldstorage:</div>
          <div class="col-3">
            <span style="font-weight: bolder;">
              {{orderDetail.fridgeStorageName}} {{orderDetail.fridgeStorageCity}}, {{orderDetail.fridgeStorageCountry}}
            </span>
          </div>

          <div class="col-3">Containers:</div>
          <div class="col-3">
            <span style="font-weight: bolder;">
              {{orderDetail.palletsQuantity ? orderDetail.palletsQuantity : 'N.A.' }}
            </span>
          </div>
          <div class="col-3">Cases per pallet:</div>
          <div class="col-3">
            <span style="font-weight: bolder;">
              {{orderDetail.boxesPerPallet ? orderDetail.boxesPerPallet : 'N.A.'}}
            </span>
          </div>

          <div class="col-3">Departure port:</div>
          <div class="col-3">
            <span style="font-weight: bolder;">
              {{orderDetail.portFromName}}
            </span>
          </div>
          <div class="col-3">Destination port:</div>
          <div class="col-3">
            <span style="font-weight: bolder;">
              {{orderDetail.portToName}}
            </span>
          </div>
        </div>
      </p-fieldset>
    </div>

    <p style="margin-top: 40px;">Select the pallets that passed the quality review and fill de QC Form</p>
    <div style="margin-top: 20px;">
      <p-table [value]="pallets"
        [(selection)]="approvedPallets"
        dataKey="id"
        responsiveLayout="stack"
        [breakpoint]="'600px'"
        styleClass="p-datatable-gridlines"
        [scrollable]="true"
        [resizableColumns]="true"
        columnResizeMode="expand"
        scrollHeight="400px">
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 4rem">
              <p-tableHeaderCheckbox *ngIf="orderDetail?.status == 2"></p-tableHeaderCheckbox>
            </th>
            <th>Folio</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.SpeciesHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.VarietyHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.ProducerHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.SpeciesSizeHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.HarvestDateHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.QualityControlDateHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.PackagingHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.PalletTypeHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.TagHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.BagTypeHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.BoxTypeHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.NetWeightHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.ColdStorageHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.BoxePerPalletHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.MarksHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.ContainerNumberHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.GradeHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.TemptaleHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.OriginCountryHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.Cert1Header' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.Cert2Header' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.Cert3Header' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadWeekHeader' | translate}}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-pallet>
          <tr>
            <td>
              <p-tableCheckbox [value]="pallet" *ngIf="pallet.status == 1"></p-tableCheckbox>
              <i class="pi pi-check" style="color: slateblue" *ngIf="pallet.status == 2" pTooltip="Pallet Verified"></i>
              <i class="pi pi-times" style="color: darkred" *ngIf="pallet.status == 3" pTooltip="Pallet Rejected"></i>
            </td>
            <td>{{pallet.palletNumber}}</td>
            <td>{{pallet.excelImportSpecies}}</td>
            <td>{{pallet.excelImportVariety}}</td>
            <td>{{pallet.excelImportProducer}}</td>
            <td>{{pallet.excelImportSpeciesSize}}</td>
            <td>{{pallet.excelImportHarvestDate}}</td>
            <td>{{pallet.excelImportQualityControlDate}}</td>
            <td>{{pallet.excelImportPackaging}}</td>
            <td>{{pallet.excelImportPalletType}}</td>
            <td>{{pallet.excelImportTag}}</td>
            <td>{{pallet.excelImportBagType}}</td>
            <td>{{pallet.excelImportBoxSize}}</td>
            <td>{{pallet.excelImportNetWeight}}</td>
            <td>{{pallet.excelImportFridgeStorage}}</td>
            <td>{{pallet.excelImportBoxesPerPallet}}</td>
            <td>{{pallet.excelImportMark}}</td>
            <td>{{pallet.excelImportContainerNumber}}</td>
            <td>{{pallet.excelImportGrade}}</td>
            <td>{{pallet.excelImportTemptale}}</td>
            <td>{{pallet.excelImportOriginCountry}}</td>
            <td>{{pallet.excelImportCertificate1}}</td>
            <td>{{pallet.excelImportCertificate2}}</td>
            <td>{{pallet.excelImportCertificate3}}</td>
            <td>{{pallet.excelImportLoadWeek}}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>

    <div style="margin-top: 40px;">
      <form [formGroup]="formGroup">
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-6 lg:col-3" style="margin-bottom: 40px;">
            <label for="qualityControlDate" style="font-weight: bolder;">{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.QualityControlDateLabel' | translate}} *</label>
            <p-calendar
              inputId="qualityControlDate"
              formControlName="qualityControlDate"
              appendTo="body"
              [showButtonBar]="true"
              [showIcon]="true"
              placeholder="{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.QualityControlDateLabel' | translate}}"
              [showWeek]="true"
              [firstDayOfWeek]="1"></p-calendar>
            <small *ngIf="submit && formGroup.controls['qualityControlDate'].invalid" class="p-error">{{getErrorMessage(formGroup, 'qualityControlDate')}}</small>
          </div>
          <div class="field col-12 md:col-6 lg:col-3" style="margin-bottom: 40px;">
            <label for="originQCReviewPrice">Origin QC Review Price</label>
            <p-inputNumber inputId="originQCReviewPrice" formControlName="originQCReviewPrice" mode="currency" currency="USD" locale="en-US" [min]="0" placeholder="Origin QC Review Price"></p-inputNumber>
            <small *ngIf="submit && formGroup.controls['originQCReviewPrice'].invalid" class="p-error">{{getErrorMessage(formGroup, 'originQCReviewPrice')}}</small>
          </div>
          <div class="field col-12" style="margin-bottom: 40px;">
            <label for="qcObservations" style="font-weight: bolder;">Observations</label>
            <textarea formControlName="qcObservations" rows="5" cols="30" pInputTextarea [maxlength]="1000" [autoResize]="true"></textarea>
          </div>
        </div>
      </form>
    </div>

    <div class="grid" style="margin-top: 30px;">
      <div class="col-12 md:col-6 lg:col-6">
      </div>
      <div class="col-12 md:col-6 lg:col-6">
        <div class="grid p-fluid">
          <div class="col-12 md:col-6 lg:col-6">
            <p-button label="Regresar" (onClick)="back()" styleClass="p-button-outlined"></p-button>
          </div>
          <div class="col-12 md:col-6 lg:col-6">
            <p-button label="Guardar" (onClick)="savePallets()" [loading]="loading"></p-button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12" style="margin-top: 40px;" *ngIf="orderDetail.status === 3 || orderDetail.status === 4">
      <label style="font-weight: bolder;">Review QC PDF document</label>
      <p-fileUpload
        *ngIf="enableUploaderReviewPdf()"
        #QCReviewPdfUploader
        (onSelect)="onSelectQCReviewPdf($event)"
        [showUploadButton]="false"
        [multiple]="false"
        accept=".pdf"
        [maxFileSize]="5000000"
        chooseLabel="{{'UserPages.UserProductCertificates.Form.SelectFile' | translate}}"
        cancelLabel="{{'UserPages.UserProductCertificates.Form.CancelSelectFile' | translate}}"/>
      <p-button *ngIf="!enableUploaderReviewPdf()" (onClick)="getQCReviewDocument()" pTooltip="QC Review Document PDF" icon="pi pi-file-pdf" styleClass="p-button-link p-button-lg"></p-button>
    </div>

    <div class="col-12" style="margin-top: 40px;" *ngIf="orderDetail.status === 3 || orderDetail.status === 4">
      <label style="font-weight: bolder;">QC Consolidation PDF document</label>
      <p-fileUpload
        *ngIf="enableConsolidationPdf()"
        #QCConsolidationPdfUploader
        (onSelect)="onSelectQCConsolidationPdf($event)"
        [showUploadButton]="false"
        [multiple]="false"
        accept=".pdf"
        [maxFileSize]="5000000"
        chooseLabel="{{'UserPages.UserProductCertificates.Form.SelectFile' | translate}}"
        cancelLabel="{{'UserPages.UserProductCertificates.Form.CancelSelectFile' | translate}}"/>
      <p-button *ngIf="!enableConsolidationPdf()" (onClick)="getQCConsolidationDocument()" pTooltip="QC Consolidation Document PDF" icon="pi pi-file-pdf" styleClass="p-button-link p-button-lg"></p-button>
    </div>
  </p-card>
</app-user-dashboard>

<p-toast></p-toast>
