import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { SecurityGateway } from 'src/app/application/gateway/SecurityGateway';
import { ProfileService } from 'src/app/application/service/ProfileService';
import { Profile } from 'src/app/domain/models/Profile';
import { BasicUser } from 'src/app/domain/models/User';
import { Routes } from 'src/app/infrastructure/routes';

@Component({
  selector: 'app-home-header',
  templateUrl: './home-header.component.html',
  styleUrls: ['./home-header.component.css'],
  providers: [MessageService]
})
export class HomeHeaderComponent implements OnInit {
  displaySidebar = false
  tabMenuItems: MenuItem[]

  isExporter: boolean = false;
  profile: BasicUser | undefined;

  @Input()
  activeItem: string = 'home'

  activeMenuItem: MenuItem

  constructor(private _router: Router,
    private _messageService: MessageService,
    private _securityService: SecurityGateway,
    private _authService: AuthGateway,
    private _profileService: ProfileService,
    public translate: TranslateService) {

    this.tabMenuItems = [
      {
        label: 'Inicio',
        icon: 'pi pi-pw pi-home',
        routerLink: ['/' + Routes.homePage]
      },
      {
        label: 'Marketplace',
        icon: 'pi pi-pw pi-shopping-cart',
        routerLink: ['/' + Routes.marketplacePage]
      },
      {
        label: 'Acerca de',
        icon: 'pi pi-pw pi-info-circle',
      },
      {
        label: 'Contacto',
        icon: 'pi pi-pw pi-envelope',
      }
    ]

    switch(this.activeItem) {
      case 'home':
        this.activeMenuItem = this.tabMenuItems[0]
        break
      case 'marketplace':
        this.activeMenuItem = this.tabMenuItems[1]
        break
      case 'about':
        this.activeMenuItem = this.tabMenuItems[2]
        break
      case 'contact':
        this.activeMenuItem = this.tabMenuItems[3]
        break
      default:
        this.activeMenuItem = this.tabMenuItems[0]
    }
  }

  ngOnInit(): void {
    if(this._authService.isAuthenticated()) {
      this.profile = this._authService.getUser()
      if(this.profile && this.profile.profileType === 'Exporter') {
        this.isExporter = true;
      }
    }
  }

  get isAuthenticated(): boolean {
    return this._authService.isAuthenticated()
  }

  goToLoginPage(): void {
    this._router.navigate([Routes.login]);
  }

  goToSignup(): void {
    this._router.navigate([Routes.signup]);
  }

  goCreateOffers(): void {
    this._router.navigate([Routes.newExportOffer])
  }

  logout(): void {
    this._securityService.doLogout().subscribe({
      next: (v) => {
        this._authService.logout()
        this._router.navigate([Routes.login])
      },
      error: (e) => {
        console.log(e)
        this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudo cerrar sesión correctamente.' });
      },
      complete: () => {
        console.info('Logout successfully')
      }
    })
  }

  goToCreateImportOrderRequeriment() {
    this._router.navigate(['/' + Routes.userNewImportRequest]);
  }

  goToMarketplaceAd()
  {
    if(this.isAuthenticated){
      this._router.navigate(['/' + Routes.marketplacePage]);
    }else{
      this._router.navigate(['/' + Routes.externalMarketplace]);
    }

  }
}
