import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Routes as AppRoutes } from './infrastructure/routes';
import { ForbiddenPageComponent } from './ui/pages/forbidden-page/forbidden-page.component';
import { HomePageComponent } from './ui/pages/home-page/home-page.component';
import { LoginPageComponent } from './ui/pages/login-page/login-page.component';
import { SignupPageContactComponent } from './ui/pages/signup-page/signup-page-contact/signup-page-contact.component';
import { SignupPageCompanyComponent } from './ui/pages/signup-page/signup-page-company/signup-page-company.component';
import { SignupPageImportAddInfoComponent } from './ui/pages/signup-page/signup-page-import-add-info/signup-page-import-add-info.component';
import { SignupInvitationcodeComponent } from './ui/pages/signup-page/signup-invitationcode/signup-invitationcode.component';
import { UserDashboardComponent } from './ui/components/user-dashboard/user-dashboard.component';
import { UserContactInfoComponent } from './ui/pages/user-dashboard-pages/user-contact-info/user-contact-info.component';
import { UserCompanyInfoComponent } from './ui/pages/user-dashboard-pages/user-company-info/user-company-info.component';
import { UserProductsComponent } from './ui/pages/user-dashboard-pages/user-products/user-products.component';
import { UserFridgestoragesComponent } from './ui/pages/user-dashboard-pages/user-fridgestorages/user-fridgestorages.component';
import { UserConsigneesComponent } from './ui/pages/user-dashboard-pages/user-consignees/user-consignees.component';
import { FridgestorageFormComponent } from './ui/pages/user-dashboard-pages/user-fridgestorages/fridgestorage-form/fridgestorage-form.component';
import { UserNotifiesComponent } from './ui/pages/user-dashboard-pages/user-notifies/user-notifies.component';
import { ConsigneeFormComponent } from './ui/pages/user-dashboard-pages/user-consignees/consignee-form/consignee-form.component';
import { NotifierFormComponent } from './ui/pages/user-dashboard-pages/user-notifies/notifier-form/notifier-form.component';
import { UserChangePasswordComponent } from './ui/pages/user-dashboard-pages/user-change-password/user-change-password.component';
import { ResetPasswordRequestPageComponent } from './ui/pages/reset-password-request-page/reset-password-request-page.component';
import { ResetPasswordPageComponent } from './ui/pages/reset-password-page/reset-password-page.component';
import { UserNotificationsConfigComponent } from './ui/pages/user-dashboard-pages/user-notifications/user-notifications-config.component';
import { UserSupportComponent } from './ui/pages/user-dashboard-pages/user-support/user-support.component';
import { MarketplacePageComponent } from './ui/pages/marketplace-page/marketplace-page.component';
import { UserExportOffersComponent } from './ui/pages/user-dashboard-pages/user-export-offers/user-export-offers.component';
import { NewExportOfferComponent } from './ui/pages/user-dashboard-pages/user-export-offers/new-export-offer/new-export-offer.component';
import { UserProductCertificatesComponent } from './ui/pages/user-dashboard-pages/user-product-certificates/user-product-certificates.component';
import { ProductCertificateFormComponent } from './ui/pages/user-dashboard-pages/user-product-certificates/product-certificate-form/product-certificate-form.component';
import { PurchaseOrdersPageComponent } from './ui/pages/purchase-orders-page/purchase-orders-page.component';
import { ContainerOrderPageComponent } from './ui/pages/container-order-page/container-order-page.component';
import { UserPurchaseOrdersComponent } from './ui/pages/user-dashboard-pages/user-purchase-orders/user-purchase-orders.component';
import { VerifyPalletsPageComponent } from './ui/pages/user-dashboard-pages/user-purchase-orders/verify-pallets-page/verify-pallets-page.component';
import { OrderDetailComponent } from './ui/pages/user-dashboard-pages/user-purchase-orders/order-detail/order-detail.component';
import { EditExportOfferComponent } from './ui/pages/user-dashboard-pages/user-export-offers/edit-export-offer/edit-export-offer.component';
import { AdminManageOrdersPageComponent } from './ui/pages/admin-dashboard-pages/admin-manage-orders-page/admin-manage-orders-page.component';
import { UserBargainingComponent } from './ui/pages/user-dashboard-pages/user-bargaining/user-bargaining.component';
import { BargainingDetailComponent } from './ui/pages/user-dashboard-pages/user-bargaining/bargaining-detail/bargaining-detail.component';
import { AdminOrderDetailComponent } from './ui/pages/admin-dashboard-pages/admin-manage-orders-page/order-detail/admin-order-detail.component';
import { AuthGuard } from './infrastructure/guards/auth.guard';
import { RolesGuard } from './infrastructure/guards/roles.guard';
import { AdminVerifyPalletsComponent } from './ui/pages/admin-dashboard-pages/admin-manage-orders-page/admin-verify-pallets/admin-verify-pallets.component';
import { AdminCompleteOrderComponent } from './ui/pages/admin-dashboard-pages/admin-manage-orders-page/admin-complete-order/admin-complete-order.component';
import { UserNotificationsComponent } from './ui/pages/user-dashboard-pages/user-notifications/notifications/user-notifications/user-notifications.component';
import { UserExportOfferSuggestionsComponent } from './ui/pages/user-dashboard-pages/user-export-offer-suggestions/user-export-offer-suggestions.component';
import { AdminDeliveryComponent } from './ui/pages/admin-dashboard-pages/admin-manage-orders-page/admin-delivery/admin-delivery.component';
import { ConfirmPurchasePageComponent } from './ui/pages/user-dashboard-pages/user-purchase-orders/confirm-purchase-page/confirm-purchase-page.component';
import { PaymentsPageComponent } from './ui/pages/user-dashboard-pages/user-purchase-orders/payments-page/payments-page.component';
import { OfferSuggestionsComponent } from './ui/pages/user-dashboard-pages/user-export-offer-suggestions/offer-suggestions/offer-suggestions.component';
import { AdminOperationComponent } from './ui/pages/admin-dashboard-pages/admin-manage-orders-page/admin-operation/admin-operation.component';
import { Role } from './domain/models/Role';
import { AdminManageExportOffersPageComponent } from './ui/pages/admin-dashboard-pages/admin-manage-export-offers-page/admin-manage-export-offers-page.component';
import { AdminEditExportOfferComponent } from './ui/pages/admin-dashboard-pages/admin-manage-export-offers-page/admin-edit-export-offer/admin-edit-export-offer.component';
import { AdminManageSpeciesComponent } from './ui/pages/admin-dashboard-pages/admin-manage-species/admin-manage-species.component';
import { AdminUsersComponent } from './ui/pages/admin-dashboard-pages/admin-users/admin-users.component';
import { AdminEditUserComponent } from './ui/pages/admin-dashboard-pages/admin-users/admin-edit-user/admin-edit-user.component';
import { AdminNewSpeciesComponent } from './ui/pages/admin-dashboard-pages/admin-manage-species/admin-new-species/admin-new-species.component';
import { UserImportRequestComponent } from './ui/pages/user-dashboard-pages/user-import-request/user-import-request.component';
import { NewImportRequestComponent } from './ui/pages/user-dashboard-pages/user-import-request/new-import-request/new-import-request.component';
import { EditImportRequestComponent } from './ui/pages/user-dashboard-pages/user-import-request/edit-import-request/edit-import-request.component';
import { ViewImportRequestOffersComponent } from './ui/pages/user-dashboard-pages/user-import-request/view-import-request-offers/view-import-request-offers.component';
import { UserClosedSaleComponent } from './ui/pages/user-dashboard-pages/user-closed-sale/user-closed-sale.component';
import { UserCreateClosedSaleComponent } from './ui/pages/user-dashboard-pages/user-closed-sale/create-closed-sale/user-create-closed-sale/user-create-closed-sale.component';
import { UserEditClosedSaleComponent } from './ui/pages/user-dashboard-pages/user-closed-sale/user-edit-closed-sale/user-edit-closed-sale.component';
import { ExporterClosedSaleOrdersComponent } from './ui/pages/user-dashboard-pages/user-closed-sale/exporter-closed-sale-orders/exporter-closed-sale-orders.component';
import { UserClosedSaleImportOrderDetailComponent } from './ui/pages/user-dashboard-pages/user-closed-sale/user-closed-sale-import-order-detail/user-closed-sale-import-order-detail.component';
import { UserClosedSaleExporterVerifyPalletsComponent } from './ui/pages/user-dashboard-pages/user-closed-sale/user-closed-sale-exporter-verify-pallets/user-closed-sale-exporter-verify-pallets.component';
import { ImporterClosedSaleOrdersComponent } from './ui/pages/user-dashboard-pages/user-closed-sale/importer-closed-sale-orders/importer-closed-sale-orders.component';
import { UserPanelComponent } from './ui/pages/user-dashboard-pages/user-panel/user-panel.component';
import { UserImportOrdersApprovedComponent } from './ui/pages/user-dashboard-pages/user-purchase-orders/user-import-orders-approved/user-import-orders-approved.component';
import { UserImportOrdersPendingComponent } from './ui/pages/user-dashboard-pages/user-purchase-orders/user-import-orders-pending/user-import-orders-pending.component';
import { UserImportOrdersConfirmedComponent } from './ui/pages/user-dashboard-pages/user-purchase-orders/user-import-orders-confirmed/user-import-orders-confirmed.component';
import { UserImportOrdersCreatedComponent } from './ui/pages/user-dashboard-pages/user-purchase-orders/user-import-orders-created/user-import-orders-created.component';
import { UserImportOrdersCurrentComponent } from './ui/pages/user-dashboard-pages/user-purchase-orders/user-import-orders-current/user-import-orders-current.component';
import { UserImportOrdersPurchaseComponent } from './ui/pages/user-dashboard-pages/user-purchase-orders/user-import-orders-purchase/user-import-orders-purchase.component';
import { UserClosedSalePaymentsComponent } from './ui/pages/user-dashboard-pages/user-closed-sale/user-closed-sale-payments/user-closed-sale-payments.component';
import { AdminNotificationsComponent } from './ui/pages/admin-dashboard-pages/admin-notifications/admin-notifications.component';
import { AdminMasterLoadPageComponent } from './ui/pages/admin-dashboard-pages/admin-master-load-page/admin-master-load-page.component';
import { AdminEditPalletsComponent } from './ui/pages/admin-dashboard-pages/admin-manage-orders-page/admin-edit-pallets/admin-edit-pallets.component';
import { SignupQccompanyPageComponent } from './ui/pages/signup-qccompany-page/signup-qccompany-page.component';
import { QcUserReviewsComponent } from './ui/pages/qc-user-dashboard-pages/qc-user-reviews/qc-user-reviews.component';
import { QcUserOriginReviewComponent } from './ui/pages/qc-user-dashboard-pages/qc-user-origin-review/qc-user-origin-review.component';
import { QcUserOriginReviewedComponent } from './ui/pages/qc-user-dashboard-pages/qc-user-origin-reviewed/qc-user-origin-reviewed.component';
import { ForwarderCreateOrderPageComponent } from './ui/pages/forwarder-pages/forwarder-create-order-page/forwarder-create-order-page.component';
import { AdminCreateUserComponent } from './ui/pages/admin-dashboard-pages/admin-users/admin-create-user/admin-create-user.component';
import { AdminExporterImportersPageComponent } from './ui/pages/admin-dashboard-pages/admin-users/admin-exporter-importers-page/admin-exporter-importers-page.component';
import { FrozenFruitExportOffersComponent } from './ui/pages/user-dashboard-pages/user-frozen-fruit-export-offer/frozen-fruit-export-offers/frozen-fruit-export-offers.component';
import { FrozenFruitExportOfferCreateComponent } from './ui/pages/user-dashboard-pages/user-frozen-fruit-export-offer/frozen-fruit-export-offer-create/frozen-fruit-export-offer-create.component';
import { FrozenFruitExportOffersActiveComponent } from './ui/pages/user-dashboard-pages/user-frozen-fruit-export-offer/frozen-fruit-export-offers-active/frozen-fruit-export-offers-active.component';
import { FrozenFruitExportOffersInactiveComponent } from './ui/pages/user-dashboard-pages/user-frozen-fruit-export-offer/frozen-fruit-export-offers-inactive/frozen-fruit-export-offers-inactive.component';
import { FrozenFruitExportOfferEditComponent } from './ui/pages/user-dashboard-pages/user-frozen-fruit-export-offer/frozen-fruit-export-offer-edit/frozen-fruit-export-offer-edit.component';
import { FrozenFruitExportOfferEditPicturesComponent } from './ui/pages/user-dashboard-pages/user-frozen-fruit-export-offer/frozen-fruit-export-offer-edit-pictures/frozen-fruit-export-offer-edit-pictures.component';
import { FrozenFruitExportOfferEditDocumentsComponent } from './ui/pages/user-dashboard-pages/user-frozen-fruit-export-offer/frozen-fruit-export-offer-edit-documents/frozen-fruit-export-offer-edit-documents.component';
import { FrozenFruitPurchaseOrdersPageComponent } from './ui/pages/purchase-orders-page/frozen-fruit-purchase-orders-page/frozen-fruit-purchase-orders-page.component';
import { FrozenFruitVerifyPalletsComponent } from './ui/pages/user-dashboard-pages/user-purchase-orders/verify-pallets-page/frozen-fruit-verify-pallets/frozen-fruit-verify-pallets.component';
import { QcUserFrozenFruitOriginReviewComponent } from './ui/pages/qc-user-dashboard-pages/qc-user-frozen-fruit-origin-review/qc-user-frozen-fruit-origin-review.component';
import { AdminFrozenFruitVerifyPalletsComponent } from './ui/pages/admin-dashboard-pages/admin-manage-orders-page/admin-frozen-fruit-verify-pallets/admin-frozen-fruit-verify-pallets.component';
import { AdminInvitationCodesComponent } from './ui/pages/admin-dashboard-pages/admin-invitation-codes/admin-invitation-codes.component';
import { ExportOfferEditAdditionalInfoComponent } from './ui/pages/user-dashboard-pages/user-export-offers/export-offer-edit-additional-info/export-offer-edit-additional-info.component';
import { ExportOfferDocumentsPageComponent } from './ui/pages/user-dashboard-pages/user-export-offers/export-offer-documents-page/export-offer-documents-page.component';
import { UserLoadOrdersComponent } from './ui/pages/user-dashboard-pages/user-purchase-orders/user-load-orders/user-load-orders.component';
import { QcUserReviewDocumentsComponent } from './ui/pages/qc-user-dashboard-pages/qc-user-review-documents/qc-user-review-documents.component';
import { AdminManageQcReviewsComponent } from './ui/pages/admin-dashboard-pages/admin-manage-qc-reviews/admin-manage-qc-reviews.component';
import { AdminQcReviewComponent } from './ui/pages/admin-dashboard-pages/admin-qc-review/admin-qc-review.component';
import { AdminQcReviewDocumentsComponent } from './ui/pages/admin-dashboard-pages/admin-qc-review-documents/admin-qc-review-documents.component';
import { UserDirectSaleFormComponent } from './ui/pages/user-dashboard-pages/user-closed-sale/user-direct-sale-form/user-direct-sale-form.component';
import { UserDirectSaleDocumentsPageComponent } from './ui/pages/user-dashboard-pages/user-closed-sale/user-direct-sale-documents-page/user-direct-sale-documents-page.component';
import { UserDirectSaleEditAdditionalInfoComponent } from './ui/pages/user-dashboard-pages/user-closed-sale/user-direct-sale-edit-additional-info/user-direct-sale-edit-additional-info.component';
import { SignupValidationtokenComponent } from './ui/pages/signup-page/signup-validationtoken/signup-validationtoken.component';
import { ForwarderUserReviewsComponent } from './ui/pages/forwarder-user-dashboard-pages/forwarder-user-reviews/forwarder-user-reviews.component';
import { ForwarderUserOriginReviewComponent } from './ui/pages/forwarder-user-dashboard-pages/forwarder-user-origin-review/forwarder-user-origin-review.component';
import { AdminForwardersComponent } from './ui/pages/admin-dashboard-pages/admin-forwarders/admin-forwarders.component';
import { AdminCreateForwarderComponent } from './ui/pages/admin-dashboard-pages/admin-forwarders/admin-create-forwarder/admin-create-forwarder.component';
import { AdminEditForwarderComponent } from './ui/pages/admin-dashboard-pages/admin-forwarders/admin-edit-forwarder/admin-edit-forwarder.component';
import { ForwarderUserOriginReviewedComponent } from './ui/pages/forwarder-user-dashboard-pages/forwarder-user-origin-reviewed/forwarder-user-origin-reviewed.component';
import { ForwarderUsersComponent } from './ui/pages/forwarder-user-dashboard-pages/forwarder-users/forwarder-users.component';
import { UserProgramRequestCreateComponent } from './ui/pages/user-dashboard-pages/user-program-request/user-program-request-create/user-program-request-create.component';
import { UserProgramEditComponent } from './ui/pages/user-dashboard-pages/user-program-request/user-program-edit/user-program-edit.component';
import { ForwarderDeliveryComponent } from './ui/pages/forwarder-user-dashboard-pages/forwarder-delivery/forwarder-delivery.component'
import { AdminCustomAgentsComponent } from './ui/pages/admin-dashboard-pages/admin-custom-agents/admin-custom-agents.component';
import { AdminCreateCustomAgentComponent } from './ui/pages/admin-dashboard-pages/admin-custom-agents/admin-create-custom-agent/admin-create-custom-agent.component';
import { AdminEditCustomAgentComponent } from './ui/pages/admin-dashboard-pages/admin-custom-agents/admin-edit-custom-agent/admin-edit-custom-agent.component';
import { CustomAgentReviewsComponent } from './ui/pages/custom-agent-dashboard-pages/custom-agent-reviews/custom-agent-reviews.component';
import { CustomAgentOriginReviewComponent } from './ui/pages/custom-agent-dashboard-pages/custom-agent-origin-review/custom-agent-origin-review.component';
import { CustomAgentOriginReviewedComponent } from './ui/pages/custom-agent-dashboard-pages/custom-agent-origin-reviewed/custom-agent-origin-reviewed.component';
import { CustomAgentUsersComponent } from './ui/pages/custom-agent-dashboard-pages/custom-agent-users/custom-agent-users.component'
import { ForwarderImportOrderComponent } from './ui/pages/forwarder-user-dashboard-pages/forwarder-import-order/forwarder-import-order.component';
import { ForwarderOrderDetailComponent } from './ui/pages/forwarder-user-dashboard-pages/forwarder-import-order/forwarder-order-detail/forwarder-order-detail.component';
import { ForwarderEditImportOrderComponent } from './ui/pages/forwarder-user-dashboard-pages/forwarder-import-order/forwarder-edit-import-order/forwarder-edit-import-order.component';
import { UserImportOrdersForwarderComponent } from './ui/pages/user-dashboard-pages/user-purchase-orders/user-import-orders-forwarder/user-import-orders-forwarder.component';
import { OrderNotFoundComponent } from './ui/pages/order-not-found/order-not-found.component';
import { QcOrderDetailComponent } from './ui/pages/user-dashboard-pages/user-purchase-orders/qc-order-detail/qc-order-detail.component';
import { QcOrderPaymentComponent } from './ui/pages/user-dashboard-pages/user-purchase-orders/qc-order-payment/qc-order-payment.component';
import { ExternalMarketplacePageComponent } from './ui/pages/external-marketplace-page/external-marketplace-page.component';
import { OfferDetailComponent } from './ui/pages/offer-detail/offer-detail.component';
import { ProductDetailComponent } from './ui/pages/product-detail/product-detail.component';
import { ImportRequestDetailPageComponent } from './ui/pages/marketplace-page/import-request-detail-page/import-request-detail-page.component';
import { ComexVirtualPageComponent } from './ui/pages/comex-virtual-page/comex-virtual-page.component';

const routes: Routes = [
  {
    path: AppRoutes.homePage,
    component: HomePageComponent,
    pathMatch: 'full'
  },
  {
    path: AppRoutes.forbiddenPage,
    component: ForbiddenPageComponent
  },
  {
    path: AppRoutes.login,
    component: LoginPageComponent
  },
  {
    path: AppRoutes.signup,
    component: SignupPageContactComponent
  },
  {
    path: AppRoutes.signup + '/:userJoinRequestId',
    component: SignupPageContactComponent
  },
  {
    path: AppRoutes.signupCompany,
    component: SignupPageCompanyComponent
  },
  {
    path: AppRoutes.signupImporterAddInfo,
    component: SignupPageImportAddInfoComponent
  },
  {
    path: AppRoutes.signupInvitationCode,
    component: SignupInvitationcodeComponent
  },
  {
    path: AppRoutes.userDashboard,
    component: UserDashboardComponent
  },
  {
    path: AppRoutes.userContactInfo,
    component: UserContactInfoComponent
  },
  {
    path: AppRoutes.userCompanyInfo,
    component: UserCompanyInfoComponent
  },
  {
    path: AppRoutes.userProducts,
    component: UserProductsComponent,
    data: {
      roles: ['Importer', 'Exporter', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.userFridgestorages,
    component: UserFridgestoragesComponent,
    data: {
      roles: ['Exporter', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.fridgestorageForm + '/:fridgestorageId',
    component: FridgestorageFormComponent,
    data: {
      roles: ['Exporter', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.userConsignees,
    component: UserConsigneesComponent,
    data: {
      roles: ['Importer', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.consigneeForm + '/:consigneeId',
    component: ConsigneeFormComponent,
    data: {
      roles: ['Importer', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.userNotifiers,
    component: UserNotifiesComponent,
    data: {
      roles: ['Importer', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.notifierForm + '/:notifierId',
    component: NotifierFormComponent,
    data: {
      roles: ['Importer', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.userChangePassword,
    component: UserChangePasswordComponent
  },
  {
    path: AppRoutes.resetPasswordRequestPage,
    component: ResetPasswordRequestPageComponent
  },
  {
    path: AppRoutes.resetPasswordPage + '/:token',
    component: ResetPasswordPageComponent
  },
  {
    path: AppRoutes.userNotifications,
    component: UserNotificationsComponent
  },
  {
    path: AppRoutes.userNotificationsConfig,
    component: UserNotificationsConfigComponent
  },
  {
    path: AppRoutes.userSupport,
    component: UserSupportComponent
  },
  {
    path: AppRoutes.offerDetail + '/:adsCode',
    component: OfferDetailComponent
  },
  {
    path: AppRoutes.productDetail + '/:exportOfferGuid',
    component: ProductDetailComponent,
    data: {
      roles: ['Importer', 'Admin', "Exporter"]
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.externalMarketplace,
    component: ExternalMarketplacePageComponent
  },
  {
    path: AppRoutes.marketplacePage,
    component: MarketplacePageComponent,
    data: {
      roles: ['Importer', 'Exporter', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.userExportOffers,
    component: UserExportOffersComponent,
    data: {
      roles: ['Exporter', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.newExportOffer,
    component: NewExportOfferComponent,
    data: {
      roles: ['Exporter', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.editExportOffer + '/:exportOfferId',
    component: EditExportOfferComponent,
    data: {
      roles: ['Exporter', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.exportOfferEditAdditionalInfo + '/:exportOfferId',
    component: ExportOfferEditAdditionalInfoComponent,
    data: {
      roles: ['Exporter', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.exportOfferDocumentsPage + '/:exportOfferId',
    component: ExportOfferDocumentsPageComponent,
    data: {
      roles: ['Exporter', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.userProductCertificates,
    component: UserProductCertificatesComponent,
    data: {
      roles: ['Exporter', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.productCertificateForm + '/:productCertificateId',
    component: ProductCertificateFormComponent,
    data: {
      roles: ['Exporter', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.purchaseOrdersPage,
    component: PurchaseOrdersPageComponent,
    data: {
      roles: ['Importer', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.containerOrderPage,
    component: ContainerOrderPageComponent,
    data: {
      roles: ['Importer', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.userPurchaseOrders,
    component: UserPurchaseOrdersComponent,
    data: {
      roles: ['Importer', 'Exporter', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.verifyPalletsPage + '/:orderDetailId',
    component: VerifyPalletsPageComponent,
    data: {
      roles: ['Importer', 'Exporter', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.userOrderDetailPage + '/:orderId',
    component: OrderDetailComponent,
    data: {
      roles: ['Importer', 'Exporter', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.userConfirmPurchasePage + '/:orderId',
    component: ConfirmPurchasePageComponent,
    data: {
      roles: ['Importer', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.userPaymentsPage + '/:importOrderId',
    component: PaymentsPageComponent,
    data: {
      roles: ['Importer', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.adminManageOrderPage,
    component: AdminManageOrdersPageComponent,
    data: {
      roles: ['Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.userBargaining,
    component: UserBargainingComponent,
    data: {
      roles: ['Importer', 'Exporter', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.bargainingDetail + '/:negotiationId',
    component: BargainingDetailComponent,
    data: {
      roles: ['Importer', 'Exporter', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.userExportOfferSuggestionPage,
    component: UserExportOfferSuggestionsComponent,
    data: {
      roles: ['Importer', 'Exporter', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.userOfferSuggestionsPage + '/:suggestionId',
    component: OfferSuggestionsComponent,
    data: {
      roles: ['Importer', 'Exporter', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.userProgramRequestCreate,
    component: UserProgramRequestCreateComponent,
    data: {
      roles: ['Importer', 'Admin', 'Exporter']
    },
    canActivate: [AuthGuard, RolesGuard]

  },
  {
    path: AppRoutes.userProgramRequestDirectSaleCreate + '/:programRequestId',
    component: UserDirectSaleFormComponent,
    data: {
      roles: ['Importer', 'Admin', 'Exporter']
    },
    canActivate: [AuthGuard, RolesGuard]

  },
  {
    path: AppRoutes.userProgramRequestEdit + '/:programRequestId',
    component: UserProgramEditComponent,
    data: {
      roles: ['Importer', 'Admin', 'Exporter']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.adminOrderDetail + '/:orderId',
    component: AdminOrderDetailComponent,
    data: {
      roles: ['Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.adminVerifyPallets + '/:orderDetailId',
    component: AdminVerifyPalletsComponent,
    data: {
      roles: ['Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.adminCompleteOrder + '/:orderDetailId',
    component: AdminCompleteOrderComponent,
    data: {
      roles: ['Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.adminDelivery + '/:orderId',
    component: AdminDeliveryComponent,
    data: {
      roles: ['Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.adminOperation + '/:orderId',
    component: AdminOperationComponent,
    data:{
      roles: ['Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.adminExportOfferPage,
    component: AdminManageExportOffersPageComponent,
    data:{
      roles: ['Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.adminUsers,
    component: AdminUsersComponent,
    data:{
      roles: ['Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.adminExportOfferEdit + '/:exportOfferId',
    component: AdminEditExportOfferComponent,
    data:{
      roles: ['Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.adminEditUser + '/:profileId',
    component: AdminEditUserComponent,
    data:{
      roles: ['Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.adminSpecies,
    component: AdminManageSpeciesComponent,
    data: {
      roles: ['Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.adminSpeciesNew,
    component: AdminNewSpeciesComponent,
    data: {
      roles: ['Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.adminNotifications,
    component: AdminNotificationsComponent,
    data: {
      roles: ['Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.adminMasterLoadPage,
    component: AdminMasterLoadPageComponent,
    data: {
      roles: ['Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.adminEditPallets + '/:orderDetailId',
    component: AdminEditPalletsComponent,
    data: {
      roles: ['Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.userImportRequest,
    component: UserImportRequestComponent,
    data: {
      roles: ['Importer', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.userNewImportRequest,
    component: NewImportRequestComponent,
    data: {
      roles: ['Importer', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.importRequestDetail + '/:importRequestGuid',
    component: ImportRequestDetailPageComponent,
    data: {
      roles: ['Importer', 'Admin', 'Exporter']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.userEditImportRequest + '/:importRequestId',
    component: EditImportRequestComponent,
    data: {
      roles: ['Importer', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.userViewImportRequest + '/:importRequestId',
    component: ViewImportRequestOffersComponent,
    data: {
      roles: ['Importer', 'Exporter', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.userClosedSales,
    component: UserClosedSaleComponent,
    data: {
      roles: ['Importer','Exporter', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.userCreateClosedSale,
    component: UserCreateClosedSaleComponent,
    data: {
      roles: ['Exporter', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.userEditClosedSale + '/:exportOfferId',
    component: UserEditClosedSaleComponent,
    data: {
      roles: ['Exporter', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.exporterClosedSaleOrdersPage + '/:directSaleProgramId',
    component: ExporterClosedSaleOrdersComponent,
    data: {
      roles: ['Exporter', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.userClosedSaleImportOrderDetailPage + '/:orderId/:directSaleProgramId',
    component: UserClosedSaleImportOrderDetailComponent,
    data: {
      roles: ['Importer', 'Exporter', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.userClosedSaleExporterVerifyPallets + '/:orderDetailId/:directSaleProgramId',
    component: UserClosedSaleExporterVerifyPalletsComponent,
    data: {
      roles: ['Importer', 'Exporter', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.userClosedSalePaymentsPage + '/:importOrderId/:directSaleProgramId',
    component: UserClosedSalePaymentsComponent,
    data: {
      roles: ['Importer', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.importerClosedSaleOrdersPage + '/:directSaleProgramId',
    component: ImporterClosedSaleOrdersComponent,
    data: {
      roles: ['Importer', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.userPanelPage,
    component: UserPanelComponent,
    data: {
      roles: ['Importer', 'Exporter', 'QCUser', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.userImportOrdersPending,
    component: UserImportOrdersPendingComponent,
    data: {
      roles: ['Importer', 'Exporter', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.userImportOrdersApproved,
    component: UserImportOrdersApprovedComponent,
    data: {
      roles: ['Importer', 'Exporter', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.userImportOrdersConfirmed,
    component: UserImportOrdersConfirmedComponent,
    data: {
      roles: ['Importer', 'Exporter', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.userImportOrdersCreated,
    component: UserImportOrdersCreatedComponent,
    data: {
      roles: ['Importer', 'Exporter', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.userImportOrdersCurrent,
    component: UserImportOrdersCurrentComponent,
    data: {
      roles: ['Importer', 'Exporter', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.userLoadOrders,
    component: UserLoadOrdersComponent,
    data: {
      roles: ['Importer', 'Exporter', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.userImportOrdersPurchase,
    component: UserImportOrdersPurchaseComponent,
    data: {
      roles: ['Importer', 'Exporter', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.signupQCCompany,
    component: SignupQccompanyPageComponent
  },
  {
    path: AppRoutes.qcUserReviews,
    component: QcUserReviewsComponent,
    data: {
      roles: ['QCUser', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.qcUserOriginReview + '/:reviewId',
    component: QcUserOriginReviewComponent,
    data: {
      roles: ['QCUser', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.qcUserOriginReviewed,
    component: QcUserOriginReviewedComponent,
    data: {
      roles: ['QCUser', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.forwarderCreateOrderPage,
    component: ForwarderCreateOrderPageComponent,
    data: {
      roles: ['Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.adminCreateUserPage,
    component: AdminCreateUserComponent,
    data: {
      roles: ['Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.adminExporterImportersPage + '/:exporterId',
    component: AdminExporterImportersPageComponent,
    data: {
      roles: ['Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.userFrozenFruitExportOfferCreate,
    component: FrozenFruitExportOfferCreateComponent,
    data: {
      roles: ['Admin', 'Exporter']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.userFrozenFruitExportOffersActivePage,
    component: FrozenFruitExportOffersActiveComponent,
    data: {
      roles: ['Admin', 'Exporter']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.userFrozenFruitExportOffersInactivePage,
    component: FrozenFruitExportOffersInactiveComponent,
    data: {
      roles: ['Admin', 'Exporter']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.userFrozenFruitExportOfferEdit + '/:frozenFruitExportOfferId',
    component: FrozenFruitExportOfferEditComponent,
    data: {
      roles: ['Admin', 'Exporter']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.userFrozenFruitExportOfferEditPictures + '/:frozenFruitExportOfferId',
    component: FrozenFruitExportOfferEditPicturesComponent,
    data: {
      roles: ['Admin', 'Exporter']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.userFrozenFruitExportOfferEditDocuments + '/:frozenFruitExportOfferId',
    component: FrozenFruitExportOfferEditDocumentsComponent,
    data: {
      roles: ['Admin', 'Exporter']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.userFrozenFruitPurchaseOrdersPage,
    component: FrozenFruitPurchaseOrdersPageComponent,
    data: {
      roles: ['Admin', 'Importer']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.userFrozenFruitVerifyPalletsPage + '/:orderDetailId',
    component: FrozenFruitVerifyPalletsComponent,
    data: {
      roles: ['Admin', 'Exporter']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.qcUserFrozenFruitOriginReviewPage + '/:orderDetailId',
    component: QcUserFrozenFruitOriginReviewComponent,
    data: {
      roles: ['Admin', 'QCUser']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.adminFrozenFruitVerifyPalletsPage + '/:orderDetailId',
    component: AdminFrozenFruitVerifyPalletsComponent,
    data: {
      roles: ['Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.adminInvitationCodes,
    component: AdminInvitationCodesComponent,
    data: {
      roles: ['Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.qcUserReviewDocuments + '/:reviewId',
    component: QcUserReviewDocumentsComponent,
    data: {
      roles: ['Admin', 'QCUser']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.adminManageQCReviews,
    component: AdminManageQcReviewsComponent,
    data: {
      roles: ['Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.adminQCReview + '/:reviewId',
    component: AdminQcReviewComponent,
    data: {
      roles: ['Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.adminQCReviewDocuments + '/:reviewId',
    component: AdminQcReviewDocumentsComponent,
    data: {
      roles: ['Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.userDirectSaleCreate,
    component: UserDirectSaleFormComponent,
    data: {
      roles: ['Admin', 'Exporter']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.userDirectSaleEdit + '/:guid',
    component: UserDirectSaleFormComponent,
    data: {
      roles: ['Admin', 'Exporter']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.userDirectSaleDocuments + '/:exportOfferId',
    component: UserDirectSaleDocumentsPageComponent,
    data: {
      roles: ['Admin', 'Exporter']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.userDirectSaleEditAdditionalInfo + '/:exportOfferId',
    component: UserDirectSaleEditAdditionalInfoComponent,
    data: {
      roles: ['Admin', 'Exporter']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.forwarderUserReviews,
    component: ForwarderUserReviewsComponent,
    data: {
      roles: ['Admin', 'Forwarder']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.forwarderUserOriginReview + '/:reviewId',
    component: ForwarderUserOriginReviewComponent,
    data: {
      roles: ['Admin', 'Forwarder']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.adminForwarder,
    component: AdminForwardersComponent,
    data: {
      roles: ['Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.adminCreateForwarderPage,
    component: AdminCreateForwarderComponent,
    data: {
      roles: ['Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.adminEditForwarderPage + '/:forwarderId',
    component: AdminEditForwarderComponent,
    data:{
      roles: ['Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.forwarderUserOriginReviewed,
    component: ForwarderUserOriginReviewedComponent,
    data: {
      roles: ['Admin', 'Forwarder']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.forwarderUsers,
    component: ForwarderUsersComponent,
    data: {
      roles: ['Forwarder']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.forwarderUsersDelivery + '/:orderId',
    component: ForwarderDeliveryComponent,
    data: {
      roles: ['Forwarder']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.adminCustomAgent,
    component: AdminCustomAgentsComponent,
    data: {
      roles: ['Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.adminCreateCustomAgentPage,
    component: AdminCreateCustomAgentComponent,
    data: {
      roles: ['Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.adminEditCustomAgentPage + '/:customAgentId',
    component: AdminEditCustomAgentComponent,
    data:{
      roles: ['Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.customAgentUserReviews,
    component: CustomAgentReviewsComponent,
    data: {
      roles: ['Admin', 'CustomAgent']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.customAgentUserOriginReview + '/:reviewId',
    component: CustomAgentOriginReviewComponent,
    data: {
      roles: ['Admin', 'CustomAgent']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.customAgentUserOriginReviewed,
    component: CustomAgentOriginReviewedComponent,
    data: {
      roles: ['Admin', 'CustomAgent']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.customAgentUsers,
    component: CustomAgentUsersComponent,
    data: {
      roles: ['CustomAgent']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.forwarderImportOrders,
    component: ForwarderImportOrderComponent,
    data: {
      roles: ['Forwarder']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.forwarderImportOrderDetail + '/:orderId',
    component: ForwarderOrderDetailComponent,
    data: {
      roles: ['Forwarder']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.forwarderImportOrderEdit + '/:importOrderId',
    component: ForwarderEditImportOrderComponent,
    data: {
      roles: ['Forwarder']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.userImportOrdersForwarder,
    component: UserImportOrdersForwarderComponent,
    data: {
      roles: ['Importer', 'Exporter', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.userValidationToken + '/:token',
    component: SignupValidationtokenComponent
  },
  {
    path: AppRoutes.userOrderNotFound,
    component: OrderNotFoundComponent
  },
  {
    path: AppRoutes.qcOrderDetailPage + '/:orderId',
    component: QcOrderDetailComponent,
    data: {
      roles: ['Importer', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.qcOrderPaymentPage + '/:orderId',
    component: QcOrderPaymentComponent,
    data: {
      roles: ['Importer', 'Admin']
    },
    canActivate: [AuthGuard, RolesGuard]
  },
  {
    path: AppRoutes.toDoPage,
    component: ComexVirtualPageComponent,
  },
  {
    path: '**',
    component: HomePageComponent,
    redirectTo: AppRoutes.homePage
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
],
  exports: [RouterModule],
})
export class AppRoutingModule { }
