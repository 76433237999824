<app-user-dashboard [userMenuActiveItemIndex]="0" [activeItem]="'marketplace'">
  <p-card>
    <h1>{{'FrozenFruitExportOfferForm.EditFormTitle' | translate}}</h1>
    <form [formGroup]="formGroup">
      <h2>{{'FrozenFruitExportOfferForm.EditSubtitle1' | translate}}</h2>
      <div class="p-fluid formgrid mr-2 ml-2 grid" style="margin-top: 40px;">
        <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
          <label for="species" style="font-weight: bolder;">{{'FrozenFruitExportOfferForm.SpeciesLabel' | translate}} *</label>
          <p-dropdown id="species"
            formControlName="speciesId"
            [options]="species"
            optionLabel="nameEs"
            optionValue="id"
            [filter]="true"
            filterBy="name"
            placeholder="{{'FrozenFruitExportOfferForm.SpeciesLabel' | translate}}"
            (onChange)="loadSpeciesParams()"
            [readonly]="true">
            <ng-template pTemplate="selectedItem">
              <div class="flex align-items-center gap-2" *ngIf="selectedSpecies">
                  <img [src]="selectedSpecies.imageUrl" style="width: 18px" alt=""/>
                  <div>{{ getSpeciesName(selectedSpecies) }}</div>
              </div>
            </ng-template>
            <ng-template let-specie pTemplate="item">
                <div class="flex align-items-center gap-2">
                    <img [src]="specie.imageUrl" style="width: 18px" alt=""/>
                    <div>{{ getSpeciesName(specie) }}</div>
                </div>
            </ng-template>
          </p-dropdown>
          <small *ngIf="submit && formGroup.controls['speciesId'].invalid" class="p-error">{{getErrorMessage(formGroup, 'speciesId')}}</small>
        </div>
        <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
          <label for="variety" style="font-weight: bolder;">{{'FrozenFruitExportOfferForm.VarietyLabel' | translate}} *</label>
          <p-dropdown id="variety"
            formControlName="varietyId"
            [options]="varieties"
            optionLabel="name"
            optionValue="id"
            [filter]="true"
            filterBy="name"
            [showClear]="true"
            placeholder="{{'FrozenFruitExportOfferForm.VarietyLabel' | translate}}"
            (onChange)="onChangeVariety()"
            emptyMessage="{{'FrozenFruitExportOfferForm.VarietyFieldEmptyMessage' | translate}}">
            <ng-template pTemplate="selectedItem">
              <div class="flex align-items-center gap-2" *ngIf="selectedVariety">
                  <img [src]="selectedVariety.urlIcon" style="width: 18px" alt=""/>
                  <div>{{ selectedVariety.name }}</div>
              </div>
            </ng-template>
            <ng-template let-variety pTemplate="item">
                <div class="flex align-items-center gap-2">
                    <img [src]="variety.urlIcon" style="width: 18px" alt=""/>
                    <div>{{ variety.name }}</div>
                </div>
            </ng-template>
          </p-dropdown>
          <small *ngIf="submit && formGroup.controls['varietyId'].invalid" class="p-error">{{getErrorMessage(formGroup, 'varietyId')}}</small>
        </div>
        <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
          <label for="category" style="font-weight: bolder;">{{'FrozenFruitExportOfferForm.CategoryLabel' | translate}} *</label>
          <input pInputText inputId="category" formControlName="category" [maxlength]="30" placeholder="{{'FrozenFruitExportOfferForm.CategoryLabel' | translate}}"/>
          <small *ngIf="submit && formGroup.controls['category'].invalid" class="p-error">{{getErrorMessage(formGroup, 'category')}}</small>
        </div>
        <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
          <label for="format" style="font-weight: bolder;">{{'FrozenFruitExportOfferForm.FormatLabel' | translate}} *</label>
          <input pInputText inputId="format" formControlName="format" [maxlength]="100" placeholder="{{'FrozenFruitExportOfferForm.FormatLabel' | translate}}"/>
          <small *ngIf="submit && formGroup.controls['format'].invalid" class="p-error">{{getErrorMessage(formGroup, 'format')}}</small>
        </div>
        <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
          <label for="size" style="font-weight: bolder;">{{'FrozenFruitExportOfferForm.SizeLabel' | translate}} *</label>
          <input pInputText inputId="size" formControlName="size" [maxlength]="50" placeholder="{{'FrozenFruitExportOfferForm.SizeLabel' | translate}}"/>
          <small *ngIf="submit && formGroup.controls['size'].invalid" class="p-error">{{getErrorMessage(formGroup, 'size')}}</small>
        </div>
        <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
          <label for="price" style="font-weight: bolder;">{{'FrozenFruitExportOfferForm.PriceLabel' | translate}} *</label>
          <p-inputNumber inputId="price" formControlName="price" mode="currency" currency="USD" locale="en-US" [min]="1" placeholder="{{'FrozenFruitExportOfferForm.PriceLabel' | translate}}"></p-inputNumber>
          <small *ngIf="submit && formGroup.controls['price'].invalid" class="p-error">{{getErrorMessage(formGroup, 'price')}}</small>
        </div>
        <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
          <label for="incoterms" style="font-weight: bolder;">{{'FrozenFruitExportOfferForm.IncotermsLabel' | translate}} *</label>
          <p-dropdown inputId="incoterms"
            formControlName="incoterms"
            [options]="incotermsOptions"
            optionLabel="label"
            optionValue="value"
            [showClear]="true"
            placeholder="{{'FrozenFruitExportOfferForm.IncotermsLabel' | translate}}"></p-dropdown>
          <small *ngIf="submit && formGroup.controls['incoterms'].invalid" class="p-error">{{getErrorMessage(formGroup, 'incoterms')}}</small>
        </div>
        <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
          <label for="containersQuantity" style="font-weight: bolder;">Containers *</label>
          <p-inputNumber
            inputId="containersQuantity"
            formControlName="containersQuantity"
            [useGrouping]="false"
            [min]="1"
            [step]="1"
            [showButtons]="false"
            placeholder="Containers"></p-inputNumber>
          <small *ngIf="submit && formGroup.controls['containersQuantity'].invalid" class="p-error">{{getErrorMessage(formGroup, 'containersQuantity')}}</small>
        </div>
        <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
          <label for="productStackingType" style="font-weight: bolder;">{{'FrozenFruitExportOfferForm.ProductStackingTypeLabel' | translate}} *</label>
          <p-dropdown inputId="productStackingType"
            formControlName="productStackingType"
            [options]="productStackingTypeOptions"
            optionLabel="label"
            optionValue="value"
            [showClear]="true"
            placeholder="{{'FrozenFruitExportOfferForm.ProductStackingTypeLabel' | translate}}"></p-dropdown>
          <small *ngIf="submit && formGroup.controls['productStackingType'].invalid" class="p-error">{{getErrorMessage(formGroup, 'productStackingType')}}</small>
        </div>
        <!--div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;" *ngIf="formGroup.controls['productStackingType'].value && formGroup.controls['productStackingType'].value === 2">
          <label for="palletsQuantity" style="font-weight: bolder;">{{'FrozenFruitExportOfferForm.PalletsQuantityLabel' | translate}} *</label>
          <p-inputNumber inputId="palletsQuantity" formControlName="palletsQuantity" [useGrouping]="false" placeholder="{{'FrozenFruitExportOfferForm.PalletsQuantityLabel' | translate}}"></p-inputNumber>
          <small *ngIf="submit && isRequiredPalletQuantity" class="p-error">{{getPalletQuantityRequiredErrorMessage()}}</small>
        </div-->
        <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;" *ngIf="formGroup.controls['productStackingType'].value && formGroup.controls['productStackingType'].value === 2">
          <label for="boxesPerPallet" style="font-weight: bolder;">{{'FrozenFruitExportOfferForm.BoxesPerPalletLabel' | translate}} *</label>
          <p-inputNumber inputId="boxesPerPallet" formControlName="boxesPerPallet" [useGrouping]="false" placeholder="{{'FrozenFruitExportOfferForm.BoxesPerPalletLabel' | translate}}"></p-inputNumber>
          <small *ngIf="submit && isRequiredBoxesPerPallet" class="p-error">{{getBoxesPerPalletRequiredErrorMessage()}}</small>
        </div>
        <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;" *ngIf="formGroup.controls['productStackingType'].value && formGroup.controls['productStackingType'].value === 1">
          <label for="boxesQuantity" style="font-weight: bolder;">{{'FrozenFruitExportOfferForm.BoxesQuantityLabel' | translate}} *</label>
          <p-inputNumber inputId="boxesQuantity" formControlName="boxesQuantity" [useGrouping]="false" placeholder="{{'FrozenFruitExportOfferForm.BoxesQuantityLabel' | translate}}"></p-inputNumber>
          <small *ngIf="submit && isRequiredBoxesQuantity" class="p-error">{{getBoxesQuantityRequiredErrorMessage()}}</small>
        </div>
        <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
          <label for="fridgeStorage" style="font-weight: bolder;">{{'FrozenFruitExportOfferForm.ProcessPlantLabel' | translate}} *</label>
          <p-dropdown id="fridgeStorage"
            formControlName="fridgeStorageId"
            [options]="fridgeStorages"
            optionLabel="name"
            optionValue="id"
            [filter]="true"
            filterBy="name"
            [showClear]="true"
            placeholder="{{'FrozenFruitExportOfferForm.ProcessPlantLabel' | translate}}"
            emptyMessage="{{'FrozenFruitExportOfferForm.ProcessPlantFieldEmptyMessage' | translate}}"
            (onChange)="onChangeFridgeStorage()">
            <ng-template pTemplate="selectedItem">
              <div class="flex align-items-center gap-2" *ngIf="selectedFridgeStorage">
                <img [src]="'../../../../assets/img/flags/' + selectedFridgeStorage.contactCountry?.code + '.png'" style="width: 18px" alt=""/>
                <div>{{ selectedFridgeStorage + ', ' + selectedFridgeStorage.contactAddress + ' - ' +  (selectedFridgeStorage.contactCountry | countryDescription)}}</div>
              </div>
            </ng-template>
            <ng-template let-fridgestorage pTemplate="item">
                <div class="flex align-items-center gap-2">
                  <img [src]="'../../../../assets/img/flags/' + fridgestorage.contactCountry?.code + '.png'" style="width: 18px" alt=""/>
                  <div>{{ fridgestorage.name + ', ' + fridgestorage.contactAddress + ' - ' +  (fridgestorage.contactCountry | countryDescription)}}</div>
                </div>
            </ng-template>
          </p-dropdown>
          <small *ngIf="submit && formGroup.controls['fridgeStorageId'].invalid" class="p-error">{{getErrorMessage(formGroup, 'fridgeStorageId')}}</small>
        </div>
      </div>

      <div style="margin-top: 40px;">
        <h2>{{'FrozenFruitExportOfferForm.EditSubtitle2' | translate}}</h2>
        <div class="p-fluid formgrid mr-2 ml-2 grid">
          <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
            <label for="harvestDate" style="font-weight: bolder;">{{'FrozenFruitExportOfferForm.HarvestDateLabel' | translate}}</label>
            <p-calendar
              inputId="harvestDate"
              formControlName="harvestDate"
              appendTo="body"
              [showButtonBar]="true"
              [showIcon]="true"
              placeholder="{{'FrozenFruitExportOfferForm.HarvestDateLabel' | translate}}"
              [showWeek]="true"
              [firstDayOfWeek]="1"></p-calendar>
          </div>
          <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
            <label for="processDate" style="font-weight: bolder;">{{'FrozenFruitExportOfferForm.ProcessDateLabel' | translate}}</label>
            <p-calendar
              inputId="processDate"
              formControlName="processDate"
              appendTo="body"
              [showButtonBar]="true"
              [showIcon]="true"
              placeholder="{{'FrozenFruitExportOfferForm.ProcessDateLabel' | translate}}"
              [showWeek]="true"
              [firstDayOfWeek]="1"></p-calendar>
          </div>
          <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
            <label for="expirationDate" style="font-weight: bolder;">{{'FrozenFruitExportOfferForm.ExpirationDateLabel' | translate}}</label>
            <p-calendar
              inputId="expirationDate"
              formControlName="expirationDate"
              appendTo="body"
              [showButtonBar]="true"
              [showIcon]="true"
              placeholder="{{'FrozenFruitExportOfferForm.ExpirationDateLabel' | translate}}"
              [showWeek]="true"
              [firstDayOfWeek]="1"></p-calendar>
          </div>
          <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
            <label for="netKilos" style="font-weight: bolder;">{{'FrozenFruitExportOfferForm.NetKilos' | translate}}</label>
            <input pInputText inputId="netKilos" formControlName="netKilos" [maxlength]="50" placeholder="{{'FrozenFruitExportOfferForm.NetKilos' | translate}}"/>
          </div>
          <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
            <label for="grossKilos" style="font-weight: bolder;">{{'FrozenFruitExportOfferForm.GrossKilosLabel' | translate}}</label>
            <input pInputText inputId="grossKilos" formControlName="grossKilos" [maxlength]="50" placeholder="{{'FrozenFruitExportOfferForm.GrossKilosLabel' | translate}}"/>
          </div>
          <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
            <label for="mainPackaging" style="font-weight: bolder;">{{'FrozenFruitExportOfferForm.MainPackagingLabel' | translate}}</label>
            <input pInputText inputId="mainPackaging" formControlName="mainPackaging" [maxlength]="100" placeholder="{{'FrozenFruitExportOfferForm.MainPackagingLabel' | translate}}"/>
          </div>
          <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
            <label for="boxType" style="font-weight: bolder;">{{'FrozenFruitExportOfferForm.BoxTypeLabel' | translate}}</label>
            <input pInputText inputId="boxType" formControlName="boxType" [maxlength]="100" placeholder="{{'FrozenFruitExportOfferForm.BoxTypeLabel' | translate}}"/>
          </div>
          <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
            <label for="boxSize" style="font-weight: bolder;">{{'FrozenFruitExportOfferForm.BoxSizeLabel' | translate}}</label>
            <input pInputText inputId="boxSize" formControlName="boxSize" [maxlength]="100" placeholder="{{'FrozenFruitExportOfferForm.BoxSizeLabel' | translate}}"/>
          </div>
          <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
            <label for="boxColor" style="font-weight: bolder;">{{'FrozenFruitExportOfferForm.BoxColorLabel' | translate}}</label>
            <input pInputText inputId="boxColor" formControlName="boxColor" [maxlength]="100" placeholder="{{'FrozenFruitExportOfferForm.BoxColorLabel' | translate}}"/>
          </div>
          <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
            <label for="bagType" style="font-weight: bolder;">{{'FrozenFruitExportOfferForm.BagTypeLabel' | translate}}</label>
            <input pInputText inputId="bagType" formControlName="bagType" [maxlength]="100" placeholder="{{'FrozenFruitExportOfferForm.BagTypeLabel' | translate}}"/>
          </div>
          <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
            <label for="bagColor" style="font-weight: bolder;">{{'FrozenFruitExportOfferForm.BagColorLabel' | translate}}</label>
            <input pInputText inputId="bagColor" formControlName="bagColor" [maxlength]="100" placeholder="{{'FrozenFruitExportOfferForm.BagColorLabel' | translate}}"/>
          </div>
          <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
            <label for="basePallet" style="font-weight: bolder;">{{'FrozenFruitExportOfferForm.BasePalletLabel' | translate}}</label>
            <input pInputText inputId="basePallet" formControlName="basePallet" [maxlength]="100" placeholder="{{'FrozenFruitExportOfferForm.BasePalletLabel' | translate}}"/>
          </div>
          <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
            <label for="isCertifiedPallet" style="font-weight: bolder;">{{'FrozenFruitExportOfferForm.CertifiedPalletsLabel' | translate}}</label>
            <p-dropdown id="isCertifiedPallet" formControlName="isCertifiedPallet" [options]="certifiedPalletOptions" optionLabel="label" optionValue="value" placeholder="{{'FrozenFruitExportOfferForm.CertifiedPalletsLabel' | translate}}"></p-dropdown>
          </div>
          <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
            <label for="isQualityControl" style="font-weight: bolder;">{{'FrozenFruitExportOfferForm.QualityControlLabel' | translate}}</label>
            <p-dropdown id="isQualityControl" formControlName="isQualityControl" [options]="qualityControlOptions" optionLabel="label" optionValue="value" placeholder="{{'FrozenFruitExportOfferForm.QualityControlLabel' | translate}}"></p-dropdown>
          </div>
          <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
            <label for="expirationDate" style="font-weight: bolder;">{{'FrozenFruitExportOfferForm.QualityControlDateLabel' | translate}}</label>
            <p-calendar
              inputId="expirationDate"
              formControlName="expirationDate"
              appendTo="body"
              [showButtonBar]="true"
              [showIcon]="true"
              placeholder="{{'FrozenFruitExportOfferForm.QualityControlDateLabel' | translate}}"
              [showWeek]="true"
              [firstDayOfWeek]="1"></p-calendar>
          </div>
          <div class="field col-12 md:col-6 lg:col-4" style="margin-bottom: 40px;">
            <label for="isMarks" style="font-weight: bolder;">{{'FrozenFruitExportOfferForm.MarksLabel' | translate}}</label>
            <p-dropdown id="isMarks" formControlName="isMarks" [options]="marksOptions" optionLabel="label" optionValue="value" placeholder="{{'FrozenFruitExportOfferForm.MarksLabel' | translate}}"></p-dropdown>
          </div>
          <div class="field col-12 md:col-6 lg:col-4">
            <label for="marketCountry1Id" style="font-weight: bolder;">{{'FrozenFruitExportOfferForm.MarketCountry1' | translate}}</label>
            <p-dropdown inputId="marketCountry1Id"
              formControlName="marketCountry1Id"
              [options]="countries"
              optionValue="id"
              optionLabel="name"
              placeholder="{{'FrozenFruitExportOfferForm.MarketCountry1' | translate}}"
              (onChange)="onChangeMarketCountry1()">
              <ng-template pTemplate="selectedItem">
                <div class="flex align-items-center gap-2" *ngIf="selectedMarketCountry1">
                    <img [src]="'../../../../assets/img/flags/' + selectedMarketCountry1.code + '.png'" style="width: 18px" alt=""/>
                    <div>{{ selectedMarketCountry1 | countryDescription }}</div>
                </div>
              </ng-template>
              <ng-template let-country pTemplate="item">
                  <div class="flex align-items-center gap-2">
                      <img [src]="'../../../../assets/img/flags/' + country.code + '.png'" style="width: 18px" alt=""/>
                      <div>{{ country | countryDescription }}</div>
                  </div>
              </ng-template>
            </p-dropdown>
          </div>
          <div class="field col-12 md:col-6 lg:col-4">
            <label for="marketCountry2" style="font-weight: bolder;">{{'FrozenFruitExportOfferForm.MarketCountry2' | translate}}</label>
            <p-dropdown inputId="marketCountry2"
              formControlName="marketCountry2Id"
              [options]="countries"
              optionValue="id"
              optionLabel="name"
              placeholder="{{'FrozenFruitExportOfferForm.MarketCountry2' | translate}}"
              [showClear]="true"
              (onChange)="onChangeMarketCountry2()">
              <ng-template pTemplate="selectedItem">
                <div class="flex align-items-center gap-2" *ngIf="selectedMarketCountry2">
                    <img [src]="'../../../../assets/img/flags/' + selectedMarketCountry2.code + '.png'" style="width: 18px" alt=""/>
                    <div>{{ selectedMarketCountry2 | countryDescription }}</div>
                </div>
              </ng-template>
              <ng-template let-country pTemplate="item">
                  <div class="flex align-items-center gap-2">
                      <img [src]="'../../../../assets/img/flags/' + country.code + '.png'" style="width: 18px" alt=""/>
                      <div>{{ country | countryDescription }}</div>
                  </div>
              </ng-template>
            </p-dropdown>
          </div>
          <div class="field col-12 md:col-6 lg:col-4">
            <label for="marketCountry3" style="font-weight: bolder;">{{'FrozenFruitExportOfferForm.MarketCountry3' | translate}}</label>
            <p-dropdown
              inputId="marketCountry3"
              formControlName="marketCountry3Id"
              [options]="countries"
              optionValue="id"
              optionLabel="name"
              placeholder="{{'FrozenFruitExportOfferForm.MarketCountry3' | translate}}"
              [showClear]="true"
              (onChange)="onChangeMarketCountry3()">
              <ng-template pTemplate="selectedItem">
                <div class="flex align-items-center gap-2" *ngIf="selectedMarketCountry3">
                    <img [src]="'../../../../assets/img/flags/' + selectedMarketCountry3.code + '.png'" style="width: 18px" alt=""/>
                    <div>{{ selectedMarketCountry3 | countryDescription }}</div>
                </div>
              </ng-template>
              <ng-template let-country pTemplate="item">
                  <div class="flex align-items-center gap-2">
                      <img [src]="'../../../../assets/img/flags/' + country.code + '.png'" style="width: 18px" alt=""/>
                      <div>{{ country | countryDescription }}</div>
                  </div>
              </ng-template>
            </p-dropdown>
          </div>
        </div>

        <div class="grid">
          <div class="col-12 md:col-6 lg:6"></div>
          <div class="col-12 md:col-6 lg:6">
            <div class="p-fluid formgrid grid">
              <div class="col-6">
                <p-button label="{{'FrozenFruitExportOfferForm.BackButtonLabel' | translate}}" [disabled]="loading" styleClass="p-button-outlined" (onClick)="goToFrozenFruitExportOffersPage()"></p-button>
              </div>
              <div class="col-6">
                <p-button label="{{'FrozenFruitExportOfferForm.SaveButtonLabel' | translate}}" [loading]="loading" (onClick)="save()"></p-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </p-card>
</app-user-dashboard>

<p-toast></p-toast>
