<app-admin-dashboard>
    <p-card>
        <ng-template pTemplate="header">
            <div class="flex justify-content-between flex-wrap align-items-center" style="padding: 15px;">
                <h2>Especies</h2>
            </div>
        </ng-template>
        <div class="flex justify-content-between align-items-end col-12">
            <div class="col-12 align-self-end" >
                <p-button label="Crear Especie" (onClick)="goToCreateSpecies()"></p-button>
            </div>
        </div>
        <p-table
        [value]="species"
        [rowsPerPageOptions]="[10, 25, 50]"
        [loading]="loading"
        [globalFilterFields]="[
        'id',
        'name'
        ]"
        >
        <ng-template pTemplate="header">
            <tr>
                <th>
                    <div class="flex justify-content-center align-items-center">
                        ID
                        <p-columnFilter type="text" field="id" display="menu" [showAddButton]="false" [showMatchModes]="false" [showOperator]="false"></p-columnFilter>
                    </div>
                </th>
                <th>
                    <div class="flex justify-content-center align-items-center">
                        Nombre
                        <p-columnFilter type="text" field="name" display="menu" [showAddButton]="false" [showMatchModes]="true" [showOperator]="false"></p-columnFilter>
                    </div>
                </th>
                <th>
                    <div class="flex justify-content-center align-items-center">
                        Icono
                    </div>
                </th>
                <th>
                    <div class="flex justify-content-center align-items-center">
                        Acciones
                    </div>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-specie>
            <tr>
                <td>
                    <div class="flex justify-content-center align-items-center" style="font-weight: bold;">
                        <p>{{specie.id}}</p>
                    </div>
                </td>
                <td>
                    <div class="flex justify-content-center align-items-center">
                        <p>{{specie.name}}</p>
                    </div>
                </td>
                <td>
                    <div class="flex justify-content-center align-items-center">
                        <img src="{{getIconUrl(specie.imageUrl)}}" alt="Image" height="70px">
                    </div>
                </td>
                <td>
                    <div class="flex justify-content-center align-items-center gap-1">
                        <p-button icon="pi pi-pencil" pTooltip="Editar"></p-button>
                    </div>
                </td>
            </tr>
        </ng-template>
        </p-table>
    </p-card>
</app-admin-dashboard>
