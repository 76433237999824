<app-admin-dashboard>
    <p-card>
        <ng-template pTemplate="header">
            <div class="flex justify-content-between flex-wrap align-items-center" style="padding: 15px;">
              <h2>Administración de Forwarders</h2>
            </div>
        </ng-template>
        <ng-template pTemplate="body">
            <p-table [value]="companies"
                [paginator]="true"
                [rows]="10"
                [showCurrentPageReport]="true"
                [rowsPerPageOptions]="[10, 25, 50]"
                [loading]="loading">
                <ng-template pTemplate="caption">
                    <div class="flex align-items-center justify-content-end">
                      <p-button label="Nuevo" icon="pi pi-plus" (onClick)="goToCreateForwarder()"></p-button>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th>
                            <div class="flex justify-content-center align-items-center">
                                ID
                            </div>
                        </th>
                        <th>
                            <div class="flex justify-content-center align-items-center">
                                Company Name
                            </div>
                        </th>
                        <th>
                            <div class="flex justify-content-center align-items-center">
                                Contact Name
                            </div>
                        </th>
                        <th>
                            <div class="flex justify-content-center align-items-center">
                                Contact LastName
                            </div>
                        </th>
                        <th>
                            <div class="flex justify-content-center align-items-center">
                                Contact Phone
                            </div>
                        </th>
                        <th>
                            <div class="flex justify-content-center align-items-center">
                                Contact Email
                            </div>
                        </th>
                        <th>
                            <div class="flex justify-content-center align-items-center">
                                Country Operations
                            </div>
                        </th>
                        <th>
                            <div class="flex justify-content-center align-items-center">
                                Forwarder Admin
                            </div>
                        </th>
                        <th>
                            <div class="flex justify-content-center align-items-center"></div>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-forwarder>
                    <tr>
                        <td>
                          <div class="flex justify-content-center align-items-center">{{forwarder.id}}</div>
                        </td>
                        <td>
                          <div class="flex justify-content-center align-items-center">{{forwarder.companyName}}</div>
                        </td>
                        <td>
                          <div class="flex justify-content-center align-items-center">{{forwarder?.contactName}}</div>
                        </td>
                        <td>
                          <div class="flex justify-content-center align-items-center">{{forwarder?.contactLastName}}</div>
                        </td>
                        <td>
                          <div class="flex justify-content-center align-items-center">{{forwarder?.contactPhone}}</div>
                        </td>
                        <td>
                          <div class="flex justify-content-center align-items-center">{{forwarder?.contactEmail}}</div>
                        </td>
                        <td>
                          <div class="flex justify-content-center align-items-center">
                              <img [src]="'../../../../assets/img/flags/' + forwarder?.country?.code + '.png'" style="width: 18px" alt=""/> 
                              {{forwarder?.country ? (forwarder?.country | countryDescription) : getAvailableLabel()}}
                            </div>
                        </td>
                        <td>
                          <div class="flex justify-content-center align-items-center">
                              {{forwarder?.profile ? (forwarder?.profile?.contactName + ' ' + forwarder?.profile?.contactLastName) : getAvailableLabel()}}
                            </div>
                        </td>
                        <td>
                            <div class="flex justify-content-center align-items-center">
                                <p-button styleClass="p-button-warning" label="Editar" (onClick)="goToEditForwarder(forwarder.id)"></p-button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </ng-template>
    </p-card>
</app-admin-dashboard>

<p-toast></p-toast>
<p-confirmDialog acceptLabel="Si" rejectLabel="No" rejectButtonStyleClass="p-button-outlined" [style]="{width: '50vw'}"></p-confirmDialog>