<app-user-dashboard [userMenuActiveItemIndex]="0" [activeItem]="'marketplace'">
  <p-breadcrumb class="max-w-full" [model]="breadcrumbMenuItems" [home]="breadcrumbHome"></p-breadcrumb>
  <p-card>
    <div class="flex justify-content-between align-items-center">
      <h2>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.PageTitle1' | translate}} , {{'UserPages.UserPurchaseOrders.VerifyPalletsPage.PageTitle2' | translate}} </h2>
      <p-button label="{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelButton' | translate }}" icon="pi pi-upload" (click)="showLoadExcelDialog()"/>
    </div>
    <div *ngIf="!success">
      <p-table
        [value]="pallets"
        dataKey="dataKey"
        responsiveLayout="stack"
        [breakpoint]="'600px'"
        styleClass="p-datatable-gridlines"
        [scrollable]="true"
        [resizableColumns]="true"
        columnResizeMode="expand"
        scrollHeight="600px">
        <ng-template pTemplate="header">
          <tr>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.FolioHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.SpeciesHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.VarietyHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.ProducerHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.SpeciesSizeHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.HarvestDateHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.QualityControlDateHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.PackagingHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.PalletTypeHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.TagHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.BagTypeHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.BoxTypeHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.NetWeightHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.ColdStorageHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.BoxePerPalletHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.MarksHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.GradeHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.ContainerNumberHeader' | translate}}</th>
            <th>ETD</th>
            <th>ETA</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadWeekHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.OriginCountryHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.DestinationCountryHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.TemptaleHeader' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.Cert1Header' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.Cert2Header' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.Cert3Header' | translate}}</th>
            <th>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadWeekHeader' | translate}}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-pallet let-editing="editing" let-ri="rowIndex">
          <tr>
            <td [pEditableColumn]="pallet.palletNumber" pEditableColumnField="palletNumber">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="text" [(ngModel)]="pallet.code" maxlength="20"/>
                </ng-template>
                <ng-template pTemplate="output">
                  {{pallet.code}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td [pEditableColumn]="pallet.excelImportSpecies" pEditableColumnField="excelImportSpecies">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="text" [(ngModel)]="pallet.excelImportSpecies" maxlength="100" required/>
                </ng-template>
                <ng-template pTemplate="output">
                  {{pallet.excelImportSpecies}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td [pEditableColumn]="pallet.excelImportVariety" pEditableColumnField="excelImportVariety">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="text" [(ngModel)]="pallet.excelImportVariety" maxlength="100" required/>
                </ng-template>
                <ng-template pTemplate="output">
                  {{pallet.excelImportVariety}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td [pEditableColumn]="pallet.excelImportProducer" pEditableColumnField="producer">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="text" [(ngModel)]="pallet.excelImportProducer" maxlength="200" required/>
                </ng-template>
                <ng-template pTemplate="output">
                  {{pallet.excelImportProducer}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td [pEditableColumn]="pallet.excelImportSpeciesSize" pEditableColumnField="speciesSize">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="text" [(ngModel)]="pallet.excelImportSpeciesSize" maxlength="30"/>
                </ng-template>
                <ng-template pTemplate="output">
                  {{pallet.excelImportSpeciesSize}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td [pEditableColumn]="pallet.excelImportHarvestDate" pEditableColumnField="excelImportHarvestDate">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="text" [(ngModel)]="pallet.excelImportHarvestDate" maxlength="50"/>
                </ng-template>
                <ng-template pTemplate="output">
                  {{pallet.excelImportHarvestDate}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td [pEditableColumn]="pallet.excelImportQualityControlDate" pEditableColumnField="excelImportQualityControlDate">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="text" [(ngModel)]="pallet.excelImportQualityControlDate" maxlength="50"/>
                </ng-template>
                <ng-template pTemplate="output">
                  {{pallet.excelImportQualityControlDate}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td [pEditableColumn]="pallet.excelImportPackaging" pEditableColumnField="excelImportPackaging">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="text" [(ngModel)]="pallet.excelImportPackaging" maxlength="50"/>
                </ng-template>
                <ng-template pTemplate="output">
                  {{pallet.excelImportPackaging}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td [pEditableColumn]="pallet.excelImportPalletType" pEditableColumnField="excelImportPalletType">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="text" [(ngModel)]="pallet.excelImportPalletType" maxlength="50"/>
                </ng-template>
                <ng-template pTemplate="output">
                  {{pallet.excelImportPalletType}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td [pEditableColumn]="pallet.excelImportTag" pEditableColumnField="excelImportTag">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="text" [(ngModel)]="pallet.excelImportTag" maxlength="50"/>
                </ng-template>
                <ng-template pTemplate="output">
                  {{pallet.excelImportTag}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td [pEditableColumn]="pallet.excelImportBagType" pEditableColumnField="excelImportBagType">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="text" [(ngModel)]="pallet.excelImportBagType" maxlength="50"/>
                </ng-template>
                <ng-template pTemplate="output">
                  {{pallet.excelImportBagType}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td [pEditableColumn]="pallet.excelImportBoxSize" pEditableColumnField="excelImportBoxSize">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="text" [(ngModel)]="pallet.excelImportBoxSize" maxlength="50"/>
                </ng-template>
                <ng-template pTemplate="output">
                  {{pallet.excelImportBoxSize}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td [pEditableColumn]="pallet.excelImportNetWeight" pEditableColumnField="excelImportNetWeight">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="text" [(ngModel)]="pallet.excelImportNetWeight" maxlength="50"/>
                </ng-template>
                <ng-template pTemplate="output">
                  {{pallet.excelImportNetWeight}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td [pEditableColumn]="pallet.excelImportFridgeStorage" pEditableColumnField="excelImportFridgeStorage">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="text" [(ngModel)]="pallet.excelImportFridgeStorage" maxlength="50"/>
                </ng-template>
                <ng-template pTemplate="output">
                  {{pallet.excelImportFridgeStorage}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td [pEditableColumn]="pallet.excelImportBoxesPerPallet" pEditableColumnField="excelImportBoxesPerPallet">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="text" [(ngModel)]="pallet.excelImportBoxesPerPallet" maxlength="50"/>
                </ng-template>
                <ng-template pTemplate="output">
                  {{pallet.excelImportBoxesPerPallet}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td [pEditableColumn]="pallet.excelImportMark" pEditableColumnField="excelImportMark">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="text" [(ngModel)]="pallet.excelImportMark" maxlength="50"/>
                </ng-template>
                <ng-template pTemplate="output">
                  {{pallet.excelImportMark}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td [pEditableColumn]="pallet.excelImportGrade" pEditableColumnField="excelImportGrade">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="text" [(ngModel)]="pallet.excelImportGrade" maxlength="50"/>
                </ng-template>
                <ng-template pTemplate="output">
                  {{pallet.excelImportGrade}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td [pEditableColumn]="pallet.excelImportContainerNumber" pEditableColumnField="excelImportContainerNumber">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="text" [(ngModel)]="pallet.excelImportContainerNumber" maxlength="50"/>
                </ng-template>
                <ng-template pTemplate="output">
                  {{pallet.excelImportContainerNumber}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td [pEditableColumn]="pallet.excelImportOriginCountry" pEditableColumnField="excelImportOriginCountry">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="text" [(ngModel)]="pallet.excelImportOriginCountry" maxlength="50"/>
                </ng-template>
                <ng-template pTemplate="output">
                  {{pallet.excelImportOriginCountry}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td></td>
            <td [pEditableColumn]="pallet.excelImportTemptale" pEditableColumnField="excelImportTemptale">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="text" [(ngModel)]="pallet.excelImportTemptale" maxlength="50"/>
                </ng-template>
                <ng-template pTemplate="output">
                  {{pallet.excelImportTemptale}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td [pEditableColumn]="pallet.excelImportCertificate1" pEditableColumnField="excelImportCertificate1">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="text" [(ngModel)]="pallet.excelImportCertificate1" maxlength="50"/>
                </ng-template>
                <ng-template pTemplate="output">
                  {{pallet.excelImportCertificate1}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td [pEditableColumn]="pallet.excelImportCertificate2" pEditableColumnField="excelImportCertificate1">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="text" [(ngModel)]="pallet.excelImportCertificate2" maxlength="50"/>
                </ng-template>
                <ng-template pTemplate="output">
                  {{pallet.excelImportCertificate2}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td [pEditableColumn]="pallet.excelImportCertificate3" pEditableColumnField="excelImportCertificate3">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="text" [(ngModel)]="pallet.excelImportCertificate3" maxlength="50"/>
                </ng-template>
                <ng-template pTemplate="output">
                  {{pallet.excelImportCertificate3}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td [pEditableColumn]="pallet.excelImportLoadWeek" pEditableColumnField="excelImportLoadWeek">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="text" [(ngModel)]="pallet.excelImportLoadWeek" maxlength="50"/>
                </ng-template>
                <ng-template pTemplate="output">
                  {{pallet.excelImportLoadWeek}}
                </ng-template>
              </p-cellEditor>
            </td>
          </tr>
        </ng-template>
      </p-table>

      <div class="grid" style="margin-top: 40px;">
        <div class="col-6"></div>
        <div class="col-6">
          <div class="grid p-fluid">
            <div class="col-6">
              <p-button label="{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.BackButton' | translate}}" (onClick)="onCancel()" styleClass="p-button-outlined" [loading]="loading"></p-button>
            </div>
            <div class="col-6">
              <p-button label="{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.SaveButton' | translate}}" (onClick)="save()" [loading]="loading"></p-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="success">
      <div class="flex align-items-center justify-content-center flex-column">
        <i class="pi pi-check-circle" style="font-size: 2.5rem"></i>
        <h1>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.SuccessMessageText' | translate}}</h1>
        <p-button label="{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.AcceptButton' | translate}}" (click)="onCancel()"></p-button>
      </div>
    </div>
  </p-card>
</app-user-dashboard>

<p-toast></p-toast>

<p-dialog header="{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.DialogTitle' | translate}}" [(visible)]="visibleLoadExcelDialog" [modal]="true" [style]="{width: '50vw'}">
  <form [formGroup]="loadExcelFormGroup">
    <div class="p-fluid formgrid grid">
      <div class="field col-12" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.LoadFileFieldLabel' | translate}}</label>
        <p-fileUpload
          #excelUploader
          mode="basic"
          [multiple]="false"
          [auto]="true"
          chooseLabel="{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.LoadFileFieldPlaceholder' | translate}}"
          name="demo[]"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          [maxFileSize]="1000000"
          (onSelect)="onSelectExcelFile(excelUploader)">
        </p-fileUpload>
        <p *ngIf="excelFile">{{excelFile.name}}</p>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.SheetFiedlLabel' | translate}}</label>
        <p-dropdown [options]="excelSheets" [(ngModel)]="selectedSheet" [ngModelOptions]="{standalone: true}" [disabled]="!excelFile"></p-dropdown>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.StartRowFieldLabel' | translate}} *</label>
        <p-inputNumber formControlName="startRow" [min]="1" [maxlength]="10" [disabled]="!excelFile" placeholder=""/>
        <small *ngIf="loadExcelFormGroup.controls['startRow'].invalid" class="p-error">{{getErrorMessage(loadExcelFormGroup, 'startRow')}}</small>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;"></div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.FolioColumnFieldLabel' | translate}} *</label>
        <input type="text" formControlName="folioColumn" pInputText maxlength="1" placeholder=""/>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.SpeciesColumnFieldLabel' | translate}} *</label>
        <input type="text" formControlName="speciesColumn" pInputText maxlength="1" placeholder=""/>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.VarietyColumnFieldLabel' | translate}} *</label>
        <input type="text" formControlName="varietyColumn" pInputText maxlength="1" placeholder=""/>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.ProducerColumnFieldLabel' | translate}} *</label>
        <input type="text" formControlName="producerColumn" pInputText maxlength="1" placeholder=""/>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.SpeciesSizeColumnFieldLabel' | translate}}</label>
        <input type="text" formControlName="speciesSizeColumn" pInputText maxlength="1" placeholder=""/>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.HarvestDateColumnFielLabel' | translate}}</label>
        <input type="text" formControlName="harvestDateColumn" pInputText maxlength="1" placeholder=""/>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.QualityControlDateColumnFielLabel' | translate}}</label>
        <input type="text" formControlName="qualityControlDateColumn" pInputText maxlength="1" placeholder=""/>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.PackagingColumnFielLabel' | translate}}</label>
        <input type="text" formControlName="packagingColumn" pInputText maxlength="1" placeholder=""/>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.PalletTypeColumnFielLabel' | translate}}</label>
        <input type="text" formControlName="palletTypeColumn" pInputText maxlength="1" placeholder=""/>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.TagColumnFielLabel' | translate}}</label>
        <input type="text" formControlName="tagColumn" pInputText maxlength="1" placeholder=""/>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.BagTypeColumnFielLabel' | translate}}</label>
        <input type="text" formControlName="bagTypeColumn" pInputText maxlength="1" placeholder=""/>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.BoxSizeColumnFielLabel' | translate}}</label>
        <input type="text" formControlName="boxSizeColumn" pInputText maxlength="1" placeholder=""/>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.NetWeightColumnFielLabel' | translate}}</label>
        <input type="text" formControlName="netWeightColumn" pInputText maxlength="1" placeholder=""/>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.BoxesPerPalletColumnFielLabel' | translate}}</label>
        <input type="text" formControlName="boxesPerPalletColumn" pInputText maxlength="1" placeholder=""/>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.MarkColumnFielLabel' | translate}}</label>
        <input type="text" formControlName="markColumn" pInputText maxlength="1" placeholder=""/>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.ContainerNumberColumnFielLabel' | translate}}</label>
        <input type="text" formControlName="containerNumberColumn" pInputText maxlength="1" placeholder=""/>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.GradeColumnFielLabel' | translate}}</label>
        <input type="text" formControlName="gradeColumn" pInputText maxlength="1" placeholder=""/>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.TemptaleColumnFielLabel' | translate}}</label>
        <input type="text" formControlName="temptaleColumn" pInputText maxlength="1" placeholder=""/>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.OriginCountryColumnFielLabel' | translate}}</label>
        <input type="text" formControlName="originCountryColumn" pInputText maxlength="1" placeholder=""/>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.Certificate1ColumnFielLabel' | translate}}</label>
        <input type="text" formControlName="certificate1Column" pInputText maxlength="1" placeholder=""/>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.Certificate2ColumnFielLabel' | translate}}</label>
        <input type="text" formControlName="certificate2Column" pInputText maxlength="1" placeholder=""/>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.Certificate3ColumnFielLabel' | translate}}</label>
        <input type="text" formControlName="certificate3Column" pInputText maxlength="1" placeholder=""/>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;">
        <label>{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.LoadWeekColumnFielLabel' | translate}}</label>
        <input type="text" formControlName="loadWeekColumn" pInputText maxlength="1" placeholder=""/>
      </div>
      <div class="field col-12 xl:col-4 lg:col-4" style="margin-bottom: 40px;"></div>
      <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;"></div>
      <div class="field col-12 xl:col-6 lg:col-6" style="margin-bottom: 40px;">
        <p-button label="{{'UserPages.UserPurchaseOrders.VerifyPalletsPage.LoadExcelModal.LoadButton' | translate}}" (click)="onLoadExcel()" [disabled]="!excelFile"></p-button>
      </div>
    </div>
  </form>
</p-dialog>
