import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { ProfileGateway } from 'src/app/application/gateway/ProfileGateway';
import { SecurityGateway } from 'src/app/application/gateway/SecurityGateway';
import { Profile } from 'src/app/domain/models/Profile';
import { BasicUser } from 'src/app/domain/models/User';
import { Routes } from 'src/app/infrastructure/routes';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css'],
  providers: [MessageService]
})
export class AdminDashboardComponent implements OnInit {
  displaySidebar = false

  items: MenuItem[] = []

  sideMenuItems: MenuItem[] = [
    {
      label: 'Salir',
      icon: 'pi pi-fw pi-sign-out',
      command: () => { this.logout() }
    }
  ]

  profile: BasicUser | undefined

  constructor(private _router: Router,
    private _messageService: MessageService,
    private _authService: AuthGateway,
    private _profileService: ProfileGateway,
    private _securityService: SecurityGateway) {
      this.items = [
        {
          label: 'Gestionar órdenes',
          icon: 'pi pi-pw pi-shopping-cart',
          routerLink: ['/' + Routes.adminManageOrderPage]
        },
        {
          label: 'Gestionar ofertas de exportación',
          icon: 'pi pi-pw pi-apple',
          routerLink: ['/' + Routes.adminExportOfferPage]
        },
        {
          label: 'Gestionar revisiones QC',
          icon: 'pi pi-pw pi-verified',
          routerLink: ['/' + Routes.adminManageQCReviews]
        },
        {
          label: 'Master Load',
          icon: 'pi pi-pw pi-table',
          routerLink: ['/' + Routes.adminMasterLoadPage]
        },
        /**{
          label: 'Forwarder',
          icon: 'pi pi-pw pi-table',
          routerLink: ['/' + Routes.forwarderCreateOrderPage]
        },**/
        {
          label: 'Notificaciones',
          icon: 'pi pi-fw pi-bell',
          routerLink: ['/' + Routes.adminNotifications]
        },
        {
          label: 'Configuración',
          icon: 'pi pi-pw pi-cog',
          items: [
            {
              label: 'Usuarios',
              routerLink: ['/' + Routes.adminUsers]
            },
            {
              label: 'Especies',
              routerLink: ['/' + Routes.adminSpecies]
            },
            {
              label: 'Códigos de invitación',
              routerLink: ['/' + Routes.adminInvitationCodes]
            },
            {
              label: 'Forwarders',
              routerLink: ['/' + Routes.adminForwarder]
            },
            {
              label: 'Custom Agents',
              routerLink: ['/' + Routes.adminCustomAgent]
            }
          ]
        },
        {
          label: 'Salir',
          icon: 'pi pi-fw pi-sign-out',
          command: () => { this.logout() }
        }
      ]
  }

  ngOnInit(): void {
    this.profile = this._authService.getUser()
  }

  get userCompleteName(): string {
    if(this.profile) {
      return this.profile.contactName
    }
    return ''
  }

  logout(): void {
    this._securityService.doLogout().subscribe({
      next: (v) => {
        this._authService.logout()
        this._router.navigate([Routes.login])
      },
      error: (e) => {
        console.log(e)
        this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudo cerrar sesión correctamente.' });
      },
      complete: () => {
        console.info('Logout successfully')
      }
    })
  }
}
