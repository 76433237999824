import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ImportOrderDetail } from "src/app/domain/models/ImportOrder";
import { UpdateControlCompanyRequest } from "src/app/domain/models/QCCompany";

@Injectable({providedIn: 'root'})
export abstract class ImportOrderDetailGateway {
  abstract getAllPendingForQCReviews(username: string): Observable<ImportOrderDetail[]>
  abstract getAllReviewedOriginQCReviews(username: string): Observable<ImportOrderDetail[]>
  abstract getInstructiveDocument(detailId: string): Observable<any>
  abstract getInstructiveDocumentPdf(detailId: string): Observable<any>
  abstract updateQcCompany(updateQcCompany: UpdateControlCompanyRequest): Observable<any>
  abstract getQcPayment(detailId: string):Observable<ImportOrderDetail>
  abstract removeQcPayment(detailId: string):Observable<any>

}
