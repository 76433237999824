import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Species, SpeciesCreateRequest, SpeciesSize, SpeciesVarieties } from "src/app/domain/models/Species";

@Injectable({providedIn: 'root'})
export abstract class SpeciesGateway {
  abstract getAll(): Observable<Species[]>
  abstract getAllByUsername(username: string): Observable<Species[]>
  abstract getAllWithVarieties(): Observable<SpeciesVarieties[]>
  abstract getSpeciesSizes(speciesId: number): Observable<SpeciesSize[]>
  abstract create(speciesCreateRequest: SpeciesCreateRequest): Observable<Species>
  abstract getAllByProductType(productType: any): Observable<Species[]>
}
