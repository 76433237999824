<div style="margin-top: 20px;">
  <p-card>
    <div class="grid" style="font-size: small;">
      <div class="col-12 md:col-4"><h3>{{'UserPages.UserPurchaseOrders.PaymentsPage.LoadingDataText' | translate}}</h3></div>
      <div class="col-12 md:col-4"></div>
      <div class="col-12 md:col-4"><h3>{{'UserPages.UserPurchaseOrders.PaymentsPage.ShippingMethodTitleText' | translate}}</h3></div>
      <div class="col-12 md:col-4" style="border-right: 2px solid #bbb;">
        <div class="grid">
          <div class="col-12 md:col-6 wordwrap" style="font-weight: bolder;">Transport type:</div>
          <div class="col-12 md:col-6 wordwrap">{{delivery?.transportType ? getTransportType(delivery?.transportType ?? 0) : DefaultBookingText }}</div>

          <div class="col-12 md:col-6 wordwrap" style="font-weight: bolder;">Place of receipt:</div>
          <div class="col-12 md:col-6 wordwrap">{{delivery?.placeReceipt ? delivery?.placeReceipt : DefaultBookingText }}</div>
          <div class="col-12 md:col-6 wordwrap" style="font-weight: bolder;">Port of loading:</div>
          <div class="col-12 md:col-6 wordwrap">{{delivery?.departurePort ? delivery?.departurePort?.name : DefaultBookingText }}</div>
          <div class="col-12 md:col-6 wordwrap" style="font-weight: bolder;">Port of discharge:</div>
          <div class="col-12 md:col-6 wordwrap">{{delivery?.destinationPort ? delivery?.destinationPort?.name : DefaultBookingText }}</div>

          <div class="col-12 md:col-6 wordwrap" style="font-weight: bolder;">Airport of loading:</div>
          <div class="col-12 md:col-6 wordwrap">{{delivery?.departureAirport ? delivery?.departureAirport?.name : DefaultBookingText }}</div>
          <div class="col-12 md:col-6 wordwrap" style="font-weight: bolder;">Airport of discharge:</div>
          <div class="col-12 md:col-6 wordwrap">{{delivery?.destinationAirport ? delivery?.destinationAirport?.name : DefaultBookingText }}</div>

          <div class="col-12 md:col-6 wordwrap" style="font-weight: bolder;">Week:</div>
          <div class="col-12 md:col-6 wordwrap">{{delivery?.week ? delivery?.week : DefaultBookingText }}</div>
          <div class="col-12 md:col-6 wordwrap" style="font-weight: bolder;">Place of delivery:</div>
          <div class="col-12 md:col-6 wordwrap">{{delivery?.placeDelivery ? delivery?.placeDelivery : DefaultBookingText }}</div>
          <div class="col-12 md:col-6 wordwrap" style="font-weight: bolder;">Vessel name/Flight number:</div>
          <div class="col-12 md:col-6 wordwrap">{{delivery?.ship ? delivery?.ship : DefaultBookingText }}</div>
          <div class="col-12 md:col-6 wordwrap" style="font-weight: bolder;">Voyage:</div>
          <div class="col-12 md:col-6 wordwrap">{{delivery?.voyageNumber ? delivery?.voyageNumber : DefaultBookingText }}</div>
          <div class="col-12 md:col-6 wordwrap" style="font-weight: bolder;">Type of movement:</div>
          <div class="col-12 md:col-6 wordwrap">{{delivery?.typeMovement ? delivery?.typeMovement : DefaultBookingText }}</div>
          <div class="col-12 md:col-6 wordwrap" style="font-weight: bolder;">BL N°:</div>
          <div class="col-12 md:col-6 wordwrap">{{delivery?.bl ? delivery?.bl : DefaultLoadingInfoText }}</div>
          <div class="col-12 md:col-6 wordwrap" style="font-weight: bolder;">MBL N°:</div>
          <div class="col-12 md:col-6 wordwrap">{{delivery?.mbl ? delivery?.mbl : DefaultLoadingInfoText }}</div>
        </div>
      </div>
      <div class="col-12 md:col-4" style="border-right: 2px solid #bbb;">
        <div class="grid">
          <div class="col-12 md:col-6 wordwrap" style="font-weight: bolder;">Booking N°:</div>
          <div class="col-12 md:col-6 wordwrap">{{delivery?.booking ? delivery?.booking : DefaultLoadingInfoText }}</div>
          <div class="col-12 md:col-6 wordwrap" style="font-weight: bolder;">Container N°:</div>
          <div class="col-12 md:col-6 wordwrap">{{delivery?.containerNumber ? delivery?.containerNumber : DefaultLoadingInfoText }}</div>
          <div class="col-12 md:col-6 wordwrap" style="font-weight: bolder;">Loading data instructions:</div>
          <div class="col-12 md:col-6 wordwrap">{{delivery?.deliveryInstructions ? delivery?.deliveryInstructions : DefaultLoadingInfoText }}</div>
          <div class="col-12 md:col-6 wordwrap" style="font-weight: bolder;">Freight payable at:</div>
          <div class="col-12 md:col-6 wordwrap">{{delivery?.freightPayableAt ? delivery?.freightPayableAt : DefaultLoadingInfoText }}</div>
          <div class="col-12 md:col-6 wordwrap" style="font-weight: bolder;">Shipped on board:</div>
          <div class="col-12 md:col-6 wordwrap">{{delivery?.shippedOnBoard ? (delivery?.shippedOnBoard | date: 'dd-MM-yyyy') : DefaultLoadingInfoText }}</div>
          <div class="col-12 md:col-6 wordwrap" style="font-weight: bolder;">Place of issue:</div>
          <div class="col-12 md:col-6 wordwrap">{{delivery?.placeIssue ? delivery?.placeIssue : DefaultLoadingInfoText }}</div>
          <div class="col-12 md:col-6 wordwrap" style="font-weight: bolder;">Date of issue:</div>
          <div class="col-12 md:col-6 wordwrap">{{delivery?.dateIssue ? (delivery?.dateIssue | date: 'dd-MM-yyyy') : DefaultLoadingInfoText }}</div>
          <div class="col-12 md:col-6 wordwrap" style="font-weight: bolder;">ETA:</div>
          <div class="col-12 md:col-6 wordwrap">{{delivery?.eta ? (delivery?.eta | date: 'dd-MM-yyyy') : DefaultLoadingInfoText }}</div>
          <div class="col-12 md:col-6 wordwrap" style="font-weight: bolder;">ETD:</div>
          <div class="col-12 md:col-6 wordwrap">{{delivery?.etd ? (delivery?.etd | date: 'dd-MM-yyyy') : DefaultLoadingInfoText }}</div>
          <div class="col-6" style="font-weight: bolder;">Temperature:</div>
          <div class="col-6">{{delivery?.containerTemperature ? delivery?.containerTemperature + ' °C': DefaultLoadingInfoText }} </div>
          <div class="col-6" style="font-weight: bolder;">CBM:</div>
          <div class="col-6">{{delivery?.containerCbm ? delivery?.containerCbm + ' %': DefaultLoadingInfoText }} </div>
          <div class="col-6" style="font-weight: bolder;">Seal:</div>
          <div class="col-6">{{delivery?.seal ? delivery?.seal: DefaultLoadingInfoText }} </div>
        </div>
      </div>
      <div class="col-12 md:col-4">
        <div class="grid">
          <div class="col-12 md:col-6 wordwrap" style="font-weight: bolder;">Cutoff date:</div>
          <div class="col-12 md:col-6 wordwrap">{{delivery?.cutoffDate ? (delivery?.cutoffDate | date: 'dd-MM-yyyy') : DefaultLoadingInfoText }}</div>
          <div class="col-12 md:col-6 wordwrap" style="font-weight: bolder;">Stacking start:</div>
          <div class="col-12 md:col-6 wordwrap">{{delivery?.stackingStart ? (delivery?.stackingStart | date: 'dd-MM-yyyy') : DefaultLoadingInfoText }}</div>
          <div class="col-12 md:col-6 wordwrap" style="font-weight: bolder;">Stacking end:</div>
          <div class="col-12 md:col-6 wordwrap">{{delivery?.stackingEnd ? (delivery?.stackingEnd | date: 'dd-MM-yyyy') : DefaultLoadingInfoText }}</div>
          <div class="col-12 md:col-6 wordwrap" style="font-weight: bolder;">Carrier name:</div>
          <div class="col-12 md:col-6 wordwrap">{{delivery?.carrierName ? delivery?.carrierName : DefaultLoadingInfoText }}</div>
          <div class="col-12 md:col-6 wordwrap" style="font-weight: bolder;">Departure license car:</div>
          <div class="col-12 md:col-6 wordwrap">{{delivery?.departurePatentCar ? delivery?.departurePatentCar : DefaultLoadingInfoText }}</div>
          <div class="col-12 md:col-6 wordwrap" style="font-weight: bolder;">Departure license truck:</div>
          <div class="col-12 md:col-6 wordwrap">{{delivery?.departurePatentTruck ? delivery?.departurePatentTruck : DefaultLoadingInfoText }}</div>
          <div class="col-12 md:col-6 wordwrap" style="font-weight: bolder;">DUS:</div>
          <div class="col-12 md:col-6 wordwrap">{{delivery?.dus ? delivery?.dus : DefaultLoadingInfoText }}</div>
          <div class="col-12 md:col-6 wordwrap" style="font-weight: bolder;">SPS:</div>
          <div class="col-12 md:col-6 wordwrap">{{delivery?.sps ? delivery?.sps : DefaultLoadingInfoText }}</div>
          <div class="col-12 md:col-6 wordwrap" style="font-weight: bolder;">OT:</div>
          <div class="col-12 md:col-6 wordwrap">{{delivery?.ot ? delivery?.ot : DefaultLoadingInfoText }}</div>
          <div class="col-12 md:col-6 wordwrap" style="font-weight: bolder;">Ref 1:</div>
          <div class="col-12 md:col-6 wordwrap">{{delivery?.ref1 ? delivery?.ref1 : DefaultLoadingInfoText }}</div>
          <div class="col-12 md:col-6 wordwrap" style="font-weight: bolder;">Ref 2:</div>
          <div class="col-12 md:col-6 wordwrap">{{delivery?.ref2 ? delivery?.ref2 : DefaultLoadingInfoText }}</div>
          <div class="col-12 md:col-6 wordwrap" style="font-weight: bolder;">CTR:</div>
          <div class="col-12 md:col-6 wordwrap">{{delivery?.ctr ? delivery?.ctr : DefaultLoadingInfoText }}</div>
        </div>
      </div>
    </div>
  </p-card>
</div>


