<app-user-dashboard [userMenuActiveItemIndex]="0">
  <p-card [style]="{'min-height':'200px'}">
    <ng-template pTemplate="header">
      <div style="padding: 15px;">
        <h2>{{'UserPages.UserExportOfferSugesstionPage.OfferSuggestions.PageTitle' | translate}}</h2>
        <p>{{'UserPages.UserExportOfferSugesstionPage.OfferSuggestions.PageDescriptionText' | translate}}</p>
      </div>
    </ng-template>
    <ng-template pTemplate="body">
      <p-table  [value]="offerSuggestions"
        [(selection)]="selected"
        dataKey="exportOffer.id"
        [paginator]="true"
        [rows]="10"
        [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[10, 25, 50]"
        [loading]="loading"
        responsiveLayout="stack"
        [breakpoint]="'600px'">
        <ng-template pTemplate="caption">
          <div class="flex align-items-center justify-content-start gap-4">
            <p-button label="{{'UserPages.UserExportOfferSugesstionPage.OfferSuggestions.SelectOfferButton' | translate}}" (onClick)="acceptOfferSuggestion()" [loading]="loading"></p-button>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 4rem"></th>
            <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserExportOfferSugesstionPage.OfferSuggestions.OffersTable.CountryHeader' | translate}}</div></th>
            <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserExportOfferSugesstionPage.OfferSuggestions.OffersTable.ProductHeader' | translate}}</div></th>
            <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserExportOfferSugesstionPage.OfferSuggestions.OffersTable.SpeciesSizeHeader' | translate}}</div></th>
            <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserExportOfferSugesstionPage.OfferSuggestions.OffersTable.IsQualityControlHeader' | translate}}</div></th>
            <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserExportOfferSugesstionPage.OfferSuggestions.OffersTable.HarvestDateHeader' | translate}}</div></th>
            <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserExportOfferSugesstionPage.OfferSuggestions.OffersTable.LocationHeader' | translate}}</div></th>
            <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserExportOfferSugesstionPage.OfferSuggestions.OffersTable.CasePerPalletsHeader' | translate}}</div></th>
            <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserExportOfferSugesstionPage.OfferSuggestions.OffersTable.ListPriceHeader' | translate}}</div></th>
            <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserExportOfferSugesstionPage.OfferSuggestions.OffersTable.AvailablePalletsHeader' | translate}}</div></th>
            <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserExportOfferSugesstionPage.OfferSuggestions.OffersTable.PalletRequiredHeader' | translate}}</div></th>
            <th><div class="flex justify-content-center align-items-center">{{'UserPages.UserExportOfferSugesstionPage.OfferSuggestions.OffersTable.TotalHeader' | translate}}</div></th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-offerSuggestion>
          <tr>
            <td>
              <p-tableRadioButton [value]="offerSuggestion"></p-tableRadioButton>
            </td>
            <td><div class="flex justify-content-center align-items-center">{{offerSuggestion.exportOffer?.fridgeStorage?.contactCountry | countryDescription}}</div></td>
            <td><div class="flex justify-content-center align-items-center">{{offerSuggestion.exportOffer?.variety?.species?.name}} / {{offerSuggestion.exportOffer?.variety?.name}}</div></td>
            <td><div class="flex justify-content-center align-items-center">{{offerSuggestion.exportOffer?.speciesSize}}</div></td>
            <td><div class="flex justify-content-center align-items-center">{{offerSuggestion.exportOffer?.isQualityControl ? 'Si' : 'No'}}</div></td>
            <td><div class="flex justify-content-center align-items-center">{{offerSuggestion.exportOffer?.harvestDate | date: 'dd-MM-yyyy'}}</div></td>
            <td><div class="flex justify-content-center align-items-center">{{offerSuggestion.exportOffer?.fridgeStorage?.contactCity}}</div></td>
            <td><div class="flex justify-content-center align-items-center">{{offerSuggestion.exportOffer?.caseQuantityPerPallet}}</div></td>
            <td><div class="flex justify-content-center align-items-center">{{offerSuggestion.exportOffer?.listPrice | currency: 'USD':'US$'}}</div></td>
            <td><div class="flex justify-content-center align-items-center">{{offerSuggestion.exportOffer?.availablePallets}}</div></td>
            <td><div class="flex justify-content-center align-items-center">{{suggestionInfo?.suggestion?.palletsQuantity}}</div></td>
            <td><div class="flex justify-content-center align-items-center">{{getTotalPrice(offerSuggestion.exportOffer) | currency: 'USD':'US$'}}</div></td>
            <td>
              <p-button icon="pi pi-search" pTooltip="{{'UserPages.UserExportOfferSugesstionPage.OfferSuggestions.OffersTable.SeeOfferButton' | translate}}" (onClick)="showExportOfferDetailDialog(offerSuggestion)"></p-button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </ng-template>
  </p-card>
</app-user-dashboard>

<p-toast></p-toast>

<p-dialog [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleOfferDetailDialog" [style]="{width: '80%'}">
  <app-export-offer-detail [offer]="selectedExportOffer" [productCertificateTypes]="selectedProductCertificateTypes" [packagingTypes]="selectedPackagingTypes"></app-export-offer-detail>
  <ng-template pTemplate="footer">
    <p-button label="{{'UserPages.UserExportOfferSugesstionPage.OfferSuggestions.CloseButton' | translate}}" (click)="visibleOfferDetailDialog = false"></p-button>
  </ng-template>
</p-dialog>
