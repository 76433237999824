import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, ConfirmEventType, MenuItem, MessageService } from 'primeng/api';
import { ConsigneeGateway } from 'src/app/application/gateway/ConsigneeGateway';
import { ImportOrderGateway } from 'src/app/application/gateway/ImportOrderGateway';
import { NotifierGateway } from 'src/app/application/gateway/NotifierGateway';
import { AuthApiService } from 'src/app/application/service/AuthService';
import { CountryService } from 'src/app/application/service/CountryService';
import { Consignee } from 'src/app/domain/models/Consignee';
import { Country } from 'src/app/domain/models/Country';
import { ImportOrderConfirmPurchaseRequest } from 'src/app/domain/models/Delivery';
import { ImportOrderInformation } from 'src/app/domain/models/ImportOrder';
import { Notifier } from 'src/app/domain/models/Notifier';
import { BasicUser } from 'src/app/domain/models/User';
import { Routes } from 'src/app/infrastructure/routes';

@Component({
  selector: 'app-confirm-purchase-page',
  templateUrl: './confirm-purchase-page.component.html',
  styleUrls: ['./confirm-purchase-page.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class ConfirmPurchasePageComponent implements OnInit {

  breadcrumbMenuItems: MenuItem[] = [{label: 'Confirmar compra'}]
  breadcrumbHome: MenuItem = {label: 'Ordenes de compra', routerLink: ['/' + Routes.userPurchaseOrders, 'pending']}

  orderId: any | undefined
  order: ImportOrderInformation | undefined
  notifiers: Notifier[] = []
  consignees: Consignee[] = []
  countries: Country[] = []
  selectedConsignee: Consignee | undefined
  selectedNotifier: Notifier | undefined

  submit: boolean = false
  loading: boolean = false
  success: boolean = false

  profile: BasicUser | undefined

  formGroup: FormGroup = this._formBuilder.group({
    consigneeCompanyName: ['', Validators.required],
    consigneeVat: ['', Validators.required],
    consigneeContactCountryId: ['', Validators.required],
    consigneeContactCity: ['', Validators.required],
    consigneeContactName: ['', Validators.required],
    consigneeContactAddress: ['', Validators.required],
    consigneeContactPhone: [''],
    consigneeContactEmail: ['', [Validators.required, Validators.email]],
    notifierCompanyName: ['', Validators.required],
    notifierVat: ['', Validators.required],
    notifierContactCountryId: ['', Validators.required],
    notifierContactCity: ['', Validators.required],
    notifierContactName: ['', Validators.required],
    notifierContactAddress: ['', Validators.required],
    notifierContactPhone: [''],
    notifierContactEmail: ['', [Validators.required, Validators.email]],
  })

  constructor(private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _messageService: MessageService,
    private _confirmationService: ConfirmationService,
    private _authService: AuthApiService,
    private _notifierService: NotifierGateway,
    private _consigneeService: ConsigneeGateway,
    private _countryService: CountryService,
    private _importOrderService: ImportOrderGateway,
    public translate: TranslateService
    ) {

      this._activatedRoute.params.subscribe(params => {
        this.orderId = params['orderId']
      })
  }

  ngOnInit(): void {
    this.profile = this._authService.getUser()
    let breadcrumbMenuItemLabel1 = 'Import order detail'
    let breadcrumbMenuItemLabel2 = 'Verify pallets'
    let breadcrumbHomeItemLabel = 'My import orders'

    if(this.profile) {
      switch(this.profile.lang) {
        case 'en':
          breadcrumbMenuItemLabel1 = 'Import order detail'
          breadcrumbMenuItemLabel2 = 'Confirm purchase'
          breadcrumbHomeItemLabel = 'My import orders'
          break
        case 'es':
          breadcrumbMenuItemLabel1 = 'Detalle de orden de importación'
          breadcrumbMenuItemLabel2 = 'Confirmar compra'
          breadcrumbHomeItemLabel = 'Ordenes de importación'
          break
      }
    }

    this.breadcrumbMenuItems = [
      {label: breadcrumbMenuItemLabel1, command: () => { this.goToImportOrderPage() }},
      {label: breadcrumbMenuItemLabel2, disabled: true}
    ]
    this.breadcrumbHome = {label: breadcrumbHomeItemLabel, routerLink: ['/' + Routes.userImportOrdersApproved]}

    this.loadImportOrder()
  }

  loadImportOrder() {
    if(this.orderId) {
      this._importOrderService.getById(this.orderId).subscribe({
        next: (v) => {
          this.order = v
          if(this.order.importOrder.status !== 2) {
            this.success = true
          }
          else {
            this.loadConsignees()
            this.loadNotifiers()
            this.loadCountries()
          }
        },
        error: (e) => {
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ImportOrder.SingleRetrieveError') });
          console.log(e)
        },
        complete: () =>  {
          console.info('Import Order query successfully')
        }
      })
    }

  }

  loadNotifiers() {
    this._notifierService.getAllByUsername(this._authService.getUsername()).subscribe({
      next: (v) => {
        this.notifiers = v
      },
      error: (e) => {
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Notify.ManyRetrieveError') });
        console.log(e)
      },
      complete: () =>  {
        console.info('Notifies query successfully')
      }
    })
  }

  loadConsignees() {
    this._consigneeService.getAllByUsername(this._authService.getUsername()).subscribe({
      next: (v) => {
        this.consignees = v
      },
      error: (e) => {
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Consignee.ManyRetrieveError') });
        console.log(e)
      },
      complete: () =>  {
        console.info('Consignees query successfully')
      }
    })
  }

  loadCountries() {
    this._countryService.getAll().subscribe({
      next: (v) => {
        this.countries = v
      },
      error: (e) => {
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Country.ManyRetrieveError') });
        console.log(e)
      },
      complete: () =>  {
        console.info('Countries query successfully')
      }
    })
  }

  loadConsigneeForm() {
    if(this.selectedConsignee) {
      this.formGroup.controls['consigneeCompanyName'].setValue(this.selectedConsignee.companyName)
      this.formGroup.controls['consigneeVat'].setValue(this.selectedConsignee.vat)
      this.formGroup.controls['consigneeContactCountryId'].setValue(this.selectedConsignee.contactCountryId)
      this.formGroup.controls['consigneeContactCity'].setValue(this.selectedConsignee.contactCity)
      this.formGroup.controls['consigneeContactName'].setValue(this.selectedConsignee.contactName)
      this.formGroup.controls['consigneeContactAddress'].setValue(this.selectedConsignee.contactAddress)
      this.formGroup.controls['consigneeContactPhone'].setValue(this.selectedConsignee.contactPhone)
      this.formGroup.controls['consigneeContactEmail'].setValue(this.selectedConsignee.contactEmail)
    }
  }

  loadNotifierForm() {
    if(this.selectedNotifier) {
      this.formGroup.controls['notifierCompanyName'].setValue(this.selectedNotifier.companyName)
      this.formGroup.controls['notifierVat'].setValue(this.selectedNotifier.vat)
      this.formGroup.controls['notifierContactCountryId'].setValue(this.selectedNotifier.contactCountryId)
      this.formGroup.controls['notifierContactCity'].setValue(this.selectedNotifier.contactCity)
      this.formGroup.controls['notifierContactName'].setValue(this.selectedNotifier.contactName)
      this.formGroup.controls['notifierContactAddress'].setValue(this.selectedNotifier.contactAddress)
      this.formGroup.controls['notifierContactPhone'].setValue(this.selectedNotifier.contactPhone)
      this.formGroup.controls['notifierContactEmail'].setValue(this.selectedNotifier.contactEmail)
    }
  }

  confirmPurchase() {
    this.submit = true
    this.loading = true

    if(this.formGroup.valid) {

      this._confirmationService.confirm({
        message: this.translate.instant('MessageService.ImportOrder.ConfirmPurchaseDialogMessage'),
        header: this.translate.instant('MessageService.ImportOrder.ConfirmPurchaseDialogHeader'),
        icon: 'pi pi-info-circle',
        accept: () => {
          if(this.orderId) {
            this.loading = true

            const consignee: Consignee = {
              id: this.selectedConsignee ? this.selectedConsignee.id : undefined,
              companyName: this.formGroup.controls['consigneeCompanyName'].value,
              vat: this.formGroup.controls['consigneeVat'].value,
              contactAddress: this.formGroup.controls['consigneeContactAddress'].value,
              contactCity: this.formGroup.controls['consigneeContactCity'].value,
              contactCountryId: this.formGroup.controls['consigneeContactCountryId'].value,
              contactEmail: this.formGroup.controls['consigneeContactEmail'].value,
              contactName: this.formGroup.controls['consigneeContactName'].value,
              contactPhone: this.formGroup.controls['consigneeContactPhone'].value,
            }

            const notifier: Notifier = {
              id: this.selectedNotifier ? this.selectedNotifier.id : undefined,
              companyName: this.formGroup.controls['notifierCompanyName'].value,
              vat: this.formGroup.controls['notifierVat'].value,
              contactAddress: this.formGroup.controls['notifierContactAddress'].value,
              contactCity: this.formGroup.controls['notifierContactCity'].value,
              contactCountryId: this.formGroup.controls['notifierContactCountryId'].value,
              contactEmail: this.formGroup.controls['notifierContactEmail'].value,
              contactName: this.formGroup.controls['notifierContactName'].value,
              contactPhone: this.formGroup.controls['notifierContactPhone'].value,
            }

            const request: ImportOrderConfirmPurchaseRequest = {
              importOrderId: this.orderId,
              consignee: consignee,
              notifier: notifier
            }

            this._importOrderService.confirmPurchase(request).subscribe({
              next: (v) => {
                this.success = true
                this._messageService.add({ severity: 'success',
                  summary: this.translate.instant('MessageService.Summary.SuccessAction'),
                  detail: this.translate.instant('MessageService.ImportOrder.ConfirmPurchaseSuccess') })
              },
              error: (e) => {
                console.log(e)
                this._messageService.add({ severity: 'error',
                  summary: this.translate.instant('MessageService.Summary.FailedAction'),
                  detail: this.translate.instant('MessageService.ImportOrder.EditError') });
                this.loading = false;
              },
              complete: () => {
                this.loading = false
                console.info('Confirm purchase successfully')
              }
            })
          }
        },
        reject: (type: any) => {
            switch (type) {
                case ConfirmEventType.REJECT:
                    //this._messageService.add({ severity: 'error', summary: 'Rejected', detail: 'Se canceló la confirmación de compra' })
                    this.loading = false
                    break;
                case ConfirmEventType.CANCEL:
                    //this._messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Se canceló la confirmación de compra' })
                    this.loading = false
                    break;
            }
        }
      })
    }
    else {
      this.loading = false
    }
  }

  onCancel() {
    this._router.navigate(['/' + Routes.userImportOrdersApproved])
  }

  goToConfirmedOrders() {
    this._router.navigate(['/' + Routes.userImportOrdersConfirmed])
  }

  goToImportOrderPage() {
    if(this.order) {
      this._router.navigate(['/' + Routes.userOrderDetailPage, this.order.importOrder.guid])
    }
  }

  getErrorMessage(fieldName: string): string {
    if (this.formGroup.controls[fieldName].hasError('required')) {
      return this.translate.instant('FormErrorMessage.FieldValueRequired')
    }
    if (this.formGroup.controls[fieldName].hasError('email')) {
      return this.translate.instant('FormErrorMessage.EmailFieldEmailFormatError')
    }
    return ''
  }
}
