import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { CountryGateway } from 'src/app/application/gateway/CountryGateway';
import { FridgeStorageGateway } from 'src/app/application/gateway/FridgeStorageGateway';
import { PortGateway } from 'src/app/application/gateway/PortGateway';
import { ProfileGateway } from 'src/app/application/gateway/ProfileGateway';
import { SpeciesGateway } from 'src/app/application/gateway/SpeciesGateway';
import { UserJoinRequestGateway } from 'src/app/application/gateway/UserJoinRequestGateway';
import { VarietyGateway } from 'src/app/application/gateway/VarietyGateway';
import { Country } from 'src/app/domain/models/Country';
import { Exporter, Importer } from 'src/app/domain/models/Profile';
import { Species } from 'src/app/domain/models/Species';
import { BasicUser } from 'src/app/domain/models/User';
import { Variety } from 'src/app/domain/models/Variety';
import { SpeciesUtils } from '../../util/species-utils';
import { statusInterface } from '../../util/filter-util';

@Component({
  selector: 'app-filter-component',
  templateUrl: './filter-component.component.html',
  styleUrls: ['./filter-component.component.css']
})
export class FilterComponentComponent implements OnInit {


  profile: BasicUser | undefined

  varieties: Variety[] = []
  species: Species[] = []
  filterSpecies: Species[] = []
  filterVarieties: Variety[] = []
  selectedSpecies: Species | undefined
  selectedVariety: Variety | undefined
  selectedCountryFrom: Country | undefined

  filterForm: FormGroup = this._formBuilder.group({
    speciesId: [null],
    varietyId: [null],
    date: [''],
    id: [''],
    status: [null],
    importerId: [null],
  })

  programRequestStatusItem: any[] = []
  
  sidebarVisible: boolean = false
  
  loading: boolean = false
  selectedCountryTo: Country | undefined
  importers: Importer[] = []
  exporters: Exporter[] = []
  
  @Output() ExecuteFilterEvent = new EventEmitter
  @Output() AdvanceFilterEvent = new EventEmitter
  @Output() CleanFilterEvent = new EventEmitter
  @Input() statusArray: statusInterface[] = []



  constructor(
    private _messageService: MessageService,
    private _formBuilder: FormBuilder,
    private _authService: AuthGateway,
    public translate: TranslateService,
    private _profileService: ProfileGateway,
    private _speciesService: SpeciesGateway,
    private _varietyService: VarietyGateway,
  ) {

    this.profile = this._authService.getUser()

  }

  ngOnInit(): void {
    this.loadSpecies()
    this.loadImporters()
  }



  loadVarieties() {
    this.selectedSpecies = this.species.find(x => x.id === this.filterForm.controls['speciesId'].value)
    if(this.selectedSpecies)
      {
      this.varieties = []
      this.loading = true      
      this._varietyService.getAllBySpecies(this.filterForm.controls['speciesId'].value).subscribe({
        next: (v) => {
  
            this.varieties = v
            this.loading = false
            this.executeFilter()
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.Variety.ManyRetrieveError') })
        },
        complete: () => console.info('Variety query successfully')
      })
    }else{
      this.executeFilter()
    }


  }

  onChangeVariety() {
    this.selectedVariety = this.varieties.find(x => x.id === this.filterForm.controls['varietyId'].value)
    this.executeFilter()
  }

  getSpeciesName(species: Species): string {
    if(this.profile)
      return SpeciesUtils.getSpeciesName(this.profile.lang, species)
    return ''
  }

  loadSpecies() {
    this._speciesService.getAllByProductType(1).subscribe({
      next: (v) => {
        this.species = v
        this.loading = false
      },
      error: (e) => {
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Species.ManyRetrieveError') })
      },
      complete: () => console.info('Species query successfully')
    })
  }

  loadImporters() {
    this.importers = []
    if(this.profile) {
      this._profileService.getAllImportersByExporterId().subscribe({
        next: (v) => {
          this.importers = v
          this.loading = false
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.Importer.ManyRetrieveError') })
        },
        complete: () => console.info('Importers query successfully')
      })
    }
  }

  cleanFilter()
  {


    this.filterForm.controls['speciesId'].setValue('')
    this.filterForm.controls['varietyId'].setValue('')
    this.filterForm.controls['date'].setValue('')
    this.filterForm.controls['status'].setValue('')
    this.filterForm.controls['importerId'].setValue('')
    this.CleanFilterEvent.emit()

    this.executeFilter()

  }

  executeFilter()
  {
    this.ExecuteFilterEvent.emit(this.filterForm.value);
  }

  showAdvanceFilter()
  {
    this.AdvanceFilterEvent.emit(this.filterForm.value)
  }

}
