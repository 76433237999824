import { Component, OnInit } from '@angular/core';
import { Routes } from "../../../infrastructure/routes";
import { Router } from "@angular/router";

@Component({
  selector: 'app-forbidden-page',
  templateUrl: './forbidden-page.component.html',
  styleUrls: ['./forbidden-page.component.css']
})
export class ForbiddenPageComponent implements OnInit {

  constructor(private readonly _router: Router) {
  }

  ngOnInit(): void {
  }

  redirect() {
    this._router.navigate([Routes.homePage]).then()
  }
}
