import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Payment, PaymentCreateRequest, PaymentsInformation, PaymentUploadFile } from "src/app/domain/models/Payment";

@Injectable({providedIn: 'root'})
export abstract class PaymentGateway {
  abstract create(paymentCreateRequest: PaymentCreateRequest): Observable<Payment>
  abstract approvePayment(paymentId: number): Observable<any>
  abstract uploadFile(paymentUploadFile: PaymentUploadFile): Observable<Payment>
  abstract getAllByImportOrderId(importOrderId: any): Observable<PaymentsInformation>
}
