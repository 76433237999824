import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SuggestionInformation, SuggestionListItem } from "src/app/domain/models/ExportOfferSuggestion";

@Injectable({providedIn: 'root'})
export abstract class ExportOfferSuggestionGateway {
  abstract getByProfileId(): Observable<SuggestionListItem[]>
  abstract getById(suggestionId: number): Observable<SuggestionInformation>
  abstract acceptSuggestion(suggestionExportOfferId: number, suggestionId: number): Observable<any>
}
