import { Component, Input } from '@angular/core';
import { ExportOfferAd } from 'src/app/domain/models/ExportOffer';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.css']
})
export class ProductCardComponent {

  @Input()
  offer: ExportOfferAd | undefined

  formatDate(dateString: Date): string {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2); // Get last 2 digits of the year

    return `${day}/${month}/${year}`;
  }

  getImageUrl(offer: ExportOfferAd): string {
    return offer.pictures && offer.pictures.length > 0 ? environment.apiUrl + offer.pictures[0].url : ''
  }
}
