import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ExportOfferAd } from 'src/app/domain/models/ExportOffer';
import { ExportOfferUtils } from '../../util/export-offer-utils';
import { BasicUser } from 'src/app/domain/models/User';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { Routes } from 'src/app/infrastructure/routes';
import { ExportOfferGateway } from 'src/app/application/gateway/ExportOfferGateway';
import { MenuItem, MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { ProductCertificateType } from 'src/app/domain/models/ProductCertificateType';
import { Packaging } from 'src/app/domain/models/Packaging';
import { Species } from 'src/app/domain/models/Species';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ImportAndProgramByExportOffer } from 'src/app/domain/models/ImportRequest';
import { ImportRequestGateway } from 'src/app/application/gateway/ImportRequestGateway';
import { ProgramRequestUtil } from '../../util/programRequestUtil';
import { DocumentServiceGateway } from 'src/app/application/gateway/DocumentServiceGateway';

@Component({
  selector: 'app-offer-detail',
  templateUrl: './offer-detail.component.html',
  styleUrls: ['./offer-detail.component.css'],
  providers: [MessageService]
})
export class OfferDetailComponent implements OnInit, AfterViewInit {

  adsCode: string | undefined

  profile: BasicUser | undefined

  selectedProductCertificateTypes: ProductCertificateType[] = []
  selectedPackagingTypes: Packaging[] = []

  exportOfferAd: ExportOfferAd | undefined

  loading: boolean = false
  urlRoute: string = ''
  imageData: any

  breadcrumbStep1ItemLabel1: string = ''
  breadcrumbStep1ItemLabel2: string = ''

  breadcrumbItems: MenuItem[] = []


  constructor(
    private _authService: AuthGateway,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _messageService: MessageService,
    private _exportOfferService: ExportOfferGateway,
    private _documentService: DocumentServiceGateway,
    public translate: TranslateService
  ){
    this._activatedRoute.params.subscribe(params => {
      this.adsCode = params['adsCode']
    })    
  }


  ngOnInit(): void {
    this.profile = this._authService.getUser()
    this.saveAdsCode()
    this.loadDetailData()
    this.urlRoute = window.location.href;
  }

  ngAfterViewInit(): void {
    const lang = this.translate.currentLang
    console.log(lang)
    switch(lang) {
      case 'en':
        this.breadcrumbStep1ItemLabel1 = 'See more offers'
        this.breadcrumbStep1ItemLabel2 = 'Offer details'
        break
      case 'es':
        this.breadcrumbStep1ItemLabel1 = 'Ver mas ofertas'
        this.breadcrumbStep1ItemLabel2 = 'Detalle de oferta'
        break
    }

    this.breadcrumbItems = [
      { label: this.breadcrumbStep1ItemLabel1,  routerLink: ['/' + Routes.marketplacePage]},
      { label: this.breadcrumbStep1ItemLabel2}
    ]
  }

  loadDetailData()
  {
    if(this.adsCode)
    {
      this.loading = true;
      this._exportOfferService.getAdsOfferDetail(this.adsCode).subscribe({
        next: (v) => {
          this.exportOfferAd = v
          if( this.exportOfferAd.packagingTypes){
            this.selectedPackagingTypes = this.exportOfferAd.packagingTypes
          }

          if(this.exportOfferAd.certificates)
            this.selectedProductCertificateTypes = this.exportOfferAd.certificates

          this.getImageQr()
          this.loading = false;
        },
        error: (e) => {
          this.loading = false;
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOffer.ManyRetrieveError') })
        }
      })
    }

  }


  getHeader(offer: ExportOfferAd): string {
    if(offer)
      return offer.variety && offer.variety.species && offer.variety.species.name ? offer.variety.species.name.toUpperCase() : ''
    //  return SpeciesUtils.getSpeciesName(offer.variety.species).toUpperCase()
    return ''
  }

  getIncludedLabel(included: boolean) {
    return included ? this.translate.instant('UserPages.UserExportOffers.Included') : this.translate.instant('UserPages.UserExportOffers.NotIncluded')
  }

  getSubheader(offer: ExportOfferAd): string {
    return offer.variety && offer.variety.name ? offer.variety.name.toUpperCase() : ''
  }

  totalPrice = 0
  getTotalPriceOfferDialog(exportOffer: ExportOfferAd, event: any) {
    //this.totalPrice = ExportOfferUtils.getTotalPrice(exportOffer, event.value)
  }

  saveAdsCode(){
    if(this.adsCode)
    {
      sessionStorage.setItem('AdsCode', this.adsCode)
    }
  }

  navigateToLogin()
  {
    if(this.profile)
    {
      this._router.navigate(['/'+ Routes.marketplacePage])
    }else{
      this._router.navigate(['/', Routes.login])
    }
  }

  getIncoterms(value: string) {
    return ExportOfferUtils.getIncotermsName(value)
  }

  getNoAvailableLabel() {
    return this.translate.instant('ExportOffer.ExportOfferDetail.NoAvailable')
  }

  getImageQr()
  {
    if(this.exportOfferAd){
      this._exportOfferService.getOfferAdsCodeQrUrl(this.exportOfferAd.adsCode).subscribe({
        next: (v) => {
          this.createImageFromBlob(v.body)
        },
        error: (e) => {
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('Cannot load QR image') })
        }
      })
    }
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
       this.imageData = reader.result;
    }, false);
 
    if (image) {
       reader.readAsDataURL(image);
    }
 }

  get speciesDescription() {
    if(this.exportOfferAd) {

      switch(this.translate.currentLang) {
        case 'en':
          return {
            description: this.exportOfferAd.variety?.species?.descriptionEn,
            technicalCharacteristics: this.exportOfferAd.variety?.species?.technicalCharacteristicsEn,
            qualityControlCriteria: this.exportOfferAd?.variety?.species?.qualityControlCriteriaEn,
            harvestAreaChile: this.exportOfferAd?.variety?.species?.harvestAreaChileEn
          }
          break
        case 'es':
          return  {
            description: this.exportOfferAd.variety?.species?.descriptionEs,
            technicalCharacteristics: this.exportOfferAd.variety?.species?.technicalCharacteristicsEs,
            qualityControlCriteria: this.exportOfferAd?.variety?.species?.qualityControlCriteriaEs,
            harvestAreaChile: this.exportOfferAd?.variety?.species?.harvestAreaChileEs
          }
          break
      }
    }
    return  {
      description: this.getAvailableLabel(),
      technicalCharacteristics: this.getAvailableLabel(),
      qualityControlCriteria: this.getAvailableLabel(),
      harvestAreaChile: this.getAvailableLabel()
    }
  }

  getHarvestPeriod(specie?: Species) {
    let specieHarvestPeriod: string | undefined = ""
    if(this.exportOfferAd && specie) {
      switch(this.translate.currentLang) {
        case 'en':
          specieHarvestPeriod = specie?.harvestPeriod
          break
        case 'es':
          specieHarvestPeriod = specie?.harvestPeriodEs
          break
      }
    }
    return specieHarvestPeriod
  }

  getAvailableLabel() {
    return this.translate.instant('ExportOffer.ExportOfferDetail.NoAvailable')
  }

  getBasePallets(basePallets?: string) {
    return this.profile ? ExportOfferUtils.getBasePalletsName(this.profile.lang, basePallets) : ''
  }

  getBoxType(boxType?: string): string {
    return this.profile ? ExportOfferUtils.getBoxTypeName(this.profile.lang, boxType) : ''
  }

  getExportOfferQualityDocument(documents: any[] | undefined) {
    if (documents == undefined || documents.length <= 0) return;

    let docId = documents[0].id

    this._documentService.getExportOfferDocument2(docId).subscribe((resultBlob: any) => {
      var downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }
}
