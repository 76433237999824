import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { Calendar } from 'primeng/calendar';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { ImportOrderGateway } from 'src/app/application/gateway/ImportOrderGateway';
import { ProfileGateway } from 'src/app/application/gateway/ProfileGateway';
import { SpeciesGateway } from 'src/app/application/gateway/SpeciesGateway';
import { VarietyGateway } from 'src/app/application/gateway/VarietyGateway';
import { ExportOffer } from 'src/app/domain/models/ExportOffer';
import { ClosedSaleWeek, ImportOrderDetail, ImportOrderInformation } from 'src/app/domain/models/ImportOrder';
import { Profile } from 'src/app/domain/models/Profile';
import { Species } from 'src/app/domain/models/Species';
import { BasicUser } from 'src/app/domain/models/User';
import { Variety } from 'src/app/domain/models/Variety';
import { Routes } from 'src/app/infrastructure/routes';
import { OrderUtils } from 'src/app/ui/util/order-utils';
import { TranslateService } from '@ngx-translate/core';
import { ProductCertificateType } from 'src/app/domain/models/ProductCertificateType';
import { ExportOfferGateway } from 'src/app/application/gateway/ExportOfferGateway';
import { Packaging } from 'src/app/domain/models/Packaging';
import { SpeciesUtils } from 'src/app/ui/util/species-utils';
import { environment } from 'src/environments/environment';
import { PalletPrice } from 'src/app/domain/models/Pallet';
import { Country } from 'src/app/domain/models/Country';
import { Port } from 'src/app/domain/models/Port';
import { CountryGateway } from 'src/app/application/gateway/CountryGateway';
import { ErrorMessageUtil } from 'src/app/ui/util/error-message-util';
import { UserJoinRequestGateway } from 'src/app/application/gateway/UserJoinRequestGateway';
import { UserJoinRequest } from 'src/app/domain/models/UserJoinRequest';
import { ProgramRequest, ProgramRequestInformation } from 'src/app/domain/models/ProgramRequest';
import { ProgramRequestGateway } from 'src/app/application/gateway/ProgramRequestGateway';
import { ProgramRequestUtil } from 'src/app/ui/util/programRequestUtil';

@Component({
  selector: 'app-importer-closed-sale-orders',
  templateUrl: './importer-closed-sale-orders.component.html',
  styleUrls: ['./importer-closed-sale-orders.component.css'],
  providers: [MessageService]
})
export class ImporterClosedSaleOrdersComponent implements OnInit {

  profile: BasicUser | undefined

  startWeek: number = 0
  endWeek: number = 0
  year: number = 0
  selectedMonth: number = 0

  weekDtos: ClosedSaleWeek[] = []
  weekQueryDtos: ClosedSaleWeek[] = []
  exportOffer: ExportOffer | undefined
  selectedProductCertificateTypes: ProductCertificateType[] = []
  selectedPackagingTypes: Packaging[] = []

  loading: boolean = false
  visibleExportOfferDialog: boolean = false
  selectedSpecies: Species | undefined
  selectedVariety: Variety | undefined

  programRequestInfo: ProgramRequestInformation[] = []
  selectedProgramRequest: ProgramRequestInformation | undefined

  directSaleProgramId: number | undefined

  visibleProgramRequestDetailDialog: boolean = false

  programRequestStatusItem: any[] = []

  exporters: Profile[] = []
  species: Species[] = []
  varieties: Variety[] = []
  visibleCurrentPalletsPrice: boolean = false

  sidebarVisible: boolean = false

  currentPrices: PalletPrice[] = []

  filterFormGroup: FormGroup = this._formBuilder.group({
    exporterId: [null],
    week: [null],
    speciesId: [null],
    varietyId: [null],
  })

  othersFiltersForm: FormGroup = this._formBuilder.group({
    harvestDate: [''],
    kilosPerBox: [''],
    marketId: [''],
    coldStorage: [''],
    speciesSize: [''],
    shelfLife: [''],
    price: [''],
    fridgeStorageId: ['']
  })

  userJoinRequestFormGroup: FormGroup = this._formBuilder.group({
    guestEmail: ['', [Validators.required, Validators.email]],
  })

  selectedCountryFrom: Country | undefined
  countries: Country[] = []
  submit: boolean = false

  visibleInvite: boolean = false;

  directSaleStartWeek: number = 0
  directSaleEndWeek: number = 0
  directSaleStartYear: number = 0
  directSaleEndYear: number = 0


  onFilter() {
    this.loading = true
    this.weekDtos = this.weekQueryDtos

    const exporterId = this.filterFormGroup.controls['exporterId'].value

    if(exporterId) {
      let filtered:ClosedSaleWeek[]  = []
      this.weekDtos.forEach(w => {
        let week: ClosedSaleWeek = {
          importOrdersDtos: [],
          week: w.week,
          year: w.year,
          startWeek: w.startWeek,
          endWeek: w.endWeek,
          endYear: w.endYear,
          startYear: w.startYear
        }
        w.importOrdersDtos.forEach(i => {
          i.details.forEach(d => {
            if(d.exportOffer.exporterId == exporterId)
              week.importOrdersDtos.push(i)
          })
        })
        if(week.importOrdersDtos.length > 0)
          filtered.push(week)
      })
      this.weekDtos = filtered
    }

    const week = this.filterFormGroup.controls['week'].value

    if(week) {
      this.weekDtos = this.weekDtos.filter(x => x.week === week)
    }

    const speciesId = this.filterFormGroup.controls['speciesId'].value

    if(speciesId) {
      let filtered:ClosedSaleWeek[]  = []
      this.weekDtos.forEach(w => {
        let week: ClosedSaleWeek = {
          importOrdersDtos: [],
          week: w.week,
          year: w.year,
          startWeek: w.startWeek,
          endWeek: w.endWeek,
          endYear: w.endYear,
          startYear: w.startYear
        }
        w.importOrdersDtos.forEach(i => {
          i.details.forEach(d => {
            if(d.speciesId == speciesId)
              week.importOrdersDtos.push(i)
          })
        })
        if(week.importOrdersDtos.length > 0)
          filtered.push(week)
      })
      this.weekDtos = filtered
    }

    const varietyId = this.filterFormGroup.controls['varietyId'].value
    if(varietyId) {
      let filtered:ClosedSaleWeek[]  = []
      if(this.weekDtos.length > 0){
        this.directSaleStartWeek = this.weekDtos[0].startWeek
        this.directSaleEndWeek = this.weekDtos[0].endWeek
        this.directSaleStartYear = this.weekDtos[0].startYear
        this.directSaleEndYear = this.weekDtos[0].endYear
      }
      this.weekDtos.forEach(w => {
        let week: ClosedSaleWeek = {
          importOrdersDtos: [],
          week: w.week,
          year: w.year,
          startWeek: w.startWeek,
          endWeek: w.endWeek,
          endYear: w.endYear,
          startYear: w.startYear
        }
        w.importOrdersDtos.forEach(i => {
          i.details.forEach(d => {
            if(d.varietyId == varietyId)
              week.importOrdersDtos.push(i)
          })
        })
        if(week.importOrdersDtos.length > 0)
          filtered.push(week)
      })
      this.weekDtos = filtered
    }

    const marketId = this.othersFiltersForm.controls['marketId'].value;
    const harvestDate = this.othersFiltersForm.controls['harvestDate'].value;
    const price = this.othersFiltersForm.controls['price'].value;
    const shelfLife = this.othersFiltersForm.controls['shelfLife'].value;
    const speciesSize = this.othersFiltersForm.controls['speciesSize'].value;
    const kilosPerBox = this.othersFiltersForm.controls['kilosPerBox'].value;

    let filteredOthers:ClosedSaleWeek[] = []
    if(this.weekDtos.length > 0){
      this.directSaleStartWeek = this.weekDtos[0].startWeek
      this.directSaleEndWeek = this.weekDtos[0].endWeek
      this.directSaleStartYear = this.weekDtos[0].startYear
      this.directSaleEndYear = this.weekDtos[0].endYear
    }
    this.weekDtos.forEach(
      (week) => {
      
      let weekNew: ClosedSaleWeek = {
        importOrdersDtos: [],
        week: week.week,
        year: week.year,
        startWeek: week.startWeek,
        endWeek: week.endWeek,
        endYear: week.endYear,
        startYear: week.startYear
      }

      week.importOrdersDtos.forEach(i => {
            let priceFilter = true;
            let harvestFilter = true;
            let speciesSizeFilter = true;
            let kilosPerBoxFilter = true;
            let shelfLifeFilter = true;
            let marketFilter = true;
            i.details.forEach(d => {

              if(price)
                {
                  let findPrice = d.palletPrices?.find(x => x.price == price);
                  priceFilter = findPrice || d.price == price ? true : false;
                }

                if(harvestDate)
                  {
                    let registerDate = new Date(d.harvestDate)
                    let selectedDate = new Date(harvestDate)
                    harvestFilter = (registerDate.getDay() == selectedDate.getDay() && 
                                     registerDate.getMonth() == selectedDate.getMonth()  &&  
                                     registerDate.getFullYear() == selectedDate.getFullYear()
                                    ) 
                  }
              
              if(shelfLife)
                {
                  shelfLifeFilter = d.shelfLife = shelfLife
                }

              if(kilosPerBox)
                {
                  kilosPerBoxFilter = d.exportOffer.kilosPerBox = kilosPerBox
                }
              
              if(speciesSize)
                {
                  let findSpeciesSize = d.palletPrices?.find(x => x.speciesSize == speciesSize);
                  
                  speciesSizeFilter = findSpeciesSize || d.exportOffer.speciesSize == speciesSize ? true : false; 
                }
              
              if(marketId)
                {
                  marketFilter = 
                    d.exportOffer.marketCountryFirstId == marketId ||
                    d.exportOffer.marketCountrySecondId == marketId ||
                    d.exportOffer.marketCountryThirdId == marketId;
                }
          });
          
            if(priceFilter && 
              speciesSizeFilter &&
              kilosPerBoxFilter &&
              shelfLifeFilter &&
              harvestFilter &&
              marketFilter){
                weekNew.importOrdersDtos.push(i)
              }
          });

          if(weekNew.importOrdersDtos.length > 0)
            {
              filteredOthers.push(weekNew)
            }
      });

    this.weekDtos = filteredOthers;
    this.loading = false
  }

  getCountries() {
    this.countries = []
    this._countryService.getAll().subscribe({
      next: (v) => {
        this.countries = v
        this.loading = false
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Country.ManyRetrieveError') })
      },
      complete: () => console.info('Country query successfully')
    })
  }

  countryChange()
  {
    this.selectedCountryFrom =this.countries.find(x => x.id == this.othersFiltersForm.controls['marketId'].value);
  }

  selectProgramRequestDetail(programRequestInfo: ProgramRequestInformation)
  {
    this.selectedProgramRequest = programRequestInfo; 
    this.visibleProgramRequestDetailDialog = true
  }

  onClearFilter() {
    this.filterFormGroup.controls['exporterId'].setValue(null)
    this.filterFormGroup.controls['week'].setValue(null)
    this.filterFormGroup.controls['speciesId'].setValue(null)
    this.filterFormGroup.controls['varietyId'].setValue(null)

    this.othersFiltersForm.controls['harvestDate'].setValue(null)
    this.othersFiltersForm.controls['kilosPerBox'].setValue(null)
    this.othersFiltersForm.controls['marketId'].setValue(null)
    this.othersFiltersForm.controls['speciesSize'].setValue(null)
    this.othersFiltersForm.controls['shelfLife'].setValue(null)
    this.othersFiltersForm.controls['fridgeStorageId'].setValue(null)
    this.othersFiltersForm.controls['price'].setValue(null)

    this.weekDtos = this.weekQueryDtos
  }

  constructor(private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _messageService: MessageService,
    private _authService: AuthGateway,
    private _profileService: ProfileGateway,
    private _varietyService: VarietyGateway,
    private _speciesService: SpeciesGateway,
    private _importOrderService: ImportOrderGateway,
    private _exportOfferService: ExportOfferGateway,
    private _countryService: CountryGateway,
    private _userJoinRequestService: UserJoinRequestGateway,
    private _programRequestService: ProgramRequestGateway,
    public translate: TranslateService) {

      let now = new Date()
      this.startWeek = this.getFirstWeek(now.getMonth(), now.getFullYear())
      this.endWeek = this.getLastWeek(now.getMonth(), now.getFullYear())
      this.year = now.getFullYear()
      this.loadOrders()
      moment.locale('es')

      this.programRequestStatusItem = [
        { label: this.profile?.lang === "es" ? "Activa" : "Active", value: 0 },
        { label: this.profile?.lang === "es" ? "Solicitado" : "Required", value: 1 },
        { label: this.profile?.lang === "es" ? "Rechazado" : "Rejected", value: 2 }
      ]

      this._activatedRoute.params.subscribe(params => {
        this.directSaleProgramId = params['directSaleProgramId']
      })
  }

  ngOnInit(): void {
    this.loadProfile()
    this.loadSpecies()
    this.getCountries()
    this.loadOrders()
    // this.getAllProgramRequest()
  }

  getExporters() {
    if(this.weekQueryDtos) {
      this.weekQueryDtos.forEach(x => {
        x.importOrdersDtos.forEach(i => {
          i.details.forEach(d => {
            let exporter = this.exporters.find(x => x.id === d.exportOffer.exporterId)
            if(!exporter)
             this.exporters.push(d.exportOffer.exporter)
          })
        })
      })
    }
  }

  loadSpecies() {
    this._speciesService.getAllByProductType(1).subscribe({
      next: (v) => {
        this.species = v
        this.loading = false
      },
      error: (e) => {
        console.log(e)
        this.loading = false
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('MessageService.Species.ManyRetrieveError') })
      },
      complete: () => console.info('Species query successfully')
    })
  }

  loadVarieties() {
    this.varieties = []
    if(this.filterFormGroup.controls['speciesId'].value) {
      this._varietyService.getAllBySpecies(this.filterFormGroup.controls['speciesId'].value).subscribe({
        next: (v) => {
          this.varieties = v
          this.loading = false
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.Variety.ManyRetrieveError') })
        },
        complete: () => console.info('Variety query successfully')
      })
    }
  }

  loadProfile() {
    this.profile = this._authService.getUser()
    console.log(this.profile)
    if(this.profile && this.profile.type != 2)
      this._router.navigate([Routes.forbiddenPage])
  }

  loadOrders() {
    if(this.directSaleProgramId)
      {
        this.loading = true
        this._importOrderService.getAllClosedSaleByImporter(this.startWeek, this.endWeek, this.year, this.directSaleProgramId).subscribe({
          next: (v) => {
            this.weekDtos = v
            this.weekQueryDtos = v
            if(v.length > 0){
              this.directSaleStartWeek = v[0].startWeek
              this.directSaleEndWeek = v[0].endWeek
              this.directSaleStartYear = v[0].startYear
              this.directSaleEndYear = v[0].endYear
            }

            this.getExporters()
            this.loading = false
          },
          error: (e) => {
            this.loading = false
            console.log(e)
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: this.translate.instant('MessageService.ImportOrder.ManyRetrieveError') });
          },
          complete: () => console.info('Orders query successfully')
        })
      }
  }

  getDetailTotalPrice(detail: ImportOrderDetail): number {
    return OrderUtils.getDetailTotalPrice(detail)
  }

  goToOrderDetailPage(orderId: number) {
    this._router.navigate(['/' + Routes.userClosedSaleImportOrderDetailPage, orderId, 0])
  }

  
  getPalletPrice(detail: ImportOrderDetail): number {
    let total = 0

    if(detail.palletPrices)
    {
      detail.palletPrices.forEach(x => {
        total += x.price ? x.boxPerPallets * x.price : 0;
      })
      return total
    }
    return 0
  }

  viewExportOfferDetailDialog(detail: ImportOrderDetail) {
    if(detail) {
      this._exportOfferService.getById(detail.exportOffer.id).subscribe({
        next: (v) => {
          this.exportOffer = v
          this.loading = false
          this.visibleExportOfferDialog = true
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ExportOffer.ManyRetrieveError') })
        },
        complete: () => console.info('Export Offers query successfully')
      })
    }
  }

  onMonthChange(event: any): void {
    this.selectedMonth = event.month
    this.startWeek = this.getFirstWeek(event.month - 1, event.year)
    this.endWeek = this.getLastWeek(event.month - 1, event.year)
    this.year = event.year
    this.loadOrders()
  }

  get selectedMonthText(): string {

    if(this.profile?.lang == 'es')
    {
      return this.selectedMonth ? moment().month(this.selectedMonth - 1).format('MMMM') : moment().format('MMMM')
    }else{
      return this.selectedMonth ? moment().locale('en').month(this.selectedMonth - 1).format('MMMM') : moment().format('MMMM')
    }

  }

  getFirstWeek(month: number, year: number): number {
    return moment().month(month).year(year).startOf('month').week()
  }

  getLastWeek(month: number, year: number): number {
    let first = moment().month(month).year(year).startOf('month').week()
    let last = moment().month(month).year(year).endOf('month').week()

    return (month == 11 && last < first) ? 52 : last
  }


  getWeekNumbers(month: number, year: number): any {
    var first, last, weeks=[];

    first = moment().month(month - 1).year(year).startOf('month').week();
    last = moment().month(month - 1).year(year).endOf('month').week();

    //Year change
    if(month == 12 && last < first)
      last = 52

    for(var i = first; i <= last; i++){
      weeks.push(i);
    }

    return weeks;
  }

  getWeekDateRanges(month: number, year: number) {
    const dateFormat = 'YYYY-MM-DD';
    let weeks = [];
    let momsrt = moment().month(month).year(year).startOf('month')
    let momend = moment().month(month).year(year).endOf('month')
    let week = momsrt.week();

    while (momend.isAfter(momsrt)) {
      /*
      weeks.push([
        momsrt.startOf('week').toDate(),
        momsrt.endOf('week').toDate()
        //momsrt.endOf('week').format(dateFormat)
      ]);*/

      weeks.push(momsrt.startOf('week').toDate())
      weeks.push(momsrt.endOf('week').toDate())

      week++
      momsrt.add(1, 'week')
    }

    weeks.push(momsrt.startOf('week').toDate())
    weeks.push(momsrt.endOf('week').toDate())

    /*
    weeks.push([
      momsrt.startOf('week').toDate(),
      momsrt.endOf('week').toDate()
    ]);*/

    return weeks
  }

  get totalPrice() {
    let total = 0
    if(this.weekDtos) {
      this.weekDtos.forEach(x => {
        x.importOrdersDtos.forEach(i => {
          i.details.forEach(d => {
            total += d.caseQuantityPerPallet * d.price * d.palletsQuantity
          })
        })
      })
    }
    return total
  }

  get totalBoxes() {
    let total = 0
    if(this.weekDtos) {
      this.weekDtos.forEach(x => {
        x.importOrdersDtos.forEach(i => {
          i.details.forEach(d => {
            total += d.caseQuantityPerPallet * d.palletsQuantity
          })
        })
      })
    }
    return total
  }

  get totalPallets() {
    let total = 0
    if(this.weekDtos) {
      this.weekDtos.forEach(x => {
        x.importOrdersDtos.forEach(i => {
          i.details.forEach(d => {
            total += d.palletsQuantity
          })
        })
      })
    }
    return total
  }

  get totalContainers() {
    let total = 0
    if(this.weekDtos) {
      total = this.totalPallets / 20
    }
    return total
  }

  get totalExporters() {
    let exporters: number[] = []
    if(this.weekDtos) {
      this.weekDtos.forEach(x => {
        x.importOrdersDtos.forEach(i => {
          i.details.forEach(d => {
            let exporter = exporters.find(x => x === d.exportOffer.exporterId)
            if(!exporter)
             exporters.push(d.exportOffer.exporterId)
          })
        })
      })
    }

    return exporters.length
  }

  loadSpeciesParams() {
    this.selectedSpecies = this.species.find(x => x.id === this.filterFormGroup.controls['speciesId'].value)
    this.loadVarieties()
  }

  getSpeciesName(species: Species): string {
    if(this.profile)
      return SpeciesUtils.getSpeciesName(this.profile.lang, species)
    return ''
  }

  onChangeVariety() {
    this.selectedVariety = this.varieties.find(x => x.id === this.filterFormGroup.controls['varietyId'].value)
  }

  getImageUrl(offer: ExportOffer): string {
    return offer.offerPictures && offer.offerPictures.length > 0 ? environment.apiUrl + offer.offerPictures[0].url : ''
  }

  goToImportOrderDetail(orderId: any, directSaleProgramId: any) {
    this._router.navigate(['/' + Routes.userClosedSaleImportOrderDetailPage, orderId, directSaleProgramId])
  }

  goToVerifyPalletsPage(detailId: any) {
    this._router.navigate(['/' + Routes.userClosedSaleExporterVerifyPallets, detailId])
  }

  setVisibleOrderDetail(order: ImportOrderInformation) {
    if (order) {
      order.expanded = !order.expanded
    }
  }

  showCurrentPalletPriceModal(detail: ImportOrderDetail) {
    // this.calculateTablePrice()
    this.currentPrices = detail.palletPrices ? detail.palletPrices : []
    this.visibleCurrentPalletsPrice = true
  }

  
  goToProgramRequestCreate()
  {
    this._router.navigate(['/' + Routes.userProgramRequestCreate])
  }

  saveUserJoinRequest() {
    this.loading = true
    this.submit = true
    if(this.userJoinRequestFormGroup.valid) {
      let request: UserJoinRequest = {
        hostUsername: this._authService.getUsername(),
        guestEmail: this.userJoinRequestFormGroup.controls['guestEmail'].value.toString().trim()
      }

      this._userJoinRequestService.create(request).subscribe({
        next: (v) => {
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: this.translate.instant('MessageService.UserJoinRequest.CreateSuccess') });
          this.submit = false
          this.loading = false
          this.visibleInvite = false
        },
        error: (e) => {
          this.loading = false
          console.log(e)

          const responseError = ErrorMessageUtil.getResponseError(e.error, this.profile?.lang);
          let errorDetail = responseError ? responseError : this.translate.instant('MessageService.UserJoinRequest.CreateError')

          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail:  errorDetail});
        },
        complete: () => console.info('User Join Request created successfully')
      })
    }
    else {
      this.loading = false
    }
  }
  
  getErrorMessage(fieldName: string): string {
    if (this.userJoinRequestFormGroup.controls[fieldName].hasError('required')) {
      return this.translate.instant('FormErrorMessage.FieldValueRequired')
    }
    if (this.userJoinRequestFormGroup.controls[fieldName].hasError('email')) {
      return this.translate.instant('FormErrorMessage.EmailFieldEmailFormatError')
    }
    return ''
  }

  getAllProgramRequest()
  {
    this.loading = true
    this._programRequestService.getAllByUsername().subscribe({
      next: (v) => {
        this.programRequestInfo = v
        this.loading = false
      },
      error: (e) => {
        this.loading = false
        this._messageService.add({ severity: 'error',
        summary: this.translate.instant('MessageService.Summary.FailedAction'),
        detail: this.translate.instant('MessageService.ProgramRequest.ManyRetrieveError') })
      }
    })
  }

  getTotalPalletsImportRequest(programRequest: ProgramRequest): number {
    return ProgramRequestUtil.getTotalPallets(programRequest)
  }

  getTotalContainersImportRequest(programRequest: ProgramRequest): number {
    return ProgramRequestUtil.getTotalContainers(programRequest)
  }

  get allCountriesText(): string {
    if(this.profile) {
      switch(this.profile.lang) {
        case 'en':
          return 'All countries'
        case 'es':
          return 'Todos los países'
        default:
          return 'All countries'
      }
    }
    return 'All countries'
  }

  getStatusValue(status: number): string {
    const statusObj = this.programRequestStatusItem.find(item => item.value === status);
    return statusObj ? statusObj.label : '';
  }

  
  goToEditProgramRequest(programRequestId: string)
  {
    this._router.navigate(['/' + Routes.userProgramRequestEdit, programRequestId])
  }

  getWeekNumber(day: number, month: number, year:number): boolean {
    const date = new Date(year,month, day)

    var weekNumber = ProgramRequestUtil.ISO8601_week_no(date)

    let weekFormat = this.getAsFormatPeriod(weekNumber, year)

    return (weekFormat >= this.startWeekValue) && (weekFormat <= this.endWeekValue)

  }

  getLength(value: number): number {
     return value.toString().length;
  }

  getAsFormatPeriod(weekNumber: number, year: number ): number {
      let digits = this.getLength(weekNumber);

      let weekNumberString = weekNumber.toString()

      if(digits <= 1){
         
         weekNumberString = this.zeroPad(weekNumber, 2)
      }

      let periodString = year.toString() + weekNumberString.toString()
      return parseInt(periodString);
  }

  zeroPad(num: number, places: number) {
    var zero = places - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join("0") + num;
  }

  get startWeekValue() {
    return this.getAsFormatPeriod(this.directSaleStartWeek,this.directSaleStartYear);
 }

 get endWeekValue() {
   return this.getAsFormatPeriod(this.directSaleEndWeek ,this.directSaleEndYear);
}

}
