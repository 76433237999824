import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Routes } from "src/app/infrastructure/routes";
import { ExportInvoiceGateway } from "../gateway/ExportInvoiceGateway";
import { UploadExportInvoicePdfRequest } from "src/app/domain/models/ExportInvoice";

@Injectable({providedIn: 'root'})
export class ExportInvoiceService extends ExportInvoiceGateway {

  constructor(private _http: HttpClient) {
    super()
  }

  uploadExportInvoicePdf(request: UploadExportInvoicePdfRequest): Observable<any> {
    const formData = new FormData()
    formData.append("guid", request.guid.toString())
    formData.append("pdf", request.pdf)

    return this._http.put<any>(Routes.exportInvoiceApi + '/Create', formData)
  }

}
