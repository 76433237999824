import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DirectSaleProgramCreateRequest, DirectSaleProgram, DirectSaleImporterFilter } from "src/app/domain/models/DirectSaleProgram";
import { ExportOfferInformation } from "src/app/domain/models/ExportOffer";

@Injectable({providedIn: 'root'})
export abstract class DirectSaleProgramGateway {
  abstract createWithOrders(request: DirectSaleProgramCreateRequest): Observable<DirectSaleProgram>
  abstract desactive(directSaleProgramGuid: string): Observable<DirectSaleProgram>
  abstract getByGuid(directSaleProgramGuid: string): Observable<DirectSaleProgram>
  abstract getDirectSaleImporter(): Observable<ExportOfferInformation[]>
  abstract getDirectSaleImporterFilter(request: DirectSaleImporterFilter): Observable<ExportOfferInformation[]>
}