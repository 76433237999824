import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { DocumentServiceGateway } from 'src/app/application/gateway/DocumentServiceGateway';
import { ExportInvoiceGateway } from 'src/app/application/gateway/ExportInvoiceGateway';
import { ImportOrderDetailGateway } from 'src/app/application/gateway/ImportOrderDetailGateway';
import { ImportOrderGateway } from 'src/app/application/gateway/ImportOrderGateway';
import { PalletGateway } from 'src/app/application/gateway/PalletGateway';
import { UploadExportInvoicePdfRequest } from 'src/app/domain/models/ExportInvoice';
import { GenericDocument } from 'src/app/domain/models/GenericDocument';
import { GenericOrderDetail, ImportOrder, ImportOrderDetailInformation } from 'src/app/domain/models/ImportOrder';
import { NewPallet, PalletInstructiveFormat } from 'src/app/domain/models/Pallet';
import { BasicUser } from 'src/app/domain/models/User';
import { PdfUtils } from 'src/app/ui/util/pdf.utils';


@Component({
  selector: 'app-import-order-documents',
  templateUrl: './import-order-documents.component.html',
  styleUrls: ['./import-order-documents.component.css']
})
export class ImportOrderDocumentsComponent implements OnInit {

  profile: BasicUser | undefined

  @Input()
  importOrder: ImportOrder | undefined
  @Input()
  details: GenericOrderDetail[] = []
  @Input()
  typeDocuments: number = 0
  @Input()
  qualityControlDocuments: GenericDocument[] | undefined
  @Input()
  operationDocuments: GenericDocument[] | undefined
  @Input()
  productCertificateDocuments: GenericDocument[] | undefined
  @Input()
  exportOfferDocuments: GenericDocument[] | undefined
  @Input()
  invoices: GenericDocument[] = []
  @Input()
  forwarderDocuments: GenericDocument[] | undefined
  @Input()
  customAgentDocuments: GenericDocument[] | undefined

  invoiceDialogVisible: boolean = false
  visibleFormatDialog: boolean = false
  loading: boolean = false
  selectedGuid!: string;
  
  palletsInstructiveFormat: PalletInstructiveFormat[] = []
  orderDetailInformation: ImportOrderDetailInformation | undefined;
  pallets: NewPallet  [] = []
  showPallets: boolean = false

 constructor(
  private _documentService: DocumentServiceGateway,
  private _importOrderDetailService: ImportOrderDetailGateway,
  private _authService: AuthGateway,
  private _messageService: MessageService,
  public translate: TranslateService,
  private _importOrderService: ImportOrderGateway,
  private _exportInvoiceService: ExportInvoiceGateway,
  private _palletService: PalletGateway
) {
}

ngOnInit(): void {
  this.profile = this._authService.getUser()
}

showModalExportInvoice() {
  this.invoiceDialogVisible = true
}

getInstructive() {
  if(this.selectedGuid)
  {
    this.loading = true;
    this._importOrderDetailService.getInstructiveDocument(this.selectedGuid).subscribe(

      {
        next:(resultBlob: any) => {
          var downloadURL = window.URL.createObjectURL(resultBlob.body);
          window.open(downloadURL)
          this.loading = false;

        },
        error:(e) => {
          this.loading = false;
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: 'Can not download excel file.' })
        },
        complete: () => {
          this.loading = false;
        }
        
      })
  }

}

getInstructivePdf() {

  if(this.selectedGuid)
  {
    this.loading = true;
    this._importOrderDetailService.getInstructiveDocumentPdf(this.selectedGuid).subscribe(
      {
        next: (resultBlob: any) => {
        
          PdfUtils.downloadPdf(resultBlob.body, "Instructivo_" + this.selectedGuid);
          this.loading = false;
          },
          error: (e) => {
            this.loading = false;
            this._messageService.add({ severity: 'error',
              summary: this.translate.instant('MessageService.Summary.FailedAction'),
              detail: 'Can not download pdf file.' })
          },
          complete: () => {
            this.loading = false;
          }
      })
  }

}

 getDocumentMain(document: GenericDocument) {
  if (document.documentType === 1) this.viewOriginQCReviewDocument(document.id)
  else if (document.documentType === 2) this.viewOriginQCConsolidationDocument(document.id)
  else if (document.documentType === 3) this.getFrozenFruitQCReviewDocument(document.id)
  else if (document.documentType === 4) this.getFrozenFruitQCConsolidationDocument(document.id)
  else if (document.documentType === 5) this.getOperationDocument(document.id)
  else if (document.documentType === 6) this.getProductCertificateDocument(document.id, this.importOrder?.guid)
  else if (document.documentType === 7) this.getExportOfferDocument(document.id, this.importOrder?.guid)
  else if (document.documentType === 8) this.getFrozenFruitPesticideAnalysisDocument(document.id)
  else if (document.documentType === 9) this.getFrozenFruitHeavyMetalsAnalysisDocument(document.id)
  else if (document.documentType === 10) this.getFrozenFruitMicrobiologicalAnalysisDocument(document.id)
  else if (document.documentType === 11) this.getFrozenFruitWaterAnalysisDocument(document.id)
  else if (document.documentType === 12) this.getExportInvoiceDocument(document.id)
  else if (document.documentType === 13 || document.documentType === 14) this.getImportOrderDetailDocument(document)
 }

 viewOriginQCReviewDocument(qcReviewGuid: string) {
  this._documentService.getOriginQCReviewDocument(qcReviewGuid).subscribe((resultBlob: any) => {
    const downloadURL = window.URL.createObjectURL(resultBlob.body);
    window.open(downloadURL);})
}

viewOriginQCConsolidationDocument(qcReviewGuid: string) {
  this._documentService.getOriginQCConsolidationDocument(qcReviewGuid).subscribe((resultBlob: any) => {
    const downloadURL = window.URL.createObjectURL(resultBlob.body);
    window.open(downloadURL);})
}

getFrozenFruitQCReviewDocument(importOrderDetailId: any) {
  this._documentService.getFrozenFruitQCReviewDocument(importOrderDetailId).subscribe((resultBlob: any) => {
    var downloadURL = window.URL.createObjectURL(resultBlob.body);
    window.open(downloadURL);})
}

getFrozenFruitQCConsolidationDocument(importOrderDetailId: any) {
  this._documentService.getFrozenFruitQCConsolidationDocument(importOrderDetailId).subscribe((resultBlob: any) => {
    var downloadURL = window.URL.createObjectURL(resultBlob.body);
    window.open(downloadURL);})
}

 getOperationDocument(documentId: number) {
  this._documentService.getOperationDocument(documentId).subscribe((resultBlob: any) => {
    var downloadURL = window.URL.createObjectURL(resultBlob.body);
    window.open(downloadURL);})
  }

  getProductCertificateDocument(productCertificateId: number, importOrderId: any) {
    this._documentService.getProductCertificateDocument(productCertificateId, importOrderId).subscribe((resultBlob: any) => {
      var downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }

 getExportOfferDocument(exportOfferDocumentId: number, importOrderId: any) {
    this._documentService.getExportOfferDocument(exportOfferDocumentId, importOrderId).subscribe((resultBlob: any) => {
      var downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }

  getFrozenFruitPesticideAnalysisDocument(importOrderDetailId: any) {
    this._documentService.getFrozenFruitPesticideAnalysisDocument(importOrderDetailId).subscribe((resultBlob: any) => {
      var downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }

  getFrozenFruitHeavyMetalsAnalysisDocument(importOrderDetailId: any) {
    this._documentService.getFrozenFruitHeavyMetalsAnalysisDocument(importOrderDetailId).subscribe((resultBlob: any) => {
      var downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }

  getFrozenFruitMicrobiologicalAnalysisDocument(importOrderDetailId: any) {
    this._documentService.getFrozenFruitMicrobiologicalAnalysisDocument(importOrderDetailId).subscribe((resultBlob: any) => {
      var downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }

  getFrozenFruitWaterAnalysisDocument(importOrderDetailId: any) {
    this._documentService.getFrozenFruitWaterAnalysisDocument(importOrderDetailId).subscribe((resultBlob: any) => {
      var downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }

  getExportInvoiceDocument(importOrderDetailId: any) {
    this._documentService.getExportInvoiceDocument(importOrderDetailId).subscribe((resultBlob: any) => {
      var downloadURL = window.URL.createObjectURL(resultBlob.body);
      window.open(downloadURL);})
  }

  getImportOrderDetailDocument(doc: any) {
    this._documentService.getImportOrderDetailDocument(doc.id).subscribe((resultBlob: any) => {

      const contentType = resultBlob.headers.get('Content-Type');
      const contentDisposition = resultBlob.headers.get('Content-Disposition');
      let fileName = doc.name;
      let fileExtension = '';

      if (contentDisposition) {
        const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = regex.exec(contentDisposition);
        if (matches != null && matches[1]) { 
          const originalFileName = matches[1];
          const dotIndex = originalFileName.lastIndexOf('.');
          if (dotIndex !== -1) {
            fileExtension = originalFileName.substring(dotIndex);
          }
        }
      }

      if (fileExtension) {
        fileName += fileExtension; // Adjunta la extensión al nombre del archivo
      }

      const blob = new Blob([resultBlob.body], { type: contentType });
      const downloadURL = window.URL.createObjectURL(blob);

      if (contentType === 'application/pdf') {
        window.open(downloadURL)
      } else {
        // Para otros tipos de archivos, forzar la descarga
        const anchor = window.document.createElement('a');
        anchor.href = downloadURL;
        anchor.download = fileName;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      }

      window.URL.revokeObjectURL(downloadURL);
    })
  }

  onUploadDocumentPdf(event: any, invoices: any) {
    if(this.details.length > 0) {
      this.loading = true
      let request: UploadExportInvoicePdfRequest = {
        guid: this.details[0].id,
        pdf: invoices.files[0]
      }

      this._exportInvoiceService.uploadExportInvoicePdf(request).subscribe({
        next: (v) => {
          this.loadExportInvoices()
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: 'Export invoice pdf file saved successfully.' })
        },
        error: (e) => {
          console.log(e)
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: 'Can not save pdf file.' })
        },
        complete: () =>  {
          this.loading = false
          console.info('Export Invoice uploaded successfully')
        }
      })
      invoices.clear()
    }
  }

  loadExportInvoices() {
    this.loading = true
    if(this.importOrder?.guid) {
      this._importOrderService.getById(this.importOrder?.guid).subscribe({
        next: (v) => {
          this.invoices = v.invoices
          this.loading = false
        },
        error: (e) => {
          console.log(e)
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ImportOrder.SingleRetrieveError') });
          this.loading = false
        },
        complete: () => console.info('Import Order query successfully')
      })
    }
  }

  showDownloadPdf(detailId : string)
  {
    this.selectedGuid = detailId;

    this.viewPallets(detailId);
  }


  viewPallets(orderDetailId: any) {
    if(orderDetailId) {
      this.loading = true
      this._palletService.getByImportOrderDetailId(orderDetailId).subscribe({
        next: (v) => {
          this.orderDetailInformation = v
          this.pallets = this.orderDetailInformation.pallets;
          this.visibleFormatDialog = true;
        },
        error: (e) => {
          this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudo recuperar el detalle de orden.' });
        },
        complete: () =>  {
          this.loading = false
          console.info('Import Order Detail query successfully')
        }
      })
    }
  }



  get notificationBadge(): string {
    return this.invoices.length > 0 ? this.invoices.length.toString() : ''
  }
}
