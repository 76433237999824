<div class="grid">
    <div class="col-4">
        <div class="flex flex-column" >
            <div class="label-text">{{'ExporterInfoModal.CompanyNameText' | translate}}</div>
            <div class="info-div border-round-sm">{{exporterInfo?.companyName}}</div>
        </div>
    </div>
    <div class="col-4">
        <div class="flex flex-column" >
            <div class="label-text">{{'ExporterInfoModal.ContactAddressText' | translate}}</div>
            <div class="info-div border-round-sm">{{exporterInfo?.contactAddress}}</div>
        </div>
    </div>
    <div class="col-4">
        <div class="flex flex-column" >
            <div class="label-text">{{'ExporterInfoModal.ContactEmailText' | translate}}</div>
            <div class="info-div border-round-sm">{{exporterInfo?.contactEmail}}</div>
        </div>
    </div>
    <div class="col-4">
        <div class="flex flex-column" >
            <div class="label-text">{{'ExporterInfoModal.CompanyNRegText' | translate}}</div>
            <div class="info-div border-round-sm">{{exporterInfo?.contactEmail}}</div>
        </div>
    </div>
    <!-- <div class="col-4">
        <div class="flex flex-column" >
            <div class="label-text">Usuario fruzty por compañía</div>
            <div class="info-div border-round-sm">Av 123, casa 123</div>
        </div>
    </div> -->
</div>
