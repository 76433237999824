import { HttpHeaders } from "@angular/common/http";
import {Injectable} from "@angular/core";
import { AuthUser } from "src/app/domain/models/AuthUser";
import { BasicUser } from "src/app/domain/models/User";

@Injectable({providedIn: 'root'})
export abstract class AuthGateway {
  abstract getToken(): string

  abstract isAuthenticated(): boolean

  abstract getUser(): BasicUser | undefined

  abstract authenticate(token: string): void

  abstract logout(): void

  abstract getHttpHeaders(): HttpHeaders

  abstract getCompleteName(): string

  abstract  getUsername(): string

  abstract getRole(): string
}
