<app-user-dashboard [fullScreen]="true" [activeItem]="'home'">
  <div class="bg-container">
    <div class="container">

      <p-carousel [value]="carouselItems" [numVisible]="1" [circular]="true" [showNavigators]="false" [showIndicators]="true" [autoplayInterval]="7000" [responsiveOptions]="responsiveOptions" class="carousel-no-display custom-carousel"
      >
        <ng-template let-item pTemplate="item">
            <div (click)="item.command($event)" class="carousel-container-img">
              <figure class="figure-img-bg">
                <img class="img-carousel" [src]="getImageBg(item.imageUrl, item.imageUrlEs)" alt="Banner image">
              </figure>
            </div>
        </ng-template>
        <!--
          <ng-template let-item pTemplate="item">
              <div [style]="{'background-color': item.backgroundColor}" class="carousel-container">
                <div class="carousel-colum-left">
                  <section class="text-section">
                    <h1 class="title-carousel">
                      {{item.message | translate}}
                    </h1>
                    <h3 class="msj-carousel">
                      {{item.description | translate}}
                    </h3>
                  </section>
                  <button (click)="navigateToMarketplace()" class="ghost-btn">{{item.btn | translate}}</button>
                </div>
                <div class="carousel-colum-right">
                  <figure class="figure-img">
                    <img class="img-carousel" [src]="item.imageUrl2" alt="Banner image">
                  </figure>
                </div>
              </div>
          </ng-template>
        -->
      </p-carousel>

      <div class="section-title">
        <h1 class="title-section">{{'HomePage.OurServicesText' | translate}}</h1>
      </div>

      <div class="container-services" *ngIf="services">
        <div *ngFor="let service of services" class="card-services">
          <figure class="figure-services">
            <img alt="service icon" src={{service.iconUrl}} class="icon-img">
          </figure>
          <p class="text-p">{{service.message | translate}}</p>
        </div>
      </div>

      <div class="section-content">
        <div class="section-title">
          <h1 class="title-section">{{'HomePage.ProductsTitle' | translate}}</h1>
        </div>
        <div class="container-cards" *ngIf="offersTrimmed">
          <div class="subcontainer-cards">
            <div class="container-card" *ngFor="let offer of offersTrimmed">
              <app-product-card [offer]="offer"></app-product-card>
            </div>
          </div>
        </div>
        <div class="section-btn">
          <button class="main-btn" (click)="navigateToMarketplace()">{{'HomePage.MarketplaceBtn' | translate}}</button>
        </div>
      </div>

      <div class="section-title">
        <h1 class="title-section">{{'HomePage.ToDoFruztyTitle' | translate}}</h1>
      </div>
      <!--
        <div class="horizontal-scroll-container" #carToDo>
          <div class="container-scroll-x" *ngIf="services" >
            <div *ngFor="let item of toDo">
              <div class="card-to-do">
                <figure class="figure-to-do">
                  <img alt="service icon" src={{item.iconUrl}} class="icon-img">
                </figure>
                <section class="section-to-do">
                  <h2 class="text-to-do">{{item.title | translate}}</h2>
                  <p class="text-to-do">{{item.message | translate}}</p>
                </section>
                <button class="ghost-btn-color">{{'HomePage.ToDoBtn' | translate}}</button>
              </div>
            </div>
          </div>
        </div>
      -->

      <p-carousel [value]="carouselToDo" [numVisible]="1" [circular]="true" [showNavigators]="false" [showIndicators]="true" [autoplayInterval]="7000" [responsiveOptions]="responsiveOptions" class="carousel-no-display custom-carousel"
      >
        <ng-template let-item pTemplate="item">
            <div class="carousel-container-to-do">
              <figure class="figure-img-bg">
                <img class="img-carousel" [src]="getImageBg(item.imageUrl, item.imageUrlEs)" alt="Banner image">
              </figure>
            </div>
        </ng-template>
        <!---->
      </p-carousel>


    </div>
  </div>
</app-user-dashboard>
<app-footer-home></app-footer-home>
