import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { CountryGateway } from 'src/app/application/gateway/CountryGateway';
import { ExportOfferGateway } from 'src/app/application/gateway/ExportOfferGateway';
import { FridgeStorageGateway } from 'src/app/application/gateway/FridgeStorageGateway';
import { ProductCertificateTypeGateway } from 'src/app/application/gateway/ProductCertificateTypeGateway';
import { SpeciesGateway } from 'src/app/application/gateway/SpeciesGateway';
import { VarietyGateway } from 'src/app/application/gateway/VarietyGateway';
import { Country } from 'src/app/domain/models/Country';
import { ExportOffer, OfferPicture } from 'src/app/domain/models/ExportOffer';
import { FridgeStorage } from 'src/app/domain/models/FridgeStorage';
import { ProductCertificateType } from 'src/app/domain/models/ProductCertificateType';
import { Profile } from 'src/app/domain/models/Profile';
import { Species, SpeciesSize } from 'src/app/domain/models/Species';
import { Variety } from 'src/app/domain/models/Variety';
import { Routes } from 'src/app/infrastructure/routes';
import { ExportOfferUtils } from 'src/app/ui/util/export-offer-utils';
import { Lang } from 'src/app/ui/util/langs';
import { environment } from 'src/environments/environment';


interface Picture {
  picture: OfferPicture
  file: any
}

@Component({
  selector: 'app-admin-edit-export-offer',
  templateUrl: './admin-edit-export-offer.component.html',
  styleUrls: ['./admin-edit-export-offer.component.css'],
  providers: [MessageService]
})
export class AdminEditExportOfferComponent implements OnInit {

  breadcrumbMenuItems: MenuItem[] = [{ label: 'Detalle de oferta de exportación' }]
  breadcrumbHome: MenuItem = { label: 'Ofertas de exportación'}

  exportOfferId: number | undefined
  exportOffer: ExportOffer | undefined

  species: Species[] = []
  varieties: Variety[] = []
  colors: string[] = []
  sizes: SpeciesSize[] = []
  qualityControlOptions: any[] = []
  certificateTypes: ProductCertificateType[] = []
  boxTypes: any[] = []
  kilosPerBoxes: any[] = []
  basePallets: any[] = []
  selectedCertifications: ProductCertificateType[] = []
  fridgeStorages: FridgeStorage[] = []
  pictures: Picture[] = []
  marksOptions: any[] = []
  removedPictures: number[] = []
  countries: Country[] = []

  incotermsOptions: any[] = ExportOfferUtils.getIncotermsOptions()

  loading: boolean = false
  submit: boolean = false

  profile: Profile | undefined

  formGroup: FormGroup = this._formBuilder.group({
    speciesId: ['', Validators.required],
    varietyId: ['', Validators.required],
    palletQuantity: [null, Validators.required],
    fridgeStorageId: ['', Validators.required],
    listPrice: [null, Validators.required],
    caseQuantityPerPallet: [null, Validators.required],
    incoterm: ['', Validators.required],
    color: [''],
    speciesSize: [''],
    harvestDate: [null, Validators.required],
    readyToLoadDate: [null],
    shelfLife: [null, Validators.required],
    boxType: ['', Validators.required],
    kilosPerBox: [''],
    netKilos: [''],
    grossKilos: [''],
    isQualityControl: [false],
    qualityControlDate: [null],
    basePallets: [''],
    marks: [false],
    marketCountry1Id: ['', Validators.required],
    marketCountry2Id: [''],
    marketCountry3Id: ['']
  })

  constructor(private _formBuilder: FormBuilder,
    private _messageService: MessageService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _varietyService: VarietyGateway,
    private _speciesService: SpeciesGateway,
    private _fridgeStorageService: FridgeStorageGateway,
    private _exportOfferService: ExportOfferGateway,
    private _productCertificateTypeService: ProductCertificateTypeGateway,
    private _countryService: CountryGateway
    ){
      this._activatedRoute.params.subscribe(
        params => {
          this.exportOfferId = params['exportOfferId']
        }
      )
    }

    ngOnInit(): void {
      this.loadProductCertificateTypes()
      this.loadExportOffer()

      this.colors = ExportOfferUtils.getColors(Lang.SPANISH)
      this.boxTypes = ExportOfferUtils.getBoxTypes(Lang.SPANISH)
      this.kilosPerBoxes = ExportOfferUtils.getKilosPerBox()
      this.basePallets = ExportOfferUtils.getBasePallets(Lang.SPANISH)
      this.qualityControlOptions = [{label: 'Si', value: true}, {label: 'No', value: false}]
      this.marksOptions = [{label: 'Si', value: true}, {label: 'No', value: false}]
    }

    loadExportOffer() {
      if(this.exportOfferId) {
        this._exportOfferService.getById(this.exportOfferId).subscribe({
          next: (v) => {
            console.log(v)
            this.exportOffer = v
            this.loadForm()
          },
          error: (e) => {
            console.log(e)
            this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudo recuperar el perfil.' });
          },
          complete: () => console.info('Profile query successfully')
        })
      }
    }

    loadForm() {
      if(this.exportOffer) {
        this.loadSpecies()
        this.formGroup.controls['speciesId'].setValue(this.exportOffer.variety.speciesId)
        this.loadVarieties(true)
        this.loadSpeciesSizes(true)
        this.formGroup.controls['palletQuantity'].setValue(this.exportOffer.palletQuantity)
        this.formGroup.controls['caseQuantityPerPallet'].setValue(this.exportOffer.caseQuantityPerPallet)
        this.formGroup.controls['listPrice'].setValue(this.exportOffer.listPrice)
        this.loadFridgeStorages(true)
        this.formGroup.controls['color'].setValue(this.exportOffer.color)
        this.formGroup.controls['harvestDate'].setValue(this.exportOffer.harvestDate ? new Date(this.exportOffer.harvestDate) : null)
        this.formGroup.controls['readyToLoadDate'].setValue(this.exportOffer.readyToLoadDate ? new Date(this.exportOffer.readyToLoadDate) : null)
        this.formGroup.controls['shelfLife'].setValue(this.exportOffer.shelfLife)
        this.formGroup.controls['boxType'].setValue(this.exportOffer.boxType)
        this.formGroup.controls['kilosPerBox'].setValue(this.exportOffer.kilosPerBox)
        this.formGroup.controls['netKilos'].setValue(this.exportOffer.netKilos);
        this.formGroup.controls['grossKilos'].setValue(this.exportOffer.grossKilos);
        this.formGroup.controls['isQualityControl'].setValue(this.exportOffer.isQualityControl)
        this.formGroup.controls['qualityControlDate'].setValue(this.exportOffer.qualityControlDate ? new Date(this.exportOffer.qualityControlDate) : null)
        this.formGroup.controls['basePallets'].setValue(this.exportOffer.basePallets)
        this.formGroup.controls['marks'].setValue(this.exportOffer.marks)
        this.formGroup.controls['incoterm'].setValue(this.exportOffer.typeSale)
        this.loadCountries(true)

        this.exportOffer.offerPictures?.forEach(async p => {
          p.isNew = false
          this.pictures.push({picture: p, file: null})
        })

        //Copy new array
        this.selectedCertifications = []
        this.selectedCertifications = this.exportOffer.productCertificates ? this.exportOffer.productCertificates : []
      }
    }

    loadSpeciesParams() {
      this.loadVarieties(false)
      this.loadSpeciesSizes(false)
      this.loadCountries(false)
    }

    loadVarieties(setValue: boolean) {
      this.varieties = []
      this._varietyService.getAllBySpecies(this.formGroup.controls['speciesId'].value).subscribe({
        next: (v) => {
          this.varieties = v
          this.loading = false
          if(setValue && this.exportOffer && this.exportOffer.variety)
            this.formGroup.controls['varietyId'].setValue(this.exportOffer.variety.id)
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudieron recuperar las variedades.' })
        },
        complete: () => console.info('Variety query successfully')
      })
    }

    loadSpeciesSizes(setValue: boolean) {
      this.sizes = []
      this._speciesService.getSpeciesSizes(this.formGroup.controls['speciesId'].value).subscribe({
        next: (v) => {
          this.sizes = v
          this.loading = false
          if(setValue && this.exportOffer && this.exportOffer.speciesSize)
            this.formGroup.controls['speciesSize'].setValue(this.exportOffer.speciesSize)
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudieron recuperar los calibres.' })
        },
        complete: () => console.info('Species Sizes query successfully')
      })
    }

    loadSpecies() {
      this._speciesService.getAll().subscribe({
        next: (v) => {
          this.species = v
          this.loading = false
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudieron recuperar las especies.' })
        },
        complete: () => console.info('Species query successfully')
      })
    }

    loadProductCertificateTypes() {
      this._productCertificateTypeService.getAll().subscribe({
        next: (v) => {
          this.certificateTypes = v
          this.loading = false
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudieron recuperar los tipos de certificados.' })
        },
        complete: () => console.info('ProductCertificateTypes query successfully')
      })
    }

    loadFridgeStorages(setValue: boolean) {
      this.fridgeStorages = []
      const username = this.exportOffer?.exporter?.contactEmail ? this.exportOffer?.exporter?.contactEmail : '';
      console.log(username);
      this._fridgeStorageService.getAllAvailable().subscribe({
        next: (v) => {
          this.fridgeStorages = v
          this.loading = false
          if(setValue && this.exportOffer)
            this.formGroup.controls['fridgeStorageId'].setValue(this.exportOffer.fridgeStorageId)
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudieron recuperar los calibres.' })
        },
        complete: () => console.info('Species Sizes query successfully')
      })
    }

    loadCountries(setValue: boolean) {
      this.countries = []
      this._countryService.getAll().subscribe({
        next: (v) => {
          this.countries = v
          this.loading = false
          if(setValue && this.exportOffer) {
            this.formGroup.controls['marketCountry1Id'].setValue(this.exportOffer.marketCountryFirstId)
            this.formGroup.controls['marketCountry2Id'].setValue(this.exportOffer.marketCountrySecondId)
            this.formGroup.controls['marketCountry3Id'].setValue(this.exportOffer.marketCountryThirdId)
          }
        },
        error: (e) => {
          console.log(e)
          this.loading = false
          this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudieron recuperar los calibres.' })
        },
        complete: () => console.info('Species Sizes query successfully')
      })
    }

    getImageUrl(picture: Picture): any {
      if(picture) {
        if(!picture.picture.isNew && picture.picture.url) {
          return environment.apiUrl + picture.picture.url
        }
        else {
          return picture.file.objectURL
        }
      }
      return ''
    }

    save() {
      this.loading = true
      this.submit = true
      if(this.formGroup.valid && this.exportOffer) {
        this.exportOffer.varietyId = this.formGroup.controls['varietyId'].value
        this.exportOffer.palletQuantity = this.formGroup.controls['palletQuantity'].value
        this.exportOffer.fridgeStorageId = this.formGroup.controls['fridgeStorageId'].value
        this.exportOffer.availablePallets = this.formGroup.controls['palletQuantity'].value
        this.exportOffer.color = this.formGroup.controls['color'].value
        this.exportOffer.harvestDate = this.formGroup.controls['harvestDate'].value
        this.exportOffer.readyToLoadDate = this.formGroup.controls['readyToLoadDate'].value
        this.exportOffer.boxType = this.formGroup.controls['boxType'].value
        this.exportOffer.kilosPerBox = this.formGroup.controls['kilosPerBox'].value
        this.exportOffer.netKilos = this.formGroup.controls['netKilos'].value
        this.exportOffer.grossKilos = this.formGroup.controls['grossKilos'].value
        this.exportOffer.caseQuantityPerPallet = this.formGroup.controls['caseQuantityPerPallet'].value
        this.exportOffer.isQualityControl = this.formGroup.controls['isQualityControl'].value
        this.exportOffer.shelfLife = this.formGroup.controls['shelfLife'].value
        this.exportOffer.speciesSize = this.formGroup.controls['speciesSize'].value
        this.exportOffer.listPrice = this.formGroup.controls['listPrice'].value
        this.exportOffer.qualityControlDate = this.formGroup.controls['qualityControlDate'].value
        this.exportOffer.basePallets = this.formGroup.controls['basePallets'].value
        this.exportOffer.marks = this.formGroup.controls['marks'].value
        this.exportOffer.typeSale = this.formGroup.controls['incoterm'].value
        this.exportOffer.marketCountryFirstId = this.formGroup.controls['marketCountry1Id'].value
        this.exportOffer.marketCountrySecondId = this.formGroup.controls['marketCountry2Id'].value
        this.exportOffer.marketCountryThirdId = this.formGroup.controls['marketCountry3Id'].value

        //Set the removed pictures
        this.exportOffer.pictures = []
        if(this.pictures) {
          this.pictures.forEach(p => {
            if (p.picture.isNew)
              this.exportOffer?.pictures.push(p.file)
          })
        }
        this.exportOffer.removedPictures = this.removedPictures

        //Set the selected certificates
        this.exportOffer.certificates = this.selectedCertifications

        //Filter and set the removed product certificates
        let removedCerts: ProductCertificateType[] = []
        if(this.selectedCertifications && this.exportOffer.productCertificates) {
          this.exportOffer.productCertificates.forEach(c => {
            const keep = this.selectedCertifications.find(s => s.id === c.id)
            if(!keep) {
              removedCerts.push(c)
            }
          })
        }
        this.exportOffer.removedCertificates = removedCerts

        this._exportOfferService.updateAdmin(this.exportOffer).subscribe({
          next: (v) => {
            this.exportOffer = v
            //this.loadForm()
            this._messageService.add({ severity: 'success', summary: 'Acción exitosa', detail: 'La orden de exportación fue actualizada con éxito.' })
            this.submit = false
            this.loading = false
          },
          error: (e) => {
            this.loading = false
            console.log(e)
            this._messageService.add({ severity: 'error', summary: 'Acción fallida', detail: 'No se pudo actualizar la orden de exportación. Inténtelo de nuevo.' });
          },
          complete: () => console.info('Export Offer update successfully')
        })

      }
      else {
        this.loading = false
      }
    }

    getErrorMessage(fieldName: string): string {
      if (this.formGroup.controls[fieldName].hasError('required')) {
        return 'Este campo es requerido.';
      }
      if (this.formGroup.controls[fieldName].hasError('email')) {
        return 'Formato de correo electrónico incorrecto.';
      }
      return '';
    }

    goToExportOffersPage() {
      this._router.navigate(['/' + Routes.adminExportOfferPage])
    }

    onRemoveImage(index: number): void {
      if(this.exportOffer && this.pictures[index]) {
        this.removedPictures.push(this.pictures[index].picture.id)
        this.pictures.splice(index, 1)
      }
      console.log(this.removedPictures)
    }

    getImageName(picture: Picture): string {
      let fileName = ''
      if(!picture.picture.isNew && picture.picture.url) {
        const urlParts: string[] = picture.picture.url.split('/')
        fileName = urlParts[urlParts.length-1]
      }
      else {
        return picture.file.name
      }
      return fileName
    }

    onSelectImage(event: any, uploader: any): void {
      this.pictures.push(
        {
          picture: {id: 0,
                    url: uploader.files[0].objectURL.changingThisBreaksApplicationSecurity,
                    isNew: true},
          file: uploader.files[0]
        })
      uploader.clear()
    }
}
