import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { NewNegotiation, OfferNegotiationInformation, AddNegotiation } from "src/app/domain/models/OfferNegotiation";
import { Routes } from "src/app/infrastructure/routes";
import { OfferNegotiationGateway } from "../gateway/OfferNegotiationGateway";

@Injectable({providedIn: 'root'})
export class OfferNegotiationService extends OfferNegotiationGateway {
  constructor(private _http: HttpClient) {
    super()
  }

  create(negotiation: NewNegotiation): Observable<OfferNegotiationInformation> {
    return this._http.post<OfferNegotiationInformation>(Routes.offerNegotiationApi + '/Create', negotiation);
  }

  addNegociation(negotiation: AddNegotiation): Observable<OfferNegotiationInformation> {
    return this._http.post<OfferNegotiationInformation>(Routes.offerNegotiationApi + '/AddNegotiation', negotiation);
  }

  getAllByProfile(username: string, searchBy: string, filterStatus: string): Observable<OfferNegotiationInformation[]> {
    return this._http.get<OfferNegotiationInformation[]>(Routes.offerNegotiationApi + '/GetAllByProfile', { params: {username, searchBy, filterStatus}})
  }

  getById(offerNegotiationId: number): Observable<OfferNegotiationInformation> {
    return this._http.get<OfferNegotiationInformation>(Routes.offerNegotiationApi + '/GetById', { params: {offerNegotiationId}})
  }

  approve(offerNegotiationDetailId: number): Observable<OfferNegotiationInformation> {
    return this._http.put<OfferNegotiationInformation>(Routes.offerNegotiationApi + '/Approve', {}, { params: {offerNegotiationDetailId}});
  }

  reject(offerNegotiationId: number): Observable<OfferNegotiationInformation> {
    return this._http.put<OfferNegotiationInformation>(Routes.offerNegotiationApi + '/Reject', {}, { params: {offerNegotiationId}});
  }
}
