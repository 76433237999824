import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Operation, OperationInformation, UploadOperationFileRequest } from "src/app/domain/models/Operation";
import { OperationDocument } from "src/app/domain/models/OperationDocument";

Injectable({providedIn: 'root'})
export abstract class OperationGateway {
    abstract uploadFile(uploadRequest: UploadOperationFileRequest): Observable<Operation>
    abstract getByImportOrderId(importOrderId: any): Observable<OperationInformation>
    abstract updateDocument(operationDocument: OperationDocument): Observable<OperationDocument>
    abstract deleteDocument(operationDocumentId: number): Observable<any>
}
