<app-user-dashboard [userMenuActiveItemIndex]="0" [activeItem]="'negotiations'">
  <p-card [style]="{'min-height':'200px'}">
    <ng-template pTemplate="header">
      <div class="flex justify-content-between flex-wrap align-items-center" style="padding: 15px;">
        <h2>{{'UserPages.UserBargaining.PageTitle' | translate}}</h2>
      </div>
      <div>
        <p-tabMenu [model]="tabMenuItems" [style]="{padding: '15px'}"></p-tabMenu>
      </div>
    </ng-template>
    <ng-template pTemplate="body">
      <p-table [value]="negotiations"
        [paginator]="true"
        [rows]="10"
        [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[10, 25, 50]"
        [loading]="loading"
        responsiveLayout="stack"
        [breakpoint]="'600px'">
        <ng-template pTemplate="header">
          <tr>
            <th colspan="2">
              <div class="flex justify-content-start align-items-center">
                {{'UserPages.UserBargaining.OpenTab.OpenTable.ProductHeader' | translate}}
              </div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">
                {{'UserPages.UserBargaining.OpenTab.OpenTable.SugesstedPriceIncontermsHeader' | translate}}
              </div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">
                {{'UserPages.UserBargaining.OpenTab.OpenTable.BoxPalletsHeader' | translate}}
              </div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">
                {{'UserPages.UserBargaining.OpenTab.OpenTable.TotalPriceHeader' | translate}}
              </div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">
                {{'UserPages.UserBargaining.OpenTab.OpenTable.PalletsLocationHeader' | translate}}
              </div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">
                {{'UserPages.UserBargaining.OpenTab.OpenTable.NegotiationDateHeader' | translate}}
              </div>
            </th>
            <th>{{'UserPages.UserBargaining.OpenTab.OpenTable.ActionsHeader' | translate}}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-negotiation>
          <tr>
            <td><img [src]="getExportOfferImageUrl(negotiation.exportOffer)" style="border-radius: 7px; width: 100%; max-width: 100px; height: auto;"/></td>
            <td>
              <h3>{{negotiation.exportOffer?.variety?.species?.name}}</h3>
              {{negotiation.exportOffer?.variety?.name}}
            </td>
            <td>
              <div class="flex justify-content-center align-items-center">
                <span style="font-weight: bold;">{{negotiation.offerNegotiationDetails[0]?.unitPrice | currency: 'USD':'US$'}}</span> / {{negotiation.offerNegotiationDetails[0]?.typeSale}}
              </div>
            </td>
            <td>
              <div class="flex justify-content-center align-items-center">
                {{negotiation.exportOffer.caseQuantityPerPallet}} / {{negotiation.offerNegotiationDetails[0]?.pallets}}
              </div>
            </td>
            <td>
              <div class="flex justify-content-center align-items-center">
                <span style="font-weight: bold;">{{getTotalPrice(negotiation) | currency: 'USD':'US$'}}</span>
              </div>
            </td>
            <td>
              <div class="flex justify-content-center align-items-center">
                {{negotiation.exportOffer.fridgeStorage?.contactCity}}
              </div>
            </td>
            <td>
              <div class="flex justify-content-center align-items-center">
                <span style="font-weight: bold;">{{negotiation.offerNegotiationDetails[0].created | date: 'dd-MM-yyyy HH:mm'}}</span>
              </div>
            </td>
            <td>
              <p-button label="{{'UserPages.UserBargaining.OpenTab.OpenTable.SeeMoreButton' | translate}}" (onClick)="goToBargainingDetail(negotiation.offerNegotiation.id)"></p-button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </ng-template>
  </p-card>
</app-user-dashboard>
