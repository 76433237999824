import { AfterViewInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { UserGateway } from 'src/app/application/gateway/UserGateway';
import { BasicUser } from 'src/app/domain/models/User';
import { Routes } from 'src/app/infrastructure/routes';

import { ExportOfferAd } from 'src/app/domain/models/ExportOffer';
import { ExportOfferGateway } from 'src/app/application/gateway/ExportOfferGateway';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { Action } from 'rxjs/internal/scheduler/Action';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css'],
  providers: [MessageService]
})
export class HomePageComponent implements OnInit, AfterViewInit {


  offers: ExportOfferAd[] = []
  offersTrimmed: ExportOfferAd[] = []

  loading: boolean = false

  responsiveOptions = [
    {
        breakpoint: '1199px',
        numVisible: 1,
        numScroll: 1
    },
    {
        breakpoint: '991px',
        numVisible: 1,
        numScroll: 1
    },
    {
        breakpoint: '767px',
        numVisible: 1,
        numScroll: 1
    }
  ]

  carouselItems = [
    {
      imageUrl: '../../../../assets/img/home_carousel/banner/01-Banner-FR-EN.png',
      imageUrlEs: '../../../../assets/img/home_carousel/banner/01-Banner-FR-SP.png',
      btn: 'HomePage.MarketplaceBtn',
      command: (event: any) => this.goToAgendy(event),
      urlLink:"",
      sectionId:""
    },
    {
      imageUrl: '../../../../assets/img/home_carousel/banner/02-Banner-FR-EN.png',
      imageUrlEs: '../../../../assets/img/home_carousel/banner/02-Banner-FR-SP.png',
      btn: 'HomePage.MarketplaceBtn',
      command: (event: any) => this.goToMarketplace(event),
      urlLink:Routes.externalMarketplace,
      sectionId:""
    },
    {
      imageUrl: '../../../../assets/img/home_carousel/banner/03-Banner-FR-EN.png',
      imageUrlEs: '../../../../assets/img/home_carousel/banner/03-Banner-FR-SP.png',
      btn: 'HomePage.MarketplaceBtn',
      urlLink:Routes.toDoPage,
      sectionId:"virtual-comex",
      command: (event: any) => this.navigateTo(event ,Routes.toDoPage, "virtual-comex" )
    },
    {
      imageUrl: '../../../../assets/img/home_carousel/banner/04-Banner-FR-EN.png',
      imageUrlEs: '../../../../assets/img/home_carousel/banner/04-Banner-FR-SP.png',
      btn: 'HomePage.MarketplaceBtn',
      urlLink:"",
      sectionId:"",
      isMail:true,
      command: (event: any) => this.contactUs(event)
    },
    {
      imageUrl: '../../../../assets/img/home_carousel/banner/05-Banner-FR-EN.png',
      imageUrlEs: '../../../../assets/img/home_carousel/banner/05-Banner-FR-SP.png',
      btn: 'HomePage.MarketplaceBtn',
      command: (event: any) => this.navigateTo(event ,Routes.toDoPage, "find-and-connect"),
      urlLink:Routes.toDoPage,
      sectionId:"find-and-connect"
    },
    {
      imageUrl: '../../../../assets/img/home_carousel/banner/06-Banner-FR-EN.png',
      imageUrlEs: '../../../../assets/img/home_carousel/banner/06-Banner-FR-SP.png',
      btn: 'HomePage.MarketplaceBtn',
      command: (event: any) => this.navigateTo(event, Routes.toDoPage, "import-program"),
      urlLink:Routes.toDoPage,
      sectionId:"import-program"
    },
  ]
  carouselToDo = [
    {
      imageUrl: '../../../../assets/img/home_carousel/banner/01-QH-EN.png',
      imageUrlEs: '../../../../assets/img/home_carousel/banner/01-QH-SP.png',
      btn: 'HomePage.MarketplaceBtn'
    },
    {
      imageUrl: '../../../../assets/img/home_carousel/banner/02-QH-EN.png',
      imageUrlEs: '../../../../assets/img/home_carousel/banner/02-QH-SP.png',
      btn: 'HomePage.MarketplaceBtn'
    },
    {
      imageUrl: '../../../../assets/img/home_carousel/banner/03-QH-EN.png',
      imageUrlEs: '../../../../assets/img/home_carousel/banner/03-QH-SP.png',
      btn: 'HomePage.MarketplaceBtn'
    }
  ]
  carouselItemsOld = [
    {
      backgroundColor: '#F1D335',
      imageUrl: '../../../../assets/img/home_carousel/banne_1.png',
      imageUrl2: '../../../../assets/img/home_carousel/duraznos_trim.png',
      message: 'HomePage.BannerCTA1',
      description: 'HomePage.BannerMsg1',
      btn: 'HomePage.MarketplaceBtn'
    },
    {
      backgroundColor: '#3C1A43',
      imageUrl: '../../../../assets/img/home_carousel/UVAS-RED-BANNER.png',
      imageUrl2: '../../../../assets/img/home_carousel/uvas-rojas.png',
      message: 'HomePage.BannerCTA1',
      description: 'HomePage.BannerMsg1',
      btn: 'HomePage.MarketplaceBtn'
    },
    {
      backgroundColor: '#5CA53A',
      imageUrl: '../../../../assets/img/home_carousel/UVAS-VERDES-BANNER.png',
      imageUrl2: '../../../../assets/img/home_carousel/uvas_verdes_trim.png',
      message: 'HomePage.BannerCTA1',
      description: 'HomePage.BannerMsg1',
      btn: 'HomePage.MarketplaceBtn'
    }
  ]

  carouselItemsOld2 = [
    {
      backgroundColor: '#6EA042',
      imageUrl: '../../../../assets/img/home_carousel/apples.png',
      message: 'HomePage.BannerMessage1'
    },
    {
      backgroundColor: '#682472',
      imageUrl: '../../../../assets/img/home_carousel/grapes_redglobe_thompson.png',
      message: 'HomePage.BannerMessage2'
    },
    {
      backgroundColor: '#8D2C87',
      imageUrl: '../../../../assets/img/home_carousel/grapes_thompson.png',
      message: 'HomePage.BannerMessage3'
    },
    {
      backgroundColor: '#6EA042',
      imageUrl: '../../../../assets/img/home_carousel/oranges.png',
      message: 'HomePage.BannerMessage4'
    },
    {
      backgroundColor: '#3D1943',
      imageUrl: '../../../../assets/img/home_carousel/blueberries.png',
      message: 'HomePage.BannerMessage5'
    },
    {
      backgroundColor: '#8D2C87',
      imageUrl: '../../../../assets/img/home_carousel/plums.png',
      message: 'HomePage.BannerMessage6'
    }
  ]


  services=[
    {
      message:'HomePage.ServicesMessage1',
      iconUrl: '../../../../assets/icons/home_icon_1.png',
    },
    {
      message:'HomePage.ServicesMessage2',
      iconUrl: '../../../../assets/icons/home_icon_2.png',
    },
    {
      message:'HomePage.ServicesMessage3',
      iconUrl: '../../../../assets/icons/home_icon_3.png',
    },
    {
      message:'HomePage.ServicesMessage4',
      iconUrl: '../../../../assets/icons/home_icon_4.png',
    },
  ]

  toDo=[
    {
      iconUrl: '../../../../assets/icons/container-icon-line.svg',
      title: 'HomePage.ToDoTitle1',
      message: 'HomePage.ToDoMessage1'
    },
    {
      iconUrl: '../../../../assets/icons/Container-icon-02-shade.svg',
      title: 'HomePage.ToDoTitle2',
      message: 'HomePage.ToDoMessage1'
    },
    {
      iconUrl: '../../../../assets/icons/container-icon-line.svg',
      title: 'HomePage.ToDoTitle3',
      message: 'HomePage.ToDoMessage1'
    },
  ]

  contactEmail: string = environment.contactEmail;
  mailToUrl: string = 'mailto:' + this.contactEmail;

  agendyUrl: string = 'https://calendly.com/marco-santana-fruzty';

  profile: BasicUser | undefined

  @ViewChild('carToDo') elementRef : any;

  ngAfterViewInit() {
    this.lang = this.translate.currentLang

  }

  constructor(
    private _router: Router,
    private _authService: AuthGateway,
    private _messageService: MessageService,
    private _exportOfferService: ExportOfferGateway,
    public translate: TranslateService
  ) {
    this.loadExportOffers()
  }

  ngOnInit(): void {
    this.profile = this._authService.getUser()
    this.loadExportOffers()
  }

  loadExportOffers() {
    this.loading = true
    this._exportOfferService.getAllForMarketPlaceAds().subscribe({
      next: (v) => {
        this.offers = v
        this.offersTrimmed = this.offers.length > 12 ? this.offers.slice(0, 12) : this.offers;

        this.loading = false
      },
      error: (e) => {
        this.loading = false
        this._messageService.add({ severity: 'error',
        summary: this.translate.instant('MessageService.Summary.FailedAction'),
        detail: this.translate.instant('MessageService.ExportOffer.ManyRetrieveError') })
      }
    })
  }

  navigateToMarketplace()
  {
    this._router.navigate(['/'+ Routes.externalMarketplace])
  }
  navigateTo(event: any, urlLink:string, sectionId:string="")
  {

    console.log('section')
    if(event){
      if(sectionId!==""){
        window.open('/'+ urlLink + '#'+ sectionId, "_self")
      } else {
        this._router.navigate(['/'+ urlLink])
      }
    }
  }

  lang:string = ""

  getImageBg(imgEn:string, imgSp:string): string {
    let bgImg = ''

    switch(this.language) {
      case 'en':
        bgImg = imgEn
        break

        case 'es':
        bgImg = imgSp
        break
    }

    return bgImg
  }


  get language() {
    return this.translate.currentLang
  }

  goToMarketplace(event: any) {
    if(event){
      this._router.navigate(['/' + Routes.externalMarketplace]);
    }
  }

  contactUs(event: any){
    if(event){
      window.open(this.mailToUrl)
    }
  }

  goToAgendy(event: any){
    if(event){
      window.open(this.agendyUrl);
    }

  }

  sendMail() {
    const mail = 'marco.santana@fruzty.com';
    const subject = 'Consulta desde el sitio web de Fruzty';
    const body = 'Hola, tengo una consulta sobre...';

    window.location.href = `mailto:${mail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  }
}
