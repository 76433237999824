<app-admin-dashboard>
  <p-card [style]="{'min-height':'400px'}">
    <ng-template pTemplate="header">
      <div class="flex justify-content-between flex-wrap align-items-center" style="padding: 15px;">
        <h2>{{'UserPages.Notifications.UserNotifications.PageTitle' | translate}}</h2>
      </div>
      <div>
        <p-tabMenu [model]="tabMenuItems" [activeItem]="activeItem" [style]="{padding: '15px'}"></p-tabMenu>
      </div>
    </ng-template>
    <ng-template pTemplate="body">
      <p-table [value]="notifications"
        [paginator]="true"
        [rows]="10"
        [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[10, 25, 50]"
        [loading]="loading"
        responsiveLayout="stack"
        [breakpoint]="'600px'">
        <ng-template pTemplate="header">
          <tr>
            <th>
              <div class="flex justify-content-center align-items-center">{{'UserPages.Notifications.UserNotifications.CreatedTableHeader' | translate}}</div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center">{{'UserPages.Notifications.UserNotifications.SubjectTableHeader' | translate}}</div>
            </th>
            <th>
              <div class="flex justify-content-center align-items-center"></div>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-notification>
          <tr>
            <td><div class="flex justify-content-center align-items-center">{{notification.created | date: 'dd-MM-yyyy HH:mm'}}</div></td>
            <td><div class="flex justify-content-center align-items-center">{{notification.title}}</div></td>
            <td>
              <div class="flex justify-content-center align-items-center">
                <p-button icon="pi pi-eye" pTooltip="{{'UserPages.Notifications.UserNotifications.SeeMoreButton' | translate}}" (onClick)="openNotificationDialog(notification)"></p-button>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </ng-template>
  </p-card>
</app-admin-dashboard>
<p-toast></p-toast>

<p-dialog header="Notificación" [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="visibleNotificationDialog" [style]="{width: '50%'}">
  <div class="grid" *ngIf="selected">
    <div class="col-12 md:col-4 lg:col-8"><span style="font-weight: bold;">{{'UserPages.Notifications.UserNotifications.SubjectTableHeader' | translate}}</span></div>
    <div class="col-12 md:col-4 lg:col-8">{{selected.title}}</div>
    <div class="col-12 md:col-4 lg:col-8"><span style="font-weight: bold;">{{'UserPages.Notifications.UserNotifications.DescriptionText' | translate}}</span></div>
    <div class="col-12 md:col-4 lg:col-8">{{selected.description}} </div>
    <div *ngIf="selected.redirectUrl">
      <p-button label="{{'UserPages.Notifications.UserNotifications.SeeNotification' | translate}}" (onClick)="goToViewMore()"></p-button>
    </div>
    <div class="col-12 md:col-4 lg:col-8"><span style="font-weight: bold;">{{'UserPages.Notifications.UserNotifications.CreatedDateText' | translate}}</span></div>
    <div class="col-12 md:col-4 lg:col-8">{{selected.created | date: 'dd-MM-yyyy HH:mm'}}</div>
  </div>
  <ng-template pTemplate="footer">
    <p-button label="{{'UserPages.Notifications.UserNotifications.AcceptButton' | translate}}" (click)="closeNotificationDialog()"></p-button>
  </ng-template>
</p-dialog>
