import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { InputNumber } from 'primeng/inputnumber';
import { AuthGateway } from 'src/app/application/gateway/AuthGateway';
import { ProfileGateway } from 'src/app/application/gateway/ProfileGateway';
import { UserGateway } from 'src/app/application/gateway/UserGateway';
import { Profile, TwoFactorCode } from 'src/app/domain/models/Profile';
import { ChangePassword } from 'src/app/domain/models/User';
import { Routes } from 'src/app/infrastructure/routes';
import { createPasswordStrengthValidator } from 'src/app/ui/util/custom-validators';

@Component({
  selector: 'app-user-change-password',
  templateUrl: './user-change-password.component.html',
  styleUrls: ['./user-change-password.component.css'],
  providers: [MessageService]
})
export class UserChangePasswordComponent implements OnInit {

  submit: boolean = false
  loading: boolean = false
  profile: Profile | undefined
  isTwoAuth: boolean = false
  authLabel: string = "Active";
  showSwitch: boolean = true;
  showAuthentication: boolean = false;
  codeError: boolean = false;
  authenticationCode: string = ""

  formGroup: FormGroup = this._formBuilder.group({
    oldPassword: [{value: null}, Validators.required],
    newPassword: [{value: null}, [Validators.required, createPasswordStrengthValidator()]],
  })

  formGroup2FA: FormGroup = this._formBuilder.group({
    authCode1: [null],
    authCode2: [null],
    authCode3: [null],
    authCode4: [null],
    authCode5: [null],
    authCode6: [null]
  })

  constructor(private _router: Router,
    private _formBuilder: FormBuilder,
    private _messageService: MessageService,
    private _profileGateway: ProfileGateway,
    private _authService: AuthGateway,
    private _userService: UserGateway,
    private _profileService: ProfileGateway,
    public translate: TranslateService) {

  }

  ngOnInit(): void {
    this._profileGateway.getByUsername().subscribe(
      {
        next: (v) => {
          this.profile = v;
          this.isTwoAuth = this.profile.hasTwoAuthetication;
          if(this.isTwoAuth){
            this.authLabel = "Desactivar";
          }
        },
        error: (e) => {
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('MessageService.ProfileMessageService.SingleRetrieveError') })
        },
        complete: () => console.info('Password update successfully')
      }
    )
  }

  save() {
    this.loading = true
    this.submit = true

    if(this.formGroup.valid) {

      let oldPassword = this.formGroup.controls['oldPassword'].value
      let newPassword = this.formGroup.controls['newPassword'].value

      if(newPassword == oldPassword) {
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('UserPages.UserChangePassword.SamePasswordError') })
        this.submit = false
        this.loading = false
        return
      }

      let changePassword: ChangePassword = {
        username: this._authService.getUsername(),
        oldPassword: this.formGroup.controls['oldPassword'].value,
        newPassword: this.formGroup.controls['newPassword'].value
      }

      this._userService.updatePassword(changePassword).subscribe({
        next: (v) => {
          this._messageService.add({ severity: 'success',
            summary: this.translate.instant('MessageService.Summary.SuccessAction'),
            detail: this.translate.instant('UserPages.UserChangePassword.UpdatePasswordSuccess') })
          this.submit = false
          this.loading = false
        },
        error: (e) => {
          this.loading = false
          console.log(e)
          this._messageService.add({ severity: 'error',
            summary: this.translate.instant('MessageService.Summary.FailedAction'),
            detail: this.translate.instant('UserPages.UserChangePassword.UpdatePasswordError') });
        },
        complete: () => console.info('Password update successfully')
      })
    }
    else {
      this.loading = false
    }
  }

  goToChangePasswordPage() {
    this._router.navigate([Routes.resetPasswordRequestPage])
  }

  getErrorMessage(fieldName: string): string {
    if (this.formGroup.controls[fieldName].hasError('required')) {
      return this.translate.instant('FormErrorMessage.FieldValueRequired')
    }
    if (this.formGroup.controls[fieldName].hasError('email')) {
      return this.translate.instant('FormErrorMessage.EmailFieldEmailFormatError')
    }
    if (this.formGroup.controls[fieldName].hasError('verifierDigitError')) {
      return this.translate.instant('FormErrorMessage.StrongPasswordError')
    }
    return ''
  }

  toggleAuth(){
    this.handleAuthCode()
    if (this.formGroup2FA.valid) {
      const reqFactorCode: TwoFactorCode = {
        authenticationCode: this.authenticationCode
      }
      this._profileGateway.toggleTwoAuth(reqFactorCode).subscribe({
        next: this.handleSuccessResponse.bind(this),
        error: this.handleErrorResponse.bind(this)
      })
    }
  }

  handleSuccessResponse(data: any) {
    if (data?.requiredAuthCode) {
      this.showSwitch = false;
      this.showAuthentication = true;
      this.loading = false;
    } else {
      this.showSwitch = true;
      this.showAuthentication = false;
      this.clearInputs()
      if(this.isTwoAuth)
      {
        if(this.profile) {
          switch(this.profile.lang) {
            case 'en':
              this.authLabel = "Deactive"
              break
            case 'es':
              this.authLabel = "Desactivar"
              break
          }
        }
        this._messageService.add({ severity: 'success',
          summary: this.translate.instant('MessageService.Summary.SuccessAction'),
          detail: this.translate.instant('UserPages.UserChangePassword.TwoStepActivationSuccess') });
      } else {
        if(this.profile) {
          switch(this.profile.lang) {
            case 'en':
              this.authLabel = "Active"
              break
            case 'es':
              this.authLabel = "Activar"
              break
          }
        }
        this._messageService.add({ severity: 'success',
          summary:  this.translate.instant('MessageService.Summary.SuccessAction'),
          detail: this.translate.instant('UserPages.UserChangePassword.TwoStepDeactivationSuccess') });
      }
    }
  }

  handleErrorResponse(error: any) {
    if (this.showAuthentication) {
      this.codeError = true;
      this.loading = false;
      let errorMessage = ''
      const lang = this.translate.currentLang
      switch(lang) {
        case 'es':
          errorMessage = error.error.msg.messageEs
          break
        case 'en':
          errorMessage = error.error.msg.messageEn
          break
        default:
          errorMessage = error.error.msg.messageEn
      }
      this._messageService.add({
        severity: 'error',
        summary: this.translate.instant('MessageService.Summary.FailedAction'),
        detail: errorMessage
      });
    } else {
      this.loading = false;
    }
  }

  handleFocusNext(inputNumber: InputNumber) {
    inputNumber.input.nativeElement.focus()
  }

  clear(auth: string) {
    this.formGroup2FA.controls[auth].setValue(null);
  }

  clearInputs() {
    this.formGroup2FA.controls['authCode1'].setValue(null)
    this.formGroup2FA.controls['authCode2'].setValue(null)
    this.formGroup2FA.controls['authCode3'].setValue(null)
    this.formGroup2FA.controls['authCode4'].setValue(null)
    this.formGroup2FA.controls['authCode5'].setValue(null)
    this.formGroup2FA.controls['authCode6'].setValue(null)
  }

  onPaste(event: ClipboardEvent) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData?.getData('text')
    if(pastedText?.length === 6 && !isNaN(parseInt(pastedText))) {
      const digits = [...pastedText]
      this.formGroup2FA.controls['authCode1'].setValue(digits[0])
      this.formGroup2FA.controls['authCode2'].setValue(digits[1])
      this.formGroup2FA.controls['authCode3'].setValue(digits[2])
      this.formGroup2FA.controls['authCode4'].setValue(digits[3])
      this.formGroup2FA.controls['authCode5'].setValue(digits[4])
      this.formGroup2FA.controls['authCode6'].setValue(digits[5])
    }
  }

  handleAuthCode() {
    if (this.showAuthentication) {
      let digit1 = this.formGroup2FA.controls['authCode1'].value;
      let digit2 = this.formGroup2FA.controls['authCode2'].value;
      let digit3 = this.formGroup2FA.controls['authCode3'].value;
      let digit4 = this.formGroup2FA.controls['authCode4'].value;
      let digit5 = this.formGroup2FA.controls['authCode5'].value;
      let digit6 = this.formGroup2FA.controls['authCode6'].value;

      this.authenticationCode = "" + digit1 + digit2 + digit3 + digit4 + digit5 + digit6;
    } else {
      this.authenticationCode = "";
    }
  }

  resendCode() {
    this.loading = true;
    this.codeError = false;
    this._profileService.resendCode(this.profile?.contactEmail ?? "", "").subscribe({
      next: (v) => {
        this.loading = false;
        this._messageService.add({ severity: 'success',
          summary: this.translate.instant('MessageService.Summary.SuccessAction'),
          detail: this.translate.instant('LoginPage.ResendCodeSuccess') })
      },
      error: (e) => {
        this.loading = false;
        this._messageService.add({ severity: 'error',
          summary: this.translate.instant('MessageService.Summary.FailedAction'),
          detail: this.translate.instant('LoginPage.ResendCodeError') })
      }
    })
  }
}
