import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Delivery, DeliveryInformation, UpdateDeliveryRequest } from "src/app/domain/models/Delivery";

@Injectable({providedIn: 'root'})
export abstract class DeliveryGateway {
  abstract getByImportOrderId(importOrderId: any): Observable<DeliveryInformation>
  abstract update(deliveryDto: UpdateDeliveryRequest): Observable<DeliveryInformation>
  abstract removeNotification(importOrderId: any, notificationType: string): Observable<any>
}
