import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { UploadImportOrderDocumentRequest, ImportOrderDocument } from "src/app/domain/models/ImportOrder";

@Injectable({providedIn: 'root'})
export abstract class ImportOrderDocumentGateway {
  abstract getAllImportOrderDocuments(guid: string, documentType: number): Observable<ImportOrderDocument[]>
  abstract uploadImportOrderDocument(request: UploadImportOrderDocumentRequest): Observable<any>
  abstract deleteImportOrderDocument(guid: any): Observable<any>
}
